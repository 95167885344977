import { createAction, props } from '@ngrx/store';
import { IMatterDetailEntry } from '@app/shared/models';
import {
  CreateMatterSuccessPayload,
  IMatterListEntry,
  IMatterListResponseSchema,
  INewMatterParams,
} from '../../../models';
import { ICloudSearchPayload } from '../matter-search/matter-search-api.action';

export const InitListMattersStart = createAction(
  '[Matter List from api] init start',
  props<{ params: any }>()
);

export const InitListMattersSuccess = createAction(
  '[Matter List from api] init success',
  props<{ response: IMatterListResponseSchema }>()
);

export const InitListMattersFailure = createAction(
  '[Matter List from api] init failure',
  props<{ error: any }>()
);

export const ListMattersStart = createAction(
  '[Matter List from api] update matter list start',
  props<{ page: number }>()
);

export const ListMattersSuccess = createAction(
  '[Matter List from api] update matter list success',
  props<{ response: IMatterListResponseSchema }>()
);

export const ListMattersFailure = createAction(
  '[Matter List from api] update matter list failure',
  props<{ error: any }>()
);

export const CloudSearchStart = createAction(
  '[Matters List] cloud search start',
  props<{ searchQuery: any }>()
);

export const CloudSearchSuccess = createAction(
  '[Matters List] cloud search success',
  props<{ result: ICloudSearchPayload }>()
);

export const DeleteMatterStart = createAction(
  '[Delete matter] start',
  props<{ matter: IMatterListEntry }>()
);

export const DeleteMatterSuccess = createAction(
  '[Delete matter] success',
  props<{ result: any }>()
);

export const DeleteMatterFailure = createAction(
  '[Delete matter] failure',
  props<{ error: any }>()
);

// Create Matter Actions
export const CreateMatterStart = createAction(
  '[Create matter] start',
  props<{ matterParams: INewMatterParams }>()
);

export const CreateMatterSuccess = createAction(
  '[Create matter] success',
  props<{ result: CreateMatterSuccessPayload }>()
);

export const CreateMatterFailure = createAction(
  '[Create matter] failure',
  props<{ errorMessage: string }>()
);

export const CreateMatterWarning = createAction(
  '[Create matter] warning',
  props<{ result: CreateMatterSuccessPayload }>()
);

export const CreateMatterLoadingStatus = createAction(
  '[Create matter] loading status',
  props<{ isLoading: boolean }>()
);

export const ReupdatedMatterDetailEntries = createAction(
  '[Reupdate matter] reupdate the matter detail',
  props<{
    matterEntries: IMatterDetailEntry[];
    matterTypeId: string;
    fileNumber: string;
    matterId: string;
  }>()
);
