import { ISortInfo } from '@app/shared/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { startOfDay, startOfMonth } from 'date-fns';
import { IBatchType, IBatch, IBatchSearchFilter, IBatchTypes } from '../../models';
import * as actions from '../actions';

export interface State extends EntityState<IBatch> {
  loading: boolean;
  filtering: boolean;
  typesLoaded: boolean;
  batchTypes: Array<IBatchType>;
  batchFilter: IBatchSearchFilter;
  sortInfo: ISortInfo;
  selectedBatch: IBatch;
  onCloseResponse: IBatch;
}

export const selectId = (batch: IBatch): string => batch.BatchGUID;

export const adapter: EntityAdapter<IBatch> = createEntityAdapter<IBatch>({
  selectId,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  filtering: false,
  typesLoaded: false,
  batchTypes: null,
  batchFilter: {
    BatchNo: null,
    Type: IBatchTypes.All,
    Staff: null,
    DateFrom: startOfMonth(new Date()),
    DateTo: startOfDay(new Date()),
  },
  sortInfo: {
    colId: 'batchNo',
    sort: 'desc',
  },
  selectedBatch: null,
  onCloseResponse: null,
});

export const reducer = (
  state: State = initialState,
  action: actions.BatchSearchActions | actions.BatchSearchInitActions
): State => {
  switch (action.type) {
    case actions.BATCH_SEARCH_INIT:
      return {
        ...state,
        loading: true,
      };

    case actions.BATCH_SEARCH_FILTER_INIT:
      return {
        ...state,
        filtering: true,
      };

    case actions.BATCH_SEARCH_INIT_SUCCESS:
      const { batches, batchTypes } = action.payload.data;
      const { typesLoaded } = action.payload;
      return state.loading || state.filtering
        ? {
            ...adapter.setAll(batches, adapter.removeAll(state)),
            batchTypes: typesLoaded ? state.batchTypes : batchTypes,
            loading: false,
            filtering: false,
          }
        : state;

    case actions.BATCH_SEARCH_INIT_FAILURE:
      return {
        ...state,
        loading: false,
        filtering: false,
      };

    case actions.BATCH_SEARCH_SET_FILTER: {
      return {
        ...state,
        batchFilter: Object.assign({}, state.batchFilter, action.payload),
      };
    }

    case actions.BATCH_SEARCH_SET_SORT: {
      return {
        ...state,
        sortInfo: action.payload,
      };
    }

    case actions.BATCH_SEARCH_BATCH_SELECTED: {
      return {
        ...state,
        selectedBatch: action.payload,
      };
    }

    case actions.BATCH_SEARCH_CLOSE: {
      return {
        ...state,
        onCloseResponse: state.selectedBatch,
        selectedBatch: null,
      };
    }

    case actions.CLEAR_STORE: {
      return initialState;
    }

    default:
      return state;
  }
};

export const selectLoading = (state: State) => state.loading;
export const selectFiltering = (state: State) => state.filtering;
export const selectBatchTypesLoaded = (state: State) => state.typesLoaded;
export const selectBatchTypes = (state: State) => state.batchTypes;
export const selectBatchFilter = (state: State) => state.batchFilter;
export const selectBatchSearchSortInfo = (state: State) => state.sortInfo;
export const selectOnCloseResponse = (state: State) => (state ? state.onCloseResponse : null);
