import { Injectable } from '@angular/core';
import { Browser } from '../../models';
import { LogService } from '../../../core/services';
import Bowser from 'bowser';
@Injectable()
export class BrowserService {
  get detectedBrowser(): Browser {
    return this._browser;
  }
  private _browser: Browser;

  constructor(private _log: LogService) {
    this._log.init('browser-service');
    this._browser = this.detect();
    this._log.info(this._browser);
  }

  private detect(): Browser {
    this._log.info('bowser: ', Bowser);

    if (!('window' in globalThis)) {
      return Browser.Other;
    }

    const browser = Bowser.getParser(window.navigator.userAgent);
    const browserName = browser.getBrowserName().toLowerCase();

    switch (browserName) {
      case 'chrome':
        return Browser.Chrome;
      case 'firefox':
        return Browser.Firefox;
      case 'internet explorer':
        return Browser.InternetExplorer;
      case 'microsoft edge':
        return Browser.MicrosoftEdge;
      case 'safari':
        return Browser.Safari;
      default:
        return Browser.Other;
    }
  }
}
