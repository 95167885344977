import { Component, OnInit } from '@angular/core';
import { LogService } from '@app/core/services';
import { BrowserService } from '../../services/browser/browser.service';
import { Browser } from '../../models';
import { environment } from '@env/environment';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'sc-popup-warning',
  templateUrl: './popup-warning.component.html',
})
export class PopupWarningComponent implements OnInit {
  get region(): string {
    return environment.config.brand.region;
  }

  get brandUrl(): string {
    return environment.config.brand.brandUrl;
  }

  get detectedBrowser(): Browser {
    return this._browser;
  }

  get message(): string {
    return this._message;
  }

  private _message: string;
  private _browser: Browser;

  constructor(public bsModalRef: BsModalRef, private _browserSvc: BrowserService, private _log: LogService) {
    this._log.init('popup-warning-component');
    this._browser = this._browserSvc.detectedBrowser;
  }

  ngOnInit() {
    this._log.info(this.detectedBrowser);
  }

  get isChrome(): boolean {
    return this.detectedBrowser === Browser.Chrome;
  }

  get isFirefox(): boolean {
    return this.detectedBrowser === Browser.Firefox;
  }

  get isInternetExplorer(): boolean {
    return this.detectedBrowser === Browser.InternetExplorer;
  }

  get isMicrosoftEdge(): boolean {
    return this.detectedBrowser === Browser.MicrosoftEdge;
  }

  get isOther(): boolean {
    return this.detectedBrowser === Browser.Other;
  }

  get isSafari(): boolean {
    return this.detectedBrowser === Browser.Safari;
  }

  get isTitleX(): boolean {
    return environment.config.brand.id.indexOf('titlex') >= 0;
  }

  dismiss(): void {
    this.bsModalRef.hide();
  }
}
