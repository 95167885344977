import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '@app/shared/services/base/base.service';
import { INamedList, INamedLists } from '../models';

@Injectable()
export class NamedListsService extends BaseService {
  private readonly path: string;

  constructor(private http: HttpClient) {
    super();

    this.path = `${this.apiPath}/api/v1/content/nameditemlists`;
  }

  getAll(): Observable<INamedLists> {
    const url = this.path;
    const project = (response: INamedLists) => response;
    return this.http.get(url).pipe(map(project));
  }

  saveNamedList(list: INamedList): Observable<any> {
    const url = this.path;
    return this.http.post<any>(url, list);
  }
}
