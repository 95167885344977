import { debounceTime, distinctUntilKeyChanged, takeUntil } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AnalyticsModel } from '@app/core/models/analytics.model';
import { AnalyticsService } from '@app/core/services';

@Component({
  selector: 'sc-search',
  templateUrl: './search.component.html',
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input()
  placeholder: string;
  @Input()
  size: string;
  @Input()
  showBorderBottom = false;
  @Input()
  isDisabled: boolean;

  @Input()
  set searchText(value: string) {
    this._searchText = value;
    // Prevents continuous form update. This patching should only happens once when initialising form data with cache.
    if (!!this.searchForm && !this.searchForm.get('searchText').value && !!value) {
      this.searchForm.patchValue({ searchText: value });
    }

    if (!!this.searchForm) {
      const control = this.searchForm.get('searchText');
      if (!!control && control.value !== value) {
        this.searchForm.patchValue({ searchText: value });
      }
    }
  }

  get searchText() {
    return this._searchText;
  }

  @Input()
  isInputGroup = false;

  @Output()
  onSearchTextChanged = new EventEmitter<string>();

  private _searchText: string;
  private _formChangeSub: Subscription;
  private unsub = new Subject<void>();

  searchFormClass = 'x-toolbar-actions-50';
  searchForm: FormGroup;

  @Input()
  searchDebounceMs = 0;

  @Input()
  analytics: AnalyticsModel;

  constructor(private _formBuilder: FormBuilder, private _analyticsSvc: AnalyticsService) {}

  ngOnInit() {
    if (this.size) {
      this.searchFormClass = 'x-toolbar-actions-' + this.size;
    }
    if (this.showBorderBottom) {
      this.searchFormClass += ' border-bottom';
    }
    this.createForm();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  private createForm() {
    this.searchForm = this._formBuilder.group({
      searchText: this.searchText,
    });
    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges() {
    if (this.searchForm) {
      this._formChangeSub = this.searchForm.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilKeyChanged('searchText'),
          takeUntil(this.unsub) // important: this operator must be last
        )
        .subscribe((formData: { searchText: string }) => {
          if (!!this.analytics && !!formData.searchText) {
            this._analyticsSvc.eventTrack(this.analytics);
          }
          this.onSearchTextChanged.emit(formData.searchText);
        });
    }
  }
}
