import { Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-date-cell',
  templateUrl: './acc-date-cell.component.html',
})
export class AccDateCellComponent implements AgRendererComponent {
  wrapperClass: string;
  transactionDate: Date;
  isHidden: boolean;

  constructor() {}

  agInit(params: any): void {
    this.wrapperClass = isFunction(params.getWrapperClass) ? params.getWrapperClass(params.data) : '';
    this.isHidden = !!params.isHidden && isFunction(params.isHidden) ? params.isHidden(params.data) : false;
    this.transactionDate = this.isHidden ? null : params.getTransactionDate(params.data);
  }

  refresh(params: any): boolean {
    return true;
  }
}
