import { Attachment } from '../../models';
import { Action } from '@ngrx/store';
import { FileItem } from 'ng2-file-upload';
import { IEmail } from '@app/features/+email/models/email.model';
import { IEmailAddress } from '@app/features/+email/models';

// add attachments
export const ADD_ATTACHMENTS = '[Email] add attachments';
export const ADD_ATTACHMENTS_SUCCESS = '[Email] add attachments success';
export const ADD_ATTACHMENTS_FAILURE = '[Email] add attachments failure';

// add attachments
export const ADD_RECIPIENTS_START = '[Email] add recipients';
export const ADD_RECIPIENTS_SUCCESS = '[Email] add recipients success';
export const ADD_RECIPIENTS_FAILURE = '[Email] add recipients failure';

// delete attachments
export const DELETE_ATTACHMENT = '[Email] delete attachment';
export const DELETE_ATTACHMENT_SUCCESS = '[Email] delete attachment success';
export const DELETE_ATTACHMENT_FAILURE = '[Email] delete attachment failure';

// refresh attachments
export const REFRESH_ATTACHMENTS = '[Email] refresh attachments';
export const REFRESH_ATTACHMENTS_SUCCESS = '[Email] refresh attachments success';
export const REFRESH_ATTACHMENTS_FAILURE = '[Email] refresh attachments failure';

// send email
export const SEND_EMAIL = '[Email] send email';
export const SEND_EMAIL_SUCCESS = '[Email] send email success';
export const SEND_EMAIL_FAILURE = '[Email] send email failure';

// get email subject
export const GET_EMAIL_SUBJ_START = '[Email] get email subject start';
export const GET_EMAIL_SUBJ_START_SUCCESS = '[Email] get email subject success';
export const GET_EMAIL_SUBJ_START_FAILURE = '[Email] get email subject failure';

// close email
export const CLOSE_EMAIL = '[Email] close email';
export const CLOSE_EMAIL_SUCCESS = '[Email] close email success';
export const CLOSE_EMAIL_FAILURE = '[Email] close email failure';

// hydrate email
export const HYDRATE_EMAIL_FORM = '[Email] hydrate email form';
export const HYDRATE_EMAIL_FORM_SUCCESS = '[Email] hydrate email form success';
export const HYDRATE_EMAIL_FORM_FAILURE = '[Email] hydrate email form failure';

// dehydrate email
export const DEHYDRATE_EMAIL_FORM = '[Email] dehydrate email form';
export const DEHYDRATE_EMAIL_FORM_SUCCESS = '[Email] dehydrate email form success';
export const DEHYDRATE_EMAIL_FORM_FAILURE = '[Email] dehydrate email form failure';

// mark email form dirty
export const MARK_EMAIL_FORM_DIRTY = '[Email] mark email form dirty';

// open attachments modal
export const OPEN_ATTACHMENTS_MODAL = '[Email] open attachments modal';

export const EMAIL_MODAL_CLOSED = '[Email] modal closed';

export class MarkEmailFormDirty implements Action {
  readonly type = MARK_EMAIL_FORM_DIRTY;
  constructor(public payload: boolean) {}
}

export class AddAttachments implements Action {
  readonly type = ADD_ATTACHMENTS;
  constructor(public payload: FileItem[]) {}
}

export class AddAttachmentsSuccess implements Action {
  readonly type = ADD_ATTACHMENTS_SUCCESS;
  constructor(public payload: Attachment[]) {}
}

export class AddAttachmentsFailure implements Action {
  readonly type = ADD_ATTACHMENTS_FAILURE;
  constructor(public payload: any) {}
}

export class AddRecipientsStart implements Action {
  readonly type = ADD_RECIPIENTS_START;
  constructor(public payload: IEmailAddress[]) {}
}

export class AddRecipientsSuccess implements Action {
  readonly type = ADD_RECIPIENTS_SUCCESS;
  constructor(public payload: any) {}
}

export class AddRecipientsFailure implements Action {
  readonly type = ADD_RECIPIENTS_FAILURE;
  constructor(public payload: any) {}
}

export class DeleteAttachment implements Action {
  readonly type = DELETE_ATTACHMENT;
  constructor(public payload: Attachment) {}
}

export class DeleteAttachmentSuccess implements Action {
  readonly type = DELETE_ATTACHMENT_SUCCESS;
  constructor(public payload: Attachment) {}
}

export class DeleteAttachmentFailure implements Action {
  readonly type = DELETE_ATTACHMENT_FAILURE;
  constructor(public payload: any) {}
}

// refresh attachments
export class RefreshAttachments implements Action {
  readonly type = REFRESH_ATTACHMENTS;
  constructor(public payload: Attachment[]) {}
}

export class RefreshAttachmentsSuccess implements Action {
  readonly type = REFRESH_ATTACHMENTS_SUCCESS;
  constructor(public payload: any) {}
}

export class RefreshAttachmentsFailure implements Action {
  readonly type = REFRESH_ATTACHMENTS_FAILURE;
  constructor(public payload: any) {}
}

// send email
export class SendEmail implements Action {
  readonly type = SEND_EMAIL;
  constructor(public payload: IEmail) {}
}

export class SendEmailSuccess implements Action {
  readonly type = SEND_EMAIL_SUCCESS;
  constructor(public payload: any) {}
}

export class SendEmailFailure implements Action {
  readonly type = SEND_EMAIL_FAILURE;
  constructor(public payload: any) {}
}

// close email
export class CloseEmail implements Action {
  readonly type = CLOSE_EMAIL;
  constructor(public payload: any) {}
}

export class CloseEmailSuccess implements Action {
  readonly type = CLOSE_EMAIL_SUCCESS;
  constructor(public payload: any) {}
}

export class CloseEmailFailure implements Action {
  readonly type = CLOSE_EMAIL_FAILURE;
  constructor(public payload: any) {}
}

// get email subject
export class GetEmailSubject implements Action {
  readonly type = GET_EMAIL_SUBJ_START;
  constructor(public payload: IEmailAddress[]) {}
}

export class GetEmailSubjectSuccess implements Action {
  readonly type = GET_EMAIL_SUBJ_START_SUCCESS;
  constructor(public payload: any) {}
}

export class GetEmailSubjectFailure implements Action {
  readonly type = GET_EMAIL_SUBJ_START_FAILURE;
  constructor(public payload: any) {}
}

//  hydrate email
export class HydrateEmailForm implements Action {
  readonly type = HYDRATE_EMAIL_FORM;
  constructor(public payload: any) {}
}

export class HydrateEmailFormSuccess implements Action {
  readonly type = HYDRATE_EMAIL_FORM_SUCCESS;
  constructor(public payload: any) {}
}

export class HydrateEmailFormFailure implements Action {
  readonly type = HYDRATE_EMAIL_FORM_FAILURE;
  constructor(public payload: any) {}
}

//  hydrate email
export class DeHydrateEmailForm implements Action {
  readonly type = DEHYDRATE_EMAIL_FORM;
  constructor(public payload: any) {}
}

export class DeHydrateEmailFormSuccess implements Action {
  readonly type = DEHYDRATE_EMAIL_FORM_SUCCESS;
  constructor(public payload: any) {}
}

export class DeHydrateEmailFormFailure implements Action {
  readonly type = DEHYDRATE_EMAIL_FORM_FAILURE;
  constructor(public payload: any) {}
}

export class OpenAttachmentsModal implements Action {
  readonly type = OPEN_ATTACHMENTS_MODAL;
  constructor(public payload: any){}
}

export class EmailModalClosed implements Action {
  readonly type = EMAIL_MODAL_CLOSED;
  constructor(public payload: {
    forceClear: boolean;
    isSent: boolean;
    isDirty: boolean;
  }){}
}

export type EmailActions =
  | DeleteAttachment
  | DeleteAttachmentSuccess
  | DeleteAttachmentFailure
  | RefreshAttachments
  | RefreshAttachmentsSuccess
  | RefreshAttachmentsFailure
  | AddAttachments
  | AddAttachmentsSuccess
  | AddAttachmentsFailure
  | AddRecipientsStart
  | AddRecipientsSuccess
  | AddRecipientsFailure
  | SendEmail
  | SendEmailSuccess
  | SendEmailFailure
  | GetEmailSubject
  | GetEmailSubjectSuccess
  | GetEmailSubjectFailure
  | CloseEmailSuccess
  | CloseEmailFailure
  | CloseEmail
  | HydrateEmailForm
  | HydrateEmailFormSuccess
  | HydrateEmailFormFailure
  | DeHydrateEmailForm
  | DeHydrateEmailFormSuccess
  | DeHydrateEmailFormFailure
  | MarkEmailFormDirty
  | EmailModalClosed;
