export enum ENotificationEntityType {
  Undefined = 'Undefined', // eslint-disable-line
  Matter = 'Matter',
  Card = 'Card',
  Person = 'Person',
  Document = 'Document',
  SearchOrder = 'SearchOrder',
  Appointment = 'Appointment',
  Task = 'Task',
  CriticalDate = 'CriticalDate',
  Comment = 'Comment',
  Email = 'Email',
  PaymentRequest = 'PaymentRequest',
}

export interface INotificationEntity {
  id: string;
  type: ENotificationEntityType;
  relatedEntityId: string;
  relatedEntity: ENotificationEntityType;
}

export interface INotification {
  id: number;
  topicName: string;
  firmId: string;
  staffId: string;
  title: string;
  body: string;
  format: string;
  senderId: string;
  senderName: string;
  timestamp: string;
  entity: INotificationEntity;
}

export interface INotificationResponse {
  notifications: INotification[];
  firmId: string;
  staffId: string;
  continuationToken: string;
  lastEvaluatedKey: string;
  // The backend has been updated (maxResult -> maxResults)
  // TODO: We need a way to (re)generate api interfaces
  maxResults?: number;
}

export interface INotificationMetaInfo {
  id: string;
  continuationToken?: string;
  firmId?: string;
  staffId?: string;
  lastEvaluatedKey?: string;
}

export interface INotificationGroup {
  notifications: INotification[];
  relatedEntityRouteId: string;
}

export interface INotificationEvent {
  staffId: string;
  notificationId: number;
  timeStamp: string;
  actionType: string;
}

export interface INotificationEventResponse {
  events: INotificationEvent[];
  lastEvaluatedKey: string;
}

export interface INotificationAppointmentBody {
  authorId: string;
  authorName: string;
  startDate: string;
  dueDate: string;
}

export interface INotificationTaskBody {
  authorId: string;
  authorName: string;
  dueDate: string;
}
