import { Action } from '@ngrx/store';
import { EPageName } from '@app/core/models';
import { IMatterListEntry } from '@app/features/+matter-list/models';

export enum BrowserTitleActionTypes {
  PRIMARY_PAGE_TITLE_INIT = '[Browser Title] primary page title init',
  POPUP_PAGE_TITLE_INIT = '[Browser Title] popup page title init',
  SET_BROWSER_TITLE = '[Browser Title] set browser title',
  SET_POPUP_PAGE_NAME = '[Browser Title] set the popup page name',
  SET_PRIMARY_PAGE_NAME = '[Browser Title] set the primary page name',
  CLEAR_POPUP_PAGE_NAME = '[Browser Title] clear popup page name',
  UPDATE_MATTER_INFO = '[Browser Title] update the matter information',
  SET_BRAND_NAME = '[Browser Title] set the brand name',
}

export class PrimaryPageTitleInit implements Action {
  readonly type = BrowserTitleActionTypes.PRIMARY_PAGE_TITLE_INIT;
}

export class PopupPageInit implements Action {
  readonly type = BrowserTitleActionTypes.POPUP_PAGE_TITLE_INIT;
}

export class SetBrowserTitle implements Action {
  readonly type = BrowserTitleActionTypes.SET_BROWSER_TITLE;

  constructor(
    public payload: {
      pageName: EPageName | undefined;
      hideMatterInfo?: boolean;
      matterInfo?: Partial<IMatterListEntry>;
      pageBrandName?: string;
    }
  ) {}
}

export class SetPrimaryPageName implements Action {
  readonly type = BrowserTitleActionTypes.SET_PRIMARY_PAGE_NAME;

  constructor(public payload: { pageName: EPageName }) {}
}

export class SetPopupPageName implements Action {
  readonly type = BrowserTitleActionTypes.SET_POPUP_PAGE_NAME;

  constructor(public payload: { pageName: EPageName }) {}
}

export class ClearPopupPageName implements Action {
  readonly type = BrowserTitleActionTypes.CLEAR_POPUP_PAGE_NAME;
}

export class UpdateMatterInfo implements Action {
  readonly type = BrowserTitleActionTypes.UPDATE_MATTER_INFO;

  constructor(public payload: any) {}
}

export class SetBrandName implements Action {
  readonly type = BrowserTitleActionTypes.SET_BRAND_NAME;

  constructor(public payload: { name: string }) {}
}

export type BrowserTitleActions =
  | PrimaryPageTitleInit
  | PopupPageInit
  | SetBrowserTitle
  | SetPopupPageName
  | SetPrimaryPageName
  | ClearPopupPageName
  | UpdateMatterInfo
  | SetBrandName;
