import { Injectable } from '@angular/core';
import { ErrorHandlerLogger } from '@app/features/error-handler/interfaces/error-handler.interfaces';

@Injectable()
export class ErrorHandlerLoggerService implements ErrorHandlerLogger {
  error(e: any): any {
    console.error(e);
  }

  warn(e: any): any {
    console.warn(e);
  }
}
