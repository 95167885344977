import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'sc-alert',
  templateUrl: './alert.component.html',
})
export class AlertComponent implements OnInit {
  @Input()
  title: string;
  @Input()
  description: string;
  @Input()
  wrapperClass: string;
  @Input()
  iconName: string;
  classes: string;

  constructor() {}

  ngOnInit() {
    this.classes = 'x-alert ';
    if (this.wrapperClass) {
      this.classes += this.wrapperClass;
    }
  }
}
