import { EAccountingTransaction } from '@app/features/accounting/models';
import { PreviewAccountingTransaction } from '../../models/preview-model';
import * as previewActions from '../actions/preview.actions';
export interface State {
  loading: boolean;
  previewUrl: string;
  previewBase64String: string;
  documentId: string;
  superDiaryDocumentId: string;
  documentName: string;
  nextNavigationTransactionType: EAccountingTransaction | null;
  nextNavigationQuery: any;
  precedentId: string; //* for docBuilder post api/invoices
  isCustomPrecedent: boolean; //* for docBuilder post api/invoices
  transactionType: EAccountingTransaction | null;
  transactionList: Array<PreviewAccountingTransaction>;
  document: any;
}

export const INITIAL_STATE: State = {
  loading: false,
  previewUrl: '',
  previewBase64String: '',
  documentId: '',
  superDiaryDocumentId: '',
  documentName: '',
  nextNavigationTransactionType: null,
  nextNavigationQuery: {},
  precedentId: '',
  isCustomPrecedent: false,
  transactionType: null,
  transactionList: [],
  document: null,
};

export const reducer = (state = INITIAL_STATE, action: previewActions.PreviewActions) => {
  switch (action.type) {
    case previewActions.GET_INVOICE_PREVIEW_VIA_DOC_BUILDER:
    case previewActions.GET_INVOICE_PREVIEW_VIA_REPORTING:
    case previewActions.GET_ACCOUNTING_PREVIEW_VIA_DOC_BUILDER:
    case previewActions.GET_ACCOUNTING_PREVIEW_VIA_REPORTING:
      return {
        ...state,
        loading: true,
      };

    case previewActions.SET_PREVIEW_ACCOUNTING_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload.status,
      };

    case previewActions.SET_PREVIEW_ACCOUNTING_BASE64:
      return {
        ...state,
        previewBase64String: action.payload.previewBase64String,
        superDiaryDocumentId: action.payload.superDiaryDocumentId,
        documentName: action.payload.documentName,
        precedentId: action.payload.precedentId,
        isCustomPrecedent: action.payload.isCustomPrecedent,
        transactionType: action.payload.transactionType,
        transactionList: action.payload.transactionList,
      };

    case previewActions.SET_PREVIEW_ACCOUNTING_URL:
      return {
        ...state,
        previewUrl: action.payload.previewUrl,
        documentName: action.payload.documentName,
        transactionType: action.payload.transactionType,
        transactionList: action.payload.transactionList,
      };

    case previewActions.SET_NEXT_ACCOUNTING_NAVIGATION_INFORM:
      return {
        ...state,
        nextNavigationTransactionType: action.payload.type,
        nextNavigationQuery: action.payload.query,
      };

    case previewActions.CLEAR_PREVIEW_ACCOUNTING:
      return INITIAL_STATE;

    case previewActions.SET_PREVIEW_DOCUMENT:
      return {
        ...state,
        document: action.payload
      };

    default:
      return state;
  }
};

export const selectPreviewUrl = (state: State) => state.previewUrl;
export const selectPreviewBase64String = (state: State) => state.previewBase64String;
export const selectDocumentName = (state: State) => state.documentName;
export const selectDocumentId = (state: State) => state.documentId;
export const selectSuperDiaryDocumentId = (state: State) => state.superDiaryDocumentId;
export const selectNextNavigationTransactionType = (state: State) => state.nextNavigationTransactionType;
export const selectNextNavigationQuery = (state: State) => state.nextNavigationQuery;
export const selectPrecedentId = (state: State) => state.precedentId;
export const selectIsCustomPrecedent = (state: State) => state.isCustomPrecedent;
export const selectTransactionType = (state: State) => state.transactionType;
export const selectTransactionList = (state: State) => state.transactionList;
export const selectPreviewLoading = (state: State) => state.loading;
export const selectPreviewDocument = (state: State) => state.document;
