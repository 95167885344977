import {
  hasProp,
  isEmptyArray,
  isObjEqual,
  omitObjValue,
} from '../../../../server/modules/shared/functions/common-util.functions';
import { CorrespondenceType, IDoc, IDocumentIconInfo, IDocumentIconConfig } from '../models';

import {
  WordDocumentExtension,
  ImageExtension,
  AudioExtension,
  EmailExtension,
  ExcelExtension,
  PdfExtension,
} from './document-extension.helper';

export class DocumentIconHelper {
  private registeredIcons: { [key: string]: IDocumentIconInfo };
  static get DEFAULT_TYPE(): string {
    return 'default';
  }
  static INFOTRACK_TYPE(status: string): string {
    return status === 'Complete' ? `Infotrack_Complete` : `Infotrack_Others`;
  }
  static get COMMENT_TYPE(): string {
    return 'comment';
  }
  static EMAIL_TYPE(transferMode: string): string {
    return `msg_${transferMode.toLowerCase()}`;
  }

  static get DEFAULT_ICON_CONFIG(): IDocumentIconConfig[] {
    return [
      {
        iconClass: 'doctype-default',
        tooltip: 'Document',
        types: [DocumentIconHelper.DEFAULT_TYPE],
      },
      {
        iconClass: 'doctype-doc',
        tooltip: WordDocumentExtension.title,
        types: WordDocumentExtension.types,
      },
      {
        iconClass: 'doctype-image',
        tooltip: ImageExtension.title,
        types: ImageExtension.types,
      },
      {
        iconClass: 'doctype-audio',
        tooltip: AudioExtension.title,
        types: AudioExtension.types,
      },
      {
        iconClass: 'doctype-filler',
        tooltip: 'Config',
        types: ['config'],
      },
      {
        iconClass: 'doctype-email-default',
        tooltip: EmailExtension.title,
        types: EmailExtension.types,
      },
      {
        iconClass: 'doctype-email-closed',
        tooltip: 'Email (Closed)',
        types: [DocumentIconHelper.EMAIL_TYPE('Closed')],
      },
      {
        iconClass: 'doctype-email-create',
        tooltip: 'Email (Draft)',
        types: ['oft' /* That's what it is in LEAP Cloud, at least */],
      },
      {
        iconClass: 'doctype-email-received',
        tooltip: 'Email (Received)',
        types: [DocumentIconHelper.EMAIL_TYPE('Received')],
      },
      {
        iconClass: 'doctype-email-sent',
        tooltip: 'Email (Sent)',
        types: [DocumentIconHelper.EMAIL_TYPE('Sent')],
      },
      {
        iconClass: 'doctype-excel',
        tooltip: ExcelExtension.title,
        types: ExcelExtension.types,
      },
      {
        iconClass: 'doctype-pdf',
        tooltip: PdfExtension.title,
        types: PdfExtension.types,
      },
      {
        iconClass: 'doctype-code',
        tooltip: 'HTML',
        types: ['html'],
      },
      {
        iconClass: 'doctype-comment',
        tooltip: 'Comment',
        types: [DocumentIconHelper.COMMENT_TYPE],
      },
      {
        iconClass: 'doctype-infotrack-new',
        tooltip: 'Infotrack Search',
        types: [DocumentIconHelper.INFOTRACK_TYPE('')],
      },
      {
        iconClass: 'doctype-infotrack',
        tooltip: 'Infotrack Search',
        types: [DocumentIconHelper.INFOTRACK_TYPE('Complete')],
      },
    ];
  }

  constructor() {
    this.registeredIcons = {};

    DocumentIconHelper.DEFAULT_ICON_CONFIG.forEach((config: IDocumentIconConfig): void => {
      this.registerIconConfig(config);
    });
  }

  registerIconConfig(config: IDocumentIconConfig): void {
    config.types = config.types?.map((s) => s.toLowerCase()) || [];

    const info: IDocumentIconInfo = omitObjValue(config, ['types']) as IDocumentIconInfo;
    const conflictingTypeMappings: string[] =
      config.types?.filter((type: string) => hasProp(this.registeredIcons, type) && !isObjEqual(this.registeredIcons[type], info)) || [];

    if (!isEmptyArray(conflictingTypeMappings)) {
      throw new Error(
        `Conflicting document icon mappings already exist for type(s): ${conflictingTypeMappings.join(', ')}.`
      );
    }

    config.types.forEach((type: string) => {
      this.registeredIcons[type] = info;
    });
  }

  getIconInfo(type: string): IDocumentIconInfo {
    let iconInfo: IDocumentIconInfo = this.registeredIcons[DocumentIconHelper.DEFAULT_TYPE];
    if (type && this.registeredIcons[type.toLowerCase()] !== undefined) {
      iconInfo = this.registeredIcons[type.toLowerCase()];
    }
    return iconInfo;
  }

  getDocumentIconInfo(document: IDoc): IDocumentIconInfo {
    let type: string = DocumentIconHelper.DEFAULT_TYPE;
    if (document) {
      if (document.orderId) {
        type = DocumentIconHelper.INFOTRACK_TYPE(document.status);
      } else if (document.type === CorrespondenceType.Comment) {
        type = DocumentIconHelper.COMMENT_TYPE;
      } else if (document.transferMode !== undefined) {
        type = DocumentIconHelper.EMAIL_TYPE(document.transferMode);
      } else {
        type = document.ext;
      }
    }
    const info = this.getIconInfo(type);
    return info;
  }
}
