export interface NewOfficeInvoiceRequest {
  MatterGUID: string;
  InvSemaphoreGUID: string;
  LABillingStage: string;
  MatterBillingMode: string;
  InvLAStrObjBilling: string;
  InvLAExceptional: number;
  InvLACRMPSFFExceeded: number;

  // Advanced Options are optional
  ExTimeAndFees?: boolean;
  ExCostRecoveries?: boolean;
  ExDisbursementJournals?: boolean;
  ExOfficePayments?: boolean;
  ExAnticipatedPayments?: boolean;
  BillAtDate?: string; // Format should be "yyyy-mm-dd" - no timezone info
  FixedFee?: boolean;
  FixedFeeAmount?: number;
}

export interface IInvoiceError {
  source: EInvoiceError;
  error: any;
}

export enum EInvoiceError {
  Init = 0,
  Layout = 1,
  Save = 2,
}
