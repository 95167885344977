import { AutomationActionType } from '@app/shared/models';
import { environment } from '@env/environment';

export interface CustomizedPrecedentProps {
  isChangeRequired: boolean;
  action: AutomationActionType;
  precedentId: string;
}

export enum UserAction {
  CreatePrecedent = 'create',
  ModifyPrecedent = 'modify',
}

export enum PrecedentType {
  Standard = 'Standard',
  Custom = 'Custom',
  Customized = 'Customized',
}

export const iconSortOrder: { [key: string]: number } = {
  'doctype-blank-document': 1,
  'doctype-fax': 2,
  'doctype-blank-fax': 3,
  'doctype-letter': 4,
  'doctype-blank-letter': 5,
  'doctype-excel': 6,
  'doctype-memo': 7,
  'doctype-tax-invoice': 8,
  'doctype-file-note': 9,
};

export interface IPrecedent {
  id: string; // new api uses precedentId
  baseId?: string;
  customPrecedentId: string;
  isCustomisedPrecedent: boolean;
  isFirmPrecedent: boolean;
  name: string; // same as new api
  code: string; // same
  parentId: string; // same
  matterTypeId: string; // same
  shortcutId: string; // same
  type: string; // same
  formNumber: string; // same
  sortOrder: number; // same
  children?: IPrecedent[];
  childCount?: number;
  documentType: string; // new api uses type
  url: string; // same
  transferType: string;
  hide: string; // in boolean
  imageName: string;
  container: {
    precedentId: string;
    versionId: string;
  };
  documentVersions: IPrecedentVersionInfo;
  tableFieldsExist: boolean;
  autoFieldsExist: boolean;
  openInDesktop: boolean;
  originInDesktop?: boolean; // used for shortcut that has origin's openInDesktop === true
  deleteCode?: number; // same
  path?: string;

  uiPath?: string; // represent actual UI precedent tree (to tackle shortcut folder)
  class: string;

  precedentId?: string; // value from api precedents/{id}
  precedentType?: string; // value Customized or Custom or Standard from api precedents/{id}
  isDraft?: boolean; // any new local creation yet submit will have this value === true
}

export interface IPrecedentWithAncestors extends IPrecedent {
  ancestors?: IPrecedent[];
}

export interface IPrecedentVersionInfo {
  latestDocumentVersionId: string;
  documentVersion: IPrecedentVersion[];
}

export interface IContentGatewayPrecedentVersionInfo {
  documentVersions: IPrecedentVersion[];
  precedentId: string;
  name: string;
  parentId: string;
  matterTypeId: string;
  shortcutId: string;
  class: string;
  code: string;
  type: string;
  formNumber: string;
  sortOrder: string;
  url: string;
  notification: string;
  precedentType: string;
  basePrecedentId: string;
  hide: boolean;
  openInDesktop: boolean;
  deleteCode: number;
  latestDocVersion: string;
}

export interface IPrecedentVersion {
  id: string;
  defaultTable: string;
  templateId: string;
  type: string;
  date: string;
  reason: string;
  fields: IPrecedentField[];
  deleteCode: number;
}

export interface IPrecedentField {
  fieldId: string;
  tableId: string;
  name: string;
  order: string;
  value: string;
}

export interface ICustomPrecedent {
  id: string;
  name: string;
  ext: string;
  basePrecedentId: string;
  isCustomized: boolean;
}

export interface ICustomPrecedentWithIcons extends ICustomPrecedent {
  icon: string;
  sortOrder: number;
}

export interface INewFolder {
  name: string;
  id: string;
  draftPrecedents?: IPrecedent[];
}

export interface INewFolderRequest extends INewFolder {
  matterTypeId?: string;
  parentPrecedentId?: string;
}

export class PrecedentHelper {
  static createNewFolderRequest(folderId: string, path: string, shortcutId = null): Partial<IPrecedent> {
    return {
      id: folderId,
      name: '',
      hide: '0',
      formNumber: '',
      childCount: 0,
      deleteCode: 0,
      shortcutId: shortcutId || null,
      openInDesktop: false,
      isFirmPrecedent: false,
      isCustomisedPrecedent: false,
      type: 'Folder',
      path,
      isDraft: true
    };
  }

  static updateFolderChildList(requestPayload, selectedFolder: IPrecedent, precedents: IPrecedent[]) {
    let path: string[] = [];

    if (selectedFolder) {
      if (selectedFolder.path) {
        path = selectedFolder.path.split('/').filter((p) => !!p);
      }

      if (!path.includes(selectedFolder.id)) {
        path = path.concat(selectedFolder.id);
      }
    }

    const children = this.getChildrenFromPath(precedents, path);
    return [...children, requestPayload];
  }

  private static getChildrenFromPath(list: IPrecedent[], path: string[]) {
    const pathId = path.shift();
    const folder = list.find((p) => p.id === pathId);
    const currentChildren = !!folder?.children ? folder.children : [];
    return path.length <= 0 ? [...currentChildren] : [...this.getChildrenFromPath(currentChildren, path)];
  }

  static getNewFolderPath(selectedFolder) {
    return !!selectedFolder ? `${selectedFolder.path || '/'}${selectedFolder.id}` : '/';
  }
}

export const getPrecedentPDFUrl = (precedentId, isCustomPrecedent?: boolean): string => isCustomPrecedent
    ? `${environment.config.endpoint.docs}/api/v1/customprecedents/${precedentId}/preview`
    : `${environment.config.endpoint.docs}/api/v1/precedents/${precedentId}/preview`;

export enum EPrecedentListModelEvent {
  Cancel,
  Confirm,
}

export interface IPrecedentListModelSelection {
  action: EPrecedentListModelEvent;
  precedentEntries: IPrecedent[];
}

export const getCustomizedPrecedentProps = (
  userAction: UserAction,
  enrichedPrecedent: IPrecedent
): CustomizedPrecedentProps => {
  const isCustomized = enrichedPrecedent?.precedentType === PrecedentType.Customized;
  const isChangeRequired = isCustomized && userAction === UserAction.ModifyPrecedent;

  const action = isChangeRequired ? AutomationActionType.EditCustomPrecedent : null;

  // if enrichedPrecedent has shortcutid, the precedentId will have value;
  const precedentId = enrichedPrecedent?.precedentId || enrichedPrecedent?.id;

  return {
    isChangeRequired,
    action,
    precedentId,
  };
};
