import { ITimeFeeSummary, ITimeFeeSummaryVM } from '@app/features/+time-fee-ledger/models';
import { AddinItem, ViewLeapApp } from '@app/features/matter-addin/models';
import { Action } from '@ngrx/store';

export const STORE_CHECKED_TIME_FEE_ENTRIES = '[Time Fee Page] Store checked/unchecked time/fee entries';

export const INIT_TASK_CODE_MODAL = '[Time Fee Page] Init task code modal';
export const INIT_TASK_CODE_MODAL_SUCCESS = '[Time Fee Page] Init task code modal success';
export const INIT_TASK_CODE_MODAL_FAILURE = '[Time Fee Page] Init task code modal failure';

export const CHECK_SET_DELETED = '[Time Fee Page] Check Set Deleted';

export const OPEN_ENTRY_MODAL = '[Time Fee Page] Open entry modal';
export const HANDLE_LEAP_ADD_IN_ITEM_CLICK = '[Time Fee Page] Handle leap add in item click';

export const SHOW_SUCCESS_TOAST_MSG = '[Time Fee Page] Show success toast message';
export const SHOW_FAILURE_TOAST_MSG = '[Time Fee Page] Show failure toast message';

export class StoreCheckedTimeFeeEntries implements Action {
    readonly type = STORE_CHECKED_TIME_FEE_ENTRIES;
    constructor(public payload: ITimeFeeSummary[]) {}
}

export class InitTaskCodeModal implements Action {
    readonly type = INIT_TASK_CODE_MODAL;
    constructor(public payload: any) {}
}

export class InitTaskCodeModalSuccess implements Action {
    readonly type = INIT_TASK_CODE_MODAL_SUCCESS;
    constructor(public payload: any){}
}

export class InitTaskCodeModalFailure implements Action {
    readonly type = INIT_TASK_CODE_MODAL_FAILURE;
    constructor(public payload: string){}
}

export class CheckSetDeleted implements Action {
    readonly type = CHECK_SET_DELETED;
    constructor(public payload: boolean){}
}

export class OpenEntryModal implements Action {
    readonly type = OPEN_ENTRY_MODAL;
    constructor(public payload: {
        entryType: 'fee' | 'time';
        summary?: ITimeFeeSummaryVM;
    }) {}
}

export class HandleLeapAddInItemClick implements Action {
    readonly type = HANDLE_LEAP_ADD_IN_ITEM_CLICK;
    constructor(public payload: {
        leapApp: ViewLeapApp;
        contextItem: AddinItem;
    }) {}
}

export class ShowSuccessToastMsg implements Action {
    readonly type = SHOW_SUCCESS_TOAST_MSG;
    readonly success = true;
    constructor(public payload: string) {}
}

export class ShowFailureToastMsg implements Action {
    readonly type = SHOW_FAILURE_TOAST_MSG;
    readonly success = false;
    constructor(public payload: string) {}
}

export type TimeFeeActions =
    | StoreCheckedTimeFeeEntries
    | InitTaskCodeModal
    | InitTaskCodeModalSuccess
    | InitTaskCodeModalFailure
    | CheckSetDeleted
    | OpenEntryModal
    | HandleLeapAddInItemClick
    | ShowSuccessToastMsg
    | ShowFailureToastMsg;
