import { Component, Input, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { AppApiService } from '@app/core/api';
import { selectCurrentMatterId } from '@app/core/store/selectors';
import { IMatterCard } from '@app/shared/models';
import { DialogService } from '@app/shared/services/dialog/dialog.service';
import * as actions from '@app/features/+matter-details/store/actions';
import * as appActions from '@app/core/store/actions';
import { Subject } from 'rxjs';
import { LayerOutletName } from '@app/core/models';

@Component({
  selector: 'sc-sidebar-contact-details',
  templateUrl: './sidebar-contact-details.component.html',
})
export class SidebarContactDetailsComponent implements OnDestroy {
  @Input()
  title: string;
  @Input()
  subtitle: string;
  @Input()
  phone: string;
  @Input()
  email: string;
  @Input()
  iconClass: string;
  @Input()
  analyticsCategory: string;
  @Input()
  matterCard: IMatterCard;

  private unsub = new Subject<void>();

  constructor(private _appApiSvc: AppApiService, private _dialogSvc: DialogService, private _store: Store<any>) {}

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }

  openCardModal(): void {
    if (!this.matterCard) {
      return;
    }
    this._store.dispatch(new actions.SetCurrentDetailEntry({ detailEntry: this.matterCard }));
    this._appApiSvc.navigate({
      path: [{ outlets: { [LayerOutletName.Global]: ['card'] } }],
      query: { matterCard: true },
    });
  }

  createEmail(event: Event) {
    event.stopPropagation();
    this._store
      .pipe(
        select(selectCurrentMatterId),
        take(1),
        takeUntil(this.unsub) // important: this operator must be last
      )
      .subscribe((matterId) =>
        this._store.dispatch(
          new appActions.InitialiseEmail({ data: { matterNumber: matterId, toAddress: this.email } })
        )
      );
  }
}
