<sc-modal-header (modalDismiss)="dismiss()"> {{ brandName }} Helper for Microsoft Office </sc-modal-header>
<sc-modal-body class="text-center">
  <ng-container *ngIf="!isOther && !isSafari">
    <div class="rounded my-3 overflow-hidden" [ngClass]="isActive ? 'active' : ''">
      <ng-container *ngIf="isTitleX">
        <img
          src="./assets/images/titlex/doc-launcher/doc-automation-launch-chrome.png"
          class="img-fluid x-image-zoom"
          *ngIf="isChrome"
        />
        <img
          src="./assets/images/titlex/doc-launcher/doc-automation-launch-firefox.png"
          class="img-fluid x-image-zoom"
          *ngIf="isFirefox"
        />
        <img
          src="./assets/images/titlex/doc-launcher/doc-automation-launch-edge.png"
          class="img-fluid x-image-zoom"
          *ngIf="isMicrosoftEdge"
        />
      </ng-container>
      <ng-container *ngIf="brandName === 'LawConnect'">
        <img
          src="./assets/images/lawconnect/doc-launcher/doc-automation-launch-chrome.png"
          class="img-fluid x-image-zoom"
          *ngIf="isChrome"
        />
        <img
          src="./assets/images/lawconnect/doc-launcher/doc-automation-launch-firefox.png"
          class="img-fluid x-image-zoom"
          *ngIf="isFirefox"
        />
        <img
          src="./assets/images/lawconnect/doc-launcher/doc-automation-launch-edge.png"
          class="img-fluid x-image-zoom"
          *ngIf="isMicrosoftEdge"
        />
      </ng-container>
      <ng-container *ngIf="brandName === 'LEAP'">
        <img
          src="./assets/images/leap/doc-launcher/doc-automation-launch-chrome.png"
          class="img-fluid x-image-zoom"
          *ngIf="isChrome"
        />
        <img
          src="./assets/images/leap/doc-launcher/doc-automation-launch-firefox.png"
          class="img-fluid x-image-zoom"
          *ngIf="isFirefox"
        />
        <img
          src="./assets/images/leap/doc-launcher/doc-automation-launch-edge.png"
          class="img-fluid x-image-zoom"
          *ngIf="isMicrosoftEdge"
        />
      </ng-container>
    </div>
  </ng-container>
  <h5 class="mt-2 my-3">
    Please open the application above. If you are unable to see the prompt message, download &amp; open the application
    manually.
  </h5>
  <p><sc-btn [btnType]="'primary'" (btnClicked)="download()">Download</sc-btn></p>
</sc-modal-body>
