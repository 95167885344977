import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as feeTimerRecordActions from '../actions/fee-timer-record.actions';
import * as activityCodeActions from '../actions/fee-timer-activity-code.actions';
import { select, Store } from '@ngrx/store';
import { selectCurrentMatterId } from '@app/core/store';
import { selectFeeTimerActivityCodesFilteredState, selectFeeTimerActivityCodesState, selectFeeTimerStoreAsRecordingBase } from '../selectors';
import { FeeTimerRecordStorageService } from '../../services/fee-timer-record/fee-timer-record-storage.service';

@Injectable()
export class FeeTimerRecordEffect {
  removeFromDbWhenStart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<feeTimerRecordActions.FeeTimerTimeElapsedStart>(feeTimerRecordActions.FEE_TIMER_TIME_ELAPSED_START),
        withLatestFrom(this._store.pipe(select(selectCurrentMatterId))),
        tap(([action, matterId]) => {
          this.feeTimerRecordStorageSvc.remove(matterId || action.payload?.matterId);
        }),
        catchError((error) => of(console.warn(error)))
      ),
    { dispatch: false }
  );

  removeFromDbWhenDiscard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<feeTimerRecordActions.FeeTimerTimeElapsedDiscard>(feeTimerRecordActions.FEE_TIMER_TIME_ELAPSED_DISCARD),
        withLatestFrom(this._store.pipe(select(selectCurrentMatterId))),
        tap(([action, matterId]) => {
          // If the action is dispatched from opening new window, we don't want to clear timer record from local storage
          if (!action.payload?.newWin) {
            this.feeTimerRecordStorageSvc.remove(matterId || action.payload?.matterId);
          }
        }),
        catchError((error) => of(console.warn(error)))
      ),
    { dispatch: false }
  );

  saveToDbWhenStop$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<feeTimerRecordActions.FeeTimerTimeElapsedStop>(feeTimerRecordActions.FEE_TIMER_TIME_ELAPSED_STOP),
        withLatestFrom(
          this._store.pipe(select(selectFeeTimerStoreAsRecordingBase)),
          this._store.pipe(select(selectFeeTimerActivityCodesState))
        ),
        tap(([action, recordingState, taskCodes]) => {
          const timerRecordingEntry = {
            startTime: recordingState.duration.startTime,
            endTime: recordingState.duration.endTime,
            durationInSeconds: recordingState.durationInSeconds,
            matterId: recordingState.matter.matterId || action.payload?.matterId,
            feeGUID: recordingState.runningTimerFeeGUID,
            isNewEntry: recordingState.isNewEntry || false,
            pendingTimeEntry: recordingState.isNewEntry ? recordingState.pendingTimeEntry : undefined,
            selectedActivityCode: recordingState.isNewEntry && recordingState.pendingTimeEntry
              ? taskCodes.find((taskCode) => recordingState.pendingTimeEntry.TaskCodeGUID === taskCode.taskCodeGUID)?.nameFileAs || ''
              : ''
          };
          this.feeTimerRecordStorageSvc.addFeeTimerRecord(timerRecordingEntry);
        }),
        catchError((error) => of(console.warn(error)))
      ),
    { dispatch: false }
  );

  updateActivityCodeWhenSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType<activityCodeActions.FeeTimerActivityCodeSelected>(activityCodeActions.FEE_TIMER_ACTIVITY_CODE_SELECTED),
      switchMap((action) => [new feeTimerRecordActions.FeeTimerActivityCodeUpdate(action.payload.nameFileAs)])
    )
  );

  feeTimerPendingTimeEntryUpdate$ = createEffect(() => this.actions$.pipe(
    ofType<feeTimerRecordActions.FeeTimerPendingTimeEntryUpdate>(feeTimerRecordActions.FEE_TIMER_PENDING_TIME_ENTRY_UPDATE),
    withLatestFrom(
      this._store.pipe(select(selectFeeTimerActivityCodesFilteredState)),
      (action, activityCodes) => ({ taskCodeGUID: action.payload.TaskCodeGUID, activityCodes})
    ),
    switchMap(({taskCodeGUID, activityCodes}) => {
      const selected = activityCodes.find((code) => code.taskCodeGUID === taskCodeGUID);
      if (!selected) {
        return [];
      }

      return [new feeTimerRecordActions.FeeTimerActivityCodeUpdate(selected.nameFileAs)];
    })
  ));

  constructor(
    private actions$: Actions,
    private _store: Store<any>,
    private feeTimerRecordStorageSvc: FeeTimerRecordStorageService
  ) {}
}
