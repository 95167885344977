// core
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { Observable, from, switchMap } from 'rxjs';

import { AuthService, LogService } from '@app/core/services';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

// TODO: should not need this, always use skip header
const hasAuthorization = (req: HttpRequest<any>) => req.headers.keys().includes('Authorization');

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _authSvc: AuthService, private _log: LogService) {
    this._log.init('auth-interceptor');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this._authSvc.getRefreshedToken()).pipe(
      switchMap((token) => {
        if (req.headers.has(InterceptorSkipHeader)) {
          this._log.info('Skipping interceptor');
          const headers = req.headers.delete(InterceptorSkipHeader);
    
          return next.handle(req.clone({ headers }));
        } else {
          this._log.info(`Bearer ${token}`);
          let authReq = req.clone();
    
          if (token && !hasAuthorization(req)) {
            // Add token
            const authHeader = `Bearer ${token}`;
            authReq = req.clone({ setHeaders: { Authorization: authHeader } });
            this._log.info(`New headers: ${authReq.headers.keys()}`);
          }
    
          return next.handle(authReq);
        }
      })
    )

    
  }
}
