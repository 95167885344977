import { ISortInfo } from '@app/shared/models';
import { IPhoneNumber, IWebAddress } from '@app/core/models';
import { ECardType } from './constants';

export type AddressType = 'Street' | 'POBox' | 'DX';

export enum EAddressType {
  STREET = 'Street',
  PO_BOX = 'POBox',
  DX = 'DX',
}

export type FilterPredicate<T> = ((item: T) => boolean) | string | object;

export interface CardListMetaInfo {
  id: string;
  lastRowVer?: number;
  total?: number;
  sortInfo?: ISortInfo;
  cardSearchText?: string;
  cardGroupBy?: ICardGroupBy;
  cardFilterBy?: string;
  showDeleted?: boolean;
  selectedCardId: string;
}

export interface ICardListResponseSchema {
  data: ICardListEntry[];
  lastRowVer: number;
  total: number;
}

export interface ICardListEntry {
  cardId: string;
  fullName: string;
  shortName: string;
  type: string;
  isSupplier: boolean;
  persons: string[];
  webAddressList: IWebAddress[];
  phoneNumberList: IPhoneNumber[];
  addressList: IAddress[];
  defaultAddress?: string;
  roles: ICardRoles;

  email: IWebAddress;
  phone: IPhoneNumber;
  address: IAddress;
  suburb: string;

  deleteCode?: number;
}

export interface IAddress {
  __className: string;
  addressType: AddressType;
  type: string;
  propertyBuildingName: string;
  levelUnitShop: string;
  number: string; // eslint-disable-line
  street: string;
  streetDirection: string;
  suburb: string;
  county: string;
  state: string;
  postcode: string;
  country: string;
  specialInstructions: string;
  // exchange: string;
  dateFrom?: Date;
  dateTo?: Date;
  // formatted: string;
  // full: string;
}

export interface ICardRoles {
  tables: string[];
  subsTables: string[];
}

export interface ICardGroupBy {
  id: number;
  value: string;
}

export interface ICardFilter {
  id: string;
  type: string;
  name: string;
  value: string;
  predicate: FilterPredicate<any>;
  class?: string;
}

export enum ECardFilterType {
  Standard = 1,
  CardList = 2,
  Email = 3,
  OfficePayment = 4,
  Staff = 5,
  AllStaff = 6, // staff list, included 'All' option
  MSTeams = 7,
  CardGuidArray = 8, // Filter cards out of a specific array (card guid array).
}

export interface IAlternativeAddressSettings {
  defaultAddress: AddressType;
  startDate: any;
  endDate: any;
}

export interface IOriginalAddressSettings {
  defaultAddress: AddressType;
}

export interface IPersonEntry {
  id: string;
  name: string;
  description?: string;
  phone: string;
  email: string;
}

export interface IRowClickOutput {
  cardId: string;
  type: ECardType;
}
