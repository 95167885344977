import { OverlayConfig } from '@angular/cdk/overlay';

export type ErrorHandlerHook = (error: any) => void;

export interface ErrorHandlerConfig {
  overlayConfig?: OverlayConfig;
  errorHandlerHooks?: Array<ErrorHandlerHook>;
}

export interface ErrorHandlerCompResponse {
  isActionClicked: boolean;
}

export interface ErrorHandlerCompData {
  type: 'error' | 'warning' | 'info' | 'confirm';
  message: string;
  title?: string;
  showCancel?: boolean;
  actionBtnText?: string;
  cancelBtnText?: string;
  onCloseFun?: (res: ErrorHandlerCompResponse) => any;
}

export interface ErrorHandlerLogger {
  warn: (e: any) => any;
  error: (e: any) => any;
}

export class SiriusError {
  readonly data: ErrorHandlerCompData;

  constructor(private config: ErrorHandlerCompData) {
    this.data = config;
  }
}
