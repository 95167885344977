import { InjectionToken } from '@angular/core';
import { DEFAULT_OVERLAY_CONFIG } from './constants/error-handler.constants';
import { ErrorHandlerConfig, ErrorHandlerLogger } from './interfaces/error-handler.interfaces';

export const DEFAULT_ERROR_HANDLER_CONFIG: ErrorHandlerConfig = {
  overlayConfig: DEFAULT_OVERLAY_CONFIG,
  errorHandlerHooks: [],
};

export const ERROR_HANDLER_CONFIG: InjectionToken<ErrorHandlerConfig> = new InjectionToken('sirius-eh-conf');
export const ERROR_HANDLER_LOGGER: InjectionToken<ErrorHandlerLogger> = new InjectionToken('sirius-eh-logger');
