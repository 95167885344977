import { Component, HostBinding } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';

@Component({
  selector: 'sc-recipient-type-cell',
  templateUrl: './recipient-type-cell.component.html',
})
export class RecipientTypeCellComponent implements AgRendererComponent {
  @HostBinding('class.x-cell-recipient-type') true;
  cardType: string;
  cardCount: number;
  iconType: string;
  constructor() {}
  agInit(params: any): void {
    this.cardType = params.getCardType(params.data);
    this.cardCount = params.getCardPersons(params.data);
    this.iconType = this.getIcon();
  }
  refresh(params: any): boolean {
    return true;
  }
  getIcon() {
    if (this.cardType === 'People' && this.cardCount === 1) {
      return (this.iconType = 'user');
    }
    if (this.cardType === 'People' && this.cardCount > 1) {
      return (this.iconType = 'users');
    }
    if (this.cardType === 'Company' || this.cardType === 'Business') {
      return (this.iconType = 'business');
    }
    if (this.cardType === 'Government') {
      return (this.iconType = 'balance');
    }
    if (this.cardType === 'Trust') {
      return (this.iconType = 'payment');
    }
    return (this.iconType = 'user-question');
  }
}
