import { Action } from '@ngrx/store';
import { ITimeFeeResponse, ITimeFeeSummaryVM } from '@app/features/+time-fee-ledger/models';

export enum TimeFeeEntryActionTypes {
  LOAD_ENTRY = '[Time Fee Entry] start to load entry',
  LOAD_ENTRY_SUCCEED = '[Time Fee Entry] load entry successfully',
  LOAD_ENTRY_FAIL = '[Time Fee Entry] unable to load entry',

  INIT_MOVE_ENTRY_MODAL = '[Time Fee Entry] Init Move Entry Modal',
  MOVE_ENTRY = '[Time Fee Entry] Move Entry',

  SET_BILLING_MODE = '[Time Fee Entry] Set Billing Mode',
  SET_DELETED = '[Time Fee Entry] Set Deleted Confirmed',
  SET_TASK_CODE = '[Time Fee Entry] Set Task Code',
  SET_TIME_ENTRY_SUMMARY = '[Time Fee Entry] Set Time Entry Summary',

  DESTROY_ENTRY = '[Time Fee Entry] destroy time fee entry',
}

export class LoadEntry implements Action {
  readonly type = TimeFeeEntryActionTypes.LOAD_ENTRY;

  constructor(public payload: any) {}
}

export class LoadEntrySucceed implements Action {
  readonly type = TimeFeeEntryActionTypes.LOAD_ENTRY_SUCCEED;

  constructor(public payload: ITimeFeeResponse) {}
}

export class LoadEntryFail implements Action {
  readonly type = TimeFeeEntryActionTypes.LOAD_ENTRY_FAIL;

  constructor(public payload: any) {}
}

export class DestroyEntry implements Action {
  readonly type = TimeFeeEntryActionTypes.DESTROY_ENTRY;

  constructor(public payload: any) {}
}

export class InitMoveEntryModal implements Action {
  readonly type = TimeFeeEntryActionTypes.INIT_MOVE_ENTRY_MODAL;
  constructor(public payload: any) {}
}

export class MoveEntry implements Action {
  readonly type = TimeFeeEntryActionTypes.MOVE_ENTRY;
  constructor(public payload: any) {}
}

export class SetBillingMode implements Action {
  readonly type = TimeFeeEntryActionTypes.SET_BILLING_MODE;
  constructor(public payload: number) {}
}

export class SetTaskCode implements Action {
  readonly type = TimeFeeEntryActionTypes.SET_TASK_CODE;
  constructor(public payload: any) {}
}

export class SetDeleted implements Action {
  readonly type = TimeFeeEntryActionTypes.SET_DELETED;
  constructor(public payload: boolean) {}
}

export class SetTimeEntrySummary implements Action {
  readonly type = TimeFeeEntryActionTypes.SET_TIME_ENTRY_SUMMARY;
  constructor(public payload: ITimeFeeSummaryVM) {}
}

export type TimeFeeEntryActions =
  | LoadEntry
  | LoadEntrySucceed
  | LoadEntryFail
  | DestroyEntry
  | InitMoveEntryModal
  | MoveEntry
  | SetBillingMode
  | SetTaskCode
  | SetDeleted
  | SetTimeEntrySummary;
