import { Attachment, IEmailAddress, ILaunchEmailOptions } from '@app/features/+email/models';
import { ICustomPrecedent, IPrecedent, UserAction } from '@app/features/+precedent/models';
import { DocType } from '@app/shared/models/correspondence.model';

export interface DesktopModeNotSupportedCallback {
  alternative: string; // text of the alternative that would show on the modal
  callback: (data?: any) => any; // action would trigger when callback link is clicked
  closeModalAfterCallback: boolean; // whether to close the modal when callback link is clicked
}

export interface AutomationContainer {
  blankWord: string;
  blankExcel: string;
}

export const AutomationContainerMap: { [key: string]: AutomationContainer } = {
  au: { blankWord: '1702', blankExcel: '34220001' },
  uk: { blankWord: '315000', blankExcel: '205000' },
  us: { blankWord: '8008', blankExcel: '9008' },
  ca: { blankWord: '8008', blankExcel: '9008' },
  ie: { blankWord: '8008', blankExcel: '9008' },
};

export enum AutomationActionType {
  New = 'new',
  Edit = 'edit',
  NewContainer = 'new_container',
  NewPrecedent = 'new_precedent',
  NewCustomPrecedent = 'new_custom_precedent',
  EditCustomPrecedent = 'edit_custom_precedent',
  PrintPdf = 'printPdf',
  NewMailItem = 'newMailItem',
  OpenMailItem = 'openMailItem',
  Close = 'close',
  Test = 'test',
  OpenTask = 'openTask',
  OpenAppointment = 'openAppointment',
  OpenAndOverwriteBody = 'open_and_overwrite_body'
}

export enum AutomationHostApplicationType {
  Outlook = 'outlook',
  Launcher = 'launcher',
  Word = 'word',
  Excel = 'excel',
}

/** Models for internal automation service usage **/
export interface IAutomationOptions {
  action: AutomationActionType;
  folderId: string;
  matterId: string;
  isOfficeOnline?: boolean;

  docInfo?: IAutomationDocOptions;
  emailInfo?: ILaunchEmailOptions;
  pdfInfo?: IAutomationPdfOptions;
  precedentInfo?: IAutomationPrecedentOptions;
  containerInfo?: IContainerOptions;
  taskId?: string; // Optional parameter to mark task complete incase of CreateDocument from existing task.
}

export interface IAutomationDocOptions {
  defaultTableId?: string;
  defaultTableOrder?: string;
  documentId?: string;
  ext?: string;
  isDesktopOnly?: boolean;
  isPending?: boolean;
}

export interface IContainerOptions {
  defaultTableId?: string;
  defaultTableOrder?: string;
  defaultPersonInstance?: string;
  isCustomPrecedent?: boolean;
  precedent?: IPrecedent;
  customPrecedent?: ICustomPrecedent;
  precedentId?: string;
}

export interface IAutomationCalendarOptions {
  action: AutomationActionType;
}

export interface IAutomationPrecedentOptions {
  isContainer?: boolean;
  isCustomPrecedent?: boolean;
  precedent?: IPrecedent;
  customPrecedent?: ICustomPrecedent;
  precedentId?: string;
}

export interface IAutomationPdfOptions {
  AddBookmarks: boolean;
  Combine: boolean;
  CombinedDocumentsName: string;
  Documents: string[];
  FolderId: string;
  MatterId: string;
  ReturnFileContent?: boolean;
  SaveToMatter: boolean;
  Secure: boolean;
}

export interface IOpenDocumentOptions {
  openDocumentFnc: (info: Partial<IOfficeMessageInfo>) => void;
  officeMessage: Partial<IOfficeMessageInfo>;
  options: IAutomationOptions;
}

/** End - Models for internal automation service usage **/

/** Models for external automation service usage **/
export interface IOfficeMessageInfo {
  action: AutomationActionType;
  defaultTableId: string;
  defaultTableOrder: string;
  desktopOnly: boolean;
  documentId: string;
  ext: string;
  folderId: string;
  id: string;
  isContainer: boolean;
  isCustomPrecedent: boolean;
  matterId: string;
  origin: string;
  precedentId: string;
  latestVersionId?: string;
  hostApplication?: AutomationHostApplicationType;
  email?: ILaunchEmailOptions;
  documentData?: any;
  taskId?: string;
  isPending?: boolean;
}

export interface ICoreLauncherRequestData {
  action: AutomationActionType;
  matterId: string;
  staffId: string;
  userId: string;
  firmId: string;
  hostApplication: AutomationHostApplicationType;
}

export interface ILauncherTicketInfo extends ICoreLauncherRequestData {
  defaultTableId: string;
  defaultTableOrder: string;
  documentId: string;
  isContainer: boolean;
  precedentId: string;
  folderId: string;
  latestVersionId: string;
  ext: string;
  email?: ILaunchEmailOptions;
  documentData?: any;
  taskId?: string;
}

export interface IDocumentLauncherRequest extends ICoreLauncherRequestData {
  documentData: IDocumentData;
  documentBody?: string;
  xmlData?: string;
  folderId?: string;
}

export interface IDocumentData {
  documentId: string;
  precedentId: string;
  defaultTableId: string;
  defaultTableOrder: string;
  isContainer: boolean;
  taskId?: string;
  parentPrecedentId?: string;
  isPending?: boolean;
  contentMode?: boolean; // flag for content-team to test content-control documents [test environment only]
  pendingPrecedentId: string;
}

export interface IPrintPdfLauncherRequest extends ICoreLauncherRequestData {
  printData: IPrintData;
  folderId: string;
}

export interface IPrintData {
  latestVersionId?: string;
  superDiaryDocumentId?: string;
  ext: string;
  documentName: string;
  url?: string;
}

export interface MailLauncherRequest extends ICoreLauncherRequestData {
  emailData: IEmailData;
}

export interface IEmailData {
  to?: IEmailAddress[];
  subject?: string;
  body?: string;
  attachments?: Attachment[];
  bcc?: IEmailAddress[];
  cc?: IEmailAddress[];
  importance?: number;
  documentId?: string;
  latestVersionId?: string;
  ext?: string;
}

// Desktop automation launcher
export interface ILauncherMessageInfo extends IOfficeMessageInfo {
  environment: string;
  firmId: string;
  leapAuthToken: string;
  messageId: string;
  region: string;
}

// Online wopi api
export interface IWopiMessageInfo extends IOfficeMessageInfo {
  emailData: IEmailMessageInfo;
  html5mode: boolean; // required by automation for office online to remove '#' from URLs
}

export interface IEmailMessageInfo {
  subject: string;
  to: IEmailAddress[];
  attachments: IAutomationPdfOptions;
}

/** End - Models for external automation service usage **/

export interface IOfficeAction {
  type: AutomationActionType;
  userAction?: UserAction;
  mode?: 'online' | 'offline' | undefined;
  context?: 'click' | 'context';
}

export interface ISelectItemOptions<T> {
  item: T;
  docType?: DocType;
  action?: IOfficeAction;
}

export interface IAutomationTicket {
  ticketId: string;
  docId: string;
}

export interface IAutomationWordBusy {
  ticketId: string;
  message: string;
}

export interface IAutomationWorkflowParams {
  navigateClear?: boolean;
  notSupportedCallback?: DesktopModeNotSupportedCallback;
}

export interface ICreateTaskDocumentTicketParams {
  folderId: string;
  ext: string;
  precedentId: string;
  taskId: string;
  contentMode?: boolean;
}

export interface ICreateNewDocumentTicketParams {
  precedentId: string;
  defaultTableId: string;
  defaultTableOrder: string;
  defaultPersonInstance: string;
  matterId?: string;
  contentMode?: boolean;
  pendingPrecedentId?: string;
}

export interface ICreateNewCustomPrecedentTicketParams {
  precedentId: string;
  parentPrecedentId: string;
  precedentMatterTypeId: string;
  defaultTableId: string;
  defaultTableOrder: string;
  contentMode?: boolean;
  pendingPrecedentId?: string;
}

export interface IDocumentTicketParams {
  documentId: string;
  ext: string;
  latestVersionId: string;
  documentName: string;
  url?: string;
  isPending?: boolean;
  precedentId?: string;
  contentMode?: boolean;
  pendingPrecedentId?: string;
}

export interface IOpenPrecedentTicketParams {
  action: AutomationActionType;
  precedentId: string;
  ext: string;
  contentMode?: boolean;
}

export interface IEditContainerTicketParams {
  containerId: string;
  contentMode?: boolean;
}

export interface ICreateNewContainerTicketParams {
  applicationType: AutomationHostApplicationType;
  blank: ICustomPrecedent;
  contentMode?: boolean;
}
/** End - Generic type for selecting word launchable item **/
