import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, delayWhen, filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, from, of, throwError } from 'rxjs';

import * as apiActions from '../actions';
import * as previewActions from '@app/features/+preview/store/actions';
import * as cardListModalActions from '@app/features/+card/store/actions/card-list-modal';
import * as appActions from '@app/core/store';
import { PrecedentService } from '@app/features/+precedent/services/precedent/precedent.service';
import { PrecedentListModalListenerService } from '@app/features/+precedent/services/precedent/precedent-list-modal-listener.service';
import { AccountingApiService } from '../../services';
import { EventBusService, FormStorageService, LogService, PlatformService } from '@app/core/services';
import { EmailReportsService } from '@app/features/accounting/services/email-reports/email-reports.service';
import { EPrintSource, PrintService } from '@app/shared/services/print/print.service';
import {
  AccountingPrintConstants,
  AccountingPrintReq,
  EAccountingTransaction,
  IAccountingFailureAction,
  UpdateOperation,
} from '@app/features/accounting/models';
import { ErrorHandlerCompResponse } from '@app/features/error-handler/interfaces/error-handler.interfaces';
import { NavigationEnd, Router } from '@angular/router';
import { MatterListStorageService } from '@app/features/+matter-list/services/storage/matter-list-storage.service';
import { IMatterListEntry } from '@app/features/+matter-list/models/matter-list.model';
import { AppApiService } from '@app/core/api/app-api.service';
import { CardListenerService } from '@app/features/+card/services/card-listener.service';
import { ECardFilterType } from '@app/features/+card/models/card-list';
import { ESiriusEvents } from '@app/core/models/feature.model';
import { ECardListModelEvent } from '@app/features/+card/models/constants';
import {
  EPrecedentListModelEvent,
  IPrecedent,
  IPrecedentListModelSelection,
} from '@app/features/+precedent/models/precedent.model';
import { CardListStorageService } from '@app/features/+card/services/card-list-storage.service';
import { selectCurrentStaff } from '@app/core/store';
import { EPreviewType } from '@app/features/+preview/models/preview-model';
import { safeThrowSiriusError } from '@app/features/error-handler/function/safe-throw-error';

@Injectable()
export class AccountingApiEffects {

  accountingTransactionNavigation$ = createEffect(() => this.actions$.pipe(
    ofType<apiActions.AccountingTransactionNavigation>(apiActions.ACCOUNTING_TRANSACTION_NAVIGATION),
    delayWhen((action) => {
      const { delayForPreviousNavigation } = action.payload;
      return delayForPreviousNavigation
        ? this._router.events.pipe(
          filter((event) => event instanceof NavigationEnd),
          take(1)
        )
        : of(true);
    }),
    switchMap((action) => {
      const { matterGuid: transactionMatterGuid } = action.payload;
      return !!transactionMatterGuid
        ? from(this._matterListStorageSvc.get(transactionMatterGuid)).pipe(
          map((matter) => {
            if (!!matter) {
              const matterNum = this.getMatterNum(matter, transactionMatterGuid);
              return {
                ...action.payload,
                matterNum,
              };
            }
            return {
              ...action.payload,
              matterNum: action.payload.matterGuid,
            };
          })
        )
        : of({ ...action.payload, matterNum: null });
    }),
    tap((data) => {
      const { matterNum, transactionType, query, printStateAfterNextAction } = data;
      switch (transactionType) {
        case EAccountingTransaction.ProtectedTrustFund:
          this._appApiSvc.navigate({
            path: [
              {
                outlets: {
                  primary: ['matters', matterNum, 'protected-trust-funds'],
                  popup: ['protected-fund-details'],
                },
              },
            ],
            query,
          });
          break;

        case EAccountingTransaction.TrustToOffice:
          const ttoPath = !!matterNum
            ? [
              {
                outlets: {
                  primary: ['matters', matterNum, 'trust-fund'],
                  popup: ['trust-to-office'],
                },
              },
            ]
            : [
              {
                outlets: {
                  popup: ['trust-to-office'],
                },
              },
            ];
          this._appApiSvc.navigate({
            path: ttoPath,
            query,
          });
          break;

        case EAccountingTransaction.ApplyCredits:
          if (!matterNum) {
            // todo: when we have the firm-level applyCredit page, we would navigate to that page
            console.log('Not Implemented Yet!');
            break;
          }
          this._appApiSvc.navigate({
            path: [
              {
                outlets: {
                  primary: ['matters', matterNum, 'credit-ledger'],
                  popup: ['apply-credit'],
                },
              },
            ],
            query,
            extras: {
              state: printStateAfterNextAction,
            },
          });
          break;

        default:
          break;
      }
    })
  ), { dispatch: false });


  save$ = createEffect(() => this.actions$.pipe(
    ofType<apiActions.AccountingSave>(apiActions.ACCOUNTING_SAVE),
    switchMap((data) => {
      const { model: originalModel, url, operation, successAction, failureAction, absolute } = data.payload;
      const model = {
        AppId: 'Leo',
        ...originalModel,
      };
      return this._apiSvc.save({ model, url, operation, absolute }).pipe(
        mergeMap((resp) => !!successAction
        ? [new apiActions.AccountingSaveSuccess(null), successAction(resp)]
        : [new apiActions.AccountingSaveSuccess(null)]),
        catchError((error) => {
          const { httpError, httpRequest } = error;
          return this.handleError({ httpError: httpError || error, httpRequest, successAction, failureAction });
        })
      );
    })
  ));


  fail$ = createEffect(() => this.actions$.pipe(
    ofType<apiActions.AccountingSaveFailure>(apiActions.ACCOUNTING_SAVE_FAILURE),
    switchMap((data) => {
      const { httpError, message } = data.payload;
      const dialogTitle =
        !!httpError.error && !!httpError.error.MessageHeader ? httpError.error.MessageHeader : 'Error';
      const dialogMessage = !!message
        ? message
        : !!httpError.error && (!!httpError.error.Message || !!httpError.error.message)
          ? httpError.error.Message || httpError.error.message
          : 'Unable to save record.';

      safeThrowSiriusError({
        type: 'error',
        message: dialogMessage,
        title: dialogTitle,
      });

      return EMPTY;
    })
  ), { dispatch: false });


  genericFailure$ = createEffect(() => this.actions$.pipe(
    ofType<apiActions.AccountingGenericFailure>(apiActions.ACCOUNTING_GENERIC_FAILURE),
    switchMap((data) => {
      const { httpError, message, defaultErrorMessage, closeModal } = data.payload;

      const title = httpError?.error?.MessageHeader || httpError?.error?.title || 'Error';
      const reason =
        this.getDefinedErrorMessage(httpError) ||
        message ||
        httpError?.error?.Message ||
        httpError?.error?.message ||
        defaultErrorMessage;
      if (!!closeModal) {
        this._appApiSvc.clearCurrentModal();
      }

      safeThrowSiriusError({
        type: 'error',
        message: reason,
        title,
      });

      return EMPTY;
    })
  ), { dispatch: false });

  // new window effects

  hydrateAccountingForm$ = createEffect(() => this.actions$.pipe(
    ofType<apiActions.HydrateAccountingFormStart>(apiActions.HYDRATE_ACCOUNTING_FORM_START),
    filter(() => this._ps.isBrowser),
    switchMap((action: apiActions.HydrateAccountingFormStart) => this._formStorageSvc
    .setFormUi(action.payload.formUiKey, action.payload.state)
    .pipe(mergeMap((x) => [new apiActions.HydrateAccountingFormSuccess(x)]))),
    catchError((error) => of(new apiActions.HydrateAccountingFormFailure(error)))
  ));


  deHydrateAccountingForm$ = createEffect(() => this.actions$.pipe(
    ofType<apiActions.DeHydrateAccountingFormStart>(apiActions.DEHYDRATE_ACCOUNTING_FORM_START),
    filter(() => this._ps.isBrowser),
    switchMap((action: apiActions.DeHydrateAccountingFormStart) => this._formStorageSvc
    .formUi(action.payload)
    .pipe(mergeMap((formUi) => [new apiActions.DeHydrateAccountingFormSuccess(formUi)]))),

    catchError((error) => of(new apiActions.DeHydrateAccountingFormFailure(error)))
  ));


  deHydrateCommentFormSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<apiActions.DeHydrateAccountingFormSuccess>(apiActions.DEHYDRATE_ACCOUNTING_FORM_SUCCESS),
    filter(() => this._ps.isBrowser),
    switchMap(() => this._formStorageSvc.clean())
  ), { dispatch: false });


  emailAccountingForm$ = createEffect(() => this.actions$.pipe(
    ofType<apiActions.AccountingReportEmail>(apiActions.EMAIL_ACCOUNTING_REPORT),
    switchMap(async (action: apiActions.AccountingReportEmail) => {
      const { reportName, reportOptions, reportSource, attachmentName, matterId } = action.payload;
      const reportUrl = await this._printService.buildPrintUrl(reportName, reportOptions, reportSource);
      this._emailReportSvc.emailReport(reportUrl, attachmentName, matterId);
      return of(null);
    })
  ), { dispatch: false });


  printAccountingForm$ = createEffect(() => this.actions$.pipe(
    ofType<apiActions.AccountingReportPrint>(apiActions.PRINT_ACCOUNTING_REPORT),
    tap((action: apiActions.AccountingReportPrint) => {
      const { reportName, reportOptions, fileName, reportSource } = action.payload;
      if (!!fileName) {
        this._printService.printPreview(reportName, reportOptions, fileName, reportSource);
      } else {
        this._printService.print(reportName, reportOptions, reportSource);
      }
    })
  ), { dispatch: false });


  accountingPrintStart$ = createEffect(() => this.actions$.pipe(
    ofType<apiActions.AccountingPrintStart>(apiActions.ACCOUNTING_PRINT_START),
    mergeMap((action) => {
      const { cardGuids } = action.payload;
      const isDebtorSelectionRequired = cardGuids.length > 1;
      if (isDebtorSelectionRequired) {
        return [
          new apiActions.DebtorSelectionBeforePrint({
            ...action.payload,
          }),
        ];
      }
      return [
        new apiActions.AccountingPrint({
          ...action.payload,
          selectedDebtorCardGuids: [],
        }),
      ];
    })
  ));


  debtorSelectionBeforePrint$ = createEffect(() => this.actions$.pipe(
    ofType<apiActions.DebtorSelectionBeforePrint>(apiActions.DEBTOR_SELECTION_BEFORE_PRINT),
    tap(({ payload }) => {
      const { cardGuids, closeTransactionPage } = payload;
      this._store.dispatch(new cardListModalActions.SetCardFilterType({ type: ECardFilterType.CardGuidArray }));
      this._store.dispatch(new cardListModalActions.SetSelectedCardFilterType({ type: 'CardGuidArray' }));
      this._store.dispatch(
        new cardListModalActions.SetCardGuidsForFiltering({ ids: cardGuids, includeAllCardOption: true })
      );
      const path = closeTransactionPage
        ? [{ outlets: { popup: null, selector: null, selectorDetail: ['card', 'card-list'] } }]
        : [{ outlets: { selectorDetail: ['card', 'card-list'] } }];
      this._appApiSvc.navigate({
        path,
        extras: { skipLocationChange: true, queryParamsHandling: 'preserve' },
      });
    }),
    delayWhen(() => this._eventBusSvc.listenWithNavigationDelay(ESiriusEvents.GetSelectedCardEntries).pipe(take(1))),
    withLatestFrom(this._eventBusSvc.listen(ESiriusEvents.GetSelectedCardEntries), (action, data) => ({
      ...data,
      ...action.payload,
    })),
    switchMap((data) => {
      const { action, cardEntries, cardGuids: debtorCardGuids } = data;
      const [selectedCard] = cardEntries;
      if (action === ECardListModelEvent.Confirm && !!selectedCard) {
        let selectedDebtorCardGuids: string[] = [];
        if (selectedCard.cardId?.includes('all')) {
          selectedDebtorCardGuids = [...debtorCardGuids];
        } else if (!!selectedCard.cardId) {
          selectedDebtorCardGuids = [selectedCard.cardId];
        }
        return [
          new apiActions.AccountingPrint({
            ...data,
            closeTransactionPage: false,
            selectedDebtorCardGuids,
          }),
        ];
      }
      return [];
    })
  ));


  accountingPrint$ = createEffect(() => this.actions$.pipe(
    ofType<apiActions.AccountingPrint>(apiActions.ACCOUNTING_PRINT),
    switchMap((action) => {
      const { type } = action.payload;
      const printDefault = AccountingPrintConstants[type];
      this._store.dispatch(new appActions.AppDisplayLoading(true)); // showing a loader to indicate that we are fetching precedents from backend
      this._log.info('Getting precedents via -- SchemaAPI');
      const precedentClass = printDefault.precedentClass;

      if (!precedentClass) {
        return of(new apiActions.AccountingPrintViaReporting({ ...action.payload }));
      }

      return this._precedentSvc.getPrecedentsByClass(precedentClass).pipe(
        mergeMap((precedents) => {
          if (!precedents) {
            return throwError(() => { message: `Unable to get ${precedentClass} precedents.` });
          }

          // we need to filter out all the deleted precedents (deleteCode: 1)
          const effectivePrecedents: IPrecedent[] = precedents.filter((p) => p.deleteCode !== 1);
          if (effectivePrecedents.length > 1) {
            this._log.info('navigate to precedent list modal page');
            this._store.dispatch(new appActions.AppDisplayLoading(false));
            this.navigateToPrecedentListPage({ precedents: effectivePrecedents, ...action.payload });
            return [];
          } else if (effectivePrecedents.length === 1) {
            this._log.info(`print ${precedentClass} via doc builder`);
            return [
              new apiActions.AccountingPrintViaDocBuilder({
                ...action.payload,
                precedent: effectivePrecedents[0],
              }),
            ];
          } else {
            this._log.info(`print ${precedentClass} via reporting api`);
            return [new apiActions.AccountingPrintViaReporting({ ...action.payload })];
          }
        }),
        catchError((err) => {
          this._log.debug(err);
          // fallback to use reporting api if get precedents fails
          return [new apiActions.AccountingPrintViaReporting({ ...action.payload })];
        })
      );
    })
  ));


  accountingPrintViaReporting$ = createEffect(() => this.actions$.pipe(
    ofType<apiActions.AccountingPrintViaReporting>(apiActions.ACCOUNTING_PRINT_VIA_REPORTING),
    switchMap((action) => {
      if (!!action.payload.selectedDebtorCardGuids && action.payload.selectedDebtorCardGuids.length > 0) {
        this._log.info('print via reporting api -- getting cards from indexed db');
        return this._cardListStorageSvc
          .getByIds(action.payload.selectedDebtorCardGuids)
          .then((selectedDebtorCards) => ({
            ...action.payload,
            selectedDebtorCards,
          }));
      } else {
        return of({
          ...action.payload,
          selectedDebtorCards: [],
        });
      }
    }),
    switchMap((data) => {
      const {
        selectedDebtorCardGuids,
        selectedDebtorCards,
        type,
        transactionList,
        closeTransactionPage,
        firmGuid,
        nextActionQuery,
        nextAction,
      } = data;
      const printDefault = AccountingPrintConstants[type];
      const selectedOneSplitCard = !!selectedDebtorCardGuids && selectedDebtorCardGuids.length === 1;
      const selectedAllSplitCards = !!selectedDebtorCardGuids && selectedDebtorCardGuids.length > 1;
      const realReportOptions = this._apiSvc.getReportOptions({
        accountingType: type,
        firmGuid,
        matterGuid: transactionList[0].matterGuid,
        transactionGuid: transactionList[0].transactionGuid,
        splitCardGuid: selectedOneSplitCard ? selectedDebtorCardGuids[0] : undefined,
        split: selectedAllSplitCards || selectedOneSplitCard ? 1 : undefined,
      });

      if (
        selectedAllSplitCards &&
        type !== EAccountingTransaction.OfficeStatementSummary &&
        type !== EAccountingTransaction.OfficeStatementDebtorsLedger
      ) {
        // open new tabs to preview split card accounting transaction except OfficeStatementSummary and officeStatementDebtorsLedger
        if (this._ps.isBrowser) {
          const previewUrl = `${window.location.origin}/preview/doc`;
          selectedDebtorCardGuids.forEach((guid) => {
            const url = `${previewUrl}
            ?previewType=${EPreviewType.ReportingAccounting}
            &accountingType=${type}
            &transactionNumber=${transactionList[0].transactionNumber}
            &transactionGuid=${transactionList[0].transactionGuid}
            &matterGuid=${transactionList[0].matterGuid}
            &splitCardGuid=${guid}
            &split=1`.replace(/[\r\n]/gm, '');
            window.open(url, '_blank');
          });
        }
        return [
          new previewActions.SetNextAccountingNavigationInform({
            type: nextAction as EAccountingTransaction,
            query: nextActionQuery,
          }),
          new appActions.AppDisplayLoading(false),
        ];
      }

      let fileName = this._translateSvc.instant(printDefault.fileName);
      if (!!transactionList && !!transactionList[0]) {
        if (!!transactionList[0].transactionNumber) {
          fileName += ` No. ${transactionList[0].transactionNumber}`;
        }
        if (!!transactionList[0].accountName) {
          fileName += ` - ${transactionList[0].accountName}`;
        }
      }

      if (selectedOneSplitCard && !!selectedDebtorCards[0] && selectedDebtorCards[0].fullName) {
        fileName += ` (${selectedDebtorCards[0].fullName})`;
      }

      this._store.dispatch(new appActions.AppDisplayLoading(true));
      this._log.info('print via reporting api -- getting previewUrl');
      return this._apiSvc
        .getPreviewUrlViaReportingApi({
          reportName: printDefault.reportName,
          fileName: `${fileName}.pdf`,
          options: realReportOptions,
          source: EPrintSource.Direct,
        })
        .pipe(
          mergeMap((previewUrl) => {
            this._log.info('print via reporting api -- getting previewUrl done');
            const path = closeTransactionPage
              ? [{ outlets: { popup: null, selector: ['preview'] } }]
              : [{ outlets: { selector: ['preview'] } }];
            this._appApiSvc.navigate({
              path,
              query: { accountingType: type },
              extras: { skipLocationChange: true },
            });
            const previewInform = {
              previewUrl,
              documentName: fileName,
              transactionType: type,
              transactionList: [
                {
                  matterGuid: transactionList[0].matterGuid,
                  transactionGuid: transactionList[0].transactionGuid,
                  transactionNumber: transactionList[0].transactionNumber,
                  transactionDocName: fileName,
                  parameters: {},
                },
              ],
            };
            return [
              new previewActions.SetPreviewAccountingInformUrl(previewInform),
              new previewActions.SetNextAccountingNavigationInform({
                type: nextAction as EAccountingTransaction,
                query: nextActionQuery,
              }),
              new appActions.AppDisplayLoading(false),
            ];
          }),
          catchError((err) => {
            this._log.error('Print Via Reporting Error: ', err);
            return [
              new appActions.AppDisplayLoading(false),
              new apiActions.AccountingTransactionNavigation({
                matterGuid: transactionList[0].matterGuid,
                transactionType: nextAction,
                transactionGuid: transactionList[0].transactionGuid,
                query: nextActionQuery,
              }),
            ];
          })
        );
    })
  ));


  accountingPrintViaDocBuilder$ = createEffect(() => this.actions$.pipe(
    ofType<apiActions.AccountingPrintViaDocBuilder>(apiActions.ACCOUNTING_PRINT_VIA_DOC_BUILDER),
    switchMap((action) => {
      if (!!action.payload.selectedDebtorCardGuids && action.payload.selectedDebtorCardGuids.length > 0) {
        return this._cardListStorageSvc
          .getByIds(action.payload.selectedDebtorCardGuids)
          .then((selectedDebtorCards) => {
            return {
              ...action.payload,
              selectedDebtorCards,
            };
          });
      } else {
        return of({
          ...action.payload,
          selectedDebtorCards: [],
        });
      }
    }),
    withLatestFrom(this._store.pipe(select(selectCurrentStaff)), (data, currentStaff) => {
      return {
        ...data,
        currentStaff,
      };
    }),
    switchMap((d) => {
      const {
        type,
        precedent,
        selectedDebtorCardGuids,
        selectedDebtorCards,
        matterId,
        currentStaff,
        closeTransactionPage,
        transactionList,
        nextAction,
        nextActionQuery,
      } = d;
      const printDefault = AccountingPrintConstants[type];
      // if a precedent's customPrecedentId, isCustomisedPrecedent or isFirmPrecedent is true, that precedent is a Custom Precedent
      const isCustomPrecedent =
        !!precedent.customPrecedentId || precedent.isCustomisedPrecedent || precedent.isFirmPrecedent;
      const precedentId = precedent.customPrecedentId || precedent.id;

      if (!!selectedDebtorCardGuids && selectedDebtorCardGuids.length > 1) {
        // open new tabs to preview split card accounting transaction
        const previewUrl = `${window.location.origin}/preview/doc`;
        selectedDebtorCardGuids.forEach((guid) => {
          const url = `${previewUrl}?previewType=${EPreviewType.DocBuilderAccounting}&accountingType=${type}&precedentId=${precedentId}&isCustomPrecedent=${isCustomPrecedent}&transactionNumber=${transactionList[0].transactionNumber}&transactionGuid=${transactionList[0].transactionGuid}&matterGuid=${transactionList[0].matterGuid}&splitCardGuid=${guid}&split=1`.replace(/[\r\n]/gm, '');
          this._log.debug('Opening new tab with URL:', url);
          window.open(url, '_blank');
        });
        return [
          new previewActions.SetNextAccountingNavigationInform({
            type: nextAction as EAccountingTransaction,
            query: nextActionQuery,
          }),
          new appActions.AppDisplayLoading(false),
        ];
      }

      const splitCardGuid =
        !!selectedDebtorCardGuids && selectedDebtorCardGuids.length === 1 ? selectedDebtorCardGuids[0] : '';
      let documentName = this._translateSvc.instant(printDefault.fileName);
      let parameters: any = { matterId };
      if (!!transactionList && !!transactionList[0] && !!transactionList[0].transactionNumber) {
        documentName += ` No. ${transactionList[0].transactionNumber}`;

        if (
          type === EAccountingTransaction.OfficeReceipt ||
          type === EAccountingTransaction.CreditReceipt
          ) {
            parameters = {
              ...parameters,
              officeReceiptId: transactionList[0].transactionGuid,
            };
        } else if (type === EAccountingTransaction.TrustReceipt) {
          parameters = {
            ...parameters,
            trustReceiptId: transactionList[0].transactionGuid,
          };
        }
      }

      if (!!splitCardGuid) {
        parameters = {
          ...parameters,
          splitCardId: splitCardGuid,
        };
        if (selectedDebtorCards && selectedDebtorCards[0] && selectedDebtorCards[0].fullName) {
          documentName += ` (${selectedDebtorCards[0].fullName})`;
        }
      }
      const staffInitials = !!currentStaff && !!currentStaff.initials ? currentStaff.initials : '';
      this._log.info('print via docBuilder api -- getting documentContent');
      this._store.dispatch(new appActions.AppDisplayLoading(true));
      return this._apiSvc
        .documentPrintViaDocBuilder({
          precedentId,
          isCustomPrecedent,
          documentName,
          matterId,
          saveToMatter: false,
          saveToSuperDiaryTempStorage: true,
          staffInitials: currentStaff?.initials,
          parameters,
          returnFileContent: true,
        })
        .pipe(
          mergeMap((data) => {
            this._log.debug('Doc Builder Response: ', data);
            if (data.documentContent) {
              const previewInform = {
                previewBase64String: data.documentContent,
                documentName,
                precedentId,
                isCustomPrecedent,
                superDiaryDocumentId: data.superDiaryDocumentId,
                transactionType: type,
                transactionList: [
                  {
                    matterGuid: transactionList[0].matterGuid,
                    transactionGuid: transactionList[0].transactionGuid,
                    transactionNumber: transactionList[0].transactionNumber,
                    transactionDocName: documentName,
                    parameters,
                  },
                ],
              };
              const path = closeTransactionPage ? [{ outlets: { popup: null, selector: ['preview'] } }] : [{ outlets: { selector: ['preview'] } }];
              this._appApiSvc.navigate({
                path,
                extras: {
                  queryParams: { accountingType: type },
                  skipLocationChange: true,
                },
              });
              return [
                new previewActions.SetPreviewAccountingInformBase64(previewInform),
                new previewActions.SetNextAccountingNavigationInform({
                  type: nextAction as EAccountingTransaction,
                  query: nextActionQuery,
                }),
                new appActions.AppDisplayLoading(false),
              ];
            } else {
              // fallback to use reporting api if there is no obj return from doc builder api
              return [
                new apiActions.AccountingPrintViaReporting({
                  ...d,
                  selectedDebtorCardGuids,
                }),
              ];
            }
          }),
          catchError((err) => {
            this._log.error('Print Via DocBuilder Error: ', err);
            // fallback to use reporting api if there is errors from doc builder api
            return [
              new apiActions.AccountingPrintViaReporting({
                ...d,
                selectedDebtorCardGuids,
              }),
            ];
          })
        );
    })
  ));

  private getDefinedErrorMessage = (httpError: HttpErrorResponse, defaultMessage?: string): string => {
    const httpErrorStatus = httpError.status;

    if (!!httpError.error) {
      if (httpErrorStatus === 500) {
        if (!!httpError.error.Message || !!httpError.error.message) {
          return httpError.error.Message || httpError.error.message;
        }

        if (httpError.error.Code === -101) {
          return httpError.statusText;
        }
      }

      if (httpErrorStatus === 403 && httpError.error.Code === -299 && !httpError.error.Message) {
        return 'Insufficient security permissions to create this transaction.';
      }
    }

    return defaultMessage;
  };

  private handleError(req: {
    httpError: HttpErrorResponse;
    httpRequest: HttpRequest<any>;
    successAction: any;
    failureAction: any;
  }): Action[] {
    const { httpError, httpRequest, successAction, failureAction } = req;
    const httpErrorStatus = httpError.status;

    if ([400, 409].includes(httpErrorStatus)) {
      return this.failureAction({ httpError, failureAction });
    }

    if (httpErrorStatus === 449) {
      return this.processWarning({ httpError, httpRequest, successAction, failureAction });
    }

    const message = this.getDefinedErrorMessage(httpError);
    return this.failureAction({ httpError, failureAction, message });
  }

  private processWarning(req: {
    httpError: HttpErrorResponse;
    httpRequest: HttpRequest<any>;
    successAction: any;
    failureAction: any;
  }): Action[] {
    const { httpError, httpRequest, successAction, failureAction } = req;
    const code = httpError.error.Code;

    // Code is defined by accouting api;
    // another update from Matthew, the 449 status Code equals to warning;
    // and Code needs to pass into WarningAcknowledgments prop if user decides to Continue.

    if (code !== null && code !== undefined) {
      this._log.warn(httpError);
      // call fn for the ErrorHandlerComponent dialog close
      const onCloseFun = (res: ErrorHandlerCompResponse) => {
        if (res.isActionClicked) {
          this._log.info('Accounting Warning Confirmed');
          const model = {
            ...httpRequest.body,
            WarningAcknowledgments: [...(httpRequest.body.WarningAcknowledgments || []), code],
          };
          const url = httpRequest.urlWithParams;
          const operation = httpRequest.method.toLowerCase() as UpdateOperation;
          this._store.dispatch(
            new apiActions.AccountingSave({
              absolute: true,
              model,
              url,
              operation,
              successAction,
              failureAction,
            })
          );
        } else {
          this._log.info('Accounting Warning Rejected');
          this._store.dispatch(new apiActions.AccountingWarningReject(null));
        }
      };

      safeThrowSiriusError({
        type: 'confirm',
        title: httpError.error.MessageHeader || httpError.error.title,
        message: httpError.error.Message || httpError.error.message,
        showCancel: true,
        actionBtnText: 'Confirm',
        cancelBtnText: 'Cancel',
        onCloseFun,
      });

      return [];
    }

    return this.failureAction({ httpError, failureAction, message: 'Unable to save record.' });
  }

  private failureAction(req: { httpError: HttpErrorResponse; failureAction?: IAccountingFailureAction; message?: string }): Action[] {
    const { httpError, failureAction, message } = req;
    const accountingApiFailureAction = new apiActions.AccountingSaveFailure({ httpError, message });
    return !!failureAction ? [accountingApiFailureAction, failureAction(httpError)] : [accountingApiFailureAction];
  }

  constructor(
    private actions$: Actions,
    private _apiSvc: AccountingApiService,
    private _formStorageSvc: FormStorageService,
    private _store: Store<any>,
    private _router: Router,
    private _appApiSvc: AppApiService,
    private _log: LogService,
    private _emailReportSvc: EmailReportsService,
    private _ps: PlatformService,
    private _eventBusSvc: EventBusService,
    private _translateSvc: TranslateService,
    private _precedentSvc: PrecedentService,
    private _precedentListModalListenerSvc: PrecedentListModalListenerService,
    private _matterListStorageSvc: MatterListStorageService,
    private _cardListStorageSvc: CardListStorageService,
    private _cardListenerSvc: CardListenerService,
    private _printService: PrintService
  ) {
    this._log.init('accounting-api-effects');
  }

  /**
   * Get a matter number (either fileNumber or matterId) for navigation
   * */
  private getMatterNum(matter: IMatterListEntry, transactionMatterGuid: string): string {
    // check whether the current matter id is the same as the matterId from accounting transaction
    // if it is the same, we use the current matter's fileNumber, else we use the matterId from transaction
    return matter.matterId === transactionMatterGuid
      ? matter.fileNumber
        ? encodeURIComponent(matter.fileNumber)
        : matter.matterId
      : transactionMatterGuid;
  }

  private navigateToPrecedentListPage(
    data: {
      precedents: IPrecedent[];
      selectedDebtorCardGuids: string[];
    } & AccountingPrintReq
  ) {
    const { precedents, closeTransactionPage } = data;
    const handlePrecedentSelection = (d: IPrecedentListModelSelection): void => {
      if (d.action === EPrecedentListModelEvent.Confirm && !!d.precedentEntries && !!d.precedentEntries[0]) {
        this._store.dispatch(
          new apiActions.AccountingPrintViaDocBuilder({
            ...data,
            precedent: d.precedentEntries[0],
          })
        );
      }
    };

    this._precedentListModalListenerSvc.navigateToPrecedentSelection({
      precedentEntries: precedents,
      closePopupOutlet: closeTransactionPage,
    });
    this._precedentListModalListenerSvc.listenToPrecedentSelectedEvent(handlePrecedentSelection);
  }
}
