export enum SemaphoreType {
  TrustToOffice = 1,
  OfficeInvoice = 2,
  TrustBankDeposit = 3,
  OfficeBankDeposit = 4,
  BankReconciliation = 5,
  UpdateInvoice = 6,
  PrintCheques = 7,
}

export interface SemaphoreResponse {
  Code: number;
  SemaphoreId: string;
  ErrorMessage: string;
}

export const getSemaphoreName = (type: SemaphoreType, param = ''): string => {
  switch (type) {
    case SemaphoreType.TrustToOffice:
      return `TOT_${param}`;

    case SemaphoreType.OfficeInvoice:
      return `Invoice_${param}`;

    case SemaphoreType.UpdateInvoice:
      return `Adjust_Inv${param}`;

    case SemaphoreType.TrustBankDeposit:
      return `BankDeposit_${param}`;

    case SemaphoreType.OfficeBankDeposit:
      return `OfficeBankDeposit${param}`;

    case SemaphoreType.BankReconciliation:
      return `BankRec_${param}`;

    case SemaphoreType.PrintCheques:
      return `PrintCheques_${param}`;

    default:
      return `Semaphore_${param}`;
  }
};
