import { AddinActionType } from '../models/matter-addin.model';

export const defaultBrowser = {
  Type: 'system',
  Width: 1000,
  Height: 662,
  MinWidth: 800,
  MinHeight: 662,
  HideControls: true,
};

export const OnlyVisibleLinks = (items: any[]) => {
  const actualItems = items || [];
  const validLinks = actualItems.filter((i) => i?.hidden === false) || [];

  const validateChildLinks = validLinks.map((v) => {
    if (v.actionType === AddinActionType.DropDown) {
      const childs = OnlyVisibleLinks(v.items);
      if (childs.length === 0) {
        return null;
      }
    }
    return v;
  });

  return validateChildLinks.filter(Boolean);
};
