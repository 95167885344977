import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, switchMap, mergeMap, withLatestFrom, filter } from 'rxjs';
import { of } from 'rxjs';

import { FormStorageService, PlatformService } from '@app/core/services';
import * as timeModalActions from '../actions/time-modal';
import { selectTimeNewWin } from '../selectors';
import { AppApiService } from '@app/core/api';
import { TimeFeeService } from '../../services';

@Injectable()
export class TimeModalEffects {
  public formUiKey = '__TIME__FORM__UI';

  // new window effects

  hydrateTimeForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(timeModalActions.HYDRATE_TIME_FORM),
      switchMap((action: timeModalActions.HydrateTimeForm) =>
        this._formStorageSvc.setFormUi(this.formUiKey, action.payload).pipe(
          mergeMap((x) => [new timeModalActions.HydrateTimeFormSuccess(x)]),
          catchError((error) => of(new timeModalActions.HydrateTimeFormFailure(error)))
        )
      )
    )
  );

  deHydrateTimeForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(timeModalActions.DEHYDRATE_TIME_FORM),
      filter(() => this._ps.isBrowser),
      switchMap(() =>
        this._formStorageSvc.formUi(this.formUiKey).pipe(
          mergeMap((formUi) => [new timeModalActions.DeHydrateTimeFormSuccess(formUi)]),
          catchError((error) => of(new timeModalActions.DeHydrateTimeFormFailure(error)))
        )
      )
    )
  );

  deHydrateTimeFormSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(timeModalActions.DEHYDRATE_TIME_FORM_SUCCESS),
        filter(() => this._ps.isBrowser),
        switchMap(() => this._formStorageSvc.clean().pipe(mergeMap(() => [])))
      ),
    { dispatch: false }
  );

  closeWindow$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(timeModalActions.CLOSE_TIME),
        withLatestFrom(this._store.pipe(select(selectTimeNewWin)), (action, newWin) => newWin),
        switchMap((newWin) => {
          if (newWin && this._ps.isBrowser) {
            window.close();
          } else {
            this._appApiSvc.clearCurrentModal();
          }
          return [];
        })
      ),
    { dispatch: false }
  );
  // end new window effects

  initTimeFeeForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType<timeModalActions.InitTimeFeeForm>(timeModalActions.INIT_TIME_FEE_FORM),
      switchMap((action) => {
        const { matterId, feeGUID } = action.payload;

        // init from existing form.
        if (feeGUID) {
          return this._timeFeeSvc.getTimeFeeInitData(feeGUID).pipe(
            mergeMap((result) => [new timeModalActions.InitTimeFeeFormSuccess(result)]),
            catchError((error) => of(new timeModalActions.InitTimeFeeFormFailure(error)))
          );
        }

        return this._timeFeeSvc.getNewTimeFeeInitData(matterId).pipe(
          mergeMap((result) => [new timeModalActions.InitTimeFeeFormSuccess(result)]),
          catchError((error) => of(new timeModalActions.InitTimeFeeFormFailure(error)))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private _appApiSvc: AppApiService,
    private _formStorageSvc: FormStorageService,
    private _ps: PlatformService,
    private _store: Store<any>,
    private _timeFeeSvc: TimeFeeService
  ) {}
}
