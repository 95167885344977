import { Action } from '@ngrx/store';

export enum PersonModalActionTypes {
    OPEN_PERSON_MODAL = '[Person Modal] Open person modal',

    CONFIRM_DISMISS_MODAL = '[Person Modal] Confirm Dismiss Modal',
    DISMISS_MODAL = '[Person Modal] Dismiss',
}

export class OpenPersonModal implements Action {
    readonly type = PersonModalActionTypes.OPEN_PERSON_MODAL;

    // id of the person to open, or null if new person
    constructor(public payload: string) {}
}

export class ConfirmDismissModal implements Action {
    readonly type = PersonModalActionTypes.CONFIRM_DISMISS_MODAL;
}

export class DismissModal implements Action {
    readonly type = PersonModalActionTypes.DISMISS_MODAL;
}


export type PersonModalActions = OpenPersonModal | ConfirmDismissModal | DismissModal;
