import { Injectable } from '@angular/core';

import { BaseService, TextUtilsService } from '@app/shared/services';
import { FirmDetails, IStaff } from '@app/shared/models';
import { ChatConfig, IChatConfig } from '@app/features/live-chat/models';
import { BrandService } from '@app/core/services';
import { Regions } from '@app/shared/models/config.model';
import { isEmptyObj, joinArrayToString } from '@server/modules/shared/functions/common-util.functions';

@Injectable()
export class LiveChatService extends BaseService {
  constructor(private _brandSvc: BrandService, private _textUtilsSvc: TextUtilsService) {
    super();
  }

  openChatWindow(firmDetails: FirmDetails, staff: IStaff): void {
    const { firmName, region } = firmDetails;

    const chatConfig = this.getChatConfig(region, this._brandSvc.brandEnv === 'live');
    const sfUrlSegment = this.urlJoin('https://', chatConfig.sfDomain, chatConfig.helpIQ);
    const regAddonUrlSegment = chatConfig.regAddon;
    const endtUrlSegment = encodeURIComponent(this.urlJoin('https://', chatConfig.endId));
    const userDetailsUrlSegment = this.buildUserDetails(firmName, staff);

    const chatWindowSpecs = this.getChatWindowSpecs();

    const chatUrl: string = this.urlJoin(
      sfUrlSegment,
      regAddonUrlSegment,
      `?endpoint=${endtUrlSegment}&chat_user=${userDetailsUrlSegment}`
    );
    window.open(chatUrl, 'Chat Window', chatWindowSpecs);
  }

  private getChatConfig(region: string, isLive: boolean): IChatConfig {
    const auConfig: IChatConfig = isLive ? ChatConfig.AU_Live : ChatConfig.AU_Test;
    const ukConfig: IChatConfig = (isLive ? ChatConfig.UK_Live : ChatConfig.UK_Test) as IChatConfig;
    const usConfig: IChatConfig = (isLive ? ChatConfig.US_Live : ChatConfig.US_Test) as IChatConfig;

    switch (region) {
      case Regions.NZ:
      case Regions.AU:
        return auConfig;
      case Regions.UK:
        return isEmptyObj(ukConfig) ? auConfig : ukConfig;
      case Regions.US:
        return isEmptyObj(usConfig) ? auConfig : usConfig;
      default:
        return auConfig;
    }
  }

  private buildUserDetails(firmName: string, staff: IStaff): string {
    const encodeString: string = joinArrayToString(
      [firmName, staff.firstName, staff.lastName, staff.email, 'General Enquiry'],
      '|'
    );
    return this._textUtilsSvc.b64EncodeUnicode(encodeString);
  }

  private getChatWindowSpecs(): string {
    const chatPosLeft = window.innerWidth - ChatConfig.DisplayOption.ChatWidth - 20;
    const chatPosTop = window.innerHeight - ChatConfig.DisplayOption.ChatHeight + 40;

    return joinArrayToString(
      [
        'toolbar=0,location=0,status=0,menubar=0,scrollbars=0,resizable=1',
        `width=${ChatConfig.DisplayOption.ChatWidth}`,
        `height=${ChatConfig.DisplayOption.ChatHeight}`,
        `top=${chatPosTop}`,
        `left=${chatPosLeft}`,
      ],
      ','
    );
  }
}
