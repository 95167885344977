import { Component, HostBinding } from '@angular/core';
import { RowNode } from '@ag-grid-community/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-group-cell',
  templateUrl: './group-cell.component.html',
})
export class GroupCellComponent implements ICellRendererAngularComp {
  rowNode: RowNode;
  groupName: string;

  private _groupCellClass: string;

  constructor() {}

  @HostBinding('class')
  get groupCellClass(): string {
    return this._groupCellClass;
  }

  agInit(params: any): void {
    this.rowNode = params.node;
    const customGroupName = isFunction(params['getGroupName']) ? params.getGroupName(this.rowNode) : null;
    this._groupCellClass = isFunction(params['getGroupCellClass'])
      ? params.getGroupCellClass(this.rowNode)
      : params['getGroupCellClass'] || '';
    this.groupName = customGroupName || this.rowNode.key || '';
  }

  refresh(params: any): boolean {
    return true;
  }
}
