import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { BaseService } from '../base/base.service';
import { TableMapping } from '../../models';

@Injectable()
export class TableMappingService extends BaseService {
  constructor(private http: HttpClient, private translateSvc: TranslateService) {
    super();
  }

  get(): Observable<TableMapping[]> {
    const url = `${this.siriusPath}/api/tablemap`;
    return this.http.get<TableMapping[]>(url);
  }

  getTableId(tables: TableMapping[], jsName: string): string {
    const table: TableMapping = tables?.find((t) => t.JSName === jsName);
    if (table) {
      return table.TableId;
    } else {
      return '';
    }
  }

  getDisplayValue(jsName: string): string {
    const localeKey = `JSName_${jsName}`;
    return this.translateSvc.instant(localeKey) || jsName;
  }
}
