import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { LogService } from '../log/log.service';

@Injectable()
export class ConfigService {
  private config = environment.config;

  constructor(private log: LogService) {
    log.init('config-service');
  }

  get(key: string) {
    this.log.info(`key: ${key}, config: ${this.config[key]}`);
    return this.config[key];
  }

  /**
   * Get the region (au, us, uk, etc) of the environment
   *
   * @return
   * */
  public getEnvironmentRegion(): string {
    return this.config.brand.region;
  }

  /**
   * Get the bylawyers url of the environment
   *
   * @return
   * */
  public getByLawyersUrl(): string {
    return this.config.brand.bylawyersUrl;
  }
}
