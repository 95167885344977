<div class="d-flex align-items-center">
  <h6 *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
    {{ title }}
  </h6>
  <a href scPreventDefault *ngIf="options.closeButton" (click)="remove()" class="close">
    <sc-icon class="x-icon-lg" iconName="close"></sc-icon>
  </a>
</div>
<div class="x-toast-message-container">
  <sc-icon class="x-toast-icon x-icon-xxl" [iconName]="getIconClass()"></sc-icon>
  <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite" [class]="options.messageClass">
    <span [innerHTML]="message"></span>
    <a href class="ml-2" (click)="action($event)" *ngIf="!!(actionText$ | async)" scPreventDefault>
      {{ actionText$ | async }}
    </a>
  </div>
  <div
    *ngIf="message && !options.enableHtml"
    role="alert"
    aria-live="polite"
    [class]="options.messageClass"
    [attr.aria-label]="message"
  >
    {{ message }}
    <a href class="ml-2" (click)="action($event)" *ngIf="!!(actionText$ | async)" scPreventDefault>
      {{ actionText$ | async }}
    </a>
  </div>
</div>
<div *ngIf="options.progressBar" class="toast-progress" [style.width]="width + '%'"></div>
