import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MatterDetailsService, MatterDetailsStorageService } from './services';
import { reducers, CurrentMatterEffects } from './store';
import { CorrespondenceStateModule } from '../+correspondence/correspondence-state.module';
import { FolderStateModule } from '../+correspondence/folder-state.module';
import { MatterTablesService } from '../+matter-table-types/services';
import { InfoTrackService } from './services/infotrack/infotrack.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('matterDetails', reducers),
    EffectsModule.forFeature([CurrentMatterEffects]),
    CorrespondenceStateModule,
    FolderStateModule,
  ],
  declarations: [],
  providers: [MatterDetailsService, MatterDetailsStorageService, MatterTablesService, InfoTrackService],
})
export class MatterDetailsStateModule {}
