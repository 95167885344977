import { TrustReceiptDetailsComponent } from './trust-receipt-details/trust-receipt-details.component';
import { TrustReceiptListComponent } from './trust-receipt-list/trust-receipt-list.component';
import { TrustReceiptViewComponent } from './trust-receipt-view/trust-receipt-view.component';
import { TrustReceiptReverseComponent } from './trust-receipt-reverse/trust-receipt-reverse.component';
import { TrustReceiptSummaryCellComponent } from './ag-grid';
import { TrustReceiptToolbarComponent } from './trust-receipt-toolbar/trust-receipt-toolbar.component';

export * from './trust-receipt-details/trust-receipt-details.component';
export * from './trust-receipt-list/trust-receipt-list.component';
export * from './trust-receipt-view/trust-receipt-view.component';
export * from './trust-receipt-reverse/trust-receipt-reverse.component';
export * from './ag-grid';
export * from './trust-receipt-toolbar/trust-receipt-toolbar.component';
export const trustReceiptComponents = [
  TrustReceiptDetailsComponent,
  TrustReceiptListComponent,
  TrustReceiptViewComponent,
  TrustReceiptReverseComponent,
  TrustReceiptToolbarComponent,
];

export const trustReceiptAgGridComponents = [TrustReceiptSummaryCellComponent];
