import { Component, Input } from '@angular/core';

@Component({
  selector: 'sc-initials-circle',
  templateUrl: './initials-circle.component.html',
})
export class InitialsCircleComponent {
  @Input() icInitials: string;
  @Input() icSizeClass: string;
  @Input() icToolTip: string;

  get initialSizeClass(): string {
    return !this.icInitials || this.icInitials.length === 2 ? 'ch2' : 'ch3';
  }

  constructor() {}
}
