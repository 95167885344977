import { EventBusService, LogService, PlatformService } from 'app/core/services';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { Observable, of, throwError } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { MatterListService, MatterListStorageService } from '../../services';
import { CreateMatterSuccessPayload, IMatterListResponseSchema } from '../../models';
import * as matterListActions from '../actions/matter-list';
import { selectLoaded, selectMetaInfo } from '../selectors';
import { State } from '../reducers';
import { selectCardModalFormValue } from '@app/features/+card/store/selectors';
import { ESiriusEvents } from '@app/core/models';
import { SiriusError } from '@app/features/error-handler/interfaces/error-handler.interfaces';
import { AppApiService } from '@app/core/api';
import { isEmptyValue } from '@server/modules/shared/functions/common-util.functions';

@Injectable()
export class MatterListEffects {

  initListMattersStart$ = createEffect(() => this.actions$.pipe(
    ofType(matterListActions.InitListMattersStart),
    withLatestFrom(this.store.pipe(select(selectLoaded)), (action, loaded) => loaded),
    filter((loaded) => !loaded),
    switchMap(() =>
      this.matterListStorageSvc.getMeta().pipe(
        map((meta) => (meta?.lastRowVer ?? 0)),
        catchError(() => this.handleInitListMatterError())
      )
    ),
    switchMap((lastRowVer) => this.matterListSvc.getAll(lastRowVer).pipe(
      catchError(() => this.handleInitListMatterError())
    )),
    mergeMap((matterResponse: IMatterListResponseSchema) => [
      matterListActions.InitListMattersSuccess({ response: matterResponse }),
      matterListActions.MatterSaveDbStart({ payload: matterResponse }),
    ])
  ));

  // Save matter list metadata to database
  saveMatterListMetaToDb$ = createEffect(() => this.actions$.pipe(
    ofType(matterListActions.MatterListMetaSaveDbStart),
    filter(() => this.platformService.isBrowser),
    withLatestFrom(this.store.pipe(select(selectMetaInfo))),
    switchMap(([action, metaInfo]) =>
      this.matterListStorageSvc
        .upsertMeta(metaInfo)
        .then(() => matterListActions.MatterListMetaSaveDbSuccess({ message: 'success' }))
        .catch((error) => matterListActions.MatterListMetaSaveDbFailure({ error }))
    )
  ));

  // Fetch matter list
  listMatters$ = createEffect(() => this.actions$.pipe(
    ofType(matterListActions.ListMattersStart),
    switchMap(() =>
      this.matterListStorageSvc.getMeta().pipe(map((meta) => (meta?.lastRowVer ?? 0)))
    ),
    switchMap((lastRowVer) =>
      this.matterListSvc.getAll(lastRowVer).pipe(
        mergeMap((matterResponse: IMatterListResponseSchema) => [
          matterListActions.ListMattersSuccess({ response: matterResponse }),
          matterListActions.MatterSaveDbStart({ payload: matterResponse }),
        ]),
        catchError((error) => of(matterListActions.ListMattersFailure({ error })))
      )
    )
  ));

  // Save matter list to database
  saveMattersToDb$ = createEffect(() => this.actions$.pipe(
    ofType(matterListActions.MatterSaveDbStart),
    filter(() => this.platformService.isBrowser),
    switchMap((action) =>
      this.matterListStorageSvc.upsertAll(action.payload).catch(() => {
        throw new SiriusError({
          type: 'error',
          title: 'Failure',
          message: 'Unable to refresh matter list',
        });
      })
    ),
    switchMap(() => this.matterListStorageSvc.getAll().pipe(
      mergeMap((data) => {
        this.matterListStorageSvc.refreshAll(data);
        return [matterListActions.MatterSaveDbSuccess({ message: 'success' })];
      }),
      catchError(() => {
        throw new SiriusError({
          type: 'error',
          title: 'Failure',
          message: 'Unable to refresh matter list',
        });
      })
    ))
  ));

  // Delete matter
  deleteMatter$ = createEffect(() => this.actions$.pipe(
    ofType(matterListActions.DeleteMatterStart),
    mergeMap((action) =>
      this.matterListSvc.delete(action.matter.matterId).pipe(
        map(() => {
          this.eventBus.emit({
            name: ESiriusEvents.ShowToastr,
            value: { type: 'success', title: 'Success', message: 'Matter deleted successfully.' },
          });
          return matterListActions.DeleteMatterSuccess({ result: null });
        }),
        catchError((error) => {
          this.store.dispatch(matterListActions.DeleteMatterFailure({ error }));
          return throwError(() => new SiriusError({
            type: 'error',
            title: 'Failure',
            message: 'Unable to delete matter.',
          }));
        })
      )
    )
  ));

  // Create matter
  createMatter$ = createEffect(() => this.actions$.pipe(
    ofType(matterListActions.CreateMatterStart),
    withLatestFrom(this.store.pipe(select(selectCardModalFormValue))),
    exhaustMap(([action, formValue]) => {
      const createParams = { ...action.matterParams, card: formValue };

      return this.matterListSvc.create(createParams).pipe(
        map((result: CreateMatterSuccessPayload) => {
          if (isEmptyValue(`${result?.fileNumber ?? ''}`)) {
            return matterListActions.CreateMatterFailure({ errorMessage: 'No file number returned.' });
          }

          this.appApiSvc.clearCurrentModal();
          return matterListActions.CreateMatterSuccess({ result: result });
        }),
        catchError(() => of(matterListActions.CreateMatterFailure({ errorMessage: 'Could not create matter.' })))
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private matterListSvc: MatterListService,
    private matterListStorageSvc: MatterListStorageService,
    private platformService: PlatformService,
    private eventBus: EventBusService,
    private store: Store<State>,
    private appApiSvc: AppApiService
  ) {}

  // Handle initialization errors
  private handleInitListMatterError(): Observable<any> {
    this.store.dispatch(matterListActions.InitListMattersFailure({ error: null }));

    return throwError(() => new SiriusError({
      type: 'error',
      title: 'Failure',
      message: 'Unable to initiate matter list',
    }));
  }
}
