import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedAgGridModule } from '@app/sharedaggrid/sharedaggrid.module';
import { CorrespondenceEffects } from './store/effects';
import { reducers } from './store/reducers';
import { AppSlice } from '@app/core/store';
import { CorrespondenceService } from './services';

@NgModule({
  imports: [
    CommonModule,
    SharedAgGridModule,
    StoreModule.forFeature(AppSlice.Correspondence, reducers),
    EffectsModule.forFeature([CorrespondenceEffects]),
  ],
  providers: [CorrespondenceService],
})
export class CorrespondenceStateModule {}
