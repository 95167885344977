import { Component, HostBinding, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorParams } from '@ag-grid-community/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { distinctUntilChanged, filter as rxjsFilter, skip, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { AggridEditorAbstract } from '@app/sharedaggrid/components/aggrid-editor/aggrid-editor.abstract';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-date-editor-cell',
  templateUrl: './date-editor-cell.component.html',
})
export class DateEditorCellComponent extends AggridEditorAbstract implements OnDestroy {
  @ViewChild('input', { read: ViewContainerRef, static: true }) public input;
  private _params: ICellEditorParams;
  public editForm: FormGroup;

  public formSub: Subscription;
  private unsub = new Subject<void>();

  constructor(private _formBuilder: FormBuilder) {
    super();
    this.createForm();
  }

  @HostBinding('class.w-100')
  agInit(params: ICellEditorParams): void {
    super.agInit(params);
    this._params = params;
    const defaultDate = isFunction(params['getDate'])
      ? new Date((params as any).getDate(params.node.data))
      : new Date();
    this.editForm.patchValue({ date: defaultDate });
  }

  focusIn() {
    const pCalendarInputs = this.input.element.nativeElement.getElementsByTagName('input');
    if (pCalendarInputs && pCalendarInputs[0]) {
      pCalendarInputs[0].focus();
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  getValue(): any {
    return this.editForm.get('date').value;
  }

  stopEditing(): void {
    this._params.api.stopEditing();
  }

  private createForm(): void {
    this.editForm = this._formBuilder.group({
      date: new Date(),
    });
    this.subscribeToFormChanges();
  }

  private dateToString = (d: any) => (d ? d.toString() : '');

  private subscribeToFormChanges(): void {
    this.formSub = this.editForm.controls['date'].valueChanges
      .pipe(
        rxjsFilter((data) => !!data),
        skip(1),
        distinctUntilChanged((prev, cur) => {
          const prevDate = this.dateToString(prev);
          const curDate = this.dateToString(cur);

          return prevDate === curDate;
        }),
        takeUntil(this.unsub) // important: this operator must be last
      )
      .subscribe(() => {
        this.stopEditing();
      });
  }
}
