import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IMatterType, IStateMatterTypesEntry } from '@app/shared/models';
import { IMatterTypesSetupData } from '@app/features/+matter-types/models';
import * as actions from '../actions';
import * as precedentListActions from '@app/features/+precedent/store/actions';

export interface State extends EntityState<IStateMatterTypesEntry> {
  loading: boolean;
  loadingStates: boolean;
  switchMatterTypeLoading: boolean;
  states: string[];
  selectedState: string;
  selectedMatterTypeId: string;
  selectedMatterTypeDetails: IMatterType;
  searchText: string;
  setupData: IMatterTypesSetupData;
}

export const selectId = (stateMatterType: IStateMatterTypesEntry): string => stateMatterType.state;

export const adapter: EntityAdapter<IStateMatterTypesEntry> = createEntityAdapter<IStateMatterTypesEntry>({
  selectId,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loading: true,
  loadingStates: false,
  switchMatterTypeLoading: false,
  states: [],
  selectedState: '',
  selectedMatterTypeId: '',
  selectedMatterTypeDetails: null,
  searchText: '',
  setupData: null,
});

export const reducer = (
  state: State = initialState,
  action: actions.MatterTypesActions | precedentListActions.PrecedentListActions
): State => {
  switch (action.type) {
    case actions.LIST_MATTER_TYPES_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.LIST_MATTER_TYPES_SUCCESS: {
      return {
        // ...upsertMany([action.payload], state, adapter, 'state'),
        ...adapter.upsertMany([action.payload], state),
        loading: false,
      };
    }

    case actions.LIST_MATTER_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.CHANGE_STATE: {
      const isStateChanged = state.selectedState !== action.payload.state;
      if (isStateChanged && action.payload?.matterTypeId) {
        return {
          ...state,
          selectedState: action.payload.state,
          selectedMatterTypeId: action.payload.matterTypeId,
        };
      }

      return {
        ...state,
        selectedState: isStateChanged ? action.payload.state : state.selectedState,
      };
    }

    case actions.CLEAR_MATTER_TYPES: {
      return initialState;
    }

    case actions.SETUP_MATTER_TYPES: {
      return {
        ...state,
        setupData: action.payload,
      };
    }

    case actions.LAST_MATTER_TYPE_ID: {
      return {
        ...state,
        switchMatterTypeLoading: true,
      };
    }

    case actions.MATTER_TYPE_START:
      return {
        ...state,
        switchMatterTypeLoading: true,
      };

    case actions.MATTER_TYPE_SUCCESS:
      return {
        ...state,
        switchMatterTypeLoading: false,
        selectedMatterTypeDetails: action.payload,
        selectedMatterTypeId: action.payload ? action.payload.id : null,
      };

    case actions.MATTER_TYPE_FAILURE: {
      return {
        ...state,
        switchMatterTypeLoading: false,
        selectedMatterTypeDetails: null,
        selectedMatterTypeId: null,
        loading: false,
      };
    }

    case actions.SEARCH_MATTER_TYPE: {
      return {
        ...state,
        searchText: action.payload,
      };
    }

    /** State list actions **/
    case actions.LIST_STATES_START: {
      return { ...state, loadingStates: true };
    }
    case actions.LIST_STATES_SUCCESS: {
      return { ...state, loadingStates: false, states: action.payload };
    }
    case actions.LIST_STATES_FAILURE: {
      return { ...state, loadingStates: false };
    }
    /** End - State list actions **/

    default: {
      return state;
    }
  }
};

export const selectSwitchMatterTypeLoading = (state: State) => state.switchMatterTypeLoading;
export const selectLoading = (state: State) => state.loading;
export const selectLoadingStates = (state: State) => state.loadingStates;
export const selectSearchText = (state: State) => state.searchText;
export const selectStates = (state: State) => state.states;
export const selectSelectedState = (state: State) => state.selectedState;
export const selectMatterTypes = (state: State) => {
  const selectedStateEntry = state.entities[state.selectedState];
  return selectedStateEntry ? selectedStateEntry.matterTypes : [];
};
export const selectSelectedMatterTypeId = (state: State) => state.selectedMatterTypeId;
export const selectSelectedMatterTypeDetails = (state: State) => state.selectedMatterTypeDetails;
export const selectSetupData = (state: State) => state.setupData;
