import { IAutomationTicket, IAutomationWorkflowParams } from '@app/shared/models';
import * as actions from '../actions/automation.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface State extends EntityState<IAutomationTicket> {
  automationLocalTicketId: string;
  automationLoading: boolean;
  automationResponded: boolean;
  automationWorkflowParams: IAutomationWorkflowParams;
}

export const adapter: EntityAdapter<IAutomationTicket> = createEntityAdapter<IAutomationTicket>({
  selectId: (automationTicket) => automationTicket.ticketId,
  sortComparer: false,
});

export const INIT_STATE: State = adapter.getInitialState({
  automationLocalTicketId: undefined,
  automationLoading: false,
  automationResponded: false,
  automationWorkflowParams: null,
});

export const reducer = (state: State = INIT_STATE, action: actions.AutomationActions) => {
  switch (action.type) {
    case actions.AutomationActionTypes.ADD_TICKET:
      return adapter.addOne(action.payload.ticket, state);

    case actions.AutomationActionTypes.DELETE_TICKET:
      return adapter.removeOne(action.payload.ticketId, state);

    case actions.AutomationActionTypes.CLEAR_TICKETS:
      return adapter.removeAll({ ...state });

    case actions.AutomationActionTypes.UPDATE_AUTOMATION_LOCAL_TICKET_ID: {
      return {
        ...state,
        automationLocalTicketId: action.payload,
      };
    }

    case actions.AutomationActionTypes.LAUNCH_AUTOMATION_DIALOG_START: {
      return {
        ...state,
        automationLoading: true,
        automationWorkflowParams: action.payload,
      };
    }

    case actions.AutomationActionTypes.AUTOMATION_CLEAR_PARAMS: {
      return {
        ...state,
        automationLocalTicketId: null,
        automationLoading: false,
        automationWorkflowParams: null,
      };
    }

    case actions.AutomationActionTypes.AUTOMATION_RESPONDED: {
      return {
        ...state,
        automationResponded: true,
      };
    }

    case actions.AutomationActionTypes.RESET_AUTOMATION_RESPONDED: {
      return {
        ...state,
        automationResponded: false,
      };
    }

    case actions.AutomationActionTypes.RESET_AUTOMATION_LOADING:
    case actions.AutomationActionTypes.AUTOMATION_READY:
    case actions.AutomationActionTypes.AUTOMATION_ERROR:
    case actions.AutomationActionTypes.NOTIFY_AUTOMATION_WORD_BUSY: {
      return {
        ...state,
        automationLoading: false,
      };
    }

    default:
      return state;
  }
};
// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of tickets
export const selectAllTickets = selectAll;

export const selectAutomationLocalTicketId = (state: State) => state.automationLocalTicketId;
export const selectAutomationLoading = (state: State) => state.automationLoading;
export const selectAutomationResponded = (state: State) => state.automationResponded;
export const selectAutomationWorkflowParams = (state: State) => state.automationWorkflowParams;
