import { Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { BrandService } from '@app/core/services';
import { DateFormatType } from '@app/shared/models/config.model';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-date-cell',
  templateUrl: './date-cell.component.html',
})
export class DateCellComponent implements AgRendererComponent {
  wrapperClass: string;
  transactionDate: Date;
  isHidden: boolean;
  dateFormat: string;
  showPending: boolean;

  constructor(private _brandSvc: BrandService) {}

  agInit(params: any): void {
    this.setupData(params);
  }

  refresh(params: any): boolean {
    this.setupData(params);
    return true;
  }

  private setupData(params: any): void {
    this.wrapperClass =
      !!params.getWrapperClass && isFunction(params.getWrapperClass) ? params.getWrapperClass(params.data) : '';
    this.isHidden = !!params.isHidden && isFunction(params.isHidden) ? params.isHidden(params.data) : false;
    this.transactionDate = this.isHidden ? null : params.getTransactionDate(params.data);
    this.dateFormat =
      !!params.getDateFormat && isFunction(params.getDateFormat)
        ? params.getDateFormat(params.data)
        : this._brandSvc.getRegionDateFormat(DateFormatType.A);
    this.showPending = !!params.showPending && isFunction(params.showPending) ? params.showPending(params.data) : false;
  }
}
