import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FeeTimerTimeElapsedSet } from '@app/features/fee-timer/store/actions';
import { TimerService } from '@app/shared/services';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'sc-timer',
  templateUrl: './timer.component.html',
})
export class TimerComponent implements OnInit, OnDestroy {
  @Input() isDisabled: boolean;

  @Output() updateSecondsElapsed = new EventEmitter<number>();

  durationInSeconds: number;
  timerDisplay: string;

  private destroy$ = new Subject<void>();

  constructor(private _store: Store<any>, private timerService: TimerService) {}

  @HostBinding('class.w-100')
  ngOnInit() {
    this.timerService.accumulatedDuration$.pipe(takeUntil(this.destroy$)).subscribe((accumulatedDuration: number) => {
      this.durationInSeconds = accumulatedDuration;
      this.updateSecondsElapsed.emit(this.durationInSeconds);
      this.timerDisplay = this.hoursMinutesSeconds(accumulatedDuration);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onBlur() {
    this.convertMaskToSeconds(this.timerDisplay);
  }

  convertMaskToSeconds(mask: string) {
    const maskArray = mask.split(':');
    if (maskArray.length < 3) {
      this.durationInSeconds = 0;
      this._store.dispatch(new FeeTimerTimeElapsedSet({ durationInSeconds: 0 }));
      return;
    }
    let secondsElapsedCalc = 0;
    // hours
    secondsElapsedCalc += parseInt(maskArray[0], 10) * 3600;
    // minutes
    secondsElapsedCalc += parseInt(maskArray[1], 10) * 60;
    // seconds
    secondsElapsedCalc += parseInt(maskArray[2], 10);
    this._store.dispatch(new FeeTimerTimeElapsedSet({ durationInSeconds: secondsElapsedCalc }));
  }

  hoursMinutesSeconds(x: number): string {
    if (!x || !(x > 0)) {
      return '00:00:00';
    }

    const hours = Math.floor(x / 3600);
    const minutes = Math.floor((x - hours * 3600) / 60);
    let seconds = x - hours * 3600 - minutes * 60;

    // round seconds
    seconds = Math.round(seconds * 100) / 100;

    const result = `
        ${hours < 10 ? '0' + hours : hours}:
        ${minutes < 10 ? '0' + minutes : minutes}:
        ${seconds < 10 ? '0' + seconds : seconds}
        `;

    return result;
  }
}
