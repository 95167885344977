import { ICardListEntry, IPersonEntry } from '@app/features/+card/models';
import { IPerson } from '@app/shared/models';
import { IMatterListEntry } from '@app/features/+matter-list/models';

export enum CardDetailsPopupActionTypes {
  UPDATE_POPUP_CARD_INFORM_START = '[Card Details Popup] start to update popup card details inform',
  UPDATE_POPUP_CARD_INFORM = '[Card Details Popup] update popup card inform (id, matter)value in state',
  REST_POPUP_CARD_INFORM = '[Card Details Popup] reset popup card inform in state',
  EDIT_CARD_DETAILS = '[Card Details Popup] go to card details modal page',
  CREATE_EMAIL = '[Card Details Popup] go to create email page',
}

export class UpdatePopupCardInformStart {
  readonly type = CardDetailsPopupActionTypes.UPDATE_POPUP_CARD_INFORM_START;

  constructor(public payload: { cardId: string }) {}
}

export class UpdatePopupCardInform {
  readonly type = CardDetailsPopupActionTypes.UPDATE_POPUP_CARD_INFORM;

  constructor(public payload: { cardEntry: ICardListEntry; persons: IPersonEntry[]; matters: IMatterListEntry[] }) {}
}

export class ResetPopupCardInform {
  readonly type = CardDetailsPopupActionTypes.REST_POPUP_CARD_INFORM;

  constructor(public payload: any) {}
}

export class EditCardDetails {
  readonly type = CardDetailsPopupActionTypes.EDIT_CARD_DETAILS;

  constructor(public payload: { cardEntry: any }) {}
}

export class CreateEmail {
  readonly type = CardDetailsPopupActionTypes.CREATE_EMAIL;

  constructor(public payload: { cardEntry: any }) {}
}

export type CardDetailsPopupActions =
  | UpdatePopupCardInformStart
  | UpdatePopupCardInform
  | ResetPopupCardInform
  | EditCardDetails
  | CreateEmail;
