import { Action } from '@ngrx/store';

import { IOfficeAction } from '@app/shared/models';

import { IPrecedentDraftValue, ISelectedFolder, ISelectPrecedentPayload } from '../../../models';

export const SEARCH_PRECEDENT_BY_MATTER_TYPE = '[Precedent Search] Precedent search by matter type starts';
export const SEARCH_PRECEDENT_BY_MATTER_TYPE_ON_CUSTOM_PRECEDENT_UPDATED =
  '[Precedent Search] Precedent search by matter type on custom precedent updated starts';
export const SEARCH_PRECEDENT_SUCCESS_V2 = '[Precedent Search] Precedent search successfully';
export const SEARCH_PRECEDENT_FAIL_V2 = '[Precedent Search] Precedent search fails';
export const SEARCH_PRECEDENT_BY_ALL = '[Precedent Search] Precedent search by all starts';
export const SEARCH_PRECEDENT_BY_ALL_SUCCESS = '[Precedent Search] Precedent search by all successfully';
export const OPEN_PRECEDENT = '[Precedent] open start';
export const PRE_OPEN_PRECEDENT_ONLINE = '[Precedent] pre open online start';
export const OPEN_PRECEDENT_ONLINE = '[Precedent] open online start';
export const OPEN_PRECEDENT_SUCCESS = '[Precedent] open success';
export const OPEN_PRECEDENT_FAILURE = '[Precedent] open failure';
export const RESET_PRECEDENT_LIST = '[Precedent list state] reset';
export const SELECT_PRECEDENT = '[Precedent] selected';
export const UPDATE_SELECTED_PRECEDENT_FOLDER = '[Precedent] selected folder';
export const PREP_NEW_LOCAL_PRECEDENT_DRAFT = '[Precedent] Prep New local precedent draft';
export const NEW_LOCAL_PRECEDENT_DRAFT = '[Precedent] New local precedent draft';
export const NEW_LOCAL_PRECEDENT_DRAFT_READY = '[Precedent] New local precedent draft ready';
export const CLEAR_LOCAL_PRECEDENT_DRAFT = '[Precedent] Clear local precedent draft';

export const PRECEDENT_MODAL_OPENED = '[Precedent] Modal Opened';
export const PRECEDENT_MODAL_CLOSED = '[Precedent] Modal Closed';

export class SearchPrecedentByAll implements Action {
  readonly type = SEARCH_PRECEDENT_BY_ALL;

  constructor(public payload: { searchText: string; state: string }) {}
}

export class SearchPrecedentByAllSuccess implements Action {
  readonly type = SEARCH_PRECEDENT_BY_ALL_SUCCESS;

  constructor(public payload: { data: any; totalPages: number; page: number }) {}
}

export class SearchPrecedentByMatterType implements Action {
  readonly type = SEARCH_PRECEDENT_BY_MATTER_TYPE;

  constructor(public payload: { matterTypeId: string; searchText: string; state: string }) {}
}

export class SearchPrecedentByMatterTypeOnCustomPrecentUpdated implements Action {
  readonly type = SEARCH_PRECEDENT_BY_MATTER_TYPE_ON_CUSTOM_PRECEDENT_UPDATED;

  constructor() {}
}

export class SearchPrecedentSuccessV2 implements Action {
  readonly type = SEARCH_PRECEDENT_SUCCESS_V2;

  constructor(public payload: any) {}
}

export class SearchPrecedentFailV2 implements Action {
  readonly type = SEARCH_PRECEDENT_FAIL_V2;

  constructor(public payload: any) {}
}

export class OpenPrecedent implements Action {
  readonly type = OPEN_PRECEDENT;
  constructor(public payload: IOfficeAction) {}
}

// Checks for desktop fields and prompts user to continue or fall back to offline
export class PreOpenPrecedentOnline implements Action {
  readonly type = PRE_OPEN_PRECEDENT_ONLINE;
  constructor(public payload: IOfficeAction) {}
}

export class OpenPrecedentOnline implements Action {
  readonly type = OPEN_PRECEDENT_ONLINE;
  constructor(public payload: IOfficeAction) {}
}

export class OpenPrecedentSuccess implements Action {
  readonly type = OPEN_PRECEDENT_SUCCESS;
  constructor(public payload: any) {}
}

export class OpenPrecedentFailure implements Action {
  readonly type = OPEN_PRECEDENT_FAILURE;
  constructor(public payload: any) {}
}

export class ResetPrecedentList implements Action {
  readonly type = RESET_PRECEDENT_LIST;
  constructor(public payload: any) {}
}

export class SelectPrecedent implements Action {
  readonly type = SELECT_PRECEDENT;
  constructor(public payload: ISelectPrecedentPayload) {}
}

export class UpdateSelectedPrecedentState implements Action {
  readonly type = UPDATE_SELECTED_PRECEDENT_FOLDER;
  constructor(public payload: ISelectPrecedentPayload) {}
}

export class PrepNewLocalPrecedentDraft implements Action {
  readonly type = PREP_NEW_LOCAL_PRECEDENT_DRAFT;
  constructor(public payload: any = null) {}
}

export class NewLocalPrecedentDraft implements Action {
  readonly type = NEW_LOCAL_PRECEDENT_DRAFT;
  constructor(public payload: ISelectedFolder) {}
}

export class NewLocalPrecedentDraftReady implements Action {
  readonly type = NEW_LOCAL_PRECEDENT_DRAFT_READY;
  constructor(public payload: IPrecedentDraftValue) {}
}

export class ClearLocalPrecedentDraft implements Action {
  readonly type = CLEAR_LOCAL_PRECEDENT_DRAFT;
  constructor(public payload: any = null) {}
}

export class PrecedentModalOpened implements Action {
  readonly type = PRECEDENT_MODAL_OPENED;
}

export class PrecedentModalClosed implements Action {
  readonly type = PRECEDENT_MODAL_CLOSED;
}

export type PrecedentListActions =
  | SearchPrecedentByAll
  | SearchPrecedentByAllSuccess
  | SearchPrecedentByMatterType
  | SearchPrecedentByMatterTypeOnCustomPrecentUpdated
  | SearchPrecedentSuccessV2
  | SearchPrecedentFailV2
  | OpenPrecedent
  | OpenPrecedentSuccess
  | OpenPrecedentFailure
  | ResetPrecedentList
  | SelectPrecedent
  | UpdateSelectedPrecedentState
  | OpenPrecedentOnline
  | PreOpenPrecedentOnline
  | PrepNewLocalPrecedentDraft
  | NewLocalPrecedentDraft
  | NewLocalPrecedentDraftReady
  | ClearLocalPrecedentDraft;
