import { Action } from '@ngrx/store';
import { IPersonListMetaInfo, IPersonListResponseSchema } from '@app/features/+person/models';

export enum PersonListDbActionTypes {
  PERSONS_SAVE_DB_START = '[Person save to db] start',
  PERSONS_SAVE_DB_SUCCESS = '[Person save to db] success',
  PERSONS_SAVE_DB_FAILURE = '[Person save to db] failure',

  LOAD_PERSON_LIST_META_START = '[Load person list meta] start',
  LOAD_PERSON_LIST_META_SUCCESS = '[Load person list meta] success',

  PERSON_LIST_META_SAVE_DB_START = '[Person list meta save to db] start',
  PERSON_LIST_META_SAVE_DB_SUCCESS = '[Person list meta save to db] success',
  PERSON_LIST_META_SAVE_DB_FAILURE = '[Person list meta save to db] failure',

  LEAP_APP_SELECT_PERSON_COMMAND_START = '[Leap app select person command] start'
}

export class PersonSaveDbStart implements Action {
  readonly type = PersonListDbActionTypes.PERSONS_SAVE_DB_START;

  constructor(public payload: IPersonListResponseSchema) {}
}

export class PersonSaveDbSuccess implements Action {
  readonly type = PersonListDbActionTypes.PERSONS_SAVE_DB_SUCCESS;

  constructor(public payload: string) {}
}

export class PersonSaveDbFailure implements Action {
  readonly type = PersonListDbActionTypes.PERSONS_SAVE_DB_FAILURE;

  constructor(public payload: string) {}
}

export class LoadPersonListMetaStart implements Action {
  readonly type = PersonListDbActionTypes.LOAD_PERSON_LIST_META_START;

  constructor(public payload: any) {}
}

export class LoadPersonListMetaSuccess implements Action {
  readonly type = PersonListDbActionTypes.LOAD_PERSON_LIST_META_SUCCESS;

  constructor(public payload: Partial<IPersonListMetaInfo>) {}
}

export class PersonListMetaSaveDbStart implements Action {
  readonly type = PersonListDbActionTypes.PERSON_LIST_META_SAVE_DB_START;

  constructor(public payload: Partial<IPersonListMetaInfo>) {}
}

export class PersonListMetaSaveDbSuccess implements Action {
  readonly type = PersonListDbActionTypes.PERSON_LIST_META_SAVE_DB_SUCCESS;

  constructor(public payload: any) {}
}

export class PersonListMetaSaveDbFailure implements Action {
  readonly type = PersonListDbActionTypes.PERSON_LIST_META_SAVE_DB_FAILURE;

  constructor(public payload: any) {}
}

export class LeapAppSelectPersonStart implements Action {
  readonly type = PersonListDbActionTypes.LEAP_APP_SELECT_PERSON_COMMAND_START;

  constructor(public payload: any) {}
}

export type PersonListDbActions =
  | PersonSaveDbStart
  | PersonSaveDbSuccess
  | PersonSaveDbFailure
  | LoadPersonListMetaStart
  | LoadPersonListMetaSuccess
  | PersonListMetaSaveDbStart
  | PersonListMetaSaveDbSuccess
  | PersonListMetaSaveDbFailure
  | LeapAppSelectPersonStart;
