import { AppSlice } from '@app/core/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPreviewAccounting from './preview-accounting.reducer';

export interface State {
  previewAccounting: fromPreviewAccounting.State;
}

export const reducers = {
  previewAccounting: fromPreviewAccounting.reducer,
};

export const INITIAL_STATE: State = {
  previewAccounting: fromPreviewAccounting.INITIAL_STATE,
};

export const selectState = createFeatureSelector<State>(AppSlice.Preview);

export const selectPreviewAccountingState = createSelector(selectState, (state) => state.previewAccounting);
