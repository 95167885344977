import { Action } from '@ngrx/store';
import { ITimeFeeSummary, TimeFeeListMetaInfo } from 'app/features/+time-fee-ledger/models/index';

export const LOAD_TIME_FEE_DB_START = '[Load Time Fee list] from db start';
export const LOAD_TIME_FEE_DB_SUCCESS = '[Load Time Fee list] from db success';
export const LOAD_TIME_FEE_DB_FAILURE = '[Load Time Fee list] from db failure';

export const TIME_FEE_SAVE_DB_START = '[Time Fee save to db] start';
export const TIME_FEE_SAVE_DB_SUCCESS = '[Time Fee save to db] success';
export const TIME_FEE_SAVE_DB_FAILURE = '[Time Fee save to db] failure';

export const TIME_FEE_ALREADY_LOADED = '[Time Fee] already loaded';

export const LOAD_TIME_FEE_LIST_META_START = '[Load Time Fee list meta] start';
export const LOAD_TIME_FEE_LIST_META_SUCCESS = '[Load Time Fee list meta] success';

export const TIME_FEE_LIST_META_SAVE_DB_START = '[Time Fee list meta save to db] start';
export const TIME_FEE_LIST_META_SAVE_DB_SUCCESS = '[Time Fee list meta save to db] succcess';
export const TIME_FEE_LIST_META_SAVE_DB_FAILURE = '[Time Fee list meta save to db] failure';

export const LOAD_SELECTED_TIME_FEE_START = '[Load selected Time Fee] start';
export const LOAD_SELECTED_TIME_FEE_SUCCESS = '[Load selected Time Fee] success';

export const TIME_FEE_NEW_FEE_ENTRY_START = '[Time Fee] new fee start';
export const TIME_FEE_NEW_FEE_ENTRY_SUCCESS = '[Time Fee] new fee success';

export const TIME_FEE_NEW_TIME_ENTRY_START = '[Time Fee] new time start';
export const TIME_FEE_NEW_TIME_ENTRY_SUCCESS = '[Time Fee] new time success';

export const LOAD_TIME_FEE_LEDGER = '[Time Fee] ledger requested';

export const TIME_FEE_RESET = '[Time Fee] reset';

export class LoadTimeFeeDbStart implements Action {
  readonly type = LOAD_TIME_FEE_DB_START;

  constructor(public payload: string) {}
}

export class LoadTimeFeeDbSuccess implements Action {
  readonly type = LOAD_TIME_FEE_DB_SUCCESS;

  constructor(public payload: ITimeFeeSummary[]) {}
}

export class LoadTimeFeeDbFailure implements Action {
  readonly type = LOAD_TIME_FEE_DB_FAILURE;

  constructor(public payload: string) {}
}

export class TimeFeeSaveDbStart implements Action {
  readonly type = TIME_FEE_SAVE_DB_START;

  constructor(public payload: ITimeFeeSummary[]) {}
}

export class TimeFeeSaveDbSuccess implements Action {
  readonly type = TIME_FEE_SAVE_DB_SUCCESS;

  constructor(public payload: string) {}
}

export class TimeFeeSaveDbFailure implements Action {
  readonly type = TIME_FEE_SAVE_DB_FAILURE;

  constructor(public payload: string) {}
}

export class TimeFeeAlreadyLoaded implements Action {
  readonly type = TIME_FEE_ALREADY_LOADED;

  constructor(public payload: any) {}
}

export class LoadTimeFeeListMetaStart implements Action {
  readonly type = LOAD_TIME_FEE_LIST_META_START;

  constructor(public payload: any) {}
}

export class LoadTimeFeeListMetaSuccess implements Action {
  readonly type = LOAD_TIME_FEE_LIST_META_SUCCESS;

  constructor(public payload: Partial<TimeFeeListMetaInfo>) {}
}

export class TimeFeeListMetaSaveDbStart implements Action {
  readonly type = TIME_FEE_LIST_META_SAVE_DB_START;

  constructor(public payload: Partial<TimeFeeListMetaInfo>) {}
}

export class TimeFeeListMetaSaveDbSuccess implements Action {
  readonly type = TIME_FEE_LIST_META_SAVE_DB_SUCCESS;

  constructor(public payload: string) {}
}

export class TimeFeeListMetaSaveDbFailure implements Action {
  readonly type = TIME_FEE_LIST_META_SAVE_DB_FAILURE;

  constructor(public payload: any) {}
}

export class LoadSelectedTimeFeeStart implements Action {
  readonly type = LOAD_SELECTED_TIME_FEE_START;

  constructor(public payload: any) {}
}

export class LoadSelectedTimeFeeSuccess implements Action {
  readonly type = LOAD_SELECTED_TIME_FEE_SUCCESS;

  constructor(public payload: any) {}
}

export class TimeFeeNewFeeEntryStart implements Action {
  readonly type = TIME_FEE_NEW_FEE_ENTRY_START;
}

export class TimeFeeNewTimeEntryStart implements Action {
  readonly type = TIME_FEE_NEW_TIME_ENTRY_START;
}

export class TimeFeeNewFeeEntrySuccess implements Action {
  readonly type = TIME_FEE_NEW_FEE_ENTRY_SUCCESS;

  constructor(public payload: any) {}
}

export class TimeFeeNewTimeEntrySuccess implements Action {
  readonly type = TIME_FEE_NEW_TIME_ENTRY_SUCCESS;

  constructor(public payload: any) {}
}

export class TimeFeeLedgerLoad implements Action {
  readonly type = LOAD_TIME_FEE_LEDGER;

  constructor(public payload: any) {}
}

export class TimeFeeReset implements Action {
  readonly type = TIME_FEE_RESET;

  constructor(public payload: any) {}
}

export type TimeFeeDbActions =
  | LoadTimeFeeDbStart
  | LoadTimeFeeDbSuccess
  | LoadTimeFeeDbFailure
  | TimeFeeSaveDbStart
  | TimeFeeSaveDbSuccess
  | TimeFeeSaveDbFailure
  | TimeFeeAlreadyLoaded
  | LoadTimeFeeListMetaStart
  | LoadTimeFeeListMetaSuccess
  | TimeFeeListMetaSaveDbStart
  | TimeFeeListMetaSaveDbSuccess
  | TimeFeeListMetaSaveDbFailure
  | LoadSelectedTimeFeeStart
  | LoadSelectedTimeFeeSuccess
  | TimeFeeNewFeeEntryStart
  | TimeFeeNewFeeEntrySuccess
  | TimeFeeNewTimeEntryStart
  | TimeFeeNewTimeEntrySuccess
  | TimeFeeLedgerLoad
  | TimeFeeReset;
