import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-icon',
  templateUrl: './icon.component.html',
})
export class IconComponent implements OnInit {
  @Input() iconName: string;
  constructor() {}
  @HostBinding('class.x-icon-container')
  ngOnInit() {}
}
