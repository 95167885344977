import { ChangeDetectorRef, Component, HostBinding, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { xAnimationScaleIn, xAnimationScaleOut } from '@app/shared/animations/animations';
import { LinkLabel } from '@app/core/models';

@Component({
  selector: 'sc-tab-item-dropdown',
  templateUrl: './tab-item-dropdown.component.html',

  animations: [
    trigger('xAnimationScale', [
      transition(':enter', [useAnimation(xAnimationScaleIn)]),
      transition(':leave', [useAnimation(xAnimationScaleOut)]),
    ]),
  ],
})
export class TabItemDropdownComponent implements OnInit {
  hoverOnLabel: boolean;
  hoverOnMenu: boolean;
  @Input()
  label: string;
  @Input()
  dropFromRight: boolean;
  @Input()
  dropdownList: any[];

  @Output()
  onTabClicked = new EventEmitter<LinkLabel>();
  constructor(private cd: ChangeDetectorRef) {}

  @HostBinding('class.nav-item')
  ngOnInit() {
    this.hoverOnLabel = false;
    this.hoverOnMenu = false;
  }

  dismissDropdownMenu() {
    setTimeout(() => {
      if (this.hoverOnLabel === true) {
        this.hoverOnLabel = false;
      } else if (this.hoverOnMenu === true) {
        this.hoverOnMenu = false;
      }
      this.cd.markForCheck();
    }, 100);
  }

  resetDropdownMenu() {
    this.hoverOnLabel = false;
    this.hoverOnMenu = false;
  }

  showDropdownMenu(): boolean {
    return this.hoverOnLabel || this.hoverOnMenu;
  }

  tabClicked(item: LinkLabel) {
    this.onTabClicked.emit(item);
    this.resetDropdownMenu();
  }
}
