import { BillingMode, PaymentMode } from '@app/features/accounting/models';
import { IAccountingStatusUI } from '@app/features/accounting/models/billing-payment.model';
import { isEmptyValue } from '@server/modules/shared/functions/common-util.functions';
import { IPaymentType } from '../models/payment.model';

export const getPaymentTranslateKey = (mode: PaymentMode, isPaid: boolean, isPartPaid: boolean): string => {
  switch (true) {
    case isPaid:
      return 'Accounting.Payment.Paid';
    case isPartPaid:
      return 'Accounting.Payment.PartPaid';
    case mode === PaymentMode.Payable:
      return 'Accounting.Payment.Payable';
    case mode === PaymentMode.NotPayable:
      return 'Accounting.Payment.NotPayable';
    case mode === PaymentMode.NotApplicable:
      return 'Accounting.Payment.NotApplicable';
    default:
      return undefined;
  }
};

export const getBillingStatusUI = (item: IBillingItem): IAccountingStatusUI => {
  switch (true) {
    case item.Deleted:
      return { translateKey: 'Accounting.Status.Deleted', class: 'label-info' };

    case !isEmptyValue(item.InvoiceItemGUID):
      return { translateKey: 'Accounting.Status.Invoiced', class: 'label-success' };

    case item.BillingMode === BillingMode.BillableNextInvoice:
      return { translateKey: 'Accounting.Status.BillableNextInvoice', class: 'label-primary' };

    case item.BillingMode === BillingMode.BillableLaterInvoice:
      return { translateKey: 'Accounting.Status.BillableLaterInvoice', class: 'label-primary' };

    case item.BillingMode === BillingMode.NotBillable:
      return { translateKey: 'Accounting.Status.NotBillable', class: 'label-info' };

    default:
      return { translateKey: 'Accounting.Status.Unknown', class: 'label-info' };
  }
};

export const sortPaymentOptions = (paymentTypeList: IPaymentType[]) => {
  if (paymentTypeList?.length > 0) {
    // exclude Deleted before sorting.
    // todo: need to conduct a scenario where transaction has been commited and follow by deleting the payment type.

    const paymentTypes = paymentTypeList.filter((pt) => pt.Deleted === false);

    return Array.from(paymentTypes).sort((a: any, b: any) => {
      const nameA = a.NameFull?.toUpperCase() || '';
      const nameB = b.NameFull?.toUpperCase() || '';

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  return [];
};

export const sortPaymentTypeList = (payload: any) => {
  if (payload?.PaymentTypeList?.length > 0) {
    let paymentTypeList = payload.PaymentTypeList;

    paymentTypeList = sortPaymentOptions(paymentTypeList);

    return {
      ...payload,
      PaymentTypeList: paymentTypeList,
    };
  }

  return payload;
};

export const sortPaymentTypes = (payload: any) => {
  if (payload?.PaymentTypes?.length > 0) {
    let paymentTypeList = payload.PaymentTypes;

    paymentTypeList = sortPaymentOptions(paymentTypeList);

    return {
      ...payload,
      PaymentTypes: paymentTypeList,
    };
  }

  return payload;
};

interface IBillingItem {
  Deleted?: boolean;
  InvoiceItemGUID?: string;
  BillingMode?: BillingMode;
}
