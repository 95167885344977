import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { BrandService } from '@app/core/services';
import { selectFirmDetails } from '@app/core/store';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class SuperDiaryGuard  {
  constructor(private _store: Store<any>, private _router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const matterNumber = decodeURIComponent(next.params['matterNumber'] || next.queryParams['matterNumber']);
    return BrandService.isLeap() ? this.checkIfSuperDiaryEnabled(matterNumber) : true;
  }

  private checkIfSuperDiaryEnabled(
    matterNumber: string
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._store.pipe(
      select(selectFirmDetails),
      filter((firmDetails) => !!firmDetails && firmDetails.superDiary !== undefined),
      map((firmDetails) => {
        if (firmDetails.superDiary) {
          return true;
        } else {
          if (matterNumber) {
            return this._router.parseUrl(`/matters/${encodeURIComponent(matterNumber)}/calendar`);
          } else {
            return this._router.parseUrl('/matters');
          }
        }
      })
    );
  }
}
