import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import { AppSlice } from '@app/core/store/app.slice';
import * as fromMatterAddin from './matter-addin.reducer';
import * as fromViewLeapApp from './view-leap-app.reducer';
import * as fromGlobalLeapApp from './global-leap-app.reducer';
import * as fromCardPageLeapApp from './card-page-leap-app.reducer';
import * as fromCardModalPageLeapApp from './card-modal-leap-app.reducer';
import * as fromPersonPageLeapApp from './person-page-leap-app.reducer';

export interface LeapAppState {
  viewLeapApps: fromViewLeapApp.State;
  globalLeapApps: fromGlobalLeapApp.State;
  cardPageLeapApps: fromCardPageLeapApp.State;
  cardModalPageLeapApps: fromCardModalPageLeapApp.State;
  personPageLeapApps: fromPersonPageLeapApp.State;
}

export const INITIAL_STATE: LeapAppState = {
  viewLeapApps: fromViewLeapApp.INITIAL_STATE,
  globalLeapApps: fromGlobalLeapApp.INITIAL_STATE,
  cardPageLeapApps: fromCardPageLeapApp.INITIAL_STATE,
  cardModalPageLeapApps: fromCardModalPageLeapApp.INITIAL_STATE,
  personPageLeapApps: fromPersonPageLeapApp.INITIAL_STATE,
};

export const leapAppReducer: ActionReducerMap<any> = {
  viewLeapApps: fromViewLeapApp.reducer,
  globalLeapApps: fromGlobalLeapApp.reducer,
  cardPageLeapApps: fromCardPageLeapApp.reducer,
  cardModalPageLeapApps: fromCardModalPageLeapApp.reducer,
  personPageLeapApps: fromPersonPageLeapApp.reducer,
};

export const selectLeapAppState = createFeatureSelector<LeapAppState>(AppSlice.LeapApp);

export const selectViewLeapAppsState = createSelector(selectLeapAppState, (state: LeapAppState) => state.viewLeapApps);

export const selectGlobalLeapAppsState = createSelector(
  selectLeapAppState,
  (state: LeapAppState) => state.globalLeapApps
);

export const selectCardPageLeapAppsState = createSelector(
  selectLeapAppState,
  (state: LeapAppState) => state.cardPageLeapApps
);

export const selectCardModalPageLeapAppsState = createSelector(
  selectLeapAppState,
  (state: LeapAppState) => state.cardModalPageLeapApps
);

export const selectPersonPageLeapAppsState = createSelector(
  selectLeapAppState,
  (state: LeapAppState) => state.personPageLeapApps
);

export type State = fromMatterAddin.State; // todo: remove this once we clear matter addin v1

export const initialState: State = fromMatterAddin.INITIAL_STATE; // todo: remove this once we clear matter addin v1

export const reducers = fromMatterAddin.reducer; // todo: remove this once we clear matter addin v1

export const selectMatterAddinState = createFeatureSelector<State>(AppSlice.MatterAddin); // todo: remove this once we clear matter addin v1
