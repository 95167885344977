import { createReducer, on } from '@ngrx/store';
import {
  searchMattersStart,
  searchMattersSuccess,
  searchMattersCloudStart,
  searchMattersCloudSuccess,
  searchMattersCloudFailure,
  loadMattersSearchStart,
  loadMattersSearchSuccess,
  searchSaveDbStart,
  searchSaveDbSuccess,
  searchSaveDbFailure
} from '../actions/matter-search';
import { IMatterSearchState } from '../../models';
import { EMattersFilter, AdvancedSearchMode, GroupByNone } from '../../constants';
import { pick } from '@server/modules/shared/functions/common-util.functions';

export interface State extends IMatterSearchState {
  error: any | string | null;
  dbSaveStatus: string;
  searching: boolean;
  loaded: boolean;
}

export const initialState: State = {
  error: null,
  dbSaveStatus: '',
  searching: false,
  groupBy: GroupByNone,
  filterBy: {
    includeAllMatters: false,
    filterKey: EMattersFilter.Current,
  },
  searchText: '',
  advancedSearch: undefined,
  loaded: false,
};

export const reducer = createReducer(
  initialState,

  // Search Matters Start
  on(searchMattersStart, (state, { payload }) => ({
    ...state,
    ...payload,
    searching: true,
  })),

  // Search Matters Success
  on(searchMattersSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    searching: false,
  })),

  // Search Matters Cloud Start and Failure
  on(searchMattersCloudStart, searchMattersCloudFailure, (state) => ({
    ...state,
  })),

  // Search Matters Cloud Success
  on(searchMattersCloudSuccess, (state, { payload }) => {
    const isAdvancedSearch = payload.searchMode === AdvancedSearchMode.AdvancedSearch;
    const updatedState = isAdvancedSearch
      ? { ...state }
      : {
          ...state,
          filterBy: { ...state.filterBy, idList: payload.data as string[] },
        };
    return {
      ...updatedState,
    };
  }),

  // Load Matters Search Start
  on(loadMattersSearchStart, (state) => ({
    ...state,
  })),

  // Load Matters Search Success
  on(loadMattersSearchSuccess, (state, { payload }) => ({
    ...state,
    ...(payload ? payload : {}),
    loaded: true,
  })),

  // Search Save DB Start
  on(searchSaveDbStart, (state, { payload }) => ({
    ...state,
    ...payload,
    dbSaveStatus: 'start',
  })),

  // Search Save DB Success
  on(searchSaveDbSuccess, (state, { payload }) => ({
    ...state,
    dbSaveStatus: payload,
    loaded: true,
  })),

  // Search Save DB Failure
  on(searchSaveDbFailure, (state, { payload }) => ({
    ...state,
    dbSaveStatus: payload,
  }))
);

// Helper Functions
export const extractMatterSearch = (searchState: State): IMatterSearchState =>
  pick(searchState, ['groupBy', 'filterBy', 'searchText', 'advancedSearch']);

export const selectMatterSearch = (state: State) => extractMatterSearch(state);
export const selectSearching = (state: State) => state.searching;
export const selectLoaded = (state: State) => state.loaded;
