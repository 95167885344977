import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { DialogService } from '../dialog/dialog.service';
import { TimeEntryValidationService } from '@app/shared/services/guard/time-entry-validation.service';
import { selectFeeTimerCurrentTimeElapsedState } from '@app/features/fee-timer/store/selectors';
import { CanComponentDeactivate } from '@app/shared/models';
import * as timeFeeActions from '@app/features/+time-fee-ledger/store/actions/time-fee/index';

@Injectable()
export class CanDeactivateTimeEntryGuardService  {
  timeElapsedState$: Observable<string>;
  private _feeTimerSub: Subscription;
  private _hasAnyUnsavedTimeEntry: boolean;

  constructor(
    private _store: Store<any>,
    private _timeEntryValidation: TimeEntryValidationService,
    private _dialogSvc: DialogService,
    private _router: Router
  ) {
    // current time elapsed state
    this.timeElapsedState$ = this._store.pipe(select(selectFeeTimerCurrentTimeElapsedState));
    this._feeTimerSub = this.timeElapsedState$.subscribe((timeElapsedState) => {
      this._hasAnyUnsavedTimeEntry = this._timeEntryValidation.hasAnyUnsavedEntry(timeElapsedState);
    });
  }

  canDeactivate(
    component: CanComponentDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ) {
    this._store.dispatch(new timeFeeActions.TimeFeeReset(0));
    return component.canDeactivate
      ? component.canDeactivate(route, state, nextState)
      : this._timeEntryValidation.canDeactivate(
          this._hasAnyUnsavedTimeEntry,
          this._store,
          this._router,
          this._dialogSvc,
          state,
          nextState
        );
  }
}
