import { Directive, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { GridApi, NavigateToNextCellParams, TabToNextCellParams } from '@ag-grid-community/core';
import { CellPosition } from '@ag-grid-community/core';
import { AggridKeyboardCode } from '@app/shared/models';

@Directive({
  selector: '[scAgGridFocus]',
})
export class AgGridFocusDirective implements OnInit {
  @Input() tabindex: number;
  @Input() agGridApi: GridApi;

  private _agGridElm: any;
  @Input('scAgGridFocus')
  set agGridElm(value: any) {
    if (value) {
      this._agGridElm = value;
    }
  }

  @Input('scAgGridFocusRowIndex') rowIndex: number;
  @Input('scAgGridFocusColIndex') colIndex: number;

  @Input() overwriteNavigateToNextCell: boolean;
  @Input() overwriteTabToNextCell: boolean;

  @Output() onKeyboardEnterPressed = new EventEmitter<any>();
  @Output() onKeyboardPressed = new EventEmitter<KeyboardEvent>();

  @HostBinding('tabindex')
  get tabindexNumber(): number {
    return this.tabindex;
  }

  @HostBinding('style.outline')
  get outlineStyle(): string {
    return 'none';
  }

  get navigateToNextCell(): (params: NavigateToNextCellParams) => CellPosition | null {
    return (params: NavigateToNextCellParams) => {
      const previousCell = params.previousCellPosition;
      const suggestedNextCell = params.nextCellPosition;
      let nextRowIndex: number;
      switch (params.key) {
        case AggridKeyboardCode['up']:
          this.agGridApi.deselectAll();
          nextRowIndex = previousCell.rowIndex - 1;
          if (nextRowIndex < 0) {
            return null;
          } else {
            return {
              rowIndex: nextRowIndex,
              column: this.agGridApi.getAllDisplayedColumns()[this.colIndex],
              rowPinned: null
            };
          }
        case AggridKeyboardCode['down']:
          this.agGridApi.deselectAll();
          nextRowIndex = previousCell.rowIndex + 1;
          const renderedRowCount = this.agGridApi.getDisplayedRowCount();
          if (nextRowIndex >= renderedRowCount) {
            return null;
          } else {
            return {
              rowIndex: nextRowIndex,
              column: this.agGridApi.getAllDisplayedColumns()[this.colIndex],
              rowPinned: null
            };
          }
        case AggridKeyboardCode['left']:
        case AggridKeyboardCode['right']:
          return suggestedNextCell;
        default:
          return null;
      }
    };
  }


  get tabToNextCell(): (params: TabToNextCellParams) => CellPosition | null {
    return (params: TabToNextCellParams) => {
      const previousCell = params.previousCellPosition;
      const lastRowIndex = previousCell.rowIndex;
      const nextRowIndex = params.backwards ? lastRowIndex - 1 : lastRowIndex + 1;
      const renderedRowCount = this.agGridApi.getDisplayedRowCount();

      if (nextRowIndex < 0 || nextRowIndex >= renderedRowCount) {
        this.agGridApi.clearFocusedCell();
        return null;
      }

      this.agGridApi.deselectAll();
      return {
        rowIndex: nextRowIndex,
        column: this.agGridApi.getAllDisplayedColumns()[this.colIndex],
        rowPinned: null
      };

    };
  }


  @HostListener('focus', ['$event'])
  public handleFocusOnAgGrid(event: any): void {
    if (!this.agGridApi || (!!event.relatedTarget && event.relatedTarget.className.endsWith('ag-cell-focus'))) {
      return;
    }

    if (!this.shouldFocusCell) {
      this.shouldFocusCell = true;
      return;
    }

    if (!!this.agGridApi.getDisplayedRowCount() && this.rowIndex < this.agGridApi.getDisplayedRowCount()) {
      this.agGridApi.ensureIndexVisible(this.rowIndex);
    }

    const specificColumn = this.agGridApi.getAllDisplayedColumns()[this.colIndex];
    this.agGridApi.ensureColumnVisible(specificColumn);
    this.agGridApi.setFocusedCell(this.rowIndex, specificColumn);
  }

  @HostListener('keydown', ['$event'])
  public handleKeydownOnAgGrid(event: KeyboardEvent): void {
    const eventKey = event.key;
    switch (eventKey) {
      case AggridKeyboardCode['enter']:
        this.onKeyboardEnterPressed.emit();
        break;

      case AggridKeyboardCode['escape']:
        break;

      default:
        break;
    }

    this.onKeyboardPressed.emit(event);
    return;
  }

  private shouldFocusCell = true;

  constructor() {}

  ngOnInit(): void {
    if (this.agGridApi && !this.overwriteNavigateToNextCell) {
      this.agGridApi.updateGridOptions({navigateToNextCell: this.navigateToNextCell});
    }

    if (this.agGridApi && !this.overwriteTabToNextCell) {
      this.agGridApi.updateGridOptions({tabToNextCell : this.tabToNextCell});
    }
  }
}
