<form [formGroup]="recipientSearchForm" class="x-toolbar-actions x-toolbar-col x-toolbar-actions-100" role="toolbar">
  <!-- <h6 *ngIf="!hideLabel" class="x-toolbar-text col"><strong>Select Recipient</strong></h6> -->
  <sc-input-with-icon-debounce
    iconName="magnifier-grid-20"
    [delay]="0"
    [autofocus]="autofocus"
    placeholder="Search recipients..."
    formControlName="searchText"
  ></sc-input-with-icon-debounce>
</form>
