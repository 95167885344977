import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, from, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { IPerson } from '@app/shared/models';
import * as cardDetailsActions from '../actions/card-details';
import { CalcService, PersonUtilsService, SpecialFeeService } from '@app/shared/services';
import { SpecialRate } from '@app/features/+time-fee-ledger/models';
import {
  selectActivePersonId,
  selectCardDetailsInvalidControls,
  selectCardDetailsRelatedMatters,
  selectFormValue,
  selectIsCardDetailsModified,
  selectPersonList,
  selectSelectedCardId,
} from '../selectors';
import {
  CardAddressService,
  CardDetailsService,
  CardListStorageService,
  CardPersonDetailsService,
} from '@app/features/+card/services';
import { CardErrors, ECardType, ICardRelatedMatter } from '@app/features/+card/models';
import * as cardListActions from '@app/features/+card/store/actions/card-list';
import { AppApiService } from '@app/core/api';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, EventBusService, PlatformService } from '@app/core/services';
import { ESiriusEvents } from '@app/core/models';
import { SiriusError } from '@app/features/error-handler/interfaces/error-handler.interfaces';
import * as leapAppActions from '@app/features/matter-addin/store/action/leap-app.action';
import { ViewLeapAppLocationId } from '@app/features/matter-addin/models';
import { PersonService } from '@app/features/+person/services/person.service';

@Injectable()
export class CardDetailsEffect {

  loadExistingCard$: any = createEffect(() => this.actions$.pipe(
    ofType<cardDetailsActions.LoadExistingCard>(cardDetailsActions.CardDetailsActionTypes.LOAD_EXISTING_CARD),
    filter(() => !!this.platformService.isBrowser),
    switchMap((action) =>
      from(this.cardListStorageSvc.get(action.payload.id)).pipe(map((cardEntry) => ({ cardEntry, ...action.payload })))
    ),
    filter((data) => !!data && !!data.cardEntry),
    mergeMap((data) => {
      const { cardEntry, itemDetails } = data;
      return [
        new cardListActions.CardListMetaSaveDbStart({ selectedCardId: cardEntry.cardId }),
        new cardDetailsActions.LoadCardDetailsStart({ id: cardEntry.cardId, itemDetails }),
      ];
    })
  ));


  selectExistingPerson$ = createEffect(() => this.actions$.pipe(
    ofType<cardDetailsActions.SelectExistingPerson>(cardDetailsActions.CardDetailsActionTypes.SELECT_EXISTING_PERSON),
    withLatestFrom(
      this.store.pipe(select(selectPersonList)),
      this.store.pipe(select(selectActivePersonId)),
      (action, personList, activePersonId) => ({
        ...action.payload,
        personList,
        activePersonId,
      })
    ),
    switchMap((data) => {
      const { id, itemDetails, personList, activePersonId } = data;
      // check whether the selected person is in the card
      if (!!personList && !!personList.find((p) => p.__id === id)) {
        return throwError(() =>
          new SiriusError({
            type: 'error',
            title: 'Failure',
            message: 'The selected person is already in the card.',
          })
        );
      }
      const details$ = !!itemDetails ? of(itemDetails) : this.personSvc.getPerson(id);
      return details$.pipe(
        mergeMap((res) => {
          const newPerson = this.utilsSvc.createPerson(res);
          const list = personList.map((p) => {
            if (p.__personId === activePersonId) {
              return newPerson;
            } else {
              return p;
            }
          });
          return [new cardDetailsActions.UpdatePersonList({ list, id: newPerson.__id })];
        })
      );
    })
  ));


  loadCardDetailsStart$: any = createEffect(() => this.actions$.pipe(
    ofType<cardDetailsActions.LoadCardDetailsStart>(cardDetailsActions.CardDetailsActionTypes.LOAD_CARD_DETAILS_START),
    switchMap((action) => {
      const { id, itemDetails } = action.payload;
      const loadCard = !!itemDetails ? of(itemDetails) : this.cardDetailsSvc.loadCard(id);
      const loadSpecialRatesForCard = this.specialFeeSvc.getSpecialRatesForCard(id);
      const userTitle = this.calcSvc.queryCardCustomDescription(['title', 'dearFormal', 'dearInformal'], id);
      const loadCardRelatedMatters = this.cardDetailsSvc.getMatters(id);
      return forkJoin([loadCard, loadSpecialRatesForCard, userTitle, loadCardRelatedMatters]).pipe(
        mergeMap((results: [any, SpecialRate[], any, ICardRelatedMatter[]]) => {
          // refactor the data from API before storing into ngrx/store
          const cardDetails = {
            ...results[0],
            autoTitle: results[2].title.trim() || '',
            autoDearFormal: results[2].dearFormal.trim() || '',
            autoDearInformal: results[2].dearInformal.trim() || '',
          };
          const card = this.cardDetailsSvc.createFormValue(cardDetails);
          const locationId = ViewLeapAppLocationId.CardDetails;
          const detailsSucceedAction = new cardDetailsActions.LoadCardDetailsSucceed({
            data: card,
            specialRates: results[1],
            relatedMatters: results[3],
          });
          return !!locationId
            ? [detailsSucceedAction, new leapAppActions.GetCardPageLeapApps({ viewId: locationId })]
            : [detailsSucceedAction];
        }),
        catchError((error) => {
          this.store.dispatch(new cardDetailsActions.LoadCardDetailsFail({ error }));
          // re-throw error and let error-handler.service to handle this type of error
          return throwError(() =>
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: 'Unable to load card details.',
            })
          );
        })
      );
    })
  ));


  addPerson$: any = createEffect(() => this.actions$.pipe(
    ofType(cardDetailsActions.CardDetailsActionTypes.ADD_PERSON),
    withLatestFrom(this.store.pipe(select(selectPersonList))),
    mergeMap(([action, personList]: [cardDetailsActions.AddPerson, IPerson[]]) => {
      const newPerson = this.utilsSvc.createPerson(action.payload.data);
      const list = [...personList, newPerson];
      return [new cardDetailsActions.UpdatePersonList({ list, id: newPerson.__id })];
    })
  ));


  removePerson$: any = createEffect(() => this.actions$.pipe(
    ofType<cardDetailsActions.RemovePerson>(cardDetailsActions.CardDetailsActionTypes.REMOVE_PERSON),
    withLatestFrom(this.store.pipe(select(selectPersonList)), (action, personList) => ({
      ...action.payload,
      personList,
    })),
    tap((data) => {
      const { id, shouldRenewLeapCalcInform, personList } = data;
      this._eventBus.emit({
        name: ESiriusEvents.ShowDialog,
        value: {
          type: 'confirm',
          message: 'Delete now?',
          showCancel: true,
          actionText: this._translateSvc.instant('Card.Delete.Confirm.Action.Text'),
          closeText: this._translateSvc.instant('Card.Delete.Confirm.Close.Text'),
          onClose: (confirmed: boolean) => {
            if (confirmed && !!personList) {
              const list = personList.filter((person) => person.__id !== id);
              const firstPersonId = list.length > 0 ? list[0].__id : null;
              this.store.dispatch(new cardDetailsActions.UpdatePersonList({ list, id: firstPersonId }));
              // check whether we should update the LeapCalcInform
              if (shouldRenewLeapCalcInform) {
                this.store.dispatch(new cardDetailsActions.GetLetterLeapCalcInform(null));
              }
            }
          },
        },
      });
    })
  ), { dispatch: false });


  changeCardType$ = createEffect(() => this.actions$.pipe(
    ofType<cardDetailsActions.ChangeCardType>(cardDetailsActions.CardDetailsActionTypes.CHANGE_CARD_TYPE),
    withLatestFrom(this.store.pipe(select(selectFormValue)), (action, formValue) => ({
      cardType: action.payload.cardType,
      formValue,
    })),
    mergeMap((data) => {
      const { cardType, formValue } = data;
      let actions: any[] = [];
      if (cardType === ECardType.People) {
        // when we switch card type to 'People', ensure at least an empty person is in the list for user to fill in inform
        if (!formValue.personList || formValue.personList.length === 0) {
          actions = [new cardDetailsActions.AddPerson({ data: {} })];
        }
      }
      actions = [new cardDetailsActions.UpdateCardType({ type: cardType }), ...actions];
      return actions;
    })
  ));


  saveDetails$: any = createEffect(() => this.actions$.pipe(
    ofType(cardDetailsActions.CardDetailsActionTypes.SAVE),
    withLatestFrom(
      this.store.pipe(select(selectCardDetailsInvalidControls)),
      this.store.pipe(select(selectFormValue)),
      this.store.pipe(select(selectIsCardDetailsModified)),
      of(this.authSvc.userId)
    ),
    switchMap(
      ([action, invalidControls, formValue, isCardDetailsModified, userId]: [
        cardDetailsActions.Save,
        string[],
        any,
        boolean,
        string
      ]) => {
        // check if there is any validation error
        if (!!invalidControls && invalidControls.length > 0) {
          return [new cardDetailsActions.SaveFail({ error: CardErrors[invalidControls[0]] || CardErrors['default'] })];
        }

        // check whether the cardDetails is modified
        if (isCardDetailsModified) {
          const properFormValue = this.cardDetailsSvc.formatFormValue(formValue, userId);

          return this.cardDetailsSvc.save(properFormValue).pipe(
            mergeMap(() => [
              new cardDetailsActions.SaveSucceed({
                message: this._translateSvc.instant('Matter.Card.Update.Success.Message'),
              }),
            ]),
            catchError((errorResponse) => {
              let actions: any[];
              const invalidControl = errorResponse.error.invalidControl;
              if (!!invalidControl && CardErrors[invalidControl]) {
                actions = [
                  new cardDetailsActions.AddCardDetailsInvalidControls({ names: [invalidControl] }),
                  new cardDetailsActions.SaveFail({ error: CardErrors[invalidControl] }),
                ];
              } else {
                actions = [
                  new cardDetailsActions.SaveFail({ message: this._translateSvc.instant('Card.Save.Error.Message') }),
                ];
              }

              return actions;
            })
          );
        } else {
          return of(new cardDetailsActions.SetCardProcessingStatus({ processing: false }));
        }
      }
    )
  ));


  deleteCardRequire$: any = createEffect(() => this.actions$.pipe(
    ofType(cardDetailsActions.CardDetailsActionTypes.REQUIRE_DELETE),
    map((action: cardDetailsActions.RequireDelete) => action.payload.card),
    tap((card: any) => {
      this._eventBus.emit({
        name: ESiriusEvents.ShowDialog,
        value: {
          type: 'confirm',
          title: this._translateSvc.instant('Card.Delete.Confirm.Title'),
          message: this._translateSvc.instant('Card.Delete.Confirm.Message'),
          showCancel: true,
          actionText: this._translateSvc.instant('Card.Delete.Confirm.Action.Text'),
          closeText: this._translateSvc.instant('Card.Delete.Confirm.Close.Text'),
          onClose: (confirmed: boolean) => {
            if (confirmed) {
              this.store.dispatch(new cardDetailsActions.Delete({ card }));
            }
          },
        },
      });
    })
  ), { dispatch: false });


  deleteCard$: any = createEffect(() => this.actions$.pipe(
    ofType(cardDetailsActions.CardDetailsActionTypes.DELETE),
    map((action: cardDetailsActions.Delete) => action.payload.card),
    mergeMap((card: any) => this.cardDetailsSvc.delete(card).pipe(
        mergeMap(() => [
          new cardDetailsActions.DeleteSucceed({
            message: this._translateSvc.instant('Matter.Card.Remove.Success.Message'),
          }),
        ]),
        catchError((error) => of(new cardDetailsActions.DeleteFail({ error })))
      ))
  ));


  deleteCardFail$: any = createEffect(() => this.actions$.pipe(
    ofType<cardDetailsActions.DeleteFail>(cardDetailsActions.CardDetailsActionTypes.DELETE_FAIL),
    filter(() => !!this.platformService.isBrowser),
    withLatestFrom(this.store.pipe(select(selectCardDetailsRelatedMatters)), (action, relatedMatters) => [
      action.payload,
      relatedMatters,
    ]),
    switchMap(([payload, relatedMatters]: [any, any[]]) => {
      const matterIds = payload?.error?.error?.ListOfMatterIds || [];
      if (matterIds.length > 0) {
        const listOfMatterIds =
          matterIds.length >= 6 // 6 is the number used in Desktop (Smyth 11/09/2018)
            ? matterIds.slice(0, 6)
            : matterIds;

        // check any card pull before has matters information.
        if (relatedMatters.length > 0) {
          const foundMatterList = relatedMatters.filter((r) => listOfMatterIds.includes(r.matterId));
          if (foundMatterList.length > 0 && foundMatterList.length === listOfMatterIds.length) {
            return forkJoin([of(payload), of(foundMatterList)]);
          }
        }

        // read from api.
        const cardId = payload.error?.error?.CardId;
        if (cardId) {
          return forkJoin([of(payload), this.cardDetailsSvc.getMatters(cardId)]);
        }
      }

      return forkJoin([of(payload), of([])]);
    }),
    mergeMap(([payload, matterList]) => {
      const rawRelatedListMatterIds = payload?.error?.error.ListOfMatterIds || [];
      let errorMessage = this._translateSvc.instant('Card.Delete.Error.Message');

      errorMessage = this._translateSvc.instant('Card.Delete.Error.Matter');
      matterList.filter(Boolean).forEach((matter, idx) => {
        const fileNumber = matter && (matter.fileNumber || matter.filenumber);
        const description = matter && (matter.firstDescription || matter.firstdesc);

        if (fileNumber && description) {
          errorMessage += `\n${fileNumber}: ${description}`;
        } else if (fileNumber) {
          errorMessage += `\n${fileNumber}`;
        } else if (description) {
          errorMessage += `\n${description}`;
        } else {
          errorMessage += `\n${rawRelatedListMatterIds[idx]}`;
        }
      });

      // re-throw error and let error-handler.service to handle this type of error
      return throwError(() =>
        new SiriusError({
          type: 'error',
          title: 'Error',
          message: errorMessage,
          actionBtnText: 'Close',
        })
      );
    })
  ), { dispatch: false });


  undeleteCardRequire$: any = createEffect(() => this.actions$.pipe(
    ofType(cardDetailsActions.CardDetailsActionTypes.REQUIRE_UNDELETE),
    map((action: cardDetailsActions.RequireUndelete) => action.payload.cardId),
    tap((cardId: string) => {
      this._eventBus.emit({
        name: ESiriusEvents.ShowDialog,
        value: {
          type: 'confirm',
          title: this._translateSvc.instant('Card.Undelete.Confirm.Title'),
          message: this._translateSvc.instant('Card.Undelete.Confirm.Message'),
          showCancel: true,
          actionText: this._translateSvc.instant('Card.Undelete.Confirm.Action.Text'),
          closeText: this._translateSvc.instant('Card.Undelete.Confirm.Close.Text'),
          onClose: (confirmed: boolean) => {
            if (confirmed) {
              this.store.dispatch(new cardDetailsActions.Undelete({ cardId }));
            }
          },
        },
      });
    })
  ), { dispatch: false });


  undeleteCard$: any = createEffect(() => this.actions$.pipe(
    ofType(cardDetailsActions.CardDetailsActionTypes.UNDELETE),
    map((action: cardDetailsActions.Undelete) => action.payload.cardId),
    mergeMap((cardId: string) => this.cardDetailsSvc.undelete(cardId).pipe(
        mergeMap(() => [
          new cardDetailsActions.UndeleteSucceed({
            message: this._translateSvc.instant('Card.Undelete.Success.Message'),
          }),
        ]),
        catchError((error) => of(new cardDetailsActions.UndeleteFail({ error })))
      ))
  ));


  succeed$: any = createEffect(() => this.actions$.pipe(
    ofType(
      cardDetailsActions.CardDetailsActionTypes.SAVE_SUCCEED,
      cardDetailsActions.CardDetailsActionTypes.DELETE_SUCCEED,
      cardDetailsActions.CardDetailsActionTypes.UNDELETE_SUCCEED
    ),
    map((action: any) => action.payload),
    tap((payload) => {
      if (!!payload && payload.message) {
        this._eventBus.emit({
          name: ESiriusEvents.ShowToastr,
          value: {
            type: 'success',
            title: 'Success',
            message: payload.message,
          },
        });
      }
    })
  ), { dispatch: false });


  getLetterLeapCalcInform$: any = createEffect(() => this.actions$.pipe(
    ofType(cardDetailsActions.CardDetailsActionTypes.GET_LETTER_LEAP_CALC_INFORM),
    withLatestFrom(this.store.pipe(select(selectFormValue))),
    switchMap(([action, formValue]: [cardDetailsActions.GetLetterLeapCalcInform, any]) => {
      formValue = {
        ...formValue,
        personList: formValue.personList.filter((person) => !this.personDetailsSvc.isEmpty(person)),
      };
      return this.calcSvc.queryCardCustomDescription(['title', 'dearFormal', 'dearInformal'], null, formValue).pipe(
        mergeMap((res: any) => {
          const inform = {
            title: formValue.useDefaultTitle ? res.title.trim() : formValue.title,
            dear: formValue.useDefaultDear
              ? formValue.useFriendlyDear
                ? res.dearInformal.trim()
                : res.dearFormal.trim()
              : formValue.dear,
            autoTitle: res.title.trim() || '',
            autoDearFormal: res.dearFormal.trim() || '',
            autoDearInformal: res.dearInformal.trim() || '',
          };
          return [new cardDetailsActions.UpdateLetterLeapCalcInform({ inform })];
        })
      );
    })
  ));


  manageTrustees$: any = createEffect(() => this.actions$.pipe(
    ofType(cardDetailsActions.CardDetailsActionTypes.MANAGE_TRUSTEES),
    withLatestFrom(this.store.pipe(select(selectSelectedCardId)), (action, selectedCardId) => selectedCardId),
    switchMap((selectedCardId) => this.cardListStorageSvc.get(selectedCardId)),
    tap((card) => {
      const isCardDeleted = card.deleteCode && card.deleteCode === 1;
      this.appApiSvc.navigate({
        path: [{ outlets: { overlay: ['card', 'card-trustees'] } }],
        query: { isCardDeleted },
      });
    })
  ), { dispatch: false });


  saveCardFail$: any = createEffect(() => this.actions$.pipe(
    ofType<cardDetailsActions.SaveFail>(cardDetailsActions.CardDetailsActionTypes.SAVE_FAIL),
    switchMap((action: cardDetailsActions.SaveFail) => {
      if (!!action.payload) {
        let message: string;
        if (!!action.payload.error) {
          message = action.payload.error.message || this._translateSvc.instant('Card.Save.Error.Message');
        } else {
          message = (action.payload && action.payload.message) || this._translateSvc.instant('Card.Save.Error.Message');
        }
        // re-throw error and let error-handler.service to handle this type of error
        return throwError(() =>
          new SiriusError({
            type: 'error',
            title: 'Card Save Error',
            message,
          })
        );
      }
    })
  ), { dispatch: false });


  undeleteCardFail$: any = createEffect(() => this.actions$.pipe(
    ofType(cardDetailsActions.CardDetailsActionTypes.UNDELETE_FAIL),
    switchMap(() =>
      // re-throw error and let error-handler.service to handle this type of error
       throwError(() =>
        new SiriusError({
          type: 'error',
          title: 'Failure',
          message: this._translateSvc.instant('Card.Undelete.Error.Message'),
        })
      )
    )
  ), { dispatch: false });


  resetCard$: any = createEffect(() => this.actions$.pipe(
    ofType(cardDetailsActions.CardDetailsActionTypes.REQUEST_RESET),
    withLatestFrom(this.store.pipe(select(selectIsCardDetailsModified))),
    filter(([action, isCardDetailsModified]: [cardDetailsActions.RequestReset, boolean]) => !!isCardDetailsModified),
    tap(() => {
      this._eventBus.emit({
        name: ESiriusEvents.ShowDialog,
        value: {
          type: 'confirm',
          title: this._translateSvc.instant('Card.Reset.Confirm.Title'),
          message: this._translateSvc.instant('Card.Reset.Confirm.Message'),
          showCancel: true,
          actionText: this._translateSvc.instant('Card.Reset.Confirm.Action.Text'),
          closeText: this._translateSvc.instant('Card.Reset.Confirm.Close.Text'),
          onClose: (discardConfirmed) => {
            if (discardConfirmed) {
              this.store.dispatch(new cardDetailsActions.Reset(null));
            }
          },
        },
      });
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private cardDetailsSvc: CardDetailsService,
    private cardListStorageSvc: CardListStorageService,
    private personDetailsSvc: CardPersonDetailsService,
    private personSvc: PersonService,
    private platformService: PlatformService,
    private _translateSvc: TranslateService,
    private specialFeeSvc: SpecialFeeService,
    private appApiSvc: AppApiService,
    private utilsSvc: PersonUtilsService,
    private _eventBus: EventBusService,
    private calcSvc: CalcService,
    private authSvc: AuthService
  ) {}
}
