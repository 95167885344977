import { ActionReducerMap, ActionReducer, MetaReducer } from '@ngrx/store';
import { storeLogger } from 'ngrx-store-logger';
import { environment } from '@env/environment';
import * as fromRouter from '@ngrx/router-store';
import * as fromNotification from '@app/features/+notification/store/reducers/notification.reducer';

const isServer = !('window' in globalThis) || window?.navigator?.userAgent === 'node';

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<any> = {
  router: fromRouter.routerReducer,
  notification: fromNotification.reducer,
};

// default, no options
export const logger = (reducer: ActionReducer<any>): any => storeLogger()(reducer);

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<any>[] = !environment.production && !isServer ? [logger] : [];
