import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EPermissionType } from '@app/core/constants';
import { Observable, Subject } from 'rxjs';
import { map as rxjsMap } from 'rxjs/operators';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { BrandService } from '@app/core/services';
import { DialogService } from '@app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { LinkLabel } from '@app/core/models';
import { environment } from '@env/environment';

@Component({
  selector: 'sc-recurring-matter-toolbar',
  templateUrl: './recurring-matter-toolbar.component.html',
})
export class RecurringMatterToolbarComponent implements OnInit, OnDestroy {
  @Input() recurringMatterId: string;

  permissionEnum: typeof EPermissionType = EPermissionType;
  isSmallScreen$: Observable<boolean>;
  titlexLive: boolean;
  isTitleX: boolean;
  detailsAndCorrenpondence: string[];
  calendar: string[];
  financialSummary: string[];
  timeAndFees: string[];
  officeAccountingDropdown: LinkLabel[];
  trustFundsDropdown: LinkLabel[];
  moreMenuDropdown: LinkLabel[];

  private unsub = new Subject<void>();

  get calendarTabName(): string {
    return BrandService.isLeap() ? 'Calendar & Tasks' : 'Schedule';
  }

  constructor(
    private _brandSvc: BrandService,
    private _breakpointObserver: BreakpointObserver,
    private _dialogSvc: DialogService,
    private _translateSvc: TranslateService
  ) {
    this.titlexLive = environment.production && environment.config.brand.leapcalc === 'titlex';
    this.isTitleX = this._brandSvc.isTitleX;
  }

  ngOnInit() {
    this.setupSubscriptions();
    this.setupToolbar();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  showWarning() {
    this._dialogSvc.warning({ message: this._translateSvc.instant('RecurringMatter.Warning') });
  }

  private setupToolbar() {
    this.calendar = ['/recurring-matters', this.recurringMatterId, 'schedule'];
    this.detailsAndCorrenpondence = ['/recurring-matters', this.recurringMatterId];
    this.timeAndFees = ['/recurring-matters', this.recurringMatterId, 'fee'];

    this.officeAccountingDropdown = [
      {
        label: 'PaymentDebtors.Label',
        isNonRouteTab: true,
      },
      {
        label: 'Credit.Menu.CreditLedger',
        hide: this.titlexLive,
        isNonRouteTab: true,
      },
      {
        label: 'AnticipatedPayment.Label',
        hide: this.titlexLive,
        isNonRouteTab: true,
      },
      {
        link: ['/recurring-matters', this.recurringMatterId, 'cost-recoveries'],
        label: 'CostRecovery.Label',
      },
    ];

    this.trustFundsDropdown = [
      {
        label: 'Trust.General.Ledger.Label',
        isNonRouteTab: true,
      },
      {
        label: 'Trust.ProtectedFund.Ledger.Label.Full',
        hide: this.isTitleX || this.titlexLive,
        isNonRouteTab: true,
      },
      {
        label: 'Trust.Investment.Ledger.Label',
        hide: this.titlexLive,
        isNonRouteTab: true,
      },
    ];

    this.moreMenuDropdown = [
      {
        isHeader: true,
        label: 'Accounting.Office.Label',
      },
      {
        label: 'PaymentDebtors.Label',
        isNonRouteTab: true,
      },
      {
        label: 'Credit.Menu.CreditLedger',
        hide: this.titlexLive,
        isNonRouteTab: true,
      },
      {
        label: 'AnticipatedPayment.Label',
        hide: this.titlexLive,
        isNonRouteTab: true,
      },
      {
        link: ['/recurring-matters', this.recurringMatterId, 'cost-recoveries'],
        label: 'CostRecovery.Label',
      },
      {
        isDivider: true,
      },
      {
        isHeader: true,
        label: 'Trust.General.Fund.MenuGroup',
      },
      {
        label: 'Trust.General.Ledger.Label',
        isNonRouteTab: true,
      },
      {
        label: 'Trust.ProtectedFund.Ledger.Label.Full',
        hide: this.titlexLive || this.isTitleX,
        isNonRouteTab: true,
      },
      {
        label: 'Trust.Investment.Ledger.Label',
        hide: this.titlexLive,
        isNonRouteTab: true,
      },
    ];
  }

  private setupSubscriptions() {
    this.isSmallScreen$ = this._breakpointObserver
      .observe(['(max-width: 1380px)'])
      .pipe(rxjsMap((state: BreakpointState) => state.matches));
  }
}
