import * as cardListModalActions from '../actions/card-list-modal';
import * as cardModalActions from '../actions/card-modal';
import { ECardFilterType, ICardListEntry } from '@app/features/+card/models';

export interface State {
  cardEntriesProcessing: boolean;
  cardEntries: ICardListEntry[];
  initialSelected: ICardListEntry[];
  cardFilterType: ECardFilterType;
  selectedCardFilterType: string;
  cardGuidsForFiltering: { includeAllCardOption: boolean; cardGuids: string[] };
}

export const INITIAL_STATE: State = {
  cardEntriesProcessing: false,
  cardEntries: [],
  initialSelected: [],
  cardFilterType: null,
  selectedCardFilterType: null,
  cardGuidsForFiltering: { includeAllCardOption: null, cardGuids: [] },
};

export const reducer = (
  state = INITIAL_STATE,
  action: cardListModalActions.CardListModalActions | cardModalActions.CardModalActions
) => {
  switch (action.type) {
    case cardListModalActions.CardListModalActionTypes.SET_UP_CARD_ENTRIES:
      return {
        ...state,
        cardEntriesProcessing: true,
      };

    case cardListModalActions.CardListModalActionTypes.SET_INITIAL_SELECTED:
      const selectedCardEntriesNotInCardList =
        state.initialSelected.filter((cardEntry) => !state.cardEntries.find((c) => c.cardId === cardEntry.cardId)) ||
        [];

      return {
        ...state,
        initialSelected: [...selectedCardEntriesNotInCardList, ...action.payload.cardEntries],
      };

    case cardListModalActions.CardListModalActionTypes.STORE_CARD_ENTRIES:
      return {
        ...state,
        cardEntries: action.payload.cardEntries || [],
        cardEntriesProcessing: false,
      };

    case cardListModalActions.CardListModalActionTypes.DESTROY:
      return {
        ...state,
        initialSelected: [],
        cardFilterType: null,
        selectedCardFilterType: null,
        cardEntriesProcessing: false,
        cardGuidsForFiltering: { includeAllCardOption: null, cardGuids: [] },
      };

    case cardListModalActions.CardListModalActionTypes.SET_CARD_FILTER_TYPE:
      return {
        ...state,
        cardFilterType: action.payload.type,
      };

    case cardListModalActions.CardListModalActionTypes.SET_CARD_GUIDS_FOR_FILTERING:
      return {
        ...state,
        cardGuidsForFiltering: {
          cardGuids: action.payload.ids,
          includeAllCardOption: action.payload.includeAllCardOption,
        },
      };

    case cardListModalActions.CardListModalActionTypes.SET_SELECTED_CARD_FILTER_TYPE:
      return {
        ...state,
        selectedCardFilterType: action.payload.type,
      };

    default:
      return state;
  }
};

export const getCardEntries = (state: State) => state.cardEntries;
export const getInitialSelected = (state: State) => state.initialSelected;
export const getCardEntriesProcessing = (state: State) => state.cardEntriesProcessing;
export const getCardFilterType = (state: State) => state.cardFilterType;
export const getSelectedCardFilterType = (state: State) => state.selectedCardFilterType;
export const getCardGuidsForFiltering = (state: State) => state.cardGuidsForFiltering;
