<div *ngIf="!hasUrlToPreview; else preview" class="d-flex h-100 justify-content-center align-items-center">
  <h5>No Preview Available!</h5>
</div>

<ng-template #preview>
  <img
    *ngIf="!hasError; else error"
    class="img-fluid"
    [src]="previewUrl"
    (error)="onError($event)"
    (load)="imageLoaded()"
  />
</ng-template>
<ng-template #error>
  <div class="d-flex h-100 justify-content-center align-items-center">
    <h5>The server appears has issue to get the image!</h5>
  </div>
</ng-template>
