import { Component, HostBinding, OnDestroy } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-memo-cell',
  templateUrl: './memo-cell.component.html',
})
export class MemoCellComponent implements AgRendererComponent, OnDestroy {
  private _params: any;
  public editForm: FormGroup;
  public isHidden: boolean;
  public rowNumber: number;
  public styleRule: any;
  public label: any;
  public onMemoUpdated: (memo: string) => void;

  private _formSub: Subscription;
  private unsub = new Subject<void>();

  constructor(private _formBuilder: FormBuilder) {
    this.createForm();
  }

  @HostBinding('class')
  classes = 'w-100 x-max-width-320 mt-auto bg-white';
  agInit(params: any): void {
    this._params = params;
    const defaultValue =
      (isFunction(params['getContent']) ? params['getContent'](params.node.data) : params.value) || '';
    this.editForm.patchValue({ memo: defaultValue });
    const isDisabled =
      (isFunction(params['getIsDisabled']) ? params['getIsDisabled'](params.node.data) : false) || false;
    if (isDisabled) {
      this.editForm.controls['memo'].disable();
    } else {
      this.editForm.controls['memo'].enable();
    }

    this.isHidden = (isFunction(params['getIsHidden']) ? params['getIsHidden'](params.node.data) : false) || false;
    this.rowNumber = (isFunction(params['getRowNumber']) ? params['getRowNumber'](params.node.data) : 3) || 3;
    this.styleRule = (isFunction(params['getStyleRule']) ? params['getStyleRule'](params.node.data) : {}) || {};
    this.label = (isFunction(params['getLabel']) ? params['getLabel'](params.node.data) : null);
    this.onMemoUpdated = isFunction(params['onMemoUpdated']) ? params['onMemoUpdated'] : () => {};
  }

  refresh(params: any): boolean {
    return true;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  keydown(event: KeyboardEvent): void {
    event.stopPropagation();
  }

  private createForm(): void {
    this.editForm = this._formBuilder.group({
      memo: '',
    });
    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges(): void {
    this._formSub = this.editForm.controls['memo'].valueChanges
      .pipe(
        distinctUntilChanged(),
        tap((memo) => {
          if (isFunction(this.onMemoUpdated)) {
            this.onMemoUpdated(memo);
          }
        }),
        takeUntil(this.unsub) // important: this operator must be last
      )
      .subscribe();
  }
}
