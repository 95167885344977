import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { fromEvent, merge, Subject, Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { debounceTime, filter } from 'rxjs/operators';
import { PDFSource, PdfViewerComponent as pd2, PDFProgressData } from 'ng2-pdf-viewer';
import { LogService } from '@app/core/services';
import { isEmptyObj } from '../../../../../server/modules/shared/functions/common-util.functions';
import { AuthAgent } from '@leapdev/auth-agent';

@Component({
  selector: 'sc-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styles: [
    `
      ::ng-deep pdf-viewer {
        position: static !important;
      }
    `,
  ],
})
export class PdfViewerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  token: string;

  private _errMsg: string;
  @Input()
  get errMsg() {
    return this._errMsg;
  }
  set errMsg(v: string) {
    if (!!v) {
      this.hasError = true;
      this._errMsg = v;
    }
  }

  @Input()
  set pdfSrc(value: string | Uint8Array | PDFSource) {
    this.hasError = false;
    this.isLoaded = false;
    this.zoom = 1;
    this.page = 1;
    this.totalPages = 1;
    this.tag = uuidv4();

    if (this.stop && !this.stop.closed) {
      this.stop.next(true);
      this.stop.unsubscribe();
      this.stop = new Subject<boolean>();
    }

    this._pdfSrc = value;
    this.objUrl = value;
    this.configPreviewSrc();
  }

  get pdfSrc(): string | Uint8Array | PDFSource {
    return this._pdfSrc;
  }

  get hasSrcToPreview(): boolean {
    if (this.previewSrc && typeof this.previewSrc === 'string') {
      return !!this.previewSrc;
    }

    return !isEmptyObj(this.previewSrc);
  }

  @Output()
  onPdfLoaded = new EventEmitter<boolean>();

  @Output()
  onPdfLoadError = new EventEmitter<any>();

  hasError: boolean;
  originalSize = true;
  zoom = 1;
  fitToPage = true;
  autoresize = false;
  page = 1;
  totalPages = 1;
  isLoaded = false;

  stop = new Subject<boolean>();
  tag: string;
  objUrl: any;

  editingPageNumber = false;
  previewSrc: string | Uint8Array | PDFSource;
  processing: boolean;
  progressValue = 0;
  progressMax = 100;

  get enablePreviousPage(): boolean {
    return this.page > 1;
  }

  get enableNextPage(): boolean {
    return this.page < this.totalPages;
  }

  private _pdfSrc: string | Uint8Array | PDFSource;
  private scrollSub: Subscription;

  constructor(private _log: LogService) {}

  ngOnInit() {}

  @ViewChild(pd2)
  private ng2PdfViewerComponent: pd2;
  @ViewChild('previewDiv', { static: true })
  private previewContainer: ElementRef;
  @ViewChild('nextPageBtn')
  private nextPageBtn: ElementRef;
  @ViewChild('previousPageBtn')
  private previousPageBtn: ElementRef;

  ngAfterViewInit(): void {
    const events = ['wheel'];
    const events$ = events.map((ev) => fromEvent(this.previewContainer.nativeElement, ev));

    this.scrollSub = merge(...events$)
      .pipe(
        debounceTime(100),
        filter(() => !this.hasError && this.hasSrcToPreview)
      )
      .subscribe((e: any) => {
        if (e instanceof WheelEvent) {
          const scPdfViewer = this.getPdfViewerElement();
          const viewerContainer = this.getXFileViewerContainerElement();
          const scrollTop = scPdfViewer.scrollTop;
          const offsetHeight = viewerContainer.offsetHeight;
          const documentHeight = this.ng2PdfViewerComponent.pdfViewer.viewer.scrollHeight;
          const isPageDown = e.deltaY > 0;

          this.scrollToChangePage(scrollTop, offsetHeight, documentHeight, isPageDown);

          return;
        }
      });
  }

  ngOnDestroy(): void {
    if (this.scrollSub && !this.scrollSub.closed) {
      this.scrollSub.unsubscribe();
    }

    this.stop.next(true);
    this.stop.complete();
  }

  onError(err): void {
    this.hasError = true;
    this.onPdfLoadError.emit(err);
    this.processing = false;

    if (typeof err === 'object' && err?.status === 401) {
      this._log.error('PDF Viewer Error (Auth Related) - forcing reauth', err);
      AuthAgent.login();
    } else {
      this._log.error('PDF Viewer Error', err);

    }
  }

  onOriginalSize(): void {
    this.autoresize = false;
    this.originalSize = !this.originalSize;
  }

  onNextPage(): void {
    if (this.enableNextPage) {
      this.page += 1;
    }
  }

  onPreviousPage(): void {
    if (this.enablePreviousPage) {
      this.page -= 1;
    }
  }

  onLoadComplete(pdfData: any): void {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
    this.processing = false;
    this.onPdfLoaded.emit(this.isLoaded);
  }

  onZoomIn(): void {
    this.zoom += 0.25;
  }

  onZoomOut(): void {
    this.zoom -= 0.25;
    if (this.zoom <= 0) {
      this.zoom = 0.25;
    }
  }

  adjustOutofRangeValue(): void {
    if (this.page > this.totalPages) {
      this.page = this.totalPages;
      return;
    }

    if (this.page < 1) {
      this.page = 1;
      return;
    }
  }

  onPdfViewerProgress(data: PDFProgressData): void {
    this.progressValue = Math.min(Math.floor((data.loaded * this.progressMax) / data.total), this.progressMax);
  }

  private scrollToChangePage(
    scrollTop: number,
    containerOffsetHeight: number,
    documentHeight: number,
    isPageDown: boolean
  ): void {
    const currentScrollHeight = scrollTop + containerOffsetHeight;
    const maxDocumentHeight = documentHeight - containerOffsetHeight;

    if (isPageDown) {
      if (currentScrollHeight >= maxDocumentHeight) {
        if (this.enableNextPage) {
          this.nextPageBtn.nativeElement.click();
          this.setScrollPosition(scrollTop); // Set scroll position to avoid jump
        } else {
          const maxScroll = maxDocumentHeight - scrollTop; // Prevent further scrolling beyond the last page
          this.getPdfViewerElement().scrollTop = scrollTop + maxScroll;
        }
      }
    } else {
      if (scrollTop <= 0) {
        if (this.enablePreviousPage) {
          this.previousPageBtn.nativeElement.click();
          this.setScrollPosition(scrollTop); // Set scroll position to avoid jump
        } else {
          this.getPdfViewerElement().scrollTop = 0; // Prevent scrolling above the first page
        }
      }
    }
  }

  private setScrollPosition(position: number): void {
    setTimeout(() => {
      const scPdfViewer = this.getPdfViewerElement();
      scPdfViewer.scrollTop = position;
    }, 0);
  }

  private getXFileViewerContainerElement(): any {
    // access to element with class name 'x-file-viewer-container'.
    return this.ng2PdfViewerComponent.pdfViewer.viewer.parentElement.parentElement.parentElement.parentElement
      .parentElement;
  }

  private getPdfViewerElement(): any {
    // access to element with id scPdfPreview.
    return this.ng2PdfViewerComponent.pdfViewer.viewer.parentElement.parentElement.parentElement.parentElement;
  }

  private configPreviewSrc(): void {
    if (!!this._pdfSrc) {
      this.processing = true;
      if (typeof this._pdfSrc === 'string' && this.token) {
        this.previewSrc = {
          url: this._pdfSrc,
        };

        if (!this._pdfSrc?.toLowerCase().includes('x-amz-security-token')) {
          this.previewSrc = {
            ...this.previewSrc,
            httpHeaders: {
              Authorization: `Bearer ${this.token}`,
            },
          };
        }
      } else {
        this.previewSrc = this._pdfSrc;
      }
    } else {
      this.previewSrc = '';
    }
  }
}
