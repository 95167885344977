import { Action } from '@ngrx/store';
import {
  EPrecedentSearchMode,
  IDuplicatePrecedentParams,
  IMatterTypePrecedents,
  IPrecedent,
  IPrecedentTransaction,
  INewFolderRequest,
} from '@app/features/+precedent/models';

export interface INestedPrecedentsPayload {
  precedentId: string;
  matterTypeId: string;
  isShortcut: boolean;
}

export const LIST_NESTED_PRECEDENTS_START = '[Precedents nested from api] start';
export const LIST_PRECEDENTS_SUCCESS = '[Precedents from api] succcess';
export const LIST_PRECEDENTS_FAILURE = '[Precedents from api] failure';

export const CREATE_READONLY_PRECEDENT_START = '[Create readonly precedent] start';
export const DELETE_PRECEDENT_START = '[Delete precedent] start';
export const UNDELETE_PRECEDENT_START = '[Undelete precedent] start';
export const DUPLICATE_PRECEDENT_START = '[Duplicate precedent] start';

export const PRECEDENT_TRANSACTION_START = '[Precedent transaction] start';
export const PRECEDENT_TRANSACTION_SUCCESS = '[Precedent transaction] success';
export const PRECEDENT_TRANSACTION_FAILURE = '[Precedent transaction] failure';

export const CREATE_NEW_FOLDER_START = '[Create new folder] start';
export const CREATE_NEW_FOLDER_SUCCESS = '[Create new folder] succcess';
export const CREATE_NEW_FOLDER_FAILURE = '[Create new folder] failure';

export class ListNestedPrecedentsStart implements Action {
  readonly type = LIST_NESTED_PRECEDENTS_START;
  constructor(public payload: INestedPrecedentsPayload) {}
}

export class ListPrecedentsSuccess implements Action {
  readonly type = LIST_PRECEDENTS_SUCCESS;
  constructor(public payload: IMatterTypePrecedents) {}
}

export class ListPrecedentsFailure implements Action {
  readonly type = LIST_PRECEDENTS_FAILURE;
  constructor(public payload: any) {}
}

export class CreateReadonlyPrecedentStart implements Action {
  readonly type = CREATE_READONLY_PRECEDENT_START;
  constructor(public payload: IPrecedent) {}
}

export class DeletePrecedentStart implements Action {
  readonly type = DELETE_PRECEDENT_START;
  constructor(public payload: { precedent: IPrecedent; searchMode: EPrecedentSearchMode }) {}
}

export class UndeletePrecedentStart implements Action {
  readonly type = UNDELETE_PRECEDENT_START;
  constructor(public payload: { precedent: IPrecedent; searchMode: EPrecedentSearchMode }) {}
}

export class DuplicatePrecedentStart implements Action {
  readonly type = DUPLICATE_PRECEDENT_START;
  constructor(public payload: { params: IDuplicatePrecedentParams; searchMode: EPrecedentSearchMode }) {}
}

export class PrecedentTransactionStart implements Action {
  readonly type = PRECEDENT_TRANSACTION_START;
  constructor(public payload: IPrecedentTransaction) {}
}

export class PrecedentTransactionSuccess implements Action {
  readonly type = PRECEDENT_TRANSACTION_SUCCESS;
  constructor(public payload: { data: IMatterTypePrecedents; precedent: IPrecedent; type: string }) {}
}

export class PrecedentTransactionFailure implements Action {
  readonly type = PRECEDENT_TRANSACTION_FAILURE;
  constructor(public payload: any) {}
}

export class CreateNewFolderStart implements Action {
  readonly type = CREATE_NEW_FOLDER_START;
  constructor(public payload: INewFolderRequest) {}
}

export class CreateNewFolderSuccess implements Action {
  readonly type = CREATE_NEW_FOLDER_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateNewFolderFailure implements Action {
  readonly type = CREATE_NEW_FOLDER_FAILURE;
  constructor(public payload: any) {}
}

export type PrecedentListApiActions =
  | ListNestedPrecedentsStart
  | ListPrecedentsSuccess
  | ListPrecedentsFailure
  | CreateReadonlyPrecedentStart
  | DeletePrecedentStart
  | UndeletePrecedentStart
  | DuplicatePrecedentStart
  | PrecedentTransactionStart
  | PrecedentTransactionSuccess
  | PrecedentTransactionFailure
  | CreateNewFolderStart
  | CreateNewFolderSuccess
  | CreateNewFolderFailure;
