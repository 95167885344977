import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromCurrentMatter from './current-matter.reducer';
import * as fromContact from './contact.reducer';

export interface State {
  currentMatter: fromCurrentMatter.State;
  contact: fromContact.State;
}

export const initialState: State = {
  currentMatter: fromCurrentMatter.initialState,
  contact: fromContact.initialState,
};

export const reducers: ActionReducerMap<State> = {
  currentMatter: fromCurrentMatter.reducer,
  contact: fromContact.reducer,
};

export const selectMatterDetailsState = createFeatureSelector<State>('matterDetails');
