import * as fromPersonList from './person-list.reducer';
import * as fromPersonDetails from './person-details.reducer';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { AppSlice } from '@app/core/store';

export interface State {
  personList: fromPersonList.State;
  personDetails: fromPersonDetails.State;
}

export const INITIAL_STATE: State = {
  personList: fromPersonList.INITIAL_STATE,
  personDetails: fromPersonDetails.INITIAL_STATE,
};

export const reducers: ActionReducerMap<State> = {
  personList: fromPersonList.reducer,
  personDetails: fromPersonDetails.reducer,
};

export const selectState = createFeatureSelector<State>(AppSlice.Persons);

export const selectPersonListState = createSelector(selectState, (state: State) => state.personList);

export const selectPersonDetailsState = createSelector(selectState, (state: State) => state.personDetails);
