import { createSelector } from '@ngrx/store';

import * as fromTimeModal from '../reducers/time-modal.reducers';
import { TimeFeeState, selectTimeFeeState } from '../reducers';

export const selectTimeModalState = createSelector(selectTimeFeeState, (state: TimeFeeState) => state.timeModal);

export const selectTimeNewWin = createSelector(selectTimeModalState, (state: fromTimeModal.State) =>
  fromTimeModal.selectTimeNewWin(state)
);

export const selectTimeSetupData = createSelector(selectTimeModalState, (state: fromTimeModal.State) =>
  fromTimeModal.selectTimeSetupData(state)
);

export const selectTimePlayTimer = createSelector(selectTimeModalState, (state: fromTimeModal.State) =>
  fromTimeModal.selectTimePlayTimer(state)
);

export const selectTimeFeeInitState = createSelector(selectTimeModalState, (state: fromTimeModal.State) => ({
    init: state.initData,
    isOpenInNewWindow: state.isOpenInNewWin,
  }));

export const selectTimeFeeInitData = createSelector(selectTimeFeeInitState, selectTimeSetupData, (init, formState) => {
  if (init?.isOpenInNewWindow) {
    return [init, formState];
  }

  return [init, null];
});

export const selectTimeFeeModalLoading = createSelector(selectTimeModalState, (state) => state.loading);
