import { Component } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';

@Component({
  selector: 'sc-translate-header',
  templateUrl: './translate-header.component.html',
})
export class TranslateHeaderComponent implements IHeaderAngularComp {
  headerName: string;

  headerClass = '';

  constructor() {}

  agInit(params: IHeaderParams): void {
    if (params['headerName']) {
      this.headerName = params['headerName'];
    }

    if (params['headerClass']) {
      this.headerClass = params['headerClass'];
    }
  }

  refresh(params: IHeaderParams<any>): boolean {
    return false;
  }
}
