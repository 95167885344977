import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { StoreModule } from '@ngrx/store';
import { AppSlice } from '@app/core/store';
import { reducers } from './store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { PersonListEffects } from './store/effects/person-list.effects';
import { PersonDetailsEffects } from './store/effects/person-details.effects';
import { PersonService } from './services';
import { PersonModalEffects } from './store/effects/person-modal.effects';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature(AppSlice.Persons, reducers),
    EffectsModule.forFeature([PersonListEffects, PersonDetailsEffects, PersonModalEffects]),
  ],
  providers: [PersonService],
})
export class PersonStateModule {}
