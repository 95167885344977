import { Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';

import { PaymentMode } from '@app/features/accounting/models';
import { getPaymentTranslateKey } from '@app/features/accounting/functions';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-payment-mode-cell',
  templateUrl: './payment-mode-cell.component.html',
})
export class PaymentModeCellComponent implements AgRendererComponent {
  public paymentMode: number;
  public paymentModeTranslateKey: string;

  constructor() {}

  agInit(params: any): void {
    const rowObj = params.data;
    const mode = isFunction(params['getPaymentMode']) ? params.getPaymentMode(rowObj) : PaymentMode.NotPayable;
    const isPaid = isFunction(params['getIsPaid']) ? params.getIsPaid(rowObj) : false;
    const isPartPaid = isFunction(params['getIsPartPaid']) ? params.getIsPartPaid(rowObj) : false;
    this.paymentMode = mode;
    this.paymentModeTranslateKey = getPaymentTranslateKey(mode, isPaid, isPartPaid);
  }

  refresh(params: any): boolean {
    return true;
  }
}
