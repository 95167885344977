import { Component, EventEmitter, forwardRef, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { xAnimationPulseWithParams } from '@app/shared/animations/animations';

@Component({
  selector: 'sc-input-group-prepend',
  templateUrl: './input-group-prepend.component.html',
  animations: [xAnimationPulseWithParams],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputGroupPrependComponent), multi: true }],
})
export class InputGroupPrependComponent implements OnInit, ControlValueAccessor {
  @Input()
  placeholder: string;
  @Input()
  isDisabled: boolean;
  @Input()
  autoFocus = false;
  @Input()
  inputClass: string;
  @Input()
  set type(data: string) {
    this._type = data;
  }
  get type() {
    return this._type === 'money' ? 'number' : this._type;
  }

  @Output()
  onBlur = new EventEmitter<any>();

  private _value: any;
  private _type: string;

  constructor() {}

  @HostBinding('class.x-input-group')
  ngOnInit() {}

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    v = this._type === 'money' ? Number(v) : v;
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  writeValue(value: any) {
    this._value = this._type === 'money' ? Number(value) : value;
  }

  onChange = (_) => {};

  onTouched = () => {};
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  blur(): void {
    this.onBlur.emit(this.value);
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
