<div class="x-toolbar-tabs">
  <sc-tabs>
    <ng-template [ngxPermissionsOnly]="[permissionEnum.LeapUser]">
      <sc-tab-item [link]="['/matters']">
        <sc-icon iconName="chevron-left-grid-20"></sc-icon>
        <span class="ml-1 d-none d-xl-block">Matters</span>
      </sc-tab-item>
    </ng-template>

    <sc-tab-item [link]="detailsAndCorrenpondence"> Details & Correspondence</sc-tab-item>

    <ng-template [ngxPermissionsOnly]="[permissionEnum.LeapUser]">
      <sc-tab-item [link]="calendar"> {{ calendarTabName }} </sc-tab-item>
      <sc-non-route-tab-item *ngIf="!titlexLive" (onTabItemClicked)="showWarning()"
        >Financial Summary
      </sc-non-route-tab-item>
    </ng-template>

    <ng-template [ngxPermissionsOnly]="[permissionEnum.LeapUser, permissionEnum.FeeRead, permissionEnum.FeeWrite]">
      <sc-tab-item [link]="timeAndFees"> Time & Fees</sc-tab-item>
    </ng-template>

    <ng-template [ngxPermissionsOnly]="[permissionEnum.LeapUser]">
      <sc-tab-item-dropdown
        *ngIf="(isSmallScreen$ | async) === false"
        [dropdownList]="officeAccountingDropdown"
        [label]="'Accounting.Office.Label' | translate"
        (onTabClicked)="showWarning()"
      >
      </sc-tab-item-dropdown>

      <sc-tab-item-dropdown
        *ngIf="(isSmallScreen$ | async) === false"
        [dropdownList]="trustFundsDropdown"
        [label]="'Trust.General.Fund.MenuGroup' | translate"
        (onTabClicked)="showWarning()"
      >
      </sc-tab-item-dropdown>

      <sc-tab-item-dropdown
        *ngIf="isSmallScreen$ | async"
        [dropdownList]="moreMenuDropdown"
        label="More"
        [dropFromRight]="true"
        (onTabClicked)="showWarning()"
      >
      </sc-tab-item-dropdown>
    </ng-template>
  </sc-tabs>
</div>
