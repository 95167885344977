import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { delayWhen, filter, map, take } from 'rxjs/operators';
import { ESiriusEvents } from '@app/core/models';
import { NavigationEnd, Router } from '@angular/router';

@Injectable()
export class EventBusService {
  private subject = new Subject<any>();

  constructor(private router: Router) {}

  on(event: ESiriusEvents, action: any): Subscription {
    return this.subject
      .pipe(
        filter((e: EmitEvent) => e.name === event),
        map((e: EmitEvent) => e.value)
      )
      .subscribe(action);
  }

  listen(event: ESiriusEvents): Observable<any> {
    return this.subject.pipe(
      filter((e: EmitEvent) => e.name === event),
      map((e: EmitEvent) => e.value)
    );
  }

  listenWithNavigationDelay(event: ESiriusEvents): Observable<any> {
    return this.subject.pipe(
      delayWhen(() =>
        this.router.events.pipe(
          filter((e) => e instanceof NavigationEnd),
          take(1)
        )
      ),
      filter((e: EmitEvent) => e.name === event),
      map((e: EmitEvent) => e.value)
    );
  }

  emit(event: EmitEvent) {
    this.subject.next(event);
  }
}

export class EmitEvent {
  constructor(public name: ESiriusEvents, public value?: any) {}
}
