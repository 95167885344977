<div dropdown placement="right" container="body">
  <a class="d-flex" href scPreventDefault dropdownToggle #dropdownToggle (click)="sendAnalytics()">
    <sc-icon class="x-ag-icon" iconName="cog"></sc-icon>
  </a>
  <ul
    class="x-dropdown-filter dropdown-menu dropdown-menu-right"
    *dropdownMenu
    role="menu"
    [ngStyle]="{ transform: 'translateY(' + dropdownToggle.offsetHeight / 2 + 'px)' }"
  >
    <ng-container *ngFor="let item of menuItems">
      <a class="dropdown-item" href scPreventDefault (click)="itemClicked(item)" [ngClass]="{ active: isActive(item) }">
        <sc-icon iconName="tick"></sc-icon>
        <span>{{ item.name | translate }}</span>
      </a>
    </ng-container>
    <ng-container *ngFor="let group of menuGroups; let index = index">
      <div *ngIf="menuItems.length !== 0 || index !== 0" class="dropdown-divider"></div>
      <a
        class="dropdown-item"
        *ngFor="let item of group?.menuItems"
        href
        scPreventDefault
        (click)="groupItemClicked(item, group)"
        [ngClass]="{ active: isActive(item) }"
      >
        <sc-icon iconName="tick"></sc-icon>
        <span>{{ item.name | translate }}</span>
      </a>
    </ng-container>
  </ul>
</div>
