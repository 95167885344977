import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/services';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { InflowPaymentTypeParserService } from '@app/features/accounting/services/payment-type-parser/payment-parser.service';
import { StartupService } from '@app/core/services';
import { ETrustTransactionMode, ITrustReceipt, ITrustReceiptItem } from '@app/features/+trust-receipt/models';
import { formatDateForAccounting } from '@app/features/accounting/functions';

@Injectable()
export class TrustReceiptService extends BaseService {
  url: string;

  constructor(
    private _http: HttpClient,
    private _formBuilder: FormBuilder,
    private _paymentTypeParserSvc: InflowPaymentTypeParserService,
    private _startupService: StartupService
  ) {
    super();
    this.url = `${this.accountingPath}/api/cloud/trustreceipt`;
  }

  init(matterId = '', payTypeDeleted: boolean = false, accountDeleted: boolean = false) {
    const url = this.urlJoin(
      this.url,
      '/initialisationdata',
      `?payTypeDeleted=${payTypeDeleted}`,
      `&accountDeleted=${accountDeleted}`,
      matterId !== '' ? `&matterId=${matterId}` : null
    );
    return this._http.get(url);
  }

  load(trustReceiptId: string, transactionMode: ETrustTransactionMode) {
    const url = this.urlJoin(this.url, `${trustReceiptId}/initialisationdata?transactionMode=${transactionMode}`);
    return this._http.get(url);
  }

  createTrustReceiptItem(options: any = {}): Partial<ITrustReceiptItem> {
    return {
      MatterGUID: options.MatterGUID || '',
      Amount: options.Amount || 0,
      Reason: options.Reason || '',
      RowVersion: options.RowVersion || 0,
      MatterFileNo: options.MatterFileNo || '',
      AvailableAmount: options.AvailableAmount || 0,
      DisplayedAvailableAmount: options.DisplayedAvailableAmount || 0,
      Withdrawal: options.Withdrawal || 0,
      Deposit: options.Deposit || 0,
      TrustReceiptItemGUID: options.TrustReceiptItemGUID || uuidv4(),
      TrustReceiptReversedGUID: null,
      InterestCalculation: options.InterestCalculation,
      InterestCalculationDate: formatDateForAccounting(options.InterestCalculationDate),
      ReverseeTrustReceiptItemGUID: null,
    } as ITrustReceiptItem;
  }

  createDetailsFormGroup(options: Partial<ITrustReceipt> = {}): FormGroup {
    return this._formBuilder.group({
      AutoTransactionNumber: this._formBuilder.group({
        transactionNumber: [{ value: options.TransactionNumber || '', disabled: false }],
        autoNumber: [{ value: options.TransactionNumberAuto || 0, disabled: false }],
      }),
      BankAccountGUID: options.BankAccountGUID || '',
      BankDepositGUID: options.BankDepositGUID || '',
      ClearanceDays: options.ClearanceDays || 0,
      ClearedDate: options.ClearedDate ? formatDateForAccounting(options.ClearedDate) : '',
      EntryDate: [
        {
          value: options.EntryDate ? new Date(options.EntryDate) : new Date(),
          disabled: true,
        },
      ],
      NeedsBanking: options.NeedsBanking || '',
      PaymentType: this._formBuilder.group({
        paymentType: {
          PaymentTypeID: options.PaymentTypeId || '',
          NameFull: options.PaymentTypeName || '',
        },
        paymentTypeDetails: options.PaymentTypeDetails || '',
      }),
      PaymentTypeDetails: options.PaymentTypeDetails || null,
      ReceivedFrom: options.ReceivedFrom || '',
      Reconciled: options.Reconciled || 0,
      ReversalEntryDate: options.ReversalEntryDate ? new Date(options.ReversalEntryDate) : new Date(),
      ReversedOrReversal: options.ReversedOrReversal || '',
      RowVersion: options.RowVersion || 0,
      TransactionDate: options.TransactionDate ? new Date(options.TransactionDate) : new Date(),
      TransactionNumber: [{ value: options.TransactionNumber || '', disabled: true }],
      TransactionNumberAuto: [{ value: options.TransactionNumberAuto || 1, disabled: false }],
      TrustReceiptGUID: options.TrustReceiptGUID || '',
      // specific to GET /api/cloud/trustreceipt/1/initialisationdata
      AccountFileAs: options.AccountFileAs || '',
      BankDepositReconciled: options.BankDepositReconciled || 0,
      ReversalInfo: options.ReversalInfo || '',
      // specific to POST /api/cloud/trustreceipt
      BTISettlementDate: options.BTISettlementDate ? new Date(options.BTISettlementDate) : new Date(),
      ExtPayReceiptNo: options.ExtPayReceiptNo || '',
      IsRapidPay: options.IsRapidPay || false,
      ReversalGUID: options.ReversalGUID || '',
      // specific to POST /api/leo/trustreceipt/reversal
      ReverseeRowVersion: options.ReverseeRowVersion || 0,
      ReverseeTrustReceiptGUID: options.ReverseeTrustReceiptGUID || '',
      // specific to POST / PUT
      TrustReceiptItems: [options.TrustReceiptItems] || [],
    });
  }

  createRequest(options: Partial<ITrustReceipt> = {}, mode: 'create' | 'update' | 'reverse'): any {
    const hasAutoNumber = !!options['AutoTransactionNumber'];
    const transactionNumber =
      hasAutoNumber && options['AutoTransactionNumber'].transactionNumber
        ? options['AutoTransactionNumber'].transactionNumber
        : options.TransactionNumber;

    const commonObject = {
      BankAccountGUID: options.BankAccountGUID || '',
      ReceivedFrom: options.ReceivedFrom || '',
      TransactionNumberAuto: hasAutoNumber && options['AutoTransactionNumber'].autoNumber ? '1' : '0',
      IsRapidPay: options.IsRapidPay ? '1' : '0',
      BankDepositReconciled: options.BankDepositReconciled ? '1' : '0',
      ClearedDate: options.ChequeCleared
        ? options.ClearedDate
          ? options.ClearedDate
          : formatDateForAccounting(new Date())
        : '',
      ClearanceDays: options.ClearanceDays || '0',
      Reconciled: options.Reconciled ? '1' : '0',
      NeedsBanking: options.NeedsBanking ? '1' : '0',
      TrustReceiptItems: this.createReceiptItem(options.TrustReceiptItems, mode),
      TrustReceiptGUID: options.TrustReceiptGUID || uuidv4(),
      PaymentTypeName: options.PaymentTypeName || '',
      PaymentTypeGUID: options.PaymentTypeGUID || '',
      PaymentTypeId: options.PaymentTypeId ? options.PaymentTypeId.toString() : 0,
      ReversedOrReversal: options.ReversedOrReversal || 0,
      EntryDate: formatDateForAccounting(options.EntryDate),
      TransactionDate: formatDateForAccounting(options.TransactionDate),
      TransactionNumber: transactionNumber ? transactionNumber.toString() : '',
      WarningAcknowledgments: [],
      ReceiptedByStaffGUID: this._startupService.userDetails.staffId,
      PaymentTypeDetails: { PaymentTypeDetailsGUID: uuidv4(), ...(options.PaymentTypeDetails || {}) },
      RowVersion: options.RowVersion,
    };
    const createObject = {};
    const updateObject = {};
    const reverseObject = {
      ReverseeTrustReceiptGUID: options.TrustReceiptGUID,
      TrustReceiptGUID: uuidv4(),
      ReverseeRowVersion: options.RowVersion,
      TransactionNumberAuto: true, // to overcome issue found in LS-2105
    };
    const extendedObject =
      mode === 'create' ? createObject : mode === 'update' ? updateObject : mode === 'reverse' ? reverseObject : {};
    return {
      ...commonObject,
      ...extendedObject,
      SecurityOverride: { Username: '', Password: '' },
    };
  }

  createReceiptItem(items: ITrustReceiptItem[], mode: 'create' | 'update' | 'reverse'): any {
    const TrustReceiptItems = [];
    items?.forEach((item) => {
      if (item.Amount && item.Amount !== 0) {
        const commonObject = {
          MatterGUID: item.MatterGUID || '',
          Amount: item.Amount || 0,
          Reason: item.Reason || '',
          RowVersion: item.RowVersion || 0,
          MatterFileNo: item.MatterFileNo || '',
          AvailableAmount: item.AvailableAmount || 0,
          DisplayedAvailableAmount: item.DisplayedAvailableAmount || 0,
          Withdrawal: item.Withdrawal || 0,
          Deposit: item.Deposit || 0,
          TrustReceiptItemGUID: item.TrustReceiptItemGUID || uuidv4(),
          TrustReceiptReversedGUID: null,
          InterestCalculation: item.InterestCalculation,
          InterestCalculationDate: formatDateForAccounting(item.InterestCalculationDate),
          ReverseeTrustReceiptItemGUID: null,
        };
        const createObject = {};
        const updateObject = {};
        const reverseObject = {
          ReverseeTrustReceiptItemGUID: item.TrustReceiptReversedGUID,
          Amount: Number(item.Amount),
        };
        const extendedObject =
          mode === 'create' ? createObject : mode === 'update' ? updateObject : mode === 'reverse' ? reverseObject : {};

        TrustReceiptItems.push({
          ...commonObject,
          ...extendedObject,
        });
      }
    });
    return TrustReceiptItems;
  }
}
