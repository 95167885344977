<nav class="x-pagination-nav-center">
  <ul class="pagination" *ngIf="pageList.length > 0">
    <li class="page-item" [ngClass]="{ disabled: isFirstPage }">
      <a class="page-link" (click)="prevPage()">&laquo;</a>
    </li>
    <li
      class="page-item"
      [ngClass]="{ active: isCurrentPage(page), disabled: isRangingDot(page) }"
      *ngFor="let page of pageList"
    >
      <a class="page-link" (click)="goToPage(page)"> {{ page === '...less' || page === '...more' ? '...' : page }} </a>
    </li>
    <li class="page-item" [ngClass]="{ disabled: isLastPage }">
      <a class="page-link" (click)="nextPage()">&raquo;</a>
    </li>
  </ul>
</nav>
