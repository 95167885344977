import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { IBankAccount } from '@app/features/accounting/models';

@Component({
  selector: 'sc-trust-bank-account-list-selector',
  templateUrl: './trust-bank-account-list-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrustBankAccountListSelectorComponent implements OnInit, OnDestroy {
  private _originalSelectedBankAccountGuidSub = Subscription.EMPTY;

  @Input()
  bankAccounts: IBankAccount[];

  @Input()
  selectedBankAccountGuid: string;

  @Output()
  onBankAccountChanged = new EventEmitter<string>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy() {
    this._originalSelectedBankAccountGuidSub.unsubscribe();
  }

  selectBankAccount(guid: any): void {
    this.selectedBankAccountGuid = guid;
    this.onBankAccountChanged.emit(guid);
    this.cdr.markForCheck();
  }
}
