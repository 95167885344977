import * as actions from '../actions/support-request.action';

export interface State {
  cancelToken: string;
  code: string;
  duration: string;
  expiry: Date;
  firmId: string;
  leapUserEmail: string;
  origin: string;
  reason: string;
  supportAgentEmail: string;
  supportAgentId: string;
  supportAgentName: string;
}

export const INITIAL_STATE: State = {
  cancelToken: null,
  code: null,
  duration: null,
  expiry: null,
  firmId: null,
  leapUserEmail: null,
  origin: null,
  reason: null,
  supportAgentEmail: null,
  supportAgentId: null,
  supportAgentName: null,
};

export const reducer = (state = INITIAL_STATE, action: actions.SupportRequestActions) => {
  switch (action.type) {
    case actions.SUPPORT_REQUEST_INIT:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export const selectDuration = (state: State) => state.duration;
export const selectReason = (state: State) => state.reason;
export const selectSupportAgentEmail = (state: State) => state.supportAgentEmail;
export const selectSupportAgentId = (state: State) => state.supportAgentId;
export const selectSupportAgentName = (state: State) => state.supportAgentName;
export const selectCode = (state: State) => state.code;
