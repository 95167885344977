<label *ngIf="uiConfig?.label">{{ uiConfig?.label | translate }}</label>
<div class="x-input-group" [ngClass]="wrapperClass">
  <ng-select
    #select
    [items]="items"
    [bindValue]="bindValue"
    [bindLabel]="bindLabel"
    (change)="onTaskCodeSelected($event)"
    [clearable]="false"
    [(ngModel)]="value"
    [disabled]="isDisabled"
    [selectOnTab]="true"
    appendTo="body"
  >
    <ng-template ng-label-tmp let-item="item">
      <span class="ng-value w-90 x-value"> {{ taskCodeLabel(item) }} </span>
    </ng-template></ng-select
  >
  <div class="x-input-group-append">
    <button class="btn x-btn-default x-btn-icon-only" (click)="openTaskCodeSelector()" [disabled]="isDisabled" tabindex="-1">
      <sc-icon class="x-icon-sm" iconName="browse"></sc-icon>
    </button>
  </div>
</div>
