import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { flyInOut } from '@app/shared/animations/animations';
import { select, Store } from '@ngrx/store';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';

import { selectAllToastr } from '@app/core/store/selectors';
import { IToastrRenderer } from '@app/core/models';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-toast',
  templateUrl: './toast.component.html',
  animations: [flyInOut],
})
export class ToastComponent extends Toast implements OnInit, OnDestroy {
  public actionText$: Observable<string>;
  public message$: Observable<string>;
  public useDynamicMessage: boolean;

  private _autoCloseSub: Subscription;
  private _messageListenerSub: Subscription;
  private _messageSub: Subscription;
  private unsub = new Subject<void>();

  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage, private _store: Store<any>) {
    super(toastrService, toastPackage);
    this.useDynamicMessage = false;
  }

  @HostBinding('class.d-block')
  // @HostBinding('@flyInOut')
  ngOnInit() {
    this.getIconClass();
    this.setupSubscriptions();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction(event);
    return false;
  }

  getIconClass() {
    switch (this.toastPackage.toastType) {
      case 'danger':
        return 'error-circle-outline';
      case 'success':
        return 'tick-circle-outline';
      case 'warning':
        return 'warning-triangle-outline';
      case 'loading':
        return 'uploading-grid-20';
      default:
        return 'info-circle-outline';
    }
  }

  private setupSubscriptions(): void {
    const renderer$ = this._store.pipe(
      select(selectAllToastr),
      map((renderers: IToastrRenderer[]) => renderers?.find((p) => p.toastrId === this.toastPackage.toastId))
    );
    this.actionText$ = renderer$.pipe(map((renderer: IToastrRenderer) => (!!renderer ? renderer.actionText : '')));
    this.message$ = renderer$.pipe(map((renderer: IToastrRenderer) => (!!renderer ? renderer.message : '')));

    this._autoCloseSub = renderer$
      .pipe(
        filter((renderer) => !!renderer),
        take(1),
        takeUntil(this.unsub) // important: this operator must be last
      )
      .subscribe((renderer: IToastrRenderer) => {
        if (isFunction(renderer.autoClose)) {
          renderer.autoClose();
        }
      });
    this._messageListenerSub = renderer$
      .pipe(
        filter((renderer) => !!renderer),
        take(1),
        takeUntil(this.unsub) // important: this operator must be last
      )
      .subscribe((renderer: IToastrRenderer) => {
        if (isFunction(renderer.messageListener)) {
          renderer.messageListener();
          this.useDynamicMessage = true;
        }
      });
    this._messageSub = this.message$
      .pipe(
        takeUntil(this.unsub) // important: this operator must be last
      )
      .subscribe((message: string) => {
        if (this.useDynamicMessage) {
          this.message = message;
        }
      });
  }
}
