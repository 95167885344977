import { ICard, IMatterType } from '@app/shared/models';
import { ECardType } from '@app/features/+card/models';

export interface INewMatterModalSetupData {
  cardId?: string;
  cardType?: ECardType;
}

export interface INewMatterParams {
  matterType: IMatterType;
  card?: ICard;
  options?: any;
}

export interface INewRecurringMatterParams extends INewMatterParams {
  recurringMatterName: string;
}

export interface Progress<T> {
  heading: string;
  step: T;
  complete: () => boolean;
  errorMsg?: string;
}

export enum Step {
  MatterType = 1,
  FileNumber = 2,
  Client = 3,
  Finish = 4,
}

export const SubstitutionTables = {
  CLIENT: '3',
  OTHER_SIDE: '7',
  OTHER_SIDE_SOLICITOR: '4',
  OTHER_SIDE_INSURER: '193',
};
