import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@app/core/services';

import { BaseService } from '@app/shared/services';
import { Observable } from 'rxjs';
import { FeeInitialDataModel } from '@app/features/fee-timer/models/task-code.model';

@Injectable()
export class ActivityCodeService extends BaseService {
  private accountingPath_: string;

  constructor(private http: HttpClient, private authService: AuthService) {
    super();

    this.accountingPath_ = `${this.accountingPath}/api/cloud/fee`;
  }

  getInitialisationData(matterId: string): Observable<FeeInitialDataModel> {
    return this.http.get<FeeInitialDataModel>(`${this.accountingPath_}/initialisationdata?matterId=${matterId}`);
  }
}
