import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';

import * as namedListActions from '../actions/named-list';
import { catchError, filter, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { NamedListsService, NamedListsStorageService } from '@app/features/+card/services';
import { selectNamedListInitiated } from '@app/features/+card/store/selectors';
import { PlatformService } from '@app/core/services';

@Injectable()
export class NamedListEffects {

  initNamedLists$ = createEffect(() => this.actions$.pipe(
    ofType<namedListActions.InitNamedListsStart>(namedListActions.NamedListApiActionTypes.INIT_NAMEDLISTS_START),
    withLatestFrom(this.store.pipe(select(selectNamedListInitiated)), (action, initiated) => initiated),
    filter((initiated) => !!this.platformSvc.isBrowser && !initiated),
    switchMap(() => this.namedListSvc.getAll()),
    mergeMap((namedListResponse) => [
      new namedListActions.InitNamedListsSuccess(null),
      new namedListActions.NamedListSaveDbStart(namedListResponse),
    ]),
    catchError(() => [new namedListActions.InitNamedListsFailure(null)])
  ));


  saveNamedListsToDb$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(namedListActions.NamedListDbActionTypes.NAMEDLISTS_SAVE_DB_START),
    filter(() => this.platformSvc.isBrowser),
    switchMap((action: namedListActions.NamedListSaveDbStart) => this.namedListStorageSvc
        .upsertAll(action.payload)
        .catch((error) => new namedListActions.NamedListSaveDbFailure(error))),
    switchMap(() => this.namedListStorageSvc.getAll().pipe(
        mergeMap((data) => {
          this.namedListStorageSvc.refreshAll(data);
          return [new namedListActions.NamedListSaveDbSuccess('success')];
        })
      )),
    catchError((error) => of(new namedListActions.NamedListSaveDbFailure(error)))
  ));


  updateNamedLists$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<namedListActions.UpdateNamedLists>(namedListActions.NamedListApiActionTypes.UPDATE_NAMEDLISTS),
    withLatestFrom(this.store.pipe(select(selectNamedListInitiated)), (action, initiated) => initiated),
    filter((initiated) => !!this.platformSvc.isBrowser && initiated),
    switchMap(() => this.namedListSvc.getAll()),
    mergeMap((namedListResponse) => [new namedListActions.NamedListSaveDbStart(namedListResponse)]),
    catchError(() => [new namedListActions.InitNamedListsFailure(null)])
  ));


  saveNamedList$ = createEffect(() => this.actions$.pipe(
    ofType<namedListActions.SaveNamedList>(namedListActions.NamedListApiActionTypes.SAVE_NAMEDLIST),
    tap((action) => {
      const { namedList } = action.payload;
      this.namedListSvc.saveNamedList(namedList);
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private platformSvc: PlatformService,
    private namedListStorageSvc: NamedListsStorageService,
    private namedListSvc: NamedListsService
  ) {}
}
