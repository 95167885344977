<div class="modal-header">
  <h5 class="modal-title"><ng-content></ng-content></h5>
  <button
    type="button"
    class="btn close"
    [ngClass]="{ 'x-no-focus': !useKeyTab }"
    (click)="openOutlook($event)"
    *ngIf="!hideOutlookBtn"
    tooltip="Edit in Outlook"
    placement="bottom"
    container="body"
    angulartics2On
    [angularticsCategory]="getDesktopIntegrationAnalyticsCategory"
    angularticsAction="Edit in Outlook"
  >
    <sc-icon iconName="outlook"></sc-icon>
  </button>
  <button
    type="button"
    class="btn close"
    [ngClass]="{ 'x-no-focus': !useKeyTab }"
    (click)="openPopout($event)"
    *ngIf="!hideNewWindowBtn"
    tooltip="Open in New Window"
    placement="bottom"
    container="body"
    angulartics2On
    [angularticsCategory]="analyticsCategory"
    angularticsAction="Open new window"
  >
    <sc-icon iconName="launch-grid-20"></sc-icon>
  </button>

  <sc-btn
    *ngIf="showRecurringMattersCreate"
    btnType="button"
    btnSize="xs"
    styleClass="x-btn-default"
    (btnClicked)="showRecurringMatters()"
  >
    Use Recurring Matter Template
  </sc-btn>

  <button
    type="button"
    class="btn close"
    [ngClass]="{ 'x-no-focus': !useKeyTab }"
    data-dismiss="modal"
    aria-label="Close"
    [disabled]="disableCloseBtn"
    (click)="closeModal($event)"
    *ngIf="!hideCloseBtn"
  >
    <sc-icon iconName="close-grid-20"></sc-icon>
  </button>
</div>
