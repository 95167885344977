import { Action } from '@ngrx/store';

export const PAYMENT_TYPE_MODAL_CLOSE = '[Payment type details] Modal close';
export const PAYMENT_TYPE_MODAL_CLEAR = '[Payment type details] Modal clear';

export class PaymentTypeModalClose implements Action {
  readonly type = PAYMENT_TYPE_MODAL_CLOSE;
  constructor(public payload: any) {}
}

export class PaymentTypeModalClear implements Action {
  readonly type = PAYMENT_TYPE_MODAL_CLEAR;
  constructor(public payload: any) {}
}

export type CommonPaymentTypeDetailsAction = PaymentTypeModalClose | PaymentTypeModalClear;
