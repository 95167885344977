import { Staff } from '@app/shared/models';

export interface IBatchSearchFilter {
  BatchNo: number;
  Type: number;
  Staff: Staff;
  DateFrom: Date;
  DateTo: Date;
}

export interface IBatchType {
  TypeID: number;
  Type: string;
}

export enum IBatchTypes {
  All = 0,
  CreateInvoices = 0,
}
