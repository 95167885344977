import { Component, HostBinding, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IAddress, ICardListEntry, IPersonEntry } from '@app/features/+card/models';
import { Observable } from 'rxjs';
import {
  selectPopupCardEntry,
  selectPopupCardEntryMatters,
  selectPopupCardEntryPersons,
} from '@app/features/+card/store/selectors/card-details-popup.selectors';
import { IMatterListEntry } from '@app/features/+matter-list/models';
import { PermissionsService } from '@app/core/services';
import * as cardDetailsPopupActions from '@app/features/+card/store/actions/card-details-popup';
import { CardHelper } from '@app/shared/utils/card.helper';
import { AnalyticsCategories } from '@app/core/constants/analytics.constant';

@Component({
  selector: 'sc-card-details-popup',
  templateUrl: './card-details-popup.component.html',
})
export class CardDetailsPopupComponent implements OnInit {
  @HostBinding('class.popover')
  get popoverClass() {
    return true;
  }

  @HostBinding('class.x-popover-card')
  get xPopoverCardClass() {
    return true;
  }

  get hasAccessPermissions(): boolean {
    return this.permissionsSvc.hasCardEditPermissions;
  }

  get getAnalyticsCategory(): string {
    return AnalyticsCategories.CardListSidebar;
  }

  public popupCardEntry$: Observable<ICardListEntry>;
  public persons$: Observable<IPersonEntry[]>;
  public matters$: Observable<IMatterListEntry[]>;

  constructor(private store: Store<any>, private permissionsSvc: PermissionsService) {
    this.popupCardEntry$ = this.store.pipe(select(selectPopupCardEntry));
    this.persons$ = this.store.pipe(select(selectPopupCardEntryPersons));
    this.matters$ = this.store.pipe(select(selectPopupCardEntryMatters));
  }

  ngOnInit() {}

  getIcon(type: string, persons: any[]) {
    if (type === 'People' && !!persons && persons.length <= 1) {
      return 'user';
    } else if (type === 'People' && !!persons && persons.length >= 2) {
      return 'users';
    } else if (type === 'Government') {
      return 'government';
    } else if (type === 'Trust') {
      return 'trust';
    } else {
      return 'business';
    }
  }

  formatAddress(address: IAddress): string {
    return CardHelper.getStreetAddress(address);
  }

  createEmail(cardEntry: any): void {
    this.store.dispatch(new cardDetailsPopupActions.CreateEmail({ cardEntry }));
  }

  editCardDetails(cardEntry: any) {
    this.store.dispatch(new cardDetailsPopupActions.EditCardDetails({ cardEntry }));
  }
}
