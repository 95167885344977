export enum EAccountUsage {
  Office = 0,
  Trust = 1,
  Controlled_Money_AU = 2,
  Investment_AU = 3,
  Investment_UK = 5,
  Investment_US = 6,
}

export { IBankAccounts, IBankAccount } from '@app/features/+payments-debtors/models';
