import { Component, HostBinding, ViewChild } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';

import { IPerson } from '@app/shared/models';
import { Recipient } from '@app/features/+letter/models';

@Component({
  selector: 'sc-recipient-details-cell',
  templateUrl: './recipient-details-cell.component.html',
})
export class RecipientDetailsCellComponent implements AgRendererComponent {
  @ViewChild('dropdown') dropdown: any;

  @HostBinding('class') classes = 'x-recipient-details-cell d-flex align-items-center justify-content-between';
  persons: IPerson[];
  personRole: string;
  personDesc: string;
  recipients: Recipient[] = [];
  isOrganisationCard = false;

  private emitRecipient: any;
  private cardId: string;
  private params: any;

  get dropdownBtnText(): string {
    const checkedRecipients = this.recipients.filter((r) => r.checked);
    const allChecked = checkedRecipients.length > 0 && checkedRecipients.length === this.recipients.length;
    if (allChecked || checkedRecipients.length === 0) {
      return 'All People';
    } else {
      const selectedPerson = this.persons.find((p) => p.__id === checkedRecipients[0].personId);
      return selectedPerson ? this.getPersonFullName(selectedPerson) : '';
    }
  }

  constructor() {}

  handleOnClick(event): void {
    event.stopImmediatePropagation();
  }

  handleItemClick(event, index): void {
    this.recipients = this.recipients.map((p, recipientIndex) => recipientIndex === index ? { ...p, checked: true } : { ...p, checked: false });
    this.emitRecipient(this.params, index);
    this.dropdown.hide();
    event.stopPropagation();
  }

  handleOnAllClick(event: MouseEvent) {
    this.recipients = this.recipients.map((p) => ({ ...p, checked: true }));
    this.emitRecipient(this.params, undefined);
    this.dropdown.hide();
    event.stopPropagation();
  }

  isPersonSelected(person: IPerson): boolean {
    const recipient = this.recipients.find((r) => r.personId === person.__id);
    return !this.isAllSelected && !!recipient && recipient.checked;
  }

  get isAllSelected(): boolean {
    const checkedRecipients = this.recipients.filter((r) => r.checked);
    return checkedRecipients.length > 0 && checkedRecipients.length === this.recipients.length;
  }

  getPersonFullName(person: IPerson): string {
    let fullName = '';
    fullName += !!person.salutation ? person.salutation : '';
    fullName += !!person.firstNames ? ` ${person.firstNames}` : '';
    fullName += !!person.lastName ? ` ${person.lastName}` : '';
    return fullName;
  }

  agInit(params: any): void {
    this.params = params;
    const { persons, cardId } = params.getCardPersons(params.data);

    this.persons = persons;
    this.cardId = cardId;
    this.isOrganisationCard = params.isOrganisationCard(params.data);
    this.personRole = params.getCardPersonRole(params.data);
    this.personDesc = params.getCardPersonDesc(params.data);

    this.recipients = this.persons.map(
      (p, i) =>
        ({
          personId: p.__id,
          checked: true,
          index: i,
        } as Recipient)
    );
    this.emitRecipient = params.emitRecipient;
  }

  refresh(params: any): boolean {
    return true;
  }

  trackByPerson(index: number, person: IPerson): string {
    return person.__id;
  }
}
