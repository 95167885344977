import { Action } from '@ngrx/store';
import { ECardFilterType, ICardFilter } from '@app/features/+card/models';

export enum CardFiltersActionTypes {
  CONFIGURE_DEFAULT_CARD_FILTERS = '[Card Filters] configure default card filters',
  UPDATE_DEFAULT_CARD_FILTERS = '[Card Filters] update default card filters',
  CONFIGURE_CUSTOM_CARD_FILTERS = '[Card Filters] configure custom card filters',
  RESET_CUSTOM_CARD_FILTERS = '[Card Filters] reset custom card filters',
  UPDATE_CUSTOM_CARD_FILTERS = '[Card Filters] update custom card filters',
}

export class ConfigureDefaultCardFilters implements Action {
  readonly type = CardFiltersActionTypes.CONFIGURE_DEFAULT_CARD_FILTERS;

  constructor(public payload: any) {}
}

export class UpdateDefaultCardFilters implements Action {
  readonly type = CardFiltersActionTypes.UPDATE_DEFAULT_CARD_FILTERS;

  constructor(public payload: { filters: Array<{ type: ECardFilterType; filters: ICardFilter[] }> }) {}
}

export class ConfigureCustomCardFilters implements Action {
  readonly type = CardFiltersActionTypes.CONFIGURE_CUSTOM_CARD_FILTERS;

  constructor(public payload: { types: string[] }) {}
}

export class ResetCustomCardFilters implements Action {
  readonly type = CardFiltersActionTypes.RESET_CUSTOM_CARD_FILTERS;

  constructor(public payload: any) {}
}

export class UpdateCustomCardFilters implements Action {
  readonly type = CardFiltersActionTypes.UPDATE_CUSTOM_CARD_FILTERS;

  constructor(public payload: { filters: ICardFilter[] }) {}
}

export type CardFiltersActions =
  | ConfigureDefaultCardFilters
  | UpdateDefaultCardFilters
  | ConfigureCustomCardFilters
  | ResetCustomCardFilters
  | UpdateCustomCardFilters;
