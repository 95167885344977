import { IMatterListEntry, IMatterListRow } from '@app/features/+matter-list/models';
import { appendComma, cropText } from '@app/shared/functions';
import { DuplicatedFileNumber } from '@app/features/+matter-list/constants';
import { LEGAL_AID_BILLING_MODE } from '@app/features/accounting/constants';
import { trimLeft } from '@server/modules/shared/functions/common-util.functions';

export const getMatterClientAndDesc = (matter: IMatterListEntry): string => {
  // transcribe 4D code from DBa_Matters_getClientAndDesc
  if (!matter) {
    return '';
  }
  const matterNo = matter.fileNumber;
  const clientName = cropText(matter.firstDescription, 40);
  const matterType = cropText(matter.matterType, 40);
  const description = cropText(matter.customDescription, 40);
  const description2 = cropText(matter.secondDescription, 40);

  let s = `${matterNo} - ${clientName}`;
  if (!description) {
    return `${appendComma(s)} ${matterType}`;
  } else {
    s = `${appendComma(s)} ${description}`;
  }
  if (description2) {
    s = `${appendComma(s + ';')} ${description2}`;
  }
  return s;
};

export const isLegalAid = (row: IMatterListRow): boolean =>
   row.data && row.data.billingMode === LEGAL_AID_BILLING_MODE // this is the billingMode for Legal Aid
;

export const matterFileNumberComparator = (valueA: string, valueB: string): number => {
  /**
   * Priority (after trimming start all '0' characters):
   * 1) Duplication status
   * 2) Length
   * 3) Alphabetical order
   */
  const mainValueA = trimLeft(valueA || '', '0');
  const mainValueB = trimLeft(valueB || '', '0');

  if (mainValueA === mainValueB) {
    return 0;
  }
  if ([mainValueA, mainValueB].includes(DuplicatedFileNumber)) {
    return mainValueA === DuplicatedFileNumber ? -1 : 1;
  }
  if (mainValueA.length !== mainValueB.length) {
    return mainValueA.length < mainValueB.length ? -1 : 1;
  }
  return mainValueA < mainValueB ? -1 : 1;
};
