<label *ngIf="inputLabel" [for]="inputId">{{ (inputLabel | translate) || '&nbsp;' }}</label>
<textarea
  class="form-control"
  [id]="inputId"
  [ngClass]="inputClass"
  [required]="required"
  [defaultValue]="value"
  [value]="value"
  [rows]="rowNumber"
  [placeholder]="placeholder | translate"
  (blur)="blur()"
  (focus)="handleFocus(inputElm)"
  [style.height.px]="inputHeight"
  [ngStyle]="styleRule"
  scAutofocus
  [autofocus]="focus"
  [disabled]="isDisabled"
  (keydown)="keydown($event)"
  (input)="handleOnChange($event)"
  autocomplete="off"
  #inputElm
></textarea>
