import { Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { isFunction } from '@server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-description-cell',
  templateUrl: './description-cell.component.html',
})
export class DescriptionCellComponent implements AgRendererComponent {
  public isTitleHidden: boolean;
  public title: string;
  public description: string;
  public labels: string[];
  public wrapperClass: string;
  public titleClass: string;

  constructor() {}

  agInit(params: any): void {
    this.configDescriptionCell(params);
  }

  refresh(params: any): boolean {
    this.configDescriptionCell(params);
    return true;
  }

  private configDescriptionCell(params: any): void {
    if (!!params && !!params.data) {
      const rowObj = params.data;
      const defaultTitle = isFunction(params['getDefaultTitle']) ? params.getDefaultTitle(rowObj) : '...';

      this.title = isFunction(params['getTitle']) ? params.getTitle(rowObj) || defaultTitle : defaultTitle;
      this.description = isFunction(params['getDescription']) ? params.getDescription(rowObj) : '';
      this.labels = isFunction(params['getLabels']) ? params.getLabels(rowObj) : [];
      this.isTitleHidden = isFunction(params['getTitleHidden']) ? params.getTitleHidden(rowObj) : false;
      this.wrapperClass = isFunction(params['getWrapperClass']) ? params.getWrapperClass(rowObj) : '';
      this.titleClass = isFunction(params['getTitleClass']) ? params.getTitleClass(rowObj) : '';
    }
  }
}
