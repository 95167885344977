import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-text-primary-cell',
  templateUrl: './text-primary-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextPrimaryCellComponent implements ICellRendererAngularComp {
  text: string;
  textClass = 'text-primary-lighter';
  params: any;

  constructor(private cdr: ChangeDetectorRef) {}

  agInit(params: any): void {
    this.params = params;
    this.updateValues(params);
  }

  refresh(params: any): boolean {
    this.params = params;
    this.updateValues(params);
    this.cdr.markForCheck();
    return true;
  }

  private updateValues(params: any): void {
    const rowObj = params.data;
    this.text = isFunction(params.getName) ? params.getName(rowObj) : '';
    this.textClass = isFunction(params.getTextClass) ? params.getTextClass(rowObj) : 'text-primary-lighter';
  }
}
