<div *ngIf="!isLeapDesktop; else leap_desktop" class="bg-white overflow-auto heightMax">
  <div class="main-content-full clearfix heightMax">
    <div class="main-subheading">
      <div class="row">
        <div class="col-xs-24">
          <h3 class="line-height-32">Page Not Found</h3>
        </div>
      </div>
    </div>

    <h5><strong>Error 404</strong></h5>
    <div class="gap10"></div>

    <div class="span12">
      <div class="hero-unit center">
        <p>The page you requested could not be found</p>
        <button routerLink="/" class="btn btn-large btn-primary btn-outline">
          <i class="icon-home icon-white"></i> Take Me Home
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #leap_desktop>
  <h5><strong>Please close the page and try again.</strong></h5>
</ng-template>
