<div [ngClass]="wrapperClass" dropdown [autoClose]="true" container="body" [placement]="dropdownPlacement">
  <ng-container>
    <button
      *ngIf="!iconButton"
      (click)="dropdownClicked($event)"
      class="dropdown-toggle"
      type="button"
      dropdownToggle
      aria-haspopup="true"
      aria-expanded="true"
    >
      {{ selectedText }}
    </button>
    <sc-icon
      *ngIf="iconButton"
      [iconName]="iconClass"
      (click)="dropdownClicked($event)"
      dropdownToggle
      aria-haspopup="true"
      aria-expanded="true"
    >
    </sc-icon>
  </ng-container>
  <ul
    class="dropdown-menu"
    aria-labelledby="dropdown"
    *dropdownMenu
    role="menu"
    [class.dropdown-menu-right]="dropdownMenuAlignRight"
  >
    <ng-container *ngFor="let item of options.items">
      <a href scPreventDefault class="dropdown-item" role="menuitem" (click)="itemClicked(item)">
        {{ options.itemLabel(item) }}
      </a>
    </ng-container>
  </ul>
</div>
