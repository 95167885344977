import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-matter-client-cell',
  templateUrl: './matter-client-cell.component.html',
})
export class MatterClientCellComponent implements ICellRendererAngularComp {
  matterEntry: any; // IMatterListEntry
  showClientShortName: boolean;
  isHidden: boolean;
  hideFirstDescription: boolean;

  get description(): string {
    return this.showClientShortName
      ? this.matterEntry.firstDescription || this.matterEntry.firstDescriptionLong || ''
      : this.matterEntry.firstDescriptionLong || this.matterEntry.firstDescription || '';
  }

  constructor() {}

  agInit(params: any): void {
    this.matterEntry = params.getMatterEntry(params.data);
    this.showClientShortName =
      params?.showClientShortName && isFunction(params.showClientShortName) ? params.showClientShortName() : false;
    this.hideFirstDescription = params?.hideFirstDescription ? params.hideFirstDescription(params.data) : false;
  }

  refresh(params: any): boolean {
    this.showClientShortName =
      params?.showClientShortName && isFunction(params.showClientShortName) ? params.showClientShortName() : false;
    this.hideFirstDescription = params?.hideFirstDescription ? params.hideFirstDescription(params.data) : false;
    return true;
  }
}
