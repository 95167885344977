import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from 'app/shared/services/base/base.service';

import { EAccountUsage, IBankAccount, IBankAccounts } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class BankAccountService extends BaseService {
  constructor(private _http: HttpClient) {
    super();
  }

  getBankAccounts(accountUsage: EAccountUsage): Observable<IBankAccount[]> {
    const url = `${this.accountingPath}/api/cloud/bankaccount?accountUsage=${accountUsage}`;
    return this._http.get<IBankAccount[]>(url);
  }

  getPrintChequeBankAccount(bankAccountGuid: string): Observable<IBankAccount> {
    return this.getPrintChequeBankAccounts().pipe(
      map((bankAccounts) => bankAccounts.find((b) => b.BankAccountGUID === bankAccountGuid))
    );
  }

  getPrintChequeBankAccounts(): Observable<IBankAccount[]> {
    const url = `${this.accountingPath}/api/cloud/printcheques/initialisationdata`;
    return this._http.get<IBankAccounts>(url).pipe(map((response) => response.BankAccounts));
  }
}
