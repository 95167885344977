import { ITimeFeeResponse, ITimeFeeSummaryVM } from '@app/features/+time-fee-ledger/models';
import * as timeFeeEntryActions from '../actions/time-fee-entry';

export interface State {
  loading: boolean;
  timeFeeEntry: ITimeFeeResponse;
  timeEntrySummary: ITimeFeeSummaryVM;
}

export const INITIAL_STATE: State = {
  loading: false,
  timeFeeEntry: null,
  timeEntrySummary: null,
};

export const reducer = (state = INITIAL_STATE, action: timeFeeEntryActions.TimeFeeEntryActions) => {
  switch (action.type) {
    case timeFeeEntryActions.TimeFeeEntryActionTypes.LOAD_ENTRY:
      return {
        ...state,
        loading: true,
      };

    case timeFeeEntryActions.TimeFeeEntryActionTypes.LOAD_ENTRY_SUCCEED:
      return {
        ...state,
        loading: false,
        timeFeeEntry: action.payload,
      };

    case timeFeeEntryActions.TimeFeeEntryActionTypes.LOAD_ENTRY_FAIL:
      return {
        ...state,
        loading: false,
      };

    case timeFeeEntryActions.TimeFeeEntryActionTypes.SET_TIME_ENTRY_SUMMARY:
      return {
        ...state,
        timeEntrySummary: action.payload,
      };

    case timeFeeEntryActions.TimeFeeEntryActionTypes.DESTROY_ENTRY:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const getTimeFeeEntry = (state: State) => state?.timeFeeEntry;
export const selectTimeEntrySummary = (state: State) => state?.timeEntrySummary;
