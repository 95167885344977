import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromAdvancedSearchModal from './advanced-search-modal.reducer';
import * as fromMatterFiltersModal from './matter-filters-modal.reducer';
import * as fromMatterList from './matter-list.reducer';
import * as fromMatterSearch from './matter-search.reducer';

export interface State {
  advancedSearchModal: fromAdvancedSearchModal.State;
  matterFiltersModal: fromMatterFiltersModal.State;
  matterList: fromMatterList.State;
  matterSearch: fromMatterSearch.State;
}

export const initialState: State = {
  advancedSearchModal: fromAdvancedSearchModal.initialState,
  matterFiltersModal: fromMatterFiltersModal.initialState,
  matterList: fromMatterList.initialState,
  matterSearch: fromMatterSearch.initialState,
};

export const reducers: ActionReducerMap<State> = {
  advancedSearchModal: fromAdvancedSearchModal.reducer,
  matterFiltersModal: fromMatterFiltersModal.reducer,
  matterList: fromMatterList.reducer,
  matterSearch: fromMatterSearch.reducer,
};

export const selectMattersState = createFeatureSelector<State>('matters');
export { extractMatterSearch } from './matter-search.reducer';
