<label *ngIf="inputLabel" [for]="id">{{ (inputLabel | translate) || '&nbsp;' }}</label>
<select
  [@xAnimationPulseWithParams]="selectAnimationPulseState"
  scAutofocus
  [autofocus]="autofocus"
  class="custom-select"
  [ngModel]="value"
  (ngModelChange)="handleSelectValueChange($event)"
  [disabled]="isDisabled"
  [ngClass]="selectClass"
  [id]="id"
  #selectElm
>
  <ng-content></ng-content>
  <ng-container *ngIf="customisable">
    <option value="divider" disabled>
      ----------------------------------------------------------------------
    </option>
    <option [value]="customise_list_option_value">{{ 'Core.CustomiseList' | translate }} ...</option>
  </ng-container>
</select>
