import { Action } from '@ngrx/store';
import { ITimeFeeSummary } from 'app/features/+time-fee-ledger/models/index';

export const LIST_TIME_FEE_START = '[Time Fee List from api] start';
export const LIST_TIME_FEE_SUCCESS = '[Time Fee List from api] success';
export const LIST_TIME_FEE_FAILURE = '[Time Fee List from api] failure';

export class ListTimeFeeStart implements Action {
  readonly type = LIST_TIME_FEE_START;

  constructor(public payload: string) {}
}

export class ListTimeFeeSuccess implements Action {
  readonly type = LIST_TIME_FEE_SUCCESS;

  constructor(public payload: ITimeFeeSummary[]) {}
}

export class ListTimeFeeFailure implements Action {
  readonly type = LIST_TIME_FEE_FAILURE;

  constructor(public payload: any) {}
}

export type TimeFeeApiActions = ListTimeFeeStart | ListTimeFeeSuccess | ListTimeFeeFailure;
