import { GridOptions, RowNode, CellClickedEvent, GridApi, IRowNode } from '@ag-grid-community/core';
import { hasProp } from '../../../../server/modules/shared/functions/common-util.functions';

export const isEdgeRow = (node: RowNode, edgeProperty: 'firstChild' | 'lastChild'): boolean => {
  if (node.group) {
    return false;
  }
  const isEdgeParent = !hasProp(node.parent, edgeProperty) || node.parent[edgeProperty];
  return node[edgeProperty] && (!node.parent || isEdgeParent);
};

export const isNextSelection = (node: RowNode, prevIndex: number, navDirection: 1 | -1): boolean => {
  const isNextRow = node.rowIndex === prevIndex + navDirection;
  switch (navDirection) {
    case -1:
      const isNextLastChild = node.lastChild && node.rowIndex === prevIndex + 2 * navDirection;
      return !node.group && (isNextLastChild || isNextRow);
    case 1:
    default:
      const isNextFirstChild = node.firstChild && node.rowIndex === prevIndex + 2 * navDirection;
      return !node.group && (isNextFirstChild || isNextRow);
  }
};

export const scrollToRow = (gridApi: GridApi, node: RowNode) => {
  const vRange = (gridApi as GridApi).getVerticalPixelRange();
  if (node.rowTop + node.rowHeight > vRange.bottom) {
    (gridApi as GridApi).ensureIndexVisible(node.rowIndex, 'bottom');
  } else if (node.rowTop < vRange.top) {
    (gridApi as GridApi).ensureIndexVisible(node.rowIndex, 'top');
  }
};

export const expandAncestors = (node: RowNode) => {
  if (!!node && node.level >= 0) {
    if (!!node.parent) {
      node.setExpanded(true);
    }
    expandAncestors(node.parent);
  }
};

export const expandDescendants = (node: RowNode): RowNode[] => {
  if (!!node && node.group) {
    node.setExpanded(true);
    const expandedNodes = [node];
    node.childrenAfterGroup.forEach((childNode: RowNode) => expandedNodes.push(...expandDescendants(childNode)));
    return expandedNodes;
  } else {
    return [];
  }
};

export const collapseDescendants = (node: RowNode): RowNode[] => {
  if (!!node && node.group && node.expanded) {
    node.setExpanded(false);
    const collapsedNodes = [node];
    node.childrenAfterGroup.forEach((childNode: RowNode) => collapsedNodes.push(...collapseDescendants(childNode)));
    return collapsedNodes;
  } else {
    return [];
  }
};

export const openContextMenuOnMouseClick = (params: CellClickedEvent): void => {
  const { api, event, node, column } = params;

  if (event instanceof MouseEvent) {
    event.stopPropagation();
    const cellValue = node.data[column.getColId()];

    api.showContextMenu({
      rowNode: node as RowNode,
      column: column,
      value: cellValue,
      x: event.clientX,
      y: event.clientY,
    });
  }
};
