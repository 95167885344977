<sc-icon
  iconName="error-circle-outline"
  class="text-danger"
  *ngIf="detailEntry.error"
  title="Error occurred when loading this detailEntry: {{ detailEntry.error }}"
></sc-icon>

<div class="layout-column">
  <div *ngIf="!isCard">
    <a href scPreventDefault *ngIf="!hasReadPermissions">
      {{ detailEntryDescription }}
      <small *ngIf="displayDebtorForAccounting">
        <strong> &nbsp;-&nbsp;{{ 'Matter.DebtorForAccounting' | translate }} </strong>
      </small>
    </a>
  </div>

  <div
    *ngIf="isCard"
    [floatUi]="popTemplate"
    (onShown)="showPopup()"
    (onHidden)="hidePopup()"
    [preventOverflow]="true"
    [hideOnMouseLeave]="true"
    [hideOnScroll]="true"
    boundaries="body"
    placement="right-start"
    applyClass="arrow-left"
    [positionFixed]="true"
    [showDelay]="500"
    [hideTimeout]="450"
    showTrigger="hover"
    [disabled]="!shouldDisplayPopper"
  >
    <span>{{ detailEntryDescription }}</span>
    <small *ngIf="displayDebtorForAccounting">
      <strong> &nbsp;-&nbsp;{{ 'Matter.DebtorForAccounting' | translate }} </strong>
    </small>
  </div>
  <!-- list of persons associated to this card role -->
  <!-- !isRecurringMatter condition can be lifted after docs api return valid assigned persons -->
  <div class="d-flex" *ngIf="displayPersons && !isRecurringMatter">
    <sc-tags
      [options]="personTagsOptions"
      *ngIf="displayPersons"
      [floatUi]="popTemplate"
      (popperOnShown)="showPopup()"
      (popperOnHidden)="hidePopup()"
      [hideOnMouseLeave]="true"
      [hideOnScroll]="true"
      boundaries="body"
      placement="left-start"
      applyArrowClass="arrow"
      [positionFixed]="true"
      [showDelay]="500"
      [hideTimeout]="500"
      [showTrigger]="'hover'"
      [disabled]="!shouldDisplayPopper"
    >
    </sc-tags>
    <sc-dropdown
      *ngIf="canAddPerson"
      [options]="addPersonDropdownOptions"
      class="align-items-center d-flex mt-1 ml-1"
    ></sc-dropdown>
  </div>

  <float-ui-content  #popTemplate>
    <sc-card-details-popup (mouseleave)="hidePopup()"></sc-card-details-popup>
  </float-ui-content>
</div>
