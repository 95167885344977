import { Component, HostBinding } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';

@Component({
  selector: 'sc-card-type-icon-cell',
  templateUrl: './card-type-icon-cell.component.html',
})
export class CardTypeIconCellComponent implements AgRendererComponent {
  @HostBinding('class.x-cell-recipient-type') type: string;
  persons: string[];
  iconType: string;

  constructor() {}

  agInit(params: any): void {
    this.configCardTypeIconCell(params);
  }

  getIcon() {
    if ((this.type === 'People' && this.persons.length === 1) || this.type === 'Staff') {
      return (this.iconType = 'user');
    }
    if (this.type === 'People' && this.persons.length > 1) {
      return (this.iconType = 'users');
    }
    if (this.type === 'Company' || this.type === 'Business') {
      return (this.iconType = 'business');
    }
    if (this.type === 'Government') {
      return (this.iconType = 'balance');
    }
    if (this.type === 'Trust') {
      return (this.iconType = 'payment');
    }
  }

  refresh(params: any): boolean {
    this.configCardTypeIconCell(params);
    return true;
  }

  private configCardTypeIconCell(params: any) {
    if (!!params && !!params.data) {
      const rowObj = params.data;
      this.type = params.getCardType(rowObj);
      this.persons = params.getCardPersons(rowObj) || [];
      this.iconType = this.getIcon();
    }
  }
}
