import { createSelector } from '@ngrx/store';
import { selectAppState } from '@app/core/store/selectors/app-state.selectors';
import { AppState } from '@app/core/store/reducers';
import * as fromSupportRequest from '../reducers/support-request.reducer';

// export const selectSupportRequestState = createFeatureSelector<fromSupportRequest.State>(AppSlice.SupportRequest);

export const selectSupportRequestState = createSelector(selectAppState, (state: AppState) =>
  !!state ? state.supportRequest : fromSupportRequest.INITIAL_STATE
);

export const selectDuration = createSelector(selectSupportRequestState, (state: fromSupportRequest.State) =>
  fromSupportRequest.selectDuration(state)
);

export const selectReason = createSelector(selectSupportRequestState, (state: fromSupportRequest.State) =>
  fromSupportRequest.selectReason(state)
);

export const selectSupportAgentEmail = createSelector(selectSupportRequestState, (state: fromSupportRequest.State) =>
  fromSupportRequest.selectSupportAgentEmail(state)
);

export const selectSupportAgentId = createSelector(selectSupportRequestState, (state: fromSupportRequest.State) =>
  fromSupportRequest.selectSupportAgentId(state)
);

export const selectSupportAgentName = createSelector(selectSupportRequestState, (state: fromSupportRequest.State) =>
  fromSupportRequest.selectSupportAgentName(state)
);

export const selectCode = createSelector(selectSupportRequestState, (state: fromSupportRequest.State) =>
  fromSupportRequest.selectCode(state)
);
