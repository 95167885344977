import { Action } from '@ngrx/store';
import { ICreateEmailOptions, IEmail, UpdateOutput } from '../../models';
import { EmailSignature } from '@app/features/+email/models';

// Create draft
export const CREATE_DRAFT = '[Email] create draft';
export const CREATE_DRAFT_SUCCESS = '[Email] create draft success';
export const CREATE_DRAFT_FAILURE = '[Email] create draft failure';

// Discard draft
export const DISCARD_DRAFT = '[Email] discard draft';
export const DISCARD_DRAFT_SUCCESS = '[Email] discard draft success';
export const DISCARD_DRAFT_FAILURE = '[Email] discard draft failure';

// Outlook
export const CREATE_DRAFT_IN_OUTLOOK = '[Email] create draft outlook';
export const CREATE_DRAFT_IN_OUTLOOK_FAILURE = '[Email] create draft outlook failure';

export class CreateDraft implements Action {
  readonly type = CREATE_DRAFT;
  constructor(public payload: EmailSignature) {}
}

export class CreateDraftSuccess implements Action {
  readonly type = CREATE_DRAFT_SUCCESS;
  constructor(public payload: Partial<IEmail>) {}
}

export class CreateDraftFailure implements Action {
  readonly type = CREATE_DRAFT_FAILURE;
  constructor(public payload: any) {}
}

export class DiscardDraft implements Action {
  readonly type = DISCARD_DRAFT;
  constructor(public payload: any) {}
}

export class DiscardDraftSuccess implements Action {
  readonly type = DISCARD_DRAFT_SUCCESS;
  constructor(public payload: IEmail) {}
}

export class DiscardDraftFailure implements Action {
  readonly type = DISCARD_DRAFT_FAILURE;
  constructor(public payload: any) {}
}

export class CreateDraftInOutlook implements Action {
  readonly type = CREATE_DRAFT_IN_OUTLOOK;
  constructor(public payload: UpdateOutput) {}
}

export class CreateDraftInOutlookFailure implements Action {
  readonly type = CREATE_DRAFT_IN_OUTLOOK_FAILURE;
  constructor(public payload: any) {}
}

export type DraftEmailActions =
  | CreateDraft
  | CreateDraftSuccess
  | CreateDraftFailure
  | DiscardDraft
  | DiscardDraftSuccess
  | DiscardDraftFailure
  | CreateDraftInOutlook
  | CreateDraftInOutlookFailure;
