import { Pipe, PipeTransform } from '@angular/core';
/*
 * Text snippet
 */
@Pipe({ name: 'snippetMiddle' })
export class SnippetMiddlePipe implements PipeTransform {
  transform(input: any, length: number, append?: any): string {
    if (input && typeof input === 'string') {
      length = length || 300;
      append = append || '...';
      append = input.length > length ? append : '';

      if (input.length <= length) {
        return input;
      }

      const sepLen = append.length;
        const charsToShow = length - sepLen;
        const frontChars = Math.ceil(charsToShow / 2);
        const backChars = Math.floor(charsToShow / 2);
      input = input.substr(0, frontChars) + append + input.substr(0, backChars);
    }

    return input;
  }
}
