import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { IDialogInfo, IDialogOptions, IConfirmModel } from '../../models';
import { ConfirmModalComponent } from '../../components/confirm-modal/confirm-modal.component';
import {
  hasProp,
  isFunction,
  omitObjValue,
} from '../../../../../server/modules/shared/functions/common-util.functions';

@Injectable()
export class DialogService {
  private static error: IDialogInfo;
  private static warning: IDialogInfo;
  private static confirm: IDialogInfo;
  private largeContentMinimum = 200;

  constructor(private _modalSvc: BsModalService, private _translateSvc: TranslateService) {
    this.setupService();
  }

  openDialog(data: { type: string; opts: IDialogOptions }): BsModalRef {
    const { type, opts } = data;
    switch (type) {
      case 'confirm':
        this.confirm(opts);
        return;

      case 'warning':
        this.warning(opts);
        return;

      case 'error':
        this.error(opts);
        return;
    }
  }

  error(opts: IDialogOptions): BsModalRef {
    const dialogData = { ...DialogService.error, ...opts };
      const { title, message, actionText, closeText, showCancel } = dialogData;
    return this.showDialog({ title, message, actionText, closeText, showCancel }, opts);
  }

  warning(opts: IDialogOptions): BsModalRef {
    const dialogData = { ...DialogService.warning, ...opts };
      const { title, message, actionText, closeText, showCancel } = dialogData;
    return this.showDialog({ title, message, actionText, closeText, showCancel, type: 'warning' }, opts);
  }

  confirm(opts: IDialogOptions): BsModalRef {
    const dialogData = { ...DialogService.confirm, ...opts };
      const { title, message, actionText, closeText, showCancel } = dialogData;
    return this.showDialog({ title, message, actionText, closeText, showCancel, type: 'confirm' }, opts);
  }

  private setupService(): void {
    DialogService.error = {
      title: this._translateSvc.instant('Core.Dialog.Error.Title'),
      message: this._translateSvc.instant('Core.Dialog.Error.Message'),
      actionText: this._translateSvc.instant('Core.Dialog.Error.Action.Text'),
    };
    DialogService.warning = {
      title: this._translateSvc.instant('Core.Dialog.Warning.Title'),
      message: this._translateSvc.instant('Core.Dialog.Warning.Message'),
      actionText: this._translateSvc.instant('Core.Dialog.Warning.Action.Text'),
    };
    DialogService.confirm = {
      title: this._translateSvc.instant('Core.Dialog.Confirm.Title'),
      message: this._translateSvc.instant('Core.Dialog.Confirm.Message'),
      actionText: this._translateSvc.instant('Core.Dialog.Confirm.Action.Text'),
      closeText: this._translateSvc.instant('Core.Dialog.Confirm.Close.Text'),
    };
  }

  private showDialog(data: Partial<IConfirmModel>, opts: IDialogOptions): BsModalRef {
    const modalClose$ = new Subject<boolean>();
    const bsModalRef: BsModalRef = this._modalSvc.show(ConfirmModalComponent, {
      backdrop: 'static',
      class: `x-modal-alert ${Math.max(data.message.length, data.title.length) > this.largeContentMinimum ? 'modal-lg' : 'modal-sm'}`,
      initialState: {
        ...data,
        options: omitObjValue(opts, Object.keys(data)),
        onClose: new Subject<boolean>(),
      },
    });

    bsModalRef.content.onClose.pipe(takeUntil(modalClose$)).subscribe((confirmed: boolean) => {
      if (hasProp(opts, 'onClose') && isFunction(opts.onClose)) {
        opts.onClose(confirmed);
      }
      modalClose$.next(true);
    });
    return bsModalRef;
  }
}
