export interface ExtensionHelper {
  title: string;
  types: string[];
}

export const WordDocumentExtension: ExtensionHelper = {
  title: 'Word Document',
  types: ['doc', 'docx', 'msword', 'DOC', 'DOCX', 'MSWORD', 'vnd.openxmlformats-officedocument.wordprocessingml.document'],
};

export const ImageExtension: ExtensionHelper = {
  title: 'Image',
  types: ['tiff', 'tif', 'jpg', 'gif', 'png', 'jpeg', 'bmp', 'TIFF', 'TIF', 'JPG', 'GIF', 'PNG', 'JPEG', 'BMP'],
};

export const AudioExtension: ExtensionHelper = {
  title: 'Audio',
  types: ['mp3', 'wav', 'flac', 'aac', 'MP3', 'WAV', 'FLAC', 'AAC'],
};

export const VideoExtension: ExtensionHelper = {
  title: 'Video',
  types: ['mp4', 'mov', 'avi', 'MP4', 'MOV', 'AVI']
};

export const EmailExtension: ExtensionHelper = {
  title: 'Email',
  types: ['msg', 'eml', 'MSG', 'EML'],
};

export const ExcelExtension: ExtensionHelper = {
  title: 'Excel',
  types: ['xls', 'xlsx', 'vnd.ms-excel', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'XLS', 'XLSX'],
};

export const PdfExtension: ExtensionHelper = {
  title: 'PDF Document',
  types: ['pdf', 'PDF'],
};

export const CommentExtension: ExtensionHelper = {
  title: 'Comment',
  types: ['html', 'HTML'],
};

export const PDFConversionInvalidExtensions = [
  ...AudioExtension.types,
  ...VideoExtension.types,
  ...ImageExtension.types
];
