import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sc-img-viewer',
  templateUrl: './img-viewer.component.html',
})
export class ImgViewerComponent implements OnInit {
  @Input()
  set imgUrl(value: string) {
    this.hasError = false;
    this._imgUrl = value;
  }
  get imgUrl(): string {
    return this._imgUrl;
  }

  @Input()
  token: string;

  get hasUrlToPreview(): boolean {
    return !!this.previewUrl;
  }

  get previewUrl(): string {
    return `${this.imgUrl}?access_token=${this.token}`;
  }

  @Output()
  onImgLoaded = new EventEmitter();

  hasError: boolean;
  private _imgUrl: string;

  constructor() {}

  ngOnInit() {}

  onError(err): void {
    this.hasError = true;
    this.onImgLoaded.emit();
  }

  imageLoaded() {
    this.onImgLoaded.emit();
  }
}
