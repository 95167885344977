<div [ngClass]="wrapperClass">
  <div class="badge x-badge-tag-content pr-0" *ngFor="let tag of tags" (click)="tagClicked($event, tag.item)">
    <sc-icon class="x-badge-tag-icon" *ngIf="!!tag.iconClass" [iconName]="tag.iconClass"></sc-icon>
    <div class="x-badge-tag-label">{{ itemLabel(tag.item) }}</div>
    <sc-icon
      class="x-badge-tag-icon is-clickable"
      *ngIf="!readonly"
      [iconName]="'close-grid-20'"
      (click)="tagRemoved($event, tag.item)"
    ></sc-icon>
  </div>
  <ng-content></ng-content>
</div>
