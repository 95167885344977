import { createSelector } from '@ngrx/store';
import * as fromBatchSearch from '../reducers/batch-search.reducer';
import { selectBatchSearchState } from '../reducers';

export const selectBatchSearchLoading = createSelector(selectBatchSearchState, fromBatchSearch.selectLoading);
export const selectBatchSearchFiltering = createSelector(selectBatchSearchState, fromBatchSearch.selectFiltering);

export const selectBatchSearchTypesLoaded = createSelector(
  selectBatchSearchState,
  fromBatchSearch.selectBatchTypesLoaded
);

export const selectBatchSearchBatchTypes = createSelector(selectBatchSearchState, fromBatchSearch.selectBatchTypes);

export const selectBatchSearchSelectResponse = createSelector(selectBatchSearchState, (state: fromBatchSearch.State) =>
  fromBatchSearch.selectOnCloseResponse(state)
);

export const selectBatchSearchFilter = createSelector(selectBatchSearchState, (state: fromBatchSearch.State) =>
  fromBatchSearch.selectBatchFilter(state)
);

export const selectBatchSearchSortInfo = createSelector(selectBatchSearchState, (state: fromBatchSearch.State) =>
  fromBatchSearch.selectBatchSearchSortInfo(state)
);

export const selectAllBatches = fromBatchSearch.adapter.getSelectors(selectBatchSearchState).selectAll;
