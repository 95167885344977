export interface CreateEmailSignatureOptions {
  content?: string;
  includeInForwardReply?: boolean;
  includeInNew?: boolean;
}

export type EmailSignature = EmailSignatureDTO;

export const EmptyEmailSignature: EmailSignature = {
  content: '',
  includeInForwardReply: false,
  includeInNew: false,
};

export interface EmailSignatureDTO {
  content: string;
  includeInForwardReply: boolean;
  includeInNew: boolean;
}

export interface OpenEmailSignatureOptions {
  signature: EmailSignature;
}

export interface EmailSignatureModalResolve extends OpenEmailSignatureOptions {
  title: string;
}

export interface UpdateEmailSignatureRequest {
  content: string;
  includeInNew: boolean;
  includeInForwardReply: boolean;
}
