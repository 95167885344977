import { from as observableFrom, Observable, of, throwError } from 'rxjs';
import { catchError, map as rxjsMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import Dexie from 'dexie';

import { IMatterListEntry, IMatterListResponseSchema, MatterListMetaInfo } from '../../models';
import { matterFileNumberComparator } from '@app/features/+matter-list/functions';
import { DexieService } from '@app/core/services/dexie/dexie.service';
import { PlatformService } from '@app/core/services/platform/platform.service';
import { BaseStorageService } from '@app/shared/services/base/base-storage.service';
import { LogService } from '@app/core/services/log/log.service';

@Injectable()
export class MatterListStorageService extends BaseStorageService<IMatterListEntry> {
  metaKey = '__MATTER_LIST_META__';
  metaTable: Dexie.Table<MatterListMetaInfo, string>;

  constructor(private _logSvc: LogService, private _dexieService: DexieService, private _platformSvc: PlatformService) {
    super();
    if (this._platformSvc.isBrowser) {
      this.table = this._dexieService.table('matters');
      this.metaTable = this._dexieService.table('mattersMeta');
    }
  }

  upsertAll(matterResponse: IMatterListResponseSchema) {
    if (this._platformSvc.isBrowser) {
      const metaInfo: Partial<MatterListMetaInfo> = {
        id: this.metaKey,
        lastRowVer: matterResponse.lastRowVer,
      };

      return this.upsertMeta(metaInfo).then(() => this.bulkPut(matterResponse.data));
    }
  }

  upsertMeta(partialMetaInfo: Partial<MatterListMetaInfo>) {
    if (this._platformSvc.isBrowser) {
      return this.metaTable
        .get(this.metaKey)
        .then((currentMeta: MatterListMetaInfo) => {
          const metaInfo = {
            ...currentMeta,
            ...partialMetaInfo,
            id: this.metaKey,
          };
          return this.metaTable.put(metaInfo);
        })
        .catch((err) => {
          this._logSvc.error('indexed db matter meta: ', err);
          throw err; // re-throw the error to the callers of this method.
        });
    }
  }

  getMeta(): Observable<MatterListMetaInfo> {
    if (this._platformSvc.isBrowser) {
      return observableFrom(this.metaTable.get(this.metaKey)).pipe(
        catchError((err) => {
          this._logSvc.error('indexed db matter meta: ', err);
          return throwError(() => err); // re-throw the error to the callers of this method.
        })
      );
    } else {
      return of({} as MatterListMetaInfo);
    }
  }

  // Selector to return active matters only. Used for matter-select within forms. Pre-sort with fileNumber.
  // filter out all deleted, un-accessible or archived matters
  // LSDK-84 - included showArchived argument which is passed by leaphost sdk for matter.selectMatter command.
  getActiveMatters(showArchived: boolean = false): Observable<IMatterListEntry[]> {
    if (this._platformSvc.isBrowser) {
      return this.getAll().pipe(
        rxjsMap((matters) => {
          const activeMatters =
            matters?.filter(
              (matter) =>
                matter.deleteCode === 0 &&
                !!matter.accessible &&
                (!matter.isArchived || matter.isArchived === showArchived)
            ) || [];
          activeMatters.sort((matterA: IMatterListEntry, matterB: IMatterListEntry) => matterFileNumberComparator(matterA.fileNumber, matterB.fileNumber));
          return activeMatters;
        })
      );
    }
  }

  handleDatabaseError(error: any): any {
    this._logSvc.error('indexed db matter list: ', error);
    return null;
  }
}
