import { createSelector } from '@ngrx/store';
import { selectAppState } from '@app/core/store/selectors/app-state.selectors';
import { AppState } from '../reducers';
import * as fromCardFilters from '../reducers/card-filters.reducer';

export const selectCardFiltersState = createSelector(selectAppState, (state: AppState) =>
  !!state ? state.cardFilters : fromCardFilters.INITIAL_STATE
);

export const selectCardFiltersDefaultFilters = createSelector(selectCardFiltersState, (state: fromCardFilters.State) =>
  fromCardFilters.selectDefaultFilters(state)
);

export const selectCardFiltersCustomFilters = createSelector(selectCardFiltersState, (state: fromCardFilters.State) =>
  fromCardFilters.selectCustomerFilters(state)
);
