import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { LogService } from '@app/core/services';
import { Correspondence, IDoc, IDocsFolder } from '@app/shared/models';
import { toRowCollection } from '@app/features/+correspondence/functions';
import { selectRoot } from '@app/features/+correspondence/store/selectors/folder.selectors';
import { isFolder, isPendingPrecedent } from '@app/shared/functions';

@Injectable({
  providedIn: 'root',
})
export class PdfCorrespondenceService {
  constructor(private _store: Store<any>, private _log: LogService) {
    this._log.init('pdf-correspondence-service');
  }

  getCorrespondences(): Observable<Correspondence[]> {
    const rootFolder$: Observable<IDocsFolder> = this._store.pipe(select(selectRoot));
    const rowCollectionProject = (root: IDocsFolder) => {
      if (!root) {
        return [];
      }
      return toRowCollection(root);
    };
    return rootFolder$.pipe(map(rowCollectionProject));
  }

  getCorrespondencesWithHierarchy(): Observable<Correspondence[]> {
    const rootFolder$: Observable<IDocsFolder> = this._store.pipe(select(selectRoot));
    return rootFolder$.pipe(
      map(root => this.extendCorrespondenceWithHierarchyProp(root)),
      map(collection => collection.filter((x) => isFolder(x) || (!(x as IDoc).deleted && !isPendingPrecedent(x as IDoc))))
    );
  }

  private extendCorrespondenceWithHierarchyProp = (root: IDocsFolder, folderIds?: string[]): Correspondence[] => {
    if (root === undefined) {
      return [];
    }

    const documentFromFolder = root.folders.length > 0
      ? root.folders.map(folder =>
        this.extendCorrespondenceWithHierarchyProp(folder, (folderIds ?? []).concat(folder.id || ''))
          .flatMap(n => n)).flatMap(m => m)
      : [];

    return [{ ...root, hierarchy: folderIds } as Correspondence]
      .concat(documentFromFolder ?? [])
      .concat(root.fullDocuments?.map(d => ({ ...d, hierarchy: (folderIds ?? []).concat(d.id) })) ?? []);
  };
}
