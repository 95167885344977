import { Component, HostBinding, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorParams } from '@ag-grid-community/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { AggridEditorAbstract } from '@app/sharedaggrid/components/aggrid-editor/aggrid-editor.abstract';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-text-editor-cell',
  templateUrl: './sc-text-cell-editor.component.html',
})
export class ScTextCellEditorComponent extends AggridEditorAbstract implements OnInit, OnDestroy {
  @ViewChild('input', { read: ViewContainerRef, static: true }) public input;

  public editForm: FormGroup;
  public formSub: Subscription;
  private unsub = new Subject<void>();

  constructor(private _formBuilder: FormBuilder) {
    super();
    this.createForm();
  }

  @HostBinding('class.w-100')
  agInit(params: ICellEditorParams): void {
    super.agInit(params);
    const defaultValue =
      (isFunction(params['getContent']) ? params['getContent'](params.node.data) : params.value) || '';
    this.patchValue({ name: defaultValue });
  }

  getValue(): any {
    return this.value;
  }

  patchValue(value: { name: string }): void {
    this.editForm.patchValue(value);
  }

  focusIn() {
    this.input.element.nativeElement.firstElementChild.focus();
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  private createForm(): void {
    this.editForm = this._formBuilder.group({
      name: '',
    });
    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges(): void {
    this.formSub = this.editForm.valueChanges
      .pipe(
        distinctUntilChanged(null, (data) => data.name),
        takeUntil(this.unsub) // important: this operator must be last
      )
      .subscribe((formData: { name: string }) => {
        this.value = formData.name;
      });
  }

  public handleBlurOnInput(inputValue: string): void {
    this.editForm.patchValue({
      name: inputValue,
    });
  }
}
