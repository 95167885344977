<div class="x-toolbar-actions x-toolbar-actions-100" role="toolbar">
  <button class="btn x-btn-default btn-sm m-auto" (click)="onActivityBtnClick($event)" *ngIf="!showSearchBar">
    CHANGE ACTIVITY CODE
  </button>
  <!--<button class="btn x-btn-default btn-sm m-auto">CHANGE MATTER</button>-->
  <sc-input-with-icon-debounce
    *ngIf="showSearchBar"
    [inputValue]="query"
    placeholder="Search activity code..."
    (keyup)="search.emit($event.target.value)"
    iconName="magnifier-grid-20"
  ></sc-input-with-icon-debounce>
</div>
