import { Component, HostBinding, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import {
  selectCurrentMatter,
  selectRouterUrl,
  selectUIPreference,
  selectRecurringMatterName,
  selectWalkmeReady,
  selectFirmDetails,
} from '@app/core/store';
import { select, Store } from '@ngrx/store';
import { debounceTime, filter, map, takeUntil } from 'rxjs';
import { IMatterListEntry } from '@app/features/+matter-list/models';

import {
  AuthService,
  BrandService,
  LogService,
  PermissionsService,
  PlatformService,
  PubnubService,
  StartupService,
  EventBusService,
} from '@app/core/services';
import { NavigationEnd, Router } from '@angular/router';
import { selectBrowserTitlePrimaryPageName } from '@app/core/store/selectors/browser-title-state.selector';
import { ITopNavAction, MatterToolBarAdditionalActions, ESiriusEvents } from '@app/core/models';
import * as notificationActions from '@app/features/+notification/store/actions';
import * as appActions from '@app/core/store/actions/app.action';
import * as feeTimerActions from '@app/features/fee-timer/store/actions';
import * as browserTitleActions from '@app/core/store/actions';

@Component({
  selector: 'sc-main',
  templateUrl: './main.component.html',
})
export class MainComponent implements OnInit, OnDestroy {
  matterTitle$: Observable<string>;
  walkmeReady$: Observable<boolean>;
  showMatterToolbar: boolean;
  showRecurringMatterToolbar: boolean;
  recurringMatterId: string;
  isAsideExpanded = false;
  toolbarAction$: Observable<ITopNavAction>;
  currentMatter$: Observable<IMatterListEntry>;
  public routeEventSub: Subscription;
  public matterSub: Subscription;
  public superDiaryEnabled: boolean;

  private isServer = true;
  private unsub = new Subject<void>();

  constructor(
    private platformSvc: PlatformService,
    private store: Store<any>,
    private router: Router,
    private permissionsSvc: PermissionsService,
    private pubnubSvc: PubnubService,
    private authSvc: AuthService,
    private startupSvc: StartupService,
    private log: LogService,
    private eventBusSvc: EventBusService,
    private vcr: ViewContainerRef,
  ) {
    this.isServer = this.platformSvc.isServer;
    this.routeEventSub = this.router.events
      .pipe(
        // we decide whether to show the matterToolBar when the route navigation is end/successful
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        takeUntil(this.unsub)
      )
      .subscribe(() => {
        this.checkShowToolbar();
        this.checkShowRecurringMatterToolbar();
      });
  }

  @HostBinding('class')
  get hostClasses(): string {
    return 'layout-column';
  }

  ngOnInit() {
    // we only loading notification in here
    // bc in new-win routes which would not load this component do not need to get any notification data
    this.store.dispatch(new notificationActions.LoadNotificationsDbStart(null));
    this.store.dispatch(new appActions.LoadUserPreferenceStart(null));

    this.startupSvc.bootstrapPrecedentView();

    this.matterTitle$ = combineLatest([
      this.store.select(selectCurrentMatter),
      this.store.select(selectRecurringMatterName),
      this.store.select(selectRouterUrl),
    ]).pipe(
      map((data: [IMatterListEntry, any, string]) => {
        const [matter, recurringMatterName, routerUrl] = data;
        if (!routerUrl) {
          return '';
        }

        const personRegex = /^\/(persons)(\(.+\))*(\??.*)$/gim;
        const cardRegex = /^\/(cards)(\(.+\))*(\??.*)$/gim;
        const mattersRegex = /^\/(matters)(\(.+\))*(\/?)$/gim;
        const previewRegex = /^\/(preview)(\(.+\))*(\??.*)$/gim;
        const bankAccountingRegex = /^\/(trust-bank-register)(\(.+\))*(\??.*)$/gim;
        const recurringMatterRegex = /\/(recurring-matters.*)/gim;
        if (cardRegex.test(routerUrl)) {
          return 'Global.Menu.Cards.Header';
        } else if (previewRegex.test(routerUrl)) {
          return 'Preview Document';
        } else if (mattersRegex.test(routerUrl)) {
          return 'Global.Menu.Matters.Header';
        } else if (bankAccountingRegex.test(routerUrl)) {
          return 'Global.Menu.Trust.BankAccountRegister';
        } else if (personRegex.test(routerUrl)) {
          return 'Global.Menu.Persons.Header';
        } else if (!!recurringMatterName && recurringMatterRegex.test(routerUrl)) {
          return recurringMatterName;
        } else {
          if (!!matter) {
            return `${matter.fileNumber} - ${this.getMatterClientAndDesc(matter)}`;
          } else {
            return 'Global.Menu.Matters.Header';
          }
        }
      })
    );

    this.walkmeReady$ = this.store.pipe(select(selectWalkmeReady));

    this.matterSub = this.store
      .pipe(select(selectCurrentMatter), takeUntil(this.unsub))
      .subscribe((matterEntry: IMatterListEntry) => {
        if (matterEntry) {
          this.store.dispatch(new feeTimerActions.FeeTimerActivityCodeLoad(matterEntry.matterId));
        }
      });

    this.store
      .pipe(
        select(selectFirmDetails),
        filter((firmDetails) => !!firmDetails),
        takeUntil(this.unsub)
      )
      .subscribe((firmDetails) => {
        this.superDiaryEnabled = BrandService.isLeap() ? !!firmDetails.superDiary : true;
      });

    if (!this.isServer) {
      this.setupSubscriptions();
      this.permissionsSvc.setupPermissions();
    }

    this.pubNubInit();
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }

  private setupSubscriptions(): void {
    this.currentMatter$ = this.store.pipe(select(selectCurrentMatter));

    this.store
      .pipe(
        select(selectUIPreference),
        map((uiPreference) => !!uiPreference?.asideExpanded),
        debounceTime(300),
        takeUntil(this.unsub)
      )
      .subscribe((isAsideExpanded) => {
        this.isAsideExpanded = isAsideExpanded;
      });

    this.toolbarAction$ = this.store.pipe(
      select(selectBrowserTitlePrimaryPageName),
      map((pageName) => MatterToolBarAdditionalActions[pageName] as ITopNavAction)
    );

    this.eventBusSvc
      .listen(ESiriusEvents.CreateLeapAppHiddenComponent)
      .pipe(takeUntil(this.unsub))
      .subscribe(async (data) => {
        const { LeapHostHiddenComponent } = await import(
          '@app/features/+leaphost/pages/+hidden/leaphost-hidden.component'
        );
        const componentRef = this.vcr.createComponent(LeapHostHiddenComponent);

        componentRef.instance.data = { ...data, componentRef };
      });
  }

  private checkShowToolbar(): void {
    const routerUrl = this.router.url.toString();
    /**
     * Hide main toolbar nav if one of the followings:
     * 1) matter-list or card-list
     * 2) modal routes wihtin matter-list or card-list
     * 3) Popout Window
     * 3) the above three with one trailing '/'
     */
    const regex = /\/(matters.+)/gim;
    this.showMatterToolbar =
      regex.test(routerUrl) && !routerUrl.includes('matters?') && !routerUrl.includes('matters(popup');
  }

  private checkShowRecurringMatterToolbar(): void {
    const routerUrl = this.router.url.toString();
    const regex = /\/(recurring-matters.*)/gim;
    this.showRecurringMatterToolbar = regex.test(routerUrl) && !routerUrl.includes('recurring-matters(popup');

    if (!!this.showRecurringMatterToolbar) {
      this.recurringMatterId = routerUrl.match(/[\da-zA-Z]{8}-([\da-zA-Z]{4}-){3}[\da-zA-Z]{12}/)[0];
    }
  }

  private getMatterClientAndDesc(matter: IMatterListEntry): string {
    // transcribe 4D code from DBa_Matters_getClientAndDesc
    if (!matter) {
      return '';
    }
    const clientName = this.cropText(matter.firstDescription, 40);
    const matterType = this.cropText(matter.matterType, 40);
    const description = this.cropText(matter.customDescription, 40);
    const description2 = this.cropText(matter.secondDescription, 40);

    let s = clientName;
    if (!description) {
      return `${this.appendComma(s)} ${matterType}`;
    } else {
      s = `${this.appendComma(s)} ${description}`;
    }
    if (description2) {
      s = `${s} | ${description2}`;
    }
    return s;
  }

  private appendComma(s: string): string {
    if (!s) {
      return '';
    }
    const blacklist = [',', '.', '?', '!', ';', ':'];
    if (blacklist.find((c) => s[s.length - 1] === c)) {
      return s;
    }
    return `${s},`;
  }

  private cropText(s: string, length: number): string {
    if (!s) {
      return '';
    }
    s = s.trim();
    if (!s) {
      return '';
    }
    if (s.length < length) {
      return s;
    }
    if (length < 3) {
      return s.substr(0, length);
    }
    return s.substr(0, length - 3) + '...';
  }

  private pubNubInit(): void {
    if (this.platformSvc.isBrowser) {
      if (!this.authSvc.decodedToken) {
        this.log.warn('skipping pubnubinit because of missing token');
        return;
      }
      const firmId = this.authSvc.decodedToken.firmId;
      this.pubnubSvc.init();
      this.pubnubSvc.subscribeFirm(firmId);
      this.pubnubSvc.subscribeLeapCalc(firmId);
      this.pubnubSvc.subscribeMatterNotification(firmId);

      this.pubnubSvc.subscribeToFirmAuth(firmId);
      this.authSvc.userDetails().then((userDetail) => {
        if (userDetail?.userId) {
          this.pubnubSvc.subscribeToUserAuth(userDetail.userId);
        }
      });
    }
  }

  public setPrimaryPageName(): void {
    this.store.dispatch(new browserTitleActions.PrimaryPageTitleInit());
  }
}
