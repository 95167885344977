import * as actions from '../actions';
import { ITaskCode, ITaskCodeUiConfig } from '@app/shared/models';

export interface State {
  searchText: string;
  selectedTaskCode: ITaskCode;
  isMultiSelect: boolean;
  onCloseResponse: ITaskCode;
  taskCodes: ITaskCode[];
  uiConfig: ITaskCodeUiConfig;
}

export const initialState: State = {
  searchText: '',
  selectedTaskCode: null,
  isMultiSelect: false,
  onCloseResponse: null,
  taskCodes: [],
  uiConfig: null,
};

export const reducer = (state = initialState, action: actions.TaskCodeSelectorModalAction): State => {
  switch (action.type) {
    case actions.TASK_CODE_SELECTOR_MODAL_SELECT: {
      return {
        ...state,
        selectedTaskCode: action.payload,
      };
    }

    case actions.TASK_CODE_SELECTOR_MODAL_SEARCH: {
      return {
        ...state,
        searchText: action.payload,
      };
    }

    case actions.TASK_CODE_SELECTOR_MODAL_CLOSE: {
      return {
        ...state,
        onCloseResponse: state.selectedTaskCode,
        selectedTaskCode: null,
      };
    }

    case actions.TASK_CODE_SELECTOR_ADD_TASK_CODES: {
      return {
        ...state,
        taskCodes: action.payload,
      };
    }

    case actions.TASK_CODE_SELECTOR_SET_UI_CONFIG: {
      return {
        ...state,
        uiConfig: action.payload,
      };
    }

    case actions.TASK_CODE_SELECTOR_MODAL_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export const selectSearchText = (state: State) => state.searchText;
export const selectSelectedTaskCode = (state: State) => state.selectedTaskCode;
export const selectIsMultiSelect = (state: State) => state.isMultiSelect;
export const selectOnCloseResponse = (state: State) => (state ? state.onCloseResponse : null);
export const selectTaskCodes = (state: State) => state.taskCodes;
export const selectSearchPlaceholder = (state: State) =>
  state && state.uiConfig ? state.uiConfig.searchPlaceHolder : '';
export const selectTitle = (state: State) => (state && state.uiConfig ? state.uiConfig.title : '');
export const selectNoGridOverlay = (state: State) => (state && state.uiConfig ? state.uiConfig.noGridOverlay : '');
