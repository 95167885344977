<label *ngIf="label">{{ label | translate }}</label>
<div class="x-input-group">
  <ng-select
    #select
    class="x-select-truncate"
    [items]="filteredItems"
    bindLabel="fullName"
    bindValue="__id"
    (change)="onStaffSelected($event)"
    [clearable]="clearable"
    [(ngModel)]="value"
    [disabled]="isDisabled"
    appendTo="body"
    (open)="filterItemsOnOpen()"
    (close)="filterItemsOnClose()"
   >
    <ng-template ng-label-tmp let-item="item"> {{ staffLabel(item) }} </ng-template></ng-select
  >
  <div class="x-input-group-append">
    <button class="btn x-btn-default x-btn-icon-only" (click)="openStaffSelector()" [disabled]="isDisabled">
      <sc-icon class="x-icon-sm" iconName="browse"></sc-icon>
    </button>
  </div>
</div>
