import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[scPreventDefault]',
})
export class PreventDefaultDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('click', ['$event'])
  onClick($event) {
    $event.preventDefault();
  }
}
