import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { BrandService, PlatformService, StartupService } from '@app/core/services';
import { FirmType } from '@app/shared/models';


@Injectable({
  providedIn: 'root',
})
export class UniversalGuard  {
  constructor(
    private _platformSvc: PlatformService,
    private _brandSvc: BrandService,
    private _router: Router,
    private _startupSvc: StartupService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isBrowser = this._platformSvc.isBrowser;

    if (isBrowser) {
      const isAU = this._brandSvc.isAU();
      const pathname = 'conveyancing';
      const isConveyancingFirm = this._startupSvc.userDetails.firmType === FirmType.Conveyancer;

      if (isAU && isConveyancingFirm && state.url.indexOf(pathname) === -1) {
        return this._router.navigateByUrl(`/matters(popup:${pathname})`, { skipLocationChange: true });
      }
    }

    return isBrowser;
  }
}
