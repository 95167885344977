import { Observable, of as observableOf } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { BaseService } from '../base/base.service';
import { DialogService } from '../dialog/dialog.service';
import { OnlineLauncherService } from './online-launcher/online-launcher.service';
import {
  AutomationActionType,
  AutomationHostApplicationType,
  IAutomationOptions,
  IOfficeMessageInfo,
  IOpenDocumentOptions,
  IWopiMessageInfo,
} from '../../models';
import { mapExtToHostApplication } from '@app/shared/functions/document-automation.functions';
import { getObjValue } from '../../../../../server/modules/shared/functions/common-util.functions';
import { CustomizedPrecedentProps, PrecedentType } from '@app/features/+precedent/models';

@Injectable()
export class DocumentAutomationService extends BaseService {
  constructor(
    private http: HttpClient,
    private _dialogSvc: DialogService,
    private _onlineLauncherSvc: OnlineLauncherService
  ) {
    super();
  }

  createFromOrEditPrecedentOnline(
    options: IAutomationOptions,
    customizedProps: CustomizedPrecedentProps
  ): Observable<void> {
    const officeMessage: Partial<IOfficeMessageInfo> = toOfficeMessageWithPrecedent(options, customizedProps);
    const openOnlineOptions: IOpenDocumentOptions = {
      openDocumentFnc: this.openDocumentOnline.bind(this),
      officeMessage,
      options,
    };

    this.openDocument(openOnlineOptions);
    return observableOf(null);
  }

  editDocumentOnline(options: IAutomationOptions): Observable<void> {
    const { action, matterId, folderId, docInfo } = options;
    const { documentId, ext, isDesktopOnly, isPending } = docInfo;
    const officeMessage: Partial<IOfficeMessageInfo> = {
      action,
      desktopOnly: isDesktopOnly,
      ext,
      folderId,
      id: documentId,
      documentId,
      matterId,
      origin: document.location.origin,
      hostApplication: mapExtToHostApplication(ext),
      isPending,
    };

    this.openDocument({
      openDocumentFnc: this.openDocumentOnline.bind(this),
      officeMessage,
      options,
    });

    return observableOf(null);
  }

  newContainerOnline(options: IAutomationOptions): Observable<void> {
    const officeMessage: Partial<IOfficeMessageInfo> = toOfficeMessageWithContainer(options);
    const openOnlineOptions: IOpenDocumentOptions = {
      openDocumentFnc: this.openDocumentOnline.bind(this),
      officeMessage,
      options,
    };

    this.openDocument(openOnlineOptions);
    return observableOf(null);
  }

  // editContainerOnline(options: IAutomationOptions): Observable<void> {
  //   const { action, matterId, folderId, docInfo } = options,
  //     { documentId, ext, isDesktopOnly } = docInfo;
  //   const officeMessage: Partial<IOfficeMessageInfo> = {
  //     action,
  //     desktopOnly: isDesktopOnly,
  //     ext,
  //     folderId,
  //     id: documentId,
  //     documentId,
  //     matterId,
  //     origin: document.location.origin,
  //     hostApplication: mapExtToHostApplication(ext),
  //   };
  //
  //   this.openDocument({
  //     openDocumentFnc: this.openDocumentOnline.bind(this),
  //     officeMessage,
  //     options,
  //   });
  //
  //   return observableOf(null);
  // }

  // newMail(options: IAutomationOptions): Observable<void> {
  //   const { action, matterId, folderId, emailInfo } = options;
  //   const officeMessage: Partial<IOfficeMessageInfo> = {
  //     action,
  //     folderId,
  //     matterId,
  //     hostApplication: AutomationHostApplicationType.Outlook,
  //     email: emailInfo,
  //   };
  //
  //   this.openDocument(
  //     {
  //       openDocumentFnc: this.openDocumentOffline.bind(this),
  //       officeMessage,
  //       options,
  //     },
  //     true
  //   );
  //
  //   return observableOf(null);
  // }

  private openDocument(openDocumentOptions: IOpenDocumentOptions, skipWopi: boolean = false): void {
    const { openDocumentFnc, officeMessage, options } = openDocumentOptions;
    const url: string = this.urlJoin(this.wopiPath, 'api/v1/wopi/messages/');

    if (skipWopi) {
      openDocumentFnc(officeMessage);
      return;
    }

    // send message to wopi prior to launching word.
    const wopiMessage: Partial<IWopiMessageInfo> = toWopiMessage(officeMessage, options);
    this.http
      .put(url, wopiMessage, { responseType: 'text' })
      .pipe(
        map(() => true),
        take(1),
        catchError(() => observableOf(false))
      )
      .subscribe((success: boolean) => {
        if (success) {
          openDocumentFnc(officeMessage);
        } else {
          this._dialogSvc.error({ message: 'There has been an error.' });
        }
      });
  }

  // private openDocumentOffline(info: Partial<IOfficeMessageInfo>): void {
  //   // this._offlineLauncherSvc.openDocumentOffline(info);
  // }

  private openDocumentOnline(info: Partial<IOfficeMessageInfo>): void {
    this._onlineLauncherSvc.openDocumentOnline(info);
  }
}

/** Message transform functions **/
const toWopiMessage = (
  officeMessage: Partial<IOfficeMessageInfo>,
  options: IAutomationOptions
): Partial<IWopiMessageInfo> => ({
  ...officeMessage,
  emailData: getObjValue(options, 'pdfInfo', {}),
  html5mode: true,
});

const toOfficeMessageWithContainer = (options: IAutomationOptions): Partial<IOfficeMessageInfo> => {
  const { action, matterId, folderId, containerInfo } = options;
  const { customPrecedent } = containerInfo;
  return {
    action,
    ext: customPrecedent.ext,
    folderId,
    id: uuidv4(),
    matterId,
    origin: document.location.origin,
    precedentId: customPrecedent.id,
    hostApplication: mapExtToHostApplication(customPrecedent.ext),
    isContainer: true,
    ...options.containerInfo,
  };
};

const toOfficeMessageWithPrecedent = (
  options: IAutomationOptions,
  customizedProps: CustomizedPrecedentProps
): Partial<IOfficeMessageInfo> => {
  const { action, matterId, folderId, precedentInfo, taskId } = options;
  const { precedent, isContainer } = precedentInfo;
  const isCreatingDocument = action === AutomationActionType.New;

  return {
    ...{
      action,
      ext: precedent.type,
      folderId,
      id: uuidv4(),
      matterId,
      origin: document.location.origin,
      precedentId: customizedProps?.precedentId || precedent.shortcutId,
      taskId,
      hostApplication: mapExtToHostApplication(precedent.type),
    },
    ...(isCreatingDocument
      ? {
          desktopOnly: precedent.openInDesktop,
          isContainer: false,
          isCustomPrecedent:
            customizedProps?.isChangeRequired || precedent.precedentType === PrecedentType.Custom ? true : false,
        }
      : {
          isContainer,
          isCustomPrecedent:
            customizedProps?.isChangeRequired ||
            precedent.precedentType === PrecedentType.Custom ||
            precedent.isFirmPrecedent
              ? true
              : false,
        }),
  };
};
/** End - Message transform functions **/
