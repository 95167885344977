import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import * as cardDetailsPopupActions from '../actions/card-details-popup';
import * as matterDetailsActions from '@app/features/+matter-details/store/actions';
import { concatMap, filter, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ICardListEntry } from '@app/features/+card/models';
import { CardListStorageService, CardPersonDetailsService } from '@app/features/+card/services';
import { MatterSearchService } from '@app/features/+matter-list/services';
import { combineLatest } from 'rxjs';
import { IPerson } from '@app/shared/models';
import { IMatterListEntry } from '@app/features/+matter-list/models';
import { AppApiService } from '@app/core/api';
import { selectCurrentMatterId } from '@app/core/store';
import * as appActions from '@app/core/store/actions';
import { PlatformService } from '@app/core/services';
import { LayerOutletName } from '@app/core/models';

@Injectable()
export class CardDetailsPopupEffect {

  updatePopupCardInformStart$ = createEffect(() => this.actions$.pipe(
    ofType<cardDetailsPopupActions.UpdatePopupCardInformStart>(
      cardDetailsPopupActions.CardDetailsPopupActionTypes.UPDATE_POPUP_CARD_INFORM_START
    ),
    filter(() => !!this.platformService.isBrowser),
    switchMap((action) => this.cardListStorageSvc.get(action.payload.cardId)),
    filter((cardEntry) => !!cardEntry),
    concatMap((cardEntry: ICardListEntry) => {
      const updateActions = cardEntry.persons
      ? [
        this.matterSearchSvc.getMattersForCard(cardEntry.cardId),
        this.cardPersonDetailsSvc.getPersons(cardEntry.persons)
      ]
      : [this.matterSearchSvc.getMattersForCard(cardEntry.cardId)];

      return combineLatest(updateActions).pipe(
        mergeMap((results: [IMatterListEntry[], IPerson[]]) => {
          const matters = results[0] && results[0].length > 0 ? results[0] : [];
          const persons =
            results[1] && results[1].length > 0
              ? results[1].map((person) => ({
                  id: person.__id,
                  name: `${person.firstNames || ''} ${person.lastName || ''}`,
                  phone: this.cardPersonDetailsSvc.getPhone(person),
                  email: this.cardPersonDetailsSvc.getEmail(person),
                }))
              : [];

          return [new cardDetailsPopupActions.UpdatePopupCardInform({ cardEntry, persons, matters })];
        })
      );
    })
  ));


  editCardDetails$ = createEffect(() => this.actions$.pipe(
    ofType(cardDetailsPopupActions.CardDetailsPopupActionTypes.EDIT_CARD_DETAILS),
    tap((action: cardDetailsPopupActions.EditCardDetails) => {
      this.store.dispatch(new matterDetailsActions.SetCurrentDetailEntry({ detailEntry: action.payload.cardEntry }));
      this.appApiSvc.navigate({
        path: [{ outlets: { [LayerOutletName.Global]: ['card'] } }],
        query: { matterCard: true },
      });
    })
  ), { dispatch: false });


  createEmail$ = createEffect(() => this.actions$.pipe(
    ofType<cardDetailsPopupActions.CreateEmail>(cardDetailsPopupActions.CardDetailsPopupActionTypes.CREATE_EMAIL),
    withLatestFrom(this.store.pipe(select(selectCurrentMatterId))),
    mergeMap(([action, matterId]) => [
      new appActions.InitialiseEmail({ data: { matterNumber: matterId, toAddress: action.payload.cardEntry.email } }),
    ])
  ));

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private appApiSvc: AppApiService,
    private cardPersonDetailsSvc: CardPersonDetailsService,
    private cardListStorageSvc: CardListStorageService,
    private platformService: PlatformService,
    private matterSearchSvc: MatterSearchService
  ) {}
}
