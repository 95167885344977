import { createSelector } from '@ngrx/store';

import * as fromMatterList from '../reducers/matter-list.reducer';
import { State, selectMattersState } from '../reducers';

export const selectMatterListState = createSelector(selectMattersState, (state: State) => state.matterList);

export const selectLoading = createSelector(selectMatterListState, (state: fromMatterList.State) =>
  fromMatterList.selectLoading(state)
);

export const selectLoaded = createSelector(selectMatterListState, (state: fromMatterList.State) =>
  fromMatterList.selectLoaded(state)
);

export const selectUIInfo = createSelector(selectMatterListState, (state: fromMatterList.State) =>
  fromMatterList.selectUIInfo(state)
);

export const selectSortInfo = createSelector(selectMatterListState, (state: fromMatterList.State) =>
  fromMatterList.selectSortInfo(state)
);

export const selectedMatterGuid = createSelector(selectMatterListState, (state: fromMatterList.State) =>
  fromMatterList.selectCurrentMatterGuid(state)
);

export const selectNewMatterSaving = createSelector(selectMatterListState, (state: fromMatterList.State) =>
  fromMatterList.selectNewMatterSaving(state)
);

export const selectCloudSearchResults = createSelector(selectMatterListState, (state: fromMatterList.State) =>
  fromMatterList.selectCloudSearchResults(state)
);

export const selectMetaInfo = createSelector(
  selectedMatterGuid,
  selectSortInfo,
  selectUIInfo,
  (matterGUID, sortInfo, uiInfo) => ({
    selectedMatterGuid: matterGUID,
    sortInfo,
    selectUIInfo: uiInfo,
  })
);
