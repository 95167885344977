import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[scAutoSelectText]',
})
export class AutoSelectTextDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('click', ['$event.target'])
  onClick() {
    this.elementRef.nativeElement.select();
  }
}
