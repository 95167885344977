import { Action } from '@ngrx/store';
import {
  IListCorrespondenceActionPayload,
  CorrespondenceListMetaInfo,
  IMoveToFolderActionPayload,
  IRenameActionPayload,
  CorrespondenceFilterOption,
} from '../../../models';
import { IDocsFolder } from '@app/shared/models';

export const LIST_CORRESPONDENCE_LIST_START = '[Correspondence List from api] start';
export const LIST_CORRESPONDENCE_LIST_SUCCESS = '[Correspondence List from api] succcess';
export const LIST_CORRESPONDENCE_LIST_FAILURE = '[Correspondence List from api] failure';

export const LIST_FOLDER_START = '[Folder List from api] start';
export const LIST_FOLDER_SUCCESS = '[Folder List from api] succcess';
export const LIST_FOLDER_FAILURE = '[Folder List from api] failure';

export const CONFIRM_DELETE_FOLDER = '[Delete folder] confirm';
export const DELETE_FOLDER_START = '[Delete folder] start';
export const DELETE_FOLDER_SUCCESS = '[Delete folder] success';
export const DELETE_FOLDER_FAILURE = '[Delete folder] failure';

export const CREATE_FOLDER_START = '[Create folder] start';
export const CREATE_FOLDER_SUCCESS = '[Create folder] success';
export const CREATE_FOLDER_FAILURE = '[Create folder] failure';

export const MOVE_TO_FOLDER_START = '[Move to folder] start';
export const MOVE_TO_FOLDER_SUCCESS = '[Move to folder] success';
export const MOVE_TO_FOLDER_FAILURE = '[Move to folder] failure';

export const RENAME_FOLDER_START = '[Rename folder] start';
export const RENAME_FOLDER_SUCCESS = '[Rename folder] success';
export const RENAME_FOLDER_FAILURE = '[Rename folder] failure';

export const ADD_AVAILABLE_FILTER_OPTIONS = '[Correspondence filter] Add available filter options';
export const UPDATE_APPLIED_FILTER_OPTIONS = '[Correspondence Filter] Update applied filter option';
export const RESET_FILTER = '[Correspondence filter] Reset filter';

export class ListCorrespondenceListStart implements Action {
  readonly type = LIST_CORRESPONDENCE_LIST_START;
  constructor(public payload: IListCorrespondenceActionPayload) {}
}

export class ListCorrespondenceListSuccess implements Action {
  readonly type = LIST_CORRESPONDENCE_LIST_SUCCESS;
  constructor(public payload: Partial<CorrespondenceListMetaInfo>) {}
}

export class ListCorrespondenceListFailure implements Action {
  readonly type = LIST_CORRESPONDENCE_LIST_FAILURE;
  constructor(public payload: any) {}
}

export class ConfirmDeleteFolder implements Action {
  readonly type = CONFIRM_DELETE_FOLDER;
  constructor(public payload: IDocsFolder) {}
}

export class DeleteFolderStart implements Action {
  readonly type = DELETE_FOLDER_START;
  constructor(public payload: IDocsFolder) {}
}

export class DeleteFolderSuccess implements Action {
  readonly type = DELETE_FOLDER_SUCCESS;
  constructor(public payload: any) {}
}

export class DeleteFolderFailure implements Action {
  readonly type = DELETE_FOLDER_FAILURE;
  constructor(public payload: any) {}
}

export class CreateFolderStart implements Action {
  readonly type = CREATE_FOLDER_START;
  constructor(public payload: IDocsFolder) {}
}

export class CreateFolderSuccess implements Action {
  readonly type = CREATE_FOLDER_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateFolderFailure implements Action {
  readonly type = CREATE_FOLDER_FAILURE;
  constructor(public payload: any) {}
}

export class MoveToFolderStart implements Action {
  readonly type = MOVE_TO_FOLDER_START;
  constructor(public payload: IMoveToFolderActionPayload) {}
}

export class MoveToFolderSuccess implements Action {
  readonly type = MOVE_TO_FOLDER_SUCCESS;
  constructor(public payload: any) {}
}

export class MoveToFolderFailure implements Action {
  readonly type = MOVE_TO_FOLDER_FAILURE;
  constructor(public payload: string) {}
}

export class RenameFolderStart implements Action {
  readonly type = RENAME_FOLDER_START;
  constructor(public payload: IRenameActionPayload) {}
}

export class RenameFolderSuccess implements Action {
  readonly type = RENAME_FOLDER_SUCCESS;
  constructor(public payload: any) {}
}

export class RenameFolderFailure implements Action {
  readonly type = RENAME_FOLDER_FAILURE;
  constructor(public payload: any) {}
}

export class AddAvailableFilterOptions implements Action {
  readonly type = ADD_AVAILABLE_FILTER_OPTIONS;
  constructor(public payload: CorrespondenceFilterOption[]) {}
}

export class UpdateFilter implements Action {
  readonly type = UPDATE_APPLIED_FILTER_OPTIONS;
  constructor(public payload: CorrespondenceFilterOption) {}
}

export class ResetFilter implements Action {
  readonly type = RESET_FILTER;
  constructor(public payload: any) {}
}

export type FolderApiActions =
  | ListCorrespondenceListStart
  | ListCorrespondenceListSuccess
  | ListCorrespondenceListFailure
  | DeleteFolderStart
  | DeleteFolderSuccess
  | DeleteFolderFailure
  | CreateFolderStart
  | CreateFolderSuccess
  | CreateFolderFailure
  | MoveToFolderStart
  | MoveToFolderSuccess
  | MoveToFolderFailure
  | RenameFolderStart
  | RenameFolderSuccess
  | RenameFolderFailure
  | AddAvailableFilterOptions
  | UpdateFilter
  | ResetFilter;
