import { Action } from '@ngrx/store';
import { IPerson } from '@app/shared/models';

export enum PersonDetailsActionTypes {
  LOAD_PERSON_DETAILS_START = '[Person Details] load start',
  LOAD_PERSON_DETAILS_SUCCESS = '[Person Details] load succeed',
  LOAD_PERSON_DETAILS_FAIL = '[Person Details] load fail',
  LOAD_EXISTING_PERSON = '[Person Details] load existing person',

  UPDATE_FORM_VALUE = '[Person Details] update form values',
  SAVE = '[Person Details] save',
  SAVE_SUCCESS = '[Person Details] save success',
  SAVE_FAIL = '[Person Details] save fail',
  SET_PERSON_PROCESSING_STATUS = '[Person Details] set the status of personProcessing',

  ADD_PERSON_DETAILS_INVALID_CONTROLS = '[Person Details] add person details invalid controls',
  REMOVE_PERSON_DETAILS_INVALID_CONTROLS = '[Person Details] remove person details invalid controls',

  REQUEST_RESET = '[Person Details] request to reset person details',
  RESET = '[Person Details] reset',

  SET_PERSON_DELETE_CODE = '[Person Details] Set delete code',

  CLEAR = '[Person Details] clear'
}

export class LoadPersonDetailsStart implements Action {
  readonly type = PersonDetailsActionTypes.LOAD_PERSON_DETAILS_START;

  // id of the person to load, null for a new person
  constructor(public payload: { id?: string }) {}
}

export class LoadPersonDetailsSuccess implements Action {
  readonly type = PersonDetailsActionTypes.LOAD_PERSON_DETAILS_SUCCESS;

  constructor(public payload: { data: IPerson; relatedCardIds: string[]; relatedMatterIds: string[] }) {}
}

export class LoadPersonDetailsFail implements Action {
  readonly type = PersonDetailsActionTypes.LOAD_PERSON_DETAILS_FAIL;

  constructor(public payload: any) {}
}

export class UpdateFormValue implements Action {
  readonly type = PersonDetailsActionTypes.UPDATE_FORM_VALUE;

  constructor(public payload: { data: Partial<IPerson> }) {}
}

export class Save implements Action {
  readonly type = PersonDetailsActionTypes.SAVE;

  constructor(public payload: {
    updateType?: 'save' | 'delete' | 'restore';
    successActions?: Action[];
  }) {}
}

export class SaveSuccess implements Action {
  readonly type = PersonDetailsActionTypes.SAVE_SUCCESS;

  constructor(public payload: { message: string }) {}
}

export class SaveFail implements Action {
  readonly type = PersonDetailsActionTypes.SAVE_FAIL;

  constructor(public payload: any) {}
}

export class SetPersonProcessingStatus implements Action {
  readonly type = PersonDetailsActionTypes.SET_PERSON_PROCESSING_STATUS;

  constructor(public payload: { processing: boolean }) {}
}

export class RequestReset implements Action {
  readonly type = PersonDetailsActionTypes.REQUEST_RESET;

  constructor(public payload: any) {}
}

export class Reset implements Action {
  readonly type = PersonDetailsActionTypes.RESET;

  constructor(public payload: any) {}
}

export class AddPersonDetailsInvalidControls implements Action {
  readonly type = PersonDetailsActionTypes.ADD_PERSON_DETAILS_INVALID_CONTROLS;

  constructor(public payload: { names: string[] }) {}
}

export class RemovePersonDetailsInvalidControls implements Action {
  readonly type = PersonDetailsActionTypes.REMOVE_PERSON_DETAILS_INVALID_CONTROLS;

  constructor(public payload: { names: string[] }) {}
}

export class LoadExistingPerson implements Action {
  readonly type = PersonDetailsActionTypes.LOAD_EXISTING_PERSON;

  constructor(public payload: {
    id: string;
    successActions?: Action[];
  }) {}
}

export class SetPersonDeleteCode implements Action {
  readonly type = PersonDetailsActionTypes.SET_PERSON_DELETE_CODE;

  constructor(public payload: boolean) {}
}

export class Clear implements Action {
  readonly type = PersonDetailsActionTypes.CLEAR;
}

export type PersonDetailsActions =
  | LoadPersonDetailsStart
  | LoadPersonDetailsSuccess
  | LoadPersonDetailsFail
  | LoadExistingPerson
  | UpdateFormValue
  | Save
  | SaveSuccess
  | SaveFail
  | SetPersonProcessingStatus
  | AddPersonDetailsInvalidControls
  | RemovePersonDetailsInvalidControls
  | RequestReset
  | Reset
  | Clear;
