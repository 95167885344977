import { Component, HostBinding } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { getObjValue, isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-amount-cell',
  templateUrl: './amount-cell.component.html',
})
export class AmountCellComponent implements AgRendererComponent {
  TotalTax: number;
  isHidden: boolean;

  constructor() {}

  @HostBinding('class')
  classes = 'ag-content-label font-weight-bold text-grey w-100';
  agInit(params: any): void {
    this.isHidden = false;
    this.TotalTax = isFunction(params['getTotalTax']) ? params.getTotalTax(params.data) || 0 : params.value || 0;
  }

  refresh(params: any): boolean {
    this.TotalTax = isFunction(params['getTotalTax']) ? params.getTotalTax(params.data) || 0 : params.value || 0;
    return true;
  }
}
