import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import {
  ENotificationEntityType,
  INotification,
  INotificationAppointmentBody,
  INotificationMetaInfo,
  INotificationTaskBody,
} from '@app/features/+notification/models/notification.model';
import { DexieService } from '@app/core/services/dexie/dexie.service';
import { LogService } from '@app/core/services/log/log.service';
import { PlatformService } from '@app/core/services/platform/platform.service';
import { BaseStorageService } from '@app/shared/services/base/base-storage.service';
import { from, Observable, throwError } from 'rxjs';
import { format } from 'date-fns';
import { getDate } from '@app/shared/utils/date.helper';

@Injectable()
export class NotificationStorageService extends BaseStorageService<INotification> {
  private metaKey = '__NOTIFICATION_LIST_META__';
  private metaTable: Dexie.Table<INotificationMetaInfo, string>;

  constructor(private dexieSvc: DexieService, private log: LogService, private _platformSvc: PlatformService) {
    super();
    log.init('notification-list-storage-service');
    if (this._platformSvc.isBrowser) {
      this.table = this.dexieSvc.table('notifications');
      this.metaTable = this.dexieSvc.table('notificationMeta');
    }
  }

  public getMeta(): Observable<INotificationMetaInfo> {
    if (this._platformSvc.isBrowser) {
      const meta = this.metaTable.get(this.metaKey);
      //console.debug('.............. meta:', meta);
      return from(meta);
    }
  }

  public upsertAll(res: any) {
    if (this._platformSvc.isBrowser) {
      const metaInfo: Partial<INotificationMetaInfo> = {
        id: this.metaKey,
        continuationToken: res.continuationToken,
        firmId: res.firmId,
        staffId: res.staffId,
        lastEvaluatedKey: res.lastEvaluatedKey,
      };

      //console.debug("=====> upsertAll:", { res, metaInfo });
      return this.upsertMeta(metaInfo).then(() => this.table.bulkPut(res.notifications));
    }
  }

  public deleteBatch(res: { lastEvaluatedKey: string; ids: number[] }) {
    if (this._platformSvc.isBrowser) {
      return this.upsertMeta({ lastEvaluatedKey: res.lastEvaluatedKey }).then(() => this.table.bulkDelete(res.ids));
    }
  }

  public upsertMeta(partialMetaInfo: Partial<INotificationMetaInfo>) {
    if (this._platformSvc.isBrowser) {
      return this.metaTable.get(this.metaKey).then((currentMeta: INotificationMetaInfo) => {

        const metaInfo = {
          ...currentMeta,
          ...partialMetaInfo,
          id: this.metaKey,
        };

        //console.debug('...........upsertMeta metaInfo', metaInfo.continuationToken);
        return this.metaTable.put(metaInfo);
      });
    }
  }

  /**
   * Construct notification's description
   *
   * @param notification
   * @return
   * */
  public constructNotificationDescription(notification: INotification): string {
    const notifEntityType = notification.entity.type;
    switch (notifEntityType) {
      case ENotificationEntityType.Appointment:
        return this.appointmentNotificationDescription(notification);

      case ENotificationEntityType.Task:
        return this.taskNotificationDescription(notification);

      case ENotificationEntityType.CriticalDate:
      case ENotificationEntityType.Document:
      case ENotificationEntityType.Comment:
      case ENotificationEntityType.Email:
      case ENotificationEntityType.SearchOrder:
      case ENotificationEntityType.PaymentRequest:
        return this.getNotificationBodyString(notification);

      default:
        return undefined;
    }
  }

  /**
   * build a message for appointment notification
   *
   * @param notification
   * @return string
   * */
  private appointmentNotificationDescription(notification: INotification): string {
    const notifBody = atob(notification.body);
    const bodyFormat = notification.format;
    let msg = notifBody;
    if (bodyFormat === 'json') {
      const jsonObj = JSON.parse(notifBody) as INotificationAppointmentBody;
      const dueDate = format(getDate(jsonObj.dueDate), 'MMM dd, yyyy p');
      msg = `Due on ${dueDate}`;
    }

    return msg;
  }

  /**
   * build a message for task notification
   *
   * @param notification
   * @return string
   * */
  private taskNotificationDescription(notification: INotification): string {
    const notifBody = atob(notification.body);
    const bodyFormat = notification.format;
    let msg = notifBody;
    if (bodyFormat === 'json') {
      const jsonObj = JSON.parse(notifBody) as INotificationTaskBody;
      const dueDate = format(getDate(jsonObj.dueDate), 'MMM dd, yyyy');
      msg = `Due on ${dueDate}`;
    }
    return msg;
  }

  /**
   * get the notification body string
   *
   * @param notification
   * @return string
   * */
  private getNotificationBodyString(notification: INotification): string {
    const notifBody = atob(notification.body);
    return notifBody;
  }

  public handleDatabaseError(error: any): any {
    return throwError(() => error);
  }
}
