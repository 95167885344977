import { Action } from '@ngrx/store';
import { IAutomationTicket, IAutomationWordBusy, IAutomationWorkflowParams } from '@app/shared/models';
import { IAutomationError } from '@app/core/models';

export enum AutomationActionTypes {
  ADD_TICKET = '[Automation] add automation ticket',
  DELETE_TICKET = '[Automation] delete automation ticket',
  CLEAR_TICKETS = '[Automation] clear automation tickets',
  UPDATE_AUTOMATION_LOCAL_TICKET_ID = '[App] update automation local ticket id',
  LAUNCH_AUTOMATION_DIALOG_START = '[App] launch automation dialog start',
  LAUNCH_AUTOMATION_DIALOG = '[App] launch automation dialog',
  CLOSE_AUTOMATION_DIALOG = '[App] close automation dialog',
  AUTOMATION_READY = '[App] automation ready',
  AUTOMATION_ERROR = '[App] automation error',
  AUTOMATION_CLEAR_PARAMS = '[App] automation clear params',
  AUTOMATION_RESPONDED = '[App] automation responded',
  RESET_AUTOMATION_RESPONDED = '[App] reset automation responded to false',
  RESET_AUTOMATION_LOADING = '[App] reset automationLoading to false',
  NOTIFY_AUTOMATION_WORD_BUSY = '[App] notify automation word busy',
}

export class AddAutomationTicket implements Action {
  readonly type = AutomationActionTypes.ADD_TICKET;

  constructor(public payload: { ticket: IAutomationTicket }) {}
}

export class DeleteAutomationTicket implements Action {
  readonly type = AutomationActionTypes.DELETE_TICKET;

  constructor(public payload: { ticketId: string }) {}
}

export class ClearAutomationTickets implements Action {
  readonly type = AutomationActionTypes.CLEAR_TICKETS;

  constructor(public payload: any) {}
}

export class UpdateAutomationLocalTicketId implements Action {
  readonly type = AutomationActionTypes.UPDATE_AUTOMATION_LOCAL_TICKET_ID;

  constructor(public payload: string) {}
}

export class LaunchAutomationDialogStart implements Action {
  readonly type = AutomationActionTypes.LAUNCH_AUTOMATION_DIALOG_START;

  constructor(public payload: IAutomationWorkflowParams) {}
}

export class LaunchAutomationDialog implements Action {
  readonly type = AutomationActionTypes.LAUNCH_AUTOMATION_DIALOG;

  constructor(public payload: any) {}
}

export class CloseAutomationDialog implements Action {
  readonly type = AutomationActionTypes.CLOSE_AUTOMATION_DIALOG;

  constructor(public payload: any) {}
}

export class AutomationReady implements Action {
  readonly type = AutomationActionTypes.AUTOMATION_READY;

  constructor(public payload: string) {}
}

export class AutomationError implements Action {
  readonly type = AutomationActionTypes.AUTOMATION_ERROR;

  constructor(public payload: IAutomationError) {}
}

export class AutomationClearParams implements Action {
  readonly type = AutomationActionTypes.AUTOMATION_CLEAR_PARAMS;

  constructor(public payload: any) {}
}

export class ResetAutomationLoading implements Action {
  readonly type = AutomationActionTypes.RESET_AUTOMATION_LOADING;

  constructor(public payload: any) {}
}

export class AutomationResponded implements Action {
  readonly type = AutomationActionTypes.AUTOMATION_RESPONDED;

  constructor(public payload: any) {}
}

export class ResetAutomationResponded implements Action {
  readonly type = AutomationActionTypes.RESET_AUTOMATION_RESPONDED;

  constructor(public payload: any) {}
}

export class NotifyAutomationWordBusy implements Action {
  readonly type = AutomationActionTypes.NOTIFY_AUTOMATION_WORD_BUSY;

  constructor(public payload: IAutomationWordBusy) {}
}

export type AutomationActions =
  | AddAutomationTicket
  | DeleteAutomationTicket
  | ClearAutomationTickets
  | UpdateAutomationLocalTicketId
  | LaunchAutomationDialogStart
  | LaunchAutomationDialog
  | CloseAutomationDialog
  | AutomationReady
  | AutomationError
  | AutomationClearParams
  | ResetAutomationLoading
  | NotifyAutomationWordBusy
  | AutomationResponded
  | ResetAutomationResponded;
