import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as actions from '../actions/contact/contact-api.action';
import { ContactService } from '@app/features/+matter-details/services';

@Injectable()
export class ContactEffects {

  invoiceStateStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.GetContactStart>(actions.GET_CONTACT_START),
    mergeMap((con) => this._contactSvc.getStaffEmail(con.payload)),
    map((contacts) => new actions.GetContactSuccess(contacts)),
    catchError((err) => observableOf({ type: actions.GET_CONTACT_FAILURE, payload: err }))
  ));

  constructor(private actions$: Actions, private _store: Store<any>, private _contactSvc: ContactService) {}
}
