import { Action } from '@ngrx/store';
import { ActivityCode } from '@app/features/fee-timer/models/fee-timer.model';

export const FEE_TIMER_ACTIVITY_CODE_LOAD = '[Fee Timer] Activity Code load';
export const FEE_TIMER_ACTIVITY_CODE_LOAD_COMPLETED = '[Fee Timer] Activity Code load completed';
export const FEE_TIMER_ACTIVITY_CODE_LOAD_FAIL = '[Fee Timer] Activity Code load fail';

export const FEE_TIMER_ACTIVITY_CODE_SEARCH = '[Fee Timer] Activity Code search';
export const FEE_TIMER_ACTIVITY_CODE_SEARCH_COMPLETED = '[Fee Timer] Activity Code search completed';

export const FEE_TIMER_ACTIVITY_CODE_SELECTED = '[Fee Timer] Activity Code selected';

export class FeeTimerActivityCodeLoad implements Action {
  readonly type = FEE_TIMER_ACTIVITY_CODE_LOAD;
  constructor(public payload: any) {}
}

export class FeeTimerActivityCodeLoadCompleted implements Action {
  readonly type = FEE_TIMER_ACTIVITY_CODE_LOAD_COMPLETED;
  constructor(public payload: ActivityCode[]) {}
}

export class FeeTimerActivityCodeLoadFail implements Action {
  readonly type = FEE_TIMER_ACTIVITY_CODE_LOAD_FAIL;
  constructor(public payload: any) {}
}

export class FeeTimerActivityCodeSearch implements Action {
  readonly type = FEE_TIMER_ACTIVITY_CODE_SEARCH;
  constructor(public payload: string) {}
}

export class FeeTimerActivityCodeSearchCompleted implements Action {
  readonly type = FEE_TIMER_ACTIVITY_CODE_SEARCH_COMPLETED;
  constructor(public payload: ActivityCode[]) {}
}

export class FeeTimerActivityCodeSelected implements Action {
  readonly type = FEE_TIMER_ACTIVITY_CODE_SELECTED;
  constructor(public payload: ActivityCode) {}
}

export type FeeTimerActivityCodeActions =
  | FeeTimerActivityCodeLoad
  | FeeTimerActivityCodeLoadCompleted
  | FeeTimerActivityCodeLoadFail
  | FeeTimerActivityCodeSearch
  | FeeTimerActivityCodeSearchCompleted
  | FeeTimerActivityCodeSelected;
