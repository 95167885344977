<label *ngIf="!!inputLabel" [for]="inputId" class="text-truncate">{{ (inputLabel | translate) || '&nbsp;' }}</label>
<input
  [@xAnimationPulseWithParams]="inputAnimationPulseState"
  [type]="inputType"
  [id]="inputId"
  scAutofocus
  [autofocus]="autofocus"
  [placeholder]="placeholder"
  class="form-control"
  [ngClass]="inputClass"
  [ngStyle]="inputStyle"
  [readonly]="readonly"
  [disabled]="isDisabled"
  [min]="inputMin"
  [class.x-is-invalid]="hasError"
  [ngModel]="value"
  (blur)="blur()"
  (keydown)="keydown($event)"
  (keyup)="handleKeyupOnTextInput(inputElm.value)"
  [autocomplete]="autocompleteOn ? 'on' : 'off'"
  #inputElm
/>
<div *ngIf="hasError && errorMessage" class="invalid-feedback">{{ errorMessage }}</div>
