import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'sc-modal-footer',
  templateUrl: './modal-footer.component.html',
})
export class ModalFooterComponent implements OnInit {
  constructor() {}

  @HostBinding('class.modal-footer')
  ngOnInit() {}
}
