import { Directive, ElementRef, HostListener, Input } from '@angular/core';

import { AccountingService } from '@app/features/accounting/services';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[scAutoRoundCurrency]',
})
export class AutoRoundCurrencyDirective {
  value: number;
  @Input() scAutoRoundCurrencyRoundValue = 2;

  @Input() inputMin: number;

  private readonly _maxLength: number;

  constructor(
    private elementRef: ElementRef,
    private control: NgControl,
    private accountingService: AccountingService
  ) {
    // max length as defined via LS-1204 and conversations with Simon is to be 11
    this._maxLength = 11;
  }

  @HostListener('blur', ['$event'])
  @HostListener('onBlur', ['$event'])
  onBlur($event) {
    const inputValue = !!$event.target ? $event.target.value : $event;
    let numForRounding = 0;
    if (typeof inputValue === 'string') {
      // max length will be one more than maxLength where a number is negative to account for minus symbol
      const lengthOfValue = inputValue.indexOf('-') === 0 ? this._maxLength + 1 : this._maxLength;
      const valueForRounding = inputValue.slice(0, lengthOfValue);
      numForRounding = !isNaN(parseFloat(valueForRounding)) ? parseFloat(valueForRounding) : 0;
    } else if (typeof inputValue === 'number') {
      numForRounding = $event;
    }

    if (this.inputMin !== undefined) {
      numForRounding = numForRounding > this.inputMin ? numForRounding : this.inputMin;
    }

    this.value = this.accountingService.round(numForRounding, this.scAutoRoundCurrencyRoundValue);
    const negativeAmount = this.control.control.setValue(this.value);
  }
}
