import { ISchemaApiRequestOptions, ISelectItemOptions } from '@app/shared/models';
import { IPrecedent } from './precedent.model';

export const AllMatterTypeId = '-1';

export type PrecedentSearchMode = 'all' | 'mattertype';

export enum EPrecedentSearchMode {
  All,
  MatterType,
}

export interface IPrecedentSearchState extends ISchemaApiRequestOptions {
  searchText: string;
}

export interface IPrecedentListUIInfo {
  showCode: boolean;
  showDeleted: boolean;
  expandedIdList: string[];
}

export interface IMatterTypePrecedents {
  matterTypeId: string;
  precedents: IPrecedent[];
}

export type ISelectPrecedentPayload = ISelectItemOptions<IPrecedent>;
