import { createAction, props } from '@ngrx/store';
import { IMatterListResponseSchema, MatterListMetaInfo } from '../../../models';

// Matter Save to DB Actions
export const MatterSaveDbStart = createAction(
  '[Matters save to db] start',
  props<{ payload: IMatterListResponseSchema }>() // Define the payload as the response schema
);

export const MatterSaveDbSuccess = createAction(
  '[Matters save to db] success',
  props<{ message: string }>() // Define the payload as a success message
);

// Matter List Meta Actions
export const MatterListMetaSaveDbStart = createAction(
  '[Matter list meta save to db] start',
  props<{ payload: Partial<MatterListMetaInfo> }>() // Define the payload as a partial meta info object
);

export const MatterListMetaSaveDbSuccess = createAction(
  '[Matter list meta save to db] success',
  props<{ message: string }>() // Define the payload as a success message
);

export const MatterListMetaSaveDbFailure = createAction(
  '[Matter list meta save to db] failure',
  props<{ error: any }>() // Define the payload as an error object
);

// Set Meta Information Action
export const SetMeta = createAction(
  '[Matter list meta] save meta information into ngrx store',
  props<{ meta: Partial<MatterListMetaInfo> }>() // Define the payload as a partial meta info object
);
