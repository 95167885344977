import { of as observableOf, Observable, combineLatest } from 'rxjs';

import { map, mergeMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { BaseService } from '@app/shared/services';
import { IMatterCore, IMatterListEntry } from '@app/features/+matter-list/models';
import { SearchTermMatchType, AdvancedSearchMode } from '@app/features/+matter-list/constants';
import { MatterSearchService } from '@app/features/+matter-list/services';
import {
  MatterDetailsQueryType,
  IMatterCard,
  IMatterDetailEntry,
  IMatterTablesState,
  IDefinableTable,
  IMatterDetailRelationship,
} from '@app/shared/models';
import { ICard, IPerson } from '@app/shared/models';
import {
  addPersonToMatterCard,
  removePersonFromMatterCard,
  isRelatedLayout,
  getMatterDetailEntryChildren,
  recalculateFileOrder,
  isChildParentRelationshipValid,
  matterDetailChildrenComparator,
} from '@app/features/+matter-details/functions';
import {
  MatterDetailsFetchMode,
  OrderCalculationPolicies,
  TableConfigIds,
} from '@app/features/+matter-details/constants';
import { EMatterDetailType } from '@app/shared/models';
import { ICardListEntry } from '@app/features/+card/models';
import { Store, select } from '@ngrx/store';
import { selectDetailEntries, selectMatterCardRelationships } from '../../store/selectors/current-matter.selectors';
import {
  IMatterDetailsGetParams,
  IMatterDetailsApiResponse,
  UpdatedMatterCorePayload,
} from '../../models/matter-details-api.model';
import { LayoutRendererCriticalDate } from '@app/features/+layout-renderer/models/layout-renderer.model';
import { differenceBetweenArrays, hasProp, uniqBy } from '@server/modules/shared/functions';

@Injectable({
  providedIn: 'root',
})
export class MatterDetailsService extends BaseService {
  constructor(private http: HttpClient, private _matterSearchSvc: MatterSearchService, private store: Store<any>) {
    super();
  }

  get(matterNumber: string, cachedMatters: IMatterListEntry[]): Observable<IMatterListEntry> {
    const matchedCachedMatter = cachedMatters?.find((matter) => matter.fileNumber === matterNumber || matter.matterId === matterNumber);
    if (!!matchedCachedMatter) {
      return observableOf(matchedCachedMatter);
    } else {
      const cloudSearch = this._matterSearchSvc.searchMatters(
        {
          SearchTerms: [
            {
              termName: 'filenumber',
              termValue: matterNumber,
              termOption: SearchTermMatchType.Exact,
            },
          ],
        },
        AdvancedSearchMode.AdvancedSearch
      );
      return cloudSearch.pipe(map((cloudSearchRes: IMatterListEntry[]) => cloudSearchRes[0]));
    }
  }

  getRelatedCardEntries(matterEntry: IMatterListEntry, cardList: ICardListEntry[]): ICardListEntry[] {
    return this.getRelatedCardEntriesByCardListId(matterEntry?.cardIdList || [], cardList);
  }

  getRelatedCardEntriesByCardListId(ids: string[], cardList: ICardListEntry[]): ICardListEntry[] {
    return uniqBy(
      ids
        ?.map((cardId: string) => cardList?.find((cardEntry: ICardListEntry) => cardEntry.cardId === cardId))
        .filter(Boolean) || [],
      'cardId'
    );
  }

  getMatterCore(matterId: string, fetchMode: 'cacheable' | 'force' = 'cacheable'): Observable<IMatterCore> {
    const url = `${this.siriusPath}/api/matter/${encodeURIComponent(matterId)}/matter-core`;
    const options = { params: new HttpParams().set('fetch', fetchMode) };
    return this.http.get<IMatterCore>(url, options).pipe(
      // confusing property names returned from API, for future reference:
      // titleClient should be grouped with client
      // title should be grouped with other
      map((core) => ({
        ...core,
        titleClientOrigin: core.titleClient,
        clientDefault: this.handleStringBoolean(core.clientDefault),
        clientPrefix: this.handleStringBoolean(core.clientPrefix),
        clientSuffix: this.handleStringBoolean(core.clientSuffix),
        titleOrigin: core.title,
        otherDefault: this.handleStringBoolean(core.otherDefault),
        otherPrefix: this.handleStringBoolean(core.otherPrefix),
        otherSuffix: this.handleStringBoolean(core.otherSuffix),
      }))
    );
  }

  handleStringBoolean(input: boolean | string): boolean {
    if (!input) {
      return false;
    }
    if (typeof input === 'boolean') {
      return input;
    }
    return input.toLowerCase() === 'true';
  }

  saveMatterCore(matterCore: IMatterCore): Observable<UpdatedMatterCorePayload> {
    const matterId = matterCore.__id;
    const url = `${this.siriusPath}/api/matter/${matterId}/matter-core`;
    return this.http.put<UpdatedMatterCorePayload>(url, matterCore);
  }

  /**
   * Update matter-type. The response will contain the updated matter-core with new matter-type.
   * (sirius server will auto clear cache)
   *
   * @param matterId
   * @param matterTypeId
   * @param state
   * @returns
   */
  saveMatterType(matterId: string, matterTypeId: string, state: string): Observable<IMatterCore> {
    const url = `${this.siriusPath}/api/matter/${matterId}/matter-type`;
    return this.http.put<IMatterCore>(url, { state, matterTypeId });
  }

  getMatterDetails(params: IMatterDetailsGetParams): Observable<IMatterDetailsApiResponse> {
    const { matterId } = params;
    const queryType = params.queryType || MatterDetailsQueryType.All;
    const fetchMode = params.fetchMode || MatterDetailsFetchMode.Force;
    const withContext = hasProp(params, 'withContext') ? params.withContext : true;
    const disableRelationships = params.disableRelationships;
    const queryUrl = `?queryType=${queryType}&withContext=${withContext}&fetchMode=${fetchMode}&disableRelationships=${disableRelationships}`;
    const url = `${this.siriusPath}/api/matter/${matterId}/matter-details${queryUrl}`;

    return this.http.get<IMatterDetailsApiResponse>(url);
  }

  getMatterCards(matterId: string): Observable<IMatterCard[]> {
    const url = `${this.siriusPath}/api/matter/${matterId}/matter-cards?includeDebtor=true`;
    return this.http.get<IMatterCard[]>(url);
  }

  deleteMatterCard(matterId: string, matterCardId: string, displayOrder: number): Observable<void> {
    const url = `${this.siriusPath}/api/matter/${matterId}/matter-cards/${matterCardId}?displayOrder=${displayOrder}`;
    return this.http.delete<void>(url);
  }

  saveMatterCard(
    matterId: string,
    matterCard: IMatterCard,
    card?: ICard,
    isNew: boolean = false
  ): Observable<IMatterCard> {
    const url = `${this.siriusPath}/api/matter/${matterId}/matter-cards`;
    return combineLatest([
      this.store.pipe(select(selectMatterCardRelationships)),
      this.store.pipe(select(selectDetailEntries)),
    ]).pipe(
      take(1),
      mergeMap((data) => {
        const [relationships, detailEntries] = data;
        let matterDetails = detailEntries;
        const parent = detailEntries.find((entry) => entry.__id === matterCard.__relatedParentTableId);
        const validRelationship = isChildParentRelationshipValid(relationships, parent, matterCard);

        if (!validRelationship) {
          // Dont create concrete relationship
          matterCard = { ...matterCard, __relatedParentTableId: undefined };
          matterDetails = [];
        }
        return this.http.put<IMatterCard>(url, { matterCard, card, isNew, matterDetails });
      })
    );
  }

  setDebtorCard(matterId: string, matterCard: IMatterCard, card?: ICard): Observable<IMatterCard> {
    const url = `${this.siriusPath}/api/matter/${matterId}/debtor-card`;
    return this.http.put<IMatterCard>(url, { matterCard, ...(!!card ? { card } : {}) });
  }

  saveDefinableTable(matterId: string, definableTable: any): Observable<any> {
    const url = `${this.siriusPath}/api/matter/${matterId}/definables`;
    return this.http.put<any>(url, definableTable);
  }

  addActingPerson(matterId: string, matterCard: IMatterCard, person: IPerson): Observable<IMatterCard> {
    return this.saveMatterCard(matterId, addPersonToMatterCard(matterCard, person));
  }

  removeActingPerson(matterId: string, matterCard: IMatterCard, person: IPerson): Observable<IMatterCard> {
    return this.saveMatterCard(matterId, removePersonFromMatterCard(matterCard, person));
  }

  buildMatterTablesState(matterDetailEntries: IMatterDetailEntry[]): IMatterTablesState {
    const cardEntries: IMatterDetailEntry[] = matterDetailEntries?.filter(
      (d) => d.detailType === EMatterDetailType.Card
    );
    const definableEntries: IMatterDetailEntry[] = differenceBetweenArrays(matterDetailEntries, cardEntries);

    return {
      matterCards: cardEntries?.map((matterTypeEntry: IMatterDetailEntry) => matterTypeEntry as IMatterCard) || [],
      definableTables:
        definableEntries?.map((matterTypeEntry: IMatterDetailEntry) => matterTypeEntry as IDefinableTable) || [],
    } as IMatterTablesState;
  }

  updateCardsAndDefinables(matterId: string, tableTypeSummary: IMatterTablesState): Observable<any> {
    const url = `${this.apiPath}/api/v1/matters/${matterId}/cardroles-definabletables`;
    return this.http.put(url, tableTypeSummary);
  }

  reOrderDetailsWithRelationships(
    details: IMatterDetailEntry[],
    relationships: IMatterDetailRelationship[]
  ): IMatterDetailEntry[] {
    let order = 0;
    const details2 = details
      .filter((x) => !x.isTransient)
      .filter((x) => !isRelatedLayout(x, details))
      .filter((x) => !x.matterType)
      .map((x) => Object.assign({}, x, { handled: false }));

    details2.forEach(() => {
      const entry = details2.find((x) => !x.__relatedParentTableId && x.handled === false);
      if (!!entry) {
        order = order + 1;
        entry.__displayOrder =
          OrderCalculationPolicies.DifferentTypeRange * order + OrderCalculationPolicies.SameTypeRange;
        entry.handled = true;

        const children = getMatterDetailEntryChildren(entry, details2);
        children.forEach((c) => {
          order = order + 1;
          c.__displayOrder =
            OrderCalculationPolicies.DifferentTypeRange * order + OrderCalculationPolicies.SameTypeRange;
          c.handled = true;
        });
      }
    });

    const reOrderedDetails = recalculateFileOrder(details2, true).sort((a, b) => a.__displayOrder - b.__displayOrder);
    reOrderedDetails.forEach((v) => delete v.handled);
    return reOrderedDetails;
  }

  updateLayoutRendererCriticalDates(matterId: string, criticalDates: LayoutRendererCriticalDate) {
    const url = `${this.calendarPath}/matters/${matterId}/criticaldates`;
    const criticalDateDto = Object.keys(criticalDates).map((key) => ({
        id: criticalDates[key].id,
        value: criticalDates[key].newValue,
      }));
    return this.http.patch(url, criticalDateDto);
  }
}
