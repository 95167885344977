import { Action } from '@ngrx/store';
import { OutlookContactResponse } from '@app/features/+matter-details/models';

export const GET_CONTACT_START = '[Contact] Get start';
export const GET_CONTACT_SUCCESS = '[Contact] Get succcess';
export const GET_CONTACT_FAILURE = '[Contact] Get failure';

export class GetContactStart implements Action {
  readonly type = GET_CONTACT_START;
  constructor(public payload: string) {}
}

export class GetContactSuccess implements Action {
  readonly type = GET_CONTACT_SUCCESS;
  constructor(public payload: OutlookContactResponse) {}
}

export class GetContactFailure implements Action {
  readonly type = GET_CONTACT_FAILURE;
  constructor(public payload: any) {}
}

export type ContactApiActions = GetContactStart | GetContactSuccess | GetContactFailure;
