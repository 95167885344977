import { IStaff } from './staff';

export interface IFirmDetails {
  __className: string;
  staffList: string[];
  __id: string;
  autoNumbering: boolean;
  externalPlatform: number;
  unitSuiteOffice: string;
  streetNumber: string;
  streetName: string;
  townCity: string;
  county: string;
  postcode: string;
  firmName: string;
  phone: string;
  vatNumber: string;
  dxNumber: string;
  dxExchange: string;
  poBoxNumber: string;
  poBoxTown: string;
  poBoxPostcode: string;
  crn: string;
  firmType: string;
  fax: string;
  emailAddress: string;
  website: string;
  propertyBuildingName: string;
  childNameDobMultiTabs: string;
  country: string;
  addresseeIncludeBoth: boolean;
  addresseeInitialsOrName: string;
  addresseeInitialsSeparator: string;
  addresseeSampleSingleName: string;
  addresseeSampleMrMrsName: string;
  dearSample: string;
  dearAndOrAmpersand: string;
  defaultSearchProvider: IntegrationProvider;
  addresseeIncludeMiddle: boolean;
  landRegDd: string;
  emailPreferences: string;
  addresseeTheManager: boolean;
  deductedAmount5thTable7: boolean;
  deductedAmountType3rdTable7: string;
  gstRate: number;
  superDiary: boolean;
}

export interface IFirm {
  firmDetails: IFirmDetails;
  staff: IStaff[];
}

// for ngrx state
export interface Currency {
  symbol: string;
  code: string;
  word: string;
  fractionalWord: string;
  isDefault: boolean;
}

export interface FirmDetails {
  defaultSearchProvider: IntegrationProvider;
  autoNumbering: boolean;
  id: string;
  firmName: string;
  externalPlatform: number;
  gstRate?: number;
  currency: Currency;
  region: string;
  byLawyers: boolean;
  superDiary: boolean;
  state: string;
  emailIntegrationLinked?: boolean;
  personLabels: PersonLabel[];
  forceEnableAutoTime: boolean;
}

export enum IntegrationProvider {
  InfoTrack = 'InfoTrack',
  TriSearch = 'TriSearch',
}

export interface IntegrationUserDetails {
  firmId: string;
  staffId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  userId: string;
  email: string;
}

export interface PersonLabel {
  Id: string;
  ListId: string;
  Value: string;
}
