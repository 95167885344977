import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'sc-pre-load',
  templateUrl: './pre-load.component.html',
})
export class PreLoadComponent implements OnInit {
  @HostBinding('class') classes = 'x-loader ';
  @Input() background: string;
  @Input() positionClass: string;
  @Input() icon: string;
  @Input() size: string;
  constructor() {}

  ngOnInit() {
    if (this.background) {
      this.classes += this.background + ' ';
    }
    if (this.positionClass) {
      this.classes += this.positionClass + ' ';
    }
  }
}
