import { Action } from '@ngrx/store';
import { IMatterCard } from '@app/shared/models';

export enum MatterCardActionTypes {
  REMOVE_DEBTOR_FOR_ACCOUNTING_CARD_START = '[Matter Card] remove DebtorForAccount matter card start',
  REMOVE_DEBTOR_CARD_START = '[Matter Card] remove debtor matter card start',
  REMOVE_MATTER_CARD_START = '[Matter Card] remove matter card start',
  REMOVE_MATTER_CARD_SUCCESS = '[Matter Card] remove matter card successfully',
  REMOVE_MATTER_CARD_FAIL = '[Matter Card] unable to remove matter card',
  REMOVE_MATTER_CARD_CANCELLED = '[Matter Card] remove matter card cancelled',
  LOAD_MATTER_CARD = '[Matter Card] load matter card details',
  SAVE_MATTER_CARD = '[Matter Card] save matter card details',
  ADD_DEBTOR_CARD = '[Matter Card] open card details form and add a debtor card to matter',
  SET_DEBTOR_CARD = '[Matter Card] set debtor card without opening card details form',
  SET_DEBTOR_CARD_SUCCEED = '[Matter Card] set debtor card succeed',
}

export class RemoveDebtorForAccountingCardStart implements Action {
  readonly type = MatterCardActionTypes.REMOVE_DEBTOR_FOR_ACCOUNTING_CARD_START;

  constructor(public payload: { clientCard: IMatterCard }) {}
}

export class RemoveDebtorCardStart implements Action {
  readonly type = MatterCardActionTypes.REMOVE_DEBTOR_CARD_START;

  constructor(
    public payload: {
      clientCard: IMatterCard;
      matterCardId: string;
      displayOrder: number;
    }
  ) {}
}

export class RemoveMatterCardStart implements Action {
  readonly type = MatterCardActionTypes.REMOVE_MATTER_CARD_START;

  constructor(public payload: { matterCardId: string; displayOrder: number }) {}
}

export class RemoveMatterCardSuccess implements Action {
  readonly type = MatterCardActionTypes.REMOVE_MATTER_CARD_SUCCESS;

  constructor(public payload: any) {}
}

export class RemoveMatterCardCancelled implements Action {
  readonly type = MatterCardActionTypes.REMOVE_MATTER_CARD_CANCELLED;

  constructor(public payload: any) {}
}

export class RemoveMatterCardFail implements Action {
  readonly type = MatterCardActionTypes.REMOVE_MATTER_CARD_FAIL;

  constructor(public payload: any) {}
}

export class LoadMatterCard implements Action {
  readonly type = MatterCardActionTypes.LOAD_MATTER_CARD;

  constructor(public payload: { cardType: string }) {}
}

export class SaveMatterCard implements Action {
  readonly type = MatterCardActionTypes.SAVE_MATTER_CARD;

  constructor(public payload: { isNewCard: boolean; isNewMatterCard: boolean }) {}
}

export class AddDebtorCard implements Action {
  readonly type = MatterCardActionTypes.ADD_DEBTOR_CARD;

  constructor(public payload: any) {}
}

export class SetDebtorCard implements Action {
  readonly type = MatterCardActionTypes.SET_DEBTOR_CARD;

  constructor(public payload: any) {}
}

export class SetDebtorCardSucceed implements Action {
  readonly type = MatterCardActionTypes.SET_DEBTOR_CARD_SUCCEED;

  constructor(public payload: any) {}
}

export type MatterCardActions =
  | LoadMatterCard
  | SaveMatterCard
  | AddDebtorCard
  | SetDebtorCard
  | SetDebtorCardSucceed
  | RemoveDebtorForAccountingCardStart
  | RemoveDebtorCardStart
  | RemoveMatterCardStart
  | RemoveMatterCardSuccess
  | RemoveMatterCardFail
  | RemoveMatterCardCancelled;
