import { Injectable } from '@angular/core';
import { TimerRecordingAction } from '@app/features/+time-fee-ledger/models';
import { FeeTimerTimeElapsedStop } from '@app/features/fee-timer/store/actions';
import { selectFeeTimerDuration, selectFeeTimerCurrentTimeElapsedState } from '@app/features/fee-timer/store/selectors';
import { select, Store } from '@ngrx/store';
import { combineLatest, fromEvent, map, Observable, of, switchMap, tap, timer, withLatestFrom } from 'rxjs';

@Injectable()
export class TimerService {
  constructor(private store: Store<any>) {}

  timer$ = timer(0, 1000);

  isTimerRunning$: Observable<boolean> = this.store.pipe(
    select(selectFeeTimerCurrentTimeElapsedState),
    map((state) => state === TimerRecordingAction.Start)
  );

  accumulatedDuration$ = combineLatest([this.store.pipe(select(selectFeeTimerDuration)), this.isTimerRunning$]).pipe(
    switchMap(([elapsedDuration, isTimerRunning]: [number, boolean]) => {
      if (isTimerRunning) {
        return this.timer$.pipe(map((t) => t + elapsedDuration));
      } else {
        return of(elapsedDuration);
      }
    })
  );

  // To prevent timer data lost from events like logging out/auto logging out/refreshing page...
  beforeUnloadHander$ = fromEvent(window, 'beforeunload').pipe(
    withLatestFrom(this.isTimerRunning$),
    tap(([_, isTimerRunning]) => {
      if (isTimerRunning) {
        this.store.dispatch(new FeeTimerTimeElapsedStop(undefined));
      }
    })
  );
}
