import { ProxyRouteCenterComponent, ProxyRouteComponent } from '@app/shared/components';
import { Routes } from '@angular/router';
import { LayerOutletName } from '@app/core/models';

export const auxRoutes: Routes = [
  {
    path: 'accounting-batch',
    outlet: LayerOutletName.Overlay,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+accounting-batch/accounting-batch-routing.module').then(
            (m) => m.AccountingBatchRoutingModule
          ),
      },
    ],
  },
  {
    path: 'anticipated-payment',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+anticipated-payment/anticipated-payment-routing.module').then(
            (m) => m.AnticipatedPaymentRoutingModule
          ),
      },
    ],
  },
  {
    path: 'time',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/features/+time-fee-ledger/time-form.module').then((m) => m.TimeFormModule),
      },
    ],
  },
  {
    path: 'fee',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/features/+time-fee-ledger/fee-form.module').then((m) => m.FeeFormModule),
      },
    ],
  },
  {
    path: 'card',
    outlet: LayerOutletName.Global,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+card/card-modal-routing.module').then((m) => m.CardModalRoutingModule),
      },
    ],
  },
  {
    path: 'card',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+card/card-modal-routing.module').then((m) => m.CardModalRoutingModule),
      },
    ],
  },
  {
    path: 'card',
    outlet: LayerOutletName.Selector,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+card/card-modal-routing.module').then((m) => m.CardModalRoutingModule),
      },
    ],
  },
  {
    path: 'card',
    outlet: LayerOutletName.SelectorDetail,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+card/card-modal-routing.module').then((m) => m.CardModalRoutingModule),
      },
    ],
  },
  {
    path: 'card',
    outlet: LayerOutletName.Overlay,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+card/card-modal-routing.module').then((m) => m.CardModalRoutingModule),
      },
    ],
  },
  {
    path: 'custom-list',
    outlet: LayerOutletName.Overlay,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+custom-list/custom-list-routing.module').then((m) => m.CustomListRoutingModule),
      },
    ],
  },
  {
    path: 'recurring-matter-critical-date',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+calendar-readonly/calendar-readonly-critical-date-routing.module').then(
            (m) => m.CalendarReadonlyCriticalDateRoutingModule
          ),
      },
    ],
  },
  {
    path: 'calendar-critical-date',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+calendar-readonly/calendar-readonly-critical-date-routing.module').then(
            (m) => m.CalendarReadonlyCriticalDateRoutingModule
          ),
      },
    ],
  },
  {
    path: 'critical-date',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+calendar/critical-date/critical-date.module').then((m) => m.CriticalDateModule),
      },
    ],
  },
  {
    path: 'appointment',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+calendar/appointment/appointment.module').then((m) => m.AppointmentModule),
      },
    ],
  },
  {
    path: 'task',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/features/+calendar/task/task.module').then((m) => m.TaskModule),
      },
    ],
  },
  {
    path: 'tasklist',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/features/+calendar/task-list/task-list.module').then((m) => m.TaskListModule),
      },
    ],
  },
  {
    path: 'createpdf',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+create-pdf/create-pdf-routing.module').then((m) => m.CreatePdfRoutingModule),
      },
    ],
  },
  {
    path: 'createpdf',
    outlet: LayerOutletName.Selector,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+create-pdf/create-pdf-routing.module').then((m) => m.CreatePdfRoutingModule),
      },
    ],
  },
  {
    path: 'createpdf',
    outlet: LayerOutletName.SelectorDetail,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+create-pdf/create-pdf-routing.module').then((m) => m.CreatePdfRoutingModule),
      },
    ],
  },
  {
    path: 'comment',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/features/+comment/comment-routing.module').then((m) => m.CommentRoutingModule),
      },
    ],
  },
  {
    path: 'email',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/features/+email/email-routing.module').then((m) => m.EmailRoutingModule),
      },
    ],
  },
  {
    path: 'email',
    outlet: LayerOutletName.Overlay,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/features/+email/email-routing.module').then((m) => m.EmailRoutingModule),
      },
    ],
  },
  {
    path: 'signature',
    outlet: LayerOutletName.Selector,
    component: ProxyRouteCenterComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+email/signature-routing.module').then((m) => m.SignatureRoutingModule),
      },
    ],
  },
  {
    path: 'precedent',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+precedent/precedent-routing.module').then((m) => m.PrecedentRoutingModule),
      },
    ],
  },
  {
    path: 'precedent',
    outlet: LayerOutletName.Selector,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+precedent/precedent-routing.module').then((m) => m.PrecedentRoutingModule),
      },
    ],
  },
  {
    path: 'custom-precedent',
    outlet: LayerOutletName.Selector,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+custom-precedent/custom-precedent-routing.module').then(
            (m) => m.CustomPrecedentRoutingModule
          ),
      },
    ],
  },
  {
    path: 'document',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+document/document-routing.module').then((m) => m.DocumentRoutingModule),
      },
    ],
  },
  {
    path: 'document-share',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+document-share/document-share-routing.module').then(
            (m) => m.DocumentShareRoutingModule
          ),
      },
    ],
  },
  {
    path: 'teams',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+msteams-integration/msteams-integration-routing.module').then(
            (m) => m.MsteamsIntegrationRoutingModule
          ),
      }
    ],
  },
  {
    path: 'letter',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/features/+letter/letter-routing.module').then((m) => m.LetterRoutingModule),
      },
    ],
  },
  {
    path: 'preview',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/features/+preview/preview-routing.module').then((m) => m.PreviewRoutingModule),
      },
    ],
  },
  {
    path: 'preview',
    outlet: LayerOutletName.Selector,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/features/+preview/preview-routing.module').then((m) => m.PreviewRoutingModule),
      },
    ],
  },
  {
    path: 'advanced-matter-search',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+matter-list/advanced-search-modal.module').then((m) => m.AdvancedSearchModalModule),
      },
    ],
  },
  {
    path: 'matter-filters',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+matter-list/matter-filters-modal.module').then((m) => m.MatterFiltersModalModule),
      },
    ],
  },
  {
    path: 'new-matter',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/features/+matter-list/new-matter.module').then((m) => m.NewMatterModule),
      },
    ],
  },
  {
    path: 'matter-types',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+matter-types/matter-types-routing.module').then((m) => m.MatterTypesRoutingModule),
      },
    ],
  },
  {
    path: 'matter-options',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+matter-options/matter-options-routing.module').then(
            (m) => m.MatterOptionsRoutingModule
          ),
      },
    ],
  },
  {
    path: 'special-fee',
    outlet: LayerOutletName.Overlay,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+special-fee/special-fee-routing.module').then((m) => m.SpecialFeeRoutingModule),
      },
    ],
  },
  {
    path: 'special-fee',
    outlet: LayerOutletName.SelectorDetail,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+special-fee/special-fee-routing.module').then((m) => m.SpecialFeeRoutingModule),
      },
    ],
  },
  {
    path: 'staff-fee-allocation',
    outlet: LayerOutletName.Selector,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+matter-options/staff-allocation-modal-routing.module').then(
            (m) => m.StaffAllocationModalRoutingModule
          ),
      },
    ],
  },
  {
    path: 'layout-renderer',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+layout-renderer/layout-renderer-routing.module').then(
            (m) => m.LayoutRendererRoutingModule
          ),
      },
    ],
  },
  {
    path: 'layout-renderer',
    outlet: LayerOutletName.Selector,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+layout-renderer/layout-renderer-routing.module').then(
            (m) => m.LayoutRendererRoutingModule
          ),
      },
    ],
  },
  {
    path: 'layout-renderer',
    outlet: LayerOutletName.Overlay,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+layout-renderer/layout-renderer-routing.module').then(
            (m) => m.LayoutRendererRoutingModule
          ),
      },
    ],
  },
  {
    path: 'invoice',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/features/+invoice/invoice-routing.module').then((m) => m.InvoiceRoutingModule),
      },
    ],
  },
  {
    path: 'invoice',
    outlet: LayerOutletName.Selector,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/features/+invoice/invoice-routing.module').then((m) => m.InvoiceRoutingModule),
      },
    ],
  },
  {
    path: 'invoice',
    outlet: LayerOutletName.SelectorDetail,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/features/+invoice/invoice-routing.module').then((m) => m.InvoiceRoutingModule),
      },
    ],
  },
  {
    path: 'purchase-reconciliation',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+layout-renderer/purchase-reconciliation-routing.module').then(
            (m) => m.PurchaseReconciliationRoutingModule
          ),
      },
    ],
  },
  {
    path: 'about',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteCenterComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('app/features/+about/about-routing.module').then((m) => m.AboutRoutingModule),
      },
    ],
  },
  {
    path: 'office-receipt',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+office-receipt/office-receipt-routing.module').then((m) => m.OfficeReceiptRoutingModule),
  },
  {
    path: 'office-journal',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+office-journal/office-journal-routing.module').then((m) => m.OfficeJournalRoutingModule),
  },
  {
    path: 'link-account',
    outlet: LayerOutletName.Selector,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+link-account/link-account-routing.module').then((m) => m.LinkAccountRoutingModule),
      },
    ],
  },
  {
    path: 'reauthenticate-account',
    outlet: LayerOutletName.Selector,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+reauthentication/reauthentication-routing.module').then(
            (m) => m.ReauthenticationRoutingModule
          ),
      },
    ],
  },
  {
    path: 'support-request',
    outlet: LayerOutletName.Selector,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+support-request/support-request-routing.module').then(
            (m) => m.SupportRequestRoutingModule
          ),
      },
    ],
  },
  {
    path: 'office-payment',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+office-payment/office-payment-routing.module').then((m) => m.OfficePaymentRoutingModule),
  },
  {
    path: 'payment-request',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+payment-request/payment-request-routing.module').then(
            (m) => m.PaymentRequestRoutingModule
          ),
      },
      {
        path: 'view',
        loadChildren: () =>
          import('app/features/+payment-request/payment-request-routing.module').then(
            (m) => m.PaymentRequestRoutingModule
          ),
      },
    ],
  },
  {
    path: 'payment-type-details',
    outlet: LayerOutletName.Selector,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+payment-type-details/payment-type-details-modal-routing.module').then(
            (m) => m.PaymentTypeDetailsModalRoutingModule
          ),
      },
    ],
  },
  {
    path: 'pay-anticipated',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/pay-anticipated/pay-anticipated-routing.module').then(
            (m) => m.PayAnticipatedRoutingModule
          ),
      },
    ],
  },
  {
    path: 'cost-recovery',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+cost-recovery-ledger/cost-recovery-modal.module').then(
            (m) => m.CostRecoveryModalModule
          ),
      },
    ],
  },
  {
    path: 'trust-receipt',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+trust-receipt/trust-receipt-routing.module').then((m) => m.TrustReceiptRoutingModule),
  },
  {
    path: 'trust-payment',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+trust-payment/trust-payment-routing.module').then((m) => m.TrustPaymentRoutingModule),
  },
  {
    path: 'trust-payment-request',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+trust-payment-request/trust-payment-request-routing.module').then((m) => m.TrustPaymentRequestRoutingModule),
  },
  {
    path: 'investment-account',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+investment-account/investment-account-routing.module').then(
            (m) => m.InvestmentAccountRoutingModule
          ),
      },
    ],
  },
  {
    path: 'investment-transaction',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+investment-transaction/investment-transaction-routing.module').then(
            (m) => m.InvestmentTransactionRoutingModule
          ),
      },
    ],
  },
  {
    path: 'power-money-account',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+power-money-account/power-money-account-routing.module').then(
            (m) => m.PowerMoneyAccountRoutingModule
          ),
      },
    ],
  },
  {
    path: 'power-money-transaction',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+power-money-transaction/power-money-transaction-routing.module').then(
            (m) => m.PowerMoneyTransactionRoutingModule
          ),
      },
    ],
  },
  {
    path: 'trust-journal',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+trust-journal/trust-journal-routing.module').then((m) => m.TrustJournalRoutingModule),
  },
  {
    path: 'credit-journal',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+credit-journal/credit-journal-routing.module').then((m) => m.CreditJournalRoutingModule),
  },
  {
    path: 'apply-credit',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+apply-credit/apply-credit-routing.module').then((m) => m.ApplyCreditRoutingModule),
  },
  {
    path: 'adjust-invoice',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+adjust-invoice/adjust-invoice-routing.module').then((m) => m.AdjustInvoiceRoutingModule),
  },
  {
    path: 'fee-allocation',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+fee-allocation/fee-allocation-routing.module').then((m) => m.FeeAllocationRoutingModule),
  },
  {
    path: 'fee-allocation',
    outlet: LayerOutletName.Selector,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+fee-allocation/fee-allocation-routing.module').then(
            (m) => m.FeeAllocationRoutingModule
          ),
      },
    ],
  },
  {
    path: 'fee-allocation',
    outlet: LayerOutletName.Overlay,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+fee-allocation/fee-allocation-routing.module').then(
            (m) => m.FeeAllocationRoutingModule
          ),
      },
    ],
  },
  {
    path: 'credit-refund',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+credit-refund/credit-refund-routing.module').then((m) => m.CreditRefundRoutingModule),
  },
  {
    path: 'trust-to-office',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+trust-to-office/trust-to-office.module').then((m) => m.TrustToOfficeModule),
  },
  {
    path: 'appmenu',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () => import('app/features/+app-menu/app-menu-routing.module').then((m) => m.AppMenuRoutingModule),
  },
  {
    path: 'matter-list',
    outlet: LayerOutletName.Selector,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+matter-list-selector/matter-selector-modal-routing.module').then(
            (m) => m.MatterSelectorModalRoutingModule
          ),
      },
    ],
  },
  {
    path: 'task-code-list',
    outlet: LayerOutletName.Selector,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+task-code-list-selector/task-code-selector-modal-routing.module').then(
            (m) => m.TaskCodeSelectorModalRoutingModule
          ),
      },
    ],
  },
  {
    path: 'task-code-list',
    outlet: LayerOutletName.SelectorDetail,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+task-code-list-selector/task-code-selector-modal-routing.module').then(
            (m) => m.TaskCodeSelectorModalRoutingModule
          ),
      },
    ],
  },
  {
    path: 'batch-search-page',
    outlet: LayerOutletName.Selector,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+batch-search/batch-search-routing.module').then((m) => m.BatchSearchRoutingModule),
      },
    ],
  },
  {
    path: 'batch-search-page',
    outlet: LayerOutletName.SelectorDetail,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+batch-search/batch-search-routing.module').then((m) => m.BatchSearchRoutingModule),
      },
    ],
  },
  {
    path: 'trust-bank-deposit',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+trust-bank-deposit/trust-bank-deposit-routing.module').then(
        (m) => m.TrustBankDepositRoutingModule
      ),
  },
  {
    path: 'office-bank-deposit',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+office-bank-deposit/office-bank-deposit-routing.module').then(
        (m) => m.OfficeBankDepositRoutingModule
      ),
  },
  {
    path: 'office-bulk-invoice',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+office-bulk-invoice/office-bulk-invoice-routing.module').then(
            (m) => m.OfficeBulkInvoiceRoutingModule
          ),
      },
    ],
  },
  {
    path: 'print-cheques',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+print-cheque/print-cheque-routing.module').then((m) => m.PrintChequeRoutingModule),
  },
  {
    path: 'print-cheques',
    outlet: LayerOutletName.Selector,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+print-cheque/print-cheque-routing.module').then((m) => m.PrintChequeRoutingModule),
  },
  {
    path: 'user-profile',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+user-profile/user-profile-routing.module').then((m) => m.UserProfileRoutingModule),
  },
  {
    path: 'matter-addin',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/matter-addin/matter-addin-routing.module').then((m) => m.MatterAddinRoutingModule),
      },
    ],
  },
  {
    path: 'leaphost',
    outlet: LayerOutletName.LeapAPP0,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+leaphost/leaphost-routing.module').then((m) => m.LeaphostRoutingModule),
      },
    ],
  },
  {
    path: 'leaphost',
    outlet: LayerOutletName.LeapAPP1,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+leaphost/leaphost-routing.module').then((m) => m.LeaphostRoutingModule),
      },
    ],
  },
  {
    path: 'office-search-transaction',
    outlet: LayerOutletName.PopupGlobal,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+office-search-transaction/office-search-transaction-routing.module').then(
            (m) => m.OfficeSearchTransactionRoutingModule
          ),
      },
    ],
  },
  {
    path: 'change-invoice-status',
    outlet: LayerOutletName.PopupGlobal,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+change-invoice-status/change-invoice-status-routing.module').then(
            (m) => m.ChangeInvoiceStatusRoutingModule
          ),
      },
    ],
  },
  {
    path: 'change-invoice-status',
    outlet: LayerOutletName.Overlay,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+change-invoice-status/change-invoice-status-routing.module').then(
            (m) => m.ChangeInvoiceStatusRoutingModule
          ),
      },
    ],
  },
  {
    path: 'overdue-invoices',
    outlet: LayerOutletName.PopupGlobal,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+overdue-invoices/overdue-invoices-routing.module').then(
            (m) => m.OverdueInvoicesRoutingModule
          ),
      },
    ],
  },
  {
    path: 'overdue-invoices',
    outlet: LayerOutletName.Overlay,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+overdue-invoices/overdue-invoices-routing.module').then(
            (m) => m.OverdueInvoicesRoutingModule
          ),
      },
    ],
  },
  {
    path: 'feedback',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () => import('app/features/+feedback/feedback-routing.module').then((m) => m.FeedbackRoutingModule),
  },
  {
    path: 'report',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+reporting/reporting-routing.module').then((m) => m.ReportingRoutingModule),
  },
  {
    path: 'live-chat',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () => import('app/features/+live-chat/live-chat-routing.module').then((m) => m.LiveChatRoutingModule),
  },
  {
    path: 'general-ledger',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () => import('app/features/+gl-link/gl-link-routing.module').then((m) => m.GlLinkRoutingModule),
  },
  {
    path: 'purchase-orders',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () => import('app/features/+gl-link/gl-link-routing.module').then((m) => m.GlLinkRoutingModule),
  },
  {
    path: 'notification',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+notification/notification-routing.module').then((m) => m.NotificationRoutingModule),
  },
  {
    path: 'recurring-matters',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+recurring-matter-list/recurring-matter-list-routing.module').then(
        (m) => m.RecurringMatterListRoutingModule
      ),
  },
  {
    path: 'recurring-matter-options',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+recurring-matter-options/recurring-matter-options-routing.module').then(
        (m) => m.RecurringMatterOptionsRoutingModule
      ),
  },
  {
    path: 'protected-fund-details',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+protected-fund/protected-fund-details-routing.module').then(
        (m) => m.ProtectedFundDetailsRoutingModule
      ),
  },
  {
    path: 'protected-fund-open-invoices',
    outlet: LayerOutletName.SelectorDetail,
    component: ProxyRouteComponent,
    loadChildren: () =>
      import('app/features/+protected-fund/protected-fund-invoice-list-routing.module').then(
        (m) => m.ProtectedFundInvoiceListRoutingModule
      ),
  },
  {
    path: 'recurring-matter-fee',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@app/features/+recurring-matter-fee/recurring-matter-fee-routing.module').then(
            (m) => m.RecurringMatterFeeRoutingModule
          ),
      },
    ],
  },
  {
    path: 'recurring-matter-cost-recovery',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@app/features/+recurring-matter-cost-recovery/recurring-matter-cost-recovery-routing.module').then(
            (m) => m.RecurringMatterCostRecoveryRoutingModule
          ),
      },
    ],
  },
  {
    path: 'conveyancing',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+conveyancing/conveyancing-routing.module').then((m) => m.ConveyancingRoutingModule),
      },
    ],
  },
  {
    path: 'person',
    outlet: LayerOutletName.Global,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+person/person-modal-routing.module').then((m) => m.PersonModalRoutingModule),
      },
    ],
  },
  {
    path: 'person',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+person/person-modal-routing.module').then((m) => m.PersonModalRoutingModule),
      },
    ],
  },
  {
    path: 'person',
    outlet: LayerOutletName.Selector,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+person/person-modal-routing.module').then((m) => m.PersonModalRoutingModule),
      },
    ],
  },
  {
    path: 'person',
    outlet: LayerOutletName.SelectorDetail,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+person/person-modal-routing.module').then((m) => m.PersonModalRoutingModule),
      },
    ],
  },
  {
    path: 'person',
    outlet: LayerOutletName.Overlay,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+person/person-modal-routing.module').then((m) => m.PersonModalRoutingModule),
      },
    ],
  },
  {
    path: 'lawconnect-share',
    outlet: LayerOutletName.Popup,
    component: ProxyRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/features/+lawconnect-share/lawconnect-share-routing.module').then((m) => m.LawConnectShareRoutingModule)
      }
    ]
  }
];
