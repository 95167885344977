import { Component } from '@angular/core';
import { AgFrameworkComponent } from '@ag-grid-community/angular';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-new-line-cell',
  templateUrl: './new-line-cell.component.html',
})
export class NewLineCellComponent implements AgFrameworkComponent<any> {
  private onNewLineClicked: () => void;
  public btnText = 'New Line';

  constructor() {}

  agInit(params: any) {
    this.onNewLineClicked = !!params && isFunction(params['onNewLineClicked']) ? params['onNewLineClicked'] : () => {};
    this.btnText = !!params && !!params.btnText ? params.btnText : 'New Line';
  }

  newLine(): void {
    this.onNewLineClicked();
  }
}
