export enum EmailPreferences {
  Web = 'Web',
  Outlook = 'Outlook',
}

export enum OfficePreferences {
  Office = 'Office',
  OfficeOnline = 'Office Online',
  Preview = 'Preview',
}

export enum DigestPreferences {
  Daily = 'Daily',
  Weekly = 'Weekly',
  None = 'None',
}

export enum MailFontPreferences {
  Arial = 'Arial',
  Calibri = 'Calibri',
  Monospace = 'Monospace',
  SansSerif = 'Sans Serif',
  Serif = 'Serif',
}

export enum ContentModePreferences {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}
