import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-popover-header',
  templateUrl: './popover-header.component.html',
})
export class PopoverHeaderComponent implements OnInit {
  @Input() headerClass: string;
  constructor() {}

  ngOnInit() {}
}
