<div class="x-tree-cell d-inline-block w-auto">
  <div class="d-flex align-items-center">
    <sc-icon class="x-ag-icon pr-2" [ngClass]="iconSize" [iconName]="iconName"></sc-icon>
    <h6 class="ag-content-name" *ngIf="content">{{ content }}</h6>
  </div>
  <sc-matter-status
    class="pl-3 pr-3"
    [msStatus]="statusClass"
    *ngIf="!!status"
    [msStatusStrip]="status | stripFrontQuestionMark"
  ></sc-matter-status>
</div>