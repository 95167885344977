import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { AppSlice } from '@app/core/store/app.slice';

import * as fromNamedList from './named-list.reducer';
import * as fromCardList from './card-list.reducer';
import * as fromCardDetails from './card-details.reducer';
import * as fromCardModal from './card-modal.reducer';
import * as fromCardListModal from './card-list-modal.reducer';
import * as fromCardDetailsPopup from './card-details-popup.reducer';

export interface State {
  namedList: fromNamedList.State;
  cardList: fromCardList.State;
  cardDetails: fromCardDetails.State;
  cardModal: fromCardModal.State;
  cardListModal: fromCardListModal.State;
  cardDetailsPopup: fromCardDetailsPopup.State;
}

export const INITIAL_STATE: State = {
  namedList: fromNamedList.INITIAL_STATE,
  cardList: fromCardList.INITIAL_STATE,
  cardDetails: fromCardDetails.INITIAL_STATE,
  cardModal: fromCardModal.INITIAL_STATE,
  cardListModal: fromCardListModal.INITIAL_STATE,
  cardDetailsPopup: fromCardDetailsPopup.INITIAL_STATE,
};

export const reducers: ActionReducerMap<any> = {
  namedList: fromNamedList.reducer,
  cardList: fromCardList.reducer,
  cardDetails: fromCardDetails.reducer,
  cardModal: fromCardModal.reducer,
  cardListModal: fromCardListModal.reducer,
  cardDetailsPopup: fromCardDetailsPopup.reducer,
};

export const selectState = createFeatureSelector<State>(AppSlice.Cards);

export const selectNamedListState = createSelector(selectState, (state: State) => state.namedList);

export const selectCardListState = createSelector(selectState, (state: State) => state.cardList);

export const selectCardDetailsState = createSelector(selectState, (state: State) => state.cardDetails);

export const selectCardModalState = createSelector(selectState, (state: State) => state.cardModal);

export const selectCardListModalState = createSelector(selectState, (state: State) => state.cardListModal);

export const selectCardDetailsPopupState = createSelector(selectState, (state: State) => state.cardDetailsPopup);
