import { Action } from '@ngrx/store';
import { Attachment } from '@app/features/+email/models';

// Attach Converted Correspondence
export const ATTACH_CONVERTED = '[AttachCorrespondence] Attach Converted start';
export const ATTACH_CONVERTED_SUCCESS = '[AttachCorrespondence] Attach Converted success';
export const ATTACH_CONVERTED_FAILURE = '[AttachCorrespondence] Attach Converted  failure';

// Attach Normal Correspondence
export const ATTACH_CORRESPONDENCE = '[AttachCorrespondence] Normal Attach start';
export const ATTACH_CORRESPONDENCE_SUCCESS = '[AttachCorrespondence] Normal Attach success';
export const ATTACH_CORRESPONDENCE_FAILURE = '[AttachCorrespondence] Normal Attach failure';

// Attach Calendar Correspondence
export const ATTACH_CALENDAR_CORRESPONDENCE = '[AttachCorrespondence] Calendar Attach start';
export const ATTACH_CALENDAR_CORRESPONDENCE_SUCCESS = '[AttachCorrespondence] Calendar Attach success';
export const ATTACH_CALENDAR_CORRESPONDENCE_FAILURE = '[AttachCorrespondence] Calendar Attach failure';

// load create-PDF Modal
export const CREATE_EMAIL_PDF_DOCS = '[AttachCorrespondence]  Email start';
export const CREATE_EMAIL_PDF_DOCS_SUCCESS = '[AttachCorrespondence]  Email start success';
export const CREATE_EMAIL_PDF_DOCS_FAILURE = '[AttachCorrespondence]  Email start failure';

// Create PDF and Email Actions
export class CreatePDFDocsEmail implements Action {
  readonly type = CREATE_EMAIL_PDF_DOCS;
  constructor(public payload: any) {}
}

export class CreatePDFDocsEmailSuccess implements Action {
  readonly type = CREATE_EMAIL_PDF_DOCS_SUCCESS;
  constructor(public payload: any) {}
}

export class CreatePDFDocsEmailFailure implements Action {
  readonly type = CREATE_EMAIL_PDF_DOCS_FAILURE;
  constructor(public payload: any) {}
}

// Attach Converted Correspondence
export class AttachConverted implements Action {
  readonly type = ATTACH_CONVERTED;
  constructor(public payload: any) {}
}

export class AttachConvertedSuccess implements Action {
  readonly type = ATTACH_CONVERTED_SUCCESS;
  constructor(public payload: any) {}
}

export class AttachConvertedFailure implements Action {
  readonly type = ATTACH_CONVERTED_FAILURE;
  constructor(public payload: any) {}
}

// Attach Normal Correspondence
export class AttachCorrespondence implements Action {
  readonly type = ATTACH_CORRESPONDENCE;
  constructor(public payload: Attachment[]) {}
}

export class AttachCorrespondenceSuccess implements Action {
  readonly type = ATTACH_CORRESPONDENCE_SUCCESS;
  constructor(public payload: Attachment[]) {}
}

export class AttachCorrespondenceFailure implements Action {
  readonly type = ATTACH_CORRESPONDENCE_FAILURE;
  constructor(public payload: any) {}
}

export class AttachCalenderCorrespondence implements Action {
  readonly type = ATTACH_CALENDAR_CORRESPONDENCE;
  constructor(public payload: Attachment[]) {}
}

export class AttachCalendarCorrespondenceSuccess implements Action {
  readonly type = ATTACH_CALENDAR_CORRESPONDENCE_SUCCESS;
  constructor(public payload: Attachment[]) {}
}

export class AttachCalendarCorrespondenceFailure implements Action {
  readonly type = ATTACH_CALENDAR_CORRESPONDENCE_FAILURE;
  constructor(public payload: any) {}
}

export type AttachCorrespondenceActions =
  | CreatePDFDocsEmail
  | CreatePDFDocsEmailSuccess
  | CreatePDFDocsEmailFailure
  | AttachConverted
  | AttachConvertedSuccess
  | AttachConvertedFailure
  | AttachCorrespondence
  | AttachCorrespondenceSuccess
  | AttachCorrespondenceFailure
  | AttachCalenderCorrespondence
  | AttachCalendarCorrespondenceSuccess
  | AttachCalendarCorrespondenceFailure;
