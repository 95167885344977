// Time Fee Ledger GroupBy
export const GroupByNone = { id: 1, value: 'Ungroup' };
// export const GroupByType = { id: 2, value: 'Type' };
// export const GroupByName = { id: 3, value: 'FullName' };
// export const GroupByAddress = { id: 4, value: 'Suburb' };

export enum TimeFeeFilter {
  All = 1,
  BillableAll,
  BillableNextInvoice,
  BillableLaterInvoice,
  NotBillable,
  Billed,
  Deleted,
  ActivityAll,
  ActivityNotDeleted,
  ActivityDeleted
}

export const TimeFeeFilterPrintSelectionMapping = [
  { id: TimeFeeFilter.All, selectId: 5 },
  { id: TimeFeeFilter.BillableAll, selectId: 10 },
  { id: TimeFeeFilter.BillableNextInvoice, selectId: 20 },
  { id: TimeFeeFilter.BillableLaterInvoice, selectId: 30 },
  { id: TimeFeeFilter.NotBillable, selectId: 40 },
  { id: TimeFeeFilter.Billed, selectId: 50 },
  { id: TimeFeeFilter.Deleted, selectId: 70 },
];
