import * as inflowActions from '../actions/inflow-payment-type-details.actions';
import * as outflowActions from '../actions/outflow-payment-type-details.actions';
import * as commonActions from '../actions/common-payment-type-details.actions';
import { IPaymentTypes } from '@app/features/+payments-debtors/models';

export interface State {
  isInflowPaymentType: boolean;
  isOutflowPaymentType: boolean;
  paymentDetails: any;
  paymentId: number;
  paymentType: IPaymentTypes;
  onCloseResponse: any;
  payeeId: string;
}

export const initialState: State = {
  isInflowPaymentType: false,
  isOutflowPaymentType: false,
  paymentDetails: null,
  paymentId: null,
  paymentType: null,
  onCloseResponse: null,
  payeeId: null,
};

export const reducer = (
  state = initialState,
  action:
    | inflowActions.InflowPaymentTypeDetailsAction
    | outflowActions.OutflowPaymentTypeDetailsAction
    | commonActions.CommonPaymentTypeDetailsAction
): State => {
  switch (action.type) {
    case inflowActions.INFLOW_PAYMENT_TYPE_INIT: {
      return {
        ...state,
        isInflowPaymentType: true,
        isOutflowPaymentType: false,
        paymentDetails: action.payload.paymentDetails,
        paymentType: action.payload.paymentType,
        paymentId: action.payload.paymentTypeId,
      };
    }

    case outflowActions.OUTFLOW_PAYMENT_TYPE_INIT: {
      return {
        ...state,
        isInflowPaymentType: false,
        isOutflowPaymentType: true,
        paymentDetails: action.payload.paymentDetails,
        paymentType: action.payload.paymentType,
        paymentId: action.payload.paymentTypeId,
      };
    }

    case outflowActions.OUTFLOW_PAYMENT_TYPE_SET_PAYEE_ID: {
      return {
        ...state,
        payeeId: action.payload,
      };
    }

    case commonActions.PAYMENT_TYPE_MODAL_CLOSE: {
      return {
        ...state,
        onCloseResponse: action.payload,
      };
    }

    case commonActions.PAYMENT_TYPE_MODAL_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export const selectIsInflowType = (state: State) => state.isInflowPaymentType;
export const selectIsOutflowType = (state: State) => state.isOutflowPaymentType;
export const selectPaymentDetails = (state: State) => state.paymentDetails;
export const selectOnCloseResponse = (state: State) => (state ? state.onCloseResponse : null);
export const selectPaymentId = (state: State) => state.paymentId;
export const selectSubPaymentTypeId = (state: State) => state.paymentType?.SubPaymentTypeID;
export const selectPaymentType = (state: State) => state.paymentType;
export const selectPayeeCardId = (state: State) => state.payeeId;
// export const selectTitle = (state: State) => (state && state.uiConfig ? state.uiConfig.title : '');
