import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';

import { environment } from '@env/environment';
import { BrandService, LogService, PlatformService } from '@app/core/services';
import { BrowserService } from '../../services/browser/browser.service';
import { Browser } from '../../models';
import * as automationActions from '@app/core/store/actions/automation.actions';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'sc-download-automation',
  templateUrl: './download-automation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadAutomationComponent implements OnInit, OnDestroy, AfterViewInit {
  private _browser: Browser;
  private _url: string;

  isActive: boolean;

  get brandName(): string {
    return this._brandSvc.brandName;
  }

  constructor(
    private _store: Store<any>,
    private _brandSvc: BrandService,
    private _platformSvc: PlatformService,
    private _log: LogService,
    private _bsModalRef: BsModalRef,
    private _browserSvc: BrowserService
  ) {
    this._browser = this._browserSvc.detectedBrowser;
    this._url = environment.config.endpoint.leaponline;
  }

  get detectedBrowser(): Browser {
    return this._browser;
  }

  get isChrome(): boolean {
    return this.detectedBrowser === Browser.Chrome;
  }

  get isFirefox(): boolean {
    return this.detectedBrowser === Browser.Firefox;
  }

  get isMicrosoftEdge(): boolean {
    return this.detectedBrowser === Browser.MicrosoftEdge;
  }

  get isOther(): boolean {
    return this.detectedBrowser === Browser.Other;
  }

  get isSafari(): boolean {
    return this.detectedBrowser === Browser.Safari;
  }

  get isTitleX(): boolean {
    return this._brandSvc.isTitleX;
  }

  ngOnInit() {
    this.isActive = false;
    this._log.info(this.detectedBrowser);
  }

  ngOnDestroy(): void {
    this._store.dispatch(new automationActions.CloseAutomationDialog(null));
  }

  ngAfterViewInit() {
    this.isActive = !this.isActive;
  }

  dismiss(): void {
    this._bsModalRef.hide();
  }

  download(): void {
    if (this._platformSvc.isBrowser) {
      window.location.assign(this._url);
      this.dismiss();
    }
  }
}
