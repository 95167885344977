import { GridOptions } from '@ag-grid-community/core';
import { LoadingCellRendererComponent } from '../components';

export const TotalRowId = '_TOTAL_ROW_';

export const BalanceRowId = '_BALANCE_ROW_';

export const ActionRowId = '_ACTION_ROW_';

export const TotalTaxRowId = '_TOTAL_INC_TAX_';

export const DefaultServerSideRowModelGridOptions: GridOptions = {
  infiniteInitialRowCount: 1,
  maxConcurrentDatasourceRequests: 1,
  maxBlocksInCache: 2,
  animateRows: true,
  rowModelType: 'serverSide',
  rowHeight: 56,
  cacheBlockSize: 50,
  paginationPageSize: 50,
  groupDisplayType: 'groupRows',
  groupRowRendererParams: {
    innerRenderer: (params) => `<b>${params.node.key}</b>`,
  },
  loadingCellRenderer: LoadingCellRendererComponent,
  loadingCellRendererParams: {
    loadingMessage: 'One moment please...',
  },
  icons: {
    sortAscending: '<span class="ag-icon-down"></span>',
    sortDescending: '<span class="ag-icon-up"></span>',
    groupExpanded:
      '<span class="pl-3 x-icon-md text-muted"><svg class="x-icon"><use xlink:href="#triangle-down"></use></svg></span>',
    groupContracted:
      '<span class="pl-3 x-icon-md text-muted"><svg class="x-icon"><use xlink:href="#triangle-right"></use></svg></span>',
  },
};
