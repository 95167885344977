import { createSelector } from '@ngrx/store';

import * as fromTaskCodeSelectorModal from '../reducers/task-code-selector.reducer';

import { selectTaskCodeSelectorState } from '../reducers';

export const selectSearchText = createSelector(selectTaskCodeSelectorState, (state: fromTaskCodeSelectorModal.State) =>
  fromTaskCodeSelectorModal.selectSearchText(state)
);

export const selectSelectedTaskCodeIds = createSelector(
  selectTaskCodeSelectorState,
  (state: fromTaskCodeSelectorModal.State) => fromTaskCodeSelectorModal.selectSelectedTaskCode(state)
);

export const selectIsMultiSelect = createSelector(
  selectTaskCodeSelectorState,
  (state: fromTaskCodeSelectorModal.State) => fromTaskCodeSelectorModal.selectIsMultiSelect(state)
);

export const selectTaskCodeSelectorResponse = createSelector(
  selectTaskCodeSelectorState,
  (state: fromTaskCodeSelectorModal.State) => fromTaskCodeSelectorModal.selectOnCloseResponse(state)
);

export const selectTaskCodes = createSelector(selectTaskCodeSelectorState, (state: fromTaskCodeSelectorModal.State) =>
  fromTaskCodeSelectorModal.selectTaskCodes(state)
);

export const selectSearchPlaceHolder = createSelector(
  selectTaskCodeSelectorState,
  (state: fromTaskCodeSelectorModal.State) => fromTaskCodeSelectorModal.selectSearchPlaceholder(state)
);

export const selectTitle = createSelector(selectTaskCodeSelectorState, (state: fromTaskCodeSelectorModal.State) =>
  fromTaskCodeSelectorModal.selectTitle(state)
);

export const selectNoGridOverlay = createSelector(
  selectTaskCodeSelectorState,
  (state: fromTaskCodeSelectorModal.State) => fromTaskCodeSelectorModal.selectNoGridOverlay(state)
);
