import { of as observableOf, timer as observableTimer, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'app/shared/services/base/base.service';
import { Attachment, AttachmentType, RefreshAttachmentsRequest } from '../../models/index';
import { map, takeUntil, switchMap } from 'rxjs/operators';
import { FileItem } from 'ng2-file-upload';

import { toRefreshAttachmentsRequest } from '../../models';
import { DocumentIconHelper } from '@app/shared/utils';
import { IDocumentIconInfo } from '@app/shared/models';

@Injectable()
export class AttachmentService extends BaseService {
  timer: Observable<number>;
  private url_: string;
  private _documentHelper: DocumentIconHelper;

  constructor(private http: HttpClient) {
    super();
    this.url_ = `${this.calendarPath}/attachments`;
    this._documentHelper = new DocumentIconHelper();
  }

  getUrl(): string {
    return this.url_;
  }

  toAttachment(files: FileItem[], id: string, index: number): Attachment {
    const file: FileItem = files[index];
    const iconInfo = this.getIconInfo(file._file.name);
    return {
      id,
      name: !!file ? file._file.name : undefined,
      type: AttachmentType.File,
      iconClass: iconInfo.iconClass,
      tooltip: iconInfo.tooltip,
    };
  }

  createAttachments(files: FileItem[]): Observable<Attachment[]> {
    const request = new FormData();
    files.forEach((file: FileItem) => request.append('filename', file._file));

    return this.http
      .post<string[]>(this.url_, request)
      .pipe(
        map((response: string[]) => response.map((id: string, index: number) => this.toAttachment(files, id, index)))
      );
  }

  deleteAttachment(id: string): Observable<void> {
    console.log('deleteAttachment: ', id);
    const url = `${this.url_}/${id}`;
    return this.http.delete<void>(url);
  }

  refreshAttachments(request: RefreshAttachmentsRequest): Observable<void> {
    const url = `${this.url_}/refresh`;
    return this.http.post<void>(url, request);
  }

  startRefreshTimer(ids: string[]): Observable<any> {
    console.log('startRefreshTimer ', ids);
    const request: RefreshAttachmentsRequest = toRefreshAttachmentsRequest(ids);
    return observableTimer(0, 60000).pipe(
      // every minute
      takeUntil(observableOf(ids.length !== 0)),
      switchMap(() => this.refreshAttachments(request))
    );
  }

  getIconInfo(filename: string): IDocumentIconInfo {
    if (!filename) {
      return;
    }
    const ext = filename.split('.').pop();
    return this._documentHelper.getIconInfo(ext);
  }
}
