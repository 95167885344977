import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter as rxjsFilter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as cardModalActions from '../actions/card-modal';
import { AppApiService } from '@app/core/api';
import { CardAddressService, CardDetailsService, CardPersonDetailsService } from '@app/features/+card/services';
import { ICard, IMatterCard, IPerson } from '@app/shared/models';
import {
  selectCardModalActivePersonId,
  selectCardModalCardDetailsInvalidControls,
  selectCardModalFormValue,
  selectCardModalIsFormValueModified,
  selectCardModalPersonList,
} from '@app/features/+card/store/selectors';
import { forkJoin, of, throwError } from 'rxjs';
import { SpecialRate } from '@app/features/+time-fee-ledger/models';
import { CalcService, PersonUtilsService, SpecialFeeHttpService, SpecialFeeService } from '@app/shared/services';
import { selectRouterSnapshot } from '@app/core/store';
import { ActivatedRouteSnapshot } from '@angular/router';
import { selectCurrentDetailEntry } from '@app/features/+matter-details/store';
import { TranslateService } from '@ngx-translate/core';
import {
  CardDetailsTabViewLeapAppLocationId,
  CardErrors,
  ECardDetailsTabType,
  ECardType,
  ICardRelatedMatter,
} from '@app/features/+card/models';
import { ESiriusEvents, LayerOutletName } from '@app/core/models';
import { AuthService, EventBusService } from '@app/core/services';
import { SiriusError } from '@app/features/error-handler/interfaces/error-handler.interfaces';
import * as leapAppActions from '@app/features/matter-addin/store/action/leap-app.action';
import * as cardListActions from '@app/features/+card/store/actions/card-list';
import * as personListActions from '@app/features/+person/store/actions/person-list';
import { ViewLeapAppLocationId } from '@app/features/matter-addin/models';
import { PersonService } from '@app/features/+person/services/person.service';
import { arrayToObj } from '@server/modules/shared/functions/common-util.functions';

@Injectable()
export class CardModalEffect {

  goNewCard$: any = createEffect(() => this.actions$.pipe(
    ofType(cardModalActions.CardModalActionTypes.GO_NEW_CARD),
    tap(() => {
      this.appApiSvc.navigate({
        path: [{ outlets: { [LayerOutletName.Global]: ['card'] } }],
        query: { isNewCard: true },
      });
    })
  ), { dispatch: false });


  closeNewCard$: any = createEffect(() => this.actions$.pipe(
    ofType(cardModalActions.CardModalActionTypes.CLOSE),
    withLatestFrom(this.store.pipe(select(selectCardModalIsFormValueModified))),
    tap(([action, isFormValueModified]: [cardModalActions.CloseModal, boolean]) => {
      if (isFormValueModified) {
        this._eventBus.emit({
          name: ESiriusEvents.ShowDialog,
          value: {
            type: 'confirm',
            title: this.translateSvc.instant('Card.New.Discard.Title'),
            message: this.translateSvc.instant('Card.New.Discard.Message'),
            showCancel: true,
            actionText: this.translateSvc.instant('Card.New.Discard.Action.Text'),
            closeText: this.translateSvc.instant('Card.New.Discard.Close.Text'),
            onClose: (leaveConfirmed) => {
              if (leaveConfirmed) {
                this.appApiSvc.clearCurrentModal();
              }
            },
          },
        });
      } else {
        this.appApiSvc.clearCurrentModal();
      }
    })
  ), { dispatch: false });


  save$: any = createEffect(() => this.actions$.pipe(
    ofType(cardModalActions.CardModalActionTypes.SAVE),
    withLatestFrom(
      this.store.pipe(select(selectCardModalCardDetailsInvalidControls)),
      this.store.pipe(select(selectCardModalFormValue)),
      this.store.pipe(select(selectCardModalIsFormValueModified)),
      of(this.authSvc.decodedToken?.userId)
    ),
    switchMap(
      ([action, invalidControls, formValue, isFormValueModified, userId]: [cardModalActions.Save, string[], any, boolean, string]) => {
        // check if there is any validation error
        if (!!invalidControls && invalidControls.length > 0) {
          return [new cardModalActions.SaveFail({ error: CardErrors[invalidControls[0]] || CardErrors['default'] })];
        }

        const { isNewCard } = action.payload;

        // we only trigger the api save call when we are saving a new card or we are saving a modified cardDetails
        if (isNewCard || isFormValueModified) {
          const properFormValue = this.cardDetailsSvc.formatFormValue(formValue, userId);

          return this.cardDetailsSvc.save(properFormValue).pipe(
            mergeMap(() => [
              new cardModalActions.SaveSucceed({
                message: this.translateSvc.instant('Card.Save.Success.Message'),
              }),
              new personListActions.ListPersonsStart(null),
              new cardListActions.ListCardsStart(null)
            ]),
            catchError((errorResponse) => [
                new cardModalActions.SaveFail({
                  message: errorResponse.error.message || this.translateSvc.instant('Card.Save.Error.Message'),
                }),
              ])
          );
        } else {
          // if the cardDetails is not modified, we close the card modal
          return [new cardModalActions.CloseModal(null)];
        }
      }
    )
  ));


  saveSucceed$ = createEffect(() => this.actions$.pipe(
    ofType(cardModalActions.CardModalActionTypes.SAVE_SUCCEED),
    map((action: any) => action.payload),
    tap((payload) => {
      this.store.dispatch(new cardModalActions.CloseModal(null));
      if (!!payload && !!payload.message) {
        this._eventBus.emit({
          name: ESiriusEvents.ShowToastr,
          value: {
            type: 'success',
            title: 'Success',
            message: payload.message,
          },
        });
      }
    })
  ), { dispatch: false });


  saveFail$ = createEffect(() => this.actions$.pipe(
    ofType(cardModalActions.CardModalActionTypes.SAVE_FAIL),
    switchMap((action: cardModalActions.SaveFail) => {
      let message: string;
      if (action.payload && action.payload.error) {
        message = action.payload.error.message || this.translateSvc.instant('Card.Save.Error.Message');
      } else {
        message = (action.payload && action.payload.message) || this.translateSvc.instant('Card.Save.Error.Message');
      }
      // re-throw error and let error-handler.service to handle this type of error
      return throwError(() =>
        new SiriusError({
          type: 'error',
          title: 'Card Save Error',
          message,
        })
      );
    })
  ), { dispatch: false });


  startNewCard$: any = createEffect(() => this.actions$.pipe(
    ofType<cardModalActions.StartNewCard>(cardModalActions.CardModalActionTypes.START_NEW_CARD),
    withLatestFrom(this.store.pipe(select(selectCurrentDetailEntry))),
    map(([action, detailEntry]: [cardModalActions.StartNewCard, IMatterCard]) => [
      action.payload.type,
      action.payload.isMatterCard,
      detailEntry,
    ]),
    mergeMap(([type, isMatterCard, detailEntry]: [string, boolean, IMatterCard]) => {
      let options: any = { type };
      if (isMatterCard) {
        options = {
          ...options,
          matterCardReference: detailEntry?.reference,
        };
      }
      let formValue = this.cardDetailsSvc.createFormValue(options);
      const newPerson = this.utilsSvc.createPerson({});
      const list = [newPerson];
      formValue = {
        ...formValue,
        personList: list,
      };
      const activePersonId = newPerson.__id;
      const locationId = ViewLeapAppLocationId.CardDetails;

      return !!locationId
        ? [
            new cardModalActions.UpdateFormValue({ data: formValue }),
            new cardModalActions.UpdateActivePersonId({ id: activePersonId }),
            new cardModalActions.UpdateCardDetailsAsFormValue(null),
            new leapAppActions.GetCardModalPageLeapApps({ viewId: locationId }),
          ]
        : [
            new cardModalActions.UpdateFormValue({ data: formValue }),
            new cardModalActions.UpdateActivePersonId({ id: activePersonId }),
            new cardModalActions.UpdateCardDetailsAsFormValue(null),
          ];
    })
  ));


  loadCardDetailsStart$: any = createEffect(() => this.actions$.pipe(
    ofType<cardModalActions.LoadCardDetailsStart>(cardModalActions.CardModalActionTypes.LOAD_CARD_DETAILS_START),
    withLatestFrom(
      this.store.pipe(select(selectCurrentDetailEntry)),
      this.store.pipe(select(selectRouterSnapshot)),
      (action, detailEntry, routerSnapshot) => [
        action.payload.id,
        action.payload.reset,
        action.payload.isMatterCard,
        action.payload.itemDetails,
        detailEntry,
        routerSnapshot,
      ]
    ),
    switchMap(
      ([id, reset, isMatterCard, itemDetails, detailEntry, routerSnapshot]: [
        string,
        boolean,
        boolean,
        ICard,
        IMatterCard,
        ActivatedRouteSnapshot
      ]) => {
        const loadCard = !!itemDetails ? of(itemDetails) : this.cardDetailsSvc.loadCard(id);
        const loadSpecialRatesForCard = this.specialFeeSvc.getSpecialRatesForCard(id);
        const userTitle = this.calcSvc.queryCardCustomDescription(['title', 'dearFormal', 'dearInformal'], id);
        const loadCardRelatedMatters = this.cardDetailsSvc.getMatters(id);
        return forkJoin([loadCard, loadSpecialRatesForCard, userTitle, loadCardRelatedMatters]).pipe(
          mergeMap((results: [any, SpecialRate[], any, ICardRelatedMatter[]]) => {
            // refactor the data from API before storing into ngrx/store
            let cardDetails = {
              ...results[0],
              autoTitle: results[2].title.trim() || '',
              autoDearFormal: results[2].dearFormal.trim() || '',
              autoDearInformal: results[2].dearInformal.trim() || '',
            };

            if (isMatterCard) {
              cardDetails = {
                ...cardDetails,
                matterCardReference: detailEntry.reference,
              };
            }
            const card = this.cardDetailsSvc.createFormValue(cardDetails);
            const isOrganisationCard = routerSnapshot.queryParams.isOrganisationCard === 'true';
            const type = routerSnapshot.queryParams.tabType;
            let locationId = ViewLeapAppLocationId.CardDetails;
            if (type) {
              const tabType = +type as ECardDetailsTabType;
              if (tabType !== ECardDetailsTabType.People || isOrganisationCard || card.cardType !== ECardType.People) {
                locationId = CardDetailsTabViewLeapAppLocationId[tabType];
              }
            }
            const detailsSucceedAction = new cardModalActions.LoadCardDetailsSucceed({
              data: card,
              specialRates: results[1],
              relatedMatters: results[3],
              reset,
            });
            return !!locationId
              ? [detailsSucceedAction, new leapAppActions.GetCardModalPageLeapApps({ viewId: locationId })]
              : [detailsSucceedAction];
          }),
          catchError((error) => {
            this._eventBus.emit({
              name: ESiriusEvents.ShowToastr,
              value: {
                type: 'error',
                title: 'Failure',
                message: 'Unable to load card details.',
              },
            });
            return of(new cardModalActions.LoadCardDetailsFail({ error }));
          })
        );
      }
    )
  ));


  selectExistingPerson$ = createEffect(() => this.actions$.pipe(
    ofType<cardModalActions.SelectExistingPerson>(cardModalActions.CardModalActionTypes.SELECT_EXISTING_PERSON),
    withLatestFrom(
      this.store.pipe(select(selectCardModalPersonList)),
      this.store.pipe(select(selectCardModalActivePersonId)),
      this.store.pipe(select(selectCardModalFormValue)),
      (action, personList, activePersonId, formValue) => ({
        ...action.payload,
        personList,
        activePersonId,
        formValue,
      })
    ),
    switchMap((data) => {
      const { id, itemDetails, personList, activePersonId, formValue } = data;
      // check whether the selected person is in the card
      if (!!personList && !!personList.find((p) => p.__id === id)) {
        return throwError(() =>
          new SiriusError({
            type: 'error',
            title: 'Failure',
            message: 'The selected person is already in the card.',
          })
        );
      }
      const details$ = !!itemDetails ? of(itemDetails) : this.personSvc.getPerson(id);
      return details$.pipe(
        mergeMap((res) => {
          const newPerson = this.utilsSvc.createPerson(res);
          const list = personList.map((p, index) => {
            if (p.__personId === activePersonId) {
              // if we are creating a new card and if we are selecting the 1st person
              // we need to check if we need to update the card level phoneNumberList and webAddressList
              if (index === 0 && formValue.isNew) {
                const hasPhoneValueInCardLevel = formValue.phoneNumberList?.find((pl) => !!pl && !!pl.number);
                const hasWebValueInCardLevel = formValue.webAddressList?.find((w) => !!w && !!w.address);
                if (!hasPhoneValueInCardLevel && !hasWebValueInCardLevel) {
                  const newFormValue = {
                    ...formValue,
                    phoneNumberList: newPerson.phoneNumberList,
                    webAddressList: newPerson.webAddressList,
                  };
                  this.store.dispatch(new cardModalActions.SetCardDetailsFormValue({ data: newFormValue }));
                }
              }

              return newPerson;
            } else {
              return p;
            }
          });
          return [new cardModalActions.UpdatePersonList({ list, id: newPerson.__id })];
        })
      );
    })
  ));


  addPerson$: any = createEffect(() => this.actions$.pipe(
    ofType(cardModalActions.CardModalActionTypes.ADD_PERSON),
    withLatestFrom(this.store.pipe(select(selectCardModalPersonList))),
    mergeMap(([action, personList]: [cardModalActions.AddPerson, IPerson[]]) => {
      const newPerson = this.utilsSvc.createPerson(action.payload.data);
      const list = [...personList, newPerson];
      return [new cardModalActions.UpdatePersonList({ list, id: newPerson.__id })];
    })
  ));


  removePerson$: any = createEffect(() => this.actions$.pipe(
    ofType<cardModalActions.RemovePerson>(cardModalActions.CardModalActionTypes.REMOVE_PERSON),
    withLatestFrom(this.store.pipe(select(selectCardModalPersonList)), (action, personList) => ({
      ...action.payload,
      personList,
    })),
    tap((data) => {
      const { id, shouldRenewLeapCalcInform, personList } = data;
      this._eventBus.emit({
        name: ESiriusEvents.ShowDialog,
        value: {
          type: 'confirm',
          message: 'Delete now?',
          showCancel: true,
          actionText: this.translateSvc.instant('Card.Delete.Confirm.Action.Text'),
          closeText: this.translateSvc.instant('Card.Delete.Confirm.Close.Text'),
          onClose: (confirmed: boolean) => {
            if (confirmed && !!personList) {
              const list = personList.filter((person) => person.__id !== id);
              const firstPersonId = list.length > 0 ? list[0].__id : null;
              this.store.dispatch(new cardModalActions.UpdatePersonList({ list, id: firstPersonId }));
              // check whether we should update the LeapCalcInform
              if (shouldRenewLeapCalcInform) {
                this.store.dispatch(new cardModalActions.GetLetterLeapCalcInform(null));
              }
            }
          },
        },
      });
    })
  ), { dispatch: false });


  changeCardType$: any = createEffect(() => this.actions$.pipe(
    ofType<cardModalActions.ChangeCardType>(cardModalActions.CardModalActionTypes.CHANGE_CARD_TYPE),
    withLatestFrom(this.store.pipe(select(selectCardModalPersonList)), (action, personList) => ({
      cardType: action.payload.cardType,
      personList,
    })),
    mergeMap((data) => {
      const { cardType, personList } = data;
      // when we switch card type to 'People', ensure at least an empty person is in the list for user to fill in inform
      const addPersonAction =
        cardType === ECardType.People && (!personList || personList.length === 0)
          ? [new cardModalActions.AddPerson({ data: {} })]
          : [];
      return [new cardModalActions.UpdateCardType({ type: cardType }), ...addPersonAction];
    })
  ));


  getLetterLeapCalcInform$: any = createEffect(() => this.actions$.pipe(
    ofType(cardModalActions.CardModalActionTypes.GET_LETTER_LEAP_CALC_INFORM),
    withLatestFrom(this.store.pipe(select(selectCardModalFormValue))),
    switchMap(([action, formValue]: [cardModalActions.GetLetterLeapCalcInform, any]) => {
      formValue = {
        ...formValue,
        personList: formValue.personList.filter((person) => !this.personDetailsSvc.isEmpty(person)),
      };
      return this.calcSvc.queryCardCustomDescription(['title', 'dearFormal', 'dearInformal'], null, formValue).pipe(
        mergeMap((res: any) => {
          const inform = {
            title: formValue.useDefaultTitle ? res.title.trim() : formValue.title,
            dear: formValue.useDefaultDear
              ? formValue.useFriendlyDear
                ? res.dearInformal.trim()
                : res.dearFormal.trim()
              : formValue.dear,
            autoTitle: res.title.trim() || '',
            autoDearFormal: res.dearFormal.trim() || '',
            autoDearInformal: res.dearInformal.trim() || '',
          };
          return [new cardModalActions.UpdateLetterLeapCalcInform({ inform })];
        })
      );
    })
  ));


  manageTrusteesInCardModal$: any = createEffect(() => this.actions$.pipe(
    ofType(cardModalActions.CardModalActionTypes.MANAGE_TRUSTEES),
    withLatestFrom(this.store.pipe(select(selectRouterSnapshot))),
    tap(([action, activatedRoute]: [cardModalActions.ManageTrustees, ActivatedRouteSnapshot]) => {
      if (activatedRoute) {
        const outlets = arrayToObj(
          (activatedRoute.children || [])
            .filter((child) => child.outlet !== 'primary')
            .map((child) => [child.outlet, [child.url[0].path]])
        );

        this.appApiSvc.navigate({
          path: [{ outlets: { ...outlets, overlay: ['card', 'card-trustees'] } }],
          query: { isCardModal: true },
        });
      }
    })
  ), { dispatch: false });


  resetCard$: any = createEffect(() => this.actions$.pipe(
    ofType(cardModalActions.CardModalActionTypes.REQUEST_RESET),
    withLatestFrom(this.store.pipe(select(selectCardModalIsFormValueModified))),
    rxjsFilter(([action, isCardDetailsModified]) => !!isCardDetailsModified),
    tap(() => {
      this._eventBus.emit({
        name: ESiriusEvents.ShowDialog,
        value: {
          type: 'confirm',
          title: this.translateSvc.instant('Card.Reset.Confirm.Title'),
          message: this.translateSvc.instant('Card.Reset.Confirm.Message'),
          showCancel: true,
          actionText: this.translateSvc.instant('Card.Reset.Confirm.Action.Text'),
          closeText: this.translateSvc.instant('Card.Reset.Confirm.Close.Text'),
          onClose: (discardConfirmed) => {
            if (discardConfirmed) {
              this.store.dispatch(new cardModalActions.Reset(null));
            }
          },
        },
      });
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private translateSvc: TranslateService,
    private appApiSvc: AppApiService,
    private specialFeeSvc: SpecialFeeService,
    private personDetailsSvc: CardPersonDetailsService,
    private personSvc: PersonService,
    private cardDetailsSvc: CardDetailsService,
    private utilsSvc: PersonUtilsService,
    private _eventBus: EventBusService,
    private calcSvc: CalcService,
    private authSvc: AuthService
  ) {}
}
