import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { AuthService } from '@app/core/services';
import { InterceptorSkipHeader } from '@app/core/interceptors';
import { IntegrationProvider, IntegrationUserDetails } from '@app/shared/models';
import { Regions } from '@app/shared/models/config.model';
import { BaseService, DialogService } from '@app/shared/services';

@Injectable()
export class InfoTrackService extends BaseService {
  constructor(private http: HttpClient, private _authSvc: AuthService, private dialogSvc: DialogService) {
    super();
  }

  public getInfotrackBase(data: {
    matterId: string;
    userDetails: IntegrationUserDetails;
    region: string;
    env: string;
    provider: IntegrationProvider;
  }) {
    const { matterId, userDetails, region, env, provider } = data;
    const providerInLower = provider?.toLowerCase();
    const tokenUrl = this.urlJoin(environment.config.endpoint.docs, `/api/v1/integration/tokens/${providerInLower}`);

    return this.http.get(tokenUrl).pipe(
      mergeMap((token) => {
        const baseUrl = this.getBaseUrl(provider);
        const infotrackUrl = this.urlJoin(baseUrl, 'v2/mapping');
        const infoTrackEnv = this.getInfotrackEnvironment(region, env);
        const prefix = environment.config.brand.id.indexOf('titlex') >= 0 ? 'TX' : 'LEO';
        const { firmId, staffId, firstName, lastName, userId, fullName, email } = userDetails;
        const body = {
          RetailerRef: `${prefix}_${firmId}_${matterId}_${staffId}_${firstName} ${lastName}_${infoTrackEnv}`,
          LEAPAuthToken: this._authSvc.token,
          UserGUID: userId,
          User: {
            UserFullName: fullName,
            Email: email,
          },
        };
        const httpOptions = {
          headers: new HttpHeaders()
            .set(InterceptorSkipHeader, '')
            .set('Authorization', `Basic ${token}`)
            .set('Content-Type', 'application/json'),
        };
        return this.http.post(infotrackUrl, body, httpOptions);
      }),
      map((response: any) => ({
        token: response.Token,
        mappingId: response.MappingId,
        url: response.Url,
        matterId,
        provider,
        success: true,
        settingsUrl: '',
      })),
      catchError((error: any) => {
        // handles errors either in first or second request
        if (error.status >= 400 && error.status < 500) {

          const url =`${environment.config.endpoint.options_app}/integrations/credentials?access_token=${this._authSvc.token}`;

          // return the settings url to go to that contains the Infotrack Settings
          return throwError(() => ({ settingsUrl: url, provider, success: false }));
        } else {
          // unknown error, just report the failure
          return throwError(() => ({ error, provider, success: false }));
        }
      })
    );
  }

  public getInfoTrackFeesUrl(params: {
    fileNumber: string;
    mappingId: string;
    token: string;
    provider: IntegrationProvider;
  }): string {
    const { fileNumber, mappingId, token, provider } = params;
    const baseUrl = this.getBaseUrl(provider, false);
    return this.urlJoin(
      baseUrl,
      '/Matter',
      `?reference=${encodeURIComponent(fileNumber)}`,
      `&mappingid=${mappingId}`,
      `&token=${token}`
    );
  }

  public getInfoTrackSupportUrl(params: {
    mappingId: string;
    token: string;
    region: string;
    provider: IntegrationProvider;
  }): string {
    const { mappingId, token, region, provider } = params;
    const baseUrl = this.getBaseUrl(provider, false);
    const urlSuffix = `mappingid=${mappingId}&token=${token}`;
    switch (region) {
      case Regions.UK:
        return this.urlJoin(baseUrl, `/SupportUk/Callback/Callback?${urlSuffix}`);
      case Regions.AU:
        return this.urlJoin(baseUrl, `/Support1/HelpDeskCall/RequestCallBack?isPopup=false&${urlSuffix}`);
      default:
        return '';
    }
  }

  public getInfoTrackUrl(params: { baseUrl: string; mappingId: string; token: string }): string {
    const { baseUrl, mappingId, token } = params;
    return this.urlJoin(decodeURIComponent(baseUrl), `?mappingid=${mappingId}`, `&token=${token}`);
  }

  public handleIntegrationErrorWithSettingUrl(data: { settingsUrl: string; provider: string }): void {
    const { settingsUrl, provider } = data;
    this.dialogSvc.confirm({
      title: `Invalid ${provider} credentials`,
      message: `Please save correct ${provider} credentials before using this feature`,
      showCancel: true,
      onClose: (confirmed: boolean) => {
        if (confirmed) {
          window.open(settingsUrl, 'InfoTrack');
        }
      },
    });
  }

  public handleIntegrationError(data: { provider?: string; message?: string }): void {
    const { provider, message } = data;
    this.dialogSvc.error({
      message: message || `Unable to connect to ${provider || 'integration'}`,
      onClose: () => {},
    });
  }

  private getInfotrackEnvironment(region: string, env: string): string {
    if (region === Regions.AU) {
      if (env === 'liveb') {
        return 'AWSLB';
      }
      if (env === 'test') {
        return 'AWST';
      }
      if (env === 'dev') {
        return 'DEV';
      }

      return 'AWSL';
    }

    if (region === Regions.US) {
      if (env === 'test') {
        return 'AWSUST';
      }

      return 'AWSUSL';
    }

    if (region === Regions.UK) {
      if (env === 'test') {
        return 'AWSUKT';
      }

      return 'AWSUKL';
    }

    if (region === Regions.CA) {
      if (env === 'test') {
        return 'AWSCAT';
      }

      return 'AWSCAL';
    }

    return 'AWSL';
  }

  private getBaseUrl(provider: IntegrationProvider, proxyUrl = true): string {
    if (!proxyUrl) {
      return provider === IntegrationProvider.TriSearch
        ? environment.config.endpoint.trisearch
        : environment.config.endpoint.infotrack;
    }

    return provider === IntegrationProvider.TriSearch ? environment.config.endpoint.trisearch : environment.config.endpoint.infotrack;
  }
}
