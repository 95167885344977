import * as appActions from '../actions/app.action';

import { Staff } from '../../../shared/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface StaffState extends EntityState<Staff> {
  currentStaffId: string;
}

export const selectId = (staff: Staff): string => staff.__id;

export const sortByFullName = (a: Staff, b: Staff): number => a.fullName.localeCompare(b.fullName);

export const adapter: EntityAdapter<Staff> = createEntityAdapter<Staff>({
  selectId,
  sortComparer: sortByFullName,
});

export const initialState: StaffState = adapter.getInitialState({
  currentStaffId: undefined,
});

export const reducer = (state: StaffState = initialState, { type, payload }: appActions.All): StaffState => {
  switch (type) {
    case appActions.ADD_STAFF_LIST_SUCCESS: {
      return {
        ...state,
        ...adapter.upsertMany(payload, state),
      };
    }

    case appActions.SET_CURRENT_STAFF_ID:
      return {
        ...state,
        currentStaffId: payload.staffId,
      };

    case appActions.LOAD_CURRENT_STAFF_SECURITY_SETTINGS_SUCCESS:
      return {
        ...state,
        ...adapter.updateOne(payload, state),
      };
    default:
      return state;
  }
};

export const getCurrentStaffId = (state: StaffState) => state.currentStaffId;
