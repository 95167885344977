import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sc-file-download-progress',
  templateUrl: './file-download-progress.component.html',
})
export class FileDownloadProgressComponent implements OnInit, OnDestroy {
  @Input() url: string;
  @Input() fileName: string;
  @Input() fileNameOnly: string;
  @Input() stop: Subject<boolean> = new Subject<boolean>();
  @Input() downloadAsFile: boolean = true;
  @Input() token: string;
  @Input() windowDownload: boolean = false;
  @Input() set tag(value: string) {
    this._tag = value;
    this.download();
  }
  get tag() {
    return this._tag;
  }

  @Output() done = new EventEmitter<boolean>();
  @Output() onDownloadError = new EventEmitter<any>();
  @Output() blob = new EventEmitter<Blob>();

  loadingProgress: number = 0;
  private _tag: string;
  private unsubscribe$ = new Subject<void>();

  constructor(private _http: HttpClient) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.stop.next(true);
    this.stop.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  download(): void {
    if (!this.url) {
      return;
    }

    const fromAws = this.url.includes('.amazonaws.');

    if (fromAws) {
      // For AWS URLs, use the URL as-is
      this.downloadUsingAnchor(this.url, this.fileName || this.fileNameOnly || 'file');
      this.done.emit(true);
    } else {
      // For non-AWS URLs, use HttpClient with Authorization header
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${this.token}`,
      });

      this._http
        .get(this.url, {
          headers: headers,
          observe: 'response',
          responseType: 'blob',
        })
        .pipe(takeUntil(this.stop), takeUntil(this.unsubscribe$))
        .subscribe({
          next: (response) => {
            if (response.status === 200) {
              const blob = response.body as Blob;
              if (this.downloadAsFile) {
                FileSaver.saveAs(blob, this.fileName || this.fileNameOnly || 'file');
              }

              this.blob.emit(blob);
              this.done.emit(true);
              this.stop.next(true);
            } else {
              const error = `Download failed with status ${response.status}: ${response.statusText}`;
              this.onDownloadError.emit(error);
            }
          },
          error: (error) => {
            this.onDownloadError.emit(error);
          },
          complete: () => {
          },
        });
    }
  }


  private downloadUsingAnchor(url: string, fileName: string): void {
    try {
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = fileName;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } catch (e) {
      this.onDownloadError.emit(e);
    }
  }
}
