import { Component } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-custom-sortable-header',
  templateUrl: './custom-sortable-header.component.html',
})
export class CustomSortableHeaderComponent implements IHeaderAngularComp {
  private _rememberSortState: boolean;
  public headerName: string;
  public enableCustomSort: boolean;
  public hideToolTips: boolean;
  public onAscClicked: () => void;
  public onDescClicked: () => void;

  agInit(params: IHeaderParams): void {
    this.headerName = params.displayName;
    this.enableCustomSort = params['enableCustomSort'];
    this.onAscClicked = isFunction(params['onAscClicked']) ? params['onAscClicked'] : () => {};
    this.onDescClicked = isFunction(params['onDescClicked']) ? params['onDescClicked'] : () => {};
    this.hideToolTips = params['hideToolTips'];
  }

  refresh(params: IHeaderParams<any>): boolean {
    return false;
  }

  constructor() {
    this._rememberSortState = false;
  }

  get ascToolTip() {
    return !!this.hideToolTips ? undefined : 'Oldest First';
  }

  get descToolTip() {
    return !!this.hideToolTips ? undefined : 'Newest First';
  }
}
