<div>
  <div class="x-cell-title">{{ personRole }}</div>
  <div class="x-cell-subtitle">{{ personDesc }}</div>
</div>

<ng-container *ngIf="persons.length === 1 && isOrganisationCard; else elseBlock">
  <div class="badge x-badge-tag-content">
    <sc-icon class="x-badge-tag-icon x-icon-container" iconName="user"></sc-icon>
    <div class="x-badge-tag-label">
      {{ persons[0].salutation }} {{ persons[0].firstNames }} {{ persons[0].lastName }}
    </div>
  </div>
</ng-container>

<ng-template #elseBlock>
  <div class="btn-toolbar" *ngIf="persons.length > 1">
    <div class="btn-group btn-group-xs btn-group-toggle">
      <div
        class="btn-group btn-group-xs"
        role="group"
        dropdown
        #dropdown="bs-dropdown"
        container="body"
        placement="bottom right"
      >
        <button
          type="button"
          class="btn x-btn-icon x-btn-default btn-xs dropdown-toggle ml-3 x-max-width-180"
          dropdownToggle
          (click)="handleOnClick($event)"
        >
          <sc-icon iconName="user"></sc-icon>
          <span class="text-truncate">{{ dropdownBtnText }}</span>
        </button>
        <ul
          class="x-dropdown-filter dropdown-menu dropdown-menu-right"
          aria-labelledby="filter-dropdown"
          *dropdownMenu
          role="menu"
        >
          <a
            href
            scPreventDefault
            class="dropdown-item"
            role="menuitem"
            [class.active]="isAllSelected"
            (click)="handleOnAllClick($event)"
            ><sc-icon iconName="tick"></sc-icon>All People</a
          >
          <a
            href
            scPreventDefault
            *ngFor="let person of persons; let i = index; trackBy: trackByPerson"
            class="dropdown-item"
            role="menuitem"
            [class.active]="isPersonSelected(person)"
            (click)="handleItemClick($event, i)"
          >
            <sc-icon iconName="tick"></sc-icon>
            <span>{{ getPersonFullName(person) }}</span>
          </a>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
