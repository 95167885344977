import { createSelector } from '@ngrx/store';

import { selectEmailStateFeature, EmailState } from '../reducers';

export const selectLoading = createSelector(selectEmailStateFeature, (state: EmailState) => state.loading);
export const selectLoaded = createSelector(selectEmailStateFeature, (state: EmailState) => state.loaded);
export const selectError = createSelector(selectEmailStateFeature, (state: EmailState) => state.error);
export const selectAttachments = createSelector(selectEmailStateFeature, (state: EmailState) => state.attachments);
export const selectDraft = createSelector(selectEmailStateFeature, (state: EmailState) => state.draft);
export const selectEmailSubject = createSelector(selectEmailStateFeature, (state: EmailState) => state.subject);
export const selectEmail = createSelector(selectEmailStateFeature, (state: EmailState) => state.email);
export const selectEmailSent = createSelector(selectEmailStateFeature, (state: EmailState) => state.emailSent);
export const selectEmailFormDirty = createSelector(selectEmailStateFeature, (state: EmailState) => state.dirty);
export const selectSignature = createSelector(selectEmailStateFeature, (state: EmailState) => state.signature);
export const selectNewWin = createSelector(selectEmailStateFeature, (state: EmailState) => state.newWin);
