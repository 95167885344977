import { environment } from '@env/environment';
import { IByLawyersGuide } from '@app/features/+precedent/models';
import { Regions } from '@app/shared/models/config.model';

const guides = {
  au: [
    {
      Id: '64556001',
      Title: 'FULL COMMENTARY - NEIGHBOURHOOD DISPUTES (NSW)',
      MatterTypeId: '08cad6a0-b2d1-46af-943c-67346873d763',
      State: 'NSW',
      OsUnique: '64556001',
    },
    {
      Id: '61666001',
      Title: 'FULL COMMENTARY - APPREHENDED VIOLENCE ORDER (NSW)',
      MatterTypeId: '08cad6a0-b2d1-46af-943c-67346873d763',
      State: 'NSW',
      OsUnique: '61666001',
    },
    {
      Id: '61453001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - LOCAL COURT (NSW)',
      MatterTypeId: '08cad6a0-b2d1-46af-943c-67346873d763',
      State: 'NSW',
      OsUnique: '61453001',
    },
    {
      Id: '61383001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - LOCAL COURT (NSW)',
      MatterTypeId: '08cad6a0-b2d1-46af-943c-67346873d763',
      State: 'NSW',
      OsUnique: '61383001',
    },
    {
      Id: 'ddec0f05-84f3-49fa-8ebf-29f20aab1a62',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (TAS)',
      MatterTypeId: '1003',
      State: 'TAS',
      OsUnique: 'ddec0f05-84f3-49fa-8ebf-29f20aab1a62',
    },
    {
      Id: '9d4b2e76-9df6-4620-bd83-57796c67a8b8',
      Title: 'FULL COMMENTARY - PROBATE (TAS)',
      MatterTypeId: '1003',
      State: 'TAS',
      OsUnique: '9d4b2e76-9df6-4620-bd83-57796c67a8b8',
    },
    {
      Id: '62564001',
      Title: 'FULL COMMENTARY - WILLS (TAS)',
      MatterTypeId: '1003',
      State: 'TAS',
      OsUnique: '62564001',
    },
    {
      Id: '62588001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ENDURING GUARDIANSHIPS (TAS)',
      MatterTypeId: '1003',
      State: 'TAS',
      OsUnique: '62588001',
    },
    {
      Id: 'ddec0f05-84f3-49fa-8ebf-29f20aab1a62',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (TAS)',
      MatterTypeId: '1004',
      State: 'TAS',
      OsUnique: 'ddec0f05-84f3-49fa-8ebf-29f20aab1a62',
    },
    {
      Id: '9d4b2e76-9df6-4620-bd83-57796c67a8b8',
      Title: 'FULL COMMENTARY - PROBATE (TAS)',
      MatterTypeId: '1004',
      State: 'TAS',
      OsUnique: '9d4b2e76-9df6-4620-bd83-57796c67a8b8',
    },
    {
      Id: '62564001',
      Title: 'FULL COMMENTARY - WILLS (TAS)',
      MatterTypeId: '1004',
      State: 'TAS',
      OsUnique: '62564001',
    },
    {
      Id: '62588001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ENDURING GUARDIANSHIPS (TAS)',
      MatterTypeId: '1004',
      State: 'TAS',
      OsUnique: '62588001',
    },
    {
      Id: 'a248b9e3-ab62-4ca6-8cc5-3491f517fceb',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1010',
      State: 'TAS',
      OsUnique: 'a248b9e3-ab62-4ca6-8cc5-3491f517fceb',
    },
    {
      Id: '186817b4-e8b9-4665-8573-1017363f1e59',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1011',
      State: 'TAS',
      OsUnique: '186817b4-e8b9-4665-8573-1017363f1e59',
    },
    {
      Id: '03cd7a40-8c07-4439-a6ad-088b16798b72',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1046',
      State: 'TAS',
      OsUnique: '03cd7a40-8c07-4439-a6ad-088b16798b72',
    },
    {
      Id: '9d04abfa-fbda-41a3-ad56-ba16e1ac908e',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1048',
      State: 'TAS',
      OsUnique: '9d04abfa-fbda-41a3-ad56-ba16e1ac908e',
    },
    {
      Id: '3ed5dd7e-7bbf-4330-9b66-1dc66ff93399',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1049',
      State: 'TAS',
      OsUnique: '3ed5dd7e-7bbf-4330-9b66-1dc66ff93399',
    },
    {
      Id: '5942b7a3-6e48-452f-ab96-69ff26784385',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1050',
      State: 'TAS',
      OsUnique: '5942b7a3-6e48-452f-ab96-69ff26784385',
    },
    {
      Id: 'da3a4460-ad35-46e8-81c0-40cbb13f35d3',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1051',
      State: 'TAS',
      OsUnique: 'da3a4460-ad35-46e8-81c0-40cbb13f35d3',
    },
    {
      Id: '1ef785ca-e721-4d25-80bf-5e3bae22c121',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1053',
      State: 'TAS',
      OsUnique: '1ef785ca-e721-4d25-80bf-5e3bae22c121',
    },
    {
      Id: 'dfaec06b-b6af-41cb-b7c8-96d01d259b45',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1055',
      State: 'TAS',
      OsUnique: 'dfaec06b-b6af-41cb-b7c8-96d01d259b45',
    },
    {
      Id: 'c6c04b18-7353-42e1-8ffb-6e7412e64d20',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1058',
      State: 'TAS',
      OsUnique: 'c6c04b18-7353-42e1-8ffb-6e7412e64d20',
    },
    {
      Id: 'a9b23d0b-2c26-4929-8e00-8c57aaa95100',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1059',
      State: 'TAS',
      OsUnique: 'a9b23d0b-2c26-4929-8e00-8c57aaa95100',
    },
    {
      Id: 'b7600cce-a74d-46f2-9998-540183d51493',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1060',
      State: 'TAS',
      OsUnique: 'b7600cce-a74d-46f2-9998-540183d51493',
    },
    {
      Id: '7c6d394e-8547-4419-b629-0ce2dc4d319e',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1061',
      State: 'TAS',
      OsUnique: '7c6d394e-8547-4419-b629-0ce2dc4d319e',
    },
    {
      Id: 'ad61d62f-cbe0-4ffb-99eb-4548840e062c',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1066',
      State: 'TAS',
      OsUnique: 'ad61d62f-cbe0-4ffb-99eb-4548840e062c',
    },
    {
      Id: '94acd572-7976-4b37-9a59-33ef9a461760',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1067',
      State: 'TAS',
      OsUnique: '94acd572-7976-4b37-9a59-33ef9a461760',
    },
    {
      Id: '0bbd3888-4794-47a9-9e66-604c4c01a881',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1069',
      State: 'TAS',
      OsUnique: '0bbd3888-4794-47a9-9e66-604c4c01a881',
    },
    {
      Id: '50f73403-0815-4cca-9652-e30c0ff130fb',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '107',
      State: 'All',
      OsUnique: '50f73403-0815-4cca-9652-e30c0ff130fb',
    },
    {
      Id: '32da42eb-14dd-4f55-aad8-2764257758cc',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1083',
      State: 'SA',
      OsUnique: '32da42eb-14dd-4f55-aad8-2764257758cc',
    },
    {
      Id: '68962001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - MAGISTRATES COURT (SA)',
      MatterTypeId: '1086',
      State: 'SA',
      OsUnique: '68962001',
    },
    {
      Id: '63241001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - MAGISTRATES COURT (SA)',
      MatterTypeId: '1086',
      State: 'SA',
      OsUnique: '63241001',
    },
    {
      Id: '80843001',
      Title: 'FULL COMMENTARY - INTERVENTION ORDERS (SA)',
      MatterTypeId: '1086',
      State: 'SA',
      OsUnique: '80843001',
    },
    {
      Id: '62e54099-57c9-4b11-b31a-1a9c608f3928',
      Title: 'Demonstrating the use of precedents',
      MatterTypeId: '1086',
      State: 'SA',
      OsUnique: '62e54099-57c9-4b11-b31a-1a9c608f3928',
    },
    {
      Id: '80917001',
      Title: '101 Subpoena Answers',
      MatterTypeId: '1086',
      State: 'SA',
      OsUnique: '80917001',
    },
    {
      Id: '80d4db9f-1a56-4412-8efa-59f739a42859',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1090',
      State: 'QLD',
      OsUnique: '80d4db9f-1a56-4412-8efa-59f739a42859',
    },
    {
      Id: '60933001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE HEALTH DIRECTIVES (QLD)',
      MatterTypeId: '1093',
      State: 'QLD',
      OsUnique: '60933001',
    },
    {
      Id: '60891001',
      Title: 'FULL COMMENTARY - WILLS (QLD)',
      MatterTypeId: '1093',
      State: 'QLD',
      OsUnique: '60891001',
    },
    {
      Id: '60871001',
      Title: 'FULL COMMENTARY - PROBATE (QLD)',
      MatterTypeId: '1093',
      State: 'QLD',
      OsUnique: '60871001',
    },
    {
      Id: '60912001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (QLD)',
      MatterTypeId: '1093',
      State: 'QLD',
      OsUnique: '60912001',
    },
    {
      Id: '68918001',
      Title: 'FULL COMMENTARY - WILLS (SA)',
      MatterTypeId: '1102',
      State: 'SA',
      OsUnique: '68918001',
    },
    {
      Id: '61062001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE CARE DIRECTIVES (SA)',
      MatterTypeId: '1102',
      State: 'SA',
      OsUnique: '61062001',
    },
    {
      Id: '61262001',
      Title: 'FULL COMMENTARY - PROBATE (SA)',
      MatterTypeId: '1102',
      State: 'SA',
      OsUnique: '61262001',
    },
    {
      Id: '68251001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (SA)',
      MatterTypeId: '1102',
      State: 'SA',
      OsUnique: '68251001',
    },
    {
      Id: '61262001',
      Title: 'FULL COMMENTARY - PROBATE (SA)',
      MatterTypeId: '1103',
      State: 'SA',
      OsUnique: '61262001',
    },
    {
      Id: '68251001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (SA)',
      MatterTypeId: '1103',
      State: 'SA',
      OsUnique: '68251001',
    },
    {
      Id: '61062001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE CARE DIRECTIVES (SA)',
      MatterTypeId: '1103',
      State: 'SA',
      OsUnique: '61062001',
    },
    {
      Id: '68918001',
      Title: 'FULL COMMENTARY - WILLS (SA)',
      MatterTypeId: '1103',
      State: 'SA',
      OsUnique: '68918001',
    },
    {
      Id: '61062001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE CARE DIRECTIVES (SA)',
      MatterTypeId: '1104',
      State: 'SA',
      OsUnique: '61062001',
    },
    {
      Id: '68918001',
      Title: 'FULL COMMENTARY - WILLS (SA)',
      MatterTypeId: '1104',
      State: 'SA',
      OsUnique: '68918001',
    },
    {
      Id: '61262001',
      Title: 'FULL COMMENTARY - PROBATE (SA)',
      MatterTypeId: '1104',
      State: 'SA',
      OsUnique: '61262001',
    },
    {
      Id: '68251001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (SA)',
      MatterTypeId: '1104',
      State: 'SA',
      OsUnique: '68251001',
    },
    {
      Id: '61211001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (SA)',
      MatterTypeId: '1107',
      State: 'SA',
      OsUnique: '61211001',
    },
    {
      Id: '61166001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (SA)',
      MatterTypeId: '1107',
      State: 'SA',
      OsUnique: '61166001',
    },
    {
      Id: '61137001',
      Title: 'FULL COMMENTARY - MORTGAGES (SA)',
      MatterTypeId: '1107',
      State: 'SA',
      OsUnique: '61137001',
    },
    {
      Id: '68918001',
      Title: 'FULL COMMENTARY - WILLS (SA)',
      MatterTypeId: '1116',
      State: 'SA',
      OsUnique: '68918001',
    },
    {
      Id: '61062001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE CARE DIRECTIVES (SA)',
      MatterTypeId: '1116',
      State: 'SA',
      OsUnique: '61062001',
    },
    {
      Id: '61262001',
      Title: 'FULL COMMENTARY - PROBATE (SA)',
      MatterTypeId: '1116',
      State: 'SA',
      OsUnique: '61262001',
    },
    {
      Id: '68251001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (SA)',
      MatterTypeId: '1116',
      State: 'SA',
      OsUnique: '68251001',
    },
    {
      Id: '61137001',
      Title: 'FULL COMMENTARY - MORTGAGES (SA)',
      MatterTypeId: '1118',
      State: 'SA',
      OsUnique: '61137001',
    },
    {
      Id: '61166001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (SA)',
      MatterTypeId: '1118',
      State: 'SA',
      OsUnique: '61166001',
    },
    {
      Id: '61211001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (SA)',
      MatterTypeId: '1118',
      State: 'SA',
      OsUnique: '61211001',
    },
    {
      Id: 'db7377cc-591d-43b6-887d-ec660d14b976',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1120',
      State: 'WA',
      OsUnique: 'db7377cc-591d-43b6-887d-ec660d14b976',
    },
    {
      Id: '57b8ce73-7b74-4153-ba58-176df3ee108c',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1121',
      State: 'WA',
      OsUnique: '57b8ce73-7b74-4153-ba58-176df3ee108c',
    },
    {
      Id: 'e0b64b1a-d482-41ac-89f9-e18ed23a2896',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1141',
      State: 'NT',
      OsUnique: 'e0b64b1a-d482-41ac-89f9-e18ed23a2896',
    },
    {
      Id: '45956f1f-70e5-49a8-9925-d873213db1bd',
      Title: 'FULL COMMENTARY - WILLS (NT)',
      MatterTypeId: '1144',
      State: 'NT',
      OsUnique: '45956f1f-70e5-49a8-9925-d873213db1bd',
    },
    {
      Id: 'c479cbb5-2c31-4e56-af3a-b4855c0a3bed',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1145',
      State: 'NT',
      OsUnique: 'c479cbb5-2c31-4e56-af3a-b4855c0a3bed',
    },
    {
      Id: '45956f1f-70e5-49a8-9925-d873213db1bd',
      Title: 'FULL COMMENTARY - WILLS (NT)',
      MatterTypeId: '1146',
      State: 'NT',
      OsUnique: '45956f1f-70e5-49a8-9925-d873213db1bd',
    },
    {
      Id: 'f7378987-2293-4f17-b8ea-bb6560d8e206',
      Title: 'FULL COMMENTARY - TRUSTS',
      MatterTypeId: '115',
      State: 'All',
      OsUnique: 'f7378987-2293-4f17-b8ea-bb6560d8e206',
    },
    {
      Id: '60159001',
      Title: 'FULL COMMENTARY - COMPANIES',
      MatterTypeId: '115',
      State: 'All',
      OsUnique: '60159001',
    },
    {
      Id: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
      Title: 'FULL COMMENTARY - PARTNERSHIPS',
      MatterTypeId: '115',
      State: 'All',
      OsUnique: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
    },
    {
      Id: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
      Title: 'FULL COMMENTARY - JOINT VENTURES',
      MatterTypeId: '115',
      State: 'All',
      OsUnique: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
    },
    {
      Id: '60196001',
      Title: 'FULL COMMENTARY - SELF MANAGED SUPERANNUATION FUNDS',
      MatterTypeId: '115',
      State: 'All',
      OsUnique: '60196001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '115',
      State: 'All',
      OsUnique: '60350001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '115',
      State: 'All',
      OsUnique: '59835001',
    },
    {
      Id: '64420001',
      Title: 'FULL COMMENTARY - LEASES (SA)',
      MatterTypeId: '1153',
      State: 'SA',
      OsUnique: '64420001',
    },
    {
      Id: '64420001',
      Title: 'FULL COMMENTARY - LEASES (SA)',
      MatterTypeId: '1154',
      State: 'SA',
      OsUnique: '64420001',
    },
    {
      Id: '64420001',
      Title: 'FULL COMMENTARY - LEASES (SA)',
      MatterTypeId: '1156',
      State: 'SA',
      OsUnique: '64420001',
    },
    {
      Id: '64420001',
      Title: 'FULL COMMENTARY - LEASES (SA)',
      MatterTypeId: '1157',
      State: 'SA',
      OsUnique: '64420001',
    },
    {
      Id: '67264001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - SUPREME COURT (VIC)',
      MatterTypeId: '1161',
      State: 'VIC',
      OsUnique: '67264001',
    },
    {
      Id: '65781001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - SUPREME COURT (VIC)',
      MatterTypeId: '1161',
      State: 'VIC',
      OsUnique: '65781001',
    },
    {
      Id: 'c7a77884-fa55-4b75-b7d5-6e39f00d6cbc',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1162',
      State: 'SA',
      OsUnique: 'c7a77884-fa55-4b75-b7d5-6e39f00d6cbc',
    },
    {
      Id: '5578dc58-3e49-4a32-ac30-4462f3639dd8',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1163',
      State: 'SA',
      OsUnique: '5578dc58-3e49-4a32-ac30-4462f3639dd8',
    },
    {
      Id: 'c1d0140b-20a8-4f46-b831-9bad69d6536c',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1175',
      State: 'VIC',
      OsUnique: 'c1d0140b-20a8-4f46-b831-9bad69d6536c',
    },
    {
      Id: '012e040d-c345-4a6a-9dae-4f47c4ce1bfa',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1179',
      State: 'SA',
      OsUnique: '012e040d-c345-4a6a-9dae-4f47c4ce1bfa',
    },
    {
      Id: '61355001',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS AND FRANCHISE (SA)',
      MatterTypeId: '1181',
      State: 'SA',
      OsUnique: '61355001',
    },
    {
      Id: '61334001',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS AND FRANCHISE (SA)',
      MatterTypeId: '1182',
      State: 'SA',
      OsUnique: '61334001',
    },
    {
      Id: '60246001',
      Title: 'FULL COMMENTARY - INSOLVENCY - COMPANY LIQUIDATION',
      MatterTypeId: '1184',
      State: 'All',
      OsUnique: '60246001',
    },
    {
      Id: '60224001',
      Title: 'FULL COMMENTARY - INSOLVENCY - BANKRUPTCY OF INDIVIDUALS',
      MatterTypeId: '1184',
      State: 'All',
      OsUnique: '60224001',
    },
    {
      Id: '60246001',
      Title: 'FULL COMMENTARY - INSOLVENCY - COMPANY LIQUIDATION',
      MatterTypeId: '1185',
      State: 'All',
      OsUnique: '60246001',
    },
    {
      Id: '60224001',
      Title: 'FULL COMMENTARY - INSOLVENCY - BANKRUPTCY OF INDIVIDUALS',
      MatterTypeId: '1185',
      State: 'All',
      OsUnique: '60224001',
    },
    {
      Id: 'b6b51c0e-6c71-44aa-95a8-e0e92acc16dd',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '122',
      State: 'All',
      OsUnique: 'b6b51c0e-6c71-44aa-95a8-e0e92acc16dd',
    },
    {
      Id: '80560001',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS AND FRANCHISE (WA)',
      MatterTypeId: '1221',
      State: 'WA',
      OsUnique: '80560001',
    },
    {
      Id: '79277001',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS AND FRANCHISE (WA)',
      MatterTypeId: '1222',
      State: 'WA',
      OsUnique: '79277001',
    },
    {
      Id: '68251001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (SA)',
      MatterTypeId: '1228',
      State: 'SA',
      OsUnique: '68251001',
    },
    {
      Id: '61262001',
      Title: 'FULL COMMENTARY - PROBATE (SA)',
      MatterTypeId: '1228',
      State: 'SA',
      OsUnique: '61262001',
    },
    {
      Id: '61062001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE CARE DIRECTIVES (SA)',
      MatterTypeId: '1228',
      State: 'SA',
      OsUnique: '61062001',
    },
    {
      Id: '68918001',
      Title: 'FULL COMMENTARY - WILLS (SA)',
      MatterTypeId: '1228',
      State: 'SA',
      OsUnique: '68918001',
    },
    {
      Id: '59694001',
      Title: 'FULL COMMENTARY - PROPERTY SETTLEMENT',
      MatterTypeId: '132',
      State: 'All',
      OsUnique: '59694001',
    },
    {
      Id: '59766001',
      Title: 'FULL COMMENTARY - FINANCIAL AGREEMENTS',
      MatterTypeId: '132',
      State: 'All',
      OsUnique: '59766001',
    },
    {
      Id: '60266001',
      Title: 'FULL COMMENTARY - DIVORCE',
      MatterTypeId: '132',
      State: 'All',
      OsUnique: '60266001',
    },
    {
      Id: '81562001',
      Title: 'FULL COMMENTARY - CHILDREN',
      MatterTypeId: '132',
      State: 'All',
      OsUnique: '81562001',
    },
    {
      Id: '65061001',
      Title: '101 Family Law Answers',
      MatterTypeId: '132',
      State: 'All',
      OsUnique: '65061001',
    },
    {
      Id: '80917001',
      Title: '101 Subpoena Answers',
      MatterTypeId: '132',
      State: 'All',
      OsUnique: '80917001',
    },
    {
      Id: '60266001',
      Title: 'FULL COMMENTARY - DIVORCE',
      MatterTypeId: '133',
      State: 'All',
      OsUnique: '60266001',
    },
    {
      Id: '65061001',
      Title: '101 Family Law Answers',
      MatterTypeId: '133',
      State: 'All',
      OsUnique: '65061001',
    },
    {
      Id: '59766001',
      Title: 'FULL COMMENTARY - FINANCIAL AGREEMENTS',
      MatterTypeId: '133',
      State: 'All',
      OsUnique: '59766001',
    },
    {
      Id: '59694001',
      Title: 'FULL COMMENTARY - PROPERTY SETTLEMENT',
      MatterTypeId: '133',
      State: 'All',
      OsUnique: '59694001',
    },
    {
      Id: 'c0a4cb86-93fb-4cf7-83a9-ca017072830b',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '13d28253-6947-4ab9-88d7-9219662a3a38',
      State: 'NT',
      OsUnique: 'c0a4cb86-93fb-4cf7-83a9-ca017072830b',
    },
    {
      Id: '24680f79-e766-4594-8da8-b8ea72f7f229',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '14107551-2523-471b-a387-a29a4059cbe4',
      State: 'NT',
      OsUnique: '24680f79-e766-4594-8da8-b8ea72f7f229',
    },
    {
      Id: '8c4a5d88-726f-4fba-bcd9-df093a7d92a7',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '145',
      State: 'NSW',
      OsUnique: '8c4a5d88-726f-4fba-bcd9-df093a7d92a7',
    },
    {
      Id: '60382001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (NSW)',
      MatterTypeId: '2',
      State: 'NSW',
      OsUnique: '60382001',
    },
    {
      Id: '68689001',
      Title: '1001 Conveyancing Answers (NSW)',
      MatterTypeId: '2',
      State: 'NSW',
      OsUnique: '68689001',
    },
    {
      Id: '59634001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (NSW)',
      MatterTypeId: '2',
      State: 'NSW',
      OsUnique: '59634001',
    },
    {
      Id: '68323001',
      Title: 'FULL COMMENTARY - MORTGAGES (NSW)',
      MatterTypeId: '2',
      State: 'NSW',
      OsUnique: '68323001',
    },
    {
      Id: '15facd93-23fc-4e76-8fe8-9957f5ce2aef',
      Title: 'FULL COMMENTARY - RETIREMENT VILLAGES NSW',
      MatterTypeId: '2',
      State: 'NSW',
      OsUnique: '15facd93-23fc-4e76-8fe8-9957f5ce2aef',
    },
    {
      Id: '577a5f70-ce05-492f-9724-3f039a1fe5f3',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '203',
      State: 'NSW',
      OsUnique: '577a5f70-ce05-492f-9724-3f039a1fe5f3',
    },
    {
      Id: '68323001',
      Title: 'FULL COMMENTARY - MORTGAGES (NSW)',
      MatterTypeId: '204',
      State: 'NSW',
      OsUnique: '68323001',
    },
    {
      Id: '68689001',
      Title: '1001 Conveyancing Answers (NSW)',
      MatterTypeId: '204',
      State: 'NSW',
      OsUnique: '68689001',
    },
    {
      Id: '64338001',
      Title: 'FULL COMMENTARY - LEASES (NSW)',
      MatterTypeId: '205',
      State: 'NSW',
      OsUnique: '64338001',
    },
    {
      Id: '68a977c5-ba97-4b74-a8df-343cdae01b5d',
      Title: 'FULL COMMENTARY - IMMIGRATION',
      MatterTypeId: '206',
      State: 'All',
      OsUnique: '68a977c5-ba97-4b74-a8df-343cdae01b5d',
    },
    {
      Id: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
      Title: 'FULL COMMENTARY - JOINT VENTURES',
      MatterTypeId: '213',
      State: 'All',
      OsUnique: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
    },
    {
      Id: '60159001',
      Title: 'FULL COMMENTARY - COMPANIES',
      MatterTypeId: '213',
      State: 'All',
      OsUnique: '60159001',
    },
    {
      Id: 'f7378987-2293-4f17-b8ea-bb6560d8e206',
      Title: 'FULL COMMENTARY - TRUSTS',
      MatterTypeId: '213',
      State: 'All',
      OsUnique: 'f7378987-2293-4f17-b8ea-bb6560d8e206',
    },
    {
      Id: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
      Title: 'FULL COMMENTARY - PARTNERSHIPS',
      MatterTypeId: '213',
      State: 'All',
      OsUnique: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
    },
    {
      Id: '60196001',
      Title: 'FULL COMMENTARY - SELF MANAGED SUPERANNUATION FUNDS',
      MatterTypeId: '213',
      State: 'All',
      OsUnique: '60196001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '213',
      State: 'All',
      OsUnique: '60350001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '213',
      State: 'All',
      OsUnique: '59835001',
    },
    {
      Id: '60159001',
      Title: 'FULL COMMENTARY - COMPANIES',
      MatterTypeId: '214',
      State: 'All',
      OsUnique: '60159001',
    },
    {
      Id: '2aadac5a-5b25-4cdd-b531-3e1baa8086ec',
      Title: 'FULL COMMENTARY - TRUSTS',
      MatterTypeId: '214',
      State: 'All',
      OsUnique: '2aadac5a-5b25-4cdd-b531-3e1baa8086ec',
    },
    {
      Id: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
      Title: 'FULL COMMENTARY - PARTNERSHIPS',
      MatterTypeId: '214',
      State: 'All',
      OsUnique: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
    },
    {
      Id: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
      Title: 'FULL COMMENTARY - JOINT VENTURES',
      MatterTypeId: '214',
      State: 'All',
      OsUnique: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
    },
    {
      Id: '60196001',
      Title: 'FULL COMMENTARY - SELF MANAGED SUPERANNUATION FUNDS',
      MatterTypeId: '214',
      State: 'All',
      OsUnique: '60196001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '214',
      State: 'All',
      OsUnique: '60350001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '214',
      State: 'All',
      OsUnique: '59835001',
    },
    {
      Id: '9a979a3d-75dc-474f-9477-2fc3401c6c84',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '217',
      State: 'All',
      OsUnique: '9a979a3d-75dc-474f-9477-2fc3401c6c84',
    },
    {
      Id: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
      Title: 'FULL COMMENTARY - PARTNERSHIPS',
      MatterTypeId: '227',
      State: 'All',
      OsUnique: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
    },
    {
      Id: '60159001',
      Title: 'FULL COMMENTARY - COMPANIES',
      MatterTypeId: '227',
      State: 'All',
      OsUnique: '60159001',
    },
    {
      Id: 'f7378987-2293-4f17-b8ea-bb6560d8e206',
      Title: 'FULL COMMENTARY - TRUSTS',
      MatterTypeId: '227',
      State: 'All',
      OsUnique: 'f7378987-2293-4f17-b8ea-bb6560d8e206',
    },
    {
      Id: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
      Title: 'FULL COMMENTARY - JOINT VENTURES',
      MatterTypeId: '227',
      State: 'All',
      OsUnique: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
    },
    {
      Id: '60196001',
      Title: 'FULL COMMENTARY - SELF MANAGED SUPERANNUATION FUNDS',
      MatterTypeId: '227',
      State: 'All',
      OsUnique: '60196001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '227',
      State: 'All',
      OsUnique: '60350001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '227',
      State: 'All',
      OsUnique: '59835001',
    },
    {
      Id: '62104001',
      Title: 'FULL COMMENTARY - PERSONAL INJURY (NSW)',
      MatterTypeId: '232',
      State: 'NSW',
      OsUnique: '62104001',
    },
    {
      Id: '61822001',
      Title: 'FULL COMMENTARY - MOTOR VEHICLE ACCIDENT (NSW) - ACCIDENTS PRIOR TO 1 DECEMBER 2017',
      MatterTypeId: '232',
      State: 'NSW',
      OsUnique: '61822001',
    },
    {
      Id: '47510fa3-ad4f-4742-8550-cf0654260307',
      Title: 'FULL COMMENTARY - MOTOR VEHICLE ACCIDENT (NSW) - ACCIDENTS FROM 1 DECEMBER 2017',
      MatterTypeId: '232',
      State: 'NSW',
      OsUnique: '47510fa3-ad4f-4742-8550-cf0654260307',
    },
    {
      Id: '61899001',
      Title: 'FULL COMMENTARY - VICTIMS RIGHTS AND SUPPORT (NSW)',
      MatterTypeId: '232',
      State: 'NSW',
      OsUnique: '61899001',
    },
    {
      Id: '61851001',
      Title: 'FULL COMMENTARY - WORKERS COMPENSATION (NSW)',
      MatterTypeId: '232',
      State: 'NSW',
      OsUnique: '61851001',
    },
    {
      Id: '61685001',
      Title: 'FULL COMMENTARY - FAMILY PROVISION CLAIMS - ACTING FOR THE ESTATE (NSW)',
      MatterTypeId: '235',
      State: 'NSW',
      OsUnique: '61685001',
    },
    {
      Id: '61724001',
      Title: 'FULL COMMENTARY - FAMILY PROVISION CLAIMS - ACTING FOR THE PLAINTIFF (NSW)',
      MatterTypeId: '235',
      State: 'NSW',
      OsUnique: '61724001',
    },
    {
      Id: '80917001',
      Title: '101 Subpoena Answers',
      MatterTypeId: '235',
      State: 'NSW',
      OsUnique: '80917001',
    },
    {
      Id: '1f97316f-ab46-4290-8add-2b79499488ca',
      Title: '101 Succession Answers (NSW)',
      MatterTypeId: '235',
      State: 'NSW',
      OsUnique: '1f97316f-ab46-4290-8add-2b79499488ca',
    },
    {
      Id: '9a979a3d-75dc-474f-9477-2fc3401c6c84',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '236',
      State: 'All',
      OsUnique: '9a979a3d-75dc-474f-9477-2fc3401c6c84',
    },
    {
      Id: '68323001',
      Title: 'FULL COMMENTARY - MORTGAGES (NSW)',
      MatterTypeId: '276',
      State: 'NSW',
      OsUnique: '68323001',
    },
    {
      Id: '68689001',
      Title: '1001 Conveyancing Answers (NSW)',
      MatterTypeId: '276',
      State: 'NSW',
      OsUnique: '68689001',
    },
    {
      Id: 'ddd29eea-ebba-4224-98ca-274e039c429f',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '280',
      State: 'NSW',
      OsUnique: 'ddd29eea-ebba-4224-98ca-274e039c429f',
    },
    {
      Id: '65061001',
      Title: '101 Family Law Answers',
      MatterTypeId: '284',
      State: 'All',
      OsUnique: '65061001',
    },
    {
      Id: '60266001',
      Title: 'FULL COMMENTARY - DIVORCE',
      MatterTypeId: '284',
      State: 'All',
      OsUnique: '60266001',
    },
    {
      Id: '59766001',
      Title: 'FULL COMMENTARY - FINANCIAL AGREEMENTS',
      MatterTypeId: '284',
      State: 'All',
      OsUnique: '59766001',
    },
    {
      Id: '59694001',
      Title: 'FULL COMMENTARY - PROPERTY SETTLEMENT',
      MatterTypeId: '284',
      State: 'All',
      OsUnique: '59694001',
    },
    {
      Id: 'bfd94e2a-0e8e-40e4-b054-f877f561bb46',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '288',
      State: 'All',
      OsUnique: 'bfd94e2a-0e8e-40e4-b054-f877f561bb46',
    },
    {
      Id: '633bcb3b-f86e-4044-ae49-29533c95a8a5',
      Title: 'FULL COMMENTARY - DEFAMATION AND PROTECTING REPUTATION',
      MatterTypeId: '28bf5920-0a46-4c87-9624-2aa82c58a7c1',
      State: 'SA',
      OsUnique: '633bcb3b-f86e-4044-ae49-29533c95a8a5',
    },
    {
      Id: '65348001',
      Title: 'FULL COMMENTARY - EMPLOYMENT LAW',
      MatterTypeId: '291',
      State: 'All',
      OsUnique: '65348001',
    },
    {
      Id: '902714ae-fb3a-48d3-ab0b-27e6a9aa3f93',
      Title: '101 Employment Law Answers',
      MatterTypeId: '291',
      State: 'All',
      OsUnique: '902714ae-fb3a-48d3-ab0b-27e6a9aa3f93',
    },
    {
      Id: '60159001',
      Title: 'FULL COMMENTARY - COMPANIES',
      MatterTypeId: '292',
      State: 'All',
      OsUnique: '60159001',
    },
    {
      Id: '2aadac5a-5b25-4cdd-b531-3e1baa8086ec',
      Title: 'FULL COMMENTARY - TRUSTS',
      MatterTypeId: '292',
      State: 'All',
      OsUnique: '2aadac5a-5b25-4cdd-b531-3e1baa8086ec',
    },
    {
      Id: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
      Title: 'FULL COMMENTARY - PARTNERSHIPS',
      MatterTypeId: '292',
      State: 'All',
      OsUnique: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
    },
    {
      Id: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
      Title: 'FULL COMMENTARY - JOINT VENTURES',
      MatterTypeId: '292',
      State: 'All',
      OsUnique: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
    },
    {
      Id: '60196001',
      Title: 'FULL COMMENTARY - SELF MANAGED SUPERANNUATION FUNDS',
      MatterTypeId: '292',
      State: 'All',
      OsUnique: '60196001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '292',
      State: 'All',
      OsUnique: '60350001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '292',
      State: 'All',
      OsUnique: '59835001',
    },
    {
      Id: '60530001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (VIC)',
      MatterTypeId: '2b30af9d-9cd0-48a1-948c-49ade05acc3a',
      State: 'VIC',
      OsUnique: '60530001',
    },
    {
      Id: '60564001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (VIC)',
      MatterTypeId: '2b30af9d-9cd0-48a1-948c-49ade05acc3a',
      State: 'VIC',
      OsUnique: '60564001',
    },
    {
      Id: '60780001',
      Title: 'FULL COMMENTARY - MORTGAGES (VIC)',
      MatterTypeId: '2b30af9d-9cd0-48a1-948c-49ade05acc3a',
      State: 'VIC',
      OsUnique: '60780001',
    },
    {
      Id: '68692001',
      Title: '1001 Conveyancing Answers (VIC)',
      MatterTypeId: '2b30af9d-9cd0-48a1-948c-49ade05acc3a',
      State: 'VIC',
      OsUnique: '68692001',
    },
    {
      Id: '2b9409aa-af8b-470d-a4a7-4c8dcc3ad3a0',
      Title: '101 Section 32 Answers (VIC)',
      MatterTypeId: '2b30af9d-9cd0-48a1-948c-49ade05acc3a',
      State: 'VIC',
      OsUnique: '2b9409aa-af8b-470d-a4a7-4c8dcc3ad3a0',
    },
    {
      Id: 'ce069915-db0a-47c7-8e0f-b8c0038c391e',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '2f90324e-7b86-4b81-b056-5efce1b011ac',
      State: 'NT',
      OsUnique: 'ce069915-db0a-47c7-8e0f-b8c0038c391e',
    },
    {
      Id: 'ce65e65b-908a-4669-bf1c-e7c673021190',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '312',
      State: 'NSW',
      OsUnique: 'ce65e65b-908a-4669-bf1c-e7c673021190',
    },
    {
      Id: '60159001',
      Title: 'FULL COMMENTARY - COMPANIES',
      MatterTypeId: '313',
      State: 'All',
      OsUnique: '60159001',
    },
    {
      Id: '2aadac5a-5b25-4cdd-b531-3e1baa8086ec',
      Title: 'FULL COMMENTARY - TRUSTS',
      MatterTypeId: '313',
      State: 'All',
      OsUnique: '2aadac5a-5b25-4cdd-b531-3e1baa8086ec',
    },
    {
      Id: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
      Title: 'FULL COMMENTARY - PARTNERSHIPS',
      MatterTypeId: '313',
      State: 'All',
      OsUnique: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
    },
    {
      Id: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
      Title: 'FULL COMMENTARY - JOINT VENTURES',
      MatterTypeId: '313',
      State: 'All',
      OsUnique: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
    },
    {
      Id: '60196001',
      Title: 'FULL COMMENTARY - SELF MANAGED SUPERANNUATION FUNDS',
      MatterTypeId: '313',
      State: 'All',
      OsUnique: '60196001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '313',
      State: 'All',
      OsUnique: '60350001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '313',
      State: 'All',
      OsUnique: '59835001',
    },
    {
      Id: '60246001',
      Title: 'FULL COMMENTARY - INSOLVENCY - COMPANY LIQUIDATION',
      MatterTypeId: '315',
      State: 'All',
      OsUnique: '60246001',
    },
    {
      Id: '60224001',
      Title: 'FULL COMMENTARY - INSOLVENCY - BANKRUPTCY OF INDIVIDUALS',
      MatterTypeId: '315',
      State: 'All',
      OsUnique: '60224001',
    },
    {
      Id: '312c71e1-802f-4952-9d3d-3804451889e7',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '326',
      State: 'All',
      OsUnique: '312c71e1-802f-4952-9d3d-3804451889e7',
    },
    {
      Id: '60196001',
      Title: 'FULL COMMENTARY - SELF MANAGED SUPERANNUATION FUNDS',
      MatterTypeId: '327',
      State: 'All',
      OsUnique: '60196001',
    },
    {
      Id: '60159001',
      Title: 'FULL COMMENTARY - COMPANIES',
      MatterTypeId: '327',
      State: 'All',
      OsUnique: '60159001',
    },
    {
      Id: '2aadac5a-5b25-4cdd-b531-3e1baa8086ec',
      Title: 'FULL COMMENTARY - TRUSTS',
      MatterTypeId: '327',
      State: 'All',
      OsUnique: '2aadac5a-5b25-4cdd-b531-3e1baa8086ec',
    },
    {
      Id: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
      Title: 'FULL COMMENTARY - PARTNERSHIPS',
      MatterTypeId: '327',
      State: 'All',
      OsUnique: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
    },
    {
      Id: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
      Title: 'FULL COMMENTARY - JOINT VENTURES',
      MatterTypeId: '327',
      State: 'All',
      OsUnique: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '327',
      State: 'All',
      OsUnique: '60350001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '327',
      State: 'All',
      OsUnique: '59835001',
    },
    {
      Id: '60196001',
      Title: 'FULL COMMENTARY - SELF MANAGED SUPERANNUATION FUNDS',
      MatterTypeId: '328',
      State: 'All',
      OsUnique: '60196001',
    },
    {
      Id: '60159001',
      Title: 'FULL COMMENTARY - COMPANIES',
      MatterTypeId: '328',
      State: 'All',
      OsUnique: '60159001',
    },
    {
      Id: 'f7378987-2293-4f17-b8ea-bb6560d8e206',
      Title: 'FULL COMMENTARY - TRUSTS',
      MatterTypeId: '328',
      State: 'All',
      OsUnique: 'f7378987-2293-4f17-b8ea-bb6560d8e206',
    },
    {
      Id: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
      Title: 'FULL COMMENTARY - PARTNERSHIPS',
      MatterTypeId: '328',
      State: 'All',
      OsUnique: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
    },
    {
      Id: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
      Title: 'FULL COMMENTARY - JOINT VENTURES',
      MatterTypeId: '328',
      State: 'All',
      OsUnique: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '328',
      State: 'All',
      OsUnique: '60350001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '328',
      State: 'All',
      OsUnique: '59835001',
    },
    {
      Id: '68694001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - DISTRICT COURT (NSW)',
      MatterTypeId: '343',
      State: 'NSW',
      OsUnique: '68694001',
    },
    {
      Id: '62017001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - DISTRICT COURT (NSW)',
      MatterTypeId: '343',
      State: 'NSW',
      OsUnique: '62017001',
    },
    {
      Id: '71729001',
      Title: 'FULL COMMENTARY - ENFORCEMENT (NSW)',
      MatterTypeId: '343',
      State: 'NSW',
      OsUnique: '71729001',
    },
    {
      Id: '80917001',
      Title: '101 Subpoena Answers',
      MatterTypeId: '343',
      State: 'NSW',
      OsUnique: '80917001',
    },
    {
      Id: 'f0c016ac-19d9-4f48-80fc-3449685e97e4',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '345',
      State: 'NSW',
      OsUnique: 'f0c016ac-19d9-4f48-80fc-3449685e97e4',
    },
    {
      Id: 'bf3c70fc-3dd7-4e07-8003-bafbb9c2f765',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '346',
      State: 'NSW',
      OsUnique: 'bf3c70fc-3dd7-4e07-8003-bafbb9c2f765',
    },
    {
      Id: '59744001',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS AND FRANCHISE (NSW)',
      MatterTypeId: '35',
      State: 'NSW',
      OsUnique: '59744001',
    },
    {
      Id: '60475001',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS AND FRANCHISE (NSW)',
      MatterTypeId: '35',
      State: 'NSW',
      OsUnique: '60475001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '35',
      State: 'NSW',
      OsUnique: '59835001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '35',
      State: 'NSW',
      OsUnique: '60350001',
    },
    {
      Id: '68689001',
      Title: '1001 Conveyancing Answers (NSW)',
      MatterTypeId: '35',
      State: 'NSW',
      OsUnique: '68689001',
    },
    {
      Id: 'c09e4955-f843-4d93-93d4-0227f6f043f1',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '350',
      State: 'VIC',
      OsUnique: 'c09e4955-f843-4d93-93d4-0227f6f043f1',
    },
    {
      Id: '68a977c5-ba97-4b74-a8df-343cdae01b5d',
      Title: 'FULL COMMENTARY - IMMIGRATION',
      MatterTypeId: '0d21ecc4-aec2-41c9-8d34-d309528b9feb',
      State: 'All',
      OsUnique: '68a977c5-ba97-4b74-a8df-343cdae01b5d',
    },
    {
      Id: '68a977c5-ba97-4b74-a8df-343cdae01b5d',
      Title: 'FULL COMMENTARY - IMMIGRATION',
      MatterTypeId: '0de10b49-ad00-42bd-bf60-30f3dc4a1daa',
      State: 'All',
      OsUnique: '68a977c5-ba97-4b74-a8df-343cdae01b5d',
    },
    {
      Id: '59634001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (NSW)',
      MatterTypeId: '1',
      State: 'NSW',
      OsUnique: '59634001',
    },
    {
      Id: '68689001',
      Title: '1001 Conveyancing Answers (NSW)',
      MatterTypeId: '1',
      State: 'NSW',
      OsUnique: '68689001',
    },
    {
      Id: '60382001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (NSW)',
      MatterTypeId: '1',
      State: 'NSW',
      OsUnique: '60382001',
    },
    {
      Id: '68323001',
      Title: 'FULL COMMENTARY - MORTGAGES (NSW)',
      MatterTypeId: '1',
      State: 'NSW',
      OsUnique: '68323001',
    },
    {
      Id: '15facd93-23fc-4e76-8fe8-9957f5ce2aef',
      Title: 'FULL COMMENTARY - RETIREMENT VILLAGES NSW',
      MatterTypeId: '1',
      State: 'NSW',
      OsUnique: '15facd93-23fc-4e76-8fe8-9957f5ce2aef',
    },
    {
      Id: 'f7e4e617-d8b7-4d73-9cf8-35d0e0bab8f5',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1001',
      State: 'TAS',
      OsUnique: 'f7e4e617-d8b7-4d73-9cf8-35d0e0bab8f5',
    },
    {
      Id: '9d4b2e76-9df6-4620-bd83-57796c67a8b8',
      Title: 'FULL COMMENTARY - PROBATE (TAS)',
      MatterTypeId: '1006',
      State: 'TAS',
      OsUnique: '9d4b2e76-9df6-4620-bd83-57796c67a8b8',
    },
    {
      Id: 'ddec0f05-84f3-49fa-8ebf-29f20aab1a62',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (TAS)',
      MatterTypeId: '1006',
      State: 'TAS',
      OsUnique: 'ddec0f05-84f3-49fa-8ebf-29f20aab1a62',
    },
    {
      Id: '62564001',
      Title: 'FULL COMMENTARY - WILLS (TAS)',
      MatterTypeId: '1006',
      State: 'TAS',
      OsUnique: '62564001',
    },
    {
      Id: '62588001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ENDURING GUARDIANSHIPS (TAS)',
      MatterTypeId: '1006',
      State: 'TAS',
      OsUnique: '62588001',
    },
    {
      Id: '2aadac5a-5b25-4cdd-b531-3e1baa8086ec',
      Title: 'FULL COMMENTARY - TRUSTS',
      MatterTypeId: '101',
      State: 'All',
      OsUnique: '2aadac5a-5b25-4cdd-b531-3e1baa8086ec',
    },
    {
      Id: '60159001',
      Title: 'FULL COMMENTARY - COMPANIES',
      MatterTypeId: '101',
      State: 'All',
      OsUnique: '60159001',
    },
    {
      Id: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
      Title: 'FULL COMMENTARY - PARTNERSHIPS',
      MatterTypeId: '101',
      State: 'All',
      OsUnique: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
    },
    {
      Id: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
      Title: 'FULL COMMENTARY - JOINT VENTURES',
      MatterTypeId: '101',
      State: 'All',
      OsUnique: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
    },
    {
      Id: '60196001',
      Title: 'FULL COMMENTARY - SELF MANAGED SUPERANNUATION FUNDS',
      MatterTypeId: '101',
      State: 'All',
      OsUnique: '60196001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '101',
      State: 'All',
      OsUnique: '60350001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '101',
      State: 'All',
      OsUnique: '59835001',
    },
    {
      Id: '941d16bf-a34e-4691-9662-e82d6e25eee9',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1012',
      State: 'TAS',
      OsUnique: '941d16bf-a34e-4691-9662-e82d6e25eee9',
    },
    {
      Id: '3f38d9df-6bfd-4ff9-955e-aacb2e717b3f',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1019',
      State: 'TAS',
      OsUnique: '3f38d9df-6bfd-4ff9-955e-aacb2e717b3f',
    },
    {
      Id: '78396efb-285e-4865-a551-8e32fc43842c',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1026',
      State: 'TAS',
      OsUnique: '78396efb-285e-4865-a551-8e32fc43842c',
    },
    {
      Id: 'abf582fe-418d-489c-9a47-60373456cef6',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1062',
      State: 'TAS',
      OsUnique: 'abf582fe-418d-489c-9a47-60373456cef6',
    },
    {
      Id: '54ef1574-e76c-42f9-897b-fc4d93a2a028',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1063',
      State: 'TAS',
      OsUnique: '54ef1574-e76c-42f9-897b-fc4d93a2a028',
    },
    {
      Id: '10c16b48-9eab-453b-850a-b55a2a19acc0',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1064',
      State: 'TAS',
      OsUnique: '10c16b48-9eab-453b-850a-b55a2a19acc0',
    },
    {
      Id: '9e4b9f56-9d6e-4dbf-b7a1-76214deaa537',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1065',
      State: 'TAS',
      OsUnique: '9e4b9f56-9d6e-4dbf-b7a1-76214deaa537',
    },
    {
      Id: '62588001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ENDURING GUARDIANSHIPS (TAS)',
      MatterTypeId: '1073',
      State: 'TAS',
      OsUnique: '62588001',
    },
    {
      Id: '62564001',
      Title: 'FULL COMMENTARY - WILLS (TAS)',
      MatterTypeId: '1073',
      State: 'TAS',
      OsUnique: '62564001',
    },
    {
      Id: '62564001',
      Title: 'FULL COMMENTARY - WILLS (TAS)',
      MatterTypeId: '1074',
      State: 'TAS',
      OsUnique: '62564001',
    },
    {
      Id: '62588001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ENDURING GUARDIANSHIPS (TAS)',
      MatterTypeId: '1074',
      State: 'TAS',
      OsUnique: '62588001',
    },
    {
      Id: '62564001',
      Title: 'FULL COMMENTARY - WILLS (TAS)',
      MatterTypeId: '1075',
      State: 'TAS',
      OsUnique: '62564001',
    },
    {
      Id: '62588001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ENDURING GUARDIANSHIPS (TAS)',
      MatterTypeId: '1075',
      State: 'TAS',
      OsUnique: '62588001',
    },
    {
      Id: '2e37cc04-eb06-48a8-a7f5-fd191c23fca1',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1082',
      State: 'SA',
      OsUnique: '2e37cc04-eb06-48a8-a7f5-fd191c23fca1',
    },
    {
      Id: '60933001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE HEALTH DIRECTIVES (QLD)',
      MatterTypeId: '1096',
      State: 'QLD',
      OsUnique: '60933001',
    },
    {
      Id: '60891001',
      Title: 'FULL COMMENTARY - WILLS (QLD)',
      MatterTypeId: '1096',
      State: 'QLD',
      OsUnique: '60891001',
    },
    {
      Id: '60871001',
      Title: 'FULL COMMENTARY - PROBATE (QLD)',
      MatterTypeId: '1096',
      State: 'QLD',
      OsUnique: '60871001',
    },
    {
      Id: '60912001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (QLD)',
      MatterTypeId: '1096',
      State: 'QLD',
      OsUnique: '60912001',
    },
    {
      Id: '60933001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE HEALTH DIRECTIVES (QLD)',
      MatterTypeId: '1097',
      State: 'QLD',
      OsUnique: '60933001',
    },
    {
      Id: '60891001',
      Title: 'FULL COMMENTARY - WILLS (QLD)',
      MatterTypeId: '1097',
      State: 'QLD',
      OsUnique: '60891001',
    },
    {
      Id: '60871001',
      Title: 'FULL COMMENTARY - PROBATE (QLD)',
      MatterTypeId: '1097',
      State: 'QLD',
      OsUnique: '60871001',
    },
    {
      Id: '60912001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (QLD)',
      MatterTypeId: '1097',
      State: 'QLD',
      OsUnique: '60912001',
    },
    {
      Id: '68251001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (SA)',
      MatterTypeId: '1101',
      State: 'SA',
      OsUnique: '68251001',
    },
    {
      Id: '61262001',
      Title: 'FULL COMMENTARY - PROBATE (SA)',
      MatterTypeId: '1101',
      State: 'SA',
      OsUnique: '61262001',
    },
    {
      Id: '61062001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE CARE DIRECTIVES (SA)',
      MatterTypeId: '1101',
      State: 'SA',
      OsUnique: '61062001',
    },
    {
      Id: '68918001',
      Title: 'FULL COMMENTARY - WILLS (SA)',
      MatterTypeId: '1101',
      State: 'SA',
      OsUnique: '68918001',
    },
    {
      Id: '395285a9-5966-4af4-9913-2b071df2ae7c',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1109',
      State: 'ACT',
      OsUnique: '395285a9-5966-4af4-9913-2b071df2ae7c',
    },
    {
      Id: '18284afb-4f3a-4087-8595-3a38f3068ebe',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1112',
      State: 'SA',
      OsUnique: '18284afb-4f3a-4087-8595-3a38f3068ebe',
    },
    {
      Id: '4087442c-da0e-41a4-8711-33ae97c2d2b8',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1114',
      State: 'All',
      OsUnique: '4087442c-da0e-41a4-8711-33ae97c2d2b8',
    },
    {
      Id: 'e9b85e78-90e2-4842-bfde-1f234c58701d',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1122',
      State: 'WA',
      OsUnique: 'e9b85e78-90e2-4842-bfde-1f234c58701d',
    },
    {
      Id: 'b93ec2a8-e0cc-4beb-a213-6b4f0368ca2f',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1123',
      State: 'WA',
      OsUnique: 'b93ec2a8-e0cc-4beb-a213-6b4f0368ca2f',
    },
    {
      Id: 'b51c4c6e-88bb-4fbb-912a-2e43fda5051d',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1125',
      State: 'WA',
      OsUnique: 'b51c4c6e-88bb-4fbb-912a-2e43fda5051d',
    },
    {
      Id: '0d0b0844-597a-40ef-b906-70c36ddc8142',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1127',
      State: 'WA',
      OsUnique: '0d0b0844-597a-40ef-b906-70c36ddc8142',
    },
    {
      Id: '54a5a348-209b-4cec-8d4a-b9bf2cf5a283',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1128',
      State: 'WA',
      OsUnique: '54a5a348-209b-4cec-8d4a-b9bf2cf5a283',
    },
    {
      Id: '7ed038e8-9f4e-4511-b4db-6a277fb54e64',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1134',
      State: 'NT',
      OsUnique: '7ed038e8-9f4e-4511-b4db-6a277fb54e64',
    },
    {
      Id: '61211001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (SA)',
      MatterTypeId: '1139',
      State: 'SA',
      OsUnique: '61211001',
    },
    {
      Id: '63241001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - MAGISTRATES COURT (SA)',
      MatterTypeId: '1140',
      State: 'SA',
      OsUnique: '63241001',
    },
    {
      Id: '68962001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - MAGISTRATES COURT (SA)',
      MatterTypeId: '1140',
      State: 'SA',
      OsUnique: '68962001',
    },
    {
      Id: '62e54099-57c9-4b11-b31a-1a9c608f3928',
      Title: 'Demonstrating the use of precedents',
      MatterTypeId: '1140',
      State: 'SA',
      OsUnique: '62e54099-57c9-4b11-b31a-1a9c608f3928',
    },
    {
      Id: '80917001',
      Title: '101 Subpoena Answers',
      MatterTypeId: '1140',
      State: 'SA',
      OsUnique: '80917001',
    },
    {
      Id: '61334001',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS AND FRANCHISE (SA)',
      MatterTypeId: '1164',
      State: 'SA',
      OsUnique: '61334001',
    },
    {
      Id: '61355001',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS AND FRANCHISE (SA)',
      MatterTypeId: '1164',
      State: 'SA',
      OsUnique: '61355001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '1164',
      State: 'SA',
      OsUnique: '59835001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '1164',
      State: 'SA',
      OsUnique: '60350001',
    },
    {
      Id: '61062001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE CARE DIRECTIVES (SA)',
      MatterTypeId: '1165',
      State: 'SA',
      OsUnique: '61062001',
    },
    {
      Id: '68918001',
      Title: 'FULL COMMENTARY - WILLS (SA)',
      MatterTypeId: '1165',
      State: 'SA',
      OsUnique: '68918001',
    },
    {
      Id: '61262001',
      Title: 'FULL COMMENTARY - PROBATE (SA)',
      MatterTypeId: '1165',
      State: 'SA',
      OsUnique: '61262001',
    },
    {
      Id: '68251001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (SA)',
      MatterTypeId: '1165',
      State: 'SA',
      OsUnique: '68251001',
    },
    {
      Id: '61355001',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS AND FRANCHISE (SA)',
      MatterTypeId: '1168',
      State: 'SA',
      OsUnique: '61355001',
    },
    {
      Id: '61334001',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS AND FRANCHISE (SA)',
      MatterTypeId: '1168',
      State: 'SA',
      OsUnique: '61334001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '1168',
      State: 'SA',
      OsUnique: '59835001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '1168',
      State: 'SA',
      OsUnique: '60350001',
    },
    {
      Id: '65348001',
      Title: 'FULL COMMENTARY - EMPLOYMENT LAW',
      MatterTypeId: '1169',
      State: 'All',
      OsUnique: '65348001',
    },
    {
      Id: '9e41c078-cf8d-49b7-a41b-626445884489',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1170',
      State: 'SA',
      OsUnique: '9e41c078-cf8d-49b7-a41b-626445884489',
    },
    {
      Id: '317177b1-9b2f-4f19-8a38-19b9596d64bd',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1172',
      State: 'WA',
      OsUnique: '317177b1-9b2f-4f19-8a38-19b9596d64bd',
    },
    {
      Id: '35e31bd5-2bf9-4c0d-9cb3-a483664b6a15',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1173',
      State: 'SA',
      OsUnique: '35e31bd5-2bf9-4c0d-9cb3-a483664b6a15',
    },
    {
      Id: '60159001',
      Title: 'FULL COMMENTARY - COMPANIES',
      MatterTypeId: '1187',
      State: 'All',
      OsUnique: '60159001',
    },
    {
      Id: 'f7378987-2293-4f17-b8ea-bb6560d8e206',
      Title: 'FULL COMMENTARY - TRUSTS',
      MatterTypeId: '1187',
      State: 'All',
      OsUnique: 'f7378987-2293-4f17-b8ea-bb6560d8e206',
    },
    {
      Id: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
      Title: 'FULL COMMENTARY - PARTNERSHIPS',
      MatterTypeId: '1187',
      State: 'All',
      OsUnique: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
    },
    {
      Id: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
      Title: 'FULL COMMENTARY - JOINT VENTURES',
      MatterTypeId: '1187',
      State: 'All',
      OsUnique: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
    },
    {
      Id: '60196001',
      Title: 'FULL COMMENTARY - SELF MANAGED SUPERANNUATION FUNDS',
      MatterTypeId: '1187',
      State: 'All',
      OsUnique: '60196001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '1187',
      State: 'All',
      OsUnique: '60350001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '1187',
      State: 'All',
      OsUnique: '59835001',
    },
    {
      Id: 'a20ef76a-ff1e-414b-b430-bd18ed3d41a3',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1188',
      State: 'NT',
      OsUnique: 'a20ef76a-ff1e-414b-b430-bd18ed3d41a3',
    },
    {
      Id: '60530001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (VIC)',
      MatterTypeId: '1189',
      State: 'VIC',
      OsUnique: '60530001',
    },
    {
      Id: '61577001',
      Title: 'FULL COMMENTARY - CRIMINAL - LOCAL COURT (NSW)',
      MatterTypeId: '1191',
      State: 'NSW',
      OsUnique: '61577001',
    },
    {
      Id: '61666001',
      Title: 'FULL COMMENTARY - APPREHENDED VIOLENCE ORDER (NSW)',
      MatterTypeId: '1191',
      State: 'NSW',
      OsUnique: '61666001',
    },
    {
      Id: '67586001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - LOCAL COURT (NSW)',
      MatterTypeId: '1191',
      State: 'NSW',
      OsUnique: '67586001',
    },
    {
      Id: '61577001',
      Title: 'FULL COMMENTARY - CRIMINAL - LOCAL COURT (NSW)',
      MatterTypeId: '1193',
      State: 'NSW',
      OsUnique: '61577001',
    },
    {
      Id: '61666001',
      Title: 'FULL COMMENTARY - APPREHENDED VIOLENCE ORDER (NSW)',
      MatterTypeId: '1193',
      State: 'NSW',
      OsUnique: '61666001',
    },
    {
      Id: '67586001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - LOCAL COURT (NSW)',
      MatterTypeId: '1193',
      State: 'NSW',
      OsUnique: '67586001',
    },
    {
      Id: '61577001',
      Title: 'FULL COMMENTARY - CRIMINAL - LOCAL COURT (NSW)',
      MatterTypeId: '1194',
      State: 'NSW',
      OsUnique: '61577001',
    },
    {
      Id: '61666001',
      Title: 'FULL COMMENTARY - APPREHENDED VIOLENCE ORDER (NSW)',
      MatterTypeId: '1194',
      State: 'NSW',
      OsUnique: '61666001',
    },
    {
      Id: '67586001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - LOCAL COURT (NSW)',
      MatterTypeId: '1194',
      State: 'NSW',
      OsUnique: '67586001',
    },
    {
      Id: 'dc30b205-1f6d-43bf-88b8-0345671ba94d',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1196',
      State: 'NSW',
      OsUnique: 'dc30b205-1f6d-43bf-88b8-0345671ba94d',
    },
    {
      Id: '61577001',
      Title: 'FULL COMMENTARY - CRIMINAL - LOCAL COURT (NSW)',
      MatterTypeId: '1196',
      State: 'NSW',
      OsUnique: '61577001',
    },
    {
      Id: '61666001',
      Title: 'FULL COMMENTARY - APPREHENDED VIOLENCE ORDER (NSW)',
      MatterTypeId: '1196',
      State: 'NSW',
      OsUnique: '61666001',
    },
    {
      Id: '67586001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - LOCAL COURT (NSW)',
      MatterTypeId: '1196',
      State: 'NSW',
      OsUnique: '67586001',
    },
    {
      Id: '61577001',
      Title: 'FULL COMMENTARY - CRIMINAL - LOCAL COURT (NSW)',
      MatterTypeId: '1197',
      State: 'NSW',
      OsUnique: '61577001',
    },
    {
      Id: '61666001',
      Title: 'FULL COMMENTARY - APPREHENDED VIOLENCE ORDER (NSW)',
      MatterTypeId: '1197',
      State: 'NSW',
      OsUnique: '61666001',
    },
    {
      Id: '67586001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - LOCAL COURT (NSW)',
      MatterTypeId: '1197',
      State: 'NSW',
      OsUnique: '67586001',
    },
    {
      Id: '60246001',
      Title: 'FULL COMMENTARY - INSOLVENCY - COMPANY LIQUIDATION',
      MatterTypeId: '1199',
      State: 'All',
      OsUnique: '60246001',
    },
    {
      Id: '60224001',
      Title: 'FULL COMMENTARY - INSOLVENCY - BANKRUPTCY OF INDIVIDUALS',
      MatterTypeId: '1199',
      State: 'All',
      OsUnique: '60224001',
    },
    {
      Id: '64568001',
      Title: 'FULL COMMENTARY - NEIGHBOURHOOD DISPUTES (QLD)',
      MatterTypeId: '11e80b16-f196-4c62-8189-8cfaa10db414',
      State: 'QLD',
      OsUnique: '64568001',
    },
    {
      Id: '63226001',
      Title: 'FULL COMMENTARY - QUEENSLAND CIVIL AND ADMINISTRATIVE TRIBUNAL (QLD)',
      MatterTypeId: '11e80b16-f196-4c62-8189-8cfaa10db414',
      State: 'QLD',
      OsUnique: '63226001',
    },
    {
      Id: '68010001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - MAGISTRATES COURT (QLD)',
      MatterTypeId: '11e80b16-f196-4c62-8189-8cfaa10db414',
      State: 'QLD',
      OsUnique: '68010001',
    },
    {
      Id: '68058001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - MAGISTRATES COURT (QLD)',
      MatterTypeId: '11e80b16-f196-4c62-8189-8cfaa10db414',
      State: 'QLD',
      OsUnique: '68058001',
    },
    {
      Id: '60224001',
      Title: 'FULL COMMENTARY - INSOLVENCY - BANKRUPTCY OF INDIVIDUALS',
      MatterTypeId: '1202',
      State: 'All',
      OsUnique: '60224001',
    },
    {
      Id: '60246001',
      Title: 'FULL COMMENTARY - INSOLVENCY - COMPANY LIQUIDATION',
      MatterTypeId: '1202',
      State: 'All',
      OsUnique: '60246001',
    },
    {
      Id: '60780001',
      Title: 'FULL COMMENTARY - MORTGAGES (VIC)',
      MatterTypeId: '1204',
      State: 'VIC',
      OsUnique: '60780001',
    },
    {
      Id: '60564001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (VIC)',
      MatterTypeId: '1204',
      State: 'VIC',
      OsUnique: '60564001',
    },
    {
      Id: '60530001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (VIC)',
      MatterTypeId: '1204',
      State: 'VIC',
      OsUnique: '60530001',
    },
    {
      Id: '68692001',
      Title: '1001 Conveyancing Answers (VIC)',
      MatterTypeId: '1204',
      State: 'VIC',
      OsUnique: '68692001',
    },
    {
      Id: '19997aa5-d7f4-4e9d-806d-49fb099f4b3e',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1206',
      State: 'QLD',
      OsUnique: '19997aa5-d7f4-4e9d-806d-49fb099f4b3e',
    },
    {
      Id: '60246001',
      Title: 'FULL COMMENTARY - INSOLVENCY - COMPANY LIQUIDATION',
      MatterTypeId: '1207',
      State: 'All',
      OsUnique: '60246001',
    },
    {
      Id: '60224001',
      Title: 'FULL COMMENTARY - INSOLVENCY - BANKRUPTCY OF INDIVIDUALS',
      MatterTypeId: '1207',
      State: 'All',
      OsUnique: '60224001',
    },
    {
      Id: '59859001',
      Title: 'FULL COMMENTARY - WILLS (NSW)',
      MatterTypeId: '121',
      State: 'NSW',
      OsUnique: '59859001',
    },
    {
      Id: '60370001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY, APPOINTMENT OF ENDURING GUARDIAN AND ADVANCE CARE PLANNING (NSW)',
      MatterTypeId: '121',
      State: 'NSW',
      OsUnique: '60370001',
    },
    {
      Id: '59789001',
      Title: 'FULL COMMENTARY - PROBATE (NSW)',
      MatterTypeId: '121',
      State: 'NSW',
      OsUnique: '59789001',
    },
    {
      Id: '68216001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (NSW)',
      MatterTypeId: '121',
      State: 'NSW',
      OsUnique: '68216001',
    },
    {
      Id: '1f97316f-ab46-4290-8add-2b79499488ca',
      Title: '101 Succession Answers (NSW)',
      MatterTypeId: '121',
      State: 'NSW',
      OsUnique: '1f97316f-ab46-4290-8add-2b79499488ca',
    },
    {
      Id: '68694001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - DISTRICT COURT (NSW)',
      MatterTypeId: '123',
      State: 'NSW',
      OsUnique: '68694001',
    },
    {
      Id: '62017001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - DISTRICT COURT (NSW)',
      MatterTypeId: '123',
      State: 'NSW',
      OsUnique: '62017001',
    },
    {
      Id: '71729001',
      Title: 'FULL COMMENTARY - ENFORCEMENT (NSW)',
      MatterTypeId: '123',
      State: 'NSW',
      OsUnique: '71729001',
    },
    {
      Id: '80917001',
      Title: '101 Subpoena Answers',
      MatterTypeId: '123',
      State: 'NSW',
      OsUnique: '80917001',
    },
    {
      Id: '62e54099-57c9-4b11-b31a-1a9c608f3928',
      Title: 'Demonstrating the use of precedents',
      MatterTypeId: '123',
      State: 'NSW',
      OsUnique: '62e54099-57c9-4b11-b31a-1a9c608f3928',
    },
    {
      Id: '6cefcea5-c366-4566-9fa9-8be41f7df3fe',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1231',
      State: 'QLD',
      OsUnique: '6cefcea5-c366-4566-9fa9-8be41f7df3fe',
    },
    {
      Id: 'b2d9eff4-d2ef-47d3-813c-62b64f2ef364',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1236',
      State: 'WA',
      OsUnique: 'b2d9eff4-d2ef-47d3-813c-62b64f2ef364',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '1238',
      State: 'All',
      OsUnique: '59835001',
    },
    {
      Id: '61383001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - LOCAL COURT (NSW)',
      MatterTypeId: '124',
      State: 'NSW',
      OsUnique: '61383001',
    },
    {
      Id: '61453001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - LOCAL COURT (NSW)',
      MatterTypeId: '124',
      State: 'NSW',
      OsUnique: '61453001',
    },
    {
      Id: '71729001',
      Title: 'FULL COMMENTARY - ENFORCEMENT (NSW)',
      MatterTypeId: '124',
      State: 'NSW',
      OsUnique: '71729001',
    },
    {
      Id: '61666001',
      Title: 'FULL COMMENTARY - APPREHENDED VIOLENCE ORDER (NSW)',
      MatterTypeId: '124',
      State: 'NSW',
      OsUnique: '61666001',
    },
    {
      Id: '80917001',
      Title: '101 Subpoena Answers',
      MatterTypeId: '124',
      State: 'NSW',
      OsUnique: '80917001',
    },
    {
      Id: '62e54099-57c9-4b11-b31a-1a9c608f3928',
      Title: 'Demonstrating the use of precedents',
      MatterTypeId: '124',
      State: 'NSW',
      OsUnique: '62e54099-57c9-4b11-b31a-1a9c608f3928',
    },
    {
      Id: '02dd02ab-a984-49f5-8126-561555f6d2ed',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1241',
      State: 'WA',
      OsUnique: '02dd02ab-a984-49f5-8126-561555f6d2ed',
    },
    {
      Id: '78480001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (WA)',
      MatterTypeId: '1242',
      State: 'WA',
      OsUnique: '78480001',
    },
    {
      Id: '77402001',
      Title: 'FULL COMMENTARY - PROBATE (WA)',
      MatterTypeId: '1242',
      State: 'WA',
      OsUnique: '77402001',
    },
    {
      Id: '63329001',
      Title: 'FULL COMMENTARY - MORTGAGES (WA)',
      MatterTypeId: '1245',
      State: 'WA',
      OsUnique: '63329001',
    },
    {
      Id: '64498001',
      Title: 'FULL COMMENTARY - LEASES (WA)',
      MatterTypeId: '1246',
      State: 'WA',
      OsUnique: '64498001',
    },
    {
      Id: '24920167-6839-4087-9c53-9eff1db30f91',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1248',
      State: 'WA',
      OsUnique: '24920167-6839-4087-9c53-9eff1db30f91',
    },
    {
      Id: '69010001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - COMMON LAW DIVISION SUPREME COURT (NSW)',
      MatterTypeId: '125',
      State: 'NSW',
      OsUnique: '69010001',
    },
    {
      Id: '62822001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - COMMON LAW DIVISION SUPREME COURT (NSW)',
      MatterTypeId: '125',
      State: 'NSW',
      OsUnique: '62822001',
    },
    {
      Id: '71729001',
      Title: 'FULL COMMENTARY - ENFORCEMENT (NSW)',
      MatterTypeId: '125',
      State: 'NSW',
      OsUnique: '71729001',
    },
    {
      Id: '80917001',
      Title: '101 Subpoena Answers',
      MatterTypeId: '125',
      State: 'NSW',
      OsUnique: '80917001',
    },
    {
      Id: '62e54099-57c9-4b11-b31a-1a9c608f3928',
      Title: 'Demonstrating the use of precedents',
      MatterTypeId: '125',
      State: 'NSW',
      OsUnique: '62e54099-57c9-4b11-b31a-1a9c608f3928',
    },
    {
      Id: '59634001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (NSW)',
      MatterTypeId: '126',
      State: 'NSW',
      OsUnique: '59634001',
    },
    {
      Id: '68689001',
      Title: '1001 Conveyancing Answers (NSW)',
      MatterTypeId: '126',
      State: 'NSW',
      OsUnique: '68689001',
    },
    {
      Id: '576dc68f-f290-4a3e-9334-e8de27ba0b9f',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1290',
      State: 'NT',
      OsUnique: '576dc68f-f290-4a3e-9334-e8de27ba0b9f',
    },
    {
      Id: '4ab93a66-e444-424c-a990-023657897e83',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1291',
      State: 'SA',
      OsUnique: '4ab93a66-e444-424c-a990-023657897e83',
    },
    {
      Id: 'd4040ed6-643d-450c-8ff7-87fe8739776a',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '1292',
      State: 'WA',
      OsUnique: 'd4040ed6-643d-450c-8ff7-87fe8739776a',
    },
    {
      Id: '61899001',
      Title: 'FULL COMMENTARY - VICTIMS RIGHTS AND SUPPORT (NSW)',
      MatterTypeId: '148',
      State: 'NSW',
      OsUnique: '61899001',
    },
    {
      Id: '47510fa3-ad4f-4742-8550-cf0654260307',
      Title: 'FULL COMMENTARY - MOTOR VEHICLE ACCIDENT (NSW) - ACCIDENTS FROM 1 DECEMBER 2017',
      MatterTypeId: '148',
      State: 'NSW',
      OsUnique: '47510fa3-ad4f-4742-8550-cf0654260307',
    },
    {
      Id: '61822001',
      Title: 'FULL COMMENTARY - MOTOR VEHICLE ACCIDENT (NSW) - ACCIDENTS PRIOR TO 1 DECEMBER 2017',
      MatterTypeId: '148',
      State: 'NSW',
      OsUnique: '61822001',
    },
    {
      Id: '62104001',
      Title: 'FULL COMMENTARY - PERSONAL INJURY (NSW)',
      MatterTypeId: '148',
      State: 'NSW',
      OsUnique: '62104001',
    },
    {
      Id: '61851001',
      Title: 'FULL COMMENTARY - WORKERS COMPENSATION (NSW)',
      MatterTypeId: '148',
      State: 'NSW',
      OsUnique: '61851001',
    },
    {
      Id: '61577001',
      Title: 'FULL COMMENTARY - CRIMINAL - LOCAL COURT (NSW)',
      MatterTypeId: '151',
      State: 'NSW',
      OsUnique: '61577001',
    },
    {
      Id: '61666001',
      Title: 'FULL COMMENTARY - APPREHENDED VIOLENCE ORDER (NSW)',
      MatterTypeId: '151',
      State: 'NSW',
      OsUnique: '61666001',
    },
    {
      Id: '67586001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - LOCAL COURT (NSW)',
      MatterTypeId: '151',
      State: 'NSW',
      OsUnique: '67586001',
    },
    {
      Id: '50bbf04a-3bd4-448c-bbb9-446af88f8d6f',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '152',
      State: 'NSW',
      OsUnique: '50bbf04a-3bd4-448c-bbb9-446af88f8d6f',
    },
    {
      Id: 'a1b32683-77ba-4b8b-aa31-24a895d47359',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (NSW)',
      MatterTypeId: '156',
      State: 'NSW',
      OsUnique: 'a1b32683-77ba-4b8b-aa31-24a895d47359',
    },
    {
      Id: '81562001',
      Title: 'FULL COMMENTARY - CHILDREN',
      MatterTypeId: '160',
      State: 'All',
      OsUnique: '81562001',
    },
    {
      Id: '60266001',
      Title: 'FULL COMMENTARY - DIVORCE',
      MatterTypeId: '160',
      State: 'All',
      OsUnique: '60266001',
    },
    {
      Id: '59766001',
      Title: 'FULL COMMENTARY - FINANCIAL AGREEMENTS',
      MatterTypeId: '160',
      State: 'All',
      OsUnique: '59766001',
    },
    {
      Id: '59694001',
      Title: 'FULL COMMENTARY - PROPERTY SETTLEMENT',
      MatterTypeId: '160',
      State: 'All',
      OsUnique: '59694001',
    },
    {
      Id: '65061001',
      Title: '101 Family Law Answers',
      MatterTypeId: '160',
      State: 'All',
      OsUnique: '65061001',
    },
    {
      Id: '80917001',
      Title: '101 Subpoena Answers',
      MatterTypeId: '160',
      State: 'All',
      OsUnique: '80917001',
    },
    {
      Id: '60370001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY, APPOINTMENT OF ENDURING GUARDIAN AND ADVANCE CARE PLANNING (NSW)',
      MatterTypeId: '171',
      State: 'NSW',
      OsUnique: '60370001',
    },
    {
      Id: '59859001',
      Title: 'FULL COMMENTARY - WILLS (NSW)',
      MatterTypeId: '171',
      State: 'NSW',
      OsUnique: '59859001',
    },
    {
      Id: '59789001',
      Title: 'FULL COMMENTARY - PROBATE (NSW)',
      MatterTypeId: '171',
      State: 'NSW',
      OsUnique: '59789001',
    },
    {
      Id: '68216001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (NSW)',
      MatterTypeId: '171',
      State: 'NSW',
      OsUnique: '68216001',
    },
    {
      Id: '1f97316f-ab46-4290-8add-2b79499488ca',
      Title: '101 Succession Answers (NSW)',
      MatterTypeId: '171',
      State: 'NSW',
      OsUnique: '1f97316f-ab46-4290-8add-2b79499488ca',
    },
    {
      Id: '8ab1be1b-b475-43fa-908b-58c6df4c94c9',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '237',
      State: 'All',
      OsUnique: '8ab1be1b-b475-43fa-908b-58c6df4c94c9',
    },
    {
      Id: '62471001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - MAGISTRATES\' COURT CLAIMS UNDER $100,000 (VIC)',
      MatterTypeId: '241',
      State: 'VIC',
      OsUnique: '62471001',
    },
    {
      Id: '62439001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - MAGISTRATES\' COURT CLAIMS UNDER $100,000 (VIC)',
      MatterTypeId: '241',
      State: 'VIC',
      OsUnique: '62439001',
    },
    {
      Id: '62502001',
      Title: 'FULL COMMENTARY - ENFORCEMENT (VIC)',
      MatterTypeId: '241',
      State: 'VIC',
      OsUnique: '62502001',
    },
    {
      Id: '63462001',
      Title: 'FULL COMMENTARY - INTERVENTION ORDERS (VIC)',
      MatterTypeId: '241',
      State: 'VIC',
      OsUnique: '63462001',
    },
    {
      Id: '60530001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (VIC)',
      MatterTypeId: '242',
      State: 'VIC',
      OsUnique: '60530001',
    },
    {
      Id: '60564001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (VIC)',
      MatterTypeId: '242',
      State: 'VIC',
      OsUnique: '60564001',
    },
    {
      Id: '60780001',
      Title: 'FULL COMMENTARY - MORTGAGES (VIC)',
      MatterTypeId: '242',
      State: 'VIC',
      OsUnique: '60780001',
    },
    {
      Id: '68692001',
      Title: '1001 Conveyancing Answers (VIC)',
      MatterTypeId: '242',
      State: 'VIC',
      OsUnique: '68692001',
    },
    {
      Id: '2b9409aa-af8b-470d-a4a7-4c8dcc3ad3a0',
      Title: '101 Section 32 Answers (VIC)',
      MatterTypeId: '242',
      State: 'VIC',
      OsUnique: '2b9409aa-af8b-470d-a4a7-4c8dcc3ad3a0',
    },
    {
      Id: '60564001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (VIC)',
      MatterTypeId: '243',
      State: 'VIC',
      OsUnique: '60564001',
    },
    {
      Id: '60530001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (VIC)',
      MatterTypeId: '243',
      State: 'VIC',
      OsUnique: '60530001',
    },
    {
      Id: '60780001',
      Title: 'FULL COMMENTARY - MORTGAGES (VIC)',
      MatterTypeId: '243',
      State: 'VIC',
      OsUnique: '60780001',
    },
    {
      Id: '68692001',
      Title: '1001 Conveyancing Answers (VIC)',
      MatterTypeId: '243',
      State: 'VIC',
      OsUnique: '68692001',
    },
    {
      Id: '2b9409aa-af8b-470d-a4a7-4c8dcc3ad3a0',
      Title: '101 Section 32 Answers (VIC)',
      MatterTypeId: '243',
      State: 'VIC',
      OsUnique: '2b9409aa-af8b-470d-a4a7-4c8dcc3ad3a0',
    },
    {
      Id: 'ef18eb1e-a9c0-4b24-8f5b-6a4c03b610ae',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (ACT)',
      MatterTypeId: '262',
      State: 'ACT',
      OsUnique: 'ef18eb1e-a9c0-4b24-8f5b-6a4c03b610ae',
    },
    {
      Id: '686195bd-8a58-45fe-8d23-2ac94d202c18',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (ACT)',
      MatterTypeId: '262',
      State: 'ACT',
      OsUnique: '686195bd-8a58-45fe-8d23-2ac94d202c18',
    },
    {
      Id: '686195bd-8a58-45fe-8d23-2ac94d202c18',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (ACT)',
      MatterTypeId: '263',
      State: 'ACT',
      OsUnique: '686195bd-8a58-45fe-8d23-2ac94d202c18',
    },
    {
      Id: 'ef18eb1e-a9c0-4b24-8f5b-6a4c03b610ae',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (ACT)',
      MatterTypeId: '263',
      State: 'ACT',
      OsUnique: 'ef18eb1e-a9c0-4b24-8f5b-6a4c03b610ae',
    },
    {
      Id: '6fd89809-4b44-4229-aef6-96b3948f8725',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '264',
      State: 'ACT',
      OsUnique: '6fd89809-4b44-4229-aef6-96b3948f8725',
    },
    {
      Id: '89c5a664-7153-40b7-b232-78bc7fccfd22',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '270',
      State: 'ACT',
      OsUnique: '89c5a664-7153-40b7-b232-78bc7fccfd22',
    },
    {
      Id: '61577001',
      Title: 'FULL COMMENTARY - CRIMINAL - LOCAL COURT (NSW)',
      MatterTypeId: '317',
      State: 'NSW',
      OsUnique: '61577001',
    },
    {
      Id: '61666001',
      Title: 'FULL COMMENTARY - APPREHENDED VIOLENCE ORDER (NSW)',
      MatterTypeId: '317',
      State: 'NSW',
      OsUnique: '61666001',
    },
    {
      Id: '67586001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - LOCAL COURT (NSW)',
      MatterTypeId: '317',
      State: 'NSW',
      OsUnique: '67586001',
    },
    {
      Id: '60266001',
      Title: 'FULL COMMENTARY - DIVORCE',
      MatterTypeId: '319',
      State: 'All',
      OsUnique: '60266001',
    },
    {
      Id: '59766001',
      Title: 'FULL COMMENTARY - FINANCIAL AGREEMENTS',
      MatterTypeId: '319',
      State: 'All',
      OsUnique: '59766001',
    },
    {
      Id: '59694001',
      Title: 'FULL COMMENTARY - PROPERTY SETTLEMENT',
      MatterTypeId: '319',
      State: 'All',
      OsUnique: '59694001',
    },
    {
      Id: '65061001',
      Title: '101 Family Law Answers',
      MatterTypeId: '319',
      State: 'All',
      OsUnique: '65061001',
    },
    {
      Id: '56be0c8e-8fbe-458e-9525-78785dcbff6e',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '320',
      State: 'All',
      OsUnique: '56be0c8e-8fbe-458e-9525-78785dcbff6e',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '321',
      State: 'All',
      OsUnique: '60350001',
    },
    {
      Id: '2e8a3e5e-ad7d-4ad8-b1e9-0f98dfd56573',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '322',
      State: 'All',
      OsUnique: '2e8a3e5e-ad7d-4ad8-b1e9-0f98dfd56573',
    },
    {
      Id: 'f4adc49a-c0e7-47ff-bc8a-b61a6cad719c',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '323',
      State: 'All',
      OsUnique: 'f4adc49a-c0e7-47ff-bc8a-b61a6cad719c',
    },
    {
      Id: '2a0ccceb-759d-45e9-bc9a-fd05205f6e33',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '325',
      State: 'All',
      OsUnique: '2a0ccceb-759d-45e9-bc9a-fd05205f6e33',
    },
    {
      Id: '3df811fd-ac88-478b-af7f-cabbc214f2db',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '364',
      State: 'All',
      OsUnique: '3df811fd-ac88-478b-af7f-cabbc214f2db',
    },
    {
      Id: '644c84eb-8d8b-4ae2-baad-fa2105ef3025',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '365',
      State: 'All',
      OsUnique: '644c84eb-8d8b-4ae2-baad-fa2105ef3025',
    },
    {
      Id: '390dab5f-cfb9-4e40-998a-cea5dabc9915',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '367',
      State: 'NSW',
      OsUnique: '390dab5f-cfb9-4e40-998a-cea5dabc9915',
    },
    {
      Id: '68a977c5-ba97-4b74-a8df-343cdae01b5d',
      Title: 'FULL COMMENTARY - IMMIGRATION',
      MatterTypeId: '36bd7b0f-b347-4544-aac2-d28184ff2fd0',
      State: 'All',
      OsUnique: '68a977c5-ba97-4b74-a8df-343cdae01b5d',
    },
    {
      Id: 'e7ca09b5-86c1-4009-9acf-18f1a19389c0',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '371',
      State: 'NSW',
      OsUnique: 'e7ca09b5-86c1-4009-9acf-18f1a19389c0',
    },
    {
      Id: '9c0faa43-d5f0-462a-b1ae-137bff41e4c1',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '374',
      State: 'NSW',
      OsUnique: '9c0faa43-d5f0-462a-b1ae-137bff41e4c1',
    },
    {
      Id: '68266001',
      Title: 'FULL COMMENTARY - PROBATE (VIC)',
      MatterTypeId: '375',
      State: 'VIC',
      OsUnique: '68266001',
    },
    {
      Id: '60755001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (VIC)',
      MatterTypeId: '375',
      State: 'VIC',
      OsUnique: '60755001',
    },
    {
      Id: '60673001',
      Title: 'FULL COMMENTARY - POWERS & ADVANCE CARE DIRECTIVES (VIC)',
      MatterTypeId: '375',
      State: 'VIC',
      OsUnique: '60673001',
    },
    {
      Id: '67685001',
      Title: 'FULL COMMENTARY - WILLS (VIC)',
      MatterTypeId: '375',
      State: 'VIC',
      OsUnique: '67685001',
    },
    {
      Id: '60755001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (VIC)',
      MatterTypeId: '376',
      State: 'VIC',
      OsUnique: '60755001',
    },
    {
      Id: '68266001',
      Title: 'FULL COMMENTARY - PROBATE (VIC)',
      MatterTypeId: '376',
      State: 'VIC',
      OsUnique: '68266001',
    },
    {
      Id: '60673001',
      Title: 'FULL COMMENTARY - POWERS & ADVANCE CARE DIRECTIVES (VIC)',
      MatterTypeId: '376',
      State: 'VIC',
      OsUnique: '60673001',
    },
    {
      Id: '67685001',
      Title: 'FULL COMMENTARY - WILLS (VIC)',
      MatterTypeId: '376',
      State: 'VIC',
      OsUnique: '67685001',
    },
    {
      Id: '63005001',
      Title: 'FULL COMMENTARY - ACTING FOR THE ESTATE - FAMILY PROVISION CLAIMS (VIC)',
      MatterTypeId: '377',
      State: 'VIC',
      OsUnique: '63005001',
    },
    {
      Id: '62731001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - FAMILY PROVISION CLAIMS (VIC)',
      MatterTypeId: '377',
      State: 'VIC',
      OsUnique: '62731001',
    },
    {
      Id: '955a1689-c241-4ead-a775-af96adc60246',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '394',
      State: 'All',
      OsUnique: '955a1689-c241-4ead-a775-af96adc60246',
    },
    {
      Id: '1e2d0baf-e94a-48cb-8f5a-a999c125ee6a',
      Title: 'FULL COMMENTARY - MORTGAGES (NSW)',
      MatterTypeId: '395',
      State: 'NSW',
      OsUnique: '1e2d0baf-e94a-48cb-8f5a-a999c125ee6a',
    },
    {
      Id: 'ed4a55cf-0af8-4502-84cd-a2acf66deb31',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '396',
      State: 'All',
      OsUnique: 'ed4a55cf-0af8-4502-84cd-a2acf66deb31',
    },
    {
      Id: '2e1b206e-1cc9-4019-970b-6246389e0343',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '398',
      State: 'VIC',
      OsUnique: '2e1b206e-1cc9-4019-970b-6246389e0343',
    },
    {
      Id: '4dc5fbe0-0992-496f-a073-f60b96b72e33',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '399',
      State: 'VIC',
      OsUnique: '4dc5fbe0-0992-496f-a073-f60b96b72e33',
    },
    {
      Id: '0136d868-5f40-4fc3-b66a-f5ff436c1920',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3b5c2fd0-26b5-4465-87e3-a331a59c1233',
      State: 'QLD',
      OsUnique: '0136d868-5f40-4fc3-b66a-f5ff436c1920',
    },
    {
      Id: '60980001',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS AND FRANCHISE (QLD)',
      MatterTypeId: '3b5c2fd0-26b5-4465-87e3-a331a59c1233',
      State: 'QLD',
      OsUnique: '60980001',
    },
    {
      Id: '61004001',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS AND FRANCHISE (QLD)',
      MatterTypeId: '3b5c2fd0-26b5-4465-87e3-a331a59c1233',
      State: 'QLD',
      OsUnique: '61004001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '3b5c2fd0-26b5-4465-87e3-a331a59c1233',
      State: 'QLD',
      OsUnique: '59835001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '3b5c2fd0-26b5-4465-87e3-a331a59c1233',
      State: 'QLD',
      OsUnique: '60350001',
    },
    {
      Id: '68691001',
      Title: '1001 Conveyancing Answers (QLD)',
      MatterTypeId: '3b5c2fd0-26b5-4465-87e3-a331a59c1233',
      State: 'QLD',
      OsUnique: '68691001',
    },
    {
      Id: '60838001',
      Title: 'FULL COMMENTARY - MORTGAGES (QLD)',
      MatterTypeId: '3b5c2fd0-26b5-4465-87e3-a331a59c1233',
      State: 'QLD',
      OsUnique: '60838001',
    },
    {
      Id: '64464001',
      Title: 'FULL COMMENTARY - LEASES (QLD)',
      MatterTypeId: '3b5c2fd0-26b5-4465-87e3-a331a59c1233',
      State: 'QLD',
      OsUnique: '64464001',
    },
    {
      Id: '64191001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (QLD)',
      MatterTypeId: '3b5c2fd0-26b5-4465-87e3-a331a59c1233',
      State: 'QLD',
      OsUnique: '64191001',
    },
    {
      Id: 'd591886d-e2fb-4d83-9fb9-2d6d3a77accb',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '400',
      State: 'VIC',
      OsUnique: 'd591886d-e2fb-4d83-9fb9-2d6d3a77accb',
    },
    {
      Id: '896bbde8-cb0b-48a2-bf9f-02a4666eda22',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '401',
      State: 'VIC',
      OsUnique: '896bbde8-cb0b-48a2-bf9f-02a4666eda22',
    },
    {
      Id: 'ba5ee27d-a7a7-431a-a457-bc6ab6db7eae',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '402',
      State: 'VIC',
      OsUnique: 'ba5ee27d-a7a7-431a-a457-bc6ab6db7eae',
    },
    {
      Id: '060b2711-8a08-4526-90ad-44141a5e560c',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '403',
      State: 'VIC',
      OsUnique: '060b2711-8a08-4526-90ad-44141a5e560c',
    },
    {
      Id: '6ad3fcb1-67dc-47ba-8daa-59ca11f88f0f',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '404',
      State: 'VIC',
      OsUnique: '6ad3fcb1-67dc-47ba-8daa-59ca11f88f0f',
    },
    {
      Id: '7350de31-955f-4110-9bd3-c4e1278be88a',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '405',
      State: 'VIC',
      OsUnique: '7350de31-955f-4110-9bd3-c4e1278be88a',
    },
    {
      Id: '54d583be-91c8-4f79-b131-db2aeef6a6fb',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '406',
      State: 'VIC',
      OsUnique: '54d583be-91c8-4f79-b131-db2aeef6a6fb',
    },
    {
      Id: 'bc834299-0e72-4156-8612-5bb2c85995f6',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '407',
      State: 'VIC',
      OsUnique: 'bc834299-0e72-4156-8612-5bb2c85995f6',
    },
    {
      Id: '1255fc76-eb0e-4fd2-971a-f8c4f035e3d0',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '408',
      State: 'VIC',
      OsUnique: '1255fc76-eb0e-4fd2-971a-f8c4f035e3d0',
    },
    {
      Id: '64577001',
      Title: 'FULL COMMENTARY - NEIGHBOURHOOD DISPUTES (SA)',
      MatterTypeId: '408e4617-f262-476a-83d0-6842614daba2',
      State: 'SA',
      OsUnique: '64577001',
    },
    {
      Id: '80843001',
      Title: 'FULL COMMENTARY - INTERVENTION ORDERS (SA)',
      MatterTypeId: '408e4617-f262-476a-83d0-6842614daba2',
      State: 'SA',
      OsUnique: '80843001',
    },
    {
      Id: '68962001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - MAGISTRATES COURT (SA)',
      MatterTypeId: '408e4617-f262-476a-83d0-6842614daba2',
      State: 'SA',
      OsUnique: '68962001',
    },
    {
      Id: '63241001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - MAGISTRATES COURT (SA)',
      MatterTypeId: '408e4617-f262-476a-83d0-6842614daba2',
      State: 'SA',
      OsUnique: '63241001',
    },
    {
      Id: '0dfb00b8-c816-4c35-bfea-01a33d4d7d15',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '409',
      State: 'VIC',
      OsUnique: '0dfb00b8-c816-4c35-bfea-01a33d4d7d15',
    },
    {
      Id: '74be75cc-5f23-4fe8-9b9a-3032fc8a6083',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '410',
      State: 'VIC',
      OsUnique: '74be75cc-5f23-4fe8-9b9a-3032fc8a6083',
    },
    {
      Id: '67264001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - SUPREME COURT (VIC)',
      MatterTypeId: '411',
      State: 'VIC',
      OsUnique: '67264001',
    },
    {
      Id: '65781001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - SUPREME COURT (VIC)',
      MatterTypeId: '411',
      State: 'VIC',
      OsUnique: '65781001',
    },
    {
      Id: '1b021132-ce03-45fd-baea-811509e96c3e',
      Title: 'FULL COMMENTARY - ENFORCEMENT (VIC)',
      MatterTypeId: '411',
      State: 'VIC',
      OsUnique: '1b021132-ce03-45fd-baea-811509e96c3e',
    },
    {
      Id: '80917001',
      Title: '101 Subpoena Answers',
      MatterTypeId: '411',
      State: 'VIC',
      OsUnique: '80917001',
    },
    {
      Id: '62e54099-57c9-4b11-b31a-1a9c608f3928',
      Title: 'Demonstrating the use of precedents',
      MatterTypeId: '411',
      State: 'VIC',
      OsUnique: '62e54099-57c9-4b11-b31a-1a9c608f3928',
    },
    {
      Id: '60630001',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS AND FRANCHISE (VIC)',
      MatterTypeId: '412',
      State: 'VIC',
      OsUnique: '60630001',
    },
    {
      Id: '60610001',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS AND FRANCHISE (VIC)',
      MatterTypeId: '412',
      State: 'VIC',
      OsUnique: '60610001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '412',
      State: 'VIC',
      OsUnique: '59835001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '412',
      State: 'VIC',
      OsUnique: '60350001',
    },
    {
      Id: '68692001',
      Title: '1001 Conveyancing Answers (VIC)',
      MatterTypeId: '412',
      State: 'VIC',
      OsUnique: '68692001',
    },
    {
      Id: '62227001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - COUNTY COURT (VIC)',
      MatterTypeId: '413',
      State: 'VIC',
      OsUnique: '62227001',
    },
    {
      Id: '68568001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - COUNTY COURT (VIC)',
      MatterTypeId: '413',
      State: 'VIC',
      OsUnique: '68568001',
    },
    {
      Id: 'd582e33a-a055-429f-8f9b-236ae8cdec20',
      Title: 'FULL COMMENTARY - ENFORCEMENT (VIC)',
      MatterTypeId: '413',
      State: 'VIC',
      OsUnique: 'd582e33a-a055-429f-8f9b-236ae8cdec20',
    },
    {
      Id: '80917001',
      Title: '101 Subpoena Answers',
      MatterTypeId: '413',
      State: 'VIC',
      OsUnique: '80917001',
    },
    {
      Id: '62e54099-57c9-4b11-b31a-1a9c608f3928',
      Title: 'Demonstrating the use of precedents',
      MatterTypeId: '413',
      State: 'VIC',
      OsUnique: '62e54099-57c9-4b11-b31a-1a9c608f3928',
    },
    {
      Id: '61788001',
      Title: 'FULL COMMENTARY - SECURITY OF PAYMENTS - BUILDING AND CONSTRUCTION INDUSTRY',
      MatterTypeId: '475',
      State: 'NSW',
      OsUnique: '61788001',
    },
    {
      Id: 'cfdc726f-8ea8-479b-ba3e-3c0087c43316',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '476',
      State: 'ACT',
      OsUnique: 'cfdc726f-8ea8-479b-ba3e-3c0087c43316',
    },
    {
      Id: '64146001',
      Title: 'Uniform contract for sale of business',
      MatterTypeId: '476',
      State: 'ACT',
      OsUnique: '64146001',
    },
    {
      Id: '2884c1a5-d699-4d80-88e0-d790fdad3db1',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '477',
      State: 'ACT',
      OsUnique: '2884c1a5-d699-4d80-88e0-d790fdad3db1',
    },
    {
      Id: '9ca825cc-2c4f-457a-91dd-5617c6a403f8',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '479',
      State: 'ACT',
      OsUnique: '9ca825cc-2c4f-457a-91dd-5617c6a403f8',
    },
    {
      Id: 'ed16facf-68cc-43a6-8e11-6ed8802808f6',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (ACT)',
      MatterTypeId: '482',
      State: 'ACT',
      OsUnique: 'ed16facf-68cc-43a6-8e11-6ed8802808f6',
    },
    {
      Id: 'c3a92862-7276-4e5c-9475-1c41a42a008f',
      Title: 'FULL COMMENTARY - PROBATE (ACT)',
      MatterTypeId: '482',
      State: 'ACT',
      OsUnique: 'c3a92862-7276-4e5c-9475-1c41a42a008f',
    },
    {
      Id: 'c3a92862-7276-4e5c-9475-1c41a42a008f',
      Title: 'FULL COMMENTARY - PROBATE (ACT)',
      MatterTypeId: '483',
      State: 'ACT',
      OsUnique: 'c3a92862-7276-4e5c-9475-1c41a42a008f',
    },
    {
      Id: 'ed16facf-68cc-43a6-8e11-6ed8802808f6',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (ACT)',
      MatterTypeId: '483',
      State: 'ACT',
      OsUnique: 'ed16facf-68cc-43a6-8e11-6ed8802808f6',
    },
    {
      Id: '9445fcf1-22c3-4d5b-9135-5ea6ec946f75',
      Title: 'FULL COMMENTARY - WILLS (ACT)',
      MatterTypeId: '484',
      State: 'ACT',
      OsUnique: '9445fcf1-22c3-4d5b-9135-5ea6ec946f75',
    },
    {
      Id: '8b3a130c-5a26-4ea3-bb13-cf73068e4031',
      Title:
        'FULL COMMENTARY - POWERS OF ATTORNEY, APPOINTMENT OF GUARDIANS AND MANAGERS AND ADVANCE CARE PLANNING (ACT)',
      MatterTypeId: '484',
      State: 'ACT',
      OsUnique: '8b3a130c-5a26-4ea3-bb13-cf73068e4031',
    },
    {
      Id: '9445fcf1-22c3-4d5b-9135-5ea6ec946f75',
      Title: 'FULL COMMENTARY - WILLS (ACT)',
      MatterTypeId: '485',
      State: 'ACT',
      OsUnique: '9445fcf1-22c3-4d5b-9135-5ea6ec946f75',
    },
    {
      Id: '8b3a130c-5a26-4ea3-bb13-cf73068e4031',
      Title:
        'FULL COMMENTARY - POWERS OF ATTORNEY, APPOINTMENT OF GUARDIANS AND MANAGERS AND ADVANCE CARE PLANNING (ACT)',
      MatterTypeId: '485',
      State: 'ACT',
      OsUnique: '8b3a130c-5a26-4ea3-bb13-cf73068e4031',
    },
    {
      Id: '60564001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (VIC)',
      MatterTypeId: '487',
      State: 'VIC',
      OsUnique: '60564001',
    },
    {
      Id: '64042001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - SUPREME COURT (QLD)',
      MatterTypeId: '488',
      State: 'QLD',
      OsUnique: '64042001',
    },
    {
      Id: '63980001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - SUPREME COURT (QLD)',
      MatterTypeId: '488',
      State: 'QLD',
      OsUnique: '63980001',
    },
    {
      Id: '80013001',
      Title: 'FULL COMMENTARY - ENFORCEMENT (QLD)',
      MatterTypeId: '488',
      State: 'QLD',
      OsUnique: '80013001',
    },
    {
      Id: '80917001',
      Title: '101 Subpoena Answers',
      MatterTypeId: '488',
      State: 'QLD',
      OsUnique: '80917001',
    },
    {
      Id: '62e54099-57c9-4b11-b31a-1a9c608f3928',
      Title: 'Demonstrating the use of precedents',
      MatterTypeId: '488',
      State: 'QLD',
      OsUnique: '62e54099-57c9-4b11-b31a-1a9c608f3928',
    },
    {
      Id: '68010001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - MAGISTRATES COURT (QLD)',
      MatterTypeId: '491',
      State: 'QLD',
      OsUnique: '68010001',
    },
    {
      Id: '68058001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - MAGISTRATES COURT (QLD)',
      MatterTypeId: '491',
      State: 'QLD',
      OsUnique: '68058001',
    },
    {
      Id: '65643001',
      Title: 'FULL COMMENTARY - DOMESTIC VIOLENCE (QLD)',
      MatterTypeId: '491',
      State: 'QLD',
      OsUnique: '65643001',
    },
    {
      Id: '63226001',
      Title: 'FULL COMMENTARY - QUEENSLAND CIVIL AND ADMINISTRATIVE TRIBUNAL (QLD)',
      MatterTypeId: '491',
      State: 'QLD',
      OsUnique: '63226001',
    },
    {
      Id: '80013001',
      Title: 'FULL COMMENTARY - ENFORCEMENT (QLD)',
      MatterTypeId: '491',
      State: 'QLD',
      OsUnique: '80013001',
    },
    {
      Id: '15facd93-23fc-4e76-8fe8-9957f5ce2aef',
      Title: 'FULL COMMENTARY - RETIREMENT VILLAGES NSW',
      MatterTypeId: '492',
      State: 'NSW',
      OsUnique: '15facd93-23fc-4e76-8fe8-9957f5ce2aef',
    },
    {
      Id: '59634001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (NSW)',
      MatterTypeId: '492',
      State: 'NSW',
      OsUnique: '59634001',
    },
    {
      Id: '60382001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (NSW)',
      MatterTypeId: '492',
      State: 'NSW',
      OsUnique: '60382001',
    },
    {
      Id: '68a977c5-ba97-4b74-a8df-343cdae01b5d',
      Title: 'FULL COMMENTARY - IMMIGRATION',
      MatterTypeId: '495',
      State: 'All',
      OsUnique: '68a977c5-ba97-4b74-a8df-343cdae01b5d',
    },
    {
      Id: '68a977c5-ba97-4b74-a8df-343cdae01b5d',
      Title: 'FULL COMMENTARY - IMMIGRATION',
      MatterTypeId: '497',
      State: 'All',
      OsUnique: '68a977c5-ba97-4b74-a8df-343cdae01b5d',
    },
    {
      Id: 'dcff4cf3-c8b3-4491-8f70-547a88662130',
      Title: 'FULL COMMENTARY - CRIMINAL (WA)',
      MatterTypeId: '5000022',
      State: 'WA',
      OsUnique: 'dcff4cf3-c8b3-4491-8f70-547a88662130',
    },
    {
      Id: 'b5d07877-93f4-42ef-b6bd-0ec97be3c9f3',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - MAGISTRATES COURT (WA)',
      MatterTypeId: '5000022',
      State: 'WA',
      OsUnique: 'b5d07877-93f4-42ef-b6bd-0ec97be3c9f3',
    },
    {
      Id: '5d19a185-c4cd-49e6-829d-20b65f968f81',
      Title: 'FULL COMMENTARY - RESTRAINING ORDERS (WA)',
      MatterTypeId: '5000026',
      State: 'WA',
      OsUnique: '5d19a185-c4cd-49e6-829d-20b65f968f81',
    },
    {
      Id: 'dcff4cf3-c8b3-4491-8f70-547a88662130',
      Title: 'FULL COMMENTARY - CRIMINAL (WA)',
      MatterTypeId: '5000026',
      State: 'WA',
      OsUnique: 'dcff4cf3-c8b3-4491-8f70-547a88662130',
    },
    {
      Id: 'ad146ecd-05fc-49a5-a23b-09fc4af7468f',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES COURT (SA)',
      MatterTypeId: '5000030',
      State: 'SA',
      OsUnique: 'ad146ecd-05fc-49a5-a23b-09fc4af7468f',
    },
    {
      Id: 'd4f82a71-2749-4664-971d-bedb448c21ed',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - MAGISTRATES COURT (SA)',
      MatterTypeId: '5000030',
      State: 'SA',
      OsUnique: 'd4f82a71-2749-4664-971d-bedb448c21ed',
    },
    {
      Id: '80843001',
      Title: 'FULL COMMENTARY - INTERVENTION ORDERS (SA)',
      MatterTypeId: '5000030',
      State: 'SA',
      OsUnique: '80843001',
    },
    {
      Id: '80843001',
      Title: 'FULL COMMENTARY - INTERVENTION ORDERS (SA)',
      MatterTypeId: '5000034',
      State: 'SA',
      OsUnique: '80843001',
    },
    {
      Id: 'ad146ecd-05fc-49a5-a23b-09fc4af7468f',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES COURT (SA)',
      MatterTypeId: '5000034',
      State: 'SA',
      OsUnique: 'ad146ecd-05fc-49a5-a23b-09fc4af7468f',
    },
    {
      Id: 'd4f82a71-2749-4664-971d-bedb448c21ed',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - MAGISTRATES COURT (SA)',
      MatterTypeId: '5000034',
      State: 'SA',
      OsUnique: 'd4f82a71-2749-4664-971d-bedb448c21ed',
    },
    {
      Id: 'f2c96bc8-bb6e-4e4b-84b5-9d9ef3c0fad2',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000036',
      State: 'SA',
      OsUnique: 'f2c96bc8-bb6e-4e4b-84b5-9d9ef3c0fad2',
    },
    {
      Id: 'eee49030-3252-4b1d-bcf4-de498f6d6ea7',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000053',
      State: 'ACT',
      OsUnique: 'eee49030-3252-4b1d-bcf4-de498f6d6ea7',
    },
    {
      Id: 'f133627a-67bc-4c2e-9aca-efb232607dc4',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000054',
      State: 'ACT',
      OsUnique: 'f133627a-67bc-4c2e-9aca-efb232607dc4',
    },
    {
      Id: 'c76cc655-5b08-43ff-99f1-def0d4f9eb9b',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000055',
      State: 'ACT',
      OsUnique: 'c76cc655-5b08-43ff-99f1-def0d4f9eb9b',
    },
    {
      Id: 'a53f5ae0-52ba-45c4-b1d6-80edb0973812',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000056',
      State: 'ACT',
      OsUnique: 'a53f5ae0-52ba-45c4-b1d6-80edb0973812',
    },
    {
      Id: '8e5fe3d6-30f5-4b40-809d-e08985086bda',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000057',
      State: 'ACT',
      OsUnique: '8e5fe3d6-30f5-4b40-809d-e08985086bda',
    },
    {
      Id: '8cba02f7-89e3-4a68-ae2c-5b7a64273472',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000058',
      State: 'ACT',
      OsUnique: '8cba02f7-89e3-4a68-ae2c-5b7a64273472',
    },
    {
      Id: '6874f31c-a6e3-41e9-97f8-ec68169ac853',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000059',
      State: 'ACT',
      OsUnique: '6874f31c-a6e3-41e9-97f8-ec68169ac853',
    },
    {
      Id: 'b53adf1b-cce3-4d08-b1ba-66752c711785',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000061',
      State: 'WA',
      OsUnique: 'b53adf1b-cce3-4d08-b1ba-66752c711785',
    },
    {
      Id: 'bd976106-86e0-490a-be57-74d12b464297',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000064',
      State: 'NT',
      OsUnique: 'bd976106-86e0-490a-be57-74d12b464297',
    },
    {
      Id: '3db2ba72-83a2-429e-8373-0e593b3e973b',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000065',
      State: 'NT',
      OsUnique: '3db2ba72-83a2-429e-8373-0e593b3e973b',
    },
    {
      Id: '64147001',
      Title: 'Uniform contract for sale of business',
      MatterTypeId: '5000065',
      State: 'NT',
      OsUnique: '64147001',
    },
    {
      Id: '257c8b29-b19e-4237-b3f0-b54cbc018637',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000066',
      State: 'WA',
      OsUnique: '257c8b29-b19e-4237-b3f0-b54cbc018637',
    },
    {
      Id: '64753001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - EQUITY DIVISION SUPREME COURT (NSW)',
      MatterTypeId: '5000069',
      State: 'NSW',
      OsUnique: '64753001',
    },
    {
      Id: '64700001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - EQUITY DIVISION SUPREME COURT (NSW)',
      MatterTypeId: '5000069',
      State: 'NSW',
      OsUnique: '64700001',
    },
    {
      Id: '71729001',
      Title: 'FULL COMMENTARY - ENFORCEMENT (NSW)',
      MatterTypeId: '5000069',
      State: 'NSW',
      OsUnique: '71729001',
    },
    {
      Id: '80917001',
      Title: '101 Subpoena Answers',
      MatterTypeId: '5000069',
      State: 'NSW',
      OsUnique: '80917001',
    },
    {
      Id: '62e54099-57c9-4b11-b31a-1a9c608f3928',
      Title: 'Demonstrating the use of precedents',
      MatterTypeId: '5000069',
      State: 'NSW',
      OsUnique: '62e54099-57c9-4b11-b31a-1a9c608f3928',
    },
    {
      Id: '61577001',
      Title: 'FULL COMMENTARY - CRIMINAL - LOCAL COURT (NSW)',
      MatterTypeId: '5000071',
      State: 'NSW',
      OsUnique: '61577001',
    },
    {
      Id: '61666001',
      Title: 'FULL COMMENTARY - APPREHENDED VIOLENCE ORDER (NSW)',
      MatterTypeId: '5000071',
      State: 'NSW',
      OsUnique: '61666001',
    },
    {
      Id: '67586001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - LOCAL COURT (NSW)',
      MatterTypeId: '5000071',
      State: 'NSW',
      OsUnique: '67586001',
    },
    {
      Id: '61577001',
      Title: 'FULL COMMENTARY - CRIMINAL - LOCAL COURT (NSW)',
      MatterTypeId: '5000072',
      State: 'NSW',
      OsUnique: '61577001',
    },
    {
      Id: '61666001',
      Title: 'FULL COMMENTARY - APPREHENDED VIOLENCE ORDER (NSW)',
      MatterTypeId: '5000072',
      State: 'NSW',
      OsUnique: '61666001',
    },
    {
      Id: '67586001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - LOCAL COURT (NSW)',
      MatterTypeId: '5000072',
      State: 'NSW',
      OsUnique: '67586001',
    },
    {
      Id: '9fef3937-e48d-4d51-b171-5f5a6e808d71',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000094',
      State: 'TAS',
      OsUnique: '9fef3937-e48d-4d51-b171-5f5a6e808d71',
    },
    {
      Id: 'b5d07877-93f4-42ef-b6bd-0ec97be3c9f3',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - MAGISTRATES COURT (WA)',
      MatterTypeId: '5000095',
      State: 'WA',
      OsUnique: 'b5d07877-93f4-42ef-b6bd-0ec97be3c9f3',
    },
    {
      Id: 'dcff4cf3-c8b3-4491-8f70-547a88662130',
      Title: 'FULL COMMENTARY - CRIMINAL (WA)',
      MatterTypeId: '5000095',
      State: 'WA',
      OsUnique: 'dcff4cf3-c8b3-4491-8f70-547a88662130',
    },
    {
      Id: 'd4f82a71-2749-4664-971d-bedb448c21ed',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - MAGISTRATES COURT (SA)',
      MatterTypeId: '5000096',
      State: 'SA',
      OsUnique: 'd4f82a71-2749-4664-971d-bedb448c21ed',
    },
    {
      Id: 'ad146ecd-05fc-49a5-a23b-09fc4af7468f',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES COURT (SA)',
      MatterTypeId: '5000096',
      State: 'SA',
      OsUnique: 'ad146ecd-05fc-49a5-a23b-09fc4af7468f',
    },
    {
      Id: '80843001',
      Title: 'FULL COMMENTARY - INTERVENTION ORDERS (SA)',
      MatterTypeId: '5000096',
      State: 'SA',
      OsUnique: '80843001',
    },
    {
      Id: '0fa56e16-0378-4163-a80a-677cc6fc34cb',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000097',
      State: 'NT',
      OsUnique: '0fa56e16-0378-4163-a80a-677cc6fc34cb',
    },
    {
      Id: 'ac4fcdf1-e5c2-4d18-bd11-f8b168bc3040',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - MAGISTRATES COURT (QLD)',
      MatterTypeId: '5000098',
      State: 'QLD',
      OsUnique: 'ac4fcdf1-e5c2-4d18-bd11-f8b168bc3040',
    },
    {
      Id: '63780001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES COURT (QLD)',
      MatterTypeId: '5000098',
      State: 'QLD',
      OsUnique: '63780001',
    },
    {
      Id: 'de14c605-017c-4d4e-a091-0ee68b7ea66c',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000099',
      State: 'ACT',
      OsUnique: 'de14c605-017c-4d4e-a091-0ee68b7ea66c',
    },
    {
      Id: 'e4ccc270-5e8b-4c95-8ec1-72dd28d9f6c2',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000102',
      State: 'NT',
      OsUnique: 'e4ccc270-5e8b-4c95-8ec1-72dd28d9f6c2',
    },
    {
      Id: '64976001',
      Title: 'FULL COMMENTARY - PRACTICE MANAGEMENT',
      MatterTypeId: '501',
      State: 'All',
      OsUnique: '64976001',
    },
    {
      Id: '2ca0e85d-f27b-4412-b213-61d0a4d23024',
      Title: 'FULL COMMENTARY - COSTS',
      MatterTypeId: '501',
      State: 'All',
      OsUnique: '2ca0e85d-f27b-4412-b213-61d0a4d23024',
    },
    {
      Id: '97e0a4c4-7916-45be-b235-53205c9b663f',
      Title: '101 Policies & Procedures',
      MatterTypeId: '501',
      State: 'All',
      OsUnique: '97e0a4c4-7916-45be-b235-53205c9b663f',
    },
    {
      Id: '96871f3c-3884-43d4-a7cb-63fc63db6838',
      Title: '101 Trusted and useful sites by area of law',
      MatterTypeId: '501',
      State: 'All',
      OsUnique: '96871f3c-3884-43d4-a7cb-63fc63db6838',
    },
    {
      Id: '63910001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - DISTRICT COURT (QLD)',
      MatterTypeId: '507',
      State: 'QLD',
      OsUnique: '63910001',
    },
    {
      Id: '63843001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - DISTRICT COURT (QLD)',
      MatterTypeId: '507',
      State: 'QLD',
      OsUnique: '63843001',
    },
    {
      Id: '80013001',
      Title: 'FULL COMMENTARY - ENFORCEMENT (QLD)',
      MatterTypeId: '507',
      State: 'QLD',
      OsUnique: '80013001',
    },
    {
      Id: '80917001',
      Title: '101 Subpoena Answers',
      MatterTypeId: '507',
      State: 'QLD',
      OsUnique: '80917001',
    },
    {
      Id: '62e54099-57c9-4b11-b31a-1a9c608f3928',
      Title: 'Demonstrating the use of precedents',
      MatterTypeId: '507',
      State: 'QLD',
      OsUnique: '62e54099-57c9-4b11-b31a-1a9c608f3928',
    },
    {
      Id: '60673001',
      Title: 'FULL COMMENTARY - POWERS & ADVANCE CARE DIRECTIVES (VIC)',
      MatterTypeId: '510',
      State: 'VIC',
      OsUnique: '60673001',
    },
    {
      Id: '67685001',
      Title: 'FULL COMMENTARY - WILLS (VIC)',
      MatterTypeId: '510',
      State: 'VIC',
      OsUnique: '67685001',
    },
    {
      Id: '68266001',
      Title: 'FULL COMMENTARY - PROBATE (VIC)',
      MatterTypeId: '510',
      State: 'VIC',
      OsUnique: '68266001',
    },
    {
      Id: '60755001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (VIC)',
      MatterTypeId: '510',
      State: 'VIC',
      OsUnique: '60755001',
    },
    {
      Id: '60933001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE HEALTH DIRECTIVES (QLD)',
      MatterTypeId: '512',
      State: 'QLD',
      OsUnique: '60933001',
    },
    {
      Id: '60891001',
      Title: 'FULL COMMENTARY - WILLS (QLD)',
      MatterTypeId: '512',
      State: 'QLD',
      OsUnique: '60891001',
    },
    {
      Id: '60871001',
      Title: 'FULL COMMENTARY - PROBATE (QLD)',
      MatterTypeId: '512',
      State: 'QLD',
      OsUnique: '60871001',
    },
    {
      Id: '60912001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (QLD)',
      MatterTypeId: '512',
      State: 'QLD',
      OsUnique: '60912001',
    },
    {
      Id: '8b3a130c-5a26-4ea3-bb13-cf73068e4031',
      Title:
        'FULL COMMENTARY - POWERS OF ATTORNEY, APPOINTMENT OF GUARDIANS AND MANAGERS AND ADVANCE CARE PLANNING (ACT)',
      MatterTypeId: '513',
      State: 'ACT',
      OsUnique: '8b3a130c-5a26-4ea3-bb13-cf73068e4031',
    },
    {
      Id: '9445fcf1-22c3-4d5b-9135-5ea6ec946f75',
      Title: 'FULL COMMENTARY - WILLS (ACT)',
      MatterTypeId: '513',
      State: 'ACT',
      OsUnique: '9445fcf1-22c3-4d5b-9135-5ea6ec946f75',
    },
    {
      Id: '54e8ed1f-aa39-4651-839d-a13ac2d52bfb',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '515',
      State: 'ACT',
      OsUnique: '54e8ed1f-aa39-4651-839d-a13ac2d52bfb',
    },
    {
      Id: 'cb75bca5-c08d-45f8-8bed-8fe63543a060',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '550',
      State: 'ACT',
      OsUnique: 'cb75bca5-c08d-45f8-8bed-8fe63543a060',
    },
    {
      Id: '61004001',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS AND FRANCHISE (QLD)',
      MatterTypeId: '551',
      State: 'QLD',
      OsUnique: '61004001',
    },
    {
      Id: '60980001',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS AND FRANCHISE (QLD)',
      MatterTypeId: '551',
      State: 'QLD',
      OsUnique: '60980001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '551',
      State: 'QLD',
      OsUnique: '60350001',
    },
    {
      Id: '68691001',
      Title: '1001 Conveyancing Answers (QLD)',
      MatterTypeId: '551',
      State: 'QLD',
      OsUnique: '68691001',
    },
    {
      Id: '60610001',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS AND FRANCHISE (VIC)',
      MatterTypeId: '552',
      State: 'VIC',
      OsUnique: '60610001',
    },
    {
      Id: '60630001',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS AND FRANCHISE (VIC)',
      MatterTypeId: '552',
      State: 'VIC',
      OsUnique: '60630001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '552',
      State: 'VIC',
      OsUnique: '60350001',
    },
    {
      Id: '68692001',
      Title: '1001 Conveyancing Answers (VIC)',
      MatterTypeId: '552',
      State: 'VIC',
      OsUnique: '68692001',
    },
    {
      Id: '65348001',
      Title: 'FULL COMMENTARY - EMPLOYMENT LAW',
      MatterTypeId: '556',
      State: 'All',
      OsUnique: '65348001',
    },
    {
      Id: '5ebea8e0-6270-4c0c-a592-2a1c4a075bfd',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '560',
      State: 'ACT',
      OsUnique: '5ebea8e0-6270-4c0c-a592-2a1c4a075bfd',
    },
    {
      Id: '64464001',
      Title: 'FULL COMMENTARY - LEASES (QLD)',
      MatterTypeId: '561',
      State: 'QLD',
      OsUnique: '64464001',
    },
    {
      Id: '68691001',
      Title: '1001 Conveyancing Answers (QLD)',
      MatterTypeId: '561',
      State: 'QLD',
      OsUnique: '68691001',
    },
    {
      Id: '64390001',
      Title: 'FULL COMMENTARY - LEASES (VIC)',
      MatterTypeId: '562',
      State: 'VIC',
      OsUnique: '64390001',
    },
    {
      Id: '68692001',
      Title: '1001 Conveyancing Answers (VIC)',
      MatterTypeId: '562',
      State: 'VIC',
      OsUnique: '68692001',
    },
    {
      Id: '63cbf450-0e5b-408b-ab69-d1fc88bd7fbf',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '566',
      State: 'ACT',
      OsUnique: '63cbf450-0e5b-408b-ab69-d1fc88bd7fbf',
    },
    {
      Id: '64464001',
      Title: 'FULL COMMENTARY - LEASES (QLD)',
      MatterTypeId: '567',
      State: 'QLD',
      OsUnique: '64464001',
    },
    {
      Id: '64390001',
      Title: 'FULL COMMENTARY - LEASES (VIC)',
      MatterTypeId: '568',
      State: 'VIC',
      OsUnique: '64390001',
    },
    {
      Id: '5f87e301-d41c-4f11-947c-1845a7f7a6a4',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '569',
      State: 'ACT',
      OsUnique: '5f87e301-d41c-4f11-947c-1845a7f7a6a4',
    },
    {
      Id: '64464001',
      Title: 'FULL COMMENTARY - LEASES (QLD)',
      MatterTypeId: '570',
      State: 'QLD',
      OsUnique: '64464001',
    },
    {
      Id: '64390001',
      Title: 'FULL COMMENTARY - LEASES (VIC)',
      MatterTypeId: '571',
      State: 'VIC',
      OsUnique: '64390001',
    },
    {
      Id: 'c8626b25-9dea-4498-99c8-a13844a2b2a8',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '591',
      State: 'ACT',
      OsUnique: 'c8626b25-9dea-4498-99c8-a13844a2b2a8',
    },
    {
      Id: '51fe0aef-93bc-4ee1-856c-97b63692d4f9',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '597',
      State: 'ACT',
      OsUnique: '51fe0aef-93bc-4ee1-856c-97b63692d4f9',
    },
    {
      Id: '68323001',
      Title: 'FULL COMMENTARY - MORTGAGES (NSW)',
      MatterTypeId: '6',
      State: 'NSW',
      OsUnique: '68323001',
    },
    {
      Id: '59634001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (NSW)',
      MatterTypeId: '6',
      State: 'NSW',
      OsUnique: '59634001',
    },
    {
      Id: '60382001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (NSW)',
      MatterTypeId: '6',
      State: 'NSW',
      OsUnique: '60382001',
    },
    {
      Id: '68689001',
      Title: '1001 Conveyancing Answers (NSW)',
      MatterTypeId: '6',
      State: 'NSW',
      OsUnique: '68689001',
    },
    {
      Id: '6b6197b0-98db-412d-ac97-c95d4637f2e8',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '600',
      State: 'ACT',
      OsUnique: '6b6197b0-98db-412d-ac97-c95d4637f2e8',
    },
    {
      Id: '48501b2d-6076-4615-857e-17e16d701cb3',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '603',
      State: 'ACT',
      OsUnique: '48501b2d-6076-4615-857e-17e16d701cb3',
    },
    {
      Id: '48501b2d-6076-4615-857e-17e16d701cb3',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '605',
      State: 'VIC',
      OsUnique: '48501b2d-6076-4615-857e-17e16d701cb3',
    },
    {
      Id: '5b9cfd81-1462-4056-a9f2-afe1d48330aa',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '647',
      State: 'VIC',
      OsUnique: '5b9cfd81-1462-4056-a9f2-afe1d48330aa',
    },
    {
      Id: '0313888a-b97d-48ff-89fc-236982a8ff1b',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '648',
      State: 'VIC',
      OsUnique: '0313888a-b97d-48ff-89fc-236982a8ff1b',
    },
    {
      Id: '60891001',
      Title: 'FULL COMMENTARY - WILLS (QLD)',
      MatterTypeId: '658',
      State: 'QLD',
      OsUnique: '60891001',
    },
    {
      Id: '60933001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE HEALTH DIRECTIVES (QLD)',
      MatterTypeId: '658',
      State: 'QLD',
      OsUnique: '60933001',
    },
    {
      Id: '60871001',
      Title: 'FULL COMMENTARY - PROBATE (QLD)',
      MatterTypeId: '658',
      State: 'QLD',
      OsUnique: '60871001',
    },
    {
      Id: '60912001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (QLD)',
      MatterTypeId: '658',
      State: 'QLD',
      OsUnique: '60912001',
    },
    {
      Id: '60891001',
      Title: 'FULL COMMENTARY - WILLS (QLD)',
      MatterTypeId: '659',
      State: 'QLD',
      OsUnique: '60891001',
    },
    {
      Id: '60933001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE HEALTH DIRECTIVES (QLD)',
      MatterTypeId: '659',
      State: 'QLD',
      OsUnique: '60933001',
    },
    {
      Id: '60871001',
      Title: 'FULL COMMENTARY - PROBATE (QLD)',
      MatterTypeId: '659',
      State: 'QLD',
      OsUnique: '60871001',
    },
    {
      Id: '60912001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (QLD)',
      MatterTypeId: '659',
      State: 'QLD',
      OsUnique: '60912001',
    },
    {
      Id: '59694001',
      Title: 'FULL COMMENTARY - PROPERTY SETTLEMENT',
      MatterTypeId: '661',
      State: 'All',
      OsUnique: '59694001',
    },
    {
      Id: '81562001',
      Title: 'FULL COMMENTARY - CHILDREN',
      MatterTypeId: '661',
      State: 'All',
      OsUnique: '81562001',
    },
    {
      Id: '60266001',
      Title: 'FULL COMMENTARY - DIVORCE',
      MatterTypeId: '661',
      State: 'All',
      OsUnique: '60266001',
    },
    {
      Id: '59766001',
      Title: 'FULL COMMENTARY - FINANCIAL AGREEMENTS',
      MatterTypeId: '661',
      State: 'All',
      OsUnique: '59766001',
    },
    {
      Id: '65061001',
      Title: '101 Family Law Answers',
      MatterTypeId: '661',
      State: 'All',
      OsUnique: '65061001',
    },
    {
      Id: '80917001',
      Title: '101 Subpoena Answers',
      MatterTypeId: '661',
      State: 'All',
      OsUnique: '80917001',
    },
    {
      Id: 'e68d6f58-9803-4b95-9cd1-55e68d58d995',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '664',
      State: 'ACT',
      OsUnique: 'e68d6f58-9803-4b95-9cd1-55e68d58d995',
    },
    {
      Id: '60530001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (VIC)',
      MatterTypeId: '351',
      State: 'VIC',
      OsUnique: '60530001',
    },
    {
      Id: '68692001',
      Title: '1001 Conveyancing Answers (VIC)',
      MatterTypeId: '351',
      State: 'VIC',
      OsUnique: '68692001',
    },
    {
      Id: '2b9409aa-af8b-470d-a4a7-4c8dcc3ad3a0',
      Title: '101 Section 32 Answers (VIC)',
      MatterTypeId: '351',
      State: 'VIC',
      OsUnique: '2b9409aa-af8b-470d-a4a7-4c8dcc3ad3a0',
    },
    {
      Id: '64191001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (QLD)',
      MatterTypeId: '352',
      State: 'QLD',
      OsUnique: '64191001',
    },
    {
      Id: '68691001',
      Title: '1001 Conveyancing Answers (QLD)',
      MatterTypeId: '352',
      State: 'QLD',
      OsUnique: '68691001',
    },
    {
      Id: '64152001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (QLD)',
      MatterTypeId: '352',
      State: 'QLD',
      OsUnique: '64152001',
    },
    {
      Id: '60838001',
      Title: 'FULL COMMENTARY - MORTGAGES (QLD)',
      MatterTypeId: '352',
      State: 'QLD',
      OsUnique: '60838001',
    },
    {
      Id: '64152001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (QLD)',
      MatterTypeId: '353',
      State: 'QLD',
      OsUnique: '64152001',
    },
    {
      Id: '68691001',
      Title: '1001 Conveyancing Answers (QLD)',
      MatterTypeId: '353',
      State: 'QLD',
      OsUnique: '68691001',
    },
    {
      Id: '64191001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (QLD)',
      MatterTypeId: '353',
      State: 'QLD',
      OsUnique: '64191001',
    },
    {
      Id: '60838001',
      Title: 'FULL COMMENTARY - MORTGAGES (QLD)',
      MatterTypeId: '353',
      State: 'QLD',
      OsUnique: '60838001',
    },
    {
      Id: '64191001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (QLD)',
      MatterTypeId: '354',
      State: 'QLD',
      OsUnique: '64191001',
    },
    {
      Id: '68691001',
      Title: '1001 Conveyancing Answers (QLD)',
      MatterTypeId: '354',
      State: 'QLD',
      OsUnique: '68691001',
    },
    {
      Id: 'caed9a64-c5ea-4ada-8db7-c258c214be66',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '358',
      State: 'NSW',
      OsUnique: 'caed9a64-c5ea-4ada-8db7-c258c214be66',
    },
    {
      Id: '89e25657-af5b-4dc0-8092-657ce6a96079',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '359',
      State: 'NSW',
      OsUnique: '89e25657-af5b-4dc0-8092-657ce6a96079',
    },
    {
      Id: '61577001',
      Title: 'FULL COMMENTARY - CRIMINAL - LOCAL COURT (NSW)',
      MatterTypeId: '359',
      State: 'NSW',
      OsUnique: '61577001',
    },
    {
      Id: '61666001',
      Title: 'FULL COMMENTARY - APPREHENDED VIOLENCE ORDER (NSW)',
      MatterTypeId: '359',
      State: 'NSW',
      OsUnique: '61666001',
    },
    {
      Id: '67586001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - LOCAL COURT (NSW)',
      MatterTypeId: '359',
      State: 'NSW',
      OsUnique: '67586001',
    },
    {
      Id: '60475001',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS AND FRANCHISE (NSW)',
      MatterTypeId: '36',
      State: 'NSW',
      OsUnique: '60475001',
    },
    {
      Id: '59744001',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS AND FRANCHISE (NSW)',
      MatterTypeId: '36',
      State: 'NSW',
      OsUnique: '59744001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '36',
      State: 'NSW',
      OsUnique: '59835001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '36',
      State: 'NSW',
      OsUnique: '60350001',
    },
    {
      Id: '68689001',
      Title: '1001 Conveyancing Answers (NSW)',
      MatterTypeId: '36',
      State: 'NSW',
      OsUnique: '68689001',
    },
    {
      Id: '5d6636bc-5a7b-4d4a-b721-f4f9365bfc3d',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '361',
      State: 'NSW',
      OsUnique: '5d6636bc-5a7b-4d4a-b721-f4f9365bfc3d',
    },
    {
      Id: '62471001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - MAGISTRATES\' COURT CLAIMS UNDER $100,000 (VIC)',
      MatterTypeId: '414',
      State: 'VIC',
      OsUnique: '62471001',
    },
    {
      Id: '62439001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - MAGISTRATES\' COURT CLAIMS UNDER $100,000 (VIC)',
      MatterTypeId: '414',
      State: 'VIC',
      OsUnique: '62439001',
    },
    {
      Id: '62502001',
      Title: 'FULL COMMENTARY - ENFORCEMENT (VIC)',
      MatterTypeId: '414',
      State: 'VIC',
      OsUnique: '62502001',
    },
    {
      Id: '63462001',
      Title: 'FULL COMMENTARY - INTERVENTION ORDERS (VIC)',
      MatterTypeId: '414',
      State: 'VIC',
      OsUnique: '63462001',
    },
    {
      Id: '60838001',
      Title: 'FULL COMMENTARY - MORTGAGES (QLD)',
      MatterTypeId: '418',
      State: 'QLD',
      OsUnique: '60838001',
    },
    {
      Id: '68691001',
      Title: '1001 Conveyancing Answers (QLD)',
      MatterTypeId: '418',
      State: 'QLD',
      OsUnique: '68691001',
    },
    {
      Id: '60980001',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS AND FRANCHISE (QLD)',
      MatterTypeId: '421',
      State: 'QLD',
      OsUnique: '60980001',
    },
    {
      Id: '61004001',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS AND FRANCHISE (QLD)',
      MatterTypeId: '421',
      State: 'QLD',
      OsUnique: '61004001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '421',
      State: 'QLD',
      OsUnique: '59835001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '421',
      State: 'QLD',
      OsUnique: '60350001',
    },
    {
      Id: '68691001',
      Title: '1001 Conveyancing Answers (QLD)',
      MatterTypeId: '421',
      State: 'QLD',
      OsUnique: '68691001',
    },
    {
      Id: '61004001',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS AND FRANCHISE (QLD)',
      MatterTypeId: '422',
      State: 'QLD',
      OsUnique: '61004001',
    },
    {
      Id: '60980001',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS AND FRANCHISE (QLD)',
      MatterTypeId: '422',
      State: 'QLD',
      OsUnique: '60980001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '422',
      State: 'QLD',
      OsUnique: '59835001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '422',
      State: 'QLD',
      OsUnique: '60350001',
    },
    {
      Id: '68691001',
      Title: '1001 Conveyancing Answers (QLD)',
      MatterTypeId: '422',
      State: 'QLD',
      OsUnique: '68691001',
    },
    {
      Id: '60838001',
      Title: 'FULL COMMENTARY - MORTGAGES (QLD)',
      MatterTypeId: '423',
      State: 'QLD',
      OsUnique: '60838001',
    },
    {
      Id: '64152001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (QLD)',
      MatterTypeId: '423',
      State: 'QLD',
      OsUnique: '64152001',
    },
    {
      Id: '64191001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (QLD)',
      MatterTypeId: '423',
      State: 'QLD',
      OsUnique: '64191001',
    },
    {
      Id: '68691001',
      Title: '1001 Conveyancing Answers (QLD)',
      MatterTypeId: '423',
      State: 'QLD',
      OsUnique: '68691001',
    },
    {
      Id: '6d003b2a-edf2-4adf-a468-14963ce023a3',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '424',
      State: 'VIC',
      OsUnique: '6d003b2a-edf2-4adf-a468-14963ce023a3',
    },
    {
      Id: '60780001',
      Title: 'FULL COMMENTARY - MORTGAGES (VIC)',
      MatterTypeId: '425',
      State: 'VIC',
      OsUnique: '60780001',
    },
    {
      Id: '68692001',
      Title: '1001 Conveyancing Answers (VIC)',
      MatterTypeId: '425',
      State: 'VIC',
      OsUnique: '68692001',
    },
    {
      Id: '60610001',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS AND FRANCHISE (VIC)',
      MatterTypeId: '430',
      State: 'VIC',
      OsUnique: '60610001',
    },
    {
      Id: '60630001',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS AND FRANCHISE (VIC)',
      MatterTypeId: '430',
      State: 'VIC',
      OsUnique: '60630001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '430',
      State: 'VIC',
      OsUnique: '59835001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '430',
      State: 'VIC',
      OsUnique: '60350001',
    },
    {
      Id: '68692001',
      Title: '1001 Conveyancing Answers (VIC)',
      MatterTypeId: '430',
      State: 'VIC',
      OsUnique: '68692001',
    },
    {
      Id: '1faf1732-36dc-480b-8030-d65b7156776e',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '431',
      State: 'ACT',
      OsUnique: '1faf1732-36dc-480b-8030-d65b7156776e',
    },
    {
      Id: '60475001',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS AND FRANCHISE (NSW)',
      MatterTypeId: '435',
      State: 'NSW',
      OsUnique: '60475001',
    },
    {
      Id: '59744001',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS AND FRANCHISE (NSW)',
      MatterTypeId: '435',
      State: 'NSW',
      OsUnique: '59744001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '435',
      State: 'NSW',
      OsUnique: '60350001',
    },
    {
      Id: '68689001',
      Title: '1001 Conveyancing Answers (NSW)',
      MatterTypeId: '435',
      State: 'NSW',
      OsUnique: '68689001',
    },
    {
      Id: '59744001',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS AND FRANCHISE (NSW)',
      MatterTypeId: '436',
      State: 'NSW',
      OsUnique: '59744001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '436',
      State: 'NSW',
      OsUnique: '60350001',
    },
    {
      Id: '68689001',
      Title: '1001 Conveyancing Answers (NSW)',
      MatterTypeId: '436',
      State: 'NSW',
      OsUnique: '68689001',
    },
    {
      Id: '60475001',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS AND FRANCHISE (NSW)',
      MatterTypeId: '437',
      State: 'NSW',
      OsUnique: '60475001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '437',
      State: 'NSW',
      OsUnique: '60350001',
    },
    {
      Id: '68689001',
      Title: '1001 Conveyancing Answers (NSW)',
      MatterTypeId: '437',
      State: 'NSW',
      OsUnique: '68689001',
    },
    {
      Id: '67685001',
      Title: 'FULL COMMENTARY - WILLS (VIC)',
      MatterTypeId: '438',
      State: 'VIC',
      OsUnique: '67685001',
    },
    {
      Id: '60673001',
      Title: 'FULL COMMENTARY - POWERS & ADVANCE CARE DIRECTIVES (VIC)',
      MatterTypeId: '438',
      State: 'VIC',
      OsUnique: '60673001',
    },
    {
      Id: '68266001',
      Title: 'FULL COMMENTARY - PROBATE (VIC)',
      MatterTypeId: '438',
      State: 'VIC',
      OsUnique: '68266001',
    },
    {
      Id: '60755001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (VIC)',
      MatterTypeId: '438',
      State: 'VIC',
      OsUnique: '60755001',
    },
    {
      Id: '67685001',
      Title: 'FULL COMMENTARY - WILLS (VIC)',
      MatterTypeId: '472',
      State: 'VIC',
      OsUnique: '67685001',
    },
    {
      Id: '60673001',
      Title: 'FULL COMMENTARY - POWERS & ADVANCE CARE DIRECTIVES (VIC)',
      MatterTypeId: '472',
      State: 'VIC',
      OsUnique: '60673001',
    },
    {
      Id: '68266001',
      Title: 'FULL COMMENTARY - PROBATE (VIC)',
      MatterTypeId: '472',
      State: 'VIC',
      OsUnique: '68266001',
    },
    {
      Id: '60755001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (VIC)',
      MatterTypeId: '472',
      State: 'VIC',
      OsUnique: '60755001',
    },
    {
      Id: 'cc7171ab-42c2-407c-bfc1-1f90bc3024ea',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '4d0f0ed9-1762-491b-b60a-fa3803605a21',
      State: 'NT',
      OsUnique: 'cc7171ab-42c2-407c-bfc1-1f90bc3024ea',
    },
    {
      Id: '63382001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '5000007',
      State: 'VIC',
      OsUnique: '63382001',
    },
    {
      Id: '67628001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '5000007',
      State: 'VIC',
      OsUnique: '67628001',
    },
    {
      Id: '63462001',
      Title: 'FULL COMMENTARY - INTERVENTION ORDERS (VIC)',
      MatterTypeId: '5000007',
      State: 'VIC',
      OsUnique: '63462001',
    },
    {
      Id: '5f3f8faa-523f-4238-943a-50c2c3f99c80',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000008',
      State: 'VIC',
      OsUnique: '5f3f8faa-523f-4238-943a-50c2c3f99c80',
    },
    {
      Id: '63382001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '5000008',
      State: 'VIC',
      OsUnique: '63382001',
    },
    {
      Id: '67628001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '5000008',
      State: 'VIC',
      OsUnique: '67628001',
    },
    {
      Id: '63462001',
      Title: 'FULL COMMENTARY - INTERVENTION ORDERS (VIC)',
      MatterTypeId: '5000008',
      State: 'VIC',
      OsUnique: '63462001',
    },
    {
      Id: '63382001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '5000009',
      State: 'VIC',
      OsUnique: '63382001',
    },
    {
      Id: '67628001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '5000009',
      State: 'VIC',
      OsUnique: '67628001',
    },
    {
      Id: '63462001',
      Title: 'FULL COMMENTARY - INTERVENTION ORDERS (VIC)',
      MatterTypeId: '5000009',
      State: 'VIC',
      OsUnique: '63462001',
    },
    {
      Id: '63382001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '5000010',
      State: 'VIC',
      OsUnique: '63382001',
    },
    {
      Id: '67628001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '5000010',
      State: 'VIC',
      OsUnique: '67628001',
    },
    {
      Id: '63462001',
      Title: 'FULL COMMENTARY - INTERVENTION ORDERS (VIC)',
      MatterTypeId: '5000010',
      State: 'VIC',
      OsUnique: '63462001',
    },
    {
      Id: 'ec47338d-7850-4243-ba7f-49250fa35265',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000011',
      State: 'VIC',
      OsUnique: 'ec47338d-7850-4243-ba7f-49250fa35265',
    },
    {
      Id: '63382001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '5000011',
      State: 'VIC',
      OsUnique: '63382001',
    },
    {
      Id: '67628001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '5000011',
      State: 'VIC',
      OsUnique: '67628001',
    },
    {
      Id: '63382001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '5000012',
      State: 'VIC',
      OsUnique: '63382001',
    },
    {
      Id: '67628001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '5000012',
      State: 'VIC',
      OsUnique: '67628001',
    },
    {
      Id: '63462001',
      Title: 'FULL COMMENTARY - INTERVENTION ORDERS (VIC)',
      MatterTypeId: '5000012',
      State: 'VIC',
      OsUnique: '63462001',
    },
    {
      Id: '033ab25a-496e-4550-ba3f-50cb5417f388',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000013',
      State: 'TAS',
      OsUnique: '033ab25a-496e-4550-ba3f-50cb5417f388',
    },
    {
      Id: 'd90794cd-21ee-4f6d-bd8f-6314e8e08b24',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000014',
      State: 'TAS',
      OsUnique: 'd90794cd-21ee-4f6d-bd8f-6314e8e08b24',
    },
    {
      Id: 'c0efe575-f805-4139-b21b-424021581652',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000015',
      State: 'TAS',
      OsUnique: 'c0efe575-f805-4139-b21b-424021581652',
    },
    {
      Id: '4fd620a7-08b6-46b3-ba44-ed55595cbd9c',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000016',
      State: 'TAS',
      OsUnique: '4fd620a7-08b6-46b3-ba44-ed55595cbd9c',
    },
    {
      Id: '10f744b9-8f93-43ad-a12f-129f6d40f3c4',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000017',
      State: 'TAS',
      OsUnique: '10f744b9-8f93-43ad-a12f-129f6d40f3c4',
    },
    {
      Id: 'cc2b051d-3a0c-4988-aa89-01056f14b5bd',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000018',
      State: 'TAS',
      OsUnique: 'cc2b051d-3a0c-4988-aa89-01056f14b5bd',
    },
    {
      Id: '3ded77d3-e746-40e6-8e3f-6dcf42fb42bb',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000020',
      State: 'WA',
      OsUnique: '3ded77d3-e746-40e6-8e3f-6dcf42fb42bb',
    },
    {
      Id: '058d9ef6-03dd-43f5-90a4-853a63eb150c',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000037',
      State: 'NT',
      OsUnique: '058d9ef6-03dd-43f5-90a4-853a63eb150c',
    },
    {
      Id: '2069d296-99bc-40df-9b31-66ae1bf20dea',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000038',
      State: 'NT',
      OsUnique: '2069d296-99bc-40df-9b31-66ae1bf20dea',
    },
    {
      Id: '1c35de3b-b758-4e71-90a8-274ce44b86c7',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000039',
      State: 'NT',
      OsUnique: '1c35de3b-b758-4e71-90a8-274ce44b86c7',
    },
    {
      Id: '2093a88f-d075-4d61-a466-c451da554b2c',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000040',
      State: 'NT',
      OsUnique: '2093a88f-d075-4d61-a466-c451da554b2c',
    },
    {
      Id: 'c8855cbe-96cb-47d4-885f-a60da8ba3b6c',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000041',
      State: 'NT',
      OsUnique: 'c8855cbe-96cb-47d4-885f-a60da8ba3b6c',
    },
    {
      Id: '4911f1e6-e5d2-4321-bb8e-5e377161cdcc',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000042',
      State: 'NT',
      OsUnique: '4911f1e6-e5d2-4321-bb8e-5e377161cdcc',
    },
    {
      Id: 'f942353b-869f-427d-a3f6-b54521ebca6d',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000043',
      State: 'NT',
      OsUnique: 'f942353b-869f-427d-a3f6-b54521ebca6d',
    },
    {
      Id: 'f8d7b79e-9c01-4d24-a6c8-0980e3287b91',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000044',
      State: 'NT',
      OsUnique: 'f8d7b79e-9c01-4d24-a6c8-0980e3287b91',
    },
    {
      Id: '022ef536-e67a-465f-bbba-90b6adb0d294',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000045',
      State: 'NT',
      OsUnique: '022ef536-e67a-465f-bbba-90b6adb0d294',
    },
    {
      Id: '63780001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES COURT (QLD)',
      MatterTypeId: '5000046',
      State: 'QLD',
      OsUnique: '63780001',
    },
    {
      Id: '63780001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES COURT (QLD)',
      MatterTypeId: '5000047',
      State: 'QLD',
      OsUnique: '63780001',
    },
    {
      Id: '63780001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES COURT (QLD)',
      MatterTypeId: '5000048',
      State: 'QLD',
      OsUnique: '63780001',
    },
    {
      Id: '63780001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES COURT (QLD)',
      MatterTypeId: '5000049',
      State: 'QLD',
      OsUnique: '63780001',
    },
    {
      Id: '021b942c-b05a-4cb7-9bdf-95a3b9742ae8',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000050',
      State: 'QLD',
      OsUnique: '021b942c-b05a-4cb7-9bdf-95a3b9742ae8',
    },
    {
      Id: '65643001',
      Title: 'FULL COMMENTARY - DOMESTIC VIOLENCE (QLD)',
      MatterTypeId: '5000051',
      State: 'QLD',
      OsUnique: '65643001',
    },
    {
      Id: '63780001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES COURT (QLD)',
      MatterTypeId: '5000051',
      State: 'QLD',
      OsUnique: '63780001',
    },
    {
      Id: '63780001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES COURT (QLD)',
      MatterTypeId: '5000052',
      State: 'QLD',
      OsUnique: '63780001',
    },
    {
      Id: '67586001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - LOCAL COURT (NSW)',
      MatterTypeId: '5000073',
      State: 'NSW',
      OsUnique: '67586001',
    },
    {
      Id: '61577001',
      Title: 'FULL COMMENTARY - CRIMINAL - LOCAL COURT (NSW)',
      MatterTypeId: '5000073',
      State: 'NSW',
      OsUnique: '61577001',
    },
    {
      Id: '61666001',
      Title: 'FULL COMMENTARY - APPREHENDED VIOLENCE ORDER (NSW)',
      MatterTypeId: '5000073',
      State: 'NSW',
      OsUnique: '61666001',
    },
    {
      Id: '67628001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '5000074',
      State: 'VIC',
      OsUnique: '67628001',
    },
    {
      Id: '63382001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '5000074',
      State: 'VIC',
      OsUnique: '63382001',
    },
    {
      Id: '63462001',
      Title: 'FULL COMMENTARY - INTERVENTION ORDERS (VIC)',
      MatterTypeId: '5000074',
      State: 'VIC',
      OsUnique: '63462001',
    },
    {
      Id: '23469ad6-f1e2-41c6-a269-d216b220620e',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000075',
      State: 'TAS',
      OsUnique: '23469ad6-f1e2-41c6-a269-d216b220620e',
    },
    {
      Id: '7647173a-44d0-4925-b583-fdc376cae55c',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000078',
      State: 'NT',
      OsUnique: '7647173a-44d0-4925-b583-fdc376cae55c',
    },
    {
      Id: '63780001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES COURT (QLD)',
      MatterTypeId: '5000079',
      State: 'QLD',
      OsUnique: '63780001',
    },
    {
      Id: 'af9a9785-bd3f-488d-b9c4-58284932103b',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000080',
      State: 'ACT',
      OsUnique: 'af9a9785-bd3f-488d-b9c4-58284932103b',
    },
    {
      Id: '67628001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '5000081',
      State: 'VIC',
      OsUnique: '67628001',
    },
    {
      Id: '63382001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '5000081',
      State: 'VIC',
      OsUnique: '63382001',
    },
    {
      Id: '63462001',
      Title: 'FULL COMMENTARY - INTERVENTION ORDERS (VIC)',
      MatterTypeId: '5000081',
      State: 'VIC',
      OsUnique: '63462001',
    },
    {
      Id: '4407e156-d50f-44c5-b983-c5dc46aaad19',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000082',
      State: 'TAS',
      OsUnique: '4407e156-d50f-44c5-b983-c5dc46aaad19',
    },
    {
      Id: '51a90346-3065-42d0-b87a-b0e03e6f40c7',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000085',
      State: 'NT',
      OsUnique: '51a90346-3065-42d0-b87a-b0e03e6f40c7',
    },
    {
      Id: '63780001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES COURT (QLD)',
      MatterTypeId: '5000086',
      State: 'QLD',
      OsUnique: '63780001',
    },
    {
      Id: 'e1b13a47-0eb9-41c1-b2b6-a73bea9235d0',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5000087',
      State: 'ACT',
      OsUnique: 'e1b13a47-0eb9-41c1-b2b6-a73bea9235d0',
    },
    {
      Id: '67628001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '5000092',
      State: 'VIC',
      OsUnique: '67628001',
    },
    {
      Id: '63382001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '5000092',
      State: 'VIC',
      OsUnique: '63382001',
    },
    {
      Id: '63462001',
      Title: 'FULL COMMENTARY - INTERVENTION ORDERS (VIC)',
      MatterTypeId: '5000092',
      State: 'VIC',
      OsUnique: '63462001',
    },
    {
      Id: '61453001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - LOCAL COURT (NSW)',
      MatterTypeId: '53',
      State: 'NSW',
      OsUnique: '61453001',
    },
    {
      Id: '61383001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - LOCAL COURT (NSW)',
      MatterTypeId: '53',
      State: 'NSW',
      OsUnique: '61383001',
    },
    {
      Id: '71729001',
      Title: 'FULL COMMENTARY - ENFORCEMENT (NSW)',
      MatterTypeId: '53',
      State: 'NSW',
      OsUnique: '71729001',
    },
    {
      Id: '80917001',
      Title: '101 Subpoena Answers',
      MatterTypeId: '53',
      State: 'NSW',
      OsUnique: '80917001',
    },
    {
      Id: '62e54099-57c9-4b11-b31a-1a9c608f3928',
      Title: 'Demonstrating the use of precedents',
      MatterTypeId: '53',
      State: 'NSW',
      OsUnique: '62e54099-57c9-4b11-b31a-1a9c608f3928',
    },
    {
      Id: '134443c4-2165-4de4-a8f5-8aeab3a7cb5d',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '530',
      State: 'ACT',
      OsUnique: '134443c4-2165-4de4-a8f5-8aeab3a7cb5d',
    },
    {
      Id: '63780001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES COURT (QLD)',
      MatterTypeId: '531',
      State: 'QLD',
      OsUnique: '63780001',
    },
    {
      Id: 'b43c77e5-43e2-4569-b08a-eff7b45484a9',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '532',
      State: 'VIC',
      OsUnique: 'b43c77e5-43e2-4569-b08a-eff7b45484a9',
    },
    {
      Id: '63382001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '532',
      State: 'VIC',
      OsUnique: '63382001',
    },
    {
      Id: '67628001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '532',
      State: 'VIC',
      OsUnique: '67628001',
    },
    {
      Id: '63462001',
      Title: 'FULL COMMENTARY - INTERVENTION ORDERS (VIC)',
      MatterTypeId: '532',
      State: 'VIC',
      OsUnique: '63462001',
    },
    {
      Id: '9ccd38be-4946-4f41-a74d-8ca22bd1e32b',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '533',
      State: 'ACT',
      OsUnique: '9ccd38be-4946-4f41-a74d-8ca22bd1e32b',
    },
    {
      Id: '63780001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES COURT (QLD)',
      MatterTypeId: '534',
      State: 'QLD',
      OsUnique: '63780001',
    },
    {
      Id: '63382001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '535',
      State: 'VIC',
      OsUnique: '63382001',
    },
    {
      Id: '67628001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '535',
      State: 'VIC',
      OsUnique: '67628001',
    },
    {
      Id: '63462001',
      Title: 'FULL COMMENTARY - INTERVENTION ORDERS (VIC)',
      MatterTypeId: '535',
      State: 'VIC',
      OsUnique: '63462001',
    },
    {
      Id: 'c53617cb-a32a-4497-80ab-c55f2d8c71cb',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '539',
      State: 'QLD',
      OsUnique: 'c53617cb-a32a-4497-80ab-c55f2d8c71cb',
    },
    {
      Id: '45aa9712-654a-4813-ad84-d8b2f9b5bffb',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '540',
      State: 'VIC',
      OsUnique: '45aa9712-654a-4813-ad84-d8b2f9b5bffb',
    },
    {
      Id: 'bad02d6f-1238-4a72-ba67-7036a4c8482e',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '544',
      State: 'ACT',
      OsUnique: 'bad02d6f-1238-4a72-ba67-7036a4c8482e',
    },
    {
      Id: '61004001',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS AND FRANCHISE (QLD)',
      MatterTypeId: '545',
      State: 'QLD',
      OsUnique: '61004001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '545',
      State: 'QLD',
      OsUnique: '60350001',
    },
    {
      Id: '68691001',
      Title: '1001 Conveyancing Answers (QLD)',
      MatterTypeId: '545',
      State: 'QLD',
      OsUnique: '68691001',
    },
    {
      Id: '60610001',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS AND FRANCHISE (VIC)',
      MatterTypeId: '546',
      State: 'VIC',
      OsUnique: '60610001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '546',
      State: 'VIC',
      OsUnique: '60350001',
    },
    {
      Id: '68692001',
      Title: '1001 Conveyancing Answers (VIC)',
      MatterTypeId: '546',
      State: 'VIC',
      OsUnique: '68692001',
    },
    {
      Id: '25efe874-2ff4-44d9-b56e-6800cb122cd5',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '547',
      State: 'ACT',
      OsUnique: '25efe874-2ff4-44d9-b56e-6800cb122cd5',
    },
    {
      Id: '60980001',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS AND FRANCHISE (QLD)',
      MatterTypeId: '548',
      State: 'QLD',
      OsUnique: '60980001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '548',
      State: 'QLD',
      OsUnique: '60350001',
    },
    {
      Id: '68691001',
      Title: '1001 Conveyancing Answers (QLD)',
      MatterTypeId: '548',
      State: 'QLD',
      OsUnique: '68691001',
    },
    {
      Id: '8e6159d0-9c6c-4d85-b246-1e337f167458',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '548f7a9c-9504-43d7-b0e3-a5cb7db63600',
      State: 'NT',
      OsUnique: '8e6159d0-9c6c-4d85-b246-1e337f167458',
    },
    {
      Id: '60630001',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS AND FRANCHISE (VIC)',
      MatterTypeId: '549',
      State: 'VIC',
      OsUnique: '60630001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '549',
      State: 'VIC',
      OsUnique: '60350001',
    },
    {
      Id: '68692001',
      Title: '1001 Conveyancing Answers (VIC)',
      MatterTypeId: '549',
      State: 'VIC',
      OsUnique: '68692001',
    },
    {
      Id: 'e38e83dc-bf6a-47bd-85c4-943dc983a688',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '621',
      State: 'ACT',
      OsUnique: 'e38e83dc-bf6a-47bd-85c4-943dc983a688',
    },
    {
      Id: 'cf8109a4-8b4c-40b7-83ae-b9a194e8060e',
      Title: 'FULL COMMENTARY - DEFAMATION AND PROTECTING REPUTATION',
      MatterTypeId: '632c3f74-8ffc-49b2-ba94-6b97cafceba3',
      State: 'TAS',
      OsUnique: 'cf8109a4-8b4c-40b7-83ae-b9a194e8060e',
    },
    {
      Id: '68010001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - MAGISTRATES COURT (QLD)',
      MatterTypeId: '634',
      State: 'QLD',
      OsUnique: '68010001',
    },
    {
      Id: '68058001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - MAGISTRATES COURT (QLD)',
      MatterTypeId: '634',
      State: 'QLD',
      OsUnique: '68058001',
    },
    {
      Id: '65643001',
      Title: 'FULL COMMENTARY - DOMESTIC VIOLENCE (QLD)',
      MatterTypeId: '634',
      State: 'QLD',
      OsUnique: '65643001',
    },
    {
      Id: '63226001',
      Title: 'FULL COMMENTARY - QUEENSLAND CIVIL AND ADMINISTRATIVE TRIBUNAL (QLD)',
      MatterTypeId: '634',
      State: 'QLD',
      OsUnique: '63226001',
    },
    {
      Id: '80013001',
      Title: 'FULL COMMENTARY - ENFORCEMENT (QLD)',
      MatterTypeId: '634',
      State: 'QLD',
      OsUnique: '80013001',
    },
    {
      Id: '60871001',
      Title: 'FULL COMMENTARY - PROBATE (QLD)',
      MatterTypeId: '635',
      State: 'QLD',
      OsUnique: '60871001',
    },
    {
      Id: '60912001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (QLD)',
      MatterTypeId: '635',
      State: 'QLD',
      OsUnique: '60912001',
    },
    {
      Id: '60933001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE HEALTH DIRECTIVES (QLD)',
      MatterTypeId: '635',
      State: 'QLD',
      OsUnique: '60933001',
    },
    {
      Id: '60891001',
      Title: 'FULL COMMENTARY - WILLS (QLD)',
      MatterTypeId: '635',
      State: 'QLD',
      OsUnique: '60891001',
    },
    {
      Id: '60912001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (QLD)',
      MatterTypeId: '636',
      State: 'QLD',
      OsUnique: '60912001',
    },
    {
      Id: '60871001',
      Title: 'FULL COMMENTARY - PROBATE (QLD)',
      MatterTypeId: '636',
      State: 'QLD',
      OsUnique: '60871001',
    },
    {
      Id: '60933001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE HEALTH DIRECTIVES (QLD)',
      MatterTypeId: '636',
      State: 'QLD',
      OsUnique: '60933001',
    },
    {
      Id: '60891001',
      Title: 'FULL COMMENTARY - WILLS (QLD)',
      MatterTypeId: '636',
      State: 'QLD',
      OsUnique: '60891001',
    },
    {
      Id: '60912001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (QLD)',
      MatterTypeId: '637',
      State: 'QLD',
      OsUnique: '60912001',
    },
    {
      Id: '60871001',
      Title: 'FULL COMMENTARY - PROBATE (QLD)',
      MatterTypeId: '637',
      State: 'QLD',
      OsUnique: '60871001',
    },
    {
      Id: '60933001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE HEALTH DIRECTIVES (QLD)',
      MatterTypeId: '637',
      State: 'QLD',
      OsUnique: '60933001',
    },
    {
      Id: '60891001',
      Title: 'FULL COMMENTARY - WILLS (QLD)',
      MatterTypeId: '637',
      State: 'QLD',
      OsUnique: '60891001',
    },
    {
      Id: '63717001',
      Title: 'FULL COMMENTARY - FAMILY PROVISION CLAIMS (QLD)',
      MatterTypeId: '638',
      State: 'QLD',
      OsUnique: '63717001',
    },
    {
      Id: '25aba4bb-9a8f-458a-8a26-09de64935e16',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '640',
      State: 'VIC',
      OsUnique: '25aba4bb-9a8f-458a-8a26-09de64935e16',
    },
    {
      Id: '63036001',
      Title: 'FULL COMMENTARY - TAC CLAIMS',
      MatterTypeId: '642',
      State: 'VIC',
      OsUnique: '63036001',
    },
    {
      Id: 'e1a6e103-c9c2-433f-9ca4-8ff16d881262',
      Title: 'FULL COMMENTARY - PERSONAL INJURY (VIC)',
      MatterTypeId: '642',
      State: 'VIC',
      OsUnique: 'e1a6e103-c9c2-433f-9ca4-8ff16d881262',
    },
    {
      Id: '2acd5933-e69e-4e53-899c-a69fdaf5a1c0',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '643',
      State: 'VIC',
      OsUnique: '2acd5933-e69e-4e53-899c-a69fdaf5a1c0',
    },
    {
      Id: 'e1a6e103-c9c2-433f-9ca4-8ff16d881262',
      Title: 'FULL COMMENTARY - PERSONAL INJURY (VIC)',
      MatterTypeId: '644',
      State: 'VIC',
      OsUnique: 'e1a6e103-c9c2-433f-9ca4-8ff16d881262',
    },
    {
      Id: '63036001',
      Title: 'FULL COMMENTARY - TAC CLAIMS',
      MatterTypeId: '644',
      State: 'VIC',
      OsUnique: '63036001',
    },
    {
      Id: '63360001',
      Title: 'FULL COMMENTARY - MOTOR VEHICLE ACCIDENT (QLD)',
      MatterTypeId: '727',
      State: 'QLD',
      OsUnique: '63360001',
    },
    {
      Id: '63083001',
      Title: 'FULL COMMENTARY - PERSONAL INJURY (QLD)',
      MatterTypeId: '727',
      State: 'QLD',
      OsUnique: '63083001',
    },
    {
      Id: '63052001',
      Title: 'FULL COMMENTARY - WORKERS COMPENSATION (QLD)',
      MatterTypeId: '727',
      State: 'QLD',
      OsUnique: '63052001',
    },
    {
      Id: '63083001',
      Title: 'FULL COMMENTARY - PERSONAL INJURY (QLD)',
      MatterTypeId: '729',
      State: 'QLD',
      OsUnique: '63083001',
    },
    {
      Id: '63360001',
      Title: 'FULL COMMENTARY - MOTOR VEHICLE ACCIDENT (QLD)',
      MatterTypeId: '729',
      State: 'QLD',
      OsUnique: '63360001',
    },
    {
      Id: '63052001',
      Title: 'FULL COMMENTARY - WORKERS COMPENSATION (QLD)',
      MatterTypeId: '729',
      State: 'QLD',
      OsUnique: '63052001',
    },
    {
      Id: 'eb656789-5c1e-4e20-91ed-405feaf3c442',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '732',
      State: 'QLD',
      OsUnique: 'eb656789-5c1e-4e20-91ed-405feaf3c442',
    },
    {
      Id: '63052001',
      Title: 'FULL COMMENTARY - WORKERS COMPENSATION (QLD)',
      MatterTypeId: '733',
      State: 'QLD',
      OsUnique: '63052001',
    },
    {
      Id: '63360001',
      Title: 'FULL COMMENTARY - MOTOR VEHICLE ACCIDENT (QLD)',
      MatterTypeId: '733',
      State: 'QLD',
      OsUnique: '63360001',
    },
    {
      Id: '63083001',
      Title: 'FULL COMMENTARY - PERSONAL INJURY (QLD)',
      MatterTypeId: '733',
      State: 'QLD',
      OsUnique: '63083001',
    },
    {
      Id: '68216001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (NSW)',
      MatterTypeId: '74',
      State: 'NSW',
      OsUnique: '68216001',
    },
    {
      Id: '59789001',
      Title: 'FULL COMMENTARY - PROBATE (NSW)',
      MatterTypeId: '74',
      State: 'NSW',
      OsUnique: '59789001',
    },
    {
      Id: '60370001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY, APPOINTMENT OF ENDURING GUARDIAN AND ADVANCE CARE PLANNING (NSW)',
      MatterTypeId: '74',
      State: 'NSW',
      OsUnique: '60370001',
    },
    {
      Id: '59859001',
      Title: 'FULL COMMENTARY - WILLS (NSW)',
      MatterTypeId: '74',
      State: 'NSW',
      OsUnique: '59859001',
    },
    {
      Id: '1f97316f-ab46-4290-8add-2b79499488ca',
      Title: '101 Succession Answers (NSW)',
      MatterTypeId: '74',
      State: 'NSW',
      OsUnique: '1f97316f-ab46-4290-8add-2b79499488ca',
    },
    {
      Id: '8cd14944-31df-4c04-91d9-2386c10e8046',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '747',
      State: 'All',
      OsUnique: '8cd14944-31df-4c04-91d9-2386c10e8046',
    },
    {
      Id: '62166001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (TAS)',
      MatterTypeId: '751',
      State: 'TAS',
      OsUnique: '62166001',
    },
    {
      Id: '62193001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (TAS)',
      MatterTypeId: '751',
      State: 'TAS',
      OsUnique: '62193001',
    },
    {
      Id: '0df736a6-60bc-4bc4-b9a4-a21b1c66ba76',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (WA)',
      MatterTypeId: '752',
      State: 'WA',
      OsUnique: '0df736a6-60bc-4bc4-b9a4-a21b1c66ba76',
    },
    {
      Id: '6299bb82-1d79-431b-8841-aaa7528be68a',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (WA)',
      MatterTypeId: '752',
      State: 'WA',
      OsUnique: '6299bb82-1d79-431b-8841-aaa7528be68a',
    },
    {
      Id: '61166001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (SA)',
      MatterTypeId: '753',
      State: 'SA',
      OsUnique: '61166001',
    },
    {
      Id: '61211001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (SA)',
      MatterTypeId: '753',
      State: 'SA',
      OsUnique: '61211001',
    },
    {
      Id: '61137001',
      Title: 'FULL COMMENTARY - MORTGAGES (SA)',
      MatterTypeId: '753',
      State: 'SA',
      OsUnique: '61137001',
    },
    {
      Id: 'a336c409-e4a1-47a8-9c2a-860d1b1dc3be',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '754',
      State: 'NT',
      OsUnique: 'a336c409-e4a1-47a8-9c2a-860d1b1dc3be',
    },
    {
      Id: '2e2febe6-a164-4ba9-a6e6-6f12afb71905',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '838',
      State: 'QLD',
      OsUnique: '2e2febe6-a164-4ba9-a6e6-6f12afb71905',
    },
    {
      Id: '60224001',
      Title: 'FULL COMMENTARY - INSOLVENCY - BANKRUPTCY OF INDIVIDUALS',
      MatterTypeId: '84',
      State: 'All',
      OsUnique: '60224001',
    },
    {
      Id: '60246001',
      Title: 'FULL COMMENTARY - INSOLVENCY - COMPANY LIQUIDATION',
      MatterTypeId: '84',
      State: 'All',
      OsUnique: '60246001',
    },
    {
      Id: '64390001',
      Title: 'FULL COMMENTARY - LEASES (VIC)',
      MatterTypeId: '840',
      State: 'VIC',
      OsUnique: '64390001',
    },
    {
      Id: '9df4f2ad-4ef2-4d5c-bc78-7c3be82815aa',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '841',
      State: 'NSW',
      OsUnique: '9df4f2ad-4ef2-4d5c-bc78-7c3be82815aa',
    },
    {
      Id: 'a413b9ce-3784-4799-80ba-82eea5d063bb',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '842',
      State: 'NSW',
      OsUnique: 'a413b9ce-3784-4799-80ba-82eea5d063bb',
    },
    {
      Id: '5d19a185-c4cd-49e6-829d-20b65f968f81',
      Title: 'FULL COMMENTARY - RESTRAINING ORDERS (WA)',
      MatterTypeId: '847',
      State: 'WA',
      OsUnique: '5d19a185-c4cd-49e6-829d-20b65f968f81',
    },
    {
      Id: 'bb1aa2ba-0706-4b9c-8123-04cceb805e99',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '847',
      State: 'WA',
      OsUnique: 'bb1aa2ba-0706-4b9c-8123-04cceb805e99',
    },
    {
      Id: '9cacf4a9-25b3-4707-a184-569ee94a2a5b',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '848',
      State: 'WA',
      OsUnique: '9cacf4a9-25b3-4707-a184-569ee94a2a5b',
    },
    {
      Id: '8efc3c3e-cb0c-4efc-8ac8-fa284bef076e',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '849',
      State: 'WA',
      OsUnique: '8efc3c3e-cb0c-4efc-8ac8-fa284bef076e',
    },
    {
      Id: '62712001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY, GUARDIANSHIP AND ADVANCE HEALTH DIRECTIVES (WA)',
      MatterTypeId: '851',
      State: 'WA',
      OsUnique: '62712001',
    },
    {
      Id: '62612001',
      Title: 'FULL COMMENTARY - WILLS (WA)',
      MatterTypeId: '851',
      State: 'WA',
      OsUnique: '62612001',
    },
    {
      Id: '62612001',
      Title: 'FULL COMMENTARY - WILLS (WA)',
      MatterTypeId: '852',
      State: 'WA',
      OsUnique: '62612001',
    },
    {
      Id: '62712001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY, GUARDIANSHIP AND ADVANCE HEALTH DIRECTIVES (WA)',
      MatterTypeId: '852',
      State: 'WA',
      OsUnique: '62712001',
    },
    {
      Id: '62612001',
      Title: 'FULL COMMENTARY - WILLS (WA)',
      MatterTypeId: '853',
      State: 'WA',
      OsUnique: '62612001',
    },
    {
      Id: '62712001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY, GUARDIANSHIP AND ADVANCE HEALTH DIRECTIVES (WA)',
      MatterTypeId: '853',
      State: 'WA',
      OsUnique: '62712001',
    },
    {
      Id: '60159001',
      Title: 'FULL COMMENTARY - COMPANIES',
      MatterTypeId: '868',
      State: 'All',
      OsUnique: '60159001',
    },
    {
      Id: 'f7378987-2293-4f17-b8ea-bb6560d8e206',
      Title: 'FULL COMMENTARY - TRUSTS',
      MatterTypeId: '868',
      State: 'All',
      OsUnique: 'f7378987-2293-4f17-b8ea-bb6560d8e206',
    },
    {
      Id: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
      Title: 'FULL COMMENTARY - PARTNERSHIPS',
      MatterTypeId: '868',
      State: 'All',
      OsUnique: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
    },
    {
      Id: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
      Title: 'FULL COMMENTARY - JOINT VENTURES',
      MatterTypeId: '868',
      State: 'All',
      OsUnique: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
    },
    {
      Id: '60196001',
      Title: 'FULL COMMENTARY - SELF MANAGED SUPERANNUATION FUNDS',
      MatterTypeId: '868',
      State: 'All',
      OsUnique: '60196001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '868',
      State: 'All',
      OsUnique: '60350001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '868',
      State: 'All',
      OsUnique: '59835001',
    },
    {
      Id: '6299bb82-1d79-431b-8841-aaa7528be68a',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (WA)',
      MatterTypeId: '870',
      State: 'WA',
      OsUnique: '6299bb82-1d79-431b-8841-aaa7528be68a',
    },
    {
      Id: '0df736a6-60bc-4bc4-b9a4-a21b1c66ba76',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (WA)',
      MatterTypeId: '870',
      State: 'WA',
      OsUnique: '0df736a6-60bc-4bc4-b9a4-a21b1c66ba76',
    },
    {
      Id: '7c84fae1-31b9-45c4-9491-9053d11dbf19',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '874',
      State: 'WA',
      OsUnique: '7c84fae1-31b9-45c4-9491-9053d11dbf19',
    },
    {
      Id: '77402001',
      Title: 'FULL COMMENTARY - PROBATE (WA)',
      MatterTypeId: '876',
      State: 'WA',
      OsUnique: '77402001',
    },
    {
      Id: '78480001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (WA)',
      MatterTypeId: '876',
      State: 'WA',
      OsUnique: '78480001',
    },
    {
      Id: 'ea13077a-07df-4193-b5d8-dd71c79faf79',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '878',
      State: 'WA',
      OsUnique: 'ea13077a-07df-4193-b5d8-dd71c79faf79',
    },
    {
      Id: '69af4e14-8be1-4042-84d6-1cc5fcc077e2',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '883',
      State: 'QLD',
      OsUnique: '69af4e14-8be1-4042-84d6-1cc5fcc077e2',
    },
    {
      Id: '7765a5b2-8b38-457e-b8f0-d5ca699a317c',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '888',
      State: 'NT',
      OsUnique: '7765a5b2-8b38-457e-b8f0-d5ca699a317c',
    },
    {
      Id: '3e362428-9363-4e7b-a285-3758cac0df85',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '88ebfd10-d172-4568-9e34-93d904572219',
      State: 'QLD',
      OsUnique: '3e362428-9363-4e7b-a285-3758cac0df85',
    },
    {
      Id: '61004001',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS AND FRANCHISE (QLD)',
      MatterTypeId: '88ebfd10-d172-4568-9e34-93d904572219',
      State: 'QLD',
      OsUnique: '61004001',
    },
    {
      Id: '60980001',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS AND FRANCHISE (QLD)',
      MatterTypeId: '88ebfd10-d172-4568-9e34-93d904572219',
      State: 'QLD',
      OsUnique: '60980001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '88ebfd10-d172-4568-9e34-93d904572219',
      State: 'QLD',
      OsUnique: '59835001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '88ebfd10-d172-4568-9e34-93d904572219',
      State: 'QLD',
      OsUnique: '60350001',
    },
    {
      Id: '68691001',
      Title: '1001 Conveyancing Answers (QLD)',
      MatterTypeId: '88ebfd10-d172-4568-9e34-93d904572219',
      State: 'QLD',
      OsUnique: '68691001',
    },
    {
      Id: '60838001',
      Title: 'FULL COMMENTARY - MORTGAGES (QLD)',
      MatterTypeId: '88ebfd10-d172-4568-9e34-93d904572219',
      State: 'QLD',
      OsUnique: '60838001',
    },
    {
      Id: '64464001',
      Title: 'FULL COMMENTARY - LEASES (QLD)',
      MatterTypeId: '88ebfd10-d172-4568-9e34-93d904572219',
      State: 'QLD',
      OsUnique: '64464001',
    },
    {
      Id: '64152001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (QLD)',
      MatterTypeId: '88ebfd10-d172-4568-9e34-93d904572219',
      State: 'QLD',
      OsUnique: '64152001',
    },
    {
      Id: '89d5c926-4bbf-45fe-bbf3-baf3df23c028',
      Title: 'FULL COMMENTARY - DEFAMATION AND PROTECTING REPUTATION',
      MatterTypeId: '88f179df-3f93-4a72-be50-b80c9f854e62',
      State: 'QLD',
      OsUnique: '89d5c926-4bbf-45fe-bbf3-baf3df23c028',
    },
    {
      Id: 'af97e9fa-7fa1-4173-8f46-4df218051b35',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '890',
      State: 'NSW',
      OsUnique: 'af97e9fa-7fa1-4173-8f46-4df218051b35',
    },
    {
      Id: '61788001',
      Title: 'FULL COMMENTARY - SECURITY OF PAYMENTS - BUILDING AND CONSTRUCTION INDUSTRY',
      MatterTypeId: '890',
      State: 'NSW',
      OsUnique: '61788001',
    },
    {
      Id: '8694f319-ba33-4d16-9021-709d49f4f67e',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '896',
      State: 'QLD',
      OsUnique: '8694f319-ba33-4d16-9021-709d49f4f67e',
    },
    {
      Id: 'fe1114df-2a61-4c4e-bb38-ccd1d0ec3a99',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '899',
      State: 'QLD',
      OsUnique: 'fe1114df-2a61-4c4e-bb38-ccd1d0ec3a99',
    },
    {
      Id: '8ebf17b9-e26e-4ee3-b3ac-c55143a917ab',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '8d58217f-a00c-45cf-adb4-df49f9c4d2ab',
      State: 'NT',
      OsUnique: '8ebf17b9-e26e-4ee3-b3ac-c55143a917ab',
    },
    {
      Id: '8f36d82e-a963-4a07-962a-39c3b77244d2',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '900',
      State: 'QLD',
      OsUnique: '8f36d82e-a963-4a07-962a-39c3b77244d2',
    },
    {
      Id: '62193001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (TAS)',
      MatterTypeId: '902',
      State: 'TAS',
      OsUnique: '62193001',
    },
    {
      Id: '62166001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (TAS)',
      MatterTypeId: '902',
      State: 'TAS',
      OsUnique: '62166001',
    },
    {
      Id: '59766001',
      Title: 'FULL COMMENTARY - FINANCIAL AGREEMENTS',
      MatterTypeId: '907',
      State: 'All',
      OsUnique: '59766001',
    },
    {
      Id: '60266001',
      Title: 'FULL COMMENTARY - DIVORCE',
      MatterTypeId: '907',
      State: 'All',
      OsUnique: '60266001',
    },
    {
      Id: '59694001',
      Title: 'FULL COMMENTARY - PROPERTY SETTLEMENT',
      MatterTypeId: '907',
      State: 'All',
      OsUnique: '59694001',
    },
    {
      Id: '81562001',
      Title: 'FULL COMMENTARY - CHILDREN',
      MatterTypeId: '907',
      State: 'All',
      OsUnique: '81562001',
    },
    {
      Id: '65061001',
      Title: '101 Family Law Answers',
      MatterTypeId: '907',
      State: 'All',
      OsUnique: '65061001',
    },
    {
      Id: '80917001',
      Title: '101 Subpoena Answers',
      MatterTypeId: '907',
      State: 'All',
      OsUnique: '80917001',
    },
    {
      Id: 'f7a5be6b-a25b-45b0-a023-407f9fc129e1',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '908',
      State: 'ACT',
      OsUnique: 'f7a5be6b-a25b-45b0-a023-407f9fc129e1',
    },
    {
      Id: '0ad51e7a-cad0-48c5-a8e7-6d76715682f5',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '916',
      State: 'ACT',
      OsUnique: '0ad51e7a-cad0-48c5-a8e7-6d76715682f5',
    },
    {
      Id: '65348001',
      Title: 'FULL COMMENTARY - EMPLOYMENT LAW',
      MatterTypeId: '919',
      State: 'All',
      OsUnique: '65348001',
    },
    {
      Id: '59634001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (NSW)',
      MatterTypeId: 'a8e0e666-2098-476f-a40a-d263a40e066f',
      State: 'NSW',
      OsUnique: '59634001',
    },
    {
      Id: '68689001',
      Title: '1001 Conveyancing Answers (NSW)',
      MatterTypeId: 'a8e0e666-2098-476f-a40a-d263a40e066f',
      State: 'NSW',
      OsUnique: '68689001',
    },
    {
      Id: '60382001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (NSW)',
      MatterTypeId: 'a8e0e666-2098-476f-a40a-d263a40e066f',
      State: 'NSW',
      OsUnique: '60382001',
    },
    {
      Id: '68323001',
      Title: 'FULL COMMENTARY - MORTGAGES (NSW)',
      MatterTypeId: 'a8e0e666-2098-476f-a40a-d263a40e066f',
      State: 'NSW',
      OsUnique: '68323001',
    },
    {
      Id: '15facd93-23fc-4e76-8fe8-9957f5ce2aef',
      Title: 'FULL COMMENTARY - RETIREMENT VILLAGES NSW',
      MatterTypeId: 'a8e0e666-2098-476f-a40a-d263a40e066f',
      State: 'NSW',
      OsUnique: '15facd93-23fc-4e76-8fe8-9957f5ce2aef',
    },
    {
      Id: 'ded03ad5-23e4-4c97-a0f7-6b272780307c',
      Title: 'FULL COMMENTARY - DEFAMATION AND PROTECTING REPUTATION',
      MatterTypeId: 'b0d1a358-7bd3-4f9f-b181-d9c3d41c015d',
      State: 'VIC',
      OsUnique: 'ded03ad5-23e4-4c97-a0f7-6b272780307c',
    },
    {
      Id: 'd0b4941e-413e-448c-b2aa-24f408c8638e',
      Title: 'FULL COMMENTARY - DEFAMATION AND PROTECTING REPUTATION',
      MatterTypeId: 'b67261d3-5860-4238-a440-76554f1e7ddf',
      State: 'WA',
      OsUnique: 'd0b4941e-413e-448c-b2aa-24f408c8638e',
    },
    {
      Id: 'fdbb3075-516f-4eed-af72-184775851efb',
      Title: 'FULL COMMENTARY - DEFAMATION AND PROTECTING REPUTATION',
      MatterTypeId: 'c0f1d5e4-afd4-4690-a57d-bf9daeffa9c3',
      State: 'ACT',
      OsUnique: 'fdbb3075-516f-4eed-af72-184775851efb',
    },
    {
      Id: '64561001',
      Title: 'FULL COMMENTARY - NEIGHBOURHOOD DISPUTES (VIC)',
      MatterTypeId: 'c1baedad-11e2-4344-a25b-aa525fe22146',
      State: 'VIC',
      OsUnique: '64561001',
    },
    {
      Id: '63462001',
      Title: 'FULL COMMENTARY - INTERVENTION ORDERS (VIC)',
      MatterTypeId: 'c1baedad-11e2-4344-a25b-aa525fe22146',
      State: 'VIC',
      OsUnique: '63462001',
    },
    {
      Id: '62439001',
      Title: 'FULL COMMENTARY - ACTING FOR THE PLAINTIFF - MAGISTRATES\' COURT CLAIMS UNDER $100,000 (VIC)',
      MatterTypeId: 'c1baedad-11e2-4344-a25b-aa525fe22146',
      State: 'VIC',
      OsUnique: '62439001',
    },
    {
      Id: '62471001',
      Title: 'FULL COMMENTARY - ACTING FOR THE DEFENDANT - MAGISTRATES\' COURT CLAIMS UNDER $100,000 (VIC)',
      MatterTypeId: 'c1baedad-11e2-4344-a25b-aa525fe22146',
      State: 'VIC',
      OsUnique: '62471001',
    },
    {
      Id: 'e8cba23c-191c-4ecf-879a-4c0c6e2df7aa',
      Title: 'FULL COMMENTARY - DEFAMATION AND PROTECTING REPUTATION',
      MatterTypeId: 'c4de9596-a361-49cb-813c-e994a594e450',
      State: 'NT',
      OsUnique: 'e8cba23c-191c-4ecf-879a-4c0c6e2df7aa',
    },
    {
      Id: '74495001',
      Title: 'FULL COMMENTARY - DEFAMATION AND PROTECTING REPUTATION',
      MatterTypeId: 'c96010ad-e7b7-45e7-9bba-789178dc8676',
      State: 'NSW',
      OsUnique: '74495001',
    },
    {
      Id: '60382001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (NSW)',
      MatterTypeId: 'cef30ab4-7c5f-4df3-afed-e91626e1c38f',
      State: 'NSW',
      OsUnique: '60382001',
    },
    {
      Id: '68689001',
      Title: '1001 Conveyancing Answers (NSW)',
      MatterTypeId: 'cef30ab4-7c5f-4df3-afed-e91626e1c38f',
      State: 'NSW',
      OsUnique: '68689001',
    },
    {
      Id: '59634001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (NSW)',
      MatterTypeId: 'cef30ab4-7c5f-4df3-afed-e91626e1c38f',
      State: 'NSW',
      OsUnique: '59634001',
    },
    {
      Id: '68323001',
      Title: 'FULL COMMENTARY - MORTGAGES (NSW)',
      MatterTypeId: 'cef30ab4-7c5f-4df3-afed-e91626e1c38f',
      State: 'NSW',
      OsUnique: '68323001',
    },
    {
      Id: '15facd93-23fc-4e76-8fe8-9957f5ce2aef',
      Title: 'FULL COMMENTARY - RETIREMENT VILLAGES NSW',
      MatterTypeId: 'cef30ab4-7c5f-4df3-afed-e91626e1c38f',
      State: 'NSW',
      OsUnique: '15facd93-23fc-4e76-8fe8-9957f5ce2aef',
    },
    {
      Id: '2cbc88f3-16a8-47dc-8075-989424c1920c',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: 'ddff8cc3-ef1d-4aa2-981f-8a901b5c4bfe',
      State: 'NT',
      OsUnique: '2cbc88f3-16a8-47dc-8075-989424c1920c',
    },
    {
      Id: '60564001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (VIC)',
      MatterTypeId: 'e43c1e90-ba1b-4850-a961-048343659181',
      State: 'VIC',
      OsUnique: '60564001',
    },
    {
      Id: '60530001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (VIC)',
      MatterTypeId: 'e43c1e90-ba1b-4850-a961-048343659181',
      State: 'VIC',
      OsUnique: '60530001',
    },
    {
      Id: '60780001',
      Title: 'FULL COMMENTARY - MORTGAGES (VIC)',
      MatterTypeId: 'e43c1e90-ba1b-4850-a961-048343659181',
      State: 'VIC',
      OsUnique: '60780001',
    },
    {
      Id: '68692001',
      Title: '1001 Conveyancing Answers (VIC)',
      MatterTypeId: 'e43c1e90-ba1b-4850-a961-048343659181',
      State: 'VIC',
      OsUnique: '68692001',
    },
    {
      Id: '2b9409aa-af8b-470d-a4a7-4c8dcc3ad3a0',
      Title: '101 Section 32 Answers (VIC)',
      MatterTypeId: 'e43c1e90-ba1b-4850-a961-048343659181',
      State: 'VIC',
      OsUnique: '2b9409aa-af8b-470d-a4a7-4c8dcc3ad3a0',
    },
    {
      Id: '057477e9-c8b7-4869-a7ae-ec76a7f30687',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: 'e81da496-c7d9-4062-9795-97ac40ed7046',
      State: 'QLD',
      OsUnique: '057477e9-c8b7-4869-a7ae-ec76a7f30687',
    },
    {
      Id: '64191001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (QLD)',
      MatterTypeId: 'e81da496-c7d9-4062-9795-97ac40ed7046',
      State: 'QLD',
      OsUnique: '64191001',
    },
    {
      Id: '64152001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (QLD)',
      MatterTypeId: 'e81da496-c7d9-4062-9795-97ac40ed7046',
      State: 'QLD',
      OsUnique: '64152001',
    },
    {
      Id: '64585001',
      Title: 'FULL COMMENTARY - NEIGHBOURHOOD DISPUTES (WA)',
      MatterTypeId: 'f7bc02d3-61de-4850-8951-7ae280e5fd0e',
      State: 'WA',
      OsUnique: '64585001',
    },
    {
      Id: 'ca19499d-a447-409a-aab7-3c036aee4a5f',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '665',
      State: 'ACT',
      OsUnique: 'ca19499d-a447-409a-aab7-3c036aee4a5f',
    },
    {
      Id: 'b140d508-2155-4d80-a13a-f3ad7f10d8af',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '666',
      State: 'ACT',
      OsUnique: 'b140d508-2155-4d80-a13a-f3ad7f10d8af',
    },
    {
      Id: '84c9089c-3a65-493e-a56c-524b5fec770f',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '667',
      State: 'ACT',
      OsUnique: '84c9089c-3a65-493e-a56c-524b5fec770f',
    },
    {
      Id: '82bffcdb-e21c-4513-bcfd-5273aa3135a6',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '668',
      State: 'ACT',
      OsUnique: '82bffcdb-e21c-4513-bcfd-5273aa3135a6',
    },
    {
      Id: '1d0cc956-24a9-4505-93e5-b0d80d921106',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '669',
      State: 'ACT',
      OsUnique: '1d0cc956-24a9-4505-93e5-b0d80d921106',
    },
    {
      Id: '80b66179-970e-44f2-bfdc-4bd838c69d69',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '670',
      State: 'ACT',
      OsUnique: '80b66179-970e-44f2-bfdc-4bd838c69d69',
    },
    {
      Id: '95c308c6-4fca-4c4b-a9fe-b0ae791ad548',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '671',
      State: 'ACT',
      OsUnique: '95c308c6-4fca-4c4b-a9fe-b0ae791ad548',
    },
    {
      Id: '806cb4da-518d-436c-9bbb-2251135ef235',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '672',
      State: 'ACT',
      OsUnique: '806cb4da-518d-436c-9bbb-2251135ef235',
    },
    {
      Id: '391dff50-b6a2-45bd-aa73-5e828e0b2340',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '673',
      State: 'ACT',
      OsUnique: '391dff50-b6a2-45bd-aa73-5e828e0b2340',
    },
    {
      Id: 'efa88338-8627-4009-ad83-b7e9702a204d',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '687',
      State: 'QLD',
      OsUnique: 'efa88338-8627-4009-ad83-b7e9702a204d',
    },
    {
      Id: '60370001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY, APPOINTMENT OF ENDURING GUARDIAN AND ADVANCE CARE PLANNING (NSW)',
      MatterTypeId: '689',
      State: 'NSW',
      OsUnique: '60370001',
    },
    {
      Id: '59859001',
      Title: 'FULL COMMENTARY - WILLS (NSW)',
      MatterTypeId: '689',
      State: 'NSW',
      OsUnique: '59859001',
    },
    {
      Id: '59789001',
      Title: 'FULL COMMENTARY - PROBATE (NSW)',
      MatterTypeId: '689',
      State: 'NSW',
      OsUnique: '59789001',
    },
    {
      Id: '64338001',
      Title: 'FULL COMMENTARY - LEASES (NSW)',
      MatterTypeId: '69',
      State: 'NSW',
      OsUnique: '64338001',
    },
    {
      Id: '95e21342-cdac-4ede-a643-bdbdbdde8270',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '693',
      State: 'All',
      OsUnique: '95e21342-cdac-4ede-a643-bdbdbdde8270',
    },
    {
      Id: 'e88ba197-414d-4a8a-830c-f4c2b31f1f5d',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '699',
      State: 'All',
      OsUnique: 'e88ba197-414d-4a8a-830c-f4c2b31f1f5d',
    },
    {
      Id: '61577001',
      Title: 'FULL COMMENTARY - CRIMINAL - LOCAL COURT (NSW)',
      MatterTypeId: '709',
      State: 'NSW',
      OsUnique: '61577001',
    },
    {
      Id: '67586001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - LOCAL COURT (NSW)',
      MatterTypeId: '709',
      State: 'NSW',
      OsUnique: '67586001',
    },
    {
      Id: '61666001',
      Title: 'FULL COMMENTARY - APPREHENDED VIOLENCE ORDER (NSW)',
      MatterTypeId: '709',
      State: 'NSW',
      OsUnique: '61666001',
    },
    {
      Id: '64338001',
      Title: 'FULL COMMENTARY - LEASES (NSW)',
      MatterTypeId: '710',
      State: 'NSW',
      OsUnique: '64338001',
    },
    {
      Id: '04896923-dbfd-4d52-9b83-59fd34d6d1b3',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '711',
      State: 'VIC',
      OsUnique: '04896923-dbfd-4d52-9b83-59fd34d6d1b3',
    },
    {
      Id: '60159001',
      Title: 'FULL COMMENTARY - COMPANIES',
      MatterTypeId: '719',
      State: 'All',
      OsUnique: '60159001',
    },
    {
      Id: 'f7378987-2293-4f17-b8ea-bb6560d8e206',
      Title: 'FULL COMMENTARY - TRUSTS',
      MatterTypeId: '719',
      State: 'All',
      OsUnique: 'f7378987-2293-4f17-b8ea-bb6560d8e206',
    },
    {
      Id: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
      Title: 'FULL COMMENTARY - PARTNERSHIPS',
      MatterTypeId: '719',
      State: 'All',
      OsUnique: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
    },
    {
      Id: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
      Title: 'FULL COMMENTARY - JOINT VENTURES',
      MatterTypeId: '719',
      State: 'All',
      OsUnique: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
    },
    {
      Id: '60196001',
      Title: 'FULL COMMENTARY - SELF MANAGED SUPERANNUATION FUNDS',
      MatterTypeId: '719',
      State: 'All',
      OsUnique: '60196001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '719',
      State: 'All',
      OsUnique: '60350001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '719',
      State: 'All',
      OsUnique: '59835001',
    },
    {
      Id: '59789001',
      Title: 'FULL COMMENTARY - PROBATE (NSW)',
      MatterTypeId: '72',
      State: 'NSW',
      OsUnique: '59789001',
    },
    {
      Id: '68216001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (NSW)',
      MatterTypeId: '72',
      State: 'NSW',
      OsUnique: '68216001',
    },
    {
      Id: '60370001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY, APPOINTMENT OF ENDURING GUARDIAN AND ADVANCE CARE PLANNING (NSW)',
      MatterTypeId: '72',
      State: 'NSW',
      OsUnique: '60370001',
    },
    {
      Id: '59859001',
      Title: 'FULL COMMENTARY - WILLS (NSW)',
      MatterTypeId: '72',
      State: 'NSW',
      OsUnique: '59859001',
    },
    {
      Id: '1f97316f-ab46-4290-8add-2b79499488ca',
      Title: '101 Succession Answers (NSW)',
      MatterTypeId: '72',
      State: 'NSW',
      OsUnique: '1f97316f-ab46-4290-8add-2b79499488ca',
    },
    {
      Id: '60159001',
      Title: 'FULL COMMENTARY - COMPANIES',
      MatterTypeId: '720',
      State: 'All',
      OsUnique: '60159001',
    },
    {
      Id: 'f7378987-2293-4f17-b8ea-bb6560d8e206',
      Title: 'FULL COMMENTARY - TRUSTS',
      MatterTypeId: '720',
      State: 'All',
      OsUnique: 'f7378987-2293-4f17-b8ea-bb6560d8e206',
    },
    {
      Id: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
      Title: 'FULL COMMENTARY - PARTNERSHIPS',
      MatterTypeId: '720',
      State: 'All',
      OsUnique: '98938c2c-966b-43c1-a935-d9fe4c2bd027',
    },
    {
      Id: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
      Title: 'FULL COMMENTARY - JOINT VENTURES',
      MatterTypeId: '720',
      State: 'All',
      OsUnique: 'fe429c9c-b3b9-40e0-83db-5f9ddc694e78',
    },
    {
      Id: '60196001',
      Title: 'FULL COMMENTARY - SELF MANAGED SUPERANNUATION FUNDS',
      MatterTypeId: '720',
      State: 'All',
      OsUnique: '60196001',
    },
    {
      Id: '60350001',
      Title: 'FULL COMMENTARY - TRADE MARKS FOR BUSINESS',
      MatterTypeId: '720',
      State: 'All',
      OsUnique: '60350001',
    },
    {
      Id: '59835001',
      Title: 'FULL COMMENTARY - PERSONAL PROPERTY SECURITIES',
      MatterTypeId: '720',
      State: 'All',
      OsUnique: '59835001',
    },
    {
      Id: 'b37a62ad-fe33-462d-ad27-1a7e55abca69',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '721',
      State: 'ACT',
      OsUnique: 'b37a62ad-fe33-462d-ad27-1a7e55abca69',
    },
    {
      Id: 'b9682dee-3449-4933-854c-cd422b3ce758',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '722',
      State: 'ACT',
      OsUnique: 'b9682dee-3449-4933-854c-cd422b3ce758',
    },
    {
      Id: '81999ead-ec32-4844-8509-2cfaf561273b',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '762',
      State: 'VIC',
      OsUnique: '81999ead-ec32-4844-8509-2cfaf561273b',
    },
    {
      Id: '663d1575-0e68-41ad-a4e4-7c503d8fabdf',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '770',
      State: 'QLD',
      OsUnique: '663d1575-0e68-41ad-a4e4-7c503d8fabdf',
    },
    {
      Id: '64338001',
      Title: 'FULL COMMENTARY - LEASES (NSW)',
      MatterTypeId: '773',
      State: 'NSW',
      OsUnique: '64338001',
    },
    {
      Id: '64464001',
      Title: 'FULL COMMENTARY - LEASES (QLD)',
      MatterTypeId: '774',
      State: 'QLD',
      OsUnique: '64464001',
    },
    {
      Id: '60755001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (VIC)',
      MatterTypeId: '779',
      State: 'VIC',
      OsUnique: '60755001',
    },
    {
      Id: '68266001',
      Title: 'FULL COMMENTARY - PROBATE (VIC)',
      MatterTypeId: '779',
      State: 'VIC',
      OsUnique: '68266001',
    },
    {
      Id: '60673001',
      Title: 'FULL COMMENTARY - POWERS & ADVANCE CARE DIRECTIVES (VIC)',
      MatterTypeId: '779',
      State: 'VIC',
      OsUnique: '60673001',
    },
    {
      Id: '67685001',
      Title: 'FULL COMMENTARY - WILLS (VIC)',
      MatterTypeId: '779',
      State: 'VIC',
      OsUnique: '67685001',
    },
    {
      Id: 'c9f9e6ec-86af-4b78-884d-1dd25d4c3147',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '785',
      State: 'QLD',
      OsUnique: 'c9f9e6ec-86af-4b78-884d-1dd25d4c3147',
    },
    {
      Id: '9ca825cc-2c4f-457a-91dd-5617c6a403f8',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '796',
      State: 'ACT',
      OsUnique: '9ca825cc-2c4f-457a-91dd-5617c6a403f8',
    },
    {
      Id: 'a9f0a519-8cdc-4bcd-95ac-ba3f01e57123',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '7b6dcf97-c34f-4a65-b5a1-45dae6a2edba',
      State: 'NT',
      OsUnique: 'a9f0a519-8cdc-4bcd-95ac-ba3f01e57123',
    },
    {
      Id: '1f17b613-ef91-4985-bbe3-0c59f5a88a0e',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '7da9891e-b06a-4516-9893-796f39720838',
      State: 'QLD',
      OsUnique: '1f17b613-ef91-4985-bbe3-0c59f5a88a0e',
    },
    {
      Id: '64152001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (QLD)',
      MatterTypeId: '7da9891e-b06a-4516-9893-796f39720838',
      State: 'QLD',
      OsUnique: '64152001',
    },
    {
      Id: '64191001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (QLD)',
      MatterTypeId: '7da9891e-b06a-4516-9893-796f39720838',
      State: 'QLD',
      OsUnique: '64191001',
    },
    {
      Id: '68691001',
      Title: '1001 Conveyancing Answers (QLD)',
      MatterTypeId: '7da9891e-b06a-4516-9893-796f39720838',
      State: 'QLD',
      OsUnique: '68691001',
    },
    {
      Id: 'dee9b8a5-bb57-42e1-9215-fce2e616c2c9',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '800',
      State: 'ACT',
      OsUnique: 'dee9b8a5-bb57-42e1-9215-fce2e616c2c9',
    },
    {
      Id: 'ba8eae22-3c7f-4566-b3dc-6a414a8373a6',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '801',
      State: 'ACT',
      OsUnique: 'ba8eae22-3c7f-4566-b3dc-6a414a8373a6',
    },
    {
      Id: '68216001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (NSW)',
      MatterTypeId: '802',
      State: 'NSW',
      OsUnique: '68216001',
    },
    {
      Id: '59789001',
      Title: 'FULL COMMENTARY - PROBATE (NSW)',
      MatterTypeId: '802',
      State: 'NSW',
      OsUnique: '59789001',
    },
    {
      Id: '60370001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY, APPOINTMENT OF ENDURING GUARDIAN AND ADVANCE CARE PLANNING (NSW)',
      MatterTypeId: '802',
      State: 'NSW',
      OsUnique: '60370001',
    },
    {
      Id: '59859001',
      Title: 'FULL COMMENTARY - WILLS (NSW)',
      MatterTypeId: '802',
      State: 'NSW',
      OsUnique: '59859001',
    },
    {
      Id: '68266001',
      Title: 'FULL COMMENTARY - PROBATE (VIC)',
      MatterTypeId: '803',
      State: 'VIC',
      OsUnique: '68266001',
    },
    {
      Id: '60755001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (VIC)',
      MatterTypeId: '803',
      State: 'VIC',
      OsUnique: '60755001',
    },
    {
      Id: '60673001',
      Title: 'FULL COMMENTARY - POWERS & ADVANCE CARE DIRECTIVES (VIC)',
      MatterTypeId: '803',
      State: 'VIC',
      OsUnique: '60673001',
    },
    {
      Id: '67685001',
      Title: 'FULL COMMENTARY - WILLS (VIC)',
      MatterTypeId: '803',
      State: 'VIC',
      OsUnique: '67685001',
    },
    {
      Id: '60912001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (QLD)',
      MatterTypeId: '807',
      State: 'QLD',
      OsUnique: '60912001',
    },
    {
      Id: '60871001',
      Title: 'FULL COMMENTARY - PROBATE (QLD)',
      MatterTypeId: '807',
      State: 'QLD',
      OsUnique: '60871001',
    },
    {
      Id: '60933001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE HEALTH DIRECTIVES (QLD)',
      MatterTypeId: '807',
      State: 'QLD',
      OsUnique: '60933001',
    },
    {
      Id: '60891001',
      Title: 'FULL COMMENTARY - WILLS (QLD)',
      MatterTypeId: '807',
      State: 'QLD',
      OsUnique: '60891001',
    },
    {
      Id: '59789001',
      Title: 'FULL COMMENTARY - PROBATE (NSW)',
      MatterTypeId: '81',
      State: 'NSW',
      OsUnique: '59789001',
    },
    {
      Id: '68216001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (NSW)',
      MatterTypeId: '81',
      State: 'NSW',
      OsUnique: '68216001',
    },
    {
      Id: '60370001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY, APPOINTMENT OF ENDURING GUARDIAN AND ADVANCE CARE PLANNING (NSW)',
      MatterTypeId: '81',
      State: 'NSW',
      OsUnique: '60370001',
    },
    {
      Id: '59859001',
      Title: 'FULL COMMENTARY - WILLS (NSW)',
      MatterTypeId: '81',
      State: 'NSW',
      OsUnique: '59859001',
    },
    {
      Id: '4dae2f15-459a-41de-9608-917045ccbb8f',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '811',
      State: 'VIC',
      OsUnique: '4dae2f15-459a-41de-9608-917045ccbb8f',
    },
    {
      Id: '488f72c1-c5e2-4b97-b0d8-d34410f18017',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '812',
      State: 'VIC',
      OsUnique: '488f72c1-c5e2-4b97-b0d8-d34410f18017',
    },
    {
      Id: 'b4fb192b-99da-4324-a5a0-7902e95731a5',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '813',
      State: 'VIC',
      OsUnique: 'b4fb192b-99da-4324-a5a0-7902e95731a5',
    },
    {
      Id: '6b5b05ef-8244-4df0-b9b4-61f092da3229',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '814',
      State: 'VIC',
      OsUnique: '6b5b05ef-8244-4df0-b9b4-61f092da3229',
    },
    {
      Id: '9a7e8d2e-1b3e-4549-a2c6-f17d267a1ae7',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '815',
      State: 'VIC',
      OsUnique: '9a7e8d2e-1b3e-4549-a2c6-f17d267a1ae7',
    },
    {
      Id: '64338001',
      Title: 'FULL COMMENTARY - LEASES (NSW)',
      MatterTypeId: '82',
      State: 'NSW',
      OsUnique: '64338001',
    },
    {
      Id: '68689001',
      Title: '1001 Conveyancing Answers (NSW)',
      MatterTypeId: '82',
      State: 'NSW',
      OsUnique: '68689001',
    },
    {
      Id: '98f97631-84f2-4d89-87be-aeaa5fe32300',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '820',
      State: 'VIC',
      OsUnique: '98f97631-84f2-4d89-87be-aeaa5fe32300',
    },
    {
      Id: '2c4d5100-258e-4dd9-950b-1dafa6bf81ec',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '825',
      State: 'NT',
      OsUnique: '2c4d5100-258e-4dd9-950b-1dafa6bf81ec',
    },
    {
      Id: 'b448cdd2-79c6-490c-bdcd-831db636dd94',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '826',
      State: 'NT',
      OsUnique: 'b448cdd2-79c6-490c-bdcd-831db636dd94',
    },
    {
      Id: '09d3b0aa-791b-49e7-91de-fe4c8fdc3214',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '827',
      State: 'NT',
      OsUnique: '09d3b0aa-791b-49e7-91de-fe4c8fdc3214',
    },
    {
      Id: '90c11c25-f2a3-4a7f-9488-9130bac24bda',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '828',
      State: 'NT',
      OsUnique: '90c11c25-f2a3-4a7f-9488-9130bac24bda',
    },
    {
      Id: '65348001',
      Title: 'FULL COMMENTARY - EMPLOYMENT LAW',
      MatterTypeId: '923',
      State: 'All',
      OsUnique: '65348001',
    },
    {
      Id: '60370001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY, APPOINTMENT OF ENDURING GUARDIAN AND ADVANCE CARE PLANNING (NSW)',
      MatterTypeId: '925',
      State: 'NSW',
      OsUnique: '60370001',
    },
    {
      Id: '59859001',
      Title: 'FULL COMMENTARY - WILLS (NSW)',
      MatterTypeId: '925',
      State: 'NSW',
      OsUnique: '59859001',
    },
    {
      Id: '59789001',
      Title: 'FULL COMMENTARY - PROBATE (NSW)',
      MatterTypeId: '925',
      State: 'NSW',
      OsUnique: '59789001',
    },
    {
      Id: '63462001',
      Title: 'FULL COMMENTARY - INTERVENTION ORDERS (VIC)',
      MatterTypeId: '931',
      State: 'VIC',
      OsUnique: '63462001',
    },
    {
      Id: '63382001',
      Title: 'FULL COMMENTARY - CRIMINAL - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '931',
      State: 'VIC',
      OsUnique: '63382001',
    },
    {
      Id: '67628001',
      Title: 'FULL COMMENTARY - TRAFFIC OFFENCES - MAGISTRATES\' COURT (VIC)',
      MatterTypeId: '931',
      State: 'VIC',
      OsUnique: '67628001',
    },
    {
      Id: 'fb3d7592-9ced-49ad-9cf0-d20aaadfa632',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '949',
      State: 'WA',
      OsUnique: 'fb3d7592-9ced-49ad-9cf0-d20aaadfa632',
    },
    {
      Id: 'c3bf7e5c-40b6-4de0-8689-d1092a16881a',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '954',
      State: 'SA',
      OsUnique: 'c3bf7e5c-40b6-4de0-8689-d1092a16881a',
    },
    {
      Id: '3672ec16-b6c4-44fd-8c17-50ad4a5aa71a',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '955',
      State: 'SA',
      OsUnique: '3672ec16-b6c4-44fd-8c17-50ad4a5aa71a',
    },
    {
      Id: '8aebed86-efe9-434b-956c-13db42512e41',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '956',
      State: 'SA',
      OsUnique: '8aebed86-efe9-434b-956c-13db42512e41',
    },
    {
      Id: '6a1fc498-9521-4575-a21e-b7669f1fdab1',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '957',
      State: 'SA',
      OsUnique: '6a1fc498-9521-4575-a21e-b7669f1fdab1',
    },
    {
      Id: 'a0de2e10-2767-490e-97c5-7b83dd50d199',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '959',
      State: 'SA',
      OsUnique: 'a0de2e10-2767-490e-97c5-7b83dd50d199',
    },
    {
      Id: '59859001',
      Title: 'FULL COMMENTARY - WILLS (NSW)',
      MatterTypeId: '96',
      State: 'NSW',
      OsUnique: '59859001',
    },
    {
      Id: '60370001',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY, APPOINTMENT OF ENDURING GUARDIAN AND ADVANCE CARE PLANNING (NSW)',
      MatterTypeId: '96',
      State: 'NSW',
      OsUnique: '60370001',
    },
    {
      Id: '59789001',
      Title: 'FULL COMMENTARY - PROBATE (NSW)',
      MatterTypeId: '96',
      State: 'NSW',
      OsUnique: '59789001',
    },
    {
      Id: '68216001',
      Title: 'FULL COMMENTARY - LETTERS OF ADMINISTRATION (NSW)',
      MatterTypeId: '96',
      State: 'NSW',
      OsUnique: '68216001',
    },
    {
      Id: '1f97316f-ab46-4290-8add-2b79499488ca',
      Title: '101 Succession Answers (NSW)',
      MatterTypeId: '96',
      State: 'NSW',
      OsUnique: '1f97316f-ab46-4290-8add-2b79499488ca',
    },
    {
      Id: '1b50beef-211f-4cef-a11e-c68d0dfc9bc0',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '960',
      State: 'SA',
      OsUnique: '1b50beef-211f-4cef-a11e-c68d0dfc9bc0',
    },
    {
      Id: 'acbaecca-54e7-459c-9e15-a627f0d1dd8b',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '961',
      State: 'SA',
      OsUnique: 'acbaecca-54e7-459c-9e15-a627f0d1dd8b',
    },
    {
      Id: '3f98a58d-2c4e-4e66-ad1f-0f3d51f07020',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '962',
      State: 'SA',
      OsUnique: '3f98a58d-2c4e-4e66-ad1f-0f3d51f07020',
    },
    {
      Id: '02a596b6-e9e5-40e9-96d7-48e4879132d1',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '963',
      State: 'SA',
      OsUnique: '02a596b6-e9e5-40e9-96d7-48e4879132d1',
    },
    {
      Id: 'a3b7a667-0ead-49b5-bf70-c087612f4595',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '964',
      State: 'SA',
      OsUnique: 'a3b7a667-0ead-49b5-bf70-c087612f4595',
    },
    {
      Id: '9f3438a6-b0ea-4b60-bbd3-839b0625c588',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '967',
      State: 'TAS',
      OsUnique: '9f3438a6-b0ea-4b60-bbd3-839b0625c588',
    },
    {
      Id: 'ba4cabb6-a275-467b-8532-37bb75f24dd6',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '975',
      State: 'TAS',
      OsUnique: 'ba4cabb6-a275-467b-8532-37bb75f24dd6',
    },
    {
      Id: '2ca8d447-d421-4f64-b9b3-297121a9c883',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '976',
      State: 'TAS',
      OsUnique: '2ca8d447-d421-4f64-b9b3-297121a9c883',
    },
    {
      Id: '62193001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (TAS)',
      MatterTypeId: '977',
      State: 'TAS',
      OsUnique: '62193001',
    },
    {
      Id: '79d86732-f355-46d8-a4ab-f3922de401db',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '978',
      State: 'TAS',
      OsUnique: '79d86732-f355-46d8-a4ab-f3922de401db',
    },
    {
      Id: '47510fa3-ad4f-4742-8550-cf0654260307',
      Title: 'FULL COMMENTARY - MOTOR VEHICLE ACCIDENT (NSW) - ACCIDENTS FROM 1 DECEMBER 2017',
      MatterTypeId: '98',
      State: 'NSW',
      OsUnique: '47510fa3-ad4f-4742-8550-cf0654260307',
    },
    {
      Id: '61822001',
      Title: 'FULL COMMENTARY - MOTOR VEHICLE ACCIDENT (NSW) - ACCIDENTS PRIOR TO 1 DECEMBER 2017',
      MatterTypeId: '98',
      State: 'NSW',
      OsUnique: '61822001',
    },
    {
      Id: '62104001',
      Title: 'FULL COMMENTARY - PERSONAL INJURY (NSW)',
      MatterTypeId: '98',
      State: 'NSW',
      OsUnique: '62104001',
    },
    {
      Id: '61899001',
      Title: 'FULL COMMENTARY - VICTIMS RIGHTS AND SUPPORT (NSW)',
      MatterTypeId: '98',
      State: 'NSW',
      OsUnique: '61899001',
    },
    {
      Id: '61851001',
      Title: 'FULL COMMENTARY - WORKERS COMPENSATION (NSW)',
      MatterTypeId: '98',
      State: 'NSW',
      OsUnique: '61851001',
    },
    {
      Id: 'b1fba9f7-6ddc-477a-9743-94def93acb9a',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '980',
      State: 'TAS',
      OsUnique: 'b1fba9f7-6ddc-477a-9743-94def93acb9a',
    },
    {
      Id: '64143001',
      Title: 'Uniform contract for sale of business',
      MatterTypeId: '980',
      State: 'TAS',
      OsUnique: '64143001',
    },
    {
      Id: 'a5abb6ad-73e1-4dd9-96db-9fc63c0a040b',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '981',
      State: 'TAS',
      OsUnique: 'a5abb6ad-73e1-4dd9-96db-9fc63c0a040b',
    },
    {
      Id: '239ac14d-8394-4759-8a34-8e01f731b74a',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '982',
      State: 'TAS',
      OsUnique: '239ac14d-8394-4759-8a34-8e01f731b74a',
    },
    {
      Id: '61851001',
      Title: 'FULL COMMENTARY - WORKERS COMPENSATION (NSW)',
      MatterTypeId: '99',
      State: 'NSW',
      OsUnique: '61851001',
    },
    {
      Id: '61822001',
      Title: 'FULL COMMENTARY - MOTOR VEHICLE ACCIDENT (NSW) - ACCIDENTS PRIOR TO 1 DECEMBER 2017',
      MatterTypeId: '99',
      State: 'NSW',
      OsUnique: '61822001',
    },
    {
      Id: '47510fa3-ad4f-4742-8550-cf0654260307',
      Title: 'FULL COMMENTARY - MOTOR VEHICLE ACCIDENT (NSW) - ACCIDENTS FROM 1 DECEMBER 2017',
      MatterTypeId: '99',
      State: 'NSW',
      OsUnique: '47510fa3-ad4f-4742-8550-cf0654260307',
    },
    {
      Id: '62104001',
      Title: 'FULL COMMENTARY - PERSONAL INJURY (NSW)',
      MatterTypeId: '99',
      State: 'NSW',
      OsUnique: '62104001',
    },
    {
      Id: '61899001',
      Title: 'FULL COMMENTARY - VICTIMS RIGHTS AND SUPPORT (NSW)',
      MatterTypeId: '99',
      State: 'NSW',
      OsUnique: '61899001',
    },
    {
      Id: 'c5cd7b4f-32e8-4a17-ac6d-2cef7d8b323f',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '990',
      State: 'TAS',
      OsUnique: 'c5cd7b4f-32e8-4a17-ac6d-2cef7d8b323f',
    },
    {
      Id: '0bb748a8-a091-4c59-976e-af7248787cc3',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '991',
      State: 'TAS',
      OsUnique: '0bb748a8-a091-4c59-976e-af7248787cc3',
    },
    {
      Id: 'ff1b00c0-7950-4a7e-86f4-1e35cf89a077',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '992',
      State: 'TAS',
      OsUnique: 'ff1b00c0-7950-4a7e-86f4-1e35cf89a077',
    },
    {
      Id: '8f28ed05-48a2-40ad-afd4-9236204e247c',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '9a301e9f-dad5-41db-ba14-eab4948744c4',
      State: 'NT',
      OsUnique: '8f28ed05-48a2-40ad-afd4-9236204e247c',
    },
    {
      Id: '64191001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (QLD)',
      MatterTypeId: '04cef84b-b985-4f2b-bf7a-1781bd1b6117',
      State: 'QLD',
      OsUnique: '64191001',
    },
    {
      Id: '68691001',
      Title: '1001 Conveyancing Answers (QLD)',
      MatterTypeId: '04cef84b-b985-4f2b-bf7a-1781bd1b6117',
      State: 'QLD',
      OsUnique: '68691001',
    },
    {
      Id: '64152001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (QLD)',
      MatterTypeId: '04cef84b-b985-4f2b-bf7a-1781bd1b6117',
      State: 'QLD',
      OsUnique: '64152001',
    },
    {
      Id: '60838001',
      Title: 'FULL COMMENTARY - MORTGAGES (QLD)',
      MatterTypeId: '04cef84b-b985-4f2b-bf7a-1781bd1b6117',
      State: 'QLD',
      OsUnique: '60838001',
    },
    {
      Id: '64152001',
      Title: 'FULL COMMENTARY - PURCHASE OF REAL PROPERTY (QLD)',
      MatterTypeId: '234344cb-643d-44bc-807c-cc19594f09c5',
      State: 'QLD',
      OsUnique: '64152001',
    },
    {
      Id: '68691001',
      Title: '1001 Conveyancing Answers (QLD)',
      MatterTypeId: '234344cb-643d-44bc-807c-cc19594f09c5',
      State: 'QLD',
      OsUnique: '68691001',
    },
    {
      Id: '64191001',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (QLD)',
      MatterTypeId: '234344cb-643d-44bc-807c-cc19594f09c5',
      State: 'QLD',
      OsUnique: '64191001',
    },
    {
      Id: '60838001',
      Title: 'FULL COMMENTARY - MORTGAGES (QLD)',
      MatterTypeId: '234344cb-643d-44bc-807c-cc19594f09c5',
      State: 'QLD',
      OsUnique: '60838001',
    },
    {
      Id: '68689001',
      Title: '1001 Conveyancing Answers (NSW)',
      MatterTypeId: '92af79bd-5b45-438a-a446-eff23ffb896e',
      State: 'NSW',
      OsUnique: '68689001',
    },
    {
      Id: '8d2f5896-127f-4ea7-9685-70432ebe21db',
      Title: 'FULL COMMENTARY - SALE OF REAL PROPERTY (NSW)',
      MatterTypeId: '92af79bd-5b45-438a-a446-eff23ffb896e',
      State: 'NSW',
      OsUnique: '8d2f5896-127f-4ea7-9685-70432ebe21db',
    },
  ],
  uk: [
    {
      Id: 'ac28f07d-a9d6-40de-9efa-ca676e87d673',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '0294f90b-cc71-4ea0-9132-8ae59aa9d561',
      State: 'ENG',
      OsUnique: 'ac28f07d-a9d6-40de-9efa-ca676e87d673',
    },
    {
      Id: 'b92671aa-314f-445c-bdb6-79375b788489',
      Title: 'FULL COMMENTARY- RESIDENTIAL LEASES',
      MatterTypeId: '21021262-5430-4917-8d8f-5b1344dab438',
      State: 'ENG',
      OsUnique: 'b92671aa-314f-445c-bdb6-79375b788489',
    },
    {
      Id: '4e8fa52b-ab00-41a5-a280-4ddd8690d9f3',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS',
      MatterTypeId: '2e66c288-1629-4ea8-87db-508148766fd0',
      State: 'ENG',
      OsUnique: '4e8fa52b-ab00-41a5-a280-4ddd8690d9f3',
    },
    {
      Id: '98cb16e9-27c5-4e5a-9e44-e58f5ea58a1d',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS',
      MatterTypeId: '2e66c288-1629-4ea8-87db-508148766fd0',
      State: 'ENG',
      OsUnique: '98cb16e9-27c5-4e5a-9e44-e58f5ea58a1d',
    },
    {
      Id: 'd9dde119-4807-490a-9b5c-885c8abe5ac6',
      Title: 'FULL COMMENTARY - COMMERCIAL LEASE',
      MatterTypeId: '2e66c288-1629-4ea8-87db-508148766fd0',
      State: 'ENG',
      OsUnique: 'd9dde119-4807-490a-9b5c-885c8abe5ac6',
    },
    {
      Id: '93a83698-0711-452c-b538-59c4740e6a8b',
      Title: 'FULL COMMENTARY - PURCHASE OF LAND',
      MatterTypeId: '3000020',
      State: 'ENG',
      OsUnique: '93a83698-0711-452c-b538-59c4740e6a8b',
    },
    {
      Id: '5f2275ef-12eb-4448-a1d0-5e659c8c641a',
      Title: 'FULL COMMENTARY - SALE OF LAND',
      MatterTypeId: '3000020',
      State: 'ENG',
      OsUnique: '5f2275ef-12eb-4448-a1d0-5e659c8c641a',
    },
    {
      Id: 'b92671aa-314f-445c-bdb6-79375b788489',
      Title: 'FULL COMMENTARY- RESIDENTIAL LEASES',
      MatterTypeId: '3000020',
      State: 'ENG',
      OsUnique: 'b92671aa-314f-445c-bdb6-79375b788489',
    },
    {
      Id: '5f2275ef-12eb-4448-a1d0-5e659c8c641a',
      Title: 'FULL COMMENTARY - SALE OF LAND',
      MatterTypeId: '3000021',
      State: 'ENG',
      OsUnique: '5f2275ef-12eb-4448-a1d0-5e659c8c641a',
    },
    {
      Id: '93a83698-0711-452c-b538-59c4740e6a8b',
      Title: 'FULL COMMENTARY - PURCHASE OF LAND',
      MatterTypeId: '3000021',
      State: 'ENG',
      OsUnique: '93a83698-0711-452c-b538-59c4740e6a8b',
    },
    {
      Id: 'b92671aa-314f-445c-bdb6-79375b788489',
      Title: 'FULL COMMENTARY- RESIDENTIAL LEASES',
      MatterTypeId: '3000021',
      State: 'ENG',
      OsUnique: 'b92671aa-314f-445c-bdb6-79375b788489',
    },
    {
      Id: '27d6ddcf-b8b8-4aff-887a-b68e83035d78',
      Title: 'FULL COMMENTARY - MORTGAGES',
      MatterTypeId: '3000022',
      State: 'ENG',
      OsUnique: '27d6ddcf-b8b8-4aff-887a-b68e83035d78',
    },
    {
      Id: '93a83698-0711-452c-b538-59c4740e6a8b',
      Title: 'FULL COMMENTARY - PURCHASE OF LAND',
      MatterTypeId: '3000022',
      State: 'ENG',
      OsUnique: '93a83698-0711-452c-b538-59c4740e6a8b',
    },
    {
      Id: 'b92671aa-314f-445c-bdb6-79375b788489',
      Title: 'FULL COMMENTARY- RESIDENTIAL LEASES',
      MatterTypeId: '3000023',
      State: 'ENG',
      OsUnique: 'b92671aa-314f-445c-bdb6-79375b788489',
    },
    {
      Id: '5f2275ef-12eb-4448-a1d0-5e659c8c641a',
      Title: 'FULL COMMENTARY - SALE OF LAND',
      MatterTypeId: '3000023',
      State: 'ENG',
      OsUnique: '5f2275ef-12eb-4448-a1d0-5e659c8c641a',
    },
    {
      Id: '93a83698-0711-452c-b538-59c4740e6a8b',
      Title: 'FULL COMMENTARY - PURCHASE OF LAND',
      MatterTypeId: '3000023',
      State: 'ENG',
      OsUnique: '93a83698-0711-452c-b538-59c4740e6a8b',
    },
    {
      Id: '710eb47c-9ef7-428f-8cff-d24f2180d60e',
      Title: 'FULL COMMENTARY - DIVORCE',
      MatterTypeId: '3000024',
      State: 'ENG',
      OsUnique: '710eb47c-9ef7-428f-8cff-d24f2180d60e',
    },
    {
      Id: 'b1b128c9-7a02-49a9-93f5-67d4c90d451e',
      Title: 'FULL COMMENTARY - FINANCIAL SETTLEMENT',
      MatterTypeId: '3000024',
      State: 'ENG',
      OsUnique: 'b1b128c9-7a02-49a9-93f5-67d4c90d451e',
    },
    {
      Id: '2ca5ff95-ccd2-41d1-ae2b-e1fbda2f9b92',
      Title: 'FULL COMMENTARY - NUPTIAL AGREEMENTS',
      MatterTypeId: '3000024',
      State: 'ENG',
      OsUnique: '2ca5ff95-ccd2-41d1-ae2b-e1fbda2f9b92',
    },
    {
      Id: '166e1b2a-b505-4f1c-9f55-81db47af976c',
      Title: 'FULL COMMENTARY - PRIVATE CHILDREN PROCEEDINGS',
      MatterTypeId: '3000024',
      State: 'ENG',
      OsUnique: '166e1b2a-b505-4f1c-9f55-81db47af976c',
    },
    {
      Id: 'bc5a14f0-1bcb-430d-90f6-e680ce73fee7',
      Title: 'FULL COMMENTARY - DOMESTIC VIOLENCE ORDERS',
      MatterTypeId: '3000024',
      State: 'ENG',
      OsUnique: 'bc5a14f0-1bcb-430d-90f6-e680ce73fee7',
    },
    {
      Id: 'b1b128c9-7a02-49a9-93f5-67d4c90d451e',
      Title: 'FULL COMMENTARY - FINANCIAL SETTLEMENT',
      MatterTypeId: '3000025',
      State: 'ENG',
      OsUnique: 'b1b128c9-7a02-49a9-93f5-67d4c90d451e',
    },
    {
      Id: '2ca5ff95-ccd2-41d1-ae2b-e1fbda2f9b92',
      Title: 'FULL COMMENTARY - NUPTIAL AGREEMENTS',
      MatterTypeId: '3000025',
      State: 'ENG',
      OsUnique: '2ca5ff95-ccd2-41d1-ae2b-e1fbda2f9b92',
    },
    {
      Id: '710eb47c-9ef7-428f-8cff-d24f2180d60e',
      Title: 'FULL COMMENTARY - DIVORCE',
      MatterTypeId: '3000025',
      State: 'ENG',
      OsUnique: '710eb47c-9ef7-428f-8cff-d24f2180d60e',
    },
    {
      Id: '166e1b2a-b505-4f1c-9f55-81db47af976c',
      Title: 'FULL COMMENTARY - PRIVATE CHILDREN PROCEEDINGS',
      MatterTypeId: '3000025',
      State: 'ENG',
      OsUnique: '166e1b2a-b505-4f1c-9f55-81db47af976c',
    },
    {
      Id: 'bc5a14f0-1bcb-430d-90f6-e680ce73fee7',
      Title: 'FULL COMMENTARY - DOMESTIC VIOLENCE ORDERS',
      MatterTypeId: '3000025',
      State: 'ENG',
      OsUnique: 'bc5a14f0-1bcb-430d-90f6-e680ce73fee7',
    },
    {
      Id: 'a32f246e-6c41-4830-bc7e-a453a6376a5b',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000026',
      State: 'ENG',
      OsUnique: 'a32f246e-6c41-4830-bc7e-a453a6376a5b',
    },
    {
      Id: '44559f41-6c4e-4754-92cc-faa8a2e7ab24',
      Title: 'FULL COMMENTARY - ESTATES',
      MatterTypeId: '3000027',
      State: 'ENG',
      OsUnique: '44559f41-6c4e-4754-92cc-faa8a2e7ab24',
    },
    {
      Id: '84ecc61d-81f7-4f62-a526-852fb165db4e',
      Title: 'FULL COMMENTARY - WILLS',
      MatterTypeId: '3000027',
      State: 'ENG',
      OsUnique: '84ecc61d-81f7-4f62-a526-852fb165db4e',
    },
    {
      Id: '7c76f73c-d3d1-4626-b55b-f4b9d56ff409',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE DECISIONS',
      MatterTypeId: '3000027',
      State: 'ENG',
      OsUnique: '7c76f73c-d3d1-4626-b55b-f4b9d56ff409',
    },
    {
      Id: '84ecc61d-81f7-4f62-a526-852fb165db4e',
      Title: 'FULL COMMENTARY - WILLS',
      MatterTypeId: '3000028',
      State: 'ENG',
      OsUnique: '84ecc61d-81f7-4f62-a526-852fb165db4e',
    },
    {
      Id: '7c76f73c-d3d1-4626-b55b-f4b9d56ff409',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE DECISIONS',
      MatterTypeId: '3000028',
      State: 'ENG',
      OsUnique: '7c76f73c-d3d1-4626-b55b-f4b9d56ff409',
    },
    {
      Id: '841f7851-fba3-4617-ba78-92e9fa9ad04a',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000029',
      State: 'ENG',
      OsUnique: '841f7851-fba3-4617-ba78-92e9fa9ad04a',
    },
    {
      Id: 'eedb5bab-f287-47ec-aa24-73e23943733f',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000030',
      State: 'ENG',
      OsUnique: 'eedb5bab-f287-47ec-aa24-73e23943733f',
    },
    {
      Id: '2d7c93c7-b2c3-42d5-81df-80f9cec6dcbc',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000033',
      State: 'ENG',
      OsUnique: '2d7c93c7-b2c3-42d5-81df-80f9cec6dcbc',
    },
    {
      Id: 'ef4058a2-c175-4df9-82b5-b8aae577c2ad',
      Title: 'FULL COMMENTARY - CRIMINAL',
      MatterTypeId: '3000038',
      State: 'ENG',
      OsUnique: 'ef4058a2-c175-4df9-82b5-b8aae577c2ad',
    },
    {
      Id: 'ef4058a2-c175-4df9-82b5-b8aae577c2ad',
      Title: 'FULL COMMENTARY - CRIMINAL',
      MatterTypeId: '3000039',
      State: 'ENG',
      OsUnique: 'ef4058a2-c175-4df9-82b5-b8aae577c2ad',
    },
    {
      Id: '7c76f73c-d3d1-4626-b55b-f4b9d56ff409',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE DECISIONS',
      MatterTypeId: '3000040',
      State: 'ENG',
      OsUnique: '7c76f73c-d3d1-4626-b55b-f4b9d56ff409',
    },
    {
      Id: '84ecc61d-81f7-4f62-a526-852fb165db4e',
      Title: 'FULL COMMENTARY - WILLS',
      MatterTypeId: '3000040',
      State: 'ENG',
      OsUnique: '84ecc61d-81f7-4f62-a526-852fb165db4e',
    },
    {
      Id: '93a83698-0711-452c-b538-59c4740e6a8b',
      Title: 'FULL COMMENTARY - PURCHASE OF LAND',
      MatterTypeId: '3000043',
      State: 'ENG',
      OsUnique: '93a83698-0711-452c-b538-59c4740e6a8b',
    },
    {
      Id: '4e8fa52b-ab00-41a5-a280-4ddd8690d9f3',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS',
      MatterTypeId: '3000043',
      State: 'ENG',
      OsUnique: '4e8fa52b-ab00-41a5-a280-4ddd8690d9f3',
    },
    {
      Id: '5f2275ef-12eb-4448-a1d0-5e659c8c641a',
      Title: 'FULL COMMENTARY - SALE OF LAND',
      MatterTypeId: '3000043',
      State: 'ENG',
      OsUnique: '5f2275ef-12eb-4448-a1d0-5e659c8c641a',
    },
    {
      Id: '98cb16e9-27c5-4e5a-9e44-e58f5ea58a1d',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS',
      MatterTypeId: '3000043',
      State: 'ENG',
      OsUnique: '98cb16e9-27c5-4e5a-9e44-e58f5ea58a1d',
    },
    {
      Id: '5f2275ef-12eb-4448-a1d0-5e659c8c641a',
      Title: 'FULL COMMENTARY - SALE OF LAND',
      MatterTypeId: '3000044',
      State: 'ENG',
      OsUnique: '5f2275ef-12eb-4448-a1d0-5e659c8c641a',
    },
    {
      Id: '98cb16e9-27c5-4e5a-9e44-e58f5ea58a1d',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS',
      MatterTypeId: '3000044',
      State: 'ENG',
      OsUnique: '98cb16e9-27c5-4e5a-9e44-e58f5ea58a1d',
    },
    {
      Id: '93a83698-0711-452c-b538-59c4740e6a8b',
      Title: 'FULL COMMENTARY - PURCHASE OF LAND',
      MatterTypeId: '3000044',
      State: 'ENG',
      OsUnique: '93a83698-0711-452c-b538-59c4740e6a8b',
    },
    {
      Id: '4e8fa52b-ab00-41a5-a280-4ddd8690d9f3',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS',
      MatterTypeId: '3000044',
      State: 'ENG',
      OsUnique: '4e8fa52b-ab00-41a5-a280-4ddd8690d9f3',
    },
    {
      Id: 'f9ee9c6e-62e9-4c3d-aeac-e0bba53ab32c',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000046',
      State: 'ENG',
      OsUnique: 'f9ee9c6e-62e9-4c3d-aeac-e0bba53ab32c',
    },
    {
      Id: '837e2b15-1e52-4071-8268-75b7941b3e1a',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000050',
      State: 'ENG',
      OsUnique: '837e2b15-1e52-4071-8268-75b7941b3e1a',
    },
    {
      Id: '2ca5ff95-ccd2-41d1-ae2b-e1fbda2f9b92',
      Title: 'FULL COMMENTARY - NUPTIAL AGREEMENTS',
      MatterTypeId: '3000051',
      State: 'ENG',
      OsUnique: '2ca5ff95-ccd2-41d1-ae2b-e1fbda2f9b92',
    },
    {
      Id: '710eb47c-9ef7-428f-8cff-d24f2180d60e',
      Title: 'FULL COMMENTARY - DIVORCE',
      MatterTypeId: '3000051',
      State: 'ENG',
      OsUnique: '710eb47c-9ef7-428f-8cff-d24f2180d60e',
    },
    {
      Id: 'bc5a14f0-1bcb-430d-90f6-e680ce73fee7',
      Title: 'FULL COMMENTARY - DOMESTIC VIOLENCE ORDERS',
      MatterTypeId: '3000051',
      State: 'ENG',
      OsUnique: 'bc5a14f0-1bcb-430d-90f6-e680ce73fee7',
    },
    {
      Id: 'd0d9470d-81b2-4467-ab7b-0def404687f3',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000053',
      State: 'ENG',
      OsUnique: 'd0d9470d-81b2-4467-ab7b-0def404687f3',
    },
    {
      Id: '9a0e37b7-7276-4f8b-a0cb-f7f2d0c9e572',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000054',
      State: 'ENG',
      OsUnique: '9a0e37b7-7276-4f8b-a0cb-f7f2d0c9e572',
    },
    {
      Id: '223dac38-9eb5-4205-9305-95e0f21c4351',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000056',
      State: 'ENG',
      OsUnique: '223dac38-9eb5-4205-9305-95e0f21c4351',
    },
    {
      Id: '2eb03f55-c415-4075-a172-1b01174dd253',
      Title: 'FULL COMMENTARY - TRUSTS',
      MatterTypeId: '3000056',
      State: 'ENG',
      OsUnique: '2eb03f55-c415-4075-a172-1b01174dd253',
    },
    {
      Id: 'cc8a78a6-5b76-4618-9142-9ce554495503',
      Title: 'FULL COMMENTARY - PARTNERSHIPS',
      MatterTypeId: '3000056',
      State: 'ENG',
      OsUnique: 'cc8a78a6-5b76-4618-9142-9ce554495503',
    },
    {
      Id: '146d6251-fdc8-4841-a20b-c5c1bbd3db13',
      Title: 'FULL COMMENTARY - JOINT VENTURES',
      MatterTypeId: '3000056',
      State: 'ENG',
      OsUnique: '146d6251-fdc8-4841-a20b-c5c1bbd3db13',
    },
    {
      Id: '8f9b98cc-9fc4-4b8a-976d-60a81525f44d',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000057',
      State: 'ENG',
      OsUnique: '8f9b98cc-9fc4-4b8a-976d-60a81525f44d',
    },
    {
      Id: 'bcb848b8-19d6-49d8-84e3-8d4e14d30d14',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000058',
      State: 'ENG',
      OsUnique: 'bcb848b8-19d6-49d8-84e3-8d4e14d30d14',
    },
    {
      Id: '5e54c0f9-18a0-4a37-9e50-74a608e4d70a',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000086',
      State: 'ENG',
      OsUnique: '5e54c0f9-18a0-4a37-9e50-74a608e4d70a',
    },
    {
      Id: '4bc0884e-8113-4573-baee-8426a413c094',
      Title: 'FULL COMMENTARY - PRACTICE MANAGEMENT',
      MatterTypeId: '3000088',
      State: 'ENG',
      OsUnique: '4bc0884e-8113-4573-baee-8426a413c094',
    },
    {
      Id: 'd2634238-342a-467e-b1e6-be383bcfe9d3',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000089',
      State: 'ENG',
      OsUnique: 'd2634238-342a-467e-b1e6-be383bcfe9d3',
    },
    {
      Id: '1e5b2d08-70ae-415a-aff1-74c6c043bf06',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000092',
      State: 'ENG',
      OsUnique: '1e5b2d08-70ae-415a-aff1-74c6c043bf06',
    },
    {
      Id: '6b28415a-f5bc-463c-9e12-c9de914eb7ab',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000093',
      State: 'ENG',
      OsUnique: '6b28415a-f5bc-463c-9e12-c9de914eb7ab',
    },
    {
      Id: 'c1f70faf-79b7-449a-bd22-3c90c5f954f1',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000094',
      State: 'ENG',
      OsUnique: 'c1f70faf-79b7-449a-bd22-3c90c5f954f1',
    },
    {
      Id: '2668c80d-9883-4d64-81fd-a13d834523d9',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000095',
      State: 'ENG',
      OsUnique: '2668c80d-9883-4d64-81fd-a13d834523d9',
    },
    {
      Id: '5a8ead59-00fd-43ae-905f-8b249a332ca8',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000096',
      State: 'ENG',
      OsUnique: '5a8ead59-00fd-43ae-905f-8b249a332ca8',
    },
    {
      Id: 'fa7a8959-ca98-4430-9242-f6505b1fa2ea',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000097',
      State: 'ENG',
      OsUnique: 'fa7a8959-ca98-4430-9242-f6505b1fa2ea',
    },
    {
      Id: '5f2275ef-12eb-4448-a1d0-5e659c8c641a',
      Title: 'FULL COMMENTARY - SALE OF LAND',
      MatterTypeId: '3000101',
      State: 'ENG',
      OsUnique: '5f2275ef-12eb-4448-a1d0-5e659c8c641a',
    },
    {
      Id: '93a83698-0711-452c-b538-59c4740e6a8b',
      Title: 'FULL COMMENTARY - PURCHASE OF LAND',
      MatterTypeId: '3000101',
      State: 'ENG',
      OsUnique: '93a83698-0711-452c-b538-59c4740e6a8b',
    },
    {
      Id: 'b92671aa-314f-445c-bdb6-79375b788489',
      Title: 'FULL COMMENTARY- RESIDENTIAL LEASES',
      MatterTypeId: '3000101',
      State: 'ENG',
      OsUnique: 'b92671aa-314f-445c-bdb6-79375b788489',
    },
    {
      Id: 'f4e186eb-04b9-42ea-af6e-465cda4cd96c',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000102',
      State: 'ENG',
      OsUnique: 'f4e186eb-04b9-42ea-af6e-465cda4cd96c',
    },
    {
      Id: 'f4e186eb-04b9-42ea-af6e-465cda4cd96c',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000102',
      State: 'NI',
      OsUnique: 'f4e186eb-04b9-42ea-af6e-465cda4cd96c',
    },
    {
      Id: 'e534acbf-3714-49b8-a386-0be76ea4100d',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000103',
      State: 'ENG',
      OsUnique: 'e534acbf-3714-49b8-a386-0be76ea4100d',
    },
    {
      Id: '38ae0551-db62-418f-9546-a50e5a877537',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000104',
      State: 'ENG',
      OsUnique: '38ae0551-db62-418f-9546-a50e5a877537',
    },
    {
      Id: '93a83698-0711-452c-b538-59c4740e6a8b',
      Title: 'FULL COMMENTARY - PURCHASE OF LAND',
      MatterTypeId: '3000105',
      State: 'ENG',
      OsUnique: '93a83698-0711-452c-b538-59c4740e6a8b',
    },
    {
      Id: '27d6ddcf-b8b8-4aff-887a-b68e83035d78',
      Title: 'FULL COMMENTARY - MORTGAGES',
      MatterTypeId: '3000105',
      State: 'ENG',
      OsUnique: '27d6ddcf-b8b8-4aff-887a-b68e83035d78',
    },
    {
      Id: '5f2275ef-12eb-4448-a1d0-5e659c8c641a',
      Title: 'FULL COMMENTARY - SALE OF LAND',
      MatterTypeId: '3000105',
      State: 'ENG',
      OsUnique: '5f2275ef-12eb-4448-a1d0-5e659c8c641a',
    },
    {
      Id: '3aa39f37-1656-45fc-a74f-74f332b21295',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000106',
      State: 'ENG',
      OsUnique: '3aa39f37-1656-45fc-a74f-74f332b21295',
    },
    {
      Id: 'd8fdebf9-2952-4ed5-9573-66e13a972d52',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000111',
      State: 'ENG',
      OsUnique: 'd8fdebf9-2952-4ed5-9573-66e13a972d52',
    },
    {
      Id: '581608cf-a427-4d5f-be4a-6683bc24e120',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000112',
      State: 'ENG',
      OsUnique: '581608cf-a427-4d5f-be4a-6683bc24e120',
    },
    {
      Id: 'c4283d67-5b22-4074-8b66-8a0ba9672893',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000113',
      State: 'ENG',
      OsUnique: 'c4283d67-5b22-4074-8b66-8a0ba9672893',
    },
    {
      Id: '9c8d2656-d66e-4b02-86d0-a1c412591b4d',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000114',
      State: 'ENG',
      OsUnique: '9c8d2656-d66e-4b02-86d0-a1c412591b4d',
    },
    {
      Id: '1086f708-523b-42f5-80ef-3cb175611081',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000115',
      State: 'ENG',
      OsUnique: '1086f708-523b-42f5-80ef-3cb175611081',
    },
    {
      Id: 'b19705ec-b359-43c6-a803-70c09f47288e',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000116',
      State: 'ENG',
      OsUnique: 'b19705ec-b359-43c6-a803-70c09f47288e',
    },
    {
      Id: '245000ba-a020-4918-997b-dde0dc9e0e6f',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000118',
      State: 'ENG',
      OsUnique: '245000ba-a020-4918-997b-dde0dc9e0e6f',
    },
    {
      Id: '673909cc-c811-4e9e-81a6-69d06d4701e7',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000120',
      State: 'ENG',
      OsUnique: '673909cc-c811-4e9e-81a6-69d06d4701e7',
    },
    {
      Id: '84ecc61d-81f7-4f62-a526-852fb165db4e',
      Title: 'FULL COMMENTARY - WILLS',
      MatterTypeId: '3000122',
      State: 'ENG',
      OsUnique: '84ecc61d-81f7-4f62-a526-852fb165db4e',
    },
    {
      Id: '7c76f73c-d3d1-4626-b55b-f4b9d56ff409',
      Title: 'FULL COMMENTARY - POWERS OF ATTORNEY AND ADVANCE DECISIONS',
      MatterTypeId: '3000122',
      State: 'ENG',
      OsUnique: '7c76f73c-d3d1-4626-b55b-f4b9d56ff409',
    },
    {
      Id: 'd9dde119-4807-490a-9b5c-885c8abe5ac6',
      Title: 'FULL COMMENTARY - COMMERCIAL LEASE',
      MatterTypeId: '3000123',
      State: 'ENG',
      OsUnique: 'd9dde119-4807-490a-9b5c-885c8abe5ac6',
    },
    {
      Id: '166e1b2a-b505-4f1c-9f55-81db47af976c',
      Title: 'FULL COMMENTARY - PRIVATE CHILDREN PROCEEDINGS',
      MatterTypeId: '3000124',
      State: 'ENG',
      OsUnique: '166e1b2a-b505-4f1c-9f55-81db47af976c',
    },
    {
      Id: '710eb47c-9ef7-428f-8cff-d24f2180d60e',
      Title: 'FULL COMMENTARY - DIVORCE',
      MatterTypeId: '3000124',
      State: 'ENG',
      OsUnique: '710eb47c-9ef7-428f-8cff-d24f2180d60e',
    },
    {
      Id: 'b1b128c9-7a02-49a9-93f5-67d4c90d451e',
      Title: 'FULL COMMENTARY - FINANCIAL SETTLEMENT',
      MatterTypeId: '3000124',
      State: 'ENG',
      OsUnique: 'b1b128c9-7a02-49a9-93f5-67d4c90d451e',
    },
    {
      Id: '2ca5ff95-ccd2-41d1-ae2b-e1fbda2f9b92',
      Title: 'FULL COMMENTARY - NUPTIAL AGREEMENTS',
      MatterTypeId: '3000124',
      State: 'ENG',
      OsUnique: '2ca5ff95-ccd2-41d1-ae2b-e1fbda2f9b92',
    },
    {
      Id: 'bc5a14f0-1bcb-430d-90f6-e680ce73fee7',
      Title: 'FULL COMMENTARY - DOMESTIC VIOLENCE ORDERS',
      MatterTypeId: '3000124',
      State: 'ENG',
      OsUnique: 'bc5a14f0-1bcb-430d-90f6-e680ce73fee7',
    },
    {
      Id: 'b1b128c9-7a02-49a9-93f5-67d4c90d451e',
      Title: 'FULL COMMENTARY - FINANCIAL SETTLEMENT',
      MatterTypeId: '3000125',
      State: 'ENG',
      OsUnique: 'b1b128c9-7a02-49a9-93f5-67d4c90d451e',
    },
    {
      Id: '710eb47c-9ef7-428f-8cff-d24f2180d60e',
      Title: 'FULL COMMENTARY - DIVORCE',
      MatterTypeId: '3000125',
      State: 'ENG',
      OsUnique: '710eb47c-9ef7-428f-8cff-d24f2180d60e',
    },
    {
      Id: '2ca5ff95-ccd2-41d1-ae2b-e1fbda2f9b92',
      Title: 'FULL COMMENTARY - NUPTIAL AGREEMENTS',
      MatterTypeId: '3000125',
      State: 'ENG',
      OsUnique: '2ca5ff95-ccd2-41d1-ae2b-e1fbda2f9b92',
    },
    {
      Id: '166e1b2a-b505-4f1c-9f55-81db47af976c',
      Title: 'FULL COMMENTARY - PRIVATE CHILDREN PROCEEDINGS',
      MatterTypeId: '3000125',
      State: 'ENG',
      OsUnique: '166e1b2a-b505-4f1c-9f55-81db47af976c',
    },
    {
      Id: 'bc5a14f0-1bcb-430d-90f6-e680ce73fee7',
      Title: 'FULL COMMENTARY - DOMESTIC VIOLENCE ORDERS',
      MatterTypeId: '3000125',
      State: 'ENG',
      OsUnique: 'bc5a14f0-1bcb-430d-90f6-e680ce73fee7',
    },
    {
      Id: 'bc5a14f0-1bcb-430d-90f6-e680ce73fee7',
      Title: 'FULL COMMENTARY - DOMESTIC VIOLENCE ORDERS',
      MatterTypeId: '3000126',
      State: 'ENG',
      OsUnique: 'bc5a14f0-1bcb-430d-90f6-e680ce73fee7',
    },
    {
      Id: '710eb47c-9ef7-428f-8cff-d24f2180d60e',
      Title: 'FULL COMMENTARY - DIVORCE',
      MatterTypeId: '3000126',
      State: 'ENG',
      OsUnique: '710eb47c-9ef7-428f-8cff-d24f2180d60e',
    },
    {
      Id: 'b1b128c9-7a02-49a9-93f5-67d4c90d451e',
      Title: 'FULL COMMENTARY - FINANCIAL SETTLEMENT',
      MatterTypeId: '3000126',
      State: 'ENG',
      OsUnique: 'b1b128c9-7a02-49a9-93f5-67d4c90d451e',
    },
    {
      Id: '2ca5ff95-ccd2-41d1-ae2b-e1fbda2f9b92',
      Title: 'FULL COMMENTARY - NUPTIAL AGREEMENTS',
      MatterTypeId: '3000126',
      State: 'ENG',
      OsUnique: '2ca5ff95-ccd2-41d1-ae2b-e1fbda2f9b92',
    },
    {
      Id: '166e1b2a-b505-4f1c-9f55-81db47af976c',
      Title: 'FULL COMMENTARY - PRIVATE CHILDREN PROCEEDINGS',
      MatterTypeId: '3000126',
      State: 'ENG',
      OsUnique: '166e1b2a-b505-4f1c-9f55-81db47af976c',
    },
    {
      Id: '3b2f58c1-8beb-4ff8-a32e-49df191a19e1',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000128',
      State: 'ENG',
      OsUnique: '3b2f58c1-8beb-4ff8-a32e-49df191a19e1',
    },
    {
      Id: '2eb03f55-c415-4075-a172-1b01174dd253',
      Title: 'FULL COMMENTARY - TRUSTS',
      MatterTypeId: '3000130',
      State: 'ENG',
      OsUnique: '2eb03f55-c415-4075-a172-1b01174dd253',
    },
    {
      Id: '84ecc61d-81f7-4f62-a526-852fb165db4e',
      Title: 'FULL COMMENTARY - WILLS',
      MatterTypeId: '3000130',
      State: 'ENG',
      OsUnique: '84ecc61d-81f7-4f62-a526-852fb165db4e',
    },
    {
      Id: '61138f91-3df0-4f18-92e1-12625cab07ca',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000131',
      State: 'ENG',
      OsUnique: '61138f91-3df0-4f18-92e1-12625cab07ca',
    },
    {
      Id: 'cdca0f4a-80be-457e-98a0-5b80ba4dff6a',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000134',
      State: 'ENG',
      OsUnique: 'cdca0f4a-80be-457e-98a0-5b80ba4dff6a',
    },
    {
      Id: '16a23040-0126-494c-804a-877661bfc290',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000138',
      State: 'ENG',
      OsUnique: '16a23040-0126-494c-804a-877661bfc290',
    },
    {
      Id: '223dac38-9eb5-4205-9305-95e0f21c4351',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000139',
      State: 'ENG',
      OsUnique: '223dac38-9eb5-4205-9305-95e0f21c4351',
    },
    {
      Id: 'cfa4979d-b54a-48c9-aff8-2e7850db3352',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000141',
      State: 'ENG',
      OsUnique: 'cfa4979d-b54a-48c9-aff8-2e7850db3352',
    },
    {
      Id: '1f090294-1266-4d78-9977-2f71e51c3022',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000143',
      State: 'ENG',
      OsUnique: '1f090294-1266-4d78-9977-2f71e51c3022',
    },
    {
      Id: 'f17ab89f-df4e-46f7-beb3-6623e31972a0',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000144',
      State: 'ENG',
      OsUnique: 'f17ab89f-df4e-46f7-beb3-6623e31972a0',
    },
    {
      Id: 'adccc312-1d54-448b-8f8b-3d17d76c1725',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000145',
      State: 'ENG',
      OsUnique: 'adccc312-1d54-448b-8f8b-3d17d76c1725',
    },
    {
      Id: '97c6b6c2-6751-414a-9958-c33d68719856',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '3000146',
      State: 'ENG',
      OsUnique: '97c6b6c2-6751-414a-9958-c33d68719856',
    },
    {
      Id: '27d6ddcf-b8b8-4aff-887a-b68e83035d78',
      Title: 'FULL COMMENTARY - MORTGAGES',
      MatterTypeId: '35052914-4d95-49e8-9fe0-c83b488343ef',
      State: 'ENG',
      OsUnique: '27d6ddcf-b8b8-4aff-887a-b68e83035d78',
    },
    {
      Id: 'b92671aa-314f-445c-bdb6-79375b788489',
      Title: 'FULL COMMENTARY- RESIDENTIAL LEASES',
      MatterTypeId: '44d4e16f-867e-4952-a6ed-881e311f53a8',
      State: 'ENG',
      OsUnique: 'b92671aa-314f-445c-bdb6-79375b788489',
    },
    {
      Id: '146d6251-fdc8-4841-a20b-c5c1bbd3db13',
      Title: 'FULL COMMENTARY - JOINT VENTURES',
      MatterTypeId: '49db4ece-2c65-41d1-82d5-52de11e88905',
      State: 'ENG',
      OsUnique: '146d6251-fdc8-4841-a20b-c5c1bbd3db13',
    },
    {
      Id: 'cc8a78a6-5b76-4618-9142-9ce554495503',
      Title: 'FULL COMMENTARY - PARTNERSHIPS',
      MatterTypeId: '49db4ece-2c65-41d1-82d5-52de11e88905',
      State: 'ENG',
      OsUnique: 'cc8a78a6-5b76-4618-9142-9ce554495503',
    },
    {
      Id: '2eb03f55-c415-4075-a172-1b01174dd253',
      Title: 'FULL COMMENTARY - TRUSTS',
      MatterTypeId: '49db4ece-2c65-41d1-82d5-52de11e88905',
      State: 'ENG',
      OsUnique: '2eb03f55-c415-4075-a172-1b01174dd253',
    },
    {
      Id: 'b92671aa-314f-445c-bdb6-79375b788489',
      Title: 'FULL COMMENTARY- RESIDENTIAL LEASES',
      MatterTypeId: '4dc78a5e-c023-4be6-b745-4a1552611f6a',
      State: 'ENG',
      OsUnique: 'b92671aa-314f-445c-bdb6-79375b788489',
    },
    {
      Id: 'a0fe2b35-4202-42cc-83b6-aec5c0b56d67',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '55d0d0c6-08e6-403c-b1f5-1f1f9dc960c6',
      State: 'ENG',
      OsUnique: 'a0fe2b35-4202-42cc-83b6-aec5c0b56d67',
    },
    {
      Id: 'b649df46-d7a4-4bf6-bd0e-6a9fc1104cbd',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '585eff39-f266-44b2-b4b2-6937cc5d07eb',
      State: 'ENG',
      OsUnique: 'b649df46-d7a4-4bf6-bd0e-6a9fc1104cbd',
    },
    {
      Id: '57d9841c-a32c-41f4-bbd1-9af51dd238de',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '5f11a877-d4a9-43e6-82b5-438bb8c13cfd',
      State: 'ENG',
      OsUnique: '57d9841c-a32c-41f4-bbd1-9af51dd238de',
    },
    {
      Id: '45544b72-c9a2-46da-b704-f867757a5d11',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '683ca6cb-1446-41fc-b3f6-4bd08e5be243',
      State: 'ENG',
      OsUnique: '45544b72-c9a2-46da-b704-f867757a5d11',
    },
    {
      Id: '2eb03f55-c415-4075-a172-1b01174dd253',
      Title: 'FULL COMMENTARY - TRUSTS',
      MatterTypeId: '6f51de2c-d716-4403-a897-52a9e7d42c42',
      State: 'ENG',
      OsUnique: '2eb03f55-c415-4075-a172-1b01174dd253',
    },
    {
      Id: 'cc8a78a6-5b76-4618-9142-9ce554495503',
      Title: 'FULL COMMENTARY - PARTNERSHIPS',
      MatterTypeId: '6f51de2c-d716-4403-a897-52a9e7d42c42',
      State: 'ENG',
      OsUnique: 'cc8a78a6-5b76-4618-9142-9ce554495503',
    },
    {
      Id: '146d6251-fdc8-4841-a20b-c5c1bbd3db13',
      Title: 'FULL COMMENTARY - JOINT VENTURES',
      MatterTypeId: '6f51de2c-d716-4403-a897-52a9e7d42c42',
      State: 'ENG',
      OsUnique: '146d6251-fdc8-4841-a20b-c5c1bbd3db13',
    },
    {
      Id: '4e1e2d43-fc0f-4278-b9fd-00cf9a8b83a6',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: '70d554d4-dded-49a8-92fb-d0aa3cde8cd1',
      State: 'ENG',
      OsUnique: '4e1e2d43-fc0f-4278-b9fd-00cf9a8b83a6',
    },
    {
      Id: '2ca5ff95-ccd2-41d1-ae2b-e1fbda2f9b92',
      Title: 'FULL COMMENTARY - NUPTIAL AGREEMENTS',
      MatterTypeId: '80c462a6-1e0c-4338-8226-3350e63a0fe6',
      State: 'ENG',
      OsUnique: '2ca5ff95-ccd2-41d1-ae2b-e1fbda2f9b92',
    },
    {
      Id: 'b1b128c9-7a02-49a9-93f5-67d4c90d451e',
      Title: 'FULL COMMENTARY - FINANCIAL SETTLEMENT',
      MatterTypeId: '80c462a6-1e0c-4338-8226-3350e63a0fe6',
      State: 'ENG',
      OsUnique: 'b1b128c9-7a02-49a9-93f5-67d4c90d451e',
    },
    {
      Id: '710eb47c-9ef7-428f-8cff-d24f2180d60e',
      Title: 'FULL COMMENTARY - DIVORCE',
      MatterTypeId: '80c462a6-1e0c-4338-8226-3350e63a0fe6',
      State: 'ENG',
      OsUnique: '710eb47c-9ef7-428f-8cff-d24f2180d60e',
    },
    {
      Id: '166e1b2a-b505-4f1c-9f55-81db47af976c',
      Title: 'FULL COMMENTARY - PRIVATE CHILDREN PROCEEDINGS',
      MatterTypeId: '80c462a6-1e0c-4338-8226-3350e63a0fe6',
      State: 'ENG',
      OsUnique: '166e1b2a-b505-4f1c-9f55-81db47af976c',
    },
    {
      Id: 'bc5a14f0-1bcb-430d-90f6-e680ce73fee7',
      Title: 'FULL COMMENTARY - DOMESTIC VIOLENCE ORDERS',
      MatterTypeId: '80c462a6-1e0c-4338-8226-3350e63a0fe6',
      State: 'ENG',
      OsUnique: 'bc5a14f0-1bcb-430d-90f6-e680ce73fee7',
    },
    {
      Id: 'd9dde119-4807-490a-9b5c-885c8abe5ac6',
      Title: 'FULL COMMENTARY - COMMERCIAL LEASE',
      MatterTypeId: '88afc6e8-811c-4a10-8eeb-4ec17fb0cfa7',
      State: 'ENG',
      OsUnique: 'd9dde119-4807-490a-9b5c-885c8abe5ac6',
    },
    {
      Id: 'cc8a78a6-5b76-4618-9142-9ce554495503',
      Title: 'FULL COMMENTARY - PARTNERSHIPS',
      MatterTypeId: '8a21c502-6f28-451b-8331-77e6fbc88d3f',
      State: 'ENG',
      OsUnique: 'cc8a78a6-5b76-4618-9142-9ce554495503',
    },
    {
      Id: '146d6251-fdc8-4841-a20b-c5c1bbd3db13',
      Title: 'FULL COMMENTARY - JOINT VENTURES',
      MatterTypeId: '8a21c502-6f28-451b-8331-77e6fbc88d3f',
      State: 'ENG',
      OsUnique: '146d6251-fdc8-4841-a20b-c5c1bbd3db13',
    },
    {
      Id: '2eb03f55-c415-4075-a172-1b01174dd253',
      Title: 'FULL COMMENTARY - TRUSTS',
      MatterTypeId: '8a21c502-6f28-451b-8331-77e6fbc88d3f',
      State: 'ENG',
      OsUnique: '2eb03f55-c415-4075-a172-1b01174dd253',
    },
    {
      Id: '98cb16e9-27c5-4e5a-9e44-e58f5ea58a1d',
      Title: 'FULL COMMENTARY - SALE OF BUSINESS',
      MatterTypeId: '9a0fa7c5-6c71-42b5-87c5-1994eb81cf17',
      State: 'ENG',
      OsUnique: '98cb16e9-27c5-4e5a-9e44-e58f5ea58a1d',
    },
    {
      Id: '4e8fa52b-ab00-41a5-a280-4ddd8690d9f3',
      Title: 'FULL COMMENTARY - PURCHASE OF BUSINESS',
      MatterTypeId: '9a0fa7c5-6c71-42b5-87c5-1994eb81cf17',
      State: 'ENG',
      OsUnique: '4e8fa52b-ab00-41a5-a280-4ddd8690d9f3',
    },
    {
      Id: 'd9dde119-4807-490a-9b5c-885c8abe5ac6',
      Title: 'FULL COMMENTARY - COMMERCIAL LEASE',
      MatterTypeId: '9a0fa7c5-6c71-42b5-87c5-1994eb81cf17',
      State: 'ENG',
      OsUnique: 'd9dde119-4807-490a-9b5c-885c8abe5ac6',
    },
    {
      Id: 'e1d3ce04-623d-4cb7-a679-4d09e7998420',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: 'a646382f-452b-4f79-9896-3a86bf62bd13',
      State: 'ENG',
      OsUnique: 'e1d3ce04-623d-4cb7-a679-4d09e7998420',
    },
    {
      Id: '76f11591-78fd-44d8-8695-447b28d9437e',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: 'a6bd3cab-72ed-4def-a3d5-834d50cac7bb',
      State: 'ENG',
      OsUnique: '76f11591-78fd-44d8-8695-447b28d9437e',
    },
    {
      Id: '1a457257-5a35-4217-808e-7907af12e00d',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: 'b2b07e53-cc56-4cc6-bbed-28ad653f3cc9',
      State: 'ENG',
      OsUnique: '1a457257-5a35-4217-808e-7907af12e00d',
    },
    {
      Id: '729483e9-8583-4d95-b31c-d0ef33f0e595',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: 'bd300a58-bddb-4a7b-96ab-3f55fd49b1e8',
      State: 'ENG',
      OsUnique: '729483e9-8583-4d95-b31c-d0ef33f0e595',
    },
    {
      Id: '27d6ddcf-b8b8-4aff-887a-b68e83035d78',
      Title: 'FULL COMMENTARY - MORTGAGES',
      MatterTypeId: 'c5885cdf-84f3-4b9e-99c7-7382a8849541',
      State: 'ENG',
      OsUnique: '27d6ddcf-b8b8-4aff-887a-b68e83035d78',
    },
    {
      Id: 'd5703658-01c0-453b-b375-72cd39908913',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: 'cfac9cdc-294a-4c7d-b2ab-97084d9c3714',
      State: 'ENG',
      OsUnique: 'd5703658-01c0-453b-b375-72cd39908913',
    },
    {
      Id: 'ee467d8d-efd8-4970-91bb-750fc9c4ac3a',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: 'd5384969-a391-448e-96e6-ed3da1aff4d9',
      State: 'ENG',
      OsUnique: 'ee467d8d-efd8-4970-91bb-750fc9c4ac3a',
    },
    {
      Id: 'd0d956f6-cc80-4690-a5a1-a04018e1baf2',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: 'f26136c0-c877-46a8-b851-2d167abb693d',
      State: 'ENG',
      OsUnique: 'd0d956f6-cc80-4690-a5a1-a04018e1baf2',
    },
    {
      Id: '7ebc7085-c799-45c2-8e6d-6cbc03c1fcd4',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: 'f5271e1c-f7f7-49ac-b3c7-cb2bb2c1eb54',
      State: 'ENG',
      OsUnique: '7ebc7085-c799-45c2-8e6d-6cbc03c1fcd4',
    },
    {
      Id: 'ca5838c0-f45a-4527-8b5a-68f286f7bc3a',
      Title: 'FULL COMMENTARY - CONDUCTING A MATTER GENERALLY',
      MatterTypeId: 'f8537119-7d2e-4d3e-bb1f-5325eb1d086f',
      State: 'ENG',
      OsUnique: 'ca5838c0-f45a-4527-8b5a-68f286f7bc3a',
    },
  ],
};

export const ByLawyersGuides: IByLawyersGuide[] = guides[environment.config.brand.region || Regions.AU];
