import { createSelector } from '@ngrx/store';

import * as fromCorrespondence from '../reducers/correspondence.reducer';
import { State, selectCorrespondenceState as reducerState } from '../reducers';

export const selectCorrespondenceState = createSelector(reducerState, (state: State) => state && state.correspondence);

export const selectCorrespondenceLoading = createSelector(
  selectCorrespondenceState,
  (state: fromCorrespondence.State) => fromCorrespondence.selectLoading(state)
);

export const selectCorrrespondeceError = createSelector(selectCorrespondenceState, (state: fromCorrespondence.State) =>
  fromCorrespondence.selectError(state)
);

export const selectDownloadUrl = createSelector(selectCorrespondenceState, (state: fromCorrespondence.State) =>
  fromCorrespondence.selectDownloadUrl(state)
);

export const selectDownloadFileName = createSelector(selectCorrespondenceState, (state: fromCorrespondence.State) =>
  fromCorrespondence.selectDownloadFileName(state)
);

export const selectCheckedCorrespondence = createSelector(
  selectCorrespondenceState,
  (state: fromCorrespondence.State) => fromCorrespondence.selectCheckedCorrespondence(state)
);

export const selectClearCorrespondenceGrid = createSelector(
  selectCorrespondenceState,
  (state: fromCorrespondence.State) => fromCorrespondence.selectClearCorrespondenceGrid(state)
);

export const selectDownloadFileNameOnly = createSelector(selectCorrespondenceState, (state: fromCorrespondence.State) =>
  fromCorrespondence.selectDownloadFileNameOnly(state)
);
export const selectDeselectDocIds = createSelector(selectCorrespondenceState, (state: fromCorrespondence.State) =>
  fromCorrespondence.selectDeselectDocIds(state)
);
export const selectExtractAttachmentsSuccess = createSelector(
  selectCorrespondenceState,
  (state: fromCorrespondence.State) => fromCorrespondence.selectExtractAttachmentsSuccess(state)
);
export const selectCorrespondences = createSelector(
  selectCorrespondenceState,
  (state) => state.correspondences
);
