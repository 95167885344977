import { Injectable } from '@angular/core';

import { ECardType, ICardListEntry } from '../models';
import { BaseService } from '@app/shared/services/base/base.service';
import { IWebAddress } from '@app/core/models';
import { capitalize } from '@app/shared/functions';
import { isEmptyValue, uniqBy } from '@server/modules/shared/functions/common-util.functions';

@Injectable({
  providedIn: 'root',
})
export class CardFiltersService extends BaseService {
  constructor() {
    super();
  }

  public applyFilterConditions(
    cards: ICardListEntry[],
    tableId: string,
    excludeEmptyFullName: boolean,
    emailOnly: boolean,
    filterType: string,
    isFilterByCurrentMatter: boolean = false
  ): ICardListEntry[] {
    const filterTypeCap = capitalize(filterType);
    const defaultCategories = ['Supplier', 'People', 'Business', 'Company', 'Outlook', 'Staff', 'Child'];
    const entries = cards?.filter((card: ICardListEntry) => {
      const roles = card.roles;
      const categoryFilterIndex = defaultCategories.findIndex((d) => d === filterTypeCap);
      const isCategoryFilter = categoryFilterIndex > -1;
      let isCardValid = (isFilterByCurrentMatter || isEmptyValue(tableId)) && !isCategoryFilter;

      if (!isCardValid) {
        if (isCategoryFilter) {
          const isPersonOutlookOrStaff = ['Staff', 'Outlook'].includes(filterTypeCap) && card.type === ECardType.People;
          isCardValid =
            filterTypeCap === 'Supplier' ? card.isSupplier : card.type === filterTypeCap || isPersonOutlookOrStaff;
        } else if (roles) {
          const tables: string[] = [].concat(roles.tables || []).concat(roles.subsTables || []);
          const index = tables.findIndex((id: string): boolean => id.replace('"', '') === tableId);
          isCardValid = index > -1;
        }
      }

      if (isCardValid && excludeEmptyFullName) {
        isCardValid = !isEmptyValue(card.fullName);
      }

      if (isCardValid && emailOnly && card.webAddressList) {
        const index = card.webAddressList.findIndex((add: IWebAddress): boolean => add.type === 'Email');
        isCardValid = index > -1;
      }

      // return (!card.deleteCode || card.deleteCode === 0) && isCardValid;
      return isCardValid; // we return all the valid cards including deleted cards
    });

    return Object.assign([], entries);
  }
}
