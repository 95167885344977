import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ILeapDropdownMoreAction, ILeapDropdownOptions, ISelectedTextFunc, IShortSelectedTextFunc } from '@app/shared/models';
import {
  getObjValue,
  isObjEqual,
  isFunction,
} from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-dropdown',
  templateUrl: './dropdown.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DropdownComponent), multi: true }],
})
export class DropdownComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  isDisabled: boolean;
  @Input()
  dropdownPlacement: string;
  @Input()
  options: ILeapDropdownOptions;
  dropdownMenuAlignRight: boolean;

  private _value: any;

  onChange: any = () => { };

  get itemClass(): (item: any) => string {
    return !!getObjValue(this.options, 'itemClass') ? this.options.itemClass : () => '';
  }

  get itemLabelVisible(): (item: any) => boolean {
    return !!getObjValue(this.options, 'itemLabelVisible') ? this.options.itemLabelVisible : () => true;
  }

  get itemSelectable(): (item: any) => boolean {
    return !!getObjValue(this.options, 'itemSelectable') ? this.options.itemSelectable : () => true;
  }

  get itemClassRule(): (item: any) => any {
    // If there exists a custom class rule, extend the default 'active' rule with the custom rule.
    if (!!getObjValue(this.options, 'itemClassRule')) {
      return (item: any) => {
        const classRuleFunc = isFunction(this.options.itemClassRule)
          ? this.options.itemClassRule
          : () => this.options.itemClassRule;
        return { active: isObjEqual(item, this._value), ...classRuleFunc(item) };
      };
    }
    return (item: any) => ({ active: isObjEqual(item, this._value) });
  }

  get itemStyleRule(): (item: any) => any {
    if (!!getObjValue(this.options, 'itemStyleRule')) {
      return isFunction(this.options.itemStyleRule) ? this.options.itemStyleRule : () => this.options.itemStyleRule;
    }
    return () => { };
  }

  get moreAction(): ILeapDropdownMoreAction {
    return getObjValue(this.options, 'moreAction', undefined);
  }

  get selectedText(): string {
    if (!this.options) {
      return '';
    }

    if (isFunction(this.options.selectedText)) {
      return (this.options.selectedText as ISelectedTextFunc)(this._value);
    }

    return this.options.selectedText as string;
  }

  get shortSelectedText(): string {
    if (!!getObjValue(this.options, 'shortSelectedText')) {
      if (isFunction(this.options.shortSelectedText)) {
        return (this.options.shortSelectedText as IShortSelectedTextFunc)(this._value);
      }

      return this.options.shortSelectedText as string;
    }

    return this.selectedText;
  }

  get iconButton(): boolean {
    return getObjValue(this.options, 'iconButton', false);
  }

  get iconClass(): string {
    return getObjValue(this.options, 'iconClass', '');
  }

  get wrapperClass(): string {
    return getObjValue(this.options, 'wrapperClass', '');
  }

  get dropdownMenuClass(): string {
    return getObjValue(this.options, 'dropdownMenuClass', '');
  }

  get dropdownMenuStyle(): any {
    return getObjValue(this.options, 'dropdownMenuStyle', {});
  }

  constructor() { }

  ngOnInit() {
    if (this.dropdownPlacement === 'bottom right') {
      this.dropdownMenuAlignRight = true;
    }
  }

  ngOnDestroy(): void { }

  writeValue(obj: any): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void { }

  itemClicked(item: any): void {
    if (!this.isDisabled) {
      this._value = item;
      if (!!getObjValue(this.options, 'itemClicked')) {
        this.options.itemClicked(item);
      } else {
        this.onChange(item);
      }
    }
  }

  dropdownClicked($event: Event): void {
    if (this.iconButton) {
      $event.stopImmediatePropagation();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
