import { Action } from '@ngrx/store';
import { IComment, IDoc } from '@app/shared/models';

// Draft Comment
export const DRAFT_COMMENT = '[Comment] Draft Comment';
export const DRAFT_COMMENT_SUCCESS = '[Comment] Draft Comment success';
export const DRAFT_COMMENT_FAILURE = '[Comment] Draft Comment failure';

// Get Comment
export const GET_COMMENT = '[Comment] fetch';
export const GET_COMMENT_SUCCESS = '[Comment] fetch success';
export const GET_COMMENT_FAILURE = '[Comment] fetch failure';

// Edit or Add Comment
export const SAVE_COMMENT = '[Comment] Save/Update';
export const SAVE_COMMENT_SUCCESS = '[Comment] Save/Update success';
export const SAVE_COMMENT_FAILURE = '[Comment] Save/Update failure';

export const RESET_STATE = '[Comment] Reset AppState';
export const CLEAR_ERROR = '[Comment] Clear Error from State';

// hydrate comment
export const HYDRATE_COMMENT_FORM = '[Comment] hydrate comment form';
export const HYDRATE_COMMENT_FORM_SUCCESS = '[Comment] hydrate comment form success';
export const HYDRATE_COMMENT_FORM_FAILURE = '[Comment] hydrate comment form failure';

// dehydrate comment
export const DEHYDRATE_COMMENT_FORM = '[Comment] dehydrate comment form';
export const DEHYDRATE_COMMENT_FORM_SUCCESS = '[Comment] dehydrate comment form success';
export const DEHYDRATE_COMMENT_FORM_FAILURE = '[Comment] dehydrate comment form failure';

export const COMMENT_MODAL_CLOSED = '[Comment] modal closed';

export class DraftComment implements Action {
  readonly type = DRAFT_COMMENT;
  constructor(public payload: any) {}
}

export class DraftCommentSuccess implements Action {
  readonly type = DRAFT_COMMENT_SUCCESS;
  constructor(public payload: IComment | IDoc) {}
}

export class DraftCommentFailure implements Action {
  readonly type = DRAFT_COMMENT_FAILURE;
  constructor(public payload: any) {}
}

export class GetComment implements Action {
  readonly type = GET_COMMENT;
  constructor(public payload: { commentId: string }) {}
}

export class GetCommentSuccess implements Action {
  readonly type = GET_COMMENT_SUCCESS;
  constructor(public payload: any) {}
}

export class GetCommentFailure implements Action {
  readonly type = GET_COMMENT_FAILURE;
  constructor(public payload: any) {}
}

export class SaveComment implements Action {
  readonly type = SAVE_COMMENT;
  constructor(public payload: any) {}
}

export class SaveCommentSuccess implements Action {
  readonly type = SAVE_COMMENT_SUCCESS;
  constructor(public payload: any) {}
}

export class SaveCommentFailure implements Action {
  readonly type = SAVE_COMMENT_FAILURE;
  constructor(public payload: any) {}
}

export class ResetState implements Action {
  readonly type = RESET_STATE;
  constructor(public payload: any) {}
}

export class ClearError implements Action {
  readonly type = CLEAR_ERROR;
  constructor(public payload: any) {}
}

//  hydrate comment
export class HydrateCommentForm implements Action {
  readonly type = HYDRATE_COMMENT_FORM;
  constructor(public payload: any) {}
}

export class HydrateCommentFormSuccess implements Action {
  readonly type = HYDRATE_COMMENT_FORM_SUCCESS;
  constructor(public payload: any) {}
}

export class HydrateCommentFormFailure implements Action {
  readonly type = HYDRATE_COMMENT_FORM_FAILURE;
  constructor(public payload: any) {}
}

//  hydrate comment
export class DeHydrateCommentForm implements Action {
  readonly type = DEHYDRATE_COMMENT_FORM;
  constructor(public payload: any) {}
}

export class DeHydrateCommentFormSuccess implements Action {
  readonly type = DEHYDRATE_COMMENT_FORM_SUCCESS;
  constructor(public payload: any) {}
}

export class DeHydrateCommentFormFailure implements Action {
  readonly type = DEHYDRATE_COMMENT_FORM_FAILURE;
  constructor(public payload: any) {}
}

export class CommentModalClosed implements Action {
  readonly type = COMMENT_MODAL_CLOSED;
  constructor(public payload: {
    isSaved: boolean;
    isNew: boolean;
  }) {}
}

export type CommentActions =
  | DraftComment
  | DraftCommentSuccess
  | DraftCommentFailure
  | GetComment
  | GetCommentSuccess
  | GetCommentFailure
  | SaveComment
  | SaveCommentSuccess
  | SaveCommentFailure
  | HydrateCommentForm
  | HydrateCommentFormSuccess
  | HydrateCommentFormFailure
  | DeHydrateCommentForm
  | DeHydrateCommentFormSuccess
  | DeHydrateCommentFormFailure
  | ResetState
  | ClearError
  | CommentModalClosed;
