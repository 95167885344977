import { Action } from '@ngrx/store';
import { IMatterListEntry } from '@app/features/+matter-list/models';
import { IDefinableTable, IMatterCard, IMatterDetailEntry, IPersonActingActionParams } from '@app/shared/models';
import { IMatterDetailsGetParams, IMatterDetailsApiResponse } from '@app/features/+matter-details/models';
import { LayoutRendererCriticalDate } from '@app/features/+layout-renderer/models/layout-renderer.model';

export const GET_CURRENT_MATTER = '[Current matter] get';
export const CURRENT_MATTER_SUCCESS = '[Current matter] succcess';
export const CURRENT_MATTER_FAILURE = '[Current matter] failure';

export const GET_MATTER_DETAILS_START = '[Get matter details] start';
export const GET_MATTER_DETAILS_SUCCESS = '[Get matter details] succcess';
export const GET_MATTER_DETAILS_FAILURE = '[Get matter details] failure';

export const GET_MATTER_CARDS_START = '[Get matter cards] start';
export const GET_MATTER_CARDS_SUCCESS = '[Get matter cards] succcess';
export const GET_MATTER_CARDS_FAILURE = '[Get matter cards] failure';

export const REORDER_MATTER_DETAIL_ENTRIES_START = '[Reorder matter-detail entries] start';
export const REORDER_MATTER_DETAIL_ENTRIES_SUCCESS = '[Reorder matter-detail entries] success';
export const REORDER_MATTER_DETAIL_ENTRIES_Fail = '[Reorder matter-detail entries] fail';

export const REORDER_RELATIONS_MATTER_DETAIL_ENTRIES_START = '[Reorder relations matter-detail entries] start';

export const ADD_ACTING_PERSON_START = '[Add acting person] start';
export const REMOVE_ACTING_PERSON_START = '[Remove acting person] start';
export const PERSON_ACTING_SUCCESS = '[Person acting] succcess';
export const PERSON_ACTING_FAILURE = '[Person acting] failure';

export const SET_CURRENT_DETAIL_ENTRY = '[Current matter] set current on-action detail entry';
export const SET_DETAIL_ENTRIES = '[Current matter] set detail entries';

export const GO_TO_FORMS_PRECEDENTS = '[Current Matter] go to Forms & Precedents page';
export const GO_TO_MATTER_OPTIONS_MODAL_PAGE = '[Matter Options] go to matter options modal page';

export const UPDATE_LAYOUT_RENDERER_CRITICAL_DATES_START =
  '[Current Matter] update layout renderer critical dates start';
export const UPDATE_LAYOUT_RENDERER_CRITICAL_DATES_SUCCESS =
  '[Current Matter] update layout renderer critical dates success';
export const UPDATE_LAYOUT_RENDERER_CRITICAL_DATES_FAILURE =
  '[Current Matter] update layout renderer critical dates failure';

export const UPDATE_MATTER_DETAIL_ENTRIES = '[Current Matter] update matter detail entries';

export class GetCurrentMatter implements Action {
  readonly type = GET_CURRENT_MATTER;

  constructor(public payload: { matterId: string }) {}
}

export class CurrentMatterSuccess implements Action {
  readonly type = CURRENT_MATTER_SUCCESS;

  constructor(public payload: IMatterListEntry) {}
}

export class CurrentMatterFailure implements Action {
  readonly type = CURRENT_MATTER_FAILURE;

  constructor(public payload: string) {}
}

export class GetMatterDetailsStart implements Action {
  readonly type = GET_MATTER_DETAILS_START;

  constructor(public payload: IMatterDetailsGetParams) {}
}

export class GetMatterDetailsSuccess implements Action {
  readonly type = GET_MATTER_DETAILS_SUCCESS;

  constructor(public payload: IMatterDetailsApiResponse) {}
}

export class GetMatterDetailsFailure implements Action {
  readonly type = GET_MATTER_DETAILS_FAILURE;

  constructor(public payload: any) {}
}

export class GetMatterCardsStart implements Action {
  readonly type = GET_MATTER_CARDS_START;

  constructor(public payload: string) {}
}

export class GetMatterCardsSuccess implements Action {
  readonly type = GET_MATTER_CARDS_SUCCESS;

  constructor(public payload: IMatterCard[]) {}
}

export class GetMatterCardsFailure implements Action {
  readonly type = GET_MATTER_CARDS_FAILURE;

  constructor(public payload: any) {}
}

export class AddActingPersonStart implements Action {
  readonly type = ADD_ACTING_PERSON_START;

  constructor(public payload: IPersonActingActionParams) {}
}

export class RemoveActingPersonStart implements Action {
  readonly type = REMOVE_ACTING_PERSON_START;

  constructor(public payload: IPersonActingActionParams) {}
}

export class ReorderMatterDetailEntriesStart implements Action {
  readonly type = REORDER_MATTER_DETAIL_ENTRIES_START;

  constructor(public payload: IMatterDetailEntry[]) {}
}

export class ReorderMatterDetailEntriesSuccess implements Action {
  readonly type = REORDER_MATTER_DETAIL_ENTRIES_SUCCESS;

  constructor(public payload: any) {}
}

export class ReorderMatterDetailEntriesFail implements Action {
  readonly type = REORDER_MATTER_DETAIL_ENTRIES_Fail;

  constructor(public payload: any) {}
}

export class PersonActingSuccess implements Action {
  readonly type = PERSON_ACTING_SUCCESS;

  constructor(public payload: any) {}
}

export class PersonActingFailure implements Action {
  readonly type = PERSON_ACTING_FAILURE;

  constructor(public payload: any) {}
}

export class SetCurrentDetailEntry implements Action {
  readonly type = SET_CURRENT_DETAIL_ENTRY;

  constructor(public payload: { detailEntry: IMatterDetailEntry }) {}
}

export class SetDetailEntries implements Action {
  readonly type = SET_DETAIL_ENTRIES;

  constructor(public payload: { detailEntries: IMatterDetailEntry[] }) {}
}

export class UpdateMatterDetailEntries implements Action {
  readonly type = UPDATE_MATTER_DETAIL_ENTRIES;

  // entries has both IMatterDetailEntry and IDefinableTable
  constructor(public payload: { matterId: string; entries: IMatterDetailEntry[] }) {}
}

export class ReorderRelationsMatterDetailsStart implements Action {
  readonly type = REORDER_RELATIONS_MATTER_DETAIL_ENTRIES_START;
  constructor(public payload: IMatterDetailEntry[]) {}
}

export class GoToFormsPrecedents implements Action {
  readonly type = GO_TO_FORMS_PRECEDENTS;

  constructor(public payload: any) {}
}

export class GoToMatterOptionsModalPage implements Action {
  readonly type = GO_TO_MATTER_OPTIONS_MODAL_PAGE;

  constructor(public payload: any) {}
}

export class UpdateLayoutRendererCriticalDatesStart {
  readonly type = UPDATE_LAYOUT_RENDERER_CRITICAL_DATES_START;
  constructor(public payload: { matterId: string; criticalDates: LayoutRendererCriticalDate }) {}
}

export class UpdateLayoutRendererCriticalDatesSuccess {
  readonly type = UPDATE_LAYOUT_RENDERER_CRITICAL_DATES_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateLayoutRendererCriticalDatesFailure {
  readonly type = UPDATE_LAYOUT_RENDERER_CRITICAL_DATES_FAILURE;
  constructor(public payload: any) {}
}

export type CurrentMatterApiActions =
  | GoToFormsPrecedents
  | GoToMatterOptionsModalPage
  | GetCurrentMatter
  | CurrentMatterSuccess
  | CurrentMatterFailure
  | GetMatterDetailsStart
  | GetMatterDetailsSuccess
  | GetMatterDetailsFailure
  | GetMatterCardsStart
  | GetMatterCardsSuccess
  | GetMatterCardsFailure
  | AddActingPersonStart
  | RemoveActingPersonStart
  | ReorderMatterDetailEntriesStart
  | ReorderMatterDetailEntriesSuccess
  | ReorderMatterDetailEntriesFail
  | PersonActingSuccess
  | PersonActingFailure
  | SetCurrentDetailEntry
  | SetDetailEntries
  | UpdateMatterDetailEntries
  | ReorderRelationsMatterDetailsStart
  | UpdateLayoutRendererCriticalDatesStart
  | UpdateLayoutRendererCriticalDatesSuccess
  | UpdateLayoutRendererCriticalDatesFailure;
