import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sc-popover-body',
  templateUrl: './popover-body.component.html',
})
export class PopoverBodyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
