import { OverlayConfig } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';
import { ErrorHandlerCompData } from '@app/features/error-handler/interfaces/error-handler.interfaces';

export const DEFAULT_OVERLAY_CONFIG: OverlayConfig = {
  hasBackdrop: true,
};

export const DEFAULT_ERROR_HANDLER_COMP_DATA: ErrorHandlerCompData = {
  type: 'error',
  message: 'An unknown error has occurred.',
  title: 'Error',
  actionBtnText: 'Close',
  showCancel: false,
  cancelBtnText: 'Cancel',
};

export const ERROR_INJECTOR_TOKEN: InjectionToken<ErrorHandlerCompData> = new InjectionToken('ErrorInjectorToken');
