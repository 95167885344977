<button
  #button
  type="button"
  class="btn mw-100 text-truncate"
  [ngClass]="btnClass"
  [disabled]="disabled || isLoading"
  [tabindex]="tabindex"
  [class.x-btn-loading]="isLoading"
  scPreventDefault
  (click)="onBtnClick($event)"
>
  <span class="x-btn-label w-90">
    <ng-container *ngIf="!!value">{{ value }}</ng-container>
    <ng-content *ngIf="!value"></ng-content>
  </span>
  <sc-pre-load *ngIf="isLoading" icon="kebab"></sc-pre-load>
</button>
