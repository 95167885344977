import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { isFunction } from '@server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-initials-cell',
  templateUrl: './initials-cell.component.html',
})
export class InitialsCellComponent implements ICellRendererAngularComp {
  wrapperClass: string;
  staffInitials: string;
  isHidden: boolean;
  tooltip: string;

  constructor() {}

  agInit(params: any): void {
    this.isHidden = params?.isHidden ? params.isHidden(params.data) : false;
    this.wrapperClass = params?.getWrapperClass ? params.getWrapperClass(params.data) : '';
    this.staffInitials = this.isHidden ? '' : params.getStaffInitials(params.data)?.substring(0, 3) ?? '';
    this.tooltip = isFunction(params['getTooltip']) ? params.getTooltip(params.data) : null;
  }

  refresh(params: any): boolean {
    return true;
  }
}
