<div class="d-inline-flex w-100">
  <div
    class="w-100 {{ wrapperClass }}"
    dropdown
    container="body"
    placement="bottom right"
    [isDisabled]="disabled"
    (isOpenChange)="onOpenChange($event)"
  >
    <a
      *ngIf="iconOnly; else BtnWithIcon"
      class="d-flex"
      href
      scPreventDefault
      dropdownToggle
      aria-haspopup="true"
      [attr.aria-expanded]="isDropdownMenuOpen"
      aria-controls="dropdown-menu"
    >
      <sc-icon class="x-ag-icon" [iconName]="iconClass"></sc-icon>
    </a>
    <ng-template #BtnWithIcon>
      <button
        class="btn x-btn-icon dropdown-toggle x-max-width-240 {{ buttonClass }}"
        type="button"
        dropdownToggle
        aria-haspopup="true"
        [attr.aria-expanded]="isDropdownMenuOpen"
        aria-controls="dropdown-menu"
      >
        <sc-icon *ngIf="!!iconClass" [iconName]="iconClass"></sc-icon>
        <span class="text-truncate w-100">{{ buttonText }}</span>
      </button>
    </ng-template>
    <ul
      id="dropdown-menu"
      class="x-dropdown-filter dropdown-menu dropdown-menu-right"
      aria-labelledby="dropdown-menu"
      *dropdownMenu
      role="menu"
      @xAnimationScale
    >
      <ng-container *ngFor="let option of options; index as i; trackBy: trackByFn">
        <ng-container [ngSwitch]="true">
          <ng-container *ngSwitchCase="!!option && !!option.header">
            <li class="dropdown-header" role="menuitem">
              <span [style.fontWeight]="600">{{ getOptionText(option) }}</span>
            </li>
          </ng-container>

          <ng-container *ngSwitchCase="!!option && !!option.divider">
            <li class="dropdown-divider" role="menuitem"></li>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <li
              class="dropdown-item"
              role="menuitem"
              [class.active]="isOptionSelected(option)"
              (click)="selectOption(option)"
            >
              <sc-icon iconName="tick"></sc-icon>
              {{ getOptionText(option) }}
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</div>
