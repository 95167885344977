import { mergeMap, withLatestFrom, switchMap, catchError, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import { MatterSearchService, MatterSearchStorageService } from '../../services';
import { IMatterSearchState } from '../../models';
import { AdvancedSearchMode, EMattersFilter } from '../../constants';

import * as matterSearchActions from '../actions/matter-search';
import * as matterListActions from '../actions/matter-list';
import { selectMatterSearch } from '../selectors';
import { State, extractMatterSearch, initialState } from '../reducers';
import { PlatformService } from '@app/core/services';

@Injectable()
export class MatterSearchEffects {

  loadSearchQueryFromDb$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(matterSearchActions.loadMattersSearchStart),
    filter(() => this.platformService.isBrowser),
    switchMap(() =>
      this.matterSearchStorageSvc.getMeta().pipe(
        mergeMap((data) => [
          matterSearchActions.loadMattersSearchSuccess({ payload: data }),
          ...getCloudSearchActionList(data, this.matterSearchSvc),
        ]),
        catchError(() => of(matterSearchActions.searchSaveDbStart({ payload: extractMatterSearch(initialState.matterSearch) })))
      )
    )
  ));

  searchMatters$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(matterSearchActions.searchMattersStart),
    withLatestFrom(this.store.pipe(select(selectMatterSearch)), (action, matterSearch) => matterSearch),
    switchMap((matterSearch) => [
      matterSearchActions.searchSaveDbStart({ payload: matterSearch }),
      ...getCloudSearchActionList(matterSearch, this.matterSearchSvc),
    ])
  ));

  searchMattersCloudStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(matterSearchActions.searchMattersCloudStart),
    withLatestFrom(this.store.pipe(select(selectMatterSearch)), (action, matterSearch) => ({ action, matterSearch })),
    switchMap((stateData: { action: ReturnType<typeof matterSearchActions.searchMattersCloudStart>; matterSearch: IMatterSearchState }) => {
      const payload = stateData.action.payload;
      if (!payload) {
        return this.matterSearchSvc
          .searchMatters(
            {
              searchText: stateData.matterSearch.searchText,
              archivedOnly: stateData.matterSearch.filterBy.includeAllMatters,
            },
            AdvancedSearchMode.QuickSearch
          )
          .pipe(
            mergeMap((data) => [
              matterSearchActions.searchMattersSuccess({ payload: null }),
              matterListActions.CloudSearchSuccess({ result: {searchMode: AdvancedSearchMode.QuickSearch, data }}),
            ]),
            catchError((error) => of(matterSearchActions.searchMattersCloudFailure({ payload: error })))
          );
      } else if (payload === EMattersFilter.AdvancedSearch) {
        return this.matterSearchSvc
          .searchMatters(
            { SearchTerms: stateData.matterSearch.advancedSearch.searchTerms },
            AdvancedSearchMode.AdvancedSearch
          )
          .pipe(
            mergeMap((data) => [
              matterSearchActions.searchMattersSuccess({ payload: null }),
              matterListActions.CloudSearchSuccess({ result: {searchMode: AdvancedSearchMode.AdvancedSearch, data }}),
            ]),
            catchError((error) => of(matterSearchActions.searchMattersCloudFailure({ payload: error })))
          );
      } else {
        return this.matterSearchSvc.searchMatters(payload, AdvancedSearchMode.AccountingSearch).pipe(
          mergeMap((data) => [
            matterSearchActions.searchMattersSuccess({ payload: null }),
            matterListActions.CloudSearchSuccess({ result: {searchMode: AdvancedSearchMode.AccountingSearch, data }}),
            matterSearchActions.searchMattersCloudSuccess({ payload: {searchMode: AdvancedSearchMode.AccountingSearch, data }}),
          ]),
          catchError((error) => of(matterSearchActions.searchMattersCloudFailure({ payload: error })))
        );
      }
    })
  ));

  saveSearchQueryToDb$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(matterSearchActions.searchSaveDbStart),
    withLatestFrom(this.store.pipe(select(selectMatterSearch)), (action, matterSearch) => matterSearch),
    filter(() => this.platformService.isBrowser),
    switchMap((matterSearch: IMatterSearchState) =>
      this.matterSearchStorageSvc
        .upsertAll(matterSearch)
        .then(() => matterSearchActions.searchSaveDbSuccess({ payload: 'success' }))
        .catch((error) => matterSearchActions.searchSaveDbFailure({ payload: error }))
    )
  ));

  constructor(
    private actions$: Actions,
    private matterSearchSvc: MatterSearchService,
    private platformService: PlatformService,
    private matterSearchStorageSvc: MatterSearchStorageService,
    private store: Store<State>
  ) {}
}

const getCloudSearchActionList = (matterSearch: IMatterSearchState, matterSearchSvc: MatterSearchService): Action[] => {
  let filterKey = matterSearch.filterBy ? matterSearch.filterBy.filterKey : undefined;
  filterKey = matterSearchSvc.getTransformedFilterKey(filterKey);

  const isAccountingSearch = !!filterKey && filterKey !== EMattersFilter.AdvancedSearch;
  const isAdvancedSearch =
    filterKey === EMattersFilter.AdvancedSearch && matterSearch.advancedSearch.includeArchivedMatters;
  const isInMemorySearch = !isAccountingSearch && !isAdvancedSearch && !matterSearch.filterBy.includeAllMatters;

  return isInMemorySearch
    ? [matterSearchActions.searchMattersSuccess({ payload: null })]
    : [
        matterSearchActions.searchMattersCloudStart({ payload: filterKey }),
        matterListActions.CloudSearchStart({ searchQuery: null }),
      ];
};
