import { createSelector } from '@ngrx/store';

import * as fromCurrentMatter from '../reducers/current-matter.reducer';
import { State, selectMatterDetailsState } from '../reducers';
import { selectCurrentMatter } from '@app/core/store/selectors';
import { IMatterListEntry } from '@app/features/+matter-list/models';

export const selectCurrentMatterState = createSelector(selectMatterDetailsState, (state: State) => state.currentMatter);

export const selectCurrentMatterLoading = createSelector(selectCurrentMatterState, (state: fromCurrentMatter.State) =>
  fromCurrentMatter.selectLoading(state)
);

export const selectCurrentMatterInitialising = createSelector(
  selectCurrentMatterState,
  (state: fromCurrentMatter.State) => fromCurrentMatter.selectInitialising(state)
);

export const selectCurrentMatterError = createSelector(selectCurrentMatterState, (state: fromCurrentMatter.State) =>
  fromCurrentMatter.selectError(state)
);

export const selectCurrentMatterCardEntries = createSelector(
  selectCurrentMatterState,
  (state: fromCurrentMatter.State) => fromCurrentMatter.selectMatterCards(state)
);

export const selectDetailEntries = createSelector(selectCurrentMatterState, (state: fromCurrentMatter.State) =>
  fromCurrentMatter.selectDetailEntries(state)
);

export const selectDetailInfo = createSelector(
  selectCurrentMatterState,
  selectCurrentMatter,
  (state: fromCurrentMatter.State, matterEntry: IMatterListEntry) => {
    const currentDetailInfo = fromCurrentMatter.selectDetailInfo(state);
    if (!matterEntry) {
      return currentDetailInfo;
    }
    const { customDescription, matterState } = matterEntry;
    return {
      ...currentDetailInfo,
      matterState,
      customDescription,
    };
  }
);

export const selectMatterCards = createSelector(selectCurrentMatterState, (state: fromCurrentMatter.State) =>
  fromCurrentMatter.selectMatterCards(state)
);

export const selectCurrentDetailEntry = createSelector(selectCurrentMatterState, (state: fromCurrentMatter.State) =>
  fromCurrentMatter.selectCurrentDetailEntry(state)
);

export const selectMatterCardRelationships = createSelector(
  selectCurrentMatterState,
  (state: fromCurrentMatter.State) => fromCurrentMatter.selectMatterCardRelationships(state)
);
