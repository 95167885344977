<div class="x-input-group" *ngIf="!isDisabled">
  <ng-select
    #matters
    dropdownPosition="auto"
    placeholder="Select Matter..."
    [items]="matterList"
    [virtualScroll]="true"
    bindLabel="customDescription"
    bindValue="matterId"
    (change)="matterSelected($event)"
    [clearable]="false"
    [searchFn]="matterSelectionSearchFunc"
    [(ngModel)]="value"
    [ngClass]="inputClass"
  >
    <ng-template ng-label-tmp let-item="item"> {{ matterLabel(item) }} </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <span class="text-truncate">{{ matterLabel(item) }}</span>
    </ng-template>
  </ng-select>
  <div
    [ngClass]="iconButton ? 'x-input-group-append' : 'input-group-append'"
    *ngIf="!!matterList && matterList.length > 0"
  >
    <button class="btn x-btn-default btn-xs" *ngIf="!iconButton" (click)="openMatterSelector()">Select</button>
    <button class="btn x-btn-default x-btn-icon-only" *ngIf="iconButton" (click)="openMatterSelector()">
      <sc-icon class="x-icon-sm" iconName="browse"></sc-icon>
    </button>
  </div>
</div>

<div class="x-input-group" *ngIf="isDisabled">
  <input disabled class="form-control" [value]="matterLabelReadonly()" />
</div>
