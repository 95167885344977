import { IDocsFolder, Correspondence, IDoc } from '../../../shared/models';
import { CorrespondenceFilterOption } from '../models';
import { isFolder } from '../../../shared/functions';
import { folderDesendantsByExt } from './folders.function';
import { sortDescBy } from '../../../../../server/modules/shared/functions/common-util.functions';

export const rowCollectionProject = (root: IDocsFolder) => {
  if (!root) {
    return [];
  }
  return toRowCollection(root);
};

export const toRowCollection = (root: IDocsFolder): Correspondence[] => {
  const folders = root.folders;
  const collection: Correspondence[] = [];

  const documents = root.fullDocuments?.reduce(
    (docGroup: IDoc[][], document: IDoc): IDoc[][] => {
      if (document.pin) {
        docGroup[0].push(document);
      } else {
        docGroup[1].push(document);
      }
      return docGroup;
    },
    [[], []]
  );

  documents[0] = sortDescBy(documents[0], (document) => document.pin);

  return [...collection, ...documents[0], ...folders, ...documents[1]];
};

// Takes root as the parameter
export const extendCorrespondenceWithHierarchyProp = (root: IDocsFolder, folderIds?: string[]): Correspondence[] => {
  const documentFromFolder =
    root.folders.length > 0
      ? root.folders
          .map((folder) =>
            extendCorrespondenceWithHierarchyProp(folder, (folderIds ?? []).concat(folder.id || '')).flatMap((n) => n)
          )
          .flatMap((m) => m)
      : [];

  return [{ ...root, hierarchy: folderIds } as Correspondence]
    .concat(documentFromFolder ?? [])
    .concat(root.fullDocuments?.map((d) => ({ ...d, hierarchy: (folderIds ?? []).concat(d.id) })) ?? []);
};

// Takes correspondence list as the parameter
export const extendCorrespondenceCollectionWithHierarchyProp = (
  data: Correspondence[],
  folderIds?: string[]
): Correspondence[] => {
  const documentFromFolder =
    data.length > 0
      ? data
          .filter((d) => isFolder(d))
          .map((folder: IDocsFolder) =>
            extendCorrespondenceWithHierarchyProp(folder, (folderIds ?? []).concat(folder.id || '')).flatMap((n) => n)
          )
          .flatMap((m) => m)
      : [];

  return ([
    ...(data.filter((d) => !isFolder(d)).map((d: IDoc) => ({ ...d, hierarchy: [d.id] })) ?? []),
  ] as Correspondence[]).concat(documentFromFolder ?? []);
};

export const extendFoldersWithHierarchyProp = (root: IDocsFolder, folderIds?: string[]): IDocsFolder[] => {
  const folders =
    root.folders.length > 0
      ? root.folders
          .map((folder) =>
            extendFoldersWithHierarchyProp(folder, (folderIds ?? []).concat(folder.id || '')).flatMap(
              (n) => n as IDocsFolder
            )
          )
          .flatMap((m) => m)
      : [];

  return [{ ...root, hierarchy: folderIds } as IDocsFolder].concat(folders ?? []);
};

export const findDocument = (id: string, root: IDocsFolder): IDoc => {
  const matchedDoc = root.fullDocuments?.find((x) => x.id === id);

  if (!!matchedDoc) {
    return matchedDoc;
  }

  return root.folders?.reduce(
    (result: IDoc, folder: IDocsFolder) => (!!result ? result : findDocument(id, folder)),
    undefined
  );
};

export const filterExt = (item: Correspondence[], filters: CorrespondenceFilterOption[]) => {
  const exts: string[] = [];
  filters.forEach((f) => exts.push(...f.ext));

  return item
    .map((i) => {
      if (isFolder(i)) {
        return folderDesendantsByExt(i as IDocsFolder, exts);
      } else {
        const doc = i as IDoc;
        if (!!doc.ext && exts.includes(doc.ext.toLowerCase())) {
          return doc;
        }
      }
    })
    .filter((x) => !!x);
};
