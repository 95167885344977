import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as actions from '../actions';
import { AppApiService } from '@app/core/api';

@Injectable()
export class TaskCodeSelectorEffects {

  staffSelectorModalSelect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.TaskCodeSelectorModalSelect>(actions.TASK_CODE_SELECTOR_MODAL_SELECT),
    switchMap(() => [new actions.TaskCodeSelectorModalClose(null)])
  ));


  staffSelectorModalClose$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.TaskCodeSelectorModalClose>(actions.TASK_CODE_SELECTOR_MODAL_CLOSE),
    switchMap(() => {
      this._appApiSvc.clearCurrentModal();
      return [];
    })
  ), { dispatch: false });

  constructor(private actions$: Actions, private _appApiSvc: AppApiService, private _store: Store<any>) {}
}
