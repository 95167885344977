import { Injectable } from '@angular/core';
import { ICostRecovery } from '@app/features/+cost-recovery-ledger/models/costRecoveries.model';
import { ITimeFee } from '@app/features/+time-fee-ledger/models';
import { environment } from '@env/environment';
import {
  CreateCostRecoveryFormData,
  CreateFeeEntryFormData,
  CreateInvoiceFormData,
  CreateOfficeJournalFormData,
  CreateOfficePaymentFormData,
  CreateOfficeReceiptFormData,
  CreateTimeEntryFormData,
  CreateTrustJournalFormData,
  CreateTrustPaymentFormData,
  CreateTrustReceiptFormData,
  CreateTrustToOfficeFormData,
  DisbursementJournalItem,
  LeapField,
  OfficePaymentItem,
  OfficePaymentTypeDetails,
  OfficeReceiptItem,
  PaymentTypeDetails,
  TrustJournalItem,
  TrustPaymentItem,
  TrustPaymentTypeDetails,
  TrustReceiptItem,
  TrustToOfficeInvoiceItem,
  TrustToOfficePaymentTypeDetails,
} from '../../models/leapapp-data.model';

enum ConfigPropType {
  date = 'date',
  string = 'string', // eslint-disable-line
  number = 'number', // eslint-disable-line
  bool = 'bool',
}

@Injectable({
  providedIn: 'root',
})
export class AccoutingMappingService {
  constructor() {}

  mapLeapAppToFee = (payloadFromLeapApp: CreateFeeEntryFormData): ITimeFee => {
    const config = [
      ['TaskCodeGUID', 'taskCodeId', ConfigPropType.string],
      ['TaxCodeGUID', 'taxCodeId', ConfigPropType.string],
      ['FeeHoursQuantity', 'quantity', ConfigPropType.number],
      ['RatePerHour', 'amountEach', ConfigPropType.number],
      ['IncTax', 'includeTax', ConfigPropType.bool],
      ['TransactionDate', 'transactionDate', ConfigPropType.date],
      ['BillingDescription', 'billingDescription', ConfigPropType.string],
      ['BillingMode', 'billingMode', ConfigPropType.number],
      ['Memo', 'memo', ConfigPropType.string],
      ['WorkDoneByStaffGUID', 'staffId', ConfigPropType.string],
    ];

    return this.baseMapTo<ITimeFee, CreateFeeEntryFormData>(config, payloadFromLeapApp);
  };

  mapLeapAppToTimeEntry = (payloadFromLeapApp: CreateTimeEntryFormData): { detail: ITimeFee; hasData: boolean } => {
    const config = [
      ['TaskCodeGUID', 'taskCodeId', ConfigPropType.string],
      ['TaxCodeGUID', 'taxCodeId', ConfigPropType.string],
      ['FeeUnits', 'feeUnits', ConfigPropType.number],
      ['SecondsPerUnit', 'secondsPerUnit', ConfigPropType.number],
      ['RatePerHour', 'ratePerHour', ConfigPropType.number],
      ['IncTax', 'includeTax', ConfigPropType.bool],
      ['TransactionDate', 'transactionDate', ConfigPropType.date],
      ['RateId', 'billingRateId', ConfigPropType.number],
      ['BillingDescription', 'billingDescription', ConfigPropType.string],
      ['BillingMode', 'billingMode', ConfigPropType.number],
      ['Memo', 'memo', ConfigPropType.string],
      ['WorkDoneByStaffGUID', 'staffId', ConfigPropType.string],
      ['SecondsElapsed', 'secondsElapsed', ConfigPropType.number]
    ];

    const detail = this.baseMapTo<ITimeFee, CreateTimeEntryFormData>(config, payloadFromLeapApp);

    return { detail, hasData: detail && Object.keys(detail).length > 0 };
  };

  mapLeapAppToOfficePayment = (payloadFromLeapApp: CreateOfficePaymentFormData): any => {
    const config = [
      [LeapField.BankAccountGUID, 'bankAccountId', ConfigPropType.string],
      [LeapField.PaymentNumber, 'paymentNumber', ConfigPropType.string],
      [LeapField.AutoNumber, 'autoNumber', ConfigPropType.bool],
      [LeapField.PayToName, 'payToName', ConfigPropType.string],
      [LeapField.PayToAddressee, 'payToAddress', ConfigPropType.string],
      [LeapField.TransactionDate, 'transactionDate', ConfigPropType.date],
      [LeapField.PaymentTypeGUID, 'paymentTypeId', ConfigPropType.string],
      [LeapField.PaymentSent, 'paymentSent', ConfigPropType.bool],
    ];

    const paymentTypeConfig = [
      [LeapField.BSB, 'bsb', ConfigPropType.string],
      [LeapField.AccountNumber, 'accountNumber', ConfigPropType.string],
      [LeapField.AccountName, 'accountName', ConfigPropType.string],
      [LeapField.Addressee, 'payableTo', ConfigPropType.string],
      [LeapField.Memo, 'memo', ConfigPropType.string],
    ];

    const paymentItemConfig = [
      [LeapField.MatterGUID, 'matterId', ConfigPropType.string],
      [LeapField.BillingDescription, 'description', ConfigPropType.string],
      [LeapField.AmountIncTax, 'amountIncTax', ConfigPropType.number],
      [LeapField.TaxCodeGUID, 'taxCodeId', ConfigPropType.string],
    ];

    const detail = this.baseMapTo<any, CreateOfficePaymentFormData>(config, payloadFromLeapApp);
    const paymentType = this.baseMapTo<any, PaymentTypeDetails>(
      paymentTypeConfig,
      payloadFromLeapApp?.paymentTypeDetails
    );
    const items =
      payloadFromLeapApp?.officePaymentItems
        ?.map((i) => this.baseMapTo<any, OfficePaymentItem>(paymentItemConfig, i))
        .filter(Boolean) || [];

    return { detail, paymentType, items, hasData: !!detail || !!paymentType || items?.length > 0 };
  };

  mapLeapAppToCostRecovery = (payloadFromLeapApp: CreateCostRecoveryFormData): ICostRecovery => {
    // TransactionDate use string because cost-recovery-page.component has a method to translate it to string and date
    const config = [
      ['TaskCodeGUID', 'taskCodeId', ConfigPropType.string],
      ['TaxCodeGUID', 'taxCodeId', ConfigPropType.string],
      ['Quantity', 'quantity', ConfigPropType.number],
      ['TransactionDate', 'transactionDate', ConfigPropType.string],
      ['BillingDescription', 'billingDescription', ConfigPropType.string],
      ['BillingMode', 'billingMode', ConfigPropType.number],
      ['AmountEach', 'amountEach', ConfigPropType.number],
      ['IncTax', 'includeTax', ConfigPropType.bool],
    ];

    return this.baseMapTo<ICostRecovery, CreateCostRecoveryFormData>(config, payloadFromLeapApp);
  };

  mapLeapAppToInvoice = (payloadFromLeapApp: CreateInvoiceFormData): ICostRecovery => {
    const config = [
      [LeapField.BillToAddressee, 'invoiceTo', ConfigPropType.string],
      [LeapField.TransactionNumber, 'invoiceNumber', ConfigPropType.string],
      [LeapField.AutoNumber, 'autoNumber', ConfigPropType.bool],
      [LeapField.TransactionDate, 'transactionDate', ConfigPropType.date],
      [LeapField.DateDue, 'dueDate', ConfigPropType.date],
      [LeapField.Memo, 'memo', ConfigPropType.string],
      [LeapField.Status, 'status', ConfigPropType.number],
      [LeapField.InvoiceLayoutGUID, 'layoutId', ConfigPropType.string],
    ];

    return this.baseMapTo<ICostRecovery, CreateInvoiceFormData>(config, payloadFromLeapApp);
  };

  mapLeapAppToTrustReceipt = (payloadFromLeapApp: CreateTrustReceiptFormData): any => {
    const config = [
      [LeapField.BankAccountGUID, 'bankAccountId', ConfigPropType.string],
      [LeapField.TransactionNumber, 'receiptNumber', ConfigPropType.string],
      [LeapField.TransactionNumberAuto, 'autoNumber', ConfigPropType.bool],
      [LeapField.ReceivedFrom, 'receivedFrom', ConfigPropType.string],
      [LeapField.TransactionDate, 'transactionDate', ConfigPropType.date],
      [LeapField.PaymentTypeGUID, 'paymentTypeId', ConfigPropType.string],
    ];

    const paymentTypeConfig = [
      [LeapField.BSB, 'bsb', ConfigPropType.string],
      [LeapField.AccountNumber, 'accountNumber', ConfigPropType.string],
      [LeapField.AccountName, 'accountName', ConfigPropType.string],
      [LeapField.Memo, 'memo', ConfigPropType.string],
      [LeapField.Addressee, 'payableTo', ConfigPropType.string],
      [LeapField.Drawer, 'drawer', ConfigPropType.string],
      [LeapField.ChequeNumber, 'chequeNumber', ConfigPropType.string],
      [LeapField.AuthorisationNumber, 'authorisationNumber', ConfigPropType.string],
      [LeapField.ExpiryDate, 'expiryDate', ConfigPropType.string],
      [LeapField.BillerCode, 'billerCode', ConfigPropType.string],
      [LeapField.BillerReference, 'billerReference', ConfigPropType.string],
    ];

    const trustItemConfig = [
      [LeapField.MatterGUID, 'matterId', ConfigPropType.string],
      [LeapField.Reason, 'reason', ConfigPropType.string],
      [LeapField.Amount, 'amount', ConfigPropType.number],
    ];

    const detail = this.baseMapTo<any, CreateTrustReceiptFormData>(config, payloadFromLeapApp);
    const paymentType = this.baseMapTo<any, TrustPaymentTypeDetails>(
      paymentTypeConfig,
      payloadFromLeapApp?.paymentTypeDetails
    );
    const items =
      payloadFromLeapApp?.trustReceiptItems
        ?.map((i) => this.baseMapTo<any, TrustReceiptItem>(trustItemConfig, i))
        .filter(Boolean) || [];

    return { detail, paymentType, items, hasData: !!detail || !!paymentType || items?.length > 0 };
  };

  mapLeapAppToTrustPayment = (payloadFromLeapApp: CreateTrustPaymentFormData): any => {
    const config = [
      [LeapField.BankAccountGUID, 'bankAccountId', ConfigPropType.string],
      [LeapField.TransactionNumber, 'paymentNumber', ConfigPropType.string],
      [LeapField.TransactionNumberAuto, 'autoNumber', ConfigPropType.bool],
      [LeapField.PayToName, 'payToName', ConfigPropType.string],
      [LeapField.PayToAddressee, 'payToAddress', ConfigPropType.string],
      [LeapField.TransactionDate, 'transactionDate', ConfigPropType.date],
      [LeapField.PaymentTypeGUID, 'paymentTypeId', ConfigPropType.string],
      [LeapField.PaymentProcessed, 'paymentSent', ConfigPropType.bool],
    ];

    const paymentTypeConfig = [
      [LeapField.BSB, 'bsb', ConfigPropType.string],
      [LeapField.AccountNumber, 'accountNumber', ConfigPropType.string],
      [LeapField.AccountName, 'accountName', ConfigPropType.string],
      [LeapField.Addressee, 'payableTo', ConfigPropType.string],
      [LeapField.Memo, 'memo', ConfigPropType.string],
    ];

    const trustItemConfig = [
      [LeapField.MatterGUID, 'matterId', ConfigPropType.string],
      [LeapField.Reason, 'reason', ConfigPropType.string],
      [LeapField.Amount, 'amount', ConfigPropType.number],
    ];

    const detail = this.baseMapTo<any, CreateTrustPaymentFormData>(config, payloadFromLeapApp);
    const paymentType = this.baseMapTo<any, PaymentTypeDetails>(
      paymentTypeConfig,
      payloadFromLeapApp?.paymentTypeDetails
    );
    const items =
      payloadFromLeapApp?.trustPaymentItems
        ?.map((i) => this.baseMapTo<any, TrustPaymentItem>(trustItemConfig, i))
        .filter(Boolean) || [];

    return { detail, paymentType, items, hasData: !!detail || !!paymentType || items?.length > 0 };
  };

  mapLeapAppToTrustJournal = (payloadFromLeapApp: CreateTrustJournalFormData): any => {
    const config = [
      [LeapField.BankAccountGUID, 'bankAccountId', ConfigPropType.string],
      [LeapField.AuthorisedByGUID, 'staffId', ConfigPropType.string],
      [LeapField.TransactionDate, 'transactionDate', ConfigPropType.date],
    ];

    const trustJournalItemConfig = [
      [LeapField.MatterGUID, 'matterId', ConfigPropType.string],
      [LeapField.Reason, 'reason', ConfigPropType.string],
      [LeapField.AmountWithdrawal, 'amountWithdrawal', ConfigPropType.number],
      [LeapField.AmountDeposit, 'amountDeposit', ConfigPropType.number],
    ];

    const detail = this.baseMapTo<any, CreateTrustJournalFormData>(config, payloadFromLeapApp);

    const items =
      payloadFromLeapApp?.trustJournalItems
        ?.map((i) => this.baseMapTo<any, TrustJournalItem>(trustJournalItemConfig, i))
        .filter(Boolean) || [];

    return { detail, items, hasData: !!detail || items?.length > 0 };
  };

  mapLeapAppToOfficeJournal = (payloadFromLeapApp: CreateOfficeJournalFormData): any => {
    const config = [
      [LeapField.WriteOffMode, 'journalType', ConfigPropType.number],
      [LeapField.TransactionDate, 'transactionDate', ConfigPropType.date],
    ];

    const disbursementJournalItemConfig = [
      [LeapField.MatterGUID, 'matterId', ConfigPropType.string],
      [LeapField.Description, 'reason', ConfigPropType.string],
      [LeapField.AmountIncTax, 'amountIncTax', ConfigPropType.number],
      [LeapField.TaxCodeGUID, 'taxCodeId', ConfigPropType.string],
    ];

    const detail = this.baseMapTo<any, CreateOfficeJournalFormData>(config, payloadFromLeapApp);

    const items =
      payloadFromLeapApp?.disbursementJournalItems
        ?.map((i) => this.baseMapTo<any, DisbursementJournalItem>(disbursementJournalItemConfig, i))
        .filter(Boolean) || [];

    return { detail, items, hasData: !!detail || items?.length > 0 };
  };

  mapLeapAppToOfficeReceipt = (payloadFromLeapApp: CreateOfficeReceiptFormData): any => {
    const config = [
      [LeapField.BankAccountGUID, 'bankAccountId', ConfigPropType.string],
      [LeapField.TransactionNumber, 'receiptNumber', ConfigPropType.string],
      [LeapField.TransactionNumberAuto, 'autoNumber', ConfigPropType.bool],
      [LeapField.MailTo, 'mailingAddress', ConfigPropType.string],
      [LeapField.TransactionDate, 'transactionDate', ConfigPropType.date],
      [LeapField.PaymentTypeGUID, 'paymentTypeId', ConfigPropType.string],
      [LeapField.Memo, 'memo', ConfigPropType.string],
    ];

    const paymentTypeConfig = [
      [LeapField.BSB, 'bsb', ConfigPropType.string],
      [LeapField.AccountNumber, 'accountNumber', ConfigPropType.string],
      [LeapField.AccountName, 'accountName', ConfigPropType.string],
      [LeapField.Memo, 'memo', ConfigPropType.string],
      [LeapField.Addressee, 'payableTo', ConfigPropType.string],
      [LeapField.Drawer, 'drawer', ConfigPropType.string],
      [LeapField.ChequeNumber, 'chequeNumber', ConfigPropType.string],
      [LeapField.AuthorisationNumber, 'authorisationNumber', ConfigPropType.string],
      [LeapField.ExpiryDate, 'expiryDate', ConfigPropType.string],
      [LeapField.BillerCode, 'billerCode', ConfigPropType.string],
      [LeapField.BillerReference, 'billerReference', ConfigPropType.string],
    ];

    const officeItemConfig = [
      [LeapField.InvoiceGUID, 'invoiceId', ConfigPropType.string],
      [LeapField.SplitCardGUID, 'splitCardId', ConfigPropType.string],
      [LeapField.Amount, 'applyAmount', ConfigPropType.number],
    ];

    const detail = this.baseMapTo<any, CreateOfficeReceiptFormData>(config, payloadFromLeapApp);
    const paymentType = this.baseMapTo<any, OfficePaymentTypeDetails>(
      paymentTypeConfig,
      payloadFromLeapApp?.paymentTypeDetails
    );
    const items =
      payloadFromLeapApp?.officeReceiptItems
        ?.map((i) => this.baseMapTo<any, OfficeReceiptItem>(officeItemConfig, i))
        .filter(Boolean) || [];

    return { detail, paymentType, items, hasData: !!detail || !!paymentType || items?.length > 0 };
  };

  mapLeapAppToTrustToOffice = (payloadFromLeapApp: CreateTrustToOfficeFormData): any => {
    const config = [
      [LeapField.TrustAccountGUID, 'trustAccountId', ConfigPropType.string],
      [LeapField.OfficeAccountGUID, 'officeAccountId', ConfigPropType.string],
      [LeapField.PayToName, 'payToName', ConfigPropType.string],
      [LeapField.PayToAddress, 'payToAddress', ConfigPropType.string],
      [LeapField.Reason, 'reason', ConfigPropType.string],
      [LeapField.TransactionNumber, 'receiptNumber', ConfigPropType.string],
      [LeapField.TransactionNumberAuto, 'autoReceiptNumber', ConfigPropType.bool],
      [LeapField.PaymentNumber, 'paymentNumber', ConfigPropType.string],
      [LeapField.PaymentNumberAuto, 'autoPaymentNumber', ConfigPropType.bool],
      [LeapField.TransactionDate, 'transactionDate', ConfigPropType.date],
      [LeapField.PaymentTypeGUID, 'paymentTypeId', ConfigPropType.string],
    ];

    const paymentTypeConfig = [
      [LeapField.BSB, 'bsb', ConfigPropType.string],
      [LeapField.AccountNumber, 'accountNumber', ConfigPropType.string],
      [LeapField.AccountName, 'accountName', ConfigPropType.string],
      [LeapField.Memo, 'memo', ConfigPropType.string],
      [LeapField.Addressee, 'payableTo', ConfigPropType.string],
      [LeapField.Drawer, 'drawer', ConfigPropType.string],
      [LeapField.ChequeNumber, 'chequeNumber', ConfigPropType.string],
      [LeapField.AuthorisationNumber, 'authorisationNumber', ConfigPropType.string],
      [LeapField.ExpiryDate, 'expiryDate', ConfigPropType.string],
      [LeapField.BillerCode, 'billerCode', ConfigPropType.string],
      [LeapField.BillerReference, 'billerReference', ConfigPropType.string],
      [LeapField.Notes, 'notes', ConfigPropType.number],
      [LeapField.Coins, 'coins', ConfigPropType.number],
    ];

    const detail = this.baseMapTo<any, CreateTrustToOfficeFormData>(config, payloadFromLeapApp);
    const paymentType = this.baseMapTo<any, TrustToOfficePaymentTypeDetails>(
      paymentTypeConfig,
      payloadFromLeapApp?.paymentTypeDetails
    );

    return { detail, paymentType, hasData: !!detail || !!paymentType };
  };

  private validatePayloadFields = <T>(config: any[], data: T): void => {
    const fields = config.map((c) => c[1]);
    const payloadFields = Object.keys(data);

    fields.forEach((field) => {
      if (!payloadFields.includes(field)) {
        console.warn(`leaphost has removed the field ${field}`);
      }
    });
  };

  private baseMapTo = <T, K>(config, payloadFromLeapApp: K): T => {
    if (payloadFromLeapApp === undefined || Object.keys(payloadFromLeapApp).length === 0) {
      return undefined;
    }

    if (!environment.production) {
      this.validatePayloadFields<K>(config, payloadFromLeapApp);
    }

    const payload = {};

    config.forEach(([field, appField, propType]) => {
      if (payloadFromLeapApp[appField] !== undefined && payloadFromLeapApp[appField] !== null) {
        if (propType === ConfigPropType.date) {
          const newDate = !isNaN(Date.parse(payloadFromLeapApp[appField]))
            ? new Date(payloadFromLeapApp[appField])
            : undefined;
          if (newDate) {
            payload[field] = newDate;
          }
        } else {
          payload[field] = payloadFromLeapApp[appField];
        }
      }
    });

    return Object.keys(payload).length > 0 && (payload as T);
  };
}
