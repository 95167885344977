import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { isFunction, stringToStartCase } from '@server/modules/shared/functions/common-util.functions';

@Injectable({
  providedIn: 'root',
})
export class AccountingLogService {
  constructor(private _toastrSvc: ToastrService, private _translateSvc: TranslateService) {}

  logAnticipatedPayment(params: Partial<IAccountingLogParams>): void {
    this.logAccountingAction({ context: this._translateSvc.instant('AnticipatedPayment.Single'), ...params });
  }

  logCostRecovery(params: Partial<IAccountingLogParams>): void {
    this.logAccountingAction({
      context: this._translateSvc.instant('CostRecovery.Action.ViewCostRecovery'),
      ...params,
    });
  }

  logOfficeInvoice(params: Partial<IAccountingLogParams>): void {
    this.logAccountingAction({ context: 'Office Invoice', ...params });
  }

  logOfficeJournal(params: Partial<IAccountingLogParams>): void {
    this.logAccountingAction({ context: 'Office Journal', ...params });
  }

  logOfficePayment(params: Partial<IAccountingLogParams>): void {
    this.logAccountingAction({ context: this._translateSvc.instant('PaymentDetails.Label.OfficePayment'), ...params });
  }

  logOfficeReceipt(params: Partial<IAccountingLogParams>): void {
    this.logAccountingAction({
      context: this._translateSvc.instant('PaymentDebtors.OfficeReceipt.Heading.Label'),
      ...params,
    });
  }

  logPaymentRequest(params: Partial<IAccountingLogParams>): void {
    this.logAccountingAction({
      context: this._translateSvc.instant('PaymentRequest.Heading.PaymentRequest'),
      ...params,
    });
  }

  logTrustJournal(params: Partial<IAccountingLogParams>): void {
    this.logAccountingAction({ context: this._translateSvc.instant('Trust.Journal.Label'), ...params });
  }

  logTrustPayment(params: Partial<IAccountingLogParams>): void {
    this.logAccountingAction({ context: this._translateSvc.instant('Trust.Payment.Label'), ...params });
  }

  logInvestmentAccount(params: Partial<IAccountingLogParams>): void {
    this.logAccountingAction({ ...params });
  }

  logPowerMoneyAccount(params: Partial<IAccountingLogParams>): void {
    this.logAccountingAction({ context: this._translateSvc.instant('Trust.PowerMoney.Account.Label'), ...params });
  }

  logTrustReceipt(params: Partial<IAccountingLogParams>): void {
    this.logAccountingAction({ context: this._translateSvc.instant('Trust.Receipt.Label'), ...params });
  }

  logTimeEntry(params: Partial<IAccountingLogParams>): void {
    this.logAccountingAction({ context: 'Time Entry', ...params });
  }

  logFeeEntry(params: Partial<IAccountingLogParams>): void {
    this.logAccountingAction({ context: 'Fee Entry', ...params });
  }

  logInvestmentTransaction(params: Partial<IAccountingLogParams>): void {
    this.logAccountingAction({ context: this._translateSvc.instant('TRUST_LABEL_TRANSACTION'), ...params });
  }

  logPowerMoneyTransaction(params: Partial<IAccountingLogParams>): void {
    this.logAccountingAction({ context: this._translateSvc.instant('Trust.PowerMoney.Transaction.Label'), ...params });
  }

  logCreditReceipt(params: Partial<IAccountingLogParams>): void {
    this.logAccountingAction({ context: this._translateSvc.instant('Credit.Title.CreditReceipt'), ...params });
  }

  logCreditRefund(params: Partial<IAccountingLogParams>): void {
    this.logAccountingAction({ context: this._translateSvc.instant('Credit.CreditRefund.Label'), ...params });
  }

  private logAccountingAction(params: Partial<IAccountingLogParams>): void {
    if (!params) {
      return;
    }
    params = { ...DefaultAccountingLog, ...params };

    const { accountingNumber, action, context, getMessage } = params;
    let logMessage = '';
      let logTitle = context;
    switch (action) {
      case 'create':
      case 'delete':
      case 'reverse':
      case 'update':
        logTitle = `${logTitle} ${stringToStartCase(action)}d`;
        logMessage = isFunction(getMessage)
          ? getMessage()
          : `${context}${!!accountingNumber ? ' Number ' + accountingNumber : ''} has been ${action}d successfully.`;
        break;
      case 'void':
        logMessage = `Cheque number ${accountingNumber} has been voided successfully.`;
        break;
      default:
        return;
    }
    this._toastrSvc.show(logMessage, logTitle, {}, 'success');
  }
}

type AccountingAction = 'create' | 'delete' | 'reverse' | 'update' | 'void';

interface IAccountingLogParams {
  accountingNumber: string;
  action: AccountingAction;
  context: string;
  getMessage?: () => string;
}

const DefaultAccountingLog = {
  accountingNumber: null,
  action: null,
  context: null,
  getMessage: null,
};
