import { Component, Inject, OnInit, HostBinding, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  ErrorHandlerCompData,
  ErrorHandlerCompResponse,
} from '@app/features/error-handler/interfaces/error-handler.interfaces';
import {
  DEFAULT_ERROR_HANDLER_COMP_DATA,
  ERROR_INJECTOR_TOKEN,
} from '@app/features/error-handler/constants/error-handler.constants';

@Component({
  selector: 'sc-error-handler',
  templateUrl: './error-handler.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .x-error-handler-panel {
        z-index: 1001;
      }
    `,
  ],
})
export class ErrorHandlerComponent implements OnInit {
  private isVisible = new Subject<ErrorHandlerCompResponse>();
  public dismiss$: Observable<ErrorHandlerCompResponse> = this.isVisible.asObservable();
  public compData: ErrorHandlerCompData;

  constructor(@Inject(ERROR_INJECTOR_TOKEN) public data: ErrorHandlerCompData) {}

  get textClass(): string {
    switch (this.compData.type) {
      case 'error': {
        return `text-danger`;
      }
      case 'warning': {
        return `text-warning`;
      }
      case 'info':
      case 'confirm': {
        return `text-primary`;
      }
    }
  }

  get actionBtnTextClass(): string {
    switch (this.compData.type) {
      case 'error': {
        return `btn-danger`;
      }
      case 'warning': {
        return `btn-warning`;
      }
      case 'info':
      case 'confirm': {
        return `btn-primary`;
      }
    }
  }

  @HostBinding('class.modal-content')
  ngOnInit() {
    this.compData = {
      ...DEFAULT_ERROR_HANDLER_COMP_DATA,
      ...this.data,
    };
  }

  dismiss() {
    this.isVisible.next({ isActionClicked: false });
    this.isVisible.complete();
  }

  handleClickOnActionBtn() {
    this.isVisible.next({ isActionClicked: true });
    this.isVisible.complete();
  }
}
