import { Action } from '@ngrx/store';
import {
  CreateAppointmentCommand,
  CreateCommand,
  CreateTaskCommand,
  OpenCommand,
  OpenTableCommand,
  PreviewDocumentCommand,
  ShareCommand,
  EmailDocumentsCommand,
  CreateDocumentFromPrecedentCommand,
  CreateDocumentFromContainerCommand,
} from '@app/shared/models/leap-host.models';

export const MATTER_INIT = '[Matter Addin] init';

export const MATTER_ADDIN_INIT_START = '[Matter Addin] start';
export const MATTER_ADDIN_INIT_SUCCESS = '[Matter Addin] success';
export const MATTER_ADDIN_INIT_FAILURE = '[Matter Addin] failure';

export const MATTER_ADDIN_ALL_INIT_START = '[Matter Addin] global start';
export const MATTER_ADDIN_ALL_INIT_SUCCESS = '[Matter Addin] global success';
export const MATTER_ADDIN_ALL_INIT_FAILURE = '[Matter Addin] global failure';

export const MATTER_ADDIN_CREATE_APPOINTMENT = '[Matter Addin] create appointment';
export const MATTER_ADDIN_CREATE_TASK = '[Matter Addin] create task';
export const MATTER_ADDIN_CREATE_DOCUMENT_FROM_PRECEDENT = '[Matter Addin] create document from precedent';
export const MATTER_ADDIN_CREATE_DOCUMENT_FROM_CONTAINER = '[Matter Addin] create document from container';
export const MATTER_ADDIN_CREATE_SEARCH = '[Matter Addin] create search';
export const MATTER_ADDIN_CREATE_OFFICE_PAYMENT = '[Matter Addin] create office payment';
export const MATTER_ADDIN_CREATE_TRUST_PAYMENT = '[Matter Addin] create trust payment';
export const MATTER_ADDIN_CREATE_TIME_ENTRY = '[Matter Addin] create time entry';
export const MATTER_ADDIN_OPEN_TABLE = '[Matter Addin] open definable table';
export const MATTER_ADDIN_SHARE_TRUST = '[Matter Addin] share trust';
export const MATTER_ADDIN_SHARE_BILLING = '[Matter Addin] share billing';
export const MATTER_ADDIN_SHARE_DOC = '[Matter Addin] share doc';
export const MATTER_ADDIN_SHARE_FOLDER = '[Matter Addin] share folder';
export const MATTER_ADDIN_OPEN_MATTER = '[Matter Addin] open matter';
export const MATTER_ADDIN_OPEN_PRECEDENT = '[Matter Addin] open precedent';
export const MATTER_ADDIN_OPEN_APP = '[Matter Addin] open app';
export const MATTER_ADDIN_OPEN_DOC = '[Matter Addin] open document';
export const MATTER_ADDIN_PREVIEW_DOC = '[Matter Addin] preview document';
export const MATTER_ADDIN_EMAIL_DOCUMENTS = '[Matter Addin] email documents';

export const MATTER_ADDIN_CLICKED = '[Matter Addin] translate';
export const OPEN_MATTER_ADDIN = '[Matter Addin] open';

export const OPEN_MATTER_ADDIN_TIME_ENTRY = '[Matter Addin] time entry open';

export class MatterAddinInit implements Action {
  readonly type = MATTER_INIT;
}

export class MatterAddinInitStart implements Action {
  readonly type = MATTER_ADDIN_INIT_START;

  constructor(public payload: { pageNumber: number }) {}
}

export class MatterAddinInitSuccess implements Action {
  readonly type = MATTER_ADDIN_INIT_SUCCESS;

  constructor(public payload: any) {}
}

export class MatterAddinInitFailure implements Action {
  readonly type = MATTER_ADDIN_INIT_FAILURE;

  constructor(public payload: any) {}
}

export class MatterAddinAllInitStart implements Action {
  readonly type = MATTER_ADDIN_ALL_INIT_START;

  constructor(public payload: any) {}
}

export class MatterAddinAllInitSuccess implements Action {
  readonly type = MATTER_ADDIN_ALL_INIT_SUCCESS;

  constructor(public payload: any) {}
}

export class MatterAddinAllInitFailure implements Action {
  readonly type = MATTER_ADDIN_ALL_INIT_FAILURE;

  constructor(public payload: any) {}
}

export class MatterAddinClicked implements Action {
  readonly type = MATTER_ADDIN_CLICKED;

  constructor(public payload: { addinItem: any; transactions?: any }) {}
}

export class OpenMatterAddin implements Action {
  readonly type = OPEN_MATTER_ADDIN;

  constructor(public payload: any) {}
}

export class OpenMatterAddinTimeEntry implements Action {
  readonly type = OPEN_MATTER_ADDIN_TIME_ENTRY;

  constructor(public payload: any) {}
}

export class MatterAddinCreateAppointment implements Action {
  readonly type = MATTER_ADDIN_CREATE_APPOINTMENT;

  constructor(public payload: CreateAppointmentCommand) {}
}

export class MatterAddinCreateTask implements Action {
  readonly type = MATTER_ADDIN_CREATE_TASK;

  constructor(public payload: CreateTaskCommand) {}
}

export class MatterAddinCreateDocumentFromPrecedent implements Action {
  readonly type = MATTER_ADDIN_CREATE_DOCUMENT_FROM_PRECEDENT;

  constructor(public payload: CreateDocumentFromPrecedentCommand) {}
}

export class MatterAddinCreateDocumentFromContainer implements Action {
  readonly type = MATTER_ADDIN_CREATE_DOCUMENT_FROM_CONTAINER;

  constructor(public payload: CreateDocumentFromContainerCommand) {}
}

export class MatterAddinCreateSearch implements Action {
  readonly type = MATTER_ADDIN_CREATE_SEARCH;

  constructor(public payload: CreateCommand) {}
}

export class MatterAddinCreateOfficePayment implements Action {
  readonly type = MATTER_ADDIN_CREATE_OFFICE_PAYMENT;

  constructor(public payload: CreateCommand) {}
}

export class MatterAddinCreateTrustPayment implements Action {
  readonly type = MATTER_ADDIN_CREATE_TRUST_PAYMENT;

  constructor(public payload: CreateCommand) {}
}

export class MatterAddinCreateTimeEntry implements Action {
  readonly type = MATTER_ADDIN_CREATE_TIME_ENTRY;

  constructor(public payload: CreateCommand) {}
}

export class MatterAddinOpenTable implements Action {
  readonly type = MATTER_ADDIN_OPEN_TABLE;

  constructor(public payload: OpenTableCommand) {}
}

export class MatterAddinShareTrust implements Action {
  readonly type = MATTER_ADDIN_SHARE_TRUST;

  constructor(public payload: ShareCommand) {}
}

export class MatterAddinShareBilling implements Action {
  readonly type = MATTER_ADDIN_SHARE_BILLING;

  constructor(public payload: ShareCommand) {}
}

export class MatterAddinShareDoc implements Action {
  readonly type = MATTER_ADDIN_SHARE_DOC;

  constructor(public payload: ShareCommand) {}
}

export class MatterAddinShareFolder implements Action {
  readonly type = MATTER_ADDIN_SHARE_FOLDER;

  constructor(public payload: ShareCommand) {}
}

export class MatterAddinOpenMatter implements Action {
  readonly type = MATTER_ADDIN_OPEN_MATTER;

  constructor(public payload: OpenCommand) {}
}

export class MatterAddinOpenPrecedent implements Action {
  readonly type = MATTER_ADDIN_OPEN_PRECEDENT;

  constructor(public payload: OpenCommand) {}
}

export class MatterAddinOpenApp implements Action {
  readonly type = MATTER_ADDIN_OPEN_APP;

  constructor(public payload: OpenCommand) {}
}

export class MatterAddinOpenDoc implements Action {
  readonly type = MATTER_ADDIN_OPEN_DOC;

  constructor(public payload: OpenCommand) {}
}

export class MatterAddinPreviewDoc implements Action {
  readonly type = MATTER_ADDIN_PREVIEW_DOC;

  constructor(public payload: PreviewDocumentCommand) {}
}

export class MatterAddinEmailDocuments implements Action {
  readonly type = MATTER_ADDIN_EMAIL_DOCUMENTS;

  constructor(public payload: EmailDocumentsCommand) {}
}

export type MatterAddinActions =
  | MatterAddinInit
  | MatterAddinInitStart
  | MatterAddinInitSuccess
  | MatterAddinInitFailure
  | MatterAddinAllInitStart
  | MatterAddinAllInitSuccess
  | MatterAddinAllInitFailure
  | MatterAddinClicked
  | MatterAddinCreateAppointment
  | MatterAddinCreateTask
  | MatterAddinCreateDocumentFromPrecedent
  | MatterAddinCreateDocumentFromContainer
  | MatterAddinCreateSearch
  | MatterAddinCreateOfficePayment
  | MatterAddinCreateTrustPayment
  | MatterAddinCreateTimeEntry
  | MatterAddinOpenTable
  | MatterAddinShareTrust
  | MatterAddinShareBilling
  | MatterAddinShareDoc
  | MatterAddinShareFolder
  | MatterAddinOpenMatter
  | MatterAddinOpenDoc
  | MatterAddinOpenPrecedent
  | MatterAddinOpenApp
  | MatterAddinPreviewDoc
  | MatterAddinEmailDocuments
  | OpenMatterAddin
  | OpenMatterAddinTimeEntry;
