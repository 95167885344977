
import * as LDClient from 'launchdarkly-js-client-sdk';
import { AuthAgent } from '@leapdev/auth-agent';
import { environment } from '@env/environment';

/**
 * Initialise LaunchDarkly.
 */
export function initLaunchDarkly() {
  return new Promise<void>(async (resolve, reject) => {
    const clientId = environment.config.featureFlag.client_id;

    const ldUser: LDClient.LDMultiKindContext = {
      kind: 'multi',
      user: {
        key: 'anonymous-user',
        anonymous: true,
      },
      environment: {
        name: 'environment',
        key: `${environment.config.brand.env}-${environment.config.brand.region}`,
        environment: environment.config.brand.env,
        region: environment.config.brand.region,
        brand: environment.config.brand.name.toLocaleLowerCase(),
      }
    }

    globalThis.ldClient = LDClient.initialize(clientId, ldUser);
  
    globalThis.ldClient.on('ready', () => {
      resolve();
    });

    globalThis.ldClient.on('failed', (err) => {
      console.error('LD Failed:', err);
      reject(err);
    });
  });
}
  
export async function updateLaunchDarkly() {
  try {
    const userInfo = await AuthAgent.userInfo();
    if (userInfo) {
      const ldUser: LDClient.LDMultiKindContext = {
        kind: 'multi',
        user: {
          key: 'leap:' + userInfo.userId,
          anonymous: false,
          staff_id: userInfo.staffId,
        },
        environment: {
          name: 'environment',
          key: `${environment.config.brand.env}-${environment.config.brand.region}`,
          environment: environment.config.brand.env,
          region: environment.config.brand.region,
          brand: environment.config.brand.name.toLocaleLowerCase(),
        },
        firm: {
          key: userInfo.firmId,
        }
      }
  
      await globalThis.ldClient.identify(ldUser);
  
    }
  } catch (err) {
    console.log('Not able to use AuthAgent for context')
  }
}
  