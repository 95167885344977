<label *ngIf="!!label" [for]="inputId">{{ label | translate }}</label>
<p-calendar
  class="w-100"
  [inputId]="inputId"
  [styleClass]="styleClass"
  [(ngModel)]="value"
  [placeholder]="placeholder"
  [disabled]="isDisabled"
  [dateFormat]="dateFormat"
  [showOtherMonths]="false"
  [showIcon]="!hideIcon"
  [inline]="inlineMode"
  [ngClass]="inputClass"
  baseZIndex="1040"
  (onBlur)="handleBlurOnInput()"
  (onSelect)="handleSelectDate($event)"
  appendTo="body"
  #pCalendar
>
</p-calendar>
