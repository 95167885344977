import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  HostBinding,
  ChangeDetectorRef,
} from '@angular/core';

import { ActivityCode } from '@app/features/fee-timer/models/fee-timer.model';
import { group, query, transition, trigger, useAnimation } from '@angular/animations';
import {
  xAnimationFadeIn,
  xAnimationFadeOut,
  xAnimationHeightCollapse,
  xAnimationHeightExpand,
} from '@app/shared/animations/animations';

@Component({
  selector: 'sc-time-activity-list',
  templateUrl: './time-activity-list.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('xAnimation', [
      transition(':enter', [
        group([
          query('.x-timer-dropdown-content', [useAnimation(xAnimationHeightExpand)]),
          query('.x-toolbar-actions', [useAnimation(xAnimationFadeIn)]),
        ]),
      ]),
      transition(':leave', [
        group([
          query('.x-timer-dropdown-content', [useAnimation(xAnimationHeightCollapse)]),
          query('.x-toolbar-actions', [useAnimation(xAnimationFadeOut)]),
        ]),
      ]),
    ]),
  ],
})
export class TimeActivityListComponent implements OnInit {
  @Input() source: ActivityCode[];
  @Input() filteredSource: ActivityCode[];
  @Input() queryText: string;
  @Output() selected = new EventEmitter<ActivityCode>();
  @Output() collapseBtnClicked = new EventEmitter<boolean>();

  get activities(): ActivityCode[] {
    if (this.queryText) {
      return this.filteredSource;
    }
    return this.source;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  @HostBinding('@xAnimation')
  ngOnInit() {}

  trackByFn(index, activity) {
    return activity.taskCodeGUID;
  }

  onCollapseBtnClicked(event: any) {
    this.collapseBtnClicked.emit(event);
    this.cdr.markForCheck();
  }

  onSelected(activityCode: ActivityCode, event: Event): void {
    this.selected.emit(activityCode);
    this.cdr.markForCheck();
    this.onCollapseBtnClicked(event);
  }
}
