import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as SentryBrowser from '@sentry/browser';

import * as appAction from '@app/core/store/actions';
import { AuthService } from '../auth/auth.service';
import { LogService } from '../log/log.service';
import { PlatformService } from '../platform/platform.service';
import { AppApiService } from '@app/core/api';
import { BaseService } from '@app/shared/services/base/base.service';
import { HttpClient } from '@angular/common/http';
import { selectUserDetails } from '@app/core/store/selectors';
import { filter } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import { UserInfo } from '@leapdev/auth-agent/src/lib/types';

declare let dataLayer: any;

@Injectable({
  providedIn: 'root',
})
export class StartupService extends BaseService {
  private _userDetails: UserInfo;
  private _storeUserDetails: UserInfo;

  constructor(
    private _platformSvc: PlatformService,
    private _appApiSvc: AppApiService,
    private _authSvc: AuthService,
    private _log: LogService,
    private _http: HttpClient,
    private _store: Store
  ) {
    super();
    this._log.init('startup-service');

    this._store
      .pipe(
        select(selectUserDetails),
        filter((ud) => !!ud)
      )
      .subscribe((userDetails) => (this._storeUserDetails = { ...userDetails }));
  }

  // This is the method you want to call at bootstrap
  // Important: It should return a Promise
  async load() {
    this._log.info('startup loading...');
    this._userDetails = null;

    if (this._platformSvc.isBrowser) {
      this._log.info('browser startup...');
      this._log.info(this._authSvc.decodedToken);

      this._userDetails = await this._authSvc.userDetails();
      const firmConsent = await this._authSvc.statusAdminConsent();

      this._store.dispatch(new appAction.UpdateFirmConsent(firmConsent));
      this._authSvc.registerEventListener('support', 'request', (data) => this._appApiSvc.dispatchSupportRequest(data));

      this._log.info('got user details...', this._userDetails);
      this.setupAnalytics(this._userDetails);
      this.setUserContext(this._userDetails);
    } else {
      // server logic
      this._log.info('server startup...');
      return;
    }
  }

  async setUserDetails() {
    // user details and table mappings
    this._userDetails = await this._authSvc.userDetails();
    this._log.info('got user details...', this._userDetails);
  }

  get userDetails(): UserInfo {
    return this._storeUserDetails || this._userDetails;
  }

  updateUserRegion(newRegion: string) {
    this._userDetails = { ...this._userDetails, region: newRegion };
  }

  private setUserContext(userDetails: UserInfo) {
    this._log.info('Setting sentry setUserContext');
    if (!!userDetails) {
      SentryBrowser.getCurrentScope()
        .setUser({
          id: userDetails.staffId,
        })
        .setExtra('FirmId', userDetails.firmId)
        .setExtra('StaffId', userDetails.staffId);
    }
  }

  private setupAnalytics(userDetails: UserInfo) {
    this._log.info('setupAnalytics');
    if (typeof dataLayer !== 'undefined' && dataLayer) {
      dataLayer.push({
        staffId: userDetails.staffId,
        firmId: userDetails.firmId,
      });
    }
  }

  async bootstrapPrecedentView() {
    if (!!this._authSvc.decodedToken) {
      //todo: new endpoint - it can be removed after migrating to new api.
      await firstValueFrom(this._http.get(`${this.schemaPath}/api/v2/precedents/primefirmview`, { responseType: 'text' }));
    } else {
      this._log.warn('primefirmview not called because of missing auth token');
    }
  }
}
