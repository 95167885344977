import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AutoTimeActionTypeId,
  AutoTimeActionTypes,
  AutoTimeEndTaskActionTypes,
  AutoTimeResourceType,
  AutoTimeResourceTypeId,
  AutoTimeStartTaskActionTypes,
  IAutoTimeEndTaskPayload,
  IAutoTimeEntry,
  IAutoTimeEntryUpdateResponse,
  IAutoTimeExternalJSON,
  IAutoTimeStartTaskPayload,
} from '../models/autotime.model';
import { v4 as uuidv4 } from 'uuid';
import { BaseService } from '@app/shared/services';
import { Observable, of } from 'rxjs';
import * as commentActions from '@app/features/+comment/store/actions';
import * as emailActions from '@app/features/+email/store/actions';
import * as previewActions from '@app/features/+preview/store';
import * as precedentActions from '@app/features/+precedent/store';
import { isComment, isEmail } from '@app/shared/functions';
import { DurationUnit, formatDateHttp, getDuration } from '@app/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class AutoTimeService extends BaseService {
  buildNewTimeEntry(
    matterId: string,
    staffId: string,
    firmId: string,
    start: Date,
    task: IAutoTimeExternalJSON,
    end = new Date()
  ): IAutoTimeEntry {
    return {
      matterId,
      staffId,
      firmId,
      feeId: uuidv4(),
      timesInSeconds: [getDuration(start, end, DurationUnit.Seconds)],
      externalJson: JSON.stringify([task]),
      billingDescription: this.getBillingDescription([task]),
      transactionDate: formatDateHttp(end),
    };
  }

  sendUpdateRequest(autotime: IAutoTimeEntry): Observable<IAutoTimeEntryUpdateResponse> {
    const validation = this.validateAutoTimeEntry(autotime);
    if (!validation.valid) {
      return of({ Message: validation.errorMessage } as IAutoTimeEntryUpdateResponse);
    }

    return this.http.put<IAutoTimeEntryUpdateResponse>(`${this.accountingPath}/api/v1/autotime`, autotime);
  }

  sendDeleteRequest(feeId: string, firmId: string): Observable<IAutoTimeEntryUpdateResponse> {
    if (!feeId || !firmId) {
      return of({ Message: 'Missing required parameter' } as IAutoTimeEntryUpdateResponse);
    }

    return this.http.delete<IAutoTimeEntryUpdateResponse>(
      `${this.accountingPath}/api/v1/autotime/delete?feeId=${feeId}&firmId=${firmId}`
    );
  }

  getBillingDescription(tasks: IAutoTimeExternalJSON[]): string {
    const groupedByResource = tasks.reduce((prev, curr) => {
      const match = prev[curr.resourceType] || {
        actions: [],
        total: 0,
      };

      if (!!curr.actionType && !match.actions.includes(curr.actionType)) {
        match.actions.push(curr.actionType);
      }
      match.total += curr.total;
      prev[curr.resourceType] = match;
      return prev;
    }, []);

    return Object.keys(groupedByResource)
      .map(Number)
      .reduce((desc, resourceType) => {
        const match = groupedByResource[resourceType];
        const resourceName = AutoTimeResourceType[resourceType].name;
        const actionsDesc = this.getActionsDescription(resourceType, match.actions);
        const total = resourceType === AutoTimeResourceTypeId.BY_LAWYERS_RESEARCH ? '' : `${match.total} `;
        return `${desc}${total}${resourceName}${match.total > 1 ? 's' : ''} ${actionsDesc} `.trimEnd();
      }, '');
  }

  getStartTaskPayload(action): IAutoTimeStartTaskPayload {
    if (!AutoTimeStartTaskActionTypes.includes(action.type)) {
      return null;
    }

    switch (action.type) {
      case commentActions.GET_COMMENT_SUCCESS:
        return {
          resourceType: AutoTimeResourceTypeId.COMMENT,
          actionType: AutoTimeActionTypeId.REVIEWED,
        };

      case commentActions.DRAFT_COMMENT_SUCCESS:
        return {
          resourceType: AutoTimeResourceTypeId.COMMENT,
          actionType: AutoTimeActionTypeId.DRAFTED,
        };

      case emailActions.CREATE_DRAFT_SUCCESS:
        return {
          resourceType: AutoTimeResourceTypeId.EMAIL,
          actionType: AutoTimeActionTypeId.DRAFTED,
        };

      case previewActions.PREVIEW_DOCUMENT_LOADED:
        return this.getPreviewStartTaskPayload(action);

      case precedentActions.PRECEDENT_MODAL_OPENED:
        return {
          resourceType: AutoTimeResourceTypeId.BY_LAWYERS_RESEARCH,
          actionType: AutoTimeActionTypeId.RESEARCHED,
        };

      default:
        return null;
    }
  }

  getEndTaskPayload(action): IAutoTimeEndTaskPayload {
    if (!AutoTimeEndTaskActionTypes.includes(action.type)) {
      return null;
    }

    switch (action.type) {
      case commentActions.COMMENT_MODAL_CLOSED:
        return this.getCommentEndTaskPayload(action);

      case emailActions.EMAIL_MODAL_CLOSED:
        return this.getEmailEndTaskPayload(action);

      case previewActions.PREVIEW_MODAL_CLOSED:
      case precedentActions.PRECEDENT_MODAL_CLOSED:
        return {};

      default:
        return null;
    }
  }

  constructor(private http: HttpClient) {
    super();
  }

  private validateAutoTimeEntry(autotime: IAutoTimeEntry): { valid: boolean; errorMessage?: string } {
    if (!autotime) {
      return { valid: false, errorMessage: 'Missing save model' };
    }

    ['matterId', 'firmId', 'staffId', 'feeId', 'timeInSeconds', 'transactionDate'].forEach((key) => {
      if (!autotime[key]) {
        return { valid: false, errorMessage: `Missing required field ${key}` };
      }
    });

    return { valid: true };
  }

  private getActionsDescription(resourceType: AutoTimeResourceTypeId, actionIds: AutoTimeActionTypeId[]): string {
    if (resourceType === AutoTimeResourceTypeId.BY_LAWYERS_RESEARCH) {
      return '';
    }

    const actionNames = actionIds.map((id) => AutoTimeActionTypes[id].action);
    return actionNames.length === 1
      ? `${actionNames[0]}.`
      : `${actionNames.slice(0, -1).join(', ')} or ${actionNames[actionNames.length - 1]}.`;
  }

  private getPreviewStartTaskPayload(action: previewActions.PreviewDocumentOpened): IAutoTimeStartTaskPayload {
    let resourceType = AutoTimeResourceTypeId.DOCUMENT;
    if (isEmail(action.payload)) {
      resourceType = AutoTimeResourceTypeId.EMAIL;
    } else if (isComment(action.payload)) {
      resourceType = AutoTimeResourceTypeId.COMMENT;
    }
    return {
      resourceType,
      actionType: AutoTimeActionTypeId.REVIEWED,
    };
  }

  private getCommentEndTaskPayload(action: commentActions.CommentModalClosed): IAutoTimeEndTaskPayload {
    const { isNew, isSaved } = action.payload;
    if (isNew) {
      return isSaved
        ? {
            actionTypeOverride: AutoTimeActionTypeId.CREATED,
          }
        : null;
    } else {
      return {
        actionTypeOverride: isSaved ? AutoTimeActionTypeId.MODIFIED : AutoTimeActionTypeId.REVIEWED,
      };
    }
  }

  private getEmailEndTaskPayload(action: emailActions.EmailModalClosed): IAutoTimeEndTaskPayload {
    const { forceClear, isSent, isDirty } = action.payload;
    if (forceClear || (!isSent && !isDirty)) {
      return null;
    }
    return {
      actionTypeOverride: isSent ? AutoTimeActionTypeId.SENT : AutoTimeActionTypeId.DRAFTED,
    };
  }
}
