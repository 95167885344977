import { IMattersFilterBy } from '../../models';
import { createReducer, on } from '@ngrx/store';
import * as matterFiltersActions from '../actions/matter-filters';

export interface State {
  onClose: boolean;
  filterBy: IMattersFilterBy | null;
}

export const initialState: State = {
  onClose: false,
  filterBy: null,
};

export const reducer = createReducer(
  initialState,
  on(matterFiltersActions.matterFiltersModalOnOpen, (state) => ({
    ...state,
    onClose: false,
    filterBy: null,
  })),
  on(matterFiltersActions.matterFiltersModalOnClose, (state, { payload }) => ({
    ...state,
    onClose: true,
    filterBy: payload,
  }))
);

// Selectors
export const selectOnClose = (state: State) => state.onClose;
export const selectFilterBy = (state: State) => state.filterBy;
