export interface IInvoiceItemCore {
  invoiceItemGUID: string;
  date: Date;
  transactionNumber: string;
  description: string;
  amountExTax: number;
  amountIncTax: number;
  amountTax: number;
  absSeqNum: number;
  seqNum: number;
  incTax: boolean;
  taxCode: string;
  taxCodeGUID: string;
  taxRatePercent: number;
  rowVers: number;
  itemType: EOfficeInvoiceItemType;
  originalAmountExTax: number;
  originalAmountIncTax: number;
  originalAmountTax: number;
  originalTaxRatePercent: number;
  originalTaxCode: string;
  originalTaxCodeGUID: string;
}

export interface IInvoiceFeeItem extends IInvoiceItemCore {
  timed: boolean;
  workDoneByStaffId: string;
  attributeToStaffId: string;
  taskCodeGUID: string;
  staffName: string;
  timeEntryGUID: string;
}

export interface IInvoiceJournalItem extends IInvoiceItemCore {
  officeDJItemGUID: string;
}
export interface IInvoicePaymentItem extends IInvoiceItemCore {
  officePaymentItemGUID: string;
}
export interface IInvoiceCostRecoveryItem extends IInvoiceItemCore {
  taskCodeGUID: string;
  costRecoveryGUID: string;
}
export interface IInvoiceAnticipatedDisbursementItem extends IInvoiceItemCore {
  anticipatedDisbursementGUID: string;
}

export interface IInvoiceItemWithVersion extends IInvoiceItemCore {
  version: InvoiceVersion;
  displayAmountExTax: number;
  displayAmountTax: number;
  displayAmountIncTax: number;
  displayTaxCode: string;
}

export interface IInvoiceItemNode {
  id: string;
  name: string;
  treePath: string[];
  type: TreeType;
  item: IInvoiceItemCore;
}

export enum TreeType {
  Section = 1,
  Group = 2,
  Item = 3,
}

export enum EOfficeInvoiceItemType {
  FEE = 1,
  PAYMENT = 2,
  JOURNAL = 3,
  CR = 4,
  AD = 5,
}

export enum InvoiceVersion {
  Original = 'Original Item',
  Invoiced = 'Invoiced Item',
}

export interface ISplitTax {
  invoiceItemGuid: string;
  taxCodeGuid: string;
  amountExTax: number;
  amountTax: number;
}
