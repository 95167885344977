<div class="x-card-contact">
  <sc-card-type-circle ctName="user" [ctSize]="'x-icon-xxl'" class="x-cell-recipient-type"></sc-card-type-circle>
  <div class="x-card-contact-details">
    <h6
      (click)="openCardModal()"
      angulartics2On
      [angularticsCategory]="analyticsCategory"
      angularticsAction="Preview card"
    >
      <strong *ngIf="title" [title]="title"> {{ title }} </strong>
      <strong *ngIf="!title"> {{ 'ContactDetails.NoName.Heading' | translate }} </strong>
      <span class="text-grey" *ngIf="subtitle">{{ subtitle }}</span>
    </h6>
    <dl class="mb-0" *ngIf="phone || email || subtitle">
      <dd class="x-card-contact-item"></dd>
      <dd class="x-card-contact-item" *ngIf="phone">
        <div class="x-card-contact-item-icon d-flex"><sc-icon iconName="phone"></sc-icon></div>
        <a
          href="tel:{{ phone }}"
          angulartics2On
          [angularticsCategory]="analyticsCategory"
          angularticsAction="New phone call"
        >
          {{ phone }}
        </a>
      </dd>
      <dd class="x-card-contact-item" *ngIf="email">
        <div class="x-card-contact-item-icon d-flex"><sc-icon iconName="envelope"></sc-icon></div>
        <a
          href="javascript:void(0);"
          (click)="createEmail($event)"
          class="text-truncate"
          angulartics2On
          [angularticsCategory]="analyticsCategory"
          angularticsAction="New email"
        >
          {{ email }}
        </a>
      </dd>
    </dl>
  </div>
</div>
