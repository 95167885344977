import { AccountingUpdateRequest } from '@app/features/accounting/models';
import { ITaskCode } from '@app/shared/models';

export interface SpecialFee {
  SpecialFeeGUID: string;
  Name: string;
  StaffGUID: string;
  StaffName: string;
  TaskCodeGUID: string;
  TaskCode: string;
  RateID: number;
  Rate: number;
  Deleted: boolean;
  DeletedDate: Date;
  RowVers: number;
  DeletedDetails: string;
}

export interface SpecialFeeListEntry extends SpecialFee {
  Initials: string;
  RateType: string;
}

export interface SpecialRate extends SpecialFeeListEntry {
  SpecialFeeMatterCardGUID: string;
}

export interface SpecialFeeInit {
  TaskCodeList: ITaskCode[];
  RateTypeList: RateType[];
  SpecialFee: SpecialFee;
}

export interface RateType {
  RateID: number;
  RateType: string;
}

export interface CreateInitDTO {
  TaskCodeList: ITaskCode[];
  RateTypeList: RateType[];
}

export interface UpdateInitDTO extends CreateInitDTO {
  SpecialFee: SpecialFeeDTO;
}

export interface SpecialFeeDTO {
  SpecialFeeGUID: string;
  Name: string;
  StaffGUID: string;
  StaffName: string;
  TaskCodeGUID: string;
  TaskCode: string;
  RateID: number;
  Rate: number;
  Deleted: boolean;
  DeletedDate: string;
  RowVers: number;
  DeletedDetails: string;
}

export interface SpecialFeeListEntryDTO extends SpecialFeeDTO {
  Initials: string;
  RateType: string;
}

export interface SpecialRateDTO extends SpecialFeeListEntryDTO {
  SpecialFeeMatterCardGUID: string;
}

export interface CreateRequest extends AccountingUpdateRequest {
  SpecialFeeGUID: string;
  Name: string;
  StaffGUID: string;
  TaskCodeGUID: string;
  RateID: number;
  Rate: number;
  Deleted: boolean;
  DeletedDate: string;
  RowVers: number;
  WarningAcknowledgements: number[];
}

export interface BulkUpdateEntry {
  SpecialFeeGUID: string;
  RowVersion: number;
  Name: string;
  Deleted: boolean;
  DeletedDate: string;
  TaskCodeGUID: string;
  StaffGUID: string;
  Rate: number;
  RateID: number;
}

export interface BulkUpdateRequest extends AccountingUpdateRequest {
  SpecialFeeList: BulkUpdateEntry[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UpdateRequest extends CreateRequest {}

export interface AddSpecialRatesRequestEntry {
  SpecialFeeGUID: string;
}

export interface ScoredSpecialRate extends SpecialRate {
  score: number;
}

export enum SpecialRateScore {
  Mismatched = 0,
  Wildcard = 1,
  TaskMatched = 2,
  StaffMatched = 3,
}

export enum SpecialFeeLevel {
  None = 0,
  Debtor = 1,
  Matter = 2,
}
