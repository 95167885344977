import { ECardFilterType, ICardFilter } from '@app/features/+card/models';
import * as actions from '../actions/card-filters.actions';

export interface State {
  defaultFilters: Array<{ type: ECardFilterType; filters: ICardFilter[] }>;
  customFilters: ICardFilter[];
}

export const INITIAL_STATE: State = {
  defaultFilters: [],
  customFilters: [],
};

export const reducer = (state = INITIAL_STATE, action: actions.CardFiltersActions) => {
  switch (action.type) {
    case actions.CardFiltersActionTypes.UPDATE_DEFAULT_CARD_FILTERS:
      return {
        ...state,
        defaultFilters: action.payload.filters,
      };

    case actions.CardFiltersActionTypes.RESET_CUSTOM_CARD_FILTERS:
      return {
        ...state,
        customFilters: [],
      };

    case actions.CardFiltersActionTypes.UPDATE_CUSTOM_CARD_FILTERS:
      return {
        ...state,
        customFilters:
          !!action.payload.filters && action.payload.filters.length > 0 ? action.payload.filters : state.customFilters,
      };

    default:
      return state;
  }
};

export const selectDefaultFilters = (state: State) => state.defaultFilters;
export const selectCustomerFilters = (state: State) => state.customFilters;
