<div class="x-input-group-prepend"><ng-content></ng-content></div>
<input
  [type]="type"
  class="form-control"
  scAutofocus
  [selectOnFocus]="true"
  scAutoSelectText
  scAutoRoundCurrency
  [autofocus]="autoFocus && !isDisabled"
  [ngClass]="inputClass"
  [(ngModel)]="value"
  [disabled]="isDisabled"
  [placeholder]="placeholder || ''"
  (blur)="blur()"
/>
