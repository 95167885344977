import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import * as matterListActions from '../actions';
import * as matterTypeActions from '@app/features/+matter-types/store/actions/matter-types.actions';
import * as cardModalActions from '@app/features/+card/store/actions/card-modal';
import { delayWhen, filter, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ECardType } from '@app/features/+card/models';
import { AppApiService } from '@app/core/api';
import { selectSelectedMatterTypeId } from '@app/features/+matter-types/store';
import { selectNewMatterSaving } from '../selectors/matter-list.selectors';
import { throwError } from 'rxjs';
import { SiriusError } from '@app/features/error-handler/interfaces/error-handler.interfaces';
import { MatterTablesService } from '@app/features/+matter-table-types/services';
import { MatterListService } from '../../services';

@Injectable()
export class NewMatterModalEffect {

  openNewMatterModal$ = createEffect(() => this.actions$.pipe(
    ofType(matterListActions.newMatterModalOnOpen),
    tap((action) => {
      const type = action.payload && !!action.payload.cardType ? action.payload.cardType : ECardType.People;
      const cardId = action.payload ? action.payload.cardId : null;
      if (cardId) {
        this.store.dispatch(new cardModalActions.LoadCardDetailsStart({ id: cardId, reset: true }));
      } else {
        this.store.dispatch(new cardModalActions.StartNewCard({ type, isMatterCard: true }));
      }
    })
  ), { dispatch: false });

  saveNewMatterSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(matterListActions.CreateMatterSuccess),
    withLatestFrom(this.store.pipe(select(selectSelectedMatterTypeId)), (action, selectedMatterTypeId) => ({
      result: action.result,
      selectedMatterTypeId,
    })),
    mergeMap((data) => {
      const { result, selectedMatterTypeId } = data;
      const { fileNumber, matterDetails, parties, matterId } = result;
      const {
        detailEntriesExcludedTableOccupiedByParties,
      } = this.matterListSvc.getMatterDetailEntriesWithPartySubstitutionTableScenario(
        parties,
        matterDetails.detailEntries,
        null,
        false
      );

      const isRequireToUpdateMatterDetails = detailEntriesExcludedTableOccupiedByParties.filter((x) => !x).length > 0;
      if (isRequireToUpdateMatterDetails) {
        return this.matterTableSvc
          .updateDisplayOrderModels(detailEntriesExcludedTableOccupiedByParties.filter(Boolean))
          .pipe(
            mergeMap((matterEntriesWithCorrectDisplayOrder) => [
              matterListActions.ReupdatedMatterDetailEntries({
                matterEntries: matterEntriesWithCorrectDisplayOrder,
                fileNumber,
                matterTypeId: selectedMatterTypeId,
                matterId,
              }),
            ])
          );
      }

      return [
        matterListActions.CreateMatterLoadingStatus({ isLoading: false }),
        new matterTypeActions.UpdateLastMatterTypeId(selectedMatterTypeId),
        matterListActions.gotoMatterDetails({ fileNumber }),
      ];
    })
  ));

  reupdatedMatterDetailEntries$ = createEffect(() => this.actions$.pipe(
    ofType(matterListActions.ReupdatedMatterDetailEntries),
    mergeMap((action) => {
      const { matterId, matterEntries, fileNumber, matterTypeId } = action;
      try {
        return this.matterTableSvc
          .updateMatterTables(matterId, matterEntries)
          .pipe(
            mergeMap(() => [
              matterListActions.CreateMatterLoadingStatus({ isLoading: false }),
              new matterTypeActions.UpdateLastMatterTypeId(matterTypeId),
              matterListActions.gotoMatterDetails({ fileNumber }),
            ])
          );
      } catch (err) {
        return throwError(() =>
          new SiriusError({
            type: 'error',
            title: 'Error',
            message: `Fail to reupdate the matter detail (${fileNumber}) after creation.`,
          })
        );
      }
    })
  ));

  saveNewMatterFail$ = createEffect(() => this.actions$.pipe(
    ofType(matterListActions.CreateMatterFailure),
    switchMap((action) =>
      throwError(() =>
        new SiriusError({
          type: 'error',
          title: 'Error',
          message: action.errorMessage,
        })
      )
    )
  ), { dispatch: false });

  goToMatterDetails$ = createEffect(() => this.actions$.pipe(
    ofType(matterListActions.gotoMatterDetails),
    delayWhen(() =>
      this.store.pipe(
        select(selectNewMatterSaving),
        filter((saving) => !saving),
        take(1)
      )
    ),
    tap((action) => {
      const { fileNumber } = action;
      this.appApiSvc.navigate({ path: [{ outlets: { primary: ['matters', fileNumber], popup: null } }] });
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private matterListSvc: MatterListService,
    private appApiSvc: AppApiService,
    private matterTableSvc: MatterTablesService
  ) {}
}
