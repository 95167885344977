import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@app/shared/services/base/base.service';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import {
  INotificationEntity,
  INotificationEventResponse,
  INotificationResponse,
} from '@app/features/+notification/models/notification.model';

@Injectable()
export class NotificationApiService extends BaseService {
  private readonly notificationPath: string;
  private readonly notificationV2Path: string;
  private readonly eventPath: string;

  constructor(private http: HttpClient) {
    super();
    this.notificationPath = `${this.notificationsPath}/api/v1/notifications`;
    this.notificationV2Path = `${this.notificationsPath}/api/v2/notifications`;
    this.eventPath = `${this.notificationsPath}/api/v2/events`;
  }

  /**
   * Gets a batch of notifications
   *
   * @param continuationToken -- it is used to get notifications from that version onward. Default is 0.
   * */
  public getNotifications(continuationToken = ''): Observable<HttpResponse<INotificationResponse>> {
    const url = this.notificationV2Path + `?continuationToken=${continuationToken}`;

    // Caching of the GET responses might causes unpredictable results
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0'
    });

    return this.http.get<INotificationResponse>(url, {
      observe: 'response',
      headers: headers
    });
  }

  /**
   * Gets all action events done by a given staff
   *
   * @param staffId
   * @param lastEvaluatedKey
   * @return
   * */
  public getEvents(staffId: string, lastEvaluatedKey = '0'): Observable<INotificationEventResponse> {
    const url = this.eventPath + `/${staffId}` + `/${lastEvaluatedKey}`;
    return this.http.get<INotificationEventResponse>(url);
  }

  /**
   * Acknowledge a notification
   *
   * @param id
   * */
  public acknowledgeNotification(id: number): Observable<any> {
    const url = this.notificationPath + `/${id}`;
    return this.http.delete(url);
  }

  /**
   * Acknowledge a batch of notifications
   *
   * @param ids
   * */
  public acknowledgeNotificationBatch(ids: number[]): Observable<any> {
    const url = this.notificationPath;
    return this.http.request('delete', url, { body: ids });
  }

  /**
   * Acknowledges all notifications related to a given entity.
   * */
  public acknowledgeEntityNotifications(entity: INotificationEntity): Observable<any> {
    const url = this.notificationPath + '/entities';
    return this.http.request('delete', url, { body: { entity } });
  }
}
