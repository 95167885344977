import { Component, EventEmitter, forwardRef, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// small tag is from ui-switch;
// button tag is generated from internal ui-switch control;
const SUPPORTED_CLICK_ORIGIN = ['BUTTON', 'button', 'SMALL', 'small'];

@Component({
  selector: 'sc-switch',
  templateUrl: './switch.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SwitchComponent), multi: true }],
})
export class SwitchComponent implements ControlValueAccessor, OnInit {
  @Input()
  label: string;
  @Input()
  size = 'small';
  @Input()
  textClass: string;

  // Not using as form component
  @Input()
  isDisabled = false;
  @Input()
  set switchStatus(data: boolean) {
    this._switchStatus = data;
  }
  get switchStatus() {
    return this._switchStatus;
  }
  @Output()
  switchStatusChange = new EventEmitter<boolean>();

  private _switchStatus: boolean;

  constructor() {}

  @HostBinding('class.d-inline-flex')
  ngOnInit() {}

  switchClick(e, isDisabled: boolean) {
    // Toggle by posting-back opposite status. Covers both use cases with or without reactive form.
    if (!isDisabled && SUPPORTED_CLICK_ORIGIN.includes(e?.target?.tagName)) {
      this.switchStatusChange.emit(!this._switchStatus);
      this.onChange(!this._switchStatus);
      this._switchStatus = !this._switchStatus;
    }
  }

  onChange = (_) => {};
  onTouched = () => {};

  writeValue(value: any) {
    this._switchStatus = value;
  }
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
