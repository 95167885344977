import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import format from 'date-fns/format';
import isDate from 'date-fns/isDate';

import { BaseService } from 'app/shared/services/base/base.service';
import { StartupService } from 'app/core/services';
import { AuthService } from 'app/core/services/auth/auth.service';

import {
  AdvancedSearchData,
  FilterPredicate,
  IAdvancedSearchParams,
  IMatterFilterTranslateKeys,
  IMatterListEntry,
  IMatterSearchAccountingResult,
  IMatterSearchResult,
  IMatterSearchState,
  IMattersFilterBy,
  IQuickSearchParams,
  MatterSearchQuery,
  MatterSearchResponse,
  SearchTerm,
} from '../../models';

import { AccountingFilterType, AdvancedSearchMode, EMattersFilter, SearchTermMatchType } from '../../constants';
import { TranslateService } from '@ngx-translate/core';
import { stringToStartCase } from '@server/modules/shared/functions/common-util.functions';

const DefaultPredicate = () => true;

@Injectable({
  providedIn: 'root',
})
export class MatterSearchService extends BaseService {
  // public
  mattersSearch$: Observable<IMatterListEntry[]>;

  // internal
  private mattersSearch_ = new BehaviorSubject<IMatterSearchResult[]>(null);
  private path_: string;
  private accountingPath_: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private startupService: StartupService,
    private translateSvc: TranslateService
  ) {
    super();
    this.path_ = `${this.apiPath}/api/v1/search/matters`;
    this.accountingPath_ = `${this.accountingPath}/api/cloud/matter/list/`;
    this.mattersSearch$ = this.mattersSearch_.asObservable();
  }

  get filterOptions(): any[] {
    return [
      {
        header: true,
        name: this.translateSvc.instant('Matter.List.Filter.Heading.FirmMatters'),
      },
      {
        value: EMattersFilter.Current,
        filterBy: {
          includeAllMatters: false,
          filterKey: EMattersFilter.Current,
        },
        name: this.translateSvc.instant(`Matter.List.Filter.Menu.${EMattersFilter[EMattersFilter.Current]}`),
        buttonText: this.translateSvc.instant(`Matter.List.Filter.Selected.${EMattersFilter[EMattersFilter.Current]}`),
      },
      {
        value: EMattersFilter.NonArchived,
        filterBy: {
          includeAllMatters: false,
          filterKey: EMattersFilter.NonArchived,
        },
        name: this.translateSvc.instant(`Matter.List.Filter.Menu.${EMattersFilter[EMattersFilter.NonArchived]}`),
        buttonText: this.translateSvc.instant(
          `Matter.List.Filter.Selected.${EMattersFilter[EMattersFilter.NonArchived]}`
        ),
      },
      {
        divider: true,
      },
      {
        header: true,
        name: this.translateSvc.instant('Matter.List.Filter.Heading.MyMatters'),
      },
      {
        value: EMattersFilter.MyCurrent,
        filterBy: {
          includeAllMatters: false,
          filterKey: EMattersFilter.MyCurrent,
        },
        name: this.translateSvc.instant(`Matter.List.Filter.Menu.${EMattersFilter[EMattersFilter.MyCurrent]}`),
        buttonText: this.translateSvc.instant(
          `Matter.List.Filter.Selected.${EMattersFilter[EMattersFilter.MyCurrent]}`
        ),
      },
      {
        divider: true,
      },
      {
        header: true,
        name: this.translateSvc.instant('Matter.List.Filter.Heading.Accounting'),
      },
      {
        value: EMattersFilter.DebtorBalance,
        filterBy: {
          includeAllMatters: false,
          filterKey: EMattersFilter.DebtorBalance,
        },
        name: this.translateSvc.instant(`Matter.List.Filter.Menu.${EMattersFilter[EMattersFilter.DebtorBalance]}`),
        buttonText: this.translateSvc.instant(
          `Matter.List.Filter.Selected.${EMattersFilter[EMattersFilter.DebtorBalance]}`
        ),
      },
      {
        value: EMattersFilter.GeneralTrust,
        filterBy: {
          includeAllMatters: false,
          filterKey: EMattersFilter.GeneralTrust,
        },
        name: this.translateSvc.instant(`Matter.List.Filter.Menu.${EMattersFilter[EMattersFilter.GeneralTrust]}`),
        buttonText: this.translateSvc.instant(
          `Matter.List.Filter.Selected.${EMattersFilter[EMattersFilter.GeneralTrust]}`
        ),
      },
      {
        value: EMattersFilter.UnbilledTimeAndFees,
        filterBy: {
          includeAllMatters: false,
          filterKey: EMattersFilter.UnbilledTimeAndFees,
        },
        name: this.translateSvc.instant(
          `Matter.List.Filter.Menu.${EMattersFilter[EMattersFilter.UnbilledTimeAndFees]}`
        ),
        buttonText: this.translateSvc.instant(
          `Matter.List.Filter.Selected.${EMattersFilter[EMattersFilter.UnbilledTimeAndFees]}`
        ),
      },
    ];
  }

  quickSearch(searchQuery: IQuickSearchParams): Observable<IMatterSearchResult[]> {
    const searchText = !!searchQuery.searchText ? searchQuery.searchText : '';
    const url = this.urlJoin(
      this.path_,
      `?query=${encodeURIComponent(searchText)}`,
      `&archivedonly=${searchQuery.archivedOnly}`
    );
    return this.http.get<IMatterSearchResult[]>(url).pipe(catchError(() => of([])));
  }

  private advancedSearch(searchQuery: IAdvancedSearchParams): Observable<IMatterSearchResult[]> {
    const url = this.urlJoin(this.path_);
    searchQuery.SearchTerms = formaliseAdvancedSearch(searchQuery.SearchTerms, this.startupService.userDetails.firmId);
    return this.http.post<IMatterSearchResult[]>(url, searchQuery).pipe(catchError(() => of([])));
  }

  private accountingSearch(searchQuery: EMattersFilter): Observable<IMatterSearchAccountingResult[]> {
    const url = this.urlJoin(this.accountingPath_, searchQuery.toString());
    return this.http.get<IMatterSearchAccountingResult[]>(url).pipe(catchError(() => of([])));
  }

  searchMatters(
    searchQuery: MatterSearchQuery,
    searchMode: AdvancedSearchMode
  ): Observable<IMatterListEntry[] | string[]> {
    let searchResponse = new Observable<MatterSearchResponse>();
    let project: (resp: MatterSearchResponse) => any[] = () => [];
    const docSearchProject = (resp: IMatterSearchResult[]): IMatterListEntry[] => resp?.map(toMatterListEntry) || [];
    const accountingSearchProject = (resp: IMatterSearchAccountingResult[]): string[] =>
      resp?.map(toStringFromMatterGuid) || [];

    switch (searchMode) {
      case AdvancedSearchMode.AdvancedSearch:
        searchResponse = this.advancedSearch(searchQuery as IAdvancedSearchParams);
        project = docSearchProject;
        break;
      case AdvancedSearchMode.QuickSearch:
        searchResponse = this.quickSearch(searchQuery as IQuickSearchParams);
        project = docSearchProject;
        break;
      case AdvancedSearchMode.AccountingSearch:
        searchResponse = this.accountingSearch(searchQuery as EMattersFilter);
        project = accountingSearchProject;
        break;
    }
    return searchResponse.pipe(map(project));
  }

  getSearchPredicate(matterSearchState: IMatterSearchState): FilterPredicate {
    if (!matterSearchState) {
      return DefaultPredicate;
    }
    const advancedSearch = matterSearchState.advancedSearch;
    const filterBy = matterSearchState.filterBy;
    const isAdvancedSearch = filterBy && filterBy.filterKey === EMattersFilter.AdvancedSearch;
  
    let basePredicate = isAdvancedSearch
      ? this.getAdvancedSearchPredicate(advancedSearch)
      : this.getFilterByPredicate(filterBy);
  
    if (matterSearchState.searchText) {
      const searchText = matterSearchState.searchText.toUpperCase();
      const searchWords = searchText.split(' ').filter((w) => w.length > 0);
      if (searchWords.length > 0) {
        const searchPredicate = (entry: IMatterListEntry): boolean => {
          const searchFields = [
            entry.fileNumber,
            entry.firstDescription,
            entry.customDescription,
            entry.secondDescription,
            entry.firstDescriptionLong,
            entry.staffInitials,
            entry.staffCreditName,
            entry.staffResponsibleName,
            entry.staffActingName,
            entry.staffAssistingName,
            entry.matterStatus,
            entry.matterState,
            entry.matterType,
            entry.matterId,
            entry.cardIdList?.join(' '),
          ]
            .filter((field) => !!field)
            .join(' ')
            .toUpperCase();
  
          return searchWords.every((word) => searchFields.includes(word));
        };
  
        const combinedPredicate = (entry: IMatterListEntry): boolean => basePredicate(entry) && searchPredicate(entry);
        return combinedPredicate;
      }
    }

    return basePredicate;
  }

  private getAdvancedSearchPredicate(advancedSearch: AdvancedSearchData): FilterPredicate {
    if (!advancedSearch) {
      return DefaultPredicate;
    }

    const searchTerms = advancedSearch ? advancedSearch.searchTerms : [];
    const fieldGetterMap: { [field: string]: (entry: IMatterListEntry) => string } = {
      actinguserid: (entry: IMatterListEntry) => entry.staffActing,
      archivenumber: (entry: IMatterListEntry) => entry.archiveNumber,
      assistinguserid: (entry: IMatterListEntry) => entry.staffAssisting,
      credituserid: (entry: IMatterListEntry) => entry.staffCredit,
      filenumber: (entry: IMatterListEntry) => entry.fileNumber,
      instructiondate: (entry: IMatterListEntry) =>
        entry.instructionDate ? format(new Date(entry.instructionDate), 'yyyy-MM-dd') : null,
      isarchived: (entry: IMatterListEntry) => (entry.isArchived ? '1' : '0'),
      iscurrent: (entry: IMatterListEntry) => (entry.isCurrent ? '1' : '0'),
      matterstatus: (entry: IMatterListEntry) => entry.matterStatus,
      responsibleuserid: (entry: IMatterListEntry) => entry.staffResponsible,
      staffintials: (entry: IMatterListEntry) => entry.staffInitials,
      state: (entry: IMatterListEntry) => entry.matterState,
      mattertype: (entry: IMatterListEntry) => entry.matterType,
      customdesc: (entry: IMatterListEntry) => entry.customDescription,
      firstdesc: (entry: IMatterListEntry) => entry.firstDescription,
      seconddesc: (entry: IMatterListEntry) => entry.secondDescription,
    };

    const allowArchivedPredicate = (entry: IMatterListEntry) =>
      advancedSearch.includeArchivedMatters || !entry.isArchived;
    const predicates: Array<FilterPredicate> = [allowArchivedPredicate];

    searchTerms.forEach((term: SearchTerm) => {
      const getter = fieldGetterMap[term.termName];
      if (!getter) {
        return;
      }
      predicates.push((entry: IMatterListEntry): boolean => {
        const value = (getter(entry) || '').toLowerCase();
        let termValue: string = term.termValue.toString().toLowerCase();
        if (term.termName === 'instructiondate') {
          const date = new Date(term.termValue);
          termValue = format(date, 'yyyy-MM-dd');
        }
        if (term.termOption === SearchTermMatchType.Exact) {
          return value === termValue;
        } else {
          return value.indexOf(termValue) > -1;
        }
      });
    });

    return (entry: IMatterListEntry) =>
      predicates?.reduce((result: boolean, value: FilterPredicate) => result && value(entry), true);
  }

  private getFilterByPredicate(filterBy: IMattersFilterBy): FilterPredicate {
    const filterKey = filterBy ? filterBy.filterKey : EMattersFilter.Current;
    const idList = filterBy ? filterBy.idList : [];
    const staffId = this.startupService.userDetails.staffId;
    const allowArchivedPredicate = (matterEntry: IMatterListEntry) =>
      filterBy.includeAllMatters || !matterEntry.isArchived;
    let mainPredicate: FilterPredicate = () => true;
    switch (filterKey) {
      case EMattersFilter.Current:
        mainPredicate = (matter: IMatterListEntry) => !['Complete', 'Not Proceeding'].includes(matter.matterStatus);
        break;
      case EMattersFilter.NonArchived:
        // archived matters are rejected automatically when not including all matters, so we just need
        // the default allow-everything filter as our main filter. This is the most permissive filter option.
        break;
      case EMattersFilter.MyCurrent:
        mainPredicate = (matter: IMatterListEntry) => {
          const myCurrentFields = [
            matter.staffResponsible,
            matter.staffActing,
            matter.staffCredit,
            matter.staffAssisting,
          ];

          return myCurrentFields.findIndex((x) => x === staffId) !== -1;
        };
        break;
      case EMattersFilter.MyResponsible:
        mainPredicate = (matter: IMatterListEntry) => matter.staffResponsible === staffId;
        break;
      case EMattersFilter.MyActing:
        mainPredicate = (matter: IMatterListEntry) => matter.staffActing === staffId;
        break;
      case EMattersFilter.MyCredit:
        mainPredicate = (matter: IMatterListEntry) => matter.staffCredit === staffId;
        break;
      case EMattersFilter.MyAssisting:
        mainPredicate = (matter: IMatterListEntry) => matter.staffAssisting === staffId;
        break;
      case EMattersFilter.Recent:
      case EMattersFilter.DebtorBalance:
      case EMattersFilter.GeneralTrust:
      case EMattersFilter.TrustInvestment:
      case EMattersFilter.UnbilledTimeAndFees:
      case EMattersFilter.UnbilledCostRecoveries:
      case EMattersFilter.UnbilledPayments:
      case EMattersFilter.UnbilledJournals:
      case EMattersFilter.UnbilledAnticipatedPayments:
      case EMattersFilter.PowerMoney:
      case EMattersFilter.TransitMoney:
        mainPredicate = (matterEntry: IMatterListEntry) =>
          (idList || []).findIndex((x) => x === matterEntry.matterId) !== -1;
        break;
      case EMattersFilter.AdvancedSearch:
        // advanced filter predicate is constructed separately for now
        break;
      default:
        break;
    }
    return (matter: IMatterListEntry) => mainPredicate(matter) && allowArchivedPredicate(matter);
  }

  isCloudSearchFilterKey(filterKey: EMattersFilter): boolean {
    switch (filterKey) {
      case EMattersFilter.Recent:
      case EMattersFilter.DebtorBalance:
      case EMattersFilter.GeneralTrust:
      case EMattersFilter.TrustInvestment:
      case EMattersFilter.UnbilledTimeAndFees:
      case EMattersFilter.UnbilledCostRecoveries:
      case EMattersFilter.UnbilledPayments:
      case EMattersFilter.UnbilledJournals:
      case EMattersFilter.UnbilledAnticipatedPayments:
      case EMattersFilter.PowerMoney:
      case EMattersFilter.TransitMoney:
      case EMattersFilter.AdvancedSearch:
        return true;
      default:
        return false;
    }
  }

  getFilterByTranslation(filterKey: EMattersFilter): IMatterFilterTranslateKeys {
    return toFilterByTranslation(filterKey);
  }

  getTransformedFilterKey(filterKey: EMattersFilter): number {
    switch (filterKey) {
      case EMattersFilter.DebtorBalance:
      case EMattersFilter.GeneralTrust:
      case EMattersFilter.TrustInvestment:
      case EMattersFilter.UnbilledTimeAndFees:
      case EMattersFilter.UnbilledCostRecoveries:
      case EMattersFilter.UnbilledPayments:
      case EMattersFilter.UnbilledJournals:
      case EMattersFilter.UnbilledAnticipatedPayments:
      case EMattersFilter.PowerMoney:
      case EMattersFilter.TransitMoney:
        return AccountingFilterType[filterKey];
      case EMattersFilter.AdvancedSearch:
        return filterKey;
      case EMattersFilter.Current:
      case EMattersFilter.NonArchived:
      case EMattersFilter.MyCurrent:
      case EMattersFilter.Recent:
      case EMattersFilter.MyResponsible:
      case EMattersFilter.MyActing:
      case EMattersFilter.MyCredit:
      case EMattersFilter.MyAssisting:
      default:
        return undefined;
    }
  }

  getMattersForCard(cardId: string): Observable<IMatterListEntry[]> {
    const url: string = this.urlJoin(this.apiPath, `api/v1/cards/${cardId}/matters`);
    return this.http.get<IMatterSearchResult[]>(url).pipe(
      map((matterSearchResults) => matterSearchResults.map(toMatterListEntry))
    );
  }
}

/* Helper functions */
const formaliseAdvancedSearch = (searchTerms: SearchTerm[], firmId: string): SearchTerm[] => {
  const addFirmIdSearchTerm = (terms: SearchTerm[]): SearchTerm[] =>
    // remove existing firmId and add one for current firm
     [
      ...terms?.filter((term) => term.termName.toLowerCase() !== 'firmid'),
      {
        termName: 'firmid',
        termValue: firmId,
        termOption: SearchTermMatchType.Exact,
      },
    ] as SearchTerm[]
  ;
  const formatDateSearchTerm = (term: SearchTerm): SearchTerm => {
    // format dates as "yyyy-MM-dd"
    const value = {
      termValue: isDate(term.termValue)
        ? format(term.termValue as Date, 'yyyy-MM-dd')
        : (term.termValue || '').toString(),
    };
    // default to exact match if not specified
    return Object.assign({}, value, term, { termOption: term.termOption || SearchTermMatchType.Exact }) as SearchTerm;
  };

  searchTerms = addFirmIdSearchTerm(searchTerms || [])
    .map(formatDateSearchTerm)
    .filter((term: SearchTerm) => term.termName !== 'isarchived');

  return [
    ...searchTerms,
    {
      termName: 'isarchived',
      termValue: 1, // true
      termOption: SearchTermMatchType.Exact,
    },
  ];
};

const capitaliseStatus = (lowercaseStatus: string): string => {
  if (!lowercaseStatus) {
    return null;
  }
  // cloud search api returns statuses in all lowercase, but to be consistent and display nicely,
  // we want to replace all the lowercase words with their equivalent one in Start Case.
  stringToStartCase(lowercaseStatus)
    .split(/\s+/g)
    .forEach((startCasedWord: string) => {
      lowercaseStatus = lowercaseStatus.replace(startCasedWord.toLowerCase(), startCasedWord);
    });

  return lowercaseStatus;
};

const toMatterListEntry = (searchResult: IMatterSearchResult): IMatterListEntry => ({
    matterId: searchResult.matterId,
    fileNumber: searchResult.filenumber,
    firstDescription: searchResult.firstdesc,
    secondDescription: searchResult.seconddesc,
    customDescription: searchResult.customdesc,
    // if matter type is just lowercased version of custom description, use the custom description instead so it
    // is the correct case
    matterType:
      searchResult.mattertype === (searchResult.customdesc || '').toLowerCase()
        ? searchResult.customdesc
        : searchResult.mattertype,
    matterStatus: capitaliseStatus(searchResult.matterstatus),
    isCurrent: searchResult.iscurrent,
    matterState: searchResult.state,
    staffInitials: searchResult.staffinitials,
    staffResponsible: searchResult.responsibleuserid,
    staffCredit: searchResult.credituserid,
    staffActing: searchResult.actinguserid,
    staffAssisting: searchResult.assistinguserid,
    isArchived: searchResult.isarchived,
    archiveNumber: searchResult.archivenumber,
    billingMode: searchResult.labillingmode,
    accessible: searchResult.accessible !== false, // assume it is accessible unless explicitly not
    deleteCode: searchResult.deletecode || 0,
  } as IMatterListEntry);

const toFilterByTranslation = (filterKey: EMattersFilter): IMatterFilterTranslateKeys => {
  const mutableSelectedTextFilterKeys = [EMattersFilter.Current, EMattersFilter.NonArchived, EMattersFilter.MyCurrent];
  const name = EMattersFilter[filterKey];
  const hasSeparateWithAllString = mutableSelectedTextFilterKeys.findIndex((c) => c === filterKey) !== -1;
  const root = 'Matter.List.Filter';
  const selected = `${root}.Selected.${name}`;
  return {
    menu: `${root}.Menu.${name}`,
    selected,
    selectedWhenIncludeAll: hasSeparateWithAllString ? `${selected}.WhenIncludeAll` : selected,
    fullName: `${root}.Modal.${name}`,
  };
};

const toStringFromMatterGuid = (result: IMatterSearchAccountingResult): string => result.MatterGUID;

/* End - Helper functions */
