import { Component, HostBinding } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { RowNode } from '@ag-grid-community/core';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-switch-cell',
  templateUrl: './switch-cell.component.html',
})
export class SwitchCellComponent implements AgRendererComponent {
  public onSwitchClicked: (node?: RowNode) => void;
  public switchStatus: boolean;
  private _node: RowNode;

  constructor() {}

  @HostBinding('class.d-flex')
  agInit(params: any) {
    this._node = params.node;
    this.switchStatus = params.value || false;
    this.onSwitchClicked = !!params && isFunction(params['onSwitchClicked']) ? params['onSwitchClicked'] : () => {};
  }

  refresh(params: any): boolean {
    this.switchStatus = params.value;
    this._node = params.node;
    return true;
  }

  switchClicked(switchStatus): void {
    this.switchStatus = switchStatus;
    this.onSwitchClicked(this._node);
  }
}
