import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { isObjEqual } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-recipient-search',
  templateUrl: './recipient-search.component.html',
})
export class RecipientSearchComponent implements OnInit, OnDestroy {
  @Input() hideLabel: boolean;
  @Input() autofocus: boolean;
  @Output() onSearchChanged = new EventEmitter<string>();

  recipientSearchForm: FormGroup;

  private _formChangeSub: Subscription;
  private unsub = new Subject<void>();

  constructor(private _formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this._formChangeSub && !this._formChangeSub.closed) {
      this._formChangeSub.unsubscribe();
    }

    this.unsub.next();
    this.unsub.complete();
  }

  private createForm() {
    this.recipientSearchForm = this._formBuilder.group({
      searchText: '',
    });

    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges() {
    if (this.recipientSearchForm) {
      this._formChangeSub = this.recipientSearchForm.valueChanges
        .pipe(
          distinctUntilChanged(isObjEqual),
          takeUntil(this.unsub) // important: this operator must be last
        )
        .subscribe((formData: { searchText: string }) => {
          this.onSearchChanged.emit(formData.searchText);
        });
    }
  }
}
