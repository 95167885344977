import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { isInfotrack } from '@app/shared/functions';
import { parseUTC } from '@app/shared/utils';
import { IComment, IDoc, IDocsFolder } from '@app/shared/models';
import { toDocumentDTO } from '../functions';
import { BaseService } from '@app/shared/services';

@Injectable({
  providedIn: 'root',
})
export class CorrespondenceService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  get(matterId: string): Observable<IDoc[]> {
    const url = `${this.siriusPath}/api/matter/${matterId}/documents`;
    return this.http.get<IDoc[]>(url);
  }

  rename(correspondence: IDoc, name: string): Observable<void> {
    const url = this.urlJoin(this.apiPath, '/api/v1/documents/', correspondence.id);
    const request = {
      ...toDocumentDTO(correspondence),
      docName: name,
    };
    return this.http.put<void>(url, request);
  }

  delete(correspondence: IDoc): Observable<void> {
    const url = this.urlJoin(this.apiPath, '/api/v1/documents/', correspondence.id);
    return this.http.delete<void>(url);
  }

  undelete(correspondence: IDoc): Observable<void> {
    const url = this.urlJoin(this.apiPath, '/api/v1/documents/', correspondence.id);
    const message = {
      ...toDocumentDTO(correspondence),
      docName: correspondence.name,
      deleteCode: 0,
    };
    return this.http.put<void>(url, message);
  }

  togglePin(correspondence: IDoc): Observable<void> {
    const prefix = `/api/v1/${isInfotrack(correspondence) ? 'searches' : 'documents'}/`;
    const url = this.urlJoin(this.apiPath, prefix, correspondence.pin ? 'unpin' : 'pin');
    const message = correspondence.pin
      ? [correspondence.id]
      : [
          {
            id: correspondence.id,
            pin: parseUTC(new Date()).toISOString(),
          },
        ];
    return this.http.put<void>(url, message);
  }

  create(options: any = {}): Observable<IDoc | IComment> {
    const url = `${this.siriusPath}/api/matter/documents`;
    return this.http.post<IDoc | IComment>(url, options);
  }

  getCorrespondenceList(matterId: string, fetchMode: string): Observable<IDocsFolder> {
    const url = `${this.siriusPath}/api/matter/${matterId}/correspondence?fetchMode=${fetchMode}`;
    return this.http.get<IDocsFolder>(url);
  }

  duplicateDocument(doc: IDoc): Observable<any> {
    const url = `${this.apiPath}/api/v1/documents/${doc.id}/duplicate`;
    return this.http.post(url, {});
  }

  extractAttachment(documentId: string, attachmentId: string): Observable<any> {
    const url = `${this.apiPath}/api/v1/documents/${documentId}/attachments/${attachmentId}/extract`;
    return this.http.post(url, {});
  }

  deletePendingPrecedent(matterId: string, recordId: string): Observable<any> {
    const url = `${this.apiPath}/api/v1/matters/${matterId}/pendingprecedents/${recordId}`;
    return this.http.delete(url, {});
  }
}
