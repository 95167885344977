import { Injectable } from '@angular/core';
import { Auth0Client, RedirectLoginResult, createAuth0Client } from '@auth0/auth0-spa-js';
import { environment } from '@env/environment';
import { ExternalUserType } from '@app/shared/models';
import { IAuthProvider } from '@app/core/models/auth.model';
import { LogService } from '../log/log.service';

@Injectable({
  providedIn: 'root',
})
export class Auth0Service implements IAuthProvider {
  private auth0Client: Auth0Client;
  private isInitialized = false;
  private initializingPromise: Promise<void>;
  private _token: string | null = null;

  constructor(private _log: LogService) {
    this._log.init('auth0-service');

    if (globalThis.ldClient.variation('can-use-auth0-login', false)) {
      this.initializingPromise = this.initializeAuth0Client();
    }
  }

  public async initialize(): Promise<void> {
    await this.initializingPromise;
    try {
      this._token = await this.auth0Client.getTokenSilently();
    } catch (error) {
      this._log.error('Error initializing Auth0 token:', error);
      throw error;
    }
  }

  private async initializeAuth0Client(): Promise<void> {
    this.auth0Client = await createAuth0Client({
      domain: environment.config.auth0.domain, 
      clientId: environment.config.auth0.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
    });

    const query = new URLSearchParams(window.location.search);
    if (query.has('code') && query.has('state')) {
      try {
        const result: RedirectLoginResult = await this.auth0Client.handleRedirectCallback();
        if (result.appState?.targetUrl) {
          window.location.replace(result.appState.targetUrl);
          return;
        }
        window.history.replaceState({}, document.title, '/');
      } catch (error) {
        this._log.error('Error handling Auth0 redirect callback:', error);
      }
    }

    try {
      this._token = await this.auth0Client.getTokenSilently();
    } catch (error) {
      this._log.error('Error getting Auth0 token during initialization:', error);
    }

    this.isInitialized = true;
  }

  private async ensureInitialized(): Promise<void> {
    if (!this.isInitialized) {
      await this.initializingPromise;
    }
  }

  async userDetails(): Promise<any> {
    await this.ensureInitialized();
    return await this.auth0Client.getUser();
  }

  async statusAdminConsent(): Promise<boolean> {
    console.warn('statusAdminConsent is not implemented for Auth0');
    return false;
  }

  requestAdminConsent(redirectUrl: string, openInNewWindow: boolean, cb: any): void {
    console.warn('requestAdminConsent is not implemented for Auth0');
  }

  async forceRefreshAccessToken(): Promise<string> {
    await this.ensureInitialized();
    try {
      return await this.auth0Client.getTokenSilently();
    } catch (error) {
      this._log.error('Error refreshing Auth0 access token:', error);
      await this.loginWithRedirect();
      return '';
    }
  }

  get decodedToken() {
    return this.getDecodedAccessToken();
  }

  get token(): string | null {
    return this._token;
  }

  async getToken(): Promise<string> {
    await this.ensureInitialized();
    try {
      return await this.auth0Client.getTokenSilently();
    } catch (error) {
      this._log.error('Error getting Auth0 token:', error);
      await this.loginWithRedirect();
      throw error;
    }
  }

  async getUserId(): Promise<string | null> {
    const user = await this.userDetails();
    return user?.sub || null;
  }

  async getRefreshedToken(): Promise<string> {
    return await this.forceRefreshAccessToken();
  }

  async linkUser(url?: string, newWindow?: boolean, callback?: any): Promise<void> {
    console.warn('linkUser is not implemented for Auth0');
  }

  async unlinkUser(url?: string, newWindow?: boolean, callback?: any): Promise<void> {
    console.warn('unlinkUser is not implemented for Auth0');
  }

  async reauthenticateCloudProvider(
    nonce?: string,
    redirect?: string,
    newWindow: boolean = false,
    callback?: any,
    isSuperdiaryFirm: boolean = false
  ): Promise<void> {
    console.warn('reauthenticateCloudProvider is not implemented for Auth0');
  }

  registerEventListener(topic: string, type: string, callback: any): void {
    if (topic === 'auth') {
      switch (type) {
        case 'login':
          console.warn('Auth0 does not support direct event subscription for login.');
          break;
        case 'logout':
          console.warn('Auth0 does not support direct event subscription for logout.');
          break;
        default:
          console.warn(`Unsupported event type: ${type} for topic: ${topic}`);
      }
    } else {
      console.warn(`Unsupported topic: ${topic}`);
    }
  }

  async authoriseSupport(code: string, duration: string): Promise<string> {
    console.warn('authoriseSupport is not implemented for Auth0');
    return '';
  }

  async logout(): Promise<void> {
    await this.auth0Client.logout({
      openUrl: (url: string) => {
        window.location.replace(url);
      },
    });
  }

  getProviderText(userType: ExternalUserType): string {
    let text = 'Cloud Provider';
    if (!userType) {
      return text;
    }
    switch (userType) {
      case ExternalUserType.Azuer:
        text = 'Office 365';
        break;
      case ExternalUserType.Google:
        text = 'Google';
        break;
      case ExternalUserType.Exchange:
        text = 'Exchange';
        break;
    }
    return text;
  }

  redirectToOptionEmailAccountPage(isSuperdiaryFirm: boolean): void {
    console.warn('redirectToOptionEmailAccountPage is not implemented for Auth0');
  }

  openAzureTenantEmailSetting(): void {
    console.warn('openAzureTenantEmailSetting is not implemented for Auth0');
  }

  async changePassword(params: { redirectUrl?: string; newWindow: boolean; callback?: any }): Promise<void> {
    const changePasswordUrl = `${environment.config.auth0.domain}/lo/reset?client=${environment.config.auth0.clientId}&redirect_uri=${encodeURIComponent(
      params.redirectUrl || window.location.origin
    )}`;
    if (params.newWindow) {
      window.open(changePasswordUrl, '_blank');
    } else {
      window.location.assign(changePasswordUrl);
    }
    if (params.callback) {
      params.callback();
    }
  }

  async loginWithRedirect(options?: any): Promise<void> {
    await this.ensureInitialized();
    try {
      await this.auth0Client.loginWithRedirect(options);
    } catch (error) {
      this._log.error('Error during Auth0 loginWithRedirect:', error);
    }
  }

  private decodeJwt(token: string): any {
    if (!token) {
      return null;
    }
    const parts = token.split('.');
    if (parts.length !== 3) {
      this._log.error('JWT must have 3 parts.');
      return null;
    }
    const payload = parts[1];
    try {
      const decodedPayload = atob(this.padBase64(payload));
      return JSON.parse(decodedPayload);
    } catch (error) {
      this._log.error('Error decoding JWT:', error);
      return null;
    }
  }

  private padBase64(base64: string): string {
    return base64.padEnd(base64.length + (4 - (base64.length % 4)) % 4, '=');
  }

  async getDecodedAccessToken(): Promise<any> {
    try {
      const token = await this.auth0Client.getTokenSilently();
      return this.decodeJwt(token);
    } catch (error) {
      this._log.error('Error getting decoded access token:', error);
      return null;
    }
  }
}
