import { createFeatureSelector } from '@ngrx/store';
import * as sidebarActions from '@app/core/store/actions/sidebar.action';
import * as emailActions from '../actions/email.actions';
import * as emailDraftActions from '../actions/draft-email.actions';
import * as appActions from '../../../../core/store/actions';
import * as signatureActions from '@app/features/+email/store/actions/signature.actions';
import { Attachment, EmailSignature, EmptyEmailSignature, IEmail } from '../../models';

import * as attachCorrespondenceActions from 'app/features/+create-pdf/store/actions/attach-correspondence.actions';
import { isEmptyArray, omitObjValue } from '@server/modules/shared/functions/common-util.functions';

export type EmailError = any | string | null | undefined;

export interface EmailState {
  draft: IEmail;
  attachments: Attachment[];
  email: IEmail;
  loading: boolean;
  loaded: boolean;
  emailSent: boolean;
  subject: string;
  signature: EmailSignature;
  dirty: boolean;
  error: EmailError;
  newWin: boolean;
}

export const INITIAL_STATE: EmailState = {
  draft: undefined,
  email: undefined,
  attachments: [],
  error: undefined,
  loading: false,
  emailSent: false,
  subject: undefined,
  signature: undefined,
  dirty: false,
  loaded: false,
  newWin: false,
};

export const reducer = (
  state: EmailState = INITIAL_STATE,
  {
    type,
    payload,
  }:
    | sidebarActions.SidebarActions
    | emailActions.EmailActions
    | signatureActions.SignatureActions
    | emailDraftActions.DraftEmailActions
    | attachCorrespondenceActions.AttachCorrespondenceActions
    | appActions.All
): Partial<EmailState> => {
  switch (type) {
    case appActions.INITIALISE_EMAIL:
    case emailDraftActions.CREATE_DRAFT: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case emailDraftActions.CREATE_DRAFT_SUCCESS: {
      return state.loading && !state.loaded
        ? {
            ...state,
            draft: payload as IEmail,
            email: {
              ...state.email,
              ...omitObjValue(payload, ['attachments', 'cc', 'bcc', 'to', 'body']),
            },
            loading: false,
            loaded: true,
          }
        : state;
    }
    case emailActions.GET_EMAIL_SUBJ_START_SUCCESS: {
      return state.loading || state.loaded
        ? {
            ...state,
            subject: payload,
          }
        : state;
    }
    case emailDraftActions.DISCARD_DRAFT: {
      return {
        ...state,
        loading: true,
      };
    }

    case emailDraftActions.DISCARD_DRAFT_SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }

    // send email
    case emailActions.SEND_EMAIL: {
      return {
        ...state,
        email: payload,
        loading: true,
      };
    }

    case emailActions.SEND_EMAIL_SUCCESS: {
      return {
        ...INITIAL_STATE,
        newWin: state.newWin,
        emailSent: true,
        loading: false,
      };
    }

    case emailActions.HYDRATE_EMAIL_FORM_SUCCESS: {
      return {
        ...state,
        attachments: [],
        dirty: false,
        loading: false,
        loaded: false,
      };
    }

    case emailActions.DEHYDRATE_EMAIL_FORM_SUCCESS: {
      const email = payload as IEmail;

      return email
        ? {
            ...state,
            attachments: isEmptyArray(email.attachments) ? [] : [...email.attachments],
            subject: email.subject,
            newWin: true,
            email: { ...state.email, ...email },
          }
        : {
            ...state,
          };
    }
    case emailDraftActions.CREATE_DRAFT_IN_OUTLOOK_FAILURE:
    case emailActions.SEND_EMAIL_FAILURE:
    case emailDraftActions.CREATE_DRAFT_FAILURE:
    case emailDraftActions.DISCARD_DRAFT_FAILURE:
    case emailActions.ADD_ATTACHMENTS_FAILURE:
    case emailActions.DELETE_ATTACHMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    }

    case emailActions.CLOSE_EMAIL: {
      return {
        ...state,
        attachments: [],
        loaded: false,
      };
    }

    case emailActions.MARK_EMAIL_FORM_DIRTY: {
      return {
        ...state,
        dirty: payload,
      };
    }

    case emailActions.ADD_RECIPIENTS_START: {
      return {
        ...state,
        email: { ...state.email, to: payload },
      };
    }

    case emailActions.ADD_ATTACHMENTS: {
      return {
        ...state,
        loading: true,
      };
    }

    case emailActions.ADD_ATTACHMENTS_SUCCESS: {
      return state.loading || state.loaded
        ? {
            ...state,
            attachments: [...state.attachments, ...payload],
            loading: false,
            dirty: true,
          }
        : state;
    }

    case emailActions.DELETE_ATTACHMENT: {
      return {
        ...state,
        loading: true,
      };
    }

    case emailActions.DELETE_ATTACHMENT_SUCCESS: {
      const idx = state.attachments.findIndex((r) => r.id === payload.id);
      if (idx === -1) {
        return state;
      }
      const attachments = [...state.attachments];
      attachments.splice(idx, 1);

      return state.loading || state.loaded
        ? {
            ...state,
            loading: false,
            attachments,
          }
        : state;
    }

    case signatureActions.GET_SIGNATURE_SUCCESS: {
      return state.loading || state.loaded
        ? {
            ...state,
            signature: payload,
          }
        : state;
    }

    case signatureActions.GET_SIGNATURE_FAILURE: {
      return state.loading || state.loaded
        ? {
            ...state,
            signature: EmptyEmailSignature,
          }
        : state;
    }

    case signatureActions.SAVE_SIGNATURE_SUCCESS: {
      return state.loading || state.loaded
        ? {
            ...state,
            signature: payload,
          }
        : state;
    }

    case signatureActions.SAVE_SIGNATURE_FAILURE: {
      return state.loading || state.loaded
        ? {
            ...state,
            signature: EmptyEmailSignature,
          }
        : state;
    }

    case attachCorrespondenceActions.ATTACH_CORRESPONDENCE_SUCCESS: {
      return state.loading || state.loaded
        ? {
            ...state,
            attachments: [...state.attachments, ...payload],
          }
        : state;
    }

    default:
      return state;
  }
};

export const selectEmailStateFeature = createFeatureSelector<EmailState>('email');
