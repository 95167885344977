import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AppApiService } from '@app/core/api';
import { Subject, Subscription } from 'rxjs';
import { filter as rxjsFilter, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { getObjValue, isString } from '../../../../../server/modules/shared/functions/common-util.functions';
import { IBatch } from '@app/features/+batch-search/models';
import { selectBatchSearchSelectResponse } from '@app/features/+batch-search/store/selectors';

@Component({
  selector: 'sc-batch-select',
  templateUrl: './batch-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BatchSelectComponent),
      multi: true,
    },
  ],
})
export class BatchSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('select', { static: true }) selectComponent: NgSelectComponent;

  @Input()
  label: string;

  @Input()
  bindValue: string;

  @Input()
  bindLabel: string;

  @Input()
  isDisabled: boolean;

  @Input()
  useSelectorDetailOutlet = false;

  @Input()
  wrapperClass: string;

  @Output()
  batchSelected = new EventEmitter<any>();

  private _value: any = '';
  get value(): any {
    return this._value;
  }
  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
    }
  }

  private _batchSelectModalSub: Subscription;
  private _unsub = new Subject<void>();

  constructor(private _appApiSvc: AppApiService, private _store: Store<any>) {}

  ngOnInit() {}

  ngOnDestroy() {
    this._unsub.next();
    this._unsub.complete();
  }

  onBatchSelected(batch: IBatch) {
    this.batchSelected.emit(batch);
  }

  openBatchSelector() {
    if (!!this._batchSelectModalSub) {
      this._batchSelectModalSub.unsubscribe();
    }
    this._batchSelectModalSub = this._store
      .pipe(
        take(1),
        tap(() => {
          let path: any = [{ outlets: { selector: ['batch-search-page'] } }];

          if (this.useSelectorDetailOutlet) {
            path = [{ outlets: { selectorDetail: ['batch-search-page'] } }];
          }

          this._appApiSvc.navigate({
            path,
            extras: { queryParamsHandling: 'merge' },
          });
        }),
        mergeMap(() => this._store.pipe(
          select(selectBatchSearchSelectResponse),
          rxjsFilter((response) => response !== null),
          take(1),
          tap((response: IBatch) => {
            if (!!response) {
              this.onBatchSelected(response);
            }
          })
        )),
        takeUntil(this._unsub) // important: this operator must be last
      )
      .subscribe();
  }

  staffLabel(item: IBatch) {
    return item.BatchNo;
  }

  writeValue(value: any): void {
    if (isString(value)) {
      this._value = value;
    } else {
      this._value = getObjValue(value, this.bindValue);
    }
  }

  onChange = (_) => {};
  registerOnChange(fn: (value?: any) => void): void {
    this.onChange = fn;
  }

  onTouched = () => {};
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
