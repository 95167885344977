import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LogService } from '@app/core/services/log/log.service';
import { BaseService } from '@app/shared/services/base/base.service';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class FirmService extends BaseService {
  constructor(private _http: HttpClient, private _log: LogService) {
    super();
    this._log.init('firm-service');
  }

  /**
   * Get Firm Details
   *
   * @param data: {fetchMode?: string, fields?: string[]}
   * */
  getFirmDetails(data: { fetchMode?: string; fields?: string[] }): Observable<any> {
    const { fetchMode, fields } = data;
    let params = new HttpParams();
    if (fetchMode) {
      params = params.set('fetchMode', fetchMode);
    }
    if (!!fields && fields.length > 0) {
      params = params.set('fields', fields.toString());
    }
    const url = `${this.siriusPath}/api/firm/details`;
    return this._http.get<any>(url, { params });
  }

  getFirmDetailsInFull(): Observable<{ name: string; address: string }> {
    const url = `${this.siriusPath}/api/firm/firmDetailsInFull`;
    return this._http.get<{ name: string; address: string }>(url);
  }
}
