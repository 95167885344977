import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { LocaleInfoMap } from '@app/core/constants';
import { FirmDetails } from '@app/shared/models';
import { getObjValue } from '../../../../../server/modules/shared/functions/common-util.functions';
import { selectFirmDetails } from '@app/core/store/selectors/app-state.selectors';

@Pipe({ name: 'scAccDate' })
export class ScAccDatePipe implements PipeTransform, OnDestroy {
  // relating to LS-988 we are to generate standard date formatting for accounting ledgers
  // these formats are based on locale {AU & UK: dd/MM/yyyy, US: MM/dd/yyyy, CA: yyyy/MM/dd}
  private _regionSub: Subscription;
  private _localeId: string;
  private unsub = new Subject<void>();

  constructor(private _datePipe: DatePipe, private _store: Store<any>) {
    this._localeId = 'en-AU';
    this._regionSub = this._store
      .pipe(
        select(selectFirmDetails),
        map((firmDetails: FirmDetails) => getObjValue(firmDetails, 'region', 'en-AU')),
        takeUntil(this.unsub) // important: this operator must be last
      )
      .subscribe((region: string) => {
        this._localeId = (LocaleInfoMap[region] || {})['localeId'] || 'en-AU';
      });
  }

  ngOnDestroy() {
    this._regionSub.unsubscribe();
    this.unsub.next();
    this.unsub.complete();
  }

  transform(value: string | number | Date, displayTime: boolean = false): string {
    let dateFormat = getLocaleFormat(this._localeId);
    if (!!displayTime) {
      dateFormat = dateFormat + ' h:mm a';
    }
    return this._datePipe.transform(value, dateFormat, undefined, this._localeId);
  }
}

const getLocaleFormat = (locale: string) => {
  switch (locale) {
    case LocaleInfoMap.us.localeId:
      return 'MM/dd/yyyy';

    case LocaleInfoMap.ca.localeId:
      return 'yyyy/MM/dd';

    default:
      return 'dd/MM/yyyy';
  }
};
