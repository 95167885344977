import { map, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as toastrActions from '@app/core/store/actions/toastr.action';
import { LogService } from '@app/core/services';
import { selectAllToastr } from '@app/core/store/selectors';
import { IToastrRenderer } from '@app/core/models';

@Injectable()
export class ToastrEffects {

  updateToastrRendererStart = createEffect(() => this.actions$.pipe(
    ofType(toastrActions.UPDATE_TOASTR_RENDERER_START),
    map((action: toastrActions.UpdateToastrRendererStart) => action.payload),
    withLatestFrom(this._store.pipe(select(selectAllToastr)), (payload, renderers) => ({ payload, renderers })),
    map((stateData: { payload: Partial<IToastrRenderer>; renderers: IToastrRenderer[] }) => {
      const { payload, renderers } = stateData;
      const activeRenderer = renderers?.find((r) => r.toastrId === payload.toastrId);
      if (!!activeRenderer) {
        return new toastrActions.UpdateToastrRendererSuccess({ ...activeRenderer, ...payload });
      } else {
        return new toastrActions.UpdateToastrRendererFailure(`Unable to find toastr with id ${payload.toastrId}`);
      }
    })
  ));

  constructor(private actions$: Actions, private _log: LogService, private _store: Store<any>) {
    this._log.init('toastr.effects');
  }
}
