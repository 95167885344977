<button
  type="button"
  class="btn x-btn-icon"
  [ngClass]="btnClass"
  [disabled]="disabled || isLoading"
  [tabindex]="tabindex"
  [class.x-btn-loading]="isLoading"
  scPreventDefault
  (click)="onBtnClick($event)"
>
  <sc-icon [iconName]="iconName"></sc-icon>
  <span class="x-btn-label">
    <ng-content></ng-content>
  </span>
  <sc-pre-load *ngIf="isLoading" icon="kebab"></sc-pre-load>
</button>
