import { Action } from '@ngrx/store';
import { INamedList } from '@app/features/+card/models';

export enum NamedListApiActionTypes {
  INIT_NAMEDLISTS_START = '[Named List] init start',
  INIT_NAMEDLISTS_SUCCESS = '[Named List] init success',
  INIT_NAMEDLISTS_FAILURE = '[Named List] init failure',
  UPDATE_NAMEDLISTS = '[Named List] update cached named list',
  SAVE_NAMEDLIST = '[Named List] save a named list',
}

export class InitNamedListsStart implements Action {
  readonly type = NamedListApiActionTypes.INIT_NAMEDLISTS_START;

  constructor(public payload: any) {}
}

export class InitNamedListsSuccess implements Action {
  readonly type = NamedListApiActionTypes.INIT_NAMEDLISTS_SUCCESS;

  constructor(public payload: any) {}
}

export class InitNamedListsFailure implements Action {
  readonly type = NamedListApiActionTypes.INIT_NAMEDLISTS_FAILURE;

  constructor(public payload: any) {}
}

export class UpdateNamedLists implements Action {
  readonly type = NamedListApiActionTypes.UPDATE_NAMEDLISTS;

  constructor(public payload: any) {}
}

export class SaveNamedList implements Action {
  readonly type = NamedListApiActionTypes.SAVE_NAMEDLIST;

  constructor(public payload: { namedList: INamedList }) {}
}

export type NamedListApiActions =
  | InitNamedListsStart
  | InitNamedListsSuccess
  | InitNamedListsFailure
  | UpdateNamedLists
  | SaveNamedList;
