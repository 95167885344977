import { Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-boolean-icon-cell',
  templateUrl: './boolean-icon-cell.component.html',
})
export class BooleanIconCellComponent implements AgRendererComponent {
  value: boolean;
  iconClasses: { positive: string; negative: string };
  sizeClass: string;
  iconSize: string;
  iconName: string;

  agInit(params: any): void {
    const rowObj = params.data;
    const safeFunctionCall = (func: (obj: any) => any, obj: any) => isFunction(func) ? func(obj) : null;

    this.value = safeFunctionCall(params.getValue, rowObj) || false;
    this.iconClasses = safeFunctionCall(params.getIconClasses, rowObj) || { positive: 'tick', negative: 'minus' };
    this.sizeClass = safeFunctionCall(params.getSizeClass, rowObj);
    if (this.sizeClass === 'x-icon-xl') {
      this.iconSize = '-24';
    } else if (this.sizeClass === 'x-icon-md') {
      this.iconSize = '-16';
    } else {
      this.iconSize = '';
    }

    this.iconName = `${this.value ? this.iconClasses.positive : this.iconClasses.negative}${this.iconSize}`;
  }

  refresh(params: any): boolean {
    return true;
  }
}
