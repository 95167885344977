import { Action } from '@ngrx/store';
import { IAutoTimeEndTaskPayload, IAutoTimeEntry, IAutoTimeExternalJSON, IAutoTimeStartTaskPayload } from '../../models';

export enum AutoTimeActionTypes {
  AUTOTIME_TRY_SET_BILLABLE = '[AutoTime] Try set billable',
  AUTOTIME_SET_BILLABLE = '[AutoTime] Set billable',

  AUTOTIME_TRY_START_TASK = '[AutoTime] Try Start Task',
  AUTOTIME_START_TASK_SUCCESS = '[AutoTime] Start Task Success',
  AUTOTIME_END_TASK = '[AutoTime] End Task',
  AUTOTIME_CLEAR_TASK = '[AutoTime] Clear Task',

  AUTOTIME_SEND_TIME_ENTRY_START = '[AutoTime] Send time entry start',
  AUTOTIME_SEND_TIME_ENTRY_SUCCESS = '[AutoTime] Send time entry success',
  AUTOTIME_SEND_TIME_ENTRY_FAILURE = '[AutoTime] Send time entry failure',

  AUTOTIME_DELETE_ENTRY_START = '[AutoTime] Delete entry start',
  AUTOTIME_DELETE_ENTRY_SUCCESS = '[AutoTime] Delete entry success',
  AUTOTIME_DELETE_ENTRY_FAILURE = '[AutoTime] Delete entry failure',
}

export class AutoTimeTrySetBillable implements Action {
  readonly type = AutoTimeActionTypes.AUTOTIME_TRY_SET_BILLABLE;
  constructor(public payload: boolean) {}
}

export class AutoTimeSetBillable implements Action {
  readonly type = AutoTimeActionTypes.AUTOTIME_SET_BILLABLE;
  constructor(public payload: boolean) {}
}

export class AutoTimeTryStartTask implements Action {
  readonly type = AutoTimeActionTypes.AUTOTIME_TRY_START_TASK;
  constructor(public payload: IAutoTimeStartTaskPayload) {}
}

export class AutoTimeStartTaskSuccess implements Action {
  readonly type = AutoTimeActionTypes.AUTOTIME_START_TASK_SUCCESS;
  constructor(public payload: {
    task: IAutoTimeExternalJSON;
    matterId?: string;
  }) {}
}

export class AutoTimeEndTask implements Action {
  readonly type = AutoTimeActionTypes.AUTOTIME_END_TASK;
  constructor(public payload: IAutoTimeEndTaskPayload) {}
}

export class AutoTimeClearTask implements Action {
  readonly type = AutoTimeActionTypes.AUTOTIME_CLEAR_TASK;
}

export class AutoTimeSendTimeEntryStart implements Action {
  readonly type = AutoTimeActionTypes.AUTOTIME_SEND_TIME_ENTRY_START;
  constructor(public payload: IAutoTimeEntry) {}
}

export class AutoTimeSendTimeEntrySuccess implements Action {
  readonly type = AutoTimeActionTypes.AUTOTIME_SEND_TIME_ENTRY_SUCCESS;
  constructor(public payload: any) {}
}

export class AutoTimeSendTimeEntryFailure implements Action {
  readonly type = AutoTimeActionTypes.AUTOTIME_SEND_TIME_ENTRY_FAILURE;
  constructor(public payload: any) {}
}

export class AutoTimeDeleteEntryStart implements Action {
  readonly type = AutoTimeActionTypes.AUTOTIME_DELETE_ENTRY_START;
  constructor(public payload: string) {}
}

export class AutoTimeDeleteEntrySuccess implements Action {
  readonly type = AutoTimeActionTypes.AUTOTIME_DELETE_ENTRY_SUCCESS;
  constructor(public payload: any) {}
}

export class AutoTimeDeleteEntryFailure implements Action {
  readonly type = AutoTimeActionTypes.AUTOTIME_DELETE_ENTRY_FAILURE;
  constructor(public payload: any) {}
}

export type AutoTimeActions =
  | AutoTimeTrySetBillable
  | AutoTimeSetBillable
  | AutoTimeTryStartTask
  | AutoTimeStartTaskSuccess
  | AutoTimeEndTask
  | AutoTimeClearTask
  | AutoTimeSendTimeEntryStart
  | AutoTimeSendTimeEntrySuccess
  | AutoTimeSendTimeEntryFailure
  | AutoTimeDeleteEntryStart
  | AutoTimeDeleteEntrySuccess
  | AutoTimeDeleteEntryFailure;
