import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sc-btn-with-icon',
  templateUrl: './button-with-icon.component.html',
})
export class ButtonWithIconComponent implements OnInit {
  @Input() btnType: string;
  @Input() btnSize: string;
  @Input() disabled: boolean;
  @Input() isLoading = false;
  @Input() tabindex = 0;
  @Input() iconName: string;
  @Input() styleClass = '';
  @Output() btnClicked = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit() {}

  onBtnClick(event: any) {
    this.btnClicked.emit(event);
  }

  buildBtnSize(): any {
    if (this.btnSize) {
      return 'btn-' + this.btnSize + ' ';
    } else {
      return '';
    }
  }
  get btnClass(): string {
    if (this.btnType === ('default' || 'outline-default')) {
      return this.buildBtnSize() + `x-btn-${this.btnType} ${this.styleClass}`;
    } else {
      return this.buildBtnSize() + `btn-${this.btnType} ${this.styleClass}`;
    }
  }
}
