import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { differenceInMilliseconds } from 'date-fns';
import {
  ENotificationEntityType,
  INotification,
  INotificationAppointmentBody,
  INotificationTaskBody,
} from '../../models/notification.model';
import * as actions from '../actions';

export interface State extends EntityState<INotification> {
  firmId: string;
  continuationToken: string;
  loaded: boolean;
  loading: boolean;
  selectedNotificationId: number;
  staffId: string;
  lastEvaluatedKey: string;
  notificationProcessing: boolean;
  showAll: boolean;
}

export const getDateStringValueForSorting = (noti: INotification): string => {
  // dates must be handled this way to correctly avoid issues with time zone information
  let timestamp = noti.timestamp + 'Z';
  if (noti.format === 'json') {
    const notifEntityType = noti.entity.type;
    // we using the dueDate value from notification's body
    if (notifEntityType === ENotificationEntityType.Appointment || notifEntityType === ENotificationEntityType.Task) {
      const notifBody = atob(noti.body);
      const notifBodyJson = JSON.parse(notifBody) as INotificationAppointmentBody | INotificationTaskBody;
      timestamp = notifBodyJson.dueDate;
    }
  }
  return timestamp;
};

export const sortNoti = (a: INotification, b: INotification): number => {
  const dateStringA = getDateStringValueForSorting(a);
  const dateStringB = getDateStringValueForSorting(b);
  return differenceInMilliseconds(new Date(dateStringA), new Date(dateStringB));
};

export const adapter: EntityAdapter<INotification> = createEntityAdapter<INotification>({
  selectId: (notification) => notification.id,
  sortComparer: sortNoti,
});

export const INITIAL_STATE: State = adapter.getInitialState({
  firmId: '',
  continuationToken: '',
  loaded: false,
  loading: false,
  selectedNotificationId: null,
  staffId: '',
  lastEvaluatedKey: '0',
  notificationProcessing: false,
  showAll: false,
});

export const reducer = (state = INITIAL_STATE, action: actions.NotificationDbActions | actions.NotificationApiActions) => {
  switch (action.type) {
    case actions.NotificationDbActionTypes.LOAD_NOTIFICATIONS_DB_START:
      return {
        ...state,
        loading: true,
      };

    case actions.NotificationDbActionTypes.LOAD_NOTIFICATIONS_DB_FAIL:
      return {
        ...state,
        loading: false,
      };

    case actions.NotificationApiActionTypes.GET_NOTIFICATION_BATCH:
      return {
        ...state,
        loading: true,
      };

    case actions.NotificationApiActionTypes.LIST_NOTIFICATIONS_SUCCESS:

      //console.debug('=====> LIST_NOTIFICATIONS_SUCCESS', { action, state });

      return {
        ...adapter.upsertMany(action.payload.data.notifications, state),
        loaded: true,
        loading: false,
        continuationToken: action.payload.data.continuationToken,
        staffId: action.payload.data.staffId,
        firmId: action.payload.data.firmId,
        lastEvaluatedKey: action.payload.data.lastEvaluatedKey,
        selectedNotificationId: null,
      };

    case actions.NotificationApiActionTypes.LIST_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case actions.NotificationApiActionTypes.STORE_META_INFORM:
      return {
        ...state,
        ...action.payload,
      };

    case actions.NotificationApiActionTypes.SET_SELECTED_NOTIFICATION_ID:
      return {
        ...state,
        selectedNotificationId: action.payload.id,
      };

    case actions.NotificationApiActionTypes.CLEAR_SELECTED_NOTIFICATION_ID:
      return {
        ...state,
        selectedNotificationId: null,
      };

    case actions.NotificationApiActionTypes.DELETE_NOTIFICATIONS:
      return adapter.removeMany(action.payload.ids, state);

    case actions.NotificationApiActionTypes.ACKNOWLEDGE_NOTIFICATION:
    case actions.NotificationApiActionTypes.ACKNOWLEDGE_NOTIFICATION_BATCH:
      return {
        ...state,
        notificationProcessing: true,
      };

    case actions.NotificationApiActionTypes.ACKNOWLEDGE_NOTIFICATION_SUCCESS:
    case actions.NotificationApiActionTypes.ACKNOWLEDGE_NOTIFICATION_FAIL:
    case actions.NotificationApiActionTypes.ACKNOWLEDGE_NOTIFICATION_BATCH_SUCCESS:
    case actions.NotificationApiActionTypes.ACKNOWLEDGE_NOTIFICATION_BATCH_FAIL:
      return {
        ...state,
        notificationProcessing: false,
      };

    case actions.NotificationApiActionTypes.CHANGE_NOTIFICATION_SCOPE:
      return {
        ...state,
        showAll: action.payload.showAll,
      };

    case actions.NotificationApiActionTypes.CLEAR_NOTIFICATION_STATE:
      return {
        ...state,
        showAll: false,
      };

    default:
      return state;
  }
};

export const selectLoaded = (state: State) => state.loaded;
export const selectStaffId = (state: State) => state.staffId;
export const selectLastEvaluatedKey = (state: State) => state.lastEvaluatedKey;
export const selectFirmId = (state: State) => state.firmId;
export const selectContinuationToken = (state: State) => state.continuationToken;
export const selectSelectedNotificationId = (state: State) => state.selectedNotificationId;
export const selectNotificationProcessing = (state: State) => state.notificationProcessing;
export const selectShowAll = (state: State) => state.showAll;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of notification ids
export const selectNotificationIds = selectIds;

// select the total notification count
export const selectNotificationTotal = selectTotal;

// select the dictionary of notification entities
export const selectNotificationEntities = selectEntities;

// select the array of notifications
export const selectAllNotifications = selectAll;
