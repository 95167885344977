import { Injectable } from '@angular/core';
import { BaseStorageService } from '@app/shared/services/base/base-storage.service';
import { CardListMetaInfo, ICardListEntry, ICardListResponseSchema } from '@app/features/+card/models';
import { DexieService } from '@app/core/services/dexie/dexie.service';
import { PlatformService } from '@app/core/services/platform/platform.service';
import { LogService } from '@app/core/services/log/log.service';
import Dexie from 'dexie';
import { from, Observable } from 'rxjs';

@Injectable()
export class CardListStorageService extends BaseStorageService<ICardListEntry> {
  private metaKey = '__CARD_LIST_META__';
  private metaTable: Dexie.Table<CardListMetaInfo, string>;

  constructor(private dexieSvc: DexieService, private log: LogService, private _platformSvc: PlatformService) {
    super();
    log.init('card-list-storage-service');
    if (this._platformSvc.isBrowser) {
      this.table = this.dexieSvc.table('cards');
      this.metaTable = this.dexieSvc.table('cardsMeta');
    }
  }

  public getMeta(): Observable<CardListMetaInfo> {
    if (this._platformSvc.isBrowser) {
      return from(this.metaTable.get(this.metaKey));
    }
  }

  public upsertAll(res: ICardListResponseSchema) {
    if (this._platformSvc.isBrowser) {
      const metaInfo: Partial<CardListMetaInfo> = {
        id: this.metaKey,
        lastRowVer: res.lastRowVer,
        total: res.total,
      };

      return this.upertMeta(metaInfo).then(() => this.bulkPut(res.data));
    }
  }

  public upertMeta(partialMetaInfo: Partial<CardListMetaInfo>) {
    if (this._platformSvc.isBrowser) {
      return this.metaTable.get(this.metaKey).then((currentMeta: CardListMetaInfo) => {
        const metaInfo = {
          ...currentMeta,
          ...partialMetaInfo,
          id: this.metaKey,
        };
        return this.metaTable.put(metaInfo);
      });
    }
  }

  public handleDatabaseError(error: any): any {
    return;
  }
}
