import { Component, OnInit } from '@angular/core';
import { PlatformService } from '@app/core/services';

@Component({
  selector: 'sc-not-found',
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent implements OnInit {
  isLeapDesktop = false;

  constructor(private _ps: PlatformService) {
    this.isLeapDesktop = !!this._ps.window['isLeapCloud'];
  }

  ngOnInit() {}
}
