import { IPerson } from '@app/shared/models';
import { uniqString } from '@server/modules/shared/functions/common-util.functions';
import * as personDetailsActions from '../actions/person-details';

export interface State {
  loading: boolean;
  personDetails: IPerson;
  formValue: IPerson;
  personProcessing: boolean;
  invalidControls: string[];
  relatedCardIds: string[];
  relatedMatterIds: string[];
}

export const INITIAL_STATE: State = {
  loading: false,
  personDetails: null,
  formValue: null,
  personProcessing: false,
  invalidControls: [],
  relatedCardIds: [],
  relatedMatterIds: [],
};

export const reducer = (state = INITIAL_STATE, action: personDetailsActions.PersonDetailsActions) => {
  switch (action.type) {
    case personDetailsActions.PersonDetailsActionTypes.LOAD_PERSON_DETAILS_START:
      return {
        ...state,
        loading: true,
      };

    case personDetailsActions.PersonDetailsActionTypes.LOAD_PERSON_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        personDetails: action.payload.data,
        formValue: action.payload.data,
        relatedCardIds: action.payload.relatedCardIds,
        relatedMatterIds: action.payload.relatedMatterIds,
        invalidControls: [],
      };

    case personDetailsActions.PersonDetailsActionTypes.LOAD_PERSON_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case personDetailsActions.PersonDetailsActionTypes.UPDATE_FORM_VALUE:
      return {
        ...state,
        formValue: { ...state.formValue, ...action.payload.data },
      };

    case personDetailsActions.PersonDetailsActionTypes.SAVE:
      return {
        ...state,
        personProcessing: true,
      };

    case personDetailsActions.PersonDetailsActionTypes.SAVE_SUCCESS:
      return {
        ...state,
        personProcessing: false,
        personDetails: {...state.formValue},
      };

    case personDetailsActions.PersonDetailsActionTypes.SAVE_FAIL:
      return {
        ...state,
        personProcessing: false,
      };

    case personDetailsActions.PersonDetailsActionTypes.SET_PERSON_PROCESSING_STATUS:
      return {
        ...state,
        personProcessing: action.payload.processing,
      };

    case personDetailsActions.PersonDetailsActionTypes.RESET:
      return {
        ...state,
        formValue: state.personDetails,
        invalidControls: [],
      };

    case personDetailsActions.PersonDetailsActionTypes.ADD_PERSON_DETAILS_INVALID_CONTROLS:
      return {
        ...state,
        invalidControls: uniqString([...state.invalidControls, ...action.payload.names]) as string[],
      };

    case personDetailsActions.PersonDetailsActionTypes.REMOVE_PERSON_DETAILS_INVALID_CONTROLS:
      return {
        ...state,
        invalidControls: state.invalidControls?.filter((control) => !action.payload.names.includes(control)) || [],
      };

    case personDetailsActions.PersonDetailsActionTypes.CLEAR:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const getLoading = (state: State) => state.loading;
export const getPersonDetails = (state: State) => state.personDetails;
export const getFormValue = (state: State) => state.formValue;
export const getPersonProcessing = (state: State) => state.personProcessing;
export const getInvalidControls = (state: State) => state.invalidControls;
export const getRelatedCardIds = (state: State) => state.relatedCardIds;
export const getRelatedMatterIds = (state: State) => state.relatedMatterIds;
