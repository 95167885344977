import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Staff } from '@app/shared/models';
import { Store } from '@ngrx/store';
import { AppApiService } from '@app/core/api';

import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
  ECardFilterType,
  ECardListModelEvent,
  ICardListEntry,
  ICardListModelSelection,
} from '@app/features/+card/models';
import * as cardListModalActions from '@app/features/+card/store/actions/card-list-modal';
import { NgSelectComponent } from '@ng-select/ng-select';
import { EventBusService } from '@app/core/services';
import { ESiriusEvents } from '@app/core/models';
import { getObjValue, isEmptyObj, isString } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-staff-select',
  templateUrl: './staff-select.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => StaffSelectComponent), multi: true }],
})
export class StaffSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('select', { static: true }) selectComponent: NgSelectComponent;

  @Input()
  label: string;

  private _items: Staff[];
  @Input()
  get items() {
    return this._items;
  }

  set items(value: Staff[]) {
    if (!!value) {
      this._items = value;
      this.filteredItems = value;
    } else {
      this._items = [];
      this.filteredItems = [];
    }
  }

  @Input()
  isDisabled: boolean;

  @Input()
  clearable = false;

  @Input()
  showAllCard = false;

  @Output()
  staffSelected = new EventEmitter<any>();

  @Input()
  useSelectorDetailOutlet = false;

  cardListModalConfirmSub: Subscription;
  private _value: any = '';
  private selectedCardEntry: ICardListEntry;
  private unsub = new Subject<void>();
  public filteredItems: Staff[];

  onChange = (_) => {};
  onTouched = () => {};

  constructor(private _appApiSvc: AppApiService, private _store: Store<any>, private _eventBusSvc: EventBusService) {}

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  ngOnInit() {
  }

  writeValue(value: any): void {
    if (isString(value)) {
      this._value = value;
    } else if (!isEmptyObj(value)){
      this._value = getObjValue(value, '__id');
    } else {
      delete this.selectedCardEntry;
      this._value = null;
    }
  }

  registerOnChange(fn: (value?: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onStaffSelected(event: Staff) {
    this._value = event;
    this.staffSelected.emit(event);
  }

  openStaffSelector() {
    this.setupCardListModal();
    this.navigateToCardListModal();
    this.subscribeToOnClose();
  }

  focusIn() {
    this.selectComponent.searchInput.nativeElement.focus();
  }

  private subscribeToOnClose() {
    if (this.cardListModalConfirmSub) {
      this.cardListModalConfirmSub.unsubscribe();
    }

    this.cardListModalConfirmSub = this._eventBusSvc
      .listen(ESiriusEvents.GetSelectedCardEntries)
      .pipe(
        take(1),
        takeUntil(this.unsub) // important: this operator must be last
      )
      .subscribe((data: ICardListModelSelection) => {
        const { action, cardEntries } = data;
        if (action === ECardListModelEvent.Confirm && !!cardEntries && !!cardEntries[0]) {
          const cardEntry = cardEntries[0];
          if (
            cardEntry &&
            cardEntry.type === 'People' &&
            (!this.selectedCardEntry || this.selectedCardEntry.cardId !== cardEntry.cardId)
          ) {
            this.selectedCardEntry = cardEntry;
            const staff = this.items.find((i) => i.__id === cardEntry.persons[0]);
            this.onStaffSelected(staff);
          }
        }
      });
  }

  private setupCardListModal() {
    // show the stall list including a 'All' option
    const cardFilterType = this.showAllCard ? ECardFilterType.AllStaff : ECardFilterType.Staff;
    this._store.dispatch(new cardListModalActions.SetCardFilterType({ type: cardFilterType }));
    this._store.dispatch(new cardListModalActions.SetSelectedCardFilterType({ type: 'Staff' }));
    this._store.dispatch(new cardListModalActions.SetSelectedCardEntries({ cardEntries: [this._value] }));
  }

  private navigateToCardListModal() {
    let path: any = [{ outlets: { selector: ['card', 'card-list'] } }];

    if (this.useSelectorDetailOutlet) {
      path = [{ outlets: { selectorDetail: ['card', 'card-list'] } }];
    }

    this._appApiSvc.navigate({
      path,
      query: { matterCard: false },
    });
  }

  staffLabel(item: Staff) {
    return item.fullName;
  }

  filterItemsOnOpen() {
    this.filteredItems = this.items.filter((x) => x.status === 0);
  }

  filterItemsOnClose() {

    this.filteredItems = this.items;
  }
}
