<form [formGroup]="toolbarForm" class="d-flex">
  <!-- TODO: fix this drop down -->
  <div class="btn-toolbar" *ngIf="!hideDropdown">
    <div class="btn-group" dropdown>
      <button type="button" class="btn x-btn-icon x-btn-default dropdown-toggle" dropdownToggle>
        <span class="d-none d-xl-block" [innerHtml]="'PaymentDetails.Options.Button' | translate"></span>
      </button>

      <ul *dropdownMenu class="dropdown-menu dropdown-icons dropdown-menu-right" aria-labelledby="actionsDropdown">
        <li class="dropdown-item">
          <label class="custom-control custom-checkbox">
            <input
              type="checkbox"
              formControlName="reconciled"
              class="custom-control-input"
              scDisableControl
              [disableControl]="status.reconciledDisabled"
            />
            <span class="custom-control-label"> {{ 'PaymentDetails.Options.Reconciled.Label' | translate }}</span>
          </label>
        </li>
        <li class="dropdown-item">
          <label class="custom-control custom-checkbox">
            <input
              type="checkbox"
              formControlName="voidCheque"
              class="custom-control-input"
              scDisableControl
              [disableControl]="status.voidChequeDisabled"
            />
            <span class="custom-control-label"> {{ 'PaymentDetails.Options.VoidCheque.Label' | translate }}</span>
          </label>
        </li>
        <li class="dropdown-item">
          <label class="custom-control custom-checkbox">
            <input
              type="checkbox"
              formControlName="printCheque"
              class="custom-control-input"
              scDisableControl
              [disableControl]="status.printChequeDisabled"
            />
            <span class="custom-control-label"> {{ 'PaymentDetails.Options.PrintCheques.Label' | translate }}</span>
          </label>
        </li>
      </ul>
    </div>
  </div>

  <div class="btn-toolbar ml-auto" *ngIf="!!printAndEmail; else printOnly">
    <sc-switch
      *ngIf="!status.printOnSaveHidden"
      formControlName="printOnSave"
      [textClass]="'text-uppercase'"
      [label]="'Core.Button.PrintAndEmail' | translate"
    ></sc-switch>
    <sc-btn btnType="default" class="btn-group" *ngIf="status.printOnSaveHidden" (btnClicked)="print()">
      {{ 'Core.Button.PrintAndEmail' | translate }}
    </sc-btn>
  </div>

  <ng-template #printOnly>
    <div class="btn-toolbar ml-auto">
      <sc-switch
        *ngIf="!status.printOnSaveHidden"
        formControlName="printOnSave"
        [textClass]="'text-uppercase'"
        [label]="'PaymentDetails.Print.Button' | translate"
      ></sc-switch>
      <sc-btn btnType="default" class="btn-group" *ngIf="status.printOnSaveHidden" (btnClicked)="print()">
        {{ 'Accounting.Print.Button' | translate }}
      </sc-btn>
    </div>
  </ng-template>
</form>
