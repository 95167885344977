<p class="mb-2">{{ 'Accounting.Trust.BankAccountSelector.SwitchAccountMessage' | translate }}</p>
<ng-select
  [clearable]="false"
  [searchable]="false"
  class="focusOnLoad"
  [ngModel]="selectedBankAccountGuid"
  (ngModelChange)="selectBankAccount($event)"
>
  <ng-option *ngFor="let account of bankAccounts" [value]="account.BankAccountGUID">
    {{ account?.NameFileAs }}
  </ng-option>
</ng-select>
