import { createSelector } from '@ngrx/store';

import { selectCardListModalState } from '@app/features/+card/store/reducers';
import * as fromCardListModal from '../reducers/card-list-modal.reducer';
import { ECardFilterType, ICardFilter } from '@app/features/+card/models';
import {
  selectCardFiltersCustomFilters,
  selectCardFiltersDefaultFilters,
} from '@app/core/store/selectors/card-filters.selectores';
import { uniqBy } from '@server/modules/shared/functions/common-util.functions';

export const selectCarListModalCardEntries = createSelector(
  selectCardListModalState,
  (state: fromCardListModal.State) => fromCardListModal.getCardEntries(state)
);

export const selectCardListModalInitialSelected = createSelector(
  selectCardListModalState,
  (state: fromCardListModal.State) => fromCardListModal.getInitialSelected(state)
);

export const selectCardListModalCardEntriesProcessing = createSelector(
  selectCardListModalState,
  (state: fromCardListModal.State) => fromCardListModal.getCardEntriesProcessing(state)
);

export const selectCardListModalCardFilterType = createSelector(
  selectCardListModalState,
  (state: fromCardListModal.State) => fromCardListModal.getCardFilterType(state)
);

export const selectCardListModalCardGuidsForFiltering = createSelector(
  selectCardListModalState,
  (state: fromCardListModal.State) => fromCardListModal.getCardGuidsForFiltering(state)
);

export const selectCardListModalSelectedCardFilterType = createSelector(
  selectCardListModalState,
  (state: fromCardListModal.State) => fromCardListModal.getSelectedCardFilterType(state)
);

export const selectCardListModalCardFilters = createSelector(
  selectCardFiltersDefaultFilters,
  selectCardFiltersCustomFilters,
  selectCardListModalCardFilterType,
  (
    allFilters: Array<{ type: ECardFilterType; filters: ICardFilter[] }>,
    customFilters: ICardFilter[],
    filterType: ECardFilterType
  ) => {
    if (allFilters && allFilters.length > 0) {
      const selectedFilterUseCase = allFilters?.find((data) => data.type === filterType);
      if (selectedFilterUseCase) {
        return uniqBy([...selectedFilterUseCase.filters, ...customFilters], 'value');
      }

      return allFilters[ECardFilterType.CardList] ? allFilters[ECardFilterType.CardList].filters : [];
    } else {
      return [] as ICardFilter[];
    }
  }
);

export const selectCardListModalSelectedCardFilter = createSelector(
  selectCardListModalCardFilters,
  selectCardListModalSelectedCardFilterType,
  (filters, type) => {
    if (!filters || filters.length === 0) {
      return null;
    }

    if (type) {
      const result = filters?.find((filter) => filter.type === type || filter.name === type);
      return result || filters[0];
    } else {
      return filters[0];
    }
  }
);
