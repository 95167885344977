import { filter, map, Observable, Subject, switchMap, take, takeUntil } from 'rxjs';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { TimerService } from '@app/shared/services';
import { select, Store } from '@ngrx/store';
import { selectCurrentMatterId } from '@app/core/store';
import { PlatformService } from '@app/core/services';
import { FeeTimerRecordingDbEntry } from '../../models/fee-timer.model';
import { FeeTimerRecordStorageService } from '../../services/fee-timer-record/fee-timer-record-storage.service';
import * as feeTimerActions from '../../store/actions';

@Component({
  selector: 'sc-time-clock',
  templateUrl: './time-clock.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeClockComponent implements OnInit, OnDestroy {
  @Input() matterId: string;
  @Input() newWin: boolean;

  currentMatterId$: Observable<string>;
  timerDisplay: string;

  private unsub = new Subject<void>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private feeTimerRecordStorageSvc: FeeTimerRecordStorageService,
    private platformSvc: PlatformService,
    private store: Store,
    private timerService: TimerService
  ) {}

  ngOnInit() {
    this.currentMatterId$ = this.store.pipe(select(selectCurrentMatterId));

    this.timerService.accumulatedDuration$.pipe(takeUntil(this.unsub)).subscribe((accumulatedDuration: number) => {
      this.timerDisplay = this.hoursMinutesSeconds(accumulatedDuration);
      this.cdRef.detectChanges();
    });

    if (this.platformSvc.isBrowser) {
      this.timerService.beforeUnloadHander$.pipe(take(1)).subscribe();
    }

    if (this.platformSvc.isBrowser) {
      if (this.newWin && this.matterId) {
        this.feeTimerRecordStorageSvc.get(this.matterId).then((timerEntry: FeeTimerRecordingDbEntry) => {
          if (timerEntry) {
            this.setTimerRecordInStore(timerEntry);
          }
        });
      } else {
        this.currentMatterId$
          .pipe(
            filter((v) => !!v),
            switchMap((id) => this.feeTimerRecordStorageSvc.get(id)),
            map((timerEntry: FeeTimerRecordingDbEntry) => {
              if (timerEntry) {
                return this.setTimerRecordInStore(timerEntry);
              } else {
                return [
                  this.store.dispatch(new feeTimerActions.ResetFeeTimer(null)),
                  this.store.dispatch(new feeTimerActions.FeeTimerTimeElapsedDiscard(undefined)),
                ];
              }
            }),
            takeUntil(this.unsub)
          )
          .subscribe();
      }
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  hoursMinutesSeconds(x: number): string {
    if (!x || !(x > 0)) {
      return '00:00:00';
    }

    const hours = Math.floor(x / 3600);
    const minutes = Math.floor((x - hours * 3600) / 60);
    let seconds = x - hours * 3600 - minutes * 60;

    // round seconds
    seconds = Math.round(seconds * 100) / 100;

    const result = `
        ${hours < 10 ? '0' + hours : hours}:
        ${minutes < 10 ? '0' + minutes : minutes}:
        ${seconds < 10 ? '0' + seconds : seconds}
        `;

    return result.replace(/\s/g, '');
  }

  setTimerRecordInStore(timerEntry: FeeTimerRecordingDbEntry) {
    return [
      this.store.dispatch(
        new feeTimerActions.SetFeeTimer({
          runningTimerFeeGUID: timerEntry.feeGUID ?? undefined,
          isNewEntry: timerEntry.isNewEntry,
          selectedActivityCode: timerEntry.selectedActivityCode,
          pendingTimeEntry: timerEntry.pendingTimeEntry
        })
      ),
      this.store.dispatch(
        new feeTimerActions.FeeTimerTimeElapsedSet({
          durationInSeconds: timerEntry.durationInSeconds,
          startTime: timerEntry.startTime,
          endTime: timerEntry.endTime,
        })
      ),
    ];
  }
}
