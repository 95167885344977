import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';

@Component({
  selector: 'sc-checkbox',
  templateUrl: './checkbox.component.html',
  providers: [NgModel, { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CheckboxComponent), multi: true }],
})
export class CheckboxComponent implements ControlValueAccessor, OnInit {
  @Input()
  type: boolean;
  @Input()
  isDisabled: boolean;
  @Input()
  label: string;
  @Input()
  toolbarButton: boolean;
  @Input()
  toolbarWrapperClass: string;
  @Input()
  set wrapperClass(data: string) {
    this._wrapperClass = data;
  }
  get wrapperClass() {
    return this._wrapperClass || 'custom-control custom-checkbox';
  }
  @Input()
  get value() {
    return this._value;
  }
  set value(v) {
    this._value = v;
  }

  @Output() onValueChange = new EventEmitter<boolean>();

  private _value: boolean;
  private _wrapperClass: string;

  // get value(): any {
  //   return this._value;
  // }
  //
  // set value(v: any) {
  //   if (v !== this._value) {
  //     this._value = v;
  //     this.onChange(v);
  //   }
  // }

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {}

  writeValue(value: any) {
    this._value = !!value;
    this.cdRef.markForCheck();
  }

  onChange = (_) => {};
  onTouched = () => {};

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  handleValueChange(v: boolean): void {
    if (v !== this._value) {
      this._value = v;
      this.onValueChange.emit(this._value);
      this.onChange(v);
    }
  }
}
