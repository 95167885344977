import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'sc-main-section',
  templateUrl: './main-section.component.html',
})
export class MainSectionComponent implements OnInit {
  constructor() {}

  @HostBinding('class')
  mainSectionClasses = 'lt-container overflow-hidden';
  ngOnInit() {}
}
