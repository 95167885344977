<sc-icon [iconName]="iconName"></sc-icon>
<input
  [type]="inputType"
  scAutofocus
  [autofocus]="autofocus"
  [placeholder]="(placeholder | translate) || ''"
  class="form-control"
  [ngClass]="inputClass"
  [ngStyle]="inputStyle"
  [readonly]="readonly"
  [disabled]="isDisabled"
  [ngModel]="value"
  (ngModelChange)="handleModelValueChange($event)"
  (blur)="blur()"
  autocomplete="off"
  #inputElm
/>
<sc-icon *ngIf="hasValue()" iconName="close-circle" class="is-clickable" (click)="resetValue()"></sc-icon>
