import { Action } from '@ngrx/store';
import { GlobalLeapApp, ViewLeapApp, ViewLeapAppLocationId } from '@app/features/matter-addin/models';

export const GET_GLOBAL_LEAP_APPS = '[Leap App] get global leap apps';
export const GET_GLOBAL_LEAP_APPS_SUCCESS = '[Leap App] get global leap apps success';
export const GET_GLOBAL_LEAP_APPS_FAIL = '[Leap App] unable to get global leap apps';
export const CLEAR_GLOBAL_LEAP_APPS = '[Leap App] clear global leap apps';
export const GET_VIEW_LEAP_APPS = '[Leap App] get view leap apps';
export const GET_VIEW_LEAP_APPS_SUCCESS = '[Leap App] get view leap apps success';
export const GET_VIEW_LEAP_APPS_FAIL = '[Leap App] unable to get view leap apps';
export const GET_CARD_PAGE_LEAP_APPS = '[Leap App] get card page leap apps';
export const GET_CARD_PAGE_LEAP_APPS_SUCCESS = '[Leap App] get card page leap apps success';
export const GET_CARD_PAGE_LEAP_APPS_FAIL = '[Leap App] unable to get card page leap apps';
export const GET_CARD_MODAL_PAGE_LEAP_APPS = '[Leap App] get card model page leap apps';
export const GET_CARD_MODAL_PAGE_LEAP_APPS_SUCCESS = '[Leap App] get card model page leap apps success';
export const GET_CARD_MODAL_PAGE_LEAP_APPS_FAIL = '[Leap App] unable to get card model page leap apps';
export const GET_PERSON_PAGE_LEAP_APPS = '[Leap App] get person page leap apps';
export const GET_PERSON_PAGE_LEAP_APPS_SUCCESS = '[Leap App] get person page leap apps success';
export const GET_PERSON_PAGE_LEAP_APPS_FAIL = '[Leap App] unable to get person page leap apps';
export const CLEAR_CARD_PAGE_LEAP_APPS = '[Leap App] clear card page leap apps';
export const CLEAR_CARD_MODAL_PAGE_LEAP_APPS = '[Leap App] clear card model page leap apps';
export const CLEAR_PERSON_PAGE_LEAP_APPS = '[Leap App] clear person page leap apps';
export const CLEAR_VIEW_LEAP_APPS = '[Leap App] clear view leap apps';

export class GetGlobalLeapApps implements Action {
  readonly type = GET_GLOBAL_LEAP_APPS;

  constructor(public payload: any) {}
}

export class GetGlobalLeapAppsSuccess implements Action {
  readonly type = GET_GLOBAL_LEAP_APPS_SUCCESS;

  constructor(public payload: { apps: GlobalLeapApp[] }) {}
}

export class GetGlobalLeapAppsFail implements Action {
  readonly type = GET_GLOBAL_LEAP_APPS_FAIL;

  constructor(public payload: any) {}
}

export class ClearGlobalLeapApps implements Action {
  readonly type = CLEAR_GLOBAL_LEAP_APPS;

  constructor(public payload: any) {}
}

export class ClearViewLeapApps implements Action {
  readonly type = CLEAR_VIEW_LEAP_APPS;

  constructor(public payload: any) {}
}

export class GetViewLeapApps implements Action {
  readonly type = GET_VIEW_LEAP_APPS;

  constructor(public payload: { viewId: ViewLeapAppLocationId }) {}
}

export class GetViewLeapAppsSuccess implements Action {
  readonly type = GET_VIEW_LEAP_APPS_SUCCESS;

  constructor(public payload: { apps: ViewLeapApp[] }) {}
}

export class GetViewLeapAppsFail implements Action {
  readonly type = GET_VIEW_LEAP_APPS_FAIL;

  constructor(public payload: any) {}
}

export class GetCardPageLeapApps implements Action {
  readonly type = GET_CARD_PAGE_LEAP_APPS;

  constructor(public payload: { viewId: ViewLeapAppLocationId }) {}
}

export class GetCardPageLeapAppsSuccess implements Action {
  readonly type = GET_CARD_PAGE_LEAP_APPS_SUCCESS;

  constructor(public payload: { apps: ViewLeapApp[] }) {}
}

export class GetCardPageLeapAppsFail implements Action {
  readonly type = GET_CARD_PAGE_LEAP_APPS_FAIL;

  constructor(public payload: any) {}
}

export class ClearCardPageLeapApps implements Action {
  readonly type = CLEAR_CARD_PAGE_LEAP_APPS;

  constructor(public payload: any) {}
}

export class GetCardModalPageLeapApps implements Action {
  readonly type = GET_CARD_MODAL_PAGE_LEAP_APPS;

  constructor(public payload: { viewId: ViewLeapAppLocationId }) {}
}

export class GetCardModalPageLeapAppsSuccess implements Action {
  readonly type = GET_CARD_MODAL_PAGE_LEAP_APPS_SUCCESS;

  constructor(public payload: { apps: ViewLeapApp[] }) {}
}

export class GetCardModalPageLeapAppsFail implements Action {
  readonly type = GET_CARD_MODAL_PAGE_LEAP_APPS_FAIL;

  constructor(public payload: any) {}
}

export class ClearCardModalPageLeapApps implements Action {
  readonly type = CLEAR_CARD_MODAL_PAGE_LEAP_APPS;

  constructor(public payload: any) {}
}

export class GetPersonPageLeapApps implements Action {
  readonly type = GET_PERSON_PAGE_LEAP_APPS;

  constructor(public payload: { viewId: ViewLeapAppLocationId }) {}
}

export class GetPersonPageLeapAppsSuccess implements Action {
  readonly type = GET_PERSON_PAGE_LEAP_APPS_SUCCESS;

  constructor(public payload: { apps: ViewLeapApp[] }) {}
}

export class GetPersonPageLeapAppsFail implements Action {
  readonly type = GET_PERSON_PAGE_LEAP_APPS_FAIL;

  constructor(public payload: any) {}
}

export class ClearPersonPageLeapApps implements Action {
  readonly type = CLEAR_PERSON_PAGE_LEAP_APPS;

  constructor(public payload: any) {}
}

export type LeapAppActions =
  | GetGlobalLeapApps
  | GetGlobalLeapAppsSuccess
  | GetGlobalLeapAppsFail
  | ClearGlobalLeapApps
  | GetViewLeapApps
  | GetViewLeapAppsSuccess
  | GetViewLeapAppsFail
  | GetCardPageLeapApps
  | GetCardPageLeapAppsSuccess
  | GetCardPageLeapAppsFail
  | GetCardModalPageLeapApps
  | GetCardModalPageLeapAppsSuccess
  | GetCardModalPageLeapAppsFail
  | GetPersonPageLeapApps
  | GetPersonPageLeapAppsSuccess
  | GetPersonPageLeapAppsFail
  | ClearCardPageLeapApps
  | ClearCardModalPageLeapApps
  | ClearPersonPageLeapApps
  | ClearViewLeapApps;
