import { ISchemaNestable, ISchemaApiRequestOptions } from '../models';

const DefaultSchemaRequestOptions: ISchemaApiRequestOptions = {
  page: 1,
  limit: 1000,
  includeAncestors: false,
};

export const listToTree = <T extends ISchemaNestable>(list: Array<T>, level: string = '/'): Array<T> => list
.filter((e) => e.path === level)
.map((e) => {
  const children = listToTree(list, `${level}${e.id}/`);
  return { ...e, children };
});

export const toStandardSchemaUrl = (
  url: string,
  options: ISchemaApiRequestOptions = {},
  skipAncestors: boolean = false
): string => {
  const { page, limit, includeAncestors } = {
    ...DefaultSchemaRequestOptions,
    ...options,
  } as ISchemaApiRequestOptions;
  const ancestorsQuery = skipAncestors ? '' : `&includeAncestors=${includeAncestors}`;
  return `${url}&page=${page}&limit=${limit}${ancestorsQuery}`;
};
