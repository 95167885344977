import { Injectable } from '@angular/core';
import { DexieService } from '@app/core/services/dexie/dexie.service';
import { PlatformService } from '@app/core/services/platform/platform.service';
import { LogService } from '@app/core/services/log/log.service';
import { INamedList, INamedLists } from '@app/features/+card/models';
import { from, Observable } from 'rxjs';
import { BaseStorageService } from '@app/shared/services/base/base-storage.service';

@Injectable()
export class NamedListsStorageService extends BaseStorageService<INamedList> {
  private metaKey = '_NAMED_LIST_META_';

  constructor(private dexieService: DexieService, private log: LogService, private _platformSvc: PlatformService) {
    super();
    log.init('named-lists-storage-service');
    if (this._platformSvc.isBrowser) {
      this.table = this.dexieService.table('namedlist');
    }
  }

  public getAll(): Observable<INamedList[]> {
    if (this._platformSvc.isBrowser) {
      return from(this.table.toArray());
    }
  }

  public getList(id: string): Observable<INamedList> {
    if (this._platformSvc.isBrowser) {
      return from(this.table.where('baseId').equals(id).or('id').equals(id).first());
    }
  }

  public upsertAll(namedListResponse: INamedLists) {
    if (this._platformSvc.isBrowser && !!namedListResponse && !!namedListResponse.lists) {
      const metaInfo = {
        id: this.metaKey,
        lastRowVer: 0,
        total: namedListResponse.lists.length,
      };
      this.log.info(metaInfo);
      return this.table.bulkPut(namedListResponse.lists);
    }
  }

  public handleDatabaseError(error: any): any {
    return;
  }

  public getDropdownOptions(namedLists: INamedList[], names: string[]): Array<string> {
    const namedList = this.getNamedList(namedLists, names);
    if (!namedList || !namedList.items) {
      return [];
    }

    return [...namedList.items.map((item) => item.text)];
  }

  public getNamedList(namedLists: INamedList[], names: string[]): INamedList | null {
    if (!namedLists || namedLists.length === 0) {
      return null;
    }

    const namedListsWithNames =
      namedLists
        .filter((l) => names.indexOf(l.name) > -1)
        .sort((a, b) => {
          if (!a.baseId) {
            // a is null? last
            return 1;
          } else if (!b.baseId) {
            // b is null? last
            return -1;
          } else {
            return a.baseId.localeCompare(b.baseId);
          }
        }) || null;

    return !!namedListsWithNames ? namedListsWithNames[0] : null;
  }
}
