import { environment } from '@env/environment';
import { AuthAgent } from '@leapdev/auth-agent';

export const initAuthAgent = () => AuthAgent.init({
  authHost: environment.config.oauth.endpoint,
  clientId: environment.config.oauth.client_id,
  scopes: ['*', 'offline_access'],
  autoLogin: true,
  autoLogout: true,
  uniqueSession: environment.config.oauth.uniqueSessionActive,
  autoRefreshToken: true,
  userPresence: true,
  listenToRestrictWebFlag: true,
});
