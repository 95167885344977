import { Injectable, OnDestroy } from '@angular/core';
import { LogService, PlatformService } from '@app/core/services';
import { HttpClient } from '@angular/common/http';
import { getSemaphoreName, SemaphoreResponse, SemaphoreType } from '../../models';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { firstValueFrom, forkJoin, Observable, of, Subject, Subscription, throwError, timer } from 'rxjs';
import { BaseService } from '../../../../shared/services/base/base.service';
import { AuthService } from '@app/core/services/auth/auth.service';
import { isEmptyValue } from '@server/modules/shared/functions/common-util.functions';

const SEMAPHORE_UPDATE_INTERVAL = 3 * 60 * 1000; //* 3 minutes

@Injectable()
export class SemaphoreService extends BaseService implements OnDestroy {
  private _semaphoreId: string;
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _semaphoreUpdateIntervalSub: Subscription;

  private url(str?: string): string {
    const suffix = isEmptyValue(str) ? '' : `/${str}`;
    return `${this.accountingPath}/api/cloud/semaphore${suffix}`;
  }

  constructor(
    private _authSvc: AuthService,
    private _http: HttpClient,
    private _log: LogService,
    private _ps: PlatformService
  ) {
    super();
    this._log.init('semaphore-service');
  }

  create(type: SemaphoreType, param?: string, payload?: any) {
    const url = this.url();
    const semaphoreName = getSemaphoreName(type, param);
    let realPayload = { SemaphoreName: semaphoreName };
    if (!!payload) {
      realPayload = {
        ...realPayload,
        ...payload,
      };
    }

    return this._http.post<SemaphoreResponse>(url, realPayload).pipe(
      switchMap((sem) => forkJoin([of(sem), !!this._semaphoreId ? this.clear(this._semaphoreId) : of(null)])),
      map((data) => {
        const sem = data[0];
        if (sem.Code === 0) {
          this._semaphoreId = sem.SemaphoreId;
          this.updateSemaphoreIntervalProcess();
          return this._semaphoreId;
        }

        return undefined;
      }),
      catchError((data: any) => {
        const { httpError } = data;
        if (!!httpError && !!httpError.error && httpError.error.Code === -2) {
          return throwError(() => httpError.error.ErrorMessage);
        } else {
          return throwError(() => 'Unable to get semaphore id.');
        }
      })
    );
  }

  async clear(semaphoreId?: string) {
    semaphoreId = semaphoreId || this._semaphoreId;
    this._log.info(`clearing semaphore: ${semaphoreId}`);

    const url: string = this.url('clear');
    await firstValueFrom(this._http
      .post<any>(url, { SemaphoreId: semaphoreId }));
    this._semaphoreId = '';
  }

  updateSemaphoreIntervalProcess() {
    if (!this._semaphoreId) {
      return;
    }
    this.clearSemaphoreUpdateInterval();
    this._log.info('setupUpdate');
    const url: string = this.url('update');
    this._semaphoreUpdateIntervalSub = timer(SEMAPHORE_UPDATE_INTERVAL, SEMAPHORE_UPDATE_INTERVAL)
      .pipe(
        tap(() => {
          if (!this._semaphoreId) {
            this._destroy$.next(true);
          }
        }),
        takeUntil(this._destroy$),
        switchMap(() => this._http.post<any>(url, { SemaphoreId: this._semaphoreId }))
      )
      .subscribe(() => {
        this._log.info(`SemaphoreId ${this._semaphoreId} updated`);
      });
  }

  getId(): string {
    this._log.info('SemaphoreId: ', this._semaphoreId);
    return this._semaphoreId;
  }

  async ngOnDestroy(): Promise<void> {
    this._log.info('Semaphore service destroyed');
    if (!isEmptyValue(this._semaphoreId)) {
      this._destroy$.next(true);
      await this.clear(this._semaphoreId);
    }
    this.clearSemaphoreUpdateInterval();
  }

  async destroy(): Promise<void> {
    this._destroy$.next(true);
    this.clearSemaphoreUpdateInterval();
    if (!!this._semaphoreId && this._ps.isBrowser) {
      this.clear(this._semaphoreId);
    }
  }

  clearSemaphoreUpdateInterval(): void {
    if (!!this._semaphoreUpdateIntervalSub) {
      this._semaphoreUpdateIntervalSub.unsubscribe();
      this._semaphoreUpdateIntervalSub = null;
    }
  }

  testSemaphore(data: { semaphoreName: string; invoiceTransactionMode?: number }): Observable<any> {
    const { semaphoreName, invoiceTransactionMode } = data;
    let url: string = this.url('test') + `?semaphoreName=${semaphoreName}`;
    if (invoiceTransactionMode) {
      url += `invoiceTransactionMode=${invoiceTransactionMode}`;
    }
    return this._http.get<any>(url).pipe(
      catchError((errorPayload: any) => {
        const { httpError } = errorPayload;
        if (!!httpError && !!httpError.error && httpError.error.Code === -2) {
          return throwError(() => httpError.error.ErrorMessage);
        } else {
          return throwError(() => 'Unable to get semaphore id.');
        }
      })
    );
  }
}
