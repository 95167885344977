import { Action } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

export const GO = '[Router] Go';
export const GO_BY_URL = '[Router] Go by url';
export const CLEAR = '[Router] Clear Outlet';
export const CLEAR_CURRENT_MODAL = '[Router] Clear Current Modal';
export const BACK = '[Router] Back';
export const FORWARD = '[Router] Forward';

export class Go implements Action {
  readonly type = GO;

  constructor(
    public payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    }
  ) {}
}

export class GoByUrl implements Action {
  readonly type = GO_BY_URL;

  constructor(
    public payload: {
      path: string;
    }
  ) {}
}

export class Forward implements Action {
  readonly type = FORWARD;
}

export class Clear implements Action {
  readonly type = CLEAR;

  constructor(public payload?: { outlets?: string[]; excludeQueryParam?: boolean; skipLocationChange?: boolean }) {}
  // use outlets to specify which outlets we want to be clear.
  // Example: if outlets: ['popup', 'selector'], we will clear outlet 'popup' and 'selector'
}

export class ClearCurrentModal implements Action {
  readonly type = CLEAR_CURRENT_MODAL;

  constructor(public payload?: { excludeQueryParam?: boolean; skipLocationChange?: boolean }) {}
}

export class Back implements Action {
  readonly type = BACK;
}

export type Actions = Go | Back | Forward | Clear | ClearCurrentModal | GoByUrl;
