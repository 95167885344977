import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBulkPaymentAddressParams, IBulkPaymentAddressResult } from '@app/features/+card/models';
import { BaseService } from '@app/shared/services/base/base.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PaymentAddressService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getPaymentToInfo(cardId: string) {
    const leapcalcPath = `${this.siriusPath}/api/card/${cardId}/paymenttoinfo`;
    return this.http.get<{ name: string; address: string }>(leapcalcPath);
  }

  getpaymentAddress(cardId: string) {
    const leapcalcPath = `${this.siriusPath}/api/card/${cardId}/paymentaddress`;
    return this.http.get(leapcalcPath, { responseType: 'text' }).pipe(catchError(() => of('')));
  }

  getBulkpaymentAddress(paymentAddresses: IBulkPaymentAddressParams[]): Observable<IBulkPaymentAddressResult[]> {
    const leapcalcPath = `${this.siriusPath}/api/card/bulkpaymentaddresses`;
    return this.http.post<IBulkPaymentAddressResult[]>(leapcalcPath, paymentAddresses);
  }

  getBankDetails(cardId: string) {
    const leapcalcPath = `${this.siriusPath}/api/card/${cardId}/details`;
    return this.http.get(leapcalcPath);
  }
}
