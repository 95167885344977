<div id="x-editor-toolbar" class="ql-toolbar">
  <div *ngIf="mode !== 4">
    <span class="ql-formats">
      <button tooltip="Undo" placement="top" container="body" class="ql-undo"></button>
      <button tooltip="Redo" placement="top" container="body" class="ql-redo"></button>
      <button tooltip="Timestamp" placement="top" container="body" class="ql-timestamp" *ngIf="mode === 1"></button>
    </span>
    <span class="ql-formats">
      <button tooltip="Bold" placement="top" container="body" class="ql-bold"></button>
      <button tooltip="Italic" placement="top" container="body" class="ql-italic"></button>
      <button tooltip="Underline" placement="top" container="body" class="ql-underline"></button>
      <button tooltip="Strikethrough" placement="top" container="body" class="ql-strike"></button>
      <span class="d-inline-flex" tooltip="Font Color" placement="top" container="body">
        <select class="ql-color"></select>
      </span>
      <span class="d-inline-flex" tooltip="Background Color" placement="top" container="body">
        <select class="ql-background"></select>
      </span>
      <button tooltip="Remove Text Style" placement="top" container="body" class="ql-clean"></button>
    </span>
    <span class="ql-formats" tooltip="Font" placement="top" container="body">
      <select class="ql-font">
        <option *ngFor="let font of fonts" [value]="font.id" [attr.selected]="isSelectedFont(font.name)">{{
          font.name
        }}</option>
      </select>
    </span>
    <span class="ql-formats">
      <ng-container *ngIf="mode !== 3">
        <button tooltip="Numbering" placement="top" container="body" class="ql-list" value="ordered"></button>
        <button tooltip="Bullets" placement="top" container="body" class="ql-list" value="bullet"></button>
      </ng-container>
      <span class="d-inline-flex" tooltip="Alignment" placement="top" container="body">
        <select class="ql-align"></select>
      </span>
    </span>
    <span class="ql-formats">
      <button tooltip="Insert Link" placement="top" container="body" class="ql-link"></button>
      <button tooltip="Upload Image" placement="top" container="body" class="ql-image"></button>
      <!--<button tooltip="test" placement="top" container="body" class="ql-print"></button>-->
    </span>
  </div>
</div>
