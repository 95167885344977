import { catchError, exhaustMap, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import {
  selectSelectedMatterType,
  selectSelectedMatterTypeId,
  selectSelectedState,
} from '@app/features/+matter-types/store';
import { AllMatterTypeId, IMatterTypePrecedents, IPrecedent } from '../../models';
import { PrecedentService } from '../../services';
import * as actions from '../actions';
import { State } from '../reducers';
import { ISchemaApiResponse } from '@app/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { selectPrecedentSearchText } from '../selectors';
import { isEmptyValue } from '@server/modules/shared/functions/common-util.functions';

@Injectable()
export class PrecedentSearchEffects {
  searchPrecedentByMatterType$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.SearchPrecedentByMatterType>(actions.SEARCH_PRECEDENT_BY_MATTER_TYPE),
      switchMap(({ payload }) => {
        const { matterTypeId, searchText, state } = payload;
        if (searchText) {
          return combineLatest([
            this._store.pipe(select(selectSelectedMatterType)),
            of(matterTypeId),
            of(searchText),
            of(state),
          ]);
        }

        return combineLatest([of(undefined), of(matterTypeId), of(searchText), of(state)]);
      }),
      switchMap(([selectedMatterTypeDetail, matterTypeId, searchText, state]) => {
        const parentId = selectedMatterTypeDetail
          ? selectedMatterTypeDetail.parentId || selectedMatterTypeDetail.id
          : matterTypeId;

        return this._precedentSvc
          .searchPrecedentsByMatterTypeId(searchText ? parentId : matterTypeId, searchText, state)
          .pipe(
            mergeMap((resp: ISchemaApiResponse<IPrecedent[]>) => {
              const matterTypePrecedents: IMatterTypePrecedents = {
                matterTypeId,
                precedents: resp.data,
              };
              return [new actions.SearchPrecedentSuccessV2({ data: matterTypePrecedents })];
            }),
            catchError((error) => of(new actions.SearchPrecedentFailV2({ error, searchText })))
          );
      })
    )
  );

  searchPrecedentByMatterTypeOnCustomPrecedentUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.SearchPrecedentByMatterTypeOnCustomPrecentUpdated>(
        actions.SEARCH_PRECEDENT_BY_MATTER_TYPE_ON_CUSTOM_PRECEDENT_UPDATED
      ),
      withLatestFrom(
        this._store.pipe(select(selectSelectedMatterTypeId)),
        this._store.pipe(select(selectSelectedState)),
        this._store.pipe(select(selectPrecedentSearchText))
      ),
      exhaustMap((data: any) => {
        const [action, matterTypeId, state, searchText] = data;
        if (isEmptyValue(matterTypeId) || isEmptyValue(state) || isEmptyValue(searchText)) {
          return [];
        }
        return [new actions.SearchPrecedentByMatterType({ matterTypeId, state, searchText })];
      })
    )
  );

  searchPrecedentFailV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.SearchPrecedentFailV2>(actions.SEARCH_PRECEDENT_FAIL_V2),
      switchMap(({ payload }) => {
        const message =
          (payload?.searchText &&
            `Unable to perform the search with "${payload?.searchText}".\nPlease try again later.`) ||
          'Unable to perform the search.\nPlease try again later.';

        this._toasterSvc.show(message, this._translateSvc.instant('Precedent.List.Heading'), {}, 'error');

        return [];
      })
    )
  );

  searchPrecedentByAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.SearchPrecedentByAll>(actions.SEARCH_PRECEDENT_BY_ALL),
      switchMap(({ payload }) => {
        const { searchText, state } = payload;
        return this._precedentSvc.searchPrecedentsByAll(searchText, state).pipe(
          mergeMap((resp: ISchemaApiResponse<IPrecedent[]>) => {
            const matterTypePrecedents: IMatterTypePrecedents = {
              matterTypeId: AllMatterTypeId,
              precedents: resp.data,
            };
            return [
              new actions.SearchPrecedentByAllSuccess({
                data: matterTypePrecedents,
                totalPages: resp.totalPages,
                page: resp.page,
              }),
            ];
          })
        );
      }),
      catchError((error) => of(new actions.SearchPrecedentFailV2(error)))
    )
  );

  constructor(
    private actions$: Actions,
    private _store: Store<State>,
    private _precedentSvc: PrecedentService,
    private _toasterSvc: ToastrService,
    private _translateSvc: TranslateService
  ) { }
}
