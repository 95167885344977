import { Injectable, Injector, NgZone } from '@angular/core';
import { Auth0Service } from './auth0.service';
import { ExternalUserType } from '@app/shared/models';
import { BehaviorSubject } from 'rxjs';
import { AuthAgentWrapper } from './auth-agent-wrapper'; 
import { IAuthProvider } from '@app/core/models/auth.model';
import { FeatureFlagService } from '../feature-flag/feature-flag.service';
import { PlatformService } from '../platform/platform.service';
import { LogService } from '../log/log.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements IAuthProvider {
  public initializationPromise: Promise<void>; 

  private activeProvider: IAuthProvider | null = null;
  private _token: string | null = null;
  isAuthenticated$ = new BehaviorSubject<boolean>(false);

  constructor(
    private _auth0Service: Auth0Service, 
    private _authAgentWrapper: AuthAgentWrapper,
    private _platformSvc: PlatformService,
    private _featureFlagSvc: FeatureFlagService,
    private _log: LogService
  ) {
    this.initializationPromise = this.initialize();
  }

  public async initialize(): Promise<void> {
    if (this._featureFlagSvc.useAuth0) {
      this._log.info('AuthService: Using Auth0 as the authentication provider.');
      this.activeProvider = this._auth0Service;
    } else {
      this._log.info('AuthService: Using AuthAgent as the authentication provider.');
      this.activeProvider = this._authAgentWrapper;
    }

    if (this.activeProvider.initialize) {
      await this.activeProvider.initialize();
    }

    try {
      const userDetails = await this.activeProvider.userDetails();
      const isAuthenticated = userDetails !== null;
      this.isAuthenticated$.next(isAuthenticated);
      this._token = await this.activeProvider.getToken();
    } catch (error) {
      this._log.error('Error fetching user details:', error);
      this.isAuthenticated$.next(false);
    }
  }

  private async ensureInitialized(): Promise<void> {
    if (!this.activeProvider) {
      await this.initializationPromise;
    }
  }

  async userDetails(): Promise<any> {
    await this.ensureInitialized();
    return await this.activeProvider!.userDetails();
  }

  async statusAdminConsent(): Promise<boolean> {
    await this.ensureInitialized();
    return await this.activeProvider!.statusAdminConsent();
  }

  requestAdminConsent(redirectUrl: string, openInNewWindow: boolean, cb: any): void {
    this.activeProvider?.requestAdminConsent(redirectUrl, openInNewWindow, cb);
  }

  async forceRefreshAccessToken(): Promise<string> {
    await this.ensureInitialized();
    return await this.activeProvider!.forceRefreshAccessToken();
  }

  get decodedToken() {
    return this.activeProvider?.decodedToken;
  }

  get token(): string | null {
    return this._token;
  }

  get staffId(): string {
    return this.decodedToken?.staffId || '';
  }

  get userId(): string | null {
    return this.decodedToken?.userId || null;
  }

  async getToken(): Promise<string> { 
    await this.ensureInitialized();
    return await this.activeProvider!.getToken();
  }

  async getUserId(): Promise<string> {
    await this.ensureInitialized();
    return await this.activeProvider!.getUserId();
  }

  async getRefreshedToken(): Promise<string> {
    await this.ensureInitialized();
    return await this.activeProvider!.getRefreshedToken();
  }

  async linkUser(url?: string, newWindow?: boolean, callback?: any): Promise<void> {
    await this.ensureInitialized();
    await this.activeProvider!.linkUser(url, newWindow, callback);
  }

  async unlinkUser(url?: string, newWindow?: boolean, callback?: any): Promise<void> {
    await this.ensureInitialized();
    await this.activeProvider!.unlinkUser(url, newWindow, callback);
  }

  async reauthenticateCloudProvider(
    nonce?: string,
    redirect?: string,
    newWindow: boolean = false,
    callback?: any,
    isSuperdiaryFirm: boolean = false
  ): Promise<void> {
    await this.ensureInitialized();
    await this.activeProvider!.reauthenticateCloudProvider(nonce, redirect, newWindow, callback, isSuperdiaryFirm);
  }

  registerEventListener(topic: string, type: string, callback: any): void {
    this.activeProvider?.registerEventListener(topic, type, callback);
  }

  async authoriseSupport(code: string, duration: string): Promise<string> {
    await this.ensureInitialized();
    return await this.activeProvider!.authoriseSupport(code, duration);
  }

  async logout(): Promise<void> {
    if (this._platformSvc.isBrowser) {
      await this.ensureInitialized();
      await this.activeProvider!.logout();
      this.isAuthenticated$.next(false);
    }
  }

  getProviderText(userType: ExternalUserType): string {
    return this.activeProvider?.getProviderText(userType) || 'Cloud Provider';
  }

  redirectToOptionEmailAccountPage(isSuperdiaryFirm: boolean): void {
    this.activeProvider?.redirectToOptionEmailAccountPage(isSuperdiaryFirm);
  }

  openAzureTenantEmailSetting(): void {
    if (this._platformSvc.isBrowser) {
      this.activeProvider?.openAzureTenantEmailSetting();
    }
  }

  async changePassword(params: { redirectUrl?: string; newWindow: boolean; callback?: any }): Promise<void> {
    if (this._platformSvc.isBrowser) {
      await this.ensureInitialized();
      await this.activeProvider!.changePassword(params);
    }
  }

  async loginWithRedirect(options?: any): Promise<void> {
    await this.ensureInitialized();
    await this.activeProvider!.loginWithRedirect(options);
  }
}
