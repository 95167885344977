import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppApiService } from '@app/core/api';
import {
  ECardFilterType,
  ECardListModelEvent,
  ICardListEntry,
  ICardListModelSelection,
} from '@app/features/+card/models';
import { CardFiltersService } from '@app/features/+card/services/card-filters.service';
import * as cardListModalActions from '@app/features/+card/store/actions/card-list-modal';
import { PaymentAddressService } from '@app/features/accounting/services/payment-address/payment-address.service';
import { Store } from '@ngrx/store';

import { Observable, of, Subscription, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { CardListStorageService } from '@app/features/+card/services';
import { EventBusService } from '@app/core/services';
import { ESiriusEvents } from '@app/core/models';
import { toSingleLine } from '@app/shared/utils/string.helper';

@Component({
  selector: 'sc-pay-to',
  templateUrl: './pay-to.component.html',
})
export class PayToComponent implements OnInit, OnDestroy {
  @Input()
  defaultFilter = 'Supplier';
  @Input()
  selectDisabled: boolean;
  @Input()
  buttonLabel: string;
  @Input()
  validationErrors: any;
  @Input()
  readOnly: boolean;
  @Input()
  label: string;
  @Input() nameSelectBtnDisabled: boolean; // a flag to disable appended btn in name input
  @Input() nameValueChangeDisabled: boolean; // a flag to disable name value change when address card changes

  @Output()
  onCardSelected = new EventEmitter<ICardListEntry>();

  cardEntries$: Observable<Partial<ICardListEntry>[]>;
  cardListModalSub: Subscription;
  cardSelectionSub: Subscription;
  payToForm: FormGroup;
  filterType = 'Supplier';

  private selectedCard: ICardListEntry;
  private parentRoute: string[];
  private forceNameUpdate = false;
  private forceAddressUpdate = false;
  private unsub = new Subject<void>();

  constructor(
    private PayToFormContainer: ControlContainer,
    private appApiSvc: AppApiService,
    private paymentAddressService: PaymentAddressService,
    private cardFiltersSvc: CardFiltersService,
    private cardListStorageSvc: CardListStorageService,
    private _eventBusSvc: EventBusService,
    private store: Store<any>,
    activatedRoute: ActivatedRoute
  ) {
    this.parentRoute = !!activatedRoute.snapshot.data.breadcrumb
      ? activatedRoute.snapshot.data.breadcrumb.split('/')
      : [];
  }

  ngOnInit(): void {
    this.payToForm = this.PayToFormContainer.control as FormGroup;
    this.subscribeToFormChanges();
    this.setupCardEntries();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  openCardSelector(caller: string): void {
    this.forceNameUpdate = caller === 'name';
    this.forceAddressUpdate = caller === 'address';

    this.store.dispatch(new cardListModalActions.SetCardFilterType({ type: ECardFilterType.Standard }));
    this.store.dispatch(new cardListModalActions.SetSelectedCardFilterType({ type: this.defaultFilter }));

    if (!!this.selectedCard) {
      this.store.dispatch(new cardListModalActions.SetSelectedCardEntries({ cardEntries: [this.selectedCard] }));
    }

    this.appApiSvc.navigate({
      path: [{ outlets: { popup: this.parentRoute, selector: ['card', 'card-list'] } }],
      extras: { skipLocationChange: true, queryParamsHandling: 'preserve' },
    });

    this.subscribeToClose();
  }

  cardLabel(cardEntry: ICardListEntry): string {
    return cardEntry.fullName;
  }

  selectCard(cardEntry) {
    this.forceNameUpdate = true;
    this.cardSelectionSub = this.subscribeToCardUpdate(of(cardEntry.item));
  }

  private setupCardEntries() {
    this.cardEntries$ = this.cardListStorageSvc.getAll().pipe(
      map((cards) => this.cardFiltersSvc.applyFilterConditions(cards, null, true, false, 'All')),
      map((cards) => cards
      .map((card) => ({
        cardId: card.cardId,
        fullName: card.fullName,
        shortName: card.shortName,
        isSupplier: card.isSupplier,
      }))
      .sort((a, b) => (a.fullName > b.fullName ? 1 : a.fullName < b.fullName ? -1 : 0)))
    );
  }

  private subscribeToFormChanges() {
    this.payToForm.valueChanges.pipe(takeUntil(this.unsub)).subscribe((value) => {
      const name = this.payToForm.get('name');
      const address = this.payToForm.get('address');
      if (!value.name && !name.pristine) {
        name.reset();
      }
      if (!value.address && !address.pristine) {
        address.reset();
      }
      this.selectedCard = null;
      this.filterSupplier(name.pristine);
    });
  }

  private subscribeToClose() {
    const closeObservable$ = this._eventBusSvc.listen(ESiriusEvents.GetSelectedCardEntries).pipe(
      take(1),
      filter((data: ICardListModelSelection) => data.action === ECardListModelEvent.Confirm),
      map((data: ICardListModelSelection) => (!!data.cardEntries ? data.cardEntries[0] : null)),
      filter((cardEntry) => !!cardEntry)
    );

    this.cardListModalSub = this.subscribeToCardUpdate(closeObservable$);
  }

  private getPaymentToInfo(cardEntry: Partial<ICardListEntry>) {
    return this.paymentAddressService
      .getPaymentToInfo(cardEntry.cardId)
      .pipe(map((payToInfo) => ({ cardEntry, name: payToInfo.name, address: payToInfo.address })));
  }

  private updatePayToValue(data: any) {
    const { cardEntry, name, address } = data; // new name value and new address value
    this.selectedCard = !!cardEntry ? cardEntry : null;
    const nameControl = this.payToForm.get('name'); // original form name value
    const addressControl = this.payToForm.get('address'); // original form address value
    const updateName =
      (!nameControl.value || nameControl.pristine || this.forceNameUpdate) && !this.nameValueChangeDisabled;
    const updateAddress = !addressControl.value || addressControl.pristine || this.forceAddressUpdate;
    const patch = {
      name: updateName ? toSingleLine(name) : nameControl.value,
      address: updateAddress ? address : addressControl.value,
    };

    this.forceNameUpdate = false;
    this.forceAddressUpdate = false;
    this.payToForm.patchValue(patch);
    this.filterType = '';
    this.onCardSelected.emit(cardEntry);
  }

  private subscribeToCardUpdate(selectedCard$: Observable<Partial<ICardListEntry>>) {
    return selectedCard$
      .pipe(
        switchMap((cardEntry) => (!!cardEntry ? this.getPaymentToInfo(cardEntry) : [])),
        filter((data) => !!data),
        take(1),
        takeUntil(this.unsub)
      )
      .subscribe((data) => this.updatePayToValue(data));
  }

  private filterSupplier(enable: boolean) {
    this.filterType = enable ? 'Supplier' : '';
  }
}
