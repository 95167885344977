<div class="x-card-value" [ngClass]="classes">
  <sc-icon [iconName]="iconName"></sc-icon>
  <div class="card-body">
    <h2>{{ value || '...' }}</h2>
    <ng-container *ngIf="!!cardRouterLink; else LabelBlock">
      <a
        class="card-text d-inline-block"
        [routerLink]="cardRouterLink"
        [queryParams]="cardRouterQueryParams"
        [tooltip]="routerLinkTooltip"
        [placement]="'bottom'"
        [container]="'body'"
      >
        {{ label }}
      </a>
    </ng-container>
    <ng-template #LabelBlock>
      <p *ngIf="label" class="card-text">{{ label }}</p>
    </ng-template>
  </div>
</div>
