import { Component, HostBinding } from '@angular/core';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { IDropdownOption } from '@app/shared/models';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-text-editor-cell',
  templateUrl: './dropdown-editor-cell.component.html',
})
export class DropdownEditorCellComponent implements ICellEditorAngularComp {
  private _params: ICellEditorParams;
  public options: IDropdownOption<unknown>[];
  public selectedInputValue = null;

  constructor() {}

  @HostBinding('class.w-100')
  agInit(params: ICellEditorParams): void {
    this._params = params;
    this.options = isFunction(params['getOptionList']) ? params['getOptionList'](params.node.data) : [];

    this.selectedInputValue = isFunction(params['getDefaultValue'])
      ? params['getDefaultValue'](params.node.data)
      : params.value;
  }

  getValue(): any {
    const selectedOption = this.options?.find((v) => v?.value === this.selectedInputValue);
    return selectedOption ? selectedOption.value : null;
  }
}
