import { Pipe, PipeTransform } from '@angular/core';
import { ICardListEntry } from '@app/features/+card/models';

@Pipe({ name: 'scCardFilter' })
export class CardFilterPipe implements PipeTransform {
  transform(cards: Partial<ICardListEntry>[], filterType: string): Array<any> {
    const type = !!filterType ? filterType.toLowerCase() : '';
    let filterFunction;
    switch (type) {
      case 'supplier':
        filterFunction = (item) => item.isSupplier;
        break;

      default:
        break;
    }
    return !!cards && !!filterFunction ? cards.filter(filterFunction) : cards;
  }
}
