import { Component } from '@angular/core';
import { IHeaderGroupAngularComp } from '@ag-grid-community/angular';
import { isFunction } from '@server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-translate-group-header',
  templateUrl: './translate-group-header.component.html',
})
export class TranslateGroupHeaderComponent implements IHeaderGroupAngularComp {
  headerName: string;

  headerClass = '';

  params: any;
  dropdownOptions: any[];
  selectedOption: any;
  iconClass: string;
  bindingProperty: string;
  bindingLabelProperty: string;

  constructor() {}

  agInit(params: any): void {
    this.params = params;
    this.dropdownOptions =
      isFunction(params?.dropdownOptions) ? params.dropdownOptions() : params.dropdownOptions;
    this.selectedOption = isFunction(params?.selectedOption) ? params.selectedOption() : params.selectedOption;
    this.iconClass = params?.iconClass;
    this.bindingProperty = params?.bindingProperty;
    this.bindingLabelProperty = params?.bindingLabelProperty;
  }

  selectedOptionChange(option: any): void {
    if (!!this.params && isFunction(this.params.selectedOptionChange)) {
      this.selectedOption = option;
      this.params.selectedOptionChange(option);
    }
  }
}
