import { EPersonListGroupInfo } from '../../models';
import * as personListActions from '../actions/person-list';

export interface State {
  groupInfo: EPersonListGroupInfo;
  lastRowVer: number;
  loading: boolean;
  personSearchText: string;
  selectedPersonId: string;
}

export const INITIAL_STATE: State = {
  groupInfo: EPersonListGroupInfo.UNGROUP,
  lastRowVer: 0,
  loading: false,
  personSearchText: null,
  selectedPersonId: null,
};

export const reducer = (
  state = INITIAL_STATE,
  action: personListActions.PersonListApiActions | personListActions.PersonListDbActions
): State => {
  switch (action.type) {
    case personListActions.PersonListApiActionTypes.LIST_PERSONS_START:
      return {
        ...state,
        loading: true,
      };

    case personListActions.PersonListDbActionTypes.PERSONS_SAVE_DB_SUCCESS:
    case personListActions.PersonListDbActionTypes.PERSONS_SAVE_DB_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case personListActions.PersonListApiActionTypes.LIST_PERSONS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case personListActions.PersonListDbActionTypes.LOAD_PERSON_LIST_META_START:
      return {
        ...state,
        loading: true,
      };

    case personListActions.PersonListDbActionTypes.LOAD_PERSON_LIST_META_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };

    case personListActions.PersonListDbActionTypes.PERSON_LIST_META_SAVE_DB_START:
      return {
        ...state,
        ...action.payload,
      };

    case personListActions.PersonListDbActionTypes.PERSON_LIST_META_SAVE_DB_SUCCESS:
    case personListActions.PersonListDbActionTypes.PERSON_LIST_META_SAVE_DB_FAILURE:
      return {
        ...state,
      };

    case personListActions.PersonListDbActionTypes.LEAP_APP_SELECT_PERSON_COMMAND_START:
      return {
        ...state,
        selectedPersonId: null
      };
    default:
      return state;
  }
};

export const selectLoading = (state: State) => state.loading;
export const selectLastRowVer = (state: State) => state.lastRowVer;
export const selectGroupInfo = (state: State) => state.groupInfo;
export const selectPersonSearchText = (state: State) => state.personSearchText;
export const selectSelectedPersonId = (state: State) => state.selectedPersonId;
