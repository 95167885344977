import { Component, HostBinding } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { isFunction } from '@server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-text-info-cell',
  templateUrl: './text-info-cell.component.html',
})
export class TextInfoCellComponent implements ICellRendererAngularComp {
  content: string;
  snipLength: number;
  styleClass: string;

  @HostBinding('class.ag-content-description')
  @HostBinding('class.text-truncate')
  @HostBinding('class.w-100')
  longTextAndTruncate = true;

  constructor() {}

  agInit(params: any): void {
    this.configTextInfoCell(params);
  }

  refresh(params: any): boolean {
    this.configTextInfoCell(params);
    return true;
  }

  private configTextInfoCell(params: any): void {
    if (!!params && !!params.data) {
      const rowObj = params.data;
      this.content = params.getContent(rowObj);
      this.styleClass = isFunction(params['getStyleClass']) ? params.getStyleClass(rowObj) : '';

      // If no snippet instructed, display content as is.
      this.snipLength = isFunction(params['getSnippet']) ? params.getSnippet(rowObj) : -1;
    }
  }
}
