import { from as observableFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import Dexie from 'dexie';

import { DexieService, LogService, PlatformService } from '@app/core/services';
import { IMatterSearchState, MatterSearchMetaInfo } from '../../models';
import { omitObjValue } from '@server/modules/shared/functions/common-util.functions';

@Injectable()
export class MatterSearchStorageService {
  tableKey = '__MATTER_SEARCH_META__';
  metaTable: Dexie.Table<MatterSearchMetaInfo, string>;

  constructor(private dexieService: DexieService, private log: LogService, private _platformSvc: PlatformService) {
    log.init('matter-search-storage-service');
    if (this._platformSvc.isBrowser) {
      this.metaTable = this.dexieService.table('mattersSearchMeta');
    }
  }

  upsertAll(matterSearchState: IMatterSearchState) {
    if (this._platformSvc.isBrowser) {
      const metaInfo: MatterSearchMetaInfo = {
        id: this.tableKey,
        ...matterSearchState,
      };
      return this.metaTable.put(metaInfo);
    }
  }

  getMeta(): Observable<IMatterSearchState> {
    if (this._platformSvc.isBrowser) {
      const project = (metaInfo: MatterSearchMetaInfo) => omitObjValue(metaInfo, ['id']);
      return observableFrom(this.metaTable.get(this.tableKey)).pipe(map(project));
    }
  }
}
