import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ICardListResponseSchema } from '@app/features/+card/models';
import { BaseService } from '@app/shared/services/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class CardListService extends BaseService {
  private readonly path: string;

  constructor(private http: HttpClient) {
    super();
    this.path = `${this.apiPath}/api/v1/cards?includes=addressList,roles&lastRowVer=`;
  }

  public getAll(lastRowVer = 0): Observable<ICardListResponseSchema> {
    const url = this.path + lastRowVer;
    return this.http.get<ICardListResponseSchema>(url).pipe(
      map((res: ICardListResponseSchema) => ({
          ...res,
          data: res.data || [],
        }))
    );
  }
}
