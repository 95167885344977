import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, Subject, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, timeout } from 'rxjs/operators';

import { LogService } from '@app/core/services';
import { EmailSignatureService } from '../../services';
import * as signatureActions from '../actions/signature.actions';

import { EmailSignature } from '../../models';
import { AppApiService } from '@app/core/api';
import * as RouterActions from '@app/core/store/actions/router.action';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from '@app/shared/services';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SignatureEffects {
  destroy$: Subject<boolean> = new Subject<boolean>();

  getSignature$ = createEffect(() => this.actions$.pipe(
    ofType(signatureActions.GET_SIGNATURE),
    switchMap(() => this._signatureSvc.getEmailSignature().pipe(
        // TODO: 2 sec timeout
        timeout(2000),
        mergeMap((signature: EmailSignature) => [new signatureActions.GetSignatureSuccess(signature)]),
        catchError((error) => this.handleReauthentication(error))
      )),
    catchError((error) => of(new signatureActions.GetSignatureFailure(error)))
  ));

  saveSignature$ = createEffect(() => this.actions$.pipe(
    ofType(signatureActions.SAVE_SIGNATURE),
    map((action: signatureActions.SaveSignature) => action.payload),
    switchMap((signature: EmailSignature) => this._signatureSvc.updateEmailSignature(signature).pipe(
      mergeMap(() => [
        new signatureActions.SaveSignatureSuccess(signature)
      ]),
      catchError((error) => {
        return of(new signatureActions.SaveSignatureFailure({error}));
      })
    )),
    catchError((error) => of(new signatureActions.SaveSignatureFailure({error})))
  ));

  saveSignatureFailure$ = createEffect(() => this.actions$.pipe(
    ofType<signatureActions.SaveSignatureFailure>(signatureActions.SAVE_SIGNATURE_FAILURE),
    tap((action) => {
      const error = action.payload.error;
      let title = this._translateSvc.instant('EmailSignature.Save.Error.General.Title');
      let message = this._translateSvc.instant('EmailSignature.Save.Error.General.Message');
      if (error?.status === 413){
        let title = this._translateSvc.instant('EmailSignature.Save.Error.TooLarge.Title');
        let message = this._translateSvc.instant('EmailSignature.Save.Error.TooLarge.Message');
      }
      this._dialogSvc.error({
        title,
        message
      });
    })
  ), {dispatch: false})

  saveSignatureSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(signatureActions.SAVE_SIGNATURE_SUCCESS),
    switchMap((action) => [new RouterActions.ClearCurrentModal({ excludeQueryParam: false, skipLocationChange: true })])
  ));

  private handleReauthentication(error) {
    if (error.status === 401) {
      this._appApiSvc.reauthenticateUser();
      return [];
    }
    return throwError(() => error);
  }

  constructor(
    private actions$: Actions,
    private _log: LogService,
    private _signatureSvc: EmailSignatureService,
    private _appApiSvc: AppApiService,
    private _dialogSvc: DialogService,
    private _translateSvc: TranslateService,
  ) {
    this._log.init('signature-effects');
  }
}
