import * as actions from '../actions/fee-modal';
import { ITimeFee } from '@app/features/+time-fee-ledger/models';

export interface State {
  newWin: boolean;
  setupData: ITimeFee;
  loading: boolean;
}

export const initialState: State = {
  newWin: false,
  setupData: null,
  loading: false,
};

export const reducer = (state = initialState, action: actions.FeeModalActions): State => {
  switch (action.type) {
    case actions.DEHYDRATE_FEE_FORM_SUCCESS: {
      return {
        ...state,
        newWin: true,
        setupData: action.payload,
      };
    }

    case actions.SET_FEE_MODAL_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export const selectFeeNewWin = (state: State) => state.newWin;
export const selectFeeSetupData = (state: State) => state.setupData;
