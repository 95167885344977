import { Action } from '@ngrx/store';
import { IPersonListEntry, IPersonListResponseSchema } from '@app/features/+person/models';

export enum PersonListApiActionTypes {
  LIST_PERSONS_START = '[Person List from api] start',
  LIST_PERSONS_SUCCESS = '[Person List from api] success',
  LIST_PERSONS_FAILURE = '[Person List from api] failure',

  SELECT_PERSON = '[Person List] select person'
}

export class ListPersonsStart implements Action {
  readonly type = PersonListApiActionTypes.LIST_PERSONS_START;

  constructor(public payload: number) {}
}

export class ListPersonsSuccess implements Action {
  readonly type = PersonListApiActionTypes.LIST_PERSONS_SUCCESS;

  constructor(public payload: IPersonListResponseSchema) {}
}

export class ListPersonsFailure implements Action {
  readonly type = PersonListApiActionTypes.LIST_PERSONS_FAILURE;

  constructor(public payload: any) {}
}

export class SelectPerson implements Action {
  readonly type = PersonListApiActionTypes.SELECT_PERSON;

  constructor(public payload: { person: IPersonListEntry }) {}
}

export type PersonListApiActions = ListPersonsStart | ListPersonsSuccess | ListPersonsFailure | SelectPerson;
