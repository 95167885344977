import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { exhaustMap } from 'rxjs/operators';

import { AppApiService } from '@app/core/api';
import * as actions from '../actions/support-request.action';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class SupportRequestEffects {

  SupportRequestInit$ = createEffect(() => this.actions$.pipe(
    ofType<actions.SupportRequestInit>(actions.SUPPORT_REQUEST_INIT),
    exhaustMap(() => {
      this._appSvc.supportRequest();
      return [];
    })
  ));


  SupportRequestSuccessNotification$ = createEffect(() => this.actions$.pipe(
    ofType<actions.SupportRequestSuccessNotification>(actions.SUPPORT_REQUEST_SUCCESS_NOTIFICATION),
    exhaustMap(() => {
      this.toastrSvc.show('Support agent now using account', 'Success', {}, 'success');
      return [];
    })
  ));

  constructor(private actions$: Actions, private _appSvc: AppApiService, private toastrSvc: ToastrService) {}
}
