import { IAuthProvider } from '@app/core/models/auth.model';
import { ExternalUserType } from '@app/shared/models';
import { environment } from '@env/environment';
import { Injectable, NgZone } from '@angular/core';
import { AuthAgent } from '@leapdev/auth-agent';

@Injectable({
  providedIn: 'root',
})
export class AuthAgentWrapper implements IAuthProvider {
  private _token: string | null = null; // Add this line

  constructor(private _ngZone: NgZone) {
  }

  async userDetails(): Promise<any> {
    return await AuthAgent.userInfo();
  }

  async statusAdminConsent(): Promise<boolean> {
    return await AuthAgent.statusAdminConsent();
  }

  requestAdminConsent(redirectUrl: string, openInNewWindow: boolean, cb: any): void {
    return AuthAgent.getAdminConsent(undefined, redirectUrl, openInNewWindow, cb);
  }

  async forceRefreshAccessToken(): Promise<string> {
    return await AuthAgent.getAccessToken();
  }

  public async initialize(): Promise<void> {
    try {
      this._token = await AuthAgent.getAccessToken();
    } catch (error) {
      console.error('Error initializing AuthAgent token:', error);
      throw error;
    }
  }

  get decodedToken() {
    return AuthAgent.getDecodedAccessToken();
  }

  get token(): string | null {
    return this._token;
  }

  async getToken(): Promise<string> {
    try {
      return await AuthAgent.getAccessToken();
    } catch (error) {
      console.error('Error getting AuthAgent token:', error);
      throw error;
    }
  }

  async getRefreshedToken(): Promise<string> {
    return await AuthAgent.getRefreshedAccessToken();
  }

  async linkUser(url?: string, newWindow?: boolean, callback?: any): Promise<void> {
    await AuthAgent.linkUser(url, newWindow, callback);
  }

  async unlinkUser(url?: string, newWindow?: boolean, callback?: any): Promise<void> {
    await AuthAgent.unlinkUser(url, newWindow, callback);
  }

  async reauthenticateCloudProvider(
    nonce?: string,
    redirect?: string,
    newWindow: boolean = false,
    callback?: any,
    isSuperdiaryFirm: boolean = false
  ): Promise<void> {
    if (!isSuperdiaryFirm) {
      return AuthAgent.cloudProviderReauthenticate(nonce, redirect, newWindow, callback);
    } else {
      this.openAzureTenantEmailSetting();
    }
  }

  registerEventListener(topic: string, type: string, callback: any): void {
    AuthAgent.registerEventListener(topic, type, callback);
  }

  async authoriseSupport(code: string, duration: string): Promise<string> {
    return await AuthAgent.authoriseSupport(code, Number(duration));
  }

  async logout(): Promise<void> {
    await AuthAgent.logout();
  }

  getProviderText(userType: ExternalUserType): string {
    let text = 'Cloud Provider';
    if (!userType) {
      return text;
    }
    switch (userType) {
      case ExternalUserType.Azuer:
        text = 'Office 365';
        break;
      case ExternalUserType.Google:
        text = 'Google';
        break;
      case ExternalUserType.Exchange:
        text = 'Exchange';
        break;
    }
    return text;
  }

  redirectToOptionEmailAccountPage(isSuperdiaryFirm: boolean): void {
    if (!isSuperdiaryFirm) {
      this.linkUser(null, true);
      return;
    }
    this.openAzureTenantEmailSetting();
  }

  openAzureTenantEmailSetting(): void {
    const optionEndpoint = environment.config.endpoint.options_app;
    const integrationsUrl = optionEndpoint && `${optionEndpoint}/integrations/email-accounts`;
    if (integrationsUrl) {
      this._ngZone.runOutsideAngular(() =>
        AuthAgent.passthrough(integrationsUrl, true, environment.config.endpoint.auth)
      );
    }
  }

  async changePassword(params: { redirectUrl?: string; newWindow: boolean; callback?: any }): Promise<void> {
    const { redirectUrl, newWindow, callback } = params;
    AuthAgent.changePassword(redirectUrl, newWindow, callback);
  }

  async loginWithRedirect(options?: any): Promise<void> {
  }

  async getUserId(): Promise<string | null> {
    const user = await this.userDetails();
    return user?.id || null;
  }
}
