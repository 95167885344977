import { Action } from '@ngrx/store';
import { IBatchSearchInit } from '../../models/batch-search-api.model';

export const BATCH_SEARCH_INIT = '[Batch Search] init';
export const BATCH_SEARCH_FILTER_INIT = '[Batch Search] filter init';
export const BATCH_SEARCH_INIT_SUCCESS = '[Batch Search] init success';
export const BATCH_SEARCH_INIT_FAILURE = '[Batch Search] init failure';

export class BatchSearchInit implements Action {
  readonly type = BATCH_SEARCH_INIT;
}

export class BatchSearchFilterInit implements Action {
  readonly type = BATCH_SEARCH_FILTER_INIT;
}

export class InitBatchSearchSuccess implements Action {
  readonly type = BATCH_SEARCH_INIT_SUCCESS;
  constructor(public payload: { data: IBatchSearchInit; typesLoaded: boolean }) {}
}

export class InitBatchSearchFailure implements Action {
  readonly type = BATCH_SEARCH_INIT_FAILURE;
  constructor(public payload: any) {}
}

export type BatchSearchInitActions =
  | BatchSearchInit
  | BatchSearchFilterInit
  | InitBatchSearchSuccess
  | InitBatchSearchFailure;
