import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { AppApiService } from '@app/core/api';
import * as sidebarActions from '@app/core/store/actions/sidebar.action';
import { OfflineLauncherService, DocumentAutomationService } from '@app/shared/services';
import { selectSelectedFolderId } from '@app/features/+correspondence/store/selectors/folder.selectors';

import { State } from '../reducers';
import * as documentActions from '../actions';
import { AutomationActionType, IAutomationOptions } from '@app/shared/models';
import { selectContentModeEnabled, selectCurrentMatter } from '@app/core/store/selectors';

@Injectable()
export class DocumentEffects {

  sidebarAction$ = createEffect(() => this.actions$.pipe(
    ofType(sidebarActions.SIDEBAR_NEW_DOCUMENT),
    mergeMap(() => [new documentActions.NewDocument(null)])
  ));

  // Open create document modal

  newDocument$: Observable<unknown | Action> = createEffect(() => this.actions$.pipe(
    ofType(documentActions.NEW_DOCUMENT),
    mergeMap(() => {
      this._appApiSvc.navigate({ path: [{ outlets: { popup: ['document'] } }] });
      return [];
    }),
    catchError((error) => of(new documentActions.NewDocumentFailure(error)))
  ));


  newDocumentCreate$: Observable<string> = createEffect(() => this.actions$.pipe(
    ofType<documentActions.NewDocumentCreate>(documentActions.NEW_DOCUMENT_CREATE),
    withLatestFrom(this._store.pipe(select(selectContentModeEnabled)), (action, contentMode) => ({
      payload: action.payload,
      contentMode,
    })),
    mergeMap(({ payload, contentMode }) => {
      const containerParams = {
        precedentId: payload.precedent.id,
        defaultTableId: payload.tableId,
        defaultTableOrder: payload.tableOrder,
        defaultPersonInstance: payload.personInstance,
        contentMode,
      };
      return this._offlineLauncherSvc.createNewDocumentTicket(containerParams, {
        navigateClear: true,
      });
    })
  ), { dispatch: false });


  newDocumentTypeCreate$ = createEffect(() => this.actions$.pipe(
    ofType<documentActions.NewDocumentTypeCreate>(documentActions.NEW_DOCUMENT_TYPE_CREATE),
    withLatestFrom(this._store.pipe(select(selectContentModeEnabled)), (action, contentMode) => ({
      payload: action.payload,
      contentMode,
    })),
    mergeMap(({ payload, contentMode }) => {
      const { applicationType, blank } = payload;
      return this._offlineLauncherSvc.createNewContainerTicket(
        { applicationType, blank, contentMode },
        { navigateClear: true }
      );
    })
  ), { dispatch: false });


  editDocument$: Observable<string> = createEffect(() => this.actions$.pipe(
    ofType<documentActions.EditPrecedentStart>(documentActions.EDIT_PRECEDENT_START),
    withLatestFrom(this._store.pipe(select(selectContentModeEnabled)), (action, contentMode) => ({
      payload: action.payload,
      contentMode,
    })),
    mergeMap(({ payload, contentMode }) =>
      this._offlineLauncherSvc.createEditContainerTicket(
        { containerId: payload.item.id.toLowerCase(), contentMode },
        {
          navigateClear: true,
        }
      )
    )
  ), { dispatch: false });


  newDocumentCreateOnline$ = createEffect(() => this.actions$.pipe(
    ofType<documentActions.NewDocumentCreateOnline>(documentActions.NEW_DOCUMENT_CREATE_ONLINE),
    withLatestFrom(this._store.pipe(select(selectCurrentMatter)), this._store.pipe(select(selectSelectedFolderId))),
    mergeMap((data) => {
      const [action, currentMatter, selectedFolderId] = data;
      const params = {
        action: AutomationActionType.New,
        folderId: selectedFolderId,
        matterId: currentMatter.matterId,
        isOfficeOnline: true,
        containerInfo: {
          defaultTableId: action.payload.tableId,
          defaultTableOrder: action.payload.tableOrder,
          defaultPersonInstance: action.payload.personInstance,
          isCustomPrecedent: action.payload.precedent.isCustomized,
          customPrecedent: action.payload.precedent,
          precedentId: action.payload.precedent.id,
        },
      } as IAutomationOptions;
      return this._documentAutomationSvc.newContainerOnline(params);
    })
  ), { dispatch: false });


  editDocumentOnline$ = createEffect(() => this.actions$.pipe(
    ofType<documentActions.EditPrecedentOnline>(documentActions.EDIT_PRECEDENT_ONLINE),
    withLatestFrom(this._store.pipe(select(selectCurrentMatter)), this._store.pipe(select(selectSelectedFolderId))),
    mergeMap((data) => {
      const [action, currentMatter, selectedFolderId] = data;
      const params = {
        action: AutomationActionType.EditCustomPrecedent,
        folderId: selectedFolderId,
        matterId: currentMatter.matterId,
        isOfficeOnline: true,
        containerInfo: {
          customPrecedent: action.payload.item,
        },
      } as IAutomationOptions;
      return this._documentAutomationSvc.newContainerOnline(params);
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private _store: Store<State>,
    private _appApiSvc: AppApiService,
    private _offlineLauncherSvc: OfflineLauncherService,
    private _documentAutomationSvc: DocumentAutomationService
  ) {}
}
