import { createSelector } from '@ngrx/store';
import { selectAppState } from '@app/core/store/selectors/app-state.selectors';
import { AppState } from '../reducers';
import * as fromAutomation from '../reducers/automation.reducer';

export const selectAutomationState = createSelector(selectAppState, (state: AppState) => state.automation);

export const selectAutomationLocalTicketId = createSelector(
  selectAutomationState,
  fromAutomation.selectAutomationLocalTicketId
);

export const selectAutomationLoading = createSelector(selectAutomationState, fromAutomation.selectAutomationLoading);

export const selectAutomationWorkflowParams = createSelector(
  selectAutomationState,
  fromAutomation.selectAutomationWorkflowParams
);

export const selectAutomationResponded = createSelector(
  selectAutomationState,
  fromAutomation.selectAutomationResponded
);

export const selectAllAutomationTickets = createSelector(selectAutomationState, fromAutomation.selectAllTickets);

export const selectAllAutomationTicketsDocIds = createSelector(selectAllAutomationTickets, (tickets) => !!tickets ? tickets.map((ticket) => ticket.docId) : []);
