import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromPrecedentList from './precedent-list.reducer';
import * as fromPrecedentListModal from './precedent-list-modal.reducer';

export interface State {
  precedentList: fromPrecedentList.State;
  precedentListModal: fromPrecedentListModal.State;
}

export const initialState: State = {
  precedentList: fromPrecedentList.initialState,
  precedentListModal: fromPrecedentListModal.INITIAL_STATE,
};

export const reducers: ActionReducerMap<State> = {
  precedentList: fromPrecedentList.reducer,
  precedentListModal: fromPrecedentListModal.reducer,
};

export const selectPrecedentsState = createFeatureSelector<State>('precedents');
