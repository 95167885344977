import { createSelector } from '@ngrx/store';
import { AppState, selectAppState } from '@app/core/store';
import * as fromBrowserTitle from '../reducers/browser-title.reducer';

export const selectBrowserTitleState = createSelector(selectAppState, (state: AppState) =>
  !!state ? state.browserTitle : fromBrowserTitle.INITIAL_STATE
);

export const selectBrowserTitlePopupPageName = createSelector(
  selectBrowserTitleState,
  (state: fromBrowserTitle.State) => fromBrowserTitle.getPopupPageName(state)
);

export const selectBrowserTitleHideMatterInfo = createSelector(
  selectBrowserTitleState,
  (state: fromBrowserTitle.State) => fromBrowserTitle.getHideMatterInfo(state)
);

export const selectBrowserTitlePrimaryPageName = createSelector(
  selectBrowserTitleState,
  (state: fromBrowserTitle.State) => fromBrowserTitle.getPrimaryPageName(state)
);

export const selectBrowserTitlePageBrandName = createSelector(
  selectBrowserTitleState,
  (state: fromBrowserTitle.State) => fromBrowserTitle.getPageBrandName(state)
);
