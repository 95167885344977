import { Injectable } from '@angular/core';
import { LDFlagValue, LDMultiKindContext } from 'launchdarkly-js-client-sdk';
import { Observable, Subject, map } from 'rxjs';
import { LogService } from '../log/log.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  public useAuth0: boolean = false;

  constructor(private _log: LogService) {
    this._log.init('feature-flag-service');  
  }

  reloadFlags() {
    this.useAuth0 = this.ldClient.variation('can-use-auth0-login', false);
  }

  isFeatureEnabled(featureName: string): boolean {
    return this.ldClient.variation(featureName, false);
  }

  get ldClient() {
    return globalThis.ldClient;
  }
  
  getFlag(key: string, defaultValue: LDFlagValue = false): Observable<LDFlagValue> {
    const flag = new Subject<void>();

    this.ldClient.on(`change:${key}`, () => {
      // If that flag changes, lets call next so we can update the value
      flag.next();
    });
 
    return flag.pipe(
      map(() => {
        return this.ldClient.variation(key, defaultValue);
      })
    );
  }
  
}
