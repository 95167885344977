import { IPrecedent } from '@app/features/+precedent/models';
import * as precedentListModalActions from '../actions/precedent-list-modal.actions';

export interface State {
  precedentEntries: IPrecedent[];
}

export const INITIAL_STATE: State = {
  precedentEntries: [],
};

export const reducer = (state = INITIAL_STATE, action: precedentListModalActions.PrecedentListModalActions) => {
  switch (action.type) {
    case precedentListModalActions.PrecedentListModalActionTypes.STORE_PRECEDENT_ENTRIES:
      return {
        ...state,
        precedentEntries: action.payload.entries,
      };

    case precedentListModalActions.PrecedentListModalActionTypes.CLEAN_UP:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const getPrecedentEntries = (state: State) => state.precedentEntries;
