import { createAction, props } from '@ngrx/store';
import { IMattersFilterBy } from '@app/features/+matter-list/models';

export const matterFiltersModalOnClose = createAction(
  '[Matter Filters Modal] on close event',
  props<{ payload: IMattersFilterBy }>()
);

export const matterFiltersModalOnOpen = createAction(
  '[Matter Filters Modal] on open event',
  props<{ payload: any }>()
);
