import { Action } from '@ngrx/store';
import { IToastrRenderer } from '@app/core/models';

export const SHOW_TOASTR = '[Toastr] show';
export const HIDE_TOASTR = '[Toastr] hide';

export const CLEAR_ALL_TOASTR = '[Toastr] clear all';

export const UPDATE_TOASTR_RENDERER_START = '[Toastr] update renderer start';
export const UPDATE_TOASTR_RENDERER_SUCCESS = '[Toastr] update renderer success';
export const UPDATE_TOASTR_RENDERER_FAILURE = '[Toastr] update renderer failure';

export class ShowToastr implements Action {
  readonly type = SHOW_TOASTR;
  constructor(public payload: IToastrRenderer) {}
}

export class HideToastr implements Action {
  readonly type = HIDE_TOASTR;
  constructor(public payload: number) {}
}

export class ClearAllToastr implements Action {
  readonly type = CLEAR_ALL_TOASTR;
  constructor(public payload: any) {}
}

export class UpdateToastrRendererStart implements Action {
  readonly type = UPDATE_TOASTR_RENDERER_START;
  constructor(public payload: Partial<IToastrRenderer>) {}
}

export class UpdateToastrRendererSuccess implements Action {
  readonly type = UPDATE_TOASTR_RENDERER_SUCCESS;
  constructor(public payload: IToastrRenderer) {}
}

export class UpdateToastrRendererFailure implements Action {
  readonly type = UPDATE_TOASTR_RENDERER_FAILURE;
  constructor(public payload: any) {}
}

export type ToastrActions =
  | ShowToastr
  | HideToastr
  | ClearAllToastr
  | UpdateToastrRendererStart
  | UpdateToastrRendererSuccess
  | UpdateToastrRendererFailure;
