import { Action } from '@ngrx/store';
import { ICardListEntry, ICardListResponseSchema } from '@app/features/+card/models';

export enum CardListApiActionTypes {
  INIT_LIST_CARDS_START = '[Card List from api] init start',
  INIT_LIST_CARDS_SUCCESS = '[Card List from api] init success',
  INIT_LIST_CARDS_FAILURE = '[Card List from api] init failure',

  LIST_CARDS_START = '[Card List from api] start',
  LIST_CARDS_SUCCESS = '[Card List from api] success',
  LIST_CARDS_FAILURE = '[Card List from api] failure',

  SELECT_CARD = '[Card List] select card',
}

export class InitListCardsStart implements Action {
  readonly type = CardListApiActionTypes.INIT_LIST_CARDS_START;

  constructor(public payload: any) {}
}

export class InitListCardsSuccess implements Action {
  readonly type = CardListApiActionTypes.INIT_LIST_CARDS_SUCCESS;

  constructor(public payload: any) {}
}

export class InitListCardsFailure implements Action {
  readonly type = CardListApiActionTypes.INIT_LIST_CARDS_FAILURE;

  constructor(public payload: any) {}
}

export class ListCardsStart implements Action {
  readonly type = CardListApiActionTypes.LIST_CARDS_START;

  constructor(public payload: number) {}
}

export class ListCardsSuccess implements Action {
  readonly type = CardListApiActionTypes.LIST_CARDS_SUCCESS;

  constructor(public payload: ICardListResponseSchema) {}
}

export class ListCardsFailure implements Action {
  readonly type = CardListApiActionTypes.LIST_CARDS_FAILURE;

  constructor(public payload: any) {}
}

export class SelectCard implements Action {
  readonly type = CardListApiActionTypes.SELECT_CARD;

  constructor(public payload: { card: ICardListEntry }) {}
}

export type CardListApiActions =
  | InitListCardsStart
  | InitListCardsSuccess
  | InitListCardsFailure
  | ListCardsStart
  | ListCardsSuccess
  | ListCardsFailure
  | SelectCard;
