import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'sc-modal-body',
  templateUrl: './modal-body.component.html',
})
export class ModalBodyComponent implements OnInit {
  constructor() {}
  @HostBinding('class.modal-body')
  ngOnInit() {}
}
