import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Staff, StaffPaymentLimit, StaffSecurity } from '../../../shared/models';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../shared/services/base/base.service';

@Injectable()
export class StaffService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  public readonly allOptionId: string = '0'; // we need to set this as 0 for ng-select, as ng-select expect an id value that is not null

  getStaffs(): Observable<Staff[]> {
    // enable force mode
    // because staff list selection in New Fee/ Time entry requires latest display.
    const url = `${this.siriusPath}/api/calc/stafflist?fetchMode=force`;
    return this.http.get<Staff[]>(url);
  }

  createStaffListOptionAll(): Staff {
    return {
      __id: this.allOptionId,
      __className: 'Staff',
      branch: null,
      certificates: null,
      deleteCode: 0,
      email: null,
      extension: null,
      fax: null,
      firmReference: null,
      firstName: 'All',
      fullName: 'All',
      immigLicense: null,
      initials: 'All',
      lastName: 'All',
      legalFullName: null,
      middleName: null,
      mobile: null,
      phone: null,
      qualifications: null,
      rate1: 0,
      rate2: 0,
      rate3: 0,
      rate4: 0,
      rate5: 0,
      rate6: 0,
      rates: [],
      staffId: this.allOptionId,
      title: null,
      userId: null,
      __tableId: null,
      StaffSecurities: null,
    } as Staff;
  }

  getStaffSecuritySettings(staffId: string): Observable<StaffSecurity> {
    const url = `${this.optionsPath}/api/staffsecurity/${staffId}`;
    return this.http.get<StaffSecurity>(url);
  }

  getStaffPaymentLimit(staffId: string): Observable<StaffPaymentLimit> {
    const url = `${this.optionsPath}/api/staffsecurity/payment-limits/${staffId}`;
    return this.http.get<StaffPaymentLimit>(url);
  }
}
