import { Action } from '@ngrx/store';
import { IDoc } from '@app/shared/models';
import { IRenameActionPayload } from '@app/features/+correspondence/models';

export const DELETE_CORRESPONDENCE_CONFIRM = '[Delete correspondence] confirm';
export const DELETE_CORRESPONDENCE_START = '[Delete correspondence] start';
export const DELETE_CORRESPONDENCE_SUCCESS = '[Delete correspondence] success';
export const DELETE_CORRESPONDENCE_FAILURE = '[Delete correspondence] failure';

export const UNDELETE_CORRESPONDENCE_START = '[Undelete correspondence] start';
export const UNDELETE_CORRESPONDENCE_SUCCESS = '[Undelete correspondence] success';
export const UNDELETE_CORRESPONDENCE_FAILURE = '[Undelete correspondence] failure';

export const TOGGLE_PIN_CORRESPONDENCE_START = '[Toggle pin correspondence] start';
export const TOGGLE_PIN_CORRESPONDENCE_SUCCESS = '[Toggle pin correspondence] success';
export const TOGGLE_PIN_CORRESPONDENCE_FAILURE = '[Toggle pin correspondence] failure';

export const RENAME_CORRESPONDENCE_START = '[Rename correspondence] start';
export const RENAME_CORRESPONDENCE_SUCCESS = '[Rename correspondence] success';
export const RENAME_CORRESPONDENCE_FAILURE = '[Rename correspondence] failure';

export const DUPLICATE_DOCUMENT_START = '[correspondence] duplicate document start';
export const DUPLICATE_DOCUMENT_SUCCESS = '[correspondence] duplicate document success';
export const DUPLICATE_DOCUMENT_FAILURE = '[correspondence] duplicate document failure';

export const EXTRACT_ATTACHMENT_START = '[correspondence] extract attachment start';
export const EXTRACT_ATTACHMENT_SUCCESS = '[correspondence] extract attachment success';
export const EXTRACT_ATTACHMENT_FAILURE = '[correspondence] extract attachment failure';
export const CLEAR_EXTRACTED_ATTACHMENTS = '[correspondence] clear extracted attachments';

export const GENERATE_PENDING_PRECEDENT_START = '[correspondence] generate pending precedent start';
export const GENERATE_PENDING_PRECEDENT_SUCCESS = '[correspondence] generate pending precedent success';
export const GENERATE_PENDING_PRECEDENT_FAILURE = '[correspondence] generate pending precedent failure';

export const GENERATE_READONLY_PENDING_PRECEDENT_START = '[correspondence] generate readonly pending precedent start';
export const GENERATE_READONLY_PENDING_PRECEDENT_SUCCESS =
  '[correspondence] generate readonly pending precedent success';
export const GENERATE_READONLY_PENDING_PRECEDENT_FAILURE =
  '[correspondence] generate readonly pending precedent failure';

export const DELETE_PENDING_PRECEDENT_CORRESPONDENCE_START =
  '[correspondence] delete pending precedent correspondence start';
export const DELETE_PENDING_PRECEDENT_CORRESPONDENCE_SUCCESS =
  '[correspondence] delete pending precedent correspondence success';
export const DELETE_PENDING_PRECEDENT_CORRESPONDENCE_FAILURE =
  '[correspondence] delete pending precedent correspondence failure';

export class DeleteCorrespondenceConfirm implements Action {
  readonly type = DELETE_CORRESPONDENCE_CONFIRM;
  constructor(public payload: IDoc) {}
}

export class DeleteCorrespondenceStart implements Action {
  readonly type = DELETE_CORRESPONDENCE_START;
  constructor(public payload: IDoc) {}
}

export class DeleteCorrespondenceSuccess implements Action {
  readonly type = DELETE_CORRESPONDENCE_SUCCESS;
  constructor(public payload: any) {}
}

export class DeleteCorrespondenceFailure implements Action {
  readonly type = DELETE_CORRESPONDENCE_FAILURE;
  constructor(public payload: any) {}
}

export class UndeleteCorrespondenceStart implements Action {
  readonly type = UNDELETE_CORRESPONDENCE_START;
  constructor(public payload: IDoc) {}
}

export class UndeleteCorrespondenceSuccess implements Action {
  readonly type = UNDELETE_CORRESPONDENCE_SUCCESS;
  constructor(public payload: any) {}
}

export class UndeleteCorrespondenceFailure implements Action {
  readonly type = UNDELETE_CORRESPONDENCE_FAILURE;
  constructor(public payload: any) {}
}

export class TogglePinCorrespondenceStart implements Action {
  readonly type = TOGGLE_PIN_CORRESPONDENCE_START;
  constructor(public payload: IDoc) {}
}

export class TogglePinCorrespondenceSuccess implements Action {
  readonly type = TOGGLE_PIN_CORRESPONDENCE_SUCCESS;
  constructor(public payload: any) {}
}

export class TogglePinCorrespondenceFailure implements Action {
  readonly type = TOGGLE_PIN_CORRESPONDENCE_FAILURE;
  constructor(public payload: any) {}
}

export class RenameCorrespondenceStart implements Action {
  readonly type = RENAME_CORRESPONDENCE_START;
  constructor(public payload: IRenameActionPayload) {}
}

export class RenameCorrespondenceSuccess implements Action {
  readonly type = RENAME_CORRESPONDENCE_SUCCESS;
  constructor(public payload: any) {}
}

export class RenameCorrespondenceFailure implements Action {
  readonly type = RENAME_CORRESPONDENCE_FAILURE;
  constructor(public payload: any) {}
}

export class DuplicateDocumentStart implements Action {
  readonly type = DUPLICATE_DOCUMENT_START;
  constructor(public payload: IDoc) {}
}

export class DuplicateDocumentSuccess implements Action {
  readonly type = DUPLICATE_DOCUMENT_SUCCESS;
  constructor(public payload: any) {}
}

export class DuplicateDocumentFailure implements Action {
  readonly type = DUPLICATE_DOCUMENT_FAILURE;
  constructor(public payload: any) {}
}

export class ExtractAttachmentsStart implements Action {
  readonly type = EXTRACT_ATTACHMENT_START;
  constructor(public payload: { documentId: string; attachments: string[] }) {}
}

export class ExtractAttachmentsSuccess implements Action {
  readonly type = EXTRACT_ATTACHMENT_SUCCESS;
  constructor(public payload: string[]) {}
}

export class ExtractAttachmentsFailure implements Action {
  readonly type = EXTRACT_ATTACHMENT_FAILURE;
  constructor(public payload: any) {}
}

export class ClearExtractedAttatchments implements Action {
  readonly type = CLEAR_EXTRACTED_ATTACHMENTS;
  constructor(public payload: any) {}
}

export class GenerateReadonlyPendingPrecedentStart implements Action {
  readonly type = GENERATE_READONLY_PENDING_PRECEDENT_START;
  constructor(public payload: IDoc) {}
}

export class GenerateReadonlyPendingPrecedentSuccess implements Action {
  readonly type = GENERATE_READONLY_PENDING_PRECEDENT_SUCCESS;
  constructor(public payload: any) {}
}

export class GenerateReadonlyPendingPrecedentFailure implements Action {
  readonly type = GENERATE_READONLY_PENDING_PRECEDENT_FAILURE;
  constructor(public payload: any) {}
}

export class GeneratePendingPrecedentStart implements Action {
  readonly type = GENERATE_PENDING_PRECEDENT_START;
  constructor(public payload: IDoc) {}
}

export class GeneratePendingPrecedentSuccess implements Action {
  readonly type = GENERATE_PENDING_PRECEDENT_SUCCESS;
  constructor(public payload: any) {}
}

export class GeneratePendingPrecedentFailure implements Action {
  readonly type = GENERATE_PENDING_PRECEDENT_FAILURE;
  constructor(public payload: any) {}
}

export class DeletePendingPrecedentCorrespondenceStart implements Action {
  readonly type = DELETE_PENDING_PRECEDENT_CORRESPONDENCE_START;
  constructor(public payload: IDoc) {}
}

export class DeletePendingPrecedentCorrespondenceSuccess implements Action {
  readonly type = DELETE_PENDING_PRECEDENT_CORRESPONDENCE_SUCCESS;
  constructor(public payload: any) {}
}

export class DeletePendingPrecedentCorrespondenceFailure implements Action {
  readonly type = DELETE_PENDING_PRECEDENT_CORRESPONDENCE_FAILURE;
  constructor(public payload: any) {}
}

export type CorrespondenceApiActions =
  | DeleteCorrespondenceConfirm
  | DeleteCorrespondenceStart
  | DeleteCorrespondenceSuccess
  | DeleteCorrespondenceFailure
  | UndeleteCorrespondenceStart
  | UndeleteCorrespondenceSuccess
  | UndeleteCorrespondenceFailure
  | TogglePinCorrespondenceStart
  | TogglePinCorrespondenceSuccess
  | TogglePinCorrespondenceFailure
  | RenameCorrespondenceStart
  | RenameCorrespondenceSuccess
  | RenameCorrespondenceFailure
  | DuplicateDocumentStart
  | DuplicateDocumentSuccess
  | DuplicateDocumentFailure
  | ExtractAttachmentsStart
  | ExtractAttachmentsSuccess
  | ExtractAttachmentsFailure
  | ClearExtractedAttatchments
  | GeneratePendingPrecedentStart
  | GeneratePendingPrecedentSuccess
  | GeneratePendingPrecedentFailure
  | GenerateReadonlyPendingPrecedentStart
  | GenerateReadonlyPendingPrecedentSuccess
  | GenerateReadonlyPendingPrecedentFailure
  | DeletePendingPrecedentCorrespondenceStart
  | DeletePendingPrecedentCorrespondenceSuccess
  | DeletePendingPrecedentCorrespondenceFailure;
