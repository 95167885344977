import { Action } from '@ngrx/store';

export const LAUNCH_LIVE_CHAT = '[Live Chat] launch';

export class LaunchLiveChat implements Action {
  readonly type = LAUNCH_LIVE_CHAT;
  constructor(public payload: any) {}
}

export type LiveChatAction = LaunchLiveChat;
