import { createSelector } from '@ngrx/store';

import * as fromPaymentTypeDetailsModal from '../reducers/payment-type-details.reducer';

import { selectPaymentTypeDetailsSelectorState } from '../reducers';

export const selectIsInflowType = createSelector(
  selectPaymentTypeDetailsSelectorState,
  (state: fromPaymentTypeDetailsModal.State) => fromPaymentTypeDetailsModal.selectIsInflowType(state)
);

export const selectIsOutflowType = createSelector(
  selectPaymentTypeDetailsSelectorState,
  (state: fromPaymentTypeDetailsModal.State) => fromPaymentTypeDetailsModal.selectIsOutflowType(state)
);

export const selectPaymentDetails = createSelector(
  selectPaymentTypeDetailsSelectorState,
  (state: fromPaymentTypeDetailsModal.State) => fromPaymentTypeDetailsModal.selectPaymentDetails(state)
);

export const selectOnCloseResponse = createSelector(
  selectPaymentTypeDetailsSelectorState,
  (state: fromPaymentTypeDetailsModal.State) => fromPaymentTypeDetailsModal.selectOnCloseResponse(state)
);

export const selectPaymentId = createSelector(
  selectPaymentTypeDetailsSelectorState,
  (state: fromPaymentTypeDetailsModal.State) => fromPaymentTypeDetailsModal.selectPaymentId(state)
);

export const selectSubPaymentTypeId = createSelector(
  selectPaymentTypeDetailsSelectorState,
  (state: fromPaymentTypeDetailsModal.State) => fromPaymentTypeDetailsModal.selectPaymentType(state)?.SubPaymentTypeID
);

export const selectPaymentType = createSelector(
  selectPaymentTypeDetailsSelectorState,
  (state: fromPaymentTypeDetailsModal.State) => fromPaymentTypeDetailsModal.selectPaymentType(state)
);

export const selectPayeeId = createSelector(
  selectPaymentTypeDetailsSelectorState,
  (state: fromPaymentTypeDetailsModal.State) => fromPaymentTypeDetailsModal.selectPayeeCardId(state)
);
