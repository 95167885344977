import { Addin } from '@app/features/matter-addin/models';
import * as matterAddinActions from '../action';
import * as appActions from '@app/core/store/actions/app.action';

export interface State {
  pageNumber: number;
  allAddins: Addin[];
  addins: Addin[];
  addinsContextMenu: any[];
  addinItem: any;
  loading: boolean;
  error: any;
}

export const INITIAL_STATE: State = {
  pageNumber: 0,
  allAddins: [],
  addins: [],
  addinsContextMenu: [],
  addinItem: null,
  loading: false,
  error: null,
};

export const reducer = (state: State = INITIAL_STATE, action: matterAddinActions.MatterAddinActions | appActions.All) => {
  switch (action.type) {
    case matterAddinActions.MATTER_ADDIN_INIT_START: {
      return {
        ...state,
        loading: true,
        pageNumber: action.payload.pageNumber,
      };
    }

    case matterAddinActions.MATTER_ADDIN_ALL_INIT_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case matterAddinActions.MATTER_ADDIN_INIT_SUCCESS: {
      return {
        ...state,
        addins: action.payload.addins,
        addinsContextMenu: action.payload.addinsContextMenu,
        loading: false,
      };
    }

    case matterAddinActions.MATTER_ADDIN_ALL_INIT_SUCCESS: {
      return {
        ...state,
        allAddins: action.payload,
        addins: [],
        addinsContextMenu: [],
        loading: false,
      };
    }

    case matterAddinActions.MATTER_ADDIN_INIT_FAILURE:
    case matterAddinActions.MATTER_ADDIN_ALL_INIT_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case matterAddinActions.OPEN_MATTER_ADDIN: {
      return {
        ...state,
        addinItem: action.payload,
      };
    }

    case appActions.CLEAR_MATTER: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};

export const selectError = (state: State) => (!!state ? state.error : INITIAL_STATE.error);
export const selectLoading = (state: State) => (!!state ? state.loading : INITIAL_STATE.loading);
export const selectPageNumber = (state: State) => (!!state ? state.pageNumber : INITIAL_STATE.pageNumber);
export const selectAllAddins = (state: State) => (!!state ? state.allAddins : INITIAL_STATE.allAddins);
export const selectAddins = (state: State) => (!!state ? state.addins : INITIAL_STATE.addins);
export const selectAddinsContextMenu = (state: State) =>
  !!state ? state.addinsContextMenu : INITIAL_STATE.addinsContextMenu;
export const selectAddinItem = (state: State) => (!!state ? state.addinItem : INITIAL_STATE.addinItem);
