import { Component, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TransactionNumberService } from 'app/features/accounting/services/transaction-number/transaction-number.service';
import { ITransactionNumber } from 'app/features/+payments-debtors/services';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { TransactionNumberInput } from '@app/features/+payments-debtors/models';
import { ITransactionNumberInput } from '@app/features/accounting/models/payment.model';

import { AnalyticsCategories } from '@app/core/constants/analytics.constant';
import { isObjEqual } from '@server/modules/shared/functions/common-util.functions';
import { PaymentTypeId } from '../../constants';

@Component({
  selector: 'sc-transaction-number',
  templateUrl: './transaction-number.component.html',
})
export class TransactionNumberComponent implements OnDestroy {
  private _autoNumberFormInitDone = false;
  private _transactionNumberFormInitDone = false;
  private _previousAutoNoBool = false;

  autoTransactionNumber: ITransactionNumber;

  @Input() isCompletedTransaction = false;

  @Input() useTransactionNumberFormInitValue = false; // only if it is not autoNumber = true;

  @Input()
  get transactionInput() {
    return this._transactionInput;
  }
  set transactionInput(value: TransactionNumberInput | ITransactionNumberInput) {
    const bankAccountUpdated =
      !!value &&
      !!value.bankAccount &&
      !!this._transactionInput &&
      value.bankAccount.BankAccountGUID !== this._transactionInput.bankAccount.BankAccountGUID;

    const paymentTypeUpdated =
      !!value &&
      !!value.paymentType &&
      (!this._transactionInput ||
        !this._transactionInput.paymentType ||
        this._transactionInput.paymentType.PaymentTypeID !== value.paymentType.PaymentTypeID ||
        this._transactionInput.paymentType.PaymentTypeGUID !== value.paymentType.PaymentTypeGUID);

    this._transactionInput = value;

    if (!this.isCompletedTransaction) {
      let skipForFirstInit = false;
      // if this is leap app data, skip for first init.
      if (this.useTransactionNumberFormInitValue && !this._autoNumberFormInitDone) {
        this._autoNumberFormInitDone = true;
        skipForFirstInit = !this._transactionNumberForm.controls['autoNumber'].value;
      }

      if (paymentTypeUpdated && value && !!this._transactionNumberForm) {
        const isPaymentTypeNotCashOrCredit =
          value.paymentType && !(value.paymentType.PaymentTypeID === PaymentTypeId.Cash || value.paymentType.PaymentTypeID === PaymentTypeId.CreditCard);

        this.autoDisabled$.next(isPaymentTypeNotCashOrCredit ? false : true);

        // if this is leap app data, skip for first init.
        if (skipForFirstInit) {
          return;
        }

        if (!isPaymentTypeNotCashOrCredit) {
          this._transactionNumberForm.patchValue({ autoNumber: false, transactionNumber: '' });
        }

        this.setAutomaticPaymentNumber(value);
      }

      // if this is leap app data, skip for first init.
      if (skipForFirstInit) {
        return;
      }

      let hasAutoNoBoolChange = false;
      if (this._previousAutoNoBool !== this._transactionNumberForm.controls['autoNumber'].value) {
        this._previousAutoNoBool = this._transactionNumberForm.controls['autoNumber'].value;
        hasAutoNoBoolChange = true;
      }

      if (
        (bankAccountUpdated || hasAutoNoBoolChange) &&
        !!this._transactionNumberForm &&
        this._transactionNumberForm.controls['autoNumber'].value
      ) {
        this.setAutomaticPaymentNumber(value);
      }
    }
  }

  get getAnalyticsCategory(): string {
    return AnalyticsCategories.OfficePaymentForm;
  }

  private unsub = new Subject<void>();

  constructor(private _transactionNumberService: TransactionNumberService) {
    this.numberDisabled$ = new BehaviorSubject<boolean>(false);
    this.autoDisabled$ = new BehaviorSubject<boolean>(true);
  }

  @Input()
  get transactionNumberForm(): FormGroup {
    return this._transactionNumberForm;
  }

  set transactionNumberForm(form: FormGroup) {
    if (form) {
      this._transactionNumberForm = form;
      this.subscribetoFormChanges();
      this.patchAutoNumber();
    }
  }

  @Input()
  transactionNumberLabel: string;
  @Input()
  validationErrors: boolean;

  @Input()
  set enableAutoNumber(value: boolean) {
    this.autoDisabled$.next(!value);
  }

  @Input()
  set disabled(value: boolean) {
    if (!!value) {
      this.numberDisabled$.next(value);
      this.autoDisabled$.next(value);
    }
  }

  private _transactionNumberForm: FormGroup;
  private _transactionInput: TransactionNumberInput | ITransactionNumberInput;

  public numberDisabled$: BehaviorSubject<boolean>;
  public autoDisabled$: BehaviorSubject<boolean>;

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  getTransactionNumber(value: any): ITransactionNumber {
    if (value.bankAccount && value.paymentType && value.preference) {
      return this._transactionNumberService.getPaymentNumber(value.bankAccount, value.paymentType, value.preference);
    }
  }

  private subscribetoFormChanges() {
    if (this._transactionNumberForm) {
      this._transactionNumberForm.controls['autoNumber'].valueChanges
        .pipe(distinctUntilChanged(isObjEqual), takeUntil(this.unsub))
        .subscribe(() => {
          this.patchAutoNumber();
        });
    }
  }

  private setAutomaticPaymentNumber(value: TransactionNumberInput | ITransactionNumberInput) {
    this.autoTransactionNumber = this.getTransactionNumber(value);
    if (!!this.autoTransactionNumber) {
      this._transactionNumberForm.patchValue({
        autoNumber: this.autoTransactionNumber.Auto,
      });
      this.patchAutoNumber();
    }
  }

  private patchAutoNumber() {
    const autoChecked = this._transactionNumberForm.controls.autoNumber.value;
    if (this.autoTransactionNumber) {
      const tnumber = autoChecked ? this.autoTransactionNumber.TransactionNumber : '';

      this._transactionNumberForm.patchValue({
        transactionNumber: tnumber,
      });
    }
    if (this._transactionNumberForm.controls['autoNumber'].value) {
      this.numberDisabled$.next(true);
    } else {
      this.numberDisabled$.next(false);
    }
  }
}
