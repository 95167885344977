import { Component, HostBinding, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { xAnimationPulseWithParams } from '@app/shared/animations/animations';

@Component({
  selector: 'sc-input-group-append',
  templateUrl: './input-group-append.component.html',
  animations: [xAnimationPulseWithParams],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputGroupAppendComponent), multi: true }],
})
export class InputGroupAppendComponent implements OnInit {
  @Input()
  inputClass: string;
  @Input()
  placeholder: string;
  @Input()
  isDisabled: boolean;

  private _value: string;
  constructor() {}

  @HostBinding('class.x-input-group')
  ngOnInit() {}

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  writeValue(value: any) {
    this._value = value;
    this.onChange(value);
  }

  onChange = (_) => {};

  onTouched = () => {};
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
