import { Component, HostBinding } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-text-icon-cell',
  templateUrl: './text-icon-cell.component.html',
})
export class TextIconCellComponent implements AgRendererComponent {
  @HostBinding('class.x-text-icon-cell')
  text: string;
  wrapperClass: string;
  iconName: string;
  iconSize: string;
  iconClass: string;
  contentClass: string;
  isPinned: boolean;
  title: string;
  sizeClass: string;
  isLoading: boolean;
  onContentClicked: () => void;

  agInit(params: any): void {
    const rowObj = params.data;
    this.text = isFunction(params['getName']) ? params.getName(rowObj, params) : null;
    this.wrapperClass = isFunction(params['getWrapperClass']) ? params.getWrapperClass(params.data) : null;
    const requireRowParams = params['requireRowParams'] || false;
    this.iconClass = isFunction(params['getIconClass'])
      ? params.getIconClass(requireRowParams ? params : params.data)
      : '';
    this.contentClass = isFunction(params['getContentClass'])
      ? params.getContentClass(requireRowParams ? params : params.data)
      : '';
    this.onContentClicked = isFunction(params['getContentClicked'])
      ? params.getContentClicked(requireRowParams ? params : params.data)
      : null;
    this.title = isFunction(params['getTitle']) ? params.getTitle(params.data) : false;
    this.sizeClass = isFunction(params['getSizeClass']) ? params.getSizeClass(params.data) : false;
    this.isLoading = isFunction(params['getIsLoading']) ? params.getIsLoading(params.data) : false;
    if (!requireRowParams) {
      if (this.sizeClass === 'x-icon-xl') {
        this.iconSize = '-24';
      } else if (this.sizeClass === 'x-icon-md') {
        this.iconSize = '-16';
      } else {
        this.iconSize = '';
      }
      this.iconName = this.iconClass + this.iconSize;
    } else {
      this.iconName = this.iconClass;
    }
    if (this.wrapperClass === 'doc-pin') {
      this.isPinned = true;
    }
  }

  refresh(params: any): boolean {
    this.isLoading = isFunction(params['getIsLoading']) ? params.getIsLoading(params.data) : false;
    return true;
  }

  contentClicked($event: Event): void {
    if (isFunction(this.onContentClicked)) {
      this.onContentClicked();
      $event.stopImmediatePropagation();
    }
  }
}
