<sc-no-results-overlay
  *ngIf="!!params && !loading; else loadingBlock"
  [params]="params"
  [gridComponent]="true"
></sc-no-results-overlay>

<ng-template #loadingBlock>
  <div
    *ngIf="showSkeleton; else loaderBlock"
    [ngStyle]="{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      backgroundColor: 'white'
    }"
    [style.marginTop]="headerHeight"
  >
    <sc-ghost-list
      class="x-list-group-content overflow-auto"
      [size]="skeletonAmount"
      [itemWrapperClass]="'list-group-item'"
      [itemWrapperStyle]="{ height: rowHeight }"
    ></sc-ghost-list>
  </div>

  <ng-template #loaderBlock>
    <sc-pre-load
      [background]="background"
      [positionClass]="'absolute'"
      [icon]="'orb'"
      [size]="'x-icon-xxl'"
    ></sc-pre-load>
  </ng-template>
</ng-template>
