import { Action } from '@ngrx/store';
import { IDocsFolder, IDoc } from '@app/shared/models';
import { IRenameActionPayload, IMoveToFolderActionPayload, AddPendingDocumentParams } from '../../models';
import { IPrecedent } from '@app/features/+precedent/models';

export const LIST_RECURRING_MATTER_CORRESPONDENCE_START = '[Recurring matter correspondence] list start';
export const LIST_RECURRING_MATTER_CORRESPONDENCE_SUCCESS = '[Recurring matter correspondence] list success';
export const LIST_RECURRING_MATTER_CORRESPONDENCE_FAILURE = '[Recurring matter correspondence] list failure';

export const REFRESH_RECURRING_MATTER_CORRESPONDENCE_START = '[Recurring matter correspondence] refresh list start';
export const REFRESH_RECURRING_MATTER_CORRESPONDENCE_SUCCESS = '[Recurring matter correspondence] refresh list success';
export const REFRESH_RECURRING_MATTER_CORRESPONDENCE_FAILURE = '[Recurring matter correspondence] refresh list failure';

export const SELECT_RECURRING_MATTER_CORRESPONDENCE_FOLDER = '[Recurring matter correspondence] select folder';

export const DELETE_RECURRING_MATTER_CORRESPONDENCE_START = '[Recurring matter correspondence] delete start';
export const DELETE_RECURRING_MATTER_CORRESPONDENCE_SUCCESS = '[Recurring matter correspondence] delete success';
export const DELETE_RECURRING_MATTER_CORRESPONDENCE_FAILURE = '[Recurring matter correspondence] delete failure';

export const UNDELETE_RECURRING_MATTER_CORRESPONDENCE_START = '[Recurring matter correspondence] undelete start';
export const UNDELETE_RECURRING_MATTER_CORRESPONDENCE_SUCCESS = '[Recurring matter correspondence] undelete success';
export const UNDELETE_RECURRING_MATTER_CORRESPONDENCE_FAILURE = '[Recurring matter correspondence] undelete failure';

export const MOVE_RECURRING_MATTER_CORRESPONDENCE_START = '[Recurring matter correspondence] move start';
export const MOVE_RECURRING_MATTER_CORRESPONDENCE_SUCCESS = '[Recurring matter correspondence] move success';
export const MOVE_RECURRING_MATTER_CORRESPONDENCE_FAILURE = '[Recurring matter correspondence] move falure';
export const MOVE_RECURRING_MATTER_CORRESPONDENCE_RESET = '[Recurring matter correspondence] move reset';

export const RENAME_RECURRING_MATTER_CORRESPONDENCE_START = '[Recurring matter correspondence] rename start';
export const RENAME_RECURRING_MATTER_CORRESPONDENCE_SUCCESS = '[Recurring matter correspondence] rename success';
export const RENAME_RECURRING_MATTER_CORRESPONDENCE_FAILURE = '[Recurring matter correspondence] rename failure';

export const CLEAR_RECURRING_MATTER_CORRESPONDENCE = '[Recurring matter correspondence] clear';

export const CHECK_PENDING_PRECEDENT_NAME = '[Recurring matter correspondence] check pending precedent name';
export const CHECK_PENDING_DOCUMENT_NAME = '[Recurring matter correspondence] check pending document name';

export const ADD_PENDING_PRECEDENT_START = '[Recurring matter correspondence] add pending precedent start';
export const ADD_PENDING_PRECEDENT_SUCCESS = '[Recurring matter correspondence] add pending precedent success';
export const ADD_PENDING_PRECEDENT_FAILURE = '[Recurring matter correspondence] add pending precedent failure';

export const ADD_PENDING_DOCUMENT_START = '[Recurring matter correspondence] add pending document start';
export const ADD_PENDING_DOCUMENT_SUCCESS = '[Recurring matter correspondence] add pending document success';
export const ADD_PENDING_DOCUMENT_FAILURE = '[Recurring matter correspondence] add pending document failure';

export const CHECK_PENDING_NAME_START = '[Recurring matter correspondence] check pending name start';
export const CHECK_PENDING_NAME_SUCCESS = '[Recurring matter correspondence] check pending name success';
export const CHECK_PENDING_NAME_FAILURE = '[Recurring matter correspondence] check pending name failure';

export class ListRecurringMatterCorrespondenceStart implements Action {
  readonly type = LIST_RECURRING_MATTER_CORRESPONDENCE_START;
  constructor(public payload: string) {}
}

export class ListRecurringMatterCorrespondenceSuccess implements Action {
  readonly type = LIST_RECURRING_MATTER_CORRESPONDENCE_SUCCESS;
  constructor(public payload: any) {}
}

export class ListRecurringMatterCorrespondenceFailure implements Action {
  readonly type = LIST_RECURRING_MATTER_CORRESPONDENCE_FAILURE;
  constructor(public payload: any) {}
}

export class RefreshRecurringMatterCorrespondenceStart implements Action {
  readonly type = REFRESH_RECURRING_MATTER_CORRESPONDENCE_START;
  constructor(public payload: string) {}
}

export class RefreshRecurringMatterCorrespondenceSuccess implements Action {
  readonly type = REFRESH_RECURRING_MATTER_CORRESPONDENCE_SUCCESS;
  constructor(public payload: any) {}
}

export class RefreshRecurringMatterCorrespondenceFailure implements Action {
  readonly type = REFRESH_RECURRING_MATTER_CORRESPONDENCE_FAILURE;
  constructor(public payload: any) {}
}

export class SelectRecurringMatterCorrespondenceFolder implements Action {
  readonly type = SELECT_RECURRING_MATTER_CORRESPONDENCE_FOLDER;
  constructor(public payload: IDocsFolder) {}
}

export class DeleteRecurringMatterCorrespondenceStart implements Action {
  readonly type = DELETE_RECURRING_MATTER_CORRESPONDENCE_START;
  constructor(public payload: IDoc) {}
}

export class DeleteRecurringMatterCorrespondenceSuccess implements Action {
  readonly type = DELETE_RECURRING_MATTER_CORRESPONDENCE_SUCCESS;
  constructor(public payload: string) {}
}

export class DeleteRecurringMatterCorrespondenceFailure implements Action {
  readonly type = DELETE_RECURRING_MATTER_CORRESPONDENCE_FAILURE;
  constructor(public payload: any) {}
}

export class UnDeleteRecurringMatterCorrespondenceStart implements Action {
  readonly type = UNDELETE_RECURRING_MATTER_CORRESPONDENCE_START;
  constructor(public payload: IDoc) {}
}

export class UnDeleteRecurringMatterCorrespondenceSuccess implements Action {
  readonly type = UNDELETE_RECURRING_MATTER_CORRESPONDENCE_SUCCESS;
  constructor(public payload: string) {}
}

export class UnDeleteRecurringMatterCorrespondenceFailure implements Action {
  readonly type = UNDELETE_RECURRING_MATTER_CORRESPONDENCE_FAILURE;
  constructor(public payload: any) {}
}

export class MoveRecurringMatterCorrespondenceStart implements Action {
  readonly type = MOVE_RECURRING_MATTER_CORRESPONDENCE_START;
  constructor(public payload: IMoveToFolderActionPayload) {}
}

export class MoveRecurringMatterCorrespondenceSuccess implements Action {
  readonly type = MOVE_RECURRING_MATTER_CORRESPONDENCE_SUCCESS;
  constructor(public payload: string) {}
}

export class MoveRecurringMatterCorrespondenceFailure implements Action {
  readonly type = MOVE_RECURRING_MATTER_CORRESPONDENCE_FAILURE;
  constructor(public payload: any) {}
}

export class MoveRecurringMatterCorrespondenceReset implements Action {
  readonly type = MOVE_RECURRING_MATTER_CORRESPONDENCE_RESET;
  constructor(public payload: any) {}
}

export class RenameRecurringMatterCorrespondenceStart implements Action {
  readonly type = RENAME_RECURRING_MATTER_CORRESPONDENCE_START;
  constructor(public payload: IRenameActionPayload) {}
}

export class RenameRecurringMatterCorrespondenceSuccess implements Action {
  readonly type = RENAME_RECURRING_MATTER_CORRESPONDENCE_SUCCESS;
  constructor(public payload: string) {}
}

export class RenameRecurringMatterCorrespondenceFailure implements Action {
  readonly type = RENAME_RECURRING_MATTER_CORRESPONDENCE_FAILURE;
  constructor(public payload: any) {}
}

export class ClearRecurringMatterCorrespondence implements Action {
  readonly type = CLEAR_RECURRING_MATTER_CORRESPONDENCE;
  constructor(public payload: any) {}
}

export class CheckPendingPrecedentName implements Action {
  readonly type = CHECK_PENDING_PRECEDENT_NAME;
  constructor(public payload: IPrecedent) {}
}

export class AddPendingPrecedentStart implements Action {
  readonly type = ADD_PENDING_PRECEDENT_START;
  constructor(public payload: IPrecedent) {}
}

export class AddPendingPrecedentSuccess implements Action {
  readonly type = ADD_PENDING_PRECEDENT_SUCCESS;
  constructor(public payload: string) {}
}

export class AddPendingPrecedentFailure implements Action {
  readonly type = ADD_PENDING_PRECEDENT_FAILURE;
  constructor(public payload: any) {}
}

export class CheckPendingDocumentName implements Action {
  readonly type = CHECK_PENDING_DOCUMENT_NAME;
  constructor(public payload: AddPendingDocumentParams) {}
}

export class AddPendingDocumentStart implements Action {
  readonly type = ADD_PENDING_DOCUMENT_START;
  constructor(public payload: AddPendingDocumentParams) {}
}

export class AddPendingDocumentSuccess implements Action {
  readonly type = ADD_PENDING_DOCUMENT_SUCCESS;
  constructor(public payload: string) {}
}

export class AddPendingDocumentFailure implements Action {
  readonly type = ADD_PENDING_DOCUMENT_FAILURE;
  constructor(public payload: any) {}
}

export type RecurringMatterCorrespondenceActions =
  | ListRecurringMatterCorrespondenceStart
  | ListRecurringMatterCorrespondenceSuccess
  | ListRecurringMatterCorrespondenceFailure
  | RefreshRecurringMatterCorrespondenceStart
  | RefreshRecurringMatterCorrespondenceSuccess
  | RefreshRecurringMatterCorrespondenceFailure
  | SelectRecurringMatterCorrespondenceFolder
  | DeleteRecurringMatterCorrespondenceStart
  | DeleteRecurringMatterCorrespondenceSuccess
  | DeleteRecurringMatterCorrespondenceFailure
  | UnDeleteRecurringMatterCorrespondenceStart
  | UnDeleteRecurringMatterCorrespondenceSuccess
  | UnDeleteRecurringMatterCorrespondenceFailure
  | MoveRecurringMatterCorrespondenceStart
  | MoveRecurringMatterCorrespondenceSuccess
  | MoveRecurringMatterCorrespondenceFailure
  | MoveRecurringMatterCorrespondenceReset
  | RenameRecurringMatterCorrespondenceStart
  | RenameRecurringMatterCorrespondenceSuccess
  | RenameRecurringMatterCorrespondenceFailure
  | ClearRecurringMatterCorrespondence
  | AddPendingPrecedentStart
  | AddPendingPrecedentSuccess
  | AddPendingPrecedentFailure
  | CheckPendingPrecedentName
  | CheckPendingDocumentName
  | AddPendingDocumentStart
  | AddPendingDocumentSuccess
  | AddPendingDocumentFailure;
