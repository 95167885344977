import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/services';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  AddinActionType,
  AddinItem,
  AddinItemWithAppInform,
  AddinWindowType,
  GlobalLeapApp,
  LeapAppGridId,
  LeapAppType,
  ViewLeapApp,
  ViewLeapAppLocationId,
} from '@app/features/matter-addin/models';
import { IMatterCore } from '@app/features/+matter-list/models';
import { FirmDetails, IMenuItem } from '@app/shared/models';
import { AppApiService } from '@app/core/api';
import { LayerOutletName } from '@app/core/models';
import { LEGAL_AID_BILLING_MODE } from '@app/features/accounting/constants';
import { LeapContext } from '@leapdev/leap-host';
import { LeapContextWithMatterContext } from '@app/features/+leaphost/models';

@Injectable()
export class LeapAppService extends BaseService {
  constructor(private _http: HttpClient, private _appApiSvc: AppApiService) {
    super();
  }

  getGlobalLeapApps(): Observable<GlobalLeapApp[]> {
    const url = `${this.siriusPath}/api/leap-app/global-menu`;
    return this._http.get<GlobalLeapApp[]>(url);
  }

  getViewLeapApp(req: {
    viewId: ViewLeapAppLocationId;
    matterTypeId?: string;
    state?: string;
  }): Observable<ViewLeapApp[]> {
    const { viewId, matterTypeId, state } = req;
    let params = new HttpParams();
    if (!!matterTypeId) {
      params = params.set('matterTypeId', matterTypeId);
    }
    if (!!state) {
      params = params.set('state', state);
    }
    const url = `${this.siriusPath}/api/leap-app/view/${viewId}`;
    return this._http.get<ViewLeapApp[]>(url, { params });
  }

  getAddinItem(req: { functionGroupId: string }): Observable<AddinItemWithAppInform> {
    const { functionGroupId } = req;
    const url = `${this.siriusPath}/api/leap-app/app-schema/function-group/${functionGroupId}`;
    return this._http.get<AddinItemWithAppInform>(url);
  }

  checkVisibilityForViewLeapApps(data: { matterCore: IMatterCore; apps: ViewLeapApp[] }): ViewLeapApp[] {
    const { matterCore, apps } = data;
    return apps.map((app) => {
      try {
        const { view } = app;
        const { buttons, grids } = view;
        const filteredButtons = buttons.map((b) => {
          const hidden = !this.checkAddinItemVisibility({ addinItem: b, matterCore });
          if (hidden) {
            return {
              ...b,
              hidden,
            };
          }
          if (b.actionType === AddinActionType.DropDown) {
            const { items } = b;
            const subItems = items.map((i) => {
              const h = !this.checkAddinItemVisibility({ addinItem: i, matterCore });
              return {
                ...i,
                hidden: h,
              };
            });
            return {
              ...b,
              hidden,
              items: subItems,
            };
          }
          return {
            ...b,
            hidden,
          };
        });
        const filteredGrids = grids.map((g) => {
          const { context } = g;
          const filterContext = context.map((c) => {
            const hidden = !this.checkAddinItemVisibility({ addinItem: c, matterCore });
            if (hidden) {
              return {
                ...c,
                hidden,
              };
            }
            if (c.actionType === AddinActionType.ContextHeader) {
              const { items } = c;
              const subItems = items.map((i) => {
                const h = !this.checkAddinItemVisibility({ addinItem: i, matterCore });
                return {
                  ...i,
                  hidden: h,
                };
              });
              return {
                ...c,
                hidden,
                items: subItems,
              };
            }
            return {
              ...c,
              hidden,
            };
          });
          return {
            ...g,
            context: filterContext,
          };
        });
        return { ...app, view: { ...view, buttons: filteredButtons, grids: filteredGrids } } as ViewLeapApp;
      } catch (err) {
        // not display any message about any LEAP app fails to load.
        // the intention is resume all good apps continue to serve.
        return { ...app, view: null };
      }
    });
  }

  checkVisibilityForGlobalLeapApps(data: { firmDetails: FirmDetails; apps: GlobalLeapApp[] }): GlobalLeapApp[] {
    const { firmDetails, apps } = data;

    return apps.map((app) => {
      const { menus } = app;
      const checkedMenus = menus.map((m) => {
        const { items } = m;
        const checkedItems = items.map((i) => {
          const hidden = !this.checkGlobalAddinItemVisibility({ addinItem: i, firmDetails });
          return {
            ...i,
            hidden,
          };
        });
        return {
          ...m,
          items: checkedItems,
        };
      });
      return {
        ...app,
        menus: checkedMenus,
      };
    });
  }

  // windowType prop determines how leap app is opened. windowType is set by user that creates the leap app.

  // if 'web', open the leap app in a new browser tab.
  // when opened in a new tab, there is no appSessionId or other context that can be provided, so there is
  // no link back to leap365. We expect that leap apps designed this way are not reliant on any context
  // and are used more for unrelated pages / apps (e.g. some other lookup service not integrated with LEAP.

  // if 'system', open the leap app in an iframe within current browsing context (proxy router overlay panel).
  // if leap app is a wrapper for leaphost, then additional context will be provided like matterId, appSessionId.

  // comment applies to openViewLeapAppAddin() and openGlobalLeapAppAddin() functions
  openViewLeapAppAddin(
    req: {
      appId: string;
      functionGroupId: string;
      gridId?: LeapAppGridId;
      leapAppLocationId?: ViewLeapAppLocationId;
      payload?: any;
      windowType?: AddinWindowType;
      url?: string;
    },
    appType = LeapAppType.MatterView
  ): void {
    const { appId, functionGroupId, gridId, payload, leapAppLocationId } = req;
    const path = !!gridId
      ? ['leaphost', appType, appId, gridId, functionGroupId]
      : ['leaphost', appType, appId, functionGroupId];

    // we handle the window type logic in leaphost.component.
    this._appApiSvc.navigate({
      path: [{ outlets: { [LayerOutletName.LeapAPP0]: path } }],
      extras: { state: { payload, leapAppLocationId }, skipLocationChange: true },
    });
  }

  openGlobalLeapAppAddin(req: {
    appId: string;
    functionGroupId: string;
    payload?: any;
    windowType?: AddinWindowType;
    url?: string;
  }): void {
    const { appId, functionGroupId, windowType, payload, url } = req;
    // we navigate to the leaphost component first as the component has all the logic to build the url.
    // todo: we should move the build url logic into a service.
    // In this case, we could call the service function to build the url before deciding to open a slide window or a new window tab.
    this._appApiSvc.navigate({
      path: [
        {
          outlets: {
            [LayerOutletName.LeapAPP0]: ['leaphost', LeapAppType.Global, appId, functionGroupId],
            popup: null,
          },
        },
      ],
      extras: { skipLocationChange: true },
    });
  }

  // todo - get windowType added to lsdk action to execute the command directly, currently it will always open the iframe
  openAddin(req: { functionGroupId: string; appId: string; outlet: string }): void {
    const { outlet, functionGroupId, appId } = req;
    this._appApiSvc.navigate({
      path: [{ outlets: { [outlet]: ['leaphost', LeapAppType.MatterView, appId, functionGroupId] } }],
      extras: { skipLocationChange: true },
    });
  }

  getAddinByFunctionalGroupIdFromGlobalLeapApp(req: { app: GlobalLeapApp; functionGroupId: string }): AddinItem | null {
    const { app, functionGroupId } = req;
    for (const menu of app.menus) {
      for (const item of menu.items) {
        if (item.functionGroupId === functionGroupId) {
          return item;
        }
      }
    }
    return null;
  }

  getAddinByFunctionalGroupIdFromViewLeapApp(req: {
    app: ViewLeapApp;
    gridId: LeapAppGridId;
    functionGroupId: string;
  }): AddinItem | null {
    const { app, gridId, functionGroupId } = req;
    if (gridId) {
      const grid = app.view.grids.find((g) => g.gridId === gridId);
      if (!!grid) {
        for (const c of grid.context) {
          if (c.functionGroupId === functionGroupId) {
            return c;
          }
          if (c.actionType === AddinActionType.ContextHeader) {
            for (const i of c.items) {
              if (i.functionGroupId === functionGroupId) {
                return i;
              }
            }
          }
        }
      }
    }

    for (const button of app.view.buttons) {
      if (button.functionGroupId === functionGroupId) {
        return button;
      }
      if (button.actionType === AddinActionType.DropDown) {
        for (const i of button.items) {
          if (i.functionGroupId === functionGroupId) {
            return i;
          }
        }
      }
    }

    return null;
  }

  getAddinByName(req: { apps: ViewLeapApp[]; name: string }): { app: ViewLeapApp; addin: AddinItem } | null {
    const { apps, name } = req;

    for (const app of apps) {
      if (app.view && app.view.buttons) {
        for (const button of app.view.buttons) {
          if (button.actionType === AddinActionType.Button && button.name === name) {
            return { app, addin: button };
          }
          if (button.actionType === AddinActionType.DropDown) {
            for (const i of button.items) {
              if (i.name === name) {
                return { app, addin: i };
              }
            }
          }
        }
      }
    }

    return null;
  }

  buildLeapAppNgxContextMenu(args: {
    leapApps: ViewLeapApp[];
    gridId: LeapAppGridId;
    getLeapAppItemVisibility: (req: { entry: any; addinItem: AddinItem }) => boolean;
    execute: (req: { entry: any; leapApp: ViewLeapApp; contextItem: AddinItem }) => void;
  }): IMenuItem<any>[] {
    const { leapApps, gridId, getLeapAppItemVisibility, execute } = args;
    if (!leapApps || leapApps.length === 0) {
      return [];
    }

    let contextMenu = [];
    for (const app of leapApps) {
      const { view } = app;
      if (!!view && !!view.grids && view.grids.length > 0) {
        const grid = view.grids.find((g) => g.gridId === gridId);

        if (!!grid && !!grid.context && grid.context.length > 0) {
          const menuItems = grid.context
            .map((c) => {
              if (c.hidden !== false) {
                return null;
              }

              if (c.actionType === AddinActionType.ContextHeader) {
                const subMenuItems = c.items
                  .filter((ii) => ii.hidden === false)
                  .map((i) => ({
                      text: () => i.label,
                      execute: (entry) => execute({ entry, leapApp: app, contextItem: i }),
                      visible: (entry) => getLeapAppItemVisibility({ entry, addinItem: i }),
                    }));

                if (subMenuItems.length > 0) {
                  return {
                    text: () => c.label,
                    visible: (entry) => !!subMenuItems.find((i) => !!i.visible(entry)),
                    subMenu: subMenuItems,
                  };
                }

                return null;
              }

              if (c.actionType === AddinActionType.Context) {
                return {
                  text: () => c.label,
                  execute: (entry) => execute({ entry, leapApp: app, contextItem: c }),
                  visible: (entry) => getLeapAppItemVisibility({ entry, addinItem: c }),
                };
              }

              return undefined;
            })
            .filter((i) => !!i);

          if (!!menuItems && menuItems.filter(Boolean).length > 0) {
            contextMenu = [
              ...contextMenu,
              {
                text: () => app.name,
                enabled: () => true,
                visible: () => true,
                subMenu: menuItems,
              },
            ];
          }
        }
      }
    }

    return contextMenu;
  }

  getAddinRequestId(addinUrl: string, payload: LeapContextWithMatterContext): Observable<string> {
    const base64EncodedData = btoa(JSON.stringify(payload));
    const requestUrl = `${this.leapAddinPath}/api/v1/addins`;
    return this._http.post<string>(requestUrl, {
      addinUrl,
      base64EncodedData,
    });
  }

  getAddinForwardUrl(addinUrl: string, payload: LeapContextWithMatterContext): Observable<string> {
    const base64EncodedData = btoa(JSON.stringify(payload));
    const requestUrl = `${this.leapAddinPath}/api/v1/addins/forward`;
    return this._http.post<string>(requestUrl, {
      addinUrl,
      base64EncodedData,
    });
  }

  private checkGlobalAddinItemVisibility(data: { addinItem: AddinItem; firmDetails: FirmDetails }): boolean {
    const { addinItem, firmDetails } = data;

    if (!addinItem.visibility) {
      return true;
    }

    if (!!addinItem.visibility.states && addinItem.visibility.states.length > 0) {
      const visible = !!addinItem.visibility.states.find((s) => s === firmDetails.state);
      if (!visible) {
        return visible;
      }
    }

    return true;
  }

  private checkAddinItemVisibility(data: { addinItem: AddinItem; matterCore: IMatterCore }): boolean {
    const { addinItem, matterCore } = data;

    if (!addinItem.visibility) {
      return true;
    }

    // only Legal App requires this visibility check;
    if (addinItem.visibility.billingModes === LEGAL_AID_BILLING_MODE) {
      // addinItem.visibility.billingModes is the value from LEAP app schema.
      // do not confuse with another billingMode in getAccountingSummaryStatus method.
      return !!(matterCore.accounting.billingMode === addinItem.visibility.billingModes);
    }

    if (!!addinItem.visibility.matterTypes && addinItem.visibility.matterTypes.length > 0) {
      const visible = !!addinItem.visibility.matterTypes.find((t) => t === matterCore.matterTypeId);
      if (!visible) {
        return visible;
      }
    }

    if (!!addinItem.visibility.states && addinItem.visibility.states.length > 0) {
      const visible = !!addinItem.visibility.states.find((s) => s === matterCore.state);
      if (!visible) {
        return visible;
      }
    }

    return true;
  }
}
