import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EmailEffects, reducer, SignatureEffects } from '@app/features/+email/store';
import { AttachmentService, EmailSignatureService } from '@app/features/+email/services';
import { EmailFileuploadService } from './services/email-fileupload/email-fileupload.service';
import { EmailGuard } from '@app/features/+email/guards/email.guard';
import { MatterDetailsStateModule } from '@app/features/+matter-details/matter-details-state.module';
import { MatterListStateModule } from '@app/features/+matter-list/matter-list-state.module';

@NgModule({
  imports: [
    CommonModule,
    MatterListStateModule,
    MatterDetailsStateModule,
    StoreModule.forFeature('email', reducer),
    EffectsModule.forFeature([EmailEffects, SignatureEffects]),
  ],
  declarations: [],
  providers: [AttachmentService, EmailSignatureService, EmailFileuploadService, EmailGuard],
})
export class EmailStateModule {}
