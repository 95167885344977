import { from, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { DexieService, LogService, PlatformService } from '@app/core/services';
import { BaseStorageService } from '@app/shared/services';
import { FeeTimerRecordingDbEntry } from '../../models/fee-timer.model';

@Injectable()
export class FeeTimerRecordStorageService extends BaseStorageService<FeeTimerRecordingDbEntry> {
  constructor(private dexieService: DexieService, private log: LogService, private _platformSvc: PlatformService) {
    super();
    this.log.init('fee-timer-record-storage-service');
    if (this._platformSvc.isBrowser) {
      this.table = this.dexieService.table('timerRecords');
    }
  }

  public getFeeTimerRecord(id: string): Observable<FeeTimerRecordingDbEntry> {
    if (this._platformSvc.isBrowser) {
      return from(this.get(id));
    }
  }

  public addFeeTimerRecord(entry: FeeTimerRecordingDbEntry) {
    if (this._platformSvc.isBrowser) {
      this.add(entry);
    }
  }

  public handleDatabaseError(error: any): any {
    return throwError(() => error);
  }
}
