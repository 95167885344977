import { ICardListEntry, IAddress } from '@app/features/+card/models';

export class CardHelper {
  static processCard(card: ICardListEntry): ICardListEntry {
    if (card) {
      if (card.phoneNumberList === undefined) {
        card.phoneNumberList = [];
      }

      if (card.webAddressList === undefined) {
        card.webAddressList = [];
      }

      if (card.addressList === undefined) {
        card.addressList = [];
      }

      if (!card.defaultAddress) {
        card.defaultAddress = 'Street';
      }

      card.phone = this.first(card.phoneNumberList);
      card.email = this.first(card.webAddressList);
      card.address = card.addressList.find((c) => c.addressType === card.defaultAddress);

      if (!card.address) {
        card.address = this.first(card.addressList); // fallback
      }

      card.suburb = card.address ? card.address.suburb : '';
    }

    return { ...card };
  }

  static getStreetAddress(address: IAddress): string {
    return `${!!address.levelUnitShop ? address.levelUnitShop : ''} ${!!address.number ? address.number : ''} ${
      !!address.street ? address.street : ''
    }, ${!!address.suburb ? address.suburb : ''}, ${!!address.state ? address.state : ''} ${
      !!address.postcode ? address.postcode : ''
    }`;
  }

  static getStreetAddressFromAddressList(card: ICardListEntry): string {
    if (card) {
      const defaultAddress = card.defaultAddress || 'Street';
      const address = card.addressList?.find((c) => c.addressType === defaultAddress);
      if (address) {
        return this.getStreetAddress(address).trimLeft();
      }
    }

    return '';
  }

  private static first(array: any[]) {
    return [].concat(array).shift();
  }
}
