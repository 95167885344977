<form [formGroup]="searchForm" class="x-toolbar-actions" [ngClass]="searchFormClass" role="toolbar">
  <div [ngClass]="{ 'x-input-group': isInputGroup, 'd-flex justify-content-between w-100': !isInputGroup }">
    <ng-content select="[prepend]"></ng-content>

    <sc-input-with-icon-debounce
      [autofocus]="true"
      [delay]="searchDebounceMs"
      [placeholder]="placeholder"
      iconName="magnifier-grid-20"
      formControlName="searchText"
      [isDisabled]="isDisabled"
    >
    </sc-input-with-icon-debounce>
    <ng-content select="[append]"></ng-content>
  </div>
</form>
