import { Action } from '@ngrx/store';
import { CorrespondenceListMetaInfo, ISelectCorrespondencePayload } from '../../../models';

export const LOAD_CORRESPONDENCE_LIST_DB_START = '[Load correspondence list] from db start';
export const LOAD_CORRESPONDENCE_LIST_DB_SUCCESS = '[Load correspondence list] from db succcess';
export const LOAD_CORRESPONDENCE_LIST_DB_FAILURE = '[Load correspondence list] from db failure';

export const UNLOAD_CORRESPONDENCE_LIST = '[Unload correspondence list] from state';

export const CORRESPONDENCE_LIST_SAVE_DB_START = '[Correspondence list save to db] start';
export const CORRESPONDENCE_LIST_SAVE_DB_SUCCESS = '[Correspondence list save to db] succcess';
export const CORRESPONDENCE_LIST_SAVE_DB_FAILURE = '[Correspondence list save to db] failure';

export const SETUP_SELECT_CORRESPONDENCE_PAYLOAD =
  '[Correspondence] setup the payload for SELECT_CORRESPONDENCE payload';
export const SELECT_CORRESPONDENCE = '[Correspondence] selected';
export const SELECT_CORRESPONDENCE_UPDATE = '[Correspondence] selected update';

export const CLEAR_CORRESPONDENCE_LIST = '[Correspondence list] clear';

export const SHOW_NOTIFICATION_CORRESPONDENCE =
  '[Correspondence list] show the related correspondence from DocumentNotification';

export class LoadCorrespondenceListDbStart implements Action {
  readonly type = LOAD_CORRESPONDENCE_LIST_DB_START;
  constructor(public payload: string) {}
}

export class LoadCorrespondenceListDbSuccess implements Action {
  readonly type = LOAD_CORRESPONDENCE_LIST_DB_SUCCESS;
  constructor(public payload: CorrespondenceListMetaInfo) {}
}

export class LoadCorrespondenceListDbFailure implements Action {
  readonly type = LOAD_CORRESPONDENCE_LIST_DB_FAILURE;
  constructor(public payload: string) {}
}

export class UnloadCorrespondenceList implements Action {
  readonly type = UNLOAD_CORRESPONDENCE_LIST;
  constructor(public payload: any) {}
}

export class CorrespondenceListSaveDbStart implements Action {
  readonly type = CORRESPONDENCE_LIST_SAVE_DB_START;
  constructor(public payload: Partial<CorrespondenceListMetaInfo>) {}
}

export class CorrespondenceListSaveDbSuccess implements Action {
  readonly type = CORRESPONDENCE_LIST_SAVE_DB_SUCCESS;
  constructor(public payload: string) {}
}

export class CorrespondenceListSaveDbFailure implements Action {
  readonly type = CORRESPONDENCE_LIST_SAVE_DB_FAILURE;
  constructor(public payload: string) {}
}

export class SetupSelectCorrespondencePayload implements Action {
  readonly type = SETUP_SELECT_CORRESPONDENCE_PAYLOAD;
  constructor(public payload: ISelectCorrespondencePayload) {}
}

export class SelectCorrespondence implements Action {
  readonly type = SELECT_CORRESPONDENCE;
  constructor(public payload: ISelectCorrespondencePayload) {}
}

export class SelectCorrespondenceUpdate implements Action {
  readonly type = SELECT_CORRESPONDENCE_UPDATE;
  constructor(public payload: ISelectCorrespondencePayload) {}
}

export class ClearCorrespondenceList implements Action {
  readonly type = CLEAR_CORRESPONDENCE_LIST;
  constructor(public payload: any) {}
}

export class ShowNotificationCorrespondence implements Action {
  readonly type = SHOW_NOTIFICATION_CORRESPONDENCE;

  constructor(public payload: any) {}
}

export type FolderDbActions =
  | LoadCorrespondenceListDbStart
  | LoadCorrespondenceListDbSuccess
  | LoadCorrespondenceListDbFailure
  | UnloadCorrespondenceList
  | CorrespondenceListSaveDbStart
  | CorrespondenceListSaveDbSuccess
  | CorrespondenceListSaveDbFailure
  | SetupSelectCorrespondencePayload
  | SelectCorrespondence
  | SelectCorrespondenceUpdate
  | ClearCorrespondenceList
  | ShowNotificationCorrespondence;
