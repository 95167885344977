import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}
  transform(source: string, sourceType: DomSourceType): SafeValue {
    switch (sourceType) {
      case 'resourceUrl':
        return this._sanitizer.bypassSecurityTrustResourceUrl(source);
      case 'url':
        return this._sanitizer.bypassSecurityTrustUrl(source);
      case 'script':
        return this._sanitizer.bypassSecurityTrustScript(source);
      case 'style':
        return this._sanitizer.bypassSecurityTrustStyle(source);
      case 'html':
      default:
        return this._sanitizer.bypassSecurityTrustHtml(source);
    }
  }
}

type DomSourceType = 'html' | 'style' | 'script' | 'url' | 'resourceUrl';
