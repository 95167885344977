export * from '@server/modules/accounting/constants';

export const externalPlatform = {
  Xero: 1,
  MYOB: 2,
  Quickbooks: 3,
};

export const LEGAL_AID_BILLING_MODE = 218;

export enum ETrustTransactionType {
  TrustPayment = 1,
  TrustJournal = 2,
  TrustReceipt = 3,
  TrustPaymentRequest = -888,
  TrustAnticipatedDeposit = -123,
}

export enum TransactionType {
  Deposit = 1,
  Withdrawal = 2,
  Interest = 3, // only applicable to Investment
  BankChargesOrOtherFees = 4, // only applicable to Investment
}

export const InvestmentTransactionTypeOptions: { value: TransactionType; label: string }[] = [
  { value: TransactionType.Deposit, label: 'Deposit (Receipt)' },
  { value: TransactionType.Withdrawal, label: 'Withdrawal' },
  { value: TransactionType.Interest, label: 'Interest' },
  { value: TransactionType.BankChargesOrOtherFees, label: 'Bank Charges/Other Fees' },
];

export const PowerMoneyTransactionTypeOptions: { value: TransactionType; label: string }[] = [
  { value: TransactionType.Deposit, label: 'Deposit (Receipt)' },
  { value: TransactionType.Withdrawal, label: 'Withdrawal' },
];

export enum PaymentTypeId {
  Wire = 0,
  Cheque = 201,
  CashiersCheque = 202,
  Cash = 300,
  CreditCard = 400,
}
