<div class="x-toolbar-actions">
  <div class="btn-toolbar ml-auto">
    <sc-dropdown-with-icon-v2
      *ngIf="!hideLayoutBtn; else layoutTextBlock"
      [options]="invoiceLayoutList"
      [ngModel]="selectedLayoutGuid"
      [disabled]="statusIsFinal"
      (ngModelChange)="handleSelectedLayoutGuidChange($event)"
      bindingLabelProperty="layoutName"
      bindingProperty="layoutGUID"
      wrapperClass="btn-group btn-group-sm"
    ></sc-dropdown-with-icon-v2>

    <ng-template #layoutTextBlock>
      <h6 class="badge badge-primary mb-0">{{ selectedLayoutName }}</h6>
    </ng-template>
  </div>

  <div class="btn-toolbar ml-auto">
    <sc-dropdown-with-icon-v2
      *ngIf="!hideApprovalStatusBtn; else approvalStatusTextBlock"
      [options]="invoiceStatusList"
      [ngModel]="invoiceApprovalStatus"
      (ngModelChange)="handleInvoiceApprovalStatusChange($event)"
      iconClass="sort"
      bindingLabelProperty="name"
      bindingProperty="value"
      wrapperClass="btn-group btn-group-sm"
    ></sc-dropdown-with-icon-v2>

    <ng-template #approvalStatusTextBlock>
      <h6 class="badge badge-primary mb-0">{{ selectedApprovalStatusName }}</h6>
    </ng-template>
  </div>

  <div class="btn-toolbar me-auto" *ngIf="!hideAlterFeeBtn">
    <sc-btn
      btnType="default"
      btnSize="xs"
      (btnClicked)="alterFees()"
      angulartics2On
      [disabled]="statusIsFinal"
      [angularticsCategory]="getAnalyticsCategory"
      angularticsAction="Click Alter Fees"
      >Alter Fees</sc-btn
    >
  </div>
  <ng-content></ng-content>
</div>
