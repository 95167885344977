import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IInvoiceLayout, InvoiceApprovalStatus } from '@app/features/+invoice/models';
import { AnalyticsCategories } from '@app/core/constants/analytics.constant';

@Component({
  selector: 'sc-invoice-toolbar-v2',
  templateUrl: './invoice-toolbar-v2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceToolbarV2Component implements OnInit {
  @Input() invoiceApprovalStatus: InvoiceApprovalStatus;
  @Input() invoiceLayoutList: IInvoiceLayout[];
  @Input() selectedLayoutGuid: string;
  @Input() hideLayoutBtn: boolean;
  @Input() hideApprovalStatusBtn: boolean;
  @Input() hideAlterFeeBtn: boolean;

  @Output() configAlterFees = new EventEmitter<any>();
  @Output() selectedLayoutGuidChange = new EventEmitter<string>();
  @Output() invoiceApprovalStatusChange = new EventEmitter<InvoiceApprovalStatus>();

  statusIsFinal: boolean;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  get invoiceStatusList(): Array<{ value: InvoiceApprovalStatus; name: string }> {
    return [
      { value: InvoiceApprovalStatus.DraftUnapproved, name: 'Draft - Unapproved' },
      { value: InvoiceApprovalStatus.DraftApproved, name: 'Draft - Approved' },
      { value: InvoiceApprovalStatus.Final, name: 'Final - Print/Sent' },
    ];
  }

  get selectedLayoutName(): string {
    const selectedLayout = this.invoiceLayoutList?.find((l) => l.layoutGUID === this.selectedLayoutGuid);
    return !!selectedLayout ? selectedLayout.layoutName : '';
  }

  get selectedApprovalStatusName(): string {
    const selectedApprovalStatus = this.invoiceStatusList?.find((s) => s.value === this.invoiceApprovalStatus);
    return !!selectedApprovalStatus ? selectedApprovalStatus.name : '';
  }

  get getAnalyticsCategory(): string {
    return AnalyticsCategories.InvoiceForm;
  }

  public alterFees(): void {
    this.configAlterFees.emit(null);
    this.cdr.markForCheck();
  }

  public handleSelectedLayoutGuidChange(guid: string): void {
    this.selectedLayoutGuidChange.emit(guid);
    this.cdr.markForCheck();
  }

  public handleInvoiceApprovalStatusChange(status: InvoiceApprovalStatus): void {
    this.statusIsFinal = status === InvoiceApprovalStatus.Final;
    this.invoiceApprovalStatusChange.emit(status);
    this.cdr.markForCheck();
  }
}
