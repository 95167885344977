import { Component, HostBinding } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { isFunction } from '@server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-percentage-cell',
  templateUrl: './percentage-cell.component.html',
})
export class PercentageCellComponent implements AgRendererComponent {
  percentage: number;
  isHidden: boolean;

  constructor() {}

  @HostBinding('class')
  classes = 'ag-content-label font-weight-bold text-grey';
  agInit(params: any): void {
    this.percentage =
      isFunction(params.getPercentage) ? params.getPercentage(params.data) || 0 : params.value || 0;
    if (isFunction(params.isHidden)) {
      this.isHidden = params.isHidden();
    }
  }

  refresh(params: any): boolean {
    this.percentage =
      isFunction(params.getPercentage)
        ? params.getPercentage(params.data, params) || 0
        : params.value || 0;
    return true;
  }
}
