import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as actions from '@app/core/store/actions/toastr.action';
import { IToastrRenderer } from '@app/core/models';

export interface State extends EntityState<IToastrRenderer> {
  error: any;
}

export const selectId = (item: IToastrRenderer): number => item.toastrId;

export const adapter: EntityAdapter<any> = createEntityAdapter<IToastrRenderer>({
  selectId,
  sortComparer: false,
});

export const INITIAL_STATE: State = adapter.getInitialState({
  error: null,
});

export const reducer = (state: State = INITIAL_STATE, { type, payload }: actions.ToastrActions): State => {
  switch (type) {
    case actions.SHOW_TOASTR: {
      return adapter.addOne(payload, state);
    }

    case actions.HIDE_TOASTR: {
      return adapter.removeOne(payload, state);
    }

    case actions.CLEAR_ALL_TOASTR: {
      return adapter.removeAll(state);
    }

    case actions.UPDATE_TOASTR_RENDERER_SUCCESS: {
      return adapter.upsertOne(payload, state);
    }

    case actions.UPDATE_TOASTR_RENDERER_FAILURE: {
      return {
        ...state,
        error: payload,
      };
    }

    default:
      return state;
  }
};
