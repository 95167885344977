import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../store/reducers';
import * as appActions from '../store/actions/app.action';
import * as routerActions from '../store/actions/router.action';
import * as sidebarActions from '../store/actions/sidebar.action';
import * as matterAddinActions from '@app/features/matter-addin/store/action/matter-addin.action';
import * as supportActions from '@app/core/store/actions/support-request.action';
import { IInvoiceItemCore } from '@app/features/+invoice/models';

@Injectable({
  providedIn: 'root',
})
export class AppApiService {
  constructor(private _store: Store<AppState>) {}

  toggleAside(value: boolean): void {
    this._store.dispatch(new appActions.SaveUIPreference({ asideExpanded: value }));
  }

  /** Details Correspondence APIs **/
  newLetter(query?: any): void {
    // this._store.dispatch(new sidebarActions.SidebarNewLetter(null));
    this.navigate({ path: [{ outlets: { popup: ['letter'] } }], query });
  }

  newCallbackRequest(query?: any): void {
    this.navigate({
      path: [{ outlets: { popup: ['teams', 'callback'] } }],
      query,
      extras: { skipLocationChange: true },
    });
  }

  newCallRequest(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['teams', 'call'] } }], query, extras: { skipLocationChange: true } });
  }

  newEmail(query?: any, outlet?: 'popup' | 'overlay'): void {
    const ol = outlet || 'popup';
    this.navigate({ path: [{ outlets: { [ol]: ['email'] } }], query, extras: { skipLocationChange: true } });
  }

  formsAndPrecedents(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['precedent'] } }], query });
  }

  customPrecedents(matterTypeId: string, selectedFolderId: string): void {
    this.navigate({
      path: [{ outlets: { popup: ['precedent'], selector: ['custom-precedent'] } }],
      query: { matterTypeId, selectedFolderId },
      extras: { queryParamsHandling: 'merge' },
    });
  }

  newComment(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['comment'] } }], query, extras: { skipLocationChange: true } });
  }

  viewComment(commentId: string, isRecurringMatter = false, matterNumber?: string): void {
    if (!!matterNumber) {
      this.navigate({
        path: [{ outlets: { primary: ['matters', matterNumber], popup: ['comment'] } }],
        query: { commentId },
        extras: { skipLocationChange: true },
      });
    } else {
      this.navigate({
        path: [{ outlets: { popup: ['comment'] } }],
        query: { commentId, isRecurringMatter },
        extras: { skipLocationChange: true },
      });
    }
  }

  newUpload(): void {
    this._store.dispatch(new sidebarActions.SidebarNewUpload(null));
  }

  newFolder(): void {
    this._store.dispatch(new sidebarActions.SidebarNewFolder(null));
  }

  newDocument(): void {
    this._store.dispatch(new sidebarActions.SidebarNewDocument(null));
  }

  newPendingPrecedent(): void {
    const query = { isRecurringMatter: true };
    this.navigate({ path: [{ outlets: { popup: ['document'] } }], query });
  }

  createPdf(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['createpdf'] } }], query });
  }

  /** End - Details Correspondence APIs **/

  /** Diary APIs **/
  criticalDate(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['critical-date'] } }], query });
  }

  newAppointment(): void {
    this.navigate({ path: [{ outlets: { popup: ['appointment'] } }] });
  }

  newTask(): void {
    this.navigate({ path: [{ outlets: { popup: ['task'] } }] });
  }

  linkUser(query?: any): void {
    this.navigate({ path: [{ outlets: { selector: ['link-account'] } }], query });
  }

  reauthenticateUser(query?: any): void {
    this.navigate({ path: [{ outlets: { selector: ['reauthenticate-account'] } }], query });
  }

  reauthenticateUserFromNewWin(query?: any): void {
    this.navigate({ path: ['new-win/reauthenticate-account'], query });
  }

  selectTasks(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['tasklist'] } }], query });
  }

  selectTaskCode() {
    this.navigate({ path: [{ outlets: { selector: ['task-code-list'] } }] });
  }

  /** End - Diary APIs **/

  /** Time Fee APIs **/
  newTimeEntry(query?: any, dataFromLeapApp = {}): void {
    this._store.dispatch(new matterAddinActions.OpenMatterAddinTimeEntry({ ...query, data: dataFromLeapApp }));
  }

  newNonAddinTimeEntry(query?: any, dataFromLeapApp = {}): void {
    this.navigate({ path: [{ outlets: { popup: ['time'] } }], query, extras: { state: { payload: dataFromLeapApp } } });
  }

  viewTimeEntry(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['time', 'view'] } }], query });
  }

  readonlyTimeEntry(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['time', 'readonly'] } }], query });
  }

  newFee(query?: any, dataFromLeapApp = {}): void {
    this.navigate({ path: [{ outlets: { popup: ['fee'] } }], query, extras: { state: { payload: dataFromLeapApp } } });
  }

  viewFee(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['fee', 'view'] } }], query });
  }

  readonlyFee(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['fee', 'readonly'] } }], query });
  }

  /** Anticipated Payment APIs **/
  newAnticipated(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['anticipated-payment'] } }], query });
  }

  payAnticipated(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['pay-anticipated'] } }], query });
  }

  /** End - Anticipated Payment APIs **/

  /** Office Payment APIs **/
  newOfficePayment(query?: any, dataFromLeapApp = {}): void {
    this.navigate({
      path: [{ outlets: { popup: ['office-payment'] } }],
      query,
      extras: { state: { payload: dataFromLeapApp } },
    });
  }

  viewOfficePayment(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['office-payment', 'view'] } }], query });
  }

  viewPaymentRequest(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['payment-request', 'view'] } }], query });
  }

  reverseOfficePayment(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['office-payment', 'reverse'] } }], query });
  }

  newOfficePaymentRequest(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['payment-request'] } }], query });
  }

  /** End - Office Payment APIs **/

  /** Office Receipt APIs **/
  newOfficeReceipt(query?: any, dataFromLeapApp = {}): void {
    this.navigate({
      path: [{ outlets: { popup: ['office-receipt'] } }],
      query,
      extras: { state: { payload: dataFromLeapApp } },
    });
  }

  viewOfficeReceipt(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['office-receipt', 'view'] } }], query });
  }

  reverseOfficeReceipt(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['office-receipt', 'reverse'] } }], query });
  }

  /** End- Office Receipt APIs **/

  /** Office Journal APIs **/
  newOfficeJournal(query?: any, dataFromLeapApp = {}): void {
    this.navigate({
      path: [{ outlets: { popup: ['office-journal'] } }],
      query,
      extras: { state: { payload: dataFromLeapApp } },
    });
  }

  viewOfficeJournal(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['office-journal', 'view'] } }], query });
  }

  reverseOfficeJournal(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['office-journal', 'reverse'] } }], query });
  }

  /** End- Office Journal APIs **/

  /** Credit APIs **/

  newCreditReceipt(input?: any): void {
    const query: any = { ...input, ...{ isCredit: true } };
    this.navigate({ path: [{ outlets: { popup: ['office-receipt'] } }], query });
  }

  viewCreditReceipt(input?: any): void {
    const query: any = { ...input, ...{ isCredit: true } };
    this.navigate({ path: [{ outlets: { popup: ['office-receipt', 'view'] } }], query });
  }

  reverseCreditReceipt(input?: any): void {
    const query: any = { ...input, ...{ isCredit: true } };
    this.navigate({ path: [{ outlets: { popup: ['office-receipt', 'reverse'] } }], query });
  }

  newCreditJournal(): void {
    this.navigate({ path: [{ outlets: { popup: ['credit-journal'] } }] });
  }

  viewCreditJournal(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['credit-journal', 'view'] } }], query });
  }

  reverseCreditJournal(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['credit-journal', 'reverse'] } }], query });
  }

  /**
   * show popup for applyCreditToInvoice form
   * */
  applyCreditToInvoice() {
    this.navigate({ path: [{ outlets: { popup: ['apply-credit'] } }] });
  }

  /**
   * show popup for viewing a specific applyCreditToInvoice transaction
   *
   * @param transactionId
   * */
  viewApplyCreditToInvoice(transactionId: string) {
    this.navigate({ path: [{ outlets: { popup: ['apply-credit'] } }], query: { transactionId } });
  }

  /**
   * show popup for viewing a specific applyCreditToInvoice transaction reverse inform
   *
   * @param transactionId
   * */
  viewApplyCreditToInvoiceReverse(transactionId: string) {
    this.navigate({ path: [{ outlets: { popup: ['apply-credit'] } }], query: { transactionId, reverseMode: true } });
  }

  /**
   * Show popup for creditRefund form
   * */
  creditRefund() {
    this.navigate({ path: [{ outlets: { popup: ['credit-refund'] } }] });
  }

  /**
   * show popup for viewing a specific creditRefund transaction
   *
   * @param creditRefundId
   * */
  viewCreditRefund(creditRefundId: string) {
    this.navigate({ path: [{ outlets: { popup: ['credit-refund'] } }], query: { creditRefundId } });
  }

  /**
   * show popup for viewing a specific creditRefund transaction reverse inform
   *
   * @param creditRefundId
   * */
  viewCreditRefundReverse(creditRefundId: string) {
    this.navigate({ path: [{ outlets: { popup: ['credit-refund'] } }], query: { creditRefundId, reverseMode: true } });
  }

  /**
   * show popup for adjustInvoice form
   *
   * @param invoiceId
   * */
  adjustInvoice(invoiceId: string): void {
    this.navigate({ path: [{ outlets: { popup: ['adjust-invoice'] } }], query: { invoiceId } });
  }

  /**
   * show popup for viewing a specific invoiceAdjustment transaction
   *
   * @param adjustmentId
   * */
  viewInvoiceAdjustment(adjustmentId: string): void {
    this.navigate({ path: [{ outlets: { popup: ['adjust-invoice'] } }], query: { adjustmentId } });
  }

  /**
   * show popup for viewing a specific applyCreditToInvoice transaction reverse inform
   *
   * @param adjustmentId
   * */
  viewInvoiceAdjustmentReverse(adjustmentId: string) {
    this.navigate({ path: [{ outlets: { popup: ['adjust-invoice'] } }], query: { adjustmentId, reverseMode: true } });
  }

  /**
   * show popup for fee allocation form
   *
   * @param invoiceId
   * */
  feeAllocation(invoiceId: string): void {
    this.navigate({ path: [{ outlets: { popup: ['fee-allocation'] } }], query: { invoiceId } });
  }

  feeAllocationsLedger(invoiceId: string): void {
    this.navigate({
      path: [{ outlets: { popup: ['invoice'], selector: ['fee-allocation', 'fee-allocations'] } }],
      query: { invoiceId },
    });
  }

  viewFeeAllocation(adjustmentId: string): void {
    this.navigate({
      path: [
        {
          outlets: { popup: ['invoice'], selector: ['fee-allocation', 'fee-allocations'], overlay: ['fee-allocation'] },
        },
      ],
      query: { adjustmentId },
    });
  }

  viewFeeAllocationReverse(adjustmentId: string): void {
    this.navigate({
      path: [
        {
          outlets: { popup: ['invoice'], selector: ['fee-allocation', 'fee-allocations'], overlay: ['fee-allocation'] },
        },
      ],
      query: { adjustmentId, reverseMode: true },
    });
  }

  /** End- Credit APIs **/

  /** Cost Recovery APIs **/
  newCostRecovery(recoveryId?: string, dataFromLeapApp?: any): void {
    const q = recoveryId ? { recoveryId } : {};
    this.navigate({
      path: [{ outlets: { popup: ['cost-recovery'] } }],
      query: q,
      extras: { state: { payload: dataFromLeapApp } },
    });
  }

  readonlyCostRecovery(recoveryId: string): void {
    this.navigate({
      path: [{ outlets: { popup: ['cost-recovery', 'readonly'] } }],
      query: { recoveryId },
    });
  }

  /** End - Cost Recovery APIs **/

  /** Trust APIs **/
  newTrustPayment(query?: any, dataFromLeapApp = {}): void {
    this.navigate({
      path: [{ outlets: { popup: ['trust-payment'] } }],
      query,
      extras: { state: { payload: dataFromLeapApp } },
    });
  }

  viewTrustPayment(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['trust-payment'] } }], query });
  }

  newTrustPaymentRequest(query?: any, dataFromLeapApp = {}): void {
    this.navigate({
      path: [{ outlets: { popup: ['trust-payment-request'] } }],
      query,
      extras: { state: { payload: dataFromLeapApp } },
    });
  }

  viewTrustPaymentRequest(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['trust-payment-request'] } }], query });
  }

  newTrustReceipt(query?: any, dataFromLeapApp?: any): void {
    this.navigate({
      path: [{ outlets: { popup: ['trust-receipt'] } }],
      query,
      extras: { state: { payload: dataFromLeapApp } },
    });
  }

  viewTrustReceipt(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['trust-receipt', 'view'] } }], query });
  }

  reverseTrustReceipt(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['trust-receipt', 'reverse'] } }], query });
  }

  newTrustJournal(dataFromLeapApp = {}): void {
    this.navigate({
      path: [{ outlets: { popup: ['trust-journal'] } }],
      extras: { state: { payload: dataFromLeapApp } },
    });
  }

  viewTrustJournal(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['trust-journal', 'view'] } }], query });
  }

  reverseTrustJournal(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['trust-journal', 'reverse'] } }], query });
  }

  trustToOffice(query?: any, dataFromLeapApp = {}): void {
    this.navigate({
      path: [{ outlets: { popup: ['trust-to-office'], selector: null, overlay: null } }],
      query,
      extras: { state: { payload: dataFromLeapApp } },
    });
  }

  trustToOfficeError(): void {
    this.navigate({ path: [{ outlets: { popup: ['trust-to-office', 'error'] } }] });
  }

  investmentAccount(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['investment-account'] } }], query });
  }

  investmentTransaction(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['investment-transaction'] } }], query });
  }

  powerMoneyAccount(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['power-money-account'] } }], query });
  }

  powerMoneyTransaction(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['power-money-transaction'] } }], query });
  }

  printCheques(): void {
    this.navigate({ path: [{ outlets: { popup: ['print-cheques'], selector: null, overlay: null } }] });
  }

  printChequesError(): void {
    this.navigate({ path: [{ outlets: { popup: ['print-cheques', 'error'], selector: null, overlay: null } }] });
  }

  printChequesSettings(): void {
    this.navigate({
      path: [{ outlets: { popup: ['print-cheques'], selector: ['print-cheques', 'settings'], overlay: null } }],
    });
  }

  generalLedger(): void {
    this.navigate({
      path: [{ outlets: { popup: ['general-ledger', 'general-ledger'], selector: null, overlay: null } }],
    });
  }

  purchaseOrders(): void {
    this.navigate({
      path: [{ outlets: { popup: ['purchase-orders', 'purchase-orders'], selector: null, overlay: null } }],
    });
  }

  /**
   * Navigate to trust bank account register page
   * */
  bankAccountRegister(): void {
    this.navigate({
      path: [{ outlets: { primary: ['trust-bank-register'], popup: null, selector: null, overlay: null } }],
    });
  }

  shareTrustAccount(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['document-share', 'trust-account'] } }], query });
  }

  /** End - Trust APIs **/

  /** Protected Trust Fund APIs **/

  newProtectedFund(query?: { matterId?: string; trustAccountGuid?: string }): void {
    this.navigate({ path: [{ outlets: { popup: ['protected-fund-details'] } }], query });
  }

  viewProtectedFund(guid: string): void {
    this.navigate({ path: [{ outlets: { popup: ['protected-fund-details'] } }], query: { protectedFundGuid: guid } });
  }

  selectOpenInvoice(): void {
    this.navigate({
      path: [{ outlets: { selectorDetail: ['protected-fund-open-invoices'] } }],
      extras: { skipLocationChange: true },
    });
  }

  /** Protected Trust Fund APIs - End **/

  /** Invoice APIs **/
  newInvoice(dataFromLeapApp?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['invoice'] } }], extras: { state: { payload: dataFromLeapApp } } });
  }

  newInvoiceAdvanced(): void {
    this.navigate({ path: [{ outlets: { popup: ['invoice', 'advanced-options'] } }] });
  }

  viewInvoice(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['invoice'] } }], query });
  }

  showInvoiceItemProperties(invoiceItemGUID: string): void {
    this.navigate({
      path: [{ outlets: { popup: ['invoice'], selector: ['invoice', 'properties'] } }],
      query: { invoiceItemGUID },
      extras: { skipLocationChange: true },
    });
  }

  showInvoiceTaxSplit(): void {
    this.navigate({
      path: [{ outlets: { popup: ['invoice'], selector: ['invoice', 'tax-split'] } }],
      extras: { skipLocationChange: true },
    });
  }

  showInvoiceTaxSplitInSelectorDetail(query: {
    invoiceItemGuid: string;
    taxCodeGuid: string;
    amountExTax: number;
  }): void {
    this.navigate({
      path: [
        {
          outlets: {
            popup: ['invoice'],
            selectorDetail: ['invoice', 'properties', 'tax-split'],
          },
        },
      ],
      query,
      extras: { skipLocationChange: true, queryParamsHandling: 'merge' },
    });
  }

  showInvoiceAlterFees(): void {
    this.navigate({
      path: [{ outlets: { popup: ['invoice'], selector: ['invoice', 'alter-fees'] } }],
      extras: { skipLocationChange: true },
    });
  }

  showInvoiceSplit(): void {
    this.navigate({
      path: [{ outlets: { popup: ['invoice'], selector: ['invoice', 'split'] } }],
      extras: { skipLocationChange: true },
    });
  }
  /** End - Invoice APIs **/

  /** Accounting APIs **/
  preBilling(): void {}

  trustBankDeposit(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['trust-bank-deposit'], selector: null, overlay: null } }], query });
  }

  /**
   * show popup for viewing a specific trust bank deposit transaction
   *
   * @param depositId
   * */
  viewTrustBankDeposit(depositId): void {
    this.navigate({ path: [{ outlets: { popup: ['trust-bank-deposit', 'view'] } }], query: { depositId } });
  }

  /**
   * show popup for viewing a specific trust bank deposit transaction
   *
   * @param depositId
   * */
  reverseTrustBankDeposit(depositId): void {
    this.navigate({ path: [{ outlets: { popup: ['trust-bank-deposit', 'reverse'] } }], query: { depositId } });
  }

  trustBankDepositError(): void {
    this.navigate({ path: [{ outlets: { popup: ['trust-bank-deposit', 'error'] } }] });
  }

  officeBankDeposit(): void {
    this.navigate({ path: [{ outlets: { popup: ['office-bank-deposit'], selector: null, overlay: null } }] });
  }

  officeSearchTransaction(): void {
    this.navigate({
      path: [{ outlets: { popupGlobal: ['office-search-transaction'], popup: null, selector: null, overlay: null } }],
    });
  }

  /**
   * show popup for viewing a specific trust bank deposit transaction
   *
   * @param depositId
   * */
  viewOfficeBankDeposit(depositId): void {
    this.navigate({ path: [{ outlets: { popup: ['office-bank-deposit', 'view'] } }], query: { depositId } });
  }

  /**
   * show popup for viewing a specific trust bank deposit transaction
   *
   * @param depositId
   * */
  reverseOfficeBankDeposit(depositId): void {
    this.navigate({ path: [{ outlets: { popup: ['office-bank-deposit', 'reverse'] } }], query: { depositId } });
  }

  officeBankDepositError(): void {
    this.navigate({ path: [{ outlets: { popup: ['office-bank-deposit', 'error'] } }] });
  }

  officeBulkInvoice(): void {
    this.navigate({ path: [{ outlets: { popup: ['office-bulk-invoice'], selector: null, overlay: null } }] });
  }

  officeOverdueInvoices(): void {
    this.navigate({
      path: [{ outlets: { popupGlobal: ['overdue-invoices'], popup: null, selector: null, overlay: null } }],
    });
  }

  officeOverdueInvoicesErrorLog(): void {
    this.navigate({
      path: [{ outlets: { popupGlobal: null, overlay: ['overdue-invoices', 'error-log'] } }],
    });
  }

  officeChangeInvoiceStatus(): void {
    this.navigate({
      path: [{ outlets: { popupGlobal: ['change-invoice-status'], popup: null, selector: null, overlay: null } }],
    });
  }

  officeChangeInvoiceStatusErrorLog(): void {
    this.navigate({
      path: [{ outlets: { popupGlobal: null, overlay: ['change-invoice-status', 'error-log'] } }],
    });
  }

  shareOfficeAccount(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['document-share', 'office-account'] } }], query });
  }

  accountingBatchDetails(): void {
    this.navigate({
      path: [{ outlets: { overlay: ['accounting-batch'] } }],
      extras: { skipLocationChange: true },
    });
  }

  /** End - Accounting APIs **/

  openMatterOptions() {
    this.navigate({ path: [{ outlets: { popup: ['matter-options'] } }] });
  }

  openRecurringMatterOptions() {
    this.navigate({ path: [{ outlets: { popup: ['recurring-matter-options'] } }] });
  }

  matters(): void {
    this.navigate({ path: [{ outlets: { primary: ['matters'], popup: null, selector: null, overlay: null } }] });
  }

  cards(): void {
    this.navigate({ path: [{ outlets: { primary: ['cards'], popup: null, selector: null, overlay: null } }] });
  }

  persons(): void {
    this.navigate({ path: [{ outlets: { primary: ['persons'], popup: null, selector: null, overlay: null } }] });
  }

  viewMatterAddin(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['matter-addin'] } }], query });
  }

  recurringMattersList(query?: any): void {
    this.navigate({ path: [{ outlets: { popup: ['recurring-matters'] } }], query });
  }

  /** Router APIs **/
  navigate(routingOptions: { path: any[]; query?: any; extras?: any }): void {
    this._store.dispatch(new routerActions.Go(routingOptions));
  }

  navigateByUrl(routingOptions: { path: string }): void {
    this._store.dispatch(new routerActions.GoByUrl(routingOptions));
  }

  navigateBack(): void {
    this._store.dispatch(new routerActions.Back());
  }

  navigateForward(): void {
    this._store.dispatch(new routerActions.Forward());
  }

  navigateClear(outlets?: string[]): void {
    this._store.dispatch(new routerActions.Clear({ outlets }));
  }

  clearCurrentModal(excludeQueryParam?: boolean, skipLocationChange?: boolean): void {
    // excludeQueryParam uses to handle some scenario that still include query param after closing the path with skipLocationChange = true;
    // with this value = true, it will ensure the new path will ignore any query param.

    this._store.dispatch(new routerActions.ClearCurrentModal({ excludeQueryParam, skipLocationChange }));
  }

  goToGeneralErrorPage(): void {
    this.navigate({ path: ['error'] });
  }

  /** End - Router APIs **/

  /** Actions to be used in effects */

  newEmailAction(query?: any) {
    return new routerActions.Go({ path: [{ outlets: { popup: ['email'] } }], query });
  }

  /** End Actions to be used in effects */

  /** Support */

  dispatchSupportRequest(data?: any): void {
    this._store.dispatch(new supportActions.SupportRequestInit(data));
  }

  dispatchSupportRequestSuccess(): void {
    this._store.dispatch(new supportActions.SupportRequestSuccessNotification(null));
  }

  supportRequest(): void {
    this.navigate({ path: [{ outlets: { selector: ['support-request'] } }] });
  }

  navigateToModifyRecurringMatter(id: string) {
    this.navigate({ path: [{ outlets: { primary: ['recurring-matters', id], popup: null } }] });
  }

  recurringMatterFee(recurringMatterId: string, isTimed = false, feeGuid?: string): void {
    this.navigate({
      path: [{ outlets: { popup: ['recurring-matter-fee'] } }],
      query: { recurringMatterId, isTimed, feeGuid },
      extras: { queryParamsHandling: 'merge' },
    });
  }

  reucrringMatterCostRecovery(recurringMatterId: string, costRecoveryGuid?: string): void {
    this.navigate({
      path: [{ outlets: { popup: ['recurring-matter-cost-recovery'] } }],
      query: { recurringMatterId, costRecoveryGuid },
      extras: { queryParamsHandling: 'merge' },
    });
  }

  shareViaLawConnect(params: any): void {
    this.navigate({
      path: [{ outlets: { popup: 'lawconnect-share'}}],
      query: { ...params }
    });
  }
}
