import { IPrecedent } from '@app/features/+precedent/models';
import { Action } from '@ngrx/store';

export enum PrecedentListModalActionTypes {
  STORE_PRECEDENT_ENTRIES = '[Precedent List Modal] store precedent entries into state',
  CLEAN_UP = '[Precedent List Modal] clean up precedent list modal state',
}

export class StorePrecedentEntries implements Action {
  readonly type = PrecedentListModalActionTypes.STORE_PRECEDENT_ENTRIES;

  constructor(public payload: { entries: IPrecedent[] }) {}
}

export class CleanUp implements Action {
  readonly type = PrecedentListModalActionTypes.CLEAN_UP;

  constructor(public payload: any) {}
}

export type PrecedentListModalActions = StorePrecedentEntries | CleanUp;
