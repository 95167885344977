import { AppSlice } from '@app/core/store/app.slice';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';
import { LayerOrderMapper, LayerOutletName } from '@app/core/models';
import * as fromRouter from '@ngrx/router-store';
import { getObjValue } from '../../../../../server/modules/shared/functions/common-util.functions';

export const selectRouterState = createFeatureSelector<RouterReducerState>(AppSlice.Router);

export const {
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = fromRouter.getRouterSelectors(selectRouterState);

export const selectCurrentRouteQueryParams = selectQueryParams;
export const selectCurrentRouteData = selectRouteData;
export const selectRouterUrl = selectUrl;

export const selectRouterSnapshot = createSelector(selectRouterState, (state: RouterReducerState) =>
  getObjValue(state, 'state.root')
);

export const selectRouterLayers = createSelector(selectRouterSnapshot, (root: any) => {
  if (!!root && root.children && root.children.length > 0) {
    const allLayers = root.children.filter((child) => child.outlet !== 'primary').map((child) => child.outlet);
    return allLayers.sort((l1, l2) => {
      const l1Value = LayerOrderMapper.get(l1);
      const l2Value = LayerOrderMapper.get(l2);
      return l2Value - l1Value;
    }) as string[];
  } else {
    return [] as string[];
  }
});

export const selectRouterPrimaryRouteOutletPageName = createSelector(selectRouterSnapshot, (root: any) => {
  if (!!root && root.children && root.children.length > 0) {
    const primaryRouteOutlet = root.firstChild;
    return !!primaryRouteOutlet && !!primaryRouteOutlet.firstChild && !!primaryRouteOutlet.firstChild.data
      ? primaryRouteOutlet.firstChild.data.pageName || ''
      : '';
  } else {
    return '';
  }
});

export const selectRouterLeapAppLayers = createSelector(selectRouterLayers, (layers: string[]) =>
  layers.filter((l) => l.includes('leap-app-'))
);

export const selectRouterLeapApp0LayerGaps = createSelector(selectRouterLayers, (layers: string[]) =>
  layers.indexOf(LayerOutletName.LeapAPP0)
);

export const selectRouterLeapApp1LayerGaps = createSelector(selectRouterLayers, (layers: string[]) =>
  layers.indexOf(LayerOutletName.LeapAPP1)
);

export const selectRouterPopupGlobalLayerGaps = createSelector(selectRouterLayers, (layers: string[]) =>
  layers.indexOf(LayerOutletName.PopupGlobal)
);

export const selectRouterPopupLayerGaps = createSelector(selectRouterLayers, (layers: string[]) =>
  layers.indexOf(LayerOutletName.Popup)
);

export const selectRouterSelectorLayerGaps = createSelector(selectRouterLayers, (layers: string[]) =>
  layers.indexOf(LayerOutletName.Selector)
);

export const selectRouterOverlayLayerGaps = createSelector(selectRouterLayers, (layers: string[]) =>
  layers.indexOf(LayerOutletName.Overlay)
);

export const selectRouterSelectorDetailLayerGaps = createSelector(selectRouterLayers, (layers: string[]) =>
  layers.indexOf(LayerOutletName.SelectorDetail)
);
