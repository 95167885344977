import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { State } from '../../+matter-list/store';
import { filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as currentMatterActions from '../../+matter-details/store/actions/current-matter';
import { selectMatterCards } from '../../+matter-details/store/selectors';
import * as folderActions from '@app/features/+correspondence/store/actions/folder';
import { selectCurrentMatter } from '@app/core/store';
import { CorrespondenceFetchMode } from '@app/features/+correspondence/constants';
import { getObjValue } from '@server/modules/shared/functions/common-util.functions';

@Injectable({
  providedIn: 'root',
})
export class EmailGuard  {
  constructor(private _store: Store<State>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const matterNumber = decodeURIComponent(route.params['matterNumber'] || route.queryParams['matterNumber']);
    return this.checkCurrentMatterInStore(matterNumber).pipe(
      switchMap(() => {
        const newWin = getObjValue(route.data, 'newWin');
        return this.loadMatterDetails(newWin);
      })
    );
  }

  // check whether the current matter is loaded in store
  // we wait for the current matter logic to be done in current-matter.guard
  private checkCurrentMatterInStore(matterNumber: string): Observable<boolean> {
    return this._store.pipe(
      select(selectCurrentMatter),
      filter(
        (currentMatter) =>
          !!currentMatter && (currentMatter.fileNumber === matterNumber || currentMatter.matterId === matterNumber)
      ),
      map((currentMatter) => !!currentMatter),
      take(1)
    );
  }

  private loadMatterDetails(newWin: boolean): Observable<boolean> {
    return this._store.pipe(
      select(selectCurrentMatter),
      withLatestFrom(this._store.pipe(select(selectMatterCards)), (currentMatter, matterCards) => ({
        currentMatter,
        matterCards,
      })),
      tap((data) => {
        const { currentMatter, matterCards } = data;

        if (newWin) {
          this._store.dispatch(new folderActions.LoadCorrespondenceListDbStart(currentMatter.matterId));
          this._store.dispatch(
            new folderActions.ListCorrespondenceListStart({
              matterId: currentMatter.matterId,
              fetchMode: CorrespondenceFetchMode.ForceDocuments,
            })
          );
        }

        if ((matterCards || []).length === 0) {
          this._store.dispatch(new currentMatterActions.GetMatterDetailsStart({ matterId: currentMatter.matterId }));
        }
      }),
      map(() => true)
    );
  }
}
