import { Action } from '@ngrx/store';
import { CardListMetaInfo, ECardFilterType, ICardListEntry, ICardListResponseSchema } from '../../../models/card-list';

export enum CardListDbActionTypes {
  CARDS_SAVE_DB_START = '[Card save to db] start',
  CARDS_SAVE_DB_SUCCESS = '[Card save to db] success',
  CARDS_SAVE_DB_FAILURE = '[Card save to db] failure',

  LOAD_CARD_LIST_META_START = '[Load card list meta] start',
  LOAD_CARD_LIST_META_SUCCESS = '[Load card list meta] success',

  CARD_LIST_META_SAVE_DB_START = '[Card list meta save to db] start',
  CARD_LIST_META_SAVE_DB_SUCCESS = '[Card list meta save to db] success',
  CARD_LIST_META_SAVE_DB_FAILURE = '[Card list meta save to db] failure',

  LOAD_SELECTED_CARD_START = '[Load selected card] start',
  LOAD_SELECTED_CARD_SUCCESS = '[Load selected card] success',

  SET_CARD_FILTER_TYPE = '[Card List] set card filter type',
  SET_SELECTED_CARD_FILTER_NAME = '[Card List] set selected card filter name',
}

export class CardSaveDbStart implements Action {
  readonly type = CardListDbActionTypes.CARDS_SAVE_DB_START;

  constructor(public payload: ICardListResponseSchema) {}
}

export class CardSaveDbSuccess implements Action {
  readonly type = CardListDbActionTypes.CARDS_SAVE_DB_SUCCESS;

  constructor(public payload: string) {}
}

export class CardSaveDbFailure implements Action {
  readonly type = CardListDbActionTypes.CARDS_SAVE_DB_FAILURE;

  constructor(public payload: string) {}
}

export class LoadCardListMetaStart implements Action {
  readonly type = CardListDbActionTypes.LOAD_CARD_LIST_META_START;

  constructor(public payload: any) {}
}

export class LoadCardListMetaSuccess implements Action {
  readonly type = CardListDbActionTypes.LOAD_CARD_LIST_META_SUCCESS;

  constructor(public payload: Partial<CardListMetaInfo>) {}
}

export class CardListMetaSaveDbStart implements Action {
  readonly type = CardListDbActionTypes.CARD_LIST_META_SAVE_DB_START;

  constructor(public payload: Partial<CardListMetaInfo>) {}
}

export class CardListMetaSaveDbSuccess implements Action {
  readonly type = CardListDbActionTypes.CARD_LIST_META_SAVE_DB_SUCCESS;

  constructor(public payload: string) {}
}

export class CardListMetaSaveDbFailure implements Action {
  readonly type = CardListDbActionTypes.CARD_LIST_META_SAVE_DB_FAILURE;

  constructor(public payload: any) {}
}

export class LoadSelectedCardStart implements Action {
  readonly type = CardListDbActionTypes.LOAD_SELECTED_CARD_START;

  constructor(public payload: any) {}
}

export class LoadSelectedCardSuccess implements Action {
  readonly type = CardListDbActionTypes.LOAD_SELECTED_CARD_SUCCESS;

  constructor(public payload: any) {}
}

export class SetCardFilterType implements Action {
  readonly type = CardListDbActionTypes.SET_CARD_FILTER_TYPE;

  constructor(public payload: { type: ECardFilterType }) {}
}

export class SetSelectedCardFilterName implements Action {
  readonly type = CardListDbActionTypes.SET_SELECTED_CARD_FILTER_NAME;

  constructor(public payload: { name: string }) {}
}

export type CardListDbActions =
  | CardSaveDbStart
  | CardSaveDbSuccess
  | CardSaveDbFailure
  | LoadCardListMetaStart
  | LoadCardListMetaSuccess
  | CardListMetaSaveDbStart
  | CardListMetaSaveDbSuccess
  | CardListMetaSaveDbFailure
  | LoadSelectedCardStart
  | LoadSelectedCardSuccess
  | SetCardFilterType
  | SetSelectedCardFilterName;
