import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'sc-guide',
  templateUrl: './guide.component.html',
})
export class GuideComponent implements OnInit {
  @Input()
  image: string;
  @Input()
  title: string;
  @Input()
  description: string;

  constructor() {}

  @HostBinding('class.x-guide')
  ngOnInit() {}
}
