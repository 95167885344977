import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectMatterAddins } from '@app/features/matter-addin/store/selector/matter-addin.selector';
import { Addin, AddinButton } from '@app/features/matter-addin/models';
import * as matterAddinActions from '@app/features/matter-addin/store/action';

@Component({
  selector: 'sc-addin-slim-buttons',
  templateUrl: './addin-slim-buttons.component.html',
})
export class AddinSlimButtonsComponent implements OnInit {
  addins$: Observable<Addin[]>;

  constructor(private _store: Store<any>) {}

  ngOnInit() {
    this.setupSubscriptions();
  }

  setupSubscriptions() {
    this.addins$ = this._store
      .pipe(select(selectMatterAddins))
      .pipe(map((addins) => addins?.filter((addin) => addin.Button.length > 0) || []));
  }

  onClicked(button: AddinButton, addin: Addin) {
    const addinItem = { ...(button || { Browser: undefined }) };
    if (addinItem.Browser === undefined) {
      addinItem.Browser = addin.Browser;
    }

    this._store.dispatch(new matterAddinActions.MatterAddinClicked({ addinItem }));
  }
}
