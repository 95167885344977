import { createReducer, on } from '@ngrx/store';
import {
  InitListMattersStart,
  InitListMattersSuccess,
  InitListMattersFailure,
  ListMattersStart,
  ListMattersSuccess,
  ListMattersFailure,
  CloudSearchStart,
  CloudSearchSuccess,
  DeleteMatterStart,
  DeleteMatterSuccess,
  DeleteMatterFailure,
  CreateMatterStart,
  CreateMatterSuccess,
  CreateMatterFailure,
  CreateMatterLoadingStatus,
  ReupdatedMatterDetailEntries,
  MatterSaveDbSuccess,
  MatterListMetaSaveDbSuccess,
  MatterListMetaSaveDbFailure,
  SetMeta,
} from '../actions/matter-list';
import { IMatterListEntry, IMatterListUIInfo } from '../../models';
import { ISortInfo } from '@app/shared/models';
import { AdvancedSearchMode } from '../../constants';

export interface State {
  selectedMatterGuid: string;
  loaded: boolean;
  loading: boolean;
  newMatterSaving: boolean;
  sortInfo: ISortInfo;
  uiInfo: IMatterListUIInfo;
  cloudSearchResults: IMatterListEntry[];
}

export const initialState: State = {
  selectedMatterGuid: '',
  loaded: false,
  loading: false,
  newMatterSaving: false,
  sortInfo: {
    colId: 'fileNumber',
    sort: 'asc',
  },
  uiInfo: {
    showState: false,
    showDeleted: false,
    showClientShortName: true,
    showClientLongName: false,
  },
  cloudSearchResults: [],
};

export const reducer = createReducer(
  initialState,

  // Matter List Actions
  on(InitListMattersStart, (state) => ({
    ...state,
    loading: true,
  })),
  on(InitListMattersSuccess, (state) => ({
    ...state,
    loading: false,
  })),
  on(InitListMattersFailure, (state) => ({
    ...state,
    loading: false,
  })),
  on(ListMattersStart, (state) => ({
    ...state,
    loading: true,
  })),
  on(ListMattersSuccess, (state, { response }) => ({
    ...state,
    loading: false,
    cloudSearchResults: response.data,
  })),
  on(ListMattersFailure, (state) => ({
    ...state,
    loading: false,
  })),

  // Cloud Search Actions
  on(CloudSearchStart, (state) => ({
    ...state,
    loading: true,
  })),
  on(CloudSearchSuccess, (state, { result }) => {
    const docsSearchModes = [AdvancedSearchMode.AdvancedSearch, AdvancedSearchMode.QuickSearch];
    const isDocsSearch = docsSearchModes.includes(result.searchMode);

    // Ensure cloudSearchResults only contains IMatterListEntry[]
    const cloudSearchResults: IMatterListEntry[] = isDocsSearch ? (result.data as IMatterListEntry[]) : [];

    return {
      ...state,
      cloudSearchResults,
      loading: false,
    };
  }),

  // Delete Matter Actions
  on(DeleteMatterStart, (state) => ({
    ...state,
    loading: true,
  })),
  on(DeleteMatterSuccess, (state) => ({
    ...state,
    loading: false,
  })),
  on(DeleteMatterFailure, (state) => ({
    ...state,
    loading: false,
  })),

  // Create Matter Actions
  on(CreateMatterStart, (state) => ({
    ...state,
    loading: true,
    newMatterSaving: true,
  })),
  on(CreateMatterSuccess, (state) => ({
    ...state,
    loading: false,
    newMatterSaving: false,
  })),
  on(CreateMatterFailure, (state) => ({
    ...state,
    loading: false,
    newMatterSaving: false,
  })),
  on(CreateMatterLoadingStatus, (state, { isLoading }) => ({
    ...state,
    loading: isLoading,
  })),

  // Re-update Matter Detail
  on(ReupdatedMatterDetailEntries, (state, { matterEntries, matterTypeId, fileNumber, matterId }) => ({
    ...state,
    cloudSearchResults: state.cloudSearchResults.map((entry) =>
      entry.matterId === matterId
        ? {
            ...entry,
            matterEntries,
            matterTypeId,
            fileNumber,
          }
        : entry
    ),
  })),

  // Matter Save to DB Success
  on(MatterSaveDbSuccess, (state) => ({
    ...state,
    loaded: true,
  })),

  // Matter Meta Save Actions
  on(MatterListMetaSaveDbSuccess, (state) => ({
    ...state,
    loaded: true,
  })),
  on(MatterListMetaSaveDbFailure, (state) => ({
    ...state,
    loaded: false,
  })),

  // Set Meta Information
  on(SetMeta, (state, { meta }) => ({
    ...state,
    ...meta,
  }))
);

// Selectors
export const selectLoading = (state: State) => state.loading;
export const selectLoaded = (state: State) => state.loaded;
export const selectUIInfo = (state: State) => state.uiInfo;
export const selectSortInfo = (state: State) => state.sortInfo;
export const selectCurrentMatterGuid = (state: State) => state.selectedMatterGuid;
export const selectNewMatterSaving = (state: State) => state.newMatterSaving;
export const selectCloudSearchResults = (state: State) => state.cloudSearchResults;
