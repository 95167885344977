import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TimeClockComponent } from './components/time-clock/time-clock.component';
import { FeeTimerStateModule } from '@app/features/fee-timer/fee-timer-state.module';
import { TimeActivityLayoutComponent } from './components/time-activity-layout/time-activity-layout.component';
import { TimeActivityHeaderComponent } from './components/time-activity-header/time-activity-header.component';
import { TimeActivitySearchComponent } from './components/time-activity-search/time-activity-search.component';
import { SharedModule } from '@app/shared/shared.module';
import { ActivityCodeService } from './services/activity-code/activity-code.service';
import { TimeActivityListComponent } from './components/time-activity-list/time-activity-list.component';
import { FeeTimerRecordStorageService } from './services/fee-timer-record/fee-timer-record-storage.service';

@Injectable()
@NgModule({
  imports: [CommonModule, SharedModule, FeeTimerStateModule],
  declarations: [
    TimeClockComponent,
    TimeActivityLayoutComponent,
    TimeActivityHeaderComponent,
    TimeActivitySearchComponent,
    TimeActivityListComponent,
  ],
  exports: [
    TimeClockComponent,
    TimeActivityLayoutComponent,
    TimeActivityHeaderComponent,
    TimeActivitySearchComponent,
    TimeActivityListComponent,
  ],
  providers: [ActivityCodeService, FeeTimerRecordStorageService],
})
export class FeeTimerModule {}
