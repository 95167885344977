<svg
  *ngIf="icon === 'orb'"
  width="100%"
  class="x-icon"
  [ngClass]="size"
  height="100%"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 120 120"
>
  <g transform="rotate(-90 43 -17)">
    <circle cx="0" cy="0" r="8">
      <animateTransform
        id="b"
        attributeName="transform"
        dur="0.2s"
        type="translate"
        values="0 0; 14 0; 0 0"
        begin="0;a.end"
      />
    </circle>
  </g>
  <g transform="rotate(-30 124.962 -145.406)">
    <circle cx="0" cy="0" r="8">
      <animateTransform
        id="c"
        attributeName="transform"
        dur="0.2s"
        type="translate"
        values="0 0; 14 0; 0 0"
        begin="b.end"
      />
    </circle>
  </g>
  <g transform="rotate(30 -98.962 205.406)">
    <circle cx="0" cy="0" r="8">
      <animateTransform
        id="d"
        attributeName="transform"
        dur="0.2s"
        type="translate"
        values="0 0; 14 0; 0 0"
        begin="c.end"
      />
    </circle>
  </g>
  <g transform="rotate(90 -17 77)">
    <circle cx="0" cy="0" r="8">
      <animateTransform
        id="e"
        attributeName="transform"
        dur="0.2s"
        type="translate"
        values="0 0; 14 0; 0 0"
        begin="d.end-0.02s"
      />
    </circle>
  </g>
  <g transform="rotate(150 4.962 42.594)">
    <circle cx="0" cy="0" r="8">
      <animateTransform
        id="f"
        attributeName="transform"
        dur="0.2s"
        type="translate"
        values="0 0; 14 0; 0 0"
        begin="e.end"
      />
    </circle>
  </g>
  <g transform="rotate(-150 21.038 17.406)">
    <circle cx="0" cy="0" r="8">
      <animateTransform
        id="a"
        attributeName="transform"
        dur="0.2s"
        type="translate"
        values="0 0; 14 0; 0 0"
        begin="f.end"
      />
    </circle>
  </g>
</svg>
<svg
  *ngIf="icon === 'kebab'"
  width="100%"
  height="100%"
  class="x-icon"
  [ngClass]="size"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 30"
>
  <circle r="3" transform="translate(2 15)">
    <animate attributeName="opacity" values="1;.2; 1" begin="0" dur="1.2s" calcMode="paced" repeatCount="indefinite" />
  </circle>
  <circle r="3" transform="translate(12 15)">
    <animate
      attributeName="opacity"
      values="1;.2; 1"
      begin="0.3s"
      dur="1.2s"
      calcMode="paced"
      repeatCount="indefinite"
    />
  </circle>
  <circle r="3" transform="translate(22 15)">
    <animate attributeName="opacity" values="1;.2; 1" begin=".6" dur="1.2s" calcMode="paced" repeatCount="indefinite" />
  </circle>
</svg>
<ng-content></ng-content>
