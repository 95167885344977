import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'sc-number-type-selector',
  templateUrl: './number-type-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NumberTypeSelectorComponent,
    },
  ],
})
export class NumberTypeSelectorComponent implements ControlValueAccessor, OnInit {
  @Input()
  numberTypes: { value: string; displayText: string };

  @Output() typeChange = new EventEmitter<any>();

  public isDisabled: boolean;
  public value: string;
  private onModelChange: (value: unknown) => void;
  private onModelTouched:  (value: unknown) => void;

  constructor() {}

  @HostBinding('class')
  get hostClass() {
    return 'x-input-group-prepend';
  }

  ngOnInit() {}

  public writeValue(obj: any): void {
    this.value = obj;
  }

  public registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public handleModelChange(res: any): void {
    this.value = res;
    this.onModelChange(this.value);
    this.typeChange.emit(this.value);
  }
}
