import { createSelector } from '@ngrx/store';
import { selectCardDetailsPopupState } from '@app/features/+card/store/reducers';
import * as fromCardDetailsPopup from '../reducers/card-details-popup.reducer';

export const selectPopupCardEntry = createSelector(selectCardDetailsPopupState, (state: fromCardDetailsPopup.State) =>
  fromCardDetailsPopup.getPopupCardEntry(state)
);

export const selectPopupCardEntryPersons = createSelector(
  selectCardDetailsPopupState,
  (state: fromCardDetailsPopup.State) => fromCardDetailsPopup.getPopupCardEntryPersons(state)
);

export const selectPopupCardEntryMatters = createSelector(
  selectCardDetailsPopupState,
  (state: fromCardDetailsPopup.State) => fromCardDetailsPopup.getPopupCardEntryMatters(state)
);
