import { createFeatureSelector } from '@ngrx/store';

import * as fromPaymentTypeDetails from './payment-type-details.reducer';
import { AppSlice } from '@app/core/store/app.slice';

export type State = fromPaymentTypeDetails.State;

export const initialState: State = fromPaymentTypeDetails.initialState;

export const reducers = fromPaymentTypeDetails.reducer;

export const selectPaymentTypeDetailsSelectorState = createFeatureSelector<State>(AppSlice.PaymentTypeDetails);
