import { Action } from '@ngrx/store';
import {
  ModifyRecurringMatterDto,
  SetRecurringMatterDebtorParams,
  RemoveRecurringMatterCardParams,
  RemoveRecurringMatterDebtorParams,
  SaveRecurringMatterTypeParams,
  SaveRecurringMatterDefinableParams,
} from '../../models';
import { IDefinableTable, IMatterDetailEntry, IComment } from '@app/shared/models';

export const SELECT_RECURRING_MATTER_START = '[Recurring matter modify] select start';
export const SELECT_RECURRING_MATTER_SUCCESS = '[Recurring matter modify] select success';
export const SELECT_RECURRING_MATTER_FAILURE = '[Recurring matter modify] select failure';

export const SAVE_RECURRING_MATTER_CARD_START = '[Recurring matter modify] save matter card start';
export const SAVE_RECURRING_MATTER_CARD_SUCCESS = '[Recurring matter modify] save matter card success';
export const SAVE_RECURRING_MATTER_CARD_FAILURE = '[Recurring matter modify] save matter card failure';

export const SAVE_RECURRING_MATTER_CARD_LIST_START = '[Recurring matter modify] save matter card list start';
export const SAVE_RECURRING_MATTER_CARD_LIST_SUCCESS = '[Recurring matter modify] save matter card list success';
export const SAVE_RECURRING_MATTER_CARD_LIST_FAILURE = '[Recurring matter modify] save matter card list failure';

export const REMOVE_RECURRING_MATTER_CARD_START = '[Recurring matter modify] remove matter card start';
export const REMOVE_RECURRING_MATTER_CARD_SUCCESS = '[Recurring matter modify] remove matter card success';
export const REMOVE_RECURRING_MATTER_CARD_FAILURE = '[Recurring matter modify] remove matter card failure';

export const SET_RECURRING_MATTER_DEBTOR_START = '[Recurring matter modify] set debtor start';
export const SET_RECURRING_MATTER_DEBTOR_SUCCESS = '[Recurring matter modify] set debtor success';
export const SET_RECURRING_MATTER_DEBTOR_FAILURE = '[Recurring matter modify] set debtor failure';

export const REMOVE_RECURRING_MATTER_DEBTOR_START = '[Recurring matter modify] remove debtor start';
export const REMOVE_RECURRING_MATTER_DEBTOR_SUCCESS = '[Recurring matter modify] remove debtor success';
export const REMOVE_RECURRING_MATTER_DEBTOR_FAILURE = '[Recurring matter modify] remove debtor failure';

export const REORDER_RECURRING_MATTER_DETAILS_START = '[Recurring matter modify] reorder details start';
export const REORDER_RECURRING_MATTER_DETAILS_SUCCESS = '[Recurring matter modify] reorder details success';
export const REORDER_RECURRING_MATTER_DETAILS_FAILURE = '[Recurring matter modify] reorder details failure';

export const SAVE_RECURRING_MATTER_COMMENT_START = '[Recurring matter modify] save comment start';
export const SAVE_RECURRING_MATTER_COMMENT_SUCCESS = '[Recurring matter modify] save comment success';
export const SAVE_RECURRING_MATTER_COMMENT_FAILURE = '[Recurring matter modify] save comment failure';

export const CLEAR_RECURRING_MATTER_DETAILS = '[Recurring matter modify] clear';

export const SAVE_RECURRING_MATTER_TYPE_START = '[Recurring matter type] save start';
export const SAVE_RECURRING_MATTER_TYPE_SUCCESS = '[Recurring matter type] save success';
export const SAVE_RECURRING_MATTER_TYPE_FAILURE = '[Recurring matter type] save failure';

export const SAVE_RECURRING_MATTER_DEFINABLE_START = '[Recurring matter] save definable start';
export const SAVE_RECURRING_MATTER_DEFINABLE_SUCCESS = '[Recurring matter] save definable success';
export const SAVE_RECURRING_MATTER_DEFINABLE_FAILURE = '[Recurring matter] save definable failure';

export const UPDATE_RECURRING_MATTER_CORE_START = '[Recurring matter options] update matter core start';
export const UPDATE_RECURRING_MATTER_CORE_SUCCESS = '[Recurring matter options] update matter core success';
export const UPDATE_RECURRING_MATTER_CORE_FAILURE = '[Recurring matter options] update matter core failure';

export const UPDATE_SELECTED_RECURRING_MATTER_START =
  '[Recurring matter modify] update selected recurring matter start';

export class SelectRecurringMatterStart implements Action {
  readonly type = SELECT_RECURRING_MATTER_START;
  constructor(public payload: string) {}
}

export class SelectRecurringMatterSuccess implements Action {
  readonly type = SELECT_RECURRING_MATTER_SUCCESS;
  constructor(public payload: ModifyRecurringMatterDto) {}
}

export class SelectRecurringMatterFailure implements Action {
  readonly type = SELECT_RECURRING_MATTER_FAILURE;
  constructor(public payload: any) {}
}

export class SaveRecurringMatterCardStart implements Action {
  readonly type = SAVE_RECURRING_MATTER_CARD_START;
  constructor(public payload: any) {}
}

export class SaveRecurringMatterCardSuccess implements Action {
  readonly type = SAVE_RECURRING_MATTER_CARD_SUCCESS;
  constructor(public payload: string) {}
}

export class SaveRecurringMatterCardFailure implements Action {
  readonly type = SAVE_RECURRING_MATTER_CARD_FAILURE;
  constructor(public payload: any) {}
}

export class SaveRecurringMatterCardListStart implements Action {
  readonly type = SAVE_RECURRING_MATTER_CARD_LIST_START;
  constructor(public payload: any) {}
}

export class SaveRecurringMatterCardListSuccess implements Action {
  readonly type = SAVE_RECURRING_MATTER_CARD_LIST_SUCCESS;
  constructor(public payload: string) {}
}

export class SaveRecurringMatterCardListFailure implements Action {
  readonly type = SAVE_RECURRING_MATTER_CARD_LIST_FAILURE;
  constructor(public payload: any) {}
}

export class RemoveRecurringMatterCardStart implements Action {
  readonly type = REMOVE_RECURRING_MATTER_CARD_START;
  constructor(public payload: RemoveRecurringMatterCardParams) {}
}

export class RemoveRecurringMatterCardSuccess implements Action {
  readonly type = REMOVE_RECURRING_MATTER_CARD_SUCCESS;
  constructor(public payload: string) {}
}

export class RemoveRecurringMatterCardFailure implements Action {
  readonly type = REMOVE_RECURRING_MATTER_CARD_FAILURE;
  constructor(public payload: any) {}
}

export class SetRecurringMatterDebtorStart implements Action {
  readonly type = SET_RECURRING_MATTER_DEBTOR_START;
  constructor(public payload: SetRecurringMatterDebtorParams) {}
}

export class SetRecurringMatterDebtorSuccess implements Action {
  readonly type = SET_RECURRING_MATTER_DEBTOR_SUCCESS;
  constructor(public payload: string) {}
}

export class SetRecurringMatterDebtorFailure implements Action {
  readonly type = SET_RECURRING_MATTER_DEBTOR_FAILURE;
  constructor(public payload: any) {}
}

export class RemoveRecurringMatterDebtorStart implements Action {
  readonly type = REMOVE_RECURRING_MATTER_DEBTOR_START;
  constructor(public payload: RemoveRecurringMatterDebtorParams) {}
}

export class RemoveRecurringMatterDebtorSuccess implements Action {
  readonly type = REMOVE_RECURRING_MATTER_DEBTOR_SUCCESS;
  constructor(public payload: string) {}
}

export class RemoveRecurringMatterDebtorFailure implements Action {
  readonly type = REMOVE_RECURRING_MATTER_DEBTOR_FAILURE;
  constructor(public payload: any) {}
}

export class ReorderRecurringMatterDetailsStart implements Action {
  readonly type = REORDER_RECURRING_MATTER_DETAILS_START;
  constructor(public payload: IMatterDetailEntry[]) {}
}

export class ReorderRecurringMatterDetailsSuccess implements Action {
  readonly type = REORDER_RECURRING_MATTER_DETAILS_SUCCESS;
  constructor(public payload: string) {}
}

export class ReorderRecurringMatterDetailsFailure implements Action {
  readonly type = REORDER_RECURRING_MATTER_DETAILS_FAILURE;
  constructor(public payload: any) {}
}

export class SaveRecurringMatterCommentStart implements Action {
  readonly type = SAVE_RECURRING_MATTER_COMMENT_START;
  constructor(public payload: IComment) {}
}

export class SaveRecurringMatterCommentSuccess implements Action {
  readonly type = SAVE_RECURRING_MATTER_COMMENT_SUCCESS;
  constructor(public payload: any) {}
}

export class SaveRecurringMatterCommentFailure implements Action {
  readonly type = SAVE_RECURRING_MATTER_COMMENT_FAILURE;
  constructor(public payload: any) {}
}

export class ClearRecurringMatterDetail implements Action {
  readonly type = CLEAR_RECURRING_MATTER_DETAILS;
  constructor(public payload: any) {}
}

export class SaveRecurringMatterTypeStart implements Action {
  readonly type = SAVE_RECURRING_MATTER_TYPE_START;
  constructor(public payload: SaveRecurringMatterTypeParams) {}
}

export class SaveRecurringMatterTypeSuccess implements Action {
  readonly type = SAVE_RECURRING_MATTER_TYPE_SUCCESS;
  constructor(public payload: string) {}
}

export class SaveRecurringMatterTypeFailure implements Action {
  readonly type = SAVE_RECURRING_MATTER_TYPE_FAILURE;
  constructor(public payload: any) {}
}

export class SaveRecurringMatterDefinableStart implements Action {
  readonly type = SAVE_RECURRING_MATTER_DEFINABLE_START;
  constructor(public payload: SaveRecurringMatterDefinableParams) {}
}

export class SaveRecurringMatterDefinableSuccess implements Action {
  readonly type = SAVE_RECURRING_MATTER_DEFINABLE_SUCCESS;
  constructor(public payload: string) {}
}

export class SaveRecurringMatterDefinableFailure implements Action {
  readonly type = SAVE_RECURRING_MATTER_DEFINABLE_FAILURE;
  constructor(public payload: any) {}
}

export class UpdateRecurringMatterCoreStart implements Action {
  readonly type = UPDATE_RECURRING_MATTER_CORE_START;
  constructor(public payload: any) {}
}

export class UpdateRecurringMatterCoreSuccess implements Action {
  readonly type = UPDATE_RECURRING_MATTER_CORE_SUCCESS;
  constructor(public payload: string) {}
}

export class UpdateRecurringMatterCoreFailure implements Action {
  readonly type = UPDATE_RECURRING_MATTER_CORE_FAILURE;
  constructor(public payload: any) {}
}

export class UpdateSelectedRecurringMatterStart implements Action {
  readonly type = UPDATE_SELECTED_RECURRING_MATTER_START;
  constructor(public payload: string) {}
}

export type RecurringMatterDetailsActions =
  | SelectRecurringMatterStart
  | SelectRecurringMatterSuccess
  | SelectRecurringMatterFailure
  | SaveRecurringMatterCardStart
  | SaveRecurringMatterCardSuccess
  | SaveRecurringMatterCardFailure
  | RemoveRecurringMatterCardStart
  | RemoveRecurringMatterCardSuccess
  | RemoveRecurringMatterCardFailure
  | SetRecurringMatterDebtorStart
  | SetRecurringMatterDebtorSuccess
  | SetRecurringMatterDebtorFailure
  | RemoveRecurringMatterDebtorStart
  | RemoveRecurringMatterDebtorSuccess
  | RemoveRecurringMatterDebtorFailure
  | ReorderRecurringMatterDetailsStart
  | ReorderRecurringMatterDetailsSuccess
  | ReorderRecurringMatterDetailsFailure
  | SaveRecurringMatterCommentStart
  | SaveRecurringMatterCommentSuccess
  | SaveRecurringMatterCommentFailure
  | ClearRecurringMatterDetail
  | SaveRecurringMatterTypeSuccess
  | SaveRecurringMatterDefinableStart
  | SaveRecurringMatterDefinableSuccess
  | SaveRecurringMatterDefinableFailure
  | UpdateRecurringMatterCoreStart
  | UpdateRecurringMatterCoreSuccess
  | UpdateRecurringMatterCoreFailure
  | UpdateSelectedRecurringMatterStart;
