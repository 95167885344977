import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sc-no-results-overlay',
  templateUrl: './no-results-overlay.component.html',
})
export class NoResultsOverlayComponent implements OnInit {
  @Input()

  get params() {
    return this._inputParams;
  }
  set params(data: InputParams) {
    this._inputParams = data;
    this._params = data.type || 'default'; // We will use this once the content
    this.iconClass = data.iconClassOverrides || 'd-block';
    this.setupContent();
  }

  @Input()
  set gridComponent(val: boolean) {
    this.gridComponentValue = val;
    this.regularComponentValue = !val;
  }

  private _inputParams: any;
  private _params: string;
  public title: string;
  public body: string;
  public iconClass: string;
  public IconName: string;

  constructor(private _translateSvc: TranslateService) {}

  @HostBinding('class.x-no-results-overlay-wrapper') public gridComponentValue = false;
  @HostBinding('class.x-no-results-component') public regularComponentValue = false;
  @HostBinding('class.h-100')
  ngOnInit() {
    this._params = this.params.type || 'default';

    this.setupContent();
  }

  private setupContent(): void {
    const keyVar = this._params;
    const NoResults = this._translateSvc.instant('NoResults') ? this._translateSvc.instant('NoResults')[keyVar] : null;

    this.IconName = !!NoResults && !!NoResults['icon'] ? `#${NoResults['icon']}` : null;
    this.title = !!NoResults && !!NoResults['title'] ? NoResults['title'] : 'No results found';
    this.body = !!NoResults && !!NoResults['body'] ? NoResults['body'] : '';
  }
}

interface IGridNoResultParams {
  title: string;
  body?: string;
  IconName?: string;
}

interface InputParams {
  type: string;
  iconClassOverrides: string;
}
