import { Directive, Input } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[scDisableControl]',
})
export class DisableControlDirective {
  @Input()
  set formFieldControl(value: FormControl) {
    this._formFieldControl = value;
  }
  get formFieldControl() {
    return this._formFieldControl;
  }
  @Input()
  set disableControl(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    if (this.formFieldControl) {
      this.formFieldControl[action]();
    }
    this.ngControl.control[action]();
  }

  private _formFieldControl: FormControl;

  constructor(private ngControl: NgControl) {}
}
