import { createSelector } from '@ngrx/store';

import * as fromMatterTypes from '../reducers/matter-types.reducer';

import { selectMatterTypesState } from '../reducers';

export const selectMatterTypes = createSelector(selectMatterTypesState, (state: fromMatterTypes.State) =>
  fromMatterTypes.selectMatterTypes(state)
);

export const selectSearchText = createSelector(selectMatterTypesState, (state: fromMatterTypes.State) =>
  fromMatterTypes.selectSearchText(state)
);

export const selectStates = createSelector(selectMatterTypesState, (state: fromMatterTypes.State) =>
  fromMatterTypes.selectStates(state)
);

export const selectSelectedState = createSelector(selectMatterTypesState, (state: fromMatterTypes.State) =>
  fromMatterTypes.selectSelectedState(state)
);

export const selectAllMatterTypes = fromMatterTypes.adapter.getSelectors(selectMatterTypesState).selectAll;

export const selectSelectedMatterTypeId = createSelector(selectMatterTypesState, (state: fromMatterTypes.State) =>
  fromMatterTypes.selectSelectedMatterTypeId(state)
);

export const selectSelectedMatterType = createSelector(
  selectMatterTypes,
  selectSelectedMatterTypeId,
  (matterTypes, matterTypeId) => matterTypes.find((type) => type.id === matterTypeId)
);

export const selectSelectedMatterTypeDetails = createSelector(
  selectMatterTypesState,
  fromMatterTypes.selectSelectedMatterTypeDetails
);

export const selectSwitchMatterTypeLoading = createSelector(selectMatterTypesState, (state: fromMatterTypes.State) =>
  fromMatterTypes.selectSwitchMatterTypeLoading(state)
);

export const selectLoading = createSelector(selectMatterTypesState, (state: fromMatterTypes.State) =>
  fromMatterTypes.selectLoading(state)
);

export const selectLoadingStates = createSelector(selectMatterTypesState, (state: fromMatterTypes.State) =>
  fromMatterTypes.selectLoadingStates(state)
);

export const selectSetupData = createSelector(selectMatterTypesState, (state: fromMatterTypes.State) =>
  fromMatterTypes.selectSetupData(state)
);

export const selectMatterTypeIdsForSelectedState = createSelector(selectMatterTypes, (matterTypes) =>
  matterTypes.map((matterType) => matterType.id)
);

export const selectAllMatterTypeIdsBySelectedMatterType = createSelector(
  selectMatterTypes,
  selectSelectedMatterTypeId,
  (matterTypes, matterTypeId) => {
    if (matterTypes && matterTypeId) {
      const matterTypeIds = drillDownToGetAllRelatedMatterTypeIds(matterTypes, [matterTypeId]);
      return matterTypeIds || [];
    }

    return [];
  }
);

const drillDownToGetAllRelatedMatterTypeIds = (matterTypes: any[], matterTypeIds: string[]) => {
  const ids = matterTypes.filter((m) => matterTypeIds.includes(m.parentId))?.map((c) => c.id);
  if (ids.length > 0) {
    return [...ids, ...drillDownToGetAllRelatedMatterTypeIds(matterTypes, ids)];
  }
  return ids;
};
