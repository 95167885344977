import { Component, HostBinding, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sc-form-row',
  templateUrl: './form-row.component.html',
})
export class FormRowComponent implements OnInit {
  @Input() label: string;

  constructor() {}

  @HostBinding('class.x-form-row')
  ngOnInit() {}
}
