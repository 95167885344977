import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import {
  selectBrowserTitleHideMatterInfo,
  selectBrowserTitlePageBrandName,
  selectBrowserTitlePrimaryPageName,
} from '../selectors/browser-title-state.selector';
import { BrowserTitleActionTypes } from '../actions/browser-title.action';
import { PlatformService } from '@app/core/services';
import { selectCurrentMatter, selectRouterSnapshot, selectRecurringMatterName } from '../selectors';
import * as browserTitleActions from '@app/core/store/actions';
import { TranslateService } from '@ngx-translate/core';
import { EPageName, LayerOutletName } from '@app/core/models';
import { IMatterListEntry } from '@app/features/+matter-list/models';
import { environment } from '@env/environment';

import { IRouterData } from '@app/core/models/router.model';

@Injectable()
export class BrowserTitleEffect {

  browserTitlePageNameUpdate$ = createEffect(() => this.actions$.pipe(
    ofType<browserTitleActions.SetBrowserTitle>(BrowserTitleActionTypes.SET_BROWSER_TITLE),
    withLatestFrom(
      this.store.pipe(select(selectCurrentMatter)),
      this.store.pipe(select(selectRecurringMatterName)),
      (action, currentMatter, recurringMatterName) => ({
        pageName: action.payload.pageName,
        hideMatterInfo: action.payload.hideMatterInfo,
        matterInfo: action.payload.matterInfo || currentMatter,
        pageBrandName: action.payload.pageBrandName,
        recurringMatterName,
      })
    ),
    tap((data) => {
      this.setBrowserTitle(data);
    })
  ), { dispatch: false });


  browserTitleMatterUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(BrowserTitleActionTypes.UPDATE_MATTER_INFO),
    withLatestFrom(
      this.store.pipe(select(selectCurrentMatter)),
      this.store.pipe(select(selectBrowserTitleHideMatterInfo)),
      this.store.pipe(select(selectBrowserTitlePrimaryPageName)),
      this.store.pipe(select(selectBrowserTitlePageBrandName)),
      this.store.pipe(select(selectRecurringMatterName)),
      (action, currentMatter, hideMatterInfo, pageName, pageBrandName, recurringMatterName) => ({
        pageName,
        hideMatterInfo,
        matterInfo: currentMatter,
        pageBrandName,
        recurringMatterName,
      })
    ),
    tap((data) => {
      this.setBrowserTitle(data);
    })
  ), { dispatch: false });


  browserPrimaryPageInit$ = createEffect(() => this.actions$.pipe(
    ofType<browserTitleActions.PrimaryPageTitleInit>(BrowserTitleActionTypes.PRIMARY_PAGE_TITLE_INIT),
    withLatestFrom(
      this.store.pipe(select(selectRouterSnapshot)),
      this.store.pipe(select(selectBrowserTitlePrimaryPageName)),
      (action, routerSnapshot: ActivatedRouteSnapshot, primaryPageName) => ({
        routerSnapshot,
        primaryPageName,
      })
    ),
    filter((data) => !!data && !!data.routerSnapshot),
    switchMap((data) => {
      const primaryPageName = data.primaryPageName;
      const route = this.getRouteWithData(data.routerSnapshot);
      const pageNameChanged = this.checkIfPageNameChanged(route, primaryPageName);
      if (pageNameChanged) {
        return [
          new browserTitleActions.SetPrimaryPageName({ pageName: route.data.pageName }),
          new browserTitleActions.SetBrowserTitle({ pageName: route.data.pageName }),
        ];
      }
      return EMPTY;
    })
  ));


  browserPopupPageInit$ = createEffect(() => this.actions$.pipe(
    ofType<browserTitleActions.PopupPageInit>(BrowserTitleActionTypes.POPUP_PAGE_TITLE_INIT),
    withLatestFrom(
      this.store.pipe(select(selectRouterSnapshot)),
      this.store.pipe(select(selectBrowserTitlePrimaryPageName)),
      (action, routerSnapshot: ActivatedRouteSnapshot, primaryPageName) => ({
        routerSnapshot,
        primaryPageName,
      })
    ),
    filter((data) => !!data && !!data.routerSnapshot),
    switchMap((data) => {
      const route: ActivatedRouteSnapshot = this.getRouteWithData(data.routerSnapshot, LayerOutletName.Popup);
      const routeData: IRouterData = {
        pageName: route.data.pageName,
        hideMatterInfo: route.data.hideMatterInfo,
        bypassPageNameConfig: route.data.bypassPageNameConfig,
      };

      if (routeData.bypassPageNameConfig) {
        return [new browserTitleActions.SetPopupPageName({ pageName: routeData.pageName })];
      }
      return [
        new browserTitleActions.SetPopupPageName({ pageName: routeData.pageName }),
        new browserTitleActions.SetBrowserTitle({
          pageName: routeData.pageName,
          hideMatterInfo: routeData.hideMatterInfo,
        }),
      ];
    })
  ));


  clearPopupPageName$ = createEffect(() => this.actions$.pipe(
    ofType<browserTitleActions.ClearPopupPageName>(BrowserTitleActionTypes.CLEAR_POPUP_PAGE_NAME),
    withLatestFrom(
      this.store.pipe(select(selectRouterSnapshot)),
      this.store.pipe(select(selectBrowserTitlePrimaryPageName)),
      (action, routerSnapshot: ActivatedRouteSnapshot, primaryPageName) => ({
        routerSnapshot,
        primaryPageName,
      })
    ),
    filter((data) => !!data && !!data.routerSnapshot),
    switchMap((data) => [new browserTitleActions.SetBrowserTitle({ pageName: data.primaryPageName })])
  ));

  constructor(
    private actions$: Actions,
    private platformSvc: PlatformService,
    private titleSvc: Title,
    private translateSvc: TranslateService,
    private store: Store<any>
  ) {}

  private setBrowserTitle(data: {
    pageName: EPageName | undefined;
    hideMatterInfo?: boolean;
    matterInfo?: Partial<IMatterListEntry>;
    pageBrandName?: string;
    recurringMatterName?: string;
  }): void {
    const { pageName, hideMatterInfo, matterInfo: matter, pageBrandName, recurringMatterName } = data;
    if (this.platformSvc.isBrowser) {
      let title = '';
      const pageNameStringValue =
        !!pageName && (pageName !== EPageName.MatterDetails || EPageName.RecurringMatterDetails)
          ? this.translateSvc.instant(pageName)
          : '';
      title += pageNameStringValue || '';
      if (!hideMatterInfo) {
        title += !!recurringMatterName ? ` - ${recurringMatterName}` : '';
        title += !!pageNameStringValue && !!matter && (matter.fileNumber || matter.firstDescription) ? ' - ' : '';
        title += !!matter && matter.fileNumber ? `${matter.fileNumber} - ` : '';
        title += !!matter && matter.firstDescription ? `${matter.firstDescription} ` : '';
      }
      title +=
        !!pageNameStringValue || (!hideMatterInfo && !!matter && (matter.fileNumber || matter.firstDescription))
          ? ' | '
          : '';
      const brandName = environment.config.brand.name;
      title += !!pageBrandName
        ? pageBrandName
        : brandName.toLowerCase() === 'leap'
        ? brandName.toUpperCase()
        : brandName;
      this.titleSvc.setTitle(title);
    }
  }

  private getRouteWithData(route: ActivatedRouteSnapshot, outletName?: string): ActivatedRouteSnapshot {
    let primaryRoute = outletName ? route.children.find((child) => child.outlet === outletName) : route.firstChild;
    while (!!primaryRoute.firstChild) {
      primaryRoute = primaryRoute.firstChild;
    }
    return primaryRoute;
  }

  private checkIfPageNameChanged(primaryRoute: ActivatedRouteSnapshot, primaryPageName): boolean {
    return (
      !!primaryRoute &&
      !!primaryRoute.data &&
      !!primaryRoute.data.pageName &&
      primaryRoute.data.pageName !== primaryPageName
    );
  }
}
