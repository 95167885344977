import { createSelector } from '@ngrx/store';

import * as fromContact from '../reducers/contact.reducer';
import { selectMatterDetailsState, State } from '../reducers';

export const selectContactState = createSelector(selectMatterDetailsState, (state: State) => state.contact);

export const selectCurrentContactLoading = createSelector(selectContactState, (state: fromContact.State) =>
  fromContact.selectLoading(state)
);

export const selectCurrentContactLoaded = createSelector(selectContactState, (state: fromContact.State) =>
  fromContact.selectLoaded(state)
);

export const selectCurrentContactError = createSelector(selectContactState, (state: fromContact.State) =>
  fromContact.selectError(state)
);

export const selectAllContacts = createSelector(selectContactState, (state: fromContact.State) =>
  fromContact.selectAllContacts(state)
);
