import { Injectable } from '@angular/core';

@Injectable()
export class TextUtilsService {
  constructor() {}

  // https://developer.mozilla.org/en/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
  b64EncodeUnicode = (str: string): string => btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match: string, p1: string): string => String.fromCharCode(parseInt(p1, 16)))
  );
}
