import { Action } from '@ngrx/store';
import { IStateMatterTypesEntry, IMatterType } from '@app/shared/models';
import { IChangeStatePayload } from '@app/features/+matter-types/models';

export const LIST_MATTER_TYPES_START = '[List matter types from api] start';
export const LIST_MATTER_TYPES_SUCCESS = '[List matter types from api] success';
export const LIST_MATTER_TYPES_FAILURE = '[List matter types from api] failure';

export const LAST_MATTER_TYPE_ID = '[Last matter type id from cached api] requested';
export const UPDATE_LAST_MATTER_TYPE_ID = '[Last matter type id from cached api] updated';

export const MATTER_TYPE_START = '[Matter type from api] start';
export const MATTER_TYPE_SUCCESS = '[Matter type from api] success';
export const MATTER_TYPE_FAILURE = '[Matter type from api] failure';

export const LIST_STATES_START = '[List states from api] start';
export const LIST_STATES_SUCCESS = '[List states from api] success';
export const LIST_STATES_FAILURE = '[List states wfrom api] failure';

export const CHANGE_STATE = '[Matter types state] change';
export const CLEAR_MATTER_TYPES = '[Matter types] clear';
export const SETUP_MATTER_TYPES = '[Matter types] setup start';

export const SEARCH_MATTER_TYPE = '[Matter type] search';

export class ListMatterTypesStart implements Action {
  readonly type = LIST_MATTER_TYPES_START;
  constructor(public payload: IChangeStatePayload) {}
}

export class ListMatterTypesSuccess implements Action {
  readonly type = LIST_MATTER_TYPES_SUCCESS;
  constructor(public payload: IStateMatterTypesEntry) {}
}

export class ListMatterTypesFailure implements Action {
  readonly type = LIST_MATTER_TYPES_FAILURE;
  constructor(public payload: any) {}
}

export class LastMatterTypeId implements Action {
  readonly type = LAST_MATTER_TYPE_ID;
  constructor(public payload: any) {}
}

export class UpdateLastMatterTypeId implements Action {
  readonly type = UPDATE_LAST_MATTER_TYPE_ID;
  constructor(public payload: string) {}
}

export class MatterTypeStart implements Action {
  readonly type = MATTER_TYPE_START;
  constructor(public payload: { id: string }) {}
}

export class MatterTypeSuccess implements Action {
  readonly type = MATTER_TYPE_SUCCESS;
  constructor(public payload: IMatterType) {}
}

export class MatterTypeFailure implements Action {
  readonly type = MATTER_TYPE_FAILURE;
  constructor(public payload: any) {}
}

export class ListStatesStart implements Action {
  readonly type = LIST_STATES_START;
  constructor(public payload: any) {}
}

export class ListStatesSuccess implements Action {
  readonly type = LIST_STATES_SUCCESS;
  constructor(public payload: string[]) {}
}

export class ListStatesFailure implements Action {
  readonly type = LIST_STATES_FAILURE;
  constructor(public payload: any) {}
}

export class ChangeState implements Action {
  readonly type = CHANGE_STATE;
  constructor(public payload: IChangeStatePayload) {}
}

export class ClearMatterTypes implements Action {
  readonly type = CLEAR_MATTER_TYPES;
  constructor(public payload: any) {}
}

export class SetupMatterTypes implements Action {
  readonly type = SETUP_MATTER_TYPES;
  constructor(public payload: any) {}
}

export class SearchMatterType implements Action {
  readonly type = SEARCH_MATTER_TYPE;
  constructor(public payload: string) {}
}

export type MatterTypesActions =
  | ListMatterTypesStart
  | ListMatterTypesSuccess
  | ListMatterTypesFailure
  | LastMatterTypeId
  | UpdateLastMatterTypeId
  | MatterTypeStart
  | MatterTypeSuccess
  | MatterTypeFailure
  | ListStatesStart
  | ListStatesSuccess
  | ListStatesFailure
  | ChangeState
  | ClearMatterTypes
  | SetupMatterTypes
  | SearchMatterType;
