import { Injectable } from '@angular/core';
import { RouterStateSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import * as feeTimerActions from '@app/features/fee-timer/store/actions';
import { DialogService } from '@app/shared/services';
import { Observable } from 'rxjs';
import { TimerRecordingAction } from '@app/features/+time-fee-ledger/models';

@Injectable()
export class TimeEntryValidationService {
  constructor() {}

  hasAnyUnsavedEntry(timeElapsedState: string): boolean {
    switch (timeElapsedState) {
      case TimerRecordingAction.Start:
      case TimerRecordingAction.Stop:
        return true;
      default:
        return false;
    }
  }

  canDeactivate(
    hasAnyUnsavedEntry: boolean,
    store: Store<any>,
    router: Router,
    dialogSvc: DialogService,
    stateSnapshot: RouterStateSnapshot,
    nextStateSnapshot?: RouterStateSnapshot
  ): Observable<boolean> {
    return new Observable((obs) => {
      if (
        nextStateSnapshot &&
        (nextStateSnapshot.url.startsWith('/matters/') || nextStateSnapshot.url.startsWith('/recurring-matters/'))
      ) {
        return obs.next(true);
      }

      if (!hasAnyUnsavedEntry) {
        store.dispatch(new feeTimerActions.FeeTimerHide(undefined));
        return obs.next(true);
      }

      dialogSvc.confirm({
        title: 'Unsaved Time Entry',
        message: 'Do you want to Discard or Resume?',
        actionText: 'Discard',
        closeText: 'Resume',
        showCancel: true,
        onClose: (confirmed: boolean) => {
          if (confirmed) {
            store.dispatch(new feeTimerActions.FeeTimerTimeElapsedDiscard(undefined));
            store.dispatch(new feeTimerActions.ResetFeeTimer(null));
            store.dispatch(new feeTimerActions.FeeTimerHide(undefined));
            return obs.next(true);
          }

          return obs.next(false);
        },
      });
    });
  }
}
