import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { AggridKeyboardCode } from '@app/shared/models';
import { isBoolean, isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

export abstract class AggridEditorAbstract implements ICellEditorAngularComp {
  public isFullRowEdit: boolean;
  public isDefaultFocus: boolean;

  public onEnter: (event: KeyboardEvent, params?: any) => void;
  public onEscape: (event: KeyboardEvent, params?: any) => void;
  public onTab: (event: KeyboardEvent, params?: any) => void;
  public onDownArrow: (event: KeyboardEvent, params?: any) => void;
  public onUpArrow: (event: KeyboardEvent, params?: any) => void;

  public params: any;
  public value: any;

  constructor() {}

  agInit(params: any): void {
    this.params = params;

    this.isFullRowEdit = isBoolean(params.isFullRowEdit) ? params.isFullRowEdit : true;
    this.isDefaultFocus = isBoolean(params.isDefaultFocus) ? params.isDefaultFocus : false;

    this.onEnter = isFunction(params.onEnter) ? params.onEnter : this.onEnter;
    this.onEscape = isFunction(params.onEscape) ? params.onEscape : this.onEscape;
    this.onTab = isFunction(params.onTab) ? params.onTab : this.onTab;
    this.onUpArrow = isFunction(params.onUpArrow) ? params.onUpArrow : this.onUpArrow;
    this.onDownArrow = isFunction(params.onDownArrow) ? params.onDownArrow : this.onDownArrow;
  }

  afterGuiAttached(): void {
    if (this.isDefaultFocus) {
      this.focusIn();
    }
  }

  focusIn(): void {}

  getValue(): any {
    return this.value;
  }

  keydown(event: KeyboardEvent): void {
    const keyboardCode = event.key;
    switch (keyboardCode) {
      case AggridKeyboardCode['enter']:
        if (isFunction(this.onEnter)) {
          this.onEnter(event, this.params);
        }
        return;
      case AggridKeyboardCode['escape']:
        event.stopImmediatePropagation();
        this.params.api.stopEditing(true);
        if (isFunction(this.onEscape)) {
          this.onEscape(event, this.params);
        }
        return;
      case AggridKeyboardCode['tab']:
        if (isFunction(this.onTab)) {
          this.params.value = this.value;
          this.onTab(event, this.params);
        }
        return;
      case AggridKeyboardCode['up']:
        if (isFunction(this.onUpArrow)) {
          this.onUpArrow(event, this.params);
        }
        return;
      case AggridKeyboardCode['down']:
        if (isFunction(this.onDownArrow)) {
          this.onDownArrow(event, this.params);
        }
        return;
      case AggridKeyboardCode['left']:
      case AggridKeyboardCode['right']:
        event.stopImmediatePropagation();
    }
  }
}
