import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { Component, EventEmitter, forwardRef, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { joinArrayToString } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-input-with-icon-debounce',
  templateUrl: './input-with-icon-debounce.component.html',
  providers: [
    NgModel,
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputWithIconDebounceComponent), multi: true },
  ],
})
export class InputWithIconDebounceComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  placeholder = '';
  @Input()
  isDisabled: boolean;
  @Input()
  readonly: boolean;
  @Input()
  delay: number;
  @Input()
  iconName: string;
  @Input()
  inputStyle: any;
  @Input()
  inputClass: string;

  @Input()
  set autofocus(data: boolean) {
    this._autofocus = data;
  }

  get autofocus() {
    return this._autofocus === undefined ? true : this._autofocus;
  }

  @Input()
  set inputType(data: string) {
    this._inputType = data;
  }

  get inputType() {
    return this._inputType || 'text';
  }

  @Input()
  set wrapperClass(data: string) {
    this._wrapperClass = data;
    this.classes = joinArrayToString(['form-group col', data], ' ');
  }

  get wrapperClass() {
    return this._wrapperClass;
  }

  @Input()
  set inputValue(data: any) {
    if (!this._value || this._value !== data) {
      // Default value
      this._value = data;
    }
  }

  @Output()
  onBlur = new EventEmitter<any>();

  @Output()
  onKeyup = new EventEmitter<string>();

  private _autofocus: boolean;
  private _inputType: string;
  private _wrapperClass = '';
  private _value: any = '';
  private inputValueStreamSub: Subscription;
  private inputValueStream = new Subject<string>();
  private inputValueStream$ = this.inputValueStream.asObservable();
  private unsub = new Subject<void>();

  constructor() {}

  @HostBinding('class')
  classes = 'x-toolbar-input';

  ngOnInit() {
    this.inputValueStreamSub = this.inputValueStream$
      .pipe(distinctUntilChanged(), debounceTime(this.delay || 200), takeUntil(this.unsub))
      .subscribe(() => {
        this.onChange(this._value);
        this.onKeyup.emit(this._value);
      });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
    }
  }

  writeValue(value: any) {
    this._value = value;
  }

  onChange = (_) => {};
  onTouched = () => {};

  registerOnChange(fn: (value?: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(value: boolean): void {
    this.isDisabled = value;
  }

  handleModelValueChange(val): void {
    this._value = val;
    this.inputValueStream.next(val);
  }

  blur(): void {
    this.onBlur.emit(this.value);
  }

  hasValue() {
    return !!this._value && this._value !== '';
  }

  resetValue() {
    const defaultValue = '';
    this._value = defaultValue;
    this.inputValueStream.next(defaultValue);
    this.onChange(this._value);
  }
}
