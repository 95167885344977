import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sc-add-remove-control',
  templateUrl: './add-remove-control.component.html',

  encapsulation: ViewEncapsulation.None,
})
export class AddRemoveControlComponent<T> implements OnInit {
  @Input() term: T;
  @Input() isFirst: boolean;
  @Input() isLast: boolean;
  @Input() canRemoveTerm: boolean;
  @Output() onAddTerm = new EventEmitter<void>();
  @Output() onRemoveTerm = new EventEmitter<T>();

  constructor() {}

  @HostBinding('class.d-inline-flex')
  ngOnInit() {}

  removeTerm(): void {
    this.onRemoveTerm.emit(this.term);
  }

  addTerm(): void {
    this.onAddTerm.emit();
  }
}
