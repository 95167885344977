<div class="x-toolbar-tabs">
  <sc-tabs>
    <ng-template [ngxPermissionsOnly]="[permissionEnum.LeapUser]">
      <sc-tab-item [link]="['/matters']">
        <sc-icon iconName="chevron-left-grid-20"></sc-icon>
        <span class="ml-1 d-none d-xl-block">Matters</span>
      </sc-tab-item>
    </ng-template>

    <sc-tab-item [link]="detailsAndCorrenpondence"> Details & Correspondence</sc-tab-item>

    <ng-template [ngxPermissionsOnly]="[permissionEnum.LeapUser]">
      <sc-tab-item [link]="calendar">
        Calendar & Tasks
      </sc-tab-item>
      <sc-tab-item *ngIf="!titlexLive" [link]="financialSummary"> Financial Summary</sc-tab-item>
    </ng-template>

    <ng-template [ngxPermissionsOnly]="[permissionEnum.LeapUser, permissionEnum.FeeRead, permissionEnum.FeeWrite]">
      <sc-tab-item [link]="timeAndFees"> Time & Fees</sc-tab-item>
    </ng-template>

    <ng-template [ngxPermissionsOnly]="[permissionEnum.LeapUser]">
      <sc-tab-item-dropdown
        *ngIf="(isSmallScreen$ | async) === false"
        [dropdownList]="officeAccountingDropdown"
        [label]="'Accounting.Office.Label' | translate"
      >
      </sc-tab-item-dropdown>
      <sc-tab-item-dropdown
        *ngIf="(isSmallScreen$ | async) === false"
        [dropdownList]="trustFundsDropdown"
        [label]="'Trust.General.Fund.MenuGroup' | translate"
      >
      </sc-tab-item-dropdown>
      <sc-tab-item-dropdown
        *ngIf="isSmallScreen$ | async"
        [dropdownList]="moreMenuDropdown"
        label="More"
        [dropFromRight]="true"
      >
      </sc-tab-item-dropdown>
    </ng-template>

    <span class="ml-auto d-flex">
      <ng-template *ngIf="!!toolbarAction" [ngxPermissionsOnly]="[permissionEnum.LeapUser, permissionEnum.MatterWrite]">
        <li class="nav-item ml-auto" dropdown container="body" placement="bottom" [tooltip]="toolbarAction.name">
          <a href scPreventDefault class="nav-link no-bottom-bar" dropdownToggle>
            <sc-icon [iconName]="toolbarAction.icon"></sc-icon>
          </a>
          <ng-container *ngIf="toolbarAction.actions.length > 0">
            <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu role="menu">
              <li
                *ngFor="let action of toolbarAction.actions"
                scPreventDefault
                class="dropdown-item"
                (click)="handleClickOnToolbarAction(action)"
              >
                {{ action.actionName | translate }}
              </li>
            </ul>
          </ng-container>
        </li>
      </ng-template>
      <li class="nav-item ml-auto" [floatUi]="popperAdditionalDetailsTooltip" hideOnMouseLeave="true"
      hideOnScroll="true" placement="bottom" showTrigger="hover" preventOverflow="true">
        <a
          class="nav-link no-bottom-bar"
          href
          scPreventDefault
          (click)="openMatterOptions()"
          angulartics2On
          angularticsAction="Open matter options"
          [angularticsCategory]="getAnalyticsCategory"
        >
          <sc-icon iconName="cog"></sc-icon>
        </a>
      </li>
      <sc-tab-item-icon
        iconName="chevron-left-grid-20"
        [IconClass]="{ 'x-transition-transform': true, 'x-scale-x-min-1': isAsideExpanded }"
        (itemClick)="toggleAside(!isAsideExpanded)"
        angulartics2On
        angularticsAction="Toggle sidebar mode"
        [angularticsCategory]="getAnalyticsCategory"
        [angularticsLabel]="getAnalyticsLabelAside(isAsideExpanded)"
      ></sc-tab-item-icon>
    </span>
  </sc-tabs>
</div>
<float-ui-content #popperAdditionalDetailsTooltip>
 <div class="tooltip-inner">Additional Details</div>
</float-ui-content>
