import { ILoadingCellRendererAngularComp } from '@ag-grid-community/angular';
import { ILoadingCellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
  selector: 'sc-loading-cell-renderer',
  templateUrl: './loading-cell-renderer.component.html'
})
export class LoadingCellRendererComponent
  implements ILoadingCellRendererAngularComp {
  public params!: ILoadingCellRendererParams & { loadingMessage: string };

  agInit(
    params: ILoadingCellRendererParams & { loadingMessage: string }
  ): void {
    this.params = params;
  }
}
