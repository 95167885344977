import { BrowserTitleActions, BrowserTitleActionTypes } from '@app/core/store/actions/browser-title.action';
import { EPageName } from '@app/core/models';

export interface State {
  PopupPageName: EPageName;
  PrimaryPageName: EPageName;
  HideMatterInfo: boolean;
  PageBrandName: string;
}

export const INITIAL_STATE: State = {
  PopupPageName: null,
  PrimaryPageName: null,
  HideMatterInfo: false,
  PageBrandName: '',
};

export const reducer = (state: State = INITIAL_STATE, action: BrowserTitleActions) => {
  switch (action.type) {
    case BrowserTitleActionTypes.SET_BROWSER_TITLE:
      return {
        ...state,
        HideMatterInfo: !!action.payload.hideMatterInfo,
        PageBrandName: action.payload.pageBrandName,
      };

    case BrowserTitleActionTypes.SET_PRIMARY_PAGE_NAME:
      return {
        ...state,
        PrimaryPageName: action.payload.pageName,
      };

    case BrowserTitleActionTypes.SET_POPUP_PAGE_NAME:
      return {
        ...state,
        PopupPageName: action.payload.pageName,
      };

    case BrowserTitleActionTypes.CLEAR_POPUP_PAGE_NAME:
      return {
        ...state,
        PopupPageName: null,
      };

    case BrowserTitleActionTypes.SET_BRAND_NAME:
      return {
        ...state,
        BrandName: action.payload.name,
      };

    default:
      return state;
  }
};

export const getPopupPageName = (state: State) => state.PopupPageName;
export const getPrimaryPageName = (state: State) => state.PrimaryPageName;
export const getHideMatterInfo = (state: State) => state.HideMatterInfo;
export const getPageBrandName = (state: State) => state.PageBrandName;
