export interface CorrespondenceFilterOption {
  label: string;
  value: CorrespondenceFilterValue;
  ext: string[];
}

export interface CorrespondenceFilter {
  availableFilterOptions: CorrespondenceFilterOption[];
  appliedFilterOptions: CorrespondenceFilterOption[];
}

export enum CorrespondenceFilterValue {
  Msg = 1,
  Doc = 2,
  PDF = 3,
  Comment = 4,
  Img = 5,
  Excel = 6,
}

export const correspondenceFilterOptions: CorrespondenceFilterOption[] = [
  {
    label: 'Correspondence.Filter.PDF.Label',
    value: CorrespondenceFilterValue.PDF,
    ext: ['pdf'],
  },
  {
    label: 'Correspondence.Filter.Doc.Label',
    value: CorrespondenceFilterValue.Doc,
    ext: ['docx', 'doc'],
  },
  {
    label: 'Correspondence.Filter.Msg.Label',
    value: CorrespondenceFilterValue.Msg,
    ext: ['msg', 'oft'],
  },
  {
    label: 'Correspondence.Filter.Image.Label',
    value: CorrespondenceFilterValue.Img,
    ext: ['tiff', 'tif', 'jpg', 'jpeg', 'gif', 'png'],
  },
  {
    label: 'Correspondence.Filter.Excel.Label',
    value: CorrespondenceFilterValue.Excel,
    ext: ['xls', 'xlsx'],
  },
  {
    label: 'Correspondence.Filter.Comment.Label',
    value: CorrespondenceFilterValue.Comment,
    ext: ['html'],
  },
];
