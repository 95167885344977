import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-divider',
  templateUrl: './divider.component.html',
})
export class DividerComponent implements OnInit {
  @Input() direction?: string;
  @Input() extraClass?: string;
  constructor() {}

  @HostBinding('class') classes;
  ngOnInit() {
    this.classes =
      'x-divider-' + (this.direction ? this.direction : '') + ' ' + (this.extraClass ? this.extraClass : '');
  }
}
