import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { Subscription } from 'rxjs';
import { AnalyticsModel, AnalyticsValueType } from '@app/core/models/analytics.model';
import { PlatformService } from '@app/core/services/platform/platform.service';

@Injectable()
export class AnalyticsService {
  private _navigateEndSub: Subscription;

  constructor(
    private _angulartics2: Angulartics2,
    private _gtm: Angulartics2GoogleTagManager,
    private platformSvc: PlatformService
  ) {}

  startTracking() {
    this._gtm.startTracking();
  }

  eventTrack(model: AnalyticsModel): void {
    if (this.platformSvc.isBrowser) {
      this._angulartics2.eventTrack.next({
        action: model.action,
        properties: {
          category: model.category,
          label: model.label,
          value: model.value,
        },
      });
    }
  }

  public sendAnalyticsEvent(category: string, action: string, label?: string, value?: any): void {
    this.eventTrack({ category, action, label: label || null, value: value || null });
  }

  public getTextValueFromBoolean(value: boolean, type: AnalyticsValueType | string): string {
    switch (type) {
      case AnalyticsValueType.OnOff:
        return value ? 'On' : 'Off';
      case AnalyticsValueType.TrueFalse:
        return value ? 'true' : 'false';
      case AnalyticsValueType.YesNo:
        return value ? 'Yes' : 'No';
      case AnalyticsValueType.ExpandedCollapsed:
        return value ? 'Collapsed' : 'Expanded';
      case AnalyticsValueType.PinnedUnpinned:
        return value ? 'Pinned' : 'Unpinned';
      case AnalyticsValueType.IncludeExcludeTax:
        return value ? 'Include Tax' : 'Exclude Tax';
      default:
        return 'unknown';
    }
  }

  dispose(): void {
    this._navigateEndSub.unsubscribe();
  }
}
