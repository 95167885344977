import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '@app/shared/services/base/base.service';
import { LogService } from '@app/core/services/log/log.service';
import {
  IEmailAddress,
  CreateEmailRequest,
  CreateEmailResponse,
  DeleteEmailResponse,
  GetEmailsParams,
  IEmail,
  IEmailDTO,
  toUpdateEmailRequest,
  UpdateEmailRequest,
  UpdateEmailResponse,
} from '@app/features/+email/models';

@Injectable()
export class EmailService extends BaseService {
  private path_: string;

  constructor(private _log: LogService, private http: HttpClient) {
    super();
    this._log.init('email-service');
    this.path_ = `${this.calendarPath}/emails`;
  }

  createEmail(request: CreateEmailRequest): Observable<CreateEmailResponse> {
    const url = `${this.path_}`;
    return this.http.post<CreateEmailResponse>(url, request);
  }

  deleteEmail(id: number): Observable<DeleteEmailResponse> {
    const url: string = this.urlJoin(this.path_, id.toString());
    return this.http.delete<DeleteEmailResponse>(url);
  }

  getEmail(id: number): Observable<IEmailDTO> {
    const url: string = this.urlJoin(this.path_, id.toString());
    return this.http.get<IEmailDTO>(url);
  }

  getEmails(params: GetEmailsParams): Observable<IEmailDTO[]> {
    // const data = {
    //   matterId: params.matterId,
    //   staffId: params.staffId
    // };
    return this.http.get<IEmailDTO[]>(this.path_);
  }

  getEmailSubject(matterId: string, recipients: IEmailAddress[]): Observable<string> {
    const leapcalcPath = `${this.siriusPath}/api/calc/emailsubject`;
    const Req = {
      matterId,
      recipients: recipients?.map((x) => ({ email: x.address })) || [],
    };
    return this.http.post(leapcalcPath, Req, { responseType: 'text' });
  }

  sendEmail(id: number): Observable<void> {
    const url = this.urlJoin(this.path_, id.toString(), '/send');
    return this.http.post<void>(url, undefined);
  }

  updateEmail(email: IEmail): Observable<UpdateEmailResponse> {
    this._log.info('email: ', email);

    const request: UpdateEmailRequest = toUpdateEmailRequest(email);
    this._log.info('email model: ', request);

    return this.http.put<UpdateEmailResponse>(this.path_, request);
  }
}
