<div class="x-grid-wrapper flex-column">
  <ag-grid-angular
    #agGrid
    [scAgGridFocus]="agGrid"
    [agGridApi]="gridApi"
    [rowData]="rowCollection"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
  >
  </ag-grid-angular>
</div>
