import {
  groupBy,
  isEmptyObj,
  isFunction,
  maxBy,
  omitObjValue,
  sortAscBy,
} from '../../../../../server/modules/shared/functions/common-util.functions';
import {
  IMatterDetailEntry,
  EMatterDetailType,
  IMatterCard,
  IMatterCardUpdateSuccess,
  IDefinableTable,
  IMatterDetailRelationship,
  IPerson,
} from '../../../shared/models';
import { DebtorClass, OrderCalculationPolicies, TableConfigIds } from '../constants';

export const isDebtorCard = (detailEntry: IMatterDetailEntry): boolean => !!detailEntry && detailEntry.__className === DebtorClass;

export const isMatterCard = (detailEntry: IMatterDetailEntry): boolean => detailEntry.detailType === EMatterDetailType.Card;

// related layout refers to a matter card with definable table.
// the definable table also known as related layout.
export const isRelatedLayout = (entry: IMatterDetailEntry, entries: IMatterDetailEntry[]) => {
  if (isMatterCard(entry)) {
    return false;
  }

  if ((entry as IDefinableTable).basedOnCard) {
    return true;
  }

  const parentCard = entries?.find(
    (mEntry) => isMatterCard(mEntry) && entry.__relatedParentTableId && mEntry.__id === entry.__relatedParentTableId
  );

  if (parentCard) {
    return true;
  }

  return false;
};

export const getMatterDetailEntryChildren = (entry: IMatterDetailEntry, entries: IMatterDetailEntry[]) => entries
    .filter((child) => {
      if (child.__relatedParentTableId !== undefined) {
        return child.__relatedParentTableId === entry.__id;
      }

      return false;
    })
    .sort(matterDetailChildrenComparator);

export const matterDetailChildrenComparator = (valueA: IMatterDetailEntry, valueB: IMatterDetailEntry): number => {
  // Sort by __displayOrder
  if (valueA.__displayOrder > valueB.__displayOrder) {
    return 1;
  }
  if (valueA.__displayOrder < valueB.__displayOrder) {
    return -1;
  }

  // Then By fileOrder
  if (valueA.__fileOrder > valueB.__fileOrder) {
    return 1;
  } else if (valueA.__fileOrder < valueB.__fileOrder) {
    return -1;
  } else {
    return 0;
  }
};

export const getLastDetailEntry = (detailEntries: IMatterDetailEntry[], tableId: string): IMatterDetailEntry => maxBy(detailEntries, (e: IMatterDetailEntry): number => {
    if (e.__tableId !== tableId) {
      return -1;
    }
    return e.__fileOrder;
  });

export const updateDetailsByMatterCard = (
  detailEntries: IMatterDetailEntry[],
  options: IMatterCardUpdateSuccess
): IMatterDetailEntry[] => {
  if (!options) {
    return detailEntries;
  }
  const { oldId, matterCard, preUpdate } = options;
  const oldMatterCardId = !!oldId && isFunction(oldId) ? oldId(detailEntries) : undefined;
  if (!oldMatterCardId) {
    return sortAscBy([...detailEntries, matterCard], (value) => value.__displayOrder);
  }
  if (!!preUpdate && isFunction(preUpdate)) {
    detailEntries = preUpdate(detailEntries ? [...detailEntries] : []);
  }
  return (
    detailEntries?.map((detailEntry: IMatterDetailEntry) => isMatterCard(detailEntry) &&
        detailEntry.tableId === matterCard.tableId &&
        detailEntry.__displayOrder === matterCard.__displayOrder
        ? matterCard
        : detailEntry) || []
  );
};

/**
 * Filter out matterCard with __className === DebtorForAccounting if it's internal debtor.
 * External debtor should still be returned as it reflects a unique card within the matter.
 *
 * @param matterCards
 * @returns
 */
export const reduceMatterCards = (matterCards: IMatterCard[]): IMatterCard[] => {
  const debtorForAccounting = matterCards?.find((x) => x.__className === DebtorClass);
  if (!!debtorForAccounting) {
    const isExternalDebtor = (matterCards || []).filter((c) => c.cardId === debtorForAccounting.cardId).length === 1;
    if (!isExternalDebtor) {
      return matterCards.filter((c) => c.__id !== debtorForAccounting.__id);
    }
  }
  return matterCards;
};

export const addPersonToMatterCard = (matterCard: IMatterCard, person: IPerson): IMatterCard => {
  if (!matterCard || !person) {
    return matterCard;
  }

  const { persons, unusedPersons, cardPersonList } = matterCard;
  return {
    ...matterCard,
    persons: [...(persons || []), person],
    unusedPersons: (unusedPersons || []).filter((personObj) => personObj.__id !== person.__id),
    cardPersonList: [...(cardPersonList || []), person.__id],
  };
};

export const removePersonFromMatterCard = (matterCard: IMatterCard, person: IPerson): IMatterCard => {
  if (!matterCard || !person) {
    return matterCard;
  }

  const { persons, unusedPersons, cardPersonList } = matterCard;
  return {
    ...matterCard,
    persons: (persons || []).filter((personObj) => personObj.__id !== person.__id),
    unusedPersons: [...(unusedPersons || []), person],
    cardPersonList: (cardPersonList || []).filter((personId) => personId !== person.__id),
  };
};

export const createDefinableTable = (
  definableTables: IDefinableTable[],
  detailEntry: IDefinableTable,
  matterId: string,
  increment: boolean = true
): IDefinableTable => {
  const tableId: string = detailEntry.__tableId || detailEntry.tableId;

  const newDefinableTable: IDefinableTable = {
    __className: detailEntry.__className,
    __fileOrder: detailEntry.__fileOrder,
    __displayOrder: detailEntry.__displayOrder,
    __name: detailEntry.__name,
    __description: undefined,
    desc: undefined,
    __matterId: matterId,
    __tableId: tableId,
    // __fields: [],
    detailType: EMatterDetailType.DefinableTable,
    tableId,
    layoutId: detailEntry.layoutId,
    isCard: false,
    // fields: []
  } as IDefinableTable;

  if (increment) {
    // find the max fileOrder of this matterCard type
    const lastDefinable = getLastDetailEntry(definableTables, tableId);

    newDefinableTable.__fileOrder = lastDefinable.__fileOrder + 1;
    newDefinableTable.__displayOrder = lastDefinable.__displayOrder + 1000;
  }

  return newDefinableTable;
};

export const recalculateFileOrder = (
  details: IMatterDetailEntry[],
  includesRelationships = false
): IMatterDetailEntry[] => {
  const diffUnit = OrderCalculationPolicies.DifferentTypeRange;
  const sameUnit = OrderCalculationPolicies.SameTypeRange;

  const nonEmptyFiles =
    details
      ?.filter((e) => ![isEmptyObj(e), e.matterType, e.isTransient].some((invalid) => !!invalid))
      .map((entry) => ({ ...entry, order: details.findIndex((x) => x === entry) })) || [];

  const groupNonEmptyFiles = groupBy(nonEmptyFiles, 'tableId');

  const files = Object.values(groupNonEmptyFiles)
    .map((sameTableEntries: IMatterDetailEntry[]) => {
      let index = 1;
      return sortAscBy(
        sameTableEntries.filter((sEntry) => !isRelatedLayout(sEntry, sameTableEntries)),
        (value) => (!!includesRelationships ? value.__displayOrder : null)
      ).map((sEntry) => {
        sEntry.__fileOrder = index;
        // Resets same type range to reflect new file order
        sEntry.__displayOrder = Math.floor(sEntry.__displayOrder / diffUnit) * diffUnit + index * sameUnit;
        index++;
        return sEntry;
      });
    })
    .flat();

  return sortAscBy(files, (value) => value.order)?.map((entry) => omitObjValue(entry, ['order'])) || [];
};

export const getTableId = (matterDetail, relationId?: string): string => {
  if (!!relationId && relationIsPrimaryTable(relationId)) {
    return matterDetail.__tableId || matterDetail.tableId;
  }

  return !!matterDetail.subsTableId ? matterDetail.subsTableId : matterDetail.__tableId || matterDetail.tableId;
};

export const isChildParentRelationshipValid = (
  relationships: IMatterDetailRelationship[],
  parent: IMatterDetailEntry,
  child: IMatterDetailEntry
): boolean => {
  if (!relationships || relationships.length === 0 || parent === undefined) {
    return false;
  }

  const appliedRelationships = relationships.filter(
    (rel) => rel.parentTableId === getTableId(parent, rel.parentTableId)
  );
  return !!appliedRelationships.find((rel) => rel.childTableId === child.tableId);
};

const relationIsPrimaryTable = (relationId: string) => (
    relationId === TableConfigIds.CLIENT ||
    relationId === TableConfigIds.OTHER_SIDE ||
    relationId === TableConfigIds.OTHER_SIDE_INSURER ||
    relationId === TableConfigIds.OTHER_SIDE_SOLICITOR
  );
