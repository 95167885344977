import { Component, Input, OnInit } from '@angular/core';
import { AppApiService } from '@app/core/api/app-api.service';
import { PermissionsService } from '@app/core/services';

@Component({
  selector: 'sc-toolbar-info',
  templateUrl: './toolbar-info.component.html',
})
export class ToolbarInfoComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() status: string;
  @Input() hasContext: boolean;
  @Input() hideStatus = false;
  @Input() isRecurringMatter = false;

  constructor(private _appApiSvc: AppApiService, private _permissionService: PermissionsService) {}

  ngOnInit() {}

  openMatterOptions() {
    if (!this._permissionService.isMatterReadOnly) {
      if (this.isRecurringMatter === true) {
        this._appApiSvc.openRecurringMatterOptions();
      } else {
        this._appApiSvc.openMatterOptions();
      }
    }
  }
}
