import { Component, HostBinding } from '@angular/core';
import { RowNode } from '@ag-grid-community/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'sc-teams-group-cell',
  templateUrl: './teams-group-cell.component.html',
})
export class MSTeamsGroupCellComponent implements ICellRendererAngularComp {
  rowNode: RowNode;
  groupName: string;

  constructor() {}

  agInit(params: any): void {
    this.rowNode = params.node;
    this.groupName = this.rowNode?.key || '';
  }

  refresh(params: any): boolean {
    return true;
  }
}
