import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter as rxjsFilter, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, of, throwError } from 'rxjs';

import { PersonListStorageService, PersonService } from '../../services';
import * as personDetailsActions from '../actions/person-details';
import * as personListActions from '../actions/person-list';
import { DialogService, PersonUtilsService } from '@app/shared/services';
import {
  selectIsPersonDetailsModified,
  selectPersonDetailsFormValue,
  selectPersonDetailsInvalidControls,
  selectPersonDetailsRelatedCardIds,
  selectPersonDetailsRelatedMatterIds
} from '@app/features/+person/store/selectors/person-details.selectors';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { PersonErrors } from '@app/features/+person/models';
import { SiriusError } from '@app/features/error-handler/interfaces/error-handler.interfaces';
import { AuthService } from '@app/core/services';
import { CardListStorageService } from '@app/features/+card/services';

@Injectable()
export class PersonDetailsEffects {

  loadPersonDetailsStart$ = createEffect(() => this.actions$.pipe(
    ofType<personDetailsActions.LoadPersonDetailsStart>(
      personDetailsActions.PersonDetailsActionTypes.LOAD_PERSON_DETAILS_START
    ),
    switchMap((action) => {
      const isExistingPerson = !!action.payload.id;

      const personDetails = (isExistingPerson ? this.personSvc.getPerson(action.payload.id) : of({})).pipe(take(1));
      const relatedCardIds = (isExistingPerson ? this.personSvc.getRelatedCardsIds(action.payload.id) : of([])).pipe(take(1));
      const relatedMatterIds = (isExistingPerson ? this.personSvc.getRelatedMattersIds(action.payload.id) : of([])).pipe(take(1));
      return forkJoin([personDetails, relatedCardIds, relatedMatterIds]).pipe(
        mergeMap((data) => {
          const [details, cardIds, matterIds] = data;
          const person = this.utilsSvc.createPerson(details);
          return [
            new personDetailsActions.LoadPersonDetailsSuccess({
              data: person,
              relatedCardIds: cardIds,
              relatedMatterIds: matterIds,
            }),
          ];
        }),
        catchError((error) => of(new personDetailsActions.LoadPersonDetailsFail({ error })))
      );
    })
  ));


  savePersonDetails$ = createEffect(() => this.actions$.pipe(
    ofType<personDetailsActions.Save>(personDetailsActions.PersonDetailsActionTypes.SAVE),
    withLatestFrom(
      this.store.pipe(select(selectPersonDetailsInvalidControls)),
      this.store.pipe(select(selectPersonDetailsFormValue)),
      this.store.pipe(select(selectIsPersonDetailsModified)),
      of(this.authService.decodedToken?.userId),
      (action, invalidControls, formValue, isPersonDetailsModified, userId) => ({
        successActions: action.payload?.successActions ?? [],
        updateType: action.payload?.updateType || 'save',
        invalidControls,
        formValue: {
          ...formValue,
          userId
        },
        isPersonDetailsModified,
      })
    ),
    switchMap((data) => {
      const { successActions, updateType, invalidControls, formValue, isPersonDetailsModified } = data;

      // check if there is any validation errors
      if (!!invalidControls && invalidControls.length > 0) {
        return [
          new personDetailsActions.SaveFail({ error: PersonErrors[invalidControls[0]] || PersonErrors['default'] }),
        ];
      }

      if (isPersonDetailsModified) {

        return this.personSvc.savePersons([formValue]).pipe(
          mergeMap(() => [
              ...successActions,
              new personListActions.PersonListMetaSaveDbStart({ selectedPersonId: formValue.__personId}),
              new personDetailsActions.SaveSuccess({
                message: this.translateSvc.instant('Person.Update.Success.Message', { action: updateType}),
              }),
            ]
          ),
          catchError((errorResponse) => {
            let actions: any[];
            const invalidControl = errorResponse.error.invalidControl;
            if (!!invalidControl && PersonErrors[invalidControl]) {
              actions = [
                new personDetailsActions.AddPersonDetailsInvalidControls({ names: [invalidControl] }),
                new personDetailsActions.SaveFail({ error: PersonErrors[invalidControl] }),
              ];
            } else {
              actions = [new personDetailsActions.SaveFail({ message: `Unable to ${updateType} person.` })];
            }
            return actions;
          })
        );
      } else {
        return [new personDetailsActions.SetPersonProcessingStatus({ processing: false })];
      }
    })
  ));


  succeed$ = createEffect(() => this.actions$.pipe(
    ofType<personDetailsActions.SaveSuccess>(personDetailsActions.PersonDetailsActionTypes.SAVE_SUCCESS),
    tap((action) => {
      if (!!action.payload && !!action.payload.message) {
        this.toastrSvc.show(action.payload.message, 'Success', {}, 'success');
      }
    })
  ), { dispatch: false });


  fail$ = createEffect(() => this.actions$.pipe(
    ofType<personDetailsActions.SaveFail>(personDetailsActions.PersonDetailsActionTypes.SAVE_FAIL),
    switchMap((action) => {
      if (!!action.payload) {
        let message: string;
        if (!!action.payload && !!action.payload.error) {
          message = action.payload.error.message || this.translateSvc.instant('Person.Update.Error.Message');
        } else {
          message =
            (action.payload && action.payload.message) || this.translateSvc.instant('Person.Update.Error.Message');
        }

        // re-throw error and let error-handler.service to handle this type of error
        return throwError(() =>
          new SiriusError({
            type: 'error',
            title: 'Person Save Error',
            message,
          })
        );
      }
      // if there is no payload, we return an empty action
      return of([]);
    })
  ), { dispatch: false });


  resetPerson$ = createEffect(() => this.actions$.pipe(
    ofType<personDetailsActions.RequestReset>(personDetailsActions.PersonDetailsActionTypes.REQUEST_RESET),
    withLatestFrom(
      this.store.pipe(select(selectIsPersonDetailsModified)),
      (action, isPersonDetailsModified) => isPersonDetailsModified
    ),
    rxjsFilter((isPersonDetailsModified) => !!isPersonDetailsModified),
    tap(() => {
      this.dialogSvc.confirm({
        title: this.translateSvc.instant('Person.Reset.Confirm.Title'),
        message: this.translateSvc.instant('Person.Reset.Confirm.Message'),
        showCancel: true,
        actionText: this.translateSvc.instant('Person.Reset.Confirm.Action.Text'),
        closeText: this.translateSvc.instant('Person.Reset.Confirm.Close.Text'),
        onClose: (discardConfirmed) => {
          if (discardConfirmed) {
            this.store.dispatch(new personDetailsActions.Reset(null));
          }
        },
      });
    })
  ), { dispatch: false });


  loadExistingPerson$ = createEffect(() => this.actions$.pipe(
    ofType<personDetailsActions.LoadExistingPerson>(personDetailsActions.PersonDetailsActionTypes.LOAD_EXISTING_PERSON),
    switchMap(async (action) => {
      const { id, successActions } = action.payload;
      const personEntry = await this.personListStorageSvc.get(id);
      return { personEntry, successActions: successActions ?? [] };
    }),
    rxjsFilter(({personEntry}) => !!personEntry),
    mergeMap(({personEntry, successActions}) => [
      ...successActions,
      new personListActions.PersonListMetaSaveDbStart({ selectedPersonId: personEntry.personId }),
      new personDetailsActions.LoadPersonDetailsStart({ id: personEntry.personId }),
    ])
  ));

  setPersonDeleteCode$ = createEffect(() => this.actions$.pipe(
    ofType<personDetailsActions.SetPersonDeleteCode>(personDetailsActions.PersonDetailsActionTypes.SET_PERSON_DELETE_CODE),
    withLatestFrom(
      this.store.pipe(select(selectPersonDetailsRelatedCardIds)),
      this.cardListStorageSvc.getAllStream(),
      (action, relatedCardIds, allCards) => ({deletePerson: action.payload, relatedCards: allCards.filter((card) => relatedCardIds.includes(card.cardId))})
    ),
    tap(({deletePerson, relatedCards}) => {
      if (deletePerson && !!relatedCards.length) {
        this.dialogSvc.error({
          title: this.translateSvc.instant(`Person.Delete.Error.Title`),
          message: this.translateSvc.instant(`Person.Delete.Error.Message`, {
            numCards: relatedCards.length,
            cardNames: relatedCards.map((card) => card.fullName).join(', ')
          }),
          actionText: this.translateSvc.instant('Core.Button.Close'),
        });
        return;
      }

      this.dialogSvc.confirm({
        title: this.translateSvc.instant(`Person.${deletePerson ? 'Delete' : 'Undelete'}.Confirm.Title`),
        message: this.translateSvc.instant(`Person.${deletePerson ? 'Delete' : 'Undelete'}.Confirm.Message`),
        actionText: this.translateSvc.instant(`Core.Button.${deletePerson ? 'Delete' : 'Undelete'}`),
        closeText: this.translateSvc.instant(`Core.Button.Cancel`),
        showCancel: true,
        onClose: (confirm) => {
          if(confirm) {
            this.store.dispatch(new personDetailsActions.UpdateFormValue({data: { deleteCode: deletePerson ? 1 : 0 }}));
            this.store.dispatch(new personDetailsActions.Save({
              updateType: deletePerson ? 'delete' : 'restore'
            }));
          }
        }
      });
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private translateSvc: TranslateService,
    private toastrSvc: ToastrService,
    private personSvc: PersonService,
    private personListStorageSvc: PersonListStorageService,
    private cardListStorageSvc: CardListStorageService,
    private dialogSvc: DialogService,
    private utilsSvc: PersonUtilsService,
    private authService: AuthService
  ) {}
}
