import { Action } from '@ngrx/store';
import { IReportingInput, IReportPreviewDownloadParams } from '@app/features/+reporting/models';

export const REPORTING_GET_PREVIEW_URL_START = '[Reporting] get preview url start';
export const REPORTING_GET_PREVIEW_URL_SUCCESS = '[Reporting] get preview url success';
export const REPORTING_GET_PREVIEW_URL_FAILURE = '[Reporting] get preview url failure';

export const REPORTING_SAVE_TO_MATTER_START = '[Reporting] save to matter start';
export const REPORTING_SAVE_TO_MATTER_SUCCESS = '[Reporting] save to matter success';
export const REPORTING_SAVE_TO_MATTER_FAILURE = '[Reporting] save to matter failure';

export const REPORTING_DOWNLOAD = '[Reporting] Download';
export const REPORTING_DOWNLOAD_INPROGRESS = '[Reporting] Download in progress';
export const REPORTING_DOWNLOAD_SUCCESS = '[Reporting] Download success';
export const REPORTING_DOWNLOAD_ERROR = '[Reporting] Download error';

export const REPORTING_PREVIEW_EMAIL = '[Reporting] preview email';

export const REPORTING_PREVIEW_PRINT = '[Reporting] preview print';
export const REPORTING_PREVIEW_PRINT_SUCCESS = '[Reporting] preview success';
export const REPORTING_PREVIEW_PRINT_ERROR = '[Reporting] preview error';

export const REPORTING_PREVIEW = '[Reporting] preview';
export const REPORTING_PREIVEW_PDF_ERROR = '[Reporting] preview load error';

export const REPORTING_CLEAR = '[Reporting] clear';

export class ReportingGetPreviewUrlStart implements Action {
  readonly type = REPORTING_GET_PREVIEW_URL_START;
  constructor(public payload: IReportingInput) {}
}

export class ReportingGetPreviewUrlSuccess implements Action {
  readonly type = REPORTING_GET_PREVIEW_URL_SUCCESS;
  constructor(public payload: string) {}
}

export class ReportingGetPreviewUrlFailure implements Action {
  readonly type = REPORTING_GET_PREVIEW_URL_FAILURE;
  constructor(public payload: any) {}
}

export class ReportingSaveToMatterStart implements Action {
  readonly type = REPORTING_SAVE_TO_MATTER_START;
  constructor(public payload: any) {}
}

export class ReportingSaveToMatterSuccess implements Action {
  readonly type = REPORTING_SAVE_TO_MATTER_SUCCESS;
  constructor(public payload: any) {}
}

export class ReportingSaveToMatterFailure implements Action {
  readonly type = REPORTING_SAVE_TO_MATTER_FAILURE;
  constructor(public payload: any) {}
}

export class ReportingDownload implements Action {
  readonly type = REPORTING_DOWNLOAD;
  constructor(public payload: any) {}
}

export class ReportingDownloadInProgress implements Action {
  readonly type = REPORTING_DOWNLOAD_INPROGRESS;
  constructor(public payload: IReportPreviewDownloadParams) {}
}

export class ReportingDownloadSuccess implements Action {
  readonly type = REPORTING_DOWNLOAD_SUCCESS;
  constructor(public payload: any) {}
}

export class ReportingDownloadError implements Action {
  readonly type = REPORTING_DOWNLOAD_ERROR;
  constructor(public payload: any) {}
}

export class ReportingPreviewEmail implements Action {
  readonly type = REPORTING_PREVIEW_EMAIL;
  constructor(public payload: any) {}
}

export class ReportingPreviewPrint implements Action {
  readonly type = REPORTING_PREVIEW_PRINT;
  constructor(public payload: any) {}
}

export class ReportingPreviewPrintSuccess implements Action {
  readonly type = REPORTING_PREVIEW_PRINT_SUCCESS;
  constructor(public payload: any) {}
}

export class ReportingPreviewPrintFailure implements Action {
  readonly type = REPORTING_PREVIEW_PRINT_ERROR;
  constructor(public payload: any) {}
}

export class ReportingPreview implements Action {
  readonly type = REPORTING_PREVIEW;
  constructor(public payload: any) {} // route state uses in navigation as extras input.s
}

export class ReportingLoadError implements Action {
  readonly type = REPORTING_PREIVEW_PDF_ERROR;
  constructor(public payload: any) {}
}

export class ReportingClear implements Action {
  readonly type = REPORTING_CLEAR;
  constructor(public payload: any) {}
}

export type ReportingActions =
  | ReportingGetPreviewUrlStart
  | ReportingGetPreviewUrlSuccess
  | ReportingGetPreviewUrlFailure
  | ReportingSaveToMatterStart
  | ReportingSaveToMatterSuccess
  | ReportingSaveToMatterFailure
  | ReportingDownload
  | ReportingDownloadInProgress
  | ReportingDownloadSuccess
  | ReportingDownloadError
  | ReportingPreviewEmail
  | ReportingPreviewPrint
  | ReportingPreviewPrintSuccess
  | ReportingPreviewPrintFailure
  | ReportingPreview
  | ReportingLoadError
  | ReportingClear;
