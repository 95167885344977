export enum BillingFilterType {
  All = 1,
  BillableAll = 2,
  BillableNextInvoice = 3,
  BillableLaterInvoice = 4,
  NotBillable = 5,
  BilledAll = 6,
  Deleted = 7,
  BilledFullyReceipted = 8,
}

export enum PaymentFilterType {
  All = 1,
  Payable = 2,
  NotPayable = 3,
  Paid = 4,
}

export enum BillingMode {
  BillableNextInvoice = 0,
  BillableLaterInvoice = 1,
  NotBillable = -1,
}

export enum BillingModeSelectId {
  All = 5,
  BillableAll = 10,
  BillableNextInvoice = 20,
  BillableLaterInvoice = 30,
  NotBillable = 40,
  BilledAll = 50,
  BilledFullyReceipted = 60,
  Deleted = 70,
}

export enum PaymentMode {
  NotApplicable = -1,
  Payable = 0,
  NotPayable = 1,
}

export enum PaymentModePayId {
  All = 10,
  Payable = 20,
  NotPayable = 30,
  Paid = 40,
  PartPaid = 50,
}

export interface IAccountingStatusUI {
  translateKey: string;
  class: string;
}

export interface IBillingMode {
  id: number;
  name: string;
  value: BillingMode;
}

export interface IPaymentMode {
  id: number;
  name: string;
  value: PaymentMode;
}
