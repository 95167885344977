import { Hotkey } from 'angular2-hotkeys';
import { Subject } from 'rxjs';

/** Dropdowns **/
export interface ILeapDropdownMoreAction {
  text: string;
  callback: () => void;
}

export type ISelectedTextFunc = (item?: unknown) => string;

export type IShortSelectedTextFunc = (item?: unknown) => string;

export interface ILeapDropdownOptions {
  items: any[];
  itemClassRule?: ((item: any) => any) | any; // object passed to [ngClass]
  itemStyleRule?: ((item: any) => any) | any; // object passed to [ngStyle]
  itemClass?: (item: any) => string;
  itemLabel: (item: any) => string;
  itemClicked?: (item: any) => void; // overwriting default onChange function
  itemLabelVisible?: (item: any) => boolean;
  itemSelectable?: (item: any) => boolean;

  moreAction?: ILeapDropdownMoreAction;

  selectedText: ISelectedTextFunc | string;
  shortSelectedText?: IShortSelectedTextFunc | string;

  iconButton?: boolean;
  iconClass?: string;
  buttonClass?: string;
  wrapperClass?: string;
  dropdownContainer?: string;
  dropdownPlacement?: string;
  dropdownMenuClass?: string;
  dropdownMenuStyle?: any;
}

export interface IDropdownOption<T> {
  label: string;
  value: T;
}
/** End - Dropdowns **/

// Used for pop-over context-menu (e.g. calendar)
export interface ILeapContextMenuOptions<T = unknown, U = unknown> {
  header?: string;
  headerDescription?: string;
  headerIcon?: string;

  contents?: U[];
  contentVisible?: (content: U) => boolean;
  contentLabel?: (content: U) => string;
  contentText?: (content: U) => string;

  items: T[];
  itemType: (item: T) => 'action' | 'divider';
  itemText?: (item: T) => string;
  itemVisible?: (item: T) => boolean;
  itemClicked?: (item: T) => void;
}

// Dynamic ngx-contextmenu action
export interface IMenuItem<T> {
  execute?: (item: T) => void;
  divider?: boolean;
  enabled?: (item: T) => boolean;
  text?: (item: T) => string;
  visible?: (item: T) => boolean;
  subMenu?: IMenuItem<T>[];
}

/** Tags **/
export interface ILeapTag<T = unknown> {
  item: T;
  iconClass?: string;
}

export interface ILeapTagsOptions<T = unknown> {
  items: ILeapTag<T>[];
  itemClicked?: (item: T) => void;
  itemLabel: (item: T) => string;
  itemRemoved?: (item: T) => void;

  readonly?: boolean;
  wrapperClass?: string;
}
/** End - Tags **/

export interface ISortInfo {
  colId: string;
  sort: string;
}

export interface IKeytrapEventMap {
  key: KeyboardCode;
  handler: () => void;
}

export type KeyboardCode =
  | 'enter'
  | 'backspace'
  | 'return'
  | 'capslock'
  | 'meta'
  | 'left'
  | 'up'
  | 'right'
  | 'down'
  | 'pageup'
  | 'pagedown'
  | 'end'
  | 'home'
  | 'plus'
  | 'shift'
  | 'ctrl'
  | 'alt'
  | 'tabs'
  | 'esc'
  | 'escape'
  | 'space'
  | 'ins'
  | 'del';

export interface IKeyPressManager {
  id: KeyPressManagerId;
  code: KeyboardCode;
  hotkey: Hotkey | Hotkey[];
  keyPressSubject: Subject<KeyboardCode>;
  order: number;
}

export type KeyPressManagerId = 'dialog' | 'popup' | 'selector' | 'overlay' | 'selectorDetail';

export type SupportedKeyCode = 'left' | 'up' | 'right' | 'down' | 'backspace' | 'escape' | 'tab' | 'enter';

export const AggridKeyboardCode: { [keyboardCode in SupportedKeyCode]: string } = {
  left: 'ArrowLeft',
  up: 'ArrowUp',
  right: 'ArrowRight',
  down: 'ArrowDown',
  backspace: 'Backspace',
  escape: 'Escape',
  tab: 'Tab',
  enter: 'Enter'
};

export const FileType = {
  Folder: 'folder',
};
