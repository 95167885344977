import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ITimeFeeGroupBy, ITimeFeeSummary, ITimeFeeSummaryVM } from '@app/features/+time-fee-ledger/models';
import { ISortInfo } from '@app/shared/models';
import { GroupByNone } from '@app/features/+time-fee-ledger/constants';
import * as actions from '../actions/time-fee';

export interface TimeFeeState extends EntityState<ITimeFeeSummary> {
  // additional entities state properties
  id: string;
  newFeeEntry: boolean;
  newTimeEntry: boolean;
  lastRowVer: number;
  total: number;
  error: any | string | null;
  loading: boolean;
  dbSaveStatus: string;
  sortInfo: ISortInfo;
  timeFeeGroupBy: ITimeFeeGroupBy;
  timeFeeFilterBy: string;
  incTax: boolean;
  checkedTimeFeeEntries: ITimeFeeSummary[];
  checkedTimeFeeEntryIds: string[];
}

export const selectId = (timeFee: ITimeFeeSummary): string => timeFee.FeeGUID;

export const adapter: EntityAdapter<ITimeFeeSummary> = createEntityAdapter<ITimeFeeSummary>({
  selectId,
  sortComparer: false,
});

export const initialState: TimeFeeState = adapter.getInitialState({
  // additional entity state properties
  id: '',
  newFeeEntry: false,
  newTimeEntry: false,
  lastRowVer: 0,
  total: 0,
  error: null,
  loading: false,
  dbSaveStatus: '',
  sortInfo: {
    colId: 'data.TransactionDate',
    sort: 'asc',
  },
  timeFeeGroupBy: GroupByNone,
  timeFeeFilterBy: '',
  incTax: false,
  checkedTimeFeeEntries: [],
  checkedTimeFeeEntryIds: [],
});

export const timeFeeReducer = (
  state = initialState,
  action: actions.TimeFeeApiActions | actions.TimeFeeDbActions | actions.TimeFeeActions
): TimeFeeState => {
  switch (action.type) {
    case actions.STORE_CHECKED_TIME_FEE_ENTRIES: {
      return {
        ...state,
        checkedTimeFeeEntries: action.payload,
        checkedTimeFeeEntryIds: action.payload.map(({FeeGUID}) => FeeGUID)
      };
    }
    case actions.TIME_FEE_NEW_FEE_ENTRY_START: {
      return {
        ...state,
        newFeeEntry: true,
      };
    }

    case actions.TIME_FEE_NEW_FEE_ENTRY_SUCCESS: {
      return {
        ...state,
        newFeeEntry: false,
      };
    }

    case actions.LIST_TIME_FEE_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.LIST_TIME_FEE_SUCCESS: {
      return {
        ...adapter.setAll(action.payload, state),
        lastRowVer: 0,
        total: action.payload.length,
        loading: false,
      };
    }

    case actions.LIST_TIME_FEE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case actions.TIME_FEE_SAVE_DB_START: {
      return {
        ...state,
        dbSaveStatus: 'start',
      };
    }

    case actions.TIME_FEE_SAVE_DB_SUCCESS:
    case actions.TIME_FEE_SAVE_DB_FAILURE: {
      return {
        ...state,
        dbSaveStatus: action.payload,
      };
    }

    case actions.TIME_FEE_NEW_TIME_ENTRY_START: {
      return {
        ...state,
        newTimeEntry: true,
      };
    }

    case actions.TIME_FEE_NEW_TIME_ENTRY_SUCCESS: {
      return {
        ...state,
        newTimeEntry: false,
      };
    }

    case actions.TIME_FEE_LIST_META_SAVE_DB_START: {
      return {
        ...state,
        ...action.payload,
        dbSaveStatus: 'start',
      };
    }

    case actions.TIME_FEE_LIST_META_SAVE_DB_SUCCESS:
    case actions.TIME_FEE_LIST_META_SAVE_DB_FAILURE: {
      return {
        ...state,
        dbSaveStatus: action.payload,
      };
    }

    case actions.TIME_FEE_RESET: {
      return adapter.setAll([], state);
    }

    default: {
      return state;
    }
  }
};
