import { Action } from '@ngrx/store';
import { Events } from '@app/features/+calendar/models/events.models';
import { CriticalDate } from '@app/features/+calendar/models/critical-date.models';

// Load Calendar event Start
export const LOAD_EVENTS_START = '[calendar] load events start';
export const LOAD_EVENTS_SUCCESS = '[calendar] load events success';
export const LOAD_EVENTS_FAILURE = '[calendar] load events failure';

// Load Calendar event Start
export const GET_CRITICAL_DATES_START = '[calendar] get critical-dates start';
export const GET_CRITICAL_DATES_SUCCESS = '[calendar] get critical-dates success';
export const GET_CRITICAL_DATES_FAILURE = '[calendar] get critical-dates failure';

export const CLEAR_ERROR_STATE = '[calendar] clear calendar state error';
export const SET_VIEW_HISTORY_FLAG = '[calendar] set view history for main page';
export const SET_VIEW_HIDDEN_CRITICAL_DATE = '[calendar] set view default critical dates for main page';

export class LoadEventsStart implements Action {
  readonly type = LOAD_EVENTS_START;
  constructor(public payload: any) {}
}

export class LoadEventsSuccess implements Action {
  readonly type = LOAD_EVENTS_SUCCESS;
  constructor(public payload: Events) {}
}

export class LoadEventsFailure implements Action {
  readonly type = LOAD_EVENTS_FAILURE;
  constructor(public payload: any) {}
}

export class GetCriticalDatesStart implements Action {
  readonly type = GET_CRITICAL_DATES_START;
  constructor(public payload: any) {}
}

export class GetCriticalDatesSuccess implements Action {
  readonly type = GET_CRITICAL_DATES_SUCCESS;
  constructor(public payload: CriticalDate[]) {}
}

export class GetCriticalDatesFailure implements Action {
  readonly type = GET_CRITICAL_DATES_FAILURE;
  constructor(public payload: any) {}
}

export class ClearErrorState implements Action {
  readonly type = CLEAR_ERROR_STATE;
  constructor(public payload: any) {}
}

export class SetViewHistoryFlag implements Action {
  readonly type = SET_VIEW_HISTORY_FLAG;
  constructor(public payload: boolean) {}
}

export class SetViewHiddenCriticalDateFlag implements Action {
  readonly type = SET_VIEW_HIDDEN_CRITICAL_DATE;
  constructor(public payload: boolean) {}
}

export type CalendarEventActions =
  | GetCriticalDatesStart
  | GetCriticalDatesSuccess
  | GetCriticalDatesFailure
  | LoadEventsStart
  | LoadEventsSuccess
  | LoadEventsFailure
  | ClearErrorState
  | SetViewHistoryFlag
  | SetViewHiddenCriticalDateFlag;
