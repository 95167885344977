import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'sc-ghost-list',
  templateUrl: './ghost-list.component.html',
  styleUrls: ['./ghost-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GhostListComponent implements OnInit {
  private _size = 10;
  @Input()
  set size(val: number) {
    if (val !== this._size) {
      this._size = val;
      this.list = new Array<any>(val).fill(null);
    }
  }

  @Input() itemWrapperClass: string;

  @Input() itemWrapperStyle: any;

  public list: Array<any> = new Array<any>(this._size).fill(null);

  constructor() {}

  ngOnInit() {}
}
