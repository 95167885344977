import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
import { distinctUntilChanged, skip, takeUntil } from 'rxjs/operators';

import { IFooterFormStatus, IFooterFormData } from '@app/features/+invoice/models';
import { DialogService } from '@app/shared/services';
import { isObjEqual } from '@server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-invoice-footer',
  templateUrl: './invoice-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceFooterComponent implements OnInit, OnDestroy {
  @Input()
  set status(data: IFooterFormStatus) {
    this._status = { ...this._status, ...data } as IFooterFormStatus;
  }
  get status() {
    return this._status;
  }

  @Input()
  set inputData(data: Partial<IFooterFormData>) {
    if (!!this.footerForm && !!data) {
      this.footerForm.patchValue(data as any);
    }
  }

  @Input() invoicePrintProcessing: boolean;

  @Output() onFooterFormUpdated = new EventEmitter<IFooterFormData>();

  @Output() onDelete = new EventEmitter<void>();

  @Output() onPrint = new EventEmitter<void>();

  private _status: IFooterFormStatus;
  private unsub = new Subject<void>();

  public footerForm: FormGroup;

  constructor(private _dialogSvc: DialogService, private _formBuilder: FormBuilder, private cdr: ChangeDetectorRef) {
    this._status = {
      printOnSaveHidden: false,
      deleteHidden: true,
      deleteDisabled: false,
      deleteLoading: false,
    };
    this.createForm();
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  delete(): void {
    this._dialogSvc.confirm({
      message: 'Would you like to delete this draft invoice?',
      showCancel: true,
      onClose: (confirmed: boolean) => {
        if (confirmed) {
          this.onDelete.emit();
          this.cdr.markForCheck();
        }
      },
    });
  }

  print(): void {
    this.onPrint.emit();
    this.cdr.markForCheck();
  }

  private createForm(): void {
    this.footerForm = this._formBuilder.group({
      printOnSave: false,
    });
    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges(): void {
    this.footerForm.valueChanges
      .pipe(distinctUntilChanged(isObjEqual), skip(1), takeUntil(this.unsub))
      .subscribe((formData: IFooterFormData) => {
        this.onFooterFormUpdated.emit(formData);
        this.cdr.markForCheck();
      });
  }
}
