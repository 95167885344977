import { Action } from '@ngrx/store';
import { EmailSignature } from '../../models';

// Get Signature
export const GET_SIGNATURE = '[Signature] fetch';
export const GET_SIGNATURE_SUCCESS = '[Signature] fetch success';
export const GET_SIGNATURE_FAILURE = '[Signature] fetch failure';

// Edit or Add Signature
export const SAVE_SIGNATURE = '[Signature] Save/Update';
export const SAVE_SIGNATURE_SUCCESS = '[Signature] Save/Update success';
export const SAVE_SIGNATURE_FAILURE = '[Signature] Save/Update failure';

export class GetSignature implements Action {
  readonly type = GET_SIGNATURE;
  constructor(public payload: any) {}
}

export class GetSignatureSuccess implements Action {
  readonly type = GET_SIGNATURE_SUCCESS;
  constructor(public payload: EmailSignature) {}
}

export class GetSignatureFailure implements Action {
  readonly type = GET_SIGNATURE_FAILURE;
  constructor(public payload: any) {}
}

export class SaveSignature implements Action {
  readonly type = SAVE_SIGNATURE;
  constructor(public payload: EmailSignature) {}
}

export class SaveSignatureSuccess implements Action {
  readonly type = SAVE_SIGNATURE_SUCCESS;
  constructor(public payload: EmailSignature) {}
}

export class SaveSignatureFailure implements Action {
  readonly type = SAVE_SIGNATURE_FAILURE;
  constructor(public payload: any) {}
}

export type SignatureActions =
  | GetSignature
  | GetSignatureSuccess
  | GetSignatureFailure
  | SaveSignature
  | SaveSignatureSuccess
  | SaveSignatureFailure;
