import { createFeatureSelector } from '@ngrx/store';

import * as fromMatterTypes from './matter-types.reducer';

export type State = fromMatterTypes.State;

export const initialState: State = fromMatterTypes.initialState;

export const reducers = fromMatterTypes.reducer;

export const selectMatterTypesState = createFeatureSelector<State>('matterTypes');
