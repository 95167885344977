import { Action } from '@ngrx/store';
import { INamedLists } from '@app/features/+card/models';

export enum NamedListDbActionTypes {
  NAMEDLISTS_SAVE_DB_START = '[NamedList save to db] start',
  NAMEDLISTS_SAVE_DB_SUCCESS = '[NamedList save to db] success',
  NAMEDLISTS_SAVE_DB_FAILURE = '[NamedList save to db] failure',
}

export class NamedListSaveDbStart implements Action {
  readonly type = NamedListDbActionTypes.NAMEDLISTS_SAVE_DB_START;

  constructor(public payload: INamedLists) {}
}

export class NamedListSaveDbSuccess implements Action {
  readonly type = NamedListDbActionTypes.NAMEDLISTS_SAVE_DB_SUCCESS;

  constructor(public payload: string) {}
}

export class NamedListSaveDbFailure implements Action {
  readonly type = NamedListDbActionTypes.NAMEDLISTS_SAVE_DB_FAILURE;

  constructor(public payload: string) {}
}

export type NamedListDbActions = NamedListSaveDbStart | NamedListSaveDbSuccess | NamedListSaveDbFailure;
