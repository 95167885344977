<div *ngFor="let addin of addins$ | async">
  <h6 class="x-aside-subheading">{{ addin.Name }}</h6>
  <div class="x-list-group-action">
    <ul class="list-group">
      <ng-container *ngFor="let button of addin.Button">
        <sc-addin-button [addinButton]="button" (onButtonClicked)="onClicked($event, addin)"></sc-addin-button>
      </ng-container>
    </ul>
  </div>
</div>
