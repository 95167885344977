import { createAction, props } from '@ngrx/store';
import { IMatterSearchState } from '../../../models';

// Converted actions using createAction

export const searchSaveDbStart = createAction(
  '[Search save to db] start',
  props<{ payload: IMatterSearchState }>()
);

export const searchSaveDbSuccess = createAction(
  '[Search save to db] success',
  props<{ payload: string }>()
);

export const searchSaveDbFailure = createAction(
  '[Search save to db] failure',
  props<{ payload: string }>()
);

export const loadMattersSearchStart = createAction(
  '[Load matter search] start',
  props<{ payload: any }>()
);

export const loadMattersSearchSuccess = createAction(
  '[Load matter search] success',
  props<{ payload: IMatterSearchState }>()
);
