import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { BaseService } from 'app/shared/services/index';

import {
  IDocCombinePDFHttpRequestParams,
  IDocCombinePDFHttpResponseSchema,
  IConvertedPdfDocument,
  PDFDocumentsActions,
} from 'app/features/+create-pdf/models/pdfoptions.model';

import { Attachment, AttachmentType } from 'app/features/+email/models/index';
import { Store, select } from '@ngrx/store';
import { CreatePDFState } from '@app/features/+create-pdf/store/reducers';
import { DefaultToPdfDocumentType } from '@app/features/+create-pdf/models';
import { DocumentIconHelper } from '@app/shared/utils';
import { AppState } from '@app/core/store/reducers';
import { selectOfficeOnline } from '@app/core/store/selectors/app-state.selectors';
import { IDoc } from '@app/shared/models';
import { isEmptyValue } from '@server/modules/shared/functions/common-util.functions';

@Injectable({
  providedIn: 'root',
})
export class PdfDocumentService extends BaseService {
  private url_: string;
  private _officeOnline$: Observable<boolean>;
  private _documentHelper: DocumentIconHelper;

  constructor(private http: HttpClient, private _store: Store<AppState>) {
    super();

    // this.url_ = `${this.pdfPath}/api/documents/pdf`;
    this.url_ = `${environment.config.endpoint.pdfservice}/api/documents/pdf`;
    this._officeOnline$ = this._store.pipe(select(selectOfficeOnline));
    this._documentHelper = new DocumentIconHelper();
  }

  createPDFDocuments(combinedPDFParams: IDocCombinePDFHttpRequestParams): Observable<IDocCombinePDFHttpResponseSchema> {
    return this.http
      .post(this.url_, combinedPDFParams)
      .pipe(map((response: IDocCombinePDFHttpResponseSchema) => response));
  }

  getUrl(): string {
    return this.url_;
  }

  // private
  buildPDFRequestParams(
    matterId: string,
    folderId: string,
    state: CreatePDFState,
    docs: IDoc[],
    saveToSuperDiary: boolean
  ): Observable<IDocCombinePDFHttpResponseSchema> {
    const request = this.buildPdfParams(matterId, folderId, state, docs, saveToSuperDiary);
    return this.createPDFDocuments(request);
  }

  buildPdfParams(
    matterId: string,
    folderId: string,
    state: CreatePDFState,
    docs: IDoc[],
    saveToSuperDiary: boolean
  ): IDocCombinePDFHttpRequestParams {
    return {
      MatterId: matterId,
      Documents:
        docs.length > 0
          ? docs.map((x: IDoc) => {
              const { id, latestVersion, name, ext } = x;
              return {
                filename: latestVersion || id,
                name,
                type: ext || DefaultToPdfDocumentType,
              };
            })
          : [],
      FolderId: folderId,
      Combine: state.combine,
      SaveToMatter: state.saveToMatter,
      SaveToSuperDiaryTempStorage: saveToSuperDiary,
      Secure: state.secure,
      AddBookmarks: true,
      CombinedDocumentsName: state.combine ? `${state.combineddocname}` : undefined,
    };
  }

  mapCorrespondenceToAttachments(documents: IDoc[]): Attachment[] {
    return documents.map((document: IDoc) => {
      const iconInfo = this._documentHelper.getDocumentIconInfo(document);
      const extension = !!document.ext ? document.ext : DefaultToPdfDocumentType;
      return Object.assign<unknown, Attachment>(
        {},
        {
          id: document.id,
          latestVersion: document.latestVersion,
          name: `${document.name}.${extension}`,
          type: AttachmentType.Correspondence, // The type should always be Correspondence
          iconClass: iconInfo.iconClass,
          tooltip: iconInfo.tooltip,
          ext: document.ext, // needs to be the same as returned by docsApi for LawConnect unsupported error to work
        }
      );
    });
  }

  mapConvertedToAttachments(
    documents: IConvertedPdfDocument[],
    saveToMatter: boolean,
    saveToSuperDiary?: boolean,
    combineDoc?: string
  ): Attachment[] {
    return documents.map((document: IConvertedPdfDocument) => {
      const iconInfo = this._documentHelper.getIconInfo('pdf');
      return Object.assign<unknown, Attachment>(
        {},
        {
          id: document.DocumentId,
          latestVersion: document.DocumentId, // LS-861
          name: isEmptyValue(document.FileName)
            ? `${combineDoc}.pdf`
            : document.FileName.includes('pdf')
            ? `${document.FileName}`
            : `${document.FileName}.pdf`,
          // AUTO-250 - If SaveToMatter is false, Automation moves the converted file to SuperDiary
          type: saveToMatter && !saveToSuperDiary ? AttachmentType.Correspondence : AttachmentType.File,
          iconClass: iconInfo.iconClass,
          tooltip: iconInfo.tooltip,
        }
      );
    });
  }
}

export const getPDFConvertedAction = (): string => PDFDocumentsActions.Converted;

export const getPDFNewEmailAction = (): string => PDFDocumentsActions.CreatePDFEmail;

export const getPDFAttachAction = (): string => PDFDocumentsActions.Attach;

export const getPDFCalendarAttachAction = (): string => PDFDocumentsActions.AttachCalendar;

export const getConvertToPDFAction = (): string => PDFDocumentsActions.ConvertToPDF;
