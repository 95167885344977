import { Component, HostBinding } from '@angular/core';
import { AgFrameworkComponent } from '@ag-grid-community/angular';
import { isFunction } from '@server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-trust-receipt-summary-cell',
  templateUrl: './trust-receipt-summary-cell.component.html',
})
export class TrustReceiptSummaryCellComponent implements AgFrameworkComponent<any> {
  public isNewLineHidden: boolean;
  public onNewLineClicked: () => void;

  constructor() {}

  @HostBinding('class')
  classes = '';
  agInit(params: any) {
    this.isNewLineHidden = params['isNewLineHidden'] || false;
    this.onNewLineClicked = isFunction(params['onNewLineClicked']) ? params['onNewLineClicked'] : () => {};
  }

  newLine(): void {
    this.onNewLineClicked();
  }
}
