import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator } from '@angular/forms';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PlatformService } from '@app/core/services';
import { EditorService } from '@app/shared/components/editor/editor.service';
import { EditorMode } from '@app/features/+email/models';
import { MailFontPreferences } from '@app/core/constants/preferences.constant';

declare let Quill: any;
const fontSelection = [
  { id: 'arial', name: MailFontPreferences.Arial },
  { id: 'calibri', name: MailFontPreferences.Calibri },
  { id: 'monospace', name: MailFontPreferences.Monospace },
  { id: 'sans-serif', name: MailFontPreferences.SansSerif },
  { id: 'serif', name: MailFontPreferences.Serif },
];

export interface CustomOption {
  import: string;
  whitelist: Array<any>;
}

@Component({
  selector: 'sc-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EditorComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class EditorComponent implements AfterViewInit, ControlValueAccessor, OnDestroy, OnChanges, Validator {
  @Input()
  mode: EditorMode = 1;
  @Input()
  theme: string;
  @Input()
  modules: { [index: string]: object };
  @Input()
  readOnly: boolean;
  @Input()
  placeholder: string;
  @Input()
  maxLength: number;
  @Input()
  minLength: number;
  @Input()
  required: boolean;
  @Input()
  formats: string[];
  @Input()
  style: any = {};
  @Input()
  strict = true;
  @Input()
  scrollingContainer: HTMLElement | string;
  @Input()
  bounds: HTMLElement | string;
  @Input()
  customOptions: CustomOption[] = [];

  @Input()
  set defaultFont(value: string) {
    if (!!value) {
      this._defaultFont = this.getFontValue(value);
      this.setEditorDefaultFont();
    }
  }
  get defaultFont() {
    return this._defaultFont;
  }

  @Output()
  onEditorCreated: EventEmitter<any> = new EventEmitter();
  @Output()
  onContentChanged: EventEmitter<any> = new EventEmitter();
  @Output()
  onSelectionChanged: EventEmitter<any> = new EventEmitter();

  editorElem: HTMLElement;
  content: any;
  selectionChangeEvent: any;
  textChangeEvent: any;
  fonts = fontSelection;

  private _quillEditor: any;
  private _defaultFont = this.getFontValue(MailFontPreferences.SansSerif.valueOf());
  private _textChangeTimer;

  onModelChange: (value: unknown) => void;
  onModelTouched: (value: unknown) => void;

  constructor(
    private elementRef: ElementRef,
    private ps: PlatformService,
    @Inject(DOCUMENT) private doc: any,
    private renderer: Renderer2,
    private _editorSvc: EditorService,
    private _zone: NgZone
  ) {}

  @HostBinding('class.layout-column')
  ngAfterViewInit() {
    if (this.ps.isServer) {
      return;
    }

    const toolbarElem = this.elementRef.nativeElement.querySelector('#x-editor-toolbar');
    // const modules: any = this.modules || this.defaultModules;
    const modules: any = this._editorSvc.getEditorConfig(this.mode);
    let placeholder = 'Insert text here ...';

    if (this.placeholder !== null && this.placeholder !== undefined) {
      placeholder = this.placeholder.trim();
    }

    if (toolbarElem && this.mode !== 4) {
      // mode 4 is for calendar apps and tasks where toolbar is not required.
      modules['toolbar'] = toolbarElem;
    }

    this.elementRef.nativeElement.insertAdjacentHTML('beforeend', '<div quill-editor-element></div>');
    this.editorElem = this.elementRef.nativeElement.querySelector('[quill-editor-element]');

    if (this.style) {
      Object.keys(this.style).forEach((key: string) => {
        this.renderer.setStyle(this.editorElem, key, this.style[key]);
      });
    }

    this.customOptions.forEach((customOption) => {
      const newCustomOption = Quill.import(customOption.import);
      newCustomOption.whitelist = customOption.whitelist;
      Quill.register(newCustomOption, true);
    });

    // Custom icons
    const customIcons = Quill.import('ui/icons');
    customIcons['align'][''] = '<svg class="x-icon"><use xlink:href="#align-left-grid-20"></use></svg>';
    customIcons['align']['center'] = '<svg class="x-icon"><use xlink:href="#align-center-grid-20"></use></svg>';
    customIcons['align']['justify'] = '<svg class="x-icon"><use xlink:href="#align-justify-grid-20"></use></svg>';
    customIcons['align']['right'] = '<svg class="x-icon"><use xlink:href="#align-right-grid-20"></use></svg>';
    customIcons['background'] =
      '<svg class="x-icon"><use class="ql-color-label" xlink:href="#background-color-grid-20"></use></svg>';
    customIcons['bold'] = '<svg class="x-icon"><use xlink:href="#bold-grid-20"></use></svg>';
    customIcons['clean'] = '<svg class="x-icon"><use xlink:href="#clean-format-grid-20"></use></svg>';
    customIcons['color'] =
      '<svg class="x-icon"><use class="ql-color-label" xlink:href="#text-color-grid-20"></use></svg>';
    customIcons['image'] = '<svg class="x-icon"><use xlink:href="#image-grid-20"></use></svg>';
    customIcons['italic'] = '<svg class="x-icon"><use xlink:href="#italic-grid-20"></use></svg>';
    customIcons['link'] = '<svg class="x-icon"><use xlink:href="#link-grid-20"></use></svg>';
    customIcons['list']['bullet'] = '<svg class="x-icon"><use xlink:href="#list-bullet-grid-20"></use></svg>';
    customIcons['list']['ordered'] = '<svg class="x-icon"><use xlink:href="#list-ordered-grid-20"></use></svg>';
    customIcons['print'] = '<svg class="x-icon"><use xlink:href="#print-grid-20"></use></svg>';
    customIcons['redo'] = '<svg class="x-icon"><use xlink:href="#redo-grid-20"></use></svg>';
    customIcons['strike'] = '<svg class="x-icon"><use xlink:href="#strike-grid-20"></use></svg>';
    customIcons['timestamp'] = '<svg class="x-icon"><use xlink:href="#timestamp-grid-20"></use></svg>';
    customIcons['underline'] = '<svg class="x-icon"><use xlink:href="#underline-grid-20"></use></svg>';
    customIcons['undo'] = '<svg class="x-icon"><use xlink:href="#undo-grid-20"></use></svg>';
    customIcons['font'] = '<svg class="x-icon"><use xlink:href="#link-grid-20"></use></svg>';

    // Custom fonts
    const fontAttributor = Quill.import('attributors/style/font');
    fontAttributor.whitelist = fontSelection.map((f) => f.id);
    Quill.register(fontAttributor);

    this._quillEditor = new Quill(this.editorElem, {
      modules,
      placeholder,
      readOnly: this.readOnly || false,
      theme: this.theme || 'snow',
      formats: this.formats,
      bounds: this.bounds || this.editorElem,
      strict: this.strict,
      scrollingContainer: this.scrollingContainer,
    });

    this.editorElem.children[0].classList.add('mousetrap');
    this.setEditorDefaultFont();

    if (this.content) {
      const contents = this._quillEditor.clipboard.convert(this.content);
      this._quillEditor.setContents(contents);
      this._quillEditor.history.clear();
    }

    this.onEditorCreated.emit(this._quillEditor);

    // mark model as touched if editor lost focus
    this._quillEditor.on('selection-change', (range: any, oldRange: any, source: string) => {
      this._zone.run(() => {
        this.onSelectionChanged.emit({
          editor: this._quillEditor,
          range,
          oldRange,
          source,
        });

        if (!range) {
          this.onModelTouched(undefined);
        }
      });
    });

    // update model if text changes
    this.textChangeEvent = this._quillEditor.on('text-change', (delta: any, oldDelta: any, source: string) => {
      clearTimeout(this._textChangeTimer);
      this._textChangeTimer = setTimeout(() => {
        const element = this.editorElem.children[0].cloneNode(true) as Element;
        const text = this._quillEditor.getText();

        // This allows for the default font to actually apply to the content
        const content = element.querySelectorAll('p,ul,ol') as NodeListOf<HTMLParagraphElement|HTMLUListElement|HTMLOListElement|HTMLLIElement>;
        content.forEach((ele) => {
          switch(ele.nodeName) {
            case 'P':
              ele.style.margin = '0';
              ele.style.padding = '0';
              break;
            case 'UL':
            case 'OL':
              this.correctListHtml(ele);
              break;
          }
          ele.style.fontFamily = this.defaultFont;
        });

        let html: string | null = element.innerHTML;

        if (html === '<p><br></p>') {
          html = null;
        }

        this._zone.run(() => {
          this.onModelChange(html);

          this.onContentChanged.emit({
            editor: this._quillEditor,
            html,
            text,
            delta,
            oldDelta,
            source,
          });
        });
      }, 250);
    });
  }

  ngOnDestroy() {
    if (this.selectionChangeEvent) {
      this.selectionChangeEvent.removeListener('selection-change');
    }
    if (this.textChangeEvent) {
      this.textChangeEvent.removeListener('text-change');
    }
    this.renderer.destroy();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['readOnly'] && this._quillEditor) {
      this._quillEditor.enable(!changes['readOnly'].currentValue);
    }
  }

  writeValue(currentValue: any) {
    this.content = currentValue;

    if (this._quillEditor) {
      if (currentValue) {
        this._quillEditor.setContents(this._quillEditor.clipboard.convert(this.content));
        return;
      }
      this._quillEditor.setText('');
    }
  }

  registerOnChange(fn: (value: unknown) => void): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: (value: unknown) => void): void {
    this.onModelTouched = fn;
  }

  isSelectedFont(fontName: string) {
    // Null is required to clear the attribute altogether
    const fontOption = this.getFontValue(fontName);
    return fontOption === this.defaultFont ? '' : null;
  }

  validate() {
    if (!this._quillEditor) {
      return null;
    }

    const err: {
      minLengthError?: { given: number; minLength: number };
      maxLengthError?: { given: number; maxLength: number };
      requiredError?: { empty: boolean };
    } = {};
    let valid = true;

    const textLength = this._quillEditor.getText().trim().length;

    if (this.minLength && textLength && textLength < this.minLength) {
      err.minLengthError = {
        given: textLength,
        minLength: this.minLength,
      };

      valid = false;
    }

    if (this.maxLength && textLength > this.maxLength) {
      err.maxLengthError = {
        given: textLength,
        maxLength: this.maxLength,
      };

      valid = false;
    }

    if (this.required && !textLength) {
      err.requiredError = {
        empty: true,
      };

      valid = false;
    }

    return valid ? null : err;
  }

  private setEditorDefaultFont() {
    if (!!this.editorElem) {
      this.editorElem.style.fontFamily = this.defaultFont;
    }
  }

  private getFontValue(value) {
    return this.fonts.find((f) => value === f.name).id;
  }

  private getListLevel(el) {
    const className = el.className || '0';
    return +className.replace(/[^\d]/g, '');
  }

  private correctListHtml(ele: HTMLElement) {
    const nodeName = ele.nodeName.toLowerCase();
    const listStyles = nodeName === 'ol'
      ? ['lower-alpha', 'lower-roman', 'decimal']
      : ['disc'];

    // Grab each list
    const listChildren = Array.from(ele.children).filter((el) => el.nodeName === 'LI') as Array<HTMLElement>;

    let differenceArr = listChildren.map((child) => this.getListLevel(child));
    while (differenceArr.some((val) => val > 0)) {
      const style = listStyles.shift();
      let newParent = this.renderer.createElement(nodeName);
      differenceArr = differenceArr.map((val, index) => {
        if (val > 0) {
          const currentChild = listChildren[index];
          // if the new parent doesn't have children, it hasn't been inserted, so do that here.
          if (newParent.children.length === 0 ) {
            const currentParent = this.renderer.parentNode(currentChild);
            currentParent.insertBefore(newParent, currentChild);
          }
          // need to apply list style to the li elements so the bullets/numbers display correctly
          this.renderer.setStyle(currentChild, 'list-style', style);
          this.renderer.appendChild(newParent, currentChild);
        // if the new parent has children, and the difference array value is <= 0, then we need a new parent
        // so that the ordering of the list is maintained
        } else if (newParent.children.length > 0 ) {
          newParent = this.renderer.createElement(nodeName);
        }
        return val - 1;
      });
      listStyles.push(style);
    }
  }
}
