import { ISubstitution } from '../../features/+matter-list/models';
import { TableConfigIds } from '../../features/+matter-details/constants';

export const mapTableId = (tableId: string, substitution: ISubstitution): string => {
  if (isDefaultTable(tableId)) {
    const tables = TableConfigIds;
    const mappedTableId =
      tableId === tables.CLIENT
        ? substitution.client
        : tableId === tables.OTHER_SIDE
        ? substitution.otherSide
        : tableId === tables.OTHER_SIDE_SOLICITOR
        ? substitution.otherSideSolicitor
        : substitution.otherSideInsurer;
    if (mappedTableId !== '0') {
      return mappedTableId;
    }
  }
  return tableId;
};

export const isDefaultTable = (tableId: string): boolean => {
  const tables = TableConfigIds;
  return (
    tableId === tables.CLIENT ||
    tableId === tables.OTHER_SIDE ||
    tableId === tables.OTHER_SIDE_SOLICITOR ||
    tableId === tables.OTHER_SIDE_INSURER
  );
};
