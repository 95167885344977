import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { xAnimationScale } from '@app/shared/animations/animations';
import { isObjEqual } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-dropdown-with-icon-v2',
  templateUrl: './dropdown-with-icon-v2.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DropdownWithIconV2Component), multi: true }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [xAnimationScale],
})
export class DropdownWithIconV2Component implements OnInit, ControlValueAccessor {
  @Input() iconOnly: boolean;

  @Input() iconClass: string;

  @Input() bindingProperty: string;

  @Input() bindingLabelProperty = 'name';

  @Input() options: any[];

  @Input() disabled: boolean;

  @Input() wrapperClass = '';

  @Input() buttonClass = 'x-btn-default';

  @Input() defaultButtonText = '';

  /**
   * Triggering by clicking an option
   * */
  @Output() onClickOption = new EventEmitter<any>();

  /**
   * Triggering by clicking a unselected option.
   * */
  @Output() onSelectOption = new EventEmitter<any>();

  private _value: any;
  get value() {
    return this._value;
  }

  private _isDropdownMenuOpen = false;
  get isDropdownMenuOpen(): boolean {
    return this._isDropdownMenuOpen;
  }

  get buttonText(): string {
    return !!this.selectedOption
      ? !!this.selectedOption['buttonText']
        ? this.selectedOption['buttonText']
        : this.bindingLabelProperty && !!this.selectedOption[this.bindingLabelProperty]
        ? this.selectedOption[this.bindingLabelProperty]
        : this.selectedOption
      : this.defaultButtonText;
  }

  get selectedOption(): string {
    if (!this.options) {
      return undefined;
    }

    return this.bindingProperty
      ? this.options.find((o) => o && o[this.bindingProperty] === this._value)
      : this.options.find((option) => isObjEqual(option, this._value));
  }

  public onModelChange: (value: unknown) => void;
  public onModelTouched: (value: unknown) => void;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {}

  public writeValue(val: any): void {
    this._value = val;
    this.cdRef.markForCheck();
  }

  public registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdRef.markForCheck();
  }

  public getOptionText(option: any): string {
    return this.bindingLabelProperty ? option[this.bindingLabelProperty] : option;
  }

  public isOptionSelected(option: any): boolean {
    return !!this.selectedOption
      ? !!this.bindingProperty
        ? isObjEqual(option[this.bindingProperty], this.selectedOption[this.bindingProperty])
        : isObjEqual(option, this.selectedOption)
      : false;
  }

  public selectOption(option: any): void {
    if (!!option && (!!option.header || !!option.divider)) {
      return;
    }

    this.onClickOption.emit(option);

    if (this.isOptionSelected(option)) {
      return;
    }

    this._value = !!this.bindingProperty ? option[this.bindingProperty] : option;
    this.onModelChange(this._value);
    this.onSelectOption.emit(option);
  }

  public onOpenChange(status: boolean): void {
    this._isDropdownMenuOpen = status;
  }

  public trackByFn(index, obj): number {
    if (!!obj && !obj.header && !obj.divider) {
      return !!this.bindingProperty ? obj[this.bindingProperty] : obj;
    } else {
      return index;
    }
  }
}
