import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPaymentDetail } from 'app/features/+payments-debtors/models';
import { OutflowPaymentTypeParserService } from '@app/features/accounting/services';
import { PaymentTypeId } from '../../constants';

@Component({
  selector: 'sc-outflow-payment-type-details-readonly',
  templateUrl: './outflow-payment-type-details-readonly.component.html',
})
export class OutflowPaymentTypeDetailsReadonlyComponent {
  showDetails = false;
  displayDetails: IPaymentDetail;

  PaymentTypeId = PaymentTypeId;

  private _paymentTypeDetails: any;

  private _paymentTypeName: string;
  @Input()
  set paymentTypeName(value: string) {
    this._paymentTypeName = value;
  }
  get paymentTypeName(): string {
    if (this._paymentTypeName === null) {
      return '';
    }
    return this._paymentTypeName;
  }

  @Input()
  get paymentSubTypeId(): number {
    return this._paymentSubTypeId;
  }
  set paymentSubTypeId(value: number) {
    if (value !== undefined) {
      this._paymentSubTypeId = value;
      this.displayDetails = this._outflowPaymentParser.get(
        this._paymentTypeDetails,
        this._paymentTypeId,
        this._paymentSubTypeId
      );
    }
  }

  @Input()
  get paymentTypeId(): number {
    return this._paymentTypeId;
  }

  set paymentTypeId(value: number) {
    if (value !== undefined) {
      this._paymentTypeId = value;
      this.displayDetails = this._outflowPaymentParser.get(
        this._paymentTypeDetails,
        this._paymentTypeId,
        this._paymentSubTypeId
      );
    }
  }
  @Input()
  get paymentTypeDetails(): any {
    return this._paymentTypeDetails;
  }

  set paymentTypeDetails(value: any) {
    if (value && this._paymentTypeId !== undefined) {
      this._paymentTypeDetails = value;
      this.parsePaymentTypeDetails(value);
    }
  }

  @Output()
  onOpenPaymentDetails = new EventEmitter<IPaymentDetail>();

  private _paymentTypeId: number;
  private _paymentSubTypeId: number;

  constructor(private _outflowPaymentParser: OutflowPaymentTypeParserService) {}

  openPaymentDetailsModal() {
    this.onOpenPaymentDetails.emit(this.displayDetails);
  }

  parsePaymentTypeDetails(data: any) {
    if (
      data.hasOwnProperty('BSB') ||
      data.hasOwnProperty('Drawer') ||
      data.hasOwnProperty('ChequeNumber') ||
      data.hasOwnProperty('AccountName') ||
      data.hasOwnProperty('Memo') ||
      data.hasOwnProperty('AccountNumber') ||
      data.hasOwnProperty('PayableTo')
    ) {
      this.displayDetails = data;
    } else {
      this.displayDetails = this._outflowPaymentParser.get(
        this._paymentTypeDetails,
        this._paymentTypeId,
        this._paymentSubTypeId
      );
    }
  }
  get isPaymentDetailsEmpty(): boolean {
    return !this._outflowPaymentParser.hasPaymentDetail(
      this.paymentTypeDetails,
      this.paymentTypeId,
      this.paymentSubTypeId
    );
  }

  get payableToValue(): string {
    if (!this.displayDetails) {
      return '';
    }
    if (this.displayDetails.Drawer !== this.displayDetails.Addressee) {
      return this.displayDetails.Addressee;
    } else {
      return this.displayDetails.Drawer;
    }
  }

  get isBlankDetails(): boolean {
    const valueArray = Object.values(this.displayDetails);
    // Filter out empty string values, undefined or '/' for credit card expiryDate format
    const filteredArray = valueArray.filter((value) => !!value && value !== '' && value !== '/');
    return filteredArray.length === 0;
  }
}
