import { Pipe, PipeTransform } from '@angular/core';
/*
 * Text snippet
 */
@Pipe({ name: 'snippet' })
export class SnippetPipe implements PipeTransform {
  transform(input: any, length: number, append?: any): string {
    // length === -1 is a special case to return the input string as is.
    if (input && typeof input === 'string' && length !== -1) {
      length = length || 300;
      append = append || '...';
      append = input.length > length ? append : '';

      if (input.length <= length) {
        return input;
      }

      input = input.substr(0, length);
      if (input.lastIndexOf('&#32;') !== -1) {
        return input.substr(0, input.lastIndexOf('&#32;')) + append;
      } else if (input.lastIndexOf(' ') !== -1) {
        return input.substr(0, input.lastIndexOf(' ')) + append;
      } else {
        return input + append;
      }
    }

    return input;
  }
}
