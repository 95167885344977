import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { IMatterListEntry } from '@app/features/+matter-list/models';
import { AppApiService } from '@app/core/api';
import { Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { EventBusService } from '@app/core/services';
import { IMatterListModelSelection } from '@app/features/+card/models';
import { ESiriusEvents } from '@app/core/models';
import {
  getObjValue,
  isString,
  joinArrayToString,
} from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-matter-select',
  templateUrl: './matter-select.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MatterSelectComponent), multi: true }],
})
export class MatterSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  matterList: IMatterListEntry[];
  @Input()
  iconButton: boolean;
  @Input()
  isDisabled: boolean;
  @Input()
  inputClass: string;
  @Input()
  isRecurringMatter: boolean;

  @Output()
  onMatterSelected = new EventEmitter<IMatterListEntry>();

  private _value: any = '';
  private _matterModalSub: Subscription;
  private unsub = new Subject<void>();
  onChange = (_) => {};
  onTouched = () => {};

  constructor(private _appApiSvc: AppApiService, private _eventBusSvc: EventBusService, private _store: Store<any>) {}

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  matterSelectionSearchFunc(term: string, item: IMatterListEntry): boolean {
    const searchTerm = term?.toLowerCase() || '';
    const fileNumber = item.fileNumber?.toLowerCase() || '';
    const firstDesc = item.firstDescription?.toLowerCase() || '';
    const customDesc = item.customDescription?.toLowerCase() || '';
    const firstLongDesc = item.firstDescriptionLong?.toLowerCase() || '';
    const separator = '|'; // Custom separator

    return joinArrayToString([fileNumber, firstDesc, customDesc, firstLongDesc], separator).indexOf(searchTerm) !== -1;
  }

  openMatterSelector(): void {
    if (!!this._matterModalSub) {
      this._matterModalSub.unsubscribe();
    }
    this._appApiSvc.navigate({
      path: [{ outlets: { selector: ['matter-list'] } }],
      query: { dmid: this.value }, // default matter id,
      extras: { queryParamsHandling: 'merge', skipLocationChange: true },
    });

    this._matterModalSub = this._eventBusSvc
      .listen(ESiriusEvents.GetSelectedMatter)
      .pipe(
        take(1),
        takeUntil(this.unsub) // important: this operator must be last
      )
      .subscribe((data: IMatterListModelSelection) => {
        const { matterEntry } = data;
        if (!!matterEntry) {
          this.matterSelected(this.matterList?.find((m) => m.matterId === matterEntry.matterId));
        }
      });
  }

  matterSelected(matterEntry: IMatterListEntry): void {
    this.onMatterSelected.emit(matterEntry);
  }

  matterLabel(matterEntry: IMatterListEntry): string {
    const { fileNumber, firstDescription, customDescription } = matterEntry;
    const description = joinArrayToString([firstDescription, customDescription].filter(Boolean), ', ');
    const label = joinArrayToString([fileNumber, description].filter(Boolean), ' - ');

    return label;
  }

  matterLabelReadonly(): string {
    if (!!this.isRecurringMatter) {
      return 'Recurring Matter';
    }
    const matterEntry = this.matterList?.find((m) => m.matterId === this.value);
    if (!matterEntry) {
      return '';
    }

    const { fileNumber, firstDescription, customDescription } = matterEntry;
    const description = joinArrayToString([firstDescription, customDescription].filter(Boolean), ', ');
    const label = joinArrayToString([fileNumber, description].filter(Boolean), ' - ');

    return label;
  }

  writeValue(value: any) {
    if (isString(value)) {
      this._value = value;
    } else {
      this._value = getObjValue(value, 'matterId');
    }
  }

  registerOnChange(fn: (value?: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
