import * as apiActions from '../actions';
import { AccountingUpdateResponse } from '../../models';

export interface AccountingState {
  error: AccountingUpdateResponse;
  success: boolean;
  saving: boolean;
  dehydratedform: any;
}

export const INITIAL_STATE: AccountingState = {
  error: undefined,
  success: undefined,
  saving: undefined,
  dehydratedform: undefined,
};

export const reducer = (state = INITIAL_STATE, { type, payload }: apiActions.Actions): AccountingState => {
  switch (type) {
    case apiActions.ACCOUNTING_INIT: {
      return {
        ...INITIAL_STATE,
      };
    }

    case apiActions.ACCOUNTING_SAVE:
      return {
        ...state,
        saving: true,
      };

    case apiActions.ACCOUNTING_SAVE_SUCCESS:
      return {
        ...state,
        saving: false,
        success: true,
      };

    case apiActions.ACCOUNTING_SAVE_FAILURE:
      return {
        ...state,
        saving: false,
        success: false,
        error: !!payload.httpError ? payload.httpError.error || payload : payload,
      };

    case apiActions.ACCOUNTING_WARNING_REJECT: {
      return {
        ...state,
        saving: false,
        success: false,
      };
    }

    case apiActions.DEHYDRATE_ACCOUNTING_FORM_SUCCESS: {
      return {
        ...state,
        dehydratedform: payload,
      };
    }

    default: {
      return state;
    }
  }
};
