import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { xAnimationPulseWithParams } from '@app/shared/animations/animations';

export const CUSTOMISE_LIST_OPTION_VALUE = 'customise-list-option-value';
@Component({
  selector: 'sc-select',
  templateUrl: './select.component.html',
  animations: [xAnimationPulseWithParams],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectComponent), multi: true }],
})
export class SelectComponent implements ControlValueAccessor, OnInit {
  @Input()
  inputLabel: string;
  @Input()
  selectClass: string;
  @Input()
  wrapperClass: string;
  @Input()
  isDisabled: boolean;
  @Input()
  id: string;
  @Input()
  customisable: boolean; //* a flag to indicate whether there is a Customise List option
  @Output()
  onOptionSelect = new EventEmitter<any>(); //* emit an event when an option is selected

  @Input()
  set autofocus(data: boolean) {
    this._autofocus = data;
  }

  get autofocus() {
    return this._autofocus === undefined ? false : this._autofocus;
  }

  @Input()
  selectAnimationPulseState: string;

  public readonly customise_list_option_value = CUSTOMISE_LIST_OPTION_VALUE;
  private _value: any;
  private _autofocus = false;

  @ViewChild('selectElm') selectElm: ElementRef;

  @HostBinding('class')
  classes = 'form-group col ';
  ngOnInit() {
    if (this.wrapperClass) {
      this.classes += this.wrapperClass;
    }
  }
  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  handleSelectValueChange(selectedValue): void {
    this.onOptionSelect.emit(selectedValue);

    if (selectedValue === CUSTOMISE_LIST_OPTION_VALUE) {
      this.selectElm.nativeElement.value = this._value;
      return;
    }

    this._value = selectedValue;
    this.onChange(this._value);
  }

  writeValue(value: any) {
    this._value = value;
  }

  onChange = (_) => {};

  onTouched = () => {};
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
