import { createSelector } from '@ngrx/store';

import * as fromAdvancedSearchModal from '../reducers/advanced-search-modal.reducer';

import { State, selectMattersState } from '../reducers';

export const selectAdvancedSearchModalState = createSelector(
  selectMattersState,
  (state: State) => state.advancedSearchModal
);

export const selectAdvancedSearchModalClose = createSelector(
  selectAdvancedSearchModalState,
  (state: fromAdvancedSearchModal.State) => fromAdvancedSearchModal.selectOnClose(state)
);

export const selectAdvancedSearchModalData = createSelector(
  selectAdvancedSearchModalState,
  (state: fromAdvancedSearchModal.State) => fromAdvancedSearchModal.selectAdvancedSearchData(state)
);
