import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { GlobalLeapApp } from '@app/features/matter-addin/models';
import * as leapAppActions from '@app/features/matter-addin/store/action/leap-app.action';

export interface State extends EntityState<GlobalLeapApp> {
  loading: boolean;
  noApps: boolean;
}

export const selectId = (item: GlobalLeapApp): string => item.id;

export const adapter: EntityAdapter<GlobalLeapApp> = createEntityAdapter<GlobalLeapApp>({
  selectId,
  sortComparer: false,
});

export const INITIAL_STATE: State = adapter.getInitialState({
  loading: false,
  noApps: null,
});

export const reducer = (state: State = INITIAL_STATE, action: leapAppActions.LeapAppActions) => {
  switch (action.type) {
    case leapAppActions.GET_GLOBAL_LEAP_APPS:
      return {
        ...state,
        ...adapter.removeAll(state),
        loading: true,
        noApps: null,
      };

    case leapAppActions.GET_GLOBAL_LEAP_APPS_FAIL:
      return {
        ...state,
        loading: false,
        noApps: true,
      };

    case leapAppActions.GET_GLOBAL_LEAP_APPS_SUCCESS:
      const hasMenuOptions = action?.payload?.apps?.some((x) => x.menus.length > 0) || false;
      return {
        ...adapter.upsertMany(action.payload.apps, state),
        loading: false,
        noApps: !hasMenuOptions,
      };

    case leapAppActions.CLEAR_GLOBAL_LEAP_APPS:
      return {
        ...adapter.removeAll(state),
        loading: false,
      };

    default:
      return state;
  }
};

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of globalLeapApp ids
export const selectGlobalLeapAppIds = selectIds;

// select the dictionary of globalLeapApp entities
export const selectGlobalLeapAppEntities = selectEntities;

// select the array of globalLeapApps
export const selectAllGlobalLeapApps = selectAll;

// select the total globalLeapApp count
export const selectGlobalLeapAppTotal = selectTotal;

export const selectGlobalLeapAppNoAppStatus = (state: State) => state.noApps;
