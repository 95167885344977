import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AggridEditorAbstract } from '@app/sharedaggrid/components/aggrid-editor/aggrid-editor.abstract';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-matter-editor',
  templateUrl: './matter-editor.component.html',
})
export class MatterEditorComponent extends AggridEditorAbstract implements OnDestroy {
  @ViewChild('select', { static: true }) selectComponent: NgSelectComponent;
  matterList: any;
  public selectedMatterId: string;
  private node: any;
  private onBlur: any;

  constructor() {
    super();
  }

  ngOnDestroy(): void {
    if (document.activeElement === this.selectComponent.searchInput.nativeElement) {
      this.handleBlurOnNgSelect();
    }
  }

  agInit(params: any): void {
    super.agInit(params);

    const stopPropagation = (event: KeyboardEvent) => event.stopImmediatePropagation();
    this.onUpArrow = isFunction(params.onUpArrow) ? params.onUpArrow : stopPropagation;
    this.onDownArrow = isFunction(params.onDownArrow) ? params.onDownArrow : stopPropagation;
    this.onBlur = isFunction(params.onBlur) ? params.onBlur : null;

    this.node = params.node.data;
    this.value = params.value;
    this.matterList = isFunction(params.matterList) ? params.matterList() : params.matterList;
    this.selectedMatterId = this.node.MatterGUID;
  }

  getValue(): any {
    return this.value;
  }

  focusIn(): void {
    this.selectComponent.searchInput.nativeElement.focus();
  }

  handleBlurOnNgSelect() {
    if (this.onBlur) {
      this.onBlur(this.params, this.value);
    }
  }

  handleNgSelectChange(data: any) {
    this.value = data;
    this.updateValue(data);
  }

  private updateValue(data: any) {
    const newValue = data.fileNumber;
    this.params.node.setDataValue(this.params.column.getColId(), newValue);
  }
}
