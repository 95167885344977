import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-tabs',
  templateUrl: './tabs.component.html',
})
export class TabsComponent implements OnInit {
  @Input() direction: string;
  @Input() wrapperClass: string;
  @Input() justified: false;
  classes: string;

  constructor() {}
  ngOnInit() {
    if (this.direction === 'vertical') {
      this.classes = 'x-nav-tabs-vertical ';
    } else {
      this.classes = 'x-nav-tabs ';
    }
    if (this.justified) {
      this.classes = this.classes + 'nav-justified';
    }
    if (this.wrapperClass) {
      this.classes += ' ' + this.wrapperClass;
    }
  }
}
