import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import * as attachCorrespondenceActions from '../../../+create-pdf/store/actions/attach-correspondence.actions';
import * as createPDFActions from '../../../+create-pdf/store/actions/createpdf.actions';
import * as emailActions from '../../../+email/store/actions';
import * as previewActions from '../actions';
import { Attachment, AttachmentType } from '@app/features/+email/models/attachments.model';
import {
  AutomationActionType,
  AutomationHostApplicationType,
  IAutomationOptions,
  IDocumentLauncherRequest,
  IDocumentTicketParams,
  IPrintData,
  IPrintPdfLauncherRequest,
  MailLauncherRequest,
} from '@app/shared/models/document-automation.model';
import { DocumentAutomationService, OfflineLauncherService } from '@app/shared/services';
import { DocumentIconHelper } from '@app/shared/utils';
import { AppApiService } from '@app/core/api';
import { selectCurrentMatterId, selectCurrentStaff, selectRouterSnapshot } from '@app/core/store';
import { IDocCombinePDFHttpRequestParams } from '@app/features/+create-pdf/models';
import * as appActions from '@app/core/store/actions';
import { selectSelectedFolderId } from '@app/features/+correspondence/store/selectors';
import { IDoc } from '@app/shared/models';
import { AuthService, LogService, StartupService } from '@app/core/services';
import { mapExtToHostApplication } from '@app/shared/functions/document-automation.functions';
import {
  selectPreviewAccountingSuperDiaryDocumentId,
  selectPreviewInvoiceDocBuilderInform,
  selectPreviewReportingInform,
} from '../selectors/preview-accounting.selectors';
import { AccountingApiService } from '@app/features/accounting/services/accounting-api/accounting-api.service';
import { ToastrService } from 'ngx-toastr';
import { AccountingPrintConstants, EAccountingTransaction } from '@app/features/accounting/models/accounting-api.model';
import { EPrintSource } from '@app/shared/services/print/print.service';
import { CardListStorageService } from '@app/features/+card/services/card-list-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { omitObjValue } from '@server/modules/shared/functions/common-util.functions';

@Injectable()
export class PreviewEffects {
  previewEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<previewActions.PreviewEmail>(previewActions.PREVIEW_EMAIL),
      withLatestFrom(
        this._store.pipe(select(selectCurrentMatterId)),
        this._store.pipe(select(selectPreviewAccountingSuperDiaryDocumentId)),
        (action, currentMatterId, superDiaryDocumentId) => ({
          isPdfFormat: action.payload.isPdfFormat,
          doc: action.payload.doc,
          previewUrl: action.payload.previewUrl,
          matterId:
            !!action.payload.doc && !!action.payload.doc.matterId ? action.payload.doc.matterId : currentMatterId,
          superDiaryDocumentId,
        })
      ),
      switchMap(({ isPdfFormat, doc, previewUrl, matterId, superDiaryDocumentId }) => {
        const fileName = isPdfFormat ? `${doc.name}.pdf` : `${doc.name}.${doc.ext}`;
        let actions: any[] = [];

        if (!!previewUrl) {
          const fromAws = previewUrl.indexOf('.amazonaws.') >= 0;
          if (!fromAws) {
            return from(this._authSvc.getToken()).pipe(
              map((token) => {
                const attachment: Attachment = {
                  id: uuidv4(),
                  name: fileName,
                  fileUrl: previewUrl,
                  iconClass: 'doctype-pdf',
                  type: AttachmentType.FileUrl,
                  token,
                };
                return [
                  new appActions.InitialiseEmail({
                    data: { matterNumber: matterId },
                    attachment: [attachment],
                    routerOutlet: 'overlay',
                  }),
                  new emailActions.AddAttachmentsSuccess([attachment]),
                ];
              }),
              catchError((error) => {
                return of([
                  new appActions.InitialiseEmail({
                    data: { matterNumber: matterId },
                    attachment: [],
                    routerOutlet: 'overlay',
                  }),
                ]);
              })
            );
          } else {
            // If from AWS, no token needed
            const attachment: Attachment[] = [
              {
                id: uuidv4(),
                name: fileName,
                fileUrl: previewUrl,
                iconClass: 'doctype-pdf',
                type: AttachmentType.FileUrl,
              },
            ];
            return of([
              new appActions.InitialiseEmail({
                data: { matterNumber: matterId },
                attachment,
                routerOutlet: 'overlay',
              }),
              new emailActions.AddAttachmentsSuccess(attachment),
            ]);
          }
        }

        if (isPdfFormat && !superDiaryDocumentId) {
          const document: IDoc = {
            ...omitObjValue({ ...doc, latestVersion: doc.latestVersionId }, ['latestVersionId']),
          };
          const createPdfDto = {
            filename: document.latestVersion || document.id,
            name: document.name,
            type: document.ext,
          };
          const createPDFRequest: IDocCombinePDFHttpRequestParams = {
            Documents: [createPdfDto],
            SaveToMatter: false,
            CombinedDocumentsName: fileName,
            Combine: false,
            Secure: true,
            SaveToSuperDiaryTempStorage: true,
          };

          actions = [
            new appActions.InitialiseEmail({
              data: { matterNumber: matterId },
              convertPdfParams: createPDFRequest,
              routerOutlet: 'overlay',
            }),
            new createPDFActions.SetCreatePDFoptions(createPDFRequest),
            new createPDFActions.SelectCorrespondence([document]),
            new createPDFActions.CreatePDFnEmail(null),
          ];
        } else {
          const attachment = {
            id: doc.documentId || superDiaryDocumentId,
            latestVersion: doc.latestVersionId || superDiaryDocumentId,
            name: fileName,
            iconClass: this.documentIconHelper.getIconInfo(doc.ext).iconClass,
            type: !!doc.documentId ? AttachmentType.Correspondence : AttachmentType.File,
          } as Attachment;
          actions = [
            new appActions.InitialiseEmail({
              data: { matterNumber: matterId },
              attachment: [attachment],
              routerOutlet: 'overlay',
            }),
            new attachCorrespondenceActions.AttachCorrespondence([attachment]),
          ];
        }
        return [...actions];
      })
    )
  );

  previewPrint$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<previewActions.PreviewPrint>(previewActions.PREVIEW_PRINT),
      withLatestFrom(
        this._store.pipe(select(selectPreviewAccountingSuperDiaryDocumentId)),
        this._store.pipe(select(selectSelectedFolderId)),
        (action, superDiaryDocumentId, folderId) => ({
          preview: action.payload.doc,
          previewUrl: action.payload.previewUrl,
          superDiaryDocumentId,
          folderId,
        })
      ),
      switchMap((data) => {
        const { preview, superDiaryDocumentId, previewUrl, folderId } = data;
        const { staffId, firmId, userId } = this._startupSvc.userDetails;
        const params = {
          documentId: preview.id || preview.documentId,
          ext: preview.ext,
          latestVersionId: preview.latestVersionId || preview.id || preview.documentId,
          documentName: preview.name,
        } as IDocumentTicketParams;
        let printData: IPrintData;
        if (!!superDiaryDocumentId) {
          printData = {
            ext: params.ext,
            documentName: params.documentName,
            superDiaryDocumentId,
          };
        } else if (!!previewUrl) {
          printData = {
            ext: params.ext,
            documentName: params.documentName,
            url: previewUrl,
          };
        } else {
          printData = {
            ext: params.ext,
            latestVersionId: params.latestVersionId,
            documentName: params.documentName,
          };
        }
        const launcherRequest = {
          staffId,
          firmId,
          userId,
          hostApplication: AutomationHostApplicationType.Launcher,
          action: AutomationActionType.PrintPdf,
          folderId,
          printData,
        } as IPrintPdfLauncherRequest;
        return this._offlineLauncherSvc.postTicket(launcherRequest);
      }),
      switchMap((ticketId) => {
        const actions: any[] = [
          new appActions.UpdateAutomationLocalTicketId(ticketId),
          new previewActions.PreviewPrintSuccess(ticketId),
        ];
        if (this._offlineLauncherSvc.launchDocumentOffline(ticketId, this._startupSvc.userDetails.firmId)) {
          actions.push(new appActions.LaunchAutomationDialogStart({ navigateClear: false }));
        }
        return actions;
      }),
      catchError((e) => [new previewActions.PreviewPrintFailure(e)])
    )
  );

  saveToMatterViaReporting$ = createEffect(() =>
    this.actions$.pipe(
      ofType<previewActions.SaveAccountingDocToMatterViaReporting>(previewActions.PREVIEW_SAVE_TO_MATTER_VIA_REPORTING),
      withLatestFrom(
        this._store.pipe(select(selectPreviewReportingInform)),
        this._store.pipe(select(selectCurrentStaff)),
        (_, reportingInform, staff) => ({ reportingInform, staff })
      ),
      filter((data) => !!data.reportingInform.transactionList && !!data.reportingInform.transactionList[0]),
      exhaustMap((data) => from(this._authSvc.userDetails()).pipe(map((user) => ({ ...data, user })))),
      concatMap((data) => {
        const { reportingInform, staff, user } = data;
        return this._accountingApiSvc
          .saveToMatterViaReportingApi({
            reportName: reportingInform.documentName,
            reportUrl: reportingInform.previewUrl,
            matterId: reportingInform.transactionList[0].matterGuid,
            staffInitials: staff.initials,
            user,
          })
          .pipe(
            mergeMap(() => {
              const message = `${reportingInform.documentName} saved to matter successfully.`;
              this._toastrSvc.show(message, 'Success', {}, 'success');
              return [];
            }),
            catchError(() => {
              const message = `Unable to save ${reportingInform.documentName} to matter`;
              this._toastrSvc.show(message, 'Error', {}, 'error');
              return [];
            })
          );
      })
    )
  );

  saveInvoiceDocToMatterViaDocBuilder$ = createEffect(() =>
    this.actions$.pipe(
      ofType<previewActions.SaveInvoicePreviewDocToMatterViaDocBuilder>(
        previewActions.PREVIEW_INVOICE_SAVE_TO_MATTER_VIA_DOC_BUILDER
      ),
      withLatestFrom(this._store.pipe(select(selectPreviewInvoiceDocBuilderInform)), (_, docBuilderInform) => ({
        docBuilderInform,
      })),
      switchMap((data) => {
        const { docBuilderInform } = data;
        const { documentName, transactionList, precedentId, isCustomPrecedent } = docBuilderInform;
        const invoices = transactionList.map((t) => ({
          matterId: t.matterGuid,
          invoiceId: t.transactionGuid,
          saveToMatter: true,
          saveToSuperDiaryTempStorage: false,
          documentName: t.transactionDocName,
          folderId: '',
          parameters: t.parameters,
        }));
        this._store.dispatch(new appActions.AppDisplayLoading(true));
        return this._accountingApiSvc
          .invoicePrintViaDocBuilder({
            precedentId,
            isCustomPrecedent,
            invoices,
            combinePdfs: false,
            combinePdfsName: '',
            returnFileContent: false,
          })
          .pipe(
            mergeMap((d) => {
              this._logSvc.debug('Doc Builder Response: ', d);
              if (!!d && !!d[0]) {
                const message = `${documentName} saved to matter successfully.`;
                this._toastrSvc.show(message, 'Success', {}, 'success');
                return [
                  new appActions.AppDisplayLoading(false),
                  new previewActions.SaveInvoicePreviewDocToMatterViaDocBuilderSuccess({
                    documentId: d[0].DocumentId,
                  }),
                ];
              }
              return throwError(() => 'Unable to save to matter');
            }),
            catchError((err) => {
              this._logSvc.error('Save to matter error: ', err);
              const message = `Unable to save ${documentName} to matter`;
              this._toastrSvc.show(message, 'Error', {}, 'error');
              return [new appActions.AppDisplayLoading(false)];
            })
          );
      })
    )
  );

  saveDocumentDocToMatterViaDocBuilder$ = createEffect(() =>
    this.actions$.pipe(
      ofType<previewActions.SaveDocumentPreviewDocToMatterViaDocBuilder>(
        previewActions.SAVE_DOCUMENT_TO_MATTER_VIA_DOC_BUILDER
      ),
      withLatestFrom(
        this._store.pipe(select(selectPreviewInvoiceDocBuilderInform)),
        this._store.pipe(select(selectCurrentStaff)),
        (_, docBuilderInform, currentStaff) => ({
          docBuilderInform,
          currentStaff,
        })
      ),
      switchMap((data) => {
        const { docBuilderInform, currentStaff } = data;
        const { documentName, transactionList, precedentId, isCustomPrecedent } = docBuilderInform;
        const staffInitials = !!currentStaff && !!currentStaff.initials ? currentStaff.initials : '';
        this._store.dispatch(new appActions.AppDisplayLoading(true));
        return this._accountingApiSvc
          .documentPrintViaDocBuilder({
            precedentId,
            isCustomPrecedent,
            documentName,
            matterId: transactionList[0].matterGuid,
            saveToMatter: true,
            saveToSuperDiaryTempStorage: false,
            staffInitials,
            parameters: transactionList[0].parameters,
            returnFileContent: false,
          })
          .pipe(
            mergeMap((d) => {
              this._logSvc.debug('Doc Builder Response: ', d);
              if (!!d) {
                const message = `${documentName} saved to matter successfully.`;
                this._toastrSvc.show(message, 'Success', {}, 'success');
                return [
                  new appActions.AppDisplayLoading(false),
                  new previewActions.SaveDocumentPreviewDocToMatterViaDocBuilderSuccess({
                    documentId: d.documentId,
                  }),
                ];
              }
              return throwError(() => 'Unable to save to matter');
            }),
            catchError((err) => {
              this._logSvc.error('Save to matter error: ', err);
              const message = `Unable to save ${documentName} to matter`;
              this._toastrSvc.show(message, 'Error', {}, 'error');
              return [new appActions.AppDisplayLoading(false)];
            })
          );
      })
    )
  );

  previewDocEdit$ = createEffect(() =>
    this.actions$.pipe(
      ofType<previewActions.PreviewDocEdit>(previewActions.PREVIEW_DOC_EDIT),
      switchMap((action) => {
        const { matterId, documentId, ext } = action.payload;
        const { staffId, firmId, userId } = this._startupSvc.userDetails;
        const data = {
          matterId,
          staffId,
          firmId,
          userId,
          hostApplication: mapExtToHostApplication(ext),
          action: AutomationActionType.Edit,
          folderId: undefined,
          documentData: {
            documentId,
            isPending: false,
          },
        } as IDocumentLauncherRequest;
        return this._offlineLauncherSvc.postTicket(data).pipe(
          mergeMap((res) => {
            const actions: any[] = [new appActions.UpdateAutomationLocalTicketId(res)];
            if (this._offlineLauncherSvc.launchDocumentOffline(res, this._startupSvc.userDetails.firmId)) {
              actions.push(new appActions.LaunchAutomationDialogStart({ navigateClear: false }));
            }
            return actions;
          })
        );
      })
    )
  );

  previewDocEditOnline$ = createEffect(() =>
    this.actions$.pipe(
      ofType<previewActions.PreviewDocEditOnline>(previewActions.PREVIEW_DOC_EDIT_ONLINE),
      withLatestFrom(this._store.pipe(select(selectCurrentMatterId)), (action, currentMatterId) => ({
        action,
        currentMatterId,
      })),
      switchMap((data) => {
        const { action, currentMatterId } = data;
        const automationOptions: IAutomationOptions = {
          action: AutomationActionType.Edit,
          matterId: action.payload.matterId || currentMatterId,
          folderId: undefined,
          isOfficeOnline: true,
          docInfo: {
            documentId: action.payload.documentId,
            ext: action.payload.ext,
            isDesktopOnly: false,
          },
        };

        this._documentAutomationSvc.editDocumentOnline(automationOptions);
        return [];
      })
    )
  );

  previewEmailOpenInOutlook$ = createEffect(() =>
    this.actions$.pipe(
      ofType<previewActions.PreviewEmailOpenInOutlook>(previewActions.PREVIEW_EMAIL_OPEN_IN_OUTLOOK),
      switchMap((action) => {
        const { matterId, documentId, ext, latestVersionId } = action.payload;
        const { staffId, firmId, userId } = this._startupSvc.userDetails;
        const data = {
          matterId,
          staffId,
          firmId,
          userId,
          hostApplication: AutomationHostApplicationType.Outlook,
          action: AutomationActionType.OpenMailItem,
          folderId: undefined,
          emailData: {
            documentId,
            ext,
            latestVersionId: latestVersionId || documentId,
            isPending: false,
          },
        } as MailLauncherRequest;
        return this._offlineLauncherSvc.postTicket(data).pipe(
          mergeMap((res) => {
            const actions: any[] = [new appActions.UpdateAutomationLocalTicketId(res)];
            if (this._offlineLauncherSvc.launchDocumentOffline(res, this._startupSvc.userDetails.firmId)) {
              actions.push(new appActions.LaunchAutomationDialogStart({ navigateClear: false }));
            }
            return actions;
          })
        );
      })
    )
  );

  getInvoicePreviewViaDocBuilder$ = createEffect(() =>
    this.actions$.pipe(
      ofType<previewActions.GetInvoicePreviewViaDocBuilder>(previewActions.GET_INVOICE_PREVIEW_VIA_DOC_BUILDER),
      switchMap((action) => {
        if (!!action.payload.splitCardGuid) {
          return this._cardListStorageSvc.get(action.payload.splitCardGuid).then((splitCard) => ({
            ...action.payload,
            splitCard,
          }));
        } else {
          return of({
            ...action.payload,
            splitCard: null,
          });
        }
      }),
      switchMap((data) => {
        const {
          splitCardGuid,
          invoiceNumber,
          invoiceGuid,
          matterGuid,
          precedentId,
          isCustomPrecedent,
          splitCard,
        } = data;
        let parameters: { splitCardId?: string } = {};
        let documentName = `Invoice No. ${invoiceNumber}`;
        if (!!splitCardGuid) {
          parameters = {
            ...parameters,
            splitCardId: splitCardGuid,
          };
          if (!!splitCard && !!splitCard.fullName) {
            documentName += ` (${splitCard.fullName})`;
          }
        }
        const invoices = [
          {
            matterId: matterGuid,
            invoiceId: invoiceGuid,
            saveToMatter: false,
            saveToSuperDiaryTempStorage: true,
            documentName,
            folderId: '',
            parameters,
          },
        ];
        return this._accountingApiSvc
          .invoicePrintViaDocBuilder({
            precedentId,
            isCustomPrecedent,
            invoices,
            combinePdfs: true,
            combinePdfsName: documentName,
            returnFileContent: true,
          })
          .pipe(
            mergeMap((d) => {
              this._logSvc.debug('Doc Builder Response: ', d);
              if (!!d && !!d[0]) {
                const previewInform = {
                  previewBase64String: d[0].DocumentContent,
                  documentName,
                  superDiaryDocumentId: d[0].SuperDiaryDocumentId,
                  precedentId,
                  isCustomPrecedent,
                  transactionType: EAccountingTransaction.OfficeInvoice,
                  transactionList: [
                    {
                      matterGuid,
                      transactionGuid: invoiceGuid,
                      transactionNumber: invoiceNumber,
                      transactionDocName: documentName,
                      parameters: { splitCardId: splitCardGuid },
                    },
                  ],
                };
                return [
                  new previewActions.SetPreviewAccountingInformBase64(previewInform),
                  new previewActions.SetPreviewAccountingLoadingStatus({ status: false }),
                ];
              } else {
                //* fallback to use reporting api if there is no obj return from doc builder api
                return [
                  new previewActions.GetInvoicePreviewViaReporting({
                    splitCardGuid,
                    invoiceGuid,
                    invoiceNumber,
                    matterGuid,
                  }),
                ];
              }
            }),
            catchError((err) => {
              this._logSvc.error('Invoice Print Via DocBuilder Error: ', err);
              //* fallback to use reporting api if there is errors from doc builder api
              return [
                new previewActions.GetInvoicePreviewViaReporting({
                  splitCardGuid,
                  invoiceGuid,
                  invoiceNumber,
                  matterGuid,
                }),
              ];
            })
          );
      })
    )
  );

  getInvoicePreviewViaReporting$ = createEffect(() =>
    this.actions$.pipe(
      ofType<previewActions.GetInvoicePreviewViaReporting>(previewActions.GET_INVOICE_PREVIEW_VIA_REPORTING),
      withLatestFrom(this._store.pipe(select(selectRouterSnapshot)), (action, routerSnapshot) => {
        const { queryParams } = routerSnapshot;
        return {
          ...action.payload,
          split: !!queryParams && !!queryParams['split'] ? queryParams['split'] : null,
        };
      }),
      switchMap((data) => {
        if (!!data.splitCardGuid) {
          return this._cardListStorageSvc.get(data.splitCardGuid).then((splitCard) => ({
            ...data,
            splitCard,
          }));
        } else {
          return of({
            ...data,
            splitCard: null,
          });
        }
      }),
      switchMap((data) => {
        const { splitCardGuid, splitCard, invoiceNumber, invoiceGuid, matterGuid, split } = data;
        let options: { InvoiceGUID: string; splitCardId?: string; split?: string } = { InvoiceGUID: invoiceGuid };
        let fileNameOnly = `Invoice No. ${invoiceNumber}`;
        if (!!splitCardGuid) {
          options = {
            ...options,
            splitCardId: splitCardGuid,
          };
          if (!!splitCard && !!splitCard.fullName) {
            fileNameOnly += ` (${splitCard.fullName})`;
          }
        }
        if (!!split) {
          options = {
            ...options,
            split,
          };
        }

        const reportName = 'Office+Invoice+header+single';
        return this._accountingApiSvc
          .getPreviewUrlViaReportingApi({
            reportName,
            fileName: `${fileNameOnly}.pdf`,
            options,
            source: EPrintSource.Direct,
          })
          .pipe(
            mergeMap((previewUrl) => {
              const previewInform = {
                previewUrl,
                documentName: fileNameOnly,
                transactionType: EAccountingTransaction.OfficeInvoice,
                transactionList: [
                  {
                    matterGuid,
                    transactionGuid: invoiceGuid,
                    transactionNumber: invoiceNumber,
                    transactionDocName: fileNameOnly,
                    parameters: { splitCardId: splitCardGuid },
                  },
                ],
              };
              return [
                new previewActions.SetPreviewAccountingInformUrl(previewInform),
                new previewActions.SetPreviewAccountingLoadingStatus({ status: false }),
              ];
            }),
            catchError(() => [new previewActions.SetPreviewAccountingLoadingStatus({ status: false })])
          );
      })
    )
  );

  getAccountingPreviewViaDocBuilder$ = createEffect(() =>
    this.actions$.pipe(
      ofType<previewActions.GetAccountingPreviewViaDocBuilder>(previewActions.GET_ACCOUNTING_PREVIEW_VIA_DOC_BUILDER),
      switchMap((action) => {
        if (!!action.payload.splitCardGuid) {
          return this._cardListStorageSvc.get(action.payload.splitCardGuid).then((splitCard) => ({
            ...action.payload,
            splitCard,
          }));
        } else {
          return of({
            ...action.payload,
            splitCard: null,
          });
        }
      }),
      withLatestFrom(this._store.pipe(select(selectCurrentStaff)), (data, currentStaff) => ({
        ...data,
        currentStaff,
      })),
      switchMap((data) => {
        const {
          currentStaff,
          accountingType,
          splitCardGuid,
          transactionNumber,
          transactionGuid,
          matterGuid,
          precedentId,
          isCustomPrecedent,
          splitCard,
        } = data;
        const printDefault = AccountingPrintConstants[accountingType];
        let parameters: any = { matterId: matterGuid };
        let documentName = this._translateSvc.instant(printDefault.fileName);
        if (!!transactionNumber) {
          documentName += ` No. ${transactionNumber}`;
        }

        if (accountingType === EAccountingTransaction.OfficeReceipt || EAccountingTransaction.CreditReceipt) {
          parameters = {
            ...parameters,
            officeReceiptId: transactionGuid,
          };
        } else if (accountingType === EAccountingTransaction.TrustReceipt) {
          parameters = {
            ...parameters,
            trustReceiptId: transactionGuid,
          };
        }

        if (!!splitCardGuid) {
          parameters = {
            ...parameters,
            splitCardId: splitCardGuid,
          };
          if (!!splitCard && !!splitCard.fullName) {
            documentName += ` (${splitCard.fullName})`;
          }
        }
        const staffInitials = !!currentStaff && !!currentStaff.initials ? currentStaff.initials : '';
        return this._accountingApiSvc
          .documentPrintViaDocBuilder({
            precedentId,
            isCustomPrecedent,
            documentName,
            matterId: matterGuid,
            saveToMatter: false,
            saveToSuperDiaryTempStorage: true,
            staffInitials,
            parameters,
            returnFileContent: true,
          })
          .pipe(
            mergeMap((d) => {
              this._logSvc.debug('Doc Builder Response: ', d);
              const { documentContent, superDiaryDocumentId } = d;
              if (!!documentContent) {
                const previewInform = {
                  previewBase64String: documentContent,
                  documentName,
                  superDiaryDocumentId,
                  precedentId,
                  isCustomPrecedent,
                  transactionType: accountingType as EAccountingTransaction,
                  transactionList: [
                    {
                      matterGuid,
                      transactionGuid,
                      transactionNumber,
                      transactionDocName: documentName,
                      parameters,
                    },
                  ],
                };
                return [
                  new previewActions.SetPreviewAccountingInformBase64(previewInform),
                  new previewActions.SetPreviewAccountingLoadingStatus({ status: false }),
                ];
              } else {
                //* fallback to use reporting api if there is no obj return from doc builder api
                return [
                  new previewActions.GetAccountingPreviewViaReporting({
                    accountingType,
                    transactionGuid,
                    transactionNumber,
                    splitCardGuid,
                    matterGuid,
                  }),
                ];
              }
            }),
            catchError((err) => {
              this._logSvc.error('Accounting Doc Print Via DocBuilder Error: ', err);
              //* fallback to use reporting api if there is errors from doc builder api
              return [
                new previewActions.GetAccountingPreviewViaReporting({
                  accountingType,
                  transactionGuid,
                  transactionNumber,
                  splitCardGuid,
                  matterGuid,
                }),
              ];
            })
          );
      })
    )
  );

  getAccountingPreviewViaReporting$ = createEffect(() =>
    this.actions$.pipe(
      ofType<previewActions.GetAccountingPreviewViaReporting>(previewActions.GET_ACCOUNTING_PREVIEW_VIA_REPORTING),
      withLatestFrom(this._store.pipe(select(selectRouterSnapshot)), (action, routerSnapshot) => {
        const { queryParams } = routerSnapshot;
        return {
          ...action.payload,
          split: !!queryParams && !!queryParams['split'] ? +queryParams['split'] : null,
        };
      }),
      switchMap((data) => {
        if (!!data.splitCardGuid) {
          return this._cardListStorageSvc.get(data.splitCardGuid).then((splitCard) => ({
            ...data,
            splitCard,
          }));
        } else {
          return of({
            ...data,
            splitCard: null,
          });
        }
      }),
      switchMap((data) => {
        const {
          accountingType,
          splitCardGuid,
          splitCard,
          transactionNumber,
          transactionGuid,
          matterGuid,
          split,
        } = data;
        const printDefault = AccountingPrintConstants[accountingType];
        const options = this._accountingApiSvc.getReportOptions({
          accountingType,
          transactionGuid,
          matterGuid,
          splitCardGuid,
          split,
        });
        let fileName = this._translateSvc.instant(printDefault.fileName);
        if (transactionNumber) {
          fileName += ` No. ${transactionNumber}`;
        }
        if (!!splitCard && !!splitCard.fullName) {
          fileName += ` (${splitCard.fullName})`;
        }
        this._logSvc.info('print via reporting api -- getting previewUrl');
        return this._accountingApiSvc
          .getPreviewUrlViaReportingApi({
            reportName: printDefault.reportName,
            fileName: `${fileName}.pdf`,
            options,
            source: EPrintSource.Direct,
          })
          .pipe(
            mergeMap((previewUrl) => {
              this._logSvc.info('print via reporting api -- getting previewUrl done');
              const previewInform = {
                previewUrl,
                documentName: fileName,
                transactionType: accountingType,
                transactionList: [
                  {
                    matterGuid,
                    transactionGuid,
                    transactionNumber,
                    transactionDocName: fileName,
                    parameters: {},
                  },
                ],
              };
              return [
                new previewActions.SetPreviewAccountingInformUrl(previewInform),
                new previewActions.SetPreviewAccountingLoadingStatus({ status: false }),
              ];
            }),
            catchError(() => [new previewActions.SetPreviewAccountingLoadingStatus({ status: false })])
          );
      })
    )
  );

  documentIconHelper: DocumentIconHelper;

  constructor(
    private actions$: Actions,
    private _store: Store<any>,
    private _logSvc: LogService,
    private _authSvc: AuthService,
    private _accountingApiSvc: AccountingApiService,
    private _toastrSvc: ToastrService,
    private _appApiSvc: AppApiService,
    private _translateSvc: TranslateService,
    private _cardListStorageSvc: CardListStorageService,
    private _startupSvc: StartupService,
    private _documentAutomationSvc: DocumentAutomationService,
    private _offlineLauncherSvc: OfflineLauncherService
  ) {
    this.documentIconHelper = new DocumentIconHelper();
  }
}
