import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'sc-popover-footer',
  templateUrl: './popover-footer.component.html',
})
export class PopoverFooterComponent implements OnInit {
  constructor() {}

  @HostBinding('class.card-popover-footer')
  ngOnInit() {}
}
