import { Action } from '@ngrx/store';

export const USER_PROFILE_INIT_START = '[User Profile] init start';
export const USER_PROFILE_INIT_SUCCESS = '[User Profile] init success';
export const USER_PROFILE_GET_ACCOUNT_INFORMATION = '[User Profile] get account information';
export const USER_PROFILE_GET_ACCOUNT_INFORMATION_SUCCESS = '[User Profile] get account information success';
export const CLEAR_USER_PROFILE_DATA = '[User Profile] clear store';
export const USER_PROFILE_FAILURE = '[User Profile] failure';

export class UserProfileInitStart implements Action {
  readonly type = USER_PROFILE_INIT_START;
  constructor(public payload: any) {}
}

export class UserProfileInitSuccess implements Action {
  readonly type = USER_PROFILE_INIT_SUCCESS;
  constructor(public payload: any) {}
}

export class UserProfileGetAccountInformation implements Action {
  readonly type = USER_PROFILE_GET_ACCOUNT_INFORMATION;
  constructor(public payload: any) {}
}

export class UserProfileGetAccountInformationSuccess implements Action {
  readonly type = USER_PROFILE_GET_ACCOUNT_INFORMATION_SUCCESS;
  constructor(public payload: any) {}
}

export class ClearUserProfileData implements Action {
  readonly type = CLEAR_USER_PROFILE_DATA;
  constructor(public payload: any) {}
}

export class UserProfileFailure implements Action {
  readonly type = USER_PROFILE_FAILURE;
  constructor(public payload: any) {}
}

export type UserProfileActions =
  | UserProfileInitStart
  | UserProfileInitSuccess
  | UserProfileGetAccountInformation
  | UserProfileGetAccountInformationSuccess
  | ClearUserProfileData
  | UserProfileFailure;
