export enum AccessControlStaffRole {
  None = 0,
  Credit = 1,
  Responsible = 2,
  Acting = 3,
  Assisting = 4,
}

export interface IAccessControlStaff {
  id: string;
  role: AccessControlStaffRole;
}

export interface IAccessControl {
  enabled: boolean;
  staffList: IAccessControlStaff[];
}
