<ng-select
  [items]="taxCodeList"
  #select
  tabindex="0"
  [clearable]="false"
  [bindLabel]="bindLabel"
  bindValue="TaxCodeGUID"
  [ngModel]="value"
  (ngModelChange)="handleValueChange($event)"
  (blur)="blur()"
  placeholder="Select tax rate"
  appendTo="#modal-container"
>
  <ng-template ng-option-tmp let-item="item">
    <strong>{{ item[bindLabel] }}</strong>
    <div class="ag-content-name" data-hj-masked [title]="item.TaxCode">{{ item.RatePercent }}%</div>
  </ng-template>
</ng-select>
