import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from '@app/features/fee-timer/store/reducers';
import { FeeTimerRecordEffect, FeeTimerActivityCodeEffect } from '@app/features/fee-timer/store/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('feeTimer', reducers),
    EffectsModule.forFeature([FeeTimerRecordEffect, FeeTimerActivityCodeEffect]),
  ],
  declarations: [],
})
export class FeeTimerStateModule {}
