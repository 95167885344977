import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { KeyboardCode, IKeyPressManager, KeyPressManagerId } from '@app/shared/models';

@Injectable()
export class ScHotKeyService {
  constructor(private _hotKeysSvc: HotkeysService) {}
  private _keyManager: IKeyPressManager[] = [];

  setupEscKey(id: KeyPressManagerId) {
    const key = this._hotKeysSvc.add(
      new Hotkey(
        'esc',
        (event: KeyboardEvent): boolean => {
          this.emitKeyPress(id, 'esc');
          return false; // Prevent bubbling
        },
        ['INPUT', 'TEXTAREA', 'SELECT']
      )
    );

    this.addHotKey(id, key, 'esc');
  }

  disposeEscKey(id: KeyPressManagerId) {
    // Removes key from manager and sets esc to whatever was there previously
    const key = this.getHotKey(id);
    if (!!key) {
      this._hotKeysSvc.remove(key.hotkey);
      this.removeHotKeyFromManager(id);
      this.resetKey(key.code);
    }
  }

  getKeyPressSubject(id: KeyPressManagerId): Subject<KeyboardCode> {
    const key = this.getHotKey(id);
    return key.keyPressSubject;
  }

  private addHotKey(id: KeyPressManagerId, hotkey: Hotkey | Hotkey[], code: KeyboardCode): void {
    this._keyManager.push({
      id,
      code,
      hotkey,
      keyPressSubject: new Subject<KeyboardCode>(),
      order: this.getNextCodeOrder(code),
    });
  }

  private removeHotKeyFromManager(id: KeyPressManagerId): void {
    // in the case where there is more than one of the same key id
    // we want to remove the one last added - (last in first out)
    const key = this.getLatestHotKey(id);
    this._keyManager.splice(this._keyManager.indexOf(key), 1);
  }

  private getHotKey(id: KeyPressManagerId) {
    return this._keyManager.find((x) => x.id === id);
  }

  private getLatestHotKey(id: KeyPressManagerId) {
    // this finds the last added key of an id for removal
    return this._keyManager.sort((a, b) => b.order - a.order).find((x) => x.id === id);
  }

  private emitKeyPress(id: KeyPressManagerId, code: KeyboardCode) {
    const key = this.getHotKey(id);
    key.keyPressSubject.next(code);
  }

  private getNextCodeOrder(code: KeyboardCode): number {
    const sortedKeys = this.getCodeByOrder(code);
    return sortedKeys.length !== 0 ? sortedKeys[sortedKeys.length - 1].order + 1 : 1;
  }

  private getCodeByOrder(code: KeyboardCode): IKeyPressManager[] {
    return this._keyManager.filter((k) => k.code === code).sort((a, b) => a.order - b.order);
  }

  private resetKey(code: KeyboardCode) {
    const nextKey = this.getCodeByOrder(code)[0];
    if(!!nextKey) {
      this._hotKeysSvc.add(nextKey.hotkey);
    }
  }
}
