import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, switchMap, mergeMap, withLatestFrom, map, tap, filter } from 'rxjs/operators';
import { of } from 'rxjs';

import { FormStorageService, PlatformService } from '@app/core/services';
import * as feeModalActions from '../actions/fee-modal';
import { selectFeeNewWin } from '../selectors';
import { AppApiService } from '@app/core/api';

@Injectable()
export class FeeModalEffects {
  public formUiKey = '__FEE__FORM__UI';

  // new window effects

  hydrateFeeForm$ = createEffect(() => this.actions$.pipe(
    ofType(feeModalActions.HYDRATE_FEE_FORM),
    filter(() => this._ps.isBrowser),
    switchMap((action: feeModalActions.HydrateFeeForm) => this._formStorageSvc.setFormUi(this.formUiKey, action.payload).pipe(
        mergeMap((x) => [new feeModalActions.HydrateFeeFormSuccess(x)]),
        catchError((error) => of(new feeModalActions.HydrateFeeFormFailure(error)))
      ))
  ));


  deHydrateFeeForm$ = createEffect(() => this.actions$.pipe(
    ofType(feeModalActions.DEHYDRATE_FEE_FORM),
    filter(() => this._ps.isBrowser),
    switchMap(() => this._formStorageSvc.formUi(this.formUiKey).pipe(
        mergeMap((formUi) => [new feeModalActions.DeHydrateFeeFormSuccess(formUi)]),
        catchError((error) => of(new feeModalActions.DeHydrateFeeFormFailure(error)))
      ))
  ));


  deHydrateFeeFormSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(feeModalActions.DEHYDRATE_FEE_FORM_SUCCESS),
    filter(() => this._ps.isBrowser),
    switchMap(() => this._formStorageSvc.clean().pipe(mergeMap(() => [])))
  ), { dispatch: false });


  closeWindow$ = createEffect(() => this.actions$.pipe(
    ofType(feeModalActions.CLOSE_FEE),
    withLatestFrom(this._store.pipe(select(selectFeeNewWin)), (action, newWin) => newWin),
    switchMap((newWin) => {
      if (newWin && this._ps.isBrowser) {
        window.close();
      } else {
        this._appApiSvc.clearCurrentModal();
      }
      return [];
    })
  ), { dispatch: false });
  // end new window effects

  constructor(
    private actions$: Actions,
    private _appApiSvc: AppApiService,
    private _formStorageSvc: FormStorageService,
    private _ps: PlatformService,
    private _store: Store<any>
  ) {}
}
