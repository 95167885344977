<div class="x-input-group">
  <div class="x-input-group-prepend">
    <div class="input-group-text" *ngIf="icon !== 'percentage'">
      {{ 'Core.CurrencySymbol' | translate }}
    </div>
  </div>
  <input
    #input
    type="number"
    step=".01"
    class="form-control text-right"
    [ngModel]="value"
    (ngModelChange)="handleValueChange($event)"
    scAutoRoundCurrency
    (keydown)="keydown($event)"
    (blur)="blur()"
  />
  <div class="input-group-text" *ngIf="icon === 'percentage'">%</div>
</div>
