export interface AnalyticsModel {
  action: string;
  category: string;
  label?: string;
  value?: number;
}

export enum AnalyticsValueType {
  OnOff = 'On/Off',
  TrueFalse = 'True/False',
  YesNo = 'Yes/No',
  ExpandedCollapsed = 'Expanded/Collapsed',
  PinnedUnpinned = 'Pinned/Unpinned',
  IncludeExcludeTax = 'Include/Exclude Tax',
}
