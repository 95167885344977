import { createSelector } from '@ngrx/store';

import * as fromFeeTimerRecord from '../reducers/fee-timer-record.reducer';
import { State, selectFeeTimer } from '../reducers';
import { FeeTimerRecordingState } from '@app/features/fee-timer/models/fee-timer.model';

export const selectFeeTimerStoreAsRecordingBase = createSelector(selectFeeTimer, (state: State) => state.recording);

export const selectFeeTimerTimeElapsedState = createSelector(
  selectFeeTimerStoreAsRecordingBase,
  (state: FeeTimerRecordingState) => fromFeeTimerRecord.selectFeeTimerRecordingState(state)
);

export const selectFeeTimerCurrentTimeElapsedState = createSelector(
  selectFeeTimerStoreAsRecordingBase,
  (state: FeeTimerRecordingState) => fromFeeTimerRecord.selectCurrentState(state)
);

export const selectFeeTimerVisible = createSelector(
  selectFeeTimerStoreAsRecordingBase,
  (state: FeeTimerRecordingState) => fromFeeTimerRecord.selectVisible(state)
);

export const selectFeeTimerDuration = createSelector(
  selectFeeTimerStoreAsRecordingBase,
  (state: FeeTimerRecordingState) => fromFeeTimerRecord.selectDurationInSeconds(state)
);

export const selectFeeTimerMatter = createSelector(
  selectFeeTimerStoreAsRecordingBase,
  (state: FeeTimerRecordingState) => fromFeeTimerRecord.selectMatter(state)
);

export const selectFeeTimerSelectedActivityCode = createSelector(
  selectFeeTimerStoreAsRecordingBase,
  (state: FeeTimerRecordingState) => fromFeeTimerRecord.selectSelectedActivityCode(state)
);

export const selectRunningTimerFeeGUID = createSelector(
  selectFeeTimerStoreAsRecordingBase,
  (state: FeeTimerRecordingState) => fromFeeTimerRecord.selectRunningTimerFeeGUID(state)
);

export const selectIsNewEntry = createSelector(selectFeeTimerStoreAsRecordingBase, (state: FeeTimerRecordingState) =>
  fromFeeTimerRecord.selectIsNewEntry(state)
);

export const selectPendingTimeEntry = createSelector(selectFeeTimerStoreAsRecordingBase, (state: FeeTimerRecordingState) =>
  fromFeeTimerRecord.selectPendingTimeEntry(state)
);
