import { Injectable } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { LogService } from 'app/core/services';

import * as emailActions from '@app/features/+email/store/actions/email.actions';
import { Store } from '@ngrx/store';
import { isEmptyObj } from '@server/modules/shared/functions/common-util.functions';

@Injectable()
export class EmailFileuploadService {
  constructor(private _log: LogService, private _store: Store<any>) {
    this._log.init('email-file-upload-service');
  }

  process = (_uploader: FileUploader, e: any) => {
    if (!isEmptyObj(e)) {
      const newPendings = [];
      [...Array(_uploader.queue.length)].forEach((_) => {
        newPendings.push(_uploader.queue.pop());
      });

      if (newPendings.length > 0) {
        this._store.dispatch(new emailActions.AddAttachments(newPendings));
      }
    }
  };

  newUploader = () => new FileUploader({
      url: '',
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date(),
          });
        }),
    });
}
