<!-- Right part -->
<div [formGroup]="toolbarForm" class="footer-toolbar">
  <sc-switch
    class="footer-btn"
    formControlName="reconciled"
    [isDisabled]="status.reconciledDisabled"
    [textClass]="'text-uppercase'"
    [label]="'PaymentDetails.Options.Reconciled.Label' | translate"
  ></sc-switch>
  <sc-switch
    class="footer-btn"
    *ngIf="!status.chequeClearedHidden"
    formControlName="chequeCleared"
    [isDisabled]="status.chequeClearedDisabled"
    [textClass]="'text-uppercase'"
    [label]="'PaymentDetails.Options.ChequeCleared.Label' | translate"
  ></sc-switch>
  <sc-switch
    class="footer-btn"
    *ngIf="!status.printOnSaveHidden"
    formControlName="printOnSave"
    [textClass]="'text-uppercase'"
    [label]="'Core.Button.PrintAndEmail' | translate"
  ></sc-switch>
  <sc-btn
    class="footer-btn"
    btnType="default"
    class="btn-group"
    *ngIf="status.printOnSaveHidden"
    (btnClicked)="print()"
  >
    {{ 'Core.Button.PrintAndEmail' | translate }}
  </sc-btn>
</div>
