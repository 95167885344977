import { Component, HostBinding } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { getObjValue, isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-icon-status-cell',
  templateUrl: './icon-status-cell.component.html',
})
export class IconStatusCellComponent implements AgRendererComponent {
  iconName: string;
  iconSize: string;
  content: string;
  status: string;
  statusClass: string;

  constructor() {}

  @HostBinding('class.w-auto')
  agInit(params: any): void {
    this.iconName = isFunction(params['getIconName']) ? params.getIconName(params.data) : '';
    this.iconSize = isFunction(params['getIconSize']) ? params.getIconSize(params.data) : 'x-icon-md';
    this.content = isFunction(params['getContent']) ? params.getContent(params.data) : null;
    this.status = isFunction(params['getStatus']) ? params.getStatus(params.data) : false;
    this.statusClass = isFunction(params['getStatusClass']) ? params.getStatusClass(params.data) : false;
  }

  refresh(params: any): boolean {
    const getIconNameFunc = getObjValue(params, 'getIconName');
    const getIconSizeFunc = getObjValue(params, 'getIconSize');
    const getContentFunc = getObjValue(params, 'getContent');
    this.iconName = isFunction(getIconNameFunc) ? getIconNameFunc(params.data) : '';
    this.iconSize = isFunction(getIconSizeFunc) ? getIconSizeFunc(params.data) : 'x-icon-md';
    this.content = isFunction(getContentFunc) ? getContentFunc(params.data) : null;
    this.status = isFunction(params['getStatus']) ? params.getStatus(params.data) : false;
    this.statusClass = isFunction(params['getStatusClass']) ? params.getStatusClass(params.data) : false;
    return true;
  }
}
