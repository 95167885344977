import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'app/shared/services/base/base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IDefinableTable } from '@app/shared/models';
import {
  CalcRendererInit,
  costCalculatorTableInfo,
  GetLayoutSaveResponse,
  MatterLayout,
  SchemaLayoutList,
} from '../../models/layout-renderer.model';
import { UiUtilsService } from '@app/shared/services/ui/ui-utils.service';

@Injectable({
  providedIn: 'root',
})
export class LayoutRendererService extends BaseService {
  private _url: string;
  private _detailEntry: IDefinableTable;
  private _matterId: string;
  private _isRecurringMatter: boolean;
  private _layoutRenderWindows: any = {};

  get currentLayoutRendererDetailEntry(): IDefinableTable {
    return this._detailEntry;
  }

  get currentLayoutRendererMatterId(): string {
    return this._matterId;
  }

  get isRecurringMatter(): boolean {
    return this._isRecurringMatter;
  }

  constructor(private _http: HttpClient, private _utilSvc: UiUtilsService) {
    super();
    this._url = environment.config.layout.renderer;
  }

  clearDetails() {
    this._detailEntry = null;
    this._matterId = '';
    this._isRecurringMatter = false;
  }

  setDetails(detailEntry: IDefinableTable, matterId: string, isRecurringMatter: boolean = false) {
    this._detailEntry = detailEntry;
    this._matterId = matterId;
    this._isRecurringMatter = isRecurringMatter;
  }

  getLayoutUrl(matterLayout = false): Observable<CalcRendererInit> {
    let url = this.urlJoin(this.siriusPath, 'api/layout-renderer/init');
    if (!!this._isRecurringMatter) {
      url = `${this.siriusPath}/api/recurringMatters/${this._matterId}/layout/init`;
    }
    return this._http.post<CalcRendererInit>(url, { matterId: this._matterId, detailEntry: this._detailEntry });
  }

  getDefinableObject(url: string): Observable<GetLayoutSaveResponse> {
    return this._http.get<GetLayoutSaveResponse>(url);
  }

  cancel(url: string): Observable<string> {
    return this._http.get(url, { responseType: 'text' });
  }

  selectListItem(query: any): Observable<string> {
    const url = this.urlJoin(this.siriusPath, 'api/layout-renderer/list/select-item');
    return this._http.post(url, query, { responseType: 'text' });
  }

  refreshList(query: any): Observable<string> {
    const url = this.urlJoin(this.siriusPath, 'api/layout-renderer/list/update');
    return this._http.post(url, query, { responseType: 'text' });
  }

  getSchemaList(id: string): Observable<SchemaLayoutList> {
    const url = `${this.siriusPath}/api/scema/list/${id}`;
    return this._http.get<any>(url);
  }

  /**
   * Open a popout window for layout renderer
   * matterLayout -- the current matterLayout obj. We want to use the same obj in the popout window
   * height -- the height of the iFrame. Originally, the height is coming from layout renderer api
   * width -- the width of the iFrame. Originally, the width is coming from layout renderer api
   *
   * @return
   * */
  openLayoutRenderPopoutWindow(data: { height: number; width: number; matterLayout: MatterLayout }): Window {
    const win = this.getWindow();
    if (!!win) {
      win.focus();
      return win;
    } else {
      const { height, width, matterLayout } = data;
      const id = this._detailEntry.__id || `${this._detailEntry.__relatedParentTableId}-${this._detailEntry.layoutId}`;
      const layoutUrl = `${window.location.origin}/new-win/layout/layout-renderer/${id}?sessionId=${
        matterLayout.calcSessionId
      }&matterId=${this._matterId || ''}&isSuperLayout=${matterLayout.isSuperLayout}&className=${
        matterLayout.className
      }&objectId=${matterLayout.objectId}&baseUrl=${encodeURIComponent(matterLayout.baseUrl)}`;

      const sizeAndPosition = this._utilSvc.getSizeAndPosition(width, height);
      const winOptions = `width=${sizeAndPosition.width}, height=${sizeAndPosition.height}, top=${sizeAndPosition.top}, left=${sizeAndPosition.left}`;
      return this._utilSvc.openPopup2(layoutUrl, '', winOptions);
    }
  }

  /**
   * Insert the popout window reference into _layoutRenderWindows
   * When we try to open a layout renderer, we would firstly check whether there is a popout window for that layout renderer.
   * */
  insertPopoutWinRef(popoutWin: Window): void {
    if (!popoutWin) {
      return;
    }

    this._layoutRenderWindows[this._matterId] = !!this._layoutRenderWindows[this._matterId]
      ? this._layoutRenderWindows[this._matterId]
      : {};

    const id = this._detailEntry.__id || `${this._detailEntry.__relatedParentTableId}-${this._detailEntry.layoutId}`;
    this._layoutRenderWindows[this._matterId][id] = popoutWin;
    popoutWin.addEventListener('message', (e: MessageEvent) => {
      if (e.origin !== window.location.origin) {
        return;
      }
      if (e.data?.indexOf('action') !== -1) {
        const data = JSON.parse(e.data);
        if (
          data.action === 'clear-parent-layout-windows' &&
          !!this._layoutRenderWindows[data.matterId] &&
          !!this._layoutRenderWindows[data.matterId][data.__id]
        ) {
          this._layoutRenderWindows[data.matterId][data.__id] = null;
        }
      }
    });
  }

  /**
   * Get the popout window ref for a specific __id of a specific matter
   *
   * @return
   * */
  getWindow(data?: { matterId: string; __id: string }): Window {
    const matterId = !!data ? data.matterId : this._matterId;
    const __id = !!data
      ? data.__id
      : !!this._detailEntry
      ? this._detailEntry.__id || `${this._detailEntry.__relatedParentTableId}-${this._detailEntry.layoutId}`
      : null;
    return !!this._layoutRenderWindows &&
      !!matterId &&
      !!__id &&
      !!this._layoutRenderWindows[matterId] &&
      !!this._layoutRenderWindows[matterId][__id]
      ? this._layoutRenderWindows[matterId][__id]
      : null;
  }

  /**
   * Create a detailEntry for CostCalculator
   * */
  createCostCalculatorDetailEntry(): IDefinableTable {
    return {
      __name: costCalculatorTableInfo.__name,
      tableId: costCalculatorTableInfo.tableId,
      layoutId: costCalculatorTableInfo.layoutId,
      __id: costCalculatorTableInfo.__id,
      __fileOrder: -1,
    } as IDefinableTable;
  }
}
