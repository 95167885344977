import { Injectable } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { EventBusService } from '@app/core/services';
import { ESiriusEvents } from '@app/core/models';
import { AppApiService } from '@app/core/api';
import * as cardListModalActions from '@app/features/+card/store/actions/card-list-modal';
import { ICardListModelSelection, ECardFilterType } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CardListenerService {
  private _cardSelectEventSub: Subscription;
  private _destroy$ = new Subject<void>();

  destroy = (): void => {
    if (this._destroy$) {
      this._destroy$.next();
      this._destroy$ = null;
    }
  };

  getUniqueGUIDs = (guids: string[]): string[] => {
    if (!guids || guids.length === 0) {
      return [];
    }

    const uniqueCardIds = new Set<string>(guids);
    return [...uniqueCardIds].filter(Boolean);
  };

  listenToCardSelectedEvent = (next: (data: ICardListModelSelection) => void): void => {
    this.init();

    if (this._cardSelectEventSub) {
      this._cardSelectEventSub.unsubscribe();
      this._cardSelectEventSub = null;
    }

    this._cardSelectEventSub = this._eventBusSvc
      .listen(ESiriusEvents.GetSelectedCardEntries)
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe((data: ICardListModelSelection) => {
        next(data);
        this.destroy();
      });
  };

  redirectToSingleCardSelection = (cardGUIDs: string[], includeAllCardOption = true): void => {
    if (cardGUIDs?.length > 1) {
      this._store.dispatch(new cardListModalActions.SetCardFilterType({ type: ECardFilterType.CardGuidArray }));
      this._store.dispatch(new cardListModalActions.SetSelectedCardFilterType({ type: 'CardGuidArray' }));
      this._store.dispatch(new cardListModalActions.SetCardGuidsForFiltering({ ids: cardGUIDs, includeAllCardOption }));

      this._appApiSvc.navigate({
        path: [{ outlets: { selectorDetail: ['card', 'card-list'] } }],
        extras: { skipLocationChange: true, queryParamsHandling: 'preserve' },
      });
    }
  };

  constructor(private _eventBusSvc: EventBusService, private _store: Store, private _appApiSvc: AppApiService) {}

  private init = (): void => {
    if (this._destroy$ === null) {
      this._destroy$ = new Subject<void>();
    }
  };
}
