import { v4 as uuidv4 } from 'uuid';
import format from 'date-fns/format';

import { Staff, IDoc, IDocAttachment, IComment, CorrespondenceType } from '../../../shared/models';
import { isDocument } from '../../../shared/functions';
import { DocumentIconHelper } from '../../../shared/utils';
import { IDocumentDTO, IDocAttachmentDTO } from '../models';
import { hasProp } from '../../../../../server/modules/shared/functions/common-util.functions';

export const toDocumentDTO = (document: IDoc): IDocumentDTO =>
  ({
    documentId: document.id,
    documentTypeId: document.type,
    toFrom: document.toFrom,
    docName: document.name,
    createDate: format(new Date(document.createDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    userId: document.userId,
    staffInitials: document.staffInitials,
    version: document.version,
    latestVersion: document.latestVersion,
    ext: document.ext,
    deleteCode: document.deleteCode,
    attachments:
      document.attachments?.map((attachment: IDocAttachment): IDocAttachmentDTO => toAttachmentDTO(attachment)) || [],
    orderDate:
      document.orderDate === undefined
        ? undefined
        : format(new Date(document.orderDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    orderId: document.orderId,
    orderBy: document.orderBy,
    availableOnline: document.availableOnline,
    url: document.url,
    status: document.status,
    dateCompleted:
      document.dateCompleted === undefined
        ? undefined
        : format(new Date(document.dateCompleted), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    transferMode: document.transferMode,
    pin: document.pin,
    desktopOnly: document.desktopOnly,
  } as IDocumentDTO);

export const toDocument = (
  documentDTO: IDocumentDTO,
  userDetails: Staff,
  documentIconHelper: DocumentIconHelper,
  basePreviewUrl: string
): IDoc => {
  const options = Object.assign({}, documentDTO as any) as IDoc;
  options.id = documentDTO.documentId;
  options.type = documentDTO.documentTypeId;
  options.name = documentDTO.docName;
  options.deleted = documentDTO.deleteCode > 0;
  options.deleteCode = documentDTO.deleteCode;
  options.transferMode = documentDTO.transferMode === null ? undefined : documentDTO.transferMode;
  options.attachments =
    documentDTO.attachments?.map(
      (attachmentDTO: IDocAttachmentDTO): IDocAttachment => toAttachment(attachmentDTO, basePreviewUrl)
    ) || [];

  options.userId = documentDTO.userId;
  options.staffInitials = documentDTO.staffInitials;
  options.pin = documentDTO.pin;
  options.desktopOnly = documentDTO.desktopOnly;

  const latestVersionId = documentDTO.latestVersion || documentDTO.documentId;
  options.previewUrl = getPreviewUrl(basePreviewUrl, options, { id: latestVersionId });
  options.pendingPrecedent = !!documentDTO.pendingPrecedent ? documentDTO.pendingPrecedent : null;

  // infotrack
  if (documentDTO.orderId) {
    options.createDate = options.orderDate;
  }
  options.signatureStatus = documentDTO.signatureStatus;
  return createDocument(options, userDetails, documentIconHelper);
};

export const createDocument = (
  options: any = {},
  userDetails: Staff,
  documentIconHelper: DocumentIconHelper
): IDoc | IComment => {
  const userStaffDetails = userDetails;
  const correspondence: IDoc = {
    id: hasProp(options, 'id') === true ? options.id : uuidv4(),
    type: options.type !== undefined ? options.type : CorrespondenceType.Document,
    toFrom: options.toFrom,
    name: options.name,
    createDate: new Date(options.createDate),
    lastModified: new Date(options.lastModified),
    userId: options.userId || (userStaffDetails && userStaffDetails.userId),
    staffInitials: options.staffInitials || (userStaffDetails && userStaffDetails.initials),
    staffName: options.staffName || (userStaffDetails && userStaffDetails.fullName),
    version: options.version !== undefined ? options.version : 1,
    latestVersion: options.latestVersion,
    ext: options.ext,
    deleted: options.deleted,
    attachments: options.attachments !== undefined ? options.attachments : [],
    orderDate: options.orderDate !== undefined ? new Date(options.orderDate) : undefined,
    orderId: options.orderId,
    orderBy: options.orderBy,
    availableOnline: options.availableOnline,
    url: options.url,
    status: options.status,
    dateCompleted: options.dateCompleted !== undefined ? new Date(options.dateCompleted) : undefined,
    transferMode: options.transferMode,
    iconClass: options.iconClass,
    iconTooltip: options.iconTooltip,
    pin: options.pin,
    desktopOnly: options.desktopOnly,
    previewUrl: options.previewUrl,
    pendingPrecedent: options.pendingPrecedent,
    matterDoxStatus: options.matterDoxStatus,
    signatureStatus: options.signatureStatus,
  };

  if (options.iconClass === undefined && options.iconTooltip === undefined) {
    const iconInfo = documentIconHelper.getDocumentIconInfo(correspondence);

    correspondence.iconClass = iconInfo.iconClass;
    correspondence.iconTooltip = iconInfo.tooltip;
  }

  if (correspondence.type === CorrespondenceType.Comment) {
    createComment(correspondence, options);
  }

  return correspondence;
};

export const createComment = (correspondence: IDoc, options: any): IComment => {
  const comment: IComment = correspondence as IComment;
  comment.body = options.body;
  Object.defineProperty(comment, 'title', {
    get: (): (() => string) => () => {
      const body: string = comment.body;
      const regexp = `/<[strong][^>]*><[span][^>]*>Edited by\s\w*\s\w*\s\on\s(Sat|Sun|Mon|Tue|Wed|Thu|Fri),\s
          ([0-9]{2}|[0-9])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Apr|Sep|Oct|Nov|Dec)\s\d{4}\s\at\s
          ([0-9]{2}|[0-9]):([0-9]{2}|[0-9])\s(AM|PM)<\/[span][^>]*><\/[strong][^>]*>/gm;`;

      const noStampString: string = body
        .replace(regexp, '')
        .replace(/<\/p>/gm, '\n')
        .replace(/<\/h[1-6]>/gm, '\n')
        .replace(/<br\s*\/?>/gm, '\n')
        .replace(/<(?:.|\n)*?>/gm, '')
        .trim()
        .replace(/\n+.*$/gm, '')
        .trim();

      let title: string = noStampString.length > 0 ? noStampString : '';
      if (title.length === 0) {
        title = title.replace(/<(?:.|\n)*?>/gm, '').trim();
      }

      if (title.length > 60) {
        // Final regexp to remove extra css classes in title
        title = title
          .replace(/body{([^}]+)}/g, '')
          .trim()
          .substr(0, 60)
          .concat('...');
      }
      return title.trim();
    },
  });
  return comment;
};

export const toAttachment = (attachmentDTO: IDocAttachmentDTO, basePreviewUrl: string): IDocAttachment => {
  const ext: string = attachmentDTO.name?.split('.').pop();
  const name: string = attachmentDTO.name?.replace(`.${ext}`, '');
  const id: string = attachmentDTO.attachmentId;
  const options: IDocAttachment = {
    id,
    name,
    ext,
    deleted: attachmentDTO.deleteCode === 1,
  };

  return {
    ...options,
    previewUrl: getPreviewUrl(basePreviewUrl, options),
  };
};

export const toAttachmentDTO = (attachment: IDocAttachment): IDocAttachmentDTO =>
  ({
    attachmentId: attachment.id,
    name: `${attachment.name}.${attachment.ext}`,
    deleteCode: attachment.deleted === true ? 1 : 0,
  } as IDocAttachmentDTO);

const getPreviewUrl = (
  basePreviewUrl: string,
  correspondence: IDoc | IDocAttachment,
  options?: { id?: string; ext?: string }
): string => {
  const { id, ext } = { ...correspondence, ...options } as IDoc | IDocAttachment;
  const queryUrl: string = isDocument(correspondence) ? '?format=pdf' : '';
  return `${basePreviewUrl}/${id}.${ext}${queryUrl}`;
};
