import { from as observableFrom, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Dexie } from 'dexie';
import { DexieService } from '../dexie/dexie.service';
import { LogService } from '../log/log.service';
import { IGlobalUIPreference } from '../../models';
import { PlatformService } from '../platform/platform.service';
import { omitObjValue } from '../../../../../server/modules/shared/functions/common-util.functions';

@Injectable()
export class AppStorageService {
  tableKey = '__APP_UI_PREFERENCE__';
  table: Dexie.Table<GlobalUIPreferenceInfo, string>;

  constructor(private _dexieSvc: DexieService, private _logSvc: LogService, private _platformSvc: PlatformService) {
    this._logSvc.init('app-storage-service');
    if (this._platformSvc.isBrowser) {
      this.table = this._dexieSvc.table('appUIPreference');
    }
  }

  upsertAll(updatedInfo: IGlobalUIPreference) {
    if (this._platformSvc.isBrowser) {
      // headerHidden is a special field for officeonline route and should not be cached
      const uiInfo: GlobalUIPreferenceInfo = omitObjValue(
        {
          ...updatedInfo,
          id: this.tableKey,
        },
        ['headerHidden']
      );
      return this.table.put(uiInfo);
    }
  }

  get(): Observable<IGlobalUIPreference> {
    if (this._platformSvc.isBrowser) {
      return observableFrom(this.table.get(this.tableKey));
    }
  }
}

interface GlobalUIPreferenceInfo extends IGlobalUIPreference {
  id: string;
}
