<div *ngIf="!dragNdrop; else dropzone">
  <input
    #newuploads
    class="d-none"
    type="file"
    ng2FileSelect
    [uploader]="uploader"
    multiple
    (onFileSelected)="onFileSelected($event)"
  />
</div>
<ng-template #dropzone>
  <div
    class="x-drop-zone"
    ng2FileDrop
    [class.d-none]="!showDropzone"
    (dragleave)="showDropzone = false"
    [ngClass]="{ 'is-dragover': hasBaseDropZoneOver }"
    (fileOver)="onFileOverBase($event)"
    (onFileDrop)="onFileDrop($event)"
    [uploader]="uploader"
  >
    <h3 class="x-file-drop-message">
      <sc-icon iconName="paperclip"></sc-icon>{{ 'Email.New.Dropzone.Label' | translate }}
    </h3>
  </div>
</ng-template>
