import { IMatterDetailEntry, EMatterDetailType } from '@app/shared/models';
import { DebtorClass, OrderCalculationPolicies, TableConfigIds } from '@app/features/+matter-details/constants';

import { IMatterTableTypeVM, ISubstitutedTableMapping } from '../models';
import { isMatterCard } from '@app/features/+matter-details/functions';
import { isEmptyObj, sortAscBy } from '@server/modules/shared/functions/common-util.functions';

/**
 * Based on the order of representativeEntries to calculate __displayOrder
 * and merge them into a single sorted array
 *
 * @param representativeEntries
 * @param dependentEntries
 * @returns
 */
export const calculateDetailEntriesDisplayOrder = (
  representativeEntries: IMatterDetailEntry[],
  dependentEntries: IMatterDetailEntry[],
  retainCurrentSorting = true
): IMatterDetailEntry[] => {
  let index = 1;
  const entries = [...representativeEntries, ...dependentEntries];
  const sameTypeUnit = OrderCalculationPolicies.SameTypeRange;
  const diffTypeUnit = OrderCalculationPolicies.DifferentTypeRange;
  const clientNumber = (entries || []).filter((x) => x.tableId === TableConfigIds.CLIENT).length;
  const isRelatedLayout = (entry: IMatterDetailEntry, mainCardTableId: string) => !isMatterCard(entry) && entry.__tableId === mainCardTableId;
  const debtorEntry = dependentEntries?.find((y) => y.__className === DebtorClass);

  const result = isEmptyObj(debtorEntry)
    ? []
    : [{ ...debtorEntry, __displayOrder: diffTypeUnit + clientNumber * sameTypeUnit + 1 }];

  // retainCurrentSorting === false when u need system sorting order instead of user sorting order.
  const representativeEntriesUpdated = retainCurrentSorting
    ? representativeEntries
    : sortAscBy(representativeEntries, (value) => value.__displayOrder);
  representativeEntriesUpdated?.forEach((rEntry: IMatterDetailEntry) => {
    const sameTypeEntries = [
      rEntry,
      ...(dependentEntries || []).filter((entry: IMatterDetailEntry) => entry.tableId === rEntry.tableId || isRelatedLayout(entry, rEntry.tableId)),
    ];
    result.push(
      ...(sameTypeEntries || []).map((sEntry: IMatterDetailEntry) => {
        const inTypeOrder = sEntry.__displayOrder % diffTypeUnit;
        return { ...sEntry, __displayOrder: index * diffTypeUnit + inTypeOrder };
      })
    );
    index++;
  });

  return sortAscBy(result, (value) => value.__displayOrder);
};

/**
 * Convert ui table types into primary matter's detail entries
 *
 * @param tableTypeVMs
 * @param initialEntries
 * @param matterId
 * @returns
 */
export const mapTableTypeVMsToDetailEntries = (
  tableTypeVMs: IMatterTableTypeVM[],
  initialEntries: IMatterDetailEntry[],
  matterId: string
): IMatterDetailEntry[] => (
    (tableTypeVMs?.map((tEntry: IMatterTableTypeVM) => {
      const matchedEntry = initialEntries?.find((iEntry) =>
        [
          [
            iEntry.tableId === tEntry.tableId,
            orderInType(iEntry) === orderInType(tEntry),
            iEntry.detailType === tEntry.detailType,
          ].every((valid) => !!valid),
          tEntry.__className === DebtorClass && iEntry.__className === DebtorClass,
        ].some((valid) => !!valid)
      );

      // Either we update the existing entry or add new item to the array.
      if (!isEmptyObj(matchedEntry)) {
        return {
          ...matchedEntry,
          __displayOrder: tEntry.__displayOrder,
          default: tEntry.default,
        };
      } else {
        return {
          isCard: isMatterCard(tEntry),
          __fileOrder: 1,
          __displayOrder: tEntry.__displayOrder,
          __name: tEntry.__name,
          tableId: tEntry.tableId,
          __tableId: tEntry.__tableId,
          detailType: isMatterCard(tEntry) ? EMatterDetailType.Card : EMatterDetailType.DefinableTable,
          default: tEntry.default,
          ...(isMatterCard(tEntry) ? {} : { __matterId: matterId }),
        };
      }
    }) as IMatterDetailEntry[]) || []
  );

export const orderInType = (entry: IMatterDetailEntry): number => entry.__displayOrder % OrderCalculationPolicies.DifferentTypeRange;

export const buildSelectableTableTypes = (
  sourceList: IMatterTableTypeVM[],
  selectedList: IMatterTableTypeVM[],
  tableMappings: ISubstitutedTableMapping[]
): IMatterTableTypeVM[] => (
    sourceList?.reduce((result: IMatterTableTypeVM[], sourceItem: IMatterTableTypeVM) => {
      // Ignore if empty or invalid item.
      if (!sourceItem || (!sourceItem.tableId && !sourceItem.__tableId)) {
        return result;
      }

      // Enhance with mapping and append item to result list.
      const matchedInCurrentList = selectedList?.find((t) => t.tableId === sourceItem.tableId);
      const mapping = tableMappings?.find((x) => x.tableId === sourceItem.tableId);
      return [
        ...result,
        {
          ...sourceItem,
          isSelected: !!matchedInCurrentList,
          removable: !!matchedInCurrentList ? matchedInCurrentList.removable : true,
          ...(!isEmptyObj(mapping)
            ? {
                subsTableId: mapping.subsTableId,
                __name: mapping.subsTableName,
              }
            : {}),
        },
      ];
    }, []) || []
  );
