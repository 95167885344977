import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'sc-matter-status-cell',
  templateUrl: './matter-status-cell.component.html',
})
export class MatterStatusCellComponent implements ICellRendererAngularComp {
  matterStatus: string;
  isHidden: boolean;

  constructor() {}

  agInit(params: any): void {
    this.matterStatus = this.isHidden ? '' : params.getMatterStatus(params.data);
  }

  refresh(params: any): boolean {
    return true;
  }
}
