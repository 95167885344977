import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ITrustReceipt } from '@app/features/+trust-receipt/models';
import { FormGroup } from '@angular/forms';
import { TrustReceiptService } from '@app/features/+trust-receipt/services/trust-receipt/trust-receipt.service';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { isObjEqual } from '@server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-trust-receipt-reverse',
  templateUrl: './trust-receipt-reverse.component.html',
})
export class TrustReceiptReverseComponent implements OnInit, OnDestroy {
  @Input() bankAccountName: string;

  @Input()
  set inputData(data: Partial<ITrustReceipt>) {
    if (!!data) {
      this.receiptDetails = data;
      if (!!this.receiptForm) {
        this.receiptForm.patchValue(data);
      } else {
        this.createForm(data);
      }
    }
  }

  @Output() onDetailsFormUpdated = new EventEmitter<any>();

  public receiptForm: FormGroup;
  public receiptDetails: Partial<ITrustReceipt>;

  private _formSub: Subscription;
  private unsub = new Subject<void>();

  constructor(private _trustReceiptSvc: TrustReceiptService) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }

  private createForm(options: Partial<ITrustReceipt>): void {
    this.receiptForm = this._trustReceiptSvc.createDetailsFormGroup(options);
    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges(): void {
    this._formSub = this.receiptForm.valueChanges
      .pipe(
        distinctUntilChanged(isObjEqual),
        takeUntil(this.unsub) // important: this operator must be last
      )
      .subscribe(() => {
        this.onDetailsFormUpdated.emit(this.receiptForm.getRawValue());
      });
  }
}
