import { Directive, OnInit, ElementRef, AfterViewInit, Input } from '@angular/core';

@Directive({
  selector: '[scAutofocus]',
})
export class AutofocusDirective implements OnInit, AfterViewInit {
  @Input()
  autofocus: boolean;
  @Input()
  selectOnFocus: boolean;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.autofocus === undefined || this.autofocus === null || this.autofocus) {
      setTimeout(() => {
        this.elementRef.nativeElement.focus();
        if (this.selectOnFocus) {
          this.elementRef.nativeElement.select();
        }
      });
    }
  }
}
