import { ISelectItemOptions, ISortInfo, IDocsFolder, IDoc, TransferMode, PendingPrecedent } from '@app/shared/models';

export interface IRenameActionPayload {
  correspondence: IDocsFolder | IDoc;
  name: string;
}

export interface IMoveToFolderActionPayload {
  correspondence?: IDoc | IDocsFolder;
  toFolder?: IDocsFolder;
}

export interface IListCorrespondenceActionPayload {
  matterId: string;
  fetchMode?: string;
}

export interface ICorrespondenceListUIInfo {
  showDeleted?: boolean;
  showCreated?: boolean;
  expandedIdList?: string[];
}

export interface CorrespondenceListMetaInfo {
  matterId?: string;
  root?: IDocsFolder;
  selectedFolder?: IDocsFolder;
  selectedFolderId?: string;
  selectedCorrespondence?: IDoc;
  searchText?: string;
  showDeleted?: boolean;
  sortInfo?: ISortInfo;
  uiInfo?: ICorrespondenceListUIInfo;
}

export interface IDocumentDTO {
  documentId: string;
  documentTypeId: number;
  toFrom: string;
  docName: string;
  createDate: string;
  lastModified: string;
  matterDoxStatus: string;
  userId: string;
  staffInitials: string;
  version: number;
  latestVersion: string;
  ext: string;
  attachments: IDocAttachmentDTO[];
  orderDate: string;
  orderId: string;
  orderBy: string;
  availableOnline: boolean;
  url: string;
  status: string;
  dateCompleted: string;
  transferMode: TransferMode;
  pin: string;
  desktopOnly: boolean;
  deleteCode?: number;
  pendingPrecedent?: PendingPrecedent;
  signatureStatus?: number;
}

export interface IDocAttachmentDTO {
  attachmentId: string;
  name: string;
  deleteCode: number;
}

export interface IDocsFolderDTO {
  parentId: string;
  folderId: string;
  folderName: string;
  documents: string[];
  folders: IDocsFolderDTO[];
  activeShared: boolean;
}

export interface ICorrespondenceList {
  root: IDocsFolder;
  folders: IDocsFolder[];
  correspondences: IDoc[];
  allCorrespondences?: IDoc[];
}

export interface IDocUploadParams {
  file: File;
  createdToday: boolean;
}

export interface IDocAttachmentDTO {
  attachmentId: string;
  name: string;
  deleteCode: number;
}

export type ISelectCorrespondencePayload = ISelectItemOptions<IDoc | IDocsFolder>;

export interface CorrespondenceDownloadProgress {
  fileName: string;
  fileNameOnly: string;
  url: string;
  loading: boolean;
}

export enum CorrespondenceListMode {
  Normal = 0,
  Search = 1,
}
