import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPaymentDetail } from '@app/features/+payments-debtors/models';
import { InflowPaymentTypeParserService } from '@app/features/accounting/services';
import format from 'date-fns/format';
import { PaymentTypeId } from '../../constants';

@Component({
  selector: 'sc-inflow-payment-type-details-readonly',
  templateUrl: './inflow-payment-type-details-readonly.component.html',
  styleUrls: ['./inflow-payment-type-details-readonly.component.scss'],
})
export class InflowPaymentTypeDetailsReadonlyComponent {
  showDetails = false;
  displayDetails: IPaymentDetail;
  private _paymentTypeDetails: IPaymentDetail;

  @Input()
  paymentTypeName: string;

  @Input()
  get paymentTypeId(): number {
    return this._paymentTypeId;
  }
  set paymentTypeId(value: number) {
    this._paymentTypeId = value;
  }

  @Input()
  get paymentTypeDetails(): any {
    return this._paymentTypeDetails;
  }

  set paymentTypeDetails(value: any) {
    if (value) {
      this._paymentTypeDetails = value;
      this.parsePaymentTypeDetails(value);
    }
  }

  PaymentTypeId = PaymentTypeId;

  @Output()
  onOpenPaymentDetails = new EventEmitter<void>();

  private _paymentTypeId: number;
  constructor(private _inflowPaymentParser: InflowPaymentTypeParserService) {}

  openPaymentDetailsModal() {
    this.onOpenPaymentDetails.emit();
  }

  get isPaymentDetailsEmpty(): boolean {
    return !this._inflowPaymentParser.hasPaymentDetail(this.paymentTypeDetails, this.paymentTypeId);
  }

  parsePaymentTypeDetails(data: any) {
    const propertyArray = Object.getOwnPropertyNames(data);
    if (
      propertyArray.includes('BSB') ||
      propertyArray.includes('Drawer') ||
      propertyArray.includes('ChequeNumber') ||
      propertyArray.includes('AccountName') ||
      propertyArray.includes('Memo') ||
      propertyArray.includes('AccountNumber') ||
      propertyArray.includes('PayableTo')
    ) {
      this.displayDetails = data;
    } else {
      this.displayDetails = this._inflowPaymentParser.get(this._paymentTypeDetails, this._paymentTypeId);
    }

    if (this.displayDetails?.ExpiryDate && this.displayDetails.ExpiryDate.indexOf('/') === -1) {
      this.displayDetails = {
        ...this.displayDetails,
        ExpiryDate: format(new Date(this.displayDetails.ExpiryDate), 'MM/ yyyy'),
      };
    }
  }
}
