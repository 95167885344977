import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { HotkeyModule, HotkeysService } from 'angular2-hotkeys';
import { NgSelectModule } from '@ng-select/ng-select';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputMaskModule } from 'primeng/inputmask';
import { CalendarModule } from 'primeng/calendar';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { TranslateModule } from '@ngx-translate/core';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FileUploadModule } from 'ng2-file-upload';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { NgxFloatUiModule } from 'ngx-float-ui';
import { LayoutModule } from '@angular/cdk/layout';

import { sharedComponents } from './components';
import { sharedDirectives } from './directives';
import { sharedPipes } from './pipes';
import { sharedServices } from './services';
import { ChipsModule } from 'primeng/chips';
import { ConfirmationService, ContextMenuService } from 'primeng/api';
import { Angulartics2Module } from 'angulartics2';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    /* angular stuff */
    CommonModule,
    RouterModule,
    FormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode'
    }),
    TranslateModule,
    ReactiveFormsModule,

    /* 3rd party components */
    Angulartics2Module,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    HotkeyModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    NgSelectModule,
    ChipsModule,
    ProgressbarModule.forRoot(),
    DropdownModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    CalendarModule,
    NgxPopperjsModule.forRoot(),
    NgxFloatUiModule.forRoot(),
    NgClickOutsideDirective,
    NgxPermissionsModule.forChild(),
    ContextMenuModule,
    FileUploadModule,
    UiSwitchModule.forRoot({
      size: 'small',
      defaultBoColor: 'null',
    }),
    PdfViewerModule,
    InputMaskModule,
    LayoutModule,
    DragDropModule,
  ],
  declarations: [...sharedComponents, ...sharedDirectives, ...sharedPipes],
  exports: [
    /* angular stuff */
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    BsDropdownModule,
    CalendarModule,
    PopoverModule,
    TooltipModule,
    TabsModule,
    CollapseModule,
    ModalModule,
    AlertModule,
    NgSelectModule,
    ChipsModule,
    DropdownModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    ContextMenuModule,
    UiSwitchModule,
    NgxPermissionsModule,
    NgClickOutsideDirective,
    FileUploadModule,
    DragDropModule,
    ...sharedComponents,
    ...sharedDirectives,
    ...sharedPipes,
  ],
  providers: [...sharedServices, ...sharedPipes, CurrencyPipe, DatePipe, ContextMenuService],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [HotkeysService, ConfirmationService],
    };
  }
}
