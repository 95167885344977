import { AppSlice } from '@app/core/store';
import { createFeatureSelector } from '@ngrx/store';

import * as fromBatchSearch from './batch-search.reducer';

export type State = fromBatchSearch.State;

export const initialState: State = fromBatchSearch.initialState;

export const reducers = fromBatchSearch.reducer;

export const selectBatchSearchState = createFeatureSelector<State>(AppSlice.BatchSearch);
