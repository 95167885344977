import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { NGXLogger } from 'ngx-logger';

const noop = (): any => undefined;

@Injectable()
export class LogService {
  info: any;
  warn: any;
  debug: any;
  error: any;

  constructor(private log: NGXLogger) {
    console.log('LogService ctor');
    this.setupLogs('core');
  }

  // rename log service
  // TODO: find a better way to inject name
  init(name: string) {
    console.log('LogService init', name);
    this.setupLogs(name);
  }

  setupLogs(name: string) {
    this.error = this.log.error.bind(this.log);
    if (!environment.production) {
      this.debug = this.log.debug.bind(this.log);
      this.info = this.log.info.bind(this.log);
      this.warn = this.log.warn.bind(this.log);
    } else {
      this.debug = noop;
      this.info = noop;
      this.warn = noop;
    }
  }
}
