<div class="x-preview-toolbar-tabs">
  <sc-tabs wrapperClass="h-100">
    <h6 class="x-toolbar-text" *ngIf="(uiPreference$ | async).asideExpanded">
      <strong *ngIf="atpMatterNo">MATTER NO: {{ atpMatterNo }}</strong>
      <strong *ngIf="atpLabel">{{ atpLabel }}</strong>
    </h6>
    <sc-tab-item-icon
      iconName="chevron-left-grid-20"
      class="ml-auto h-100"
      [IconClass]="{ 'x-transition-transform': true, 'x-scale-x-min-1': (uiPreference$ | async).asideExpanded }"
      (click)="toggleAside()"
    ></sc-tab-item-icon>
  </sc-tabs>
</div>

<!--<div class="col-xs-20">-->
<!--<div *ngIf="atpPrint || !atpHideOption" class="row">-->
<!--<ul class="aside-top-panel-btn-group">-->
<!--<li *ngIf="atpPrint.length > 1" dropdown>-->
<!--<a class="btn btn-aside-top-panel" dropdownToggle aria-haspopup="true" aria-expanded="true" href scPreventDefault>-->
<!--<i [ngClass]="'tk-icon-print-grid-20'"></i>Print-->
<!--</a>-->
<!--<ul class="dropdown-menu dropdown-icons" aria-labelledby="dropdownPrint" *dropdownMenu role="menu">-->
<!--<li *ngFor="let print of atpPrint" [ngClass]="{divider : !print.value}">-->
<!--<a href scPreventDefault (click)="perform(print.name)" *ngIf="print.value" [innerHtml]="print.value | translate"></a>-->
<!--</li>-->
<!--</ul>-->
<!--</li>-->
<!--<li *ngIf="atpPrint.length === 1">-->
<!--<a class="btn btn-aside-top-panel" (click)="perform(atpPrint[0].name)" href scPreventDefault>-->
<!--<i [ngClass]="'tk-icon-print-grid-20'"></i>Print-->
<!--</a>-->
<!--</li>-->
<!--<li class="pull-right" *ngIf="!atpHideOption" dropdown>-->
<!--<a class="btn btn-aside-top-panel" dropdownToggle-->
<!--aria-haspopup="true" aria-expanded="true" href scPreventDefault>-->
<!--<i [ngClass]="'tk-icon-cog'"></i>Options-->
<!--</a>-->
<!--<ul class="dropdown-menu dropdown-icons" aria-labelledby="dropdownPrint" *dropdownMenu role="menu">-->
<!--<li>-->
<!--<a href (click)="perform('EDIT_MATTER_DETAILS')" [innerHtml]="'Matter.Details.ContextMenu.Edit' | translate"></a>-->
<!--</li>-->
<!--<li class="divider"></li>-->
<!--<li>-->
<!--<a href (click)="perform('EDIT_MATTER_TITLE')" [innerHtml]="'Matter.Details.ContextMenu.Title' | translate"></a>-->
<!--</li>-->
<!--<li>-->
<!--<a href (click)="perform('EDIT_MATTER_REFERRER')" [innerHtml]="'Matter.Details.ContextMenu.Referrer' | translate"></a>-->
<!--</li>-->
<!--<li>-->
<!--<a href (click)="perform('EDIT_MATTER_ACCOUNTING')" [innerHtml]="'Matter.Details.ContextMenu.Accounting' | translate"></a>-->
<!--</li>-->
<!--<li *ngIf="!atpHideSpecialFees">-->
<!--<a href (click)="perform('EDIT_MATTER_SPECIAL_FEES')" [innerHtml]="'Matter.Details.ContextMenu.SpecialFees' | translate"></a>-->
<!--</li>-->
<!--<li>-->
<!--<a href (click)="perform('EDIT_MATTER_ACCESS_PERMISSIONS')" [innerHtml]="'Matter.Details.ContextMenu.Permissions' | translate"></a>-->
<!--</li>-->
<!--<li>-->
<!--<a href (click)="perform('EDIT_MATTER_TABLE_TYPES')" [innerHtml]="'Matter.Details.ContextMenu.TableTypes' | translate"></a>-->
<!--</li>-->
<!--</ul>-->
<!--</li>-->
<!--</ul>-->
<!--</div>-->
<!--</div>-->
