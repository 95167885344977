import { Action } from '@ngrx/store';
import {
  ENotificationEntityType,
  INotification,
  INotificationEvent,
  INotificationMetaInfo,
  INotificationResponse,
} from '@app/features/+notification/models/notification.model';

export enum NotificationApiActionTypes {
  GET_NOTIFICATION_BATCH = '[Notification API] get a batch of notifications',
  GET_EVENT_BATCH = '[Notification API] get a batch of events',
  LIST_NOTIFICATIONS_SUCCESS = '[Notification List from api] success',
  LIST_NOTIFICATIONS_FAILURE = '[Notification List from api] failure',
  STORE_META_INFORM = '[Notification] store meta inform',
  ACKNOWLEDGE_STICKY_NOTIFICATION = '[Notification API] acknowledge a sticky notification',
  ACKNOWLEDGE_TRANSIENT_NOTIFICATIONS = '[Notification API] acknowledge Transient notifications',
  ACKNOWLEDGE_CURRENT_MATTER_ENTITY_NOTIFICATIONS = '[Notification API] acknowledge all notifications of a entity in current matter',
  ACKNOWLEDGE_CURRENT_MATTER_TRANSIENT_NOTIFICATIONS = '[Notification API] acknowledge current matter Transient notifications',
  ACKNOWLEDGE_NOTIFICATION = '[Notification API] acknowledge a notification',
  ACKNOWLEDGE_NOTIFICATION_SUCCESS = '[Notification API] succeed to acknowledge a notification',
  ACKNOWLEDGE_NOTIFICATION_FAIL = '[Notification API] fail to acknowledge a notification',
  ACKNOWLEDGE_NOTIFICATION_BATCH = '[Notification API] acknowledge a batch of notifications',
  ACKNOWLEDGE_NOTIFICATION_BATCH_SUCCESS = '[Notification API] succeed to acknowledge a batch of notifications',
  ACKNOWLEDGE_NOTIFICATION_BATCH_FAIL = '[Notification API] fail to acknowledge a batch of notifications',
  CLEAR_ALL_NOTIFICATIONS = '[Notification API] clear all notifications',
  CLEAR_NOTIFICATION = '[Notification API] clear one notification',
  CHECK_NOTIFICATION = '[Notification API] check notification',
  SET_SELECTED_NOTIFICATION_ID = '[Notification API] set selectedNotificationId in ngrx store',
  CLEAR_SELECTED_NOTIFICATION_ID = '[Notification API] clear selectedNotificationId in ngrx store',

  OPEN_ENTITY_FAIL = '[Notification API] unable to open notification entity',

  DELETE_NOTIFICATIONS = '[Notifications] delete notifications from ngrx store',

  CHANGE_NOTIFICATION_SCOPE = '[Notifications] change notification scope',

  CLEAR_NOTIFICATION_STATE = '[Notifications] clear notification state',

  PUBNUB_NOTIFICATIONS_UPDATE = '[Notifications] notifications pubnub update',
  PUBNUB_EVENTS_UPDATE = '[Notifications] events pubnub update',

  POLLING_NOTIFICATIONS = '[Notifications] Polling',
}

export class GetNotificationBatch implements Action {
  readonly type = NotificationApiActionTypes.GET_NOTIFICATION_BATCH;

  // getEvents: whether we trigger GetEventBatch after GetNotificationBatch
  constructor(public payload: { continuationToken?: string; lastBatch: INotification[]; getEvents?: boolean }) {}
}

export class ListNotificationsSuccess implements Action {
  readonly type = NotificationApiActionTypes.LIST_NOTIFICATIONS_SUCCESS;

  constructor(public payload: { data: INotificationResponse }) {}
}

export class ListNotificationsFailure implements Action {
  readonly type = NotificationApiActionTypes.LIST_NOTIFICATIONS_FAILURE;

  constructor(public payload: any) {}
}

export class GetEventBatch implements Action {
  readonly type = NotificationApiActionTypes.GET_EVENT_BATCH;

  constructor(
    public payload: { lastEvaluatedKey?: string; lastBatch: INotificationEvent[]; notifications?: INotification[] }
  ) {}
}

export class StoreMetaInform implements Action {
  readonly type = NotificationApiActionTypes.STORE_META_INFORM;

  constructor(public payload: Partial<INotificationMetaInfo>) {}
}

export class AcknowledgeNotificationBatch implements Action {
  readonly type = NotificationApiActionTypes.ACKNOWLEDGE_NOTIFICATION_BATCH;

  constructor(public payload: { ids: number[] }) {}
}

export class AcknowledgeNotificationBatchSuccess implements Action {
  readonly type = NotificationApiActionTypes.ACKNOWLEDGE_NOTIFICATION_BATCH_SUCCESS;

  constructor(public payload: { ids: number[] }) {}
}

export class AcknowledgeNotificationBatchFail implements Action {
  readonly type = NotificationApiActionTypes.ACKNOWLEDGE_NOTIFICATION_BATCH_FAIL;

  constructor(public payload: any) {}
}

export class AcknowledgeNotification implements Action {
  readonly type = NotificationApiActionTypes.ACKNOWLEDGE_NOTIFICATION;

  constructor(public payload: { notification: INotification }) {}
}

export class AcknowledgeNotificationSuccess implements Action {
  readonly type = NotificationApiActionTypes.ACKNOWLEDGE_NOTIFICATION_SUCCESS;

  constructor(public payload: { notification: INotification }) {}
}

export class AcknowledgeNotificationFail implements Action {
  readonly type = NotificationApiActionTypes.ACKNOWLEDGE_NOTIFICATION_FAIL;

  constructor(public payload: any) {}
}

export class CheckNotification implements Action {
  readonly type = NotificationApiActionTypes.CHECK_NOTIFICATION;

  constructor(public payload: { notification: INotification; relatedEntityRouteId?: string }) {}
}

export class AcknowledgeStickyNotification implements Action {
  readonly type = NotificationApiActionTypes.ACKNOWLEDGE_STICKY_NOTIFICATION;

  constructor(public payload: any) {}
}

export class AcknowledgeTransientNotifications implements Action {
  readonly type = NotificationApiActionTypes.ACKNOWLEDGE_TRANSIENT_NOTIFICATIONS;

  constructor(public payload: { entityType: ENotificationEntityType }) {}
}

export class AcknowledgeCurrentMatterEntityNotifications implements Action {
  readonly type = NotificationApiActionTypes.ACKNOWLEDGE_CURRENT_MATTER_ENTITY_NOTIFICATIONS;

  constructor(public payload: { entityId: string | number }) {}
}

export class AcknowledgeCurrentMatterTransientNotifications implements Action {
  readonly type = NotificationApiActionTypes.ACKNOWLEDGE_CURRENT_MATTER_TRANSIENT_NOTIFICATIONS;

  constructor(public payload: { entityType: ENotificationEntityType }) {}
}

export class SetSelectedNotificationId implements Action {
  readonly type = NotificationApiActionTypes.SET_SELECTED_NOTIFICATION_ID;

  constructor(public payload: { id: number }) {}
}

export class ClearSelectedNotificationId implements Action {
  readonly type = NotificationApiActionTypes.CLEAR_SELECTED_NOTIFICATION_ID;

  constructor(public payload: any) {}
}

export class PollingNotifications implements Action {
  readonly type = NotificationApiActionTypes.POLLING_NOTIFICATIONS;

  constructor(public payload: any) {}
}

export class ClearAllNotifications implements Action {
  readonly type = NotificationApiActionTypes.CLEAR_ALL_NOTIFICATIONS;

  constructor(public payload: any) {}
}

export class ClearNotification implements Action {
  readonly type = NotificationApiActionTypes.CLEAR_NOTIFICATION;

  constructor(public payload: { notification: INotification }) {}
}

export class DeleteNotifications implements Action {
  readonly type = NotificationApiActionTypes.DELETE_NOTIFICATIONS;

  constructor(public payload: { ids: number[] }) {}
}

export class PubnubNotificationsUpdate implements Action {
  readonly type = NotificationApiActionTypes.PUBNUB_NOTIFICATIONS_UPDATE;

  constructor(public payload: { firmId: string; staffIds: string[] }) {}
}

export class PubnubEventsUpdate implements Action {
  readonly type = NotificationApiActionTypes.PUBNUB_EVENTS_UPDATE;

  constructor(public payload: { firmId: string; staffId: string }) {}
}

export class OpenEntityFail implements Action {
  readonly type = NotificationApiActionTypes.OPEN_ENTITY_FAIL;

  constructor(public payload: { entityType: ENotificationEntityType }) {}
}

export class ChangeNotificationScope implements Action {
  readonly type = NotificationApiActionTypes.CHANGE_NOTIFICATION_SCOPE;

  constructor(public payload: { showAll: boolean }) {}
}

export class ClearNotificationState implements Action {
  readonly type = NotificationApiActionTypes.CLEAR_NOTIFICATION_STATE;

  constructor(public payload: any) {}
}

export type NotificationApiActions =
  | GetNotificationBatch
  | GetEventBatch
  | ListNotificationsSuccess
  | ListNotificationsFailure
  | StoreMetaInform
  | AcknowledgeNotification
  | AcknowledgeNotificationSuccess
  | AcknowledgeNotificationFail
  | AcknowledgeNotificationBatch
  | AcknowledgeNotificationBatchSuccess
  | AcknowledgeNotificationBatchFail
  | ClearAllNotifications
  | ClearNotification
  | CheckNotification
  | AcknowledgeCurrentMatterEntityNotifications
  | AcknowledgeStickyNotification
  | AcknowledgeTransientNotifications
  | AcknowledgeCurrentMatterTransientNotifications
  | SetSelectedNotificationId
  | ClearSelectedNotificationId
  | PollingNotifications
  | PubnubNotificationsUpdate
  | PubnubEventsUpdate
  | OpenEntityFail
  | DeleteNotifications
  | ClearNotificationState
  | ChangeNotificationScope;
