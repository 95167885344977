import { Injectable } from '@angular/core';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import * as actions from '../actions/app.action';
import { LogService } from '@app/core/services';
import { StaffService } from '@app/core/services/staff/staff.service';
import { Staff } from '@app/shared/models';
import { AuthService } from '@app/core/services/auth/auth.service';
import { FirmDetailsFetchFields, FirmDetailsFetchMode } from '@app/core/constants';
import { selectCurrentStaff } from '../selectors';
import { Update } from '@ngrx/entity';
import { forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class StaffEffects {
  constructor(
    private actions$: Actions,
    private _staffSvc: StaffService,
    private _log: LogService,
    private _store: Store<any>,
    private _authSvc: AuthService,
    private _toastrSvc: ToastrService
  ) {
    this._log.init('staff-effects');
  }


  addStaffList$ = createEffect(() => this.actions$.pipe(
    ofType(actions.ADD_STAFF_LIST),
    exhaustMap(() => this._staffSvc.getStaffs()),
    exhaustMap((staffs: Staff[]) => {
      this._log.info('addStaffList$');
      const staffId = this._authSvc.staffId;
      this._log.info('current staff id: ', staffId);
      return [
        new actions.AddStaffListSuccess(staffs),
        new actions.SetCurrentStaffId({ staffId }),
        new actions.AddFirmDetails({ fetchMode: FirmDetailsFetchMode.Force, fields: FirmDetailsFetchFields }),
        new actions.LoadStaffSecuritySettingsStart({ staffId }),
      ];
    }),
    catchError((error) => [new actions.AddStaffListFailure(error)])
  ));

  //enrich current staff entity with security ids.

  currentStaffSecurity$ = createEffect(() => this.actions$.pipe(
    ofType<actions.LoadStaffSecuritySettingsStart>(actions.LOAD_CURRENT_STAFF_SECURITY_SETTINGS_START),
    withLatestFrom(this._store.select(selectCurrentStaff)),
    exhaustMap(([action, currentStaff]) => {
      const { payload } = action;
      return forkJoin([
        this._staffSvc.getStaffSecuritySettings(payload.staffId),
        this._staffSvc.getStaffPaymentLimit(payload.staffId),
      ]).pipe(
        map(([staffSecuritySettings, staffPaymentLimit]) => {
          const staffWithSecurityUpdate: Update<Staff> = {
            id: currentStaff.__id,
            changes: {
              ...currentStaff,
              ...staffSecuritySettings,
              officePaymentLimit: staffPaymentLimit?.OfficePaymentLimit,
              trustPaymentLimit: staffPaymentLimit?.TrustPaymentLimit,
            },
          };
          return new actions.LoadStaffSecuritySettingsSuccess(staffWithSecurityUpdate);
        }),
        catchError((error) => [new actions.LoadStaffSecuritySettingsFailure(error)])
      );
    })
  ));


  LoadStaffSecuritySettingsFailure$ = createEffect(() => this.actions$.pipe(
    ofType<actions.LoadStaffSecuritySettingsFailure>(actions.LOAD_CURRENT_STAFF_SECURITY_SETTINGS_FAILURE),
    exhaustMap(() => {
      this._toastrSvc.show('Fail to get user\'s setting and payment limit', 'Warning', {}, 'warning');
      return [];
    })
  ));
}
