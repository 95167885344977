import { createSelector } from '@ngrx/store';

import * as fromFolder from '../reducers/folder.reducer';
import { State, selectCorrespondenceState } from '../reducers';
import { stat } from 'fs';

export const selectFoldersState = createSelector(selectCorrespondenceState, (state: State) => state.folder);

export const selectFoldersLoading = createSelector(selectFoldersState, (state: fromFolder.State) =>
  fromFolder.selectLoading(state)
);

export const selectFoldersUpdatingFromApi = createSelector(selectFoldersState, (state: fromFolder.State) =>
  fromFolder.selectUpdatingFromApi(state)
);

export const selectFoldersErrorCounter = createSelector(selectFoldersState, (state: fromFolder.State) =>
  fromFolder.selectErrorCounter(state)
);

export const selectRoot = createSelector(selectFoldersState, (state: fromFolder.State) => fromFolder.selectRoot(state));

export const selectSelectedFolder = createSelector(selectFoldersState, (state: fromFolder.State) => {
  return fromFolder.selectSelectedFolder(state)
}
);

export const selectSelectedFolderId = createSelector(selectFoldersState, (state: fromFolder.State) =>
  fromFolder.selectSelectedFolderId(state)
);

export const selectSelectedCorrespondence = createSelector(selectFoldersState, (state: fromFolder.State) =>
  fromFolder.selectSelectedCorrespondence(state)
);

export const selectSelectedCorrespondenceId = createSelector(selectFoldersState, (state: fromFolder.State) =>
  fromFolder.selectSelectedCorrespondenceId(state)
);

export const selectFolderPath = createSelector(selectFoldersState, (state: fromFolder.State) =>
  fromFolder.selectFolderPath(state)
);

export const selectSearchText = createSelector(selectFoldersState, (state: fromFolder.State) =>
  fromFolder.selectSearchText(state)
);

export const selectSortInfo = createSelector(selectFoldersState, (state: fromFolder.State) =>
  fromFolder.selectSortInfo(state)
);

export const selectMatterId = createSelector(selectFoldersState, (state: fromFolder.State) =>
  fromFolder.selectMatterId(state)
);

export const selectUIInfo = createSelector(selectFoldersState, (state: fromFolder.State) =>
  fromFolder.selectUIInfo(state)
);

export const selectFolderActionRef = createSelector(selectFoldersState, (state: fromFolder.State) =>
  fromFolder.selectFolderActionRef(state)
);

export const selectFoldersMoving = createSelector(selectFoldersState, (state: fromFolder.State) =>
  fromFolder.selectMoving(state)
);

export const selectFoldersMoveStatus = createSelector(selectFoldersState, (state: fromFolder.State) =>
  fromFolder.selectMoveStatus(state)
);

export const selectAppliedFilters = createSelector(selectFoldersState, (state: fromFolder.State) =>
  fromFolder.selectAppliedFilter(state)
);

export const selectAvailableFilters = createSelector(selectFoldersState, (state: fromFolder.State) =>
  fromFolder.selectAvailableFilter(state)
);
