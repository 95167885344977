import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';

import { isEmptyObj } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-file-upload',
  templateUrl: './file-upload.component.html',
})
export class FileUploadComponent implements OnInit {
  @Input() showDropzone: boolean;
  @Input() dragNdrop: boolean;
  @Input()
  set autoTag(value: string) {
    this._tag = value;

    if (!this.dragNdrop && this._tag !== 'default') {
      this.newUploads.nativeElement.click();
    }
  }

  @Output() files = new EventEmitter<FileItem[]>();
  @Output() showDropzoneChanged = new EventEmitter<boolean>();

  @ViewChild('newuploads', { read: ElementRef })
  private newUploads: ElementRef;

  title: string;
  uploader: FileUploader;

  hasBaseDropZoneOver: boolean;

  private _tag: string;
  private _queueLimit = 20;

  constructor(private _toastrSvc: ToastrService) {}

  private getNewFileUploadInstance = () => new FileUploader({
    url: '',
    queueLimit: this._queueLimit, // this number can be update if get another business input.
    disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
    formatDataFunctionIsAsync: true,
    formatDataFunction: async (item) => new Promise((resolve) => {
      resolve({
        name: item._file.name,
        length: item._file.size,
        contentType: item._file.type,
        date: new Date(),
      });
    }),
  });

  ngOnInit() {
    this.uploader = this.getNewFileUploadInstance();
  }

  onFileSelected(e: any) {
    this.emitSelectedFiles(e);
  }

  onFileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  onFileDrop = (e: FileList) => {
    this.showDropzone = false;
    this.showDropzoneChanged.emit(false);
    this.emitSelectedFiles(e);
  };

  private emitSelectedFiles(e: any) {
    if (!isEmptyObj(e)) {
      const newPendings = [];
      [...Array(this.uploader.queue.length)].forEach((_) => {
        newPendings.push(this.uploader.queue.pop());
      });

      this.files.emit(newPendings);

      this.uploader = null;
      this.uploader = this.getNewFileUploadInstance();

      if (e.length > this._queueLimit) {
        this._toastrSvc.warning(`Only the first ${this._queueLimit} files will be uploaded`, 'Upload Warning', {
          closeButton: true,
          timeOut: 3000,
        });
      }
    }
  }
}
