import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { catchError, map, debounceTime, withLatestFrom, switchMap, filter, mergeMap } from 'rxjs/operators';

import * as actions from '../actions/fee-timer-activity-code.actions';
import { ActivityCodeService } from '@app/features/fee-timer/services/activity-code/activity-code.service';
import { FeeInitialDataModelHelper } from '@app/features/fee-timer/models/task-code.model';
import {
  selectFeeTimerActivityCodesState,
  selectFeeTimerActivityCodeQueryTextState,
} from '@app/features/fee-timer/store/selectors';
import { ActivityCode } from '@app/features/fee-timer/models/fee-timer.model';

@Injectable()
export class FeeTimerActivityCodeEffect {

  loadActivityCodeStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.FeeTimerActivityCodeLoad>(actions.FEE_TIMER_ACTIVITY_CODE_LOAD),
    switchMap((action) => this._activityCodeService.getInitialisationData(action.payload).pipe(
      map((d) => new FeeInitialDataModelHelper().toActivityCodes(d)),
      map((c) => new actions.FeeTimerActivityCodeLoadCompleted(c)),
      catchError((err) => observableOf({ type: actions.FEE_TIMER_ACTIVITY_CODE_LOAD_FAIL }))
    ))
  ));


  searchActivityCode$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.FeeTimerActivityCodeSearch>(actions.FEE_TIMER_ACTIVITY_CODE_SEARCH),
    debounceTime(300),
    withLatestFrom(
      this._store.pipe(select(selectFeeTimerActivityCodeQueryTextState)),
      (action, queryText) => queryText
    ),
    switchMap((queryText) => this._store.pipe(select(selectFeeTimerActivityCodesState)).pipe(
      map((ac) => {
        const query = queryText.toLocaleLowerCase();
        const result = new Array<ActivityCode>();
        if (ac && ac.length) {
          ac.forEach((ff) => {
            if (
              (ff.nameFileAs && ff.nameFileAs.toLocaleLowerCase().indexOf(query) > -1) ||
              (ff.billingDescription && ff.billingDescription.toLocaleLowerCase().indexOf(query) > -1)
            ) {
              result.push(ff);
            }
          });
        }
        return result;
      }),
      map((f) => new actions.FeeTimerActivityCodeSearchCompleted(f))
    ))
  ));

  constructor(
    private actions$: Actions,
    private _store: Store<any>,
    private _activityCodeService: ActivityCodeService
  ) {}
}
