import { SortModelItem } from '@ag-grid-community/core';
import { ViewLeapAppLocationId } from '../../matter-addin/models';

export interface IPersonListEntry {
  personId: string;
  groupName?: string;
  salutation?: string;
  forenames?: string;
  surname?: string;
  recVer?: number;
  deleteCode?: number;
}

export interface IPersonListRow {
  isGroup?: boolean;
  groupName?: string;
  data?: IPersonListEntry;
  children?: IPersonListRow[];
}

export interface IPersonListResponseSchema {
  data: IPersonListEntry[];
  lastRowVer: number;
  total: number;
}

export interface IPersonListMetaInfo {
  id: string;
  lastRowVer?: number;
  personSearchText?: string;
  groupInfo?: EPersonListGroupInfo;
  selectedPersonId?: string;
}

export enum EPersonListGroupInfo {
  UNGROUP = 1,
  FORENNAMES = 2,
  SURNAME = 3,
}


export enum EPersonDetailsTabType {
  Basic,
  Other,
  AdditionalFields,
  Cards,
  Matters,
}

export interface IPersonGroupListQuery {
  showDeleted: boolean;
  startRow: number;
  endRow: number;
  sortModel: SortModelItem[];
  searchText: string;
  rowGroupCols: string[];
  groupKeys: string[];
}

export const PersonErrors = {
  firstNames: {
    invalidTab: EPersonDetailsTabType.Basic,
    message: 'This Person cannot be saved. Please enter person\'s name.',
  },
  lastName: {
    firstNames: {
      invalidTab: EPersonDetailsTabType.Basic,
      message: 'This Person cannot be saved. Please enter person\'s name.',
    },
  },
  emailList: {
    inValidTab: EPersonDetailsTabType.Basic,
    message: 'This Person cannot be saved. Please enter a valid email address.',
  },
  default: {
    inValidTab: null,
    message: 'This Card cannot be saved.',
  },
};

export const PersonDetailsTabViewLeapAppLocationId = {
  [EPersonDetailsTabType.Basic]: ViewLeapAppLocationId.PeopleBasic,
  [EPersonDetailsTabType.Other]: ViewLeapAppLocationId.PeopleOther,
  [EPersonDetailsTabType.Cards]: ViewLeapAppLocationId.PeopleCardList,
  [EPersonDetailsTabType.Matters]: ViewLeapAppLocationId.PeopleMatterList,
};
