<sc-time-activity-header
  [selectedActivityCode]="selectedActivityCode$ | async"
  [recordingState]="recordingState$ | async"
  [details]="activityDetails$ | async"
  [feeGUID]="selectedTimeEntryFeeGUID$ | async"
  [runningTimerFeeGUID]="runningTimerFeeGUID$ | async"
  [isNewEntry]="isNewEntry$ | async"
  scPreventDefault
  (saveCalled)="saveCalled()"
>
</sc-time-activity-header>
<sc-time-activity-search
  [showSearchBar]="showActivityList"
  [query]="activityCodesQueryText$ | async"
  [searching]="loading$ | async"
  (activityBtnClicked)="showActivityList = !showActivityList"
  (search)="onSearch($event)"
>
</sc-time-activity-search>
<sc-time-activity-list
  *ngIf="showActivityList"
  (collapseBtnClicked)="showActivityList = !showActivityList"
  (selected)="onSelected($event)"
  [source]="activityCodes$ | async"
  [filteredSource]="activityCodesFiltered$ | async"
  [queryText]="activityCodesQueryText$ | async"
>
</sc-time-activity-list>
