import { Component, OnInit } from '@angular/core';
import { BaseDetailEntryAbstract } from '../detail-entry-item/base-detail-entry.abstract';

@Component({
  selector: 'sc-detail-reconciliation',
  templateUrl: './detail-reconciliation.component.html',
})
export class DetailReconciliationComponent extends BaseDetailEntryAbstract implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}
