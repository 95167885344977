import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/services/base/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ITrustLedgerBalance } from '@app/features/+trust-journal/models/trust-journal.model';

@Injectable()
export class TrustMatterBalanceService extends BaseService {
  url: string;

  constructor(private _http: HttpClient) {
    super();
    this.url = `${this.accountingPath}/api/cloud/matter/trustbalance`;
  }

  get(matterId: string, bankAccountId: string): Observable<ITrustLedgerBalance> {
    const url = this.urlJoin(this.url, `/${matterId}?bankAccountId=${bankAccountId}`);
    return this._http.get<ITrustLedgerBalance>(url);
  }
}
