<div
  class="x-nav-item-dropdown"
  dropdown
  container="body"
  placement="{{ dropFromRight ? 'bottom right' : 'bottom left' }}"
  (mouseenter)="hoverOnLabel = true"
  (mouseleave)="dismissDropdownMenu()"
  (clickOutside)="dismissDropdownMenu()"
  [isOpen]="showDropdownMenu()"
>
  <a class="nav-link" href scPreventDefault [class.active]="showDropdownMenu()">
    {{ label }}
    <sc-icon iconName="triangle-down"></sc-icon>
  </a>
  <ul
    class="dropdown-menu"
    *dropdownMenu
    role="menu"
    (mouseenter)="hoverOnMenu = true"
    (mouseleave)="dismissDropdownMenu()"
    [@xAnimationScale]="showDropdownMenu()"
    [ngClass]="{ 'dropdown-menu-right': dropFromRight }"
  >
    <ng-container *ngFor="let item of dropdownList">
      <ng-container *ngIf="!item.hide">
        <li class="dropdown-divider" *ngIf="item.isDivider"></li>
        <h6 class="dropdown-header" *ngIf="item.isHeader">{{ item.label | translate }}</h6>
        <a
          class="dropdown-item"
          (click)="resetDropdownMenu()"
          *ngIf="!item.isHeader && !item.isDivider && !item.isNonRouteTab"
          [routerLink]="item.link"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ item.label | translate }}
        </a>

        <a
          class="dropdown-item"
          scPreventDefault
          href
          (click)="tabClicked(item)"
          *ngIf="!item.isHeader && !item.isDivider && item.isNonRouteTab"
        >
          {{ item.label | translate }}
        </a>
      </ng-container>
    </ng-container>
  </ul>
</div>
