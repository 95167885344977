import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as feeTimerRecord from './fee-timer-record.reducer';
import * as feeTimerActivityCode from './fee-timer-activity-code.reducer';

import { ActivityCodesState, FeeTimerRecordingState } from '@app/features/fee-timer/models/fee-timer.model';

export interface State {
  activityCode: ActivityCodesState;
  recording: FeeTimerRecordingState;
}

export const INITIAL_STATE: State = {
  activityCode: feeTimerActivityCode.INITIAL_STATE,
  recording: feeTimerRecord.INITIAL_STATE,
};

export const reducers: ActionReducerMap<State> = {
  recording: feeTimerRecord.reducer,
  activityCode: feeTimerActivityCode.reducer,
};

export const selectFeeTimer = createFeatureSelector<State>('feeTimer');
