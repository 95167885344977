import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppApiService } from '@app/core/api';

@Component({
  selector: 'sc-aside-heading-slim',
  templateUrl: './aside-heading-slim.component.html',
})
export class AsideHeadingSlimComponent implements OnInit, OnDestroy {
  constructor(private _appApiSvc: AppApiService) {}

  ngOnInit() {}
  ngOnDestroy(): void {}

  toggleAside(): void {
    this._appApiSvc.toggleAside(true);
  }
}
