<div
  [ngClass]="wrapperClass"
  dropdown
  container="body"
  placement="bottom right"
  (isOpenChange)="showDropdownMenu = !showDropdownMenu"
>
  <button
    *ngIf="!iconButton"
    [ngClass]="buttonClass"
    class="btn x-btn-icon dropdown-toggle x-max-width-240"
    type="button"
    dropdownToggle
    aria-haspopup="true"
    aria-expanded="true"
  >
    <sc-icon *ngIf="!!iconClass" [ngClass]="iconClass" iconName="{{ iconClass }}"></sc-icon>
    <span class="d-none d-xl-block text-truncate" [innerHtml]="selectedText"></span>
    <span class="d-xl-none text-truncate" [innerHtml]="shortSelectedText"></span>
  </button>
  <sc-icon *ngIf="iconButton" [iconName]="iconClass" dropdownToggle aria-haspopup="true" aria-expanded="true">
  </sc-icon>
  <ul
    class="x-dropdown-filter dropdown-menu dropdown-menu-right"
    aria-labelledby="filter-dropdown"
    *dropdownMenu
    role="menu"
    [ngStyle]="dropdownMenuStyle"
    [@xAnimationScale]="showDropdownMenu"
  >
    <ng-container *ngFor="let item of options.items">
      <h6
        class="dropdown-header"
        *ngIf="itemLabelVisible(item) && !itemSelectable(item)"
        [innerHtml]="options.itemLabel(item)"
      ></h6>
      <a
        *ngIf="itemLabelVisible(item) && itemSelectable(item)"
        href
        scPreventDefault
        [ngClass]="itemClassRule(item)"
        [ngStyle]="itemStyleRule(item)"
        class="{{ 'dropdown-item ' + itemClass(item) }}"
        role="menuitem"
        (click)="itemClicked(item)"
      >
        <sc-icon iconName="tick"></sc-icon>
        <span [innerHtml]="options.itemLabel(item)"></span>
      </a>
      <div *ngIf="itemClass(item) === 'divider'" class="dropdown-divider"></div>
    </ng-container>
    <li *ngIf="!!moreAction" class="dropdown-divider"></li>

    <a *ngIf="!!moreAction" class="dropdown-item" href (click)="moreAction.callback()" scPreventDefault>
      <strong [innerHtml]="moreAction.text"></strong>
    </a>
  </ul>
</div>
