import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

export interface IPlatformService {
  isBrowser: boolean;
  isServer: boolean;
  isMacLikeOS: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PlatformService implements IPlatformService {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  public get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  public get isServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  public get isMacLikeOS(): boolean {
    const isMacLike = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;
    return this.isBrowser && isMacLike;
  }

  public get window(): Window | unknown {
    return this.isBrowser ? window : {};
  }

  public get isMobileBrowser(): boolean {
    if (!this.isBrowser) {
      return false;
    }

    // https://stackoverflow.com/questions/25393865/how-do-you-detect-between-a-desktop-and-mobile-chrome-user-agent/25394023#25394023
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
      navigator.userAgent
    );
    return isMobile;
  }
}
