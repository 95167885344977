import { Action } from '@ngrx/store';

export const SIDEBAR_NEW_FOLDER = '[Sidebar] new folder';
export const SIDEBAR_NEW_LETTER = '[Sidebar] new letter';
export const SIDEBAR_NEW_UPLOAD = '[Sidebar] new upload';
export const SIDEBAR_NEW_DOCUMENT = '[Sidebar] new document';

export class SidebarNewFolder implements Action {
  readonly type = SIDEBAR_NEW_FOLDER;
  constructor(public payload: any) {}
}

export class SidebarNewLetter implements Action {
  readonly type = SIDEBAR_NEW_LETTER;
  constructor(public payload: any) {}
}

export class SidebarNewUpload implements Action {
  readonly type = SIDEBAR_NEW_UPLOAD;
  constructor(public payload: any) {}
}

export class SidebarNewDocument implements Action {
  readonly type = SIDEBAR_NEW_DOCUMENT;
  constructor(public payload: any) {}
}

export type SidebarActions = SidebarNewFolder | SidebarNewLetter | SidebarNewUpload | SidebarNewDocument;
