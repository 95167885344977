import { createSelector } from '@ngrx/store';

import * as fromDocument from '../reducers/document.reducer';

import { selectDocumentState } from '../reducers';

export const selectDocumentLaddaLoading = createSelector(selectDocumentState, (state: fromDocument.State) =>
  fromDocument.selectLaddaLoading(state)
);

export const selectDocumentError = createSelector(selectDocumentState, (state: fromDocument.State) =>
  fromDocument.selectError(state)
);

export const selectDocumentLoading = createSelector(selectDocumentState, (state: fromDocument.State) =>
  fromDocument.selectLoading(state)
);

export const selectDocumentRecipient = createSelector(selectDocumentState, (state: fromDocument.State) =>
  fromDocument.selectRecipient(state)
);

export const selectDocumentNewDocumentPayload = createSelector(selectDocumentState, (state: fromDocument.State) =>
  fromDocument.selectNewDocumentPayload(state)
);
