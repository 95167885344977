<div [ngClass]="titleClass" class="d-flex align-items-center">
  <h6 class="ag-content-name" [title]="title" *ngIf="!isTitleHidden">
    {{ title }}
  </h6>
  <div class="ag-content-name x-badge-cell-primary ml-2" *ngFor="let label of labels" title={{label}}>{{ label }}</div>
</div>

<div class="ag-content-description" [ngClass]="wrapperClass" *ngIf="description">
  <span [innerHtml]="description" [title]="description"></span>
</div>
