<div class="x-toolbar-info" style="cursor: pointer;" scPreventDefault (click)="openMatterOptions()">
  <div class="media-body">
    <h6>
      <strong>{{ title || '&nbsp;' }}</strong>
      <br />
      {{ description || '&nbsp;' }}
    </h6>
  </div>
  <sc-matter-status *ngIf="!hideStatus" [msStatus]="status" [msStatusStrip]="status"></sc-matter-status>
</div>
