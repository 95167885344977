import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { isEmptyArray } from '../../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-tab-item',
  templateUrl: './tab-item.component.html',
})
export class TabItemComponent implements OnInit {
  @Input()
  set link(value: any[]) {
    if (!isEmptyArray(value)) {
      if (value.length > 1 && value[0].includes('/matters')) {
        this._link = [...value].map((p, idx) => (idx === 1 ? encodeURIComponent(p) : p));
      } else {
        this._link = [...value];
      }
    }
  }
  get link(): any[] {
    return this.hasLink ? this._link : [];
  }
  @Input() heading: string;
  @Input() subHeading: string;
  @Input() icon: string;
  @Input() isActive: boolean;
  @Input() isDisabled: boolean;
  @Input() class: string;
  @Input() tabindex = -1;

  @HostBinding('class')
  get hostClasses(): string {
    return !!this.class ? 'nav-item ' + this.class : 'nav-item';
  }

  private _link: any[];

  constructor() {}
  ngOnInit() {}

  get hasLink() {
    return !isEmptyArray(this._link);
  }
}
