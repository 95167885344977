import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'sc-alert-message',
  templateUrl: './alert-message.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AlertMessageComponent), multi: true }],
})
export class AlertMessageComponent implements ControlValueAccessor, OnInit {
  @Input()
  message: string;
  @Input()
  iconName: string;
  @Input()
  badgeClass = 'badge-secondary-darker';

  constructor() {
    this.message = '';
    this.iconName = 'info-circle-outline-grid-20';
  }

  ngOnInit() {}

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {}

  writeValue(obj: any): void {
    this.message = obj;
  }
}
