import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ViewLeapApp } from '@app/features/matter-addin/models';
import * as leapAppActions from '@app/features/matter-addin/store/action/leap-app.action';

export interface State extends EntityState<ViewLeapApp> {
  loading: boolean;
}

export const selectId = (item: ViewLeapApp): string => item.id;

export const adapter: EntityAdapter<ViewLeapApp> = createEntityAdapter<ViewLeapApp>({
  selectId,
  sortComparer: false,
});

export const INITIAL_STATE: State = adapter.getInitialState({
  loading: false,
});

export const reducer = (state: State = INITIAL_STATE, action: leapAppActions.LeapAppActions) => {
  switch (action.type) {
    case leapAppActions.GET_CARD_MODAL_PAGE_LEAP_APPS:
      return {
        ...state,
        loading: true,
      };

    case leapAppActions.GET_CARD_MODAL_PAGE_LEAP_APPS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case leapAppActions.GET_CARD_MODAL_PAGE_LEAP_APPS_SUCCESS:
      return state.loading
        ? {
            ...adapter.upsertMany(action.payload.apps, state),
            loading: false,
          }
        : state;

    case leapAppActions.CLEAR_CARD_MODAL_PAGE_LEAP_APPS:
      return {
        ...adapter.removeAll(state),
        loading: false,
      };

    default:
      return state;
  }
};

export const loading = (state: State) => state.loading;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of cardModalPageLeapApp ids
export const selectCardModalPageLeapAppIds = selectIds;

// select the dictionary of cardModalPageLeapApp entities
export const selectCardModalPageLeapAppEntities = selectEntities;

// select the array of cardModalPageLeapApp
export const selectAllCardModalPageLeapApps = selectAll;

// select the total cardModalPageLeapApp count
export const selectCardModalPageLeapAppTotal = selectTotal;
