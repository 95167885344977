import { IAutoTimeExternalJSON } from '../../models/autotime.model';
import { AutoTimeActions, AutoTimeActionTypes } from '../actions/autotime.action';

export interface State {
  matterId: string;
  task: IAutoTimeExternalJSON;
  start: Date;
  billable: boolean;
}

export const INITIAL_STATE = {
  matterId: undefined,
  task: undefined,
  start: undefined,
  billable: false,
};

export const reducers = (state: State = INITIAL_STATE, action: AutoTimeActions) => {
  switch (action.type) {
    case AutoTimeActionTypes.AUTOTIME_SET_BILLABLE:
      return {
        ...state,
        billable: action.payload
      };
    case AutoTimeActionTypes.AUTOTIME_START_TASK_SUCCESS:
      return {
        ...state,
        matterId: action.payload.matterId ?? state.matterId,
        task: action.payload.task,
        start: new Date()
      };
    case AutoTimeActionTypes.AUTOTIME_CLEAR_TASK:
      return {
        ...state,
        start: undefined,
        task: undefined,
        matterId: undefined,
      };
    default:
      return {
        ...state
      };
  }
};
