import { Injectable } from '@angular/core';
import { ICardListEntry, ICardRoles } from '@app/features/+card/models';
import { CardListService } from '@app/features/+card/services/card-list.service';
import { Staff, IMatterCard } from '@app/shared/models';
import { v4 as uuidv4 } from 'uuid';
import { CardFiltersService } from '@app/features/+card/services/card-filters.service';
import { combineLatest, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectAllContacts, selectCurrentMatterCardEntries } from '@app/features/+matter-details/store';
import { selectStaffList } from '@app/core/store';
import { map } from 'rxjs/operators';
import { IWebAddress } from '@app/core/models';
import { CardHelper } from '@app/shared/utils/card.helper';
import { OutlookContact } from '@app/features/+matter-details/models';
import { SuggestedStaff } from '@app/shared/models/staff';
import {
  getObjValue,
  isEmptyArray,
  joinArrayToString,
  uniqBy,
  uniqByDeepProp,
} from '@server/modules/shared/functions/common-util.functions';

@Injectable()
export class CardListModalService {
  constructor(
    private cardListSvc: CardListService,
    private cardFiltersSvc: CardFiltersService,
    private store: Store<any>
  ) {}

  public mapCardsWithAddress(cards: ICardListEntry[]): ICardListEntry[] {
    return cards.map((c) => CardHelper.processCard({ ...c }));
  }

  public getCardEntryFromSource(
    filterTypeInLowerCase: string,
    tableId: string,
    source: OutlookContact[] | Staff[] | ICardListEntry[] | IMatterCard[] | SuggestedStaff[]
  ): ICardListEntry[] {
    const result: ICardListEntry[] = [];

    // Return matter cards
    if (filterTypeInLowerCase === 'matter') {
      source.forEach((card) => {
        result.push(card as ICardListEntry);
      });
      return this.mapCardsWithAddress(
        this.cardFiltersSvc.applyFilterConditions(result, tableId, true, true, filterTypeInLowerCase, true)
      );
    }

    // Return matter contacts (cards, persons)
    if (filterTypeInLowerCase === 'matter-email') {
      const matterEmails2D = uniqBy(source, 'cardId').map((mc) => this.getContactsFromMatterCard(mc as IMatterCard));
      const matterEmails = matterEmails2D.flatMap((x) => x) as ICardListEntry[];

      return matterEmails;
    }

    if (filterTypeInLowerCase === 'staff') {
      // Return staff contacts
      source.forEach((staff) => {
        result.push(
          this.mapCardFromEmailSource(
            staff.fullName,
            staff.lastName,
            staff.email,
            'People',
            [staff.__id],
            staff.deleteCode,
            staff.__tableId
          )
        );
      });
    } else if (filterTypeInLowerCase === 'suggestedstaff') {
      // Return staff contacts
      source.forEach((staff) => {
        result.push(
          this.mapCardFromEmailSource(
            staff.fullName,
            staff.lastName,
            staff.email,
            'People',
            [staff.__id],
            staff.deleteCode,
            staff.__tableId
          )
        );
      });
    } else if (filterTypeInLowerCase === 'outlook') {
      // Return outlook contacts
      source.forEach((contact) => {
        if (contact.emailAddresses && contact.emailAddresses.length) {
          const email = contact.emailAddresses[0];
          result.push(this.mapCardFromEmailSource(email.name, contact.lastName, email.address, 'People', [uuidv4()]));
        }
      });
    }

    if (!result || !result.length) {
      return result;
    }

    return this.cardFiltersSvc.applyFilterConditions(result, tableId, true, true, filterTypeInLowerCase);
  }

  mapCardFromEmailSource(
    fullName: string,
    lastName: string,
    email: string,
    category: string = 'People',
    persons: string[] = [],
    deleteCode = 0,
    tableId = ''
  ): ICardListEntry {
    return Object.assign({}, {
      cardId: !!persons && persons.length > 0 ? persons[0] : '',
      fullName,
      shortName: lastName,
      type: category,
      isSupplier: false,
      persons,
      webAddressList: [
        {
          type: 'Email',
          address: email,
        } as IWebAddress,
      ],
      phoneNumberList: [],
      addressList: [],
      phone: undefined,
      address: undefined,
      suburb: undefined,
      email: {
        type: 'Email',
        address: email,
      } as IWebAddress,
      deleteCode,
      roles: {
        tables: [tableId],
      } as ICardRoles,
    } as ICardListEntry);
  }

  public getAllByEmailUseCase(includeEmptyEmail: boolean = false): Observable<ICardListEntry[]> {
    return combineLatest([
      this.store.pipe(select(selectCurrentMatterCardEntries)),
      this.store.pipe(select(selectStaffList)),
      this.store.pipe(select(selectAllContacts)),
    ]).pipe(
      map((data: [IMatterCard[], Staff[], OutlookContact[]]) => {
        const [matters, staffs, contacts] = data;
        // Prioritize matter-card contacts over staff and outlook
        const c1 = this.getCardEntryFromSource('matter-email', '1', matters);
        const c2 = this.getCardEntryFromSource('staff', undefined, staffs);
        const c3 = this.getCardEntryFromSource('outlook', undefined, contacts);

        const preUniqResult = includeEmptyEmail
          ? [...c1, ...c2, ...c3]
          : [...c1, ...c2, ...c3].filter((c) => !!getObjValue(c, 'email.address'));
        return uniqByDeepProp(preUniqResult, 'email.address');
      })
    );
  }

  /**
   * Get a flat list of contacts (in format of ICardListEntry) in a matter-card, including:
   *  - Main card contact (if organisation card and has direct email address)
   *  - Persons within the card
   *
   * @param matterCard
   */
  private getContactsFromMatterCard(matterCard: IMatterCard): ICardListEntry[] {
    const cardContact: ICardListEntry = matterCard.isPersonCard
      ? null
      : this.mapCardFromEmailSource(
          matterCard.__description,
          matterCard.cardShortName,
          getObjValue(matterCard, 'cardInfo.email', null),
          getObjValue(matterCard, 'cardInfo.type', 'Organisation'),
          matterCard.personSchemas?.map((x) => x.id) || [],
          0,
          matterCard.tableId
        );
    const personContacts: ICardListEntry[] = !!matterCard.personSchemas
      ? matterCard.personSchemas
          // check if the person is in the unusedPersons list
          // and we only use the used person -- LS-2736
          .filter(
            (ps) => !matterCard.unusedPersons || matterCard.unusedPersons.findIndex((up) => up.__id === ps.id) === -1
          )
          .map((ps) => {
            const fullName = joinArrayToString([ps.firstName, ps.lastName], ' ');
            return this.mapCardFromEmailSource(fullName, ps.lastName, ps.email, 'People', [ps.id]);
          })
      : [];
    return [...(!!cardContact ? [{ ...cardContact, cardId: matterCard.cardId }] : []), ...personContacts];
  }
}

const getFormattedStaffRoles = (roles: string[]): string => {
  const insertIndex = roles?.length - 1;
  const lastItem = roles?.length > 1 && roles[insertIndex];
  const formatRoles = roles.slice(0, insertIndex).join(', ').concat(' & ', lastItem);
  return insertIndex > 0 ? `[Person ${formatRoles}]` : `[Person ${roles}]`;
};
