import { CurrentFeeTimerMatterState, FeeTimerRecordingState } from '@app/features/fee-timer/models/fee-timer.model';
import * as fromFeeTimer from '../actions/';
import { Duration } from '../../models/fee-timer.model';
import { TimerRecordingAction } from '@app/features/+time-fee-ledger/models';
import { DurationUnit, getDuration } from '@app/shared/utils/date.helper';

const INITIAL_DURATION_STATE: Duration = {
  startTime: undefined,
  endTime: undefined,
};

export const INITIAL_STATE: FeeTimerRecordingState = {
  durationInSeconds: 0,
  duration: INITIAL_DURATION_STATE,
  state: 'discard',
  selectedActivityCode: '',
  matter: {} as CurrentFeeTimerMatterState,
  visible: false,
  runningTimerFeeGUID: '',
  pendingTimeEntry: undefined,
  isNewEntry: undefined,
};

export const reducer = (state = INITIAL_STATE, action: fromFeeTimer.FeeTimerRecordActions): FeeTimerRecordingState => {
  switch (action.type) {
    case fromFeeTimer.FEE_TIMER_TIME_ELAPSED_START: {
      return {
        ...state,
        state: TimerRecordingAction.Start,
        duration: { ...INITIAL_DURATION_STATE, startTime: new Date() },
      };
    }

    case fromFeeTimer.FEE_TIMER_TIME_ELAPSED_STOP: {
      const updatedDuration = updateDuration(state.duration);
      return {
        ...state,
        state: TimerRecordingAction.Stop,
        duration: updatedDuration,
        durationInSeconds: state.durationInSeconds + calculateDurationInSeconds(updatedDuration),
      };
    }

    case fromFeeTimer.FEE_TIMER_TIME_ELAPSED_UPDATE: {
      const updatedDuration = updateDuration(state.duration);
      return {
        ...state,
        duration: updatedDuration,
        durationInSeconds: state.durationInSeconds + calculateDurationInSeconds(updatedDuration),
      };
    }

    case fromFeeTimer.FEE_TIMER_TIME_ELAPSED_DISCARD: {
      return {
        ...state,
        duration: INITIAL_DURATION_STATE,
        durationInSeconds: 0,
        state: TimerRecordingAction.Discard,
      };
    }

    case fromFeeTimer.FEE_TIMER_TIME_ELAPSED_SET: {
      return {
        ...state,
        durationInSeconds: action.payload.durationInSeconds,
        duration: {
          startTime: action.payload.startTime,
          endTime: action.payload.endTime,
        },
      };
    }

    case fromFeeTimer.RESET_FEE_TIMER: {
      return {
        ...state,
        selectedActivityCode: '',
        durationInSeconds: 0,
        runningTimerFeeGUID: '',
        pendingTimeEntry: undefined,
        isNewEntry: undefined,
      };
    }

    case fromFeeTimer.SET_FEE_TIMER: {
      return {
        ...state,
        ...action.payload
      };
    }

    case fromFeeTimer.FEE_TIMER_SHOW: {
      return {
        ...state,
        visible: true,
        matter: action.payload,
      };
    }

    case fromFeeTimer.FEE_TIMER_HIDE: {
      return {
        ...state,
        visible: false,
      };
    }

    case fromFeeTimer.FEE_TIMER_ACTIVITY_CODE_UPDATE: {
      return {
        ...state,
        selectedActivityCode: action.payload,
      };
    }

    case fromFeeTimer.FEE_TIMER_PENDING_TIME_ENTRY_CLEAR: {
      return {
        ...state,
        pendingTimeEntry: INITIAL_STATE.pendingTimeEntry
      };
    }

    case fromFeeTimer.FEE_TIMER_PENDING_TIME_ENTRY_UPDATE: {
      return {
        ...state,
        pendingTimeEntry: {
          ...state.pendingTimeEntry,
          ...action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
};

const updateDuration = (duration: Duration) => ({
  ...duration,
  endTime: new Date(),
});

const calculateDurationInSeconds = (duration: Duration) => getDuration(duration.startTime, duration.endTime, DurationUnit.Seconds);

export const selectFeeTimerRecordingState = (state: FeeTimerRecordingState) => state;
export const selectDurationInSeconds = (state: FeeTimerRecordingState) => state.durationInSeconds;
export const selectCurrentState = (state: FeeTimerRecordingState) => state.state;
export const selectVisible = (state: FeeTimerRecordingState) => state.visible;
export const selectMatter = (state: FeeTimerRecordingState) => state.matter;
export const selectSelectedActivityCode = (state: FeeTimerRecordingState) => state.selectedActivityCode;
export const selectRunningTimerFeeGUID = (state: FeeTimerRecordingState) => state.runningTimerFeeGUID;
export const selectIsNewEntry = (state: FeeTimerRecordingState) => state.isNewEntry;
export const selectPendingTimeEntry = (state: FeeTimerRecordingState) => state.pendingTimeEntry;
