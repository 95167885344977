export enum CorrespondenceType {
  Document = 0,
  Comment = 1,
  VoiceMemo = 2,
}

export type TransferMode = 'Closed' | 'Received' | 'Sent';

export type Correspondence = IDoc | IDocsFolder;

export interface IDocsFolder {
  parent: IDocsFolder;
  id: string;
  name: string;
  documents: string[];
  fullDocuments: IDoc[];
  folders: IDocsFolder[];
  activeShared: boolean;
  hierarchy?: string[]; // use by ag-grid tree data
}

export interface IDoc {
  id: string;
  type: CorrespondenceType;
  toFrom: string;
  name: string;
  createDate: Date;
  lastModified: Date;
  userId: string;
  staffInitials: string;
  staffName: string;
  version: number;
  latestVersion: string;
  ext: string;
  deleted: boolean;
  attachments: IDocAttachment[];
  orderDate: Date;
  orderId: string;
  orderBy: string;
  availableOnline: boolean;
  url: string;
  status: string;
  dateCompleted: Date;
  transferMode: TransferMode;
  iconClass: string;
  iconTooltip: string;
  pin: string;
  desktopOnly: boolean;
  deleteCode?: number;
  previewUrl?: string; // docs-api pdf version url
  pendingPrecedent?: PendingPrecedent;
  matterDoxStatus: string;
  signatureStatus: number;
  hierarchy?: string[]; // use by ag-grid tree data
}

export interface IDocAttachment {
  id: string;
  name: string;
  ext: string;
  deleted: boolean;
  previewUrl?: string; // docs-api pdf version url
}

export interface PendingPrecedent {
  recordId: string;
  precedentId: string;
  precedentName: string;
  entity: string;
  tableId: string;
  tableOrder: number;
  instance: number;
  userId: string;
  created: string;
  deleteCode: number;
  precedentType: string;
}

export interface IComment extends IDoc {
  body: string;
  assignedToStaff?: string;
  precedentId?: string;
  customPrecedentId?: string;
  defaultTableId?: string;
  defaultTableOrder?: string;
}

export enum MatterDoxStatus {
  Shared = 1,
  Viewed = 2,
  Revoked = 3,
  Commented = 4,
}

export interface IDocumentIconInfo {
  iconClass: string;
  tooltip: string;
}

export interface IDocumentIconConfig extends IDocumentIconInfo {
  types: string[];
}

export interface IDocMetaInfo {
  firmId: string;
  matterId: string;
  userId: string;
  documentId: string;
  docName: string;
  fileType: string;
  staffInitials?: string;
  latestVersionId: string;
  precedentId?: string;
  customPrecedentId?: string;
  defaultTableId?: string;
  defaultTableOrder?: string;
  docTypeId: number;
  transferMode?: string;
  deleteCode?: number;
  attachments?: Array<{
    name: string;
    fileId: string;
    ext: string;
  }>;
  assignedToStaff?: string;
  created?: string; // used for toggle created date when uploading files
  folderId: string; // used for creating correspondence in a specific folder
}

export enum FolderValidation {
  NoError = 0,
  Error = 1,
  FolderBadInfo = 2,
  FolderDuplicated = 3,
}

export enum DocType {
  Undefined = 1, // eslint-disable-line
  Word,
  Excel,
  Email,
  DraftEmail,
  Image,
  PDF,
  Html,
  Comment,
  InfotrackReady,
  InfotrackOnline,
  Infotrack,
  TXT,
  Deleted,
}
