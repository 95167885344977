import { EAccountingTransaction } from '@app/features/accounting/models';
import { createSelector } from '@ngrx/store';
import { selectPreviewAccountingState } from '../reducers';
import * as fromPreviewAccounting from '../reducers/preview-accounting.reducer';

export const selectPreviewAccountingPreviewLoading = createSelector(
  selectPreviewAccountingState,
  fromPreviewAccounting.selectPreviewLoading
);

export const selectPreviewAccountingPreviewUrl = createSelector(
  selectPreviewAccountingState,
  fromPreviewAccounting.selectPreviewUrl
);

export const selectPreviewAccountingPreviewBase64String = createSelector(
  selectPreviewAccountingState,
  fromPreviewAccounting.selectPreviewBase64String
);

export const selectPreviewAccountingDocumentName = createSelector(
  selectPreviewAccountingState,
  fromPreviewAccounting.selectDocumentName
);

export const selectPreviewAccountingDocumentId = createSelector(
  selectPreviewAccountingState,
  fromPreviewAccounting.selectDocumentId
);

export const selectPreviewAccountingSuperDiaryDocumentId = createSelector(
  selectPreviewAccountingState,
  fromPreviewAccounting.selectSuperDiaryDocumentId
);

export const selectNextNavigationAccountingTransactionType = createSelector(
  selectPreviewAccountingState,
  fromPreviewAccounting.selectNextNavigationTransactionType
);

export const selectNextNavigationQuery = createSelector(
  selectPreviewAccountingState,
  fromPreviewAccounting.selectNextNavigationQuery
);

export const selectPreviewAccountingPrecedentId = createSelector(
  selectPreviewAccountingState,
  fromPreviewAccounting.selectPrecedentId
);

export const selectPreviewAccountingIsCustomPrecedent = createSelector(
  selectPreviewAccountingState,
  fromPreviewAccounting.selectIsCustomPrecedent
);

export const selectPreviewAccountingTransactionType = createSelector(
  selectPreviewAccountingState,
  fromPreviewAccounting.selectTransactionType
);

export const selectPreviewAccountingTransactionList = createSelector(
  selectPreviewAccountingState,
  fromPreviewAccounting.selectTransactionList
);

export const selectPreviewAccountingPreviewInform = createSelector(
  selectPreviewAccountingDocumentName,
  selectPreviewAccountingDocumentId,
  selectPreviewAccountingPreviewUrl,
  selectPreviewAccountingPreviewBase64String,
  selectPreviewAccountingTransactionType,
  selectPreviewAccountingPreviewLoading,
  (documentName, documentId, previewUrl, previewBase64String, transactionType, loading) => ({
    documentName,
    documentId,
    previewUrl,
    previewBase64String,
    transactionType,
    loading,
  })
);

export const selectPreviewReportingInform = createSelector(
  selectPreviewAccountingPreviewUrl,
  selectPreviewAccountingDocumentName,
  selectPreviewAccountingTransactionList,
  (previewUrl, documentName, transactionList) => ({
    previewUrl,
    documentName,
    transactionList,
  })
);

export const selectPreviewInvoiceDocBuilderInform = createSelector(
  selectPreviewAccountingDocumentName,
  selectPreviewAccountingPrecedentId,
  selectPreviewAccountingIsCustomPrecedent,
  selectPreviewAccountingTransactionList,
  (documentName, precedentId, isCustomPrecedent, transactionList) => ({
    documentName,
    precedentId,
    isCustomPrecedent,
    transactionList,
  })
);

export const selectPreviewAccountingNextTransactionNavigationInform = createSelector(
  selectPreviewAccountingTransactionList,
  selectPreviewAccountingTransactionType,
  selectNextNavigationAccountingTransactionType,
  selectNextNavigationQuery,
  (transactionList, transactionType, nextNavigationTransactionType, nextNavigationQuery) => {
    if (transactionType === EAccountingTransaction.OfficeBulkInvoice) {
      return {
        matterGuid: '',
        transactionGuid: '',
        nextNavigationTransactionType,
        nextNavigationQuery,
      };
    } else if (!!transactionList && !!transactionList[0]) {
      return {
        matterGuid: transactionList[0].matterGuid,
        transactionGuid: transactionList[0].transactionGuid,
        nextNavigationTransactionType,
        nextNavigationQuery,
      };
    } else {
      return null;
    }
  }
);

export const selectPreviewDocument = createSelector(
  selectPreviewAccountingState,
  fromPreviewAccounting.selectPreviewDocument
);
