<div class="x-form-wrapper x-accounting-form">
  <sc-row *ngIf="!!receiptForm" [formGroup]="receiptForm">
    <sc-col class="x-max-width-320">
      <sc-form-row>
        <sc-col class="form-group">
          <label>Bank Account</label> <input disabled class="form-control" [value]="bankAccountName" />
        </sc-col>
      </sc-form-row>

      <sc-form-row>
        <sc-col class="form-group">
          <label>Received From</label>
          <textarea disabled rows="5" class="form-control" [innerHTML]="receiptDetails.ReceivedFrom"></textarea>
        </sc-col>
      </sc-form-row>
    </sc-col>
    <sc-col class="col-3">
      <sc-form-row>
        <sc-col class="form-group">
          <sc-inflow-payment-type-details-readonly
            [paymentTypeId]="receiptDetails.PaymentTypeId"
            [paymentTypeName]="receiptDetails.PaymentTypeName"
            [paymentTypeDetails]="receiptDetails.PaymentTypeDetails"
          ></sc-inflow-payment-type-details-readonly>
        </sc-col>
      </sc-form-row>
    </sc-col>
    <sc-col class="col-auto">
      <sc-form-row>
        <sc-col class="form-group">
          <label for="transactionNumber">{{ 'Trust.Receipt.TransactionNumber' | translate }}</label>
          <input
            id="transactionNumber"
            disabled
            class="form-control x-max-width-180"
            [value]="receiptDetails.TransactionNumber"
          />
        </sc-col>
      </sc-form-row>
      <sc-form-row>
        <sc-col class="form-group">
          <label>Transaction Date</label>
          <input disabled class="form-control x-max-width-180" [value]="receiptDetails.TransactionDate | scAccDate" />
        </sc-col>
        <sc-col class="form-group">
          <label>Entry Date</label>
          <input disabled class="form-control x-max-width-180" [value]="receiptDetails.EntryDate | scAccDate" />
        </sc-col>
      </sc-form-row>
    </sc-col>
  </sc-row>
</div>
