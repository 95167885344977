import { Component, Input, OnInit } from '@angular/core';
import { ITrustReceipt } from '@app/features/+trust-receipt/models';
import { FormGroup } from '@angular/forms';
import { TrustReceiptService } from '@app/features/+trust-receipt/services/trust-receipt/trust-receipt.service';

@Component({
  selector: 'sc-trust-receipt-view',
  templateUrl: './trust-receipt-view.component.html',
})
export class TrustReceiptViewComponent implements OnInit {
  @Input() bankAccountName: string;

  @Input()
  set inputData(data: Partial<ITrustReceipt>) {
    if (!!data) {
      this.receiptDetails = data;
      if (!!this.receiptForm) {
        this.receiptForm.patchValue(data);
      } else {
        this.createForm(data);
      }
    }
  }

  public receiptForm: FormGroup;
  public receiptDetails: Partial<ITrustReceipt>;

  constructor(private _trustReceiptSvc: TrustReceiptService) {}

  ngOnInit() {}

  private createForm(options: Partial<ITrustReceipt>): void {
    this.receiptForm = this._trustReceiptSvc.createDetailsFormGroup(options);
  }
}
