import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { LayerOutletName } from '@app/core/models';
import { AppApiService } from '@app/core/api';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DialogService } from '@app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import * as personModalActions from '../actions/person-modal';
import * as personDetailsActions from '../actions/person-details';
import * as personSelectors from '../selectors';

@Injectable()
export class PersonModalEffects {

  openNewPersonModal$ = createEffect(() => this.actions$.pipe(
    ofType<personModalActions.OpenPersonModal>(personModalActions.PersonModalActionTypes.OPEN_PERSON_MODAL),
    tap((action) => {
      this.appApiSvc.navigate({
        path: [{ outlets: { [LayerOutletName.Global]: ['person'] } }],
        query: { personId: action.payload },
      });
    }),
  ), {dispatch: false});

  confirmDismissModal$ = createEffect(() => this.actions$.pipe(
    ofType(personModalActions.PersonModalActionTypes.CONFIRM_DISMISS_MODAL),
    withLatestFrom(
      this.store.pipe(select(personSelectors.selectIsPersonDetailsModified)),
      this.store.pipe(select(personSelectors.selectSelectedPersonId)),
      (action, modified, selectedPersonId) => ({modified, selectedPersonId})
    ),
    tap(({modified, selectedPersonId}) => {
      const dismissConfirmed = () => {
        this.store.dispatch(selectedPersonId
          ? new personDetailsActions.LoadExistingPerson({id: selectedPersonId })
          : new personDetailsActions.Clear()
        );
        this.store.dispatch(new personModalActions.DismissModal());
      };

      if (!modified) {
        dismissConfirmed();
        return;
      }

      this.dialogSvc.confirm({
        title: this.translateSvc.instant('Person.Details.Discard.Title'),
        message: this.translateSvc.instant('Person.Details.Discard.Message'),
        showCancel: true,
        actionText: this.translateSvc.instant('Person.Details.Discard.Action.Text'),
        closeText: this.translateSvc.instant('Person.Details.Discard.Close.Text'),
        onClose: (confirm) => {
          if (confirm) {
            dismissConfirmed();
          }
        }
      });
    })
  ), {dispatch: false});

  dismissModal$ = createEffect(() => this.actions$.pipe(
    ofType(personModalActions.PersonModalActionTypes.DISMISS_MODAL),
    tap(() => {
      this.appApiSvc.clearCurrentModal();
    })
  ), { dispatch: false});

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private appApiSvc: AppApiService,
    private dialogSvc: DialogService,
    private translateSvc: TranslateService,
  ) { }
}
