import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { IBankAccount, IPaymentTypes } from '@app/features/+payments-debtors/models/payment-debtors.models';
import { Store } from '@ngrx/store';
import { ITrustReceipt, ITrustReceiptFormData } from '@app/features/+trust-receipt/models';
import { distinctUntilChanged, filter as rxjsFilter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import * as cardListModalActions from '@app/features/+card/store/actions/card-list-modal';
import { AppApiService } from '@app/core/api';
import { InflowPaymentTypeParserService, PaymentAddressService } from '@app/features/accounting/services';
import { TrustReceiptService } from '@app/features/+trust-receipt/services/trust-receipt/trust-receipt.service';
import {
  ECardFilterType,
  ECardListModelEvent,
  ICardListEntry,
  ICardListModelSelection,
} from '@app/features/+card/models';
import { EventBusService } from '@app/core/services';
import { ESiriusEvents } from '@app/core/models';
import { IMatterCard } from '@app/shared/models';
import { isObjEqual } from '@server/modules/shared/functions/common-util.functions';
import { PaymentTypeId } from '@app/features/accounting/constants';

@Component({
  selector: 'sc-trust-receipt-details',
  templateUrl: './trust-receipt-details.component.html',
})
export class TrustReceiptDetailsComponent implements OnInit, OnDestroy {
  @Input() hasDataFromLeapApp = false;
  @Input() enforcePaymentTypeDetails: boolean = false;

  @Input()
  get matterId(): string {
    return this._matterId;
  }

  set matterId(value: string) {
    this._matterId = value;
  }

  @Input()
  get debtorCard(): IMatterCard {
    return this._debtorCard;
  }

  set debtorCard(value: IMatterCard) {
    if (value) {
      this._debtorCard = value;
      this._paymentAddressSvc
        .getpaymentAddress(value.cardId)
        .pipe(
          map((addressee) => {
            this.supplierGuid = value.cardId;
            this.receivedFrom = addressee;

            if (
              this.hasDataFromLeapApp &&
              !this.initDebtorCompleted &&
              this.receiptForm.controls['ReceivedFrom'].value
            ) {
              this.receivedFrom = this.receiptForm.controls['ReceivedFrom'].value;
              this.initDebtorCompleted = true;
            }

            if (this.receiptForm) {
              this.receiptForm.patchValue({ SupplierCardGuid: this.supplierGuid, ReceivedFrom: this.receivedFrom });
            }
          }),
          takeUntil(this.unsub) // important: this operator must be last
        )
        .subscribe();
    }
  }

  @Input()
  bankAccounts: IBankAccount[] = [];

  @Input()
  isGlobal: boolean; // whether the trust-receipt-page is open from trust-bank-register (global trust ledger)

  @Input()
  set inputData(data: ITrustReceiptFormData) {
    if (!!data) {
      this.bankAccount = data.receiptNumberInput.bankAccount;
      if (!!this.receiptForm) {
        if (!this._initDataCompleted) {
          this._initDataCompleted = true;

          const { PaymentTypeGUID, PaymentType, PaymentTypeDetails } = data.receiptDetails as any;
          if (PaymentTypeGUID && !PaymentType) {
            const paymentTypeDetails = this._paymentParserSvc.parse(
              PaymentTypeDetails as any,
              PaymentTypeDetails.PaymentTypeID
            );

            this.receiptForm.patchValue(
              {
                ...data.receiptDetails,
                PaymentType: {
                  paymentType: { ...PaymentTypeDetails },
                  paymentTypeDetails,
                },
              },
              { emitEvent: false }
            );

            return;
          }
        }

        this.receiptForm.patchValue(data.receiptDetails, { emitEvent: false });
      }

      this.updateFormUiStatus(data.receiptDetails);
    }
  }

  private _debtorCard: IMatterCard;
  @Input()
  paymentTypeList: IPaymentTypes[];
  private _matterId: string;

  @Input()
  validationErrors: any;

  @Output() onDetailsFormUpdated = new EventEmitter<any>();

  public bankAccount: IBankAccount;
  public receiptForm: FormGroup;
  public isPaymentSentVisible: boolean;
  public _formSub: Subscription;
  public _cardListModalSub: Subscription;
  public supplierGuid: string;
  public receivedFrom: string;

  private _initDataCompleted = false;
  private initDebtorCompleted = false;
  private unsub = new Subject<void>();

  constructor(
    private _store: Store<any>,
    private _appApiSvc: AppApiService,
    private _paymentAddressSvc: PaymentAddressService,
    private _eventBusSvc: EventBusService,
    private _trustReceiptSvc: TrustReceiptService,
    private _paymentParserSvc: InflowPaymentTypeParserService
  ) {
    this.isPaymentSentVisible = true;
    if (!this.receiptForm) {
      this.createForm({});
    }
  }

  ngOnInit() {
    this.subscribeToFormChanges();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  selectCard(): void {
    this._store.dispatch(new cardListModalActions.SetCardFilterType({ type: ECardFilterType.Standard }));
    const filterType = this.isGlobal ? 'Client' : 'Matter';
    this._store.dispatch(new cardListModalActions.SetSelectedCardFilterType({ type: filterType }));

    this._appApiSvc.navigate({
      path: [{ outlets: { popup: ['trust-receipt'], selector: ['card', 'card-list'] } }],
      extras: { skipLocationChange: true, queryParamsHandling: 'preserve' },
    });
    this.subscribeToOnClose();
  }

  private subscribeToOnClose() {
    if (this._cardListModalSub) {
      this._cardListModalSub.unsubscribe();
    }
    this._cardListModalSub = this._eventBusSvc
      .listen(ESiriusEvents.GetSelectedCardEntries)
      .pipe(
        take(1),
        rxjsFilter((data: ICardListModelSelection) => data.action === ECardListModelEvent.Confirm),
        map((data: ICardListModelSelection) => (!!data.cardEntries ? data.cardEntries[0] : null)),
        rxjsFilter((cardEntry) => !!cardEntry),
        switchMap((cardEntry: ICardListEntry) => this._paymentAddressSvc.getpaymentAddress(cardEntry.cardId).pipe(
          map((addressee) => ({
            cardId: cardEntry.cardId,
            addressee,
          }))
        )),
        takeUntil(this.unsub) // important: this operator must be last
      )
      .subscribe((data: { cardId: string; addressee: string }) => {
        this.receiptForm.patchValue({ SupplierCardGuid: data.cardId });
        this.receiptForm.patchValue({ ReceivedFrom: data.addressee });
      });
  }

  private createForm(options): void {
    this.receiptForm = this._trustReceiptSvc.createDetailsFormGroup(options);
  }

  private subscribeToFormChanges(): void {
    this._formSub = this.receiptForm.valueChanges
      .pipe(
        distinctUntilChanged(isObjEqual),
        takeUntil(this.unsub) // important: this operator must be last
      )
      .subscribe((formData) => {
        // must get raw value to account for disabled fields
        this.onDetailsFormUpdated.emit(formData);
      });
  }

  private updateFormUiStatus(data: Partial<ITrustReceipt>): void {
    this.isPaymentSentVisible = [PaymentTypeId.Cheque, PaymentTypeId.CashiersCheque].includes(data.PaymentTypeId);
  }
}
