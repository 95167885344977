import { Action } from '@ngrx/store';
import {
  IDocCombinePDFHttpRequestParams,
  IDocCombinePDFHttpResponseSchema,
  ISortableDoc,
} from '@app/features/+create-pdf/models';
import { IDoc } from '@app/shared/models';

// load create-PDF Documents
export const CREATE_PDF_DOCS = '[CreatePDF] create PDF docs start';
export const CREATE_PDF_DOCS_SUCCESS = '[CreatePDF] create PDF docs success';
export const CREATE_PDF_DOCS_FAILURE = '[CreatePDF] create PDF docs failure';

// select correspondence
export const SELECT_CORRESPONDENCE = '[CreatePDF]]  select correspondence start';
export const SELECT_CORRESPONDENCE_SUCCESS = '[CreatePDF]  select correspondence success';
export const SELECT_CORRESPONDENCE_FAILURE = '[CreatePDF]  select correspondence failure';

// close create-PDF Modal
export const CLOSE_PDF_MODAL = '[CreatePDF] modal close start';
export const CLOSE_PDF_MODAL_SUCCESS = '[CreatePDF] modal close success';
export const CLOSE_PDF_MODAL_FAILURE = '[CreatePDF] modal close failure';

// set combine doc name
export const SET_COMBINE_DOC_NAME = '[CreatePDF] set combineDocName start';
export const SET_COMBINE_DOC_NAME_SUCCESS = '[CreatePDF] set combineDocName success';
export const SET_COMBINE_DOC_NAME_FAILURE = 'CreatePDF] set combineDocName failure';

// Set Callback Action
export const SET_CREATE_PDF_OPTIONS = '[CreatePDF] set request options start';

// Create and Email PDF Docs
export const CREATE_PDF_EMAIL = '[CreatePDF] create PDF & send email start';
export const CREATE_PDF_EMAIL_SUCCESS = '[CreatePDF] create PDF & send email success';
export const CREATE_PDF_EMAIL_FAILURE = '[CreatePDF] create PDF & send email failure';

// Handle pdf conversion error
export const HANDLE_PDF_ERROR = '[CreatePDF] handle pdf conversion error';
export const CREATE_EMAIL_INIT = '[CreatePDF] create email init';

// Handle invalid extensions
export const CHECK_SOURCE_DOCUMENT_EXTENSIONS = '[CreatePDF] check source document extensions';
export const SET_INVALID_EXTENSIONS = '[CreatePDF] set invalid extensions';

export class CreateEmailInit implements Action {
  readonly type = CREATE_EMAIL_INIT;
  constructor(public payload: any) {}
}

export class SetCreatePDFoptions implements Action {
  readonly type = SET_CREATE_PDF_OPTIONS;
  constructor(public payload: IDocCombinePDFHttpRequestParams) {}
}

// Create PDF Docs Actions
export class CreatePDFDocs implements Action {
  readonly type = CREATE_PDF_DOCS;
  constructor(public payload: any) {}
}

export class CreatePDFDocsSuccess implements Action {
  readonly type = CREATE_PDF_DOCS_SUCCESS;
  constructor(public payload: IDocCombinePDFHttpResponseSchema) {}
}

export class CreatePDFDocsFailure implements Action {
  readonly type = CREATE_PDF_DOCS_FAILURE;
  constructor(public payload: IDocCombinePDFHttpResponseSchema) {}
}

// Select Correspondence
export class SelectCorrespondence implements Action {
  readonly type = SELECT_CORRESPONDENCE;
  constructor(public payload: (ISortableDoc | IDoc)[]) {}
}

export class SelectCorrespondenceSuccess implements Action {
  readonly type = SELECT_CORRESPONDENCE_SUCCESS;
  constructor(public payload: (ISortableDoc | IDoc)[]) {}
}

export class SelectCorrespondenceFailure implements Action {
  readonly type = SELECT_CORRESPONDENCE_FAILURE;
  constructor(public payload: any) {}
}

// close PDF modal
export class ClosePDFModal implements Action {
  readonly type = CLOSE_PDF_MODAL;
  constructor(public payload: any) {}
}

export class ClosePDFModalSuccess implements Action {
  readonly type = CLOSE_PDF_MODAL_SUCCESS;
  constructor(public payload: any) {}
}

export class ClosePDFModalFailure implements Action {
  readonly type = CLOSE_PDF_MODAL_FAILURE;
  constructor(public payload: any) {}
}

// set combine doc name
export class SetDocName implements Action {
  readonly type = SET_COMBINE_DOC_NAME;
  constructor(public payload: any) {}
}

export class SetDocNameSuccess implements Action {
  readonly type = SET_COMBINE_DOC_NAME_SUCCESS;
  constructor(public payload: string) {}
}

export class SetDocNameFailure implements Action {
  readonly type = SET_COMBINE_DOC_NAME_FAILURE;
  constructor(public payload: any) {}
}

// create and Email PDF Docs
export class CreatePDFnEmail implements Action {
  readonly type = CREATE_PDF_EMAIL;
  constructor(public payload: any) {}
}

export class CreatePDFnEmailSuccess implements Action {
  readonly type = CREATE_PDF_EMAIL_SUCCESS;
  constructor(public payload: string) {}
}

export class CreatePDFnEmailFailure implements Action {
  readonly type = CREATE_PDF_EMAIL_FAILURE;
  constructor(public payload: any) {}
}

export class HandlePDFError implements Action {
  readonly type = HANDLE_PDF_ERROR;
  constructor(public payload: IDocCombinePDFHttpResponseSchema) {}
}

export class CheckSourceDocumentExtensions implements Action {
  readonly type = CHECK_SOURCE_DOCUMENT_EXTENSIONS;
  constructor(public payload: IDoc[]) {}
}

export class SetInvalidExtensions implements Action {
  readonly type = SET_INVALID_EXTENSIONS;
  constructor(public payload: string[]) {}
}

export type createPDFActions =
  | CreatePDFDocs
  | CreatePDFDocsSuccess
  | CreatePDFDocsFailure
  | SelectCorrespondence
  | SelectCorrespondenceSuccess
  | SelectCorrespondenceFailure
  | SetDocName
  | SetDocNameSuccess
  | SetDocNameFailure
  | ClosePDFModal
  | ClosePDFModalSuccess
  | ClosePDFModalFailure
  | CreatePDFnEmail
  | CreatePDFnEmailSuccess
  | CreatePDFnEmailFailure
  | SetCreatePDFoptions
  | HandlePDFError
  | CreateEmailInit
  | SetInvalidExtensions
  | CheckSourceDocumentExtensions;
