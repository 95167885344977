import { ActivityCode } from '@app/features/fee-timer/models/fee-timer.model';

export interface FeeInitialDataModel {
  TaskCodeList: TaskCodeListData[];
}

export interface TaskCodeListData {
  TaskCodeGUID: string;
  TaxCodeGUID: string;
  NameFileAs: string;
  BillingDescription: string;
  AmountRate: number;
  Deleted: boolean;
  Timed: boolean;
  CalculationMode: number;
  RowVersion: number;
  BillingMode: number;
}

export class FeeInitialDataModelHelper {
  constructor() {}

  toActivityCodes(origin: FeeInitialDataModel): ActivityCode[] {
    if (origin && origin.TaskCodeList) {
      const result = new Array<ActivityCode>();
      (origin.TaskCodeList || [])
        .filter((x) => x.Timed === true)
        .forEach((code) => {
          result.push({
            taskCodeGUID: code.TaskCodeGUID,
            taxCodeGUID: code.TaxCodeGUID,
            nameFileAs: code.NameFileAs,
            billingDescription: code.BillingDescription,
            amountRate: code.AmountRate,
            deleted: code.Deleted,
            timed: code.Timed,
            calculationMode: code.CalculationMode,
            rowVersion: code.RowVersion,
            billingMode: code.BillingMode,
          } as ActivityCode);
        });
      return result;
    }
    return [];
  }
}
