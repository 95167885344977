import { Component, HostBinding } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { getObjValue, isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-icon-cell',
  templateUrl: './icon-cell.component.html',
})
export class IconCellComponent implements AgRendererComponent {
  iconName: string;
  iconSize: string;
  isHidden: boolean;
  content: string;
  circle: boolean;
  circleStyle: boolean;

  constructor() {}

  @HostBinding('class.d-flex')
  agInit(params: any): void {
    this.iconName = isFunction(params['getIconName']) ? params.getIconName(params.data) : '';
    this.iconSize = isFunction(params['getIconSize']) ? params.getIconSize(params.data) : 'x-icon-md';
    this.isHidden = isFunction(params['getIconHidden']) ? params.getIconHidden(params.data) : false;
    this.content = isFunction(params['getContent']) ? params.getContent(params.data) : null;
    this.circle = isFunction(params['getCircle']) ? params.getCircle(params.data) : false;
    this.circleStyle = isFunction(params['getCircleClass']) ? params.getCircleClass(params.data) : '';
  }

  refresh(params: any): boolean {
    const getIconHiddenFunc = getObjValue(params, 'getIconHidden');
    const getIconNameFunc = getObjValue(params, 'getIconName');
    const getIconSizeFunc = getObjValue(params, 'getIconSize');
    const getContentFunc = getObjValue(params, 'getContent');
    const getCircleFunc = getObjValue(params, 'getCircle');
    const getCircleClassFunc = getObjValue(params, 'getCircleClass');
    this.isHidden = isFunction(getIconHiddenFunc) ? getIconHiddenFunc(params.data) : false;
    this.iconName = isFunction(getIconNameFunc) ? getIconNameFunc(params.data) : '';
    this.iconSize = isFunction(getIconSizeFunc) ? getIconSizeFunc(params.data) : 'x-icon-md';
    this.content = isFunction(getContentFunc) ? getContentFunc(params.data) : null;
    this.circle = isFunction(getCircleFunc) ? getCircleFunc(params.data) : false;
    this.circleStyle = isFunction(getCircleClassFunc) ? getCircleClassFunc(params.data) : '';
    return true;
  }
}
