<a
  href
  scPreventDefault
  class="x-list-group-action-slim-item"
  [popover]="alsSkipTranslation ? alsLabel : (alsLabel | translate: labelParams)"
  triggers="mouseenter:mouseleave"
  [adaptivePosition]="false"
  placement="left"
  container="body"
  containerClass="x-popover-no-arrow"
  (click)="clicked($event)"
>
  <sc-icon [iconName]="alsIcon"></sc-icon>
</a>
