import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from '../../../../../server/modules/shared/functions/common-util.functions';
import { ScCurrencyPipe } from '../currency/sc-currency.pipe';

@Pipe({ name: 'money' })
export class MoneyPipe implements PipeTransform {
  constructor(private currencyPipe: ScCurrencyPipe) {}

  transform(value: string | number, currencySymbol?: string): string {
    if (isNumber(value) && value === 0) {
      return '-';
    }
    const money: string = (value || '').toString();
    return (money && money.charAt(0)) === '-'
      ? '(' + this.currencyPipe.transform(money.substring(1, money.length)) + ')'
      : this.currencyPipe.transform(money);
  }
}
