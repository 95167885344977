const checkPrecision = (val: number, base: number): number => {
  val = Math.round(Math.abs(val));
  return isNaN(val) ? base : val;
};

export const toFixed = (value: number, precision: number = 2): string => {
  precision = checkPrecision(precision, 2); // Default precision is 2
  const power = Math.pow(10, precision);

  // Multiply up by precision, round accurately, then divide and use native toFixed():
  return (Math.round((value + 1e-8) * power) / power).toFixed(precision);
};

export const round = (amount: number, precision = 2): number => +toFixed(amount, precision);

export const getPaymentSubTypeIdFunc = (...source) => {
  let result;

  if (source) {
    Object.entries(source).forEach(([_, value]) => {
      if (value) {
        // SubPaymentTypeID is from Dropdown list option.
        // PaymentSubTypeId is from saved transaction.
        const id = value.SubPaymentTypeID || value.PaymentSubTypeId;
        if (result === undefined && id) {
          result = id;
        }
      }
    });
  }

  return result;
};
