import { ECardFilterType, GroupByNone, ICardGroupBy } from '@app/features/+card/models';
import { ISortInfo } from '@app/shared/models';
import * as actions from '../actions/card-list';
import * as cardDetailsActions from '../actions/card-details';

export interface State {
  selectedCardId: string;
  loading: boolean;
  dbSaveStatus: string;
  initiated: boolean;
  sortInfo: ISortInfo;
  cardSearchText: string;
  cardGroupBy: ICardGroupBy;
  cardFilterBy: string;
  showDeleted: boolean;
  cardFilterType: ECardFilterType;
  selectedCardFilterName: string;
}

export const INITIAL_STATE: State = {
  selectedCardId: null,
  loading: false,
  dbSaveStatus: '',
  initiated: false,
  sortInfo: {
    colId: 'shortName',
    sort: 'asc',
  },
  cardSearchText: '',
  cardGroupBy: GroupByNone,
  cardFilterBy: '',
  showDeleted: false,
  cardFilterType: null,
  selectedCardFilterName: null,
};

export const reducer = (
  state = INITIAL_STATE,
  action: actions.CardListApiActions | actions.CardListDbActions | cardDetailsActions.CardDetailsActions
): State => {
  switch (action.type) {
    case actions.CardListApiActionTypes.INIT_LIST_CARDS_START:
      return {
        ...state,
        loading: !state.initiated,
      };

    case actions.CardListApiActionTypes.INIT_LIST_CARDS_SUCCESS:
      return {
        ...state,
        initiated: true,
      };

    case actions.CardListApiActionTypes.INIT_LIST_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
        initiated: false,
      };

    case actions.CardListApiActionTypes.LIST_CARDS_START:
      return {
        ...state,
        loading: true,
      };

    case actions.CardListApiActionTypes.LIST_CARDS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case actions.CardListApiActionTypes.LIST_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case actions.CardListDbActionTypes.CARDS_SAVE_DB_START:
      return {
        ...state,
        dbSaveStatus: 'start',
        loading: true,
      };

    case actions.CardListDbActionTypes.CARDS_SAVE_DB_SUCCESS:
      return {
        ...state,
        dbSaveStatus: action.payload,
        loading: false,
      };

    case actions.CardListDbActionTypes.CARDS_SAVE_DB_FAILURE:
      return {
        ...state,
        dbSaveStatus: action.payload,
        loading: false,
      };

    case actions.CardListDbActionTypes.CARD_LIST_META_SAVE_DB_START:
      return {
        ...state,
        ...action.payload,
        dbSaveStatus: 'start',
      };

    case actions.CardListDbActionTypes.CARD_LIST_META_SAVE_DB_SUCCESS:
    case actions.CardListDbActionTypes.CARD_LIST_META_SAVE_DB_FAILURE:
      return {
        ...state,
        dbSaveStatus: action.payload,
      };

    case actions.CardListDbActionTypes.SET_CARD_FILTER_TYPE:
      return {
        ...state,
        cardFilterType: action.payload.type,
      };

    case actions.CardListDbActionTypes.SET_SELECTED_CARD_FILTER_NAME:
      return {
        ...state,
        selectedCardFilterName: action.payload.name,
      };

    case actions.CardListDbActionTypes.LOAD_CARD_LIST_META_SUCCESS:
      return {
        ...state,
        selectedCardId:
          action.payload && action.payload.selectedCardId ? action.payload.selectedCardId : state.selectedCardId,
        cardSearchText:
          action.payload && action.payload.cardSearchText ? action.payload.cardSearchText : state.cardSearchText,
      };

    default:
      return state;
  }
};

export const selectInitiated = (state: State) => state.initiated;
export const selectLoading = (state: State) => state.loading;
export const selectCardSearchText = (state: State) => state.cardSearchText;
export const selectSortInfo = (state: State) => state.sortInfo;
export const selectShowDeletedState = (state: State) => state.showDeleted;
export const selectCardGroupBy = (state: State) => state.cardGroupBy;
export const selectSelectedCardId = (state: State) => state.selectedCardId;
export const selectCardFilterType = (state: State) => state.cardFilterType;
export const selectSelectedCardFilterName = (state: State) => state.selectedCardFilterName;
