import { createSelector } from '@ngrx/store';
import { selectPersonListState } from '../reducers';
import * as fromPersonList from '../reducers/person-list.reducer';

export const selectPersonListLoading = createSelector(selectPersonListState, (state: fromPersonList.State) =>
  fromPersonList.selectLoading(state)
);

export const selectPersonListGroupInfo = createSelector(selectPersonListState, (state: fromPersonList.State) =>
  fromPersonList.selectGroupInfo(state)
);

export const selectPersonListLastRowVer = createSelector(selectPersonListState, (state: fromPersonList.State) =>
  fromPersonList.selectLastRowVer(state)
);

export const selectPersonSearchText = createSelector(selectPersonListState, (state: fromPersonList.State) =>
  fromPersonList.selectPersonSearchText(state)
);

export const selectSelectedPersonId = createSelector(selectPersonListState, (state: fromPersonList.State) =>
  fromPersonList.selectSelectedPersonId(state)
);
