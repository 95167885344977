import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { CurrentFeeTimerMatterState, ActivityCode } from '@app/features/fee-timer/models/fee-timer.model';
import {
  selectFeeTimerMatter,
  selectFeeTimerActivityCodesState,
  selectFeeTimerActivityCodesFilteredState,
  selectFeeTimerActivityCodeQueryTextState,
  selectFeeTimerCurrentTimeElapsedState,
  selectFeeTimerSelectedActivityCode,
  selectIsNewEntry,
  selectRunningTimerFeeGUID,
} from '@app/features/fee-timer/store/selectors';
import { FeeTimerActivityCodeSearch, FeeTimerActivityCodeSelected } from '@app/features/fee-timer/store/actions';
import { sortAscBy } from '@server/modules/shared/functions/common-util.functions';
import { selectTimeEntrySummary } from '@app/features/+time-fee-ledger/store/selectors/time-fee-entry.selectors';

@Component({
  selector: 'sc-time-activity-layout',
  templateUrl: './time-activity-layout.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeActivityLayoutComponent implements OnInit {
  @Output() closeDropdown = new EventEmitter();

  activityDetails$: Observable<CurrentFeeTimerMatterState>;
  recordingState$: Observable<string>;
  selectedActivityCode$: Observable<string>;
  selectedTimeEntryFeeGUID$: Observable<string>;
  runningTimerFeeGUID$: Observable<string>;
  isNewEntry$: Observable<boolean>;

  loading$: Observable<boolean>;
  activityCodes$: Observable<ActivityCode[]>;
  activityCodesFiltered$: Observable<ActivityCode[]>;
  activityCodesQueryText$: Observable<string>;
  showActivityList = false;

  constructor(private _store: Store<any>, private cdr: ChangeDetectorRef) {
    this.activityDetails$ = this._store.pipe(select(selectFeeTimerMatter));
    this.recordingState$ = this._store.pipe(select(selectFeeTimerCurrentTimeElapsedState));
    this.selectedActivityCode$ = this._store.pipe(select(selectFeeTimerSelectedActivityCode));
    this.selectedTimeEntryFeeGUID$ = this._store.pipe(
      select(selectTimeEntrySummary),
      filter((v) => !!v),
      map((summary) => summary.FeeGUID)
    );
    this.runningTimerFeeGUID$ = this._store.pipe(select(selectRunningTimerFeeGUID));
    this.isNewEntry$ = this._store.pipe(select(selectIsNewEntry));

    this.activityCodes$ = this._store.pipe(
      select(selectFeeTimerActivityCodesState),
      map((activityCodes) =>
        sortAscBy(activityCodes, (ac) => {
          if (!!ac && !!ac.nameFileAs) {
            return ac.nameFileAs.toUpperCase();
          }

          return '';
        })
      )
      // sort for nameFileAs must be done on all upperCase, as some nameFileAs are lowerCase
    );
    this.activityCodesFiltered$ = this._store.pipe(
      select(selectFeeTimerActivityCodesFilteredState),
      map((activityCodes) =>
        sortAscBy(activityCodes, (ac) => {
          if (!!ac && !!ac.nameFileAs) {
            return ac.nameFileAs.toUpperCase();
          }

          return '';
        })
      )
      // sort for nameFileAs must be done on all upperCase, as some nameFileAs are lowerCase
    );
    this.activityCodesQueryText$ = this._store.pipe(select(selectFeeTimerActivityCodeQueryTextState));
  }

  ngOnInit() {}

  onSearch(query: string): void {
    this._store.dispatch(new FeeTimerActivityCodeSearch(query));
  }

  onSelected(activityCode: ActivityCode): void {
    this._store.dispatch(new FeeTimerActivityCodeSelected(activityCode));
  }

  saveCalled() {
    this.closeDropdown.emit();
    this.cdr.markForCheck();
  }
}
