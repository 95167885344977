import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'sc-btn',
  templateUrl: './button.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ButtonComponent), multi: true }],
})
export class ButtonComponent implements OnInit, ControlValueAccessor {
  @ViewChild('button', { static: true }) button: ElementRef;

  @Input()
  btnType: string;
  @Input()
  styleClass: string;
  @Input()
  btnSize: string;
  @Input()
  disabled: boolean;
  @Input()
  isLoading = false;
  @Input()
  tabindex = 0;
  @Output()
  btnClicked = new EventEmitter<any>();

  public value: string;

  constructor() {
    this.value = null;
  }

  ngOnInit() {}

  onBtnClick(event: any) {
    if (!this.disabled) {
      this.btnClicked.emit(event);
    }
  }

  buildBtnSize(): any {
    if (this.btnSize) {
      return 'btn-' + this.btnSize + ' ';
    } else {
      return '';
    }
  }

  get btnClass(): string {
    let classes = this.styleClass ? this.styleClass + ' ' : '';

    if (this.btnType === ('default' || 'outline-default')) {
      classes += this.buildBtnSize() + 'x-btn-' + this.btnType;
    } else {
      classes += this.buildBtnSize() + 'btn-' + this.btnType;
    }
    return classes;
  }

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  focus(): void {
    this.button.nativeElement.focus();
  }
}
