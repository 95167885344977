<footer *ngIf="ttNumber >= 0" class="ag-total-count">
  <p>
    Showing:
    <span *ngIf="ttNumber > 1; else singular">
      <strong>{{ ttNumber }}</strong> {{ (ttUnitPlural | translate) || 'entries' }}
    </span>
    <ng-template #singular>
      <span>
        <strong>{{ ttNumber }}</strong> {{ (ttUnitSingular | translate) || 'entry' }}
      </span>
    </ng-template>
  </p>
</footer>
