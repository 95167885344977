import { AppSlice } from '@app/core/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducers/autotime.reducer';

export const selectAutoTimeState = createFeatureSelector<State>(AppSlice.AutoTime);

export const selectAutoTimeTask = createSelector(selectAutoTimeState, (state: State) => state.task);
export const selectAutoTimeStart = createSelector(selectAutoTimeState, (state: State) => state.start);
export const selectAutoTimeBillable = createSelector(selectAutoTimeState, (state: State) => state.billable);
export const selectAutoTimeMatterId = createSelector(selectAutoTimeState, (state: State) => state.matterId);
