import { RecipientPipe } from './recipient/recipient.pipe';
import { SafePipe } from './safe/safe.pipe';
import { ScTranslatePipe } from '@app/shared/pipes/translate/sc-translate.pipe';
import { SnippetPipe } from './snippet/snippet.pipe';
import { SnippetMiddlePipe } from './snippet/snippet-middle.pipe';
import { StripFrontQuestionMarkPipe } from './strip-front-question-mark/strip-front-question-mark.pipe';
import { MoneyPipe } from './money/money.pipe';
import { ScCurrencyPipe } from './currency/sc-currency.pipe';
import { ScDatePipe } from './date/sc-date.pipe';
import { ScAccDatePipe } from './date/sc-acc-date.pipe';
import { CardFilterPipe } from './card-filter/sc-card-filter.pipe';

export * from './recipient/recipient.pipe';
export * from './safe/safe.pipe';
export * from '@app/shared/pipes/translate/sc-translate.pipe';
export * from './snippet/snippet.pipe';
export * from './snippet/snippet-middle.pipe';
export * from './strip-front-question-mark/strip-front-question-mark.pipe';
export * from './money/money.pipe';
export * from './currency/sc-currency.pipe';
export * from './date/sc-date.pipe';
export * from './date/sc-acc-date.pipe';
export * from './card-filter/sc-card-filter.pipe';

export const sharedPipes = [
  MoneyPipe,
  RecipientPipe,
  ScTranslatePipe,
  SafePipe,
  SnippetPipe,
  SnippetMiddlePipe,
  StripFrontQuestionMarkPipe,
  ScCurrencyPipe,
  ScDatePipe,
  ScAccDatePipe,
  CardFilterPipe,
];
