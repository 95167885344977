import { Injectable } from '@angular/core';
import { UiUtilsService } from '../ui/ui-utils.service';
import { BaseService } from '../base/base.service';
import { AuthService } from '../../../core/services';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import * as reportingActions from '@app/features/+reporting/store/actions';
import { joinArrayToString } from '@server/modules/shared/functions';

export enum EPrintSource {
  Direct = 0,
  View = 1,
  Custom = 2,
}

@Injectable({
  providedIn: 'root',
})
export class PrintService extends BaseService {
  private url_: string;

  constructor(private _uiUtilsSvc: UiUtilsService, private _authSvc: AuthService, private store: Store<any>) {
    super();
    this.url_ = environment.config.endpoint.reporting;
  }

  printPreview(reportName: string, options: any, filename?: string, source?: EPrintSource, extras?: any): void {
    this.store.dispatch(new reportingActions.ReportingPreview(extras));
    this.store.dispatch(
      new reportingActions.ReportingGetPreviewUrlStart({
        reportName,
        options,
        fileName: `${filename}.pdf`,
        fileNameOnly: filename,
        source,
      })
    );
  }

  async print(reportName: string, options: any, source?: EPrintSource): Promise<void> {
    const url = await this.buildPrintUrl(reportName, options, source);
    const browserWindow = window.open(url, '_blank');
    if (!browserWindow || browserWindow.closed || typeof browserWindow.closed === 'undefined') {
      this._uiUtilsSvc.handlePopUpBlocked();
    }
  }

  async buildPrintUrl(reportName: string, options: any, source: EPrintSource = EPrintSource.Direct): Promise<string> {
    let page: string;
    if (source === EPrintSource.Direct) {
      page = 'directreport';
    } else if (source === EPrintSource.View) {
      page = 'Reports';
    } else if (source === EPrintSource.Custom) {
      // noted: Custom endpoint has issue with arraybuffer responseType.
      page = 'CustomReports/ReportViewer';
    }

    const token = await this._authSvc.getToken();
    const httpsSegment: string = this.url_.indexOf('https:') === -1 ? 'https:' : '';
    const additionalQueries = options ? Object.entries(options).map(([key, value], idx) => `&${key}=${value}`) : [];

    return (
      httpsSegment +
      this.urlJoin(
        this.url_,
        `${page}.aspx`,
        `?access_token=${token}`,
        `&src=leo`,
        `&rpt=${reportName}`,
        `&rs:Command=Render`,
        `&rs:Format=PDF`,
        joinArrayToString(additionalQueries, '')
      )
    );
  }

  buildSimplePrintUrl(reportName: string, options: any, source: EPrintSource = EPrintSource.Direct): string {
    let page: string;
    if (source === EPrintSource.Direct) {
      page = 'directreport';
    } else if (source === EPrintSource.View) {
      page = 'ViewReport';
    } else if (source === EPrintSource.Custom) {
      // noted: Custom endpoint has issue with arraybuffer responseType.
      page = 'CustomReports/ReportViewer';
    }

    const httpsSegment: string = this.url_.indexOf('https:') === -1 ? 'https:' : '';
    const additionalQueries = options ? Object.entries(options).map(([key, value], idx) => `&${key}=${value}`) : [];

    return (
      httpsSegment +
      this.urlJoin(
        this.url_,
        `${page}.aspx`,
        `?src=leo`,
        `&rpt=${reportName}`,
        `&rs:Command=Render`,
        `&rs:Format=PDF`,
        joinArrayToString(additionalQueries, '')
      )
    );
  }
}
