import { Routes } from '@angular/router';
import { NotFoundComponent } from '@app/core/pages';

export const errorRoutes: Routes = [
  {
    path: 'error',
    children: [
      {
        path: '',
        component: NotFoundComponent,
        pathMatch: 'full',
      },
      {
        path: 'page-not-found',
        component: NotFoundComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**', // always last
    component: NotFoundComponent,
  },
];
