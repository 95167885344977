import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { MenuItemDef } from '@ag-grid-community/core';
import { AddinActionType, AddinItem, LeapAppGridId, ViewLeapApp } from '@app/features/matter-addin/models';
import { OnlyVisibleLinks } from '@app/features/matter-addin/constants/matter-addin.constants';

interface ILeapAppContextItem {
  rowItem: any;
  selectedItems: any[];
  leapApp: ViewLeapApp;
  contextItem: AddinItem;
}
@Directive()
export abstract class GridWithLeapAppSupportBase {
  @Input() leapApps: ViewLeapApp[];

  @Output() onClickLeapAppContextItem = new EventEmitter<ILeapAppContextItem>();

  abstract leapAppGridId: LeapAppGridId;

  protected abstract leapAppContextItemVisibilityCheck(params: { rowItem?: any; addinItem: AddinItem }): boolean;

  protected configLeapAppForAgGridContextMenu(params: {
    rowItem: any;
    selectedItems: any[];
  }): Array<MenuItemDef | string> {
    // no leap apps, return empty array
    if (!this.leapApps || this.leapApps.length === 0) {
      return [];
    }

    const { rowItem, selectedItems } = params;
    let contextMenu = [];

    // loop through every apps
    for (const app of this.leapApps) {
      const { view } = app;
      if (!!view && !!view.grids && view.grids.length > 0) {
        const grid = view.grids.find((g) => g.gridId === this.leapAppGridId);

        const validContext = OnlyVisibleLinks(grid?.context);
        if (validContext.length > 0) {
          let menuItems = [];
          for (const c of grid.context) {
            if (this.leapAppContextItemVisibilityCheck({ addinItem: c, rowItem })) {
              const pre = !!menuItems && menuItems.length !== 0 ? [...menuItems, 'separator'] : [...menuItems];
              // If the AddinItem's actionType is ContextHeader,
              // it means it has subItems and we need to build a submenu for it
              if (c.actionType === AddinActionType.ContextHeader) {
                const subMenu = c.items
                  .filter((i) => this.leapAppContextItemVisibilityCheck({ addinItem: i, rowItem }))
                  .map((i) => ({
                    name: i.label,
                    action: () =>
                      this.handleClickActionOnLeapAppContextItem({
                        leapApp: app,
                        rowItem,
                        addinItem: i,
                        selectedItems,
                      }),
                  }));

                if (!!subMenu && subMenu.length > 0) {
                  menuItems = [
                    ...pre,
                    {
                      name: c.label,
                      cssClasses: ['dropdown-header', 'ag-dropdown-header'],
                      disabled: true,
                    },
                    ...subMenu,
                  ];
                }
              } else {
                // AddinActionType.Context
                menuItems = [
                  ...pre,
                  {
                    name: c.label,
                    action: () =>
                      this.handleClickActionOnLeapAppContextItem({
                        leapApp: app,
                        rowItem,
                        addinItem: c,
                        selectedItems,
                      }),
                    disabled: c.hidden !== false,
                  },
                ];
              }
            }
          }

          if (!!menuItems && menuItems.length > 0) {
            // remove the last item of the array, if the last item is a `separator`
            if (menuItems[menuItems.length - 1] === 'separator') {
              menuItems = menuItems.slice(0, -1);
            }
            contextMenu = [
              ...contextMenu,
              {
                name: app.name,
                subMenu: menuItems,
              },
            ];
          }
        }
      }
    }

    return contextMenu;
  }

  protected handleClickActionOnLeapAppContextItem(params: {
    rowItem: any;
    selectedItems: any[];
    addinItem: AddinItem;
    leapApp: ViewLeapApp;
  }) {
    this.onClickLeapAppContextItem.emit({
      rowItem: params.rowItem,
      selectedItems: params.selectedItems,
      leapApp: params.leapApp,
      contextItem: params.addinItem,
    });
  }
}
