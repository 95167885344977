import { EAccountingTransaction } from '@app/features/accounting/models';
import { Action } from '@ngrx/store';
import { IDocumentRecord, PreviewAccountingTransaction } from '../../models/preview-model';

export const PREVIEW_EMAIL = '[Preview] correspondence email';

export const PREVIEW_PRINT = '[Preview] print';
export const PREVIEW_PRINT_SUCCESS = '[Preview] print success';
export const PREVIEW_PRINT_FAILURE = '[Preview] print failure';

export const PREVIEW_DOC_EDIT = '[Preview] edit doc';
export const PREVIEW_DOC_EDIT_ONLINE = '[Preview] edit doc online';
export const PREVIEW_EMAIL_OPEN_IN_OUTLOOK = '[Preview] open email in outlook';

export const SET_PREVIEW_ACCOUNTING_BASE64 = '[Preview] set preview accounting inform for base64 string';
export const SET_PREVIEW_ACCOUNTING_URL = '[Preview] set preview accounting inform for reporting url';
export const SET_NEXT_ACCOUNTING_NAVIGATION_INFORM = '[Preview] set next accounting transaction navigation inform';
export const CLEAR_PREVIEW_ACCOUNTING = '[Preview] clear preview accounting store';
export const PREVIEW_SAVE_TO_MATTER_VIA_REPORTING = '[Preview] save doc as matter\'s correspondence via reporting api';
export const PREVIEW_INVOICE_SAVE_TO_MATTER_VIA_DOC_BUILDER =
  '[Preview] save invoice preview doc as matter\'s correspondence via docBuilder api';
export const PREVIEW_INVOICE_SAVE_TO_MATTER_VIA_DOC_BUILDER_SUCCESS =
  '[Preview] save invoice preview doc as matter\'s correspondence via docBuilder api successfully';
export const SAVE_DOCUMENT_TO_MATTER_VIA_DOC_BUILDER =
  '[Preview] save document preview doc as matter\'s correspondence via docBuilder api';
export const SAVE_DOCUMENT_TO_MATTER_VIA_DOC_BUILDER_SUCCESS =
  '[Preview] save document preview doc as matter\'s correspondence via docBuilder api successfully';
export const GET_INVOICE_PREVIEW_VIA_DOC_BUILDER = '[Preview] get invoice preview via docBuilder';
export const GET_INVOICE_PREVIEW_VIA_REPORTING = '[Preview] get invoice preview via reporting';
export const GET_ACCOUNTING_PREVIEW_VIA_DOC_BUILDER = '[Preview] get accounting doc preview via docBuilder';
export const GET_ACCOUNTING_PREVIEW_VIA_REPORTING = '[Preview] get accounting doc preview via reporting';
export const SET_PREVIEW_ACCOUNTING_LOADING_STATUS = '[Preview] set preview accounting loading status';

export const SET_PREVIEW_DOCUMENT = '[Preview] set preview document';

export const PREVIEW_DOCUMENT_LOADED = '[Preview] document loaded';
export const PREVIEW_MODAL_CLOSED = '[Preview] modal closed';

export class PreviewEmail implements Action {
  readonly type = PREVIEW_EMAIL;

  constructor(public payload: { isPdfFormat: boolean; doc: IDocumentRecord; previewUrl: string }) {}
}

export class PreviewPrint implements Action {
  readonly type = PREVIEW_PRINT;

  constructor(public payload: { doc: any; previewUrl?: string }) {}
}

export class PreviewPrintSuccess implements Action {
  readonly type = PREVIEW_PRINT_SUCCESS;

  constructor(public payload: string) {}
}

export class PreviewPrintFailure implements Action {
  readonly type = PREVIEW_PRINT_FAILURE;

  constructor(public payload: any) {}
}

export class PreviewDocEdit implements Action {
  readonly type = PREVIEW_DOC_EDIT;

  constructor(public payload: { matterId: string; documentId: string; ext: string }) {}
}

export class PreviewDocEditOnline implements Action {
  readonly type = PREVIEW_DOC_EDIT_ONLINE;

  constructor(public payload: { matterId?: string; documentId: string; ext: string }) {}
}

export class PreviewEmailOpenInOutlook implements Action {
  readonly type = PREVIEW_EMAIL_OPEN_IN_OUTLOOK;

  constructor(public payload: { matterId: string; documentId: string; ext: string; latestVersionId?: string }) {}
}

export class SetPreviewAccountingInformBase64 implements Action {
  readonly type = SET_PREVIEW_ACCOUNTING_BASE64;

  constructor(
    public payload: {
      documentName: string;
      superDiaryDocumentId: string;
      previewBase64String: string;
      precedentId: string;
      isCustomPrecedent: boolean;
      transactionType: EAccountingTransaction;
      transactionList: Array<PreviewAccountingTransaction>;
    }
  ) {}
}

export class SetPreviewAccountingInformUrl implements Action {
  readonly type = SET_PREVIEW_ACCOUNTING_URL;

  constructor(
    public payload: {
      documentName: string;
      previewUrl: string;
      transactionType: EAccountingTransaction;
      transactionList?: Array<PreviewAccountingTransaction>;
    }
  ) {}
}

export class ClearPreviewAccounting implements Action {
  readonly type = CLEAR_PREVIEW_ACCOUNTING;

  constructor(public payload: any) {}
}

export class SaveAccountingDocToMatterViaReporting implements Action {
  readonly type = PREVIEW_SAVE_TO_MATTER_VIA_REPORTING;

  constructor(public payload: any) {}
}

export class SaveInvoicePreviewDocToMatterViaDocBuilder implements Action {
  readonly type = PREVIEW_INVOICE_SAVE_TO_MATTER_VIA_DOC_BUILDER;

  constructor(public payload: any) {}
}

export class SaveInvoicePreviewDocToMatterViaDocBuilderSuccess implements Action {
  readonly type = PREVIEW_INVOICE_SAVE_TO_MATTER_VIA_DOC_BUILDER_SUCCESS;

  constructor(public payload: { documentId: string }) {}
}

export class SaveDocumentPreviewDocToMatterViaDocBuilder implements Action {
  readonly type = SAVE_DOCUMENT_TO_MATTER_VIA_DOC_BUILDER;

  constructor(public payload: any) {}
}

export class SaveDocumentPreviewDocToMatterViaDocBuilderSuccess implements Action {
  readonly type = SAVE_DOCUMENT_TO_MATTER_VIA_DOC_BUILDER_SUCCESS;

  constructor(public payload: { documentId: string }) {}
}

export class SetNextAccountingNavigationInform implements Action {
  readonly type = SET_NEXT_ACCOUNTING_NAVIGATION_INFORM;

  constructor(
    public payload: {
      type: EAccountingTransaction;
      query: any;
    }
  ) {}
}

export class GetInvoicePreviewViaDocBuilder implements Action {
  readonly type = GET_INVOICE_PREVIEW_VIA_DOC_BUILDER;

  constructor(
    public payload: {
      precedentId: string;
      isCustomPrecedent: boolean;
      invoiceNumber: string;
      invoiceGuid: string;
      matterGuid: string;
      splitCardGuid: string;
    }
  ) {}
}

export class GetInvoicePreviewViaReporting implements Action {
  readonly type = GET_INVOICE_PREVIEW_VIA_REPORTING;

  constructor(
    public payload: {
      invoiceNumber: string;
      invoiceGuid: string;
      matterGuid: string;
      splitCardGuid: string;
    }
  ) {}
}

export class GetAccountingPreviewViaDocBuilder implements Action {
  readonly type = GET_ACCOUNTING_PREVIEW_VIA_DOC_BUILDER;

  constructor(
    public payload: {
      accountingType: EAccountingTransaction;
      precedentId: string;
      isCustomPrecedent: boolean;
      transactionNumber: string;
      transactionGuid: string;
      matterGuid: string;
      splitCardGuid: string;
    }
  ) {}
}

export class GetAccountingPreviewViaReporting implements Action {
  readonly type = GET_ACCOUNTING_PREVIEW_VIA_REPORTING;

  constructor(
    public payload: {
      accountingType: EAccountingTransaction;
      transactionNumber: string;
      transactionGuid: string;
      matterGuid: string;
      splitCardGuid: string;
    }
  ) {}
}

export class SetPreviewAccountingLoadingStatus implements Action {
  readonly type = SET_PREVIEW_ACCOUNTING_LOADING_STATUS;

  constructor(
    public payload: {
      status: boolean;
    }
  ) {}
}

export class SetPreviewDocument implements Action {
  readonly type = SET_PREVIEW_DOCUMENT;

  constructor(public payload: any) {}
}

export class PreviewDocumentOpened implements Action {
  readonly type = PREVIEW_DOCUMENT_LOADED;
  constructor(public payload: IDocumentRecord) {}
}

export class PreviewModalClosed implements Action {
  readonly type = PREVIEW_MODAL_CLOSED;
  constructor(public payload: any) {}
}

export type PreviewActions =
  | PreviewDocEdit
  | PreviewDocEditOnline
  | PreviewEmailOpenInOutlook
  | PreviewEmail
  | SetPreviewAccountingInformBase64
  | SetPreviewAccountingInformUrl
  | SetNextAccountingNavigationInform
  | ClearPreviewAccounting
  | SaveAccountingDocToMatterViaReporting
  | SaveInvoicePreviewDocToMatterViaDocBuilder
  | SaveInvoicePreviewDocToMatterViaDocBuilderSuccess
  | SaveDocumentPreviewDocToMatterViaDocBuilder
  | SaveDocumentPreviewDocToMatterViaDocBuilderSuccess
  | SetPreviewAccountingLoadingStatus
  | GetInvoicePreviewViaDocBuilder
  | GetInvoicePreviewViaReporting
  | GetAccountingPreviewViaDocBuilder
  | GetAccountingPreviewViaReporting
  | PreviewPrint
  | PreviewPrintSuccess
  | PreviewPrintFailure
  | SetPreviewDocument
  | PreviewDocumentOpened
  | PreviewModalClosed;
