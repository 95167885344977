import { Injectable } from '@angular/core';
import { Dexie } from 'dexie';
import { DexieService } from '../dexie/dexie.service';
import { LogService } from '../log/log.service';
import { from, Observable } from 'rxjs';
import { PlatformService } from '../platform/platform.service';

@Injectable()
export class FormStorageService {
  table: Dexie.Table<string, any>;

  constructor(private _dexieSvc: DexieService, private _log: LogService, private _platformSvc: PlatformService) {
    this._log.init('form-storage-service');
    if (this._platformSvc.isBrowser) {
      this.table = this._dexieSvc.table('formUi');
    }
  }

  formUi(key: string): Observable<any> {
    if (this._platformSvc.isBrowser) {
      return from(this.table.get(key));
    }
  }

  clean() {
    if (this._platformSvc.isBrowser) {
      return from(this.table.clear());
    }
  }

  setFormUi(key: string, value: any) {
    if (this._platformSvc.isBrowser) {
      return from(this.table.put(value, key));
    }
  }
}
