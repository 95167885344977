<div class="h-100" #previewDiv>
  <div
    *ngIf="!hasSrcToPreview || hasError; else preview"
    class="d-flex h-100 justify-content-center align-items-center"
  >
    <h5>
      {{ errMsg || 'Sorry, no preview available.' }}
    </h5>
  </div>
  <ng-template #preview>
    <pdf-viewer
      *ngIf="!hasError"
      [src]="previewSrc"
      class="d-block"
      (error)="onError($event)"
      [original-size]="originalSize"
      [zoom]="zoom"
      [page]="page"
      [show-all]="false"
      [fit-to-page]="fitToPage"
      [autoresize]="autoresize"
      (on-progress)="onPdfViewerProgress($event)"
      (after-load-complete)="onLoadComplete($event)"
    ></pdf-viewer>

    <div class="x-file-viewer-controls-wrapper" *ngIf="isLoaded">
      <div class="x-file-viewer-controls">
        <button type="button" class="x-file-viewer-control-btn" (click)="onZoomOut()">
          <sc-icon iconName="minus-circle-outline"></sc-icon>
        </button>
        <button type="button" class="x-file-viewer-control-btn" (click)="onZoomIn()">
          <sc-icon iconName="plus-circle-outline"></sc-icon>
        </button>
        <button
          type="button"
          class="x-file-viewer-control-btn"
          #previousPageBtn
          (click)="onPreviousPage()"
          [disabled]="!enablePreviousPage"
        >
          <sc-icon iconName="chevron-up-circle-outline"></sc-icon>
        </button>
        <div class="x-file-viewer-control-text" [ngSwitch]="editingPageNumber">
          <button
            type="button"
            class="x-file-viewer-control-btn"
            *ngSwitchCase="false"
            (click)="editingPageNumber = true"
          >
            {{ page }} / {{ totalPages }}
          </button>
          <input
            class="x-file-viewer-control-input"
            type="number"
            min="1"
            [max]="totalPages"
            [(ngModel)]="page"
            value="1"
            pattern="[0-9]*"
            *ngSwitchCase="true"
            (focusout)="editingPageNumber = false"
            (change)="adjustOutofRangeValue()"
            scAutofocus
          />
        </div>

        <button
          type="button"
          class="x-file-viewer-control-btn"
          #nextPageBtn
          (click)="onNextPage()"
          [disabled]="!enableNextPage"
        >
          <sc-icon iconName="chevron-down-circle-outline"></sc-icon>
        </button>
      </div>
    </div>
    <div
      *ngIf="processing"
      class="d-flex h-100 w-100 bg-white justify-content-center align-items-center"
      [ngStyle]="{ position: 'absolute', top: '0', left: '0' }"
    >
      <progressbar
        [value]="progressValue"
        [max]="progressMax"
        [striped]="true"
        [animate]="true"
        [ngStyle]="{ height: '1rem', width: '250px' }"
      >
        <i>{{ progressValue }}%</i>
      </progressbar>
    </div>
  </ng-template>
</div>
