import { createSelector } from '@ngrx/store';

import * as fromMatterAddin from '../reducer/matter-addin.reducer';
import { selectMatterAddinState } from '../reducer';

export const selectPageNumber = createSelector(selectMatterAddinState, (state: fromMatterAddin.State) =>
  fromMatterAddin.selectPageNumber(state)
);

export const selectAllAddins = createSelector(selectMatterAddinState, (state: fromMatterAddin.State) =>
  fromMatterAddin.selectAllAddins(state)
);

export const selectMatterAddins = createSelector(selectMatterAddinState, (state: fromMatterAddin.State) =>
  fromMatterAddin.selectAddins(state)
);

export const selectMatterAddinsContextMenu = createSelector(selectMatterAddinState, (state: fromMatterAddin.State) =>
  fromMatterAddin.selectAddinsContextMenu(state)
);

export const selectMatterAddinItem = createSelector(selectMatterAddinState, (state: fromMatterAddin.State) =>
  fromMatterAddin.selectAddinItem(state)
);
