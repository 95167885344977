import { createSelector } from '@ngrx/store';
import { selectSelectedMatterTypeId } from '@app/features/+matter-types/store';

import * as fromPrecedentList from '../reducers/precedent-list.reducer';
import { selectPrecedentsState, State } from '../reducers';
import { AllMatterTypeId } from '../../models';

export const selectPrecedentListState = createSelector(selectPrecedentsState, (state: State) => state.precedentList);

export const selectLoading = createSelector(selectPrecedentListState, (state: fromPrecedentList.State) =>
  fromPrecedentList.selectLoading(state)
);

export const selectLoadingFolderId = createSelector(selectPrecedentListState, (state: fromPrecedentList.State) =>
  fromPrecedentList.selectLoadingFolderId(state)
);

export const selectError = createSelector(selectPrecedentListState, (state: fromPrecedentList.State) =>
  fromPrecedentList.selectError(state)
);

export const selectMatterTypePrecedentsEntities = createSelector(
  selectPrecedentListState,
  fromPrecedentList.selectMatterTypePrecedentsEntities
);

export const selectSelectedMatterTypePrecedentList = createSelector(
  selectMatterTypePrecedentsEntities,
  selectSelectedMatterTypeId,
  (entities, id) => (!!entities[id] ? entities[id].precedents : [])
);

export const selectPrecedentsTree = createSelector(
  selectPrecedentListState,
  selectSelectedMatterTypeId,
  (state: fromPrecedentList.State, matterTypeId: string) => fromPrecedentList.selectPrecedentsTree(state, matterTypeId)
);

export const selectPrecedentsList = createSelector(selectMatterTypePrecedentsEntities, (entities) =>
  !!entities[AllMatterTypeId] ? entities[AllMatterTypeId].precedents : []
);

export const selectSelectedPrecedentId = createSelector(selectPrecedentListState, (state: fromPrecedentList.State) =>
  fromPrecedentList.selectSelectedPrecedentId(state)
);

export const selectSelectedPrecedent = createSelector(selectPrecedentListState, (state: fromPrecedentList.State) =>
  fromPrecedentList.selectSelectedPrecedent(state)
);

export const selectePrecedentsListPage = createSelector(selectPrecedentListState, fromPrecedentList.selectPage);

export const selectePrecedentsListTotalPage = createSelector(
  selectPrecedentListState,
  fromPrecedentList.selectTotalPage
);

export const isSearchPrecedentByDocumentName = createSelector(
  selectPrecedentListState,
  (state: fromPrecedentList.State) => !!state.searchText
);

export const selectPrecedentSearchText = createSelector(selectPrecedentListState, fromPrecedentList.selectSearchText);

export const selectNewPrecedentDraftCopy = createSelector(selectPrecedentListState, fromPrecedentList.selectNewPrecedentDraft);

export const selectFilterByMatterType = createSelector(selectPrecedentListState, fromPrecedentList.selectFilterByMatterType);
