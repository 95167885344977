import { createAction, props } from '@ngrx/store';
import { AdvancedSearchData } from '@app/features/+matter-list/models';

export const advancedSearchModalOnClose = createAction(
  '[Advanced Search Modal] on close event',
  props<{ payload: AdvancedSearchData }>()
);

export const advancedSearchModalOnOpen = createAction(
  '[Advanced Search Modal] on open event',
  props<{ payload: AdvancedSearchData }>()
);
