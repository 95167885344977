import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sc-value-card',
  templateUrl: './value-card.component.html',
})
export class ValueCardComponent implements OnInit {
  @Input() iconName: string;
  @Input() value: string;
  @Input() label: string;
  @Input() wrapperClass: string;
  @Input() cardRouterLink: string | any[];
  @Input()
  cardRouterQueryParams: {
    [k: string]: any;
  };
  @Input() routerLinkTooltip: string;
  classes = '';

  constructor() {}

  ngOnInit() {
    if (this.wrapperClass) {
      this.classes += this.wrapperClass;
    }
  }
}
