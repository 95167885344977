import { Action } from '@ngrx/store';

import { Staff, FirmDetails } from '../../../shared/models';
import { IGlobalUIPreference, IClearCacheParams, EmailInitialisationData } from '../../models';
import { IMatterCore, IMatterListEntry } from '@app/features/+matter-list/models';
import { Update } from '@ngrx/entity';
import { Preference } from '@app/shared/models/user-preferences.model';
import { UserInfo } from '@leapdev/auth-agent/src/lib/types';

export const INIT_APP = '[App] init';
export const APP_LOADING = '[App] display loading';
export const SET_APP_LOADING_MESSAGE = '[App] set loading message';
export const CLEAR_APP_LOADING_MESSAGE = '[App] clear loading message';

export const ADD_STAFF_LIST = '[App] add staff list';
export const ADD_STAFF_LIST_SUCCESS = '[App] add staff list success';
export const ADD_STAFF_LIST_FAILURE = '[App] add staff list failure';

export const SET_CURRENT_STAFF_ID = '[App] set current staff id';

export const LOAD_CURRENT_STAFF_SECURITY_SETTINGS_START = '[App] load current staff security settings start';
export const LOAD_CURRENT_STAFF_SECURITY_SETTINGS_SUCCESS = '[App] load current staff security settings success';
export const LOAD_CURRENT_STAFF_SECURITY_SETTINGS_FAILURE = '[App] load current staff security settings failure';

export const LOAD_USER_PREFERENCE_START = '[App] load user preferences start';
export const LOAD_USER_PREFERENCE_SUCCESS = '[App] load user preferences success';
export const LOAD_USER_PREFERENCE_FAILURE = '[App] load user preferences failure';

export const UPDATE_USER_PREFERENCE_START = '[App] update user preferences start';
export const UPDATE_USER_PREFERENCE_SUCCESS = '[App] update user preferences success';
export const UPDATE_USER_PREFERENCE_FAILURE = '[App] update user preferences failure';

export const LOAD_UI_PREFERENCE_DB_START = '[App] load ui preference from db start';
export const LOAD_UI_PREFERENCE_DB_SUCCESS = '[App] load ui preference from db success';
export const LOAD_UI_PREFERENCE_DB_FAILURE = '[App] load ui preference from db failure';

export const SAVE_UI_PREFERENCE = '[App] save ui preference';
export const SAVE_PREVIEW_UI_PREFERENCE = '[App] save preview ui preference';

export const APP_DEFAULT = '[App] default';

export const SAVE_MATTER = '[App] save matter entry';
export const GET_MATTER_CORE = '[App] get current matter\'s matterCode';
export const GET_MATTER_CORE_SUCCESS = '[App] get current matter\'s matterCode successfully';
export const GET_MATTER_CORE_FAIL = '[App] unable to get current matter\'s matterCode';
export const CLEAR_MATTER = '[App] clear matter';

export const CLEAR_CACHE = '[App] clear cache';
export const REFRESH_MATTER_CACHE = '[App] refresh matter cache';

export const ADD_FIRM_DETAILS = '[App] add firm details';
export const ADD_FIRM_DETAILS_SUCCESS = '[App] add firm details success';
export const ADD_FIRM_DETAILS_FAILURE = '[App] add firm details failure';

export const INITIALISE_EMAIL = '[App] initialise email';
export const SET_CURRENT_MATTER = '[Current matter] set';
export const SET_WALKME_READY = '[App] set walkme ready status';

export const UPDATE_FIRM_CONSENT = '[App] update firm consent';
export const UPDATE_USER_DETAILS = '[App] update user details';
export const UPDATE_USER_DETAILS_SUCCESS = '[App] update user details success';
export const UPDATE_USER_DETAILS_FAILURE = '[App] update user details failure';

export class SetCurrentMatter implements Action {
  readonly type = SET_CURRENT_MATTER;

  constructor(public payload: { matter: IMatterListEntry }) {}
}

export class InitApp implements Action {
  readonly type = INIT_APP;
  constructor(public payload: any) {}
}

export class AppDisplayLoading implements Action {
  readonly type = APP_LOADING;
  constructor(public payload: boolean) {}
}

export class AppSetLoadingMessage implements Action {
  readonly type = SET_APP_LOADING_MESSAGE;
  constructor(public payload: string) {}
}


export class AppClearLoadingMessage implements Action {
  readonly type = CLEAR_APP_LOADING_MESSAGE;
  constructor(public payload: any) {}
}


export class AddStaffList implements Action {
  readonly type = ADD_STAFF_LIST;
  constructor(public payload: any) {}
}

export class SetCurrentStaffId implements Action {
  readonly type = SET_CURRENT_STAFF_ID;
  constructor(public payload: { staffId: string }) {}
}

export class LoadStaffSecuritySettingsStart implements Action {
  readonly type = LOAD_CURRENT_STAFF_SECURITY_SETTINGS_START;
  constructor(public payload: any) {}
}

export class LoadStaffSecuritySettingsSuccess implements Action {
  readonly type = LOAD_CURRENT_STAFF_SECURITY_SETTINGS_SUCCESS;
  constructor(public payload: Update<Staff>) {}
}

export class LoadStaffSecuritySettingsFailure implements Action {
  readonly type = LOAD_CURRENT_STAFF_SECURITY_SETTINGS_FAILURE;
  constructor(public payload: any) {}
}
export class AddStaffListSuccess implements Action {
  readonly type = ADD_STAFF_LIST_SUCCESS;
  constructor(public payload: Staff[]) {}
}

export class AddStaffListFailure implements Action {
  readonly type = ADD_STAFF_LIST_FAILURE;
  constructor(public payload: any) {}
}

export class LoadUserPreferenceStart implements Action {
  readonly type = LOAD_USER_PREFERENCE_START;
  constructor(public payload: any) {}
}

export class LoadUserPreferenceSuccess implements Action {
  readonly type = LOAD_USER_PREFERENCE_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadUserPreferenceFailure implements Action {
  readonly type = LOAD_USER_PREFERENCE_FAILURE;
  constructor(public payload: any) {}
}

export class UpdateUserPreferenceStart implements Action {
  readonly type = UPDATE_USER_PREFERENCE_START;
  constructor(public payload: Preference[]) {}
}

export class UpdateUserPreferenceSuccess implements Action {
  readonly type = UPDATE_USER_PREFERENCE_SUCCESS;
  constructor(public payload: Preference[]) {}
}

export class UpdateUserPreferenceFailure implements Action {
  readonly type = UPDATE_USER_PREFERENCE_FAILURE;
  constructor(public payload: any) {}
}

export class LoadUIPreferenceDbStart implements Action {
  readonly type = LOAD_UI_PREFERENCE_DB_START;
  constructor(public payload: any) {}
}

export class LoadUIPreferenceDbSuccess implements Action {
  readonly type = LOAD_UI_PREFERENCE_DB_SUCCESS;
  constructor(public payload: IGlobalUIPreference) {}
}

export class LoadUIPreferenceDbFailure implements Action {
  readonly type = LOAD_UI_PREFERENCE_DB_FAILURE;
  constructor(public payload: any) {}
}

export class SaveUIPreference implements Action {
  readonly type = SAVE_UI_PREFERENCE;
  constructor(public payload: Partial<IGlobalUIPreference>) {}
}

export class SavePreviewUIPreference implements Action {
  readonly type = SAVE_PREVIEW_UI_PREFERENCE;
  constructor(public payload: Partial<IGlobalUIPreference>) {}
}

export class GetMatterCore implements Action {
  readonly type = GET_MATTER_CORE;
  constructor(public payload: { matterId: string }) {}
}

export class GetMatterCoreSuccess implements Action {
  readonly type = GET_MATTER_CORE_SUCCESS;
  constructor(public payload: { matterCore: IMatterCore }) {}
}

export class GetMatterCoreFail implements Action {
  readonly type = GET_MATTER_CORE_FAIL;
  constructor(public payload: any) {}
}

export class SaveMatter implements Action {
  readonly type = SAVE_MATTER;
  constructor(public payload: Partial<IMatterListEntry>) {}
}

export class ClearMatter implements Action {
  readonly type = CLEAR_MATTER;
  constructor(public payload: any) {}
}

export class ClearCache implements Action {
  readonly type = CLEAR_CACHE;
  constructor(public payload: IClearCacheParams) {}
}

export class AppDefault implements Action {
  readonly type = APP_DEFAULT;
  constructor(public payload: any) {}
}

export class AddFirmDetails implements Action {
  readonly type = ADD_FIRM_DETAILS;
  constructor(public payload: { fetchMode?: string; fields?: string[] }) {}
}

export class AddFirmDetailsSuccess implements Action {
  readonly type = ADD_FIRM_DETAILS_SUCCESS;
  constructor(public payload: FirmDetails) {}
}

export class AddFirmDetailsFailure implements Action {
  readonly type = ADD_FIRM_DETAILS_FAILURE;
  constructor(public payload: any) {}
}

export class UpdateFirmConsent implements Action {
  readonly type = UPDATE_FIRM_CONSENT;
  constructor(public payload: boolean) {}
}

export class UpdateUserDetails implements Action {
  readonly type = UPDATE_USER_DETAILS;
  constructor(public payload: any) {}
}

export class UpdateUserDetailsSuccess implements Action {
  readonly type = UPDATE_USER_DETAILS_SUCCESS;
  constructor(public payload: UserInfo) {}
}

export class UpdateUserDetailsFailure implements Action {
  readonly type = UPDATE_USER_DETAILS_FAILURE;
  constructor(public payload: any) {}
}

export class InitialiseEmail implements Action {
  readonly type = INITIALISE_EMAIL;
  constructor(public payload: EmailInitialisationData) {}
}

export class RefreshMatterCache implements Action {
  readonly type = REFRESH_MATTER_CACHE;
  constructor(public payload: string) {}
}

export class SetWalkmeReady implements Action {
  readonly type = SET_WALKME_READY;
  constructor(public payload: { status: boolean }) {}
}

export type All =
  | InitApp
  | AppDisplayLoading
  | AppSetLoadingMessage
  | AppClearLoadingMessage
  | AddStaffList
  | AddStaffListSuccess
  | AddStaffListFailure
  | SetCurrentStaffId
  | LoadStaffSecuritySettingsStart
  | LoadStaffSecuritySettingsSuccess
  | LoadStaffSecuritySettingsFailure
  | AppDefault
  | ClearCache
  | LoadUserPreferenceStart
  | LoadUserPreferenceSuccess
  | LoadUserPreferenceFailure
  | UpdateUserPreferenceStart
  | UpdateUserPreferenceSuccess
  | UpdateUserPreferenceFailure
  | LoadUIPreferenceDbStart
  | LoadUIPreferenceDbSuccess
  | LoadUIPreferenceDbFailure
  | SaveUIPreference
  | SavePreviewUIPreference
  | GetMatterCore
  | GetMatterCoreSuccess
  | GetMatterCoreFail
  | SaveMatter
  | ClearMatter
  | AddFirmDetails
  | AddFirmDetailsSuccess
  | AddFirmDetailsFailure
  | InitialiseEmail
  | SetCurrentMatter
  | RefreshMatterCache
  | SetWalkmeReady
  | UpdateUserDetails
  | UpdateUserDetailsSuccess
  | UpdateUserDetailsFailure
  | UpdateFirmConsent;
