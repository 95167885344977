import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import { LayerOutletName } from '@app/core/models';
import { selectRouterPopupLayerGaps, selectRouterSelectorLayerGaps } from '@app/core/store/selectors';
import { xAnimationRouterLayer } from '@app/shared/animations/animations';

@Component({
  selector: 'sc-proxy-route-center',
  templateUrl: './proxy-route-center.component.html',
  animations: [xAnimationRouterLayer],
})
export class ProxyRouteCenterComponent implements OnInit, OnDestroy {
  @HostBinding('class') classes = 'x-overlay x-overlay-center';
  @HostBinding('@xAnimationRouterLayer')
  @HostBinding('style.zIndex')
  get overLayZIndex(): number {
    return 1040 - this.currentLayerNumber + this.adjustOnlyGlobalIsBased;
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.unsub.next();
  }

  private unsub = new Subject<void>();
  private layerGaps$: Observable<number>;
  private adjustOnlyGlobalIsBased = 0;
  private currentLayerNumber = 0;
  public previousLayerNumber = 0;
  private outletName = '';

  constructor(private _activatedRoute: ActivatedRoute, private _store: Store<any>) {
    this.outletName = this._activatedRoute.snapshot.outlet;
    const children = this._activatedRoute.parent?.children?.map((c) => c.outlet) || [];

    if (children?.length > 1 && children.includes(LayerOutletName.Global)) {
      const globalIndex = children.findIndex((g) => g === LayerOutletName.Global);
      const comingIndex = children.findIndex((c) => c === this.outletName);

      if (comingIndex > globalIndex) {
        this.adjustOnlyGlobalIsBased = comingIndex;
      }
    }

    switch (this.outletName) {
      case LayerOutletName.Popup:
        this.layerGaps$ = this._store.pipe(select(selectRouterPopupLayerGaps));
        break;

      case LayerOutletName.Selector:
        this.layerGaps$ = this._store.pipe(select(selectRouterSelectorLayerGaps));
        break;
    }

    if (!!this.layerGaps$) {
      this.layerGaps$
        .pipe(
          distinctUntilChanged(),
          filter((layerGaps) => layerGaps >= 0),
          takeUntil(this.unsub)
        )
        .subscribe((layerGaps) => {
          this.previousLayerNumber = this.currentLayerNumber;
          this.currentLayerNumber = layerGaps;
        });
    }
  }
}
