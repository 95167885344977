import { createSelector } from '@ngrx/store';

import * as fromFeeTimerActivityCode from '../reducers/fee-timer-activity-code.reducer';
import { State, selectFeeTimer } from '../reducers';
import { ActivityCodesState } from '@app/features/fee-timer/models/fee-timer.model';

export const selectFeeTimerStoreAsActivityCodeBase = createSelector(
  selectFeeTimer,
  (state: State) => state.activityCode
);

export const selectFeeTimerActivityCodeSearchingState = createSelector(
  selectFeeTimerStoreAsActivityCodeBase,
  (state: ActivityCodesState) => fromFeeTimerActivityCode.selectSearchingState(state)
);

export const selectFeeTimerActivityCodeQueryTextState = createSelector(
  selectFeeTimerStoreAsActivityCodeBase,
  (state: ActivityCodesState) => fromFeeTimerActivityCode.selectQueryTextState(state)
);

export const selectFeeTimerActivityCodeSearchCompletedState = createSelector(
  selectFeeTimerStoreAsActivityCodeBase,
  (state: ActivityCodesState) => fromFeeTimerActivityCode.selectSearchCompletedState(state)
);

export const selectFeeTimerActivityCodesState = createSelector(
  selectFeeTimerStoreAsActivityCodeBase,
  (state: ActivityCodesState) => fromFeeTimerActivityCode.selectActivityCodesState(state)
);

export const selectFeeTimerActivityCodesFilteredState = createSelector(
  selectFeeTimerStoreAsActivityCodeBase,
  (state: ActivityCodesState) => fromFeeTimerActivityCode.selectFilteredActivityCodesState(state)
);
