import { createAction, props } from '@ngrx/store';
import { IMatterListEntry, IMatterSearchState } from '../../../models';
import { AdvancedSearchMode, EMattersFilter } from '@app/features/+matter-list/constants';

export interface ICloudSearchPayload {
  searchMode: AdvancedSearchMode;
  data: (IMatterListEntry | string)[];
}

export const searchMattersStart = createAction(
  '[Matter search] start',
  props<{ payload: Partial<IMatterSearchState> }>()
);

export const searchMattersSuccess = createAction(
  '[Matter search] success',
  props<{ payload: any }>()
);

export const searchMattersCloudStart = createAction(
  '[Matter search on cloud] start',
  props<{ payload: EMattersFilter }>()
);

export const searchMattersCloudSuccess = createAction(
  '[Matter search on cloud] success',
  props<{ payload: ICloudSearchPayload }>()
);

export const searchMattersCloudFailure = createAction(
  '[Matter search on cloud] failure',
  props<{ payload: any }>()
);
