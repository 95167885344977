import { Action } from '@ngrx/store';
import { ITaskCode, ITaskCodeUiConfig } from '@app/shared/models';

export const TASK_CODE_SELECTOR_MODAL_SELECT = '[Task Code Selector Modal] select staff';
export const TASK_CODE_SELECTOR_MODAL_SEARCH = '[Task Code Selector Modal] search';
export const TASK_CODE_SELECTOR_MODAL_CLOSE = '[Task Code Selector Modal] close';
export const TASK_CODE_SELECTOR_MODAL_CLEAR = '[Task Code Selector Modal] clear';
export const TASK_CODE_SELECTOR_ADD_TASK_CODES = '[Task Code Selector Modal] add task codes';
export const TASK_CODE_SELECTOR_SET_UI_CONFIG = '[Task Code Selector Modal] set ui config';

export class TaskCodeSelectorModalSelect implements Action {
  readonly type = TASK_CODE_SELECTOR_MODAL_SELECT;
  constructor(public payload: ITaskCode) {}
}

export class TaskCodeSelectorModalSearch implements Action {
  readonly type = TASK_CODE_SELECTOR_MODAL_SEARCH;
  constructor(public payload: string) {}
}

export class TaskCodeSelectorModalClose implements Action {
  readonly type = TASK_CODE_SELECTOR_MODAL_CLOSE;
  constructor(public payload: any) {}
}

export class TaskCodeSelectorModalClear implements Action {
  readonly type = TASK_CODE_SELECTOR_MODAL_CLEAR;
  constructor(public payload: any) {}
}

export class TaskCodeSelectorAddTaskCodes implements Action {
  readonly type = TASK_CODE_SELECTOR_ADD_TASK_CODES;
  constructor(public payload: ITaskCode[]) {}
}

export class TaskCodeSelectorSetUiConfig implements Action {
  readonly type = TASK_CODE_SELECTOR_SET_UI_CONFIG;
  constructor(public payload: ITaskCodeUiConfig) {}
}

export type TaskCodeSelectorModalAction =
  | TaskCodeSelectorModalSelect
  | TaskCodeSelectorModalSearch
  | TaskCodeSelectorModalClose
  | TaskCodeSelectorModalClear
  | TaskCodeSelectorAddTaskCodes
  | TaskCodeSelectorSetUiConfig;
