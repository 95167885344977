import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromCorrespondence from './correspondence.reducer';
import * as fromFolder from './folder.reducer';
import { AppSlice } from '@app/core/store/app.slice';

export interface State {
  correspondence: fromCorrespondence.State;
  folder: fromFolder.State;
}

export const initialState: State = {
  correspondence: fromCorrespondence.initialState,
  folder: fromFolder.initialState,
};

export const reducers: ActionReducerMap<State> = {
  correspondence: fromCorrespondence.reducer,
  folder: fromFolder.reducer,
};

export const selectCorrespondenceState = createFeatureSelector<State>(AppSlice.Correspondence);
