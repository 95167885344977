import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { LogService } from '../log/log.service';
import { IClearCacheParams } from '@app/core/models';
import { BaseService } from '../../../shared/services/base/base.service';

@Injectable()
export class CacheService extends BaseService {
  constructor(private http: HttpClient, private _logSvc: LogService) {
    super();
    _logSvc.init('config-service');
  }

  clearCache(params: IClearCacheParams): Observable<void> {
    const { context, objectId } = params;
    const url = `${this.siriusPath}/api/calc/clearcache?${context}=${objectId}`;
    return this.http.get<void>(url);
  }

  reloadLeapCalc(): Observable<void> {
    const url = `${this.siriusPath}/api/calc/reload`;
    return this.http.get<void>(url);
  }

  refreshMatterCache(matterId: string): Observable<void> {
    const url = `${this.siriusPath}/api/matter/${matterId}/syncMatterCache`;
    return this.http.get<void>(url);
  }
}
