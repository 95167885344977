import { ModuleWithProviders, NgModule, Optional, PLATFORM_ID, SkipSelf } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { environment } from '@env/environment';
import { AuthInterceptor, HttpErrorInterceptor } from './interceptors';
import { TranslateFileLoader } from '@app/shared/utils/translate-file-loader';
import { APP_APIS } from './api';
import { APP_SERVICES } from './services';
import { MAIN_PAGES } from './pages';
import { layoutComponents } from '@app/core/components';
import { Angulartics2Module } from 'angulartics2';
import { SharedModule } from '@app/shared';
import { FeeTimerModule } from '@app/features/fee-timer/fee-timer.module';
import { AppStateModule } from '@app/core/app-state.module';
import { SuperDiaryGuard } from '@app/core/guards/super-diary.guard';
import { UniversalGuard } from './guards/universal.guard';
import { AutoTimeModule } from '@app/features/autotime/autotime.module';
import { RouterModule } from '@angular/router';
import { NgxFloatUiModule } from 'ngx-float-ui';

export const createTranslateLoader = (http: HttpClient, platformId: object) => {
  if (isPlatformBrowser(platformId)) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  } else {
    return new TranslateFileLoader();
  }
};

@NgModule({
  declarations: [...MAIN_PAGES, ...layoutComponents],
  exports: [], imports: [
    /* 3rd party libraries */
    CommonModule,
    SharedModule,
    FeeTimerModule,
    Angulartics2Module,
    AppStateModule,
    AutoTimeModule,
    RouterModule,
    NgxFloatUiModule,
    LoggerModule.forRoot({ level: environment.production ? NgxLoggerLevel.WARN : NgxLoggerLevel.DEBUG }),
    NgxPermissionsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, PLATFORM_ID],
      },
    })], providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...APP_SERVICES,
        ...APP_APIS,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpErrorInterceptor,
          multi: true,
        },
        SuperDiaryGuard,
        UniversalGuard,
      ],
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
