// Enums
export enum AdvancedSearchMode {
  QuickSearch = 1,
  AdvancedSearch = 2,
  AccountingSearch = 3,
  InMemorySearch = 4,
}

export enum ENewMatterMode {
  SAME_TYPE = 1,
  SAME_CLIENT = 2,
}

export enum SearchTermMatchType {
  Partial = 1,
  Exact = 2,
}

export enum EMattersFilter {
  NonArchived = 1,
  Current = 2,
  Recent = 3,
  MyCurrent = 5,
  MyResponsible = 6,
  MyActing = 7,
  MyCredit = 8,
  MyAssisting = 9,
  DebtorBalance = 10,
  GeneralTrust = 11, // General Trust Balances
  TrustInvestment = 12, // Controlled / Investment Balances
  PowerMoney = 18,
  TransitMoney = 19,
  UnbilledTimeAndFees = 13, // Unbilled Time & Fees
  UnbilledCostRecoveries = 14, // Unbilled Cost Recoveries
  UnbilledPayments = 15, // Unbilled Office Payments
  UnbilledJournals = 16, // Unbilled Disbursement Journals
  UnbilledAnticipatedPayments = 17, // Unbilled Anticipated Payments
  AdvancedSearch = 20,
}

// Constants
export const AccountingFilterType: { [matterFilter: number]: number } = {
  [EMattersFilter.DebtorBalance]: 1,
  [EMattersFilter.PowerMoney]: 2,
  [EMattersFilter.TrustInvestment]: 3,
  [EMattersFilter.TransitMoney]: 4,
  [EMattersFilter.GeneralTrust]: 5,
  [EMattersFilter.UnbilledAnticipatedPayments]: 6,
  [EMattersFilter.UnbilledCostRecoveries]: 7,
  [EMattersFilter.UnbilledJournals]: 8,
  [EMattersFilter.UnbilledPayments]: 9,
  [EMattersFilter.UnbilledTimeAndFees]: 10,
};

export const GroupByNone = { id: 1, value: 'Ungroup' };
export const GroupByStatus = { id: 2, value: 'Status' };
export const GroupByClient = { id: 3, value: 'Client' };
export const GroupByStaff = { id: 4, value: 'Staff' };

export const DefaultFields: MatterSearchField[] = [
  'filenumber',
  'firstdesc',
  'mattertype',
  'instructiondate',
  'matterstatus',
  'archivenumber',
];

export const DuplicatedFileNumber = '-1';

// Types
export type MatterListFilterType = 'Divider' | 'Heading' | 'Item' | 'Action';

export type MatterSearchField =
  | 'actinguserid'
  | 'archivenumber'
  | 'assistinguserid'
  | 'credituserid'
  | 'filenumber'
  | 'firmid'
  | 'instructiondate'
  | 'isarchived'
  | 'iscurrent'
  | 'matterstatus'
  | 'responsibleuserid'
  | 'staffintials'
  | 'state'
  | 'mattertype'
  | 'customdesc'
  | 'filenumber'
  | 'firstdesc'
  | 'seconddesc';
