<ng-container *ngIf="{ cardEntry: popupCardEntry$ | async, persons: persons$ | async } as popup">
  <ng-container *ngIf="popup.cardEntry; else loadingBlock">
    <sc-popover-header [headerClass]="'is-' + getIcon(popup.cardEntry.type, popup.persons)">
      <h5 class="card-popover-title">{{ popup.cardEntry.shortName | translate }}</h5>
      <div class="x-card-contact-item" *ngIf="popup.cardEntry.phone">
        <div class="x-card-contact-item-icon d-flex"><sc-icon iconName="phone"></sc-icon></div>
        <div>
          <a href="tel:{{ popup.cardEntry.phone.number }}"> {{ popup.cardEntry.phone.number }} </a>
        </div>
      </div>
      <div class="x-card-contact-item" *ngIf="popup.cardEntry.email">
        <div>
          <a href="javascript:void(0);" (click)="createEmail(popup.cardEntry)"> {{ popup.cardEntry.email.address }} </a>
        </div>
      </div>
      <div class="x-card-contact-item" *ngIf="popup.cardEntry.address">
        <div class="x-card-contact-item-icon align-self-baseline"><sc-icon iconName="place"></sc-icon></div>
        <div>
          <address class="mb-0" [ngClass]="{ 'text-muted': !popup.cardEntry.address }">
            {{ popup.cardEntry.address ? formatAddress(popup.cardEntry.address) : '...' }}
          </address>
          <a
            class="mb-2"
            href="https://www.google.com/maps/place/{{ formatAddress(popup.cardEntry.address) }}"
            target="_blank"
            title="View Map"
            angulartics2On=""
            angularticsAction="View map"
            [angularticsCategory]="getAnalyticsCategory"
            translate
          >
            Address.ViewMap.Label
          </a>
        </div>
      </div>
    </sc-popover-header>

    <sc-popover-body *ngIf="popup.persons && popup.persons.length > 0">
      <div class="x-card-contact-list">
        <sc-sidebar-contact-details
          *ngFor="let person of popup.persons"
          [title]="person.name"
          [subtitle]="person.description"
          [phone]="person.phone"
          [email]="person.email"
          (click)="editCardDetails(popup.cardEntry)"
        >
        </sc-sidebar-contact-details>
      </div>
    </sc-popover-body>

    <sc-popover-footer *ngIf="hasAccessPermissions">
      <sc-btn btnType="default" btnSize="xs"> {{ 'Core.Button.Edit' | translate }} </sc-btn>
    </sc-popover-footer>
  </ng-container>
</ng-container>

<ng-template #loadingBlock>
  <sc-popover-body> <sc-pre-load positionClass="absolute" icon="orb" size="x-icon-xxl"></sc-pre-load> </sc-popover-body>
</ng-template>
