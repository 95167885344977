import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { delayWhen, mergeMap, skipWhile, withLatestFrom } from 'rxjs/operators';

import { selectTableMappings } from '@app/core/store/selectors/app-state.selectors';
import { ECardFilterType, FilterPredicate, ICardFilter, ICardListEntry } from '@app/features/+card/models';
import { TableMapping } from '@app/shared/models';
import * as cardFiltersActions from '../actions/card-filters.actions';
import { pickFirstArg } from '../../../../../server/modules/shared/functions';

type FilterType = { type: ECardFilterType; filters: ICardFilter[] };

@Injectable()
export class CardFiltersEffect {

  configureDefaultCardFilters$ = createEffect(() => this.actions$.pipe(
    ofType(cardFiltersActions.CardFiltersActionTypes.CONFIGURE_DEFAULT_CARD_FILTERS),
    withLatestFrom(this.store.pipe(select(selectTableMappings))),
    mergeMap(([action, mappings]: [cardFiltersActions.ConfigureDefaultCardFilters, TableMapping[]]) => {
      const filters = [];
      filters.push(
        this.newFilterAction(
          ECardFilterType.Standard,
          ['All', 'Matter', 'Client', 'OtherSide', 'OtherSidesSolicitor', 'Supplier', 'People', 'Business', 'Company'],
          mappings
        )
      );

      filters.push(this.newFilterAction(ECardFilterType.Email, ['All Lists', 'Matter', 'Staff', 'Outlook'], mappings));

      filters.push(
        this.newFilterAction(
          ECardFilterType.OfficePayment,
          ['All', 'Client', 'OtherSide', 'OtherSidesSolicitor', 'Supplier'],
          mappings
        )
      );

      filters.push(
        this.newFilterAction(ECardFilterType.CardList, ['All', 'Client', 'OtherSide', 'OtherSidesSolicitor'], mappings)
      );

      filters.push(this.newFilterAction(ECardFilterType.Staff, ['Staff'], mappings));

      filters.push(this.newFilterAction(ECardFilterType.AllStaff, ['Staff'], mappings));

      filters.push(this.newFilterAction(ECardFilterType.CardGuidArray, ['CardGuidArray'], mappings));

      filters.push(this.newFilterAction(ECardFilterType.MSTeams, ['Staff', 'suggestedStaff'], mappings));

      return [new cardFiltersActions.UpdateDefaultCardFilters({ filters })];
    })
  ));


  configureCustomCardFilters$ = createEffect(() => this.actions$.pipe(
    ofType(cardFiltersActions.CardFiltersActionTypes.CONFIGURE_CUSTOM_CARD_FILTERS),
    // delayWhen would ensure that tableMappings data has been stored in redux
    delayWhen(() =>
      this.store.pipe(
        select(selectTableMappings),
        skipWhile((list) => !list || list.length === 0)
      )
    ),
    withLatestFrom(this.store.pipe(select(selectTableMappings))),
    mergeMap(([action, mappings]: [cardFiltersActions.ConfigureCustomCardFilters, TableMapping[]]) => {
      const filters = this.produceFiltersFor(action.payload.types, mappings);
      return [new cardFiltersActions.UpdateCustomCardFilters({ filters })];
    })
  ));

  constructor(private actions$: Actions, private store: Store<any>) {}

  private newFilterAction(type: ECardFilterType, filters: string[], mappings: TableMapping[]): FilterType {
    return {
      type,
      filters: this.produceFiltersFor(filters, mappings),
    } as FilterType;
  }

  private produceFiltersFor(filters: string[], mappings: TableMapping[]): ICardFilter[] {
    const types: string[] = Array.from(new Set(filters?.filter(Boolean)));
    const ofcInvoiceFilter: ICardFilter[] = [];

    types.forEach((type: string): void => {
      const typeLowerCase = type ? type.toLowerCase() : '';
      let typeName = '';
      let typeTableId = '';
      switch (typeLowerCase) {
        case 'all':
        case 'all lists':
          typeName = type;
          break;
        case 'matter':
          typeName = 'Current Matter';
          typeTableId = '1';
          break;
        case 'suggestedstaff':
          typeName = 'Suggested Staff';
          typeTableId = null;
          break;
        case 'cardguidarray':
          typeName = 'Card Guid Array';
          typeTableId = null;
          break;
        default:
          const tableDetail = mappings?.find((tb: TableMapping): boolean => tb.JSName === type);
          typeName = type;
          if (tableDetail) {
            typeName = tableDetail.TableName;
            typeTableId = tableDetail.TableId;
          } else {
            typeTableId = '<tableid not found in store>';
          }
          break;
      }

      ofcInvoiceFilter.push(this.mapToFilterObject(typeTableId, typeName, type, pickFirstArg));
    });

    return ofcInvoiceFilter;
  }

  private mapToFilterObject(
    id: string,
    name: string,
    type: string,
    predicate: FilterPredicate<ICardListEntry>,
    value?: string
  ): ICardFilter {
    return {
      id,
      name,
      type,
      value: value || name.toLowerCase(),
      predicate,
    } as ICardFilter;
  }
}
