import { Component, HostBinding } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import has = Reflect.has;

@Component({
  selector: 'sc-footer-cell',
  templateUrl: './footer-cell.component.html',
})
export class FooterCellComponent implements AgRendererComponent {
  content: string;
  isPrimaryFooter: boolean;
  isHidden: boolean;
  isTitle: boolean;
  wrapperClass: string;
  iconButton: boolean;

  constructor() {}

  @HostBinding('class')
  classes;

  agInit(params: any): void {
    const rowObj = params.data;
    this.isPrimaryFooter = has(params, 'getIsPrimaryFooter') ? params.getIsPrimaryFooter(params.data) : false;
    const defaultClass = this.isPrimaryFooter ? 'x-ag-footer-primary' : 'x-ag-footer';
    this.wrapperClass = has(params, 'getWrapperClass') ? params.getWrapperClass(params.data) : '';
    this.classes = defaultClass + ' ' + this.wrapperClass;
    this.isHidden = has(params, 'getIsHidden') ? params.getIsHidden(params.data) : false;
    this.isTitle = has(params, 'getIsTitle') ? params.getIsTitle(params.data) : false;
    this.content = has(params, 'getContent') ? params.getContent(params.data) : '';
    this.iconButton = has(params, 'iconButton') ? params.iconButton(params.data) : false;
  }

  refresh(params: any): boolean {
    return true;
  }
}
