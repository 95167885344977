import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'sc-main-aside',
  templateUrl: './main-aside.component.html',
})
export class MainAsideComponent implements OnInit {
  constructor() {}

  @HostBinding('class.lt-aside')
  ngOnInit() {}
}
