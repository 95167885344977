import { Injectable } from '@angular/core';
import { NavigateToNextCellParams, CellPosition } from '@ag-grid-community/core';

@Injectable()
export class AgGridNavigationService {

  constructor() { }

  tabToNextCell(params: NavigateToNextCellParams): CellPosition | null {
    const previousCell = params.previousCellPosition;
    const lastRowIndex = params.api.getDisplayedRowCount() - 1;

    if (previousCell.rowIndex < lastRowIndex) {
      return {
        rowIndex: previousCell.rowIndex + 1,
        column: previousCell.column,
        rowPinned: previousCell.rowPinned
      };
    } else {
      return null;
    }
  }
}
