import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Location } from '@angular/common';
import { exhaustMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Angulartics2GoogleTagManager } from 'angulartics2';

import * as RouterActions from '../actions/router.action';
import { LogService } from '@app/core/services';
import { StaffService } from '@app/core/services/staff/staff.service';
import { select, Store } from '@ngrx/store';
import { selectRouterLayers, selectRouterSnapshot, selectRouterUrl } from '../selectors';
import { OutletSeparator } from '@app/core/constants';
import { firstItemInArray } from '../../../../../server/modules/shared/functions/common-util.functions';

@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location,
    private staffSvc: StaffService,
    private _log: LogService,
    private _analytics: Angulartics2GoogleTagManager,
    private _store: Store<any>
  ) {
    this._log.init('router-effects');
  }


  navigate$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.GO),
    map((action: RouterActions.Go) => action.payload),
    tap(({ path, query: queryParams, extras }) => {
      const encodedPath = path?.map((p) => (typeof p === 'string' ? encodeURIComponent(p) : p)) || path;
      this.router.navigate(encodedPath, { queryParams, ...extras } as NavigationExtras);
    })
  ), { dispatch: false });


  navigateByUrl$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.GO_BY_URL),
    map((action: RouterActions.GoByUrl) => action.payload),
    tap(({ path }) => {
      this.router.navigateByUrl(path);
    })
  ), { dispatch: false });


  navigateClear$ = createEffect(() => this.actions$.pipe(
    ofType<RouterActions.Clear>(RouterActions.CLEAR),
    withLatestFrom(this._store.pipe(select(selectRouterSnapshot)), (action, routeSnapshot) => ({
      action,
      routeSnapshot,
    })),
    tap((data) => {
      const { action, routeSnapshot } = data;
      if (action.payload && action.payload.outlets && action.payload.outlets.length > 0) {
        const outlets = {};
        for (const outlet of action.payload.outlets) {
          outlets[outlet] = null;
        }

        // when there is only one outlet ('primary'), we don't preserve the queryParam
        const currentOutletsAmount =
          !!routeSnapshot.children && !!routeSnapshot.children.length ? routeSnapshot.children.length : 0;
        const clearOutletsAmount = Object.keys(outlets).length;

        const queryParamsHandling = action.payload.excludeQueryParam
          ? null
          : currentOutletsAmount - clearOutletsAmount > 1
          ? 'preserve'
          : '';

        this.router.navigate([{ outlets }], {
          queryParamsHandling,
          skipLocationChange: action.payload.skipLocationChange,
        });
      } else {
        // Providing a `null` value to the named outlet
        // clears the contents of the named outlet
        // We don't set popupGlobal to null, as we open search-transaction page on popupGlobal.
        // Each transaction open from search-transaction would use navigateClear() to close their transaction page.
        // If we set popupGlobal to null here, we would close the search-transaction page as well.
        this.router.navigate([{ outlets: { popup: null, selector: null, overlay: null, selectorDetail: null } }]);
      }
    })
  ), { dispatch: false });


  clearCurrentModal$ = createEffect(() => this.actions$.pipe(
    ofType<RouterActions.ClearCurrentModal>(RouterActions.CLEAR_CURRENT_MODAL),
    withLatestFrom(this._store.pipe(select(selectRouterLayers)), (action, routerLayers) => ({
      payload: action.payload,
      outletName: firstItemInArray(routerLayers),
    })),
    exhaustMap(({ payload, outletName }) => {
      if (payload?.excludeQueryParam || payload?.skipLocationChange) {
        return [
          new RouterActions.Clear({
            outlets: [outletName],
            excludeQueryParam: payload.excludeQueryParam,
            skipLocationChange: payload.skipLocationChange,
          }),
        ];
      }

      return [new RouterActions.Clear({ outlets: [outletName] })];
    })
  ));


  navigateForward$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.FORWARD),
    tap(() => this.location.forward())
  ), { dispatch: false });


  navigateBack$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.BACK),
    withLatestFrom(this._store.pipe(select(selectRouterUrl)), (action, url) => url),
    tap((url: string) => {
      const regex = /\((?:.*?)\)/gm;
      const matches = regex.exec(url);
      const multiModalUrl = matches?.find((match) => match.split(OutletSeparator).length > 1);
      if (!!multiModalUrl) {
        this.location.back();
      } else {
        this.router.navigate([{ outlets: { popupGlobal: null, popup: null, selector: null, overlay: null } }]);
      }
    })
  ), { dispatch: false });
}
