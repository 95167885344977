<h6 class="text-uppercase mb-3">Recipient Details</h6>
<div class="bg-white border p-5 shadow-sm">
  <ng-container *ngIf="address && cardId; else noResultBlock">
    <div class="d-flex justify-content-center align-items-center">
      <sc-card-type-circle ctName="user" [ctSize]="'x-icon-xxxl is-lg'"></sc-card-type-circle>
    </div>
    <div class="text-center mt-4">
      <h4 class="text-primary">{{ fullName }}</h4>
      <div>
        {{ address }}
      </div>
    </div>
  </ng-container>

  <ng-template #noResultBlock>
    <sc-no-results-overlay [params]="noResultMessage(cardId)" class="h-100"> </sc-no-results-overlay>
  </ng-template>
</div>
