import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { IMatterListEntry } from '@app/features/+matter-list/models';
import {
  ColDef,
  GridApi,
  GridOptions,
  ICellRendererParams,
  IsFullWidthRowParams,
  RowNode,
  TabToNextCellParams,
} from '@ag-grid-community/core';
import { CellPosition } from '@ag-grid-community/core';
import {
  AmountEditorComponent,
  CurrencyDisplayComponent,
  FooterCellComponent,
  IconCellComponent,
  MatterEditorComponent,
  ScTextCellEditorComponent,
  TextInfoCellComponent,
  TextPrimaryCellComponent,
} from '@app/sharedaggrid/components';
import { ITrustReceiptItem, ITrustReceiptItemsTransaction } from '@app/features/+trust-receipt/models';
import { TrustReceiptSummaryCellComponent } from '@app/features/+trust-receipt/components/ag-grid';
import { ActionRowId, TotalRowId } from '@app/sharedaggrid/constants';
import { ScCurrencyPipe } from '@app/shared/pipes';
import { isObjEqual, sumBy } from '@server/modules/shared/functions/common-util.functions';
import { AgGridNavigationService } from '@app/shared/services';

@Component({
  selector: 'sc-trust-receipt-list',
  templateUrl: './trust-receipt-list.component.html',
})
export class TrustReceiptListComponent implements OnInit {
  @Input()
  isNew: boolean;
  @Input()
  isReversal: boolean;
  @Input()
  matterList: IMatterListEntry[];

  @Input()
  set receiptItems(value: ITrustReceiptItem[]) {
    if (!!value && value.length > 0) {
      this.rowCollection = [...value];
      this.rebuildPinnedRows();
    }
  }

  @Output()
  onNewItemsTransaction = new EventEmitter<ITrustReceiptItemsTransaction>();

  public rowCollection: ITrustReceiptItem[] = [];
  public gridOptions: GridOptions;
  public gridApi: GridApi;
  private _pinnedBottomData: any[];
  private _updateAndAdd = false;
  private _shouldEditLastRow = false;
  private _shouldEditNextRow = false;
  private _editingRowData: ITrustReceiptItem;

  public get isGridRendered(): boolean {
    return !!this.gridOptions && !!this.gridApi;
  }

  public onGridReady(params: { api: GridApi }) {
    this.gridApi = params.api;
    if (this.isGridRendered) {
      this.gridApi.sizeColumnsToFit();
      this.rebuildColumns();
      this.rebuildPinnedRows();
    }
  }

  constructor(private _scCurrencyPipe: ScCurrencyPipe, private _agGridNavigationService: AgGridNavigationService) {
    this.gridOptions = {} as GridOptions;
    this._pinnedBottomData = this.buildPinnedBottomData();
  }

  ngOnInit() {
    this.gridOptions = this.getGridOptions();
    this.gridOptions.columnDefs = this.buildColumns();
  }

  getGridOptions(): GridOptions {
    return {
      headerHeight: 37,
      domLayout: 'autoHeight',
      editType: 'fullRow',
      singleClickEdit: true,
      getContextMenuItems: () => [],
      stopEditingWhenCellsLoseFocus: true,
      suppressMovableColumns: true,
      isFullWidthRow: (r: IsFullWidthRowParams) =>
        r.rowNode.data.TrustReceiptItemGUID === ActionRowId && !!r.rowNode.rowPinned,
      fullWidthCellRenderer: TrustReceiptSummaryCellComponent,
      fullWidthCellRendererParams: {
        isNewLineHidden: !this.isNew,
        onNewLineClicked: () => this.onNewItemsTransaction.emit({ list: this.rowCollection, type: 'add' }),
      },
      pinnedBottomRowData: this._pinnedBottomData,
      getRowHeight: (params) => (!!params.node.rowPinned ? (this.isNew ? 46 : 38) : 56),
      onCellClicked: (params) => {
        if (params.column.getColId() === 'remove') {
          this.onNewItemsTransaction.emit({ list: this.rowCollection, type: 'remove', item: params.node.data });
        }
      },
      onRowEditingStarted: (params) => {
        this._editingRowData = { ...params.data };
      },
      onRowEditingStopped: (params) => {
        if (!isObjEqual(this._editingRowData, params.data) || this._updateAndAdd) {
          this.onNewItemsTransaction.emit({
            list: this.gridApi.getRenderedNodes()?.map((m) => m.data) || [],
            item: params.data,
            type: this._updateAndAdd ? 'updateAndAdd' : 'update',
          });
        }
        this._editingRowData = null;
        this._updateAndAdd = false;
      },
      onCellFocused: (params) => {
        const rowNode = this.gridApi.getDisplayedRowAtIndex(params.rowIndex);
        if (!!rowNode && !rowNode.rowPinned) {
          this.gridApi.startEditingCell({
            rowIndex: params.rowIndex,
            colKey: 'MatterFileNo',
          });
        }
      },
      onRowDataUpdated: () => {
        this.resumeEditing();
      },
      tabToNextCell: this._agGridNavigationService.tabToNextCell.bind(this._agGridNavigationService),
    };
  }

  buildColumns(): ColDef[] {
    return [
      {
        headerName: 'Matter No.',
        field: 'MatterFileNo',
        colId: 'MatterFileNo',
        maxWidth: 150,
        minWidth: 150,
        cellRendererSelector: (params: ICellRendererParams) => {
          if (params.node.rowPinned) {
            return {
              component: FooterCellComponent,
              params: {
                getIsPrimaryFooter: (row: ITrustReceiptItem) => row.TrustReceiptItemGUID === TotalRowId,
                getContent: (row) => row.Reason,
              },
            };
          }
          return {
            component: TextPrimaryCellComponent,
            params: {
              getName: (row) => row.MatterFileNo,
            },
          };
        },
        editable: (params) => this.isNew && !params.node.rowPinned,
        cellEditor: MatterEditorComponent,
        cellEditorParams: {
          isDefaultFocus: true,
          matterList: () => this.matterList,
        },
        valueSetter: (params) => {
          if (typeof params.newValue === 'string') {
            const matterEntry = this.matterList.find((matter) => matter.fileNumber === params.newValue);
            params.node.setData({
              ...params.data,
              MatterFileNo: params.newValue,
              MatterGUID: matterEntry.matterId,
            });
            return true;
          }
          return false;
        },
        colSpan: (params) => (params.node.rowPinned ? 2 : 1),
        cellClass: (params) => `${params.node.rowPinned ? 'is-right' : ''}`,
      },
      {
        headerName: 'Reason',
        field: 'Reason',
        colId: 'Reason',
        editable: (params) => (this.isNew || this.isReversal) && !params.node.rowPinned,
        cellEditor: ScTextCellEditorComponent,
        cellEditorParams: {},
        cellRendererSelector: (params: ICellRendererParams) => {
          if (params.node.rowPinned) {
            return {
              component: FooterCellComponent,
              params: {
                getIsPrimaryFooter: (row: ITrustReceiptItem) => row.TrustReceiptItemGUID === TotalRowId,
              },
            };
          }
          return {
            component: TextInfoCellComponent,
            params: {
              getContent: (row) => row.Reason,
            },
          };
        },
      },
      {
        headerName: 'Amount',
        field: 'Amount',
        colId: 'Amount',
        maxWidth: 180,
        minWidth: 180,
        headerClass: 'is-centered x-border-left-dotted',
        cellClass: (params) => `is-right ${params.node.rowPinned ? '' : 'x-border-left-dotted'}`,
        cellRendererSelector: (params: ICellRendererParams) => {
          if (params.node.rowPinned) {
            return {
              component: FooterCellComponent,
              params: {
                getIsPrimaryFooter: (row: ITrustReceiptItem) => row.TrustReceiptItemGUID === TotalRowId,
                getContent: (row) => this._scCurrencyPipe.transform(row.Amount),
              },
            };
          }
          return {
            component: CurrencyDisplayComponent,
            params: {
              getamount: (row) => (!!row.Amount ? row.Amount : 0),
            },
          };
        },
        editable: (params) => this.isNew && !params.node.rowPinned,
        cellEditor: AmountEditorComponent,
        cellEditorParams: (params) => ({
          OutOfBalance: 0,
          focus: false,
        }),
      },
      {
        hide: !this.isNew,
        maxWidth: 49,
        minWidth: 49,
        headerName: '',
        field: 'remove',
        colId: 'remove',
        cellClass: (params) => {
          const borderClass = !params.node.rowPinned ? 'x-border-left-dotted' : '';
          return 'is-centered ' + borderClass;
        },
        cellRendererSelector: (params: ICellRendererParams) => {
          if (params.node.rowPinned) {
            return {
              component: FooterCellComponent,
              params: {
                getIsPrimaryFooter: (row: ITrustReceiptItem) => row.TrustReceiptItemGUID === TotalRowId,
              },
            };
          }
          return {
            component: IconCellComponent,
            params: {
              getIconName: () => 'bin-grid-20',
              getIconHidden: () => this.rowCollection.length < 2,
            },
          };
        },
      },
    ];
  }

  rebuildColumns(): void {
    if (this.gridOptions && this.gridApi) {
      // Aggrid cannot repaint until the full grid is constructed.
      // Set grid repaint job to the next event loop.
      setTimeout(() => {
        this.gridApi.setGridOption('columnDefs', this.buildColumns());
        this.gridApi.sizeColumnsToFit();
        this.gridApi.hideOverlay();
      }, 0);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.rebuildColumns();
    this.rebuildPinnedRows();
  }

  private buildPinnedBottomData(): Partial<ITrustReceiptItem>[] {
    return [
      ...(this.isNew ? [{ TrustReceiptItemGUID: ActionRowId }] : []),
      {
        TrustReceiptItemGUID: TotalRowId,
        Reason: 'Total',
        Amount: this.rowCollection.length > 0 ? sumBy(this.rowCollection, 'Amount') : 0,
      },
    ];
  }

  private rebuildPinnedRows() {
    if (this.isGridRendered) {
      this._pinnedBottomData = this.buildPinnedBottomData();
      this.gridApi.setGridOption('pinnedBottomRowData', this._pinnedBottomData);
    }
  }

  private resumeEditing() {
    if (!this.isGridRendered || !this._shouldEditNextRow) {
      return;
    }
    const focusedCell = this.gridApi.getFocusedCell();
    if (focusedCell && !this._shouldEditLastRow) {
      this.gridApi.startEditingCell({ rowIndex: focusedCell.rowIndex, colKey: 'MatterFileNo' });
      this._shouldEditNextRow = false;
    } else {
      const lastRowIndex = this.gridApi.getLastDisplayedRowIndex();
      this.gridApi.startEditingCell({ rowIndex: lastRowIndex, colKey: 'MatterFileNo' });
      this._shouldEditNextRow = false;
    }

    this.rebuildPinnedRows();
  }
}
