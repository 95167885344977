import * as cardDetailsPopupActions from '../actions/card-details-popup';
import { ICardListEntry, IPersonEntry } from '@app/features/+card/models';
import { IMatterListEntry } from '@app/features/+matter-list/models';

export interface State {
  loading: boolean;
  popupCardEntry: ICardListEntry;
  popupCardEntryPersons: IPersonEntry[];
  popupCardEntryMatters: IMatterListEntry[];
}

export const INITIAL_STATE: State = {
  loading: false,
  popupCardEntry: null,
  popupCardEntryPersons: [],
  popupCardEntryMatters: [],
};

export const reducer = (state = INITIAL_STATE, action: cardDetailsPopupActions.CardDetailsPopupActions) => {
  switch (action.type) {
    case cardDetailsPopupActions.CardDetailsPopupActionTypes.UPDATE_POPUP_CARD_INFORM_START:
      return {
        ...state,
        loading: true,
      };

    case cardDetailsPopupActions.CardDetailsPopupActionTypes.UPDATE_POPUP_CARD_INFORM:
      return {
        ...state,
        loading: false,
        popupCardEntry: action.payload.cardEntry,
        popupCardEntryPersons: action.payload.persons,
        popupCardEntryMatters: action.payload.matters,
      };

    case cardDetailsPopupActions.CardDetailsPopupActionTypes.REST_POPUP_CARD_INFORM:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const getPopupCardEntry = (state: State) => state.popupCardEntry;
export const getPopupCardEntryPersons = (state: State) => state.popupCardEntryPersons;
export const getPopupCardEntryMatters = (state: State) => state.popupCardEntryMatters;
