import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '@app/shared/services';
import { LogService } from '@app/core/services';
import { OutlookContactResponse } from '../../models';

@Injectable()
export class ContactService extends BaseService {
  private url_: string;

  constructor(private _http: HttpClient, private _log: LogService) {
    super();
    this.url_ = `${this.calendarPath}/contacts`;
  }

  getStaffEmail(email: string): Observable<OutlookContactResponse> {
    const queryEmail = email ? `?email=${email}` : undefined;

    return this._http.get<OutlookContactResponse>(this.urlJoin(this.url_, queryEmail));
  }
}
