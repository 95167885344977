export const DebtorClass = 'DebtorForAccounting';

export const TableConfigIds = {
  CLIENT: '3',
  OTHER_SIDE: '7',
  OTHER_SIDE_SOLICITOR: '4',
  OTHER_SIDE_INSURER: '193',
};

export const MatterDetailsFetchMode = {
  Cacheable: 'cacheable',
  Force: 'force',
};

// Hard code - Order policies
export const OrderCalculationPolicies = {
  SameTypeRange: 1000,
  DifferentTypeRange: 1000000,
};

export enum MultipleSelectionContextMenuActions {
  'NONE' = 0,
  'LAWCONNECT_SHARE' = 1,
  'CREATE_PDF' = 2,
  'IFRAME_LAWCONNECT_DOCUMENT_SHARE' = 3,
  'IFRAME_LAWCONNECT_REQUEST_ESIGNATURE' = 4
}
