import { Injectable } from '@angular/core';
import { IPaymentDetail } from '@app/features/+payments-debtors/models';
import { getDate } from '@app/shared/utils/date.helper';
import { format, getMonth, getYear } from 'date-fns';
import { PaymentTypeId } from '../../constants';

export interface IPaymentTypeParser {
  get: (paymentDetails: any, paymentTypeId: number, paymentSubTypeId?: number) => IPaymentDetail;
  // reset: () => void; // TODO
  parse: (payment: IPaymentDetail, paymentId: number, paymentSubTypeId?: number) => any;
  hasPaymentDetail: (paymentDetails: any, paymentId: number, paymentSubTypeId?: number) => boolean;
}

@Injectable()
export class OutflowPaymentTypeParserService implements IPaymentTypeParser {
  paymentTypeDetails: any;

  constructor() {}

  get(paymentDetails: any, paymentTypeId: number, paymentSubTypeId: number): IPaymentDetail {
    let authNumber: string;
    let addressee: string;
    let bsb: string;
    let accountNumber: string;
    let accountName: string;
    let drawer: string;
    let chequeNumber: string;
    let expiryDate: string;
    let notes: number;
    let coins: number;
    let memo: string;
    let billerCode: string;
    let billerReference: string;

    if (paymentTypeId === undefined || !paymentDetails) {
      return null;
    }

    switch (paymentTypeId) {
      case PaymentTypeId.Cash: // cash
        notes = paymentDetails.cashNotes;
        coins = paymentDetails.cashCoins;
        memo = paymentDetails.cashMemo;
        return {
          Notes: notes,
          Coins: coins,
          Memo: memo,
        } as IPaymentDetail;
      case PaymentTypeId.CreditCard: // credit card
        accountName = paymentDetails.ccName;
        expiryDate = paymentDetails.ccExpiryMonth + '/' + paymentDetails.ccExpiryYear;
        authNumber = paymentDetails.ccAuthCode;
        memo = paymentDetails.ccMemo;
        return {
          AccountName: accountName,
          ExpiryDate: expiryDate,
          AuthorisationNumber: authNumber,
          Memo: memo,
        } as IPaymentDetail;
      case PaymentTypeId.CashiersCheque: // bank cheque
        bsb = paymentDetails.bankChequeBsb;
        accountNumber = paymentDetails.bankChequeAccountNumber;
        accountName = paymentDetails.bankChequeAccountName;
        addressee = paymentDetails.bankChequeDrawer || paymentDetails.bankChequePayable;
        drawer = paymentDetails.bankChequeDrawer || paymentDetails.bankChequePayable;
        chequeNumber = paymentDetails.bankChequeChequeNumber;
        memo = paymentDetails.bankChequeMemo;
        return {
          BSB: bsb,
          AccountNumber: accountNumber,
          AccountName: accountName,
          Addressee: addressee,
          Drawer: drawer,
          ChequeNumber: chequeNumber,
          Memo: memo,
        } as IPaymentDetail;
      case PaymentTypeId.Cheque: // personal cheque
        bsb = paymentDetails.personalChequeBsb;
        accountNumber = paymentDetails.personalChequeAccountNumber;
        accountName = paymentDetails.personalChequeAccountName;
        drawer = paymentDetails.personalChequeDrawer;
        chequeNumber = paymentDetails.personalChequeChequeNumber;
        memo = paymentDetails.personalChequeMemo;
        return {
          BSB: bsb,
          AccountNumber: accountNumber,
          AccountName: accountName,
          Drawer: drawer,
          ChequeNumber: chequeNumber,
          Memo: memo,
        } as IPaymentDetail;
      case PaymentTypeId.Wire: // EFT
        // BPay
        if (paymentSubTypeId === 1) {
          billerCode = paymentDetails.bpayBillerCode;
          billerReference = paymentDetails.bpayBillerReference;
          memo = paymentDetails.bpayMemo;
          return {
            BillerCode: billerCode,
            BillerReference: billerReference,
            Memo: memo,
          } as IPaymentDetail;
        }

        bsb = paymentDetails.eftBsb;
        accountNumber = paymentDetails.eftAccountNumber;
        accountName = paymentDetails.eftAccountName;
        drawer = paymentDetails.eftPayable;
        memo = paymentDetails.eftMemo;

        return {
          Memo: memo,
          BSB: bsb,
          AccountNumber: accountNumber,
          AccountName: accountName,
          Addressee: drawer,
        } as IPaymentDetail;
    }
  }

  parse(payment: IPaymentDetail, paymentId: number, paymentSubTypeId: number): any {
    if (paymentId === undefined || !payment) {
      return;
    }

    switch (paymentId) {
      case PaymentTypeId.Cash: // cash
        return {
          cashMemo: payment.Memo,
        };
      case PaymentTypeId.CreditCard: // credit card
        return {
          ccMemo: payment.Memo,
        };
      case PaymentTypeId.CashiersCheque: // bank cheque
        return {
          bankChequePayable: payment.Addressee || payment.Drawer,
          bankChequeMemo: payment.Memo,
        };
      case PaymentTypeId.Cheque: // personal cheque
        return {
          personalChequeMemo: payment.Memo,
        };
      case PaymentTypeId.Wire: // EFT
        // BPay
        if (paymentSubTypeId === 1) {
          return {
            bpayBillerCode: payment.BillerCode,
            bpayBillerReference: payment.BillerReference,
            bpayMemo: payment.Memo,
          };
        }

        return {
          eftBsb: payment.BSB,
          eftAccountNumber: payment.AccountNumber,
          eftAccountName: payment.AccountName,
          eftPayable: payment.Addressee,
          eftMemo: payment.Memo,
        };
    }
  }

  hasPaymentDetail(paymentDetails: any, paymentId: number, paymentSubTypeId: number): boolean {
    if (paymentId === undefined || paymentId === null || !paymentDetails) {
      return false;
    }

    switch (paymentId) {
      case PaymentTypeId.Cash: // cash
        return !!paymentDetails.cashMemo || !!paymentDetails.Memo;
      case PaymentTypeId.CreditCard: // credit card
        return !!paymentDetails.ccMemo || !!paymentDetails.Memo;
      case PaymentTypeId.CashiersCheque: // bank cheque
        return (
          !!paymentDetails.bankChequePayable ||
          !!paymentDetails.bankChequeMemo ||
          !!paymentDetails.Addressee ||
          !!paymentDetails.Memo
        );
      case PaymentTypeId.Cheque: // personal cheque
        return !!paymentDetails.personalChequeMemo || !!paymentDetails.Memo;
      case PaymentTypeId.Wire: // EFT
        // BPay
        if (paymentSubTypeId === 1) {
          return (
            !!paymentDetails &&
            !!(
              paymentDetails.bpayBillerCode ||
              paymentDetails.bpayBillerReference ||
              paymentDetails.bpayMemo ||
              paymentDetails.BillerCode ||
              paymentDetails.BillerReference ||
              paymentDetails.Memo
            )
          );
        }

        return (
          !!paymentDetails.eftBsb ||
          !!paymentDetails.BSB ||
          !!paymentDetails.eftAccountNumber ||
          !!paymentDetails.AccountNumber ||
          !!paymentDetails.eftAccountName ||
          !!paymentDetails.AccountName ||
          !!paymentDetails.eftPayable ||
          !!paymentDetails.Addressee ||
          !!paymentDetails.eftMemo ||
          !!paymentDetails.Memo
        );
    }
  }
}

@Injectable()
export class InflowPaymentTypeParserService implements IPaymentTypeParser {
  paymentTypeDetails: any;

  constructor() {}

  get(paymentDetails: any, paymentTypeId: number): IPaymentDetail {
    if (paymentTypeId === undefined || paymentTypeId === null || !paymentDetails) {
      return null;
    }

    switch (paymentTypeId) {
      case PaymentTypeId.Cash: // cash
        return {
          Notes: paymentDetails.cashNotes,
          Coins: paymentDetails.cashCoins,
          Memo: !!paymentDetails.cashMemo ? paymentDetails.cashMemo : '',
        } as IPaymentDetail;
      case PaymentTypeId.CreditCard: // credit card
        return {
          AccountName: paymentDetails.ccName,
          ExpiryDate: format(getDate(paymentDetails.ccExpiryDate), 'M/yyyy'),
          AuthorisationNumber: paymentDetails.ccAuthCode,
          Memo: !!paymentDetails.ccMemo ? paymentDetails.ccMemo : '',
        } as IPaymentDetail;
      case PaymentTypeId.CashiersCheque: // bank cheque
        return {
          BSB: paymentDetails.bankChequeBsb,
          AccountNumber: paymentDetails.bankChequeAccountNumber,
          AccountName: paymentDetails.bankChequeAccountName,
          Drawer: paymentDetails.bankChequeDrawer,
          ChequeNumber: paymentDetails.bankChequeChequeNumber,
          Memo: !!paymentDetails.bankChequeMemo ? paymentDetails.bankChequeMemo : '',
        } as IPaymentDetail;
      case PaymentTypeId.Cheque: // personal cheque
        return {
          BSB: paymentDetails.personalChequeBsb,
          AccountNumber: paymentDetails.personalChequeAccountNumber,
          AccountName: paymentDetails.personalChequeAccountName,
          Drawer: paymentDetails.personalChequeDrawer,
          ChequeNumber: paymentDetails.personalChequeChequeNumber,
          Memo: !!paymentDetails.personalChequeMemo ? paymentDetails.personalChequeMemo : '',
        } as IPaymentDetail;
      case PaymentTypeId.Wire: // EFT
        // BPay (paymentSubTypeId === 1) reuse eftMemo without creating others props e.g. BillerCode & BillerReference.

        return {
          Memo: !!paymentDetails.eftMemo ? paymentDetails.eftMemo : '',
          BSB: !!paymentDetails.eftBsb ? paymentDetails.eftBsb : '',
          AccountNumber: !!paymentDetails.eftAccountNumber ? paymentDetails.eftAccountNumber : '',
          AccountName: !!paymentDetails.eftAccountName ? paymentDetails.eftAccountName : '',
          Addressee: !!paymentDetails.eftPayable ? paymentDetails.eftPayable : '',
        } as IPaymentDetail;
    }
  }

  parse(payment: IPaymentDetail, paymentTypeId: number): any {
    if (paymentTypeId === null || paymentTypeId === undefined || !payment) {
      return;
    }

    switch (paymentTypeId) {
      case PaymentTypeId.Cash: // cash
        return {
          cashNotes: payment.Notes,
          cashCoins: payment.Coins,
          cashMemo: payment.Memo,
        };
      case PaymentTypeId.CreditCard: // credit card
        const expiryDate = getDate(payment.ExpiryDate);
        if (expiryDate) {
          return {
            ccExpiryDate: expiryDate, // this prop required for default p-calendar display.
            ccName: payment.AccountName,
            ccExpiryMonth: getMonth(expiryDate),
            ccExpiryYear: getYear(expiryDate),
            ccAuthCode: payment.AuthorisationNumber,
            ccMemo: payment.Memo,
          };
        }

        return {
          ccName: payment.AccountName,
          ccExpiryMonth: '',
          ccExpiryYear: '',
          ccAuthCode: payment.AuthorisationNumber,
          ccMemo: payment.Memo,
        };
      case PaymentTypeId.CashiersCheque: // bank cheque
        return {
          bankChequeBsb: payment.BSB,
          bankChequeAccountNumber: payment.AccountNumber,
          bankChequeAccountName: payment.AccountName,
          bankChequeDrawer: payment.Drawer,
          bankChequeChequeNumber: payment.ChequeNumber,
          bankChequeMemo: payment.Memo,
        };
      case PaymentTypeId.Cheque: // personal cheque
        return {
          personalChequeBsb: payment.BSB,
          personalChequeAccountNumber: payment.AccountNumber,
          personalChequeAccountName: payment.AccountName,
          personalChequeDrawer: payment.Drawer,
          personalChequeChequeNumber: payment.ChequeNumber,
          personalChequeMemo: payment.Memo,
        };
      case PaymentTypeId.Wire: // EFT
        // BPay (paymentSubTypeId === 1) reuse eftMemo without creating others props e.g. BillerCode & BillerReference.

        return {
          eftMemo: payment.Memo,
          eftBsb: payment.BSB,
          eftAccountNumber: payment.AccountNumber,
          eftAccountName: payment.AccountName,
          eftPayable: payment.Addressee,
        };
    }
  }

  hasPaymentDetail(paymentDetails: any, paymentTypeId: number): boolean {
    if (paymentTypeId === undefined || !paymentDetails) {
      return false;
    }

    switch (paymentTypeId) {
      case PaymentTypeId.Cash: // cash
        return (
          !!paymentDetails.cashNotes ||
          !!paymentDetails.Notes ||
          !!paymentDetails.cashMemo ||
          !!paymentDetails.Memo ||
          !!paymentDetails.cashCoins ||
          !!paymentDetails.Coins
        );
      case PaymentTypeId.CreditCard: // credit card
        return !!paymentDetails.ccName || !!paymentDetails.AccountName;
      case PaymentTypeId.CashiersCheque: // bank cheque
      case PaymentTypeId.Cheque: // personal cheque
      case PaymentTypeId.Wire: // EFT
        // BPay (paymentSubTypeId === 1) reuse eftMemo without creating others props e.g. BillerCode & BillerReference.

        return (
          !!paymentDetails.eftBsb ||
          !!paymentDetails.bankChequeBsb ||
          !!paymentDetails.personalChequeBsb ||
          !!paymentDetails.BSB ||
          !!paymentDetails.eftAccountNumber ||
          !!paymentDetails.personalChequeAccountNumber ||
          !!paymentDetails.AccountNumber ||
          !!paymentDetails.eftAccountName ||
          !!paymentDetails.AccountName ||
          !!paymentDetails.eftPayable ||
          !!paymentDetails.Addressee ||
          !!paymentDetails.eftMemo ||
          !!paymentDetails.Memo
        );
    }
  }
}
