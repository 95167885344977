import { Input, Directive } from '@angular/core';
import { IMatterDetailEntry } from '@app/shared/models';
import { getObjValue } from '../../../../../server/modules/shared/functions/common-util.functions';

@Directive()
export abstract class BaseDetailEntryAbstract {
  @Input()
  set detailEntry(data: IMatterDetailEntry) {
    this._detailEntry = data;
    this.detailEntryUpdated(data);
  }
  get detailEntry() {
    return this._detailEntry;
  }

  private _detailEntry: IMatterDetailEntry;

  get name(): string {
    return this.fieldValue('__name', '');
  }
  get showContextMenu(): boolean {
    return this.fieldValue('context.showContextMenu', false);
  }
  get displayDebtorForAccounting(): boolean {
    return this.fieldValue('context.displayDebtorForAccounting', false);
  }
  get requireContentApp(): boolean {
    return this.fieldValue('context.requireContentApp', false);
  }
  get contextType(): 'empty' | 'non-empty' | 'reconciliation' {
    return this.fieldValue('context.type');
  }
  get displayPersons(): boolean {
    return this.fieldValue('context.displayPersons', false);
  }
  get hidden(): boolean {
    return this.fieldValue('context.hidden', false);
  }
  get detailNumber(): string {
    return this.fieldValue('context.detailNumber', '');
  }
  constructor() {}

  fieldValue(field: string, defaultValue?: any): any {
    return getObjValue(this.detailEntry, field, defaultValue);
  }

  // Hook to detailEntry changes - to be overwritten by derived classes
  detailEntryUpdated(detailEntry: IMatterDetailEntry): void {}
}
