<div class="footer-toolbar-container">
  <!-- Left part -->
  <div class="footer-toolbar">
    <sc-btn
      *ngIf="!status.deleteHidden"
      [btnType]="'link'"
      class="footer-btn"
      styleClass="text-capitalize text-danger"
      [isLoading]="status.deleteLoading"
      [disabled]="status.deleteDisabled"
      (btnClicked)="delete()"
    >
      {{ 'Core.Button.Delete' | translate }}
    </sc-btn>
  </div>

  <!-- Right part -->
  <div [formGroup]="footerForm" *ngIf="!!footerForm" class="footer-toolbar">
    <!-- TODO: Email -->

    <sc-switch
      *ngIf="!status.printOnSaveHidden"
      formControlName="printOnSave"
      class="footer-btn"
      [textClass]="'text-uppercase'"
      [label]="'Core.Button.PrintAndEmail' | translate"
    ></sc-switch>

    <sc-btn
      class="footer-btn"
      btnType="default"
      *ngIf="status.printOnSaveHidden"
      [isLoading]="invoicePrintProcessing"
      (btnClicked)="print()"
    >
      {{ 'Core.Button.PrintAndEmail' | translate }}
    </sc-btn>
  </div>
</div>
