<div [formGroup]="editForm" *ngIf="!isHidden" class="x-form-row pt-1">
  <div class="form-group col">
    <label *ngIf="label">{{label}}</label>
    <sc-textarea
      inputClass="py-2"
      [rowNumber]="rowNumber"
      [styleRule]="styleRule"
      [delay]="200"
      formControlName="memo"
      [focus]="false"
      (onKeydown)="keydown($event)"
    ></sc-textarea>
  </div>
</div>
