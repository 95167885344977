import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContextMenuComponent, ContextMenuService } from '@perfectmemory/ngx-contextmenu';

import { transformContextMenuActions } from '@app/shared/functions';
import { DialogService } from '@app/shared/services/dialog/dialog.service';
import { AnalyticsService, PermissionsService } from '@app/core/services';
import { AppApiService } from '@app/core/api';
import { IEmailAddress } from '@app/features/+email/models/email.model';

import {
  EMatterDetailType,
  IDefinableTable,
  IMatterCard,
  IMatterDetailEntry,
  IMatterDetailRelationship,
  IMatterDetailsInfo,
  INewDetailParams,
  IPersonActingActionParams,
} from '@app/shared/models/matter-details.model';

import { DebtorClass, TableConfigIds } from '@app/features/+matter-details/constants';
import {
  createDefinableTable,
  getLastDetailEntry,
  isChildParentRelationshipValid,
  isDebtorCard,
  isMatterCard,
  isRelatedLayout,
  matterDetailChildrenComparator,
} from '@app/features/+matter-details/functions';
import { LayoutRendererService } from '@app/features/+layout-renderer/services/layout-renderer/layout-renderer.service';
import { ICardListEntry } from '@app/features/+card/models';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AnalyticsCategories } from '@app/core/constants/analytics.constant';
import {
  IMatterDetailsApiError,
  MatterDetailsError,
} from '@app/features/+matter-details/models/matter-details-api.model';
import { IMenuItem } from '@app/shared/models/ui-utils.model';
import { IMatterTypesSetupData } from '@app/features/+matter-types/models';
import { AddinItem, LeapAppGridId, ViewLeapApp } from '@app/features/matter-addin/models';
import { LeapAppService } from '@app/features/matter-addin/services';
import {
  differenceBetweenArrays,
  getObjValue,
  hasProp,
  isEmptyArray,
  isObjEqual,
  sliceArray,
  unzip,
  zip,
} from '../../../../../server/modules/shared/functions/common-util.functions';

export interface IContextMenuClickEvent {
  event: MouseEvent;
  item: any; // Adjust the type if 'item' has a specific type
}

@Component({
  selector: 'sc-detail-entry-list',
  templateUrl: './detail-entry-list.component.html',
})
export class DetailEntryListComponent implements OnInit, OnDestroy {
  isCardReadOnly: boolean;
  isMatterReadOnly: boolean;

  @ViewChild('contextMenu', { static: true }) contextMenuRef: ContextMenuComponent<any>;

  @Input()
  set error(data: any | IMatterDetailsApiError) {
    // Matter-details api error
    if (hasProp(data, 'source')) {
      switch (data.source) {
        case MatterDetailsError.Reorder:
          this.setupVisibleContent();
          return;
      }
    }
  }

  @Input()
  set detailEntries(data: IMatterDetailEntry[]) {
    this._detailEntries = data;
    this.setupVisibleContent();
    this.contextMenuActions = this.buildContextMenu();
    this.visibleDetailEntriesTree = this.createDetailEntryTree(this.visibleDetailEntries, this.matterCardRelationships);
  }

  get detailEntries() {
    return this._detailEntries;
  }

  private _matterCardRelationships: IMatterDetailRelationship[];
  @Input()
  set matterCardRelationships(data: IMatterDetailRelationship[]) {
    this._matterCardRelationships = data;

    this.visibleDetailEntriesTree = this.createDetailEntryTree(this.visibleDetailEntries, this.matterCardRelationships);
  }

  get matterCardRelationships() {
    return this._matterCardRelationships;
  }

  @Input()
  set detailInfo(data: IMatterDetailsInfo) {
    this._detailInfo = data;
  }

  get detailInfo() {
    return this._detailInfo;
  }

  @Input()
  set cardEntries(data: ICardListEntry[]) {
    this._cardEntries = data;
  }

  get cardEntries() {
    return this._cardEntries;
  }

  @Input()
  set initialising(data: boolean) {
    this._initialising = data;
  }

  get initialising() {
    return this._initialising;
  }

  @Input()
  set loading(data: boolean) {
    this._loading = data;
  }

  get loading() {
    return this._loading;
  }

  @Input()
  isRecurringMatter = false;

  private _leapApps: ViewLeapApp[];
  @Input()
  set leapApps(value: ViewLeapApp[]) {
    this._leapApps = value;
    if (!!this._leapApps && this._leapApps.length > 0) {
      this.leapAppContextMenuActions = this._leapAppService.buildLeapAppNgxContextMenu({
        leapApps: this._leapApps,
        gridId: LeapAppGridId.Details,
        getLeapAppItemVisibility: this.getLeapAppItemVisibilityForDetailEntry,
        execute: (req: { entry: any; leapApp: ViewLeapApp; contextItem: AddinItem }) =>
          this.executeLeapAppContextItem.emit({
            detailEntry: req.entry,
            leapApp: req.leapApp,
            contextItem: req.contextItem,
          }),
      });
    }
  }

  @Output()
  onOpenReadOnlyPopOver = new EventEmitter<string>();
  @Output()
  onHideReadOnlyPopOver = new EventEmitter<void>();
  @Output()
  onSetDebtorCard = new EventEmitter<any>();
  @Output()
  onAddActingPerson = new EventEmitter<IPersonActingActionParams>();
  @Output()
  onRemoveActingPerson = new EventEmitter<IPersonActingActionParams>();
  @Output()
  onCreateEmailTo = new EventEmitter<IEmailAddress>();
  @Output()
  onCreateLetterTo = new EventEmitter<IMatterCard>();
  @Output()
  onOpenMatterTypesModal = new EventEmitter<IMatterTypesSetupData>();
  @Output()
  onMatterEntriesReordered = new EventEmitter<IMatterDetailEntry[]>();
  @Output()
  onRelatedMatterEntriesReordered = new EventEmitter<IMatterDetailEntry[]>();
  @Output()
  debtorForAccountingCardRemove = new EventEmitter<any>();
  @Output()
  debtorCardRemove = new EventEmitter<any>();
  @Output()
  matterCardRemove = new EventEmitter<{
    matterCardId: string;
    displayOrder: number;
    children: IMatterDetailEntry[];
    tableId: string;
  }>();

  @Output()
  onOpenCardModel = new EventEmitter<any>();

  @Output()
  onOpenLayoutRendererModal = new EventEmitter<any>();

  @Output()
  onOpenCardListModal = new EventEmitter<any>();

  @Output()
  executeLeapAppContextItem = new EventEmitter<{
    detailEntry: IMatterDetailEntry;
    leapApp: ViewLeapApp;
    contextItem: AddinItem;
  }>();

  public contextMenuActions: IMenuItem<IMatterDetailEntry>[] = [];
  public leapAppContextMenuActions: IMenuItem<IMatterDetailEntry>[] = [];
  public matterTypeIndex: number;
  public visibleDetailEntries: IMatterDetailEntry[];
  public visibleDetailEntriesTree: IMatterDetailEntry[];

  get contextMenu() {
    if (this.leapAppContextMenuActions.length > 0) {
      return [...this.contextMenuActions, { divider: true, visible: () => true }, ...this.leapAppContextMenuActions];
    }
    return this.contextMenuActions;
  }

  private _cardEntries: ICardListEntry[];
  private _detailEntries: IMatterDetailEntry[];
  private _detailInfo: IMatterDetailsInfo;
  private _initialising: boolean;
  private _loading: boolean;

  constructor(
    private _appApiSvc: AppApiService,
    private _contextMenuSvc: ContextMenuService<any>,
    private _dialogSvc: DialogService,
    private _layoutRendererService: LayoutRendererService,
    private _permissionsSvc: PermissionsService,
    private _translateSvc: TranslateService,
    private _leapAppService: LeapAppService,
    private _analyticsSvc: AnalyticsService
  ) {
    this.matterTypeIndex = 0;
    this.visibleDetailEntries = [];
  }

  ngOnInit() {
    this.isCardReadOnly = this._permissionsSvc.isCardReadOnly;
    this.isMatterReadOnly = this._permissionsSvc.isMatterReadOnly;
  }

  ngOnDestroy() { }

  isClient = (entry: IMatterDetailEntry) => getObjValue(entry, 'tableId') === TableConfigIds.CLIENT;

  public handleCDKDrop(event: CdkDragDrop<any, any>) {
    if (!this._matterCardRelationships || this._matterCardRelationships.length === 0) {
      this.onDrop(event);
      return;
    }

    const { currentIndex: nextIndex, previousIndex: prevIndex } = event;
    if (prevIndex === nextIndex) {
      return;
    }

    const noDragChildrenList = [
      ...sliceArray(this.visibleDetailEntriesTree, 0, prevIndex),
      ...sliceArray(this.visibleDetailEntriesTree, prevIndex + 1),
    ];

    const childrenList: IMatterDetailEntry[] = [
      ...sliceArray(noDragChildrenList, 0, nextIndex),
      this.visibleDetailEntriesTree[prevIndex],
      ...sliceArray(noDragChildrenList, nextIndex),
    ];

    let currentIndex: number;
    const previousIndex = this.visibleDetailEntriesTree[prevIndex].flatListIndex;
    if (nextIndex === 0) {
      currentIndex = childrenList[nextIndex + 1].flatListIndex - 1;
    } else {
      currentIndex = childrenList[nextIndex - 1].flatListIndex + 1;
    }

    this.onDrop({ currentIndex, previousIndex });
  }

  onDrop(event: any): void {
    const { currentIndex: nextIndex, previousIndex: prevIndex } = event;
    if (prevIndex === nextIndex) {
      return;
    }

    const item = this.visibleDetailEntries[prevIndex];
    const currentState = this.visibleDetailEntries?.filter((entry) => !!entry) || [];
    const noDragItemList = [
      ...sliceArray(this.visibleDetailEntries, 0, prevIndex),
      ...sliceArray(this.visibleDetailEntries, prevIndex + 1),
    ];
    const targetModel = [
      ...sliceArray(noDragItemList, 0, nextIndex),
      this.visibleDetailEntries[prevIndex],
      ...sliceArray(noDragItemList, nextIndex),
    ];
    const nextState = targetModel?.filter((entry) => !!entry) || [];
    const [originalSegments, updatedSegments] = unzip(
      zip(currentState, nextState).filter(
        (pair: [IMatterDetailEntry, IMatterDetailEntry]) => !isObjEqual(pair[0], pair[1])
      )
    );

    /**
     * Predicates to detect faulty drag-n-drop:
     * 1) Drag item is matter-type || visible debtor-card || related-layout
     * 2) Restricted zone = client + debtor + matter-type
     *   2.1) Drag item from restricted zone to outside
     *   2.2) Drag item from outside to restricted zone
     * 3) Within same table-type zone, card is switched with a related-layout
     * 4) Drop client-card after debtor-card
     * 5) Item is a child and is being moved above parent
     *
     * @type {((() => boolean) | (() => boolean) | (() => boolean))[]}
     */
    const revertPredicates = [
      () => !item || item.matterType || isDebtorCard(item) || isRelatedLayout(item, nextState),
      () => {
        const clientsDebtorNumber = currentState.filter((entry) => (!entry.matterType && entry.__tableId === TableConfigIds.CLIENT) || isDebtorCard(entry)).length;

        // noted: previously using the _.indexOf which also depends on the item reference.
        // if the value is not being reference in the array, the result will be -1 eventhough the value is same in the list.
        // therefore, the targetModel.indexOf is equavalent to _.indexOf

        return (
          (targetModel.indexOf(item) >= clientsDebtorNumber && item.__tableId === TableConfigIds.CLIENT) ||
          (targetModel.indexOf(item) <= clientsDebtorNumber && item.__tableId !== TableConfigIds.CLIENT)
        );
      },
      () => {
        const [_, ...tailofOriginalSegments] = originalSegments || [];
        const isMixedTableTypes = !!tailofOriginalSegments?.find(
          (segment) => segment.__tableId !== originalSegments[0].__tableId
        );

        if (!isEmptyArray(originalSegments) && !isMixedTableTypes) {
          return (
            updatedSegments.length === 2 &&
            (isRelatedLayout(updatedSegments[0], nextState) || isRelatedLayout(updatedSegments[1], nextState))
          );
        }
        return false;
      },
      () => isDebtorCard(updatedSegments[updatedSegments.length - 2]),
      () => this.checkParentDisplayOrder(item),
    ];

    const invalidDragDrop = revertPredicates.reduce(
      (result: boolean, predicate: () => boolean) => result || predicate(),
      false
    );

    if (invalidDragDrop) {
      return;
    } else {
      this.visibleDetailEntries = nextState;

      if (!!this._matterCardRelationships && this._matterCardRelationships.length > 0) {
        this.onRelatedMatterEntriesReordered.emit([
          ...nextState,
          ...differenceBetweenArrays(this.detailEntries, nextState),
        ] as IMatterDetailEntry[]);
      } else {
        this.onMatterEntriesReordered.emit([
          ...nextState,
          ...differenceBetweenArrays(this.detailEntries, nextState),
        ] as IMatterDetailEntry[]);
      }
    }
  }

  checkParentDisplayOrder(item: IMatterDetailEntry): boolean {
    if (!item.__relatedParentTableId) {
      // Does not have a parent, do not need to check
      return false;
    }

    const parent = this.detailEntries.find((e) => e.__id === item.__relatedParentTableId);

    return !!parent && parent.__displayOrder < item.__displayOrder;
  }

  newDetail(params: INewDetailParams): void {
    const { detailEntry, isPersonCard, isCard } = params;
    if (isCard) {
      this.onOpenCardModel.emit({
        detailEntry,
        isOrganisationCard: !isPersonCard,
        isNewCard: true,
        isNewMatterCard: this.isNewMatterCard(detailEntry),
      });
    } else {
      const detail = detailEntry as IDefinableTable;
      this.onOpenLayoutRendererModal.emit({
        matterId: this.detailInfo.matterId,
        detailEntry: detail,
      });
    }
  }

  toggleContextMenu(params: IContextMenuClickEvent): void {
    const { event, item } = params;

    if (!this.contextMenuRef) return;

    this._contextMenuSvc.show(this.contextMenuRef, { x: event.clientX, y: event.clientY });
    event.preventDefault();
    event.stopPropagation();
  }

  openDetailEntry(detailEntry: IMatterDetailEntry): void {
    const contextType = getObjValue(detailEntry, 'context.type');
    switch (contextType) {
      case 'empty':
        // If it is an empty matter-card, we don't want to open on row click
        // as user has option to pick either person or organisation type.
        if (!isMatterCard(detailEntry)) {
          this._analyticsSvc.eventTrack({
            category: AnalyticsCategories.MatterDetailsDefinable,
            action: 'New definable',
          });
          this.newDetail({ detailEntry, isPersonCard: false, isCard: false });
        }
        break;
      case 'non-empty':
        if (this.isCardReadOnly && isMatterCard(detailEntry)) {
          return;
        }
        if (!isMatterCard(detailEntry)) {
          this._analyticsSvc.eventTrack({
            category: AnalyticsCategories.MatterDetailsDefinable,
            action: 'Open definable',
          });
        }
        this.openDetail(detailEntry);
        break;
      case 'reconciliation':
        this.openReconciliationModal(detailEntry as IDefinableTable);
        break;
      default:
        this.openMatterTypeModal();
    }
  }

  addActingPerson(params: IPersonActingActionParams): void {
    this.onAddActingPerson.emit(params);
  }

  removeActingPerson(params: IPersonActingActionParams): void {
    this.onRemoveActingPerson.emit(params);
  }

  private setupVisibleContent(): void {
    if (this.detailEntries && this.detailEntries.length > 0) {
      // Only interested in visible entries.
      const visibleEntries =
        this.detailEntries?.filter((detailEntry) => !getObjValue(detailEntry, 'context.hidden', false)) || [];

      // Get the first client in the details list that doesn't have a debtor class.
      const client: IMatterDetailEntry = visibleEntries.find((detail: IMatterDetailEntry): boolean => detail.__className !== DebtorClass && detail.__tableId === TableConfigIds.CLIENT);

      if (client !== undefined) {
        const lastClient: IMatterDetailEntry = getLastDetailEntry(visibleEntries, client.tableId);
        if (lastClient !== undefined) {
          const displayOrder = lastClient.__displayOrder;
          const index = visibleEntries.findIndex((detail: IMatterDetailEntry): boolean => detail.__className !== DebtorClass && detail.__displayOrder > displayOrder);
          this.matterTypeIndex = Math.max(index, 0);
        }
      } else {
        this.matterTypeIndex = 0;
      }

      // Matter-type needs a dummy entry as a placeholder to be displayed.
      const finalList = [
        ...sliceArray(visibleEntries, 0, this.matterTypeIndex),
        { matterType: true },
        ...sliceArray(visibleEntries, this.matterTypeIndex),
      ] as IMatterDetailEntry[];

      this.visibleDetailEntries = finalList;
    }
  }

  private openReconciliationModal(detailEntry: IDefinableTable): void {
    this._appApiSvc.navigate({
      path: [{ outlets: { popup: ['purchase-reconciliation'] } }],
      query: {
        tableId: detailEntry.tableId,
        layoutId: detailEntry.layoutId,
        isRecurringMatter: this.isRecurringMatter,
      },
    });
  }

  private openMatterTypeModal(): void {
    if (!this.isRecurringMatter) {
      this.onOpenMatterTypesModal.emit(null);
    } else {
      const { matterId, matterTypeId, matterState: state } = this.detailInfo;
      const event: IMatterTypesSetupData = {
        matterId,
        matterTypeId,
        state,
      };
      this.onOpenMatterTypesModal.emit(event);
    }
  }

  private openDetail(detailEntry: IMatterDetailEntry): void {
    if (isMatterCard(detailEntry)) {
      this.onOpenCardModel.emit({ detailEntry });
    } else {
      const detail = detailEntry as IDefinableTable;
      this.onOpenLayoutRendererModal.emit({
        matterId: this.detailInfo.matterId,
        detailEntry: detail,
      });
    }
  }

  private addDefinableTable(detailEntry: IDefinableTable): void {
    const definableTables = (this.detailEntries?.filter((d) => d.detailType === EMatterDetailType.DefinableTable) ||
      []) as IDefinableTable[];
    const newDefinableTable = createDefinableTable(definableTables, detailEntry, this.detailInfo.matterId);
    this.newDetail({ detailEntry: newDefinableTable, isCard: false, isPersonCard: false });
  }

  private addMatterCard(matterCard: IMatterCard): void {
    if (!isMatterCard(matterCard)) {
      return;
    }

    this._analyticsSvc.eventTrack({
      category: AnalyticsCategories.MatterDetailsMatterCardContextMenu,
      action: 'New card',
    });
    this.onOpenCardModel.emit({
      detailEntry: matterCard,
      isOrganisationCard: !matterCard.isPersonCard,
      isNewMatterCard: true,
      isNewCard: true,
    });
  }

  /**
   * Setting a debtor card in card modal page
   * */
  private addDebtorCard(matterCard: IMatterCard): void {
    if (!isMatterCard(matterCard)) {
      return;
    }

    this.onOpenCardModel.emit({
      detailEntry: matterCard,
      isOrganisationCard: !matterCard.isPersonCard,
      addDebtorCard: true,
      isNewCard: true,
    });
  }

  /**
   * Setting a debtor card without opening the card modal page
   * */
  private setDebtorCard(matterCard: IMatterCard): void {
    if (!isMatterCard(matterCard)) {
      return;
    }

    this.onSetDebtorCard.emit({ detailEntry: matterCard, isClient: this.isClient(matterCard) });
  }

  private removeMatterCard(matterCard: IMatterCard): void {
    const { __className, __id, __displayOrder, __fileOrder, children, tableId } = matterCard;

    if (matterCard.isDebtor) {
      const clientCard = this.detailEntries?.find((d: IMatterDetailEntry) => d.tableId === TableConfigIds.CLIENT && (__id === d.__id ? d.__fileOrder < __fileOrder : true));

      if (__className === DebtorClass) {
        this.debtorForAccountingCardRemove.emit(clientCard);
      } else {
        this.debtorCardRemove.emit({ clientCard, matterCardId: __id, displayOrder: __displayOrder, children, tableId });
      }
    } else {
      this.matterCardRemove.emit({
        matterCardId: __id,
        displayOrder: __displayOrder,
        children: !!children ? children.filter((c) => c.context.type === 'non-empty') : undefined,
        tableId,
      });
    }
  }

  private replaceMatterCard(matterCard: IMatterCard): void {
    const isOrganisationCard = matterCard && matterCard.cardInfo && matterCard.cardInfo.type === 'People';
    this.onOpenCardModel.emit({ detailEntry: matterCard, isOrganisationCard, isNewCard: true });
  }

  openReadCardDetails(cardId: string) {
    this.onOpenReadOnlyPopOver.emit(cardId);
  }

  resetReadCardDetails() {
    this.onHideReadOnlyPopOver.emit();
  }

  private openCardSelectorModal(
    matterCard: IMatterCard,
    newAfterSelect: boolean = false,
    isSelectingDebtorCard: boolean = false
  ): void {
    this.onOpenCardListModal.emit({ detailEntry: matterCard, isNewMatterCard: newAfterSelect, isSelectingDebtorCard });
  }

  private createEmailTo(matterCard: IMatterCard): void {
    this.onCreateEmailTo.emit({
      name: matterCard.__description,
      address: getObjValue(matterCard, 'cardInfo.email'),
    });
  }

  private createLetterTo(matterCard: IMatterCard): void {
    this.onCreateLetterTo.emit(matterCard);
  }

  private isNewMatterCard(entry: IMatterDetailEntry): boolean {
    return this.visibleDetailEntries
      .filter((e) => e.tableId === entry.tableId)
      .filter((e) => e.context.type === 'empty')
      .filter((e) => e.isTransient === undefined).length === 0
      ? entry.isTransient
      : false;
  }

  private createDetailEntryTree(
    detailEntries: IMatterDetailEntry[],
    relationships: IMatterDetailRelationship[]
  ): IMatterDetailEntry[] {
    if (!relationships || relationships.length === 0) {
      return detailEntries;
    }

    const indexedEntries = detailEntries.map((entry, idx) => ({ ...entry, flatListIndex: idx, isChild: false }));

    indexedEntries.forEach((entry) => {
      if (!entry || isRelatedLayout(entry, indexedEntries) || !!entry.matterType || entry.__id === undefined) {
        return;
      }

      entry.children = indexedEntries.filter(
        (child) =>
          child.__relatedParentTableId === entry.__id &&
          (isChildParentRelationshipValid(relationships, entry, child) || isRelatedLayout(child, indexedEntries))
      );
      entry.children.forEach((child) => (indexedEntries[child.flatListIndex].isChild = true));
    });
    const returnValue = indexedEntries
      .filter((de) => !!de && de.isChild === false)
      .sort(matterDetailChildrenComparator);
    returnValue.forEach((value) => delete value.isChild);
    return returnValue;
  }

  private buildContextMenu(): IMenuItem<IMatterDetailEntry>[] {
    const clientMatches = this.detailEntries?.filter((d) => d.tableId === TableConfigIds.CLIENT) || [];
    const isNotOnlyClient = clientMatches.length > 1;

    const contextMenu = transformContextMenuActions([
      {
        execute: (entry: IMatterDetailEntry) => {
          if (!!entry) {
            if (getObjValue(entry, 'context.type') === 'reconciliation') {
              this.openReconciliationModal(entry as IDefinableTable);
            } else {
              if (isMatterCard(entry)) {
                this._analyticsSvc.eventTrack({
                  category: AnalyticsCategories.MatterDetailsMatterCardContextMenu,
                  action: 'Select card',
                });
              } else {
                this._analyticsSvc.eventTrack({
                  category: AnalyticsCategories.MatterDetailsMatterCardContextMenu,
                  action: 'Open definable',
                });
              }

              this.openDetail(entry);
            }
          } else {
            this.openMatterTypeModal();
          }
        },
        text: () => this._translateSvc.instant('Matter.DetailEntry.ContextMenu.Open'),
        visible: (entry) =>
          !entry || this.hasDescription(entry) || getObjValue(entry, 'context.type') === 'reconciliation',
      },
      {
        divider: true,
        visible: (entry) => [
          this.hasDescription(entry),
          [
            [this.isCard(entry), isDebtorCard(entry)].some(Boolean),
            [!this.singleton(entry), !this.isBasedOnCard(entry)].some(Boolean),
          ].every(Boolean),
          !this.isCardReadOnly,
        ].every(Boolean),
      },
      {
        execute: (entry: IMatterCard) => {
          // If the entry has cardId, we need to create an extra detail entry.
          if (!!(entry).cardId) {
            this.addMatterCard(entry);
          } else {
            this._analyticsSvc.eventTrack({
              category: AnalyticsCategories.MatterDetailsMatterCardContextMenu,
              action: 'New card',
              label: 'from',
            });
            this.newDetail({ detailEntry: entry, isPersonCard: true, isCard: true });
          }
        },
        text: (entry: IMatterDetailEntry) => this._translateSvc.instant('Matter.DetailEntry.ContextMenu.NewCard', { type: entry.__name }),
        visible: (entry) => [
          [!this.hasDescription(entry), this.isCard(entry), !isDebtorCard(entry)].every(Boolean),
          [this.hasDescription(entry), this.isCard(entry), !isDebtorCard(entry), !this.singleton(entry)].every(
            Boolean
          ),
        ].some(Boolean) && !this.isCardReadOnly,
      },
      {
        execute: (entry) => {
          this._analyticsSvc.eventTrack({
            category: AnalyticsCategories.MatterDetailsDefinableContextMenu,
            action: 'New definable',
          });
          this.newDetail({ detailEntry: entry, isPersonCard: false, isCard: false });
        },
        text: (entry: IMatterDetailEntry) => this._translateSvc.instant('Matter.DetailEntry.ContextMenu.NewDefinableTable', { type: entry.__name }),
        visible: (entry) => [!this.hasDescription(entry), this.isDefinable(entry)].every(Boolean) && !this.isCardReadOnly,
      },
      {
        text: (entry: IMatterDetailEntry) => this._translateSvc.instant('Matter.DetailEntry.ContextMenu.SelectCard', { type: entry.__name }),
        visible: (entry) =>
          [!this.hasDescription(entry), this.isCard(entry), !isDebtorCard(entry), !this.isMatterReadOnly].every(
            Boolean
          ),
        execute: (entry) => {
          this._analyticsSvc.eventTrack({
            category: AnalyticsCategories.MatterDetailsMatterCardContextMenu,
            action: 'New card',
            label: 'from existing',
          });
          this.openCardSelectorModal(entry as IMatterCard, this.isNewMatterCard(entry));
        },
      },
      {
        text: (entry: IMatterDetailEntry) => this._translateSvc.instant('Matter.DetailEntry.ContextMenu.AddExistingCard', { type: entry.__name }),
        visible: (entry) =>
          [
            this.hasDescription(entry),
            this.isCard(entry),
            !isDebtorCard(entry),
            !this.singleton(entry),
            !this.isMatterReadOnly,
          ].every(Boolean),
        execute: (entry: IMatterCard) => {
          this.openCardSelectorModal(entry, true);
        },
      },
      {
        text: (entry: IMatterDetailEntry) => this._translateSvc.instant('Matter.DetailEntry.ContextMenu.AddDefinableTable', { type: entry.__name }),
        visible: (entry) =>
          [
            this.hasDescription(entry),
            this.isDefinable(entry),
            !this.singleton(entry),
            !this.isBasedOnCard(entry),
            !this.isMatterReadOnly,
          ].every(Boolean),
        execute: (entry: IDefinableTable) => {
          this._analyticsSvc.eventTrack({
            category: AnalyticsCategories.MatterDetailsMatterCardContextMenu,
            action: 'New definable',
          });
          this.addDefinableTable(entry);
        },
      },
      {
        divider: true,
        visible: (entry) =>
          [
            this.hasDescription(entry),
            this.isCard(entry),
            !this.isMatterReadOnly,
            !isDebtorCard(entry),
            !this.singleton(entry),
            !this.isBasedOnCard(entry),
          ].every(Boolean),
      },
      {
        execute: (entry: IMatterCard) => {
          this._analyticsSvc.eventTrack({
            category: AnalyticsCategories.MatterDetailsMatterCardContextMenu,
            action: 'New email',
          });
          this.createEmailTo(entry);
        },
        text: (entry) =>
          this._translateSvc.instant('Matter.DetailEntry.ContextMenu.Email', {
            email: getObjValue(entry, 'cardInfo.email'),
          }),
        visible: (entry) =>
          [
            this.isCard(entry),
            !this.isMatterReadOnly,
            this.hasDescription(entry),
            getObjValue(entry, 'cardInfo.email'),
          ].every(Boolean),
      },
      {
        execute: (entry: IMatterCard) => {
          this._analyticsSvc.eventTrack({
            category: AnalyticsCategories.MatterDetailsMatterCardContextMenu,
            action: 'New letter',
          });
          this.createLetterTo(entry);
        },
        text: (entry: IMatterDetailEntry) => this._translateSvc.instant('Matter.DetailEntry.ContextMenu.Letter', {
          name: entry.__description,
        }),
        visible: (entry) => [this.isCard(entry), !this.isMatterReadOnly, this.hasDescription(entry)].every(Boolean),
      },
      {
        divider: true,
        visible: (entry) => this.isCard(entry) && !isDebtorCard(entry) && !this.isMatterReadOnly,
      },
      {
        execute: (entry: IMatterCard) => {
          this._analyticsSvc.eventTrack({
            category: AnalyticsCategories.MatterDetailsMatterCardContextMenu,
            action: 'Set as debtor',
          });
          this.setDebtorCard(entry);
        },
        text: () => this._translateSvc.instant('Matter.DetailEntry.ContextMenu.SetDebtor'),
        visible: (entry) =>
          [this.isCard(entry), this.hasDescription(entry), !this.isDebtor(entry), !this.isMatterReadOnly].every(
            Boolean
          ),
      },
      {
        execute: (entry: IMatterCard) => {
          this._analyticsSvc.eventTrack({
            category: AnalyticsCategories.MatterDetailsMatterCardContextMenu,
            action: 'New card debtor',
          });
          this.addDebtorCard(entry);
        },
        text: () => this._translateSvc.instant('Matter.DetailEntry.ContextMenu.NewDebtor'),
        visible: (entry) => [this.isCard(entry), !this.isMatterReadOnly, !this.isCardReadOnly].every(Boolean),
      },
      {
        execute: (entry: IMatterCard) => {
          this._analyticsSvc.eventTrack({
            category: AnalyticsCategories.MatterDetailsMatterCardContextMenu,
            action: 'Select card debtor',
          });
          this.openCardSelectorModal(entry, false, true);
        },
        text: () => this._translateSvc.instant('Matter.DetailEntry.ContextMenu.SelectDebtor'),
        visible: (entry) => [this.isCard(entry), !this.isMatterReadOnly, !this.isCardReadOnly].every(Boolean),
      },
      {
        divider: true,
        visible: (entry) => this.hasDescription(entry) && this.isCard(entry) && !this.isMatterReadOnly,
      },
      {
        execute: (entry: IMatterCard) => {
          this._analyticsSvc.eventTrack({
            category: AnalyticsCategories.MatterDetailsMatterCardContextMenu,
            action: 'Replace card',
          });
          this.replaceMatterCard(entry);
        },
        text: () => this._translateSvc.instant('Matter.DetailEntry.ContextMenu.Replace'),
        visible: (entry) =>
          [this.hasDescription(entry), this.isCard(entry), !this.isMatterReadOnly, !this.isCardReadOnly].every(Boolean),
      },
      {
        // Comment from LEO: remove definable table is not supported by backend.
        execute: (entry) => {
          this._analyticsSvc.eventTrack({
            category: AnalyticsCategories.MatterDetailsMatterCardContextMenu,
            action: 'Remove card',
          });
          this.removeMatterCard(entry as IMatterCard);
        },
        text: () => this._translateSvc.instant('Matter.DetailEntry.ContextMenu.Remove'),
        visible: (entry) =>
          [
            this.hasDescription(entry),
            this.isCard(entry),
            [this.isRecurringMatter, !this.isClient(entry), this.isClient(entry) && isNotOnlyClient].some(Boolean),
            !this.hasChildren(entry),
            !this.isCardReadOnly,
            !this.isMatterReadOnly,
          ].every(Boolean),
      },
    ]);

    return contextMenu;
  }

  private getLeapAppItemVisibilityForDetailEntry(req: { entry: IMatterDetailEntry; addinItem: AddinItem }): boolean {
    const { entry, addinItem } = req;
    if (addinItem.hidden) {
      return false;
    }

    if (!addinItem.visibility) {
      return true;
    }

    if (addinItem.visibility && !!addinItem.visibility.entityType && addinItem.visibility.entityType.length > 0) {
      const cardEntityRestriction = addinItem.visibility.entityType.includes('Card');
      const definableEntityRestriction = addinItem.visibility.entityType.includes('Table');
      if (!!cardEntityRestriction && !this.isCard(entry)) {
        return false;
      }
      if (!!definableEntityRestriction && !this.isDefinable(entry)) {
        return false;
      }
    }

    return true;
  }

  private hasDescription = (entry: IMatterDetailEntry) => !!getObjValue(entry, '__description');
  private isCard = (entry: IMatterDetailEntry) => !!entry && isMatterCard(entry);
  private isDefinable = (entry: IMatterDetailEntry) => !!entry && entry.detailType === EMatterDetailType.DefinableTable;
  private hasChildren = (entry: IMatterDetailEntry) =>
    !!entry &&
    !!entry.children &&
    entry.children.length !== 0 &&
    entry.children.filter((c) => !isRelatedLayout(c, this.detailEntries)).some((x) => x.context.type === 'non-empty');

  private isDebtor = (entry: IMatterDetailEntry) =>
    !!entry && (isDebtorCard(entry) || (this.isCard(entry) && (entry as IMatterCard).isDebtor));
  private singleton = (entry: IMatterDetailEntry) => getObjValue(entry, 'context.singleton', false);
  private isBasedOnCard = (entry: any) => !!entry?.basedOnCard;  // true also treat it as singleton.
}
