import { createSelector } from '@ngrx/store';

import { AppState, adapter } from '../reducers';
import { selectAppState } from './app-state.selectors';
import * as fromStaff from '../reducers/staff.reducer';

export const selectStaffState = createSelector(selectAppState, (state: AppState) => (state ? state.staff : null));

export const {
  // select the array of staff ids
  selectIds: selectStaffIds,

  // select the dictionary of staff entities
  selectEntities: selectStaffEntities,

  // select the array of staff
  selectAll: selectStaffList,

  // select the total staff count
  selectTotal: selectStaffTotal,
} = adapter.getSelectors(selectStaffState);

export const selectCurrentStaffId = createSelector(selectStaffState, fromStaff.getCurrentStaffId);

export const selectCurrentStaff = createSelector(
  selectStaffEntities,
  selectCurrentStaffId,
  (staffEntities, currentStaffId) => staffEntities[currentStaffId]
);

export const selectCurrentStaffSecurity = createSelector(
  selectCurrentStaff,
  (currentStaff) => currentStaff?.StaffSecurities
);

export const selectCurrentStaffPaymentLimit = createSelector(selectCurrentStaff, (currentStaff) => {
  if (currentStaff) {
    return {
      officePaymentLimit: currentStaff.officePaymentLimit,
      trustPaymentLimit: currentStaff.trustPaymentLimit,
    };
  }

  return null;
});

export const selectCurrentStaffAutoTime = createSelector(selectCurrentStaff, (currentStaff) => !!currentStaff?.autoTime);

export const selectStaffById = (id) => createSelector(selectStaffEntities, (entities) => entities[id]);
