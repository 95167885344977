<div class="btn-toolbar ml-auto" *ngIf="toolbarButton" [ngClass]="toolbarWrapperClass">
  <label [ngClass]="wrapperClass">
    <input
      class="custom-control-input"
      type="checkbox"
      [value]="value"
      [ngModel]="value"
      (ngModelChange)="handleValueChange($event)"
      [disabled]="isDisabled"
    />
    <span class="custom-control-label">{{ label | translate }}</span>
  </label>
</div>

<label [ngClass]="wrapperClass" *ngIf="!toolbarButton">
  <input
    class="custom-control-input"
    type="checkbox"
    [value]="value"
    [ngModel]="value"
    (ngModelChange)="handleValueChange($event)"
    [disabled]="isDisabled"
  />
  <span class="custom-control-label">{{ label | translate }}</span>
</label>
