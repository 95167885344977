import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ViewLeapApp } from '@app/features/matter-addin/models';
import * as leapAppActions from '@app/features/matter-addin/store/action/leap-app.action';

export interface State extends EntityState<ViewLeapApp> {
  loading: boolean;
}

export const selectId = (item: ViewLeapApp): string => item.id;

export const adapter: EntityAdapter<ViewLeapApp> = createEntityAdapter<ViewLeapApp>({
  selectId,
  sortComparer: false,
});

export const INITIAL_STATE: State = adapter.getInitialState({
  loading: false,
});

export const reducer = (state: State = INITIAL_STATE, action: leapAppActions.LeapAppActions) => {
  switch (action.type) {
    case leapAppActions.GET_VIEW_LEAP_APPS:
      return {
        ...state,
        loading: true,
      };

    case leapAppActions.GET_VIEW_LEAP_APPS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case leapAppActions.GET_VIEW_LEAP_APPS_SUCCESS:
      return {
        ...adapter.upsertMany(action.payload.apps, state),
        loading: false,
      };

    case leapAppActions.CLEAR_VIEW_LEAP_APPS:
      return {
        ...adapter.removeAll(state),
        loading: false,
      };

    default:
      return state;
  }
};

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of viewLeapApp ids
export const selectViewLeapAppIds = selectIds;

// select the dictionary of viewLeapApp entities
export const selectViewLeapAppEntities = selectEntities;

// select the array of viewLeapApps
export const selectAllViewLeapApps = selectAll;

// select the total viewLeapApp count
export const selectViewLeapAppTotal = selectTotal;
