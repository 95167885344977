import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppStorageService } from './services';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  AppSlice,
  AppEffects,
  reducers,
  StaffEffects,
  FirmDetailsEffects,
  PubnubEffects,
  ToastrEffects,
  AutomationEffect,
} from './store';
import { BrowserTitleEffect } from './store/effects/browser-title.effect';
import { CardFiltersEffect } from './store/effects/card-filters.effect';
import { PdfDocumentService } from '@app/features/+create-pdf/services';
import { SupportRequestEffects } from './store/effects/support-request.effect';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(AppSlice.App, reducers),
    EffectsModule.forFeature([
      StaffEffects,
      AppEffects,
      FirmDetailsEffects,
      PubnubEffects,
      ToastrEffects,
      BrowserTitleEffect,
      CardFiltersEffect,
      AutomationEffect,
      SupportRequestEffects,
    ]),
  ],
  declarations: [],
  providers: [AppStorageService, PdfDocumentService],
})
export class AppStateModule {}
