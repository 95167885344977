import { v4 as uuidv4 } from 'uuid';
import { IDocsFolder, IDoc } from '../../../shared/models';
import { IDocsFolderDTO, CorrespondenceFilterOption, correspondenceFilterOptions } from '../models';
import { isFolder } from '../../../shared/functions';
import {
  hasProp,
  isEmptyObj,
  uniqBy,
  uniqString,
} from '../../../../../server/modules/shared/functions/common-util.functions';

export const findFolder = (folderId: string, root: IDocsFolder): IDocsFolder => {
  if (isEmptyObj(root) || folderId === root.id) {
    return root;
  }
  return root.folders?.reduce(
    (result: IDocsFolder, folder: IDocsFolder) => (!!result ? result : findFolder(folderId, folder)),
    undefined
  );
};

export const buildFolderPath = (folder: IDocsFolder, root: IDocsFolder): IDocsFolder[] => {
  const path: IDocsFolder[] = [folder];
  let iterator = { ...folder };
  while (!isEmptyObj(iterator) && !isEmptyObj(root) && iterator.id !== root.id) {
    path.push(iterator.parent);
    iterator = iterator.parent;
  }

  return path.reverse();
};

export const createFolderObject = (options: Partial<IDocsFolder>): IDocsFolder =>
  ({
    parent: options.parent || undefined,
    id: hasProp(options, 'id') === true ? options.id : uuidv4(),
    name: hasProp(options, 'name') === true ? options.name : '',
    documents: options.documents || [],
    folders: options.folders || [],
    fullDocuments: options.fullDocuments || [],
  } as IDocsFolder);

export const toFolder = (folderDTO: IDocsFolderDTO, documents: IDoc[], parent?: IDocsFolder): IDocsFolder => {
  if (folderDTO !== undefined) {
    const emptyFolder = {
      folders: [],
      documents: [],
      fullDocuments: [],
    };
    const folder: IDocsFolder = {
      parent: !!parent ? { ...parent, ...emptyFolder } : undefined,
      id: folderDTO.folderId,
      name: folderDTO.folderName,
      documents: folderDTO.documents === undefined || folderDTO.documents === null ? [] : folderDTO.documents,
      fullDocuments: [],
      folders: [],
      activeShared: folderDTO.activeShared,
    };
    folder.folders = folderDTO.folders?.map((f: IDocsFolderDTO): IDocsFolder => toFolder(f, documents, folder)) || [];
    folder.fullDocuments =
      documents?.filter((doc: IDoc): boolean => !!folder.documents?.find((id: string): boolean => id === doc.id)) || [];
    return folder;
  }
  return undefined;
};

export const getDocuments = (folder: IDocsFolder): string[] => {
  const documents: string[] = folder?.documents ? [...folder.documents] : [];
  folder.folders?.forEach((subfolder: IDocsFolder): void => {
    documents.push(...getDocuments(subfolder));
  });
  return documents;
};

export const folderDesendantsByExt = (folder: IDocsFolder, extensions: string[]) => {
  const returnValue = { ...folder } as IDocsFolder;
  const docs = returnValue.fullDocuments.filter((doc) => !!doc.ext && extensions.includes(doc.ext.toLowerCase()));
  const folders = returnValue.folders.map((f) => folderDesendantsByExt(f, extensions)).filter((x) => !!x);

  if (!!docs && docs.length === 0) {
    returnValue.folders = folders;
    returnValue.documents = [];
    returnValue.fullDocuments = [];
  } else {
    returnValue.folders = folders;
    returnValue.documents = docs.map((d) => d.id);
    returnValue.fullDocuments = docs;
  }
  return returnValue;
};

export const buildExtensionFilterOptions = (docs: (IDocsFolder | IDoc)[]): CorrespondenceFilterOption[] => {
  const returnValue = [];
  if (!!docs) {
    const extensions = flattenExtList(docs);

    extensions.forEach((ext) => {
      const filterOption = getFilterOptionByExt(ext);
      if (!!filterOption) {
        returnValue.push(filterOption);
      }
    });
  }
  return uniqBy(returnValue, 'value');
};

const getFilterOptionByExt = (ext: string) => correspondenceFilterOptions.find((opt) => opt.ext.includes(ext));

const flattenExtList = (docs: (IDocsFolder | IDoc)[]): string[] => {
  const result: string[] = [];

  docs.forEach((doc) => {
    if (isFolder(doc)) {
      const folder = doc as IDocsFolder;
      result.push(...flattenExtList(folder.folders));
      folder.fullDocuments.forEach((d) => result.push(d.ext));
    } else {
      result.push((doc as IDoc).ext);
    }
  });

  return uniqString(result) as string[];
};
