import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'sc-matter-status',
  templateUrl: './matter-status.component.html',
})
export class MatterStatusComponent {
  @HostBinding('class.d-inline-block')
  @Input()
  msStatus: string;
  @Input() msStatusStrip: string;
  constructor() {}
}
