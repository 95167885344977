import { Injectable } from '@angular/core';
import { selectCurrentMatterId, selectCurrentStaff, selectCurrentStaffId, selectFirmDetails } from '@app/core/store';
import { ListTimeFeeStart } from '@app/features/+time-fee-ledger/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { of, switchMap, tap, withLatestFrom } from 'rxjs';
import { AutoTimeService } from '../../services/autotime.service';
import * as autoTimeActions from '../actions/autotime.action';
import { selectAutoTimeBillable, selectAutoTimeMatterId, selectAutoTimeStart, selectAutoTimeTask } from '../selectors/autotime.selector';

@Injectable()
export class AutoTimeEffect {
  autoTimeTrySetBillable$ = createEffect(() => this.actions$.pipe(
    ofType<autoTimeActions.AutoTimeTrySetBillable>(autoTimeActions.AutoTimeActionTypes.AUTOTIME_TRY_SET_BILLABLE),
    withLatestFrom(
      this.store.pipe(select(selectCurrentStaff)),
      (action, staff) => ({ billable: action.payload, staff })
    ),
    switchMap((data) => {
      const { billable, staff } = data;
      /*
        Billable is false by default
        Do not let it get enabled if the staff member does not have autotime enabled
      */
      if (!staff.autoTime) {
        return [];
      }


      const actions: Action[] = [
        new autoTimeActions.AutoTimeSetBillable(billable),
        //billable ? new autoTimeActions.AutoTimeTryStartTracking() :
      ];

      if(!billable) {
        actions.push(new autoTimeActions.AutoTimeEndTask({}));
      }

      return actions;
    })
  ));

  autoTimeTryStartTask$ = createEffect(() => this.actions$.pipe(
    ofType<autoTimeActions.AutoTimeTryStartTask>(autoTimeActions.AutoTimeActionTypes.AUTOTIME_TRY_START_TASK),
    withLatestFrom(
      this.store.pipe(select(selectAutoTimeTask)),
      this.store.pipe(select(selectCurrentMatterId)),
      (action, currentTask, matterId) => ({...action.payload, currentTask, matterId})
    ),
    switchMap((data) => {
      const { actionType, resourceType, currentTask, matterId } = data;
      if (!!currentTask) {
        return of(new autoTimeActions.AutoTimeEndTask({
          next: {
            resourceType,
            actionType
          }
        }));
      }

      return of(new autoTimeActions.AutoTimeStartTaskSuccess({
        task: {
          actionType,
          resourceType,
          total: 1
        },
        matterId
      }));
    })
  ));

  autoTimeEndTask$ = createEffect(() => this.actions$.pipe(
    ofType<autoTimeActions.AutoTimeEndTask>(autoTimeActions.AutoTimeActionTypes.AUTOTIME_END_TASK),
    withLatestFrom(
      this.store.pipe(select(selectAutoTimeBillable)),
      this.store.pipe(select(selectAutoTimeTask)),
      this.store.pipe(select(selectAutoTimeStart)),
      this.store.pipe(select(selectAutoTimeMatterId)),
      this.store.pipe(select(selectCurrentStaffId)),
      this.store.pipe(select(selectFirmDetails)),
      (action, billable, task, start, matterId, staffId, firmDetails) => ({ ...action.payload, billable, task, start, staffId, firmId: firmDetails.id, matterId })
    ),
    switchMap((data) => {
      const { requiredResourceType, actionTypeOverride, next, billable, task, start, staffId, firmId, matterId } = data;

      const postAction: Action = !!next
        ? new autoTimeActions.AutoTimeStartTaskSuccess({
          task: {
            ...next,
            total: 1
          },
          matterId
        })
        : new autoTimeActions.AutoTimeClearTask();


      if (!billable || !task || (!!requiredResourceType && task.resourceType !== requiredResourceType)) {
        return of(postAction);
      }

      const finalTask = {
        ...task,
        actionType: actionTypeOverride ?? task.actionType
      };

      const autoTime = this.autoTimeSvc.buildNewTimeEntry(matterId, staffId, firmId, start, finalTask);
      return [
        new autoTimeActions.AutoTimeSendTimeEntryStart(autoTime),
        postAction
      ];
    })
  ));

  autoTimeUpdateTimeEntryStart$ = createEffect(() => this.actions$.pipe(
    ofType<autoTimeActions.AutoTimeSendTimeEntryStart>(autoTimeActions.AutoTimeActionTypes.AUTOTIME_SEND_TIME_ENTRY_START),
    switchMap((action) => {
      const timeEntry = action.payload;
      return this.autoTimeSvc.sendUpdateRequest(timeEntry);
    }),
    switchMap((result) => [
        result.Message === 'Success'
          ? new autoTimeActions.AutoTimeSendTimeEntrySuccess(null)
          : new autoTimeActions.AutoTimeSendTimeEntryFailure(null)
      ]
    )
  ));

  autoTimeDeleteEntryStart$ = createEffect(() => this.actions$.pipe(
    ofType<autoTimeActions.AutoTimeDeleteEntryStart>(autoTimeActions.AutoTimeActionTypes.AUTOTIME_DELETE_ENTRY_START),
    withLatestFrom(
      this.store.pipe(select(selectFirmDetails)),
      (action, firmDetails) => ({ feeId: action.payload, firmId: firmDetails.id })
    ),
    switchMap((data) => {
      const { feeId, firmId } = data;
      return this.autoTimeSvc.sendDeleteRequest(feeId, firmId);
    }),
    switchMap((result) => [
        result.Message === 'Success'
          ? new autoTimeActions.AutoTimeDeleteEntrySuccess(null)
          : new autoTimeActions.AutoTimeSendTimeEntryFailure(null)
      ]
    )
  ));

  autoTimeDeleteEntrySuccess$ = createEffect(() => this.actions$.pipe(
    ofType<autoTimeActions.AutoTimeDeleteEntrySuccess>(autoTimeActions.AutoTimeActionTypes.AUTOTIME_DELETE_ENTRY_SUCCESS),
    switchMap((action) => {
      this.toastrSvc.show('Activity entry has been deleted successfully.', 'Success', {}, 'success');
      return of(new ListTimeFeeStart(null));
    })
  ));

  autoTimeDeleteEntryFailure$ = createEffect(() => this.actions$.pipe(
    ofType<autoTimeActions.AutoTimeDeleteEntryFailure>(autoTimeActions.AutoTimeActionTypes.AUTOTIME_DELETE_ENTRY_FAILURE),
    tap((action) => {
      this.toastrSvc.error('Unable to delete activity entry.', '');
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private autoTimeSvc: AutoTimeService,
    private toastrSvc: ToastrService
  ) { }
}
