import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AddinButton, IContextMenuAction } from '@app/features/matter-addin/models';
import { ContextMenuComponent, ContextMenuService, ContextMenuDirective, ContextMenuOpenEvent } from '@perfectmemory/ngx-contextmenu';
import * as matterAddinActions from '@app/features/matter-addin/store/action';
import { Store } from '@ngrx/store';

@Component({
  selector: 'sc-addin-button',
  templateUrl: './addin-button.component.html',
})
export class AddinButtonComponent implements OnInit {
  @Input() addinButton: AddinButton;
  @Output() onButtonClicked = new EventEmitter();
  @Output()
  public onOpen = new EventEmitter<ContextMenuOpenEvent<any>>();

  @Output()
  public onClose = new EventEmitter<'void'>();

  @Output()
  public onMenuItemExecuted = new EventEmitter<string>();

  @ViewChild(ContextMenuDirective)
  public contextMenuDirective?: ContextMenuDirective<void>;
  public contextMenuActions: IContextMenuAction[] = [];

  constructor(private _store: Store<any>, private _contextMenuSvc: ContextMenuService<void>) {}

  ngOnInit() {
    this.buildContextMenu();
  }

  onClicked() {
    this.onButtonClicked.emit(this.addinButton);
  }

  // toggleContextMenu(contextMenu, event): void {
  //   this._contextMenuSvc.show.next({ contextMenu, event, item: this.addinButton });
  //   event.preventDefault();
  //   event.stopPropagation();
  // }
  toggleContextMenu(contextMenu: ContextMenuComponent<void>, event: MouseEvent): void {
    this._contextMenuSvc.show(contextMenu, { x: event.clientX, y: event.clientY });
    event.preventDefault();
    event.stopPropagation();
  }

  buildContextMenu() {
    const contextMenu = [];
    this.addinButton.DropdownMenu.forEach((addinDropdownItem) => {
      contextMenu.push({
        html: (item) => addinDropdownItem.Title,
        click: (item) =>
          this._store.dispatch(new matterAddinActions.MatterAddinClicked({ addinItem: addinDropdownItem })),
        enabled: (item) => !!addinDropdownItem,
        visible: !!addinDropdownItem,
      });
      // don't show the divider after the last entry
      if (this.addinButton.DropdownMenu.indexOf(addinDropdownItem) !== this.addinButton.DropdownMenu.length - 1) {
        contextMenu.push({
          divider: true,
          visible: true,
        });
      }
    });
    this.contextMenuActions = contextMenu;
  }
}
