// core
import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { Action, Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { Dictionary } from '@ngrx/entity/src/models';

import { environment } from '@env/environment';
import { IAutomationWordBusy } from '@app/shared/models';
import * as calendarActions from '@app/features/+calendar/store/actions/events.actions';
import * as matterListActions from '@app/features/+matter-list/store/actions/matter-list';
import * as cardListActions from '@app/features/+card/store/actions/card-list';
import * as namedListActions from '@app/features/+card/store/actions/named-list';
import * as personListActions from '@app/features/+person/store/actions/person-list';
import * as folderActions from '@app/features/+correspondence/store/actions';
import * as trustInvestmentActions from '@app/features/+trust-investment-ledger/store/actions';
import * as notificationActions from '@app/features/+notification/store/actions';
import * as recurringMatterActions from '@app/features/+recurring-matter-details/store/actions';

import * as appActions from '@app/core/store/actions/app.action';
import * as automationActions from '@app/core/store/actions/automation.actions';
import * as pubnubActions from '@app/core/store/actions/pubnub.action';
import * as precedentActions from '@app/features/+precedent/store/actions';
import { EMPTY_GUID, EPubNubMessageType, FirmDetailsFetchFields, FirmDetailsFetchMode } from '@app/core/constants';
import { IAutomationError, IMatterSubscription, IMatterUpdateManagement } from '@app/core/models';
import { AppState } from '@app/core/store/reducers';
import { LogService } from '../log/log.service';
import { CorrespondenceFetchMode } from '@app/features/+correspondence/constants';
import { AuthService } from '@app/core/services/auth/auth.service';
import {
  isFunction,
  omitObjValue,
  toLowerCase,
  toUpperCase,
} from '../../../../../server/modules/shared/functions/common-util.functions';
import PubNub from 'pubnub';

const ENV = environment.config.brand.env === 'live' ? 'production' : 'test';

@Injectable()
export class PubnubService {
  private pubnub: PubNub;
  private _initialised = false;
  private _subscribedMatterId: string;
  private _matterActions$ = new Subject<Action[]>();
  private _matterActionsSubs: Dictionary<Subscription> = {};

  constructor(private store: Store<AppState>, private log: LogService, private authSvc: AuthService) {
    this.init();
  }

  init(): void {
    const subscribeKey = environment.config.keys.pnSubscribe;
    this.pubnub = new PubNub({
      subscribeKey: environment.config.keys.pnSubscribe,
      uuid: uuidv4(),
      ssl: toLowerCase(location.protocol) === 'https:',
    });
    this._initialised = true;
  }

  private pubnubGuard(firmId: string) {
    if (!firmId) {
      return () => { };
    }

    if (!this._initialised) {
      this.init();
    }
  }

  subscribeMatter(matterId: string, next: (actions: Action[]) => void): IMatterSubscription {
    this._subscribedMatterId = matterId;
    const ticket = uuidv4();
    this._matterActionsSubs[ticket] = this._matterActions$.subscribe(next);
    return {
      unsubscribe: () => this.unsubscribeMatter(ticket),
    };
  }

  unsubscribeMatter(ticket: string, unsubscribeAll: boolean = false): void {
    if (unsubscribeAll) {
      Object.values(this._matterActionsSubs).forEach((subscription: Subscription) => subscription.unsubscribe());
      this._matterActionsSubs = {};
    } else {
      const subscription = this._matterActionsSubs[ticket];
      if (subscription) {
        subscription.unsubscribe();
        this._matterActionsSubs = omitObjValue(this._matterActionsSubs, [ticket]);
      }
    }
  }

  subscribeAccountingChannel(matterId: string, next?: (type: EPubNubMessageType) => void): IMatterSubscription {
    const channel = toUpperCase(matterId);
    const listener = {
      message: /*istanbul ignore next Cannot test PubNub callback*/ (event: any): void => {
        if (event.channel !== channel) {
          return;
        }
        const message = event.message as string;
        const messageType = parseInt(message.substring(0, message.length - 36), 10);
        // the backend publishes messages multiple times for matter channels
        // but only once will it have the userId in the message
        // so ignore the messages without the userId
        const userId = message.substring(message.length - 36);
        if (userId === EMPTY_GUID) {
          return;
        }
        // Override normal behaviour if a next function is provided.
        if (!!next && isFunction(next)) {
          next(messageType);
        } else {
          switch (messageType) {
            case EPubNubMessageType.TrustInvestments:
              this.store.dispatch(new trustInvestmentActions.ListInvestmentLedgerStart(matterId));
              break;
            case EPubNubMessageType.MatterIndexSucceeded:
              // publish(EPubNubMessageType.MatterIndexSucceeded, recordId);
              break;
          }
        }
      },
    };

    this.pubnub.addListener(listener);
    this.pubnub.subscribe({ channels: [channel], withPresence: true });

    return {
      unsubscribe: (): void => {
        this.pubnub.removeListener(listener);
        this.pubnub.unsubscribe({ channels: [channel] });
      },
    };
  }

  private emitMatterActions(pubnubUpdate: IMatterUpdateManagement): void {
    if (this._subscribedMatterId === pubnubUpdate.matterId && this._matterActions$) {
      this._matterActions$.next(pubnubUpdate.actions);
    }
  }

  private async ensureInitialized() {
    if (!this._initialised) {
      await this.init();
    }
  }

  public async subscribeMatterNotification(firmId: string): Promise<() => void> {
    await this.ensureInitialized();
    this.pubnubGuard(firmId);
    this.unsubscribeMatter(undefined, true);

    const channel = toLowerCase(firmId) + '_napi';
    const listener = {
      message: (event: any): void => {
        if (event.channel !== channel) {
          return;
        }
        let message: any;
        try {
          message = JSON.parse(event.message);
        } catch (ex) {
          message = { MessageType: event.message };
        }

        // 'NotificationReceived' returns StaffIds: Array<string>
        // 'NotificationAckedEvent' returns StaffId: string
        const { Name, FirmId, StaffIds, StaffId } = message.MessageType;
        switch (Name) {
          case 'NotificationReceived':
            this.store.dispatch(
              new notificationActions.PubnubNotificationsUpdate({ firmId: FirmId, staffIds: StaffIds })
            );
            return;

          case 'NotificationAckedEvent':
            this.store.dispatch(new notificationActions.PubnubEventsUpdate({ firmId: FirmId, staffId: StaffId }));
            return;

          default:
            return;
        }
      },
    };

    this.pubnub.addListener(listener);
    this.pubnub.subscribe({ channels: [channel], withPresence: true });

    return (): void => {
      this.pubnub.removeListener(listener);
      this.pubnub.unsubscribe({ channels: [channel] });
    };
  }

  subscribeFirm(firmId: string): () => void {
    this.pubnubGuard(firmId);
    this.unsubscribeMatter(undefined, true);

    const channel = toLowerCase(firmId) + '_bc';
    const listener = {
      message: /*istanbul ignore next Cannot test PubNub callback*/ (event: any): void => {
        if (event.channel !== channel) {
          return;
        }
        let message: any;
        let recordId: string;
        let parentRecordId: string;
        try {
          message = JSON.parse(event.message);
          recordId = message.RecordId;
          parentRecordId = message.ParentRecordId;
        } catch (ex) {
          message = { MessageType: event.message };
        }
        switch (message.MessageType) {
          case 'Automation':
            // Sets current session automation state
            this.store.dispatch(new automationActions.AutomationResponded(null));
            this.store.dispatch(new automationActions.DeleteAutomationTicket({ ticketId: recordId }));
            return;
          case 'Automation-WordBusy':
            const wordBusy = {
              ticketId: message.RecordId,
              message: message.Message,
            } as IAutomationWordBusy;
            this.store.dispatch(new automationActions.NotifyAutomationWordBusy(wordBusy));
            return;
          case 'UpdateDocListEntry':
          case 'Search':
            if (parentRecordId) {
              this.emitMatterActions({
                actions: [new pubnubActions.PubnubDocumentsUpdate(parentRecordId)],
                matterId: parentRecordId,
              });
              // publish(EPubNubMessageType.UpdateDocument, parentRecordId);
            }
            break;
          case 'UpdateMatter':
            if (recordId) {
              // whenever a 'UpdateMatter' message comes, we need to clear its cache even
              // the this._subscribedMatterId is not the same as the recordId from pubnub
              this.store.dispatch(new appActions.ClearCache({ context: 'matterId', objectId: recordId }));
              this.emitMatterActions({
                actions: [new pubnubActions.PubnubMatterUpdate(recordId)],
                matterId: recordId,
              });
            }
            break;
          case 'MatterListEntry':
            this.store.dispatch(matterListActions.ListMattersStart(null));
            // publish(EPubNubMessageType.MatterListEntry, recordId);
            break;
          case 'PersonListEntry':
            this.store.dispatch(new personListActions.ListPersonsStart(null));
            // publish(EPubNubMessageType.PersonListEntry, recordId);
            break;
          case 'CardListEntry':
            this.store.dispatch(new cardListActions.ListCardsStart(null));
            // publish(EPubNubMessageType.CardListEntry, recordId);
            break;
          case 'UpdateCard':
            if (recordId) {
              this.store.dispatch(new pubnubActions.PubnubCardDetailsUpdate({ id: recordId }));
            }
            break;
          case 'StaffInfoUpdate':
            this.store.dispatch(new appActions.AddStaffList(null));
            // publish(EPubNubMessageType.UpdateStaff, recordId);
            break;
          case 'UpdateBranchInfo':
            // publish(EPubNubMessageType.UpdateBranchInfo, recordId);
            break;
          case 'FirmInfoUpdate':
            // publish(EPubNubMessageType.UpdateFirm, recordId);
            this.store.dispatch(
              new appActions.AddFirmDetails({ fetchMode: FirmDetailsFetchMode.Force, fields: FirmDetailsFetchFields })
            );
            this.store.dispatch(new namedListActions.UpdateNamedLists(null));
            break;
          case 'UpdateMatterDocFolder':
            console.debug('------------> PubNub: UpdateMatterDocFolder', recordId);
            if (recordId) {
              this.emitMatterActions({
                actions: [
                  new folderActions.ListCorrespondenceListStart({
                    matterId: recordId,
                    fetchMode: CorrespondenceFetchMode.ForceFolders,
                  }),
                ],
                matterId: recordId,
              });
            }
            // publish(EPubNubMessageType.UpdateFolder, recordId);
            break;
          case 'SuperDiary':
            if (message.EventType.includes('CriticalDate')) {
              this.store.dispatch(new calendarActions.GetCriticalDatesStart(null));
              this.store.dispatch(new appActions.RefreshMatterCache(message.MatterId));
            } else {
              this.store.dispatch(
                new calendarActions.LoadEventsStart({ start: undefined, end: undefined, staffId: undefined })
              );
            }
            break;
          case 'UpdateTask':
          case 'UpdateAppointment':
            this.store.dispatch(
              new calendarActions.LoadEventsStart({ start: undefined, end: undefined, staffId: undefined })
            );
            break;
          case 'Automation-Ready': {
            this.store.dispatch(new automationActions.AutomationReady(message.RecordId));
            break;
          }

          case 'Automation-Error': {
            this.store.dispatch(new automationActions.AutomationError(message as IAutomationError));
            break;
          }
          case 'RecurringMatter': {
            this.store.dispatch(new recurringMatterActions.UpdateSelectedRecurringMatterStart(message.RecordId));
            break;
          }
          case EPubNubMessageType[EPubNubMessageType.MatterIndexSucceeded]:
            // publish(EPubNubMessageType.MatterIndexSucceeded, recordId);
            break;
          case 'UpdateCustomPrecedent': {
            this.store.dispatch(new precedentActions.SearchPrecedentByMatterTypeOnCustomPrecentUpdated());
          }
        }
      },
    };

    this.pubnub.addListener(listener);
    this.pubnub.subscribe({ channels: [channel], withPresence: true });

    return (): void => {
      this.pubnub.removeListener(listener);
      this.pubnub.unsubscribe({ channels: [channel] });
    };
  }

  subscribeLeapCalc(firmId: string): () => void {
    this.pubnubGuard(firmId);
    const channel = 'LEAPCALC';
    const listener = {
      message: (event: any): void => {
        if (event.channel !== channel) {
          return;
        }
        let message: any;
        try {
          message = JSON.parse(event.message);
        } catch (ex) {
          message = { action: '', payload: {} };
        }

        const { action, payload } = message;
        const { brand, env, region } = payload;
        if (action === 'reload') {
          this.store.dispatch(new pubnubActions.ReloadLeapCalc({ brand, env, region }));
        }
      },
    };

    this.pubnub.addListener(listener);
    this.pubnub.subscribe({ channels: [channel], withPresence: true });

    return (): void => {
      this.pubnub.removeListener(listener);
      this.pubnub.unsubscribe({ channels: [channel] });
    };
  }

  subscribeToFirmAuth(firmId: string): () => void {
    this.pubnubGuard(firmId);

    const channel = `${toLowerCase(firmId)}`;
    const listener = {
      message: (event: any): void => {
        if (event.channel !== channel) {
          return;
        }

        try {
          const payload = event?.message;
          if (payload?.topic === 'link' && payload?.messageType === 'firm') {
            const isConsentGranted = !!payload?.data?.status;
            this.store.dispatch(new appActions.UpdateFirmConsent(isConsentGranted));
          }
        } catch (ex) {
          this.log.warn('Firm auth pubnub', ex);
        }
      },
    };

    this.pubnub.addListener(listener);
    this.pubnub.subscribe({ channels: [channel], withPresence: true });

    return (): void => {
      this.pubnub.removeListener(listener);
      this.pubnub.unsubscribe({ channels: [channel] });
    };
  }

  subscribeToUserAuth(userId: string): () => void {
    const channel = `user_${toLowerCase(userId)}`;
    const listener = {
      message: (event: any): void => {
        if (event.channel !== channel) {
          return;
        }

        try {
          const payload = event?.message;
          if (payload?.topic === 'link' && payload?.messageType === 'user') {
            this.store.dispatch(new appActions.UpdateUserDetails(undefined));
          }
        } catch (ex) {
          this.log.warn('User auth pubnub', ex);
        }
      },
    };

    this.pubnub.addListener(listener);
    this.pubnub.subscribe({ channels: [channel], withPresence: true });

    return (): void => {
      this.pubnub.removeListener(listener);
      this.pubnub.unsubscribe({ channels: [channel] });
    };
  }

  addListener(listener: any): () => void {
    if (!this._initialised) {
      this.log.error('PubNub client is not initialized.');
      return () => { };
    }
    this.pubnub.addListener(listener);
    return () => {
      this.pubnub.removeListener(listener);
    };
  }

  subscribe(options: any): () => void {
    this.pubnub.subscribe(options);
    return () => {
      this.pubnub.unsubscribe(options);
    };
  }

  unsubscribeAll(): void {
    if (this.pubnub) {
      this.pubnub.unsubscribeAll();
      this.pubnub = undefined;
    }
    this._initialised = false;
  }

  publish(firmId: string, message: any) {
    if (!this._initialised) {
      this.log.error('Attempt to use PubNub before initialization');
      return;
    }
    this.pubnubGuard(firmId);
    this.pubnub.publish(
      {
        channel: `${toLowerCase(firmId)}_bc`,
        message,
      },
      (status, response) => {
        if (status.error) {
          this.log.error('Publish Error:', status);
        } else {
          this.log.info('Message Published:', response);
        }
      }
    );
  }
}
