<div class="ag-cell-label-container">
  <div class="ag-header-cell-label">
    <a
      href
      scPreventDefault
      (click)="onAscClicked()"
      [tooltip]="ascToolTip"
      container="body"
      placement="top"
      *ngIf="enableCustomSort"
      class="px-1"
    >
      <sc-icon class="x-icon-sm" [iconName]="'arrow-dot-up'"></sc-icon>
    </a>
    <span class="ag-header-cell-text" [ngClass]="{ 'm-2': enableCustomSort }" role="columnheader">{{
      headerName
    }}</span>
    <a
      href
      scPreventDefault
      (click)="onDescClicked()"
      [tooltip]="descToolTip"
      container="body"
      placement="top"
      *ngIf="enableCustomSort"
      class="px-1"
    >
      <sc-icon class="x-icon-sm" [iconName]="'arrow-dot-down'"></sc-icon>
    </a>
  </div>
</div>
