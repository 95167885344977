import { Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-status-cell',
  templateUrl: './status-cell.component.html',
})
export class StatusCellComponent implements AgRendererComponent {
  isHidden: boolean;
  status: string;
  statusClass: string;

  constructor() {}

  agInit(params: any): void {
    const rowObj = params.data;
    this.isHidden = isFunction(params['getIsHidden']) ? params.getIsHidden(rowObj) : false;
    this.status = isFunction(params['getStatus']) ? params.getStatus(rowObj) : false;
    this.statusClass = isFunction(params['getStatusClass']) ? params.getStatusClass(rowObj) : false;
  }

  refresh(params: any): boolean {
    return true;
  }
}
