import { Pipe, PipeTransform } from '@angular/core';
/*
 * Text snippet
 */
@Pipe({ name: 'stripFrontQuestionMark' })
export class StripFrontQuestionMarkPipe implements PipeTransform {
  transform(input: any): string {
    if (input) {
      return input.replace(/^\?\?/, '').replace(/[()]/g, '').trim();
    }
  }
}
