import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { INewDetailParams } from '@app/shared/models';
import { isMatterCard as isMatteCardFnc } from '@app/features/+matter-details/functions';
import { EPermissionType } from '@app/core/constants';
import { BaseDetailEntryAbstract } from '../detail-entry-item/base-detail-entry.abstract';

@Component({
  selector: 'sc-detail-empty',
  templateUrl: './detail-empty.component.html',
})
export class DetailEmptyComponent extends BaseDetailEntryAbstract implements OnInit {
  @Output() onNewDetail = new EventEmitter<Partial<INewDetailParams>>();
  permissionEnum: typeof EPermissionType = EPermissionType;
  get isMatterCard(): boolean {
    return isMatteCardFnc(this.detailEntry);
  }

  constructor() {
    super();
  }

  ngOnInit() {}

  newDetail($event: MouseEvent, isCard: boolean = false, isPersonCard: boolean = true): void {
    // Prevent bubbling event up to the list level.
    $event.stopImmediatePropagation();
    this.onNewDetail.emit({ isCard, isPersonCard });
  }
}
