<div
  *ngIf="!showMatterType; else matterTypeBlock"
  class="x-list-group-item-wrapper"
  [contextMenu]="contextMenu"
  [contextMenuValue]="detailEntry"
  (click)="openDetailEntry()"
>
  <div class="x-list-group-item-header">
    <label>
      <span> {{ name }} {{ detailNumber }} </span>
    </label>

    <sc-icon iconName="more-vert-grid-20" (click)="toggleContextMenu($event, detailEntry)"></sc-icon>
  </div>
  <div class="x-list-group-item-content">
    <ng-container [ngSwitch]="contextType">
      <!-- empty row -->
      <sc-detail-empty
        *ngSwitchCase="'empty'"
        [detailEntry]="detailEntry"
        (onNewDetail)="newDetail($event)"
      ></sc-detail-empty>

      <!-- non-empty row -->
      <sc-detail-non-empty
        *ngSwitchCase="'non-empty'"
        [detailEntry]="detailEntry"
        (onShowPopUp)="onSelectCard($event)"
        (onHidePopUp)="onUnSelectCard()"
        (onAddActingPerson)="addActingPerson($event)"
        (onRemoveActingPerson)="removeActingPerson($event)"
        [isRecurringMatter]="isRecurringMatter"
      ></sc-detail-non-empty>

      <!-- reconciliation row -->
      <sc-detail-reconciliation *ngSwitchCase="'reconciliation'" [detailEntry]="detailEntry"></sc-detail-reconciliation>
    </ng-container>
  </div>
</div>
<ng-template #matterTypeBlock>
  <div
    class="x-list-group-item-wrapper"
    (click)="openMatterTypeDetailEntry()"
    [class.recurring-matter-type]="isRecurringMatter"
  >
    <div class="x-list-group-item-header">
      <label>
        <span> {{ 'Matter.Type.Label' | translate }} </span>
      </label>

      <sc-icon iconName="more-vert-grid-20" (click)="toggleContextMenu($event, detailEntry)"></sc-icon>
    </div>
    <div class="x-list-group-item-content">
      <div>
        <a href scPreventDefault>
          <strong>{{ matterType }}</strong
          >&nbsp;[{{ matterState }}]
        </a>
      </div>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="showChildren(detailEntry)">
  <ul class="x-list-group-content mt-3 overflow-auto" cdkDropList (cdkDropListDropped)="handleCDKDrop($event)">
    <sc-detail-entry-item
      class="list-group-item x-border-ver-dashed pr-0 layer-1"
      cdkDragBoundary=".x-list-group-content"
      cdkDrag
      cdkDragLockAxis="y"
      *ngFor="let item of detailEntry.children"
      [contextMenuActions]="contextMenuActions"
      [detailEntry]="item"
      [detailInfo]="detailInfo"
      [showMatterType]="showMatterType"
      [detailEntries]="detailEntries"
      (onOpenDetailEntry)="onOpenDetailEntry.emit($event)"
      (onNewDetail)="onNewDetail.emit($event)"
      (onCardSelected)="onCardSelected.emit($event)"
      (onCardUnSelected)="onCardUnSelected.emit($event)"
      (onToggleContextMenu)="onToggleContextMenu.emit($event)"
      (onAddActingPerson)="onAddActingPerson.emit($event)"
      (onRemoveActingPerson)="onRemoveActingPerson.emit($event)"
      (onDrop)="onDrop.emit($event)"
    >
    </sc-detail-entry-item>
  </ul>
</ng-container>

<context-menu #contextMenu [disabled]="!(contextMenuActions?.length > 0)">
  <ng-container *ngFor="let action of contextMenuActions">
    <ng-template
      contextMenuItem
      let-item
      [visible]="action.visible"
      [disabled]="!action.enabled"
      [divider]="action.divider"
      [subMenu]="!!action.subMenu && action.subMenu.length > 0 ? subMenu1 : null"
      (execute)="action.execute($event.value)"
    >
      {{ action.text(item) }}
    </ng-template>
    <context-menu #subMenu1 [menuClass]="'dropdown--ngx-contentmenu'">
      <ng-container *ngFor="let subAction of action.subMenu; index as i; let last = last">
        <ng-template contextMenuItem [visible]="subAction.visible && i !== 0 && !last" [divider]="true"> </ng-template>

        <ng-template
          contextMenuItem
          let-item
          [visible]="subAction.visible"
          [subMenu]="!!subAction.subMenu && subAction.subMenu.length > 0"
          (execute)="subAction.execute($event.value)"
        >
          {{ subAction.text(item) }}
        </ng-template>
        <ng-container *ngIf="!!subAction.subMenu && subAction.subMenu.length > 0">
          <ng-container *ngFor="let subAction2 of subAction.subMenu">
            <ng-template
              contextMenuItem
              let-item
              [visible]="subAction2.visible"
              (execute)="subAction2.execute($event.value)"
            >
              {{ subAction2.text(item) }}
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-container>
    </context-menu>
  </ng-container>
</context-menu>
