import { Component, HostBinding } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'sc-currency-display-cell',
  templateUrl: './currency-display-cell.component.html',
})
export class CurrencyDisplayComponent implements ICellRendererAngularComp {
  amount: any;
  params: any;
  constructor() {}

  @HostBinding('class.ag-content-label')
  agInit(params: any): void {
    this.params = params;
    this.amount = params.getamount(params.data);
  }

  refresh(params: any): boolean {
    this.amount = this.params.getamount(params.data);
    return true;
  }
}
