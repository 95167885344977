export interface IChatConfig {
  helpIQ: string;
  sfDomain: string;
  regAddon: string;
  endId: string;
  endLanguage: string;
}

export const ChatConfig = {
  AU_Live: {
    helpIQ: '',
    sfDomain: 'community.titlex.com.au',
    regAddon: 'AU_TitleX_LiveAgent_Pre_Chat_Form',
    endId:
      'community.titlex.com.au/AU_TitleX_LiveAgent_Custom_Chat' +
      '?language=#deployment_id=572w0000000TQWY&org_id=00Dw0000000lMiE&button_id=573w0000000TQwJ',
    endLanguage: 'en_US',
  },
  UK_Live: {},
  US_Live: {},
  AU_Test: {
    helpIQ: 'au/apex/',
    sfDomain: 'rafal-leap.cs88.force.com',
    regAddon: 'AU_LEAP_LiveAgent_HelpIQ',
    endId:
      'rafal-leap.cs88.force.com/au/apex/AU_LEAP_LiveAgent_Custom_Chat' +
      '?language=#deployment_id=572w0000000L0wm&org_id=00D9E0000000kFA&button_id=5739E00000000MN',
    endLanguage: 'en_US',
  },
  UK_Test: {},
  US_Test: {},
  DisplayOption: {
    ChatWidth: 370,
    ChatHeight: 800,
  },
};
