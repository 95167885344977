import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from '@ag-grid-community/angular';
import { getObjValue } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-grid-loading-overlay',
  templateUrl: './grid-loading-overlay.component.html',
})
export class GridLoadingOverlayComponent implements ILoadingOverlayAngularComp {
  public background = 'bg-white';

  constructor() {}

  agInit(params: any): void {
    this.background = getObjValue(params, 'background', this.background);
  }
}
