export enum TimerAction {
  Play = 'play',
  Stop = 'stop',
}

export enum TimerRecordingAction {
  Discard = 'discard',
  Start = 'start',
  Stop = 'stop',
}
