import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { selectFirmDetails } from '@app/core/store';
import { FirmDetails } from '@app/shared/models';
import { LogService } from '@app/core/services';
import { getObjValue } from '../../../../../server/modules/shared/functions/common-util.functions';

@Pipe({ name: 'scCurrency' })
export class ScCurrencyPipe implements PipeTransform, OnDestroy {
  private _currencyCodeSub: Subscription;
  private _currencyCode: string;

  constructor(private _currencyPipe: CurrencyPipe, private _store: Store<any>, private _logSvc: LogService) {
    this._currencyCode = 'AUD';
    this._currencyCodeSub = this._store
      .pipe(
        select(selectFirmDetails),
        map((firmDetails: FirmDetails) => getObjValue(firmDetails, 'currency.code', 'AUD'))
      )
      .subscribe((currencyCode: string) => {
        this._currencyCode = currencyCode;
      });
  }

  ngOnDestroy(): void {
    this._currencyCodeSub.unsubscribe();
  }

  transform(value: string | number): string {
    // https://github.com/angular/angular/issues/22519 - always explicitly set currency
    try {
      const result = this._currencyPipe.transform(value, this._currencyCode, 'symbol-narrow');
      return !!result && result.charAt(0) === '-' ? `(${result.substring(1, result.length)})` : result;
    } catch (e) {
      this._logSvc.error(e);
      return '0.00';
    }
  }
}
