import { concatMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, Observable, of } from 'rxjs';

import * as folderActions from '@app/features/+correspondence/store/actions/folder/folder-api.action';
import * as matterDetailsActions from '@app/features/+matter-details/store/actions/current-matter';
import * as cardDetailsActions from '@app/features/+card/store//actions/card-details';

import * as pubnubActions from '@app/core/store/actions/pubnub.action';
import { BrandService, CacheService, ConfigService, LogService } from '@app/core/services';
import { selectRouterUrl } from '@app/core/store/selectors';
import { CorrespondenceFetchMode } from '@app/features/+correspondence/constants';

@Injectable()
export class PubnubEffects {

  documentsUpdate$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(pubnubActions.PUBNUB_DOCUMENTS_UPDATE),
    map((action: pubnubActions.PubnubDocumentsUpdate) => action.payload),
    withLatestFrom(this._store.pipe(select(selectRouterUrl)), (payload, url) => ({ payload, url })),
    concatMap((stateData: { payload: string; url: string }) => {
      const { payload, url } = stateData;
      if (matterRegex.details.test(url)) {
        return from([
          new folderActions.ListCorrespondenceListStart({
            matterId: payload,
            fetchMode: CorrespondenceFetchMode.ForceDocuments,
          }),
        ]);
      }
      return from([]);
    })
  ));


  matterUpdate: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(pubnubActions.PUBNUB_MATTER_UPDATE),
    map((action: pubnubActions.PubnubMatterUpdate) => action.payload),
    withLatestFrom(this._store.pipe(select(selectRouterUrl)), (payload, url) => ({ payload, url })),
    concatMap((stateData: { payload: string; url: string }) => {
      const { payload, url } = stateData;
      if (matterRegex.list.test(url)) {
        return from([
          new matterDetailsActions.GetMatterCardsStart(payload),
          new matterDetailsActions.GetCurrentMatter({ matterId: payload }), // we need to update the currentMatter state if we get a pubnub matter_update
        ]);
      } else if (matterRegex.details.test(url)) {
        return from([
          new matterDetailsActions.GetMatterDetailsStart({ matterId: payload }),
          new matterDetailsActions.GetCurrentMatter({ matterId: payload }), // we need to update the currentMatter state if we get a pubnub matter_update
          new folderActions.ListCorrespondenceListStart({
            matterId: payload,
            fetchMode: CorrespondenceFetchMode.ForcePendingPrecedents,
          }),
        ]);
      }
      return from([]);
    })
  ));


  cardDetailsUpdate: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(pubnubActions.PUBNUB_CARD_DETAILS_UPDATE),
    map((action: pubnubActions.PubnubCardDetailsUpdate) => action.payload),
    withLatestFrom(this._store.pipe(select(selectRouterUrl)), (payload, url) => ({ payload, url })),
    switchMap((stateData: { payload: { id: string }; url: string }) => {
      const { payload, url } = stateData;
      return this.cacheSvc.clearCache({ context: 'cardId', objectId: payload.id }).pipe(
        mergeMap(() => of({ payload, url }))
      );
    }),
    concatMap((stateData: { payload: { id: string }; url: string }) => {
      const { payload, url } = stateData;
      const { id } = payload;
      if (cardRegex.list.test(url)) {
        return from([new cardDetailsActions.LoadCardDetailsStart({ id })]);
      }
      return from([]);
    })
  ));


  reloadLeapCalc = createEffect(() => this.actions$.pipe(
    ofType<pubnubActions.ReloadLeapCalc>(pubnubActions.RELOAD_LEAP_CALC),
    filter((action) => {
      const { brand, env, region } = action.payload;
      return (
        brand === this.brandSvc.brandName &&
        env === this.brandSvc.brandEnv &&
        region === this.configSvc.getEnvironmentRegion()
      );
    }),
    switchMap(() => this.cacheSvc.reloadLeapCalc())
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private _log: LogService,
    private _store: Store<any>,
    private cacheSvc: CacheService,
    private brandSvc: BrandService,
    private configSvc: ConfigService
  ) {
    this._log.init('pubnub.effects');
  }
}

const matterRegex = { list: /^\/matters(\(.+\).*)?\/?$/m, details: /^\/matters\/.+$/m };
const cardRegex = { list: /^\/cards(\(.+\).*)?\/?$/m, details: /^\/cards\/.+$/m };
