<div class="x-timer-dropdown-header">
  <div class="text-truncate">
    <p class="text-secondary mb-2">{{ details.fileNumber || '--' }}</p>
    <h5>{{ details.clientName || '--' }}</h5>
    <small class="text-secondary">Activity Code: {{ selectedActivityCode?.toUpperCase() || '--' }}</small>
  </div>
  <a href scPreventDefault *ngIf="showStop" (click)="onStop()" class="x-timer-icon text-danger">
    <sc-icon iconName="stop-circle-outline"></sc-icon>
  </a>
  <button href *ngIf="showDiscard" (click)="onDiscard()" class="x-btn-rounded btn-sm btn-outline-danger mr-1">
    Discard
  </button>
  <a href scPreventDefault *ngIf="showPlay" (click)="onPlay()" class="x-timer-icon text-success">
    <sc-icon iconName="play-circle"></sc-icon>
  </a>
  <button href *ngIf="showSave" (click)="onSave()" class="x-btn-rounded btn-outline-success btn-sm">Save</button>
</div>
