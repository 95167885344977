import { Action } from '@ngrx/store';

export const PUBNUB_DOCUMENTS_UPDATE = '[Pubnub] update documents';
export const PUBNUB_MATTER_UPDATE = '[Pubnub] update matter';
export const PUBNUB_CARD_DETAILS_UPDATE = '[Pubnub] update card';
export const RELOAD_LEAP_CALC = '[Pubnub] reload leap calc';

export class PubnubDocumentsUpdate implements Action {
  readonly type = PUBNUB_DOCUMENTS_UPDATE;
  constructor(public payload: string) {}
}

export class PubnubMatterUpdate implements Action {
  readonly type = PUBNUB_MATTER_UPDATE;
  constructor(public payload: string) {}
}

export class PubnubCardDetailsUpdate implements Action {
  readonly type = PUBNUB_CARD_DETAILS_UPDATE;
  constructor(public payload: { id: string }) {}
}

export class ReloadLeapCalc implements Action {
  readonly type = RELOAD_LEAP_CALC;

  constructor(public payload: { brand: string; env: string; region: string }) {}
}

export type PubnubActions = PubnubDocumentsUpdate | PubnubMatterUpdate | PubnubCardDetailsUpdate | ReloadLeapCalc;
