import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  catchError,
  exhaustMap,
  filter,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
  delayWhen,
  concatMap,
} from 'rxjs/operators';
import { of } from 'rxjs';

import { selectCurrentMatterId, selectCurrentStaff, selectRouterSnapshot } from '@app/core/store';
import { TimeFeeService } from '@app/features/+time-fee-ledger/services';
import { ToastrService } from 'ngx-toastr';
import * as timeFeeActions from '../actions/time-fee';
import * as timeFeeEntryActions from '../actions/time-fee-entry';
import * as timeFeeSelectors from '../selectors';
import * as taskCodeActions from '@app/features/+task-code-list-selector/store/actions';
import * as feeTimerActions from '../../../fee-timer/store/actions';
import { selectTaskCodes, selectTaskCodeSelectorResponse } from '@app/features/+task-code-list-selector/store';
import {
  arrayToObj,
  differenceBetweenArrays,
  isEmptyValue,
} from '@server/modules/shared/functions/common-util.functions';
import { Staff, StaffSecurityIds } from '@app/shared/models';
import { ITimeFeeSummary, TimerRecordingAction } from '../../models';
import { AnalyticsService, EventBusService } from '@app/core/services';
import { AnalyticsCategories } from '@app/core/constants/analytics.constant';
import { AppApiService } from '@app/core/api';
import { LeapAppService, MatterAddinService } from '@app/features/matter-addin/services';
import { LeapAppGridId, ViewLeapAppLocationId } from '@app/features/matter-addin/models';
import { selectCheckedTimeFeeEntries } from '../selectors';
import { DialogService } from '@app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { ESiriusEvents } from '@app/core/models';
import { BillingMode } from '@app/features/accounting/models';
import {
  selectFeeTimerDuration,
  selectFeeTimerCurrentTimeElapsedState,
  selectIsNewEntry,
  selectRunningTimerFeeGUID,
} from '@app/features/fee-timer/store/selectors';

@Injectable()
export class TimeFeeEffects {
  listTimeAndFees$: any = createEffect(() =>
    this.actions$.pipe(
      ofType(timeFeeActions.LIST_TIME_FEE_START),
      withLatestFrom(
        this._store.pipe(select(selectCurrentMatterId)),
        this._store.pipe(select(selectCurrentStaff)),
        (action, matterId, staff) => ({ action, matterId, staff })
      ),
      switchMap((stateData: { action: timeFeeActions.ListTimeFeeStart; matterId: string; staff: Staff }) =>
        this._timeFeeSvc
          .getTimeAndFees(isEmptyValue(stateData.action.payload) ? stateData.matterId : stateData.action.payload)
          .pipe(
            mergeMap((timeFeeData: ITimeFeeSummary[]) => {
              const canViewOtherStaffMembersTimeEntries =
                stateData.staff.StaffSecurities.filter(
                  (x) => x.SecurityId === StaffSecurityIds.VIEW_OTHER_STAFF_MEMBERS_TIME_ENTRIES
                ).length === 0;

              if (!canViewOtherStaffMembersTimeEntries) {
                const filteredTimeFeeData = timeFeeData.filter((x) => x.WorkDoneByStaffGUID === stateData.staff.__id);

                return [new timeFeeActions.ListTimeFeeSuccess(filteredTimeFeeData)];
              }

              return [new timeFeeActions.ListTimeFeeSuccess(timeFeeData)];
            }),
            catchError((error) => of(new timeFeeActions.ListTimeFeeFailure(error)))
          )
      )
    )
  );

  timeFeeNewFeeEntryStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(timeFeeActions.TIME_FEE_NEW_FEE_ENTRY_START),
      exhaustMap(() => [
        new timeFeeActions.OpenEntryModal({ entryType: 'fee' }),
        new timeFeeActions.TimeFeeNewFeeEntrySuccess(null),
      ])
    )
  );

  timeFeeNewTimeEntryStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(timeFeeActions.TIME_FEE_NEW_TIME_ENTRY_START),
      withLatestFrom(
        this._store.pipe(select(selectFeeTimerCurrentTimeElapsedState)),
        this._store.pipe(select(selectRunningTimerFeeGUID)),
        this._store.pipe(select(selectIsNewEntry)),
        (action, state, feeGUID, isNewEntry) => ({ state, feeGUID, isNewEntry })
      ),
      tap(({ state, feeGUID, isNewEntry }) => {
        if ((feeGUID && isNewEntry) || (!feeGUID && !isNewEntry)) {
          this._store.dispatch(new feeTimerActions.FeeTimerTimeElapsedDiscard(null));
          this._store.dispatch(new feeTimerActions.FeeTimerActivityCodeUpdate(null));
        }
        if (state === TimerRecordingAction.Start) {
          this._store.dispatch(new feeTimerActions.FeeTimerTimeElapsedUpdate(null));
        }
      }),
      mergeMap(() => [
        new feeTimerActions.SetFeeTimer({ runningTimerFeeGUID: undefined, isNewEntry: true }),
        new timeFeeActions.OpenEntryModal({ entryType: 'time' }),
        new timeFeeActions.TimeFeeNewTimeEntrySuccess(null),
      ])
    )
  );

  setBillingMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<timeFeeEntryActions.SetBillingMode>(timeFeeEntryActions.TimeFeeEntryActionTypes.SET_BILLING_MODE),
      withLatestFrom(
        this._store.select(selectCurrentMatterId),
        this._store.select(selectCheckedTimeFeeEntries),
        (action, matterId, selected) => ({ billingMode: action.payload, matterId, selected })
      ),
      concatMap((data) => {
        const { billingMode, matterId, selected } = data;
        const unbilledItems = this.getUnbilledSummaryItems(
          selected,
          'Following items will not be set as they have been billed'
        );
        return this._timeFeeSvc.setBillingMode(matterId, unbilledItems?.map((t) => t.FeeGUID) || [], billingMode).pipe(
          exhaustMap((res) => [
            this._timeFeeSvc.getAccountingSaveAction(
              res,
              () =>
                new timeFeeActions.ShowSuccessToastMsg(
                  `Selected time/fee entries have been successfully ${this._translateSvc
                    .instant(
                      `TimeFee.List.ContextMenu.${Object.keys(BillingMode).find(
                        (key) => BillingMode[key] === billingMode
                      )}`
                    )
                    .toLowerCase()}.`
                ),
              () => new timeFeeActions.ShowFailureToastMsg('Unable to update billing mode')
            ),
            new timeFeeActions.StoreCheckedTimeFeeEntries([]),
          ])
        );
      })
    )
  );

  initTaskCodeModal = createEffect(() =>
    this.actions$.pipe(
      ofType<timeFeeActions.InitTaskCodeModal>(timeFeeActions.INIT_TASK_CODE_MODAL),
      withLatestFrom(
        this._store.select(selectCurrentMatterId),
        this._store.select(selectCheckedTimeFeeEntries),
        (action, matterId, selected) => ({ matterId, selected })
      ),
      concatMap((data) => {
        const { matterId, selected } = data;

        if (selected.length === 0) {
          return [];
        }

        if (selected.some(({ Timed }) => !Timed) && selected.some(({ Timed }) => !!Timed)) {
          this._dialogSvc.error({ message: 'Please select either Time or Fee entries only' });
          return [];
        }

        return this._timeFeeSvc.getNewTimeFeeInitData(matterId).pipe(
          exhaustMap((res) => {
            const taskCodes = res.TaskCodeList?.filter((x) => x.Timed === selected[0].Timed) || [];
            return [
              new taskCodeActions.TaskCodeSelectorAddTaskCodes(taskCodes),
              new taskCodeActions.TaskCodeSelectorSetUiConfig({
                searchPlaceHolder: 'FeeEntry.ActivityCode.Search.Placeholder',
                title: this._translateSvc.instant('FeeEntry.ActivityCode.Title'),
                label: 'FeeEntry.ActivityCode.Label',
                noGridOverlay: 'activityCodeList',
              }),
              new timeFeeActions.InitTaskCodeModalSuccess(null),
            ];
          }),
          catchError((err) => of(new timeFeeActions.ShowFailureToastMsg('Unable to open task code modal')))
        );
      })
    )
  );

  initTaskCodeModalSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<timeFeeActions.InitTaskCodeModalSuccess>(timeFeeActions.INIT_TASK_CODE_MODAL_SUCCESS),
      delayWhen(() => this._store.select(selectTaskCodes).pipe(filter((codes) => !!codes))),
      withLatestFrom(this._store.select(selectRouterSnapshot), (action, snapshot) => ({ snapshot })),
      exhaustMap((data) => {
        const { snapshot } = data;

        const outlets = arrayToObj(
          snapshot.children
            ?.filter((child) => child.outlet !== 'primary')
            .map((child) => [child.outlet, [child.url[0].path]])
        );

        this._appApiSvc.navigate({
          path: [{ outlets: { ...outlets, selector: ['task-code-list'] } }],
          extras: { queryParamsHandling: 'merge' },
        });

        return of(new timeFeeEntryActions.SetTaskCode(null));
      })
    )
  );

  setTaskCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<timeFeeEntryActions.SetTaskCode>(timeFeeEntryActions.TimeFeeEntryActionTypes.SET_TASK_CODE),
      delayWhen(() => this._store.select(selectTaskCodeSelectorResponse).pipe(filter((response) => !!response))),
      withLatestFrom(
        this._store.select(selectCurrentMatterId),
        this._store.select(selectTaskCodeSelectorResponse),
        this._store.select(selectCheckedTimeFeeEntries),
        (action, matterId, response, selected) => ({ matterId, taskCode: response, selected })
      ),
      concatMap((data) => {
        const { matterId, taskCode, selected } = data;
        const unbilledItems = this.getUnbilledSummaryItems(
          selected,
          'Following items will not be set as they have been billed'
        );
        return this._timeFeeSvc
          .setActivityCode(matterId, unbilledItems?.map((x) => x.FeeGUID) || [], taskCode.TaskCodeGUID)
          .pipe(
            exhaustMap((res) =>
              of(
                this._timeFeeSvc.getAccountingSaveAction(
                  {
                    ...res,
                    TaxCodeGUID: taskCode.TaxCodeGUID,
                  },
                  () => new timeFeeActions.ShowSuccessToastMsg('Task code updated'),
                  () => new timeFeeActions.ShowFailureToastMsg('Unable to update task code')
                )
              )
            )
          );
      })
    )
  );

  checkSetDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType<timeFeeActions.CheckSetDeleted>(timeFeeActions.CHECK_SET_DELETED),
      withLatestFrom(this._store.select(selectCheckedTimeFeeEntries), (action, selected) => ({
        deleted: action.payload,
        selected,
      })),
      exhaustMap((data) => {
        const { deleted, selected } = data;
        const unbilledItems = this.getUnbilledSummaryItems(
          selected,
          `Following items will not be ${deleted ? 'deleted' : 'undeleted'} as they have been billed`
        );
        if (!deleted) {
          return of(new timeFeeEntryActions.SetDeleted(deleted));
        }

        const prefix = unbilledItems.length > 1 ? 'Multiple' : 'Single';
        let type = '';
        if (unbilledItems.every(({ Timed }) => !!Timed)) {
          type = 'Time';
        } else if (unbilledItems.every(({ Timed }) => !Timed)) {
          type = 'Fee';
        }
        const title = this._translateSvc.instant(`TimeFee.List.SelectedItems.Delete.${prefix}.Title`, { type });
        const message = this._translateSvc.instant(`TimeFee.List.SelectedItems.Delete.${prefix}.Message`, {
          type: type?.toLowerCase(),
        });

        this._dialogSvc.confirm({
          title,
          message,
          actionText: this._translateSvc.instant('Core.Button.Confirm'),
          closeText: this._translateSvc.instant('Core.Button.Cancel'),
          showCancel: true,
          onClose: (confirmed: boolean) => {
            if (confirmed) {
              this._store.dispatch(new timeFeeEntryActions.SetDeleted(deleted));
            }
          },
        });
        return [];
      })
    )
  );

  setDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType<timeFeeEntryActions.SetDeleted>(timeFeeEntryActions.TimeFeeEntryActionTypes.SET_DELETED),
      withLatestFrom(
        this._store.select(selectCurrentMatterId),
        this._store.select(selectCheckedTimeFeeEntries),
        (action, matterId, selected) => ({ deleted: action.payload, matterId, selected })
      ),
      concatMap((data) => {
        const { deleted, matterId, selected } = data;
        const unbilledItems = this.getUnbilledSummaryItems(selected);
        return this._timeFeeSvc.setDeleted(matterId, unbilledItems?.map((x) => x.FeeGUID) || [], deleted).pipe(
          exhaustMap((res) => [
            this._timeFeeSvc.getAccountingSaveAction(
              res,
              () =>
                new timeFeeActions.ShowSuccessToastMsg(
                  `Selected time/fee entries have been successfully ${deleted ? 'deleted' : 'undeleted'}.`
                ),
              () =>
                new timeFeeActions.ShowFailureToastMsg(`Unable to delete ${selected.length > 1 ? 'entries' : 'entry'}`)
            ),
            new timeFeeActions.StoreCheckedTimeFeeEntries([]),
          ])
        );
      })
    )
  );

  initMoveMatter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(timeFeeEntryActions.TimeFeeEntryActionTypes.INIT_MOVE_ENTRY_MODAL),
      withLatestFrom(this._store.select(selectCurrentMatterId), (action, matterId) => ({ matterId })),
      mergeMap((data) => {
        const { matterId } = data;
        this._appApiSvc.navigate({
          path: [{ outlets: { selector: ['matter-list'] } }],
          query: { dmid: matterId },
          extras: { skipLocationChange: true },
        });
        return of(new timeFeeEntryActions.MoveEntry(null));
      })
    )
  );

  moveEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType<timeFeeEntryActions.MoveEntry>(timeFeeEntryActions.TimeFeeEntryActionTypes.MOVE_ENTRY),
      delayWhen(() =>
        this._eventBusSvc.listen(ESiriusEvents.GetSelectedMatter).pipe(filter(({ matterEntry }) => !!matterEntry))
      ),
      withLatestFrom(
        this._eventBusSvc.listen(ESiriusEvents.GetSelectedMatter),
        this._store.select(selectCurrentMatterId),
        this._store.select(selectCheckedTimeFeeEntries),
        (action, { matterEntry }, matterId, selected) => ({ matterEntry, matterId, selected })
      ),
      concatMap((data) => {
        const { matterEntry, matterId, selected } = data;
        if (matterEntry.deleteCode === 0 && !matterEntry.isArchived) {
          const unbilledItems = this.getUnbilledSummaryItems(
            selected,
            `Following items will not be set as they have been billed`
          );
          return this._timeFeeSvc
            .moveToMatter(matterId, unbilledItems?.map((x) => x.FeeGUID) || [], matterEntry.matterId)
            .pipe(
              exhaustMap((res) =>
                of(
                  this._timeFeeSvc.getAccountingSaveAction(
                    res,
                    () =>
                      new timeFeeActions.ShowSuccessToastMsg(
                        `Selected time/fee entries have been successfully moved to matter ${matterEntry.fileNumber}`
                      ),
                    () =>
                      new timeFeeActions.ShowFailureToastMsg(
                        `Selected time/fee entries are unable to moved to matter ${matterEntry.fileNumber}`
                      )
                  )
                )
              )
            );
        }
      })
    )
  );

  openEntryModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<timeFeeActions.OpenEntryModal>(timeFeeActions.OPEN_ENTRY_MODAL),
        withLatestFrom(
          this._store.select(selectCurrentMatterId),
          (action, matterId) => ({ ...action.payload, matterId })
        ),
        tap((data) => {
          const { summary, entryType, matterId } = data;
          let query: any = { matterNumber: matterId };
          if (!!summary?.FeeGUID) {
            query = { feeGUID: summary.FeeGUID, ...query };
          }
          if (
            !!summary &&
            ((!!summary.ExternalJSON && !!summary.ExternalURL) || !!summary.InvoiceItemGUID || !!summary.Deleted)
          ) {
            this._analyticsSvc.eventTrack({ category: AnalyticsCategories.TimeFeeList, action: `Open ${entryType}` });
            if (!!summary.InvoiceItemGUID || !!summary.Deleted) {
              if (entryType === 'time') {
                this._appApiSvc.readonlyTimeEntry(query);
              } else {
                this._appApiSvc.readonlyFee(query);
              }
            } else {
              this._matterAddinSvc.openAddinRecord(summary);
            }
          } else {
            this._analyticsSvc.eventTrack({ category: AnalyticsCategories.TimeFeeSidebar, action: `New ${entryType}` });
            if (entryType === 'time') {
              this._appApiSvc.newTimeEntry(query);
            } else {
              this._appApiSvc.newFee(query);
            }
          }
        })
      ),
    { dispatch: false }
  );

  handleLeapAddinItemClick$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<timeFeeActions.HandleLeapAddInItemClick>(timeFeeActions.HANDLE_LEAP_ADD_IN_ITEM_CLICK),
        withLatestFrom(this._store.select(timeFeeSelectors.selectCheckedTimeFeeEntries), (action, selected) => ({
          ...action.payload,
          selected,
        })),
        tap((data) => {
          const { leapApp, contextItem, selected } = data;

          this._analyticsSvc.eventTrack({
            category: AnalyticsCategories.GridLeapApp,
            action: `Open ${contextItem.label}`,
            label: leapApp.name,
          });

          this._leapAppSvc.openViewLeapAppAddin({
            appId: leapApp.id,
            functionGroupId: contextItem.functionGroupId,
            gridId: LeapAppGridId.TimeFeeList,
            leapAppLocationId: ViewLeapAppLocationId.TimeFeeList,
            payload: selected,
            windowType: contextItem.windowType,
            url: contextItem.url,
          });
        })
      ),
    { dispatch: false }
  );

  showToastMsg$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<timeFeeActions.ShowFailureToastMsg | timeFeeActions.ShowSuccessToastMsg>(
          timeFeeActions.SHOW_SUCCESS_TOAST_MSG,
          timeFeeActions.SHOW_FAILURE_TOAST_MSG
        ),
        tap((action) => {
          const title = action.success ? 'Success' : 'Error';
          this._toastrSvc.show(action.payload, title, {}, title.toLowerCase());
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private _timeFeeSvc: TimeFeeService,
    private _store: Store<any>,
    private _toastrSvc: ToastrService,
    private _dialogSvc: DialogService,
    private _analyticsSvc: AnalyticsService,
    private _appApiSvc: AppApiService,
    private _matterAddinSvc: MatterAddinService,
    private _leapAppSvc: LeapAppService,
    private _translateSvc: TranslateService,
    private _eventBusSvc: EventBusService
  ) {}

  private getUnbilledSummaryItems(items: ITimeFeeSummary[], message?: string) {
    const billedItems = items.filter(({ InvoiceItemGUID }) => !!InvoiceItemGUID);
    if (billedItems.length > 0 && !!message) {
      this._toastrSvc.warning(
        `${message} - ${billedItems.map(({ TransactionNumber }) => TransactionNumber).join('</br>')}`
      );
    }
    return differenceBetweenArrays(items, billedItems);
  }
}
