<sc-main-header [mainTitle]="matterTitle$ | async" [walkmeReady]="walkmeReady$ | async"></sc-main-header>

<sc-matter-toolbar
  *ngIf="showMatterToolbar"
  [matter]="currentMatter$ | async"
  [superDiaryEnabled]="superDiaryEnabled"
  [toolbarAction]="toolbarAction$ | async"
  [isAsideExpanded]="isAsideExpanded"
></sc-matter-toolbar>

<sc-recurring-matter-toolbar *ngIf="showRecurringMatterToolbar" [recurringMatterId]="recurringMatterId">
</sc-recurring-matter-toolbar>

<router-outlet (activate)="setPrimaryPageName()"></router-outlet>
