import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  BulkUpdateEntry,
  BulkUpdateRequest,
  CreateInitDTO,
  CreateRequest,
  SpecialFee,
  SpecialFeeLevel,
  SpecialFeeListEntry,
  SpecialFeeListEntryDTO,
  SpecialRate,
  SpecialRateDTO,
  SpecialRateScore,
  UpdateRequest,
} from '@app/features/+time-fee-ledger/models';
import { SpecialFeeHttpService } from '../special-fee-http/special-fee-http.service';
import { v4 as uuidv4 } from 'uuid';
import { map, switchMap } from 'rxjs/operators';
import { AccountingUpdateResponse } from '@app/features/accounting/models';
import { StaffService } from '@app/core/services';
import { ITaskCode } from '@app/shared/models';
import { formatStringToDate } from '@app/shared/utils';
import { isEmptyArray, sortDescBy } from '../../../../../server/modules/shared/functions/common-util.functions';

@Injectable()
export class SpecialFeeService {
  constructor(private _httpSvc: SpecialFeeHttpService, private _staffSvc: StaffService) {}

  public readonly taskCodeAllOptionId: string = '0'; // we need to set this as 0 for ng-select, as ng-select expect an id value that is not null

  createSpecialFee(options = {} as any): SpecialFeeListEntry {
    const id: string = options.SpecialFeeGUID || '';
    return {
      SpecialFeeGUID: id,
      Deleted: options.Deleted || false,
      DeletedDate: options.DeletedDate || options.Deleted ? new Date() : null,
      DeletedDetails: options.DeletedDetails || '',
      Name: options.Name || '',
      Rate: options.Rate || 0,
      RateID: options.RateID || 1,
      RateType: options.RateType || 'A',
      RowVers: options.RowVers !== undefined && options.RowVers !== null ? options.RowVers : -1,
      Selected: options.Selected || false,
      StaffGUID: options.StaffGUID === this._staffSvc.allOptionId ? null : options.StaffGUID || null,
      StaffName: options.StaffName || '',
      TaskCode: options.TaskCode || 'All',
      TaskCodeGUID: options.TaskCodeGUID === this.taskCodeAllOptionId ? null : options.TaskCodeGUID || null,
      Initials: options.Initials || 'All',
    } as SpecialFeeListEntry;
  }

  get isSpecialFeesEnabled(): boolean {
    // TODO return this.featureControlService.isEnabled(app.services.Feature.SpecialFees);
    return true;
  }

  init(): Observable<CreateInitDTO> {
    return this._httpSvc.init().pipe(
      map(
        (response) =>
          ({
            TaskCodeList: response.TaskCodeList,
            RateTypeList: response.RateTypeList,
          } as CreateInitDTO)
      )
    );
  }

  selectPreferredSpecialRate(taskCodeId: string, staffId: string, specialRates: SpecialRate[]): SpecialRate {
    if (isEmptyArray(specialRates) || !this.isSpecialFeesEnabled) {
      return null;
    }

    const unsortedRates = specialRates
      .map((s) => ({ ...s, score: SpecialRateScore.Mismatched }))
      .map((s) => {
        if (s.StaffGUID) {
          if (s.StaffGUID === staffId) {
            s.score = SpecialRateScore.StaffMatched;
          } else {
            s.score = SpecialRateScore.Mismatched;
            return s;
          }
        } else {
          s.score = SpecialRateScore.Wildcard;
        }

        if (s.TaskCodeGUID) {
          if (s.TaskCodeGUID === taskCodeId) {
            s.score += SpecialRateScore.TaskMatched;
          } else {
            s.score = SpecialRateScore.Mismatched;
            return s;
          }
        } else {
          s.score += SpecialRateScore.Wildcard;
        }

        return s;
      });

    const rates = sortDescBy(unsortedRates, (c) => c.score);

    const preferredRate = rates?.length > 0 && rates[0];
    return preferredRate?.score > 0 ? preferredRate : undefined;
  }

  getApplicableSpecialRates(matterGUID: string, debtorGUID): Observable<SpecialRate[]> {
    return this._httpSvc.getMatterSpecialRateLevel(matterGUID).pipe(
      switchMap((level) => {
        if (level === SpecialFeeLevel.Debtor) {
          return this._httpSvc.getSpecialRatesForCard(debtorGUID);
        } else if (level === SpecialFeeLevel.Matter) {
          return this._httpSvc.getSpecialRatesForMatter(matterGUID);
        } else {
          return observableOf([]);
        }
      }),
      map((rateDtos) => rateDtos.map((r) => this.fromSpecialRateDTO(r)))
    );
  }

  private fromSpecialRateDTO(s: SpecialRateDTO): SpecialRate {
    if (s) {
      return { ...s } as any;
    }

    return {} as any;
  }

  getSpecialFeeLevel(matterId: string): Observable<number> {
    return this._httpSvc.getMatterSpecialRateLevel(matterId);
  }

  setSpecialFeeLevel(matterId: string, level: number): Observable<AccountingUpdateResponse> {
    return this._httpSvc.setMatterSpecialRateLevel(matterId, level);
  }

  setSpecialRatesForMatter(matterId: string, currentSpecialFeeIds: string[]): Observable<AccountingUpdateResponse> {
    return this._httpSvc.setSpecialRatesForMatter(matterId, currentSpecialFeeIds);
  }

  setSpecialRatesForCard(cardId: string, currentSpecialFeeIds: string[]): Observable<AccountingUpdateResponse> {
    return this._httpSvc.setSpecialRatesForCard(cardId, currentSpecialFeeIds);
  }

  listSpecialFees(deleted: boolean): Observable<SpecialFeeListEntry[]> {
    return this._httpSvc
      .list(deleted)
      .pipe(
        map(
          (response: SpecialFeeListEntryDTO[]) =>
            response?.map((entry) => formatStringToDate<SpecialFeeListEntry>(entry as any)) || []
        )
      );
  }

  getSpecialRatesForCard(cardId: string): Observable<SpecialRate[]> {
    return this._httpSvc
      .getSpecialRatesForCard(cardId)
      .pipe(map((rateDtos: any) => rateDtos.map((r) => this.fromSpecialRateDTO(r))));
  }

  getSpecialRatesForMatter(matterId: string): Observable<SpecialRate[]> {
    return this._httpSvc
      .getSpecialRatesForMatter(matterId)
      .pipe(map((rateDtos: any) => rateDtos.map((r) => this.fromSpecialRateDTO(r))));
  }

  save(data: SpecialFee) {
    const request = {
      Name: data.Name,
      Rate: data.Rate,
      RateID: data.RateID,
      Deleted: data.Deleted,
      DeletedDate: data.DeletedDate ? new Date().toString() : undefined,
      RowVers: data.RowVers,
      SpecialFeeGUID: data.SpecialFeeGUID || uuidv4(),
      StaffGUID: data.StaffGUID === this._staffSvc.allOptionId ? null : data.StaffGUID,
      TaskCodeGUID: data.TaskCodeGUID === this.taskCodeAllOptionId ? null : data.TaskCodeGUID,
      WarningAcknowledgments: [],
    } as CreateRequest;
    this._httpSvc.save(request);
  }

  update(data: SpecialFee) {
    const request = {
      Name: data.Name,
      Rate: data.Rate,
      RateID: data.RateID,
      Deleted: data.Deleted,
      DeletedDate: data.DeletedDate ? data.DeletedDate.toString() : undefined,
      RowVers: data.RowVers,
      SpecialFeeGUID: data.SpecialFeeGUID,
      StaffGUID: data.StaffGUID === this._staffSvc.allOptionId ? null : data.StaffGUID,
      TaskCodeGUID: data.TaskCodeGUID === this.taskCodeAllOptionId ? null : data.TaskCodeGUID,
      WarningAcknowledgments: [],
    } as UpdateRequest;
    this._httpSvc.updateSingle(request);
  }

  deleteSingle(data: SpecialFee) {
    data.Deleted = true;
    data.DeletedDate = new Date();

    this.update(data);
  }

  bulkSetStaff(staffId: string, specialFees: SpecialFee[]) {
    const specialFeeEntries =
      specialFees?.map(
        (s) =>
          ({
            Deleted: s.Deleted,
            DeletedDate: s.DeletedDate ? new Date().toString() : undefined,
            Name: s.Name,
            Rate: s.Rate,
            RateID: s.RateID,
            RowVersion: s.RowVers,
            SpecialFeeGUID: s.SpecialFeeGUID,
            StaffGUID: staffId,
            TaskCodeGUID: s.TaskCodeGUID,
          } as BulkUpdateEntry)
      ) || [];
    const request = {
      SpecialFeeList: specialFeeEntries,
    } as BulkUpdateRequest;
    this._httpSvc.updateBulk(request);
  }

  bulkSetTaskcode(taskCodeId: string, specialFees: SpecialFee[]) {
    const specialFeeEntries =
      specialFees?.map(
        (s) =>
          ({
            Deleted: s.Deleted,
            DeletedDate: s.DeletedDate ? new Date().toString() : undefined,
            Name: s.Name,
            Rate: s.Rate,
            RateID: s.RateID,
            RowVersion: s.RowVers,
            SpecialFeeGUID: s.SpecialFeeGUID,
            StaffGUID: s.StaffGUID,
            TaskCodeGUID: taskCodeId,
          } as BulkUpdateEntry)
      ) || [];
    const request = {
      SpecialFeeList: specialFeeEntries,
    } as BulkUpdateRequest;
    this._httpSvc.updateBulk(request);
  }

  bulkDelete(specialFees: SpecialFee[]) {
    const deletedDate = new Date().toString();
    const specialFeeEntries =
      specialFees?.map(
        (s) =>
          ({
            Deleted: true,
            DeletedDate: deletedDate,
            Name: s.Name,
            Rate: s.Rate,
            RateID: s.RateID,
            RowVersion: s.RowVers,
            SpecialFeeGUID: s.SpecialFeeGUID,
            StaffGUID: s.StaffGUID,
            TaskCodeGUID: s.TaskCodeGUID,
          } as BulkUpdateEntry)
      ) || [];
    const request = {
      SpecialFeeList: specialFeeEntries,
    } as BulkUpdateRequest;
    this._httpSvc.bulkDelete(request);
  }

  bulkUndelete(specialFees: SpecialFee[]) {
    const specialFeeEntries =
      specialFees?.map(
        (s) =>
          ({
            Deleted: false,
            DeletedDate: undefined,
            Name: s.Name,
            Rate: s.Rate,
            RateID: s.RateID,
            RowVersion: s.RowVers,
            SpecialFeeGUID: s.SpecialFeeGUID,
            StaffGUID: s.StaffGUID,
            TaskCodeGUID: s.TaskCodeGUID,
          } as BulkUpdateEntry)
      ) || [];

    const request = {
      SpecialFeeList: specialFeeEntries,
    } as BulkUpdateRequest;
    this._httpSvc.updateBulk(request);
  }

  createTaskCodeListOptionAll(): ITaskCode {
    return { BillingDescription: 'All', NameFileAs: 'All', TaskCodeGUID: this.taskCodeAllOptionId } as ITaskCode;
  }
}
