import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { GridApi, IServerSideDatasource, IServerSideGetRowsParams } from '@ag-grid-community/core';
import { catchError, EMPTY, Observable, take, tap } from 'rxjs';

export interface OnServerSideRowModelConfigAPI {
    getData: (params: IServerSideGetRowsParams)
        => Observable<{ data; totalRecords }>;
    getDataError?: (err) => void;
    onDataUpdated: (data: any[], totalRecords: number) => void;
    gridApi: GridApi;
}

@Directive({
    selector: '[scGridServerSideRowModel]'
})
export class AgGridServerSideDirective {
    @Input()
    scGridServerSideRowModel: OnServerSideRowModelConfigAPI;

    @Output()
    gridServerSideRowModelReady = new EventEmitter();

    constructor() { }

    @HostListener('gridReady', ['$event'])
    gridReady(gridApi: { api: GridApi }) {
        gridApi.api.setGridOption('serverSideDatasource', this.dataSource);

        this.gridServerSideRowModelReady.emit(gridApi);
    }

    handleError = (err) => {
        this.scGridServerSideRowModel.getDataError(err);

        return EMPTY;
    };

    dataSource: IServerSideDatasource = {
        getRows: (params: IServerSideGetRowsParams) => {
            this.scGridServerSideRowModel
                .getData(params)
                .pipe(
                    tap(({ data, totalRecords }) => {
                        params.success({
                            rowData: data,
                            rowCount: totalRecords
                        });

                        this.scGridServerSideRowModel.onDataUpdated(data, totalRecords);
                    }),
                    catchError(err => this.handleError(err)),
                    take(1)
                )
                .subscribe();
        }
    };
}
