import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromNotification from '../reducers/notification.reducer';
import { selectCurrentMatter } from '@app/core/store/selectors/app-state.selectors';

import { DuplicatedFileNumber } from '@app/features/+matter-list/constants';
import { AppSlice } from '@app/core/store/app.slice';
import { groupBy } from '@server/modules/shared/functions/common-util.functions';

const selectNotificationState = createFeatureSelector<fromNotification.State>(AppSlice.Notification);

export const selectNotificationListLoaded = createSelector(selectNotificationState, (state: fromNotification.State) =>
  fromNotification.selectLoaded(state)
);

export const selectNotificationListStaffId = createSelector(selectNotificationState, (state: fromNotification.State) =>
  fromNotification.selectStaffId(state)
);

export const selectNotificationListFirmId = createSelector(selectNotificationState, (state: fromNotification.State) =>
  fromNotification.selectFirmId(state)
);

export const selectNotificationListLastEvaluatedKey = createSelector(
  selectNotificationState,
  (state: fromNotification.State) => fromNotification.selectLastEvaluatedKey(state)
);

export const selectNotificationListContinuationToken = createSelector(
  selectNotificationState,
  (state: fromNotification.State) => fromNotification.selectContinuationToken(state)
);

export const selectNotificationListMetaInform = createSelector(
  selectNotificationListStaffId,
  selectNotificationListFirmId,
  selectNotificationListContinuationToken,
  selectNotificationListLastEvaluatedKey,
  (staffId, firmId, continuationToken, lastEvaluatedKey) => ({
    staffId,
    firmId,
    continuationToken,
    lastEvaluatedKey,
  })
);

export const selectAllNotifications = createSelector(selectNotificationState, fromNotification.selectAllNotifications);

export const selectNotificationTotal = createSelector(
  selectNotificationState,
  fromNotification.selectNotificationTotal
);

export const selectNotificationEntities = createSelector(
  selectNotificationState,
  fromNotification.selectNotificationEntities
);

export const selectNotificationGroupEntities = createSelector(selectAllNotifications, (notifications) => {
  const notificationsWithGroupKey = Array.from(notifications || []).map((m) => ({
    ...m,
    groupKey: m.entity?.relatedEntityId || '', // matterId
  }));

  const grouped = groupBy(notificationsWithGroupKey, 'groupKey');

  return grouped;
});

export const selectCurrentMatterNotificationGroup = createSelector(
  selectCurrentMatter,
  selectNotificationGroupEntities,
  (matter, groupEntities) =>
    !!matter && !!groupEntities && !!groupEntities[matter.matterId]
      ? {
          notifications: groupEntities[matter.matterId],
          relatedEntityFileNumber: matter.fileNumber === DuplicatedFileNumber ? null : matter.fileNumber,
        }
      : undefined
);

export const selectCurrentMatterNotifications = createSelector(
  selectCurrentMatterNotificationGroup,
  (notificationGroup) =>
    !!notificationGroup && !!notificationGroup.notifications ? notificationGroup.notifications : []
);

export const selectCurrentMatterNotificationIds = createSelector(selectCurrentMatterNotifications, (notifications) => {
  if (!!notifications) {
    return notifications.map((notification) => notification.id);
  }
});

export const selectNotificationListSelectedNotificationId = createSelector(
  selectNotificationState,
  (state: fromNotification.State) => fromNotification.selectSelectedNotificationId(state)
);

export const selectNotificationProcessing = createSelector(selectNotificationState, (state: fromNotification.State) =>
  fromNotification.selectNotificationProcessing(state)
);

export const selectNotificationListShowAll = createSelector(selectNotificationState, (state: fromNotification.State) =>
  fromNotification.selectShowAll(state)
);

export const selectNotificationListSelectedNotification = createSelector(
  selectNotificationEntities,
  selectNotificationListSelectedNotificationId,
  (notificationEntities, notificationId) => !!notificationEntities && notificationId !== null ? notificationEntities[notificationId] : null
);

export const selectCurrentMatterNotificationTotal = createSelector(
  selectCurrentMatterNotificationGroup,
  (notificationGroup) =>
    !!notificationGroup && !!notificationGroup.notifications ? notificationGroup.notifications.length : 0
);

export const selectNotificationBellTotal = createSelector(
  selectCurrentMatter,
  selectCurrentMatterNotificationTotal,
  selectNotificationTotal,
  (currentMatter, currentMatterNotificationTotal, allNotificationTotal) =>
    !!currentMatter ? currentMatterNotificationTotal : allNotificationTotal
);

export const selectNotificationListNotifications = createSelector(
  selectCurrentMatter,
  selectCurrentMatterNotifications,
  selectAllNotifications,
  selectNotificationListShowAll,
  (currentMatter, currentMatterNotifications, allNotifications, showAll) =>
    !!currentMatter && !showAll ? currentMatterNotifications : allNotifications
);
