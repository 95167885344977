import { Action } from '@ngrx/store';
import { IOfficeAction, IDoc, IDocAttachment } from '@app/shared/models';
import { CorrespondenceDownloadProgress } from '../../../models';

export const TOGGLE_CORRESPONDENCE_LOADING = '[Loading correspondence] toggled';

export const STORE_CORRESPONDENCE_SELECTION = '[store checked/ unchecked correspondence] selection';
export const DESELECT_DOCUMENT_IN_GRID = '[deselect documents from correspodence] selection';
export const CLEAR_CORRESPONDENCE_SELECTION = '[clear grid] selection';

export const PREVIEW_DOCUMENT = '[Document] previewed';

export const EDIT_DOCUMENT_START = '[Edit document] start';
export const EDIT_DOCUMENT_ONLINE_START = '[Edit document] start online';
export const EDIT_DOCUMENT_SUCCESS = '[Edit document] success';
export const EDIT_DOCUMENT_FAILURE = '[Edit document] failure';
export const OPEN_EMAIL_IN_OUTLOOK = '[Email] open in outlook';

export const DOCUMENT_DOWNLOAD = '[Download document] correspondence download';
export const DOCUMENT_DOWNLOAD_INPROGRESS = '[Download document] correspondence download inprogress';
export const DOCUMENT_DOWNLOAD_SUCCESS = '[Download document] correspondence download success';
export const DOCUMENT_DOWNLOAD_FAILURE = '[Download document] correspondence download failure';

export class ToggleCorrespondenceLoading implements Action {
  readonly type = TOGGLE_CORRESPONDENCE_LOADING;
  constructor(public payload: undefined | boolean) {}
}

export class StoreCorrespondenceSelectedDocs implements Action {
  readonly type = STORE_CORRESPONDENCE_SELECTION;
  constructor(public payload: IDoc[]) {}
}

export class DeselectDocumentInGrid implements Action {
  readonly type = DESELECT_DOCUMENT_IN_GRID;
  constructor(public payload: string[]) {}
}

export class ClearCorrespondenceGrid implements Action {
  readonly type = CLEAR_CORRESPONDENCE_SELECTION;
  constructor(public payload: boolean) {}
}

export class PreviewDocument implements Action {
  readonly type = PREVIEW_DOCUMENT;
  constructor(public payload: IDoc | IDocAttachment) {}
}

export class EditDocumentStart implements Action {
  readonly type = EDIT_DOCUMENT_START;
  constructor(public payload: IOfficeAction) {}
}

export class EditDocumentOnlineStart implements Action {
  readonly type = EDIT_DOCUMENT_ONLINE_START;
  constructor(public payload: IOfficeAction) {}
}

export class EditDocumentSuccess implements Action {
  readonly type = EDIT_DOCUMENT_SUCCESS;
  constructor(public payload: any) {}
}

export class EditDocumentFailure implements Action {
  readonly type = EDIT_DOCUMENT_FAILURE;
  constructor(public payload: any) {}
}

export class OpenEmailInOutlook implements Action {
  readonly type = OPEN_EMAIL_IN_OUTLOOK;
  constructor(public payload: IDoc | IDocAttachment) {}
}

export class DocumentDownload implements Action {
  readonly type = DOCUMENT_DOWNLOAD;
  constructor(public payload) {}
}

export class DocumentDownloadInprogress implements Action {
  readonly type = DOCUMENT_DOWNLOAD_INPROGRESS;
  constructor(public payload: CorrespondenceDownloadProgress) {}
}

export class DocumentDownloadSuccess implements Action {
  readonly type = DOCUMENT_DOWNLOAD_SUCCESS;
  constructor(public payload: any) {}
}

export class DocumentDownloadFaillure implements Action {
  readonly type = DOCUMENT_DOWNLOAD_FAILURE;
  constructor(public payload: any) {}
}

export type CorrespondenceActions =
  | ToggleCorrespondenceLoading
  | StoreCorrespondenceSelectedDocs
  | DeselectDocumentInGrid
  | ClearCorrespondenceGrid
  | PreviewDocument
  | EditDocumentStart
  | EditDocumentSuccess
  | EditDocumentFailure
  | OpenEmailInOutlook
  | DocumentDownload
  | DocumentDownloadInprogress
  | DocumentDownloadSuccess
  | DocumentDownloadFaillure;
