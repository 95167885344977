import {
  IInvoiceAnticipatedDisbursementItem,
  IInvoiceCostRecoveryItem,
  IInvoiceFeeItem,
  IInvoiceItemNode,
  IInvoiceJournalItem,
  IInvoicePaymentItem,
} from '@app/features/+invoice/models/invoice-item.model';
import {
  IInvoiceTaskCodeSource,
  IInvoiceJsonSchema,
  IInvoiceLayoutConfig,
  IPrintColumn,
} from '@app/features/+invoice/models/invoice-response.model';
import { EAccountingTransaction, ITaxCode } from '@app/features/accounting/models';
import { ICardListEntry } from '@app/features/+card/models';
import { IMatterListEntry } from '@app/features/+matter-list/models';
import { Staff } from '@app/shared/models';

/** VM models **/
export interface IToolbarFormData {
  invoiceStatus: InvoiceApprovalStatus;
  layouts: IInvoiceLayout[];
  selectedLayoutGuid: string;
}

export interface IDetailsFormStatus {
  dateDisabled: boolean;
  dueDateDisabled: boolean;
  inputsDisabled: boolean;
  invoiceNumberDisabled: boolean;
  autoNumberHidden: boolean;
  dueDateHidden: boolean;
}

export interface IDetailsFormData {
  invoiceTo: string;
  splitInvoiceTo: string;
  cardId: string;
  date: Date;
  dueDate: Date;
  autoNumber: boolean;
  invoiceNumber: string;
}

export interface IGridFormData {
  includeTax: boolean;
  memo: string;
}

export interface IGridStatus {
  editable: boolean;
  includeTax?: boolean;
  memoDisabled?: boolean;
}

export interface IFooterFormData {
  printOnSave: boolean;
}

export interface IFooterFormStatus {
  printOnSaveHidden: boolean;
  deleteHidden: boolean;
  deleteLoading: boolean;
  deleteDisabled: boolean;
}

export interface IPropertiesFormStatus {
  staffHidden: boolean;
}
/** End - VM models **/

export enum InvoiceApprovalStatus {
  None = -1,
  DraftUnapproved = 0,
  DraftApproved = 1,
  Final = 3,
}

export interface IInvoiceLayout {
  layoutGUID: string;
  layoutName: string;
}

export interface IInvoiceSummary extends IToolbarFormData, IDetailsFormData, IGridFormData, IFooterFormData {
  matterId: string;
  semaphoreId: string;

  rowVers?: number;
  reversedOrReversal?: boolean;
  reversalDetails?: string;
  deleted?: boolean;
  splitCardGUID?: string[];
  createdByStaffId: string;
  createdDate: Date;
}

export interface IInvoiceItemSource {
  fees: IInvoiceFeeItem[];
  costRecoveries: IInvoiceCostRecoveryItem[];
  anticipatedDisbursements: IInvoiceAnticipatedDisbursementItem[];
  officeDisbursementJournals: IInvoiceJournalItem[];
  officePayments: IInvoicePaymentItem[];
}

export interface IInvoiceGridStatistics {
  totalIncTax?: number;
  totalExTax?: number;
  totalTax?: number;
  balanceDue?: number;
  invoiceBalanceDue?: number;
}

export interface IInvoiceStatistics extends IInvoiceGridStatistics {
  adjustmentAmount: number;
  paidAmount: number;
  trustBalance: number;
  ptfAmount: number;
  creditBalAmount: number;
}

export interface IInvoiceInitResponse {
  invoiceSummary: Partial<IInvoiceSummary>;
  invoiceSource: IInvoiceItemSource;
  taskCodeSource: IInvoiceTaskCodeSource;
  invoiceStatistics: Partial<IInvoiceStatistics>;
  taxCodes: ITaxCode[];
  printColumnsMapper: { [key: string]: IPrintColumn[] };

  // Existing invoice requirement
  invoiceJsonSchema?: IInvoiceJsonSchema;

  // New invoice requirement
  lastInvoiceNumber?: string;
  defaultInvoiceLayout?: IInvoiceLayoutConfig;

  disbursementsExceedFixedFeeAmount?: boolean;

  originalData?: any; // Raw init existing invoice response
  splitInvoiceTo?: InvoiceSplitCard[];
}

export interface IInvoiceLayoutInitResponse {
  invoiceItems: IInvoiceItemNode[];
  printColumnsMapper: { [key: string]: IPrintColumn[] };
}

export interface TaxSplit {
  taxCode: string;
  taxRatePercent: number;
  amountTax: number;
}

export interface IFeeAdjustmentData {
  adjustedFees: IInvoiceFeeItem[];
  totalFeesApplied: number;
}

export interface IFixedFeeCalculationData {
  adjustedFees?: IInvoiceFeeItem[];
  feesUnmodified?: boolean;
  disbursementsExceedFixedAmount?: boolean;
}

export interface IInvoiceRequestObjectParams {
  cards: ICardListEntry[];
  items: IInvoiceItemNode[];
  taxCodes?: ITaxCode[];
  invoiceId?: string;
  matter: IMatterListEntry;
  mode: 'create' | 'delete' | 'update' | 'reverse';
  originalData: any;
  printColumnsMapper: { [key: string]: IPrintColumn[] };
  staffs: Staff[];
  summary: IInvoiceSummary;
  splitTos?: InvoiceSplitCard[];
  semaphoreId: string;
}

export interface IInvoicePageStatus {
  canSave: boolean;
  dropdownSave: boolean;
  enableViewFeeAllocations: boolean;
  canReverse: boolean;
}

export interface InvoiceSplitCard {
  cardGUID: string;
  fullName: string;
  address: string;
  percent: number;
  amount: number;
  amountExTax: number;
  invoiceId?: string;
}

export interface InvoicePrintInform {
  invoiceNumber: string;
  invoiceGuid: string;
  matterGuid: string;
  nextAction: EAccountingTransaction | null;
  closeInvoicePage?: boolean;
}

export const GetInvoiceToTextFromSplitInvoice = (splitTos: InvoiceSplitCard[]): string =>
  splitTos?.length > 0
    ? splitTos
        .map((s) => s.fullName)
        .reduce((a, b, idx) => {
          if (idx === 1) {
            return `- ${a}\n- ${b}`;
          }

          return `${a}\n- ${b}`;
        })
    : '';
