import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostBinding } from '@angular/core';

import { query, transition, trigger, useAnimation } from '@angular/animations';
import { xAnimationFadeIn } from '@app/shared/animations/animations';

@Component({
  selector: 'sc-time-activity-search',
  templateUrl: './time-activity-search.component.html',

  animations: [
    trigger('xAnimation', [
      transition('false => true', [
        query('sc-input-with-icon-debounce', [useAnimation(xAnimationFadeIn)], { optional: true }),
      ]),
      transition('true => false', [query('.btn', [useAnimation(xAnimationFadeIn)], { optional: true })]),
    ]),
  ],
})
export class TimeActivitySearchComponent implements OnInit, OnDestroy {
  @Input() query = '';
  @Input() searching = false;
  @Input() showSearchBar = false;
  @Output() search = new EventEmitter<string>();
  @Output() activityBtnClicked = new EventEmitter<boolean>();
  @HostBinding('@xAnimation')
  get xAnimation() {
    return this.showSearchBar;
  }
  ngOnInit() {}

  onActivityBtnClick(event: any) {
    this.activityBtnClicked.emit(event);
  }
  ngOnDestroy(): void {}
}
