import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { CurrentFeeTimerMatterState } from '@app/features/fee-timer/models/fee-timer.model';
import { Store } from '@ngrx/store';
import * as feeTimerActions from '@app/features/fee-timer/store/actions';
import { AppApiService } from '@app/core/api';
import { TimerRecordingAction } from '@app/features/+time-fee-ledger/models';

@Component({
  selector: 'sc-time-activity-header',
  templateUrl: './time-activity-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeActivityHeaderComponent {
  @Input() recordingState: string;
  @Input() selectedActivityCode: string;
  @Input() details: CurrentFeeTimerMatterState;
  @Input() isNewEntry: boolean;
  @Input() feeGUID: string;
  @Input() runningTimerFeeGUID: string;

  @Output() saveCalled = new EventEmitter();

  get showPlay(): boolean {
    switch (this.recordingState) {
      case TimerRecordingAction.Stop:
      case TimerRecordingAction.Discard:
        return true;
      default:
        return false;
    }
  }

  get showDiscard(): boolean {
    switch (this.recordingState) {
      case TimerRecordingAction.Stop:
        return true;
      default:
        return false;
    }
  }

  get showStop(): boolean {
    switch (this.recordingState) {
      case TimerRecordingAction.Start:
        return true;
      default:
        return false;
    }
  }

  get showSave(): boolean {
    switch (this.recordingState) {
      case TimerRecordingAction.Stop:
        return true;
      default:
        return false;
    }
  }

  constructor(private _store: Store<any>, private _appApiSvc: AppApiService, private cdr: ChangeDetectorRef) {}

  onPlay(): void {
    this._store.dispatch(
      new feeTimerActions.SetFeeTimer(
        this.feeGUID || this.runningTimerFeeGUID
          ? { runningTimerFeeGUID: this.feeGUID || this.runningTimerFeeGUID, isNewEntry: false }
          : { runningTimerFeeGUID: undefined, isNewEntry: true }
      )
    );
    this._store.dispatch(new feeTimerActions.FeeTimerTimeElapsedStart(undefined));
  }

  onDiscard(): void {
    this._store.dispatch(new feeTimerActions.FeeTimerTimeElapsedDiscard(undefined));
    this._store.dispatch(new feeTimerActions.ResetFeeTimer(null));
  }

  onStop(): void {
    this._store.dispatch(new feeTimerActions.FeeTimerTimeElapsedStop(undefined));
  }

  onSave(): void {
    this._appApiSvc.navigate({
      path: [{ outlets: { popup: ['time'] } }],
      query: { feeGUID: this.runningTimerFeeGUID ?? undefined, matterNumber: this.details.matterId },
    });
    this.saveCalled.emit();
    this.cdr.markForCheck();
  }
}
