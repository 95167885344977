import * as actions from '../actions/current-matter';
import * as matterCardActions from '@app/features/+card/store/actions/matter-card';
import { IMatterCard, IMatterDetailEntry, IMatterDetailsInfo, IMatterDetailRelationship } from '@app/shared/models';
import { isMatterCard, reduceMatterCards } from '@app/features/+matter-details/functions';
import { IMatterDetailsApiError } from '../../models/matter-details-api.model';
import { getObjValue } from '@server/modules/shared/functions';

export interface State {
  detailEntries: IMatterDetailEntry[];
  detailInfo: IMatterDetailsInfo;
  matterCards: IMatterCard[];
  matterCardRelationships: IMatterDetailRelationship[];
  initialising: boolean;
  loading: boolean;
  error: any | string | IMatterDetailsApiError;
  currentDetailEntry: IMatterDetailEntry;
}

export const initialState: State = {
  detailEntries: [],
  detailInfo: {
    matterId: '',
    matterDescription: '',
    matterFileNumber: '',
    matterTypeId: null, // initialised as null for Matter Addin check
  },
  matterCards: [],
  matterCardRelationships: [],
  initialising: false,
  loading: false,
  error: null,
  currentDetailEntry: null,
};

export const reducer = (
  state = initialState,
  action: actions.CurrentMatterApiActions | actions.CurrentMatterDbActions | matterCardActions.MatterCardActions
): State => {
  switch (action.type) {
    case actions.GET_MATTER_DETAILS_START: {
      return {
        ...state,
        loading: true,
        initialising: getObjValue(state, 'detailInfo.matterId', '') !== action.payload.matterId,
        detailInfo: {
          ...state.detailInfo,
          matterId: action.payload.matterId,
        },
      };
    }

    case matterCardActions.MatterCardActionTypes.SET_DEBTOR_CARD:
    case matterCardActions.MatterCardActionTypes.REMOVE_MATTER_CARD_START:
    case matterCardActions.MatterCardActionTypes.REMOVE_DEBTOR_CARD_START:
    case matterCardActions.MatterCardActionTypes.REMOVE_DEBTOR_FOR_ACCOUNTING_CARD_START:
    case actions.GET_MATTER_CARDS_START:
    case actions.REORDER_MATTER_DETAIL_ENTRIES_START:
    case actions.ADD_ACTING_PERSON_START:
    case actions.REMOVE_ACTING_PERSON_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case matterCardActions.MatterCardActionTypes.SET_DEBTOR_CARD_SUCCEED:
    case matterCardActions.MatterCardActionTypes.REMOVE_MATTER_CARD_SUCCESS:
    case matterCardActions.MatterCardActionTypes.REMOVE_MATTER_CARD_FAIL:
    case matterCardActions.MatterCardActionTypes.REMOVE_MATTER_CARD_CANCELLED:
      return {
        ...state,
        loading: false,
      };

    case actions.GET_MATTER_DETAILS_SUCCESS: {
      const { detailEntries, detailInfo, relations } = action.payload;
      return {
        ...state,
        loading: false,
        initialising: false,
        matterCards: extractMatterCards(detailEntries),
        matterCardRelationships: relations,
        detailEntries,
        detailInfo: {
          ...state.detailInfo,
          ...detailInfo,
        },
      };
    }

    case actions.GET_MATTER_CARDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        matterCards: action.payload,
      };
    }

    case actions.CURRENT_MATTER_FAILURE:
    case actions.GET_MATTER_CARDS_FAILURE:
    case actions.GET_MATTER_DETAILS_FAILURE:
    case actions.PERSON_ACTING_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case actions.CLEAR_MATTER_DETAILS: {
      return initialState;
    }

    case actions.SET_CURRENT_DETAIL_ENTRY: {
      return {
        ...state,
        currentDetailEntry: action.payload.detailEntry,
      };
    }

    case actions.SET_DETAIL_ENTRIES: {
      return {
        ...state,
        detailEntries: action.payload.detailEntries,
      };
    }

    default: {
      return state;
    }
  }
};

export const selectDetailEntries = (state: State) => state.detailEntries;
export const selectDetailInfo = (state: State) => state.detailInfo;
export const selectError = (state: State) => state.error;
export const selectInitialising = (state: State) => state.initialising;
export const selectLoading = (state: State) => state.loading;
export const selectMatterCards = (state: State) => state.matterCards;
export const selectCurrentDetailEntry = (state: State) => state.currentDetailEntry;
export const selectMatterCardRelationships = (state: State) => state.matterCardRelationships;

const extractMatterCards = (entries: IMatterDetailEntry[]): IMatterCard[] => reduceMatterCards(
    entries?.filter((entry) => isMatterCard(entry) && !!(entry as IMatterCard).cardId).map((x) => x as IMatterCard) ||
      []
  );
