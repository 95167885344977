import { createSelector } from '@ngrx/store';

import * as fromMatterSearch from '../reducers/matter-search.reducer';

import { State, selectMattersState } from '../reducers';
import { IMatterSearchState } from '@app/features/+matter-list/models';

export const selectMatterSearchState = createSelector(selectMattersState, (state: State) => state.matterSearch);

export const selectMatterSearch = createSelector(selectMatterSearchState, (state: fromMatterSearch.State) =>
  fromMatterSearch.selectMatterSearch(state)
);

export const selectMatterSearchFilterBy = createSelector(
  selectMatterSearch,
  (matterSearch: IMatterSearchState) => matterSearch.filterBy
);

export const selectSearching = createSelector(selectMatterSearchState, (state: fromMatterSearch.State) =>
  fromMatterSearch.selectSearching(state)
);

export const selectMatterSearchLoaded = createSelector(selectMatterSearchState, (state: fromMatterSearch.State) =>
  fromMatterSearch.selectLoaded(state)
);
