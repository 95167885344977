import { Action } from '@ngrx/store';

import { TableMapping } from '../../../shared/models';

export const ADD_TABLE_MAPPING = '[App] add table mapping';
export const ADD_TABLE_MAPPING_SUCCESS = '[App] add table mapping success';
export const ADD_TABLE_MAPPING_FAILURE = '[App] add table mapping failure';

// we initialise the tableMapping when we initialising cards
export class AddTableMapping implements Action {
  readonly type = ADD_TABLE_MAPPING;
  constructor(public payload: any) {}
}

export class AddTableMappingSuccess implements Action {
  readonly type = ADD_TABLE_MAPPING_SUCCESS;
  constructor(public payload: TableMapping[]) {}
}

export class AddTableMappingFailure implements Action {
  readonly type = ADD_TABLE_MAPPING_FAILURE;
  constructor(public payload: any) {}
}

export type TableMappingActions = AddTableMapping | AddTableMappingSuccess | AddTableMappingFailure;
