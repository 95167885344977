// core
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// 3rd party
import { AgGridModule } from '@ag-grid-community/angular';

// local
import { gridComponents } from './shared.component';
import { SharedModule } from '@app/shared';
import { sharedAgGridDirectives } from '@app/sharedaggrid/directives';
import { Angulartics2Module } from 'angulartics2';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AgGridModule,
    Angulartics2Module,
  ],
  exports: [AgGridModule, ...gridComponents, ...sharedAgGridDirectives],
  declarations: [...gridComponents, ...sharedAgGridDirectives],
  providers: [],
})
export class SharedAgGridModule {}
