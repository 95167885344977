import * as commentActions from '@app/features/+comment/store/actions';
import * as emailActions from '@app/features/+email/store/actions';
import * as previewActions from '@app/features/+preview/store';
import * as precedentActions from '@app/features/+precedent/store';

export const AutoTimeStartTaskActionTypes = [
  commentActions.GET_COMMENT_SUCCESS,
  commentActions.DRAFT_COMMENT_SUCCESS,
  emailActions.CREATE_DRAFT_SUCCESS,
  previewActions.PREVIEW_DOCUMENT_LOADED,
  precedentActions.PRECEDENT_MODAL_OPENED
];

export const AutoTimeEndTaskActionTypes = [
  commentActions.COMMENT_MODAL_CLOSED,
  emailActions.EMAIL_MODAL_CLOSED,
  previewActions.PREVIEW_MODAL_CLOSED,
  precedentActions.PRECEDENT_MODAL_CLOSED
];

export interface IAutoTimeStartTaskPayload {
  actionType: AutoTimeActionTypeId;
  resourceType?: AutoTimeResourceTypeId;
}

export interface IAutoTimeEndTaskPayload {
  requiredResourceType?: AutoTimeResourceTypeId;
  actionTypeOverride?: AutoTimeActionTypeId;
  next?: {
    actionType: AutoTimeActionTypeId;
    resourceType: AutoTimeResourceTypeId;
  };
}

export interface IAutoTimeExternalJSON {
  actionType: AutoTimeActionTypeId;
  resourceType?: AutoTimeResourceTypeId;
  total?: number;
}

export interface IAutoTimeEntry {
  matterId: string;
  staffId: string;
  transactionDate: string;
  feeId: string;
  firmId: string;
  timesInSeconds: number[];
  billingDescription: string;
  externalJson: string; //stringified IAutoTimeExternalJSON[]
}

export interface IAutoTimeEntryUpdateResponse {
  FeeId: string;
  Message: string;
  Code: number;
  RowVersion: number;
  TransactionNumber: number;
}

export enum AutoTimeActionTypeId {
  CREATED = 1,
  REVIEWED,
  MODIFIED,
  DRAFTED,
  SENT,
  RECEIVED,
  INCOMING,
  OUTGOING,
  USED,
  RESEARCHED,
  OTHER = 99,
  UNKNOWN = 0,
};

export const AutoTimeActionTypes = {
  [AutoTimeActionTypeId.CREATED]: {
    name: 'create',
    action: 'created'
  }, [AutoTimeActionTypeId.REVIEWED]: {
    name: 'read',
    action: 'reviewed'
  }, [AutoTimeActionTypeId.MODIFIED]: {
    name: 'edit',
    action: 'modified'
  }, [AutoTimeActionTypeId.DRAFTED]: {
    name: 'draft',
    action: 'drafted'
  }, [AutoTimeActionTypeId.SENT]: {
    name: 'sent',
    action: 'sent'
  }, [AutoTimeActionTypeId.RECEIVED]: {
    name: 'received',
    action: 'received'
  }, [AutoTimeActionTypeId.INCOMING]: {
    name: 'incoming',
    action: 'incoming'
  }, [AutoTimeActionTypeId.OUTGOING]: {
    name: 'outgoing',
    action: 'outgoing'
  }, [AutoTimeActionTypeId.USED]: {
    name: 'used',
    action: 'used'
  }, [AutoTimeActionTypeId.RESEARCHED]: {
    name: 'research',
    action: 'researched'
  }, [AutoTimeActionTypeId.OTHER]: {
    name: 'other',
    action: 'other'
  }, [AutoTimeActionTypeId.UNKNOWN]: {
    name: 'unknown',
    action: 'unknown'
  }
};

export enum AutoTimeResourceTypeId {
  DOCUMENT = 1,
  EMAIL,
  APPOINTMENT,
  MEETING,
  PHONE_CALL,
  COMMENT,
  SMS,
  VIDEO,
  DOCUMENT_SCAN,
  VOICE_MEMO,
  APP = 50,
  BY_LAWYERS_RESEARCH,
  OTHER = 99,
  UNKNOWN = 0
};

export const AutoTimeResourceType: {
  [key: number]: {
    name: string;
  };
} = {
  [AutoTimeResourceTypeId.DOCUMENT]: { name: 'document' },
  [AutoTimeResourceTypeId.EMAIL]: { name: 'email' },
  [AutoTimeResourceTypeId.APPOINTMENT]: { name: 'appointment' },
  [AutoTimeResourceTypeId.MEETING]: { name: 'meeting' },
  [AutoTimeResourceTypeId.PHONE_CALL]: { name: 'phone call' },
  [AutoTimeResourceTypeId.COMMENT]: { name: 'comment' },
  [AutoTimeResourceTypeId.SMS]: { name: 'sms' },
  [AutoTimeResourceTypeId.VIDEO]: { name: 'video' },
  [AutoTimeResourceTypeId.DOCUMENT_SCAN]: { name: 'document scan' },
  [AutoTimeResourceTypeId.VOICE_MEMO]: { name: 'voice memo' },
  [AutoTimeResourceTypeId.APP]: { name: 'app' },
  [AutoTimeResourceTypeId.BY_LAWYERS_RESEARCH]: { name: 'By Lawyers research' },
  [AutoTimeResourceTypeId.OTHER]: { name: 'other' },
  [AutoTimeResourceTypeId.UNKNOWN]: { name: 'unknown' }
};
