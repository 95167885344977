<ng-container *ngIf="!isHidden">
  <h6 class="ag-content-name" data-hj-masked [title]="matterEntry.firstDescription" *ngIf="!hideFirstDescription">
    {{ description }}
  </h6>
  <dl class="ag-content-description" *ngIf="matterEntry.customDescription">
    <dd>
      <strong data-hj-masked>
        {{ matterEntry.customDescription }}
      </strong>
      <span *ngIf="matterEntry.secondDescription" data-hj-masked> - {{ matterEntry.secondDescription }}</span>
    </dd>
  </dl>
</ng-container>
