import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';

import { IDialogOptions } from '@app/shared/models';
import { ScHotKeyService } from '@app/core/services/hotkey/hot-key.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sc-confirm-modal',
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent implements OnInit, OnDestroy {
  actionText: string;
  closeText: string;
  message: string;
  showCancel = false;
  title: string;
  type: 'error' | 'warning' | 'info' | 'confirm' = 'error';

  options: IDialogOptions; // styling options
  onClose: Subject<boolean>;
  escKeySubscription: Subscription;

  private unsub = new Subject<void>();

  get proxyActionText(): string {
    return this.actionText || 'OK';
  }

  get proxyCloseText(): string {
    return this.closeText || 'Cancel';
  }

  get headerClass(): string {
    switch (this.type) {
      case 'error': {
        return `text-danger`;
      }
      case 'warning': {
        return `text-warning`;
      }
      case 'info':
      case 'confirm': {
        return `text-primary`;
      }
    }
  }

  get iconClass(): string {
    switch (this.type) {
      case 'error': {
        return `text-danger`;
      }
      case 'warning': {
        return `text-warning`;
      }
      case 'info':
      case 'confirm': {
        return `text-primary`;
      }
    }
  }

  get iconName(): string {
    switch (this.type) {
      case 'error': {
        return `error-circle-outline`;
      }
      case 'warning': {
        return `warning-triangle-outline`;
      }
      case 'info':
      case 'confirm': {
        return `info-circle-outline-grid-20`;
      }
    }
  }

  get btnType(): string {
    switch (this.type) {
      case 'error': {
        return `danger`;
      }
      case 'warning': {
        return `warning`;
      }
      case 'info':
      case 'confirm': {
        return `primary`;
      }
    }
  }

  constructor(private _bsModalRef: BsModalRef, private _hotKeysSvc: ScHotKeyService) {}

  ngOnInit() {
    this._hotKeysSvc.setupEscKey('dialog');
    this.setupSubscriptions();
  }

  ngOnDestroy() {
    this._hotKeysSvc.disposeEscKey('dialog');
    this.unsub.next();
    this.unsub.complete();
  }

  private setupSubscriptions() {
    this.escKeySubscription = this._hotKeysSvc
      .getKeyPressSubject('dialog')
      .pipe(takeUntil(this.unsub))
      .subscribe((keyPress) => (keyPress === 'esc' ? this.close(false) : null));
  }

  confirm() {
    // we set dialog result as true on click on confirm button,
    // then we can get dialog result from caller code
    this.close(true);
  }

  close(confirmed: boolean = false): void {
    this.onClose.next(confirmed);
    this._bsModalRef.hide();
  }

}
