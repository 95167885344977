import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'sc-row',
  templateUrl: './row.component.html',
})
export class RowComponent implements OnInit {
  constructor() {}

  @HostBinding('class.row')
  ngOnInit() {}
}
