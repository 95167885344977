import * as namedListActions from '../actions/named-list';

export interface State {
  // additional entities state properties
  error: any | string | null;
  loading: boolean;
  dbSaveStatus: string;
  initiated: boolean;
}

export const INITIAL_STATE: State = {
  error: null,
  loading: false,
  dbSaveStatus: '',
  initiated: false,
};

export const reducer = (
  state = INITIAL_STATE,
  action: namedListActions.NamedListApiActions | namedListActions.NamedListDbActions
): State => {
  switch (action.type) {
    case namedListActions.NamedListApiActionTypes.INIT_NAMEDLISTS_START:
      return {
        ...state,
        loading: true,
      };

    case namedListActions.NamedListApiActionTypes.INIT_NAMEDLISTS_SUCCESS:
      return {
        ...state,
        initiated: true,
      };

    case namedListActions.NamedListApiActionTypes.INIT_NAMEDLISTS_FAILURE:
      return {
        ...state,
        loading: false,
        initiated: false,
      };

    case namedListActions.NamedListDbActionTypes.NAMEDLISTS_SAVE_DB_START:
      return {
        ...state,
        loading: true,
        dbSaveStatus: 'start',
      };

    case namedListActions.NamedListDbActionTypes.NAMEDLISTS_SAVE_DB_SUCCESS:
      return {
        ...state,
        loading: false,
        dbSaveStatus: action.payload,
      };

    case namedListActions.NamedListDbActionTypes.NAMEDLISTS_SAVE_DB_FAILURE:
      return {
        ...state,
        loading: false,
        dbSaveStatus: action.payload,
      };

    default:
      return state;
  }
};

export const selectInitiated = (state: State) => state.initiated;
export const selectLoading = (state: State) => state.loading;
