import { Injectable } from '@angular/core';
import { Attachment, AttachmentType } from '../../../+email/models';
import { v4 as uuidv4 } from 'uuid';
import * as emailActions from '../../../+email/store/actions';
import { Store } from '@ngrx/store';
import { AuthService } from '../../../../core/services';
import { AppApiService } from '../../../../core/api';
import * as appActions from '@app/core/store/actions';

@Injectable({
  providedIn: 'root',
})
export class EmailReportsService {
  constructor(private _store: Store<any>, private _authSvc: AuthService, private _appApiService: AppApiService) {}

  async emailReport(reportUrl: string, reportName: string, fileNumber: string) {
    const fromAws = reportUrl.indexOf('.amazonaws.') >= 0;
    const attachment: Attachment[] = [
      {
        id: uuidv4(),
        name: reportName,
        fileUrl: reportUrl,
        iconClass: 'doctype-pdf',
        type: AttachmentType.FileUrl,
        ...(!fromAws && { token: await this._authSvc.getToken() }),
      },
    ];
    this._store.dispatch(new appActions.InitialiseEmail({ data: { matterNumber: fileNumber }, attachment }));
    this._store.dispatch(new emailActions.AddAttachmentsSuccess(attachment));
  }
}
