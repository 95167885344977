<span *ngIf="!isMatterCard && requireContentApp" [innerHtml]="'DefinableTable.ContentAppRequired' | translate"></span>
<div class="x-list-group-content-action">
  <ng-template [ngxPermissionsExcept]="[permissionEnum.CardRead, permissionEnum.MatterRead]">
    <a
      href
      scPreventDefault
      (click)="newDetail($event, true)"
      *ngIf="isMatterCard"
      [innerHtml]="'Matter.DetailEntry.New.Person' | translate"
    ></a>
    <sc-divider direction="vertical" *ngIf="isMatterCard"></sc-divider>
    <a
      href
      scPreventDefault
      (click)="newDetail($event, true, false)"
      *ngIf="isMatterCard"
      [innerHtml]="'Matter.DetailEntry.New.Organisation' | translate"
    ></a>
    <a
      href
      scPreventDefault
      *ngIf="!isMatterCard && !requireContentApp"
      [innerHtml]="'Matter.DetailEntry.New.DefinableTable' | translate: { type: detailEntry.__name }"
    ></a>
  </ng-template>
</div>
