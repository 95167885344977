import { Injectable } from '@angular/core';
import { BrandService } from '@app/core/services';
import {
  BillingMode,
  IBillingMode,
  IPaymentMode,
  ITaxCode,
  PaymentMode,
  TaxAmounts,
} from '@app/features/accounting/models';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Regions } from '@app/shared/models/config.model';
import { externalPlatform } from '@app/features/accounting/constants';
import { FirmDetails, Staff } from '@app/shared/models';
import { UserInfo } from '@leapdev/auth-agent/src/lib/types';
import {
  floorToPrecision,
  roundToPrecision,
  stringToStartCase,
} from '@server/modules/shared/functions/common-util.functions';
import { ScAccDatePipe } from '@app/shared/pipes/date/sc-acc-date.pipe';

export enum RoundMode {
  Round = 'round',
  Floor = 'floor',
}

@Injectable({
  providedIn: 'root',
})
export class AccountingService {
  private _isUkXeroRounding = false;

  constructor(
    private _translateSvc: TranslateService,
    private _toastrSvc: ToastrService,
    private _brandSvc: BrandService,
    private _accDatePipe: ScAccDatePipe
  ) {}

  configAccounting(config: { firmDetails: FirmDetails; userDetails: UserInfo }): void {
    const { firmDetails, userDetails } = config;
    this._isUkXeroRounding =
      userDetails.region === Regions.UK && firmDetails.externalPlatform === externalPlatform.Xero;
  }

  calculateTotalAmount(
    amount: number,
    tax: ITaxCode,
    taxCodeList: ITaxCode[],
    incTax: boolean = false,
    calculateSplits = false
  ): TaxAmounts {
    const hasTaxSplits = !!tax && !!tax.TaxCodeSplits && tax.TaxCodeSplits.length > 0;

    if (!!tax && !!taxCodeList) {
      return hasTaxSplits && this._brandSvc.isCA() && calculateSplits
        ? this.calculateAmountSplitTax(amount, tax, taxCodeList, incTax)
        : this.calculateAmountTax(amount, tax, incTax);
    }

    return { TotalExTax: 0, TotalIncTax: 0, TotalTax: 0 };
  }

  private calculateAmountSplitTax(
    amount: number,
    tax: ITaxCode,
    taxCodeList: ITaxCode[],
    incTax: boolean = false
  ): TaxAmounts {
    const taxSplit = tax.TaxCodeSplits.map((s) => s.TaxCodeGUID);
    const taxRate = taxSplit
      .map((taxGuid) => taxCodeList.find((tc) => tc.TaxCodeGUID === taxGuid).RatePercent)
      .filter(Boolean)
      .reduce((a, b) => a + b);
    const totalTax = this.calculateTax(amount, taxRate, incTax);
    const totalIncTax = incTax ? amount : amount + totalTax;
    const totalExTax = this.round(totalIncTax - totalTax);

    return {
      TotalExTax: totalExTax,
      TotalTax: totalTax,
      TotalIncTax: totalIncTax,
    };
  }

  public calculateAmountTax(amount: number, tax: ITaxCode, incTax: boolean = false): TaxAmounts {
    const taxRate = !!tax.RatePercent ? tax.RatePercent : 0;
    const totalTax = this.calculateTax(amount, taxRate, incTax);
    const totalIncTax = incTax ? amount : amount + totalTax;
    const totalExTax = this.round(totalIncTax - totalTax);

    return {
      TotalExTax: totalExTax,
      TotalTax: totalTax,
      TotalIncTax: totalIncTax,
    };
  }

  /**
   * From 4D Acc_CalcTax method:
   *
   * If ($TaxInclusive)
   *      If ((countryCode==UK) & (externalAccPlatform=='XERO') & (roundingTypeID==0))
   *          tax==amount-(Round((amount/(taxRate+100))*100;2))  // NEW LOGIC
   *      Else
   *          Tax==(amount/(taxRate+100))*taxRate
   * Else
   *      tax==amount*(taxRate/100)f
   * End if
   *
   * @param amount
   * @param taxRate
   * @param includesTax
   */

  private calculateTax(amount: number, taxRate: number, includesTax: boolean = false): number {
    let tax = 0;
    if (includesTax) {
      if (this._isUkXeroRounding) {
        // to overcome issues with negative amounts when calculating tax amount
        // treat amount as positive and add positive tax to negative amount
        // this ensures calculations and rounding will always be the same
        if (amount < 0) {
          tax = amount + this.round((Math.abs(amount) / (taxRate + 100)) * 100, 2);
        } else {
          tax = amount - this.round((amount / (taxRate + 100)) * 100, 2);
        }
      } else {
        tax = (amount / (taxRate + 100)) * taxRate;
      }
    } else {
      tax = amount * (taxRate / 100);
    }

    return this.round(tax, 2);
  }

  round(amount: number, precision = 2, mode: RoundMode = RoundMode.Round): number {
    switch (mode) {
      case RoundMode.Round:
        return roundToPrecision(amount, precision);

      case RoundMode.Floor:
        return floorToPrecision(amount, precision);

      default:
        return roundToPrecision(amount, precision);
    }
  }

  getBillingModes(): IBillingMode[] {
    return [
      {
        id: 1,
        name: this._translateSvc.instant('Accounting.BillingMode.BillableNextInvoice'),
        value: BillingMode.BillableNextInvoice,
      },
      {
        id: 2,
        name: this._translateSvc.instant('Accounting.BillingMode.BillableLaterInvoice'),
        value: BillingMode.BillableLaterInvoice,
      },
      {
        id: 3,
        name: this._translateSvc.instant('Accounting.BillingMode.NotBillable'),
        value: BillingMode.NotBillable,
      },
    ];
  }

  findBillingModeLabel(value: BillingMode): string {
    const billingModes = this.getBillingModes();
    const mode = billingModes.find((b) => b.value === value);
    return !!mode ? mode.name : billingModes[0].name;
  }

  getPaymentModes(): IPaymentMode[] {
    return [
      {
        id: 1,
        name: this._translateSvc.instant('Accounting.PaymentMode.Payable'),
        value: PaymentMode.Payable,
      },
      {
        id: 2,
        name: this._translateSvc.instant('Accounting.PaymentMode.NotPayable'),
        value: PaymentMode.NotPayable,
      },
    ];
  }

  notify(action: 'print' | 'email'): void {
    const translateNS = `Accounting.Notification.${stringToStartCase(action)}`;
    this._toastrSvc.show(
      this._translateSvc.instant(`${translateNS}.Message`),
      this._translateSvc.instant(`${translateNS}.Title`),
      {},
      'info'
    );
  }

  buildReversalAlertMessage(reversalDetails: string, reversalDate?: Date, staff?: Staff) {
    if (!reversalDetails || !reversalDate) {
      return 'This transaction has been reversed or is a reversal.';
    }
    // Many timestamps returned by api do not include Z to indicate UTC, so browser doesn't display date in local format.
    const parsedReversalDate = reversalDate.toString().indexOf('Z') !== -1 ? reversalDate : reversalDate + 'Z';

    let alertMessage = reversalDetails;
    if (reversalDate) {
      alertMessage = alertMessage + ` | ${this._accDatePipe.transform(parsedReversalDate, true)}`;
    }
    if (!!staff && staff.initials) {
      alertMessage = alertMessage + ` (${staff.initials})`;
    }
    return alertMessage;
  }
}
