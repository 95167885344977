import { Injectable } from '@angular/core';
import { GOOGLE_MAP_API_KEY } from '@app/core/models';
import { BrandService } from '@app/core/services';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  private apiLoaded = false;

  constructor() {
    this.loadGoogleMapsApi();
  }

  private loadGoogleMapsApi() {
    if (this.apiLoaded) {
      return;
    }

    const apiKey = BrandService.isLive() ? GOOGLE_MAP_API_KEY.live_env : GOOGLE_MAP_API_KEY.test_env;
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap&loading=async`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      this.apiLoaded = true;
    };
    document.head.appendChild(script);

    (window as any).initMap = () => {
      this.apiLoaded = true;
    };
  }

  public isApiLoaded(): Promise<boolean> {
    return new Promise((resolve) => {
      const checkApiLoaded = () => {
        if (this.apiLoaded && (window as any).google && (window as any).google.maps) {
          resolve(true);
        } else {
          setTimeout(checkApiLoaded, 100);
        }
      };
      checkApiLoaded();
    });
  }
}
