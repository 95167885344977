import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable()
export class TrusteeService {
  constructor(private formBuilder: FormBuilder) {}

  create(options: any = {}): any {
    return {
      trusteeName: options.trusteeName || '',
      trusteeIsACompany: options.trusteeIsACompany || false,
      company: options.company || '',
    };
  }

  createFormGroup(options: any = {}): FormGroup {
    return this.formBuilder.group({
      trusteeName: options.trusteeName || '',
      trusteeIsACompany: options.trusteeIsACompany || false,
      company: options.company || '',
    });
  }
}
