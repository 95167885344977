import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import { AppSlice } from '@app/core/store';
import * as fromTimeFee from './time-fee.reducers';
import * as fromFeeModal from './fee-modal.reducers';
import * as fromTimeModal from './time-modal.reducers';
import * as fromTimeFeeEntry from './time-fee-entry.reducers';

export interface TimeFeeState {
  timeFee: fromTimeFee.TimeFeeState;
  feeModal: fromFeeModal.State;
  timeModal: fromTimeModal.State;
  timeFeeEntry: fromTimeFeeEntry.State;
}

export const initialState: TimeFeeState = {
  timeFee: fromTimeFee.initialState,
  feeModal: fromFeeModal.initialState,
  timeModal: fromTimeModal.initialState,
  timeFeeEntry: fromTimeFeeEntry.INITIAL_STATE,
};

export const reducers: ActionReducerMap<TimeFeeState> = {
  timeFee: fromTimeFee.timeFeeReducer,
  feeModal: fromFeeModal.reducer,
  timeModal: fromTimeModal.reducer,
  timeFeeEntry: fromTimeFeeEntry.reducer,
};

export const selectTimeFeeState = createFeatureSelector<TimeFeeState>(AppSlice.TimeFee);

export const selectTimeFeeEntryState = createSelector(selectTimeFeeState, (state: TimeFeeState) => state?.timeFeeEntry);
