<sc-modal-header (modalDismiss)="dismiss()">
  Unsupported Feature
</sc-modal-header>
<div class="modal-body text-center">
  <p>
    Editing documents is not currently supported for OSX in <strong> Office Desktop </strong> Mode. <br />
    <ng-container *ngIf="!!callback">
      Please click
      <a class="text-primary" href scPreventDefault (click)="handleCallback()"><strong>here</strong></a>
      to open the document
      <ng-container *ngIf="!!alternative">
        in <strong> {{ alternative }} </strong>
      </ng-container>
    </ng-container>
  </p>
</div>
