import { Component, HostBinding } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-transaction-amount-cell',
  templateUrl: './transaction-amount-cell.component.html',
})
export class TransactionAmountCellComponent implements ICellRendererAngularComp {
  amount: number;
  emptyAsNumber: boolean;

  constructor() {
    this.amount = 0;
    this.emptyAsNumber = false;
  }

  @HostBinding('class.ag-content-label')
  agInit(params: any): void {
    this.amount = params.getamount(params.data);
    this.emptyAsNumber =
      !!params.emptyAsNumber && isFunction(params.emptyAsNumber) ? params.emptyAsNumber(params.data) : false;
  }

  refresh(params: any): boolean {
    this.amount = isFunction(params.getamount)
      ? params.getamount(params.data) || 0
      : params.value || 0;
    return true;
  }
}
