import { createSelector } from '@ngrx/store';

import { selectCardListState } from '@app/features/+card/store/reducers';
import * as fromCardList from '../reducers/card-list.reducer';
import { ECardFilterType, ICardFilter } from '@app/features/+card/models';
import { selectCardFiltersDefaultFilters } from '@app/core/store/selectors/card-filters.selectores';

export const selectCardListInitiated = createSelector(selectCardListState, (state: fromCardList.State) =>
  fromCardList.selectInitiated(state)
);

export const selectCardListLoading = createSelector(selectCardListState, (state: fromCardList.State) =>
  fromCardList.selectLoading(state)
);

export const selectCardSearchText = createSelector(selectCardListState, (state: fromCardList.State) =>
  fromCardList.selectCardSearchText(state)
);

export const selectSortInfo = createSelector(selectCardListState, (state: fromCardList.State) =>
  fromCardList.selectSortInfo(state)
);

export const selectShowDeletedState = createSelector(selectCardListState, (state: fromCardList.State) =>
  fromCardList.selectShowDeletedState(state)
);

export const selectCardGroupBy = createSelector(selectCardListState, (state: fromCardList.State) =>
  fromCardList.selectCardGroupBy(state)
);

export const selectSelectedCardId = createSelector(selectCardListState, (state: fromCardList.State) =>
  fromCardList.selectSelectedCardId(state)
);

export const selectCardFilterType = createSelector(selectCardListState, (state: fromCardList.State) =>
  fromCardList.selectCardFilterType(state)
);

export const selectSelectedCardFilterName = createSelector(selectCardListState, (state: fromCardList.State) =>
  fromCardList.selectSelectedCardFilterName(state)
);

export const selectCardListCardFilters = createSelector(
  selectCardFiltersDefaultFilters,
  selectCardFilterType,
  (allFilters: Array<{ type: ECardFilterType; filters: ICardFilter[] }>, filterType: ECardFilterType) => {
    if (allFilters && allFilters.length > 0) {
      const selectedFilterUseCase = allFilters?.find((data) => data.type === filterType);
      if (selectedFilterUseCase) {
        return selectedFilterUseCase.filters;
      }
      return allFilters[ECardFilterType.CardList] ? allFilters[ECardFilterType.CardList].filters : [];
    } else {
      return [];
    }
  }
);

export const selectCardListSelectedCardFilter = createSelector(
  selectCardListCardFilters,
  selectSelectedCardFilterName,
  (filters: ICardFilter[], filterName: string) => {
    if (!filters || filters.length === 0) {
      return null;
    }

    if (filterName) {
      const result = filters?.find((filter) => filter.name === filterName);
      return result || filters[0];
    } else {
      return filters[0];
    }
  }
);
