import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap, withLatestFrom } from 'rxjs/operators';

import * as actions from '@app/features/live-chat/store/actions';
import { LiveChatService } from '@app/features/live-chat/services';
import { selectFirmDetails, selectCurrentStaff } from '@app/core/store';
import { IStaff } from '@app/shared/models';

@Injectable()
export class LiveChatEffect {

  launchLiveChat$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<actions.LaunchLiveChat>(actions.LAUNCH_LIVE_CHAT),
    withLatestFrom(
      this._store.pipe(select(selectFirmDetails)),
      this._store.pipe(select(selectCurrentStaff)),
      (action, firmDetails, staff) => ({ firmDetails, staff })
    ),
    tap(({ firmDetails, staff }) => {
      this._liveChatSvc.openChatWindow(firmDetails, staff as any);
    })
  ), { dispatch: false });

  constructor(private actions$: Actions, private _store: Store<any>, private _liveChatSvc: LiveChatService) {}
}
