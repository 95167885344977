import { ISortInfo } from '@app/shared/models';
import { TimeFeeFilter } from '@app/features/+time-fee-ledger/constants';

export interface ITimeFeeSummary {
  TransactionDate: Date;
  BillingDescription: string;
  FeeHoursQuantity: number;
  FeeGUID: string;
  BillingMode: number;
  InvoiceItemGUID: string;
  TransactionNumber: string;
  TotalIncTax: number;
  TotalExTax: number;
  Deleted: boolean;
  RowVers: number;
  WorkDoneByStaffGUID: string;
  Timed: boolean;
  DeletedDate: Date;
  TaskCodeGUID: string;
  UnitType?: string;
  UnitValue?: string;
  StaffInitials?: string;
  SecondsPerUnit: number;
  IsAutoTime?: boolean;
  ExternalJSON?: string;
}

export interface TimeFeeListMetaInfo {
  id: string;
  lastRowVer?: number;
  total?: number;
  sortInfo?: ISortInfo;
  timeFeeFilterBy?: string;
  incTax?: boolean;
}

export interface ITimeFeeGroupBy {
  id: number;
  value: string;
}

export interface ITimeFeeSummaryVM extends ITimeFeeSummary {
  status: string;
  statusClass: string;
  selected: boolean;
  ExternalJSON?: string;
  ExternalURL?: string;
  matterId?: string;
}

export interface UpdateTimeFeesRequestDelta {
  MatterGUID?: string;
  TaskCodeGUID?: string;
  Deleted?: boolean;
  DeletedDate?: string;
  BillingMode?: any;
  WorkDoneByStaffGUID?: string;
}

export interface ITimeFeeFilter {
  header?: boolean;
  name?: string;
  fullname?: string;
  value?: TimeFeeFilter;
  predicate?: (summary: ITimeFeeSummary) => boolean;
}

export interface ITimeFeeContextActionParams extends Partial<ITimeFeeSummaryVM> {
  items: ITimeFeeSummaryVM[];
}

export const SpecialFeePrefix = 'Special Fee';
