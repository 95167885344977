import { Action } from '@ngrx/store';
import { Attachment } from '@app/features/+email/models/attachments.model';
import { IDocCombinePDFHttpRequestParams } from '@app/features/+create-pdf/models/pdfoptions.model';

export * from './feature.model';

export interface IMatterUpdateManagement {
  actions: Action[];
  matterId: string;
}

export interface IMatterSubscription {
  unsubscribe: () => void;
}

export interface IGlobalUIPreference {
  asideExpanded?: boolean;
  headerHidden?: boolean;
  officeOnline?: boolean;
  previewAsideExpanded?: boolean;
  resizeExpanded?: boolean;
}

export interface IClearCacheParams {
  objectId: string;
  context: 'matterId' | 'firmId' | 'cardId' | 'correspondenceMatterId';
}

export interface ILocaleInfo {
  localeId: string;
  translateId: string;
}

export interface IToastrRenderer {
  toastrId: number;
  actionText?: string;
  actionCallback?: () => void;
  autoClose?: () => void;
  messageListener?: () => void;
  message?: string;
}

export interface IAutomationError {
  Error: number;
  MessageType: string;
  RecordId: string;
  Message: string;
}

export interface EmailInitialisationData {
  data: any;
  attachment?: Attachment[];
  convertPdfParams?: IDocCombinePDFHttpRequestParams;
  routerOutlet?: 'popup' | 'overlay';
}

export interface LinkLabel {
  isHeader?: boolean;
  isDivider?: boolean;
  link?: any[];
  label?: string;
  hide?: boolean;
  isNonRouteTab?: boolean;
}

export const GOOGLE_MAP_API_KEY = {
  live_env: 'AIzaSyAUaGkOzU9VeAa6MkwoHhL3-1ALQUo70RU',
  test_env: 'AIzaSyDGu0G73Q4ULGSKK5C5QjfGcQXfZEIqH34',
};
