import { Action } from '@ngrx/store';
import { AccountingPrintReq, AccountingRequest, EAccountingTransaction } from '../../models';
import { EPrintSource } from '@app/shared/services/print/print.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IPrecedent } from '@app/features/+precedent/models';

export const ACCOUNTING_INIT = '[Accounting] init';
export const ACCOUNTING_SAVE = '[Accounting] save';
export const ACCOUNTING_SAVE_FAILURE = '[Accounting] save failure';
export const ACCOUNTING_SAVE_SUCCESS = '[Accounting] save success';
export const ACCOUNTING_WARNING_REJECT = '[Accounting] warning reject';
export const ACCOUNTING_GENERIC_FAILURE = '[Accounting] generic failure';
export const ACCOUNTING_TRANSACTION_NAVIGATION = '[Accounting] transaction navigation';

// hydrate accounting form
export const HYDRATE_ACCOUNTING_FORM_START = '[AccountingForm] hydrate form';
export const HYDRATE_ACCOUNTING_FORM_SUCCESS = '[AccountingForm] hydrate form success';
export const HYDRATE_ACCOUNTING_FORM_FAILURE = '[AccountingForm] hydrate form failure';

// dehydrate accounting form
export const DEHYDRATE_ACCOUNTING_FORM_START = '[AccountingForm] dehydrate form';
export const DEHYDRATE_ACCOUNTING_FORM_SUCCESS = '[AccountingForm] dehydrate form success';
export const DEHYDRATE_ACCOUNTING_FORM_FAILURE = '[AccountingForm] dehydrate  form failure';

// email accounting form
export const EMAIL_ACCOUNTING_REPORT = '[AccountingForm] email accounting report';

// print accounting form
export const PRINT_ACCOUNTING_REPORT = '[AccountingForm] print accounting report';

export const ACCOUNTING_PRINT_START = '[Accounting] start print process in accounting';
export const DEBTOR_SELECTION_BEFORE_PRINT = '[Accounting] debtor selection before print';
export const ACCOUNTING_PRINT = '[Accounting] print accounting on going';
export const ACCOUNTING_PRINT_VIA_DOC_BUILDER = '[Accounting] print via docBuilder';
export const ACCOUNTING_PRINT_VIA_REPORTING = '[Accounting] print via reporting';

export class AccountingInit implements Action {
  readonly type = ACCOUNTING_INIT;
  constructor(public payload: any) {}
}

export class AccountingSave implements Action {
  readonly type = ACCOUNTING_SAVE;
  constructor(public payload: AccountingRequest) {}
}

export class AccountingSaveFailure implements Action {
  readonly type = ACCOUNTING_SAVE_FAILURE;
  constructor(public payload: { httpError: HttpErrorResponse; message?: string }) {}
}

export class AccountingSaveSuccess implements Action {
  readonly type = ACCOUNTING_SAVE_SUCCESS;
  constructor(public payload: any) {}
}

export class AccountingWarningReject implements Action {
  readonly type = ACCOUNTING_WARNING_REJECT;
  constructor(public payload: any) {}
}

export class AccountingGenericFailure implements Action {
  readonly type = ACCOUNTING_GENERIC_FAILURE;
  constructor(
    public payload: {
      httpError: HttpErrorResponse;
      message?: string;
      defaultErrorMessage?: string;
      closeModal?: boolean;
    }
  ) {}
}

export class HydrateAccountingFormStart implements Action {
  readonly type = HYDRATE_ACCOUNTING_FORM_START;
  constructor(public payload: any) {}
}

export class HydrateAccountingFormSuccess implements Action {
  readonly type = HYDRATE_ACCOUNTING_FORM_SUCCESS;
  constructor(public payload: any) {}
}

export class HydrateAccountingFormFailure implements Action {
  readonly type = HYDRATE_ACCOUNTING_FORM_FAILURE;
  constructor(public payload: any) {}
}

export class DeHydrateAccountingFormStart implements Action {
  readonly type = DEHYDRATE_ACCOUNTING_FORM_START;
  constructor(public payload: any) {}
}

export class DeHydrateAccountingFormSuccess implements Action {
  readonly type = DEHYDRATE_ACCOUNTING_FORM_SUCCESS;
  constructor(public payload: any) {}
}

export class DeHydrateAccountingFormFailure implements Action {
  readonly type = DEHYDRATE_ACCOUNTING_FORM_FAILURE;
  constructor(public payload: any) {}
}

export class AccountingReportPrint implements Action {
  readonly type = PRINT_ACCOUNTING_REPORT;
  constructor(
    public payload: { reportName: string; reportOptions: any; reportSource: EPrintSource; fileName?: string }
  ) {}
}
export class AccountingReportEmail implements Action {
  readonly type = EMAIL_ACCOUNTING_REPORT;
  constructor(
    public payload: {
      reportName: string;
      reportOptions: any;
      reportSource: EPrintSource;
      attachmentName: string;
      matterId: string;
    }
  ) {}
}

export class AccountingTransactionNavigation implements Action {
  readonly type = ACCOUNTING_TRANSACTION_NAVIGATION;

  constructor(
    public payload: {
      transactionGuid: string;
      matterGuid: string;
      transactionType: string;
      delayForPreviousNavigation?: boolean;
      query?: any;
      printStateAfterNextAction?: { printSource: 'reporting' | 'docbuilder'; payload: any };
    }
  ) {}
}

export class AccountingPrintStart implements Action {
  readonly type = ACCOUNTING_PRINT_START;
  constructor(public payload: AccountingPrintReq) {}
}

export class DebtorSelectionBeforePrint implements Action {
  readonly type = DEBTOR_SELECTION_BEFORE_PRINT;
  constructor(public payload: AccountingPrintReq) {}
}

export class AccountingPrint implements Action {
  readonly type = ACCOUNTING_PRINT;
  constructor(public payload: AccountingPrintReq & { selectedDebtorCardGuids: string[] }) {}
}

export class AccountingPrintViaDocBuilder implements Action {
  readonly type = ACCOUNTING_PRINT_VIA_DOC_BUILDER;

  constructor(public payload: AccountingPrintReq & { precedent: IPrecedent; selectedDebtorCardGuids: string[] }) {}
}

export class AccountingPrintViaReporting implements Action {
  readonly type = ACCOUNTING_PRINT_VIA_REPORTING;

  constructor(public payload: AccountingPrintReq & { selectedDebtorCardGuids: string[] }) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
  | AccountingInit
  | AccountingSave
  | AccountingSaveFailure
  | AccountingSaveSuccess
  | AccountingWarningReject
  | AccountingGenericFailure
  | AccountingTransactionNavigation
  | HydrateAccountingFormStart
  | HydrateAccountingFormSuccess
  | HydrateAccountingFormFailure
  | DeHydrateAccountingFormStart
  | DeHydrateAccountingFormSuccess
  | DeHydrateAccountingFormFailure
  | AccountingReportPrint
  | AccountingReportEmail
  | AccountingPrintStart
  | AccountingPrint
  | AccountingPrintViaDocBuilder
  | AccountingPrintViaReporting;
