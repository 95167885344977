export interface Attachment {
  id: string;
  latestVersion?: string;
  name: string;
  type: AttachmentType;
  fileUrl?: string;
  iconClass?: string;
  tooltip?: string;
  token?: string;
  ext?: string;
}

export enum AttachmentType {
  File = 0,
  Correspondence = 1,
  FileUrl = 2,
}

export interface RefreshAttachmentsRequest {
  attachmentIds: string[];
}

export type CreateAttachmentsRequest = FormData;

export const toRefreshAttachmentsRequest = (ids: string[]): RefreshAttachmentsRequest => ({
    attachmentIds: ids,
  });
