<span class="ag-header-cell-label">{{ params.displayName | translate }}</span>
<sc-dropdown-with-icon-v2
  *ngIf="!!iconClass && !!dropdownOptions && dropdownOptions.length > 0"
  [style]="{ position: 'absolute', right: '1rem', top: '50%', transform: 'translate(0, -50%)' }"
  [ngModel]="selectedOption"
  (ngModelChange)="selectedOptionChange($event)"
  [options]="dropdownOptions"
  [iconOnly]="true"
  [iconClass]="iconClass"
  wrapperClass="btn-group btn-group-sm"
  [bindingProperty]="bindingProperty"
  [bindingLabelProperty]="bindingLabelProperty"
></sc-dropdown-with-icon-v2>
