import { Injectable } from '@angular/core';
import { EditorMode } from '@app/features/+email/models/editor.options';

@Injectable()
export class EditorService {
  emptyArray: any[] = [];

  constructor() {}

  commonToolbar = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ color: this.emptyArray.slice() }, { background: this.emptyArray.slice() }], // dropdown with defaults from theme
    [{ font: this.emptyArray.slice() }],
    [{ align: this.emptyArray.slice() }],
    ['link', 'image'], // link and image
    ['clean'], // remove formatting button
  ];

  getEditorConfig(mode: EditorMode): any {
    const config = {
      imageResize: {},
      imageDrop: true,
    };

    switch (mode) {
      case EditorMode.COMMENT:
        return { ...config, toolbar: [...this.commonToolbar, ['timestamp'], ['undo'], ['redo'], ['print']] };
      case EditorMode.EMAIL:
        return { ...config, toolbar: [...this.commonToolbar, ['undo'], ['redo']] };
      case EditorMode.CALENDAR:
        return { ...config, toolbar: false };
      case EditorMode.SIGNATURE:
        return config;
    }
  }
}
