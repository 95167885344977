import { ITaxCode, IPreferences, BillingMode } from '@app/features/accounting/models';
import { ITaskCode } from '@app/shared/models';

export enum FeeType {
  Fee = 1,
  Time = 2,
}

export interface IBillingUnit {
  Seconds: number;
  Minutes?: number;
}

export interface ITimeFeeResponse {
  TaxCodeList: ITaxCode[];
  TaskCodeList: ITaskCode[];
  BillingUnitList: IBillingUnit[];
  Preferences: IPreferences;
  MatterInfo?: IMatterInfo;
  Fee?: ITimeFee;
}

export interface ITimeFee {
  // common
  FeeGUID: string;
  MatterGUID: string;
  TaxCodeGUID: string;
  TaskCodeGUID: string;
  TransactionDate: string;
  RateId: number;
  RatePerHour: number;
  SecondsElapsed: number;
  SecondsPerUnit: number;
  FeeUnits: number;
  FeeHoursQuantity: number;
  Timed: boolean;
  CalculationMode: number;
  IncTax: boolean;
  TotalIncTax: number;
  TotalExTax: number;
  TotalTax: number;
  BillingDescription: string;
  Memo: string;
  BillingMode: number;
  WorkDoneByStaffGUID: string;
  LAActivityTypeCode: string;
  LAActivityTypeDescription: string;
  LABillingStage: number;
  MatterBillingMode: number;
  LAAdjFeePercent: number;
  LACourtType: string;
  LAMedSessionAmt: number;
  LAMedTime: number;

  // get specific
  TransactionNumber: string;
  Deleted: boolean;
  DeletedDate: Date;
  DeletedDetails: string;
  RowVersion: number;
  TaxCode: string;
  TaskName: string;
  InvoiceGUID: string;
  InvoiceTransactionNumber: string;
  RecordVersion: number;
  IsAutoTime?: boolean;

  // post specific
  AttributeFeesToStaffGUID: string;
  LAHearingType: string;
  LAPersonAttending: string;

  // convenient properties
  // MillisElapsed: number;
  MinutesPerUnit?: number;
  isNew: boolean;
  WorkDoneByStaff?: IStaff;
  // totalExTaxChanged: () => void;
  // totalIncTaxChanged: () => void;
  // totalTaxChanged: () => void;
  // valuesChanged: () => void;
}

export interface UpdateOutput extends ITimeFee {
  isValid: boolean;
  isDirty: boolean;
  Print?: boolean;
  transactionDate: Date;
  MatterFileNumber: string;
  BillingRate: IRate;
  BillingMode: BillingMode;
  RatePerHour: number;
}

export interface FeeView extends ITimeFee {
  staffFullName: string;
  taskCodeName: string;
  matterDescription: string;
}

interface IRate {
  id: number;
  name: string;
  rate: number;
  display: string;
}

interface IDeletable {
  deleteCode?: number;
}

interface IStaff extends IDeletable {
  staffId: string;
  userId: string;
  firstName: string;
  lastName: string;
  middleName: string;
  initials: string;
  email: string;
  phone: string;
  rates: IRate[];
  __id: string;
  __className: string;
  branch: string;
  fullName: string;
  legalFullName: string;
  qualifications: string;
  title: string;
  fax: string;
  immigLicense: string;
  certificates: string;
  extension: string;
  mobile: string;
  firmReference: string;
  rate1: number;
  rate2: number;
  rate3: number;
  rate4: number;
  rate5: number;
  rate6: number;
}

interface IMatterInfo {
  ClientAndDescription: string;
  FileNumberString: string;
  LABillingMode: number;
  LABillingState: number;
}

export interface ITimeFeeUpdate {
  AppId: number;
  FeeList: FeeList[];
  MatterGUID: string;
  TaskCodeGUID?: string;
  TaxCodeGUID?: string;
  Deleted: boolean;
  DeletedDate: string;
  BillingMode: number;
  WorkDoneByStaffGUID: string;
  SemaphoreGUID?: string;
  MatterSourceList: MatterSourceList[];
  WarningAcknowledgments: number[];
}

export interface FeeList {
  FeeGUID: string;
  RowVersion: number;
}

export interface MatterSourceList {
  MatterGUID: string;
}
