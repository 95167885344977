<a
  class="nav-link"
  href
  scPreventDefault
  [class.disabled]="isDisabled"
  [class.active]="isActive"
  [class.detailed]="subHeading"
  (click)="itemClicked()"
>
  <ng-content></ng-content>
</a>
