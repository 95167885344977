import { createFeatureSelector } from '@ngrx/store';

import * as fromTaskCodeSelectorModal from './task-code-selector.reducer';
import { AppSlice } from '@app/core/store/app.slice';

export type State = fromTaskCodeSelectorModal.State;

export const initialState: State = fromTaskCodeSelectorModal.initialState;

export const reducers = fromTaskCodeSelectorModal.reducer;

export const selectTaskCodeSelectorState = createFeatureSelector<State>(AppSlice.TaskCodeSelector);
