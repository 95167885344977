import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, mergeMap, switchMap, withLatestFrom, exhaustMap, map } from 'rxjs/operators';

import {
  selectContentModeEnabled,
  selectCurrentMatterId,
  selectFirmDetails,
  selectRouterSnapshot,
} from '@app/core/store';
import { selectSelectedMatterTypeId } from '@app/features/+matter-types/store';
import { DialogService, DocumentAutomationService, OfflineLauncherService } from '@app/shared/services';
import { IAutomationOptions, IDocMetaInfo, IOfficeAction, IOpenPrecedentTicketParams } from '@app/shared/models';

import { PrecedentService } from '../../services';
import * as actions from '../actions';
import * as appActions from '@app/core/store/actions';
import * as recurringMatterActions from '@app/features/+recurring-matter-correspondence/store/actions';
import {
  AllMatterTypeId,
  EPrecedentSearchMode,
  getCustomizedPrecedentProps,
  IMatterTypePrecedents,
  IPrecedent,
  ISelectedFolder,
  PrecedentHelper,
} from '../../models';
import { State } from '../reducers';
import {
  selectFilterByMatterType,
  selectMatterTypePrecedentsEntities,
  selectPrecedentsList,
  selectPrecedentsTree,
  selectSelectedMatterTypePrecedentList,
  selectSelectedPrecedent,
} from '../selectors';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { selectSelectedFolderId } from '@app/features/+correspondence/store/selectors';
import {
  isEmptyObj,
  isEmptyValue,
  splitStringToArray,
  trim,
} from '@server/modules/shared/functions/common-util.functions';
import { Dictionary } from '@ngrx/entity';
import { AuthService } from '@app/core/services';
import { DocumentService } from '@app/features/+document/services';
import { v4 as uuidv4 } from 'uuid';

export const ReadonlyTypes: string[] = ['pdf'];

@Injectable()
export class PrecedentListEffects {
  listNestedPrecedentsStart$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.LIST_NESTED_PRECEDENTS_START),
      withLatestFrom(
        this._store.pipe(select(selectMatterTypePrecedentsEntities)),
        this._store.pipe(select(selectSelectedMatterTypeId)),
        this._store.pipe(select(selectFilterByMatterType)),
        (action, matterTypePrecedents, selectedMatterTypeId, precedentFilterByMatterType) => ({
          action,
          matterTypePrecedents,
          selectedMatterTypeId,
          precedentFilterByMatterType,
        })
      ),
      switchMap(
        (stateData: {
          action: actions.ListNestedPrecedentsStart;
          matterTypePrecedents: Dictionary<IMatterTypePrecedents>;
          selectedMatterTypeId: string;
          precedentFilterByMatterType: string;
        }) => {
          const { action, matterTypePrecedents, selectedMatterTypeId, precedentFilterByMatterType } = stateData;
          const { precedentId, matterTypeId, isShortcut } = action.payload;

          const selectedMattterTypePrecedents: IMatterTypePrecedents = precedentFilterByMatterType
            ? matterTypePrecedents[precedentFilterByMatterType]
            : matterTypePrecedents[selectedMatterTypeId];

          const precedents = selectedMattterTypePrecedents?.precedents || [];

          return this._precedentSvc.getChildPrecedents(matterTypeId, precedentId, isShortcut).pipe(
            mergeMap((data) => {
              const mtPrecedents: IMatterTypePrecedents = {
                matterTypeId: precedentFilterByMatterType || selectedMatterTypeId,
                precedents: this._precedentSvc.appendChildren(precedents, precedentId, data),
              };

              return [new actions.ListPrecedentsSuccess(mtPrecedents)];
            }),
            catchError((error) => of(new actions.ListPrecedentsFailure(error)))
          );
        }
      )
    )
  );

  // get the binary to upload to the matter, the precedent version history, and the user / firm / matter / folder info
  // needed to populate the docMetaInfo.
  createReadonlyPrecedentStart$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.CreateReadonlyPrecedentStart>(actions.CREATE_READONLY_PRECEDENT_START),
      withLatestFrom(
        this._store.pipe(select(selectCurrentMatterId)),
        this._store.pipe(select(selectSelectedFolderId)),
        (action, matterId, folderId) => ({
          action,
          matterId,
          folderId,
        })
      ),
      mergeMap((data) => {
        const { action } = data;
        const precedentId = action.payload.shortcutId || action.payload.id;
        return combineLatest([
          this._precedentSvc.getLatestPrecedentFile(precedentId),
          this._precedentSvc.getPrecedentVersions(precedentId),
          of(data),
        ]);
      }),
      switchMap((res) => {
        const decodedToken = this._authSvc.decodedToken;
        const [binary, precedent, data] = res;
        const docId = uuidv4();
        const payload: IDocMetaInfo = {
          firmId: decodedToken.firmId,
          matterId: data.matterId,
          userId: decodedToken.userId,
          documentId: docId,
          docName: precedent.name,
          fileType: precedent.type,
          latestVersionId: docId,
          docTypeId: 0,
          precedentId: precedent.precedentId,
          customPrecedentId: null,
          defaultTableId: null,
          defaultTableOrder: null,
          assignedToStaff: null,
          folderId: data.folderId,
        };
        const docMetaInfo = new Blob([JSON.stringify(payload)], { type: 'application/json' });
        const formData: FormData = new FormData();
        formData.append('binary', binary);
        formData.append('docMetaInfo', docMetaInfo);
        return this._documentSvc.createDocumentPackage(formData);
      }),
      map(() => new appActions.ClearCurrentModal()),
      catchError(() => {
        this._toastrSvc.show('Could not create precedent.', 'Error', {}, 'error');
        return of(new appActions.ClearCurrentModal());
      })
    )
  );

  deletePrecedentStart$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.DeletePrecedentStart>(actions.DELETE_PRECEDENT_START),
      switchMap((action) => {
        const { precedent, searchMode } = action.payload;
        const grid = searchMode === EPrecedentSearchMode.All ? 'list' : 'tree';
        return this._precedentSvc.deletePrecedent(precedent.id).pipe(
          map(() => {
            const updatedPrecedent = { ...precedent, deleteCode: 1 };
            return new actions.PrecedentTransactionStart({
              precedent: updatedPrecedent,
              type: 'delete',
              grid,
            });
          }),
          catchError((error) => of(new actions.PrecedentTransactionFailure(error)))
        );
      })
    )
  );

  undeletePrecedentStart$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.UndeletePrecedentStart>(actions.UNDELETE_PRECEDENT_START),
      switchMap((action) => {
        const { precedent, searchMode } = action.payload;
        const grid = searchMode === EPrecedentSearchMode.All ? 'list' : 'tree';
        return this._precedentSvc.undeletePrecedent(precedent.id).pipe(
          map(() => {
            const updatedPrecedent = { ...precedent, deleteCode: 0 };
            return new actions.PrecedentTransactionStart({
              precedent: updatedPrecedent,
              type: 'update',
              grid,
            });
          }),
          catchError((error) => of(new actions.PrecedentTransactionFailure(error)))
        );
      })
    )
  );

  duplicatePrecedentStart$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.DuplicatePrecedentStart>(actions.DUPLICATE_PRECEDENT_START),
      switchMap((action) => {
        const { params, searchMode } = action.payload;
        const { precedent, duplicateId, duplicateName, addOnSuccess } = params;
        const grid = searchMode === EPrecedentSearchMode.All ? 'list' : 'tree';
        return this._precedentSvc.duplicatePrecedent(params).pipe(
          map(
            () =>
              new actions.PrecedentTransactionStart({
                precedent: { ...precedent, id: duplicateId, name: duplicateName, isFirmPrecedent: true },
                type: addOnSuccess ? 'create' : 'update',
                grid,
              })
          ),
          catchError((error) =>
            of(
              new actions.PrecedentTransactionStart({
                precedent: { ...precedent, id: duplicateId },
                type: 'delete',
                grid,
              }),
              new actions.PrecedentTransactionFailure(error)
            )
          )
        );
      })
    )
  );

  precedentTransactionStart$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.PRECEDENT_TRANSACTION_START),
      withLatestFrom(
        this._store.pipe(select(selectPrecedentsTree)),
        this._store.pipe(select(selectPrecedentsList)),
        this._store.pipe(select(selectSelectedMatterTypeId)),
        (action, tree, list, matterTypeId) => ({ action, tree, list, matterTypeId })
      ),
      switchMap((stateData: IDraftPrecedentTransactionPayload) => {
        const { action, tree, list, matterTypeId } = stateData;
        const { precedent, type, grid } = action.payload;
        const getTransactedResult = (pList: IPrecedent[]) => {
          switch (type) {
            case 'create':
              return [...pList, precedent];
            case 'delete':
              return pList.filter((p: IPrecedent) => p.id !== precedent.id);
            default:
              return pList.map((p: IPrecedent) => (p.id === precedent.id ? precedent : p));
          }
        };

        let message: IMatterTypePrecedents = null;
        if (grid === 'list') {
          message = {
            precedents: getTransactedResult(list),
            matterTypeId: AllMatterTypeId,
          };
        } else if (grid === 'tree') {
          const paths = getPaths(precedent.uiPath || '/');
          const rootPrecedent = { id: null, children: tree ? [...tree] : [] };
          const parent = findParent(precedent.parentId, rootPrecedent, paths);
          if (!!parent) {
            parent.children = getTransactedResult(parent.children);
          }
          message = {
            precedents: rootPrecedent.children,
            matterTypeId,
          };
        }
        return [
          new actions.PrecedentTransactionSuccess({
            data: message,
            precedent: action.payload.precedent,
            type: type
          }),
        ];
      })
    )
  );

  selectPrecedent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SELECT_PRECEDENT),
      switchMap((action: actions.SelectPrecedent) => {
        if (!!action.payload && !!action.payload.item && !!action.payload.action) {
          if (action.payload.action.mode === 'online') {
            this._store.dispatch(new actions.PreOpenPrecedentOnline(action.payload.action));
          } else {
            this._store.dispatch(new actions.OpenPrecedent(action.payload.action));
          }
        }
        return [];
      })
    )
  );

  openPrecedent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.OpenPrecedent>(actions.OPEN_PRECEDENT),
      withLatestFrom(
        this._store.pipe(select(selectSelectedPrecedent)),
        this._store.pipe(select(selectContentModeEnabled)),
        this._store.pipe(select(selectFirmDetails)),
        this._store.pipe(select(selectRouterSnapshot)),
        (action, precedent, contentMode, firmDetails, routerSnapshot) => ({
          payload: action.payload,
          precedent,
          contentMode,
          firmDetails,
          routerSnapshot,
        })
      ),
      switchMap(({ payload, precedent, contentMode, firmDetails, routerSnapshot }) => {
        const accessible = this._precedentSvc.checkPrecedentAccessibility({ precedent, firmDetails });
        if (!accessible) {
          return [];
        }

        if (
          !!routerSnapshot &&
          !!routerSnapshot.queryParams &&
          routerSnapshot.queryParams['isRecurringMatter'] === 'true'
        ) {
          return [new recurringMatterActions.CheckPendingPrecedentName(precedent)];
        }

        // handling opening of PDFs
        if (ReadonlyTypes.includes(precedent.type)) {
          this._store.dispatch(new actions.CreateReadonlyPrecedentStart(precedent));
          return [];
        } else {
          // Make sure the precedent respect openInDesktop rule when it is shortcut
          return this._precedentSvc.getEnrichedPrecedent(precedent).pipe(
            switchMap((enrichedPrecedent: IPrecedent) => {
              const { isChangeRequired, ...customizedProps } = getCustomizedPrecedentProps(
                payload.userAction,
                enrichedPrecedent
              );

              const params = {
                action: isChangeRequired ? customizedProps.action : payload.type,
                precedentId: customizedProps.precedentId || enrichedPrecedent.shortcutId,
                ext: precedent.type,
                contentMode,
              } as IOpenPrecedentTicketParams;

              return this._offlineLauncherSvc.createOpenPrecedentTicket(params, {
                navigateClear: true,
              });
            }),
            mergeMap((ticketId) => [new actions.OpenPrecedentSuccess(ticketId)]),
            catchError((e) => of(new actions.OpenPrecedentFailure(e)))
          );
        }
      })
    )
  );

  preOpenPrecedentOnline$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.PreOpenPrecedentOnline>(actions.PRE_OPEN_PRECEDENT_ONLINE),
      withLatestFrom(
        this._store.pipe(select(selectSelectedPrecedent)),
        this._store.pipe(select(selectFirmDetails)),
        this._store.pipe(select(selectRouterSnapshot))
      ),
      mergeMap((data) => {
        const [action, precedent, firmDetails, routerSnapshot] = data;
        const accessible = this._precedentSvc.checkPrecedentAccessibility({ precedent, firmDetails });
        if (!accessible) {
          return [];
        }
        if (
          !!routerSnapshot &&
          !!routerSnapshot.queryParams &&
          routerSnapshot.queryParams['isRecurringMatter'] === 'true'
        ) {
          return [new recurringMatterActions.CheckPendingPrecedentName(precedent)];
        }
        return this._precedentSvc.getEnrichedPrecedent(precedent).pipe(
          switchMap((enrichedPrecedent) => {
            if (
              enrichedPrecedent.autoFieldsExist ||
              enrichedPrecedent.tableFieldsExist ||
              enrichedPrecedent.originInDesktop
            ) {
              this.handleOnlinePrecedentWithDesktopFields(action.payload);
            } else {
              return [new actions.OpenPrecedentOnline(action.payload)];
            }

            return [];
          })
        );
      })
    )
  );

  openPrecedentOnline$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.OpenPrecedentOnline>(actions.OPEN_PRECEDENT_ONLINE),
      withLatestFrom(
        this._store.pipe(select(selectSelectedPrecedent)),
        this._store.pipe(select(selectCurrentMatterId)),
        this._store.pipe(select(selectSelectedFolderId))
      ),
      switchMap((data) => {
        const [action, precedent, matterId, folderId] = data;

        // Make sure the precedent respect openInDesktop rule when it is shortcut
        return this._precedentSvc.getEnrichedPrecedent(precedent).pipe(
          switchMap((enrichedPrecedent: IPrecedent) => {
            const { isChangeRequired, ...customizedProps } = getCustomizedPrecedentProps(
              action.payload.userAction,
              enrichedPrecedent
            );

            const automationOptions: IAutomationOptions = {
              action: isChangeRequired ? customizedProps.action : action.payload.type,
              matterId,
              folderId,
              precedentInfo: {
                precedent: enrichedPrecedent,
              },
            };
            return this._documentAutomationSvc
              .createFromOrEditPrecedentOnline(automationOptions, { isChangeRequired, ...customizedProps })
              .pipe(switchMap(() => [new actions.OpenPrecedentSuccess(null)]));
          }),
          catchError(() => of(new actions.OpenPrecedentFailure(null)))
        );
      })
    )
  );

  createNewFolderStart$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.CreateNewFolderStart>(actions.CREATE_NEW_FOLDER_START),
      withLatestFrom(this._store.pipe(select(selectSelectedMatterTypeId))),
      exhaustMap((data: [actions.CreateNewFolderStart, string]) => {
        const [action, selectedMatterTypeId] = data;
        const request = action.payload;
        return this._precedentSvc.createNewFolder(request).pipe(
          exhaustMap(() => [
            new actions.CreateNewFolderSuccess({
              matterTypeId: request.matterTypeId || selectedMatterTypeId,
              precedents: request.draftPrecedents,
            }),
          ]),
          catchError((error) => [new actions.CreateNewFolderFailure(error)])
        );
      })
    )
  );

  prepareNewLocalPrecedentDraft$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.NewLocalPrecedentDraft>(actions.PREP_NEW_LOCAL_PRECEDENT_DRAFT),
      withLatestFrom(
        this._store.pipe(select(selectSelectedMatterTypePrecedentList)),
        this._store.pipe(select(selectSelectedPrecedent))
      ),
      switchMap(([_, precedents, selected]) => {
        if (selected === null) {
          return [new actions.NewLocalPrecedentDraft(null)];
        }

        if (selected.type === 'Folder') {
          return [new actions.NewLocalPrecedentDraft(selected as ISelectedFolder)];
        }

        // non folder precedent will not be handled.
        return [];
      })
    )
  );

  createNewLocalPrecedentDraft$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.NewLocalPrecedentDraft>(actions.NEW_LOCAL_PRECEDENT_DRAFT),
      switchMap((data) => {
        const selectedFolder = data.payload;
        const path = selectedFolder === null ? '/' : PrecedentHelper.getNewFolderPath(selectedFolder);
        const draftFolderId = uuidv4();
        const draft = PrecedentHelper.createNewFolderRequest(
          draftFolderId,
          path,
          selectedFolder?.shortcutId
        ) as IPrecedent;

        return [new actions.NewLocalPrecedentDraftReady({ precedent: draft, selectedFolder })];
      })
    )
  );

  constructor(
    private actions$: Actions,
    private _store: Store<State>,
    private _precedentSvc: PrecedentService,
    private _documentAutomationSvc: DocumentAutomationService,
    private _offlineLauncherSvc: OfflineLauncherService,
    private _dialogSvc: DialogService,
    private _translateSvc: TranslateService,
    private _toastrSvc: ToastrService,
    private _documentSvc: DocumentService,
    private _authSvc: AuthService
  ) {}

  private handleOnlinePrecedentWithDesktopFields(payload: IOfficeAction) {
    const translate = (field: string): string => {
      const translateNamespace = payload.type === 'new' ? 'Precedent.New.Warning' : 'Precedent.Edit.Warning';
      return `${translateNamespace}.${field}`;
    };

    this._dialogSvc.warning({
      title: this._translateSvc.instant(translate('Title')),
      message: this._translateSvc.instant(translate('Message')),
      actionText: this._translateSvc.instant(translate('Action.Text')),
      closeText: this._translateSvc.instant(translate('Close.Text')),
      showCancel: true,
      onClose: (confirmed: boolean) => {
        if (confirmed) {
          this._store.dispatch(new actions.OpenPrecedent(payload));
        } else {
          this._store.dispatch(new actions.OpenPrecedentOnline(payload));
        }
      },
    });
  }
}

interface IDraftPrecedentTransactionPayload {
  action: actions.PrecedentTransactionStart;
  tree: IPrecedent[];
  list: IPrecedent[];
  matterTypeId: string;
}

const findParent = (parentId: string, root: Partial<IPrecedent>, paths: string[]): Partial<IPrecedent> => {
  if (isEmptyObj(root) || isEmptyValue(parentId) || root.id === parentId || root.shortcutId === parentId) {
    return root;
  }
  const inPath = (root.children || []).find((p: IPrecedent) => paths?.includes(p.id));
  return findParent(parentId, inPath, paths);
};

const getPaths = (path: string): string[] => splitStringToArray(trim(path || '', '/'), '/');
