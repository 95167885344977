import { Action } from '@ngrx/store';
import { IPaymentDetailsTypeInit } from '../../models';

export const INFLOW_PAYMENT_TYPE_PRE_INIT = '[Inflow payment type details] Pre init';
export const INFLOW_PAYMENT_TYPE_INIT = '[Inflow payment type details] Initialise';

export class InflowPaymentTypePreInit implements Action {
  readonly type = INFLOW_PAYMENT_TYPE_PRE_INIT;
  constructor(public payload: IPaymentDetailsTypeInit) {}
}

export class InflowPaymentTypeInit implements Action {
  readonly type = INFLOW_PAYMENT_TYPE_INIT;
  constructor(public payload: IPaymentDetailsTypeInit) {}
}

export type InflowPaymentTypeDetailsAction = InflowPaymentTypeInit;
