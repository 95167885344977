export enum AppSlice {
  Accounting = 'accounting',
  AccountingBatch = 'accountingBatch',
  AnticipatedPayment = 'anticipatedPayment',
  AnticipatedPaymentLedger = 'anticipatedPaymentLedger',
  App = 'app',
  ApplyCredit = 'applyCredit',
  AdjustInvoice = 'adjustInvoice',
  AutoTime = 'autoTime',
  Calendar = 'calendar',
  CalendarReadonly = 'calendarReadonly',
  Cards = 'cards',
  Comment = 'comment',
  Correspondence = 'correspondence',
  CreatePdf = 'createPDF',
  Credit = 'credit',
  CreditJournal = 'creditJournal',
  CreditRefund = 'creditRefund',
  CostRecovery = 'costrecovery',
  FinancialSummary = 'financialSummary',
  InfoTrack = 'infoTrack',
  InvestmentAccount = 'investmentAccount',
  InvestmentLedger = 'investmentLedger',
  InvestmentTransaction = 'investmentTransaction',
  Invoice = 'invoice',
  Journal = 'journal',
  Matters = 'matters',
  MatterOptions = 'matterOptions',
  MatterTableTypes = 'matterTableTypes',
  MatterSelector = 'matterSelector',
  MatterTypes = 'matterTypes',
  MatterAddin = 'matterAddin',
  LeapApp = 'leapApp',
  NamedList = 'namedlist',
  Notification = 'notification',
  OfficeBankDeposit = 'officeBankDeposit',
  OfficeBulkInvoice = 'officeBulkInvoice',
  OfficeJournal = 'officeJournal',
  OfficeReceipt = 'officeReceipt',
  OfficeSearchTransaction = 'OfficeSearchTransaction',
  OfficeOverdueInvoices = 'OfficeOverdueInvoices',
  OfficeChangeInvoiceStatus = 'OfficeChangeInvoiceStatus',
  BatchSearch = 'BatchSearch',
  PaymentsLedger = 'paymentsLedger',
  OfficePayment = 'officePayment',
  PaymentRequest = 'paymentRequest',
  PaymentTypeDetails = 'paymentTypeDetails',
  PayAnticipated = 'payanticipated',
  Persons = 'persons',
  PowerMoneyAccount = 'powerMoneyAccount',
  PowerMoneyTransaction = 'powerMoneyTransaction',
  PowerMoneyLedger = 'powerMoneyLedger',
  Preview = 'preview',
  ProtectTrustFund = 'protectedTrustFund',
  PdfViewer = 'pdfViewer',
  RecurringMatterList = 'recurringMatters',
  RecurringMatter = 'recurringMatterDetails',
  RecurringMatterOptions = 'recurringMatterOptions',
  RecurringMatterFeeLedger = 'recurringMatterFeeLedger',
  RecurringMatterFee = 'recurringMatterFee',
  RecurringMatterCostRecoveryLedger = 'recurringMatterCostRecoveryLedger',
  RecurringMatterCostRecovery = 'recurringMatterCostRecovery',
  RecurringMatterCorrespondence = 'recurringMatterCorrespondence',
  Reporting = 'reporting',
  Router = 'router',
  Staff = 'staff',
  SpecialFee = 'specialFee',
  TaskCodeSelector = 'taskCodeSelector',
  TimeFee = 'timefee',
  TrustBankAccountRegister = 'trustBankAccountRegister',
  TrustBankDeposit = 'trustBankDeposit',
  TrustJournal = 'trustJournal',
  TrustLedger = 'trustLedger',
  TrustPayment = 'trustPayment',
  TrustReceipt = 'trustReceipt',
  TrustToOffice = 'trustToOffice',
  PrintCheque = 'printCheque',
  UserProfile = 'userProfile',
  SupportRequest = 'supportRequest',
  LeapHost = 'leapHost',
}
