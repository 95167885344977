import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MatterTypesEffects } from './store';
import { SchemaService } from '@app/shared/services';
import { reducers } from './store/reducers';
import { matterTypesServices } from '@app/features/+matter-types/services';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('matterTypes', reducers),
    EffectsModule.forFeature([MatterTypesEffects]),
  ],
  declarations: [],
  providers: [SchemaService, ...matterTypesServices],
})
export class MatterTypesStateModule {}
