import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EApiPath } from '@app/shared/services';
import { AuthService, LogService } from '@app/core/services';
import { environment } from '@env/environment';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private logSvc: LogService, private authSvc: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // devops noted not required, but leaving the kasada late load here if we need it.
        // const isKsdLoaded = 'KPSDK' in globalThis;

        // if (error.status === 429 && !isKsdLoaded) {
        //   // Kasada has not been loaded and the backend has enabled it, load kasada
        //   return this.loadKsd(next, error, request);
        // }

        return this.handleError(error, request);
      })
    );
  }

  /**
   * Handles 429 errors from Kasada
   */
  loadKsd(next: HttpHandler, error: HttpErrorResponse, request: HttpRequest<any>) {
    return new Observable<HttpEvent<any>>((observer) => {
      const ksdHandler = () => {
        window.KPSDK.configure(environment.config.ksd.configurations);

        // now retry
        next.handle(request).pipe(
          catchError((err: HttpErrorResponse) => {
            return this.handleError(err, request);
          })
        ).subscribe(observer);
      }

      // Load kasada sdk, retry
      document.addEventListener('kpsdk-load', ksdHandler);
      
      const script = document.createElement('script');
      script.src = `${environment.config.endpoint.docs}/149e9513-01fa-4fb0-aad4-566afd725d1b/2d206a39-8ed7-437e-a3be-862e0f06eea3/p.js`;
      script.addEventListener('error', () => {
        document.removeEventListener('kpsdk-load', ksdHandler);

        this.handleError(error, request).subscribe(observer);
      })

      document.head.appendChild(script);

      return () => {
        document.removeEventListener('kpsdk-load', ksdHandler);
      }
    });
  }


  handleError(error: HttpErrorResponse, request: HttpRequest<any>): Observable<never> {
    let errorMessage = '';
    if ('ErrorEvent' in globalThis && error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error

      // if the error is related to unauthorised & app also not able to return valid token
      if (error.status === 401 && !this.authSvc.token) {
        this.authSvc.logout();
        return EMPTY;
      }

      const errorResponseUrl = error.url;
      const isAccountingRequest =
        errorResponseUrl.includes(EApiPath.Accounting) || errorResponseUrl.includes('api/sirius-accounting');

      if (isAccountingRequest) {
        // if it is a accounting request, rethrow the error and let accounting-api.effects to handle the error
        // passing the httpRequest is for handling the accounting warning error
        // and re-triggering the request if the user select ignoring the warning
        return throwError(() => ({ httpError: error, httpRequest: request }));
      }

      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    this.logSvc.error('Http Error Interceptor: ', errorMessage);

    return throwError(() => error); // prevent from silently dying

  }
}
