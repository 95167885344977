import { createSelector } from '@ngrx/store';

import * as fromMatterFiltersModal from '../reducers/matter-filters-modal.reducer';

import { State, selectMattersState } from '../reducers';

export const selectMatterFiltersModalState = createSelector(
  selectMattersState,
  (state: State) => state.matterFiltersModal
);

export const selectMatterFiltersModalClose = createSelector(
  selectMatterFiltersModalState,
  (state: fromMatterFiltersModal.State) => fromMatterFiltersModal.selectOnClose(state)
);

export const selectMatterFiltersModalData = createSelector(
  selectMatterFiltersModalState,
  (state: fromMatterFiltersModal.State) => fromMatterFiltersModal.selectFilterBy(state)
);
