import { Component, EventEmitter, forwardRef, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'sc-textarea',
  templateUrl: './textarea.component.html',

  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TextareaComponent), multi: true }],
})
export class TextareaComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  inputLabel: string;
  @Input()
  inputId = `textarea-${uuidv4()}`;
  @Input()
  isDisabled: boolean;
  @Input()
  placeholder = '';
  @Input()
  wrapperClass: string;
  @Input()
  inputClass: string;
  @Input()
  inputHeight: string;
  @Input()
  delay = 200;
  @Input()
  rowNumber = 5;
  @Input()
  styleRule = {};
  @Input()
  focus: boolean;
  @Input()
  required: boolean;

  @Input()
  get hasError(): boolean {
    return this._hasError;
  }

  set hasError(value: boolean) {
    if (value !== undefined) {
      this.classes += value ? 'border-danger' : '';
      this._hasError = value;
    }
  }

  @Input()
  bindDefaultClasses = true;
  @Output()
  onBlur = new EventEmitter<any>();
  @Output()
  onKeydown = new EventEmitter<KeyboardEvent>();

  private _value: any = '';
  private _hasError: boolean;

  private inputValueStream$ = new Subject<string>();
  private unsub = new Subject<void>();

  constructor() {}

  @HostBinding('class')
  classes = '';

  ngOnInit() {
    // Bind 'form-group' and 'col' classes if inputLabel presents
    const defaultClasses: string = this.inputLabel && this.bindDefaultClasses ? 'form-group col ' : '';
    this.classes += defaultClasses;

    if (this.wrapperClass) {
      this.classes += this.wrapperClass;
    }

    this.inputValueStream$
      .pipe(debounceTime(this.delay), distinctUntilChanged(), takeUntil(this.unsub))
      .subscribe((newValue) => {
        this.onChange(newValue);
      });
  }

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
    }
  }

  writeValue(value: any) {
    this._value = value;
  }

  onChange = (_) => {};
  onTouched = () => {};

  registerOnChange(fn: (value?: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  blur(): void {
    this.onBlur.emit(this.value);
  }

  handleFocus(elm: any): void {
    elm.select();
  }

  keydown(event: KeyboardEvent): void {
    this.onKeydown.emit(event);
  }

  handleOnChange(event): void {
    this._value = event.target.value;
    this.inputValueStream$.next(this._value);
  }
}
