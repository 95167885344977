import { Action } from '@ngrx/store';
import { INotificationResponse } from '@app/features/+notification/models/notification.model';

export enum NotificationDbActionTypes {
  LOAD_NOTIFICATIONS_DB_START = '[Notification DB] load notifications from db start',
  LOAD_NOTIFICATIONS_DB_SUCCESS = '[Notification DB] succeed to load notifications from db',
  LOAD_NOTIFICATIONS_DB_FAIL = '[Notification DB] unable to load notifications from db',

  NOTIFICATIONS_SAVE_DB_START = '[Notification save to db] start',
  NOTIFICATIONS_SAVE_DB_SUCCESS = '[Notification save to db] success',
  NOTIFICATIONS_SAVE_DB_FAILURE = '[Notification save to db] failure',

  NOTIFICATIONS_DELETE_DB_START = '[Notification delete from db] start',
  NOTIFICATIONS_DELETE_DB_SUCCESS = '[Notification delete from db] success',
  NOTIFICATIONS_DELETE_DB_FAILURE = '[Notification delete from db] failure',
}

export class LoadNotificationsDbStart implements Action {
  readonly type = NotificationDbActionTypes.LOAD_NOTIFICATIONS_DB_START;

  constructor(public payload: any) {}
}

export class LoadNotificationsDbSuccess implements Action {
  readonly type = NotificationDbActionTypes.LOAD_NOTIFICATIONS_DB_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadNotificationsDbFail implements Action {
  readonly type = NotificationDbActionTypes.LOAD_NOTIFICATIONS_DB_FAIL;

  constructor(public payload: any) {}
}

export class NotificationsSaveDbStart implements Action {
  readonly type = NotificationDbActionTypes.NOTIFICATIONS_SAVE_DB_START;

  constructor(public payload: { data: INotificationResponse }) {}
}

export class NotificationsSaveDbSuccess implements Action {
  readonly type = NotificationDbActionTypes.NOTIFICATIONS_SAVE_DB_SUCCESS;

  constructor(public payload: any) {}
}

export class NotificationsSaveDbFailure implements Action {
  readonly type = NotificationDbActionTypes.NOTIFICATIONS_SAVE_DB_FAILURE;

  constructor(public payload: any) {}
}

export class NotificationsDeleteDbStart implements Action {
  readonly type = NotificationDbActionTypes.NOTIFICATIONS_DELETE_DB_START;

  constructor(public payload: { ids: number[]; lastEvaluatedKey: string }) {}
}

export class NotificationsDeleteDbSuccess implements Action {
  readonly type = NotificationDbActionTypes.NOTIFICATIONS_DELETE_DB_SUCCESS;

  constructor(public payload: any) {}
}

export class NotificationsDeleteDbFailure implements Action {
  readonly type = NotificationDbActionTypes.NOTIFICATIONS_DELETE_DB_FAILURE;

  constructor(public payload: any) {}
}

export type NotificationDbActions =
  | LoadNotificationsDbStart
  | LoadNotificationsDbSuccess
  | LoadNotificationsDbFail
  | NotificationsSaveDbStart
  | NotificationsSaveDbSuccess
  | NotificationsSaveDbFailure
  | NotificationsDeleteDbStart
  | NotificationsDeleteDbSuccess
  | NotificationsDeleteDbFailure;
