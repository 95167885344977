import { createSelector } from '@ngrx/store';

import * as fromFeeModal from '../reducers/fee-modal.reducers';
import { TimeFeeState, selectTimeFeeState } from '../reducers';

export const selectFeeModalState = createSelector(selectTimeFeeState, (state: TimeFeeState) => state.feeModal);

export const selectFeeNewWin = createSelector(selectFeeModalState, (state: fromFeeModal.State) =>
  fromFeeModal.selectFeeNewWin(state)
);

export const selectFeeSetupData = createSelector(selectFeeModalState, (state: fromFeeModal.State) =>
  fromFeeModal.selectFeeSetupData(state)
);

export const selectFeeModalLoading = createSelector(selectFeeModalState, (state: fromFeeModal.State) => state.loading);
