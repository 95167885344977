import * as fromTimeFeeEntry from '../reducers/time-fee-entry.reducers';
import { createSelector } from '@ngrx/store';
import { selectTimeFeeEntryState } from '@app/features/+time-fee-ledger/store';
import { ITimeFeeResponse } from '@app/features/+time-fee-ledger/models';

export const selectTimeFeeEntryDetails = createSelector(selectTimeFeeEntryState, (state: fromTimeFeeEntry.State) =>
  fromTimeFeeEntry.getTimeFeeEntry(state)
);

// check whether timeFeeEntry is billed
export const selectIsTimeFeeEntryBilled = createSelector(
  selectTimeFeeEntryDetails,
  (timeFeeEntry: ITimeFeeResponse) =>
    timeFeeEntry && timeFeeEntry.Fee && (!!timeFeeEntry.Fee.InvoiceGUID || !!timeFeeEntry.Fee.Deleted)
);

export const selectTimeEntrySummary = createSelector(selectTimeFeeEntryState, (state: fromTimeFeeEntry.State) =>
  fromTimeFeeEntry.selectTimeEntrySummary(state)
);
