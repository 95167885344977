<ng-container *ngFor="let addin of addins$ | async">
  <hr class="aside-slim-separator" />
  <div *ngFor="let button of addin.Button">
    <a
      href
      scPreventDefault
      class="x-list-group-action-slim-item"
      [popover]="button.Name"
      triggers="mouseenter:mouseleave"
      [adaptivePosition]="false"
      placement="left"
      container="body"
      containerClass="x-popover-no-arrow"
      (click)="onClicked(button, addin)"
    >
      <div class="x-icon-container">
        <div class="x-icon d-flex align-items-center">
          <img style="height: 100%;" [src]="button.Icon" />
        </div>
      </div>
    </a>
  </div>
</ng-container>
