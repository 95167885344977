import { Component, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TransactionNumberService } from 'app/features/accounting/services/transaction-number/transaction-number.service';
import { IBankAccount } from '@app/features/+payments-debtors/models';

import { Subscription, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { isObjEqual } from '@server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-receipt-number',
  templateUrl: './receipt-number.component.html',
})
export class ReceiptNumberComponent implements OnDestroy {
  @Input()
  get bankAccount(): IBankAccount {
    return this._bankAccount;
  }

  set bankAccount(value: IBankAccount) {
    if (value) {
      this._bankAccount = value;
      if (this._transactionNumberForm) {
        const receipt = this._transactionNumberService.getReceiptNumber(value);
        this.autoTransactionNumber = receipt.TransactionNumber;
        this._transactionNumberForm.patchValue({
          autoNumber: receipt.Auto,
          transactionNumber: receipt.TransactionNumber,
        });
      }
    }
  }

  @Input() transactionNumberLabel: string;
  @Input() validationErrors: boolean;
  @Input() viewMode = false;

  @Input()
  get transactionNumberForm(): FormGroup {
    return this._transactionNumberForm;
  }

  set transactionNumberForm(form: FormGroup) {
    if (form) {
      this._transactionNumberForm = form;
      this.autoTransactionNumber = form.value.transactionNumber;
      this.subscribetoFormChanges();
      this.configTransactionNumberReadOnly();
    }
  }

  public autoTransactionNumber: string;
  public transactionNumberReadOnly: boolean;
  public _autoNumberSub: Subscription;

  private _bankAccount: IBankAccount;
  private _transactionNumberForm: FormGroup;
  private unsub = new Subject<void>();

  constructor(private _transactionNumberService: TransactionNumberService) {}

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  private subscribetoFormChanges() {
    if (this._transactionNumberForm) {
      this._autoNumberSub = this._transactionNumberForm.controls.autoNumber.valueChanges
        .pipe(distinctUntilChanged(isObjEqual), takeUntil(this.unsub))
        .subscribe(() => this.patchAutoNumber());
    }
  }

  private patchAutoNumber() {
    const autoChecked = this._transactionNumberForm.controls.autoNumber.value;

    const nextTN = autoChecked
      ? this._transactionNumberService.getReceiptNumber(this._bankAccount)?.TransactionNumber
      : '';
    if (autoChecked && nextTN && nextTN !== this.autoTransactionNumber && this._bankAccount) {
      this.autoTransactionNumber = nextTN;
    }

    if (!!this.autoTransactionNumber) {
      this._transactionNumberForm.patchValue({
        transactionNumber: autoChecked ? this.autoTransactionNumber : '',
      });
    }
    this.configTransactionNumberReadOnly();
  }

  private configTransactionNumberReadOnly() {
    this.transactionNumberReadOnly = !!this._transactionNumberForm.controls['autoNumber'].value;
  }
}
