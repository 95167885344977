import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullscreenOverlayContainer, OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';

import { ErrorHandlerService, ErrorHandlerLoggerService } from './service';
import { DEFAULT_ERROR_HANDLER_CONFIG, ERROR_HANDLER_CONFIG, ERROR_HANDLER_LOGGER } from './error-handler.config';
import { ErrorHandlerComponent } from './components';

@NgModule({
  declarations: [ErrorHandlerComponent],
  imports: [CommonModule, OverlayModule, A11yModule],
})
export class ErrorHandlerModule {
  constructor(overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement().classList.add('x-cdk-overlay-container');
  }

  public static forRoot(): ModuleWithProviders<ErrorHandlerModule> {
    return {
      ngModule: ErrorHandlerModule,
      providers: [
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
        { provide: ERROR_HANDLER_LOGGER, useClass: ErrorHandlerLoggerService },
        { provide: ERROR_HANDLER_CONFIG, useValue: DEFAULT_ERROR_HANDLER_CONFIG },
      ],
    };
  }
}
