import { Component, forwardRef, HostBinding, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { xAnimationPulseChildren } from '@app/shared/animations/animations';
import { environment } from '@env/environment';
import { Regions } from '@app/shared/models/config.model';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'sc-date',
  templateUrl: './date.component.html',
  animations: [xAnimationPulseChildren],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateComponent), multi: true }],
})
export class DateComponent implements OnInit, ControlValueAccessor {
  dateFormat: string;
  @Input()
  placeholder: string;
  @Input()
  label: string;
  @Input()
  hideIcon: boolean;
  @Input()
  isDisabled: boolean;
  @Input()
  inputClass: string;
  @Input()
  inputId = `calendar-${uuidv4()}`;
  @Input()
  inlineMode: boolean;
  @Input()
  set wrapperClass(data: string) {
    this.classes = data || '';
  }
  // p-calendar's component class
  @Input()
  styleClass: string;

  @Input()
  minDate: Date;

  @Input()
  maxDate: Date;

  private _value: any;

  constructor() {
    switch (environment.config.brand.region.toLowerCase()) {
      case Regions.US:
        this.dateFormat = 'mm/dd/yy';
        break;
      case Regions.CA:
        this.dateFormat = 'yy/mm/dd';
        break;
      default:
        this.dateFormat = 'dd/mm/yy';
        break;
    }
  }

  @HostBinding('class')
  classes = '';
  ngOnInit() {}

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
    }
  }

  writeValue(value: any) {
    this._value = value;
  }

  onChange = (_) => {};
  onTouched = () => {};

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * We only output the calendar value once we select a date or the textInput is on blur
   * */
  handleSelectDate(selectDate: any) {
    this.value = selectDate;
    this.onChange(this._value);
  }

  /**
   * We only output the calendar value once we select a date or the textInput is on blur
   * */
  handleBlurOnInput() {
    this.onChange(this._value);
  }
}
