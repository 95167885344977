<sc-modal-header scPreventDefault (modalDismiss)="dismiss()">
  {{ 'PopupBlocker.Heading' | translate }}
</sc-modal-header>
<div class="modal-body text-center">
  <h3>{{ 'PopupBlocker.Heading' | translate }}</h3>
  <p>
    Please select <strong *ngIf="!isFirefox">Always allow pop-ups from https://{{ brandUrl }}</strong>
    <strong *ngIf="isFirefox">'Allow popup-ups for {{ brandUrl }}'</strong> to continue.
  </p>
  <ng-container *ngIf="!isOther && !isSafari">
    <ng-container *ngIf="!isTitleX">
      <img src="./assets/images/popup-blocker/leap/Chrome.png" width="552" *ngIf="isChrome" />
      <img src="./assets/images/popup-blocker/leap/Firefox.png" width="552" *ngIf="isFirefox" />
      <img src="./assets/images/popup-blocker/leap/IE.png" width="552" *ngIf="isInternetExplorer" />
      <img src="./assets/images/popup-blocker/leap/Edge.png" width="552" *ngIf="isMicrosoftEdge" />
    </ng-container>
    <ng-container *ngIf="isTitleX">
      <img src="./assets/images/popup-blocker/titlex/{{ region }}/Chrome.png" width="552" *ngIf="isChrome" />
      <img src="./assets/images/popup-blocker/titlex/{{ region }}/Firefox.png" width="552" *ngIf="isFirefox" />
      <img src="./assets/images/popup-blocker/titlex/{{ region }}/IE.png" width="552" *ngIf="isInternetExplorer" />
      <img src="./assets/images/popup-blocker/titlex/{{ region }}/Edge.png" width="552" *ngIf="isMicrosoftEdge" />
    </ng-container>
  </ng-container>

  <h6 class="text-primary">
    <strong>{{ 'PopupBlocker.Information.Label' | translate }} : </strong>
    <ng-container *ngIf="isChrome">
      {{ 'PopupBlocker.Information.Text.AddressBarRightCorner' | translate }}
    </ng-container>
    <ng-container *ngIf="isFirefox"> {{ 'PopupBlocker.Information.Text.TopOfPage' | translate }} </ng-container>
    <ng-container *ngIf="isInternetExplorer || isMicrosoftEdge">
      {{ 'PopupBlocker.Information.Text.BottomOfPage' | translate }}
    </ng-container>
    <ng-container *ngIf="isOther"> {{ 'PopupBlocker.Information.Text.Unknown' | translate }} </ng-container>
  </h6>
</div>
