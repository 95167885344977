import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sc-modal-toolbar',
  templateUrl: './modal-toolbar.component.html',
})
export class ModalToolbarComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
