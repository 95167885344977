import { createAction, props } from '@ngrx/store';
import { INewMatterModalSetupData } from '@app/features/+matter-list/models';

export const newMatterModalOnOpen = createAction(
  '[New Matter Modal] on open event',
  props<{ payload: INewMatterModalSetupData }>()
);

export const gotoMatterDetails = createAction(
  '[New Matter Modal] go to matter details page',
  props<{ fileNumber: string }>()
);

// Action type for card form value
export const setCardFormValue = createAction(
  '[New Matter Modal Card Form] set cardFormValue value on state',
  props<{ payload: any }>()
);
