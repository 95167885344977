<a
  class="nav-link"
  *ngIf="!heading && hasLink"
  [class.disabled]="isDisabled"
  [routerLink]="link"
  [routerLinkActive]="['active']"
  [routerLinkActiveOptions]="{ exact: true }"
  [tabindex]="tabindex"
>
  <ng-content></ng-content>
</a>
<a
  class="nav-link"
  *ngIf="heading && !hasLink"
  href
  scPreventDefault
  [class.disabled]="isDisabled"
  [class.active]="isActive"
  [class.detailed]="subHeading"
  [tabindex]="tabindex"
>
  <sc-icon *ngIf="icon" class="nav-link-icon" [iconName]="icon"></sc-icon>{{ heading | translate }}
  <span *ngIf="subHeading" class="nav-link-subheading">{{ subHeading | translate }}</span>
</a>
