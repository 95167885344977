import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'sc-notification-icon',
  templateUrl: './notification-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationIconComponent implements OnInit, OnDestroy {
  private _count: number;
  @Input()
  get count() {
    return this._count;
  }
  set count(value: number) {
    this._count = value;
  }

  get notificationBellInfo(): string {
    if (!!this._count) {
      return this._count > 9 ? '9+' : this._count.toString();
    }
  }

  constructor() {}

  @HostBinding('class.x-notification-badge-container')
  ngOnInit() {}

  ngOnDestroy() {}
}
