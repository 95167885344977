<section cdkTrapFocus [cdkTrapFocusAutoCapture]="true" class="btp-error-handler__container">
  <div class="modal-header {{ textClass }}">
    <h5 class="modal-title">{{ compData.title }}</h5>
  </div>
  <div class="modal-body">
    <div class="x-icon-container {{ textClass }}">
      <svg class="x-icon">
        <use [attr.xlink:href]="'#' + 'error-circle-outline'"></use>
      </svg>
    </div>
    <h5 class="mt-2">{{ compData.message }}</h5>
  </div>
  <div class="modal-footer">
    <button class="btn {{ actionBtnTextClass }}" (click)="handleClickOnActionBtn()">
      {{ compData.actionBtnText }}
    </button>
    <button *ngIf="compData.showCancel" class="btn x-btn-default" (click)="dismiss()">
      {{ compData.cancelBtnText }}
    </button>
  </div>
</section>
