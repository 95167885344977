import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IToolbarFormData, IToolbarFormStatus } from '@app/shared/models/payment-toolbar.model';
import { isObjEqual } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-payment-toolbar',
  templateUrl: './payment-toolbar.component.html',
})
export class PaymentToolbarComponent implements OnInit, OnDestroy {
  @Input()
  set status(data: IToolbarFormStatus) {
    if (!!data) {
      this._status = { ...this._status, ...data };
    }
  }

  get status() {
    return this._status;
  }

  @Input()
  set inputData(data: IToolbarFormData) {
    if (!!this.toolbarForm && !!data) {
      this.toolbarForm.patchValue(data);
    }
  }

  @Input()
  printAndEmail: boolean;

  @Output()
  onToolbarFormUpdated = new EventEmitter<IToolbarFormData>();
  @Output()
  onPrint = new EventEmitter<void>();

  public toolbarForm: FormGroup;
  private _status: IToolbarFormStatus;
  private _toolbarFormSub: Subscription;
  private unsub = new Subject<void>();

  constructor(private _formBuilder: FormBuilder) {
    this._status = {
      printOnSaveHidden: false,
      reconciledDisabled: true,
      voidChequeDisabled: true,
      printChequeDisabled: true,
    };
    this.createForm();
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  private createForm(): void {
    this.toolbarForm = this._formBuilder.group({
      printOnSave: false,
      reconciled: false,
      voidCheque: false,
      printCheque: false,
    });
    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges(): void {
    this._toolbarFormSub = this.toolbarForm.valueChanges
      .pipe(distinctUntilChanged(isObjEqual), takeUntil(this.unsub))
      .subscribe((formData: IToolbarFormData) => {
        this.onToolbarFormUpdated.emit(formData);
      });
  }

  print(): void {
    this.onPrint.emit();
  }

  get hideDropdown(): boolean {
    // hide dropdown if all status are disabled
    if (this._status.reconciledDisabled && this._status.voidChequeDisabled && this._status.printChequeDisabled) {
      return true;
    } else {
      return false;
    }
  }
}
