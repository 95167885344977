import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CorrespondenceListMetaStorageService, CorrespondenceService, FolderService } from './services';
import { AppSlice } from '@app/core/store';
import { reducers } from './store/reducers';
import { FolderEffects } from './store/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(AppSlice.Correspondence, reducers),
    EffectsModule.forFeature([FolderEffects]),
  ],
  providers: [FolderService, CorrespondenceService, CorrespondenceListMetaStorageService],
})
export class FolderStateModule {}
