import { Pipe, PipeTransform } from '@angular/core';
/*
 * Text snippet
 */
@Pipe({ name: 'recipient' })
export class RecipientPipe implements PipeTransform {
  transform(input: any): string {
    if (input) {
      if (
        (input.indexOf(`'`) === 0 && input.indexOf(`'`, 1) === input.length - 1) ||
        (input.indexOf(`"`) === 0 && input.indexOf(`"`, 1) === input.length - 1)
      ) {
        return input.substr(1, input.length - 2);
      }
    }
    return input;
  }
}
