export interface GetLayoutSaveResponse {
  tableObject: any;
  criticalDates: LayoutRendererCriticalDate;
}

export interface LayoutRendererCriticalDate {
  [property: string]: LayoutRendererCriticalDateValue;
}

export interface LayoutRendererCriticalDateValue {
  id: string;
  newValue: string;
  oldValue: string;
}

export interface ICostCalculatorTableInfo {
  __name: string;
  tableId: string;
  layoutId: string;
  __id: string;
}

export interface SchemaLayoutList {
  deleteCode: number;
  deleteDescription: string;
  description: string;
  hash: string;
  id: string;
  items: Array<{
    deleteCode: number;
    listId: string;
    order: number;
    region: string;
    text: string;
  }>;
  name: string;
  region: string;
  userDefinable: boolean;
  type?: string; // added to serve lsdk-v2 contract of schema.customiseList response
}

export const costCalculatorTableInfo: ICostCalculatorTableInfo = {
  __name: 'Quick Calculator',
  tableId: '89',
  layoutId: '62',
  __id: '62',
};

export enum ELayoutRendererAction {
  EDIT_LIST = 'edit-list',
  POPOUT_WIN_OPENED = 'popout-win-opened',
  POPOUT_WIN_OPEN_FAIL = 'popout-win-open-fail',
}

export enum EditListMode {
  UPDATE = 1,
  SELECT,
}

export enum ListType {
  STANDARD,
  CUSTOMISED,
}

export interface CalcRendererInit {
  iFrame: {
    url: string;
    width: number;
    height: number;
  };
  actions: {
    save: string;
    saveV2: string;
    cancel: string;
  };
  calcObject: {
    sessionId: string;
    className: string;
    objectId: string;
  };
  layout: {
    appName: string;
    id: string;
    isSuper: boolean;
  };
}

export interface MatterLayout {
  baseUrl: string;
  objectId: string;
  className: string;
  calcSessionId: string;
  matterId: string;
  isSuperLayout: boolean;
}
