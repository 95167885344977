import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { auxRoutes, mainRoutes, errorRoutes } from '@app/core/routes';

// TODO: fix this
export const errorHandler = (err: any): any => {
  console.warn('Route error?', err);
  return '';
};

const routes: Routes = [...mainRoutes, ...auxRoutes, ...errorRoutes];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      canceledNavigationResolution: 'replace',
      paramsInheritanceStrategy: 'always',
      urlUpdateStrategy: 'eager',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
