import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AppSlice } from '@app/core/store';
import { reducers } from '@app/features/autotime/store/reducers';
import { AutoTimeEffect } from '@app/features/autotime/store/effects';
import { AutoTimeService } from './services/autotime.service';
import { AutoTimeHeaderComponent } from './components/autotime-header/autotime-header.component';
import { SharedModule } from '@app/shared';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature(AppSlice.AutoTime, reducers),
    EffectsModule.forFeature([AutoTimeEffect]),
  ],
  declarations: [AutoTimeHeaderComponent],
  exports: [AutoTimeHeaderComponent],
  providers: [AutoTimeService],
})
export class AutoTimeModule {}
