import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FirstDataRenderedEvent, GridOptions, GridApi } from '@ag-grid-community/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { delayWhen, filter, map, takeUntil } from 'rxjs/operators';

import { selectUIPreference } from '@app/core/store';
import { IGlobalUIPreference } from '@app/core/models';

@Directive({
  selector: '[scAutoGridResize]',
})
export class AutoGridResizeDirective implements OnInit, OnDestroy {
  @Input()
  gridOptions: GridOptions;

  private _gridApi: GridApi;
  private isFirstDataRendered = new BehaviorSubject(false);
  private _asideExpandedSub: Subscription;
  private unsub = new Subject<void>();

  constructor(private _store: Store<any>) {}

  @HostListener('firstDataRendered', ['$event'])
  onFirstDataRendered(event: FirstDataRenderedEvent) {
    this.isFirstDataRendered.next(true);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeGrid();
  }

  ngOnInit(): void {
    this.gridOptions.onGridReady = (params: { api: any }) => {
      this._gridApi = params.api;
      this.resizeGrid();
    };

    this._asideExpandedSub = this._store
      .pipe(
        select(selectUIPreference),
        map((uiPreference: IGlobalUIPreference) => uiPreference.asideExpanded),
        delayWhen(() => this.isFirstDataRendered.pipe(filter((isFirstDataRendered) => !!isFirstDataRendered))),
        takeUntil(this.unsub)
      )
      .subscribe((asideExpanded: boolean) => {
        setTimeout(() => {
          this.resizeGrid();
        }, 0);
      });
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
    this.isFirstDataRendered.complete();
  }

  resizeGrid(): void {
    if (this._gridApi) {
      this._gridApi.sizeColumnsToFit();
    }
  }
}
