import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/services';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPersonListResponseSchema } from '@app/features/+person/models';
import { map } from 'rxjs/operators';
import { IPerson } from '@app/shared/models';

@Injectable()
export class PersonService extends BaseService {
  private readonly path: string;

  constructor(private http: HttpClient) {
    super();
    this.path = `${this.siriusPath}/api/persons`;
  }

  public getAll(lastRowVer = 0): Observable<IPersonListResponseSchema> {
    const url = this.path + `?lastRowVer=${lastRowVer}`;
    return this.http.get<IPersonListResponseSchema>(url).pipe(
      map((res: IPersonListResponseSchema) => ({
        ...res,
        data: res.data || [],
      }))
    );
  }

  public getPerson(id: string): Observable<IPerson> {
    const url = this.path + `/${id}`;
    return this.http.get<IPerson>(url);
  }

  public savePersons(persons: IPerson[]): Observable<any> {
    return this.http.put(this.path, persons);
  }

  public getRelatedCardsIds(id: string): Observable<string[]> {
    const url = `${this.path}/${id}/relatedCards`;
    return this.http.get<string[]>(url);
  }

  public getRelatedMattersIds(id: string): Observable<string[]> {
    const url = `${this.path}/${id}/relatedMatters`;
    return this.http.get<string[]>(url);
  }
}
