import { Component, Input, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'sc-card-type-circle',
  templateUrl: './card-type-circle.component.html',
})
export class CardTypeCircleComponent implements OnInit {
  @Input()
  ctName: string;
  @Input()
  ctSize: string;
  @Input()
  ctCount: string;
  @Input()
  ctNotify: string;

  constructor() {}

  @HostBinding('class.position-relative')
  ngOnInit() {}
}
