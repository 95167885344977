import { Component, HostBinding, OnInit } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { isFunction } from '@server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-dynamic-cell',
  templateUrl: './dynamic-cell.component.html',
})
export class DynamicCellComponent implements AgRendererComponent {
  @HostBinding('class.x-dynamic-cell')
  public content: string;
  public contentActive: boolean;
  public iconName: string;
  public iconSize: string;
  public isHidden: boolean;
  constructor() {}

  agInit(params: any): void {
    this.configDynamicCell(params);
  }

  refresh(params: any): boolean {
    this.configDynamicCell(params);
    return true;
  }

  private configDynamicCell(params: any): void {
    if (!!params && !!params.data) {
      const rowObj = params.data;
      this.content = isFunction(params['getContent']) ? params.getContent(rowObj) : '';
      this.contentActive = isFunction(params['getContentActive']) && params.getContentActive ? params.getContentActive(rowObj) : false;
      this.iconName = isFunction(params['getIconName']) && params.getIconName ? params.getIconName(rowObj) : '';
      this.iconSize = isFunction(params['getIconSize']) && params.getIconSize ? params.getIconSize(rowObj) : 'x-icon-lg';
      this.isHidden = isFunction(params['getIconHidden']) && params.getIconHidden ? params.getIconHidden(rowObj) : false;
    }
  }

}
