<ul
  class="x-list-group-content overflow-auto"
  *ngIf="!initialising"
  cdkDropList
  (cdkDropListDropped)="handleCDKDrop($event)"
>
  <sc-detail-entry-item
    class="list-group-item"
    *ngFor="let item of visibleDetailEntriesTree; let i = index"
    cdkDragBoundary=".x-list-group-content"
    cdkDrag
    cdkDragLockAxis="y"
    [contextMenuActions]="contextMenu"
    [detailEntry]="item"
    [detailInfo]="detailInfo"
    [showMatterType]="!!item.matterType"
    [detailEntries]="detailEntries"
    [isRecurringMatter]="isRecurringMatter"
    (onOpenDetailEntry)="openDetailEntry($event)"
    (onNewDetail)="newDetail($event)"
    (onCardSelected)="openReadCardDetails($event)"
    (onCardUnSelected)="resetReadCardDetails()"
    (onToggleContextMenu)="toggleContextMenu($event)"
    (onAddActingPerson)="addActingPerson($event)"
    (onRemoveActingPerson)="removeActingPerson($event)"
    (onDrop)="onDrop($event)"
  >
  </sc-detail-entry-item>
</ul>

<sc-pre-load
  *ngIf="loading"
  [background]="'bg-light-transparent'"
  positionClass="absolute"
  icon="orb"
  size="x-icon-xxl"
></sc-pre-load>
