<div [formGroup]="payToForm" *ngIf="!readOnly; else readOnlyDisplay">
  <label>{{ label || 'PaymentDetails.Label.PayTo' | translate }}</label>
  <div class="x-input-group" style="margin-bottom: 1rem;">
    <!-- [typeaheadSelectFirstItem]="false" Requires ngx-bootstrap update -->
    <input
      [typeahead]="cardEntries$ | async | scCardFilter: filterType"
      [typeaheadScrollable]="true"
      [typeaheadMinLength]="0"
      [typeaheadOptionsInScrollableView]="7"
      [typeaheadItemTemplate]="itemTemplate"
      [ngClass]="{ 'x-is-invalid': validationErrors?.hasMatterError }"
      typeaheadOptionField="fullName"
      class="form-control"
      formControlName="name"
      (typeaheadOnSelect)="selectCard($event)"
    />
    <div class="x-input-group-append">
      <button
        class="btn x-btn-default x-btn-icon-only"
        (click)="openCardSelector('name')"
        [disabled]="nameSelectBtnDisabled"
      >
        <sc-icon class="x-icon-sm" iconName="browse"></sc-icon>
      </button>
    </div>
  </div>
  <label>{{ 'Address.Heading' | translate }}</label>
  <div class="x-textarea-with-button">
    <sc-textarea
      [inputClass]="{ 'x-is-invalid': validationErrors }"
      [rowNumber]="5"
      [focus]="false"
      formControlName="address"
      [delay]="0"
    >
    </sc-textarea>
    <div class="x-button-top-right" *ngIf="!selectDisabled">
      <sc-btn
        [tabindex]="-1"
        [btnSize]="'sm'"
        [btnType]="'default'"
        tooltip="{{ buttonLabel || 'Card.Select' | translate }}"
        container="body"
        data-placement="right"
        (btnClicked)="openCardSelector('address')"
      >
        <sc-icon iconName="browse"></sc-icon>
      </sc-btn>
    </div>
  </div>
</div>

<!--
  needs .dropdown-item to be overridden with white-space: nowrap !important;
  to avoid issues with long names.
-->
<ng-template #itemTemplate let-item="item">
  {{ cardLabel(item) }}
</ng-template>

<ng-template #readOnlyDisplay>
  <div [formGroup]="payToForm">
    <label>{{ 'PaymentDetails.Label.PayTo' | translate }}</label>
    <div class="x-input-group" style="margin-bottom: 1rem;">
      <input class="form-control" formControlName="name" />
    </div>
    <label>{{ 'Address.Heading' | translate }}</label>
    <sc-textarea [rowNumber]="5" formControlName="address"> </sc-textarea>
  </div>
</ng-template>
