import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { createRange } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-precedent-pagination',
  templateUrl: './precedent-pagination.component.html',
})
export class PrecedentPaginationComponent implements OnInit {
  @Input()
  set totalPages(data: number) {
    this._totalPages = data;
  }
  get totalPages() {
    return this._totalPages;
  }
  @Input()
  set currentPage(data: number) {
    this._currentPage = data;
  }
  get currentPage() {
    return this._currentPage;
  }

  @Output() onPageChanged = new EventEmitter<number>();

  private _totalPages: number;
  private _currentPage: number;

  get isFirstPage(): boolean {
    return this.currentPage === 1;
  }
  get isLastPage(): boolean {
    return this.currentPage === this.totalPages;
  }
  get pageList(): string[] {
    return this.buildPagination(this.totalPages, this.currentPage);
  }

  constructor() {}

  ngOnInit() {}

  isCurrentPage(page: string): boolean {
    return page === this.currentPage.toString();
  }

  isRangingDot(page: string): boolean {
    return page ? page.indexOf('...') !== -1 : false;
  }

  goToPage(page: string): void {
    try {
      const pageNum = parseInt(page, 10);
      if (pageNum > 0) {
        this.onPageChanged.emit(pageNum);
      }
    } catch (err) {
      // TODO: handle error
    }
  }

  prevPage(): void {
    const prevPage = +this.currentPage - 1;
    this.goToPage(prevPage.toString());
  }

  nextPage(): void {
    const nextPage = +this.currentPage + 1;
    this.goToPage(nextPage.toString());
  }

  private buildPagination(totalPage: number, currentPage: number): string[] {
    if (!totalPage || !currentPage) {
      return [];
    }

    const buildStringRange = (start: number, end: number) => createRange(start, end).map((num) => num.toString());
    let list: string[] = [];

    if (totalPage <= 9) {
      list = buildStringRange(1, totalPage + 1);
    } else if (currentPage < 6) {
      list = buildStringRange(1, 8);
      list = [...list, '...more', totalPage.toString()];
    } else if (currentPage > totalPage - 5) {
      list = buildStringRange(totalPage - 6, totalPage + 1);
      list.unshift('1', '...less');
    } else {
      list = ['1', '...less', ...buildStringRange(currentPage - 2, currentPage + 3), '...more', totalPage.toString()];
    }

    return list;
  }
}
