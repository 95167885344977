import { InvoiceApprovalStatus } from '@app/features/+invoice/models';
import { BillingMode } from '@app/features/accounting/models';

export interface CreateFeeEntryFormData {
  taskCodeId?: string;
  taxCodeId?: string;
  quantity?: number;
  amountEach?: number;
  includeTax?: boolean;
  transactionDate?: string;
  billingDescription?: string;
  billingMode?: BillingMode;
  memo?: string;
  staffId?: string;
}

export interface CreateTimeEntryFormData {
  taskCodeId?: string;
  taxCodeId?: string;
  feeUnits?: number;
  secondsPerUnit?: number;
  ratePerHour?: number;
  includeTax?: boolean;
  transactionDate?: string;
  billingRateId?: number;
  billingDescription?: string;
  memo?: string;
  staffId?: string;
  billingMode?: BillingMode;
}

export interface OfficePaymentItem {
  matterId?: string;
  description?: string;
  amountIncTax?: number;
  taxCodeId?: string;
}

export interface PaymentTypeDetails {
  bsb?: string;
  accountNumber?: string;
  accountName?: string;
  payableTo?: string;
  memo?: string;
}

export interface CreateOfficePaymentFormData {
  bankAccountId?: string;
  paymentTypeDetails?: PaymentTypeDetails;
  paymentNumber?: string;
  autoNumber?: boolean;
  payToName?: string;
  payToAddress?: string;
  transactionDate?: string;
  officePaymentItems?: OfficePaymentItem[];
  paymentTypeId?: string;
  paymentSent?: boolean;
}

export interface CreateCostRecoveryFormData {
  taskCodeId?: string;
  taxCodeId?: string;
  quantity?: number;
  amountEach?: number;
  includeTax?: boolean;
  transactionDate?: string;
  billingDescription?: string;
  billingMode?: BillingMode;
}

export interface CreateInvoiceFormData {
  invoiceTo?: string;
  invoiceNumber?: string;
  autoNumber?: boolean;
  dueDate?: string;
  memo?: string;
  transactionDate?: string;
  status?: InvoiceApprovalStatus;
  layoutId?: string;
}

export interface TrustPaymentTypeDetails extends PaymentTypeDetails {
  drawer?: string;
  chequeNumber?: string;
  authorisationNumber?: string;
  expiryDate?: string;
  billerCode?: string;
  billerReference?: string;
}

export interface TrustReceiptItem {
  matterId?: string;
  reason?: string;
  amount?: number;
}

export interface CreateTrustReceiptFormData {
  bankAccountId?: string;
  receiptNumber?: string;
  autoNumber?: boolean;
  receivedFrom?: string;
  transactionDate?: string;
  paymentTypeId?: string;
  paymentTypeDetails?: TrustPaymentTypeDetails;
  trustReceiptItems?: TrustReceiptItem[];
}

export type TrustPaymentItem = TrustReceiptItem;

export interface CreateTrustPaymentFormData {
  bankAccountId?: string;
  paymentTypeDetails?: PaymentTypeDetails;
  paymentNumber?: string;
  autoNumber?: boolean;
  payToName?: string;
  payToAddress?: string;
  transactionDate?: string;
  trustPaymentItems?: TrustPaymentItem[];
  paymentTypeId?: string;
  paymentSent?: boolean;
}

export interface TrustJournalItem {
  matterId?: string;
  reason?: string;
  amountWithdrawal?: number;
  amountDeposit?: number;
}

export interface CreateTrustJournalFormData {
  bankAccountId?: string;
  staffId?: string;
  transactionDate?: string;
  trustJournalItems?: TrustJournalItem[];
}

export enum JournalType {
  MatterToMatters = 0,
  WriteOff = 1,
}

export interface DisbursementJournalItem {
  matterId?: string;
  reason?: string;
  amountIncTax?: number; // negavtive = credit; positive = debit;
  taxCodeId?: string;
}

export interface CreateOfficeJournalFormData {
  transactionDate?: string;
  journalType?: JournalType;
  disbursementJournalItems?: DisbursementJournalItem[];
}

export interface OfficePaymentTypeDetails extends PaymentTypeDetails {
  drawer?: string;
  chequeNumber?: string;
  authorisationNumber?: string;
  expiryDate?: string;
  billerCode?: string;
  billerReference?: string;
}

export interface OfficeReceiptItem {
  invoiceId?: string;
  splitCardId?: string;
  applyAmount?: number;
}

export interface CreateOfficeReceiptFormData {
  bankAccountId?: string;
  receiptNumber?: string;
  autoNumber?: boolean;
  mailingAddress?: string;
  transactionDate?: string;
  paymentTypeId?: string;
  memo?: string;
  paymentTypeDetails?: OfficePaymentTypeDetails;
  officeReceiptItems?: OfficeReceiptItem[];
}

export interface TrustToOfficePaymentTypeDetails extends PaymentTypeDetails {
  drawer?: string;
  chequeNumber?: string;
  authorisationNumber?: string;
  expiryDate?: string;
  billerCode?: string;
  billerReference?: string;
  Notes?: number;
  Coins?: number;
}

export interface TrustToOfficeInvoiceItem {
  invoiceId?: string;
  amountApplied?: number;
}

export interface CreateTrustToOfficeFormData {
  trustAccountId?: string;
  officeAccountId?: string;
  payToName?: string;
  payToAddress?: string;
  paymentTypeDetails?: TrustToOfficePaymentTypeDetails;
  reason?: string;
  paymentNumber?: string;
  autoPaymentNumber?: boolean;
  receiptNumber?: string;
  autoReceiptNumber?: boolean;
  transactionDate?: string;
  paymentTypeId?: string;
}

export enum LeapField {
  BankAccountGUID = 'BankAccountGUID',
  PaymentNumber = 'PaymentNumber',
  AutoNumber = 'AutoNumber',
  PayToName = 'PayToName',
  PayToAddressee = 'PayToAddressee',
  TransactionDate = 'TransactionDate',
  BSB = 'BSB',
  AccountNumber = 'AccountNumber',
  AccountName = 'AccountName',
  Addressee = 'Addressee',
  Memo = 'Memo',
  PaymentTypeGUID = 'PaymentTypeGUID',
  PaymentSent = 'PaymentSent',
  PaymentProcessed = 'PaymentProcessed',
  MatterGUID = 'MatterGUID',
  BillingDescription = 'BillingDescription',
  AmountIncTax = 'AmountIncTax',
  TaxCodeGUID = 'TaxCodeGUID',
  DateDue = 'DateDue',
  Status = 'Status',
  InvoiceLayoutGUID = 'InvoiceLayoutGUID',
  BillToAddressee = 'BillToAddressee',
  TransactionNumber = 'TransactionNumber',
  ReceivedFrom = 'ReceivedFrom',
  TransactionNumberAuto = 'TransactionNumberAuto',
  ChequeNumber = 'ChequeNumber',
  AuthorisationNumber = 'AuthorisationNumber',
  ExpiryDate = 'ExpiryDate',
  BillerCode = 'BillerCode',
  BillerReference = 'BillerReference',
  Drawer = 'Drawer',
  Reason = 'Reason',
  Amount = 'Amount',
  AuthorisedByGUID = 'AuthorisedByGUID',
  AmountWithdrawal = 'AmountWithdrawal',
  AmountDeposit = 'AmountDeposit',
  Description = 'Description',
  WriteOffMode = 'WriteOffMode',
  MailTo = 'MailTo',
  InvoiceGUID = 'InvoiceGUID',
  SplitCardGUID = 'SplitCardGUID',
  TrustAccountGUID = 'TrustAccountGUID',
  OfficeAccountGUID = 'OfficeAccountGUID',
  PayToAddress = 'PayToAddress',
  PaymentNumberAuto = 'PaymentNumberAuto',
  Notes = 'Notes',
  Coins = 'Coins',
  RateId = 'RateId',
}
