import { Component, EventEmitter, forwardRef, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { xAnimationPulseWithParams } from '@app/shared/animations/animations';
import { joinArrayToString } from '../../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-input',
  templateUrl: './input.component.html',
  animations: [xAnimationPulseWithParams],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputComponent), multi: true }],
})
export class InputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  inputLabel: string;
  @Input()
  inputId = `input-${uuidv4()}`;
  @Input()
  placeholder = '';
  @Input()
  isDisabled: boolean;
  @Input()
  readonly: boolean;
  @Input()
  delay = 200;
  @Input()
  hasError: boolean;
  @Input()
  errorMessage: string;
  @Input()
  inputStyle: any;
  @Input()
  autocompleteOn: boolean;
  @Input()
  inputMin: number;

  @Input()
  get inputClass() {
    return this._inputClass;
  }

  set inputClass(data: string) {
    this._inputClass = data;
  }

  @Input()
  inputAnimationPulseState: string;

  @Input()
  set autofocus(data: boolean) {
    this._autofocus = data;
  }

  get autofocus() {
    return this._autofocus === undefined ? false : this._autofocus;
  }

  @Input()
  set inputType(data: string) {
    this._inputType = data;
  }

  get inputType() {
    return this._inputType || 'text';
  }

  @Input()
  set wrapperClass(data: string) {
    this._wrapperClass = data;
  }

  get wrapperClass() {
    return this._wrapperClass;
  }

  @Input()
  set inputValue(data: any) {
    if (!this._value) {
      // Default value
      this._value = data;
    }
  }

  @Output()
  onBlur = new EventEmitter<any>();
  @Output()
  onKeydown = new EventEmitter<KeyboardEvent>();

  inputValueStreamSub: Subscription;

  private _autofocus: boolean;
  private _inputType: string;
  private _inputClass = '';
  private _wrapperClass = '';
  private _value: any = '';

  private inputValueStream = new Subject<string>();
  private inputValueStream$ = this.inputValueStream.asObservable();
  private unsub = new Subject<void>();

  constructor() {}

  @HostBinding('class')
  classes = '';

  ngOnInit() {
    const defaultClasses: string = this.inputLabel ? 'form-group col' : '';
    this.classes = joinArrayToString([defaultClasses, this.wrapperClass], ' ');

    this.inputValueStreamSub = this.inputValueStream$
      .pipe(debounceTime(this.delay), distinctUntilChanged(), takeUntil(this.unsub))
      .subscribe(() => {
        this.onChange(this._value);
      });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  get value(): any {
    return this._value;
  }

  writeValue(value: any) {
    this._value = value;
  }

  onChange = (_) => {};
  onTouched = () => {};

  registerOnChange(fn: (value?: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  blur(): void {
    this.onBlur.emit(this.value);
  }

  keydown(event: KeyboardEvent): void {
    this.onKeydown.emit(event);
  }

  handleKeyupOnTextInput(val): void {
    this._value = val;
    this.inputValueStream.next(val);
  }
}
