import { Action } from '@ngrx/store';

export const SUPPORT_REQUEST_INIT = '[Support Request] init';
export const SUPPORT_REQUEST_SUCCESS_NOTIFICATION = '[Support Request] success notification';

export class SupportRequestInit implements Action {
  readonly type = SUPPORT_REQUEST_INIT;
  constructor(public payload: any) {}
}

export class SupportRequestSuccessNotification implements Action {
  readonly type = SUPPORT_REQUEST_SUCCESS_NOTIFICATION;
  constructor(public payload: any) {}
}

export type SupportRequestActions = SupportRequestInit | SupportRequestSuccessNotification;
