import { Injectable } from '@angular/core';
import { BaseService } from 'app/shared/services/base/base.service';
import { Observable, of } from 'rxjs';
import {
  AddSpecialRatesRequestEntry,
  BulkUpdateRequest,
  CreateInitDTO,
  CreateRequest,
  SpecialFeeListEntryDTO,
  SpecialRateDTO,
  UpdateInitDTO,
  UpdateRequest,
} from 'app/features/+time-fee-ledger/models/index';
import { HttpClient } from '@angular/common/http';
import { AccountingUpdateResponse } from 'app/features/accounting/models/index';
import { AccountingApiService } from '@app/features/accounting/services/accounting-api/accounting-api.service';
import * as accApiActions from '@app/features/accounting/store/actions';
import { Store } from '@ngrx/store';
import { State } from '@app/features/+matter-list/store';

@Injectable()
export class SpecialFeeHttpService extends BaseService {
  constructor(
    private _http: HttpClient,
    private _accountingApiSvc: AccountingApiService,
    private _matterStore: Store<State>
  ) {
    super();
  }

  private url(...parts: string[]): string {
    return this.urlJoin(this.accountingPath, '/api/cloud', ...parts);
  }

  list(deleted: boolean): Observable<SpecialFeeListEntryDTO[]> {
    const url = this.url(`specialfees?deleted=${deleted}`);
    return this._http.get<SpecialFeeListEntryDTO[]>(url);
  }

  init(): Observable<CreateInitDTO> {
    const url = this.url('specialfee/initialisationdata');
    return this._http.get<CreateInitDTO>(url);
  }

  get(id: string): Observable<UpdateInitDTO> {
    const url = this.url(`specialfee/${id}/initialisationdata`);
    return this._http.get<UpdateInitDTO>(url);
  }

  save(request: CreateRequest) {
    this._matterStore.dispatch(
      new accApiActions.AccountingSave({
        model: request,
        url: 'api/cloud/specialfee',
        operation: 'post',
      })
    );
  }

  updateBulk(request: BulkUpdateRequest) {
    this._matterStore.dispatch(
      new accApiActions.AccountingSave({
        model: request,
        url: 'api/cloud/specialfee',
        operation: 'put',
      })
    );
  }

  bulkDelete(request: BulkUpdateRequest) {
    this._matterStore.dispatch(
      new accApiActions.AccountingSave({
        model: request,
        url: 'api/cloud/specialfee',
        operation: 'put',
      })
    );
  }

  updateSingle(request: UpdateRequest) {
    this._matterStore.dispatch(
      new accApiActions.AccountingSave({
        model: request,
        url: `api/cloud/specialfee/${request.SpecialFeeGUID}`,
        operation: 'put',
      })
    );
  }

  setSpecialRatesForMatter(matterId: string, specialFeeIds: string[]): Observable<AccountingUpdateResponse> {
    const url: string = this.url(`specialrates/AddSpecialRates?matterGUID=${matterId}`);
    const request = specialFeeIds?.map((sid) => ({ SpecialFeeGUID: sid } as AddSpecialRatesRequestEntry)) || [];
    return this._http.post<AccountingUpdateResponse>(url, request);
  }

  setSpecialRatesForCard(cardId: string, specialFeeIds: string[]): Observable<AccountingUpdateResponse> {
    const url: string = this.url(`specialrates/AddSpecialRates?cardGUID=${cardId}`);
    const request = specialFeeIds?.map((sid) => ({ SpecialFeeGUID: sid } as AddSpecialRatesRequestEntry)) || [];
    return this._http.post<AccountingUpdateResponse>(url, request);
  }

  getSpecialRatesForMatter(matterId: string): Observable<SpecialRateDTO[]> {
    const url: string = this.url(`specialrates/GetSpecialRates?matterGUID=${matterId}`);
    if (matterId === '') {
      return of([]);
    }
    return this._http.get<SpecialRateDTO[]>(url);
  }

  getSpecialRatesForCard(cardId: string): Observable<SpecialRateDTO[]> {
    const url: string = this.url(`specialrates/GetSpecialRates?cardGUID=${cardId}`);
    if (cardId === '') {
      return of([]);
    }
    return this._http.get<SpecialRateDTO[]>(url);
  }

  setMatterSpecialRateLevel(matterId: string, level: number): Observable<AccountingUpdateResponse> {
    const url: string = this.url(`specialrates/SetMatterSpecialFeeLevel/${matterId}?level=${level}`);
    return this._http.post<AccountingUpdateResponse>(url, {});
  }

  getMatterSpecialRateLevel(matterId: string): Observable<number> {
    const url: string = this.url(`specialrates/GetMatterSpecialFeeLevel/${matterId}`);
    // TODO return this._http.get<number>(url).then(response => response.data);
    return this._http.get<number>(url);
  }
}
