import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ITaskCode, ITaskCodeUiConfig } from '@app/shared/models';
import { select, Store } from '@ngrx/store';
import { AppApiService } from '@app/core/api';

import { Subject, Subscription } from 'rxjs';
import { filter as rxjsFilter, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { selectTaskCodeSelectorResponse } from '@app/features/+task-code-list-selector/store';
import * as taskSelectorActions from '@app/features/+task-code-list-selector/store/actions';
import { NgSelectComponent } from '@ng-select/ng-select';
import { getObjValue, isString } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-task-code-select',
  templateUrl: './task-code-select.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TaskCodeSelectComponent), multi: true }],
})
export class TaskCodeSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('select', { static: true }) selectComponent: NgSelectComponent;

  @Input()
  uiConfig: ITaskCodeUiConfig;

  @Input()
  bindValue: string;

  @Input()
  bindLabel: string;

  @Input()
  isDisabled: boolean;

  @Input()
  items: ITaskCode[];

  @Input()
  useSelectorDetailOutlet = false;

  @Input()
  wrapperClass: string;

  @Output()
  taskCodeSelected = new EventEmitter<any>();

  private _value: any = '';
  private _taskCodeModalSub: Subscription;
  private unsub = new Subject<void>();

  onChange = (_) => {};
  onTouched = () => {};

  constructor(
    private _appApiSvc: AppApiService,
    private _store: Store<any>,
    private _renderer: Renderer2
  ) {}

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
    this._renderer.destroy();
  }

  ngOnInit() {}

  writeValue(value: any): void {
    if (isString(value)) {
      this._value = value;
    } else {
      this._value = getObjValue(value, this.bindValue);
    }

    this.removeValueParentClass();
  }

  registerOnChange(fn: (value?: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onTaskCodeSelected(event: ITaskCode) {
    this.selectComponent.searchInput.nativeElement.blur();
    this.taskCodeSelected.emit(event);
  }

  openTaskCodeSelector() {
    if (!!this._taskCodeModalSub) {
      this._taskCodeModalSub.unsubscribe();
    }

    this._store.dispatch(new taskSelectorActions.TaskCodeSelectorAddTaskCodes(this.items));
    this._store.dispatch(new taskSelectorActions.TaskCodeSelectorSetUiConfig(this.uiConfig));

    this._taskCodeModalSub = this._store
      .pipe(
        take(1),
        tap(() => {
          let path: any = [{ outlets: { selector: ['task-code-list'] } }];

          if (this.useSelectorDetailOutlet) {
            path = [{ outlets: { selectorDetail: ['task-code-list'] } }];
          }

          this._appApiSvc.navigate({
            path,
            extras: { queryParamsHandling: 'merge' },
          });
        }),
        mergeMap(() => this._store.pipe(
          select(selectTaskCodeSelectorResponse),
          rxjsFilter((response) => response !== null),
          take(1),
          tap((response: ITaskCode) => {
            if (!!response) {
              this.onTaskCodeSelected(response);
            }
          })
        )),
        takeUntil(this.unsub) // important: this operator must be last
      )
      .subscribe();
  }

  taskCodeLabel(item: ITaskCode) {
    return item.NameFileAs;
  }

  focusIn() {
    this.selectComponent.focus();
  }

  private removeValueParentClass() {
    setTimeout(() => {
      const xValueNode = document.querySelector('.x-value');
      if (xValueNode) {
        const parentNode = this._renderer.parentNode(xValueNode);
        if (parentNode?.classList.contains('ng-value')) {
          this._renderer.removeClass(parentNode, 'ng-value');
        }
      }
    }, 0);
  }
}
