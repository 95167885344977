import { ISubstitution } from '@app/features/+matter-list/models';
import {
  ICard,
  IMatterDetailRelationship,
  IMatterCard,
  IMatterDetailEntry,
  MatterDetailsQueryType,
  IMatterDetailsInfo,
} from '@app/shared/models';

export interface IMatterDetailsApiResponse {
  detailEntries: IMatterDetailEntry[];
  detailInfo: IMatterDetailsInfo;
  relations: IMatterDetailRelationship[];
}

export interface IMatterDetailsGetParams {
  matterId: string;
  queryType?: MatterDetailsQueryType;
  withContext?: boolean;
  fetchMode?: string;
  disableRelationships?: boolean;
}

export interface IMatterCardSaveParams {
  matterCard: IMatterCard;
  matterDetails?: IMatterDetailEntry[];
  card?: ICard;
  isNew?: boolean;
}

export interface IMatterCardDeleteParams {
  matterId: string;
  matterCardId: string;
  displayOrder: number;
}

export interface IMatterTypeSaveParams {
  state: string;
  matterTypeId: string;
}

export interface ICorrespondenceGetParams {
  includeDeleted?: boolean;
  fetchMode?: string;
}

export interface IMatterCardsInfoApiResponse {
  matterCards: IMatterCard[];
  matterDetails?: {
    [key: string]: string;
  };
  error?: any;
}

// Matter api errors
export enum MatterDetailsError {
  Fetch = 'fetch',
  Remove = 'remove',
  Reorder = 'reorder',
  SetDebtor = 'setDebtor',
  Update = 'update',
}

export interface IMatterDetailsApiError {
  source: MatterDetailsError;
  error?: any;
  onError?: () => void;
}

export interface IMatterBulkCardDeleteParams {
  displayOrder: number;
  matterCardId: string;
}

export interface UpdatedMatterCorePayload {
  matterId: string;
  matterDetails: IMatterDetailsApiResponse;
  parties: ISubstitution;
}
