<a href tabindex="-1" class="list-group-item" (click)="onClicked()" scPreventDefault>
  <div class="x-icon-container">
    <div class="x-icon d-flex align-items-center">
      <img style="height: 100%;" [src]="addinButton.Icon" />
    </div>
  </div>
  <span>{{ addinButton.Label }}</span>
  <span (click)="toggleContextMenu(myContextMenu, $event)">
    <sc-icon iconName="more-vert-grid-20" *ngIf="addinButton.DropdownMenu.length > 0"></sc-icon>
  </span>
</a>
<context-menu #myContextMenu [disabled]="!(contextMenuActions?.length > 0)">
  <ng-template
    *ngFor="let action of contextMenuActions"
    contextMenuItem
    let-item
    [visible]="action.visible"
    [disabled]="!action.enabled"
    [divider]="action.divider"
    (execute)="action.click($event.item)"
  >
    {{ action.html(item) }}
  </ng-template>
</context-menu>
