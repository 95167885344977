import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sc-list-slim-item',
  templateUrl: './list-slim-item.component.html',
})
export class ListSlimItemComponent implements OnInit {
  @Input() alsId: string;
  @Input() alsLabel: string;
  @Input() labelParams: object = {};
  @Input() alsClass: string;
  @Input() alsIcon: string;
  @Input() alsStyle: any;
  @Input() alsSkipTranslation = false;
  @Output() itemClicked = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  clicked(event: any) {
    this.itemClicked.emit(event);
  }
}
