import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from '@app/shared/shared.module';
import { LiveChatEffect } from '@app/features/live-chat/store/effects';
import { LiveChatService } from '@app/features/live-chat/services';

@NgModule({
  imports: [CommonModule, SharedModule, EffectsModule.forFeature([LiveChatEffect])],
  providers: [LiveChatService],
})
export class LiveChatModule {}
