import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  CreateEmailSignatureOptions,
  EmailSignature,
  EmailSignatureDTO,
  EmptyEmailSignature,
  UpdateEmailSignatureRequest,
} from '../../models/index';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BaseService } from 'app/shared/services';
import { catchError, tap } from 'rxjs/operators';
import { LogService } from '@app/core/services';
import { handleHttpError } from '@app/shared/functions';
import { isBoolean } from '@server/modules/shared/functions/common-util.functions';

@Injectable()
export class EmailSignatureService extends BaseService {
  private readonly url_: string;

  constructor(private _http: HttpClient, private _log: LogService) {
    super();
    this._log.init('email-signature-service');
    this.url_ = `${this.calendarPath}/emailsignatures`;
  }

  createEmailSignatureModel(options: CreateEmailSignatureOptions = {}): EmailSignature {
    return {
      content: options.content || '',
      includeInForwardReply: isBoolean(options.includeInForwardReply) ? options.includeInForwardReply : false,
      includeInNew: isBoolean(options.includeInNew) ? options.includeInNew : false,
    };
  }

  getEmailSignature(): Observable<EmailSignature> {
    return this._http.get<EmailSignatureDTO>(this.url_).pipe(
      tap((data) => this._log.info('server data:', data)),
      catchError((error) =>
        handleHttpError(error, {
          operation: 'getEmailSignature',
          url: this.url_,
          strategy: { 404: EmptyEmailSignature },
          log: this._log.info,
        })
      )
    );
  }

  updateEmailSignature(signature: EmailSignature): Observable<void> {
    const request: UpdateEmailSignatureRequest = signature;
    return this._http.post<void>(this.url_, request);
  }
}
