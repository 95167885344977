import { Component } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IConfigHeaderMenuItem } from '@app/sharedaggrid/models';
import { AnalyticsService } from '@app/core/services';
import { AnalyticsModel } from '@app/core/models/analytics.model';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';
import { IHeaderParams } from '@ag-grid-community/core';

export interface IConfigHeaderMenuGroup {
  menuItems: IConfigHeaderMenuItem[];
  selectedItem: IConfigHeaderMenuItem;
}

@Component({
  selector: 'sc-icon-config-header',
  templateUrl: './icon-config-header.component.html',
})
export class IconConfigHeaderComponent implements IHeaderAngularComp {
  menuItems: IConfigHeaderMenuItem[] = [];
  menuGroups: IConfigHeaderMenuGroup[] = [];
  selectedItems: string[] = [];
  analytics: AnalyticsModel = null;

  agInit(params: any): void {
    if (params['menuItems']) {
      const items = isFunction(params['menuItems']) ? params.menuItems() : params['menuItems'];
      items.map((i: IConfigHeaderMenuItem | IConfigHeaderMenuItem[]) =>
        Array.isArray(i)
          ? this.menuGroups.push({ menuItems: i } as IConfigHeaderMenuGroup)
          : this.menuItems.push(i as IConfigHeaderMenuItem)
      );
    }
    if (params['selectedItems']) {
      this.selectedItems = isFunction(params['selectedItems']) ? params.selectedItems() : params['selectedItems'];
    }

    if (params['analytics']) {
      this.analytics = params['analytics'];
    }
  }

  refresh(params: IHeaderParams<any>): boolean {
    return false;
  }

  constructor(private _analyticsSvc: AnalyticsService) {}

  isActive(item: IConfigHeaderMenuItem): boolean {
    return this.selectedItems?.includes(item.name);
  }

  itemClicked(item: IConfigHeaderMenuItem): void {
    if (this.selectedItems?.includes(item.name)) {
      this.removeSelectedItem(item);
    } else {
      this.selectedItems.push(item.name);
    }
    item.callback();
  }

  groupItemClicked(item: IConfigHeaderMenuItem, group: IConfigHeaderMenuGroup): void {
    group.selectedItem = item;
    group.menuItems
      .filter((x) => x.name !== item.name)
      .forEach((mItem: IConfigHeaderMenuItem) => this.removeSelectedItem(mItem));
    this.selectedItems.push(item.name);

    item.callback();
  }

  sendAnalytics() {
    if (!!this.analytics) {
      this._analyticsSvc.eventTrack(this.analytics);
    }
  }

  private removeSelectedItem(item: IConfigHeaderMenuItem) {
    this.selectedItems = this.selectedItems?.filter((si) => si !== item.name) || [];
  }
}
