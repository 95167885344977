import { Action } from '@ngrx/store';
import { ITimePopoutParams } from '@app/features/+time-fee-ledger/models';

export const INIT_TIME_FEE_FORM = '[Time] init time fee form';
export const INIT_TIME_FEE_FORM_SUCCESS = '[Time] init time fee form success';
export const INIT_TIME_FEE_FORM_FAILURE = '[Time] init time fee form failure';

// close time
export const CLOSE_TIME = '[Time] close time';

// hydrate time
export const HYDRATE_TIME_FORM = '[Time] hydrate time form';
export const HYDRATE_TIME_FORM_SUCCESS = '[Time] hydrate time form success';
export const HYDRATE_TIME_FORM_FAILURE = '[Time] hydrate time form failure';

// dehydrate time
export const DEHYDRATE_TIME_FORM = '[Time] dehydrate time form';
export const DEHYDRATE_TIME_FORM_SUCCESS = '[Time] dehydrate time form success';
export const DEHYDRATE_TIME_FORM_FAILURE = '[Time] dehydrate time form failure';

export const SET_TIME_MODAL_LOADING = '[Time] Set Modal loading';

// close time
export class CloseTime implements Action {
  readonly type = CLOSE_TIME;
  constructor(public payload: any) {}
}

//  hydrate time
export class HydrateTimeForm implements Action {
  readonly type = HYDRATE_TIME_FORM;
  constructor(public payload: ITimePopoutParams) {}
}

export class HydrateTimeFormSuccess implements Action {
  readonly type = HYDRATE_TIME_FORM_SUCCESS;
  constructor(public payload: any) {}
}

export class HydrateTimeFormFailure implements Action {
  readonly type = HYDRATE_TIME_FORM_FAILURE;
  constructor(public payload: any) {}
}

//  hydrate time
export class DeHydrateTimeForm implements Action {
  readonly type = DEHYDRATE_TIME_FORM;
  constructor(public payload: any) {}
}

export class DeHydrateTimeFormSuccess implements Action {
  readonly type = DEHYDRATE_TIME_FORM_SUCCESS;
  constructor(public payload: ITimePopoutParams) {}
}

export class DeHydrateTimeFormFailure implements Action {
  readonly type = DEHYDRATE_TIME_FORM_FAILURE;
  constructor(public payload: any) {}
}

export class InitTimeFeeForm implements Action {
  readonly type = INIT_TIME_FEE_FORM;
  constructor(public payload: { isOpenInNewWin: boolean; matterId: string; feeGUID?: string }) {}
}

export class InitTimeFeeFormSuccess implements Action {
  readonly type = INIT_TIME_FEE_FORM_SUCCESS;
  constructor(public payload: any) {}
}

export class InitTimeFeeFormFailure implements Action {
  readonly type = INIT_TIME_FEE_FORM_FAILURE;
  constructor(public payload: any) {}
}

export class SetTimeModalLoading implements Action {
  readonly type = SET_TIME_MODAL_LOADING;
  constructor(public payload: boolean) {}
}

export type TimeModalActions =
  | CloseTime
  | HydrateTimeForm
  | HydrateTimeFormSuccess
  | HydrateTimeFormFailure
  | DeHydrateTimeForm
  | DeHydrateTimeFormSuccess
  | DeHydrateTimeFormFailure
  | InitTimeFeeForm
  | InitTimeFeeFormSuccess
  | InitTimeFeeFormFailure
  | SetTimeModalLoading;
