import { Action } from '@ngrx/store';

// close fee
export const CLOSE_FEE = '[Fee] close fee';

// hydrate fee
export const HYDRATE_FEE_FORM = '[Fee] hydrate fee form';
export const HYDRATE_FEE_FORM_SUCCESS = '[Fee] hydrate fee form success';
export const HYDRATE_FEE_FORM_FAILURE = '[Fee] hydrate fee form failure';

// dehydrate fee
export const DEHYDRATE_FEE_FORM = '[Fee] dehydrate fee form';
export const DEHYDRATE_FEE_FORM_SUCCESS = '[Fee] dehydrate fee form success';
export const DEHYDRATE_FEE_FORM_FAILURE = '[Fee] dehydrate fee form failure';

export const SET_FEE_MODAL_LOADING = '[Fee] Set Modal Loading';

// close fee
export class CloseFee implements Action {
  readonly type = CLOSE_FEE;
  constructor(public payload: any) {}
}

//  hydrate fee
export class HydrateFeeForm implements Action {
  readonly type = HYDRATE_FEE_FORM;
  constructor(public payload: any) {}
}

export class HydrateFeeFormSuccess implements Action {
  readonly type = HYDRATE_FEE_FORM_SUCCESS;
  constructor(public payload: any) {}
}

export class HydrateFeeFormFailure implements Action {
  readonly type = HYDRATE_FEE_FORM_FAILURE;
  constructor(public payload: any) {}
}

//  hydrate fee
export class DeHydrateFeeForm implements Action {
  readonly type = DEHYDRATE_FEE_FORM;
  constructor(public payload: any) {}
}

export class DeHydrateFeeFormSuccess implements Action {
  readonly type = DEHYDRATE_FEE_FORM_SUCCESS;
  constructor(public payload: any) {}
}

export class DeHydrateFeeFormFailure implements Action {
  readonly type = DEHYDRATE_FEE_FORM_FAILURE;
  constructor(public payload: any) {}
}

export class SetFeeModalLoading implements Action {
  readonly type = SET_FEE_MODAL_LOADING;
  constructor(public payload: boolean){}
}

export type FeeModalActions =
  | CloseFee
  | HydrateFeeForm
  | HydrateFeeFormSuccess
  | HydrateFeeFormFailure
  | DeHydrateFeeForm
  | DeHydrateFeeFormSuccess
  | DeHydrateFeeFormFailure
  | SetFeeModalLoading;
