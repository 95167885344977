import { Injectable } from '@angular/core';
import { SpecialFeeService } from '@app/shared/services/special-fee/special-fee.service';
import { ITimeFee, SpecialFeePrefix, SpecialRate } from '../../models';
import { IRate, ITaskCode } from '@app/shared/models';
import { TimeFeeService } from '@app/features/+time-fee-ledger/services/timeFee/timeFee.service';
import { isEmptyArray, isEmptyObj } from '@server/modules/shared/functions/common-util.functions';

@Injectable()
export class BillingRateService {
  constructor(private _specialFeeSvc: SpecialFeeService, private _timeFeeSvc: TimeFeeService) {}

  populateBillingRates(fee: ITimeFee, specialRates: SpecialRate[], taskCodeGUID: string): IRate[] {
    let billingRates = [];
    if (!isEmptyObj(fee.WorkDoneByStaff)) {
      billingRates = fee.WorkDoneByStaff.rates;
    } else {
      billingRates = this._timeFeeSvc.defaultBillingRates();
    }
    if (!billingRates) {
      billingRates = [];
    }

    const specialRate = this._specialFeeSvc.selectPreferredSpecialRate(
      taskCodeGUID,
      fee.WorkDoneByStaffGUID,
      specialRates
    );

    if (specialRate && specialRate !== undefined) {
      let rate: IRate;
      if (specialRate.RateID !== 0) {
        rate = billingRates?.find(
          (r: IRate): IRate => {
            if (r.id === specialRate.RateID && r.display.indexOf(SpecialFeePrefix) < 0) {
              r.display += ` ${SpecialFeePrefix} (${specialRate.Name})`;
              return r;
            }
          }
        );

        if (!rate) {
          throw new RangeError('No matching staff rate corresponding to the special rate ' + specialRate.Name);
        }
      } else {
        rate = {
          id: -2,
          display: `${SpecialFeePrefix} (${specialRate.Name})`,
          name: specialRate.Name,
          rate: specialRate.Rate,
        };

        billingRates = billingRates.filter((r: IRate) => !(r.id === 0 && r.name !== 'Other'));
        billingRates.push(rate);
      }
    }
    return billingRates;
  }

  selectBillingRate(fee: ITimeFee, billingRates: IRate[], useDefaultRate: boolean = true): IRate {
    let billingRate: IRate;

    if (isEmptyArray(billingRates)) {
      return billingRate;
    }

    if (!useDefaultRate) {
      // an existing time entry
      // first try to find a matching rate or special fee
      billingRate = billingRates?.find(
        (rate: IRate) => rate.id === 0 && rate.rate === fee.RatePerHour && rate.name !== 'Other'
      );

      // default case: when nothing matches....
      if (!billingRate) {
        // defensive check, fall back to Other rate
        billingRate = billingRates?.find((rate: IRate) => rate.rate === fee.RatePerHour && rate.name !== 'Other');
      }

      // default case: when nothing matches....
      if (!billingRate) {
        // defensive check, fall back to Other rate
        billingRate = billingRates?.find((rate: IRate) => rate.name === 'Other');
      }
    } else {
      // new time entry, use first billing rate entry
      const specialRate = billingRates?.find((b) => b.display.indexOf(SpecialFeePrefix) >= 0);
      billingRate = specialRate ? specialRate : billingRates[0];
    }

    return billingRate;
  }

  getUpdatedBillingRate(billingRates: IRate[], taskCode?: ITaskCode): IRate {
    if (isEmptyArray(billingRates)) {
      return undefined;
    }

    let billingRate = billingRates?.find((b) => b.display.indexOf(SpecialFeePrefix) >= 0);

    if (!billingRate) {
      billingRate = taskCode
        ? billingRates?.find((b) => b.id === taskCode.RateID)
        : billingRates?.find((b) => b.id === 0 && b.name === 'Other');
    }

    return billingRate;
  }

  onRatePerHourChanged(billingRates: IRate[], ratePerHour: number) {
    if (isEmptyArray(billingRates)) {
      return undefined;
    }

    let billingRate = billingRates?.find((b: IRate): boolean => b.rate === ratePerHour);

    if (!billingRate) {
      billingRate = billingRates?.find((b: IRate): boolean => b.name === 'Other');
      billingRate.rate = ratePerHour;
    }

    return billingRate;
  }
}
