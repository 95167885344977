import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'sc-col',
  templateUrl: './col.component.html',
})
export class ColComponent implements OnInit {
  constructor() {}

  @HostBinding('class.col')
  ngOnInit() {}
}
