import {
  catchError,
  concatMap,
  delayWhen,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { defer, forkJoin, from, Observable, of, throwError } from 'rxjs';
import { AuthAgent } from '@leapdev/auth-agent';
import * as appActions from '../actions/app.action';
import * as tableMappingActions from '../actions/tablemapping.action';
import * as browserTitleActions from '@app/core/store/actions/browser-title.action';
import { IGlobalUIPreference, UserPreferenceTypes } from '../../models';
import { selectCurrentMatterId, selectUIPreference, selectUserPreferences } from '../selectors';
import { AppState } from '../reducers';
import { AppStorageService } from '../../services/storage/app-storage.service';
import { TableMappingService } from '@app/shared/services/mapping/table-mapping.service';
import { LogService, PlatformService, UserPreferencesService, AuthService } from '@app/core/services';
import { CacheService } from '@app/core/services/cache/cache.service';
import { OfflineLauncherService } from '../../../shared/services/document-automation/offline-launcher/offline-launcher.service';
import { AppApiService } from '@app/core/api';
import { EmailService } from '@app/core/services/email/email.service';
import { EmailPreferences } from '@app/core/constants/preferences.constant';
import { IDocCombinePDFHttpRequestParams } from '@app/features/+create-pdf/models';
import { Attachment } from '@app/features/+email/models/attachments.model';
import { PdfDocumentService } from '@app/features/+create-pdf/services/pdf-document/pdf-document.service';
import { MatterDetailsService } from '@app/features/+matter-details/services';
import * as cardFiltersActions from '@app/core/store/actions/card-filters.actions';
import { SiriusError } from '@app/features/error-handler/interfaces/error-handler.interfaces';
import * as userProfileActions from '@app/features/+user-profile/store/actions';
import { isEmptyObj } from '../../../../../server/modules/shared/functions/common-util.functions';
import { UserInfo } from '@leapdev/auth-agent/src/lib/types';

@Injectable()
export class AppEffects {

  loadTableMappings$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(tableMappingActions.ADD_TABLE_MAPPING),
    concatMap(() =>
      this._tableMappingSvc.get().pipe(
        mergeMap((mappings) => [
          new tableMappingActions.AddTableMappingSuccess(mappings),
          new cardFiltersActions.ConfigureDefaultCardFilters(null),
        ]),
        catchError((error) => [new tableMappingActions.AddTableMappingFailure(error)])
      )
    ),
    catchError((error) => [new tableMappingActions.AddTableMappingFailure(error)])
  ));


  loadUserPreference$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<appActions.LoadUserPreferenceStart>(appActions.LOAD_USER_PREFERENCE_START),
    exhaustMap(() =>
      forkJoin([this._userPreferenceSvc.userPreferencesInit(), this._userPreferenceSvc.getUserPreferencesDefaults()])
    ),
    map(
      ([userPreferences, userPreferencesDefaults]) =>
        new appActions.LoadUserPreferenceSuccess({ userPreferences, userPreferencesDefaults })
    ),
    catchError((error) => [new appActions.LoadUserPreferenceFailure(error)])
  ));


  updateUserPreference$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<appActions.UpdateUserPreferenceStart>(appActions.UPDATE_USER_PREFERENCE_START),
    exhaustMap((action) =>
      this._userPreferenceSvc
        .updateUserPreferences(action.payload)
        .pipe(map(() => new appActions.UpdateUserPreferenceSuccess(action.payload)))
    ),
    catchError((error) => [new appActions.UpdateUserPreferenceFailure(error)])
  ));


  loadUIPreferenceFromDb$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(appActions.LOAD_UI_PREFERENCE_DB_START),
    withLatestFrom(this._store.pipe(select(selectUIPreference)), (action, uiPreference) => uiPreference),
    filter(() => this._platformSvc.isBrowser),
    switchMap((uiPreference: IGlobalUIPreference) =>
      this._appStorageSvc.get().pipe(
        mergeMap((data) => {
          const reducedAction: appActions.All = !isEmptyObj(data)
            ? new appActions.LoadUIPreferenceDbSuccess(data)
            : new appActions.SaveUIPreference(uiPreference);
          return [reducedAction];
        })
      )
    ),
    catchError((error) => of(new appActions.LoadUIPreferenceDbFailure(error)))
  ));


  saveUIPreference$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(appActions.SAVE_UI_PREFERENCE, appActions.SAVE_PREVIEW_UI_PREFERENCE),
    withLatestFrom(this._store.pipe(select(selectUIPreference)), (action, uiPreference) => uiPreference),
    filter(() => this._platformSvc.isBrowser),
    switchMap((uiPreference: IGlobalUIPreference) =>
      this._appStorageSvc
        .upsertAll(uiPreference)
        .then(() => new appActions.AppDefault(null))
        .catch(() => new appActions.AppDefault(null))
    ),
    catchError(() => of(new appActions.AppDefault(null)))
  ));


  clearCache$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(appActions.CLEAR_CACHE),
    switchMap((action: appActions.ClearCache) => {
      const clearCacheSub = this._cacheSvc.clearCache(action.payload).subscribe(() => {
        if (clearCacheSub) {
          clearCacheSub.unsubscribe();
        }
      });
      return [];
    })
  ));


  saveMatter$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(appActions.SAVE_MATTER),
    mergeMap((action: appActions.SaveMatter) => [new browserTitleActions.UpdateMatterInfo(null)])
  ));


  initialiseEmail$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<appActions.InitialiseEmail>(appActions.INITIALISE_EMAIL),
    delayWhen(() =>
      this._store.pipe(
        select(selectUserPreferences),
        filter((preferences) => !!preferences && preferences.length > 0)
      )
    ),
    withLatestFrom(this._store.pipe(select(selectUserPreferences)), this._store.pipe(select(selectCurrentMatterId))),
    exhaustMap((data) => {
      const [action, preferences, currentMatterId] = data;
      const matterId =
        !!action.payload.data && !!action.payload.data.matterNumber
          ? action.payload.data.matterNumber
          : currentMatterId;
      const emailPreference = preferences.find((u) => u.Key === UserPreferenceTypes.OpenEmail);
      switch (emailPreference.Value) {
        case EmailPreferences.Web:
          this._appApiSvc.newEmail(action.payload.data, action.payload.routerOutlet);
          break;
        case EmailPreferences.Outlook:
          if (!!action.payload.convertPdfParams) {
            return this.convertPdfAttachment(matterId, action.payload.convertPdfParams).pipe(
              mergeMap((res) => this.createEmailTicket(matterId, res))
            );
          }
          return this.createEmailTicket(matterId, action.payload.attachment);

        default:
          this._appApiSvc.newEmail(action.payload.data, action.payload.routerOutlet);
          break;
      }

      return [];
    })
  ), { dispatch: false });


  getMatterCore$ = createEffect(() => this.actions$.pipe(
    ofType<appActions.GetMatterCore>(appActions.GET_MATTER_CORE),
    switchMap((action) => {
      const matter = action.payload;
      return this._matterDetailsSvc.getMatterCore(matter.matterId).pipe(
        mergeMap((matterCore) => [new appActions.GetMatterCoreSuccess({ matterCore })]),
        catchError((error) => {
          this._store.dispatch(new appActions.GetMatterCoreFail(error));
          // re-throw error and let error-handler.service to handle this type of error
          return throwError(() =>
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: 'Unable to load matter\'s core details.',
            })
          );
        })
      );
    })
  ));


  // this init effect would be called as long as "AppEffect" got initialised
  // we shall consider to move this "AddStaffList" action to somewhere when the actual app is initialised
  init$: Observable<Action> = createEffect(() => defer(() => {
    console.log('app-effects init$');
    const token = AuthAgent.getAccessToken();
    return token? of(new appActions.AddStaffList(null)) : [];
  }));


  addUserDetails$ = createEffect(() => this.actions$.pipe(
    ofType<appActions.UpdateUserDetails>(appActions.UPDATE_USER_DETAILS),
    switchMap((action) => from(this._authSvc.userDetails()).pipe(
        mergeMap((userDetails) => {
          if (userDetails) {
            this._authSvc.forceRefreshAccessToken();

            return [
              new appActions.UpdateUserDetailsSuccess(userDetails),
              new userProfileActions.UserProfileGetAccountInformation(null),
            ];
          }

          return [];
        }),
        catchError((error) => {
          this._store.dispatch(new appActions.UpdateUserDetailsFailure(error));
          // re-throw error and let error-handler.service to handle this type of error
          return throwError(() =>
            new SiriusError({
              type: 'error',
              title: 'Failure',
              message: 'Unable to update user details.',
            })
          );
        })
      ))
  ));


  refreshMatterCache$ = createEffect(() => this.actions$.pipe(
    ofType<appActions.RefreshMatterCache>(appActions.REFRESH_MATTER_CACHE),
    withLatestFrom(this._store.pipe(select(selectCurrentMatterId))),
    mergeMap((data) => {
      const [action, matterId] = data;
      const id = !!action.payload ? action.payload : matterId;
      return id === matterId ? this._cacheSvc.refreshMatterCache(matterId) : [];
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private _appStorageSvc: AppStorageService,
    private _cacheSvc: CacheService,
    private _tableMappingSvc: TableMappingService,
    private _store: Store<AppState>,
    private _log: LogService,
    private _platformSvc: PlatformService,
    private _offlineLauncherSvc: OfflineLauncherService,
    private _appApiSvc: AppApiService,
    private _userPreferenceSvc: UserPreferencesService,
    private _emailService: EmailService,
    private _matterDetailsSvc: MatterDetailsService,
    private _pdfDocumentSvc: PdfDocumentService,
    private _authSvc: AuthService
  ) {
    this._log.init('app.effects');
  }

  private extractExtension(fileName: string): string {
    const results: string[] = fileName.match(/\.[0-9a-zA-Z]+$/);
    return results && results.length > 0 ? results[0].replace('.', '') : '';
  }

  private convertPdfAttachment(
    matterId: string,
    createPdfParams: IDocCombinePDFHttpRequestParams
  ): Observable<Attachment[]> {
    const params = { ...createPdfParams, MatterId: matterId };
    return this._pdfDocumentSvc
      .createPDFDocuments(params)
      .pipe(map((res) => this._pdfDocumentSvc.mapConvertedToAttachments(res.PdfDocuments, false)));
  }

  private createEmailTicket(matterId: string, attachment: Attachment[]): Observable<string> {
    return this._emailService.getEmailSubject(matterId, null).pipe(
      mergeMap((subject) => {
        const emailData = {
          subject,
          attachments: !!attachment
            ? [
                ...attachment.map((a) => {
                  const ext = this.extractExtension(a.name);
                  const name = a.name.replace(`.${ext}`, '');
                  return { ...a, name, ext };
                }),
              ]
            : [],
        };
        return this._offlineLauncherSvc.createNewEmailTicket({ emailData, matterId });
      })
    );
  }
}
