import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { ICardListEntry } from '@app/features/+card/models';
import { AuthService } from '@app/core/services';
import { IPerson } from '@app/shared/models';
import { CardHelper } from '@app/shared/utils/card.helper';
import { isEmptyObj, clone } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-recipient-address',
  templateUrl: './recipient-address.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecipientAddressComponent implements OnInit, OnDestroy {
  private _matterCardEntries: ICardListEntry[];
  @Input()
  set matterCardEntries(value: ICardListEntry[]) {
    if (!!value) {
      this._matterCardEntries = value;
      this.setUpAddresses();
    }
  }

  private _cardId: string;
  @Input()
  get cardId(): string {
    return this._cardId;
  }
  set cardId(value: string) {
    if (!!value) {
      this._cardId = value;
      this.selectedPerson = null;
      this.setUpAddresses();
    }
  }

  @Input() selectedPerson: IPerson;

  @Output() showAddress = new EventEmitter<boolean>();

  public selectedMatterCard: ICardListEntry;
  public address: string;
  public today: Date;
  public staffName = 'Staff Name';

  private addresses = new Map<string, string>();

  get fullName(): string {
    return this.selectedPerson ? this.getPersonFullName(this.selectedPerson) : this.selectedMatterCard.fullName;
  }

  get dear(): string {
    return this.selectedPerson
      ? this.selectedPerson.lastName
      : this.selectedMatterCard.shortName || this.selectedMatterCard.fullName;
  }

  public noResultMessage = (cardId: string) => {
    if (cardId) {
      if (cardId === 'none') {
        return {
          type: 'noRecipientByIntention',
        };
      }

      return {
        type: 'recipientAddress',
      };
    }

    return { type: 'recipientAddressNoCardId' };
  };

  constructor(private authSvc: AuthService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.today = new Date();

    this.authSvc.userDetails().then((user) => {
      if (user) {
        this.staffName = user.firstName + ' ' + user.lastName;
        this.cdr.markForCheck();
      }
    });
  }

  ngOnDestroy(): void {
    this.addresses.clear();
  }

  private setUpAddresses(): void {
    if (this._cardId && this._matterCardEntries && this._matterCardEntries.length > 0) {
      const selectedMatterCard = this._matterCardEntries.find((m) => m.cardId === this._cardId);

      if (!selectedMatterCard) {
        return;
      }

      this.selectedMatterCard = selectedMatterCard;

      if (this.isAddressInMemory()) {
        return;
      }

      const enrichedWithAddress = this.enrichedCardEntryWithAddressIfAvailable(this.selectedMatterCard);
      if (isEmptyObj(enrichedWithAddress) || !enrichedWithAddress.address || !enrichedWithAddress.address.street) {
        return;
      }

      const address = CardHelper.getStreetAddress(enrichedWithAddress.address);
      this.addresses.set(this._cardId, address);
      this.address = address;
      this.showAddress.emit(!!this.address);
      this.cdr.markForCheck();
    } else {
      this.address = '';
      this.showAddress.emit(!!this.address);
      this.cdr.markForCheck();
    }
  }

  private isAddressInMemory(): boolean {
    const addressInMemory = this.addresses.get(this._cardId);
    if (addressInMemory) {
      this.address = addressInMemory;
      this.showAddress.emit(!!this.address);
      this.cdr.markForCheck();
      return true;
    } else {
      return false;
    }
  }

  private enrichedCardEntryWithAddressIfAvailable(cardListEntry: ICardListEntry): ICardListEntry {
    const enrichedWithAddress = CardHelper.processCard(clone(cardListEntry));

    if (isEmptyObj(enrichedWithAddress) || !enrichedWithAddress.address || !enrichedWithAddress.address.street) {
      this.addresses[this._cardId] = '';
      this.address = '';
      this.showAddress.emit(!!this.address);
      this.cdr.markForCheck();

      return undefined;
    }

    return enrichedWithAddress;
  }

  private getPersonFullName(person: IPerson): string {
    let fullName = '';
    fullName += !!person.salutation ? person.salutation : '';
    fullName += !!person.firstNames ? ` ${person.firstNames}` : '';
    fullName += !!person.lastName ? ` ${person.lastName}` : '';
    return fullName;
  }
}
