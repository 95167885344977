<div [formGroup]="editForm" *ngIf="!isHidden">
  <sc-textarea
    #input
    inputClass="py-1"
    [delay]="0"
    [rowNumber]="rowNumber"
    [styleRule]="styleRule"
    formControlName="name"
    [focus]="true"
    (onBlur)="handleOnBlur()"
    (onKeydown)="keydown($event)"
  ></sc-textarea>
</div>
