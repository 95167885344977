import { AdvancedSearchData } from '../../models';
import { createReducer, on } from '@ngrx/store';
import * as advancedSearchActions from '../actions/advanced-search';

export interface State {
  onClose: boolean;
  advancedSearchData: AdvancedSearchData | null;
}

export const initialState: State = {
  onClose: false,
  advancedSearchData: null,
};

export const reducer = createReducer(
  initialState,
  on(advancedSearchActions.advancedSearchModalOnOpen, (state, { payload }) => ({
    ...state,
    onClose: false,
    advancedSearchData: payload,
  })),
  on(advancedSearchActions.advancedSearchModalOnClose, (state, { payload }) => ({
    ...state,
    onClose: true,
    advancedSearchData: payload,
  }))
);

// Selectors
export const selectOnClose = (state: State) => state.onClose;
export const selectAdvancedSearchData = (state: State) => state.advancedSearchData;
