import {
  animate,
  animation,
  group,
  query,
  sequence,
  state,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';

export const standardAnimationTiming = '250ms cubic-bezier(0.16, 0.66, 0.32, 1.2)';

// Time and timing curve for overlay panel animations.
export const overlayPanelAnimationEnterTiming = '300ms cubic-bezier(.14,.66,.67,1)';
export const overlayPanelAnimationLeaveTiming = '250ms ease';

// this needs to be same as the $x-overlay-panel-spacing in node_modules/@leapdev/gui/dist/scss/x1/components/overlay-panel/_overlay-panel-stacked.scss
export const layerRightGap = 5;

export const xAnimationScaleIn = animation([
  style({
    transform: 'scale(0)',
  }),
  animate(
    standardAnimationTiming,
    style({
      transform: 'scale(1)',
    })
  ),
]);

export const xAnimationScaleOut = animation([
  style({
    transform: 'scale(1)',
  }),
  animate(
    standardAnimationTiming,
    style({
      transform: 'scale(0)',
    })
  ),
]);

export const xAnimationFadeIn = animation([
  style({
    opacity: 0,
  }),
  animate(
    '250ms ease',
    style({
      opacity: 1,
    })
  ),
]);

export const xAnimationFadeOut = animation([
  style({
    opacity: 1,
  }),
  animate(
    '250ms ease',
    style({
      opacity: 0,
    })
  ),
]);

export const xAnimationHeightExpand = animation([
  style({
    height: 0,
  }),
  animate(
    '250ms ease',
    style({
      height: '*',
    })
  ),
]);

export const xAnimationHeightCollapse = animation([
  style({
    height: '*',
  }),
  animate(
    '250ms ease',
    style({
      height: 0,
    })
  ),
]);

export const xAnimationPulseAnimation = animation([
  state('*', style({ 'box-shadow': '*' })),
  sequence([
    // Fade In Box Shadow
    animate(
      '.3s ease-in',
      style({
        border: '1px solid var(--secondary-lighter)',
        'box-shadow': '0 0 0 0 var(--secondary-transparent), 0 0 0 0 #dceef9', // Fallback of css variables
      })
    ),
    // Fade stage start
    animate(
      '.3s ease-out',
      style({
        border: '1px solid var(--secondary)',
        'box-shadow': '0 0 0.25rem 0.125rem var(--secondary-transparent), 0 0 0.125rem 0.125rem #dceef9', // Fallback of css variables
      })
    ),
    // Fade stage full
    animate(
      '.4s',
      style({
        border: '1px solid var(--secondary-lighter)',
        'box-shadow': '0 0 0.17rem 0.075rem var(--secondary-transparent), 0 0 0.075rem 0.075rem #dceef9', // Fallback of css variables
      })
    ),
    // Fade Out Box Shadow
    animate(
      '.4s ease-out',
      style({
        'box-shadow': '0 0 0 0 var(--secondary-transparent), 0 0 0 0 #dceef9', // Fallback of css variables
      })
    ),
  ]),
]);

export const xAnimationPulseWithParams = trigger('xAnimationPulseWithParams', [
  transition('* => appear', useAnimation(xAnimationPulseAnimation)),
]);

export const xAnimationPulseChildren = trigger('xAnimationPulseChildren', [
  transition(':enter', [
    group([
      query('.ng-select-container', useAnimation(xAnimationPulseAnimation), { optional: true }),
      query('.ui-calendar-w-btn', useAnimation(xAnimationPulseAnimation), { optional: true }),
    ]),
  ]),
]);

export const xAnimationBlockInit = trigger('xAnimationBlockInit', [transition(':enter', [])]);

// This Animation is for ngx-toastr, keep this name or error will occur
export const flyInOut = trigger('flyInOut', [
  state(
    'inactive',
    style({
      display: 'none',
      opacity: 0,
    })
  ),
  transition('inactive => active', [
    group([query(':self', [useAnimation(xAnimationScaleIn)]), query(':self', [useAnimation(xAnimationFadeIn)])]),
  ]),
  transition(':leave', [
    group([query(':self', [useAnimation(xAnimationScaleOut)]), query(':self', [useAnimation(xAnimationFadeOut)])]),
  ]),
]);

// This Animation is for card-details-body. When switch active tabs, this animation occurs.
export const xAnimationFadeInOut = trigger('fadeInOut', [
  transition('* <=> *', [
    query('.fade-in-out-sub', style({ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }), {
      optional: true,
    }),
    query(':enter', style({ opacity: 0 }), { optional: true }),
    query(':leave', style({ opacity: 1 }), { optional: true }),

    query(':leave', [animate(250, style({ opacity: 0 }))], { optional: true }),
    query(':enter', [animate(250, style('*'))], { optional: true }),
  ]),
]);

export const xAnimationMenuExpansion = trigger('menuExpansion', [
  transition(':enter', [
    group([
      query(':self', [useAnimation(xAnimationHeightExpand)]),
      query('h5', [useAnimation(xAnimationFadeIn)], { optional: true }),
      query('.list-group-item', [useAnimation(xAnimationFadeIn)]),
    ]),
  ]),
  transition(':leave', [
    group([
      query(':self', [useAnimation(xAnimationHeightCollapse)]),
      query('h5', [useAnimation(xAnimationFadeOut)], { optional: true }),
      query('.list-group-item', [useAnimation(xAnimationFadeOut)]),
    ]),
  ]),
]);

/**
 * The router animation for different router-outlet entering or leaving
 * */
export const xAnimationRouterLayer = trigger('xAnimationRouterLayer', [
  transition(':enter', [
    group([
      query(
        '.x-panel-right',
        [
          style({
            transform: 'translate3d(150px, 0, 0)',
            opacity: 0,
          }),
          animate(
            overlayPanelAnimationEnterTiming,
            style({
              transform: 'translate3d(0, 0, 0)',
              opacity: 1,
            })
          ),
        ],
        { optional: true }
      ),
      query(
        '.x-panel-left',
        [
          style({
            transform: 'translate3d(-150px, 0, 0)',
            opacity: 0,
          }),
          animate(
            overlayPanelAnimationEnterTiming,
            style({
              transform: 'translate3d(0, 0, 0)',
              opacity: 1,
            })
          ),
        ],
        { optional: true }
      ),
      query(
        '.x-panel-top, .x-panel-center',
        [
          style({
            transform: 'translate3d(0, -150px, 0)',
            opacity: 0,
          }),
          animate(
            overlayPanelAnimationEnterTiming,
            style({
              transform: 'translate3d(0, 0, 0)',
              opacity: 1,
            })
          ),
        ],
        { optional: true }
      ),
      query(
        '.x-overlay-backdrop',
        [
          style({
            opacity: 0,
          }),
          animate(overlayPanelAnimationEnterTiming, style('*')),
        ],
        { optional: true }
      ),
    ]),
  ]),
  transition(':leave', [
    group([
      query(
        '.x-overlay-backdrop',
        [
          animate(
            overlayPanelAnimationLeaveTiming,
            style({
              opacity: 0,
            })
          ),
        ],
        { optional: true }
      ),
      query(
        '.x-overlay-panel > *',
        [
          animate(
            '100ms ease',
            style({
              opacity: 0,
            })
          ),
        ],
        { optional: true }
      ),
      query(
        '.x-panel-right',
        [
          group([
            animate(
              overlayPanelAnimationLeaveTiming,
              style({
                transform: 'translate3d(150px, 0, 0)',
              })
            ),
            animate(
              '225ms ease',
              style({
                opacity: 0,
              })
            ),
          ]),
        ],
        { optional: true }
      ),
      query(
        '.x-panel-left',
        [
          group([
            animate(
              overlayPanelAnimationLeaveTiming,
              style({
                transform: 'translate3d(-150px, 0, 0)',
              })
            ),
            animate(
              '225ms ease',
              style({
                opacity: 0,
              })
            ),
          ]),
        ],
        { optional: true }
      ),
      query(
        '.x-panel-top, .x-panel-center',
        [
          animate(
            overlayPanelAnimationLeaveTiming,
            style({
              transform: 'translate3d(0, 0, 0)',
              opacity: 0,
            })
          ),
        ],
        { optional: true }
      ),
    ]),
  ]),
]);

/**
 * Animation for putting gap between different router outlet
 * */
export const xAnimationLayerGap = trigger('xAnimationLayerGap', [
  transition('* <=> *', [
    query(
      '.x-panel-right',
      [
        style({ transform: 'translate(-{{offsetFrom}}rem, 0)' }),
        animate(overlayPanelAnimationEnterTiming, style({ transform: 'translate(-{{offsetTo}}rem, 0)' })),
      ],
      { optional: true }
    ),
    query(
      '.x-panel-left',
      [
        style({ transform: 'translate({{offsetFrom}}rem, 0)' }),
        animate(overlayPanelAnimationEnterTiming, style({ transform: 'translate({{offsetTo}}rem, 0)' })),
      ],
      { optional: true }
    ),
  ]),
]);

export const xAnimationScale = trigger('xAnimationScale', [
  transition(':enter', [useAnimation(xAnimationScaleIn)]),
  transition(':leave', [useAnimation(xAnimationScaleOut)]),
]);
