import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'sc-ghost',
  templateUrl: './ghost.component.html',
  styleUrls: ['./ghost.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GhostComponent implements OnInit {
  @Input() itemWrapperClass: string;

  @HostBinding('class')
  get hostClasses(): string {
    return this.itemWrapperClass;
  }

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {}
}
