import { Component, HostBinding, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorParams } from '@ag-grid-community/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AggridEditorAbstract } from '@app/sharedaggrid/components/aggrid-editor/aggrid-editor.abstract';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-textarea-editor-cell',
  templateUrl: './textarea-editor-cell.component.html',
})
export class TextareaEditorCellComponent extends AggridEditorAbstract {
  @ViewChild('input', { read: ViewContainerRef }) public input;
  private _params: ICellEditorParams;
  public editForm: FormGroup;
  public isHidden: boolean;
  public rowNumber: number;
  public styleRule: any;
  private editRowHeight: number;
  private inputOnBlur: any;

  constructor(private _formBuilder: FormBuilder) {
    super();
    this.createForm();
    this.isHidden = false;
  }

  @HostBinding('class')
  classes = 'w-100 mt-auto';

  agInit(params: ICellEditorParams): void {
    super.agInit(params);
    this._params = params;
    const defaultValue =
      (isFunction(params['getContent']) ? params['getContent'](params.node.data) : params.value) || '';
    this.editForm.patchValue({ name: defaultValue });
    this.isHidden = (isFunction(params['getIsHidden']) ? params['getIsHidden'](params.node.data) : false) || false;
    this.rowNumber = (isFunction(params['getRowNumber']) ? params['getRowNumber'](params.node.data) : 3) || 3;
    this.styleRule =
      (isFunction(params['getStyleRule']) ? params['getStyleRule'](params.node.data) : params['getStyleRule']) || {};
    this.editRowHeight = params['editRowHeight'];
    this.inputOnBlur = params['inputOnBlur'];
  }

  getValue(): any {
    return this.editForm.get('name').value;
  }

  focusIn() {
    this.input.element.nativeElement.firstElementChild.focus();
  }

  afterGuiAttached() {
    super.afterGuiAttached();
    if (!!this.editRowHeight) {
      this.params.node.setRowHeight(this.editRowHeight);
    }
  }

  handleOnBlur() {
    if (!!this.inputOnBlur) {
      this.inputOnBlur(this._params, this.getValue());
      return;
    }

    this.stopEditing();
  }

  stopEditing(cancel: boolean = false): void {
    this._params.api.stopEditing(cancel);
  }

  private createForm(): void {
    this.editForm = this._formBuilder.group({
      name: '',
    });
  }
}
