import { Injectable } from '@angular/core';
import { AppApiService } from '@app/core/api';
import { ESiriusEvents } from '@app/core/models/feature.model';
import { EventBusService } from '@app/core/services';
import * as precedentActions from '@app/features/+precedent/store/actions';
import { trustReceiptAgGridComponents } from '@app/features/+trust-receipt/components';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IPrecedent, IPrecedentListModelSelection } from '../../models';

@Injectable()
export class PrecedentListModalListenerService {
  private _precedentSelectEventSub: Subscription;
  private _destroy$ = new Subject<void>();

  destroy = (): void => {
    if (this._destroy$) {
      this._destroy$.next();
      this._destroy$ = null;
    }
  };

  listenToPrecedentSelectedEvent = (next: (data: IPrecedentListModelSelection) => void): void => {
    this.init();

    if (this._precedentSelectEventSub) {
      this._precedentSelectEventSub.unsubscribe();
      this._precedentSelectEventSub = null;
    }

    this._precedentSelectEventSub = this._eventBusSvc
      .listen(ESiriusEvents.GetSelectedPrecedentEntries)
      .pipe(take(1), takeUntil(this._destroy$))
      .subscribe((data: IPrecedentListModelSelection) => {
        next(data);
        this.destroy();
      });
  };

  navigateToPrecedentSelection = (data: {
    precedentEntries?: IPrecedent[];
    className?: string;
    multiSelection?: boolean;
    closePopupOutlet?: boolean;
  }): void => {
    const { precedentEntries, className, multiSelection, closePopupOutlet } = data;

    if (!!precedentEntries) {
      this._store.dispatch(new precedentActions.StorePrecedentEntries({ entries: precedentEntries }));
    }

    let queryParams = {};

    if (!!className) {
      queryParams = {
        ...queryParams,
        className,
      };
    }

    if (!!multiSelection) {
      queryParams = {
        ...queryParams,
        multiSelection: trustReceiptAgGridComponents,
      };
    }

    const path = closePopupOutlet
      ? [{ outlets: { popup: null, selector: ['precedent', 'precedent-list'] } }]
      : [{ outlets: { selector: ['precedent', 'precedent-list'] } }];

    this._appApiSvc.navigate({
      path,
      extras: { queryParams, skipLocationChange: true },
    });
  };

  constructor(private _eventBusSvc: EventBusService, private _store: Store, private _appApiSvc: AppApiService) {}

  private init = (): void => {
    if (this._destroy$ === null) {
      this._destroy$ = new Subject<void>();
    }
  };
}
