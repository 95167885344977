import { Action } from '@ngrx/store';
import { ISortInfo } from '@app/shared/models';
import { IBatchSearchFilter } from '../../models/batch-search-filter.model';
import { IBatch } from '../../models';

export const BATCH_SEARCH_SET_FILTER = '[Batch Search] set filter';
export const BATCH_SEARCH_SET_SORT = '[Batch Search] set sort info';
export const BATCH_SEARCH_BATCH_SELECTED = '[Batch Search] batch selected';
export const BATCH_SEARCH_CLOSE = '[Batch Search] close';
export const CLEAR_STORE = '[Batch Search] clear store';

export class BatchSearchSetFilter implements Action {
  readonly type = BATCH_SEARCH_SET_FILTER;
  constructor(public payload: IBatchSearchFilter) {}
}

export class BatchSearchSetSort implements Action {
  readonly type = BATCH_SEARCH_SET_SORT;
  constructor(public payload: ISortInfo) {}
}

export class BatchSelected implements Action {
  readonly type = BATCH_SEARCH_BATCH_SELECTED;
  constructor(public payload: IBatch) {}
}

export class BatchSearchClose implements Action {
  readonly type = BATCH_SEARCH_CLOSE;
  constructor(public payload: any) {}
}

export class ClearStore implements Action {
  readonly type = CLEAR_STORE;
  constructor(public payload: any) {}
}

export type BatchSearchActions =
  | BatchSearchSetFilter
  | BatchSearchSetSort
  | BatchSelected
  | BatchSearchClose
  | ClearStore;
