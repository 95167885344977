import { catchError, map, mergeMap, switchMap, withLatestFrom, exhaustMap, filter, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppApiService } from '@app/core/api';
import { EInfoTrackType } from '@app/core/models';
import { selectContentModeEnabled, selectCurrentMatter, selectCurrentMatterId } from '@app/core/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of, forkJoin } from 'rxjs';

import { DialogService, DocumentAutomationService, OfflineLauncherService } from '@app/shared/services';
import { IAutomationOptions, IDocumentTicketParams, IDoc, IAutomationWorkflowParams } from '@app/shared/models';
import * as actions from '../actions/correspondence';
import { selectSelectedCorrespondence, selectSelectedFolderId } from '../selectors';
import * as CommentActions from 'app/features/+comment/store/actions/comment.actions';
import * as currentMatterActions from 'app/features/+matter-details/store/actions/current-matter';

import { isInfotrackOnline, isComment, isPdf, isEmail, isImage } from '@app/shared/functions';
import { CorrespondenceDownloadProgress } from '../../models';
import { CorrespondenceService } from '../../services';
import { PrecedentService } from '@app/features/+precedent/services';
import { DeleteCorrespondenceStart } from '../actions/correspondence';
import { TranslateService } from '@ngx-translate/core';
import { SetPreviewDocument } from '@app/features/+preview/store';

@Injectable()
export class CorrespondenceEffects {

  renameCorrespondence$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.RenameCorrespondenceStart>(actions.RENAME_CORRESPONDENCE_START),
    mergeMap((action) => {
      const { correspondence, name } = action.payload;
      return this._correspondenceSvc.rename(correspondence as IDoc, name).pipe(
        map(() => new actions.RenameCorrespondenceSuccess(null)),
        catchError((error) => of(new actions.RenameCorrespondenceFailure(error)))
      );
    })
  ));

  deleteCorrespondenceConfirm$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.DeleteCorrespondenceConfirm>(actions.DELETE_CORRESPONDENCE_CONFIRM),
    tap((action) => this._dialogSvc.confirm({
      title: this._translateSvc.instant('Correspondence.List.Delete.Modal.Title'),
      message: this._translateSvc.instant('Correspondence.List.Delete.Modal.Message', {documentName: action.payload.name}),
      showCancel: true,
      closeText: this._translateSvc.instant('Core.Button.Cancel'),
      actionText: this._translateSvc.instant('Core.Button.Delete'),
      onClose: (confirmed) => {
        if(confirmed){
          this._store.dispatch(new DeleteCorrespondenceStart(action.payload));
        }
      }
    }))
  ), {dispatch: false});


  deleteCorrespondence$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.DeleteCorrespondenceStart>(actions.DELETE_CORRESPONDENCE_START),
    mergeMap((action) =>
      this._correspondenceSvc.delete(action.payload).pipe(
        map(() => new actions.DeleteCorrespondenceSuccess(action.payload)),
        catchError((error) => of(new actions.DeleteCorrespondenceFailure(error)))
      )
    )
  ));


  undeleteCorrespondence$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.UndeleteCorrespondenceStart>(actions.UNDELETE_CORRESPONDENCE_START),
    mergeMap((action) =>
      this._correspondenceSvc.undelete(action.payload).pipe(
        map(() => new actions.UndeleteCorrespondenceSuccess(null)),
        catchError((error) => of(new actions.UndeleteCorrespondenceFailure(error)))
      )
    )
  ));


  togglePinCorrespondence$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.TogglePinCorrespondenceStart>(actions.TOGGLE_PIN_CORRESPONDENCE_START),
    mergeMap((action) =>
      this._correspondenceSvc.togglePin(action.payload).pipe(
        map(() => new actions.TogglePinCorrespondenceSuccess(null)),
        catchError((error) => of(new actions.TogglePinCorrespondenceFailure(error)))
      )
    )
  ));


  openEmailInOutlook$: Observable<string> = createEffect(() => this.actions$.pipe(
    ofType<actions.OpenEmailInOutlook>(actions.OPEN_EMAIL_IN_OUTLOOK),
    mergeMap((action) => {
      const document = action.payload as IDoc;
      const params = {
        documentId: document.id,
        ext: document.ext,
        latestVersionId: document.latestVersion,
        isPending: !!document.pendingPrecedent,
      } as IDocumentTicketParams;

      // callback when office is not supported
      const notSupportedCallback = {
        alternative: 'Preview',
        callback: () => this._store.dispatch(new actions.PreviewDocument(action.payload)),
        closeModalAfterCallback: true,
      };
      const workflowParams: IAutomationWorkflowParams = {
        navigateClear: false,
        notSupportedCallback,
      };

      return this._offlineLauncherSvc.createEditEmailTicket({ params, workflowParams });
    })
  ), { dispatch: false });


  editDocumentOnline$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.EditDocumentOnlineStart>(actions.EDIT_DOCUMENT_ONLINE_START),
    withLatestFrom(
      this._store.pipe(select(selectSelectedCorrespondence)),
      this._store.pipe(select(selectCurrentMatterId)),
      this._store.pipe(select(selectSelectedFolderId))
    ),
    switchMap((data) => {
      const [action, document, matterId, folderId] = data;
      const doc = document as IDoc;
      const automationOptions: IAutomationOptions = {
        action: action.payload.type,
        matterId,
        folderId,
        docInfo: {
          documentId: document.id,
          ext: doc.ext,
          isDesktopOnly: doc.desktopOnly,
          isPending: !!doc.pendingPrecedent,
        },
      };

      this._documentAutomationSvc.editDocumentOnline(automationOptions);

      return [];
    }),
    catchError((error) => of(new actions.EditDocumentFailure(error)))
  ));


  editDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.EditDocumentStart>(actions.EDIT_DOCUMENT_START),
    withLatestFrom(
      this._store.pipe(select(selectSelectedCorrespondence)),
      this._store.pipe(select(selectContentModeEnabled)),
      (action, document, contentMode) => ({ action, document, contentMode })
    ),
    mergeMap(({ action, document, contentMode }) => {
      const doc = document as IDoc;
      const params = {
        documentId: doc.id,
        ext: doc.ext,
        latestVersionId: doc.latestVersion,
        isPending: !!doc.pendingPrecedent,
        contentMode,
      } as IDocumentTicketParams;
      // callback when office is not supported
      const notSupportedCallback = {
        alternative: 'Office Online',
        callback: () => this._store.dispatch(new actions.EditDocumentOnlineStart(action.payload)),
        closeModalAfterCallback: true,
      };
      const workflowParams: IAutomationWorkflowParams = {
        navigateClear: false,
        notSupportedCallback,
      };
      return this._offlineLauncherSvc.createEditDocumentTicket({ params, workflowParams });
    }),
    mergeMap((ticketId) => [new actions.EditDocumentSuccess(ticketId)]),
    catchError((e) => [new actions.EditDocumentFailure(e)])
  ));


  previewDocument$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType<actions.PreviewDocument>(actions.PREVIEW_DOCUMENT),
    map((action) => action.payload as IDoc),
    switchMap((document: IDoc) => {
      /**
       * Order as Infotrack > Comment => Pdf viewable (pdf, email, images)
       * since there are Infotrack items with ext pdf but still available online
       */
    if (isInfotrackOnline(document)) {
        return [
          new currentMatterActions.InfotrackNewWin({
            type: EInfoTrackType.Url,
            url: (document as IDoc).url,
          }),
        ];
      } else if (isComment(document)) {
        this._appApiSvc.viewComment(document.id);
        return [];
      } else if (isPdf(document) || isEmail(document) || isImage(document)) {
        this._appApiSvc.navigate({
          path: [{ outlets: { popup: ['preview'] } }],
          extras: { queryParams: { docGuid: !!document.orderId ? document.latestVersion || document.id : document.id, isInfotrack: !!document.orderId } },
        });
        return [new SetPreviewDocument(document)];
      } else {
        return []; // TODO other scenarios
      }
    }),
    catchError((error) => of(new CommentActions.GetCommentFailure(error)))
  ));


  documentDownload$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.DOCUMENT_DOWNLOAD),
    mergeMap(({ payload }) => {
      const doc = payload as any;
      const result = {
        fileName: `${doc.name}.${doc.ext}`,
        fileNameOnly: doc.name,
        url: doc.ext === 'pdf' ? doc.previewUrl : doc.previewUrl.replace('?format=pdf', ''),
      } as CorrespondenceDownloadProgress;
      return [new actions.DocumentDownloadInprogress(result)];
    })
  ));


  duplicateDocumentStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.DuplicateDocumentStart>(actions.DUPLICATE_DOCUMENT_START),
    mergeMap((action) =>
      this._correspondenceSvc.duplicateDocument(action.payload).pipe(
        map((res) => new actions.DuplicateDocumentSuccess(res)),
        catchError((err) => of(new actions.DuplicateDocumentFailure(err)))
      )
    )
  ));


  ExtractAttachmentStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.ExtractAttachmentsStart>(actions.EXTRACT_ATTACHMENT_START),
    map((action) =>
      action.payload.attachments.map((a) =>
        this._correspondenceSvc
          .extractAttachment(action.payload.documentId, a)
          .pipe(catchError((err) => [{ failed: true, err }]))
      )
    ),
    exhaustMap((a) => forkJoin(a)),
    mergeMap((res) => [new actions.ExtractAttachmentsSuccess(res.filter((r) => !r.failed).map((r) => r.fileName))]),
    catchError((err) => [new actions.ExtractAttachmentsFailure(err)])
  ));


  generatePendingPrecedentStart$ = createEffect(() => this.actions$.pipe(
    ofType<actions.GeneratePendingPrecedentStart>(actions.GENERATE_PENDING_PRECEDENT_START),
    filter((action) => !!action.payload && !!action.payload.pendingPrecedent),
    withLatestFrom(this._store.pipe(select(selectContentModeEnabled)), (action, contentMode) => ({
      payload: action.payload,
      contentMode,
    })),
    switchMap(({ payload, contentMode }) => {
      const doc = payload as IDoc;
      const params = {
        documentId: doc.id,
        precedentId: doc.pendingPrecedent.precedentId,
        ext: doc.ext,
        latestVersionId: doc.latestVersion,
        isPending: true,
        contentMode: false,
        pendingPrecedentId: doc.pendingPrecedent.recordId,
      } as IDocumentTicketParams;

      return this._offlineLauncherSvc.generatePendingPrecedent(params);
    }),
    mergeMap((ticketId) => [new actions.GeneratePendingPrecedentSuccess(ticketId)]),
    catchError((error) => of(new actions.GeneratePendingPrecedentFailure(error)))
  ));


  generateReadonlyPendingPrecedentStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.GenerateReadonlyPendingPrecedentStart>(actions.GENERATE_READONLY_PENDING_PRECEDENT_START),
    withLatestFrom(
      this._store.pipe(select(selectCurrentMatterId)),
      this._store.pipe(select(selectSelectedFolderId)),
      (action, matterId, folderId) => [action, matterId, folderId]
    ),
    mergeMap((data) => {
      const [action, matterId, folderId] = data;
      const precedentId = action.payload.pendingPrecedent.precedentId;
      return this._precedentSvc.createDocumentFromReadonlyPrecedent(precedentId, matterId, folderId).pipe(
        map(() => new actions.GenerateReadonlyPendingPrecedentSuccess(action.payload)),
        catchError((error) => of(new actions.GenerateReadonlyPendingPrecedentFailure(error)))
      );
    })
  ));


  generateReadonlyPendingPrecedentSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.GenerateReadonlyPendingPrecedentSuccess | actions.DeletePendingPrecedentCorrespondenceStart>(
      actions.GENERATE_READONLY_PENDING_PRECEDENT_SUCCESS,
      actions.DELETE_PENDING_PRECEDENT_CORRESPONDENCE_START
    ),
    withLatestFrom(this._store.pipe(select(selectCurrentMatterId)), (action, matterId) => [action, matterId]),
    mergeMap((data) => {
      const [action, matterId] = data;
      return this._correspondenceSvc.deletePendingPrecedent(matterId, action.payload.id).pipe(
        map(() => new actions.DeleteCorrespondenceSuccess(null)),
        catchError((error) => of(new actions.DeleteCorrespondenceFailure(error)))
      );
    })
  ));

  constructor(
    private _correspondenceSvc: CorrespondenceService,
    private _precedentSvc: PrecedentService,
    private actions$: Actions,
    private _store: Store<any>,
    private _appApiSvc: AppApiService,
    private _documentAutomationSvc: DocumentAutomationService,
    private _offlineLauncherSvc: OfflineLauncherService,
    private _dialogSvc: DialogService,
    private _translateSvc: TranslateService
  ) {}
}
