import { IDoc } from '@app/shared/models';

export interface DocumentsFilesLatest {
  Url: string;
  AuthRequired: boolean;
  SizeInBytes: number;
}

export interface CurrentPreviewCorrespondence {
  id: string;
  latestVersionId: string;
  name: string;
  previewUrl?: string;
  folderId: string;
  createDate: Date;
  staffName: string;
  ext: string;
  desktopOnly: boolean;
  isEmail: boolean;
  isPdf: boolean;
  isImage: boolean;
  isWord: boolean;
  isExcel: boolean;
  previewFrom: PreviewTriggerType;
  parentDocumentId?: string;
}

export interface SelectedPreviewCorrespondence {
  folderId: string;
  doc: IDoc;
}

export interface PreviewCorrespondenceDownloadProgress {
  fileName: string;
  fileNameOnly: string;
  url: string;
  loading: boolean;
}

export enum PreviewTriggerType {
  default = 0,
  click = 1,
  contextMenu = 2,
  attachment = 4,
  precedent = 5,
}

export interface IPreviewUiStatus {
  treeCollapsed: boolean;
  treeExpandable: boolean;
  wordEditable: boolean;
  excelEditable: boolean;
  emailable: boolean;
  showToolbar: boolean;
  extractAttachment: boolean;
  saveToMatterEnable: boolean;
}

export interface IDocumentRecord {
  documentId: string;
  matterId: string;
  precedentId: string;
  customPrecedentId: string;
  version: number;
  name: string;
  type: string;
  ext?: string;
  fileName: string;
  latestVersionId?: string;
  createDate: string;
  deleteCode: number;
  deleted?: boolean;
  lastModified: string;
  attachments: [
    {
      name: string;
      fileId: string;
      ext: string;
    }
  ];
  lastModifiedBy: string;
  lastModifiedByName?: string;
  staffInitials: string;
  staffName?: string;
  toFrom: string;
  transferMode: string;
  firmId: string;
  timestamp: string;
  docTypeId: string;
  defaultTableId: string;
  defaultTableOrder: string;
  defaultPersonId: string;
  templateName: string;
  templateId: string;
  templateVersion: number;
  usingContentControls: boolean;
  fields: [
    {
      ElementName: string;
      tableId: string;
      order: number;
      fieldId: string;
      name: string;
      value: string;
    }
  ];
  locked: boolean;
  messageId: string;
  assignedToStaff: string;
  macroFields: [
    {
      ElementName: string;
      tableId: string;
      order: number;
      fieldId: string;
      name: string;
      value: string;
    }
  ];
  number: string; // eslint-disable-line
}

export interface PreviewAccountingTransaction {
  matterGuid: string;
  transactionGuid: string;
  transactionNumber: string;
  transactionDocName: string;
  parameters: any;
}

export enum EPreviewType {
  Doc = 'doc',
  DocBuilderInvoice = 'docBuilderInvoice',
  ReportingInvoice = 'reportingInvoice',
  DocBuilderAccounting = 'docBuilderAccounting',
  ReportingAccounting = 'reportingAccounting',
}
