import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../../../../shared/services/base/base.service';
import { HttpClient } from '@angular/common/http';
import { ITaxCode } from 'app/features/accounting/models';
import { map } from 'rxjs/operators';

@Injectable()
export class TaxCodeService extends BaseService {
  constructor(private _http: HttpClient) {
    super();
  }

  getIncomeTaxCodes(): Observable<ITaxCode[]> {
    const url = this.urlJoin(this.accountingPath, '/api/cloud/incomeTaxCodes');
    return this._http.get<ITaxCode[]>(url).pipe(map((taxCodes) => taxCodes.filter((tc) => !tc.Deleted)));
  }

  getExpenseTaxCodes(): Observable<ITaxCode[]> {
    const url = this.urlJoin(this.accountingPath, '/api/cloud/expenseTaxCodes');
    return this._http.get<ITaxCode[]>(url).pipe(map((taxCodes) => taxCodes.filter((tc) => !tc.Deleted)));
  }
}
