<div [formGroup]="paymentTypeForm">
  <label>{{ '__Accounting.Label.PaymentType' | translate }}</label>
  <div>
    <div [class.x-input-group]="paymentTypeId !== undefined">
      <ng-select
        [items]="paymentTypeList"
        [clearable]="false"
        bindLabel="NameFull"
        formControlName="paymentType"
        [ngClass]="{ 'x-is-invalid': validationErrors }"
      >
      </ng-select>

      <div class="x-input-group-append" *ngIf="!!paymentTypeId || paymentTypeId === 0">
        <button class="btn x-btn-default x-btn-icon-only" (click)="showPaymentModal()" [disabled]="isDisabled">
          <sc-icon class="x-icon-sm" iconName="edit"></sc-icon>
        </button>
      </div>
    </div>
  </div>
</div>
