const isEmptyObj = (source: any) => !(source !== undefined && source !== null && Object.keys(source).length > 0);
const isEmptyArray = (source: any[]) => !(source !== undefined && source !== null && source.length > 0);
const firstItemInArray = (source: any[]) => {
  if (!isEmptyArray(source)) {
    const [first, ...others] = source;
    return first;
  }

  return null;
};

const joinArrayToString = (source: any[], seperator: string = ',') => {
  if (!isEmptyArray(source)) {
    return source.reduce((a, b) => `${a}${seperator}${b}`);
  }

  return '';
};

const splitStringToArray = (source: string, seperator: string = ',') => {
  if (source) {
    return source.split(seperator);
  }

  return [''];
};

const everyMatchCondition = (source: any[], condition) => {
  if (isEmptyArray(source) || condition === null || condition === undefined) {
    return true;
  }

  return source.every(condition);
};

const fullArrayJoin = (...array) => {
  const fn = typeof array[array.length - 1] === 'function' ? array.pop() : undefined;
  return Array.from({ length: Math.max(...array.map((a) => a.length)) }, (_, i) =>
    fn ? fn(...array.map((a) => a[i])) : array.map((a) => a[i])
  );
};

const fullObjMerge = (...argument: any[]) => {
  // enhanced from: https://attacomsian.com/blog/javascript-merge-objects

  // array match obj or non obj
  const arrMerge = (arrA, arrB) => {
    const result = [];
    arrA.forEach((a, idx) => {
      result[idx] = typeof arrA[idx] !== 'object' ? arrB[idx] : fullObjMerge(arrA[idx], arrB[idx]);
    });

    return result;
  };

  // create a new object
  const target = {};

  // deep merge the object into the target object
  const merger = (obj) => {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (Object.prototype.toString.call(obj[prop]) === '[object Object]') {
          // if the property is a nested object
          target[prop] = fullObjMerge(target[prop], obj[prop]);
        } else {
          // for regular property
          if (Array.isArray(obj[prop])) {
            if (target[prop] === undefined) {
              target[prop] = obj[prop];
            } else {
              if (target[prop].length > obj[prop].length) {
                target[prop] = [
                  ...arrMerge(obj[prop], target[prop].slice(0, obj[prop].length)),
                  ...target[prop].slice(obj[prop].length),
                ];
              } else {
                target[prop] = obj[prop];
              }
            }
          } else {
            target[prop] = obj[prop];
          }
        }
      }
    }
  };

  // iterate through all objects and
  // deep merge them with target
  argument?.forEach((ar, idx) => {
    merger(argument[idx]);
  });

  return target as any;
};

const singlePick = (data: any, objPath: string) => {
  if (!objPath) {
    return {};
  }

  if (!objPath.includes('.')) {
    const value = data[objPath];

    if (value === undefined || value === null) {
      return {};
    }

    return value;
  }

  const splitArg = objPath.split('.');
  const firstArg = splitArg[0];
  const remainingArgInString = splitArg.slice(1).join('.');

  return singlePick(data[firstArg] || {}, remainingArgInString);
};

const isDate = (value) => {
  if (value instanceof Date) {
    return true;
  }
  return typeof value === 'string' ? !isNaN(Date.parse(value)) : false;
};

const multiPick = (data: any, objPaths: string[]) => {
  const validArgs = objPaths?.filter(Boolean);
  if (validArgs?.length > 0) {
    let result = {};

    validArgs.forEach((arg) => {
      const value = singlePick(data, arg);
      if (value !== undefined && value !== null) {
        if (arg.includes('.')) {
          let temp = '';
          let returnNothing = false;
          const lastIdx = arg.split('.').length - 1;
          const allClosings = arg
            .split('.')
            .map((z) => '}')
            .reduce((a, b) => `${a}${b}`);

          arg.split('.').forEach((a, idx, arr) => {
            temp = temp ? `${temp}:{\"${a}\"` : `{\"${a}\"`;
            if (idx === lastIdx) {
              const isArray = Array.isArray(value);
              const isEmpty = typeof value === 'object' && isEmptyObj(value);
              returnNothing = isEmpty && !isArray;

              if (returnNothing) {
                temp = `${temp}:"\"\"`;
              } else {
                temp = `${temp}:${isArray || typeof value === 'object' ? JSON.stringify(value) : value}${allClosings}`;
              }
            }
          });

          result = returnNothing ? result : { ...result, ...JSON.parse(temp) };
        } else {
          const isArray = Array.isArray(value);
          const isEmpty = typeof value === 'object' && isEmptyObj(value);
          const returnNothing = isEmpty && !isArray && !isDate(value);
          result = returnNothing ? result : { ...result, [arg]: value };
        }
      }
    });

    return result as any;
  }

  return {} as any;
};

const sortAscBy = (entries: any[], propFunc) => {
  if (entries?.length > 0) {
    if (entries.length === 1 || !propFunc) {
      return entries;
    }

    return Array.from(entries).sort((a, b) => {
      if (propFunc(a) > propFunc(b)) {
        return 1;
      }

      if (propFunc(b) > propFunc(a)) {
        return -1;
      }

      return 0;
    });
  }

  return [];
};

const sortDescBy = (entries: any[], propFunc) => {
  if (entries?.length > 0) {
    if (entries.length === 1 || !propFunc) {
      return entries;
    }

    return entries.sort((a, b) => {
      if (propFunc(a) > propFunc(b)) {
        return -1;
      }

      if (propFunc(b) > propFunc(a)) {
        return 1;
      }

      return 0;
    });
  }

  return [];
};

const setOrCreateObjValue = (object, path, value) => {
  // refer to https://stackoverflow.com/questions/54733539/javascript-implementation-of-lodash-set-method
  // When obj is not an object
  if (typeof object !== 'object') {
    return object;
  }

  // If not yet an array, get the keys from the string-path
  if (!Array.isArray(path)) {
    path = path.toString().match(/[^.[\]]+/g) || [];
  }

  path.slice(0, -1).reduce(
    (
      a,
      c,
      i // Iterate all of them except the last one
    ) =>
      Object(a[c]) === a[c] // Does the key exist and is its value an object?
        ? // Yes: then follow that path
          a[c]
        : // No: create the key. Is the next key a potential array-index?
          (a[c] =
            Math.abs(path[i + 1]) > 0 && Math.abs(path[i + 1]) === +path[i + 1]
              ? [] // Yes: assign a new array object
              : {}), // No: assign a new plain object
    object
  )[path[path.length - 1]] = value; // Finally assign the value to the last key

  return object; // Return the top-level object to allow chaining
};

const getObjValue = (object, path, defaultValue = undefined) => {
  // refactor from setOrCreateObjValue.

  if (typeof object !== 'object') {
    return defaultValue;
  }

  if (object === undefined || object === null) {
    return defaultValue;
  }

  if (object && path && typeof path === 'string' && object[path] !== undefined) {
    return object[path];
  }

  if (!Array.isArray(path)) {
    path = path.toString().match(/[^.[\]]+/g) || [];
  }

  const clonedObject = Object.assign({}, object);
  const filteredValue = path.slice(0, -1).reduce(
    (
      a,
      c,
      i // Iterate all of them except the last one
    ) =>
      Object(a[c]) === a[c] // Does the key exist and is its value an object?
        ? // Yes: then follow that path
          a[c]
        : // No: create the key. Is the next key a potential array-index?
          (a[c] =
            Math.abs(path[i + 1]) > 0 && Math.abs(path[i + 1]) === +path[i + 1]
              ? [] // Yes: assign a new array object
              : {}), // No: assign a new plain object
    clonedObject
  );

  const value =
    (filteredValue && Object.keys(filteredValue).length > 0 && filteredValue[path[path.length - 1]]) || undefined;

  if (value !== undefined && value !== null) {
    return value;
  }

  return defaultValue;
};

const stringToStartCase = (value: string) => {
  // _.startCase
  if (!value) {
    return value;
  }

  const raw = typeof value !== 'string' ? JSON.stringify(value) : value;
  const excludeNonWordOrNumericToUnderscore = raw.replace(/[\W_]/g, '_');
  const removeUnderscoreStrings = excludeNonWordOrNumericToUnderscore.split('_').filter(Boolean);
  const capitalizeStrings = removeUnderscoreStrings.map((word) => {
    const [firstLetter, ...restLetter] = word;
    return restLetter?.length > 0 ? `${firstLetter.toUpperCase()}${restLetter.join('')}` : firstLetter.toUpperCase();
  });

  return capitalizeStrings.join(' ');
};

const stringToCamelCase = (value: string) => {
  if (!value) {
    return value;
  }

  const excludeNonWordOrNumericToUnderscore = value.replace(/[\W_]/g, '_');
  const removeUnderscoreStrings = excludeNonWordOrNumericToUnderscore.split('_').filter(Boolean);
  const camelCaseStrings = removeUnderscoreStrings.map((word, idx) => {
    if (idx === 0) {
      return word.substr(0, 1).toLowerCase() + word.substr(1);
    }

    return word.substr(0, 1).toUpperCase() + word.substr(1);
  });

  return joinArrayToString(camelCaseStrings, '');
};

const isString = (value: any) => typeof value === 'string';

const omitObjProp = (object: any, prop: string) => {
  const { [prop]: omitted, ...rest } = object;

  return rest;
};

const omitObjValue = (object: any, props: string[]) => {
  if (!object || typeof object !== 'object' || Array.isArray(object) || !props || props.length === 0) {
    return object;
  }

  let result = { ...object };

  props.forEach((prop) => {
    result = omitObjProp(result, prop);
  });

  return result;
};

const parseJSONSafely = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
};

const stringifyJSONSafely = (obj: any) => {
  try {
    return JSON.stringify(obj);
  } catch (e) {
    return obj;
  }
};

const differenceBetweenArrays = (arrA: any[], arrB: any[]) => {
  // not 100% similar to _.difference. but, it is enough for same order object comparison.
  const isArrAEmpty = !arrA || arrA.length === 0;
  const isArrBEmpty = !arrB || arrB.length === 0;

  if (isArrAEmpty && isArrBEmpty) {
    return [];
  }

  if (isArrAEmpty) {
    return arrB;
  }

  if (isArrBEmpty) {
    return arrA;
  }

  const stringifyArrA = arrA.map((a) => (typeof a === 'object' ? stringifyJSONSafely(a) : a));
  const stringifyArrB = arrB.map((b) => (typeof b === 'object' ? stringifyJSONSafely(b) : b));

  const onlyInArrAStringified = stringifyArrA.filter((a) => !stringifyArrB.includes(a));
  const onlyInArrBStringified = stringifyArrB.filter((b) => !stringifyArrA.includes(b));

  const onlyInArrA = arrA.filter((a) => onlyInArrAStringified.includes((stringifyJSONSafely(a))));
  const onlyInArrB = arrB.filter((b) => onlyInArrBStringified.includes((stringifyJSONSafely(b))));


  return onlyInArrA.concat(onlyInArrB);
};

const differenceBetweenArraysByProp = (arrA: any[], arrB: any[], propFunc) => {
  // not 100% similar to _.difference. but, it is enough for same order object comparison.
  const isArrAEmpty = !arrA || arrA.length === 0;
  const isArrBEmpty = !arrB || arrB.length === 0;

  if (isArrAEmpty && isArrBEmpty) {
    return [];
  }

  if (isArrAEmpty) {
    return arrB;
  }

  if (isArrBEmpty) {
    return arrA;
  }

  if (!propFunc) {
    return [];
  }

  const onlyInArrA = arrA.filter((a) => arrB.findIndex((b) => propFunc(b) === propFunc(a)) === -1);
  const onlyInArrB = arrB.filter((b) => arrA.findIndex((a) => propFunc(a) === propFunc(b)) === -1);

  return onlyInArrA.concat(onlyInArrB);
};

const maxBy = (arr: any[], predicate) => {
  // equals to _.maxBy
  const descOrder = sortDescBy(arr, predicate);

  if (descOrder.length > 0) {
    const [max, ...others] = descOrder;
    return max;
  }

  return undefined;
};

const clone = (obj: any) => {
  return { ...obj } as any;
};

const pick = (obj: any, props: string[]) => {
  if (!obj || Object.keys(obj).length === 0 || !props || props.length === 0) {
    return {} as any;
  }

  const result = {};
  const keys = Object.keys(obj) || [];

  props.forEach((prop) => {
    if (keys.includes(prop)) {
      result[prop] = obj[prop];
    }
  });

  return result as any;
};

const isSubstring = (source: string, substring: string) => !!(source && source.indexOf(substring) !== -1);

const isValueInList = (source: any[], value: any) => {
  if (source?.length > 0) {
    return source.includes(value);
  }

  return false;
};

const trimLeft = (source: string, substring: string) => {
  // _.trimStart

  if (!source || !substring) {
    return source;
  }

  const splitSource = source.split(substring);
  let previousValueMatch = false;
  const updatedSource = splitSource
    .map((s, idx) => {
      if (idx === 0 && s === '') {
        previousValueMatch = true;
        return null;
      }

      if (previousValueMatch && s === '') {
        return null;
      }

      previousValueMatch = false;
      return s;
    })
    .filter((x) => x !== null);

  return joinArrayToString(updatedSource, substring);
};

const trim = (source: string, substring: string) => {
  if (!source || !substring) {
    return source;
  }

  const splitSource = source.split(substring);
  const lastValueIndex = splitSource.length > 1 ? splitSource.length - 1 : -1;

  let previousValueMatch = false;

  const updatedSource = splitSource
    .map((s, idx) => {
      if (idx === 0 && s === '') {
        previousValueMatch = true;
        return null;
      }

      if (idx === lastValueIndex && s === '') {
        return null;
      }

      if (previousValueMatch && s === '') {
        return null;
      }

      previousValueMatch = false;
      return s;
    })
    .filter((x) => x !== null);

  return joinArrayToString(updatedSource, substring);
};

const isFunction = (arg: any) => !!(arg && typeof arg === 'function');

const isBoolean = (arg: any) => !!(!isEmptyValue(arg) && typeof arg === 'boolean');

const isNumber = (arg: any) => !isEmptyValue(arg) && (typeof arg === 'number' || !isNaN(arg));

const toLowerCase = (arg: any) => {
  if (arg && typeof arg === 'string') {
    return arg.toLowerCase();
  }

  return '';
};

const toUpperCase = (arg: any) => {
  if (arg && typeof arg === 'string') {
    return arg.toUpperCase();
  }

  return '';
};

const pickFirstArg = (...args) => {
  if (args) {
    return args[0];
  }

  return undefined;
};

const hasProp = (obj: any, prop: string) => {
  if (!obj || Object.keys(obj).length === 0) {
    return false;
  }

  return Object.keys(obj).includes(prop);
};

const isObjEqual = (objA: any, objB: any) => {
  if (objA === objB) {
    return true;
  }

  // handle array obj.
  if (objA && objB && Array.isArray(objA) && Array.isArray(objB)) {
    return isArrayEqual(objA, objB);
  }

  // handle no array obj.
  if (objA && objB && typeof objA === 'object' && typeof objB === 'object') {
    const objAKeys = Object.keys(objA);
    const objBKeys = Object.keys(objB);

    if (objAKeys.length === objBKeys.length) {
      // Date could be an obj with 0 prop.
      const isDateA = objAKeys.length === 0 && isDate(objA);
      const isDateB = objBKeys.length === 0 && isDate(objB);
      if (isDateA || isDateB) {
        return (isDateA && objA.toString()) === (isDateB && objB.toString());
      }

      let isMatch = true;
      objAKeys.forEach((propA) => {
        if (isMatch) {
          const objAValue = objA[propA];
          const objBValue = objB[propA];

          if (Array.isArray(objAValue) || Array.isArray(objBValue)) {
            isMatch = Array.isArray(objAValue) && Array.isArray(objBValue) ? isArrayEqual(objAValue, objBValue) : false;
          } else {
            const isAValueObj = typeof objA[propA] === 'object';
            const isBValueObj = typeof objB[propA] === 'object';
            const aValue = isAValueObj ? JSON.stringify(objA[propA]) : objA[propA];
            const bValue = isBValueObj ? JSON.stringify(objB[propA]) : objB[propA];
            isMatch = aValue === bValue;
          }
        }
      });

      return isMatch;
    }
  }

  return false;
};

const sliceArray = (arr: any[], startIndex: number, length = -1) => {
  if (arr?.length > 0) {
    const maxLength = length === -1 ? arr.length : length;
    return arr.slice(startIndex, maxLength);
  }

  return [];
};

const zip = (...arrays) => {
  const maxLength = Math.max(...arrays.map((x) => x.length));
  const isAArray = Array.isArray(arrays[0]);
  const isBArray = Array.isArray(arrays[1]);
  const isEitherInputIsArray = isAArray || isBArray;
  const isBothArrays = isAArray && isBArray;

  return Array.from({ length: maxLength }).map((_, i) => {
    const value = Array.from({ length: arrays.length }, (_, k) => {
      const v = arrays[k][i];

      if (!Array.isArray(arrays[k]) && i < 2) {
        return undefined;
      }
      return v;
    });

    if (!isEitherInputIsArray && i < 2) {
      return value.filter(Boolean);
    }

    if (!isBothArrays && isEitherInputIsArray) {
      if (i === 0) {
        const [a, ...aOthers] = value;
        if (isAArray) {
          const [b, ...bOthers] = aOthers;
          return [a].concat(bOthers);
        }

        return aOthers;
      }

      if (i === 1) {
        const [b, ...bOthers] = value;
        if (isBArray) {
          return bOthers;
        }

        const [c, ...cOthers] = bOthers;
        return [b].concat(cOthers);
      }
    }

    return value;
  });
};

const unzip = (arr: any[]) => {
  let result = [];

  const validArrOnly = arr?.filter((a) => a && Array.isArray(a)) || [];

  Object.keys(validArrOnly || []).forEach((idx) => {
    const resultA = [...(result[0] || []), validArrOnly[idx][0]];
    const resultB = [...(result[1] || []), validArrOnly[idx][1]];

    result = [resultA, resultB];
  });

  if (result.length > 0) {
    result = result.filter((e) => e.filter(Boolean).length !== 0);
  }

  return result;
};

const isArrayEqual = (arrA, arrB) => {
  if (arrA && arrB && arrA.length === arrB.length) {
    if (arrA.length === 0) {
      return true;
    }

    let isAllMatch = true;
    arrA.forEach((a, i) => {
      if (isAllMatch) {
        const bValue = arrB[i];
        if (Array.isArray(a) || Array.isArray(bValue)) {
          isAllMatch = Array.isArray(a) && Array.isArray(bValue) ? isArrayEqual(a, bValue) : false;
        } else {
          isAllMatch = isObjEqual(a, bValue);
        }
      }
    });

    return isAllMatch;
  }

  return false;
};

const createRange = (start, end) => {
  const diff = end - start;
  const operator = diff >= 0 ? 1 : -1;

  let result = [];

  [...Array(Math.abs(diff))].forEach((_, i) => {
    result = i === 0 ? [start] : [...result, start + i * operator];
  });

  return result;
};

const groupBy = (items, prop) => {
  const itemByGroup = items?.reduce(
    (result, item) => ({
      ...result,
      [item[prop]]: [...(result[item[prop]] || []), item],
    }),
    {}
  );

  if (itemByGroup && Object.keys(itemByGroup).length > 0) {
    return itemByGroup;
  }

  return {};
};

const isEmptyValue = (value) => Array.isArray(value)
  ? value.filter(v => !isEmptyValue(v)).length === 0
  : [null, undefined, ''].includes(value);

const sumBy = (rows, prop) => {
  const sum = (rows || []).map((v) => v[prop]).reduce((a, b) => a + b, 0);

  return isNaN(sum) ? 0 : sum;
};

const sumByDeepPath = (rows, propFunc) => {
  const sum = (rows || []).map((v) => propFunc(v)).reduce((a, b) => a + b, 0);

  return isNaN(sum) ? 0 : sum;
};

const toPairs = (groupValue) => Object.keys(groupValue).map((k) => [k, groupValue[k]]);

const omitEmptyValue = (obj) => {
  const newObj = {};

  if (obj) {
    Object.keys(obj).forEach((prop) => {
      const value = obj[prop];
      if (!isEmptyValue(value)) {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            newObj[prop] = value;
          }
        } else if (typeof value === 'object') {
          if (Object.keys(value).length > 0) {
            newObj[prop] = value;
          }
        } else {
          newObj[prop] = value;
        }
      }
    });
  }
  return newObj;
};

const roundToPrecision = (value, precision = 2) => +(value + Number.EPSILON).toFixed(Math.abs(precision));

const floorToPrecision = (value, precision = 2) => {
  if (value) {
    const alwaysPositivePrecision = Math.abs(precision);
    const [intValue, decimalValue] = `${value}`.split('.');

    if (alwaysPositivePrecision === 0 || [null, undefined].includes(decimalValue)) {
      return +intValue;
    }

    return +`${intValue}.${decimalValue.substr(0, alwaysPositivePrecision)}`;
  }

  return 0;
};

const uniqBy = (arr, prop) => {
  // _.uniqBy([{ 'x': 1 }, { 'x': 2 }, { 'x': undefined },  { z: 1 }, { x: 1 }], 'z');
  // above code show the uniqBy has bug. it should only return [{z: 1}]; but, it return [{x:1}, {z:1}].

  if (isEmptyArray(arr)) {
    return [];
  }

  if (isEmptyValue(prop) || arr.map((a) => hasProp(a, prop)).filter(Boolean).length === 0) {
    return [arr[0]];
  }

  return arr.filter((value, idx, self) => {
    return self.findIndex((s) => s[prop] === value[prop]) === idx;
  });
};

const uniqByDeepProp = (arr, prop) => {
  // _.uniqBy([{ 'x': 1 }, { 'x': 2 }, { 'x': undefined },  { z: 1 }, { x: 1 }], 'z');
  // above code show the uniqBy has bug. it should only return [{z: 1}]; but, it return [{x:1}, {z:1}].

  if (isEmptyArray(arr)) {
    return [];
  }

  if (isEmptyValue(prop) || arr.map((a) => getObjValue(a, prop, -2)).filter((x) => x === -2).length === arr.length) {
    return [arr[0]];
  }

  let newArr = [];

  arr.forEach((a) => {
    // no prop will be assigned with -2 value
    const value = getObjValue(a, prop, -2);

    if (value !== -2) {
      const anyValueInCurrentList = getObjValue(newArr, prop, -2);

      if (anyValueInCurrentList === -2) {
        newArr = [...newArr, a];
      }
    }
  });

  return newArr;
};

const arrayToObj = (arr) => {
  if (isEmptyArray(arr)) {
    return {};
  }

  const obj = {};
  arr.forEach((a) => {
    const [key, value] = a;
    obj[key] = value;
  });

  return obj;
};

const uniqString = (arr) => {
  const newSet = new Set(arr);
  return Array.from(newSet);
};

const invertObject = (obj: any) => {
  const reverseOrder = sortDescBy(
    Object.keys(obj).map((key, idx) => ({ key, idx })),
    (v) => v.idx
  );
  const newObj = {};

  reverseOrder.forEach((current) => {
    const newKey = obj[current.key];
    const newValue = current.key;

    if (!Object.keys(newObj).includes(newKey)) {
      newObj[newKey] = newValue;
    }
  });

  return newObj;
};

const excludeLastItemInArray = (arr) => {
  if (isEmptyArray(arr) || arr.length === 1) {
    return [];
  }

  return arr.slice(0, arr.length - 1);
};

const intersectionBetweenArrays = (arrA, arrB) => {
  if (arrA?.length > 0 && arrB?.length > 0) {
    const stringifyArrA = arrA.map((a) => (typeof a === 'object' ? stringifyJSONSafely(a) : a));
    const stringifyArrB = arrB.map((b) => (typeof b === 'object' ? stringifyJSONSafely(b) : b));

    return stringifyArrA.filter((a) => stringifyArrB.includes(a)).map((a) => parseJSONSafely(a));
  }

  return [];
};

const intersectionBetweenArraysByProp = (arrA, arrB, propFunc) => {
  if (arrA?.length > 0 && arrB?.length > 0 && propFunc) {
    return arrA.filter((a) => arrB.findIndex((b) => propFunc(a, b)) !== -1);
  }

  return [];
};

const convertStringToJson = (val: string): {isValidJson: boolean; json: any} => {
  try {
    const valJson = JSON.parse(val);
    return {
      isValidJson: true,
      json: valJson
    }
  } catch(_) {
    return {
      isValidJson: false,
      json: null
    }
  }
}

export {
  arrayToObj,
  clone,
  createRange,
  differenceBetweenArrays,
  differenceBetweenArraysByProp,
  everyMatchCondition,
  excludeLastItemInArray,
  firstItemInArray,
  floorToPrecision,
  fullArrayJoin,
  fullObjMerge,
  getObjValue,
  groupBy,
  hasProp,
  invertObject,
  intersectionBetweenArrays,
  intersectionBetweenArraysByProp,
  isArrayEqual,
  isBoolean,
  isEmptyArray,
  isEmptyObj,
  isEmptyValue,
  isFunction,
  isNumber,
  isObjEqual,
  isString,
  isSubstring,
  isValueInList,
  joinArrayToString,
  maxBy,
  multiPick,
  omitObjValue,
  omitEmptyValue,
  pick,
  pickFirstArg,
  roundToPrecision,
  setOrCreateObjValue,
  singlePick,
  sliceArray,
  sortAscBy,
  sortDescBy,
  splitStringToArray,
  stringToCamelCase,
  stringToStartCase,
  sumBy,
  sumByDeepPath,
  trim,
  trimLeft,
  toLowerCase,
  toPairs,
  toUpperCase,
  unzip,
  uniqBy,
  uniqByDeepProp,
  uniqString,
  zip,
  convertStringToJson,
};
