import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sc-main-subheading',
  templateUrl: './main-subheading.component.html',
})
export class MainSubheadingComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
