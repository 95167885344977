export enum Feature {
  PowerMoney = 1,
  TransitMoney = 2,
  SuperDiary = 3,
  ProtectedFunds = 4,
  SpecialFees = 5,
  PrintCheques = 6,
  RapidPay = 7,
  QuickCalculator = 8,
  LiveChat = 9,
  ByLawyers = 10,
  CdnPerEnvironment = 11,
  MatterAddin = 12,
}

export enum ESiriusEvents {
  PrintCreditLedger,
  PrintOfficeLedgerPreBilling,
  PrintTrustInvestmentLedger,
  PrintTrustInvestmentStatement,
  PrintTrustFundsLedger,
  PrintTrustFundsStatement,
  PrintPaymentsDebtorsLedger,
  PrintPaymentsDebtorsStatement,
  PrintPaymentsDebtorsCombinedMatter,
  PrintPaymentsDebtorsPreBilling,
  PrintTimeFeeLedgerList,
  PrintCostRecoveryLedger,
  ReloadLayoutRenderer,
  SetCustomisedListSelectedValue,
  GetSelectedCardEntries,
  ClearSelectedCardEntries,
  GetSelectedMatter,
  GetSelectedPersonEntries,
  GetSelectedPrecedentEntries,
  ShowDialog,
  ShowToastr,
  CreateLeapAppHiddenComponent,
  DestroyLeapAppHiddenComponent,
  LeapAppLoading,
  LeapHostSemaphoreStatus,
  GetSelectedSpecialFees,
  UpdateStaffFeeAllocation,
  CreateNewPrecedent,
  EditFolderNameCompleted,
  SetCurrentUserDetail,
  ResponseToLeapApp,
}

export enum LayerOutletName {
  SelectorDetail = 'selectorDetail',
  Overlay = 'overlay',
  Selector = 'selector',
  Popup = 'popup',
  PopupGlobal = 'popupGlobal',
  LeapAPP1 = 'leap-app-1',
  LeapAPP0 = 'leap-app-0',
  Global = 'global',
}

enum layerOutletOrder {
  Global = 1,
  LeapAPP0,
  LeapAPP1,
  PopupGlobal,
  Popup,
  Selector,
  Overlay,
  SelectorDetail,
}

export const LayerOrderMapper = new Map([
  [LayerOutletName.Global, layerOutletOrder.Global],
  [LayerOutletName.LeapAPP0, layerOutletOrder.LeapAPP0],
  [LayerOutletName.LeapAPP1, layerOutletOrder.LeapAPP1],
  [LayerOutletName.PopupGlobal, layerOutletOrder.PopupGlobal],
  [LayerOutletName.Popup, layerOutletOrder.Popup],
  [LayerOutletName.Selector, layerOutletOrder.Selector],
  [LayerOutletName.Overlay, layerOutletOrder.Overlay],
  [LayerOutletName.SelectorDetail, layerOutletOrder.SelectorDetail],
]);

export enum EPageName {
  About = 'About',
  AccountingBatchDetails = 'Batch Details',
  AnticipatedPayment = 'AnticipatedPayment.Label',
  AppMenu = 'Menu',
  Appointment = 'Appointment',
  CardList = 'Card List',
  Cards = 'Cards',
  Comment = 'Comment.Title.Name',
  CostRecovery = 'CostRecovery.Label',
  CreatePDF = 'Create PDF',
  CreditApply = 'Credit.Title.CreditInvoice',
  CreditJournal = 'Credit.CreditJournal.Heading.New',
  CreditJournalView = 'Credit.CreditJournal.Heading.View',
  CreditJournalReverse = 'Credit.CreditJournal.Heading.Reverse',
  CreditLedger = 'Credit.Title.CreditLedger',
  CreditRefund = 'Credit.Title.CreditRefund',
  CriticalDate = 'Critical Date',
  CustomiseList = 'Core.CustomiseList',
  Conveyancing = 'Conveyancing.Heading',
  Dashboard = 'Dashboard',
  PrecedentCreate = 'Create Precedent',
  DocumentCreate = 'Create Document',
  DocumentShare = 'Share Document',
  OfficeAccountShare = 'Share Office Account',
  TrustAccountShare = 'Share Trust Account',
  Fee = 'Fee Entry',
  FinancialSummary = 'Financial Summary',
  GeneralLedger = 'General Ledger',
  Home = 'Home',
  InfoTrack = 'InfoTrack',
  InvestmentAccount = 'Investment Account',
  InvestmentTransaction = 'Investment Transaction',
  Invoice = 'Invoice',
  InvoiceAdjust = 'Adjust Invoice',
  FeeAllocation = 'Fee Allocation',
  FeeAllocations = 'Fee Allocations',
  InvoiceAlterFees = 'Alter Time/Fees',
  InvoiceAdvancedOptions = 'Advanced Options',
  LawConnectShare = 'LawConnect Share',
  LayoutRenderer = 'Layout Renderer',
  LinkAccount = 'Require Office Authentication',
  MatterDetails = 'Matter Details',
  MatterOptions = 'Matter Options',
  Matters = 'Matters',
  MatterType = 'Matter Type',
  TeamsCallBack = 'Teams Callback',
  TeamsCall = 'Teams Call',
  NewEmail = 'New Email',
  NewLetter = 'New Letter',
  NewMatter = 'New Matter',
  Notifications = 'Notifications',
  OfficeBankDeposit = 'Office Bank Deposit',
  OfficeBankDepositError = 'Office Bank Deposit Error',
  OfficeBulkInvoice = 'Office Bulk Invoice',
  OfficeInvoice = 'Office Invoice',
  OfficeJournal = 'Office Journal',
  OfficeOnline = 'Office Online',
  OfficePayment = 'PaymentDetails.Label.OfficePayment',
  OfficePaymentNew = 'PaymentDetails.Label.NewOfficePayment',
  OfficeReceipt = 'PaymentDebtors.OfficeReceipt.Heading.New',
  OfficeReceiptView = 'PaymentDebtors.OfficeReceipt.Heading.View',
  OfficeReceiptReverse = 'PaymentDebtors.OfficeReceipt.Heading.Reverse',
  OfficeSearchTransactions = 'Office Search Transactions',
  BatchSearch = 'Batch Search',
  ChangeInvoiceStatus = 'Change Invoice Status',
  OverdueInvoices = 'Overdue Invoices',
  PayAnticipated = 'Pay Anticipated',
  PaymentDetails = 'Payment Details',
  PaymentRequest = 'PaymentRequest.Heading.PaymentRequest',
  PaymentRequestNew = 'Office Payment Request',
  PaymentsDebtors = 'PaymentDebtors.Label',
  Persons = 'Persons',
  PowerMoney = 'Trust.PowerMoney.Ledger.Label',
  PowerMoneyAccount = 'Trust.PowerMoney.Account.Menu',
  PrecedentList = 'Precedent.List.Heading',
  Preview = 'Preview.Title',
  PrintCheques = 'Accounting.PrintCheques.Header',
  PrintChequesError = 'Accounting.PrintCheques.Error',
  PrintChequesSettings = 'Accounting.PrintCheques.PrintChequeSettings',
  ProtectedFund = 'Trust.ProtectedFund.Ledger.Label.Full',
  PurchaseOrders = 'Purchase Orders',
  Reauthenticate = 'Reauthenticate',
  RecurringMatters = 'Recurring Matters',
  RecurringMatterDetails = 'Recurring Matter Details',
  RecurringMatterFee = 'Recurring Matter Fee',
  RecurringMatterFeeEntry = 'Recurring Matter Fee Entry',
  Reconciliation = 'Reconciliation',
  Report = 'Report',
  Schedule = 'Calendar & Tasks',
  SelectMatter = 'Select Matter',
  SelectTaskCode = 'Select Task Code',
  SpecialFees = 'Special Fees',
  StaffAllocation = 'Staff Allocation',
  Task = 'Task',
  Time = 'Time Entry',
  TimeFee = 'Time Fee',
  Trust2Office = 'TRUST_LABEL_TRUST2OFFICE',
  Trust2OfficeError = 'TRUST_LABEL_TRUST2OFFICE_ERROR',
  TrustBankAccountRegister = 'Trust Bank Account Register',
  TrustBankDeposit = 'Trust Bank Deposit',
  TrustBankDepositError = 'Trust Bank Deposit Error',
  Trustees = 'Trustees',
  TrustFunds = 'TRUST_MENU_TRUST_FUNDS',
  TrustInvestmentLedger = 'Trust.Investment.Ledger.Label',
  TrustJournal = 'Trust.Journal.Label',
  TrustPayment = 'Trust.Payment.Label',
  TrustPaymentRequest = 'Trust Payment Request',
  TrustReceipt = 'Trust.Receipt.Label',
  UserProfile = 'User Profile',
}

export interface ITopNavAction {
  icon: string;
  name: string;
  actions: Array<{ actionName: string; event: ESiriusEvents }>;
}

export const MatterToolBarAdditionalActions = {
  [EPageName.CreditLedger]: {
    icon: 'print-grid-20',
    name: 'Print',
    actions: [
      {
        actionName: 'Navbar.Print.CreditLedger.Title',
        event: ESiriusEvents.PrintCreditLedger,
      },
    ],
  },
  [EPageName.AnticipatedPayment]: {
    icon: 'print-grid-20',
    name: 'Print',
    actions: [
      {
        actionName: 'Navbar.Print.OfficeLedger.PreBilling',
        event: ESiriusEvents.PrintOfficeLedgerPreBilling,
      },
    ],
  },
  [EPageName.TrustInvestmentLedger]: {
    icon: 'print-grid-20',
    name: 'Print',
    actions: [
      {
        actionName: 'Navbar.Print.Generic.Ledger',
        event: ESiriusEvents.PrintTrustInvestmentLedger,
      },
      {
        actionName: 'Navbar.Print.Generic.Statement',
        event: ESiriusEvents.PrintTrustInvestmentStatement,
      },
    ],
  },
  [EPageName.TrustFunds]: {
    icon: 'print-grid-20',
    name: 'Print',
    actions: [
      {
        actionName: 'Navbar.Print.Generic.Ledger',
        event: ESiriusEvents.PrintTrustFundsLedger,
      },
      {
        actionName: 'Navbar.Print.Generic.Statement',
        event: ESiriusEvents.PrintTrustFundsStatement,
      },
    ],
  },
  [EPageName.PaymentsDebtors]: {
    icon: 'print-grid-20',
    name: 'Print',
    actions: [
      {
        actionName: 'Navbar.Print.OfficeLedger.Ledger',
        event: ESiriusEvents.PrintPaymentsDebtorsLedger,
      },
      {
        actionName: 'Navbar.Print.OfficeLedger.Statement',
        event: ESiriusEvents.PrintPaymentsDebtorsStatement,
      },
      {
        actionName: 'Navbar.Print.OfficeLedger.CombinedMatter',
        event: ESiriusEvents.PrintPaymentsDebtorsCombinedMatter,
      },
      {
        actionName: 'Navbar.Print.OfficeLedger.PreBilling',
        event: ESiriusEvents.PrintPaymentsDebtorsPreBilling,
      },
    ],
  },
  [EPageName.TimeFee]: {
    icon: 'print-grid-20',
    name: 'Print',
    actions: [
      {
        actionName: 'Navbar.Print.TimeFeeLedger.TimeFeeList',
        event: ESiriusEvents.PrintTimeFeeLedgerList,
      },
      {
        actionName: 'Navbar.Print.OfficeLedger.PreBilling',
        event: ESiriusEvents.PrintOfficeLedgerPreBilling,
      },
    ],
  },
  [EPageName.CostRecovery]: {
    icon: 'print-grid-20',
    name: 'Print',
    actions: [
      {
        actionName: 'Navbar.Print.CostRecoveryLedger.Title',
        event: ESiriusEvents.PrintCostRecoveryLedger,
      },
      {
        actionName: 'Navbar.Print.OfficeLedger.PreBilling',
        event: ESiriusEvents.PrintOfficeLedgerPreBilling,
      },
    ],
  },
  [EPageName.FinancialSummary]: {
    icon: 'print-grid-20',
    name: 'Print',
    actions: [
      {
        actionName: 'Navbar.Print.OfficeLedger.PreBilling',
        event: ESiriusEvents.PrintOfficeLedgerPreBilling,
      },
    ],
  },
};

export interface Country {
  name: string;
  code: string;
}

export interface IPhoneNumber {
  __className: string;
  numberType: string;
  number: string; // eslint-disable-line
  areaCode: string;
}

export interface IWebAddress {
  __className: string;
  type: string;
  address: string;
}

export enum UserPreferenceTypes {
  OpenEmail = 'OpenEmail',
  OpenOffice = 'OpenOffice',
  EmailDigest = 'EmailDigest',
  MailFont = 'MailFont',
  ContentMode = 'ContentMode',
}

export enum EInfoTrackType {
  Close = 'close',
  Search = 'search',
  Fees = 'fees',
  Support = 'support',
  Url = 'url',
}
