import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { DateFormatType, Regions } from '@app/shared/models/config.model';

@Injectable()
export class BrandService {
  constructor() {}

  public get isTitleX(): boolean {
    return environment.config.brand.id.indexOf('titlex') >= 0;
  }

  public get isLawconnect(): boolean {
    return environment.config.brand.id.indexOf('lawconnect') >= 0;
  }

  public get brandName(): string {
    const name = environment.config.brand.name;
    return name.toLowerCase() === 'leap' ? name.toUpperCase() : name;
  }

  public get brandEnv(): string {
    return environment.config.brand.env;
  }

  public get brandRegion(): string {
    return environment.config.brand.region;
  }

  public get isSplitInvoiceEnabled(): boolean {
    //* splitInvoice feature is enabled in all test env, US Live, AU Live, and CA Live.
    return (
      !BrandService.isLive() || (BrandService.isLive() && (this.isUS() || this.isAU() || this.isNZ() || this.isCA()))
    );
  }

  public get isPowerMoneyEnabled(): boolean {
    return this.isCA() || this.isAU();
  }

  public get isInfoTrackEnabled(): boolean {
    return !this.isCA();
  }

  public static isLeap(): boolean {
    return environment.config.brand.name === 'Leap';
  }

  public static isLive(): boolean {
    return environment.config.brand.env.includes('live');
  }

  public get walkMeLocale(): string {
    // See LS-2443 for requirements about en-TX
    if (this.isTitleX === true) {
      return 'en-TX';
    }
    switch (environment.config.brand.region) {
      case 'au':
        return 'en-AU';
      case 'nz':
        return 'en-NZ';
      case 'us':
        return 'en-US';
      case 'ca':
        return 'en-CA';
      case 'uk':
      case 'ie':
        return 'en-GB';
      default:
        return '';
    }
  }

  public isNZ(): boolean {
    return environment.config.brand.region === Regions.NZ;
  }

  public isAU(): boolean {
    return environment.config.brand.region === Regions.AU;
  }

  public isUK(): boolean {
    return environment.config.brand.region === Regions.UK || environment.config.brand.region === Regions.IE;
  }

  public isUS(): boolean {
    return environment.config.brand.region === Regions.US;
  }

  public isCA(): boolean {
    return environment.config.brand.region === Regions.CA;
  }

  public getRegionDateFormat(formatType: DateFormatType) {
    if (this.isCA()) {
      if (formatType === DateFormatType.D) {
        return 'EE y/MM/dd';
      }

      if (formatType === DateFormatType.B) {
        return 'yy/mm/dd';
      }

      if (formatType === DateFormatType.C) {
        return 'yyyy/mm/dd';
      }

      return 'y/MM/dd';
    }

    if (this.isUS()) {
      if (formatType === DateFormatType.B) {
        return 'mm/dd/yy';
      }

      if (formatType === DateFormatType.C) {
        return 'mm/dd/yyyy';
      }

      if (formatType === DateFormatType.D) {
        return 'EE MMM d y';
      }

      if (formatType === DateFormatType.E) {
        return 'MMM d y';
      }

      return 'shortDate';
    }

    if (formatType === DateFormatType.B) {
      return 'dd/mm/yy';
    }

    if (formatType === DateFormatType.C) {
      return 'dd/mm/yyyy';
    }

    if (formatType === DateFormatType.D) {
      return 'EE d MMM y';
    }

    if (formatType === DateFormatType.E) {
      return 'd MMM y';
    }

    return 'shortDate';
  }
}
