import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { IGlobalUIPreference } from '@app/core/models';
import { selectUIPreference } from '@app/core/store/selectors/app-state.selectors';
import { select, Store } from '@ngrx/store';
import * as appActions from '@app/core/store/actions/app.action';

@Component({
  selector: 'sc-aside-top-panel',
  templateUrl: './aside-top-panel.component.html',
})
export class AsideTopPanelComponent implements OnInit {
  @Input() atpLabel: string;
  @Input() atpHideOption: boolean;
  @Input() atpPrint: any[];
  @Input() atpMatterNo: string;
  @Input() atpHideSpecialFees: boolean;
  @Output() onToggleAside = new EventEmitter<boolean>();

  // From store
  uiPreference$: Observable<IGlobalUIPreference>;

  constructor(private _store: Store<any>) {}

  ngOnInit() {
    this._store.dispatch(new appActions.LoadUIPreferenceDbStart(null));
    this.uiPreference$ = this._store.pipe(select(selectUIPreference));
  }

  perform(action: string): void {
    console.log(action);
  }

  toggleAside(): void {
    this.onToggleAside.emit(false);
  }
}
