import * as actions from '../actions/time-modal';
import { ITimeFee, ITimeFeeResponse } from '@app/features/+time-fee-ledger/models';

export interface State {
  newWin: boolean;
  setupData: ITimeFee;
  initData: ITimeFeeResponse;
  isOpenInNewWin: boolean;
  playTimer: boolean;
  loading: boolean;
}

export const initialState: State = {
  newWin: false,
  setupData: null,
  initData: null,
  isOpenInNewWin: false,
  playTimer: false,
  loading: false,
};

export const reducer = (state = initialState, action: actions.TimeModalActions): State => {
  switch (action.type) {
    case actions.DEHYDRATE_TIME_FORM_SUCCESS: {
      const payload = action.payload || {} as { formData: ITimeFee; playTimer: boolean };
      const { formData, playTimer } = payload;
      return {
        ...state,
        newWin: true,
        setupData: formData,
        playTimer,
      };
    }

    case actions.INIT_TIME_FEE_FORM: {
      return {
        ...state,
        initData: null,
        isOpenInNewWin: action.payload?.isOpenInNewWin || false,
      };
    }

    case actions.INIT_TIME_FEE_FORM_SUCCESS: {
      const payload = action.payload as ITimeFeeResponse;

      return {
        ...state,
        initData: payload,
      };
    }

    case actions.SET_TIME_MODAL_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export const selectTimeNewWin = (state: State) => state.newWin;
export const selectTimeSetupData = (state: State) => state.setupData;
export const selectTimePlayTimer = (state: State) => state.playTimer;
