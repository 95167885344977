import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/services/base/base.service';
import { HttpClient } from '@angular/common/http';
import { Preference } from '@app/shared/models/user-preferences.model';
import { Observable } from 'rxjs';

@Injectable()
export class UserPreferencesService extends BaseService {
  constructor(private _http: HttpClient) {
    super();
  }

  userPreferencesInit(): Observable<Preference[]> {
    const url = this.urlJoin(this.siriusPath, '/api/preferences');
    return this._http.get<Preference[]>(url);
  }

  updateUserPreferences(data: Preference[]) {
    const url = this.urlJoin(this.siriusPath, '/api/preferences');
    return this._http.put(url, data);
  }

  getUserPreferencesDefaults() {
    const url = this.urlJoin(this.siriusPath, '/api/preferences/preferencedefaults');
    return this._http.get(url);
  }
}
