export enum ExternalUserType {
  Azuer = 'azure-user',
  Google = 'google-user',
  Exchange = 'exchange-user',
}

export enum FirmType {
  Phoenix = -1,
  Conveyancer = 0,
  Solicitor = 1,
  EasysoftConveyancer = 2,
  TitleX = 3
}
