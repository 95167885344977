import { Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';

import { BrandService } from '@app/core/services';
import { DateFormatType } from '@app/shared/models/config.model';

@Component({
  selector: 'sc-date-time-cell',
  templateUrl: './date-time-cell.component.html',
})
export class DateTimeCellComponent implements AgRendererComponent {
  transactionDateTime: Date;
  isHidden: boolean;
  format: string;

  constructor(private _brandSvc: BrandService) {}

  agInit(params: any): void {
    const rowObj = params.data;
    this.isHidden = false;
    this.transactionDateTime = this.isHidden ? '' : params.getDateTime(rowObj);
    this.format = this._brandSvc.getRegionDateFormat(DateFormatType.E);
  }

  refresh(params: any): boolean {
    return true;
  }
}
