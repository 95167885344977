import { selectNamedListState } from '../reducers';

import * as fromNamedList from '../reducers/named-list.reducer';
import { createSelector } from '@ngrx/store';

export const selectNamedListInitiated = createSelector(selectNamedListState, (state: fromNamedList.State) =>
  fromNamedList.selectInitiated(state)
);

export const selectNamedListLoading = createSelector(selectNamedListState, (state: fromNamedList.State) =>
  fromNamedList.selectLoading(state)
);
