<label>{{ transactionNumberLabel | translate }}</label>
<div class="form-inline" [formGroup]="transactionNumberForm">
  <div class="x-max-width-180 w-100">
    <input
      type="text"
      class="form-control col w-100"
      formControlName="transactionNumber"
      [attr.disabled]="(numberDisabled$ | async) || transactionNumberForm.disabled ? '' : null"
      [ngClass]="{ 'x-is-invalid': validationErrors }"
    />
  </div>

  <sc-checkbox
    [wrapperClass]="'custom-control custom-checkbox ml-2'"
    [isDisabled]="(autoDisabled$ | async) || transactionNumberForm.disabled"
    [label]="'Auto No.'"
    formControlName="autoNumber"
    angulartics2On
    [angularticsCategory]="getAnalyticsCategory"
    angularticsAction="Toggle Auto Number"
  ></sc-checkbox>
</div>
