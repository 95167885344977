import { Action } from '@ngrx/store';
import { IInvestmentAccountLedger, IInvestmentLedger } from '@app/features/+trust-investment-ledger/models';

export const LIST_INVESTMENT_LEDGER_START = '[investment ledger] list from api start';
export const LIST_INVESTMENT_LEDGER_SUCCESS = '[investment ledger] list from api success';
export const LIST_INVESTMENT_LEDGER_FAILURE = '[investment ledger] list from api failure';

export const SET_SELECTED_ACCOUNT_GUID = '[investment account] set selected account guid';
export const CLEAR_SELECTED_ACCOUNT_GUID = '[investment account] clear selected account guid';

export const CLEAR_STATE = '[investment ledger] clear state';

export class ListInvestmentLedgerStart implements Action {
  readonly type = LIST_INVESTMENT_LEDGER_START;
  constructor(public payload: string) {}
}

export class ListInvestmentLedgerSuccess implements Action {
  readonly type = LIST_INVESTMENT_LEDGER_SUCCESS;
  constructor(public payload: IInvestmentLedger) {}
}

export class ListInvestmentLedgerFailure implements Action {
  readonly type = LIST_INVESTMENT_LEDGER_FAILURE;
  constructor(public payload: any) {}
}

export class SetSelectedAccountGuid implements Action {
  readonly type = SET_SELECTED_ACCOUNT_GUID;
  constructor(public payload: string) {}
}

export class ClearSelectedAccountGuid implements Action {
  readonly type = CLEAR_SELECTED_ACCOUNT_GUID;
  constructor(public payload: any) {}
}

export class ClearState implements Action {
  readonly type = CLEAR_STATE;
  constructor(public payload: any) {}
}

export type InvestmentLedgerActions =
  | ListInvestmentLedgerStart
  | ListInvestmentLedgerSuccess
  | ListInvestmentLedgerFailure
  | SetSelectedAccountGuid
  | ClearSelectedAccountGuid
  | ClearState;
