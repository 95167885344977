import { createSelector } from '@ngrx/store';
import { isObjEqual } from '@server/modules/shared/functions/common-util.functions';

import { selectCardModalState } from '../reducers';
import * as fromCardModal from '../reducers/card-modal.reducer';

export const selectCardModalFormValue = createSelector(selectCardModalState, (state: fromCardModal.State) =>
  fromCardModal.getFormValue(state)
);

export const selectCardModalActivePersonId = createSelector(selectCardModalState, (state: fromCardModal.State) =>
  fromCardModal.getActivePersonId(state)
);

export const selectCardModalPersonList = createSelector(selectCardModalFormValue, (value: any) =>
  value ? value.personList : []
);

export const selectCardModalCardProcessing = createSelector(selectCardModalState, (state: fromCardModal.State) =>
  fromCardModal.getCardProcessing(state)
);

export const selectCardModalCardDetails = createSelector(selectCardModalState, (state: fromCardModal.State) =>
  fromCardModal.getCardDetails(state)
);

export const selectCardModalSpecialRatesForCard = createSelector(selectCardModalState, (state: fromCardModal.State) =>
  fromCardModal.getSpecialRatesForCard(state)
);

export const selectCardModalCardDetailsInvalidControls = createSelector(
  selectCardModalState,
  (state: fromCardModal.State) => fromCardModal.getInvalidControls(state)
);

export const selectCardModalRelatedMatters = createSelector(selectCardModalState, (state: fromCardModal.State) =>
  fromCardModal.getRelatedMatters(state)
);

export const selectCardModalIsFormValueModified = createSelector(
  selectCardModalFormValue,
  selectCardModalCardDetails,
  (formValue, cardDetails) => !isObjEqual(formValue, cardDetails)
);
