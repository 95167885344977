import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatterListService, MatterSearchService } from './services';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MatterListEffects, NewMatterModalEffect } from './store/effects';
import { reducers } from './store/reducers';
import { MatterDetailsStateModule } from '@app/features/+matter-details/matter-details-state.module';
import { MatterTypesStateModule } from '@app/features/+matter-types/matter-types-state.module';
import { CardStateModule } from '../+card/card-state.module';

@NgModule({
  imports: [
    CommonModule,
    MatterDetailsStateModule,
    MatterTypesStateModule,
    CardStateModule,
    StoreModule.forFeature('matters', reducers),
    EffectsModule.forFeature([MatterListEffects, NewMatterModalEffect]),
  ],
  declarations: [],
  providers: [MatterListService, MatterSearchService],
})
export class MatterListStateModule {}
