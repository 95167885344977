import { Observable } from 'rxjs';
import { AccessType } from '@app/features/+document-share/models/models';

export const PDFDocumentsActions = {
  CreatePDFEmail: 'CREATE_PDF_EMAIL',
  Attach: 'ATTACH',
  AttachCalendar: 'ATTACH_CALENDAR',
  ConvertToPDF: 'CONVERT_TO_PDF',
  Converted: 'ATTACH_CONVERTED',
};
export const DefaultToPdfDocumentType = 'html';

export interface IPdfServiceDocumentDTO {
  name: string;
  filename: string;
  type: string;
}

export interface IDocCombinePDFHttpRequestParams {
  Documents?: IPdfServiceDocumentDTO[];
  MatterId?: string;
  FolderId?: string;
  SaveToMatter: boolean;
  CombinedDocumentsName: string;
  Combine: boolean;
  Secure: boolean;
  SaveToSuperDiaryTempStorage: boolean;
  AddBookmarks?: boolean;
  Convert?: boolean;
  ReturnFileContent?: boolean;
  UseLawConnect?: boolean;
  accessType?: AccessType;
}

export interface IPdfDocument {
  DocumentId: string;
}

export interface IConvertedPdfDocument extends IPdfDocument {
  OriginalId: string;
  FileContent: string;
  FileName: string;
  FileType: string;
}

export interface IFailedPdfDocument extends IPdfDocument {
  Message: string;
}

export interface IDocCombinePDFHttpResponseSchema {
  PdfDocuments: IConvertedPdfDocument[];
  FailedDocuments: IFailedPdfDocument[];
}

export interface ICombinePDFModalOptions {
  title: string;
  folderId: string;
  saveToMatter: boolean;
  saveToSuperDiary: boolean;
  primaryButton: string;
  secondaryButton?: string;
  callbackAction?: string;
  forceConversion?: boolean;
}

export interface IPdfAction {
  title: string;
  payload: IPdfOptions;
}

export interface IPdfOptions {
  pdfPromise: Observable<IDocCombinePDFHttpResponseSchema>;
  pdfRequestParams: IDocCombinePDFHttpRequestParams;
}
