<div class="x-timer-dropdown-content">
  <a
    href
    scPreventDefault
    class="list-group-item"
    *ngFor="let activity of activities; trackBy: trackByFn"
    (click)="onSelected(activity, $event)"
  >
    <h6>{{ activity.nameFileAs }}</h6>
    <span>{{ activity.billingDescription }}</span>
  </a>
</div>
<div class="x-toolbar-actions border-top">
  <a class="d-flex ml-auto text-grey" href scPreventDefault (click)="onCollapseBtnClicked($event)">
    <sc-icon iconName="chevron-up-grid-20" class="x-icon-lg"></sc-icon>
  </a>
</div>
