import { createSelector } from '@ngrx/store';

import * as fromTimeFee from '../reducers/time-fee.reducers';
import { TimeFeeState, selectTimeFeeState } from '../reducers';

export const selectTimeFeeLedgerState = createSelector(selectTimeFeeState, (state: TimeFeeState) => state.timeFee);

export const selectNewFeeEntry = createSelector(
  selectTimeFeeLedgerState,
  (state: fromTimeFee.TimeFeeState) => state.newFeeEntry
);

export const selectAllTimeFees = fromTimeFee.adapter.getSelectors(selectTimeFeeLedgerState).selectAll;

export const selectNewTimeEntry = createSelector(
  selectTimeFeeLedgerState,
  (state: fromTimeFee.TimeFeeState) => state.newTimeEntry
);

export const selectTimeFeeBillingFilter = createSelector(
  selectTimeFeeLedgerState,
  (state: fromTimeFee.TimeFeeState) => state.timeFeeFilterBy
);

export const selectTimeFeeIncTax = createSelector(
  selectTimeFeeLedgerState,
  (state: fromTimeFee.TimeFeeState) => state.incTax
);

export const selectTimeFeeLedgerLoading = createSelector(
  selectTimeFeeLedgerState,
  (state: fromTimeFee.TimeFeeState) => state.loading
);

export const selectCheckedTimeFeeEntries = createSelector(
  selectTimeFeeLedgerState,
  (state: fromTimeFee.TimeFeeState) => state.checkedTimeFeeEntries
);

export const selectCheckedTimeFeeEntryIds = createSelector(
  selectTimeFeeLedgerState,
  (state: fromTimeFee.TimeFeeState) => state.checkedTimeFeeEntryIds
);
