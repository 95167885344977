<div [formGroup]="editForm">
  <sc-input
    #input
    inputClass="py-1"
    formControlName="name"
    [delay]="0"
    (onKeydown)="keydown($event)"
    (onBlur)="handleBlurOnInput($event)"
  ></sc-input>
</div>
