import { Component, HostBinding, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'sc-non-route-tab-item',
  templateUrl: './non-route-tab-item.component.html',
})
export class NonRouteTabItemComponent implements OnInit {
  @Input() heading: string;
  @Input() subHeading: string;
  @Input() isActive: boolean;
  @Input() isDisabled: boolean;
  @Input() class: string;

  @Output() onTabItemClicked = new EventEmitter();

  @HostBinding('class')
  get hostClasses(): string {
    return !!this.class ? 'nav-item ' + this.class : 'nav-item';
  }

  constructor() {}
  ngOnInit() {}

  itemClicked() {
    this.onTabItemClicked.emit();
  }
}
