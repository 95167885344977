import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sc-tab-item-icon',
  templateUrl: './tab-item-icon.component.html',
})
export class TabItemIconComponent implements OnInit {
  @Input() iconName: string;
  @Input() IconClass: string;
  @Output() itemClick = new EventEmitter<boolean>();
  constructor() {}

  @HostBinding('class.nav-item')
  ngOnInit() {}
  itemClicked(event: any) {
    this.itemClick.emit(event);
  }
}
