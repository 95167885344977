import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import * as personListActions from '../actions/person-list';
import * as personDetailsActions from '../actions/person-details';
import { selectSelectedPersonId } from '../selectors/person-list.selectors';
import { PersonListStorageService, PersonService } from '../../services';
import { IPersonListEntry, IPersonListResponseSchema } from '@app/features/+person/models';
import { INITIAL_STATE } from '@app/features/+person/store/reducers';
import {
  selectIsPersonDetailsModified,
  selectPersonDetailsLoading,
} from '@app/features/+person/store/selectors/person-details.selectors';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@app/shared/services';
import { PlatformService } from '@app/core/services';

@Injectable()
export class PersonListEffects {

  listPersons$ = createEffect(() => this.actions$.pipe(
    ofType<personListActions.ListPersonsStart>(personListActions.PersonListApiActionTypes.LIST_PERSONS_START),
    switchMap(() =>
      this.personListStorageSvc.getMeta().pipe(map((meta) => (!!meta && !!meta.lastRowVer ? meta.lastRowVer : 0)))
    ),
    switchMap((lastRowVer) => this.personSvc
        .getAll(lastRowVer)
        .pipe(
          mergeMap((res: IPersonListResponseSchema) => [
            new personListActions.ListPersonsSuccess(res),
            new personListActions.PersonSaveDbStart(res),
          ])
        )),
    catchError((error) => of(new personListActions.ListPersonsFailure(error)))
  ));


  savePersonsToDb$ = createEffect(() => this.actions$.pipe(
    ofType<personListActions.PersonSaveDbStart>(personListActions.PersonListDbActionTypes.PERSONS_SAVE_DB_START),
    filter(() => this.platformService.isBrowser),
    switchMap((action) => this.personListStorageSvc
        .upsertAll(action.payload)
        .catch((error) => new personListActions.PersonSaveDbFailure(error))),
    switchMap(() => this.personListStorageSvc.getAll().pipe(
        mergeMap((data) => {
          this.personListStorageSvc.refreshAll(data);
          return [new personListActions.PersonSaveDbSuccess('success')];
        })
      )),
    catchError((error) => of(new personListActions.PersonSaveDbFailure(error)))
  ));


  loadPersonListMetaFromDb$: any = createEffect(() => this.actions$.pipe(
    ofType<personListActions.LoadPersonListMetaStart>(
      personListActions.PersonListDbActionTypes.LOAD_PERSON_LIST_META_START
    ),
    filter(() => this.platformService.isBrowser),
    switchMap(() => this.personListStorageSvc
        .getMeta()
        .pipe(mergeMap((data) => [new personListActions.LoadPersonListMetaSuccess(data)]))),
    catchError((error) => {
      const { personSearchText, groupInfo } = INITIAL_STATE.personList;
      return of(new personListActions.PersonListMetaSaveDbStart({ personSearchText, groupInfo }));
    })
  ));


  savePersonListMetaToDbStart$ = createEffect(() => this.actions$.pipe(
    ofType<personListActions.PersonListMetaSaveDbStart>(
      personListActions.PersonListDbActionTypes.PERSON_LIST_META_SAVE_DB_START
    ),
    filter(() => this.platformService.isBrowser),
    switchMap((action) => this.personListStorageSvc
        .upsertMeta(action.payload)
        .then(() => new personListActions.PersonListMetaSaveDbSuccess(null))
        .catch(() => new personListActions.PersonListMetaSaveDbFailure(null))),
    catchError(() => of(new personListActions.PersonListMetaSaveDbFailure(null)))
  ));


  selectPerson$: any = createEffect(() => this.actions$.pipe(
    ofType<personListActions.SelectPerson>(personListActions.PersonListApiActionTypes.SELECT_PERSON),
    withLatestFrom(
      this.store.pipe(select(selectIsPersonDetailsModified)),
      this.store.pipe(select(selectSelectedPersonId)),
      this.store.pipe(select(selectPersonDetailsLoading)),
      (action, isPersonDetailsModified, currentSelectedPersonId, isPersonDetailsLoading) => ({
        targetPerson: action.payload.person,
        isPersonDetailsModified,
        currentSelectedPersonId,
        isPersonDetailsLoading,
      })
    ),
    filter((data) => !data.isPersonDetailsLoading),
    tap((data) => {
      const { targetPerson, isPersonDetailsModified, currentSelectedPersonId } = data;
      if (currentSelectedPersonId === targetPerson.personId) {
        return;
      }

      if (isPersonDetailsModified && currentSelectedPersonId !== targetPerson.personId) {
        this.dialogSvc.confirm({
          title: this.translateSvc.instant('Person.Details.Discard.Title'),
          message: this.translateSvc.instant('Person.Details.Discard.Message'),
          showCancel: true,
          actionText: this.translateSvc.instant('Person.Details.Discard.Action.Text'),
          closeText: this.translateSvc.instant('Person.Details.Discard.Close.Text'),
          onClose: (leaveConfirmed) => {
            if (leaveConfirmed) {
              this.confirmPersonSelection(targetPerson);
            }
          },
        });
      } else {
        this.confirmPersonSelection(targetPerson);
      }
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private translateSvc: TranslateService,
    private dialogSvc: DialogService,
    private personSvc: PersonService,
    private platformService: PlatformService,
    private personListStorageSvc: PersonListStorageService
  ) {}

  private confirmPersonSelection(person: IPersonListEntry): void {
    this.store.dispatch(new personListActions.PersonListMetaSaveDbStart({ selectedPersonId: person.personId }));
    this.store.dispatch(new personDetailsActions.LoadPersonDetailsStart({ id: person.personId }));
  }
}
