import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, HostBinding, ViewChild } from '@angular/core';
import { ContextMenuComponent, ContextMenuService } from '@perfectmemory/ngx-contextmenu';
import { ContextMenuOpenAtPositionOptions } from '@perfectmemory/ngx-contextmenu/lib/services/context-menu/context-menu.service';

import {
  IMatterCard,
  IMatterDetailEntry,
  IMatterDetailsInfo,
  INewDetailParams,
  IPersonActingActionParams,
} from '@app/shared/models';

import { BaseDetailEntryAbstract } from './base-detail-entry.abstract';
import { IPerson } from '@app/shared/models';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AnalyticsService } from '@app/core/services';
import { AnalyticsCategories } from '@app/core/constants/analytics.constant';
import { DialogService } from '@app/shared/services';
import {
  getObjValue,
  isFunction,
  sliceArray,
} from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-detail-entry-item',
  templateUrl: './detail-entry-item.component.html',
  styles: [
    `
      .recurring-matter-type,
      .recurring-matter-type * {
        cursor: not-allowed;
      }
    `,
  ],
})
export class DetailEntryItemComponent extends BaseDetailEntryAbstract implements OnInit, OnDestroy {
  @ViewChild('contextMenu', { static: true }) contextMenuRef: ContextMenuComponent<any>;

  @Input()
  set showMatterType(data: boolean) {
    this._showMatterType = data;
  }
  get showMatterType() {
    return this._showMatterType;
  }
  @Input()
  set detailInfo(data: IMatterDetailsInfo) {
    this._detailInfo = data;
  }
  get detailInfo() {
    return this._detailInfo;
  }

  @Input()
  set isRecurringMatter(data: boolean) {
    this._isRecurringMatter = data;
  }
  get isRecurringMatter() {
    return this._isRecurringMatter;
  }

  @Input() contextMenuActions: any[];

  @Input() detailEntries: IMatterDetailEntry[];

  @HostBinding('class.x-detail-entry-item')
  @Output()
  onNewDetail = new EventEmitter<INewDetailParams>();
  @Output() onToggleContextMenu = new EventEmitter();
  @Output() onAddActingPerson = new EventEmitter<IPersonActingActionParams>();
  @Output() onRemoveActingPerson = new EventEmitter<IPersonActingActionParams>();
  @Output() onCardSelected = new EventEmitter<string>();
  @Output() onCardUnSelected = new EventEmitter<void>();
  @Output() onOpenDetailEntry = new EventEmitter<IMatterDetailEntry>();
  @Output() onDrop = new EventEmitter<{ currentIndex: number; previousIndex: number }>();

  private _detailInfo: IMatterDetailsInfo;
  private _showMatterType: boolean;
  private _isRecurringMatter: boolean;

  get saveEnabled(): boolean {
    return !!this.detailInfo && !this.detailInfo.corrupted;
  }
  get customDescription(): string {
    return getObjValue(this.detailInfo, 'customDescription', '...');
  }
  get matterType(): string {
    return getObjValue(this.detailInfo, 'matterType', '');
  }
  get matterState(): string {
    return getObjValue(this.detailInfo, 'matterState');
  }
  get showContextMenu(): boolean {
    return (
      !!this.contextMenuRef &&
      !!this.contextMenuRef.menuItems &&
      this.contextMenuRef.menuItems.length !== 0 &&
      this.contextMenuRef.menuItems.some((m) => this.evaluateVisible(m.visible))
    );
  }

  constructor(
    private _analyticsSvc: AnalyticsService,
    private _dialogSvc: DialogService,
    private _contextMenuSvc: ContextMenuService<any>
  ) {
    super();
  }

  ngOnInit() {}

  ngOnDestroy() {}

  newDetail(params: Partial<INewDetailParams>): void {
    this._analyticsSvc.eventTrack({ category: AnalyticsCategories.MatterDetailsMatterCard, action: 'New card' });
    this.onNewDetail.emit({ ...params, detailEntry: this.detailEntry });
  }

  toggleContextMenu(event: MouseEvent, item: IMatterDetailEntry): void {
    event.preventDefault();
    event.stopPropagation();

    if (this._contextMenuSvc.hasOpenMenu()) {
      this._contextMenuSvc.closeAll();
    }

    if (!this.contextMenuRef) return;

    const posX = event.clientX;
    const posY = event.clientY;

    const options: ContextMenuOpenAtPositionOptions<IMatterDetailEntry> = {
      value: item,
      x: posX,
      y: posY,
    };

    this._contextMenuSvc.show(this.contextMenuRef, options);
  }

  onSelectCard(cardId: string) {
    this._analyticsSvc.eventTrack({ category: AnalyticsCategories.MatterDetailsMatterCard, action: 'Preview card' });
    this.onCardSelected.emit(cardId);
  }

  onUnSelectCard() {
    this.onCardUnSelected.emit();
  }

  addActingPerson(person: IPerson): void {
    this.onAddActingPerson.emit({
      matterId: this._detailInfo.matterId,
      matterCard: this.detailEntry as IMatterCard,
      person,
    });
  }

  removeActingPerson(person: IPerson): void {
    this.onRemoveActingPerson.emit({
      matterId: this._detailInfo.matterId,
      matterCard: this.detailEntry as IMatterCard,
      person,
    });
  }

  showChildren(detailEntry: IMatterDetailEntry) {
    return detailEntry && this.contextType !== 'empty' && detailEntry.children && detailEntry.children.length > 0;
  }

  public openDetailEntry() {
    this.onOpenDetailEntry.emit(this.detailEntry);
  }

  public openMatterTypeDetailEntry() {
    if (!this.isRecurringMatter) {
      this.onOpenDetailEntry.emit(this.detailEntry);
    } else {
      this._dialogSvc.warning({
        message: 'This action is not available whilst creating or editing a recurring matter.',
      });
    }
  }

  public handleCDKDrop(event: CdkDragDrop<any, any>) {
    const { currentIndex: nextIndex, previousIndex: prevIndex } = event;
    if (prevIndex === nextIndex) {
      return;
    }

    const noDragChildrenList = [
      ...sliceArray(this.detailEntry.children, 0, prevIndex),
      ...sliceArray(this.detailEntry.children, prevIndex + 1),
    ];

    const childrenList: IMatterDetailEntry[] = [
      ...sliceArray(noDragChildrenList, 0, nextIndex),
      this.detailEntry.children[prevIndex],
      ...sliceArray(noDragChildrenList, nextIndex),
    ];

    let currentIndex: number;
    const previousIndex = this.detailEntry.children[prevIndex].flatListIndex;
    if (nextIndex === 0) {
      currentIndex = childrenList[nextIndex + 1].flatListIndex - 1;
    } else {
      currentIndex = childrenList[nextIndex - 1].flatListIndex + 1;
    }

    return this.onDrop.emit({ currentIndex, previousIndex });
  }

  private evaluateVisible(value: any): any {
    if (isFunction(value)) {
      return value(this.detailEntry);
    }
    return value;
  }
}
