import { Action } from '@ngrx/store';
import { IPaymentDetailsTypeInit } from '../../models';

export const OUTFLOW_PAYMENT_TYPE_INIT = '[Outflow payment type details] Initialise';
export const OUTFLOW_PAYMENT_TYPE_SET_PAYEE_ID = '[Outflow payment type details] Set payee card id';

export class OutflowPaymentTypeInit implements Action {
  readonly type = OUTFLOW_PAYMENT_TYPE_INIT;
  constructor(public payload: IPaymentDetailsTypeInit) {}
}

export class OutflowPaymentTypeSetPayeeCardId implements Action {
  readonly type = OUTFLOW_PAYMENT_TYPE_SET_PAYEE_ID;
  constructor(public payload: string) {}
}

export type OutflowPaymentTypeDetailsAction = OutflowPaymentTypeInit | OutflowPaymentTypeSetPayeeCardId;
