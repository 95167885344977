import * as actions from '../actions/contact';
import { OutlookContact, OutlookContactResponse } from '../../models';

export interface State {
  contacts: OutlookContact[];
  loading: boolean;
  loaded: boolean;
  error: any | string | null;
}

export const initialState: State = {
  contacts: [],
  loading: true,
  loaded: false,
  error: undefined,
};

export const reducer = (state = initialState, action: actions.ContactApiActions): State => {
  switch (action.type) {
    case actions.GET_CONTACT_START: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case actions.GET_CONTACT_SUCCESS: {
      const payload = action.payload;
      const result = new Array<OutlookContact>();

      if (payload) {
        Array.from(payload as any).forEach((p: OutlookContactResponse) => {
          result.push(p as OutlookContact);
        });
      }

      return {
        ...state,
        loading: false,
        contacts: result,
        error: undefined,
      };
    }

    case actions.GET_CONTACT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const selectError = (state: State) => state.error;
export const selectLoading = (state: State) => state.loading;
export const selectLoaded = (state: State) => state.loaded;
export const selectAllContacts = (state: State) => state.contacts;
