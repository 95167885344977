<ng-select
  #select
  tabindex="0"
  [items]="matterList"
  [virtualScroll]="true"
  [clearable]="false"
  [(ngModel)]="this.selectedMatterId"
  bindLabel="fileNumber"
  bindValue="matterId"
  class="x-select x-min-width-360"
  appendTo="#modal-container"
  (change)="handleNgSelectChange($event)"
  (keydown)="keydown($event)"
  (blur)="handleBlurOnNgSelect()"
>
  <ng-template ng-option-tmp let-item="item">
    <div class="mb-1">
      <strong>{{ item.fileNumber }}</strong>
    </div>
    <div>
      <h6 class="ag-content-name" data-hj-masked [title]="item.firstDescription">{{ item.firstDescription }}</h6>
      <dl class="ag-content-description" *ngIf="item.customDescription">
        <dd>
          <strong data-hj-masked> {{ item.customDescription }} </strong>
          <span *ngIf="item.secondDescription" data-hj-masked> - {{ item.secondDescription }}</span>
        </dd>
      </dl>
    </div>
  </ng-template>
</ng-select>
