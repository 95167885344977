import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { getObjValue } from '../../../../../server/modules/shared/functions/common-util.functions';

import { ILeapTagsOptions, ILeapTag } from '../../models';

@Component({
  selector: 'sc-tags',
  templateUrl: './tags.component.html',

  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TagsComponent), multi: true }],
})
export class TagsComponent<T> implements OnInit, ControlValueAccessor {
  @Input() options: ILeapTagsOptions<T>;
  onChange: any = () => {};

  get tags(): ILeapTag<T> {
    return getObjValue(this.options, 'items', []);
  }

  get itemLabel(): (item: T) => string {
    return !!getObjValue(this.options, 'itemLabel') ? this.options.itemLabel : () => '';
  }

  get readonly(): boolean {
    return getObjValue(this.options, 'readonly', false);
  }

  get wrapperClass(): string {
    return getObjValue(this.options, 'wrapperClass', '');
  }

  private get itemClicked(): (item: T) => void {
    return !!getObjValue(this.options, 'itemClicked') ? this.options.itemClicked : () => {};
  }

  private get itemRemoved(): (item: T) => void {
    return !!getObjValue(this.options, 'itemRemoved') ? this.options.itemRemoved : () => {};
  }

  constructor() {}

  ngOnInit() {}

  writeValue(obj: any): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  tagClicked($event: MouseEvent, item: T): void {
    $event.stopImmediatePropagation();
    this.itemClicked(item);
  }

  tagRemoved($event: MouseEvent, item: T): void {
    $event.stopImmediatePropagation();
    this.itemRemoved(item);
  }
}
