// core
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActiveToast } from 'ngx-toastr';
import { take } from 'rxjs/operators';

import { AppState } from '@app/core/store/reducers';
import { ToastComponent } from '@app/shared/components';
import * as toastrActions from '@app/core/store/actions/toastr.action';
import { IToastrRenderer } from '@app/core/models';

@Injectable()
export class ScToastrService {
  constructor(private _store: Store<AppState>) {}

  registerToastrAction(activeToastr: ActiveToast<ToastComponent>, renderer: Partial<IToastrRenderer> = {}): void {
    const { actionText, actionCallback, autoClose, messageListener } = renderer;
    const registeredCallback = actionCallback || (() => {});
    const registeredActionText = actionText || 'View Details';
    if (!!activeToastr) {
      this._store.dispatch(
        new toastrActions.ShowToastr({
          toastrId: activeToastr.toastId,
          actionText: registeredActionText,
          autoClose,
          messageListener,
        })
      );
      activeToastr.onAction.pipe(take(1)).subscribe(registeredCallback);
    }
  }
}
