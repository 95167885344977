import { Component, ViewChild } from '@angular/core';
import { ITaxCode } from 'app/features/accounting/models/index';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { AggridEditorAbstract } from '@app/sharedaggrid/components/aggrid-editor/aggrid-editor.abstract';
import { NgSelectComponent } from '@ng-select/ng-select';
import { isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-tax-code-select-cell',
  templateUrl: './tax-code-select-cell.component.html',
})
export class TaxCodeSelectCellComponent extends AggridEditorAbstract implements ICellEditorAngularComp {
  @ViewChild('select', { static: true }) selectComponent: NgSelectComponent;
  taxCodeList: ITaxCode[];
  declare params: any; //TODO: [JK] remove declare
  declare value: any; //TODO: [JK] remove declare
  bindLabel: string;
  taxCode: ITaxCode;
  private onTaxCodeInputBlur: (params: unknown, value: unknown) => void;
  private onTaxCodeChange: (params: unknown, value: unknown) => void;

  constructor() {
    super();
  }

  agInit(params: any): void {
    this.params = params;
    this.taxCodeList = this.params.taxCodeList;
    this.bindLabel = isFunction(params['getBindLabel'])
      ? params.getBindLabel(params.node.data) || 'TaxCode'
      : 'TaxCode';
    this.onTaxCodeInputBlur = isFunction(params.onTaxCodeInputBlur) ? params.onTaxCodeInputBlur : null;
    this.onTaxCodeChange = isFunction(params.onTaxCodeChange) ? params.onTaxCodeChange : null;

    const taxCodeId = isFunction(params['getTaxCodeId'])
      ? params.getTaxCodeId(params.node.data)
      : params.node.data.TaxId || params.node.data.TaxCodeId;
    const selectedTaxCode = this.taxCodeList?.find((taxCode) => taxCode.TaxCodeGUID === taxCodeId);
    this.value = !!selectedTaxCode ? selectedTaxCode.TaxCodeGUID : null;
  }

  refresh(params: any): boolean {
    return true;
  }

  getValue(): any {
    return this.value;
  }

  focusIn(): void {
    this.selectComponent.searchInput.nativeElement.focus();
  }

  blur(): void {
    if (this.onTaxCodeInputBlur) {
      this.onTaxCodeInputBlur(this.params, this.value);
    }
  }

  // isPopup() {
  //   return true;
  // }
  handleValueChange(newValue: any) {
    this.value = newValue;
    if (this.onTaxCodeChange) {
      this.onTaxCodeChange(this.params, this.value);
    }
    this.params.node.data.TaxCodeId = newValue; // For AD-Items
    this.params.node.data.TaxCodeRef = newValue; // For Payment Items
  }
}
