import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from '@ag-grid-community/angular';
import { getObjValue, isFunction } from '../../../../../server/modules/shared/functions/common-util.functions';

@Component({
  selector: 'sc-grid-no-results-overlay',
  templateUrl: './grid-no-results-overlay.component.html',
})
export class GridNoResultsOverlayComponent implements INoRowsOverlayAngularComp {
  params: any;
  background = 'bg-white';
  loading: boolean;
  showSkeleton: boolean;
  rowHeight = '37px';
  headerHeight = '56px';
  skeletonAmount = 20;

  constructor() {}

  agInit(params: any): void {
    this.params = params;
    this.loading = isFunction(params['getLoading']) ? params.getLoading() || false : false;
    this.background = getObjValue(params, 'background', this.background);
    this.showSkeleton = getObjValue(params, 'showSkeleton', this.showSkeleton);
    this.headerHeight = getObjValue(params, 'headerHeight', this.headerHeight);
    this.rowHeight = getObjValue(params, 'rowHeight', this.rowHeight);
    this.skeletonAmount = getObjValue(params, 'skeletonAmount', this.skeletonAmount);
  }

  refresh(params: any): boolean {
    return true;
  }
}
