import { Injectable } from '@angular/core';

import { UiUtilsService } from '../../ui/ui-utils.service';
import { IOfficeMessageInfo } from '../../../models';

@Injectable()
export class OnlineLauncherService {
  constructor(private _uiUtilsSvc: UiUtilsService) {}

  openDocumentOnline(info: Partial<IOfficeMessageInfo>): void {
    const screen = window.screen;
    const width = 1200;
    const height = screen.height - 150;
    const left = Number(screen.width / 2 - width / 2);
    const top = Number(screen.height / 2 - height / 2) - 50;

    const hostEditUrl = `${window.location.origin}/officeonline/${info.action}/${info.id}?ext=${info.ext}`;
    const wordWindow = window.open(hostEditUrl, '', `width=${width}, height=${height}, top=${top}, left=${left}`);

    if (!wordWindow || wordWindow.closed || wordWindow.closed === undefined) {
      this._uiUtilsSvc.handlePopUpBlocked();
    }
  }
}
