import { UpdateOutput } from '@app/features/+time-fee-ledger/models';
import { Action } from '@ngrx/store';
import { CurrentFeeTimerMatterState, FeeTimerRecordingState } from '../../models/fee-timer.model';

export const FEE_TIMER_TIME_ELAPSED_START = '[Fee Timer] Time Elapsed start';
export const FEE_TIMER_TIME_ELAPSED_STOP = '[Fee Timer] Time Elapsed stop';
export const FEE_TIMER_TIME_ELAPSED_UPDATE = '[Fee Timer] Time Elapsed update';
export const FEE_TIMER_TIME_ELAPSED_DISCARD = '[Fee Timer] Time Elapsed discard';

export const FEE_TIMER_TIME_ELAPSED_SET = '[Fee Timer] Time Elapsed set';

export const RESET_FEE_TIMER = '[Fee Timer] Reset fee timer';
export const SET_FEE_TIMER = '[Fee Timer] Set fee timer';

export const FEE_TIMER_SHOW = '[Fee Timer] Show fee timer';
export const FEE_TIMER_HIDE = '[Fee Timer] Hide fee timer';

export const FEE_TIMER_ACTIVITY_CODE_UPDATE = '[Fee Timer] Activity code update';

export const FEE_TIMER_PENDING_TIME_ENTRY_UPDATE = '[Fee Timer] Pending time entry update';
export const FEE_TIMER_PENDING_TIME_ENTRY_CLEAR = '[Fee Timer] Pending time entry clear';

export class FeeTimerTimeElapsedStart implements Action {
  readonly type = FEE_TIMER_TIME_ELAPSED_START;
  constructor(public payload: { matterId: string }) {}
}

export class FeeTimerTimeElapsedStop implements Action {
  readonly type = FEE_TIMER_TIME_ELAPSED_STOP;
  constructor(public payload: { matterId: string }) {}
}

export class FeeTimerTimeElapsedUpdate implements Action {
  readonly type = FEE_TIMER_TIME_ELAPSED_UPDATE;
  constructor(public payload: any) {}
}

export class FeeTimerTimeElapsedDiscard implements Action {
  readonly type = FEE_TIMER_TIME_ELAPSED_DISCARD;
  constructor(public payload: { newWin: boolean; matterId?: string }) {}
}

export class FeeTimerTimeElapsedSet implements Action {
  readonly type = FEE_TIMER_TIME_ELAPSED_SET;
  constructor(public payload: { durationInSeconds: number; startTime?: Date; endTime?: Date }) {}
}

export class ResetFeeTimer implements Action {
  readonly type = RESET_FEE_TIMER;
  constructor(public payload: any) {}
}

export class SetFeeTimer implements Action {
  readonly type = SET_FEE_TIMER;
  constructor(public payload: Partial<FeeTimerRecordingState>) {}
}

export class FeeTimerShow implements Action {
  readonly type = FEE_TIMER_SHOW;
  constructor(public payload: CurrentFeeTimerMatterState) {}
}

export class FeeTimerHide implements Action {
  readonly type = FEE_TIMER_HIDE;
  constructor(public payload: any) {}
}

export class FeeTimerActivityCodeUpdate implements Action {
  readonly type = FEE_TIMER_ACTIVITY_CODE_UPDATE;
  constructor(public payload: string) {}
}

export class FeeTimerPendingTimeEntryUpdate implements Action {
  readonly type = FEE_TIMER_PENDING_TIME_ENTRY_UPDATE;
  constructor(public payload: Partial<UpdateOutput>) {}
}

export class FeeTimerPendingTimeEntryClear implements Action {
  readonly type = FEE_TIMER_PENDING_TIME_ENTRY_CLEAR;
}

export type FeeTimerRecordActions =
  | FeeTimerTimeElapsedStart
  | FeeTimerTimeElapsedStop
  | FeeTimerTimeElapsedUpdate
  | FeeTimerTimeElapsedDiscard
  | FeeTimerTimeElapsedSet
  | ResetFeeTimer
  | SetFeeTimer
  | FeeTimerShow
  | FeeTimerHide
  | FeeTimerActivityCodeUpdate
  | FeeTimerPendingTimeEntryUpdate
  | FeeTimerPendingTimeEntryClear;
