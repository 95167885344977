import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AppState } from '../reducers';
import { CoreState } from '../reducers/app.reducer';
import { AppSlice } from '@app/core/store/app.slice';
import { IntegrationProvider } from '@app/shared/models';
import { UserPreferenceTypes } from '@app/core/models';
import { ContentModePreferences } from '@app/core/constants';
import { getObjValue } from '../../../../../server/modules/shared/functions/common-util.functions';

export const selectAppState = createFeatureSelector<AppState>(AppSlice.App);

// TODO: we should not need this hack
export const selectCoreState = createSelector(selectAppState, (state: AppState) => (state ? state.core : {} as CoreState));

export const selectTableMappings = createSelector(selectCoreState, (state: CoreState) => state.tableMappings);

export const selectUserPreferences = createSelector(selectCoreState, (state: CoreState) => state.userPreferences);
export const selectUserPreferencesDefaults = createSelector(
  selectCoreState,
  (state: CoreState) => state.userPreferencesDefaults
);
export const selectContentModeEnabled = createSelector(selectUserPreferences, (userPreferences) => {
  if (!userPreferences) {
    return false;
  }
  const contentModePreference = userPreferences.find((p) => p.Key === UserPreferenceTypes.ContentMode);
  return !!contentModePreference && contentModePreference.Value === ContentModePreferences.Enabled;
});

export const selectUIPreference = createSelector(selectCoreState, (state: CoreState) => state.uiPreference);
export const selectResizeExpanded = createSelector(
  selectCoreState,
  (state: CoreState) => getObjValue(state.uiPreference, 'resizeExpanded', false) || false
);
export const selectFirmDetails = createSelector(selectCoreState, (state: CoreState) => state.firmDetails);
export const selectOfficeOnline = createSelector(
  selectCoreState,
  (state: CoreState) => state.uiPreference.officeOnline
);

export const selectCurrentMatter = createSelector(selectCoreState, (state: CoreState) => state.matterEntry);
export const selectCurrentMatterId = createSelector(selectCoreState, (state: CoreState) =>
  getObjValue(state.matterEntry, 'matterId')
);
export const selectCurrentMatterCore = createSelector(selectCoreState, (state: CoreState) => state.matterCore);

export const selectIntegrationProvider = createSelector(selectCoreState, (state: CoreState) => (
    getObjValue(state, 'firmDetails.defaultSearchProvider', IntegrationProvider.InfoTrack) ||
    IntegrationProvider.InfoTrack
  ));

export const selectAppDisplayLoading = createSelector(selectCoreState, (state: CoreState) => state.appDisplayLoading);

export const selectAppLoadingMessage = createSelector(selectCoreState, (state: CoreState) => state.appLoadingMessage);

export const selectRecurringMatterName = createSelector(
  selectCoreState,
  (state: CoreState) => state.recurringMatterName
);

export const selectAppRegion = createSelector(selectCoreState, (state: CoreState) => getObjValue(state, 'firmDetails.region'));

export const selectWalkmeReady = createSelector(selectCoreState, (state: CoreState) => state.walkmeReady);

export const selectUserDetails = createSelector(selectCoreState, (state: CoreState) => state.userDetails);

export const selectFirmPersonLabels = createSelector(selectFirmDetails, (firmDetails) =>
  !!firmDetails ? firmDetails.personLabels : []
);
