import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { DatePipe, FormatWidth, getLocaleDateFormat } from '@angular/common';

import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocaleInfoMap } from '@app/core/constants';
import { selectFirmDetails } from '@app/core/store';
import { FirmDetails } from '@app/shared/models';
import {
  getObjValue,
  isEmptyObj,
  isSubstring,
  isValueInList,
} from '../../../../../server/modules/shared/functions/common-util.functions';

@Pipe({ name: 'scDate' })
export class ScDatePipe implements PipeTransform, OnDestroy {
  private _regionSub: Subscription;
  private _localeId: string;

  constructor(private _datePipe: DatePipe, private _store: Store<any>) {
    this._localeId = 'en-AU';
    this._regionSub = this._store
      .pipe(
        select(selectFirmDetails),
        map((firmDetails: FirmDetails) => getObjValue(firmDetails, 'region', 'en-AU'))
      )
      .subscribe((region: string) => {
        this._localeId = (LocaleInfoMap[region] || {})['localeId'] || 'en-AU';
      });
  }

  ngOnDestroy() {
    this._regionSub.unsubscribe();
  }

  transform(value: string | number | Date, format?: string): string {
    // No format equals mediumDate
    const formatWidth = getFormatWidth(format);
    const dateFormat = isEmptyObj(formatWidth) ? format : getFormatFunc(value, format, this._localeId, formatWidth);
    return this._datePipe.transform(value, dateFormat, undefined, this._localeId);
  }
}

const getFormatWidth = (format: string): FormatWidth => {
  if (!format || isSubstring(format, 'medium')) {
    return FormatWidth.Medium;
  } else if (isSubstring(format, 'short')) {
    return FormatWidth.Short;
  } else if (isSubstring(format, 'long')) {
    return FormatWidth.Long;
  } else if (isSubstring(format, 'full')) {
    return FormatWidth.Full;
  } else {
    return null;
  }
};

const getFormatFunc = (value: string | number | Date, format: string, locale: string, width: FormatWidth): string => {
  const dateFormats = ['shortDate', 'mediumDate', 'longDate', 'fullDate'];
  const dateTimeFormats = ['short', 'medium', 'long', 'full'];
  const timeFormats = ['shortTime', 'mediumTime', 'longTime', 'fullTime'];
  if (!format || isValueInList(dateFormats, format)) {
    return getLocaleDateFormat(locale, width);
  } else if (isValueInList(dateTimeFormats, format)) {
    return getLocaleDateFormat(locale, width);
  } else if (isValueInList(timeFormats, format)) {
    return getLocaleDateFormat(locale, width);
  } else {
    return value.toString();
  }
};
