import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { countries as countriesList } from 'countries-list';
import { Country, IPhoneNumber, IWebAddress } from '@app/core/models';
import { IPerson } from '../../models';
import { StartupService } from '@app/core/services';
import { sortAscBy } from '@server/modules/shared/functions/common-util.functions';

@Injectable()
export class PersonUtilsService {
  constructor(private formBuilder: FormBuilder, private startupSvc: StartupService) {}

  get countries() {
    const countries: Country[] = Object.entries(countriesList).map((entry) => ({
      code: entry[0],
      name: entry[1].name,
    }));
    return sortAscBy(countries, (c) => c.name);
  }

  createPhone(type: string = 'Phone', options: any = {}): IPhoneNumber {
    return {
      __className: 'PhoneNumber',
      numberType: type,
      number: options.number || '', // eslint-disable-line
      areaCode: options.areaCode || '',
    } as IPhoneNumber;
  }

  createPhoneFormGroup(type: string = 'Phone', options: any = {}): FormGroup {
    return this.formBuilder.group({
      __className: 'PhoneNumber',
      numberType: type,
      number: options.number || '', // eslint-disable-line
      areaCode: options.areaCode || '',
    });
  }

  createWebAddress(type: string = 'Email', options: any = {}): IWebAddress {
    return {
      __className: 'WebAddress',
      type,
      address: options.address || '',
    };
  }

  createWebAddressFormGroup(type: string = 'Email', options: any = {}): FormGroup {
    const formGroup = this.formBuilder.group({
      __className: 'WebAddress',
      type,
      address: options.address || '',
    });
    formGroup.setValidators(this.emailValidator());
    formGroup.updateValueAndValidity();
    return formGroup;
  }

  // only if email is entered should it be validated
  // email can be empty as it is not required
  emailValidator(): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {
      const type = group.controls['type'];
      const address = group.controls['address'];
      if (type.value?.includes('Email') && address.value !== '' && address.dirty && Validators.email(address)) {
        return { email: true };
      } else {
        return null;
      }
    };
  }

  createPerson(options: any = {}): IPerson {
    const id: string = options.__id || uuidv4();
    const firmId: string = options.__firmId || this.startupSvc.userDetails.firmId;
    const userId: string = options.userId || this.startupSvc.userDetails.userId;
    const phoneNumberList =
      options.phoneNumberList && options.phoneNumberList.length > 0
        ? options.phoneNumberList
            .filter((phone) => !!phone.number)
            .map((phone) => this.createPhone(phone.numberType, phone))
        : [];
    const web =
      options.webAddressList && options.webAddressList.length > 0
        ? options.webAddressList
            .filter((w) => (w.type === 'Web' || w.type === 'Web2') && !!w.address)
            .map((w) => this.createWebAddress(w.type, w)) || []
        : [];
    const emails =
      options.webAddressList && options.webAddressList.length > 0
        ? options.webAddressList
            .filter((w) => (w.type === 'Email' || w.type === 'Email2') && !!w.address)
            .map((w) => this.createWebAddress(w.type, w)) || []
        : [];
    const webAddressList = [...emails, ...web];

    const person = {
      __id: id,
      __className: 'Person',
      __firmId: firmId,
      userId,
      __personId: id,
      salutation: options.salutation,
      firstNames: options.firstNames || '',
      lastName: options.lastName || '',
      previousNames: options.previousNames || '',
      maidenName: options.maidenName || '',
      gender: options.gender || null,
      nationality: options.nationality,
      passportNumber: options.passportNumber || '',
      passportName: options.passportName || '',
      dateOfBirth: options.dateOfBirth ? new Date(options.dateOfBirth) : null,
      placeOfBirth: options.placeOfBirth,
      countryOfBirth: options.countryOfBirth,
      dateOfDeath: options.dateOfDeath ? new Date(options.dateOfDeath) : null,
      placeOfDeath: options.placeOfDeath,
      primary: false,
      webAddressList,
      phoneNumberList,
      dirty: true,
      version: options.version || 1,
      deleteCode: options.deleteCode || 0,
      comments: options.comments || '',
      occupation: options.occupation || '',
      eMailAddressesText: options.eMailAddressesText || '',
      interpreterLanguage: options.interpreterLanguage || '',
      specialNeeds: options.specialNeeds || '',
      maritalStatus: options.maritalStatus || '',
      custom1: options.custom1 || null,
      custom2: options.custom2 || null,
      custom3: options.custom3 || null,
      custom4: options.custom4 || null,
      custom5: options.custom5 || null,
      custom6: options.custom6 || null,
      custom7: options.custom7 || null,
      custom8: options.custom8 || null,
      custom9: options.custom9 || null,
      custom10: options.custom10 || null,
      custom11: options.custom11 || null,
      custom12: options.custom12 || null,
    } as IPerson;
    return person;
  }
}

export const getPersonsNamesString = (personList: any[]): string => {
  let personNamesText = '';
  if (!!personList && personList.length > 0) {
    personList
      .filter((p) => !!p.firstNames || !!p.lastName || !!p.forenames || !!p.surname)
      .forEach((p, i, arr) => {
        let chars = '';
        if (i !== 0) {
          chars = i === arr.length - 1 ? ' & ' : ', ';
        }
        personNamesText += chars + getPersonFullName(p);
      });
  }
  return personNamesText.trim();
};

export const getPersonFullName = (person: any): string => {
  if (!person) {
    return '';
  }
  const salutation = person.salutation;
  const firstNames = person.firstNames || person.forenames;
  const lastName = person.lastName || person.surname;
  let fullName = '';
  fullName += !!salutation ? salutation : '';
  fullName += !!firstNames ? ` ${firstNames}` : '';
  fullName += !!lastName ? ` ${lastName}` : '';
  return fullName.trim();
};
