import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isString } from '../../../../../server/modules/shared/functions/common-util.functions';

@Pipe({ name: 'scTranslate' })
export class ScTranslatePipe implements PipeTransform {
  constructor(private _translateSvc: TranslateService) {}

  transform(value: string): string {
    const translatedValue = this._translateSvc.instant(value);
    return isString(translatedValue) ? translatedValue : value;
  }
}
