import { selectPersonDetailsState } from '../reducers';
import * as fromPersonDetails from '../reducers/person-details.reducer';
import { selectSelectedPersonId } from '../selectors/person-list.selectors';
import { createSelector } from '@ngrx/store';
import { getPersonFullName } from '@app/shared/services';
import { isObjEqual } from '@server/modules/shared/functions/common-util.functions';

export const selectPersonDetailsLoading = createSelector(selectPersonDetailsState, (state: fromPersonDetails.State) =>
  fromPersonDetails.getLoading(state)
);

export const selectPersonDetailsFormValue = createSelector(selectPersonDetailsState, (state: fromPersonDetails.State) =>
  fromPersonDetails.getFormValue(state)
);

export const selectPersonDetails = createSelector(selectPersonDetailsState, (state: fromPersonDetails.State) =>
  fromPersonDetails.getPersonDetails(state)
);

export const selectPersonDetailsProcessing = createSelector(
  selectPersonDetailsState,
  (state: fromPersonDetails.State) => fromPersonDetails.getPersonProcessing(state)
);

export const selectPersonDetailsInvalidControls = createSelector(
  selectPersonDetailsState,
  (state: fromPersonDetails.State) => fromPersonDetails.getInvalidControls(state)
);

export const selectPersonDetailsRelatedCardIds = createSelector(
  selectPersonDetailsState,
  (state: fromPersonDetails.State) => fromPersonDetails.getRelatedCardIds(state)
);

export const selectPersonDetailsRelatedMatterIds = createSelector(
  selectPersonDetailsState,
  (state: fromPersonDetails.State) => fromPersonDetails.getRelatedMatterIds(state)
);

export const selectPersonDetailsFormValueFullName = createSelector(selectPersonDetailsFormValue, (formValue) =>
  formValue ? getPersonFullName(formValue) : ''
);

export const selectPersonDetailsLoaded = createSelector(
  selectPersonDetailsLoading,
  selectSelectedPersonId,
  selectPersonDetailsFormValue,
  (loading, currentPersonEntryId, formValue) =>
    !loading && ((!!currentPersonEntryId && !!formValue) || !currentPersonEntryId)
);

export const selectIsPersonDetailsModified = createSelector(
  selectPersonDetailsLoading,
  selectPersonDetailsFormValue,
  selectPersonDetails,
  (personDetailsLoading, formValue, personDetails) => !!(
      !personDetailsLoading &&
      !!formValue &&
      !!personDetails &&
      formValue.__personId === personDetails.__personId &&
      !isObjEqual(formValue, personDetails)
    )
);

export const selectIsSelectedPersonDeleted = createSelector(
  selectPersonDetailsFormValue,
  (formValue) => !!formValue && formValue.deleteCode === 1
);
