import { IPartiesFormData } from '@app/features/+matter-types/models';
import { IMatterType } from '@app/shared/models';
import { getObjValue } from '@server/modules/shared/functions/common-util.functions';

export const getSafePartiesInfo = (partiesInfo: IPartiesFormData, matterType: IMatterType): IPartiesFormData => {
  const defaultTableId = (key: string): string => {
    const subsTables = getObjValue(matterType, `substitutionTables.${key}.tables`, []);
    if (subsTables.length === 0) {
      return null;
    }
    return (subsTables.find((t) => t.default === true) || subsTables[0]).tableId;
  };
  return {
    client: getObjValue(partiesInfo, 'client', defaultTableId('client')),
    otherSide: getObjValue(partiesInfo, 'otherSide', defaultTableId('otherSide')),
    otherSideSolicitor: getObjValue(partiesInfo, 'otherSideSolicitor', defaultTableId('otherSideSolicitor')),
    otherSideInsurer: getObjValue(partiesInfo, 'otherSideInsurer', defaultTableId('otherSideInsurer')),
  };
};

export const getDisplayMatterType = (matterType: IMatterType, state: string): string => {
  if (!matterType) {
    return '';
  }
  const thisState = matterType.states?.find((s) => s.name === state);
  if (thisState) {
    return thisState.alias[state] || matterType.name;
  } else {
    const allState = matterType.states?.find((s) => s.name === 'All');
    if (allState) {
      return allState.alias[state] || matterType.name;
    } else {
      return matterType.name;
    }
  }
};

export const extendRowCollectionWithHierarchyProp = (data: IMatterType[]): IMatterType[] => {
  if (data.length) {
    const newDataAccumulator = [];
    data.forEach((parentNode) => {
      flatChildrenRecursively(parentNode, [], newDataAccumulator);
    });
    return newDataAccumulator;
  }
  return data;
};

const flatChildrenRecursively = (node, hierarchy, into) => {
  into.push({
    ...node,
    hierarchy: [...hierarchy, node.id],
  });
  if (node?.children.length) {
    node.children.forEach((childNode) => {
      flatChildrenRecursively(childNode, [...hierarchy, node.id], into);
    });
  }
};
