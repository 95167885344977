import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'sc-table-total-count',
  templateUrl: './table-total-count.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableTotalCountComponent {
  @HostBinding('class.w-100')
  @Input()
  ttUnitPlural: string;
  @Input() ttUnitSingular: string;
  @Input()
  set ttNumber(value: number) {
    this._ttNumber = value;
  }
  get ttNumber() {
    return this._ttNumber;
  }

  private _ttNumber: number;

  constructor() {}
}
