<div [formGroup]="editForm">
  <sc-input
    inputClass="py-1"
    formControlName="name"
    [delay]="delayInputInMs"
    [autofocus]="true"
    (onBlur)="stopEditing()"
    (onKeydown)="keydown($event)"
    (input)="onInput($event)"
    #input
  ></sc-input>
</div>
