import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PrecedentListEffects } from './store/effects';
import { reducers } from './store/reducers';
import { PrecedentService } from './services/precedent/precedent.service';
import { PrecedentListModalListenerService } from './services/precedent/precedent-list-modal-listener.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('precedents', reducers),
    EffectsModule.forFeature([PrecedentListEffects]),
  ],
  declarations: [],
  providers: [PrecedentService, PrecedentListModalListenerService],
})
export class PrecedentListStateModule {}
