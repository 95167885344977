import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LogService } from '@app/core/services';
import { Store } from '@ngrx/store';
import * as automationActions from '@app/core/store/actions/automation.actions';

@Component({
  selector: 'sc-desktop-mode-warning',
  templateUrl: './desktop-mode-warning.component.html',
})
export class DesktopModeWarningComponent implements OnInit, OnDestroy {
  alternative: string; // text of the alternative that would show on the modal
  callback: (data?: any) => any;
  closeModalAfterCallback: boolean;

  constructor(public bsModalRef: BsModalRef, private _log: LogService, private _store: Store<any>) {
    this._log.init('desktop-mode-warning-component');
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this._store.dispatch(new automationActions.ClearAutomationTickets(null));
  }

  dismiss(): void {
    this.bsModalRef.hide();
  }

  handleCallback() {
    if (!!this.callback) {
      this.callback();
      if (this.closeModalAfterCallback) {
        this.dismiss();
      }
    }
  }
}
