import { ActivityCodesState } from '@app/features/fee-timer/models/fee-timer.model';
import * as fromFeeTimer from '../actions/';

export const INITIAL_STATE: ActivityCodesState = {
  loaded: false,
  loading: false,
  queryText: '',
  searchCompleted: false,
  searching: false,
  origin: [],
  filtered: [],
};

export const reducer = (state = INITIAL_STATE, action: fromFeeTimer.FeeTimerActivityCodeActions): ActivityCodesState => {
  switch (action.type) {
    case fromFeeTimer.FEE_TIMER_ACTIVITY_CODE_LOAD: {
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    }

    case fromFeeTimer.FEE_TIMER_ACTIVITY_CODE_LOAD_COMPLETED: {
      return {
        ...state,
        loaded: true,
        loading: false,
        origin: action.payload,
        filtered: action.payload,
      };
    }

    case fromFeeTimer.FEE_TIMER_ACTIVITY_CODE_LOAD_FAIL: {
      return {
        ...state,
        loaded: false,
        loading: false,
        origin: [],
        filtered: [],
      };
    }

    case fromFeeTimer.FEE_TIMER_ACTIVITY_CODE_SEARCH: {
      return {
        ...state,
        searching: true,
        searchCompleted: false,
        queryText: action.payload,
      };
    }

    case fromFeeTimer.FEE_TIMER_ACTIVITY_CODE_SEARCH_COMPLETED: {
      return {
        ...state,
        searching: false,
        searchCompleted: true,
        filtered: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export const selectFeeTimerActivityCodeState = (state: ActivityCodesState) => state;
export const selectSearchingState = (state: ActivityCodesState) => state.searching;
export const selectQueryTextState = (state: ActivityCodesState) => state.queryText;
export const selectSearchCompletedState = (state: ActivityCodesState) => state.searchCompleted;
export const selectActivityCodesState = (state: ActivityCodesState) => state.origin;
export const selectFilteredActivityCodesState = (state: ActivityCodesState) => state.filtered;
