import { Action } from '@ngrx/store';
import { DocumentRecipient, ISelectCustomPrecedentPayload } from '@app/features/+document/models';
import { AutomationHostApplicationType } from '@app/shared/models';
import { ICustomPrecedent } from '@app/features/+precedent/models';

export const NEW_DOCUMENT = '[New document] modal open';
export const NEW_DOCUMENT_FAILURE = '[New document] modal open failure';

export const NEW_DOCUMENT_CREATE = '[New document] create';
export const NEW_DOCUMENT_CREATE_SUCCESS = '[New document] create success';
export const NEW_DOCUMENT_CREATE_FAILURE = '[New document] create failure';

export const NEW_DOCUMENT_TYPE_CREATE = '[New document] create new document type';

export const NEW_DOCUMENT_CREATE_ONLINE = '[New document] create online';
export const EDIT_PRECEDENT_ONLINE = '[Edit document] edit online';

export const EDIT_PRECEDENT_START = '[Edit precedent] start';
export const EDIT_PRECEDENT_SUCCESS = '[Edit precedent] success';
export const EDIT_PRECEDENT_FAILURE = '[Edit precedent] failure';

export class NewDocument implements Action {
  readonly type = NEW_DOCUMENT;
  constructor(public payload: any) {}
}

export class NewDocumentFailure implements Action {
  readonly type = NEW_DOCUMENT_FAILURE;
  constructor(public payload: any) {}
}

export class NewDocumentCreate implements Action {
  readonly type = NEW_DOCUMENT_CREATE;
  constructor(public payload: DocumentRecipient) {}
}

export class NewDocumentCreateOnline implements Action {
  readonly type = NEW_DOCUMENT_CREATE_ONLINE;
  constructor(public payload: DocumentRecipient) {}
}

export class NewDocumentCreateSuccess implements Action {
  readonly type = NEW_DOCUMENT_CREATE_SUCCESS;
  constructor(public payload: string) {}
}

export class NewDocumentCreateFailure implements Action {
  readonly type = NEW_DOCUMENT_CREATE_FAILURE;
  constructor(public payload: any) {}
}

export class EditPrecedentStart implements Action {
  readonly type = EDIT_PRECEDENT_START;
  constructor(public payload: ISelectCustomPrecedentPayload) {}
}

export class EditPrecedentOnline implements Action {
  readonly type = EDIT_PRECEDENT_ONLINE;
  constructor(public payload: ISelectCustomPrecedentPayload) {}
}

export class EditPrecedentSuccess implements Action {
  readonly type = EDIT_PRECEDENT_SUCCESS;
  constructor(public payload: string) {}
}

export class EditPrecedentFailure implements Action {
  readonly type = EDIT_PRECEDENT_FAILURE;
  constructor(public payload: any) {}
}

export class NewDocumentTypeCreate implements Action {
  readonly type = NEW_DOCUMENT_TYPE_CREATE;

  constructor(
    public payload: {
      applicationType: AutomationHostApplicationType;
      blank: ICustomPrecedent;
    }
  ) {}
}

export type DocumentActions =
  | NewDocument
  | NewDocumentFailure
  | NewDocumentCreate
  | NewDocumentCreateSuccess
  | NewDocumentCreateFailure
  | NewDocumentTypeCreate
  | EditPrecedentStart
  | EditPrecedentSuccess
  | EditPrecedentFailure
  | NewDocumentCreateOnline
  | EditPrecedentOnline;
