import { Component, HostBinding } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';

@Component({
  selector: 'sc-type-icon-cell',
  templateUrl: './type-icon-cell.component.html',
})
export class TypeIconCellComponent implements AgRendererComponent {
  wrapperClass: string;
  iconClass: string;
  isPinned: boolean;
  title: string;

  constructor() {}

  @HostBinding('class.d-flex')
  agInit(params: any): void {
    this.wrapperClass = params.getWrapperClass(params.data);
    this.iconClass = params.getIconClass(params.data) + '-24';
    this.title = params.getTitle(params.data);
    if (this.wrapperClass === 'doc-pin') {
      this.isPinned = true;
    }
  }

  refresh(params: any): boolean {
    return true;
  }
}
