import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isMatterCard } from '@app/features/+matter-details/functions';
import { IMatterCard, IMatterDetailEntry } from '@app/shared/models';
import { ILeapDropdownOptions, ILeapTag, ILeapTagsOptions, IPerson } from '@app/shared/models';
import { PermissionsService } from '@app/core/services';
import { BaseDetailEntryAbstract } from '../detail-entry-item/base-detail-entry.abstract';
import { IPersonEntry, ICardListEntry } from '@app/features/+card/models';

@Component({
  selector: 'sc-detail-non-empty',
  templateUrl: './detail-non-empty.component.html',
})
export class DetailNonEmptyComponent extends BaseDetailEntryAbstract implements OnInit {
  @Input()
  contextMenuEnabled: boolean;
  isOpen = false;

  @Input() persons: IPersonEntry[];
  @Input() popupCardEntry: ICardListEntry;

  @Output() onCreateEmail = new EventEmitter();
  @Output() onEditCardDetails = new EventEmitter();

  @Output()
  onAddActingPerson = new EventEmitter<IPerson>();
  @Output()
  onRemoveActingPerson = new EventEmitter<IPerson>();
  @Output()
  onShowPopUp = new EventEmitter<string>();
  @Output()
  onHidePopUp = new EventEmitter<void>();

  @Input()
  isRecurringMatter = false;

  get canAddPerson(): boolean {
    return (this.unusedPersons || []).length > 0 && this.hasMatterWrite;
  }

  public usedPersons: IPerson[];
  public unusedPersons: IPerson[];

  private _usedPersonTags: ILeapTag<IPerson>[];

  get personTagsOptions(): ILeapTagsOptions<IPerson> {
    return {
      items: this._usedPersonTags,
      itemLabel: getPersonFullName,
      wrapperClass: 'x-badge-container',
      itemClicked: (person: IPerson) => {
        // TODO: open card in person tab - support when popover is ready.
      },
      itemRemoved: (person: IPerson) => {
        this.removePerson(person);
      },
      readonly: !this.hasMatterWrite,
    };
  }

  get addPersonDropdownOptions(): ILeapDropdownOptions {
    return {
      items: this.unusedPersons,
      itemLabel: getPersonFullName,
      itemClicked: (person: IPerson) => {
        this.onAddActingPerson.emit(person);
      },
      selectedText: () => '',
      wrapperClass: 'd-flex',
      iconClass: 'plus-circle-outline',
      iconButton: true,
    };
  }

  get hasMatterWrite(): boolean {
    return this._permissionsSvc.hasMatterWrite;
  }

  get hasReadPermissions(): boolean {
    return this._permissionsSvc.hasReadPermissions;
  }

  get isCard(): boolean {
    return isMatterCard(this.detailEntry);
  }

  get shouldDisplayPopper(): boolean {
    const { isPersonCard, cardInfo, personSchemas } = this.detailEntry as IMatterCard;
    // we show the ngx-popper, when
    // 1) the detailEntry isPersonCard or has cardInfo type
    // 2) the detailsEntry has persons
    // 3) at least one person has either email or phone
    return (
      (isPersonCard || (cardInfo && cardInfo.type)) &&
      personSchemas &&
      personSchemas.length > 0 &&
      !!personSchemas.find((person) => !!person.email || !!person.phone)
    );
  }

  public detailEntryDescription: string;

  constructor(private _permissionsSvc: PermissionsService) {
    super();
  }

  ngOnInit() {
    this.generateDetailEntryDescription();
  }

  showPopup() {
    this.isOpen = true;
    const { cardId } = this.detailEntry;
    this.onShowPopUp.emit(cardId);
  }

  hidePopup() {
    this.isOpen = false;
    this.onHidePopUp.emit();
  }

  detailEntryUpdated(detailEntry: IMatterDetailEntry): void {
    if (isMatterCard(detailEntry)) {
      const { persons: rawPersons, unusedPersons: rawUnusedPersons } = this.detailEntry as IMatterCard;
      this.usedPersons = rawPersons;
      this.unusedPersons = rawUnusedPersons;

      this._usedPersonTags = this.usedPersons?.map((usedPerson) => ({ item: usedPerson, iconClass: 'user' })) || [];
      return;
    }
    this.usedPersons = [];
    this.unusedPersons = [];
  }

  removePerson(person: IPerson): void {
    this.onRemoveActingPerson.emit(person);
  }

  createEmail(event) {
    this.onCreateEmail.emit(event);
  }

  editCardDetails(event) {
    this.onEditCardDetails.emit(event);
  }

  generateDetailEntryDescription() {
    if (!this.detailEntry.__description && !this.detailEntry.__name) {
      this.detailEntryDescription = 'Modify Details';
      return;
    }
    this.detailEntryDescription = this.detailEntry.__description
      ? this.detailEntry.__description
      : `Modify ${this.detailEntry.__name}`;
  }
}

const getPersonFullName = (person: IPerson): string => {
  if (!person) {
    return '';
  }
  const { firstNames: firstName, lastName } = person;
  let fullName = '';
  fullName += !!firstName ? firstName : '';
  fullName += !!lastName ? ` ${lastName}` : '';
  return fullName.trim();
};
